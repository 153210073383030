<template>
  <chart-js
    :chart-data="yChartData.data"
    :options="yChartData.options"
    :height="height ? height : 500"
  ></chart-js>
</template>

<script>
import chartJs from '@/assets/js/chartJs'

export default {
  components: {
    chartJs
  },
  props: ['dateRange', 'chartData', 'height'],
  data() {
    return {}
  },
  computed: {
    daysStandard: function() {
      return this.$getDateRange(this.dateRange[0], this.dateRange[1])
    },
    yChartData: function() {
      // =======================
      // yChart Variables
      // =======================
      let pointBackgroundColor = '#FFF'
      let pointRadius = 6
      let pointHitRadius = 6
      let pointHoverRadius = 6
      let borderWidth = 2
      let rgbs = ['37,41,62', '14,118,188', '37,41,62', '14,118,188'] // Update 3rd and 4th Color
      let fontFamily =
        "'Heebo', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
      var xLabels = this._.map(this.daysStandard, this.getDayLabel)

      let yAxesData = () => {
        let yAxes = []
        var datasets = this.chartData.datasets
        datasets.forEach((element, index) => {
          let yAxe = {
            id: element.id ? element.id : this.stringToId(element.label),
            type: 'linear',
            position: this.isOdd(index) ? 'right' : 'left',
            scaleLabel: {
              display: true,
              labelString: element.label,
              fontColor: 'rgba(' + rgbs[index] + ',1)',
              fontStyle: 'bold',
              fontFamily: fontFamily
            },
            ticks: {
              callback: (value, index) => {
                if (!index) {
                  return
                }
                return this.formatType(value, element.formatType)
              },
              fontFamily: fontFamily,
              fontColor: 'rgba(' + rgbs[index] + ',1)',
              padding: 10
            },
            gridLines: {
              display: index == 0 || index == 2 ? true : false,
              drawBorder: false,
              drawTicks: false
            }
          }
          yAxes.push(yAxe)
        })
        return yAxes
      }

      let labelsData = () => {
        let labels = []
        let datasets = this.chartData.datasets
        datasets.forEach((element, index) => {
          let label = {
            text: element.label,
            fillStyle: '#FFF',
            strokeStyle: 'rgba(' + rgbs[index] + ',1)',
            pointStyle: 'circle',
            lineWidth: 2,
            boxWidth: 10
          }
          if (element.legend && element.legend.label) {
            this._.merge(label, element.legend.label)
          }

          labels.push(label)
        })
        return labels
      }

      let datasetsData = () => {
        let output = []
        let datasets = this.chartData.datasets

        let backgroundOpacity = 0.05
        let borderOpacity = 1

        datasets.forEach((element, index) => {
          let outputItem = {
            label: element.label,
            yAxisID: element.id ? element.id : this.stringToId(element.label),
            data: element.data,
            backgroundColor:
              'rgba(' +
              rgbs[index] +
              ',' +
              (element.backgroundOpacity
                ? element.backgroundOpacity
                : backgroundOpacity) +
              ')',
            borderColor:
              'rgba(' +
              rgbs[index] +
              ',' +
              (element.borderOpacity ? element.borderOpacity : borderOpacity) +
              ')',
            borderWidth: borderWidth,
            lineTension: 0,
            pointBackgroundColor: pointBackgroundColor,
            pointBorderColor:
              'rgba(' +
              rgbs[index] +
              ',' +
              (element.borderOpacity ? element.borderOpacity : borderOpacity) +
              ')',
            pointRadius: pointRadius,
            pointHitRadius: pointHitRadius,
            pointHoverRadius: pointHoverRadius,
            pointStyle: element.pointStyle ? element.pointStyle : null,
            borderDash: element.borderDash ? element.borderDash : null
          }
          output.push(outputItem)
        })
        return output
      }

      // =======================
      // yChart Variables Ends
      // =======================

      let legend = {
        position: 'bottom',
        fullWidth: true,
        labels: {
          boxWidth: 10,
          fontFamily: fontFamily,
          usePointStyle: true,
          padding: 20,
          fontSize: 10,
          generateLabels: function() {
            return labelsData()
          }
          //text: "Revenue",
          //fillStyle: line2Color,
          //pointStyle: "border-radius: 40px; width: 10px; height: 10px;"
        }
      }
      // if(element.legend){
      //    _.merge(legend, element.legend)
      // }

      return {
        options: {
          title: {
            text: ''
          },
          tooltips: {
            backgroundColor: 'rgba(0,0,0,0.7)',
            // titleFontFamily: fontFamily,
            // bodyFontFamily: fontFamily,
            titleFontSize: 11,
            bodyFontSize: 11,
            displayColors: false,
            caretSize: 5,
            titleSpacing: 0,
            callbacks: {
              label: tooltipItem => {
                return this.formatType(
                  tooltipItem.yLabel,
                  this.chartData.datasets[tooltipItem.datasetIndex].formatType
                )
              }
            }
          },
          legend: legend,
          scales: {
            yAxes: yAxesData(),
            xAxes: [
              {
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function(value) {
                    return value
                  },
                  fontFamily: fontFamily,
                  //fontColor: line1Color,
                  padding: 5
                }
              }
            ]
          },
          responsive: true,
          maintainAspectRatio: false
        },
        data: {
          labels: xLabels,
          datasets: datasetsData()
        }
      }
    }
  },
  mounted() {
    //this.updateChartData()
  },
  updated() {},
  methods: {
    formatType(value, type) {
      let output
      switch (type) {
        case 'currency':
          output = this.toLocaleCurrency(value)
          break
        case 'integer':
          output = parseInt(value).toLocaleString('en-US')
          break
        default:
          output = value
          break
      }
      return output
    },
    stringToId(string) {
      return string.replace(/\s+/g, '-').toLowerCase()
    },
    isOdd(num) {
      return num % 2
    },
    toLocaleCurrency(num) {
      num = parseFloat(num)
      return num.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    formatPercentage(num) {
      var output = Math.round(num)
      output = output < 0 ? output * -1 : output
      output = output == Infinity ? '∞' : output + '%'
      return output
    },
    getDayLabel(day) {
      return this.$luxon(day, 'LLL d')
    },
    getDateStandard(day) {
      return day.format('YYYY-MM-DD')
    }
  }
}
</script>
<style></style>
