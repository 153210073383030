<template>
  <div class="px-12">
    <h5 class="font-bold uppercase text-gray-500 mb-1">
      <small>Previous Payouts</small>
    </h5>
    <ul>
      <li v-for="payout in rowData.previous" :key="payout.id">
        <span class="mr-2">
          <span class="text-sm text-indigo-600">Rate:</span> {{ payout.rate }}
        </span>
        <span class="mr-2">
          <span class="text-sm text-indigo-600">Start:</span>
          {{ payout.startDate | luxon }}
        </span>
        <span class="mr-2">
          <span class="text-sm text-indigo-600">End:</span>
          {{ payout.endDate | luxon }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  updated() {},

  methods: {}
}
</script>
<style scoped></style>
