var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isHeader ? _c('th', {
    staticClass: "vuetable-th-component-checkbox"
  }, [_c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.isAllItemsInCurrentPageSelected()
    },
    on: {
      "change": function ($event) {
        return _vm.toggleAllCheckbox($event);
      }
    }
  })]) : _c('td', {
    staticClass: "vuetable-td-component-checkbox"
  }, [_c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.isSelected(_vm.rowData)
    },
    on: {
      "change": function ($event) {
        return _vm.toggleCheckbox(_vm.rowData, $event);
      }
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }