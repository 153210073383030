var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.offers ? _c('div', {
    staticClass: "py-6"
  }, [_c('Superselect', {
    staticClass: "mb-2",
    attrs: {
      "title": "Offers",
      "track-by": "id",
      "label": "label",
      "options": [],
      "hideSelected": true,
      "query": _vm.OFFERS_QUERY,
      "query-variables": {
        first: 10,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          traffic: {
            campaignId: this.campaignId
          }
        }
      },
      "query-default-input-text": ''
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('YImage', {
          staticClass: "rounded mr-2",
          attrs: {
            "src": props.option.legacyImageThumbnailUrl,
            "width": 12,
            "height": 12
          }
        }), _c('div', [_c('div', [_vm._v(_vm._s(props.option.label))]), _vm._l(props.option.advertisers, function (advertiser) {
          return _c('div', {
            key: advertiser.advertiserId,
            staticClass: "text-xxs"
          }, [_vm._v(" " + _vm._s(advertiser.primaryContact.name) + " ")]);
        })], 2)], 1)];
      }
    }], null, false, 4010288765),
    model: {
      value: _vm.offers,
      callback: function ($$v) {
        _vm.offers = $$v;
      },
      expression: "offers"
    }
  }), _vm.hasChanges ? _c('YAlert', {
    staticClass: "mb-2",
    attrs: {
      "allowClose": false,
      "color": "blue"
    }
  }, [_c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('div', [_vm._v(" You have unsaved changes ")]), _c('div', [_c('YButton', {
    staticClass: "hover:bg-blue-300 mr-1",
    attrs: {
      "color": "link",
      "disabled": _vm.isSaving
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v("Reset")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSaving
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save")])], 1)])]) : _vm._e(), _c('Draggable', _vm._b({
    attrs: {
      "draggable": ".x-offer"
    },
    model: {
      value: _vm.offers,
      callback: function ($$v) {
        _vm.offers = $$v;
      },
      expression: "offers"
    }
  }, 'Draggable', _vm.dragOptions, false), _vm._l(_vm.offers, function (offer) {
    return _c('div', {
      key: offer.id,
      staticClass: "x-offer group"
    }, [_c('div', {
      staticClass: "flex justify-between items-center hover:bg-blue-100 rounded"
    }, [_c('div', {
      staticClass: "flex items-center p-2"
    }, [_c('YImage', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: `Assign to Campaign`,
        expression: "`Assign to Campaign`"
      }],
      staticClass: "rounded mr-4",
      class: [offer.legacyImageThumbnailUrl ? 'hover:shadow-md cursor-pointer' : ''],
      attrs: {
        "src": offer.legacyImageThumbnailUrl,
        "width": 16,
        "height": 16
      },
      on: {
        "click": function ($event) {
          return _vm.openAddMediaFromUrlModal(offer.legacyImageUrl);
        }
      }
    }), _c('div', [_c('div', {
      staticClass: "mb-1"
    }, [_c('div', [_vm._v(" " + _vm._s(offer.label) + " "), offer.isCapped ? _c('Tag', {
      staticClass: "ml-1",
      attrs: {
        "color": "darkRed"
      }
    }, [_vm._v("CAPPED")]) : _vm._e()], 1), _vm._l(offer.advertisers, function (advertiser) {
      return _c('div', {
        key: advertiser.advertiserId,
        staticClass: "text-xxs text-gray-600"
      }, [_vm._v(" " + _vm._s(advertiser.primaryContact.name) + " ")]);
    })], 2), _c('div', [_c('div', {
      staticClass: "text-xxs text-gray-600"
    }, [_vm._v(_vm._s(offer.encodedId))])])])], 1), _c('div', {
      staticClass: "flex-shrink flex items-center"
    }, [_c('button', {
      staticClass: "x-offer-remove text-blue-600 hover:text-blue-700 hover:bg-blue-200 p-2 mr-2 rounded group-hover:opacity-100 opacity-0 outline-none-important block",
      on: {
        "click": function ($event) {
          return _vm.removeOffer(offer.id);
        }
      }
    }, [_c('Icon', {
      staticClass: "block",
      attrs: {
        "name": "close"
      }
    })], 1)])])]);
  }), 0)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }