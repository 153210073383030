<template>
  <div
    class="font-sans antialiased h-screen w-full flex flex-wrap overflow-auto pt-8"
    :style="
      `background-image: url(${bgImage}); background-size: cover; background-position: center;`
    "
  >
    <div class="w-full flex flex-col m-auto p-4">
      <div class="w-full m-auto " style="max-width: 24rem;">
        <div class="pt-6 pb-2 bg-white rounded-t-xl border-t border-l border-r">
          <img
            class="block m-auto w-full"
            :src="logo"
            style="max-width: 9rem;"
          />
        </div>
      </div>
      <div class="w-full m-auto">
        <slot><router-view /></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BgImage from '@/assets/images/backgrounds/bg-circles.svg'
import Logo from '@/assets/images/logo/logo_ytz.png'

export default {
  components: {},
  data() {
    return {
      logo: Logo,
      bgImage: BgImage
    }
  }
}
</script>

<style scoped></style>
