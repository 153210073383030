var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grid grid-cols-3 gap-2"
  }, [_c('YButton', {
    staticClass: "bg-blue-200 text-blue-700 mr-1 hover:bg-blue-300",
    attrs: {
      "disabled": _vm.selections.length > 0 ? false : true
    },
    on: {
      "click": function ($event) {
        return _vm.openBulkEditTagsModal(_vm.selections);
      }
    }
  }, [_vm._v(" Edit Tags ")]), _c('YButton', {
    staticClass: "mr-1",
    attrs: {
      "disabled": _vm.selections.length > 0 ? false : true,
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.openBulkEditDomainStatusModal(_vm.selections);
      }
    }
  }, [_vm._v(" Edit Status ")]), _c('YButton', {
    staticClass: "mr-1",
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.openEditModal(_vm.selections);
      }
    }
  }, [_vm._v(" Edit Records ")]), _c('YButton', {
    staticClass: "bg-blue-200 text-blue-700 mr-1 hover:bg-blue-300",
    on: {
      "click": _vm.openCreateModal
    }
  }, [_vm._v(" Add Domains ")]), _c('YButton', {
    staticClass: "p-0",
    attrs: {
      "disabled": _vm.selections.length > 0 ? false : true,
      "color": "red"
    },
    on: {
      "click": function ($event) {
        return _vm.openDeleteModal(_vm.selections);
      }
    }
  }, [_vm._v(" Delete Domains ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }