var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "py-6 border-b",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "flex justify-between items-center w-100 ts-4"
  }, [_c('div', {
    staticClass: "font-bold"
  }, [_c('i', {
    staticClass: "zmdi -file-plzmdius pr-2"
  }), _vm._v(" Replace Domain (" + _vm._s(_vm.domain.id) + ") ")]), _c('close-x', {
    staticClass: "text-white",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  })], 1)])], 2), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "value": _vm.domain.id,
      "label": "Domain",
      "readonly": ""
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Registrars",
      "options": _vm.registrars,
      "multiple": false
    },
    on: {
      "select": function ($event) {
        _vm.replacementDomain = null;
      }
    },
    model: {
      value: _vm.selectedRegistrar,
      callback: function ($$v) {
        _vm.selectedRegistrar = $$v;
      },
      expression: "selectedRegistrar"
    }
  }), _c('div', [_c('div', {
    staticClass: "flex items-center h-12"
  }, [_c('y-radio', {
    staticClass: "mr-4",
    attrs: {
      "label": "Auto Assign Replacement",
      "name": "replacement-domain-mode",
      "radio-value": "auto",
      "color": "info"
    },
    model: {
      value: _vm.replacementDomainMode,
      callback: function ($$v) {
        _vm.replacementDomainMode = $$v;
      },
      expression: "replacementDomainMode"
    }
  }), _c('y-radio', {
    attrs: {
      "label": "Select Replacement",
      "name": "replacement-domain-mode",
      "radio-value": "manual",
      "color": "info"
    },
    model: {
      value: _vm.replacementDomainMode,
      callback: function ($$v) {
        _vm.replacementDomainMode = $$v;
      },
      expression: "replacementDomainMode"
    }
  })], 1), _vm.replacementDomainMode == 'manual' ? [_vm.domainOptions.length > 0 ? _c('multiselect', {
    attrs: {
      "title": "Domain",
      "placeholder": "Select Domain",
      "options": _vm.domainOptions,
      "track-by": "id",
      "label": "label",
      "multiple": false
    },
    model: {
      value: _vm.replacementDomain,
      callback: function ($$v) {
        _vm.replacementDomain = $$v;
      },
      expression: "replacementDomain"
    }
  }) : _c('div', {
    staticClass: "pt-1 text-center"
  }, [_c('small', {
    staticClass: "text-muted",
    staticStyle: {
      "font-style": "italic"
    }
  }, [_vm._v("No Domains Available")])])] : _vm._e()], 2)], 1), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('y-checkbox', {
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue",
      "disabled": !_vm.canSubmit
    },
    on: {
      "click": _vm.submit,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm._v("Submit")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }