var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-actions"
  }, [_c('a', {
    staticClass: "text-danger",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.itemAction('delete-item', _vm.rowData, _vm.rowIndex);
      }
    }
  }, [_c('Icon', {
    staticClass: "w-4 h-4",
    attrs: {
      "name": "trashCan"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }