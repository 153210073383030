var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "font-semibold text-sm list-none block cursor:pointer"
  }, [_vm.parent.name !== 'Settings' ? _c('div', {
    staticClass: "flex items-center justify-between mb-2 cursor-pointer pr-1 rounded-md hover:bg-inverse-700",
    class: _vm.parent.name == _vm.currentRoute ? ['bg-inverse-500'] : ['bg-inverse-900']
  }, [_c('router-link', {
    staticClass: "w-10/12 py-2 pl-4 rounded-md",
    attrs: {
      "to": {
        name: _vm.parent.name
      },
      "target": _vm.parent.meta.newTable ? '_blank' : ''
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "hover:bg-inverse-500 rounded-full",
    on: {
      "click": _vm.toggleShow
    }
  }, [_c('svg', {
    staticClass: "dropdown-menu",
    class: {
      'rotate-90': _vm.showMenu
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill": "none",
      "d": "M0 0h24v24H0V0z"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z",
      "fill": "#fffffe"
    }
  })])])], 1) : _vm._e(), _vm.parent.name == 'Settings' ? _c('div', {
    staticClass: "flex items-center justify-between mb-2 cursor-pointer pr-1 rounded-md hover:bg-inverse-700 py-2 pl-4 rounded-md",
    on: {
      "click": _vm.toggleShow
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('div', {
    staticClass: "hover:bg-inverse-600 rounded-full"
  }, [_c('svg', {
    staticClass: "dropdown-menu",
    class: {
      'rotate-90': _vm.showMenu
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill": "none",
      "d": "M0 0h24v24H0V0z"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z",
      "fill": "#fffffe"
    }
  })])])]) : _vm._e(), _c('ul', {
    staticClass: "ml-4 overflow-hidden dropdown-menu font-normal",
    class: this.class
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }