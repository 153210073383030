var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_vm._v(" Bulk Payout Updates "), _vm.offersLoaded ? [_vm._v("for "), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'Advertiser',
        params: {
          id: _vm.offers.data[0].advertisers[0].id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.offers.data[0].advertisers[0].label))])] : _vm._e()], 2), _vm._l(_vm.setIds, function (setId) {
    return [_c('div', {
      key: setId,
      staticClass: "bg-gray-100 p-1 m-1 rounded shadow-sm"
    }, [_vm.offersLoaded ? _c('OfferRevenueUpsertRow', {
      key: setId,
      attrs: {
        "id": setId,
        "offers-to-edit": _vm.offers.data
      },
      on: {
        "summaryUpdated": _vm.updateSummary,
        "updated": _vm.updateSet,
        "deleted": _vm.deleteSet
      }
    }) : _vm._e()], 1)];
  }), _c('div', {
    staticClass: "flex justify-between my-4"
  }, [_c('div'), _c('div', [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.addSet
    }
  }, [_vm._v("Add New Row")])], 1)]), _vm._m(0), _vm.summaries ? _c('YTable', {
    staticClass: "table-striped table-hover overflow-hidden",
    attrs: {
      "data": Object.values(_vm.summaries),
      "block-style": "min-height: 25rem;"
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [props.rowData.offers ? _vm._l(props.rowData.offers, function (offer) {
          return _c('div', {
            key: offer.id
          }, [_c('router-link', {
            staticClass: "link",
            attrs: {
              "to": {
                name: 'Offer Edit',
                params: {
                  id: offer.id
                }
              }
            }
          }, [_vm._v(" " + _vm._s(offer.label) + " ")])], 1);
        }) : _vm._e()], 2), _c('td', [props.rowData.accounts ? _vm._l(props.rowData.accounts, function (account) {
          return _c('div', {
            key: account.id
          }, [_vm._v(" " + _vm._s(account.label) + " ")]);
        }) : _vm._e()], 2), _c('td', [props.rowData.countries ? _vm._l(props.rowData.countries, function (country) {
          return _c('div', {
            key: country.code
          }, [_vm._v(" " + _vm._s(country.code) + " ")]);
        }) : _vm._e()], 2), _c('td', [props.rowData.campaigns ? _vm._l(props.rowData.campaigns, function (campaign) {
          return _c('div', {
            key: campaign.id
          }, [_c('router-link', {
            staticClass: "link",
            attrs: {
              "to": {
                name: 'Campaign',
                params: {
                  id: campaign.id
                }
              }
            }
          }, [_vm._v(" " + _vm._s(campaign.label) + " ")])], 1);
        }) : _vm._e()], 2), _c('td', [props.rowData.redirects ? _vm._l(props.rowData.redirects, function (redirect) {
          return _c('div', {
            key: redirect.id
          }, [_c('router-link', {
            staticClass: "link",
            attrs: {
              "to": {
                name: 'Redirect',
                params: {
                  id: redirect.id
                }
              }
            }
          }, [_vm._v(" " + _vm._s(redirect.label) + " ")])], 1);
        }) : _vm._e()], 2), _c('td', [props.rowData.sources ? _vm._l(props.rowData.sources, function (source) {
          return _c('div', {
            key: source
          }, [_vm._v(" " + _vm._s(source) + " ")]);
        }) : _vm._e()], 2), _c('td', [_vm._v(" " + _vm._s(props.rowData.value) + " ")])];
      }
    }], null, false, 212782540)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v(" Offers ")]), _c('th', [_vm._v(" Account ")]), _c('th', [_vm._v(" Countries ")]), _c('th', [_vm._v(" Campaigns ")]), _c('th', [_vm._v(" Redirects ")]), _c('th', [_vm._v(" Sources ")]), _c('th', [_vm._v(" Value ")])])], 2) : _vm._e(), _c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('YButton', {
    staticClass: "mr-1",
    attrs: {
      "color": "blue",
      "disabled": _vm.saveDisabled || _vm.isUpserting,
      "is-loading": _vm.isUpserting
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save ")])], 1)]), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-2"
  }, [_c('span', {
    staticClass: "uppercase text-gray-600 font-bold"
  }, [_vm._v("Update Preview")])]);

}]

export { render, staticRenderFns }