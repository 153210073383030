<template>
  <a @click="updateValue(toggleValue)" :class="className">
    <slot></slot>
  </a>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: [Boolean, String, Number, Object, Array]
    },
    id: {
      type: String
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    inline: {
      type: Boolean
    },
    toggleValues: {
      type: Array,
      default: () => [true, false]
    }
  },
  data() {
    return {

    }
  },
  computed: {
    toggleValue: {
      get: function(){
        return this.value
      },
      set: function(value){
        this.$emit('input', value)
      }
    },
    className() {
      let className = this.value ? 'active' : ''
      return className
    }
  },

  mounted() {},

  updated() {},

  methods: {
    updateValue(oldValue) {
      const oldIndex = this.toggleValues.indexOf(oldValue)
      const isLastItem = oldIndex == this.toggleValues.length - 1 ? true : false
      let newValue

      if (isLastItem) {
        newValue = this.toggleValues[0]
      } else {
        newValue = this.toggleValues[oldIndex + 1]
      }

      this.toggleValue = newValue
    }
  }
}
</script>
<style></style>
