var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-heading flex justify-between items-center flex-shrink"
  }, [_vm.$slots.default ? _c('div', {
    staticClass: "items-center flex"
  }, [_c('Icon', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.icon,
      expression: "icon"
    }],
    staticClass: "mr-4",
    class: _vm.iconClass,
    attrs: {
      "name": "iconName"
    }
  }), _c('h4', {
    staticClass: "widget-title"
  }, [_vm._t("default")], 2), _vm.badge ? _c('div', {
    staticClass: "ml-3",
    class: 'badge badge-' + _vm.badge.style
  }, [_vm._v(" " + _vm._s(_vm.badge.text) + " ")]) : _vm._e()], 1) : _vm._e(), _vm._t("rawContent"), _vm._t("right", function () {
    return [_vm.hasCloseListener ? _c('h4', {
      staticClass: "ml-4",
      class: _vm.closeClass
    }, [_c('CloseX', {
      on: {
        "click": function ($event) {
          return _vm.$emit('close');
        }
      }
    })], 1) : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }