<template>
  <div class="flex justify-center">
    <div class="grid grid-cols-12 gap-3 flex-grow max-w-screen-2xl">
      <div
        class="col-span-12 lg:col-span-8 row-span-1 rounded-md grid grid-cols-12 gap-5"
      >
        <div class="col-span-12 lg:col-span-9 shadow-md">
          <div class="header-container header-border">Daily Summary</div>
          <simple-report :filters="reportFilters"> </simple-report>
        </div>

        <div class="hidden lg:grid lg:col-span-3 flex flex-col gap-4">
          <div class="data-block" style="background: #ef4565; color: #fffffe">
            <h2>net revenue</h2>

            <h3>${{ totalRevenue }}</h3>
          </div>
          <div class="data-block" style="background: #ef4565; color: #fffffe">
            <h2>impressions</h2>
            <h3>{{ totalImpressions }}</h3>
          </div>
          <div class="">
            <router-link
              :to="{
                name: 'RequestedRedirects',
                params: { status: 'requested' }
              }"
            >
              <div class="data-block" style="background: #3da9fc">
                <span class="flex flex-row justify-center"
                  ><h2>campaign requests</h2>
                  <Icon name="openInNew" :size="3" class="mt-1 ml-1"
                /></span>

                <Spinner
                  v-if="$apollo.queries.campaignRequests.loading"
                  :size="6"
                  class="mt-2 ml-0 absolute"
                />
                <span v-else class="text-xl font-extrabold">
                  <h3>{{ campaignRequests.paginatorInfo.total }}</h3></span
                >
              </div>
            </router-link>
          </div>
          <div class="">
            <router-link
              :to="{
                name: 'Users',
                params: {
                  filterValues: {
                    status: 'Pending'
                  }
                }
              }"
            >
              <div class="data-block" style="background: #3da9fc">
                <span class="flex flex-row justify-center"
                  ><h2>pending users</h2>
                  <Icon name="openInNew" :size="3" class="mt-1 ml-1"
                /></span>
                <Spinner
                  v-if="$apollo.queries.pendingUsers.loading"
                  :size="6"
                  class="mt-2 ml-0 mb-0 absolute"
                />

                <span v-else class="text-xl font-extrabold">
                  <h3>{{ pendingUsers.paginatorInfo.total }}</h3>
                </span>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- col 2, row 1 span 2 -->
      <div
        class="col-span-12 lg:col-span-4 lg:row-span-2 rounded-md flex flex-col justify-between gap-3 px-3 py-8 shadow-md bg-gray-100"
      >
        <!-- new revenue publishers -->
        <div
          class="rounded-md col-span-4 rounded-md py-2 px-4 border"
          style="background: #fffffe"
        >
          <div class="flex justify-between items-center mb-2">
            <div class="font-bold text-base" style="color: #094067">
              New Publishers Top Revenue
            </div>
            <div class="text-xs font-semibold" style="color: #094067">
              90 days
            </div>
          </div>
          <Spinner
            v-if="$apollo.queries.publishersNewRevenue.loading"
            :size="6"
            class="mt-2 ml-0 mb-0 absolute"
          />
          <ul
            v-for="publisher in publishersNewRevenue"
            :key="publisher.id"
            class="pl-2"
          >
            <li class="flex justify-between pt-1">
              <div v-tooltip="publisher.user.id">
                <router-link
                  :to="{
                    name: 'User',
                    params: { id: publisher.user.id }
                  }"
                  class="hover:text-blue-800"
                >
                  {{ publisher.user.email }}
                </router-link>
              </div>
              <div class="text-xs font-bold text-gray-900">
                {{ $formatMoney(publisher.realRevenue) }}
              </div>
            </li>
          </ul>
        </div>

        <!-- new revenue advertisers -->
        <div
          class="rounded-md col-span-4 rounded-md py-2 px-4 border"
          style="background: #fffffe"
        >
          <div class="flex justify-between items-center mb-2">
            <div class="font-bold text-base" style="color: #094067">
              New Advertisers Net Revenue
            </div>
            <div class="text-xs font-semibold" style="color: #094067">
              90 days
            </div>
          </div>
          <Spinner
            v-if="$apollo.queries.advertisersNetRevenue.loading"
            :size="6"
            class="mt-2 ml-0 mb-0 absolute"
          />
          <ul
            v-for="advertiser in advertisersNetRevenue"
            :key="advertiser.user.id"
            class="pl-2"
          >
            <li class="flex justify-between pt-1">
              <div v-tooltip="advertiser.user.id">
                <router-link
                  :to="{
                    name: 'User',
                    params: { id: advertiser.user.id }
                  }"
                  class="hover:text-blue-800"
                >
                  {{
                    advertiser.user.label
                      .split(' - ')
                      .slice(1)
                      .join(' - ')
                  }}
                </router-link>
              </div>
              <div
                class="text-xs font-bold text-gray-900"
                :class="
                  advertiser.netRevenue >= 0
                    ? 'text-green-600'
                    : 'text-pink-600'
                "
              >
                <span v-if="advertiser.netRevenue > 0">+</span>
                {{ $formatMoney(advertiser.netRevenue) }}
              </div>
            </li>
          </ul>
          <span class="text-xs text-gray-800 block w-full text-right italic">
            Net revenue = revenue - payout</span
          >
        </div>

        <!-- Negative Net revenues -->
        <div
          class="rounded-md col-span-4 rounded-md py-2 px-4 border"
          style="background: #fffffe"
        >
          <div class="flex justify-between items-center mb-2">
            <div class="font-bold text-base" style="color: #094067">
              Net Revenue
            </div>
            <div class="text-xs font-semibold" style="color: #094067">
              30 days
            </div>
          </div>
          <Spinner
            v-if="$apollo.queries.userNetRevenueVariance.loading"
            :size="6"
            class="mt-2 ml-0 mb-0 absolute"
          />
          <ul
            v-for="publisher in userNetRevenueVariance"
            :key="publisher.user.id"
            class="pl-2"
          >
            <li class="flex justify-between pt-1">
              <div v-tooltip="publisher.user.id">
                <router-link
                  :to="{
                    name: 'User',
                    params: { id: publisher.user.id }
                  }"
                  class="hover:text-blue-800"
                >
                  {{ publisher.user.email }}
                </router-link>
              </div>
              <div
                class="text-xs font-bold text-gray-900"
                :class="
                  publisher.netRevenue > 0 ? 'text-green-600' : 'text-pink-600'
                "
              >
                <span v-if="publisher.netRevenue > 0">+</span>
                {{ $formatMoney(publisher.netRevenue) }}
              </div>
            </li>
          </ul>
          <span class="text-xs text-gray-800 block w-full text-right italic">
            Net revenue = revenue - payout</span
          >
        </div>
      </div>

      <div class="hidden lg:col-span-8 lg:grid lg:grid-cols-8 gap-4 rounded-md">
        <!-- status cake -->
        <div class="rounded-md col-span-4 bg-white shadow-md">
          <div class="header-container header-border">Status Cake</div>
          <div
            class="border-l border-b rounded-b-md overflow-y-scroll"
            style="height: 14rem"
          >
            <StatusCakeTable />
          </div>
        </div>

        <!-- login history -->
        <div class="rounded-md col-span-4 bg-white shadow-md">
          <div class="header-container header-border">Logins</div>
          <div
            class="border-l border-b rounded-b-md overflow-y-scroll"
            style="height: 14rem"
          >
            <YTable
              :scrollX="false"
              :data="recentLogins || []"
              :bottom-loading="$apolloData.queries.recentLogins.loading"
            >
              <template slot="header-row">
                <th>User</th>
                <th>last login</th>
              </template>
              <template slot="row" slot-scope="props">
                <td>
                  <div class="flex flex-col">
                    <router-link
                      :to="{
                        name: 'User',
                        params: { id: props.rowData.causer.id }
                      }"
                      class="link inline-flex items-center"
                      v-tooltip="props.rowData.causer.id"
                    >
                      <span class="truncate" style="max-width: 15rem">
                        {{ props.rowData.causer.email }}
                      </span>
                    </router-link>
                    <div>
                      <span class="font-semibold text-gray-800 text-xs pr-2"
                        >IP:</span
                      >
                      <a
                        :href="
                          `https://www.ip2location.com/demo/${props.rowData.properties.ip}`
                        "
                        target="_blank"
                        class="link inline-flex items-center"
                      >
                        <Flag
                          v-if="props.rowData.properties.countryCode"
                          class="mr-2 rounded-sm overflow-hidden"
                          :country="props.rowData.properties.countryCode"
                        />
                        <span class="truncate" style="max-width: 9rem">
                          {{ props.rowData.properties.ip }}
                        </span>
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  {{ props.rowData.createdAt | luxonRelative }}
                </td>
              </template>
            </YTable>
          </div>
        </div>
      </div>

      <!-- bottom row -->
      <div class="grid col-span-12 rounded-md p-2 shadow-md bg-gray-100 w-full">
        <div class="flex lg:h-64 w-full">
          <div class="hidden lg:block lg:relative w-8">
            <div
              class="text-lg font-bold uppercase relative transform -rotate-90 py-2 px-6 rounded-md"
              style="
                transform-origin: left;
                left: 12px;
                top: 214px;
                width: max-content;
                color: #094067;
              "
            >
              Daily Leaderboard
            </div>
          </div>
          <div
            class="flex-grow w-full flex flex-col lg:grid lg:grid-cols-12 gap-3 py-2"
          >
            <!-- top publishers -->
            <div class="col-span-4 border rounded-md bg-white mr-1">
              <Spinner
                v-if="$apollo.queries.topPublisherDaily.loading"
                :size="6"
                class="mt-2 ml-0 mb-0 absolute"
              />
              <YTable :data="topPublisherDaily || []">
                <template slot="secondHeader">
                  <td class="leaderboard-container">Publishers</td>
                  <td>
                    <span class="second-header-text">Real</span>
                  </td>
                  <td><span class="second-header-text">Net</span></td>
                </template>
                <template slot="row" slot-scope="props">
                  <td>
                    <div class="truncate w-32 2xl:w-full">
                      <router-link
                        :to="{
                          name: 'User',
                          params: { id: props.rowData.user.id }
                        }"
                        class="hover:text-blue-800"
                      >
                        {{ props.rowData.user.label.split('-')[1] }}
                      </router-link>
                    </div>
                  </td>
                  <td>
                    <span class="text-xs font-bold text-gray-700">
                      ${{ props.rowData.realRevenue }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="text-xs font-bold"
                      :class="
                        props.rowData.revenue < 0
                          ? 'text-red-500'
                          : 'text-gray-900'
                      "
                    >
                      ${{ props.rowData.netRevenue }}
                    </span>
                  </td>
                </template>
              </YTable>
            </div>

            <!-- top advertisers -->
            <div class="col-span-4 border rounded-md bg-white mr-1">
              <Spinner
                v-if="$apollo.queries.topAdvertiserDaily.loading"
                :size="6"
                class="mt-2 ml-0 mb-0 absolute"
              />
              <YTable
                v-if="topAdvertiserDaily"
                :data="topAdvertiserDaily || []"
              >
                <template slot="secondHeader">
                  <td class="leaderboard-container">Advertisers</td>
                  <td>
                    <span class="second-header-text">Real</span>
                  </td>
                  <td><span class="second-header-text">Net</span></td>
                </template>
                <template slot="row" slot-scope="props">
                  <td>
                    <div class="truncate w-32 2xl:w-full">
                      <router-link
                        :to="{
                          name: 'User',
                          params: { id: props.rowData.user.id }
                        }"
                        class="hover:text-blue-800"
                      >
                        {{ props.rowData.user.label.split('-').pop() }}
                      </router-link>
                    </div>
                  </td>
                  <td>
                    <span class="text-xs font-bold text-gray-700">
                      ${{ props.rowData.realRevenue }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="text-xs font-bold"
                      :class="
                        props.rowData.revenue < 0
                          ? 'text-red-500'
                          : 'text-gray-900'
                      "
                    >
                      ${{ props.rowData.netRevenue }}
                    </span>
                  </td>
                </template>
              </YTable>
            </div>

            <!-- top direct campaigns -->
            <div class="col-span-4 border rounded-md bg-white mr-3">
              <Spinner
                v-if="$apollo.queries.topDirectCampaignsDaily.loading"
                :size="6"
                class="mt-2 ml-0 mb-0 absolute"
              />
              <YTable
                v-if="topDirectCampaignsDaily"
                :data="topDirectCampaignsDaily || []"
                ><template slot="secondHeader">
                  <td class="leaderboard-container">Direct Campaigns</td>
                  <td>
                    <span class="second-header-text">Real</span>
                  </td>
                  <td><span class="second-header-text">Net</span></td>
                </template>
                <template slot="row" slot-scope="props">
                  <td>
                    <div class="truncate w-32 2xl:w-full">
                      <router-link
                        :to="{
                          name: 'Campaign',
                          params: {
                            id: props.rowData.campaign.split('-')[0].trim()
                          }
                        }"
                        class="hover:text-blue-800"
                      >
                        {{ props.rowData.campaign.split('-')[1] }}
                      </router-link>
                    </div>
                  </td>
                  <td>
                    <span class="text-xs font-bold text-gray-700"
                      >${{ props.rowData.realRevenue }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="text-xs font-bold"
                      :class="
                        props.rowData.revenue < 0
                          ? 'text-red-500'
                          : 'text-gray-900'
                      "
                      >${{ props.rowData.netRevenue }}</span
                    >
                  </td>
                </template>
              </YTable>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SimpleReport from '@/views/Admin/Reports/SimpleReport'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import USER_OPTIONS_QUERY from '@/graphql/User/UserOptionsQuery.gql'
import RECENT_LOGINS_QUERY from '@/graphql/Activity/RecentLoginsQuery.gql'
import StatusCakeTable from '@/views/Admin/Diagnostic/StatusCakeTable'
export default {
  components: {
    SimpleReport,
    StatusCakeTable
  },
  apollo: {
    weeklyTotals: {
      query: gql`
        query getReport(
          $filters: AdminReportFilters!
          $sortOrder: [SortItem!]
          $export: Boolean
          $fields: [String]!
        ) {
          adminReport(
            filters: $filters
            sortOrder: $sortOrder
            export: $export
            fields: $fields
          ) {
            data {
              day
              revenue
              visits
            }
            totals {
              visits
              revenue
            }
          }
        }
      `,
      update: data => data.adminReport,
      variables() {
        return {
          filters: { dateRange: this.computedWeeklyDateFilter },
          sortOrder: [{ field: 'day', sortField: 'day', direction: 'desc' }],
          export: false,
          fields: ['day', 'revenue', 'visits']
        }
      }
    },
    topPublisherDaily: {
      query: gql`
        query topPublisherDaily {
          topPublisherDaily {
            user {
              id
              label
            }
            realRevenue
            netRevenue
          }
        }
      `
    },
    topAdvertiserDaily: {
      query: gql`
        query TopAdvertiserDaily {
          topAdvertiserDaily {
            user {
              id
              label
            }
            realRevenue
            netRevenue
          }
        }
      `
    },
    topDirectCampaignsDaily: {
      query: gql`
        query TopDirectCampaignsDaily {
          topDirectCampaignsDaily {
            campaign
            realRevenue
            netRevenue
          }
        }
      `
    },
    campaignRequests: {
      query: REDIRECT_OPTIONS_QUERY,
      update: data => data.redirectOptions,
      variables() {
        return {
          filters: {
            status: 'requested'
          },
          first: 1
        }
      },
      deep: true
    },
    pendingUsers: {
      query: USER_OPTIONS_QUERY,
      update: data => data.userOptions,
      variables() {
        return {
          filters: {
            status: 'pending'
          },
          first: 1
        }
      },
      deep: true
    },
    recentLogins: {
      query: RECENT_LOGINS_QUERY,
      variables: {
        filters: {
          userRole: 'publisher'
        }
      }
    },
    publishersNewRevenue: {
      query: gql`
        query PublishersNewRevenue {
          publishersNewRevenue {
            user {
              id
              email
            }
            realRevenue
          }
        }
      `
    },
    advertisersNetRevenue: {
      query: gql`
        query advertisersNetRevenue {
          advertisersNetRevenue {
            user {
              id
              label
            }
            netRevenue
          }
        }
      `
    },
    userNetRevenueVariance: {
      query: gql`
        query UserNetRevenueVariance {
          userNetRevenueVariance {
            user {
              id
              email
              label
            }
            netRevenue
          }
        }
      `
    }
  },
  data() {
    return {
      reportFilters: {
        dateTimeRange: {
          start:
            this.$dateTime
              .local()
              .minus({ days: 7 })
              .toFormat('yyyy-LL-dd') + ' 12:00 am',
          end: this.$dateTime.local().toFormat('yyyy-LL-dd') + ' 11:59 pm'
        }
      }
    }
  },
  updated() {},
  computed: {
    computedDailyDateFilter() {
      return [
        this.$dateTime.local().toFormat('yyyy-LL-dd'),
        this.$dateTime.local().toFormat('yyyy-LL-dd')
      ]
    },
    computedWeeklyDateFilter() {
      return [
        this.$dateTime
          .local()
          .minus({ days: 7 })
          .toFormat('yyyy-LL-dd'),
        this.$dateTime.local().toFormat('yyyy-LL-dd')
      ]
    },
    totalRevenue() {
      if (this.weeklyTotals) {
        return this.weeklyTotals.totals.revenue.toLocaleString('en-US')
      } else {
        return 0
      }
    },
    totalImpressions() {
      if (this.weeklyTotals) {
        return this.weeklyTotals.totals.visits.toLocaleString('en-US')
      } else {
        return 0
      }
    }
  },
  methods: {}
}
</script>

<style scoped>
.data-block {
  @apply flex flex-col px-1 py-3 justify-center content-center text-center text-white capitalize shadow-md rounded-md flex-grow;
}
.data-block h2 {
  @apply text-xs font-bold pb-1;
}
.data-block h3 {
  @apply font-extrabold text-lg;
}
.border {
  @apply shadow-sm;
}
.header-container {
  @apply rounded-t-md px-6 py-3 font-semibold;
  border: 1px solid #094067;
  background: #094067;
  color: #fefefe;
  font-size: 1rem;
}
.header-border {
  @apply border-t border-r border-l;
}
.leaderboard {
  @apply font-semibold px-6;
  color: #094067;
  font-size: 1rem;
}
.leaderboard-container {
  /*@apply px-6 pt-3 font-semibold;*/
  @apply font-semibold pl-6 pb-2 pt-3;
  color: #094067;
  font-size: 1rem;
}
.gradient {
  background: linear-gradient(
    60deg,
    hsla(205, 95%, 92%, 1) 0%,
    hsla(205, 98%, 96%, 1) 100%
  );
  color: #094067;
}
.second-header-text {
  @apply text-xs font-semibold pl-5 block pt-4;
  color: #094067;
}
</style>
