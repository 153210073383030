var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isHeader ? _c('th', {
    staticClass: "vuetable-th-component-handle",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }) : _c('td', {
    staticClass: "vuetable-td-component-handle",
    domProps: {
      "innerHTML": _vm._s(_vm.renderIconTag(['handle-icon', _vm.css.handleIcon]))
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }