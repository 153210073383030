<template>
  <div v-observe-visibility="visibilityChanged" class="mt-4">
    <div v-if="layout == 'stacked'">
      <YInput class="mb-1" label="KW" v-model="structure.kw" />
      <YInput class="mb-1" label="S1" v-model="structure.s1" />
      <YInput class="mb-1" label="S2" v-model="structure.s2" />
      <YInput class="mb-1" label="S3" v-model="structure.s3" />
      <YInput class="mb-1" label="S4" v-model="structure.s4" />
      <YInput class="mb-1" label="S5" v-model="structure.s5" />
    </div>
    <div class="inline-flex" v-else-if="layout == 'inline'">
      <YInput
        label="KW"
        v-model="structure.kw"
        inputClass="border-r-0 rounded-r-none"
      />
      <YInput
        label="S1"
        v-model="structure.s1"
        inputClass="border-r-0 border-l-0 rounded-none"
      />
      <YInput
        label="S2"
        v-model="structure.s2"
        inputClass="border-r-0 border-l-0 rounded-none"
      />
      <YInput
        label="S3"
        v-model="structure.s3"
        inputClass="border-r-0 border-l-0 rounded-none"
      />
      <YInput
        label="S4"
        v-model="structure.s4"
        inputClass="border-r-0 border-l-0 rounded-none"
      />
      <YInput
        label="S5"
        v-model="structure.s5"
        inputClass="border-l-0 rounded-l-none"
      />
    </div>
    <ValidationErrors
      v-if="validationErrors && isVisible"
      :errors="validationErrors"
    ></ValidationErrors>
    <portal to="top-bar-right" v-if="hasChanges && isVisible && !disableSaving">
      <YButton @click="update()" color="green">Update Redirect Params</YButton>
    </portal>
  </div>
</template>

<script>
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  apollo: {},
  props: {
    user: {
      type: Object,
      required: true
    },
    layout: {
      type: String,
      default: 'stacked'
    },
    disableSaving: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localUser: JSON.parse(JSON.stringify(this.user)),
      structure: this.user.userDetails.defaultRedirectParameters
        ? JSON.parse(this.user.userDetails.defaultRedirectParameters)
        : {
            kw: null,
            s1: null,
            s2: null,
            s3: null,
            s4: null,
            s5: null
          },
      isVisible: true,
      isUpdating: false,
      countries: []
    }
  },
  watch: {
    structure: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.$emit('value', newValue)
      }
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
    },
    update() {
      this.clearValidationErrors()
      this.isUpdating = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          // Query
          mutation: UPDATE_USER_MUTATION,
          // Parameters
          variables: {
            input: {
              id: this.user.id,
              userDetails: {
                update: {
                  id: this.user.userDetails.id,
                  defaultRedirectParameters: JSON.stringify({
                    kw: this.structure.kw,
                    s1: this.structure.s1,
                    s2: this.structure.s2,
                    s3: this.structure.s3,
                    s4: this.structure.s4,
                    s5: this.structure.s5
                  })
                }
              }
            }
          }
        })
        .then(data => {
          // Result
          console.log(data)
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Success')
        })
        .catch(error => {
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.setValidationErrors(error)
          this.$toastr.s('Unable to Save new parameters')
        })
    }
  },
  computed: {
    hasChanges() {
      return (
        JSON.stringify(this.structure) !=
        this.user.userDetails.defaultRedirectParameters
      )
    }
  }
}
</script>

<style></style>
