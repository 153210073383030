var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `w-${_vm.width} h-${_vm.height} relative overflow-hidden text-gray-300`,
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm.src ? _c('img', {
    class: `w-${_vm.width} h-${_vm.height} block`,
    attrs: {
      "src": _vm.src
    }
  }) : _c('svg', {
    class: `feather feather-image w-${_vm.blankWidth || _vm.width} h-${_vm.blankHeight || _vm.height} block m-auto`,
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "40",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "1",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('rect', {
    attrs: {
      "x": "3",
      "y": "3",
      "width": "18",
      "height": "18",
      "rx": "2",
      "ry": "2"
    }
  }), _c('circle', {
    attrs: {
      "cx": "8.5",
      "cy": "8.5",
      "r": "1.5"
    }
  }), _c('polyline', {
    attrs: {
      "points": "21 15 16 10 5 21"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }