<template>
  <ImageInput
    :width="width"
    :height="height"
    :blank-width="blankWidth"
    :blank-height="blankHeight"
    :editable-only-when-empty="editableOnlyWhenEmpty"
    :editable="editable"
    :image-url="imageUrl"
    :thumbnail-url="thumbnailUrl"
    :file.sync="file"
    :show-loader="isUploading"
    class="rounded-lg"
  />
</template>

<script>
import UPLOAD_MEDIA_MUTATION from '@/graphql/Media/UploadMediaMutation.gql'
import ImageInput from '@/components/utilities/ImageInput.vue'

export default {
  components: {
    ImageInput
  },
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    blankWidth: {
      type: Number
    },
    blankHeight: {
      type: Number
    },
    editableOnlyWhenEmpty: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    modelClass: {
      type: String,
      required: true
    },
    modelId: {
      type: [Number, String],
      required: true
    },
    imageUrl: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    thumbnailUrl: {
      type: String
    },
    deleteExisting: {
      type: Boolean,
      default: undefined
    }
  },
  data() {
    return {
      isUploading: false,
      file: null
    }
  },
  watch: {
    file: function(file) {
      this.isUploading = true
      if (file) {
        this.$apollo
          .mutate({
            mutation: UPLOAD_MEDIA_MUTATION,
            variables: {
              input: {
                modelClass: this.modelClass,
                modelId: this.modelId,
                file: file,
                deleteExisting: this.deleteExisting
              }
            }
          })
          .then(({ data }) => {
            this.$emit('modelUpdated', data.uploadMedia)
            this.isUploading = false
            this.$toastr.s('Image Uploaded')
          })
          .catch(error => {
            this.isUploading = false
            console.error(error)
          })
      }
    }
  },
  computed: {
    isEditable() {
      return (
        this.editable &&
        (!this.imageUrl || (!this.editableOnlyWhenEmpty && this.imageUrl))
      )
    }
  }
}
</script>

<style scoped>
.x-upload-image {
  @apply relative;
}
.x-overlay {
  @apply absolute h-full w-full hidden items-center text-center;
  background: rgba(0, 0, 0, 0.75);
}

.x-overlay > .x-overlay-icon {
  width: 35%;
  margin: auto;
}

.x-hoverable {
  @apply cursor-pointer;
}

.x-hoverable .x-upload-image:hover .x-overlay,
.x-hoverable .x-upload-image:focus .x-overlay,
.x-hoverable .x-upload-image:active .x-overlay {
  @apply flex;
}
</style>
