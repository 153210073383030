var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', {
    staticClass: "mb-2"
  }, [_vm.filters && _vm.filters.id ? _c('WidgetHeader', {
    staticClass: "bg-gray-200"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 sm:grid-cols-8 gap-1 w-full"
  }, [_c('Superselect', {
    attrs: {
      "title": "Provider",
      "modifiers": _vm.filters.id.modifiers,
      "options": _vm.providerOptions,
      "track-by": "id",
      "label": "label",
      "close-on-select": true
    },
    model: {
      value: _vm.filters.id.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.id, "value", $$v);
      },
      expression: "filters.id.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Type",
      "modifiers": _vm.filters.type.modifiers,
      "options": _vm.typeOptions,
      "track-by": "id",
      "label": "label",
      "close-on-select": true
    },
    model: {
      value: _vm.filters.type.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.type, "value", $$v);
      },
      expression: "filters.type.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "IP Type",
      "modifiers": _vm.filters.serverIpType.modifiers,
      "options": _vm.ipTypeOptions,
      "track-by": "id",
      "label": "label",
      "close-on-select": true
    },
    model: {
      value: _vm.filters.serverIpType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.serverIpType, "value", $$v);
      },
      expression: "filters.serverIpType.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Server IPs",
      "force-edit": true,
      "edit-placeholder": ``,
      "modifiers": _vm.filters.serverIp.modifiers,
      "track-by": "id",
      "label": "label"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "mt-1 flex"
        }, [_c('YToggle', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Filter Servers`,
            expression: "`Filter Servers`"
          }],
          staticClass: "mr-2",
          scopedSlots: _vm._u([{
            key: "true",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-blue-600",
                attrs: {
                  "name": "desktopMac",
                  "size": 4
                }
              })];
            },
            proxy: true
          }, {
            key: "false",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-gray-600",
                attrs: {
                  "name": "desktopMac",
                  "size": 4
                }
              })];
            },
            proxy: true
          }], null, false, 167015281),
          model: {
            value: _vm.filters.serverIp.settings.filtersServers,
            callback: function ($$v) {
              _vm.$set(_vm.filters.serverIp.settings, "filtersServers", $$v);
            },
            expression: "filters.serverIp.settings.filtersServers"
          }
        }), _c('YToggle', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Filter IPs`,
            expression: "`Filter IPs`"
          }],
          scopedSlots: _vm._u([{
            key: "true",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-blue-600",
                attrs: {
                  "name": "videoInputAntenna",
                  "size": 4
                }
              })];
            },
            proxy: true
          }, {
            key: "false",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-gray-600",
                attrs: {
                  "name": "videoInputAntenna",
                  "size": 4
                }
              })];
            },
            proxy: true
          }], null, false, 2400907825),
          model: {
            value: _vm.filters.serverIp.settings.filtersIps,
            callback: function ($$v) {
              _vm.$set(_vm.filters.serverIp.settings, "filtersIps", $$v);
            },
            expression: "filters.serverIp.settings.filtersIps"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1323553341),
    model: {
      value: _vm.filters.serverIp.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.serverIp, "value", $$v);
      },
      expression: "filters.serverIp.value"
    }
  })], 1)])], 2) : _vm._e(), _c('WidgetHeader', {
    staticClass: "bg-opacity-faded",
    staticStyle: {
      "border-top": "1px solid #D9D9D9"
    }
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "mb-4 mb-sm-0"
  }), _c('div', [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-100 mr-3",
    on: {
      "click": function ($event) {
        return _vm.resetFilters();
      }
    }
  }, [_vm._v("Reset Filters")])], 1)])], 2)], 1), _c('div', {
    staticClass: "flex justify-between mb-2 items-center"
  }, [_vm._m(0), _c('div', [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-blue-100",
    on: {
      "click": _vm.popCreateProviderModal
    }
  }, [_vm._v(" Add Provider ")])], 1)]), _vm.$apollo.queries.providers.loading ? _c('div', {
    staticClass: "text-center p-6"
  }, [_c('Spinner', {
    attrs: {
      "color": "green",
      "size": 12
    }
  })], 1) : _vm.providers.length == 0 ? _c('div', {
    staticClass: "p-6 text-center text-muted"
  }, [_c('span', {
    staticStyle: {
      "text-shadow": "0px 1px 0px rgba(255,255,255,0.5)"
    }
  }, [_vm._v("No providers found")])]) : _vm.providers.length > 0 ? _vm._l(_vm.providers, function (provider, index) {
    return _c('provider', {
      key: provider.name,
      staticClass: "mb-1",
      model: {
        value: _vm.providers[index],
        callback: function ($$v) {
          _vm.$set(_vm.providers, index, $$v);
        },
        expression: "providers[index]"
      }
    }, [_c('servers', {
      attrs: {
        "filters": _vm.getServerFiltersByProvider(provider.id),
        "show-servers-by-default": _vm.providers.length == 1,
        "provider": provider
      }
    })], 1);
  }) : _vm._e()], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', {
    staticClass: "mb-0 font-bold text-lg"
  }, [_vm._v("Providers")])]);

}]

export { render, staticRenderFns }