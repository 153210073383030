var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Tabs', {
    attrs: {
      "show-type": "show"
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Alf Listings",
      "active": true
    }
  }, [_c('AlfListings')], 1), _c('Tab', {
    attrs: {
      "name": "Alf Exceptions"
    }
  }, [_c('AlfExceptions')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }