<template>
  <Widget class="bg-red-600 text-white border-red-700">
    <WidgetHeader class="bg-red-600 text-white"
      ><span class="text-white">Are you sure?</span></WidgetHeader
    >
    <WidgetBody>
      <h5 class="text-yellow-400 font-bold">This action cannot be undone.</h5>
      <h5>
        Please confirm you'd like to
        <span class="text-yellow-400 font-bold">DELETE</span> the Notification
        with the following details:
      </h5>
      <ul class="text-xs mt-4 mb-3">
        <li>Name: {{ notification.name }}</li>
        <li>Routes: {{ notification.routes }}</li>
        <li>Start Date: {{ notification.startDate }}</li>
        <li>Expiry Date: {{ notification.expiryDate }}</li>
      </ul>
    </WidgetBody>
    <WidgetFooter class="bg-dark-transparent-1 flex justify-between py-2 px-4">
      <YButton class="hover:bg-red-700 focus:bg-red-700" @click="$emit('close')"
        >Cancel</YButton
      >
      <YButton
        @click="update"
        class="hover:bg-red-700 focus:bg-red-700"
        :is-loading="isSubmitting"
        >Delete Notification</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import DELETE_NOTIFICATION_MUTATION from '@/graphql/Notifications/DeleteNotificationMutation.gql'
import UPDATE_NOTIFICATION_MUTATION from '@/graphql/Notifications/UpdateNotificationMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    update() {
      this.$apollo
        .mutate({
          mutation: UPDATE_NOTIFICATION_MUTATION,
          variables: {
            input: {
              id: this.notification.id,
              users: {
                sync: []
              }
            }
          }
        })
        .then(() => {
          this.deleteNotification()
        })
        .catch(error => {
          this.setValidationErrors(error)
        })
    },
    deleteNotification() {
      this.$apollo
        .mutate({
          mutation: DELETE_NOTIFICATION_MUTATION,
          variables: {
            id: this.notification.id
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$events.emit('refreshNotifications', true)
          this.$toastr.s('Notification Deleted', 'Success!')
          this.$emit('close')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
