var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', [_c('WidgetBody', {
    staticClass: "bg-gray-100"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('div'), _c('y-button', {
    staticClass: "btn btn-blue",
    on: {
      "click": function ($event) {
        return _vm.openCreatePostback(_vm.user_id, _vm.redirect_id);
      }
    }
  }, [_vm._v("New Postback ")])], 1)]), _vm.postbacks ? _c('YTable', {
    attrs: {
      "data": _vm.postbacks.data || [],
      "bottom-loading": _vm.$apolloData.queries.postbacks.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _props$rowData$conver;
        return [_c('td', [_vm._v(_vm._s(props.rowData.id))]), _c('td', [props.rowData.conversionType ? _c('Tag', {
          attrs: {
            "color": "blue"
          }
        }, [_vm._v(_vm._s((_props$rowData$conver = props.rowData.conversionType) === null || _props$rowData$conver === void 0 ? void 0 : _props$rowData$conver.name))]) : _c('Tag', {
          attrs: {
            "color": "orange"
          }
        }, [_vm._v("Default")])], 1), !_vm.redirect_id ? _c('td', [_vm._v(_vm._s(props.rowData.redirectId))]) : _vm._e(), _c('td', [_c('editable-postback-protocol', {
          attrs: {
            "id": props.rowData.id,
            "protocol": props.rowData.protocol
          }
        }, [_vm._v(_vm._s(props.rowData.protocol))])], 1), _c('td', [_c('editable-postback-url', {
          attrs: {
            "id": props.rowData.id,
            "url": props.rowData.url
          }
        }, [_vm._v(_vm._s(props.rowData.url))])], 1), _c('td', {
          staticClass: "text-right"
        }, [_c('a', {
          staticClass: "text-red-500 text-red-600",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDeleteModal(props.rowData.id, props.rowData.protocol + '://' + props.rowData.url);
            }
          }
        }, [_c('Icon', {
          staticClass: "inline-block",
          attrs: {
            "name": "trashCan"
          }
        })], 1)])];
      }
    }], null, false, 1866559990)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("ID")]), _c('th', [_vm._v("Conversion Type")]), !_vm.redirect_id ? _c('th', [_vm._v("Redirect")]) : _vm._e(), _c('th', [_vm._v("Protocol")]), _c('th', [_vm._v("Url")]), _c('th')])], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }