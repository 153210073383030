<template>
  <widget>
    <widget-header class="tiny-forms bg-primary blue pt-4 pb-4 text-white">
      <template slot="rawContent">
        <div
          class="d-flex justify-content-between align-items-center w-100 ts-4"
        >
          <div class="">
            <i class="zmdi -file-plzmdius pr-2"></i>
            yReplace
          </div>
          <close-x @click="$emit('close')" class="text-white"></close-x>
        </div>
      </template>
    </widget-header>
    <widget-body class="pt-5">
      <div>
        <y-form-group label="Type">
          <multiselect
            title="Type"
            v-model="replacementType"
            :options="['mini', 'ip']"
            :multiple="false"
          ></multiselect>
        </y-form-group>
      </div>
      <div>
        <y-form-group label="Value">
          <input
            class="form-control small-input-size"
            v-model="replacementValue"
            placeholder="Replacement Value {IP/Mini Domain}"
          />
        </y-form-group>
      </div>
      <div v-show="replacementType == 'mini'">
        <y-form-group label="Registrar (Empty Allowed)">
          <multiselect
            title="Type"
            v-model="specifiedRegistrar"
            :options="registrars"
            :multiple="false"
            @close="updateDomains()"
          ></multiselect>
        </y-form-group>
        <y-form-group label="Domains (Leave Empty to Auto Select)">
          <multiselect
            title="Domain"
            v-model="replacementDomain"
            :options="options.ydnsDomains"
            :multiple="false"
            track-by="label"
            label="label"
          ></multiselect>
        </y-form-group>
      </div>
    </widget-body>

    <widget-footer class="pt-4 pb-4">
      <y-checkbox
        v-model="closeOnComplete"
        label="Close on Complete"
      ></y-checkbox>
      <y-button
        :is-loading="isSubmitting"
        type="button"
        class="btn btn-primary btn-sm ml-auto"
        @click="submit"
        @keydown.enter="submit"
        >Submit</y-button
      >
    </widget-footer>
  </widget>
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    replacementType: {
      type: String,
      required: false
    },
    replacementValue: {
      type: String,
      required: false
    }
  },
  components: {},
  apollo: {
    ydnsDomains: {
      query: gql`
        query ydnsDomains(
          $filters: YdnsDomainFilterInput
          $recordFilters: YdnsRecordFilterInput
        ) {
          ydnsDomains(filters: $filters) {
            id
            domain
            registrar
            type
            records(filters: $recordFilters) {
              id
              type
              subdomain
              ip {
                id
                ip
                server {
                  id
                }
              }
              content
            }
          }
        }
      `,
      variables() {
        console.log(this.specifiedRegistrar)
        if (this.specifiedRegistrar === '') {
          return {
            filters: {
              hasRecords: false
            }
          }
        } else {
          return {
            filters: {
              registrar: {
                value: this.specifiedRegistrar
              },
              hasRecords: false
            }
          }
        }
      },
      result({ data }) {
        this._.assignIn(
          this.ydnsDomains,
          this.$cleanApolloData(data.ydnsDomains)
        )
      }
    },

    registrars: {
      query: gql`
        {
          registrars: ydnsRegistrars
        }
      `
    }
  },
  data() {
    let registrars = {
      1: 'test'
    }
    return {
      closeOnComplete: true,
      isSubmitting: false,
      replacementDomain: '',
      specifiedRegistrar: '',
      registrars: registrars
    }
  },
  watch: {},
  computed: {
    options() {
      return {
        ydnsDomains: this.ydnsDomains
          ? this.ydnsDomains.map(ydnsDomains => ({
              id: ydnsDomains.id,
              label: ydnsDomains.domain
            }))
          : []
      }
    }
  },
  mounted() {},
  methods: {
    updateDomains() {
      this.$apollo.queries.ydnsDomains.refetch()
    },

    submit() {
      this.isSubmitting = true
      var specifiedDomain =
        this.replacementDomain.label == '' ? null : this.replacementDomain.label

      this.$apollo
        .mutate({
          mutation: gql`
            mutation yreplaceQue($input: YreplaceInput!) {
              yreplaceQueue(input: $input) {
                return
              }
            }
          `,
          variables: {
            input: {
              replacementValue: this.replacementValue,
              replacementType: this.replacementType,
              replacementDomain: specifiedDomain,
              specifiedRegistrar: this.specifiedRegistrar,
              specifiedProvider: null
            }
          }
        })
        .then(result => {
          this.$toastr.success('Replacement Queued', 'Success!')
          this.errors = {}
          this.isSubmitting = false
          this.$emit('success', result)
          this.$events.emit('refreshDomainmanagerDomains', true)
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.$toastr.error(error, 'Oops!')
        })
    }
  },

  close() {
    this.$emit('close')
  }
}
</script>
<style>
.publisher-postbacks-table-actions-header {
  width: 3rem;
}
.text-opacity-light {
  opacity: 0.7;
}
</style>
