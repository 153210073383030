var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-5 flex items-start flex-grow bg-gray-100 h-full ml-4 mr-4",
    staticStyle: {
      "transform-origin": "top"
    },
    style: {
      transform: `scale(${_vm.scaleWindow})`
    }
  }, [_c('div', {
    staticClass: "text-gray-500 text-center w-full",
    style: {
      width: '100vw',
      height: '100vh',
      position: 'relative'
    }
  }, [_c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "src": _vm.previewLink
    }
  }), _c('div', {
    staticClass: "flex m-2 rounded-lg overflow-hidden"
  }, [!_vm.autoRefresh ? _c('button', {
    staticClass: "flex items-center rounded-l-lg px-3 bg-inverse-900 hover-bg-inverse-700 text-white cursor-pointer shadow-md",
    on: {
      "click": _vm.refresh
    }
  }, [_c('Icon', {
    attrs: {
      "name": "refresh",
      "size": "4"
    }
  })], 1) : _vm._e(), _c('CopyTextButton', {
    attrs: {
      "text": _vm.previewLink,
      "divClass": "rounded-r-lg shadow-md"
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }