<template>
  <div class="form-group" :class="{ 'has-danger': hasErrors }">
    <slot
      name="label"
      class="d-flex align-items-center justify-content-between"
    >
      <template v-if="toggleable">
        <y-checkbox
          v-model="activeToggle"
          :label="label ? label : undefined"
          type="info"
          class="col-form-label fw-500"
          :class="labelClasses"
        ></y-checkbox>
      </template>
      <template v-else>
        <label
          v-if="label"
          class="col-form-label"
          :class="labelClasses"
          :style="inlineStyle"
        >
          <template v-if="!hideLabel">
            {{ label }}
            <sup v-if="markRequired" class="text-danger fw-600">*</sup>
            <span
              v-if="labelNote"
              class="ml-1 fw-400"
              style="opacity: 0.5; font-style: italic;"
              >{{ labelNote }}</span
            >
          </template>
          <template v-if="hideLabel">
            &nbsp;
          </template>
        </label>
      </template>
    </slot>
    <slot v-if="active" v-bind:disabled="disabled"></slot>
    <div v-if="info" class="form-control-feedback">
      <small class="text-muted" v-text="info"></small>
    </div>
    <template v-if="error">
      <div
        v-if="!error.minLength && error.$params.minLength"
        class="form-control-feedback"
      >
        <small
          >Must have at least {{ error.$params.minLength.min }} letters.</small
        >
      </div>
      <div
        v-else-if="!error.email && error.$params.email"
        class="form-control-feedback"
      >
        <small>Invalid email</small>
      </div>
      <div class="error" v-if="!error.minLength"></div>
    </template>
    <template v-if="errorStrings">
      <div
        v-for="(error, index) of errorStrings"
        :key="index"
        class="form-control-feedback text-danger"
      >
        <small class="fw-500">{{ error }}</small>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    label: {
      type: String
    },
    error: {},
    inline: {},
    info: {},
    hideLabel: {},
    labelNote: {
      type: String,
      default: null
    },
    markRequired: {},
    disabled: {},
    errorStrings: {},
    toggleable: {},
    isToggled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      active: this.isToggled
    }
  },
  computed: {
    hasErrors() {
      return (this.error && this.error.$error) || this.errorStrings
    },
    inlineStyle() {
      return this.inline == true ? 'display: inline;' : 'display: block;'
    },
    labelClasses() {
      let classes = []
      if (this.disabled) {
        classes.push('disabled-opacity')
      }
      return classes
    },
    activeToggle: {
      get: function() {
        return this.active
      },
      set: function(newValue) {
        if (newValue == false) {
          this.$emit('toggleOff', newValue)
        } else {
          this.$emit('toggleOn', newValue)
        }
        this.active = newValue
      }
    }
  },
  created() {},
  mounted() {},
  updated() {},
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style scoped>
.disabled-opacity {
  opacity: 0.5;
}
</style>
