<template>
  <Widget class="x-publisher-account-manager">
    <WidgetBody>
      <!--<div v-for="manager in me.accountManager" :key="manager.id">-->
      <div v-if="me && me.accountManager" class="widget-edge">
        <div class="media">
          <div class="media-body">
            <div class="flex items-center">
              <img
                :src="
                  accountManagerImage(me.accountManager.primaryContact.name)
                "
                class="rounded-circle flex m-3 p-2"
                style=" border-radius: 1000px;"
                width="90"
              />
              <div>
                <span
                  class="block font-semibold uppercase text-gray-600 text-xs leading-tight"
                  >Account Manager</span
                >
                <span
                  class="text-xl block font-bold text-gray-800  leading-tight mb-1"
                  >{{ me.accountManager.primaryContact.name }}</span
                >
                <div class="flex items-center">
                  <Icon name="email" class="w-4 h-4 mr-2 text-gray-400" />
                  <a
                    :href="
                      me.accountManager.primaryContact.email
                        ? accountManagerEmail(
                            me.accountManager.primaryContact.email
                          )
                        : accountManagerEmail(me.accountManager.email)
                    "
                    class="link"
                  >
                    {{
                      me.accountManager.primaryContact.email
                        ? me.accountManager.primaryContact.email
                        : accountManagerEmail(me.accountManager.email)
                    }}
                  </a>
                </div>
                <div class="flex items-center">
                  <Icon name="skype" class="w-4 h-4 mr-2 text-blue-500" />
                  <a
                    :href="
                      `skype:${
                        JSON.parse(me.accountManager.primaryContact.messenger)
                          .handle
                      }?add`
                    "
                    class="link"
                    >{{
                      JSON.parse(me.accountManager.primaryContact.messenger)
                        .handle
                    }}</a
                  >
                </div>
              </div>
            </div>
            <div class="Widget-x-edge text-center py-3 border-t bg-gray-100">
              <a
                class="font-semibold link"
                :href="
                  accountManagerTelegramLink(
                    me.accountManager.primaryContact.name
                  )
                "
                target="_blank"
                >Contact an AM on Telegram</a
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else class="widget-edge">
        <div class="media">
          <div class="media-body">
            <a href="mailto:affiliates@ytz.com">
              <div
                class="Widget-x-edge text-center py-3 border-t bg-gray-100 font-semibold link"
              >
                Contact an Account Manager
              </div>
            </a>
          </div>
        </div>
      </div>
    </WidgetBody>
  </Widget>
</template>

<script>
import ME_QUERY from '@/graphql/User/MeQuery.gql'
export default {
  apollo: {
    me: {
      query: ME_QUERY
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    accountManagerEmail(email) {
      return 'mailto:' + email
    },
    accountManagerTelegramLink(name) {
      let stringName = 'https://t.me/MarissaYTZ'

      if (name == 'Kate Porter') {
        stringName = 'https://t.me/KateYTZ'
      }

      if (name == 'Megan Sagriff') {
        stringName = 'https://t.me/MarissaYTZ'
      }

      if (name == 'Marissa Chateau') {
        stringName = 'https://t.me/MarissaYTZ'
      }

      if (name == 'Devin Holmberg') {
        stringName = 'https://t.me/KateYTZ'
      }
      return stringName
    },
    accountManagerImage(name) {
      const nameToImageMap = {
        'Kate Porter': 'kate',
        'Megan Sagriff': 'megan',
        'Andrew Mackinnon': 'andrew',
        'Marissa Chateau': 'marissa',
        'Max Dennis': 'max',
        'Devin Holmberg': 'devin',
        'Nicole Henson': 'nicole'
      }

      const stringName = nameToImageMap[name]
      const output = stringName
        ? require(`@/assets/images/users/${stringName}-600x600.png`)
        : null

      return output
    }
  }
}
</script>

<style scoped>
.sidebar {
  width: 14rem;
}

.x-publisher-account-manager::-webkit-scrollbar {
  display: none;
}
</style>
