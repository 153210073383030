<template>
  <div>
    <div class="row">
      <div class="col">
        <Widget class="mb-1">
          <WidgetHeader class="bg-gray-100">Upload </WidgetHeader>
          <WidgetBody class="pt-0 bg-gray-100">
            <BulkPayoutUpload
              :loading-preview="loadingPreview"
            ></BulkPayoutUpload>
          </WidgetBody>
        </Widget>
        <UploadPreview :loadingPreview="loadingPreview"></UploadPreview>
      </div>
    </div>
  </div>
</template>

<script>
// Adjustment Components
import UploadPreview from './UploadPreview'
import BulkPayoutUpload from './BulkPayoutUpload'

export default {
  components: {
    UploadPreview,
    BulkPayoutUpload
  },
  data() {
    return {
      loadingPreview: false
    }
  },
  computed: {},
  methods: {
    enableLoadingPreview() {
      this.loadingPreview = true
    },
    disableLoadingPreview() {
      setTimeout(() => {
        this.loadingPreview = false
      }, 400)
    }
  },
  mounted() {
    this.$events.listen('submitUploadForm', eventData =>
      this.enableLoadingPreview(eventData)
    )
    this.$events.listen('disableUploadLoadingPreview', eventData =>
      this.disableLoadingPreview(eventData)
    )
  },
  beforeDestroy() {
    this.$events.remove('submitUploadForm')
    this.$events.remove('disableUploadLoadingPreview')
  },
  updated() {}
}
</script>
<style scoped>
.form-group .multiselect {
  margin-bottom: 0.5rem;
}
.form-group .multiselect:last-child {
  margin-bottom: 0rem;
}

.widget-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.tiny-forms .input-group-btn .btn {
  line-height: 1.3;
}
</style>
