<template>
  <div>
    <Widget>
      <WidgetHeader class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <RuleFilters
            @rulesFetched="updateRules"
            @loading="updateLoading"
            :order-by="orderBy"
            :per-page="perPage"
            :current-page="currentPage"
            :default-values="defaultFilterValues"
            :hide="hideFilters"
            :filterable-polarity="false"
            :hide-all-offer-filters="true"
          ></RuleFilters>
        </template>
      </WidgetHeader>
    </Widget>
    <div
      class="flex justify-between items-center mt-4 px-4 py-4 rounded-lg bg-gray-200 "
    >
      <div class="text-muted">
        <span>
          {{ selectedCount }}
          {{ selectedCount == 1 ? 'item' : 'items' }} selected
        </span>
      </div>
      <div class="flex gap-4">
        <YButton
          color="red"
          :disabled="!rowsAreSelected"
          @click="openDeleteRulesModal"
          >Delete</YButton
        >
        <YButton
          color="blue"
          @click="openCreateModal"
          :disabled="rowsAreSelected"
          >Create Rules</YButton
        >
      </div>
    </div>
    <OfferPerspectiveRulesTable
      v-if="rules && rules.data"
      :rules="rules.data || []"
      :order-by="orderBy"
      :bottom-loading="loading"
      class="pt-0"
      :hide="['filterable']"
    />
    <YPagination
      v-if="rules && rules.paginatorInfo"
      :data="rules.paginatorInfo"
      @load-page="loadPage"
      @next-page="nextPage"
      @previous-page="previousPage"
      :key="rules.paginatorInfo.lastPage"
      @per-page="perPageSelection"
    >
    </YPagination>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RuleFilters from '@/views/Admin/Rules/RuleFilters'
import OfferPerspectiveRulesTable from '@/views/Admin/Rules/OfferPerspectiveRulesTable'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'
import CreateOfferPerspectiveRules from '@/views/Admin/Rules/CreateOfferPerspectiveRules.vue'
import DeleteRules from '@/views/Admin/Rules/DeleteRules'

export default {
  mixins: [BackToTop],
  components: {
    RuleFilters,
    OfferPerspectiveRulesTable,
    YPagination
  },
  props: {
    defaultFilterValues: {
      type: Object
    },
    hideFilters: {
      type: Array
    },
    defaultsForCreate: {
      type: Object
    }
  },
  apollo: {},
  data() {
    return {
      rules: null,
      loading: false,
      orderBy: [
        {
          column: 'id',
          order: 'DESC'
        }
      ],
      perPage: 20,
      currentPage: 1
    }
  },
  beforeDestroy() {
    this.$store.commit('rule/RESET_SELECTED_ROW_IDS')
  },
  mounted() {},
  updated() {
    this.rules
      ? this.$emit('totalRulesTab', this.rules.paginatorInfo)
      : undefined
  },
  computed: {
    ...mapState('rule', ['selectedRowIds']),
    selectedCount() {
      return this.selectedRowIds.length
    },
    rowsAreSelected() {
      return this.selectedRowIds.length > 0
    }
  },
  methods: {
    openCreateModal() {
      this.$modal.show(
        CreateOfferPerspectiveRules,
        {
          defaults: this.defaultsForCreate ?? {},
          hide: ['filterables']
        },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDeleteRulesModal() {
      this.$modal.show(
        DeleteRules,
        { selectedRowIds: this.selectedRowIds },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 650,
          adaptive: true,
          scrollable: true
        }
      )
    },
    updateLoading(loadingState) {
      this.loading = loadingState
    },
    updateRules(rules) {
      this.rules = rules
    },
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    }
  }
}
</script>

<style scoped></style>
