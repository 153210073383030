<template>
  <div>
    <template v-if="rowData && rowData.subscribers">
      <div class="box-width">
        <div
          class="overflow-y-auto rounded cursor-pointer hover:shadow-md focus:shadow-md outline-none"
          style="max-height: 8rem;"
        >
          <router-link
            v-for="user of rowData.subscribers"
            :to="{
              name: 'User',
              params: { id: user.id }
            }"
            class="link truncate block hover:underline"
            :key="user.id"
          >
            <Tag
              color="blue"
              :shade="100"
              class="block py-2 rounded-none"
              :hover="true"
            >
              {{ user.label }}
            </Tag>
          </router-link>
        </div>
        <div class="flex justify-between px-2">
          <div>
            <small>
              <a
                href="#"
                class="link cursor-pointer mt-1 block font-semibold"
                @click.prevent="openSubscribeUserModal"
                tabindex="0"
                >Add Subscribers</a
              >
            </small>
          </div>
          <div>
            <small
              v-if="rowData.subscribers"
              class="font-semibold text-gray-700 text-blue-600 hover:text-blue-500 cursor-pointer"
              @click.prevent="openSubscribeUserModal"
              >{{ rowData.subscribers.length }} Subscriber<template
                v-if="rowData.subscribers.length > 1"
                >s</template
              ></small
            >
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SubscribeToCampaignWidget from '@/views/Admin/Campaign/SubscribeToCampaignWidget'

export default {
  components: {},
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {
    openSubscribeUserModal() {
      this.$modal.show(
        SubscribeToCampaignWidget,
        {
          campaignId: parseInt(this.rowData.id)
        },
        {
          classes: ['overflow-visible-important'],
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  }
}
</script>
<style scoped>
.box-width {
  width: 14rem;
}
</style>
