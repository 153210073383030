var render = function render(){
  var _vm$regionCodeOptions;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "w-full font-bold text-lg text-gray-900 flex justify-between"
  }, [_vm._v(" Regional Descriptions ")]), _c('div', {
    staticClass: "w-full flex flex-col justify-between"
  }, [_c('div', {
    staticClass: "flex space-between gap-2"
  }, [_c('Superselect', {
    staticClass: "flex-grow",
    attrs: {
      "title": "Country",
      "options": _vm.countryOptions,
      "label": "name",
      "track-by": "code",
      "multiple": false
    },
    model: {
      value: _vm.selectedCountry,
      callback: function ($$v) {
        _vm.selectedCountry = $$v;
      },
      expression: "selectedCountry"
    }
  }), (_vm$regionCodeOptions = _vm.regionCodeOptions) !== null && _vm$regionCodeOptions !== void 0 && _vm$regionCodeOptions.values ? _c('Superselect', {
    staticClass: "flex-grow",
    attrs: {
      "title": "Region",
      "options": _vm.regionCodeOptions.values,
      "multiple": false
    },
    model: {
      value: _vm.selectedRegion,
      callback: function ($$v) {
        _vm.selectedRegion = $$v;
      },
      expression: "selectedRegion"
    }
  }) : _vm._e(), _c('YButton', {
    staticClass: "w-32",
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.createNewDescription();
      }
    }
  }, [_vm._v("Add Region")])], 1), _c('div', {
    staticClass: "mt-4 flex flex-col border-bottom w-full"
  }, [_vm._l(_vm.localizedDescriptions, function (desc, index) {
    return [_c('div', {
      key: index,
      staticClass: "flex w-full items-center gap-3"
    }, [_c('YTextarea', {
      staticClass: "flex-grow",
      attrs: {
        "label": `Regional Description: ${Object.keys(desc)[0]}`
      },
      model: {
        value: _vm.localizedDescriptions[index][Object.keys(desc)[0]],
        callback: function ($$v) {
          _vm.$set(_vm.localizedDescriptions[index], Object.keys(desc)[0], $$v);
        },
        expression: "localizedDescriptions[index][Object.keys(desc)[0]]"
      }
    }), _c('div', {
      staticClass: "cursor-pointer",
      on: {
        "click": function ($event) {
          _vm.removeDescription(Object.keys(desc)[0]);
        }
      }
    }, [_c('Icon', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: 'Delete Description',
        expression: "'Delete Description'"
      }],
      staticClass: "text-red-600",
      attrs: {
        "name": "trashCan",
        "size": 5
      }
    })], 1)], 1)];
  })], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }