var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "py-6 border-b",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "pencil",
      "size": 4
    }
  }), [_vm._v(" Edit " + _vm._s(_vm.domain.id) + " ")]], 2)]), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('Superselect', {
    attrs: {
      "title": "Tags",
      "options": _vm.tagOptions,
      "multiple": true,
      "track-by": "tagConfigId",
      "label": "tag"
    },
    model: {
      value: _vm.tags,
      callback: function ($$v) {
        _vm.tags = $$v;
      },
      expression: "tags"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('y-checkbox', {
    staticClass: "flex items-center",
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue"
    },
    on: {
      "click": _vm.updateDomain
    }
  }, [_vm._v("Update")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }