<template>
  <SetStatusWidget
    :header="header"
    :modelName="modelName"
    :modelIds="modelIds"
    :currentStatus="currentStatus"
    :newStatus="newStatus"
    :options="options"
    :autoSubmit="autoSubmit"
    :enumName="enumName"
    :userWasAutoRejected="userWasAutoRejected"
    modelType="domain"
    @success="setStatusOnSuccess"
    @close="$emit('close')"
  />
</template>

<script>
import DOMAINS_QUERY from '@/graphql/Domain/DomainsQuery.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import SetStatusWidget from '@/views/Admin/Status/SetStatusWidget.vue'
import REMOVE_USER_FROM_DOMAIN from '@/graphql/Redirect/RemoveUserFromDomain.gql'
import SetAssignUserToDomain from '@/views/Admin/Status/SetAssignUserToDomain.vue'

export default {
  mixins: [ValidationErrorsMixin],
  components: { SetStatusWidget },
  apollo: {
    domains: {
      query: DOMAINS_QUERY,
      variables() {
        return {
          first: 100,
          filters: {
            publisher: this.modelIds[0]
          }
        }
      },
      result(data) {
        this.currentDomains = data.data.domains.data
      }
    }
  },
  props: {
    header: {
      type: String,
      default: 'Set Status'
    },
    modelName: {
      type: String,
      required: true
    },
    modelIds: {
      type: Array,
      required: true
    },
    currentStatus: {
      type: Object
    },
    newStatus: {
      type: Object
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    autoSubmit: {
      type: Boolean,
      default: false
    },
    enumName: {
      type: String
    },
    userWasAutoRejected: {
      type: Boolean
    }
  },
  data() {
    return {
      DOMAINS_QUERY,
      isSubmitting: false,
      currentDomains: []
    }
  },
  computed: {
    assignedDomains() {
      return this.currentDomains.map(domain => domain.id)
    },
    syncAbilitiesOnly() {
      let userIds = this.currentDomains[0].userDomainHistory
        .map(user => user.user.id)
        .filter(id => id != this.modelIds[0])

      return userIds ? userIds : null
    }
  },
  mounted() {},
  updated() {},
  methods: {
    close() {
      this.$emit('close')
    },
    setStatusOnSuccess(statusName) {
      if (statusName == 'fraud') {
        this.fraudStatusDomainPermissions()
      }
      if (statusName == 'active') {
        this.$modal.show(
          SetAssignUserToDomain,
          {
            modelId: this.modelIds
          },
          {
            height: 'auto',
            scrollable: true,
            width: '80%',
            maxWidth: 600,
            minWidth: 200,
            adaptive: true,
            classes: ['overflow-visible-important']
          }
        )
      }
    },
    fraudStatusDomainPermissions() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: REMOVE_USER_FROM_DOMAIN,
          variables: {
            input: {
              userId: this.modelIds[0],
              domainIds: this.assignedDomains
            }
          }
        })
        .then(() => {
          this.$emit('close')
          this.isSubmitting = false
        })
        .catch(error => {
          this.setValidationErrors(error)
        })
    }
  }
}
</script>

<style></style>
