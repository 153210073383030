<template>
  <YTable v-if="campaigns" :data="campaigns.data || []">
    <template v-slot:row="props">
      <td>
        <campaign-info-cell
          class="-ml-4 -my-1"
          :row-data="props.rowData"
          :row-index="props.rowIndex"
          :key="`campaign-info-${props.rowData.id}`"
          :minimalView="true"
        ></campaign-info-cell>
      </td>
    </template>
  </YTable>
</template>

<script>
import CAMPAIGNS_QUERY from '@/graphql/Campaign/CampaignsQuery.gql'
import CampaignInfoCell from '@/components/legacy/VuetableCells/CampaignInfoCell.vue'

export default {
  components: {
    CampaignInfoCell
  },
  mounted() {},
  updated() {},
  props: {
    isLoading: {
      default: false,
      type: Boolean
    }
  },
  apollo: {
    campaigns: {
      query: CAMPAIGNS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          first: 10,
          filters: {
            isPinned: true
          }
        }
      }
    }
  }
}
</script>
