<template>
  <div v-if="campaign">
    <portal to="top-bar-left-header">
      <div class="flex items-center">
        <div v-if="campaign.legacyImageUrl" class="mr-4 hidden sm:block">
          <EditableImage
            class="rounded mr-2x"
            :imageUrl="campaign.legacyImageUrl"
            :thumbnailUrl="campaign.legacyImageThumbnailUrl"
            :height="12"
            :width="12"
            :blankHeight="12"
            :blankWidth="12"
            :editable-only-when-empty="true"
            :modelId="campaign.id"
            :deleteExisting="true"
            :edit-focus-icon-enabled="false"
            :editable="false"
            modelClass="campaign"
          ></EditableImage>
        </div>
        <div v-else class="mr-4 hidden sm:block">
          <EditableImage
            class="rounded mr-2x"
            :imageUrl="imageFileComputed?.original || null"
            :thumbnailUrl="imageFileComputed?.imageThumbnailUrl || null"
            :height="12"
            :width="12"
            :blankHeight="12"
            :blankWidth="12"
            :modelId="campaign.id"
            :editable="false"
            modelClass="campaign"
          ></EditableImage>
        </div>
        {{ campaign.name }}
        <small class="text-gray-600 font-sm">
          <sup>#</sup>
          {{ campaign.id }}
        </small>
      </div>
    </portal>

    <div class="flex">
      <div class="flex-grow w-1/2 lg:w-full">
        <div class="flex flex-wrap flex-row-reverse sm:items-center mb-4">
          <div v-if="campaign.status" class="ml-auto flex-shrink">
            <div class="ts-4 text-right">
              <CampaignStatusToggleButton
                :campaign="campaign"
              ></CampaignStatusToggleButton>
            </div>
          </div>
          <div class="flex items-center mr-auto">
            <div v-if="campaign.legacyImageUrl" class="mr-4 hidden sm:block">
              <EditableImage
                class="rounded mr-2x"
                :imageUrl="campaign.legacyImageUrl"
                :thumbnailUrl="campaign.legacyImageThumbnailUrl"
                :height="48"
                :width="48"
                :blankHeight="36"
                :blankWidth="36"
                :editable-only-when-empty="true"
                :modelId="campaign.id"
                :deleteExisting="true"
                :edit-focus-icon-enabled="false"
                :editable="false"
                modelClass="campaign"
              ></EditableImage>
            </div>
            <div v-else class="mr-4 hidden sm:block">
              <EditableImage
                class="rounded mr-2x"
                :imageUrl="imageFileComputed?.original || null"
                :thumbnailUrl="imageFileComputed?.imageThumbnailUrl || null"
                :height="48"
                :width="48"
                :blankHeight="36"
                :blankWidth="36"
                :modelId="campaign.id"
                :editable="false"
                modelClass="campaign"
              ></EditableImage>
            </div>
            <div>
              <div class="px-2">
                <h3 class="mb-0 text-lg font-bold ">
                  <EditableValue
                    ref="editableCampaignName"
                    type="contentEditable"
                    :value="campaign.name"
                    :mutation="UPDATE_CAMPAIGN_MUTATION"
                    :variables="{ input: { id: campaign.id, name: '{value}' } }"
                    placeholder="No Campaign Name"
                    class="mr-2"
                    :edit-focus-icon-enabled="false"
                  />
                  <small class="text-gray-600 font-sm">
                    <sup>#</sup>
                    {{ campaign.id }}
                  </small>
                </h3>
                <div class="flex items-center mt-2">
                  <EditableValue
                    :value="campaign.payoutType"
                    type="slot"
                    :mutation="UPDATE_CAMPAIGN_MUTATION"
                    :variables="{
                      input: {
                        id: campaign.id,
                        payoutType: '{value}'
                      }
                    }"
                  >
                    <template v-slot:default="slotProps">
                      <Tag v-if="campaign.isCapped" color="darkRed" class="mr-1"
                        >CAPPED</Tag
                      >
                      <Tag
                        icon="currencyUsd"
                        color="purple"
                        tabindex="0"
                        @focus="
                          () => {
                            slotProps.focus()
                          }
                        "
                        >{{ slotProps.value }}</Tag
                      >
                    </template>
                    <template v-slot:focusSlot="slotProps">
                      <Superselect
                        title="Payout Type"
                        :value="{
                          id: slotProps.value,
                          label: slotProps.value.toUpperCase()
                        }"
                        @input="
                          value => {
                            slotProps.updateInternalValue(value.id)
                          }
                        "
                        @close="
                          () => {
                            slotProps.unfocusOnNoChanges() ||
                              slotProps.focusOnSave()
                          }
                        "
                        :focusOnMount="true"
                        :multiple="false"
                        placeholder="Select"
                        track-by="id"
                        label="label"
                        :allow-empty="false"
                        style="min-width: 10rem"
                        :query="INTROSPECTION_TYPE_QUERY"
                        :query-variables="{ name: 'CampaignPayoutTypeEnum' }"
                        :query-result-function="
                          data => {
                            return data.__type.enumValues.map(value => ({
                              id: value.name,
                              label: value.name.toUpperCase()
                            }))
                          }
                        "
                      ></Superselect>
                    </template>
                  </EditableValue>
                </div>
              </div>
              <div class="my-3">
                <EditableValue
                  ref="descriptionEditable"
                  :value="campaign.description"
                  :mutation="UPDATE_CAMPAIGN_MUTATION"
                  input-style="min-width: 10rem; max-width: 100%;"
                  type="richText"
                  :variables="{
                    input: {
                      id: campaign.id,
                      description: '{value}'
                    }
                  }"
                ></EditableValue>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <Tabs show-type="show" :route="tabRoute">
              <Tab name="Details" :active="true">
                <CampaignDetails :id="id"></CampaignDetails>
              </Tab>
              <Tab
                v-if="!campaign.smartLink"
                name="Offers"
                :tag="
                  campaign && campaign.offers
                    ? campaign.offers.length
                    : undefined
                "
                tag-color="blue"
              >
                <CampaignOffers :campaignId="campaign.id"></CampaignOffers>
              </Tab>
              <Tab name="Prelander" v-if="campaign.smartLink">
                <CampaignPrelander
                  :campaignId="campaign.id"
                ></CampaignPrelander>
              </Tab>
              <Tab name="Stats">
                <simple-report
                  :filters="reportFilters"
                  :fields="[
                    'day',
                    'visits',
                    'revenue',
                    'conversions',
                    'payout',
                    'netRevenue'
                  ]"
                  class="col-span-4 mt-4"
                >
                  <h4 slot="table-header" class="widget-title">
                    Daily Summary
                  </h4>
                </simple-report>
              </Tab>
              <Tab name="Payouts">
                <Payouts
                  v-if="campaign && campaign.payouts"
                  :payouts="campaign.payouts"
                  :campaignId="parseInt(id)"
                  level="campaign"
                ></Payouts>
              </Tab>
              <Tab name="Daypart">
                <Dayparting
                  :daypartable-id="campaign.id"
                  daypartable-type="Campaign"
                />
              </Tab>
              <Tab name="Redirects">
                <Redirects
                  :overrideFilters="{
                    campaign: {
                      value: [{ id: campaign.id }]
                    }
                  }"
                >
                </Redirects>
              </Tab>
              <Tab name="Caps">
                <UpdateCampaignFallback
                  class="px-1 pt-3 bg-gray-100"
                  :id="id"
                  :fallbackCampaignId="campaign.fallbackCampaignId"
                  :fallbackOfferId="campaign.fallbackOfferId"
                ></UpdateCampaignFallback>
                <Caps
                  v-if="id"
                  :cappableId="id"
                  cappableType="Campaign"
                  :fallbackCampaignId="campaign && campaign.fallbackCampaignId"
                ></Caps>
              </Tab>
              <Tab name="Cap Defaults">
                <CapDefaults
                  v-if="id"
                  :cappableId="id"
                  cappableType="Campaign"
                  triggerableType="Redirect"
                ></CapDefaults>
              </Tab>
              <Tab name="Action Scubs">
                <ActionScrubs
                  v-if="id"
                  :campaignId="id"
                  :defaultScrub="campaign.aSPercentage"
                >
                </ActionScrubs>
              </Tab>
              <Tab name="Rules">
                <RulesTableWrapper
                  :default-filter-values="{ campaigns: [campaign] }"
                  :hide-filters="[
                    'types',
                    'redirects',
                    'campaigns',
                    'publishers',
                    'sources'
                  ]"
                >
                  <template v-slot:beforeTable>
                    <div
                      class="flex justify-between items-center mt-4 px-4 py-4 rounded-lg bg-gray-200 "
                    >
                      <div class="text-muted">
                        <span>
                          {{ selectedCount }}
                          {{ selectedCount == 1 ? 'item' : 'items' }} selected
                        </span>
                      </div>
                      <div class="flex gap-4">
                        <YButton
                          color="red"
                          :disabled="!rowsAreSelected"
                          @click="openDeleteRulesModal"
                          >Delete</YButton
                        >
                        <YButton
                          color="blue"
                          @click="openCreateRulesModal"
                          :disabled="rowsAreSelected"
                          >Create Rules</YButton
                        >
                      </div>
                    </div>
                  </template>
                </RulesTableWrapper>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <portal to="sidebar-right">
        <SideBarRightItem
          title="Top Publishers"
          icon="chartLine"
          class="flex-1"
        >
          <CampaignTopPublisherStats :campaignId="parseInt(campaign.id)">
          </CampaignTopPublisherStats>
        </SideBarRightItem>
      </portal>
    </div>
  </div>
  <div v-else>
    <Spinner
      :size="12"
      v-if="$apollo.queries.campaign.loading"
      color="green"
    ></Spinner>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CampaignDetails from '@/views/Admin/Campaign/CampaignDetails'
import Redirects from '@/views/Admin/Redirects/Redirects'
import Payouts from '@/views/Admin/Payouts/Payouts'
import CampaignOffers from '@/views/Admin/Campaign/CampaignOffers'
import CampaignPrelander from '@/views/Admin/Campaign/CampaignPrelander'
import UpdateCampaignFallback from '@/views/Admin/Campaign/UpdateCampaignFallback'
import CampaignStatusToggleButton from '@/components/legacy/Campaigns/CampaignStatusToggleButton'
import CampaignTopPublisherStats from '@/views/Admin/Campaign/CampaignTopPublisherStats'
import EditableImage from '@/components/utilities/EditableImage'
import Dayparting from '@/components/ui/Dayparting'
import CAMPAIGN_QUERY from '@/graphql/Campaign/CampaignQuery.gql'
import REDIRECTS_QUERY from '@/graphql/Redirect/RedirectsQuery.gql'
import UPDATE_CAMPAIGN_MUTATION from '@/graphql/Campaign/UpdateCampaignMutation.gql'
import INTROSPECTION_TYPE_QUERY from '@/graphql/Introspection/IntrospectionTypeQuery.gql'
import Caps from '@/views/Admin/Caps/Caps'
import CapDefaults from '@/views/Admin/Caps/CapDefaults'
import RulesTableWrapper from '@/views/Admin/Rules/RulesTableWrapper'
import CreateRules from '@/views/Admin/Rules/CreateRules'
import DeleteRules from '@/views/Admin/Rules/DeleteRules'
import ActionScrubs from '@/views/Admin/Campaign/ActionScrubs'
import SimpleReport from '@/views/Admin/Reports/SimpleReport'

export default {
  components: {
    CampaignDetails,
    CampaignOffers,
    CampaignPrelander,
    UpdateCampaignFallback,
    Payouts,
    Redirects,
    CampaignStatusToggleButton,
    CampaignTopPublisherStats,
    EditableImage,
    Caps,
    CapDefaults,
    RulesTableWrapper,
    ActionScrubs,
    Dayparting,
    SimpleReport
  },
  apollo: {
    campaign: {
      query: CAMPAIGN_QUERY,
      variables() {
        return {
          id: this.id,
          landingPagesOrderBy: [{ column: 'priority', order: 'ASC' }]
        }
      },
      result(result) {
        if (result.data && result.data.campaign) {
          this.campaignPayouts = result.data.campaign.payouts ?? null
          this.verticalTags = result.data.campaign.tags.filter(
            tag => tag.type == 'vertical'
          )
          if (!this.campaign.name) {
            this.$nextTick(() => {
              this.$refs.editableCampaignName.focus()
            })
          }
        }
      },
      deep: false
    },
    redirects: {
      query: REDIRECTS_QUERY,
      variables() {
        return {
          campaign: {
            id: { value: [this.id] }
          },
          first: 100
        }
      }
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    tab: {
      type: String,
      default: 'details'
    }
  },
  data() {
    return {
      UPDATE_CAMPAIGN_MUTATION,
      INTROSPECTION_TYPE_QUERY,
      columnSizes: ['col-xl-3 col-lg-6', 'col-xl-6 col-lg-12'],
      campaignPayouts: null,
      reportFilters: {
        campaign: {
          value: [{ label: this.id, id: this.id }],
          modifiers: { exclude: false, edit: true }
        }
      },
      labelWidth: '8.5rem',
      labelTextAlign: 'right',
      campaignStatusInput: 'paused',
      verticalTags: [],
      me: null
    }
  },
  computed: {
    ...mapState('rule', ['selectedRowIds']),
    imageFileComputed() {
      if (this.campaign?.images) {
        let result = this.campaign.images
          .filter(image => image.type === 'template')
          .map(image => {
            return {
              id: image.id,
              name: image.name,
              imageThumbnailUrl:
                image.conversions.find(image => image.conversion === 'thumb')
                  ?.storageUrl || null,
              original:
                image.conversions.find(image => image.conversion === 'full')
                  ?.storageUrl || null
            }
          })
        return result[0]
      }
      return null
    },

    tabRoute() {
      return { name: 'Campaign', params: { id: this.id, tab: this.tab } }
    },
    selectedCount() {
      return this.selectedRowIds.length
    },
    rowsAreSelected() {
      return this.selectedRowIds.length > 0
    }
  },
  mounted() {
    this.$events.listen('refetchPayouts', () => {
      this.$apollo.queries.campaign.refetch()
    })
    this.$events.listen('refetchPostbacks', () => {
      this.$apollo.queries.campaign.refetch()
    })
    this.$events.listen('refetchRedirects', () => {
      this.$apollo.queries.campaignRedirects.refetch()
    })
    this.$events.listen('refreshCampaigns', () => {
      this.$apollo.queries.campaign.refetch()
    })
    this.$events.listen('campaignDeleted', id => {
      if (this.campaign.id == id) {
        window.location = '/admin/campaigns'
      }
    })
  },
  beforeDestroy() {
    this.$events.remove('refetchPayouts')
    this.$events.remove('refetchPostbacks')
    this.$events.remove('refreshRedirects')
    this.$events.remove('refreshCampaigns')
    this.$events.remove('campaignDeleted')
  },
  updated() {},
  methods: {
    openCreateRulesModal() {
      this.$modal.show(
        CreateRules,
        {
          defaults: {
            type: {
              name: 'campaign',
              label: 'Campaign'
            },
            ruleables: [this.campaign]
          },
          hide: ['ruleables']
        },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDeleteRulesModal() {
      this.$modal.show(
        DeleteRules,
        { selectedRowIds: this.selectedRowIds },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 650,
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>
<style scoped></style>
