<template>
  <widget>
    <widget-header v-if="title">
      <h4 class="widget-title">{{ title }}</h4>
    </widget-header>
    <widget-body>
      <div v-for="[label, time] in times" :key="label" class="mb-4">
        <div class="mb-1">
          <span class="fw-500">{{ label }}</span> -
          {{
            time - startTime > 1
              ? parseFloat(time - startTime).toFixed(3) + 's'
              : parseFloat(time - startTime).toFixed(3) * 1000 + 'ms'
          }}
        </div>
        <div class="progress">
          <div
            class="progress-bar bg-info"
            :style="`width: ${timePercentage(time)}%;`"
          ></div>
        </div>
      </div>
    </widget-body>
  </widget>
</template>

<script>
export default {
  props: {
    times: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: null
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    startTime() {
      return this.times[0][1]
    },
    endTime() {
      return this.times[this.times.length - 1][1]
    },
    elapsedTime() {
      return this.endTime - this.startTime
    }
  },
  mounted() {},
  updated() {},
  methods: {
    timePercentage(time) {
      return ((time - this.startTime) / (this.endTime - this.startTime)) * 100
    }
  }
}
</script>
<style scoped>
.progress-bar {
  height: 4px;
}
</style>
