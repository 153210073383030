var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "block w-full",
    staticStyle: {
      "min-height": "65px"
    }
  }, [_c('portal', {
    attrs: {
      "to": "content-top-bar-left-header"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('YButton', {
    attrs: {
      "color": "inverse"
    }
  }, [_c('Icon', {
    staticClass: "inline mr-1",
    staticStyle: {
      "margin-bottom": "3px"
    },
    attrs: {
      "name": "arrowLeft",
      "size": "4"
    }
  }), _vm._v("Back to Login")], 1)], 1)], 1)])], 1), _c('div', {
    staticClass: "mx-auto w-full xl:w-3/5 2xl:w-2/5 mb-12"
  }, [_c('div', {
    staticClass: "bg-white rounded-md pt-4 pb-8 px-2 sm:px-4 md:px-10 shadow-md flex flex-col"
  }, [_c('h1', {
    staticClass: "font-bold text-2xl text-center text-inverse-700 mb-2"
  }, [_vm._v(" Welcome to YTZ 👋 ")]), _c('div', {
    staticClass: "mt-3"
  }, [_c('p', {
    staticClass: "pb-2 font-semibold uppercase text-inverse-700"
  }, [_vm._v(" Contact Details ")]), _c('div', {
    staticClass: "grid grid-rows-1 gap-3"
  }, [_c('YInput', {
    attrs: {
      "label": "Name",
      "required": true
    },
    model: {
      value: _vm.userName,
      callback: function ($$v) {
        _vm.userName = $$v;
      },
      expression: "userName"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Company"
    },
    model: {
      value: _vm.company,
      callback: function ($$v) {
        _vm.company = $$v;
      },
      expression: "company"
    }
  }), _vm._m(0), _c('div', {
    staticClass: "grid grid-cols-1"
  }, [_c('div', {
    staticClass: "flex flex-row gap-5"
  }, [_c('div', {
    staticClass: "mr-6 flex items-center mb-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contactTypeEmail,
      expression: "contactTypeEmail"
    }],
    attrs: {
      "checked": "",
      "type": "checkbox",
      "id": "email",
      "value": "email"
    },
    domProps: {
      "checked": Array.isArray(_vm.contactTypeEmail) ? _vm._i(_vm.contactTypeEmail, "email") > -1 : _vm.contactTypeEmail
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.contactTypeEmail,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "email",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.contactTypeEmail = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.contactTypeEmail = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.contactTypeEmail = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "text-base capitalize",
    attrs: {
      "for": "type"
    }
  }, [_vm._v("Email")])]), _c('div', {
    staticClass: "mr-6 flex items-center mb-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contactTypeMessenger,
      expression: "contactTypeMessenger"
    }],
    attrs: {
      "type": "radio",
      "id": "skype",
      "value": "skype"
    },
    domProps: {
      "checked": _vm._q(_vm.contactTypeMessenger, "skype")
    },
    on: {
      "change": function ($event) {
        _vm.contactTypeMessenger = "skype";
      }
    }
  }), _c('label', {
    staticClass: "text-base capitalize",
    attrs: {
      "for": "type"
    }
  }, [_vm._v("Skype")])]), _c('div', {
    staticClass: "mr-6 flex items-center mb-2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.contactTypeMessenger,
      expression: "contactTypeMessenger"
    }],
    attrs: {
      "type": "radio",
      "id": "telgram",
      "value": "telegram"
    },
    domProps: {
      "checked": _vm._q(_vm.contactTypeMessenger, "telegram")
    },
    on: {
      "change": function ($event) {
        _vm.contactTypeMessenger = "telegram";
      }
    }
  }), _c('label', {
    staticClass: "text-base capitalize",
    attrs: {
      "for": "type"
    }
  }, [_vm._v("telegram")])])]), _c('div', {
    staticClass: "col-span-3"
  }, [_c('YInput', {
    staticClass: "capitalize",
    class: _vm.contactTypeEmail ? 'block' : 'hidden',
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.contactEmail,
      callback: function ($$v) {
        _vm.contactEmail = $$v;
      },
      expression: "contactEmail"
    }
  }), _c('YInput', {
    staticClass: "capitalize mt-2",
    class: _vm.contactTypeMessenger == 'skype' ? 'block' : 'hidden',
    attrs: {
      "label": "Skype"
    },
    model: {
      value: _vm.contactMessenger,
      callback: function ($$v) {
        _vm.contactMessenger = $$v;
      },
      expression: "contactMessenger"
    }
  }), _c('YInput', {
    staticClass: "capitalize mt-2",
    class: _vm.contactTypeMessenger == 'telegram' ? 'block' : 'hidden',
    attrs: {
      "label": "Telegram"
    },
    model: {
      value: _vm.contactMessenger,
      callback: function ($$v) {
        _vm.contactMessenger = $$v;
      },
      expression: "contactMessenger"
    }
  })], 1)]), _c('div', {
    staticClass: "flex items-center mt-1"
  }, [_vm._m(1), _c('div', {
    staticClass: "flex flex-col flex-wrap md:grid md:grid-cols-2 w-full"
  }, _vm._l(_vm.leadTypeOptions, function (leadType) {
    return _c('div', {
      key: leadType
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedLeadType,
        expression: "selectedLeadType"
      }],
      attrs: {
        "type": "checkbox",
        "id": leadType
      },
      domProps: {
        "value": leadType,
        "checked": Array.isArray(_vm.selectedLeadType) ? _vm._i(_vm.selectedLeadType, leadType) > -1 : _vm.selectedLeadType
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.selectedLeadType,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = leadType,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.selectedLeadType = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedLeadType = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedLeadType = $$c;
          }
        }
      }
    }), _c('label', {
      staticClass: "text-base capitalize",
      attrs: {
        "for": leadType
      }
    }, [_vm._v(_vm._s(leadType))])]);
  }), 0)])], 1)]), _c('div', {
    staticClass: "mt-4 border-t pt-4"
  }, [_c('YAccordion', {
    staticClass: "mb-6",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Payout Types (optional) ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "grid grid-cols-2 md:grid-cols-4 gap-y-5 gap-x-3 mb-8"
        }, _vm._l(_vm.payoutTypeOptions, function (payoutType) {
          return _c('div', {
            key: payoutType
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.selectedPayoutType,
              expression: "selectedPayoutType"
            }],
            attrs: {
              "type": "checkbox",
              "id": payoutType
            },
            domProps: {
              "value": payoutType,
              "checked": Array.isArray(_vm.selectedPayoutType) ? _vm._i(_vm.selectedPayoutType, payoutType) > -1 : _vm.selectedPayoutType
            },
            on: {
              "change": function ($event) {
                var $$a = _vm.selectedPayoutType,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = payoutType,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.selectedPayoutType = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.selectedPayoutType = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.selectedPayoutType = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "text-base capitalize",
            attrs: {
              "for": payoutType
            }
          }, [_vm._v(_vm._s(payoutType))])]);
        }), 0)];
      },
      proxy: true
    }])
  }), _c('YAccordion', {
    staticClass: "mb-6",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Traffic Types (optional) ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "grid grid-cols-2 md:grid-cols-4 gap-y-5 gap-x-3 mb-8"
        }, _vm._l(_vm.trafficTagsOptions, function (trafficType) {
          return _c('div', {
            key: trafficType
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.selectedTrafficType,
              expression: "selectedTrafficType"
            }],
            attrs: {
              "type": "checkbox",
              "id": trafficType
            },
            domProps: {
              "value": trafficType,
              "checked": Array.isArray(_vm.selectedTrafficType) ? _vm._i(_vm.selectedTrafficType, trafficType) > -1 : _vm.selectedTrafficType
            },
            on: {
              "change": function ($event) {
                var $$a = _vm.selectedTrafficType,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = trafficType,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.selectedTrafficType = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.selectedTrafficType = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.selectedTrafficType = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "text-base capitalize",
            attrs: {
              "for": trafficType
            }
          }, [_vm._v(_vm._s(trafficType))])]);
        }), 0)];
      },
      proxy: true
    }])
  }), _c('YAccordion', {
    staticClass: "mb-6",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Verticals (optional) ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "grid grid-cols-2 md:grid-cols-4 gap-y-5 gap-x-3 mb-8"
        }, _vm._l(_vm.verticalTagsOptions, function (verticalType) {
          return _c('div', {
            key: verticalType
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.selectedVerticalType,
              expression: "selectedVerticalType"
            }],
            attrs: {
              "type": "checkbox",
              "id": verticalType
            },
            domProps: {
              "value": verticalType,
              "checked": Array.isArray(_vm.selectedVerticalType) ? _vm._i(_vm.selectedVerticalType, verticalType) > -1 : _vm.selectedVerticalType
            },
            on: {
              "change": function ($event) {
                var $$a = _vm.selectedVerticalType,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = verticalType,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.selectedVerticalType = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.selectedVerticalType = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.selectedVerticalType = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "text-base capitalize",
            attrs: {
              "for": verticalType
            }
          }, [_vm._v(_vm._s(verticalType))])]);
        }), 0)];
      },
      proxy: true
    }])
  }), _c('YAccordion', {
    staticClass: "mb-6",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Geos (optional) ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "grid grid-cols-2 md:grid-cols-4 gap-y-5 gap-x-3 mb-8"
        }, _vm._l(_vm.geoTagsOptions, function (geoType) {
          return _c('div', {
            key: geoType
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.selectedGeoType,
              expression: "selectedGeoType"
            }],
            attrs: {
              "type": "checkbox",
              "id": geoType
            },
            domProps: {
              "value": geoType,
              "checked": Array.isArray(_vm.selectedGeoType) ? _vm._i(_vm.selectedGeoType, geoType) > -1 : _vm.selectedGeoType
            },
            on: {
              "change": function ($event) {
                var $$a = _vm.selectedGeoType,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = geoType,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.selectedGeoType = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.selectedGeoType = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.selectedGeoType = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "text-base capitalize",
            attrs: {
              "for": geoType
            }
          }, [_vm._v(_vm._s(geoType))])]);
        }), 0)];
      },
      proxy: true
    }])
  }), _c('YAccordion', {
    staticClass: "mb-6",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Leave a note (optional) ")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('div', {
          staticClass: "w-full mb-8"
        }, [_c('textarea', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.message,
            expression: "message"
          }],
          staticClass: "px-3 py-3 w-full rounded mb-2 border block",
          attrs: {
            "placeholder": "Type here..."
          },
          domProps: {
            "value": _vm.message
          },
          on: {
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
              $event.preventDefault();
              return _vm.addComment();
            },
            "input": function ($event) {
              if ($event.target.composing) return;
              _vm.message = $event.target.value;
            }
          }
        })])];
      },
      proxy: true
    }])
  })], 1), _c('YButton', {
    staticClass: "w-full xl:w-2/5 mx-auto mt-16",
    attrs: {
      "color": "pink",
      "disabled": !_vm.contactMethodProvided,
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.registerUser
    }
  }, [_vm._v("Submit")])], 1), _c('div', [_c('portal-target', {
    attrs: {
      "name": "registration-errors"
    }
  })], 1)]), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors,
      "portal-to": "registration-errors",
      "alert-class": "text-xs",
      "icon-size": 12,
      "icon": null,
      "color": "yellow"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "font-semibold uppercase text-inverse-700"
  }, [_vm._v(" Preferred Contact Method "), _c('span', {
    staticClass: "ml-1 text-lg text-red-500 inline"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-40"
  }, [_c('p', {
    staticClass: "font-semibold uppercase text-inverse-700"
  }, [_vm._v(" Role "), _c('span', {
    staticClass: "ml-1 text-lg text-red-500 inline"
  }, [_vm._v("*")])])]);

}]

export { render, staticRenderFns }