var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', {
    staticStyle: {
      "height": "100vh"
    }
  }, [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "mr-2 rounded-full h-2 w-2 inline-flex md:hidden text-opacity-0",
    class: `bg-${_vm.TextColorMap[_vm.user.status]}-500 hover:bg-${_vm.TextColorMap[_vm.user.status]}-600`
  }), _c('div', {
    staticClass: "x-topbar-username break-all"
  }, [_vm._v(_vm._s(_vm.user.label))])])]), _c('div', {
    staticClass: "flex items-center justify-between w-full h-full"
  }, [_c('div', {
    staticClass: "flex flex-col lg:flex-row w-full items-start gap-4 h-full"
  }, [_c('div', {
    staticClass: "w-full lg:w-1/3 rounded-lg p-2 flex flex-col h-full"
  }, [_c('div', {
    staticClass: "flex flex-col text-center items-center text-gray-900"
  }, [_vm.user.primaryContact.company ? _c('Icon', {
    attrs: {
      "name": "accountMultiple",
      "size": "12",
      "color": "gray"
    }
  }) : _c('Icon', {
    attrs: {
      "name": "account",
      "size": "12",
      "color": "gray"
    }
  }), _vm.user.primaryContact.company ? _c('span', [_c('span', {
    staticClass: "block font-semibold text-gray-800 text-lg"
  }, [_vm._v(" " + _vm._s(_vm.user.primaryContact.company))]), _vm.user.primaryContact.company ? _c('span', {
    staticClass: "block"
  }, [_vm._v(_vm._s(_vm.user.name))]) : _vm._e()]) : _vm._e(), !_vm.user.primaryContact.company ? _c('span', [_c('span', {
    staticClass: "block font-semibold text-gray-800 text-lg"
  }, [_vm._v(" " + _vm._s(_vm.user.name))])]) : _vm._e(), _c('div', {
    staticClass: "flex flex-row items-center mt-3"
  }, [_c('button', {
    staticClass: "hover:bg-gray-200 p-1 rounded",
    on: {
      "click": function ($event) {
        return _vm.openLeadPriorityStatusModal(_vm.user);
      }
    }
  }, [_c('Icon', {
    class: _vm.priorityIconColor(_vm.user.userDetails.priorityStatus),
    attrs: {
      "name": _vm.priorityIconType(_vm.user.userDetails.priorityStatus),
      "size": 6
    }
  })], 1), _vm._v(" Priority Status: "), _c('span', {
    staticClass: "capitalize ml-2"
  }, [_vm._v(" " + _vm._s(_vm.user.userDetails.priorityStatus ? _vm.user.userDetails.priorityStatus : 'Regular'))])]), _vm.user.commentsLead.length > 0 ? _c('div', {
    staticClass: "flex flex-row items-center"
  }, [_c('Icon', {
    staticClass: "text-green-500 mr-2 text-sm",
    attrs: {
      "name": "circle",
      "size": "2"
    }
  }), _vm._v(" Last Contacted: " + _vm._s(_vm._f("luxon")(_vm.user.commentsLead[0].createdAt)) + " ")], 1) : _vm._e()], 1), _c('div', {
    staticClass: "mt-5 bg-gray-100 rounded-lg shadow"
  }, [_c('div', {
    staticClass: "grid grid-cols-2",
    staticStyle: {
      "grid-template-columns": "auto 40px"
    }
  }, [_c('div', {
    staticClass: "flex flex-col gap-y-3 text-gray-700 p-4"
  }, [_vm.user.status == 'lead' ? _c('div', [_c('span', {
    staticClass: "block flex flex-row"
  }, [_vm._v("Email "), _c('Icon', {
    staticClass: "ml-1",
    staticStyle: {
      "margin-top": "2px"
    },
    attrs: {
      "name": "pencil",
      "size": 3
    }
  })], 1), _c('span', {
    staticClass: "flex flex-row align-center text-black mt-1"
  }, [_c('EditableValue', {
    staticClass: "-mr-1 -ml-1",
    attrs: {
      "type": "contentEditable",
      "value": _vm.user.email,
      "mutation": _vm.UPDATE_USER_EMAIL_MUTATION,
      "variables": {
        input: {
          id: _vm.user.id,
          email: '{value}'
        }
      },
      "placeholder": "0",
      "value-class": "px-1",
      "edit-focus-icon-enabled": false
    }
  })], 1)]) : _c('div', [_c('span', {
    staticClass: "block flex flex-row"
  }, [_vm._v("Email")]), _c('span', {
    staticClass: "text-black"
  }, [_vm._v(_vm._s(_vm.accountEmail(_vm.user)))])]), _vm.user.primaryContact.messenger ? _c('div', [_vm._v(" Messenger "), _c('span', {
    staticClass: "text-black block mt-1"
  }, [_c('Icon', {
    staticClass: "w-4 h-4 text-gray-600 inline",
    attrs: {
      "name": JSON.parse(_vm.user.primaryContact.messenger.toLowerCase()).app
    }
  }), _vm._v(" " + _vm._s(JSON.parse(_vm.user.primaryContact.messenger).handle))], 1)]) : _vm._e(), _vm.user.primaryContact.country ? _c('div', [_vm._v(" Country "), _c('span', {
    staticClass: "text-black block mt-1"
  }, [_vm._v(_vm._s(_vm.user.primaryContact.country.name))])]) : _vm._e(), JSON.parse(_vm.user.userDetails.meta) ? _c('div', [_vm._v(" Lead Comments "), _c('span', {
    staticClass: "text-black block mt-1"
  }, [_vm._v(" " + _vm._s(JSON.parse(_vm.user.userDetails.meta)))])]) : _vm._e()]), _vm.user.status == 'lead' ? _c('div', {
    staticClass: "pt-2 pr-2"
  }, [_c('YButton', {
    staticStyle: {
      "padding": "0.25rem 0.5rem 0.5rem 0.5rem"
    },
    on: {
      "click": function ($event) {
        return _vm.openLeadDetailsModal(_vm.user);
      }
    }
  }, [_c('Icon', {
    staticClass: "text-inverse-400 hover:text-inverse-500",
    attrs: {
      "name": "accountEdit",
      "size": "6"
    }
  })], 1)], 1) : _vm._e()]), _vm.user.status == 'lead' && _vm.user.secondaryContact.length < 1 ? _c('div', {
    staticClass: "p-2"
  }, [_c('YButton', {
    attrs: {
      "color": "inverse"
    },
    on: {
      "click": _vm.openSecondaryContactModal
    }
  }, [_vm._v("Merge User")])], 1) : _vm._e(), _c('div', {
    staticClass: "bg-gray-200 m-1 rounded px-3 pt-2 pb-3 text-gray-900"
  }, [_c('div', {
    staticClass: "mb-3 flex justify-between"
  }, [_c('div', {
    staticClass: "widget-title"
  }, [_vm._v(" Secondary Contacts ")]), _c('button', {
    on: {
      "click": _vm.openCreateSeconaryContactModal
    }
  }, [_c('Icon', {
    staticClass: "hover:bg-gray-300 rounded",
    attrs: {
      "name": "plus",
      "size": 6
    }
  })], 1)]), _vm._l(_vm.user.secondaryContact, function (user) {
    return _c('ul', {
      key: user.id,
      staticClass: "mb-3 py-1 flex flex-row justify-between hover:bg-gray-300 rounded showElementParent"
    }, [_c('div', {}, [_c('li', {
      staticClass: "font-semibold text-sm"
    }, [_vm._v(_vm._s(user.name))]), _c('li', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: user.email,
        expression: "user.email"
      }],
      staticClass: "truncate"
    }, [_vm._v(" " + _vm._s(user.email) + " ")]), user.messenger ? _c('li', [_c('Icon', {
      staticClass: "w-4 h-4 text-gray-600 inline",
      attrs: {
        "name": JSON.parse(user.messenger.toLowerCase()).app
      }
    }), _vm._v(" " + _vm._s(JSON.parse(user.messenger).handle) + " ")], 1) : _vm._e()]), _c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: `Delete Secondary Contact`,
        expression: "`Delete Secondary Contact`"
      }],
      staticClass: "py-1 px-2 cursor-pointer showElement",
      on: {
        "click": function ($event) {
          return _vm.deleteContact(user.id);
        }
      }
    }, [_c('Icon', {
      staticClass: "hover:text-red-800",
      attrs: {
        "name": "close",
        "size": 6,
        "color": "red"
      }
    })], 1)]);
  })], 2)])]), _c('div', {
    staticClass: "w-full lg:w-2/3 rounded-lg bg-gray-100"
  }, [_c('LeadComments', {
    attrs: {
      "modelId": _vm.user.id,
      "comments": _vm.user.commentsLead,
      "modelClass": "User",
      "user": _vm.user
    }
  })], 1)])]), _vm.user.status == 'lead' ? _c('portal', {
    attrs: {
      "to": "sidebar-right"
    }
  }, [_c('SideBarRightItem', {
    attrs: {
      "title": "Convert to Active User"
    }
  }, [_c('YButton', {
    staticClass: "mx-4",
    attrs: {
      "color": "pink"
    },
    on: {
      "click": function ($event) {
        return _vm.openConvertToActiveModal(_vm.user);
      }
    }
  }, [_vm._v("Convert to Active")])], 1), _c('SideBarRightItem', {
    scopedSlots: _vm._u([{
      key: "root",
      fn: function () {
        return [_c('div', {
          key: _vm.user.accountManager ? _vm.user.accountManager.id : 0,
          staticClass: "x-header group cursor-pointer",
          on: {
            "click": function ($event) {
              return _vm.openSyncAccountManagerWidget();
            }
          }
        }, [_c('div', {
          staticClass: "x-icon-container"
        }, [_vm.user.accountManager ? _c('UserAvatar', {
          attrs: {
            "id": _vm.user.accountManager.id,
            "height": "10"
          }
        }) : _c('div', {
          staticClass: "text-white w-10 h-10 text-center leading-10 rounded-full font-extrabold bg-gray-300"
        }, [_c('Icon', {
          staticClass: "p-2",
          attrs: {
            "name": "accountQuestion",
            "size": 10
          }
        })], 1), _vm._v(" --> ")], 1), _c('div', {
          staticClass: "x-header-text flex-1"
        }, [_c('div', {
          staticClass: "text-xs font-semibold text-gray-500"
        }, [_vm._v(" Account Manager ")]), _c('div', {
          staticClass: "text-gray-700"
        }, [_vm.user.accountManager && _vm.user.accountManager.primaryContact ? [_vm._v(" " + _vm._s(_vm.user.accountManager.primaryContact.name) + " ")] : [_c('span', {
          staticClass: "text-gray-400"
        }, [_vm._v(" Not Assigned")])]], 2)]), _c('Icon', {
          staticClass: "text-blue-600 mx-2 invisible group-hover:visible",
          attrs: {
            "name": "pencil"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 4183588422)
  }), _c('SideBarRightItem', {
    attrs: {
      "title": "Traffic Sources"
    }
  }, [_c('EditableValue', {
    ref: "trafficSourcesEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'trafficType';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.id,
          type: 'trafficType',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "orange",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['trafficType']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 1178113921)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Verticals"
    }
  }, [_c('EditableValue', {
    ref: "verticalsEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'vertical';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.id,
          type: 'vertical',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "blue",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['vertical']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 2547501416)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Geos"
    }
  }, [_c('EditableValue', {
    ref: "geosEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'geoLocation';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.id,
          type: 'geoLocation',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "green",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['geoLocation']
                }
              },
              first: 100
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 2354433743)
  })], 1), _c('SideBarRightItem', {
    attrs: {
      "title": "Referred By"
    }
  }, [_c('EditableValue', {
    ref: "referredEditable",
    staticClass: "px-3",
    attrs: {
      "value": _vm.user.tags.filter(function (tag) {
        return tag.type === 'referredOption';
      }),
      "type": "slot",
      "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
      "sortBy": "id",
      "transformValueForQuery": function (value) {
        return value.map(function (tag) {
          return tag.name;
        });
      },
      "variables": {
        input: {
          taggableId: _vm.id,
          type: 'referredOption',
          taggableType: 'user',
          tags: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": "gray",
              "tabindex": "0"
            },
            on: {
              "focus": function () {
                slotProps.focus();
              }
            }
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1) : _c('div', {
          on: {
            "click": function () {
              slotProps.focus();
            }
          }
        }, [_c('span', {
          staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
        }, [_vm._v("None")])])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticClass: "box-width min-width-full",
          attrs: {
            "title": "Tags",
            "value": slotProps.value,
            "focusOnMount": true,
            "multiple": true,
            "track-by": "slug",
            "label": "name",
            "placeholder": "Select",
            "query": _vm.TAGS_QUERY,
            "query-variables": {
              filters: {
                type: {
                  value: ['referredOption']
                }
              },
              first: 500
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value);
            }
          }
        })];
      }
    }], null, false, 1095304123)
  })], 1)], 1) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }