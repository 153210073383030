<template>
  <Widget>
    <WidgetHeader @close="$emit('close')">
      <div class="flex">
        <span class="upperCase" v-text="transformText"></span>

        <Icon
          :name="
            eventType == 'positive' ? 'alertCircleOutline' : 'alertOctagon'
          "
          size="6"
          class="ml-2"
          :class="eventType == 'positive' ? 'text-blue-600' : 'text-red-600'"
        />
      </div>
    </WidgetHeader>
    <WidgetBody>
      <YTable v-if="dataByEventName" :data="dataByEventName || []">
        <template slot="header-row">
          <th>Created</th>
          <th>Comment</th>
          <th></th>
        </template>
        <template slot="row" slot-scope="props">
          <td style="width: 175px;">
            <div class="flex justify-start items-center">
              <UserAvatar height="8" :id="props.rowData.commenter.id" />
              <span class="pl-3 text-gray-600 text-xs">
                {{
                  props.rowData.createdAt
                    | luxon({
                      output: { format: 'yyyy-MM-dd' }
                    })
                }}
              </span>
            </div>
          </td>
          <td>{{ props.rowData.message }}</td>
          <td style="justify-content:end;">
            <span
              @click="openConfirmationModal(props.rowData.id)"
              :is-loading="isDeleting"
            >
              <Icon
                name="trashCan"
                size="4"
                class="text-red-600 hover:text-red-700 cursor-pointer"
              />
            </span>
          </td>
        </template>
      </YTable>

      <!-- confirmation modal -->
      <modal name="confirmation" :width="364" :height="140">
        <Widget class="bg-red-600 text-white border-red-700">
          <WidgetHeader class="bg-red-600 text-white"
            ><span class="text-white">Are you sure?</span></WidgetHeader
          >
          <WidgetBody>
            <h5 class="text-yellow-400 font-bold">
              This action cannot be undone.
            </h5>
          </WidgetBody>
          <WidgetFooter
            class="bg-dark-transparent-1 flex justify-end gap-6 py-2 px-4"
          >
            <YButton
              class="border hover:bg-red-500 focus:bg-red-700"
              @click="$modal.hide('confirmation')"
              >Cancel</YButton
            >
            <YButton
              class="bg-red-700 hover:bg-red-800 border focus:bg-red-700"
              @click="confirmationOfDelete"
              >Delete</YButton
            >
          </WidgetFooter>
        </Widget>
      </modal>
    </WidgetBody>
  </Widget>
</template>

<script>
import DELETE_COMMENT_MUTATION from '@/graphql/Comment/DeleteCommentMutation.gql'

export default {
  components: {},
  props: {
    userId: {
      type: String,
      required: true
    },
    eventType: {
      type: String
    },
    data: {
      type: Array
    }
  },
  data() {
    return {
      isDeleting: false,
      deleteItemId: null
    }
  },
  mounted() {},
  updated() {},
  computed: {
    transformText() {
      let eventName = this.eventType.replace(/([A-Z])\w+/g, '')
      if (eventName !== 'positive') {
        return eventName + ' Complaint'
      } else {
        return eventName + ' Feedback'
      }
    },
    dataByEventName() {
      return this.data.filter(event => event.commentType == this.eventType)
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirmationOfDelete() {
      this.isDeleting = true
      this.$apollo
        .mutate({
          mutation: DELETE_COMMENT_MUTATION,
          variables: {
            id: this.deleteItemId
          }
        })
        .then(() => {
          this.isDeleting = false
          this.$emit('close')
          this.$events.emit('refreshCommunications')
          this.$toastr.s('Quality Comment Deleted')
        })
        .catch(error => {
          this.isDeleting = false
          console.error(error)
        })
    },

    openConfirmationModal(value) {
      this.deleteItemId = value
      this.$modal.show('confirmation')
    }
  }
}
</script>

<style>
.upperCase {
  text-transform: capitalize;
}
</style>
