<template>
  <portal to="alerts">
    <div
      v-if="publisher && publisher.isQuarantined"
      :allow-close="false"
      class="bg-yellow-200 border-b border-yellow-300 text-yellow-800 px-4 py-4"
    >
      <div class="flex items-center">
        <div class="flex items-center justify-between">
          <div class="flex items-center mr-6 flex-shrink">
            <Icon name="information" class="h-8 w-8" />
          </div>
          <div class="text-left">
            <div class="uppercase font-bold flex justify-between">
              You are currently under Quarantine
            </div>
            <div class="text-xs">
              You can subscribe to a maximum of
              <span class="font-bold">3 campaigns</span> per month.
            </div>
          </div>
          <div></div>
        </div>
        <div class="text-center ml-6">
          <div class="text-xs font-semibold">USED</div>
          <div class="text-base font-bold">
            {{ publisher.quarantine.campaignSubscribeCount }}/3
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
export default {
  props: {
    publisher: {
      type: Object,
      required: true
    }
  }
}
</script>

<style></style>
