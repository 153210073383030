<template>
  <Widget>
    <WidgetHeader>Edit Postback</WidgetHeader>
    <WidgetBody>
      <form class="row" @submit.prevent="update" ref="form">
        <div class="flex">
          <Superselect
            class="flex-shrink"
            title="Protocol"
            v-model="protocol"
            :multiple="false"
            :options="protocolOptions"
            select-class="rounded-r-none border-r-0"
          ></Superselect>
          <YInput
            class="flex-grow"
            input-class="rounded-l-none border-l-0"
            v-model="url"
            :label="`your-postback-url.com/?sub1={SUB1}&rev={REVENUE}`"
          ></YInput>
        </div>
      </form>
      <YAlert type="info" :allow-close="false" class="mt-2">
        Available parameters are:
        <span class="font-weight-semibold"
          >{S1} {S2} {S3} {S4} {S5} {REVENUE}</span
        >. Case insensitive.
      </YAlert>
    </WidgetBody>

    <WidgetFooter class="bg-gray-100 py-2 px-4 flex justify-between">
      <YButton color="link" @click="$emit('close')">Cancel</YButton>
      <YButton color="blue" @click="update" :is-loading="isSubmitting"
        >Edit Postback</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import UPDATE_POSTBACK_MUTATION from '@/graphql/Postback/UpdatePostbackMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],

  props: {
    postback: {
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      url: this.postback.url,
      protocol: this.postback.protocol
        ? this.postback.protocol + '://'
        : 'https://',
      protocolOptions: ['http://', 'https://'],
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    update() {
      this.isSubmitting = true
      let input = {
        protocol: this.protocol,
        url: this.url,
        id: this.postback.id
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_POSTBACK_MUTATION,
          variables: {
            input: input
          }
        })
        .then(result => {
          this.clearValidationErrors()

          this.$toastr.s('Postback Updated', 'Success!')
          console.log(result)
          this.isSubmitting = false
          // this.$events.emit('refreshAdvertiserAccounts', true) // deprecated
          this.$events.emit('postbackUpdated', result.data.updatePostback)
          this.$emit('close')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
