var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-6 w-full h-full bg-gray-200"
  }, [_c('div', {
    staticClass: "container mx-auto"
  }, [_c('div', {
    staticClass: "py-6 w-full h-full bg-gray-200"
  }, [_c('div', {
    staticClass: "w-10/12 mx-auto mb-4 my-6 shadow sm:px-10 sm:py-6 py-4 px-4 bg-white rounded-md"
  }, [_c('div', {
    staticClass: "flex w-full justify-between"
  }, [_c('p', {
    staticClass: "text-lg text-gray-800 font-semibold pb-3"
  }, [_vm._v(" Subscribe to Reminder Emails ")]), _c('div', {
    staticClass: "h-6 cursor-pointer rounded-full relative"
  }, [_c('input', {
    staticClass: "focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer",
    attrs: {
      "type": "checkbox",
      "name": "toggle",
      "id": "disableEngemenetEmailToggle"
    },
    domProps: {
      "checked": !this.me.userDetails.disableEngagementEmails
    },
    on: {
      "change": function ($event) {
        return _vm.disableEngemenetEmailSubmit();
      }
    }
  }), _c('label', {
    staticClass: "toggle-label bg-gray-300 block w-12 h-6 overflow-hidden rounded-full cursor-pointer",
    attrs: {
      "for": "disableEngemenetEmailToggle"
    }
  })])]), _vm._m(0)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full text-sm text-gray-600 font-normal pb-3"
  }, [_c('p', {
    staticClass: "pb-1"
  }, [_vm._v(" Receive helpful reminders to your account. ")]), _c('p', [_vm._v(" Unsubscribing from reminders will not impact any system or campaign critical notifications. ")])]);

}]

export { render, staticRenderFns }