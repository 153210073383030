let defaultFilters = {
  id: {
    value: null,
    modifiers: null,
    title: 'ID',
    type: 'input'
  },
  name: {
    value: null,
    modifiers: {
      matchType: 'contains'
    },
    title: 'Contact Name',
    type: 'input'
  },
  companyName: {
    value: null,
    modifiers: {
      matchType: 'contains'
    },
    title: 'Company Name',
    type: 'input'
  },
  contactEmail: {
    value: null,
    modifiers: {
      matchType: 'contains'
    },
    title: 'Email',
    type: 'input'
  },
  instantMessengerHandle: {
    value: null,
    modifiers: {
      matchType: 'contains'
    },
    title: 'IM Handle',
    type: 'input'
  },
  leadType: {
    value: null,
    title: 'Lead Type',
    type: 'select',
    multiple: true,
    trackByKey: 'name'
  },
  payoutType: {
    value: null,
    title: 'Payout Type',
    type: 'select',
    multiple: true,
    trackByKey: 'name'
  },
  trafficType: {
    value: null,
    title: 'Traffic Type',
    type: 'select',
    multiple: true,
    trackByKey: 'name'
  },
  verticalTypeTags: {
    value: null,
    title: 'Verticals',
    type: 'select',
    multiple: true,
    trackByKey: 'name'
  },
  geoLocation: {
    value: null,
    title: 'Geos',
    type: 'select',
    multiple: true,
    trackByKey: 'name'
  },
  priorityStatus: {
    value: null,
    title: 'Priority Status',
    type: 'select',
    multiple: false,
    modifiers: {
      matchType: 'exact'
    }
  }
}

export const namespaced = true

export const state = {
  filters: defaultFilters,
  orderBy: []
}

export const mutations = {
  RESET_FILTERS(state) {
    state.filters = defaultFilters
  }
}

export const actions = {
  resetFilters({ commit }) {
    commit('RESET_FILTERS')
  }
}
