<template>
  <span :class="classes">
    <template v-if="actionsState == 'default'">
      <a @click="edit" class="editable-hover">
        <slot></slot>
      </a>
    </template>
    <template v-if="actionsState == 'edit'">
      <y-form-group :error-strings="errors.type" class="mb-1">
        <multiselect
          v-model="value"
          :options="options"
          placeholder="Select One"
          :show-labels="false"
          :allow-empty="false"
          :loading="$apollo.loading"
          @input="submit"
        ></multiselect>
      </y-form-group>
      <editable-actions
        :submitting="isSubmitting"
        :state="actionsState"
        @edit="edit"
        @submit="submit"
        @cancel="cancel"
      ></editable-actions>
    </template>
  </span>
</template>

<script>
import EditableMixin from '@/components/legacy/Editables/EditableMixin.js'
import gql from 'graphql-tag'

export default {
  mixins: [EditableMixin],
  apollo: {
    advertiserAccount: {
      query: gql`
        query getAdvertiserAccount($id: ID!) {
          advertiserAccount(id: $id) {
            id
            duplicationHandler
          }
        }
      `,
      variables() {
        return {
          id: this.id
        }
      },
      result(result) {
        this.value = result.data.advertiserAccount.duplicationHandler
      },
      fetchPolicy: 'network-only',
      skip() {
        return this.actionsState != 'edit'
      }
    }
  },
  data() {
    return {
      mutation: gql`
        mutation UpdateAdvertiserAccount(
          $input: UpdateAdvertiserAccountInput!
        ) {
          updateAdvertiserAccount(input: $input) {
            id
            duplicationHandler
          }
        }
      `,
      options: [
        'ignore-duplicates',
        'allow-duplicates',
        'allow-duplicates-diff'
      ]
    }
  },
  computed: {
    mutationVariables() {
      return {
        input: {
          id: this.id,
          duplicationHandler: this.value
        }
      }
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
