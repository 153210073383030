var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "top"
    }
  }, [_c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Onboarding'
      }
    }
  }, [_c('YButton', {
    attrs: {
      "color": "pink"
    }
  }, [_vm._v(" Quick Onboarding")])], 1)], 1)])], 1), _c('Widget', {
    staticClass: "mb-2"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('LeadFilters')], 1)], 2)], 1), _vm.leads ? _c('YTable', {
    attrs: {
      "data": _vm.leads.data || [],
      "bottom-loading": _vm.$apolloData.queries.leads.loading,
      "row-classes": function (row) {
        return row.userDetails.followUpDue ? ['follow-up-row'] : [];
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', {
          staticStyle: {
            "padding-right": "0",
            "width": "20px"
          }
        }, [props.rowData.userDetails.followUpDue ? _c('span', [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: 'Follow Up Due',
            expression: "'Follow Up Due'"
          }],
          staticClass: "text-pink-500 rounded m-1",
          attrs: {
            "name": "exclamationThick",
            "size": 4
          }
        })], 1) : _vm._e()]), _c('td', {
          staticStyle: {
            "padding-left": "0.5rem"
          }
        }, [_c('div', {
          staticClass: "flex flex-row gap-1"
        }, [_c('div', {
          staticClass: "flex flex-col flex-grow"
        }, [_c('div', [_c('router-link', {
          staticClass: "link block",
          attrs: {
            "to": {
              name: 'Lead',
              params: {
                id: props.rowData.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.primaryContact.company ? props.rowData.primaryContact.company : props.rowData.name))])], 1), _c('div', {
          staticClass: "flex flex-row items-center text-xs text-gray-600 block"
        }, [_vm._v(" " + _vm._s(props.rowData.id) + " "), props.rowData.email ? _c('span', [_vm._v(" - " + _vm._s(props.rowData.email) + " ")]) : _vm._e()]), props.rowData.primaryContact.messenger ? _c('div', {
          staticClass: "flex items-center text-xs"
        }, [_c('Icon', {
          staticClass: "w-4 h-4 text-gray-600 mr-1",
          attrs: {
            "name": JSON.parse(props.rowData.primaryContact.messenger.toLowerCase()).app
          }
        }), _c('span', {
          staticClass: "text-gray-600"
        }, [_vm._v(" " + _vm._s(JSON.parse(props.rowData.primaryContact.messenger).handle) + " ")])], 1) : _vm._e()]), _c('div', {
          staticClass: "flex items-center"
        }, [_c('button', {
          staticClass: "hover:bg-gray-100 p-1 rounded",
          on: {
            "click": function ($event) {
              return _vm.openPriorityStatusModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.priorityFollowUp(props.rowData.userDetails.followUpAt),
            expression: "\n                  priorityFollowUp(props.rowData.userDetails.followUpAt)\n                "
          }],
          class: _vm.priorityIconColor(props.rowData.userDetails.priorityStatus),
          attrs: {
            "name": _vm.priorityIconType(props.rowData.userDetails.priorityStatus),
            "size": 6
          }
        })], 1)])])]), _c('td', [_c('div', _vm._l(props.rowData.tags.filter(function (item) {
          return item.type == 'leadType';
        }), function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-2"
          }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
        }), 1)]), _c('td', [props.rowData.accountManager ? _c('span', {
          staticClass: "text-gray-900 text-sm"
        }, [_vm._v(" " + _vm._s(props.rowData.accountManager.primaryContact.name.split(' ')[0]) + " ")]) : _vm._e()]), _c('td', [_c('div', {
          staticClass: "mt-1 text-xs text-gray-900"
        }, [_vm._v(" " + _vm._s(_vm._f("luxon")(props.rowData.createdAt)) + " ")])]), _c('td', {
          staticClass: "truncate",
          staticStyle: {
            "max-width": "10rem"
          }
        }, _vm._l(props.rowData.tags.filter(function (item) {
          return item.type == 'trafficType';
        }), function (tag) {
          return _c('ul', {
            key: tag.id,
            staticClass: "mr-1 my-1 text-xs text-gray-800"
          }, [_c('li', [_vm._v(_vm._s(tag.name))])]);
        }), 0), _c('td', {
          staticClass: "truncate",
          staticStyle: {
            "max-width": "10rem"
          }
        }, _vm._l(props.rowData.tags.filter(function (item) {
          return item.type == 'vertical';
        }), function (tag) {
          return _c('ul', {
            key: tag.id,
            staticClass: "mr-1 my-1 text-xs text-gray-800 capitalize"
          }, [_c('li', [_vm._v(_vm._s(tag.name))])]);
        }), 0), _c('td', [_c('div', {
          staticClass: "flex flex-row justify-end items-center"
        }, [props.rowData.commentsLead.length > 0 ? _c('div', {
          staticClass: "flex flex-row text-xs flex-grow text-left text-gray-800"
        }, [_c('UserAvatar', {
          staticClass: "mb-1 mr-2",
          attrs: {
            "height": "5",
            "id": props.rowData.commentsLead[0].commenter.id
          }
        }), _vm._v(" " + _vm._s(_vm._f("luxon")(props.rowData.commentsLead[0].createdAt)) + " ")], 1) : _vm._e()])]), _c('td', {
          staticClass: "text-center",
          staticStyle: {
            "width": "50px"
          }
        }, [_c('v-popover', {
          staticClass: "p-0",
          attrs: {
            "offset": "0"
          }
        }, [_c('button', {
          staticClass: "bg-white-600 hover:bg-gray-100 text-blue-600 font-bold p-1 rounded truncate"
        }, [_c('Icon', {
          staticClass: "w-6 h-6 text-blue-600",
          attrs: {
            "name": "dotsVertical"
          }
        })], 1), _c('template', {
          slot: "popover"
        }, [_c('ul', [_c('li', [_c('YButton', {
          on: {
            "click": function ($event) {
              return _vm.openConvertToActiveModal(props.rowData);
            }
          }
        }, [_vm._v("Convert to Active")])], 1)])])], 2)], 1)];
      }
    }], null, false, 4107253692)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th'), _c('th', [_vm._v("lead")]), _c('th'), _c('th', [_vm._v("manager")]), _c('th', [_c('OrderByItem', {
    staticClass: "text-right",
    attrs: {
      "column": "created_at",
      "order": "ASC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Created")])], 1), _c('th', [_vm._v("traffic")]), _c('th', [_vm._v("verticals")]), _c('th', [_vm._v("Last contact")]), _c('th', [_c('OrderByItem', {
    staticClass: "flex justify-end",
    attrs: {
      "column": "updated_at",
      "order": "ASC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("updated")])], 1)])], 2) : _vm._e(), _vm.leads ? _c('YPagination', {
    key: _vm.totalPages,
    attrs: {
      "data": _vm.leads.paginatorInfo
    },
    on: {
      "load-page": _vm.loadPage,
      "next-page": _vm.nextPage,
      "previous-page": _vm.previousPage,
      "per-page": _vm.perPageSelection
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }