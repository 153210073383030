<template>
  <div class="w-full">
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-1 mb-1">
      <YInput label="Campaign" v-model="filters.campaign.value" />
      <Superselect
        title="Verticals"
        v-model="filters.verticals.value"
        :modifiers="filters.verticals.modifiers"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['vertical'] },
            name: {
              value: [
                'general',
                'nutra',
                'sweepstakes',
                'vod',
                'casino',
                'sports betting'
              ]
            }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        title="Countries"
        v-model="filters.countries.value"
        placeholder="Select Countries"
        track-by="code"
        label="label"
        :options="[]"
        :query="COUNTRIES_QUERY"
        :query-variables="{
          first: 300
        }"
      ></Superselect>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-1">
      <Superselect
        title="Offer Type"
        v-model="filters.offerType.value"
        :custom-label="capitalizeAllWords"
        :options="offerTypes"
        :multiple="false"
      ></Superselect>
      <Superselect
        title="Status"
        v-model="filters.status.value"
        :custom-label="capitalize"
        :options="statusOptions"
      ></Superselect>
      <Superselect
        title="Payout Conversion Type"
        v-model="filters.payoutConversionType.value"
        placeholder="Select One"
        :modifiers="filters.payoutConversionType.modifiers"
        :multiple="true"
        track-by="id"
        label="label"
        :query="CONVERSION_TYPE_OPTIONS_QUERY"
        queryDefaultInputText=""
        :queryVariables="{
          filters: {
            name: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            },
            slug: {
              value: [
                'first-time-deposit',
                'qualified-first-time-deposit',
                'registration'
              ]
            }
          },
          first: 25
        }"
        :query-result-function="
          data => {
            let options = data.conversionTypes.data.map(value => ({
              id: value.slug,
              label: value.name
            }))
            option = [{ id: null, label: 'Default' }, ...options]
            return option
          }
        "
      ></Superselect>
      <!--<Superselect
        title="Conversion Type"
        v-model="filters.conversionType.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['campaign'] }
          },
          first: 100
        }"
      ></Superselect>-->
      <Superselect
        title="Allowed Traffic Types"
        v-model="filters.allowed.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['trafficType'] }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        title="Allowed OS Type"
        v-model="filters.campaignOS.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['campaignOS'] }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        title="Allowed Browser Type"
        v-model="filters.campaignBrowser.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['campaignBrowser'] }
          },
          first: 100
        }"
      ></Superselect>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import CONVERSION_TYPE_OPTIONS_QUERY from '@/graphql/ConversionType/ConversionTypeOptionsQuery.gql'

export default {
  components: {},
  data() {
    return {
      CONVERSION_TYPE_OPTIONS_QUERY,
      COUNTRIES_QUERY,
      TAGS_QUERY,
      verticals: [],
      devices: ['Mobile', 'Desktop'],
      statusOptions: [
        'subscribed',
        'available',
        'pending approval',
        'declined',
        'unsubscribed'
      ],
      offerTypes: ['smart link', 'single offer'],
      restrictions: [
        'pops',
        'domains',
        'display',
        'interstitials',
        'search',
        'email',
        'social',
        'push',
        'sms',
        'native',
        'incent',
        'adult',
        'viral'
      ],
      orderBy: {
        column: 'created_at',
        order: 'DESC',
        label: 'Newest'
      },
      orderByOptions: [
        {
          label: 'Newest',
          column: 'created_at',
          order: 'DESC'
        },
        {
          label: 'Oldest',
          column: 'created_at',
          order: 'ASC'
        }
      ]
    }
  },
  methods: {
    capitalize(value) {
      let output = value.split('')
      output[0] = output[0].toUpperCase()
      return output.join('')
    },
    capitalizeAllWords(value) {
      let output = []
      let words = value.split(' ')
      for (let word of words) {
        output.push(this.capitalize(word))
      }
      return output.join(' ')
    }
  },
  computed: {
    ...mapState({
      filters: state => state.publisherCampaign.filters
    })
  }
}
</script>

<style></style>
