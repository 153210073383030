<template>
  <Widget class="bg-red-600 text-white border-red-700">
    <WidgetHeader class="bg-red-600 text-white"
      ><span class="text-white">Are you sure?</span></WidgetHeader
    >
    <WidgetBody>
      <h5 class="text-yellow-400 font-bold">This action cannot be undone.</h5>
      <h5>
        Please confirm you'd like to
        <span class="text-yellow-400 font-bold">DELETE</span> the below Domains:
      </h5>
      <ul class="text-xs mt-4 mb-3">
        <li v-for="selection in selections" :key="selection.id">
          {{ selection.id }}
        </li>
      </ul>
    </WidgetBody>
    <WidgetFooter class="bg-dark-transparent-1 flex justify-between py-2 px-4">
      <YButton class="hover:bg-red-700 focus:bg-red-700" @click="$emit('close')"
        >Cancel</YButton
      >
      <YButton
        @click="deleteDomains(selections)"
        class="hover:bg-red-700 focus:bg-red-700"
        :is-loading="isSubmitting"
        >Delete Domains</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import DELETE_DOMAINS_MUTATION from '@/graphql/Domain/DeleteDomainsMutation.gql'

export default {
  props: {
    selections: {
      type: Array,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    deleteDomains(selections) {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: DELETE_DOMAINS_MUTATION,
          variables: {
            id: selections.map(selection => selection.id)
          }
        })
        .then(() => {
          this.$toastr.s('Domains Deleted', 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshYdnsDomains', true)
          this.$events.emit('domainsDeleted', true)
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.error(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
