<template>
  <div
    class="grid grid-cols-1 gap-3 m-auto bg-white rounded-b-xl mb-12 border-l border-r border-b shadow-md p-6"
    style="max-width: 24rem;"
  >
    <h4 class="font-bold text-base uppercase text-inverse-800">
      Create Your Password
    </h4>
    <template v-if="!successMessage">
      <YInput label="Email" v-model="email" :disabled="true" />
      <YInput label="New Password" v-model="password" type="password" />
      <YInput
        label="Confirm Password"
        v-model="confirmPassword"
        type="password"
      />
      <YButton
        class="w-full mt-6"
        color="pink"
        @click="submit"
        :is-loading="isSubmitting"
        >Submit</YButton
      >
    </template>
    <YAlert
      v-if="successMessage"
      color="green"
      class="mt-2"
      :allow-close="false"
    >
      {{ successMessage }}
      <router-link :to="{ name: 'Login' }" class="link mt-2 block"
        >Return to login.</router-link
      >
    </YAlert>
    <YAlert v-if="errorMessage" color="red" class="mt-2" :allow-close="false">
      {{ errorMessage }}
    </YAlert>
    <portal-target name="errors"></portal-target>

    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
      portal-to="errors"
      alert-class="text-xs"
      :icon-size="12"
      :icon="null"
      color="yellow"
    ></ValidationErrors>
  </div>
</template>

<script>
import CREATE_PASSWORD_MUTATION from '@/graphql/Auth/CreatePasswordMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
export default {
  mixins: [ValidationErrorsMixin],
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      email: this.$route.query.email,
      password: null,
      confirmPassword: null,
      successMessage: null,
      errorMessage: null,
      isSubmitting: false
    }
  },
  methods: {
    submit() {
      this.isSubmitting = true
      this.errorMessage = null
      this.successMessage = null
      this.clearValidationErrors()
      this.$apollo
        .mutate({
          mutation: CREATE_PASSWORD_MUTATION,
          variables: {
            input: {
              email: this.email,
              token: this.token,
              password: this.password,
              password_confirmation: this.confirmPassword
            }
          }
        })
        .then(({ data }) => {
          this.clearValidationErrors()
          this.isSubmitting = false
          if (data.createPassword.status == 'PASSWORD_UPDATED') {
            this.successMessage = 'Your password has been updated!'
          } else {
            this.errorMessage =
              'A problem occurred and the password was not updated.'
          }
          console.log(data)
          this.$router.push({ name: 'Password Successful' })
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    detectEnterKey(event) {
      if (event.code === 'Enter') {
        this.submit()
      }
    }
  },
  mounted() {
    window.addEventListener('keyup', this.detectEnterKey)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.detectEnterKey)
  }
}
</script>

<style></style>
