var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', [_vm._v("Create Notification")]), _c('WidgetBody', [_c('YInput', {
    staticClass: "mb-2",
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('Superselect', {
    staticClass: "mb-2",
    attrs: {
      "title": "Routes",
      "options": _vm.routeOptions || [],
      "track-by": "name",
      "label": "label"
    },
    model: {
      value: _vm.routes,
      callback: function ($$v) {
        _vm.routes = $$v;
      },
      expression: "routes"
    }
  }), _c('Superselect', {
    staticClass: "mb-2",
    attrs: {
      "title": "Users",
      "track-by": "id",
      "label": "label",
      "query": _vm.USER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      }
    },
    model: {
      value: _vm.users,
      callback: function ($$v) {
        _vm.users = $$v;
      },
      expression: "users"
    }
  }), _c('div', {
    staticClass: "flex"
  }, [_c('YInput', {
    staticClass: "mb-2 mr-2 w-1/2",
    attrs: {
      "type": "date",
      "dateFormat": "YYYY-MM-DD",
      "dateFormatted": "ll",
      "label": "Start Date"
    },
    model: {
      value: _vm.startDate,
      callback: function ($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  }), _c('YInput', {
    staticClass: "mb-2 w-1/2",
    attrs: {
      "type": "date",
      "dateFormat": "YYYY-MM-DD",
      "dateFormatted": "ll",
      "label": "Expiry Date"
    },
    model: {
      value: _vm.expiryDate,
      callback: function ($$v) {
        _vm.expiryDate = $$v;
      },
      expression: "expiryDate"
    }
  })], 1), _c('Superselect', {
    staticClass: "mb-2",
    attrs: {
      "title": "Color",
      "options": _vm.colorOptions || [],
      "multiple": false,
      "label": "label",
      "track-by": "value"
    },
    model: {
      value: _vm.color,
      callback: function ($$v) {
        _vm.color = $$v;
      },
      expression: "color"
    }
  }), _c('YTextarea', {
    attrs: {
      "label": "Message",
      "required": ""
    },
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "bg-gray-100 flex justify-end p-4 items-center"
  }, [_c('YButton', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.create,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_vm._v("Create Notification")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }