var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    class: _vm.classes,
    style: _vm.styles
  }, [_vm.actionsState == 'default' ? [_c('a', {
    staticClass: "editable-hover d-inline-block",
    on: {
      "click": _vm.edit
    }
  }, [_vm._t("default")], 2), _c('editable-actions', {
    attrs: {
      "state": _vm.actionsState,
      "edit-action-display-type": _vm.editActionDisplayType
    },
    on: {
      "edit": _vm.edit,
      "submit": _vm.submit,
      "cancel": _vm.cancel
    }
  })] : _vm._e(), _vm.actionsState == 'edit' ? [_c('y-form-group', {
    staticClass: "mb-1",
    attrs: {
      "error-strings": _vm.errors.name
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.cancel.apply(null, arguments);
      }],
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.value = $event.target.value;
      }
    }
  })]), _c('editable-actions', {
    attrs: {
      "submitting": _vm.isSubmitting,
      "state": _vm.actionsState
    },
    on: {
      "edit": _vm.edit,
      "submit": _vm.submit,
      "cancel": _vm.cancel
    }
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }