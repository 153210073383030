<template>
  <div
    class="flex flex-col w-3/4 m-auto justify-center text-sm bg-white px-8 pt-12 pb-16 rounded-lg border border-gray-100 shadow-xl text-center"
    style="max-width: 34rem"
  >
    <p class="text-3xl font-bold mb-1 ">
      Welcome to yTrack! 🚀
    </p>
    <p class="text-lg text-gray-950 font-light">
      You will be receiving an email to verify your email address. Please
      <span class="font-semibold"> check your inbox</span>.
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped></style>
