var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.autoAbyssedRedirects.data ? _c('YTable', {
    attrs: {
      "data": _vm.autoAbyssedRedirects.data || [],
      "bottom-loading": _vm.$apolloData.queries.autoAbyssedRedirects.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.redirect.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.label) + " ")]), _c('br'), _c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.redirect.user.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.user.label) + " ")])], 1), _c('td', [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Campaign',
              params: {
                id: props.rowData.redirect.campaign.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.campaign.label) + " ")])], 1), _c('td', [_vm._v(_vm._s(props.rowData.revenue))]), _c('td', [_vm._v(_vm._s(props.rowData.previousRevenue))]), _c('td', [_vm._v(_vm._s(props.rowData.visits))]), _c('td', [_vm._v(_vm._s(props.rowData.previousVisits))]), props.rowData.reset ? _c('td', [_vm._v(_vm._s(props.rowData.reset))]) : _c('td', [_vm._v("Indefinite")])];
      }
    }], null, false, 4177378417)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Redirect")]), _c('th', [_vm._v("Campaign")]), _c('th', [_vm._v("revenue")]), _c('th', [_vm._v("previous revenue")]), _c('th', [_vm._v("visits")]), _c('th', [_vm._v("previous visits")]), _c('th', [_vm._v("reset")])])], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }