<template>
  <Widget>
    <WidgetHeader @close="$emit('close')">
      Edit Nameserver Domain
    </WidgetHeader>
    <WidgetBody>
      <Superselect
        title="Nameserver Domain"
        v-model="nameserverDomain"
        :multiple="false"
        placeholder="Select"
        track-by="id"
        label="id"
        :options="nameserverDomainOptions || []"
        :loading="$apollo.queries.domainOptions.loading"
      ></Superselect>
    </WidgetBody>
    <WidgetFooter class="bg-gray-100 py-2 px-4 text-right">
      <YButton
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="updateNameserverDomain"
        @keydown.enter="updateNameserverDomain"
        >Update</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import UPDATE_DOMAIN_MUTATION from '@/graphql/Domain/UpdateDomainMutation.gql'
import DOMAIN_OPTIONS_QUERY from '@/graphql/Domain/DomainOptionsQuery.gql'
import DOMAIN_QUERY from '@/graphql/Domain/DomainQuery.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    domain: {
      type: Object,
      required: true
    }
  },
  components: {},
  apollo: {
    domain: {
      query: DOMAIN_QUERY,
      variables() {
        return {
          id: this.domain.nameserverId
        }
      },
      result({ data }) {
        this.nameserverDomain = { id: data.domain.id }
      }
    },
    domainOptions: {
      query: DOMAIN_OPTIONS_QUERY,
      variables() {
        return {
          first: 1000,
          filters: {
            type: {
              value: ['nameserver']
            }
          }
        }
      },
      result({ data }) {
        this.nameserverDomainOptions = data.domainOptions.data
      }
    }
  },
  data() {
    return {
      DOMAIN_OPTIONS_QUERY,
      isSubmitting: false,
      nameserverDomain: null,
      nameserverDomainOptions: []
    }
  },
  watch: {},
  computed: {},
  methods: {
    updateNameserverDomain() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: UPDATE_DOMAIN_MUTATION,
          variables: {
            input: [
              {
                id: this.domain.id,
                nameserverId: this.nameserverDomain
                  ? this.nameserverDomain.id
                  : null
              }
            ]
          }
        })
        .then(() => {
          this.isSubmitting = true
          this.clearValidationErrors()
          this.$events.emit('refreshYdnsDomains')
          this.$toastr.s('Nameserver Domain Updated', 'Success!')
          this.$emit('close')
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)

          console.log(error)
        })
    }
  },

  mounted() {},
  updated() {}
}
</script>
