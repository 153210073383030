<template>
  <div>
    <tabs persist-as="domainManager">
      <tab name="Domains" :active="true">
        <domains-table-wrapper></domains-table-wrapper>
      </tab>
      <!--       <tab name="IPs">
        Content
      </tab>-->
      <tab name="Providers">
        <providers></providers>
      </tab>
      <tab name="DNS">
        <dns></dns>
      </tab>
      <tab name="Tags">
        <tags></tags>
      </tab>
    </tabs>
  </div>
</template>

<script>
import DomainsTableWrapper from '@/components/legacy/Domainmanager/DomainsTableWrapper'
import Providers from '@/components/legacy/Domainmanager/Providers'
import Tags from '@/components/legacy/Domainmanager/Tags'
import Dns from '@/views/Admin/DomainManager/DnsTable'

export default {
  components: {
    DomainsTableWrapper,
    Providers,
    Tags,
    Dns
  },
  apollo: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {},
  updated() {}
}
</script>
<style></style>
