var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', {
    staticClass: "pt-5",
    attrs: {
      "loading": !_vm.offers.data
    }
  }, [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Pause Offers ")]), _vm.offers.data ? _c('widget-body', [_c('div', {
    staticClass: "flex flex-col shadow-md mb-4 rounded-lg"
  }, [_c('div', {
    staticClass: "flex items-center justify-center h-12 bg-red-600 text-white rounded-t-lg"
  }, [_c('Icon', {
    attrs: {
      "name": "alertCircleOutline",
      "size": "8"
    }
  })], 1), _c('div', {
    staticClass: "text-center text-lg pt-8"
  }, [_vm._v(" Are you sure you want to pause "), _c('span', {
    staticClass: "font-extrabold text-red-700"
  }, [_vm._v(_vm._s(this.offers.data.length))]), _vm._v(" offers? ")]), _c('div', {
    staticClass: "text-center space-x-10 py-6"
  }, [_c('YButton', {
    attrs: {
      "color": "gray"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": _vm.pauseOffers
    }
  }, [_vm._v("Pause Traffic")])], 1)]), _vm.offers.data ? _c('YTable', {
    attrs: {
      "data": _vm.offers.data
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(" " + _vm._s(props.rowData.id) + " ")]), _c('td', [_vm._v(" " + _vm._s(props.rowData.name) + " ")])];
      }
    }], null, false, 1289814875)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("id")]), _c('th', [_vm._v("Offer")])])], 2) : _vm._e()], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }