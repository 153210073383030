var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('Superselect', {
          staticStyle: {
            "min-width": "8rem"
          },
          attrs: {
            "options": _vm.campaignTypeOptions,
            "title": "Campaign Type",
            "multiple": false,
            "allow-empty": false
          },
          on: {
            "close": _vm.onCampaignTypeChange
          },
          model: {
            value: _vm.campaignType,
            callback: function ($$v) {
              _vm.campaignType = $$v;
            },
            expression: "campaignType"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v("Pixel ")]), _c('widget-body', [_c('form', {
    ref: "form",
    staticClass: "block",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col-1"
  }, [_c('small', [_vm._v("yTrack")])]), _c('textarea', {
    staticClass: "x-col-2",
    attrs: {
      "readonly": ""
    },
    domProps: {
      "value": _vm.yTrackMessage
    }
  })]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col-1"
  }, [_c('small', [_vm._v("Image")])]), _c('textarea', {
    staticClass: "x-col-2",
    attrs: {
      "readonly": ""
    },
    domProps: {
      "value": _vm.imageMessage
    }
  })]), _c('div', {
    staticClass: "x-row"
  }, [_c('div', {
    staticClass: "x-col-1"
  }, [_c('small', [_vm._v("Iframe")])]), _c('textarea', {
    staticClass: "x-col-2",
    attrs: {
      "readonly": ""
    },
    domProps: {
      "value": _vm.iframeMessage
    }
  })])])]), _c('WidgetFooter', {
    staticClass: "bg-gray-100 py-2 px-4 text-right"
  }, [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Close")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }