import { render, staticRenderFns } from "./CreatePasswordComplete.vue?vue&type=template&id=159b5006&scoped=true"
import script from "./CreatePasswordComplete.vue?vue&type=script&lang=js"
export * from "./CreatePasswordComplete.vue?vue&type=script&lang=js"
import style0 from "./CreatePasswordComplete.vue?vue&type=style&index=0&id=159b5006&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159b5006",
  null
  
)

export default component.exports