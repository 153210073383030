<template>
  <div class="w-full">
    <div class="grid grid-cols-1 sm:grid-cols-6 gap-1">
      <YInput v-model="filters.id.value" label="ID"></YInput>
      <YInput v-model="filters.subdomain.value" label="Subdomain"></YInput>
      <Superselect
        title="Domain"
        v-model="filters.domain.value"
        track-by="id"
        label="id"
        :modifiers="filters.domain.modifiers"
        :multiple="true"
        :options="ydnsDomains || []"
      ></Superselect>
      <Superselect
        title="Publisher"
        v-model="filters.user.value"
        :modifiers="filters.user.modifiers"
        :options="publisherOptions || []"
        placeholder="Select"
        track-by="id"
        label="label"
        :multiple="true"
        :close-on-select="false"
        :query="PUBLISHER_OPTIONS_QUERY"
        :query-variables="{
          first: 20,
          filters: {
            label: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            }
          }
        }"
        edit-placeholder="Paste Publishers"
      ></Superselect>

      <Superselect
        title="Campaign"
        v-model="filters.campaign.value"
        track-by="id"
        label="label"
        :modifiers="filters.campaign.modifiers"
        :multiple="true"
        :options="[]"
        :query="CAMPAIGN_OPTIONS_QUERY"
        :query-variables="{
          first: 20,
          filters: {
            label: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            }
          }
        }"
      ></Superselect>
      <Superselect
        title="Manager"
        v-model="filters.accountManager.value"
        :modifiers="filters.accountManager.modifiers"
        :multiple="true"
        track-by="id"
        label="label"
        :query="ACCOUNT_MANAGER_OPTIONS_QUERY"
        :query-variables="{
          filters: {
            label: {
              value: [],
              modifiers: { matchType: 'contains' }
            }
          },
          first: 25
        }"
      ></Superselect>
      <Superselect
        title="Status"
        v-model="filters.status.value"
        :modifiers="filters.status.modifiers"
        :multiple="true"
        :options="[
          'requested',
          'approved',
          'subscribed',
          'unsubscribed',
          'fraud'
        ]"
      ></Superselect>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { mapState } from 'vuex'
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import ACCOUNT_MANAGER_OPTIONS_QUERY from '@/graphql/AccountManager/AccountManagerOptionsQuery.gql'

export default {
  components: {},
  apollo: {
    ydnsDomains: {
      query: gql`
        query ydnsDomains($filters: YdnsDomainFilterInput) {
          ydnsDomains: ydnsDomains(filters: $filters) {
            id
          }
        }
      `,
      variables() {
        return {
          filters: {
            type: { value: 'redirect' }
          }
        }
      }
    }
  },
  data() {
    return {
      PUBLISHER_OPTIONS_QUERY,
      CAMPAIGN_OPTIONS_QUERY,
      ACCOUNT_MANAGER_OPTIONS_QUERY,
      orderBy: {
        column: 'created_at',
        order: 'DESC',
        label: 'Newest'
      },
      orderByOptions: [
        {
          label: 'Newest',
          column: 'created_at',
          order: 'DESC'
        },
        {
          label: 'Oldest',
          column: 'created_at',
          order: 'ASC'
        }
      ],
      publisherOptions: []
    }
  },
  methods: {},
  computed: {
    ...mapState({
      filters: state => state.redirect.filters
    })
  }
}
</script>

<style></style>
