var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ul', {
    staticClass: "nav-tabs overflow-x-auto",
    class: _vm.tabsClass,
    style: _vm.tabsStyle,
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.tabs, function (tab) {
    return _c('li', {
      key: tab.slug,
      staticClass: "nav-item"
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('a', {
      staticClass: "nav-link flex items-center",
      class: {
        active: tab.isActive
      },
      attrs: {
        "href": _vm.route ? `./${tab.slug}` : `#${tab.slug}`,
        "role": "tab",
        "aria-control": `${tab.name}`,
        "aria-expanded": "true",
        "disabled": tab.disabled ? 'disabled' : false
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.selectTab(tab);
        }
      }
    }, [tab.icon ? _c('Icon', {
      class: tab.iconClass,
      attrs: {
        "name": tab.icon
      }
    }) : _vm._e(), !tab.hideName ? _c('span', {
      domProps: {
        "innerHTML": _vm._s(tab.name)
      }
    }) : _vm._e(), tab.tag ? _c('Tag', {
      staticClass: "ml-2",
      attrs: {
        "color": tab.tagColor
      }
    }, [_vm._v(" " + _vm._s(tab.tag) + " ")]) : _vm._e()], 1), tab.$slots.aside ? [_c('vnodes', {
      attrs: {
        "vnodes": tab.$slots.aside
      }
    })] : _vm._e()], 2)]);
  }), this.$slots.topRight ? _c('li', {
    staticClass: "nav-item",
    staticStyle: {
      "margin-left": "auto"
    }
  }, [_vm._t("topRight")], 2) : _vm._e()], 2), _c('div', {
    staticClass: "tab-details h-full flex",
    class: _vm.tabDetailsClass
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }