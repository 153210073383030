var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "badge",
    class: _vm.classes
  }, [_vm._t("default", function () {
    return [_vm.icon ? _c('i', {
      class: ['zmdi', 'zmdi-' + _vm.icon, 'mr-1']
    }) : _vm._e(), _vm.text != '' ? _c('span', {
      domProps: {
        "textContent": _vm._s(_vm.text)
      }
    }) : _vm._e(), _vm.html ? _c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.html)
      }
    }) : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }