var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "py-6 border-b",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "pencil",
      "size": 4
    }
  }), [_vm._v(" Bulk Tags Edit ")]], 2)]), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('h5', {
    staticClass: "font-bold"
  }, [_vm._v("Selected Domains:")]), _c('ul', {
    staticClass: "text-xs mt-1 mb-3"
  }, _vm._l(_vm.domains, function (domain) {
    return _c('li', {
      key: domain.id
    }, [_vm._v(" " + _vm._s(domain.id) + " ")]);
  }), 0), _c('Superselect', {
    attrs: {
      "title": "Tags to Add",
      "options": _vm.tagOptions,
      "multiple": true,
      "track-by": "tagConfigId",
      "label": "tag"
    },
    model: {
      value: _vm.selectedTagsToCreate,
      callback: function ($$v) {
        _vm.selectedTagsToCreate = $$v;
      },
      expression: "selectedTagsToCreate"
    }
  }), _c('Superselect', {
    staticClass: "mt-2",
    attrs: {
      "title": "Tags to Delete",
      "options": _vm.tagOptions,
      "multiple": true,
      "track-by": "tagConfigId",
      "label": "tag"
    },
    model: {
      value: _vm.selectedTagsToDelete,
      callback: function ($$v) {
        _vm.selectedTagsToDelete = $$v;
      },
      expression: "selectedTagsToDelete"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-end items-center px-4 bg-gray-100"
  }, [_c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue"
    },
    on: {
      "click": _vm.updateDomain
    }
  }, [_vm._v("Update")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }