<template>
  <div>
    <button
      :class="clickableClass"
      class="flex justify-between w-full items-center"
      @click="toggleExpanded"
    >
      <span class="flex items-center">
        <slot name="title"> {{ title }}</slot>
      </span>
      <span><Icon :name="icon" :size="iconSize"/></span>
    </button>
    <div v-show="expanded" :class="contentClass" class="pt-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    clickableClass: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    },
    iconSize: {
      type: String,
      default: '5'
    },
    initialState: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expanded: this.initialState
    }
  },
  watch: {
    initialState(value) {
      this.expanded = value
    }
  },
  computed: {
    icon() {
      return this.expanded ? 'chevronUp' : 'chevronDown'
    }
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded
      if (this.expanded) {
        this.$emit('expanded')
      }
      if (!this.expanded) {
        this.$emit('collapsed')
      }
    }
  }
}
</script>

<style></style>
