<template>
  <div class="font-sans antialiased h-screen flex">
    <div
      class="flex-1 flex flex-col bg-white"
      style=" background-attachment: fixed;
        background-size: cover; 
        background-repeat: no-repeat;
        overflow-y: scroll;"
      :style="`background-image: url(${bgImage}); `"
    >
      <!-- topbar -->
      <div class="border-b w-full flex px-6 py-4 align-center items-center">
        <div class="w-1/2 flex">
          <portal-target name="content-top-bar-left">
            <h3 class="text-gray-800 font-extrabold flex">
              <portal-target name="content-top-bar-left-header-before">
              </portal-target>
              <portal-target name="content-top-bar-left-header">
                <!-- default text here -->
              </portal-target>
              <portal-target name="content-top-bar-left-header-after">
              </portal-target>
            </h3>
          </portal-target>
        </div>
        <div class="hidden md:flex w-1/2 justify-end">
          <div>
            <img class="" :src="logo" style="max-width: 9rem" />
          </div>
        </div>
      </div>
      <!-- content -->
      <div class="w-full flex flex-col py-6 px-4 sm:px-8 md:px-12">
        <div class="w-full m-auto">
          <slot><router-view /></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BgImage from '@/assets/images/backgrounds/bg-circles.svg'
import Logo from '@/assets/images/logo/logo_ytz.png'

export default {
  components: {},
  data() {
    return {
      logo: Logo,
      bgImage: BgImage
    }
  }
}
</script>

<style scoped></style>
