var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col w-3/4 m-auto justify-center text-sm bg-white px-8 pt-12 pb-16 rounded-lg border border-gray-100 shadow-xl text-center",
    staticStyle: {
      "max-width": "34rem"
    }
  }, [_c('p', {
    staticClass: "text-3xl font-bold mb-1"
  }, [_vm._v(" Welcome to yTrack! 🚀 ")]), _c('p', {
    staticClass: "text-lg text-gray-950 font-light"
  }, [_vm._v(" You will be receiving an email to verify your email address. Please "), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v(" check your inbox")]), _vm._v(". ")])]);

}]

export { render, staticRenderFns }