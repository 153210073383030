var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Create New Conversion Type ")]), _c('widget-body', [_c('form', {
    ref: "form",
    staticClass: "row",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('YInput', {
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.conversionType.name,
      callback: function ($$v) {
        _vm.$set(_vm.conversionType, "name", $$v);
      },
      expression: "conversionType.name"
    }
  })], 1)])])])]), _c('widget-footer', {
    staticClass: "bg-gray-100 py-2 px-4 text-right"
  }, [_c('YButton', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.create,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_vm._v("Create Conversion Type")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }