var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "box-width"
  }, [_c('div', {
    staticClass: "overflow-y-auto rounded cursor-pointer hover:shadow-md focus:shadow-md outline-none",
    staticStyle: {
      "max-height": "8rem"
    },
    attrs: {
      "tabindex": "0"
    },
    on: {
      "click": _vm.openPermissionsModal,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.openPermissionsModal.apply(null, arguments);
      }
    }
  }, [_vm.rowData.publisherAbilitySummary.all ? _c('Tag', {
    staticClass: "block py-2 rounded-none w-56",
    attrs: {
      "color": "gray",
      "shade": 100
    }
  }, [_vm._v(" All Publishers ")]) : _vm._e(), _vm._l(_vm.rowData.publisherAbilitySummary.only, function (only) {
    return _c('Tag', {
      key: only.id,
      staticClass: "block py-2 rounded-none",
      attrs: {
        "color": "blue",
        "shade": 100
      }
    }, [_vm._v(" " + _vm._s(only.label) + " ")]);
  }), _vm._l(_vm.rowData.publisherAbilitySummary.except, function (except) {
    return _c('Tag', {
      key: except.id,
      staticClass: "block py-2 rounded-none",
      attrs: {
        "color": "red",
        "shade": 100
      }
    }, [_vm._v(" " + _vm._s(except.label) + " ")]);
  })], 2), _c('div', {
    staticClass: "text-right"
  }, [_vm.rowData.publisherAbilitySummary.except.length ? _c('small', {
    staticClass: "font-semibold text-red-600"
  }, [_vm._v(_vm._s(_vm.rowData.publisherAbilitySummary.except.length) + " Excluded")]) : _vm._e(), _vm.rowData.publisherAbilitySummary.only.length ? _c('small', {
    staticClass: "font-semibold text-blue-600"
  }, [_vm._v(_vm._s(_vm.rowData.publisherAbilitySummary.only.length) + " Users")]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }