var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('YInput', {
    attrs: {
      "input-class": "border-r-none rounded-r-none",
      "label": "Source ID"
    },
    model: {
      value: _vm.sourceId,
      callback: function ($$v) {
        _vm.sourceId = $$v;
      },
      expression: "sourceId"
    }
  }), _c('YButton', {
    staticClass: "btn btn-blue text-white rounded-l-none",
    on: {
      "click": _vm.goToSource
    }
  }, [_vm._v("Go")])], 1)]), _c('admin-sources-table-wrapper')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }