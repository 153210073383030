<template>
  <div id="top">
    <portal to="top-bar-right">
      <div class="flex justify-right">
        <YButton class="btn btn-blue" @click="openCreateAdvertiserModal">
          New Advertiser
        </YButton>
      </div>
    </portal>
    <widget class="mb-2">
      <widget-header class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <AdvertiserFilters></AdvertiserFilters>
        </template>
      </widget-header>
    </widget>
    <YTable
      v-if="advertisers"
      :data="advertisers.data || []"
      :bottom-loading="$apolloData.queries.advertisers.loading"
      :row-classes="
        row => (row.userDetails.followUpDue ? ['follow-up-row'] : [])
      "
    >
      <template slot="header-row">
        <th></th>
        <th>Advertiser</th>
        <th>Payment Terms</th>
        <th>Manager</th>
      </template>
      <template slot="row" slot-scope="props">
        <td style="padding-right: 0; width: 20px;">
          <span v-if="props.rowData.userDetails.followUpDue">
            <Icon
              name="exclamationThick"
              class="text-pink-500 rounded m-1"
              :size="4"
              v-tooltip="'Follow Up Due'"
            />
          </span>
        </td>

        <td style="padding-left: 0.5rem;">
          <div class="flex flex-row gap-1">
            <div class="flex flex-col flex-grow">
              <div>
                <router-link
                  :to="{
                    name: 'Advertiser',
                    params: { id: props.rowData.advertiserId }
                  }"
                  class="link block"
                >
                  {{
                    props.rowData.primaryContact.name
                      ? props.rowData.primaryContact.name
                      : props.rowData.primaryContact.company
                  }}</router-link
                >
              </div>
              <div
                class="flex flex-row items-center text-xs text-gray-600 block"
              >
                {{ props.rowData.id }}
                <span v-if="props.rowData.email">
                  - {{ props.rowData.email }}
                </span>
              </div>
              <div
                v-if="props.rowData.primaryContact.messenger"
                class="flex items-center text-xs"
              >
                <Icon
                  :name="
                    JSON.parse(
                      props.rowData.primaryContact.messenger.toLowerCase()
                    ).app
                  "
                  class="w-4 h-4 text-gray-600 mr-1"
                />
                <span class="text-gray-600">
                  {{
                    JSON.parse(props.rowData.primaryContact.messenger).handle
                  }}
                </span>
              </div>
            </div>
            <div class="flex items-center">
              <button
                @click="openPriorityStatusModal(props.rowData)"
                class="hover:bg-gray-100 p-1 rounded"
              >
                <Icon
                  :name="
                    priorityIconType(props.rowData.userDetails.priorityStatus)
                  "
                  :size="6"
                  :class="
                    priorityIconColor(props.rowData.userDetails.priorityStatus)
                  "
                  v-tooltip="
                    priorityFollowUp(props.rowData.userDetails.followUpAt)
                  "
                />
              </button>
            </div>
          </div>
        </td>

        <td>
          <template v-if="props.rowData.billingContact">
            {{ props.rowData.billingContact.paymentTerms }} -
            {{ props.rowData.billingContact.paymentFrequency }}
          </template>
        </td>
        <td>
          <template v-if="props.rowData.accountManager">
            <router-link
              :to="{
                name: 'User',
                params: { id: props.rowData.accountManager.id }
              }"
              class="link"
            >
              {{
                props.rowData.accountManager.primaryContact.name.split(' ')[0]
              }}
            </router-link>
          </template>
        </td>
      </template>
      <template v-slot:after>
        <YPagination
          v-if="advertisers.paginatorInfo"
          :data="advertisers.paginatorInfo"
          @load-page="loadPage"
          @next-page="nextPage"
          @previous-page="previousPage"
          :key="totalPages"
          @per-page="perPageSelection"
        ></YPagination>
      </template>
    </YTable>
  </div>
</template>

<script>
import AdvertiserFilters from '@/views/Admin/Advertisers/AdvertiserFilters'
import CreateAdvertiser from '@/views/Admin/Advertisers/CreateAdvertiser.vue'
import ADVERTISERS_QUERY from '@/graphql/Advertiser/AdvertisersQuery.gql'
import { mapState, mapActions } from 'vuex'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'
import PriorityStyleMixin from '@/mixins/PriorityStyleMixin.js'
import PriorityStatusModal from '@/views/Admin/User/PriorityStatusModal.vue'

export default {
  mixins: [BackToTop, PriorityStyleMixin],
  components: {
    AdvertiserFilters,
    YPagination
  },
  apollo: {
    advertisers: {
      query: ADVERTISERS_QUERY,
      variables() {
        return {
          filters: {
            advertiserId: this.cleanFilter(this.filters.id),
            contacts: {
              name: this.cleanFilter(this.filters.name)
            },
            accountManagers: {
              id: this.cleanObjectFilter(this.filters.manager, 'id')
            },
            roles: {
              name: { value: 'advertiser' }
            }
          },
          orderBy: this.orderBy,
          page: this.currentPage,
          first: this.perPage
        }
      },
      result({ data }, key) {
        this.totalPages = data[key].paginatorInfo.lastPage
      },
      deep: true
    }
  },
  data() {
    return {
      advertisers: {},
      currentPage: 1,
      perPage: 20,
      totalPages: 0
    }
  },
  computed: {
    ...mapState({
      filters: state => state.advertiser.filters
    }),
    orderBy: {
      get() {
        return this.$store.state.advertiser.orderBy
      },
      set(value) {
        this.$store.state.advertiser.orderBy = JSON.parse(JSON.stringify(value))
      }
    }
  },
  mounted() {
    this.$events.listen('refreshAdvertisers', value => {
      console.log(value)
      this.$apollo.queries.advertisers.refetch()
    })
  },
  updated() {},
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  },
  methods: {
    openPriorityStatusModal(data) {
      this.$modal.show(
        PriorityStatusModal,
        { user: data },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    ...mapActions(['updateGlobalLoader']),
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }

      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    openCreateAdvertiserModal() {
      this.$modal.show(
        CreateAdvertiser,
        {},
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>
<style></style>
