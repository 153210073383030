var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-blue-100 rounded text-left block mr-2 truncate flex-grow",
    on: {
      "click": function ($event) {
        _vm.showIps = !_vm.showIps;
      }
    }
  }, [_c('span', {
    staticClass: "flex items-center"
  }, [_vm.showIps ? _c('Icon', {
    attrs: {
      "name": "menuDown",
      "size": 4
    }
  }) : _c('Icon', {
    attrs: {
      "name": "menuRight",
      "size": 4
    }
  }), _vm._v(" IPs ")], 1)]), _c('div', [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-blue-100",
    on: {
      "click": _vm.popCreateIpsModal
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Add IPs")])])], 1)], 1), _vm.showIps ? _c('div', {
    staticClass: "ts-1 mt-2 ip-list w-100",
    staticStyle: {
      "overflow-x": "scroll"
    }
  }, [_c('YTable', {
    staticClass: "w-100",
    attrs: {
      "data": _vm.ips,
      "selectable": true,
      "selectBy": "id",
      "selected": _vm.selectedIps
    },
    on: {
      "update:selected": function ($event) {
        _vm.selectedIps = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', {
          class: [props.rowData.enabled ? '' : 'disabled-column']
        }, [_c('div', {
          staticClass: "flex"
        }, [_c('Icon', {
          staticClass: "inline",
          class: _vm.ipIsUp(props.rowData.checks) ? 'text-green-500' : 'text-red-500',
          attrs: {
            "name": "circleMedium",
            "size": 5
          }
        }), _vm._v(" " + _vm._s(props.rowData.id) + " "), props.rowData.spamhausCheck && props.rowData.spamhausCheck.lastRunOutput ? _c('img', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `<div class='text-center'>Listed <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`,
            expression: "\n                `<div class='text-center'>Listed <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`\n              "
          }],
          staticClass: "h-3 ml-1",
          attrs: {
            "src": require("@/assets/images/logo/spamhaus-logo-red.png")
          }
        }) : props.rowData.spamhausCheck ? _c('img', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `<div class='text-center'>Unlisted <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`,
            expression: "\n                `<div class='text-center'>Unlisted <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`\n              "
          }],
          staticClass: "h-3 ml-1",
          staticStyle: {
            "margin-top": "3px"
          },
          attrs: {
            "src": require("@/assets/images/logo/spamhaus-logo.png")
          }
        }) : _vm._e(), props.rowData.spamhausCheck && props.rowData.spamhausCheck.enabled ? _c('span', [_vm._v(" * ")]) : _vm._e()], 1)]), _c('td', {
          class: [props.rowData.enabled ? '' : 'disabled-column'],
          staticStyle: {
            "text-align": "right"
          }
        }, [props.rowData.records && props.rowData.records.length > 0 ? _c('Tag', {
          staticClass: "mr-2",
          style: props.rowData.enabled ? '' : 'opacity: 0.4',
          attrs: {
            "color": props.rowData.enabled ? 'blue' : 'red'
          }
        }, [_vm._v(_vm._s(`${props.rowData.records.length} Records`))]) : _vm._e(), _vm.assignedPublishers(props.rowData.id).length > 0 ? _c('div', _vm._l(_vm.assignedPublishers(props.rowData.id), function (publisher) {
          return _c('Tag', {
            key: publisher.id,
            staticClass: "mr-2",
            attrs: {
              "color": "blue"
            }
          }, [_vm._v(" " + _vm._s(publisher.email) + " ")]);
        }), 1) : _vm._e()], 1), _c('td', {
          staticClass: "bg-gray-200",
          staticStyle: {
            "padding": "0"
          }
        }, [_c('v-popover', {
          class: ['sidebar-user-popover'],
          attrs: {
            "offset": "10",
            "placement": "left-start",
            "autoHide": true,
            "popoverClass": "",
            "popoverBaseClass": "popover-menu sidebar-popover-menu",
            "popoverInnerClass": "popover-menu-inner animated animated-speed1 fadeIn"
          }
        }, [_c('YButton', {
          staticClass: "btn-transparent btn-rounded tooltip-target"
        }, [_c('Icon', {
          attrs: {
            "name": "dotsVertical",
            "size": 4
          }
        })], 1), _c('template', {
          slot: "popover"
        }, [_c('div', {
          staticClass: "list-group"
        }, [props.rowData.enabled ? _c('SpamhausCheckPopover', {
          attrs: {
            "model": "Ip",
            "rowData": props.rowData
          }
        }) : _vm._e(), props.rowData.enabled ? _c('YButton', {
          staticClass: "list-group-item list-group-item-action",
          on: {
            "click": function ($event) {
              _vm.openEditPublishersModal(props.rowData, _vm.assignedPublishers(props.rowData.id));
            }
          }
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          staticClass: "mr-2",
          attrs: {
            "name": "accountMultipleOutline",
            "size": 4
          }
        }), _vm._v(" Edit Publishers ")], 1)]) : _vm._e(), props.rowData.enabled ? _c('YButton', {
          staticClass: "list-group-item list-group-item-action",
          on: {
            "click": function ($event) {
              return _vm.$refs.ipActions.openReplaceModal(props.rowData.id);
            }
          }
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          staticClass: "mr-2",
          attrs: {
            "name": "sync",
            "size": 4
          }
        }), _vm._v(" Replace ")], 1)]) : _vm._e(), props.rowData.enabled ? _c('YButton', {
          staticClass: "list-group-item list-group-item-action text-red-600 hover:bg-red-100",
          on: {
            "click": function ($event) {
              return _vm.$refs.ipActions.disable([props.rowData]);
            }
          }
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          staticClass: "mr-2",
          attrs: {
            "name": "cancel",
            "size": 4
          }
        }), _vm._v(" Disable ")], 1)]) : _c('YButton', {
          staticClass: "list-group-item list-group-item-action",
          on: {
            "click": function ($event) {
              return _vm.$refs.ipActions.enable([props.rowData]);
            }
          }
        }, [_c('span', {
          staticClass: "flex items-center"
        }, [_c('Icon', {
          staticClass: "mr-2",
          attrs: {
            "name": "power",
            "size": 4
          }
        }), _vm._v(" Enable ")], 1)])], 1)])], 2)], 1)];
      }
    }], null, false, 341642596)
  }, [_c('template', {
    slot: "colgroup"
  }, [_c('col'), _c('col')]), _c('template', {
    slot: "header-row"
  }, [_c('th'), _c('th'), _c('th', {
    staticStyle: {
      "text-align": "right",
      "padding": "0 !important",
      "width": "0.1%",
      "white-space": "nowrap"
    }
  }, [_c('ip-actions', {
    ref: "ipActions",
    attrs: {
      "ips": _vm.selectedIpObjects
    },
    on: {
      "delete": _vm.removeIps
    }
  })], 1)])], 2)], 1) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }