var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.campaign ? _c('div', {
    staticClass: "py-6"
  }, [_c('Superselect', {
    staticClass: "mb-2",
    attrs: {
      "title": "Prelander",
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "options": [],
      "hideSelected": true,
      "query": _vm.OFFERS_QUERY,
      "query-variables": {
        first: 10,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          destinationType: {
            value: ['template']
          },
          traffic: {
            campaignId: this.campaignId
          }
        }
      },
      "query-default-input-text": ''
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('YImage', {
          staticClass: "rounded mr-2",
          attrs: {
            "src": props.option.imageThumbnailUrl,
            "width": 12,
            "height": 12
          }
        }), _c('div', [_c('div', [_vm._v(_vm._s(props.option.label))]), _vm._l(props.option.advertisers, function (advertiser) {
          return _c('div', {
            key: advertiser.advertiserId,
            staticClass: "text-xxs"
          }, [_vm._v(" " + _vm._s(advertiser.primaryContact.name) + " ")]);
        })], 2)], 1)];
      }
    }], null, false, 2050035656),
    model: {
      value: _vm.prelander,
      callback: function ($$v) {
        _vm.prelander = $$v;
      },
      expression: "prelander"
    }
  }), _vm.hasChanges ? _c('YAlert', {
    staticClass: "mb-2",
    attrs: {
      "allowClose": false,
      "color": "blue"
    }
  }, [_c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('div', [_vm._v(" You have unsaved changes ")]), _c('div', [_c('YButton', {
    staticClass: "hover:bg-blue-300 mr-1",
    attrs: {
      "color": "link",
      "disabled": _vm.isSaving
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v("Reset")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSaving
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save")])], 1)])]) : _vm._e(), _vm.prelander ? _c('div', {
    staticClass: "x-offer group"
  }, [_c('div', {
    staticClass: "flex justify-between items-center hover:bg-blue-100 rounded"
  }, [_c('div', {
    staticClass: "flex items-center p-2"
  }, [_c('YImage', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: `Assign to Campaign`,
      expression: "`Assign to Campaign`"
    }],
    staticClass: "rounded mr-4",
    class: [_vm.prelander.imageThumbnailUrl ? 'hover:shadow-md cursor-pointer' : ''],
    attrs: {
      "src": _vm.prelander.imageThumbnailUrl,
      "width": 16,
      "height": 16
    },
    on: {
      "click": function ($event) {
        return _vm.openAddMediaFromUrlModal(_vm.prelander.imageUrl);
      }
    }
  }), _c('div', [_c('div', {
    staticClass: "mb-1"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.prelander.label) + " "), _vm.prelander.isCapped ? _c('Tag', {
    staticClass: "ml-1",
    attrs: {
      "color": "darkRed"
    }
  }, [_vm._v("CAPPED")]) : _vm._e()], 1), _vm._l(_vm.prelander.advertisers, function (advertiser) {
    return _c('div', {
      key: advertiser.advertiserId,
      staticClass: "text-xxs text-gray-600"
    }, [_vm._v(" " + _vm._s(advertiser.primaryContact.name) + " ")]);
  })], 2), _c('div', [_c('div', {
    staticClass: "text-xxs text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.prelander.encodedId) + " ")])])])], 1), _c('div', {
    staticClass: "flex-shrink flex items-center"
  }, [_c('button', {
    staticClass: "x-offer-remove text-blue-600 hover:text-blue-700 hover:bg-blue-200 p-2 mr-2 rounded group-hover:opacity-100 opacity-0 outline-none-important block",
    on: {
      "click": function ($event) {
        return _vm.clear();
      }
    }
  }, [_c('Icon', {
    staticClass: "block",
    attrs: {
      "name": "close"
    }
  })], 1)])])]) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }