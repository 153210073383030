<template>
  <Widget class="x-top-manager-widget overflow-x-hidden h-full">
    <WidgetHeader>
      Top Campaigns
      <router-link
        class="link text-xs"
        :to="{
          name: 'Publisher Campaigns'
        }"
      >
        VIEW CAMPAIGNS
      </router-link>
    </WidgetHeader>
    <WidgetBody :scrollable="true" class="pt-0 pb-0 relative">
      <div class="h-full w-full relative">
        <div
          v-if="$apollo.queries.topCampaigns.loading"
          class="h-full w-full flex items-center"
        >
          <Spinner :size="10" color="green" />
        </div>
        <div v-else class="x-campaigns">
          <div
            v-for="campaign in topCampaigns"
            :key="campaign.id"
            class="py-2 x-campaign px-4"
          >
            <router-link
              class="media"
              :to="{ name: 'Publisher Campaign', params: { id: campaign.id } }"
            >
              <div>
                <span class="link-wrap">{{ campaign.name }}</span>
                <div class="flex items-center py-2">
                  <div class="flex-shrink">
                    <small class="text-muted fw-500 pr-1">Top Geos:</small>
                  </div>
                  <div class="flex-grow">
                    <img
                      class="inline"
                      style="margin: 2px;"
                      v-for="geo in campaign.topGeos"
                      :key="geo.id"
                      v-tooltip="geo.code"
                      :src="getFlag(geo.code)"
                      width="16"
                    />
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </WidgetBody>
  </Widget>
</template>
<script>
import TOP_CAMPAIGNS_QUERY from '@/graphql/Campaign/TopCampaignsQuery.gql'

export default {
  apollo: {
    topCampaigns: {
      query: TOP_CAMPAIGNS_QUERY
    }
  },
  data() {
    return {
      topCampaigns: []
    }
  },
  computed: {},
  methods: {
    getFlag(country) {
      return require('@/assets/images/flags/svg/4x3/' +
        country.toLowerCase() +
        '.svg')
    }
  }
}
</script>
<style scoped>
.x-campaigns .x-campaign:nth-child(even) {
  @apply bg-gray-100;
}

.x-top-manager-widget::-webkit-scrollbar {
  display: none;
}
</style>
