<template>
  <form
    id="new-advertiser-adjustments-form"
    name="bulk-publisher-adjustments-form"
    @submit="submitForm"
    ref="form"
    @keydown="errors.clear($event.target.name)"
    @change="errors.clear($event.target.name)"
  >
    <div>
      <div class="flex w-full items-center justify-center mb-3">
        <label
          class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-600 rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-600 hover:text-white"
        >
          <svg
            class="w-8 h-8"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
            />
          </svg>
          <span v-if="csvFileName" class="mt-2 text-base leading-normal">{{
            csvFileName
          }}</span>
          <span v-else class="mt-2 text-base leading-normal uppercase"
            >Upload CSV</span
          >
          <input
            type="file"
            class="hidden"
            @change="onCsvChange"
            ref="bulkCsvInput"
          />
        </label>
      </div>
      <div class="text-center">
        <small
          ><a
            class="link inline-flex items-center"
            href="https://ytrack-documents.s3.amazonaws.com/adjustments-example.csv"
            ><Icon name="download" :size="4" class="mr-1" /> Download CSV
            Example</a
          ></small
        >
      </div>
    </div>
    <div class="flex justify-end">
      <YButton class="link mr-2" @click.prevent="clearForm">Clear CSV</YButton>
    </div>
    <!--
    <div>
      <div class="row pt-3">
        <div class="col-lg-5 col-md-6">
          <div class="row">
            <div class="col-md-12">
              <y-form-group label="Upload CSV">
                <input
                  @change="onCsvChange"
                  type="file"
                  data-buttonname="btn-outline btn-secondary"
                  data-iconname="zmdi zmdi-file-text mr-2"
                  class="filestyle"
                  id="filestyle-0"
                  tabindex="-1"
                  style="position: absolute; clip: rect(0px 0px 0px 0px);"
                  ref="bulkCsvInput"
                />
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    disabled=""
                    v-model="csvFileName"
                  />
                  <div class="input-group-append">
                    <label for="filestyle-0" class="btn btn-inverse">
                      <span
                        class="icon-span-filestyle zmdi zmdi-file-text mr-1"
                      ></span>
                      <span class="buttonText">Choose file</span>
                    </label>
                  </div>
                </div>
              </y-form-group>
              <small
                ><a href="/csv/adjustments-example.csv"
                  ><i class="zmdi zmdi-download mr-1"></i> Download CSV
                  Example</a
                ></small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <y-button class="btn-light text-link mr-2" @click.prevent="clearForm"
        >Reset</y-button
      >
      <y-button
        class="btn-primary"
        :is-loading="loading"
        @click.prevent="addToPreview"
        >Add to Preview</y-button
      >
    </div>-->
  </form>
</template>

<script>
import Papa from 'papaparse'
class Errors {
  constructor() {
    this.errors = {}
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0]
    }
  }

  record(errors) {
    this.errors = errors
  }

  clear(field) {
    delete this.errors[field]
  }
}

export default {
  components: {},
  props: ['loadingPreview'],
  data() {
    let initialData = {
      bulk: true,
      type: 'publisher',
      csvData: null,
      notes: null
    }

    return {
      data: this._.clone(initialData, true),
      initialData: this._.clone(initialData, true),
      csvFileName: null,
      errors: new Errors()
    }
  },
  computed: {
    loading() {
      return this.loadingPreview
    },
    adjustmentsData() {
      let rows = this.data.csvData
      let data = []
      if (rows) {
        var i = 0
        for (let row of rows) {
          data[i] = {}
          data[i].publisher = { id: row.PublisherID, label: row.PublisherID }
          data[i].advertiser = { id: row.AdvertiserID, label: row.AdvertiserID }
          data[i].campaign = row.CampaignID
            ? { id: row.CampaignID, label: row.CampaignID }
            : null
          data[i].revenue = row.Revenue
          data[i].payout = row.Payout
          i++
        }
      }
      return data
    }
  },
  methods: {
    addToPreview() {
      this.$events.fire('addAdjustmentToPreview', {
        data: this.adjustmentsData
      })
    },
    submitForm(event) {
      event.preventDefault()
      this.$events.fire('submitAdjustmentsForm', {
        form: this.$refs.form,
        data: this.data
      })
    },
    onCsvChange(event) {
      let files = event.target.files || event.dataTransfer.files
      if (files[0]) {
        var myReader = new FileReader()
        myReader.onload = e => {
          console.log(e)
          var csv = myReader.result

          var results = Papa.parse(csv, {
            header: true
          })
          this.data.csvData = results.data
          this.csvFileName = files[0].name
        }

        myReader.readAsText(files[0])
        this.$nextTick(() => {
          setTimeout(() => {
            this.addToPreview()
          }, 100)
        })
      }
    },
    catchErrors(errors) {
      if (errors.form == this.$refs.form) {
        this.errors.record(errors.errors)
      }
    },
    clearForm() {
      this.data = this._.clone(this.initialData, true)
      this.csvFileName = null
      this.$refs.bulkCsvInput.value = null
    }
  },
  mounted() {},

  updated() {}
}
</script>
<style scoped>
.form-group .multiselect {
  margin-bottom: 0.5rem;
}
.form-group .multiselect:last-child {
  margin-bottom: 0rem;
}

.widget-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.tiny-forms .input-group-btn .btn {
  line-height: 1.3;
}
</style>
