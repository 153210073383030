<template>
  <div v-bind:style="styleObject"></div>
</template>

<script>
import PDFObject from 'pdfobject'

export default {
  props: {
    height: {
      type: Number,
      default: 400
    },
    url: {
      type: String,
      required: true
    }
  },
  mounted: function() {
    PDFObject.embed(this.url, this.$el)
  },
  data: function() {
    var h = '400px'
    if (!this.height) {
      console.warn('Please specify viewer height, e.g. height="500px"')
    } else {
      h = this.height + 'px'
    }
    return {
      styleObject: {
        height: h
      }
    }
  },
  watch: {
    url: function(val) {
      var vm = this
      PDFObject.embed(val, vm.$el)
    }
  }
}
</script>
<style>
.pdfobject-container {
  width: 100%;
}
.pdfobject {
  border: 1px solid #666;
}
</style>
