<template>
  <Widget class="bg-red-600 text-white border-red-700">
    <WidgetHeader class="bg-red-600 text-white"
      ><span class="text-white">Are you sure?</span></WidgetHeader
    >
    <WidgetBody>
      <h5 class="text-yellow-400 font-bold">This action cannot be undone.</h5>
      <h5>
        Please confirm you'd like to
        <span class="text-yellow-400 font-bold">DELETE</span> the Account with
        the following details:
      </h5>
      <ul class="text-xs mt-4 mb-3">
        <li>ID: {{ id }}</li>
        <li>Name: {{ username }}</li>
      </ul>
    </WidgetBody>
    <WidgetFooter class="bg-dark-transparent-1 flex justify-between py-2 px-4">
      <YButton class="hover:bg-red-700 focus:bg-red-700" @click="$emit('close')"
        >Cancel</YButton
      >
      <YButton
        @click="update"
        class="hover:bg-red-700 focus:bg-red-700"
        :is-loading="isSubmitting"
        >Delete Account</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    username: {
      type: String,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    update() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteAdvertiserAccount($id: ID!) {
              deleteAdvertiserAccount(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: this.id
          }
        })
        .then(result => {
          //toastr.success('Account Deleted', 'Success!')
          console.log(result)
          this.isSubmitting = false
          this.$events.emit('refreshAdvertiserAccounts', true)
          this.$emit('close')
        })
        .catch(error => {
          //toastr.error(error, 'Oops!')
          console.log(error)
          this.isSubmitting = false
        })
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
