var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Set Auto Redirect ")]), _c('WidgetBody', {
    staticClass: "pt-0"
  }, [_c('Superselect', {
    staticClass: "mb-2",
    attrs: {
      "multiple": false,
      "title": "Auto Redirect",
      "options": ['Yes', 'No']
    },
    model: {
      value: _vm.autoRedirectStatus,
      callback: function ($$v) {
        _vm.autoRedirectStatus = $$v;
      },
      expression: "autoRedirectStatus"
    }
  }), _c('Superselect', {
    attrs: {
      "disabled": _vm.autoRedirectStatus !== 'Yes',
      "title": "Open Redirects",
      "options": _vm.autoRedirectOptions,
      "multiple": false
    },
    model: {
      value: _vm.selectedAutoRedirect,
      callback: function ($$v) {
        _vm.selectedAutoRedirect = $$v;
      },
      expression: "selectedAutoRedirect"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "bg-gray-100 px-4 py-2"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('YButton', {
    staticClass: "btn btn-link",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('YButton', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "disabled": !_vm.autoRedirectStatus
    },
    on: {
      "click": _vm.setAutoRedirect
    }
  }, [_vm._v(" Set Auto Redirect ")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }