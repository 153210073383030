var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.userInterfaceNotifications, function (userInterfaceNotification) {
    return _c('div', {
      key: userInterfaceNotification.id
    }, [_c('YAlert', {
      staticClass: "mt-0 mb-0 px-6",
      attrs: {
        "color": userInterfaceNotification.color
      }
    }, [_vm._v(" " + _vm._s(userInterfaceNotification.message) + " ")])], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }