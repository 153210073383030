<template>
  <div class="grid grid-cols-3 gap-2">
    <YButton
      :disabled="selections.length > 0 ? false : true"
      class="bg-blue-200 text-blue-700 mr-1 hover:bg-blue-300"
      @click="openBulkEditTagsModal(selections)"
    >
      Edit Tags
    </YButton>
    <YButton
      :disabled="selections.length > 0 ? false : true"
      class="mr-1"
      color="blue"
      @click="openBulkEditDomainStatusModal(selections)"
    >
      Edit Status
    </YButton>
    <YButton class="mr-1" color="blue" @click="openEditModal(selections)">
      Edit Records
    </YButton>
    <YButton
      class="bg-blue-200 text-blue-700 mr-1 hover:bg-blue-300"
      @click="openCreateModal"
    >
      Add Domains
    </YButton>
    <YButton
      :disabled="selections.length > 0 ? false : true"
      color="red"
      @click="openDeleteModal(selections)"
      class="p-0"
    >
      Delete Domains
    </YButton>
  </div>
</template>

<script>
import DomainsCreate from './DomainsCreate'
import DomainsDelete from './DomainsDelete'
import DomainsEdit from './DomainsEdit'
import DomainStatusEdit from './DomainStatusEdit'
import DomainBulkStatusEdit from './DomainBulkStatusEdit'
import DomainTagsEdit from './DomainTagsEdit'
import DomainBulkTagsEdit from './DomainBulkTagsEdit'
import EditModelPublisherPermissions from '@/views/Admin/Permissions/EditModelPublisherPermissions'
import YReplaceDomain from './YReplaceDomain'
import YReplace from './YReplace'
import gql from 'graphql-tag'

export default {
  components: {},
  props: {
    selections: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},

  updated() {},
  methods: {
    editPublishersOnSuccess() {
      this.$events.emit('refreshYdnsDomains')
    },
    openCreateModal() {
      this.$modal.show(
        DomainsCreate,
        {},
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openReplaceModal(domain) {
      this.$modal.show(
        YReplaceDomain,
        { domain: domain },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openEditModal(selections) {
      this.$modal.show(
        DomainsEdit,
        { selections: selections },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openDeleteModal(selections) {
      this.$modal.show(
        DomainsDelete,
        { selections: selections },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openEditDomainStatusModal(domain) {
      this.$modal.show(
        DomainStatusEdit,
        { domain: domain },
        {
          classes: ['overflow-visible-important'],
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openBulkEditDomainStatusModal(selections) {
      this.$modal.show(
        DomainBulkStatusEdit,
        { domains: selections },
        {
          classes: ['overflow-visible-important'],
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openEditTagsModal(domain) {
      this.$modal.show(
        DomainTagsEdit,
        { domain: domain },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openBulkEditTagsModal(selections) {
      this.$modal.show(
        DomainBulkTagsEdit,
        { domains: selections },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openEditPublishersModal({
      modelIds,
      modelClass,
      ability,
      all,
      only,
      except
    }) {
      this.$modal.show(
        EditModelPublisherPermissions,
        {
          modelIds,
          modelClass,
          ability,
          all,
          only,
          except,
          onSuccess: this.editPublishersOnSuccess
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openYreplaceModal() {
      this.$modal.show(
        YReplace,
        { replacementType: '', replacementValue: '' },
        {
          height: 'auto',
          scrollable: true
        }
      )
    },
    delete(domains) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteDomains($id: [ID!]!) {
              ydnsDeleteDomains(id: $id) {
                id
                records {
                  id
                }
              }
            }
          `,
          variables: {
            id: domains.map(domain => domain.id)
          }
        })
        .then(() => {
          this.$toastr.s('Domain Deleted', 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshYdnsDomains', true)
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.error(error, 'Oops!')
          this.isSubmitting = false
        })
    },
    restore(domains) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation RestoreDomains($id: [ID!]!) {
              ydnsRestoreDomains(id: $id) {
                id
                records {
                  id
                }
              }
            }
          `,
          variables: {
            id: domains.map(domain => domain.id)
          }
        })
        .then(() => {
          this.$toastr.s('Domain Restored', 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshYdnsDomains', true)
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.error(error, 'Oops!')
          this.isSubmitting = false
        })
    },
    hydrateWhoIsData(domainIds) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation hydrateWhoIsData($id: [ID!]!) {
              ydnsHydrateDomainsWithWhoIsData(id: $id) {
                id
                registrar
                expiry
              }
            }
          `,
          variables: {
            id: domainIds
          }
        })
        .then(() => {
          this.$toastr.s('Domains Hydrated with WhoIs', 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshYdnsDomains', true)
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  }
}
</script>
<style></style>
