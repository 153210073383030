var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "btn",
    class: [_vm.color ? 'btn-' + _vm.color : ''],
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "disabled": _vm.isDisabled
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click', $event);
      }
    }
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "top-0 left-0 absolute h-full w-full flex"
  }, [_c('Spinner', {
    staticClass: "w-8 h-8 m-auto spin-animation block"
  })], 1), _c('span', {
    class: _vm.slotClass
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }