export default {
  data() {
    return {
      pointer: 0,
      visibleElements: this.maxHeight / this.optionHeight
    }
  },
  props: {
    /**
     * Enable/disable highlighting of the pointed value.
     * @type {Boolean}
     * @default true
     */
    showPointer: {
      type: Boolean,
      default: true
    },
    optionHeight: {
      type: Number,
      default: 40
    }
  },
  computed: {
    pointerPosition() {
      return this.pointer * this.optionHeight
    }
  },
  watch: {
    filteredOptions() {
      this.pointerAdjust()
    }
  },
  methods: {
    optionHighlight(index, option) {
      return {
        'multiselect__option--highlight':
          index === this.pointer && this.showPointer,
        'multiselect__option--selected': this.isSelected(option)
      }
    },
    addPointerElement({ key } = 'Enter') {
      /* istanbul ignore else */
      if (this.filteredOptions.length > 0) {
        this.select(this.filteredOptions[this.pointer], key)
      }
      this.pointerReset()
    },
    pointerForward() {
      /* istanbul ignore else */
      if (this.pointer < this.filteredOptions.length - 1) {
        this.pointer++
        /* istanbul ignore next */
        if (
          this.$refs.list.scrollTop <=
          this.pointerPosition - this.visibleElements * this.optionHeight
        ) {
          this.$refs.list.scrollTop =
            this.pointerPosition -
            (this.visibleElements - 1) * this.optionHeight
        }
        /* istanbul ignore else */
        if (this.filteredOptions[this.pointer].$isLabel) this.pointerForward()
      }
    },
    pointerBackward() {
      if (this.pointer > 0) {
        this.pointer--
        /* istanbul ignore else */
        if (this.$refs.list.scrollTop >= this.pointerPosition) {
          this.$refs.list.scrollTop = this.pointerPosition
        }
        /* istanbul ignore else */
        if (this.filteredOptions[this.pointer].$isLabel) this.pointerBackward()
      } else {
        /* istanbul ignore else */
        if (this.filteredOptions[0].$isLabel) this.pointerForward()
      }
    },
    pointerReset() {
      /* istanbul ignore else */
      if (!this.closeOnSelect) return
      this.pointer = 0
      /* istanbul ignore else */
      if (this.$refs.list) {
        this.$refs.list.scrollTop = 0
      }
    },
    pointerAdjust() {
      /* istanbul ignore else */
      if (this.pointer >= this.filteredOptions.length - 1) {
        this.pointer = this.filteredOptions.length
          ? this.filteredOptions.length - 1
          : 0
      }
    },
    pointerSet(index) {
      this.pointer = index
    }
  }
}
