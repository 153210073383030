<template>
  <div
    class="widget"
    :class="backgroundClass"
    v-on:mouseenter="isHovered = true"
    v-on:mouseleave="isHovered = false"
    style="position: relative;"
  >
    <slot
      name="disabledOverlay"
      v-if="disabled"
      class="widget-disabled-overlay"
    >
    </slot>
    <Spinner
      v-if="loading"
      :size="12"
      color="blue"
      class="m-4 absolute"
      style="top: 0; right: 0;"
    />
    <div class="widget-inner flex flex-col h-full">
      <transition
        @before-enter="animateHoverActionsBeforeEnter"
        @enter="animateHoverActionsEnter"
        @leave="animateHoverActionsLeave"
        :css="false"
      >
        <div
          v-show="isHovered"
          v-if="actions != undefined && actions.type == 'horizontal-hover'"
          class="absolute m-3 actions animated"
          style="top: 0; right: 0;"
        >
          <transition-group
            @before-enter="animateHoverActionsBeforeEnter"
            @enter="animateHoverActionsEnter"
            @leave="animateHoverActionsLeave"
            :css="false"
          >
            <template v-show="isHovered" v-for="item in actions.actions">
              <button
                v-if="item.type == 'button'"
                class="btn btn-sm btn-dark-o ml-1"
                @click="item.onClick"
                :key="item.id"
              >
                <i
                  v-if="item.iconClass != undefined"
                  class="mr-2"
                  :class="item.iconClass"
                  style="width: 10px;"
                ></i>
                {{ item.text }}
              </button>
            </template>
          </transition-group>
        </div>
      </transition>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
  components: {},
  props: {
    background: {
      type: String,
      default: 'white'
    },
    actions: {},
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHovered: false
    }
  },
  computed: {
    backgroundClass: function() {
      let output = []
      let whiteTextWhen = [
        'primary',
        'secondary',
        'purple',
        'success',
        'info',
        'danger'
      ]

      if (this.background) {
        output.push('bg-' + this.background)
      }
      output.push(
        this._.indexOf(whiteTextWhen, this.background) !== -1
          ? 'text-white'
          : ''
      )
      output.push(this.loading ? 'is-loading' : '')
      output.push(this.disabled ? 'disabled' : '')
      return output
    }
  },
  mounted() {},

  updated() {},

  methods: {
    animateHoverActionsBeforeEnter(el, done) {
      this.$root.Velocity(
        el,
        {
          translateY: -20,
          opacity: 0
        },
        {
          duration: 100,
          easing: 'easeOutQuad',
          complete: done
        }
      )
    },
    animateHoverActionsEnter(el, done) {
      Velocity(
        el,
        {
          translateY: 0,
          opacity: 1
        },
        {
          duration: 100,
          easing: 'easeOutQuad',
          complete: done
        }
      )
    },
    animateHoverActionsLeave(el, done) {
      Velocity(
        el,
        {
          translateY: -20,
          opacity: 0
        },
        {
          duration: 100,
          easing: 'easeOutQuad',
          complete: done
        }
      )
    }
  }
}
</script>
<style>
.widget.disabled .widget-inner,
.widget.is-loading .widget-inner {
  filter: blur(1px);
  opacity: 0.1;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
  pointer-events: none;
}
.widget.is-loading .vuetable-loading {
  opacity: 1;
}

.widget .widget-disabled-overlay {
  position: absolute;
  top: 0;
}
</style>
