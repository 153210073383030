var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative y-input",
    class: [_vm.internalValue ? 'filled' : '']
  }, [_vm.required ? _c('span', {
    staticClass: "absolute right-0 pr-1 text-2xl text-red-500"
  }, [_vm._v("*")]) : _vm._e(), _vm.tooltip ? _c('Icon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip,
      expression: "tooltip"
    }],
    staticClass: "absolute right-0 bottom-0 mb-1 mr-1 text-gray-400 bg-white",
    attrs: {
      "name": "helpCircle",
      "size": 4
    }
  }) : _vm._e(), _c('ckeditor', {
    staticClass: "input bg-white",
    class: [_vm.inputClass],
    attrs: {
      "editor": _vm.editor,
      "config": _vm.config,
      "disabled": _vm.disabled || _vm.readonly
    },
    model: {
      value: _vm.internalValue,
      callback: function ($$v) {
        _vm.internalValue = $$v;
      },
      expression: "internalValue"
    }
  }), _c('label', {
    staticClass: "pointer-events-none label absolute mb-0 -mt-2 pt-4 pl-3 leading-tighter text-gray-400 text-base mt-2 cursor-text",
    attrs: {
      "for": _vm.label ? _vm.label.toLowerCase() : null
    }
  }, [_vm._v(_vm._s(_vm.label))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }