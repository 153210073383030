<template>
  <section>
    <div class="flex-grow max-w-full">
      <div class="flex w-full gap-8 2xl:gap-8">
        <div class="w-full flex flex-col gap-3 pt-4">
          <div class="grid grid-cols-9 gap-4">
            <div class="pt-2 col-span-3 flex flex-col gap-2">
              <p class="w-full font-bold text-lg mb-3 text-gray-900">
                English
              </p>
              <div
                v-for="(offerVar, index) in offerTranslationVars"
                :key="index"
              >
                <div v-if="inputFieldType(offerVar) == 'string'">
                  <YInput
                    disabled
                    :value="offerVars[offerVar]"
                    :label="offerVar"
                    class="w-full"
                  />
                </div>
                <div v-if="inputFieldType(offerVar) == 'textarea'">
                  <YTextarea
                    disabled
                    :value="offerVars[offerVar]"
                    :label="offerVar"
                    class="w-full"
                  />
                </div>
              </div>
            </div>

            <div
              class="bg-gray-100 rounded-lg py-2 px-4 col-span-6 flex flex-col"
            >
              <div
                class="w-full font-bold text-lg text-gray-900 flex justify-between mb-0.5"
              >
                <p>
                  Language:
                  <span class="uppercase">{{ selectedLanguage }}</span>
                </p>
              </div>
              <div class="flex text-xs text-gray-900">
                <span class="w-1/2 pl-1">Original LLM</span>
                <span class="w-1/2 pl-1">Manual Values</span>
              </div>
              <div
                v-for="translation in translationValues"
                :key="translation.id"
                class="grid grid-cols-2 gap-2"
              >
                <div class="flex flex-col gap-2">
                  <div v-for="(value, key) in translation.data" :key="key">
                    <div v-if="inputFieldType(key) == 'string'">
                      <YInput
                        :label="key + '_' + selectedLanguage"
                        :value="value"
                        disabled
                      />
                    </div>
                    <div v-if="inputFieldType(key) == 'textarea'">
                      <YTextarea
                        :label="key + '_' + selectedLanguage"
                        :value="value"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-2">
                  <div
                    v-for="(value, key) in existsManualTranslations"
                    :key="key"
                  >
                    <div v-if="inputFieldType(key) == 'string'">
                      <YInput
                        v-model="localTranslation[key]"
                        :placeholder="key"
                        :value="localTranslation[key]"
                        :label="'manual_' + key + '_' + selectedLanguage"
                      />
                    </div>
                    <div v-if="inputFieldType(key) == 'textarea'">
                      <YTextarea
                        v-model="localTranslation[key]"
                        :placeholder="key"
                        :value="localTranslation[key]"
                        :label="'manual_' + key + '_' + selectedLanguage"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex justify-end">
            <YButton
              v-if="isSubmitting"
              disabled
              color="blue"
              class="self-end mt-2 w-24"
              style="width: 14rem"
            >
              <Spinner color="white" :size="5" />
            </YButton>
            <YButton
              v-if="!isSubmitting"
              @click="saveManualTranslations"
              color="blue"
              class="self-end mt-2"
              style="width:14rem"
              :disabled="isSubmitting"
            >
              Save Manual Translations
            </YButton>
          </div>
        </div>

        <div class="flex-end pt-3 flex flex-col" style="width:14rem">
          <div
            class="w-full rounded-lg border-2 border-blue-500 p-1"
            style="height:fit-content"
          >
            <Superselect
              class="w-full"
              title="Available Translations"
              v-model="selectedLanguage"
              :options="selectedTranslatableLanguages"
              :multiple="false"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UPDATE_TRANSLATION from '@/graphql/Offer/UpdateTranslation.gql'

export default {
  props: {
    offerTranslations: {
      type: Array
    },
    offerId: {
      type: String
    },
    selectedTranslatableLanguages: {
      type: Array
    },
    offerVars: {
      type: Object
    },
    definitions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isSubmitting: false,
      selectedLanguage: null,
      localTranslation: {}
    }
  },
  created() {
    if (
      this.selectedTranslatableLanguages &&
      this.selectedTranslatableLanguages.length > 0
    ) {
      this.selectedLanguage = this.selectedTranslatableLanguages[0]
    }
    this.localTranslation = { ...this.existsManualTranslations }
  },
  computed: {
    existsManualTranslations() {
      if (this.offerTranslations) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )

        if (translation && Object.keys(translation.dataOverride).length > 0) {
          // if dataoverride is missing keys, populate them with empty inputs
          let llmValue = translation.data
          let manualValue = translation.dataOverride
          let mergedObject = { ...llmValue }

          for (let key in llmValue) {
            if (key in manualValue) {
              mergedObject[key] = manualValue[key]
            } else {
              mergedObject[key] = undefined
            }
          }
          return mergedObject
        } else {
          return Object.fromEntries(
            Object.keys(translation.data).map(key => [key, undefined])
          )
        }
      }
      return null
    },
    translationValues() {
      return this.offerTranslations
        .filter(translation => translation.language === this.selectedLanguage)
        .map(translation => {
          if (
            translation.data.description &&
            translation.data.description.value
          ) {
            return {
              ...translation,
              data: {
                ...translation.data,
                description: translation.data.description.value
              }
            }
          } else {
            return translation
          }
        })
    },
    offerTranslationVars() {
      const uniqueVars = new Set()
      this.offerTranslations.forEach(translation => {
        const dataKeys = Object.keys(translation.data)
        dataKeys.forEach(key => {
          uniqueVars.add(key)
        })
      })

      return Array.from(uniqueVars)
    },
    selectedTranslationId() {
      if (this.offerTranslations) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )
        return translation.id
      }
      return null
    }
  },
  updated() {},
  methods: {
    inputFieldType(value) {
      return this.definitions.find(item => item.key == value).type
    },
    saveManualTranslations() {
      let dataOverride = this.createLocalTranslationObject(
        this.localTranslation,
        this.translationValues[0].data
      )

      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_TRANSLATION,
          variables: {
            input: {
              id: this.selectedTranslationId,
              dataOverride: dataOverride
            }
          }
        })
        .then(() => {
          this.$toastr.s('Manual Changes Saved')
          this.$tabEvent.emit('refreshOffersTable')
          this.isSubmitting = false
        })
        .catch(error => {
          console.log(error)
          this.isSubmitting = false
          this.$toastr.e('Manual Changes not saved')
        })
    },
    createLocalTranslationObject(localValue, llmValue) {
      let differences = {}

      for (let key in localValue) {
        if (key === 'description') {
          differences[key] = { value: localValue[key] }
        } else if (localValue[key] !== llmValue[key]) {
          differences[key] = localValue[key]
        }
      }
      return differences
    }
  },
  watch: {
    selectedLanguage(newLanguage, oldLanguage) {
      if (newLanguage !== oldLanguage) {
        this.localTranslation = { ...this.existsManualTranslations }
      }
    }
  }
}
</script>
