var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "bg-red-600 border-red-700 text-white"
  }, [_c('WidgetHeader', {
    staticClass: "tiny-forms pt-4 pb-4",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "flex justify-between items-center w-full"
  }, [_c('div', {}, [_c('i', {
    staticClass: "zmdi zmdi-file-plus pr-2"
  }), _vm._v(" Delete Provider - " + _vm._s(this.provider.name) + " ")])])])], 2), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_vm.providerHasRecords ? [_c('h5', {
    staticClass: "font-bold",
    staticStyle: {
      "color": "#ffc107 !important"
    }
  }, [_vm._v(" ** PROVIDER CAN NOT BE DELETED ** ")]), _c('p', [_vm._v(" This provider has records associated with it and cannot be deleted."), _c('br'), _vm._v(" Please delete these records first. ")])] : [_c('h5', [_c('strong', {
    staticStyle: {
      "color": "#ffc107 !important"
    }
  }, [_vm._v("Are you sure? This action cannot be undone")])])]], 2), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 px-4 bg-red-700 text-white flex justify-between items-center"
  }, [_c('y-checkbox', {
    staticClass: "flex items-center",
    attrs: {
      "label": "Close on Complete",
      "type": "primary"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    staticClass: "bg-red-800 hover:bg-red-900 text-white",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "disabled": !_vm.ydnsProviders || _vm.providerHasRecords
    },
    on: {
      "click": function ($event) {
        return _vm.deleteItems([_vm.provider.id]);
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.deleteItems([_vm.provider.id]);
      }
    }
  }, [_vm._v(" Delete ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }