<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <div class="flex items-center">
        <Icon name="pencil" :size="4" class="mr-2"></Icon>
        <template> Edit {{ domain.id }} </template>
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <Superselect
        title="Tags"
        v-model="tags"
        :options="tagOptions"
        :multiple="true"
        track-by="tagConfigId"
        label="tag"
      >
      </Superselect>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <y-checkbox
        class="flex items-center"
        v-model="closeOnComplete"
        label="Close on Complete"
      ></y-checkbox>
      <YButton
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="updateDomain"
        >Update</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    domain: {
      type: Object,
      required: true
    }
  },
  components: {},
  apollo: {
    tagOptions: {
      query: gql`
        query getYdnsTagConfigs($filters: YdnsTagConfigsFilterInput) {
          tagOptions: ydnsTagConfigs(filters: $filters) {
            id
            tag
            color
          }
        }
      `,
      variables: {
        filters: {
          type: {
            value: ['domain']
          }
        }
      },
      result(result) {
        let output = result.data.tagOptions.map(option => ({
          tag: option.tag,
          tagConfigId: option.id
        }))

        this.tagOptions = output
      }
    }
  },
  data() {
    return {
      closeOnComplete: true,
      isSubmitting: false,
      originalTags: this.domain.tags,
      tags: this.domain.tags.map(tag => ({
        id: tag.id,
        tag: tag.tagConfig.tag,
        tagConfigId: tag.tagConfig.id
      })),
      tagOptions: []
    }
  },
  watch: {},
  computed: {
    tagsToCreate() {
      return this.tags
        .filter(tag => !tag.id)
        .map(tag => ({ tagConfig: { connect: tag.tagConfigId } })) // new tags won't have an id
    },
    tagsToDelete() {
      let idsNotToDelete = this.tags.map(tag => tag.id)
      return this.originalTags.filter(tag => !idsNotToDelete.includes(tag.id))
    }
  },
  mounted() {},
  methods: {
    updateDomain() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateDomainTags($input: [YdnsUpdateDomainInput!]!) {
              ydnsUpdateDomains(input: $input) {
                id
                registrar
                type
                tags {
                  id
                  tagConfig {
                    id
                    tag
                    color
                  }
                }
              }
            }
          `,
          variables: {
            input: [
              {
                id: this.domain.id,
                tags: {
                  create: this.tagsToCreate,
                  delete: this.tagsToDelete.map(tag => tag.id)
                }
              }
            ]
          }
        })
        .then(result => {
          this.$toastr.s('Domain Edited', 'Success!')
          this.errors = {}
          this.isSubmitting = false
          this.$emit('success', result)
          this.$events.emit('refreshYdnsDomains')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false

          if (Object.entries(this.errors).length <= 0) {
            this.$toastr.e(error, 'Oops!')
          }
        })
    }
  },
  close() {
    this.$emit('close')
  }
}
</script>
<style></style>
