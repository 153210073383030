var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.autoSubmit,
      expression: "!autoSubmit"
    }]
  }, [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Approve Redirect Subscription Request (" + _vm._s(_vm.id) + ") ")]), _c('WidgetBody', {
    staticClass: "pt-0"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.internalReason,
      expression: "internalReason"
    }],
    staticClass: "w-full p-4 bg-gray-100 rounded-md border border-gray-200",
    attrs: {
      "placeholder": "Write a reason..."
    },
    domProps: {
      "value": _vm.internalReason
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.internalReason = $event.target.value;
      }
    }
  })]), _c('WidgetFooter', {
    staticClass: "bg-gray-100 px-4 py-2"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('YButton', {
    staticClass: "btn btn-link",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('YButton', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.setStatus
    }
  }, [_vm._v(" Subscribe Publisher ")])], 1)]), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }