var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-sans antialiased h-screen w-full flex flex-wrap overflow-auto pt-8",
    style: `background-image: url(${_vm.bgImage}); background-size: cover; background-position: center;`
  }, [_c('div', {
    staticClass: "w-full flex flex-col m-auto p-4"
  }, [_c('div', {
    staticClass: "w-full m-auto",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_c('div', {
    staticClass: "pt-6 pb-2 bg-white rounded-t-xl border-t border-l border-r"
  }, [_c('img', {
    staticClass: "block m-auto w-full",
    staticStyle: {
      "max-width": "9rem"
    },
    attrs: {
      "src": _vm.logo
    }
  })])]), _c('div', {
    staticClass: "w-full m-auto"
  }, [_vm._t("default", function () {
    return [_c('router-view')];
  })], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }