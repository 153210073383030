import { render, staticRenderFns } from "./yTag.vue?vue&type=template&id=6b24c76a&scoped=true"
import script from "./yTag.vue?vue&type=script&lang=js"
export * from "./yTag.vue?vue&type=script&lang=js"
import style0 from "./yTag.vue?vue&type=style&index=0&id=6b24c76a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b24c76a",
  null
  
)

export default component.exports