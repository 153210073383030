<template>
  <div>
    <div class="row">
      <div class="col">
        <Widget class="tiny-forms">
          <WidgetHeader class="bg-opacity-gray">Add Adjustments</WidgetHeader>
          <WidgetBody class="pt-0">
            <Tabs tabsClass="pt-0 widget-edge mb-4 bg-opacity-gray">
              <Tab name="Single Adjustments" :active="true">
                <AdjustmentsFormPublisher
                  :loading-preview="loadingPreview"
                ></AdjustmentsFormPublisher>
              </Tab>
              <Tab name="Bulk Upload">
                <AdjustmentsFormBulkPublishers
                  :loading-preview="loadingPreview"
                ></AdjustmentsFormBulkPublishers>
              </Tab>
            </Tabs>
          </WidgetBody>
        </Widget>
        <AdjustmentsPreview
          :loadingPreview="loadingPreview"
        ></AdjustmentsPreview>
      </div>
    </div>
  </div>
</template>

<script>
// Adjustment Components
import AdjustmentsPreview from './AdjustmentsPreview'
import AdjustmentsFormPublisher from './AdjustmentsFormPublisher'
import AdjustmentsFormBulkPublishers from './AdjustmentsFormBulkPublishers'

export default {
  components: {
    AdjustmentsPreview,
    AdjustmentsFormPublisher,
    AdjustmentsFormBulkPublishers
  },
  data() {
    return {
      loadingPreview: false
    }
  },
  computed: {},
  methods: {
    enableLoadingPreview() {
      this.loadingPreview = true
    },
    disableLoadingPreview() {
      setTimeout(() => {
        this.loadingPreview = false
      }, 400)
    }
  },
  mounted() {
    this.$events.listen('submitAdjustmentsForm', eventData =>
      this.enableLoadingPreview(eventData)
    )
    this.$events.listen('disableAdjustmentsLoadingPreview', eventData =>
      this.disableLoadingPreview(eventData)
    )
  },
  beforeDestroy() {
    this.$events.remove('submitAdjustmentsForm')
    this.$events.remove('disableAdjustmentsLoadingPreview')
  },
  updated() {}
}
</script>
<style scoped>
.form-group .multiselect {
  margin-bottom: 0.5rem;
}
.form-group .multiselect:last-child {
  margin-bottom: 0rem;
}

.widget-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.tiny-forms .input-group-btn .btn {
  line-height: 1.3;
}
</style>
