<template>
  <widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <template slot="rawContent">
        <div
          class="flex justify-between items-center w-100 text-sm text-gray-800 font-extrabold"
        >
          <div class="flex items-center">
            <Icon name="pencil" :size="4" class="mr-1" />
            <template v-if="domains.length == 1">
              Edit {{ domains[0].id }}
            </template>
            <template v-else>
              Edit Domain{{ domains.length > 1 ? 's' : '' }}
            </template>
          </div>
        </div>
      </template>
    </WidgetHeader>

    <WidgetBody v-if="section == 'primary'">
      <div v-if="domains && domains.length > 0">
        <div v-if="domains.length == 1">
          <div class="flex items-center justify-between mb-1 mt-0 ">
            <div>
              <span class="mb-0 font-semibold">Add With Records</span>
            </div>
            <div>
              <YButton
                class="text-blue-600 hover:bg-gray-100"
                @click="
                  () => {
                    this.section = 'addRecords'
                  }
                "
              >
                Add IP Records
              </YButton>
              <YButton
                class="text-blue-600 hover:bg-gray-100"
                @click="addNewRecord"
              >
                Add Custom Record
              </YButton>
            </div>
          </div>
          <div>
            <div style="width: 100%; overflow-x: scroll;">
              <records-table-editable
                v-model="recordsToMutate"
                @removed="markRecordForDeletion"
              ></records-table-editable>
            </div>
          </div>
        </div>
        <div v-if="domains.length > 1">
          <YAlert
            :allowClose="false"
            v-if="recordSelections.length > 0"
            type="danger"
            color="red"
            class="-mt-2"
          >
            Warning! This will permanently delete ({{
              recordSelections.length
            }}) selected records.
          </YAlert>
          <YAlert v-if="multipleDomainTypesSelected" :allowClose="false" type="info" color="blue">
            Multiple domain types selected.
            <span class="fw-500">Some functionality has been disabled.</span>
          </YAlert>
          <div class="flex items-center justify-between mb-4 mt-4">
            <div>
              <h6 class="mb-0 font-bold">
                Replace {{ recordSelections.length }} record<template
                  v-if="recordSelections.length != 1"
                  >s</template
                >
                across {{ domains.length }} domains
              </h6>
            </div>
            <div>
              <YButton
                class="text-blue-600 hover:bg-gray-100 mr-1"
                @click="
                  () => {
                    this.section = 'addRecords'
                  }
                "
                :disabled="multipleDomainTypesSelected"
              >
                Add IP Records
              </YButton>
              <YButton
                class="text-blue-600 hover:bg-gray-100"
                @click="addNewRecord"
              >
                Add Custom Record
              </YButton>
            </div>
          </div>
          <records-table-editable
            class="w-full"
            v-model="recordsToCreate"
            @removed="markRecordForDeletion"
          ></records-table-editable>
        </div>
      </div>
      <div v-else class="text-center">
        <span class="text-muted">No Domains Selected</span>
      </div>
    </WidgetBody>
    <WidgetBody v-if="section == 'addRecords'" class="pt-5">
      <records-new-helper
        ref="recordsNewHelper"
        @update="
          records => {
            this.newIpRecords = records
          }
        "
        :domain-type="domainType"
      >
      </records-new-helper>
    </WidgetBody>
    <WidgetFooter
      v-if="section == 'primary'"
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <y-checkbox
        v-model="closeOnComplete"
        label="Close on Complete"
      ></y-checkbox>
      <YButton
        v-if="updateRecordsMode == 'replace'"
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="replaceRecords"
        @keydown.enter="replaceRecords"
        >Replace Records</YButton
      >
      <YButton
        v-else
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="saveRecords"
        @keydown.enter="saveRecords"
        >Save</YButton
      >
    </WidgetFooter>
    <WidgetFooter
      v-if="section == 'addRecords'"
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <YButton
        :is-loading="isSubmitting"
        class="text-blue-600 hover:bg-gray-200"
        @click="
          () => {
            this.section = 'primary'
          }
        "
        @keydown.enter="
          () => {
            this.section = 'primary'
          }
        "
        >Back</YButton
      >
      <div>
        <YButton
          @click="$refs.recordsNewHelper.clear()"
          class="text-blue-600 hover:bg-gray-200 mr-1"
          >Clear</YButton
        >
        <YButton
          :is-loading="isSubmitting"
          type="button"
          color="blue"
          @click="addIpRecords"
          @keydown.enter="addIpRecords"
          :disabled="
            !this.newIpRecords ||
              (this.newIpRecords && this.newIpRecords.length == 0)
          "
          >Add Records</YButton
        >
      </div>
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import RecordsTableEditable from './RecordsTableEditable'
import RecordsNewHelper from './RecordsNewHelper'
import { uuid } from 'vue-uuid'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    selections: {
      type: Array,
      required: true
    }
  },
  components: {
    RecordsTableEditable,
    RecordsNewHelper
  },
  data() {
    return {
      uuidHelper: uuid,
      closeOnComplete: true,
      isSubmitting: false,
      updateRecordsMode: this.selections.length <= 1 ? 'edit' : 'replace',
      domainType: this.selections[0] ? this.selections[0].type : '',
      recordsToCreate: [],
      recordsToUpdate: this.selections.flatMap(selection => selection.records),
      recordsToDelete: [],
      recordSelections: this.selections.flatMap(selection => selection.records),
      newIpRecords: [],
      section: 'primary',
      domains: this.selections
    }
  },
  watch: {},
  computed: {
    recordsToMutate: {
      get: function() {
        return [...this.recordsToCreate, ...this.recordsToUpdate]
      },
      set: function(newRecords) {
        newRecords.filter(record => !record.id)
        this.recordsToCreate = newRecords.filter(record => !record.id)
        this.recordsToUpdate = newRecords.filter(record => record.id)
      }
    },
    multipleDomainTypesSelected() {
      let domainTypes = this.domains.map(domain => domain.type)
      domainTypes = [...new Set(domainTypes)]
      return domainTypes.length > 1
    }
  },
  mounted() {},
  methods: {
    addIpRecords() {
      this.recordsToCreate = [...this.recordsToCreate, ...this.newIpRecords]
      this.$refs.recordsNewHelper.clear()
      this.section = 'primary'
    },
    saveRecords() {
      let editInput = [
        {
          id: this.domains[0].id,
          records: {
            create: this.recordsToCreate.map(record => ({
              type: record.type,
              subdomain: record.subdomain,
              content: record.content,
              ttl: record.ttl,
              priority: record.priority,
              disabled: record.disabled
            })),
            update: this.recordsToUpdate.map(record => ({
              id: record.id,
              type: record.type,
              subdomain: record.subdomain,
              content: record.content,
              ttl: record.ttl,
              priority: record.priority,
              disabled: record.disabled
            })),
            delete: this.recordsToDelete
              .map(record => record.id)
              .filter(record => record)
          }
        }
      ]

      let replaceInput = this.domains.map(domain => ({
        id: domain.id,
        records: {
          create: this.recordsToCreate.map(record => ({
            type: record.type,
            subdomain: record.subdomain,
            content: record.content,
            ttl: record.ttl,
            priority: record.priority,
            disabled: record.disabled
          })),
          delete: domain.records.map(record => record.id) // delete all original records
        }
      }))

      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation replaceRecords($input: [YdnsUpdateDomainInput!]!) {
              ydnsUpdateDomains(input: $input) {
                id
                registrar
                type
                records {
                  id
                  type
                  subdomain
                  ip {
                    id
                    server {
                      id
                      provider {
                        id
                        name
                      }
                    }
                  }
                  content
                  ttl
                  priority
                }
              }
            }
          `,
          variables: {
            input:
              this.updateRecordsMode == 'replace' ? replaceInput : editInput
          }
        })
        .then(result => {
          this.$toastr.s('Domain Edited', 'Success!')
          this.errors = {}
          this.isSubmitting = false
          this.$emit('success', result)
          this.$events.emit('refreshYdnsDomains')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
        })
    },
    replaceRecords() {
      this.saveRecords()
    },
    markRecordForDeletion(record) {
      this.recordsToDelete = [...this.recordsToDelete, record]
    },
    addNewRecord() {
      this.recordsToCreate = [
        {
          type: 'A',
          subdomain: null,
          content: null,
          ttl: 300,
          uniqueKey: this.uuidHelper.v4()
        },
        ...this.recordsToCreate
      ]
    }
  },
  close() {
    this.$emit('close')
  }
}
</script>
<style>
.publisher-postbacks-table-actions-header {
  width: 3rem;
}
.text-opacity-light {
  opacity: 0.7;
}
</style>
