let defaultFilters = {
  id: {
    value: null,
    modifiers: null,
    title: 'ID',
    type: 'input'
  },
  email: {
    value: null,
    modifiers: {
      matchType: 'contains'
    },
    title: 'Login',
    type: 'input'
  },
  contactEmail: {
    value: null,
    modifiers: {
      matchType: 'contains'
    },
    title: 'Email',
    type: 'input'
  },
  instantMessengerHandle: {
    value: null,
    modifiers: {
      matchType: 'contains'
    },
    title: 'IM Handle',
    type: 'input'
  },
  country: {
    value: null,
    title: 'Country',
    type: 'select',
    multiple: true,
    optionLabelKey: 'label',
    trackByKey: 'code'
  },
  role: {
    value: null,
    title: 'Role',
    type: 'select',
    multiple: false
  },
  referrer: {
    title: 'Referrer',
    type: 'select',
    multiple: true,
    optionLabelKey: 'label',
    trackByKey: 'id'
  },
  status: {
    value: null,
    title: 'Status',
    type: 'select',
    multiple: false
  },
  accountManager: {
    value: null,
    title: 'Account Manager',
    type: 'select',
    multiple: 'false',
    trackByKey: 'id'
  },
  trafficType: {
    value: null,
    title: 'Traffic Type Tags',
    type: 'select',
    multiple: true,
    trackByKey: 'name'
  },
  verticals: {
    value: null,
    title: 'Verticals',
    type: 'select',
    multiple: true,
    trackByKey: 'name'
  },
  referredOption: {
    value: null,
    title: 'Referred Type',
    type: 'select',
    modifiers: {
      matchType: 'contains'
    }
  },
  priorityStatus: {
    value: null,
    title: 'Priority Status',
    type: 'select',
    multiple: false
  },
  trackingPlatform: {
    value: null,
    title: 'Tracking Platform',
    type: 'select',
    multiple: true,
    trackByKey: 'name'
  },
  qualityMetrics: {
    value: null,
    title: 'Complaints',
    type: 'select',
    multiple: true,
    trackByKey: 'id'
  },
  ipHistory: {
    value: false,
    modifiers: {
      matchType: 'contains'
    }
  },
  autoRejected: {
    value: false,
    title: 'AutoRejected',
    modifiers: {
      matchType: 'contains'
    }
  },
  autoApproved: {
    value: false,
    title: 'AutoRejected',
    modifiers: {
      matchType: 'contains'
    }
  }
  // orderBy: {
  //   value: null,
  //   modifiers: null,
  //   title: "Order By",
  //   type: "select",
  //   optionLabelKey: "label",
  //   trackByKey: "id",
  //   multiple: false
  // }
}

export const namespaced = true

export const state = {
  filters: defaultFilters,
  orderBy: []
}

export const mutations = {
  RESET_FILTERS(state) {
    state.filters = defaultFilters
  }
}

export const actions = {
  resetFilters({ commit }) {
    commit('RESET_FILTERS')
  }
}
