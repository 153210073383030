<template>
  <div>
    <div class="flex items-center justify-left mt-4 mb-3">
      <tag-actions ref="tagActions"></tag-actions>
    </div>
    <YTable :data="tags">
      <template slot="header-row">
        <th>
          Type
        </th>
        <th>
          Tag
        </th>
        <th></th>
      </template>
      <template slot="row" slot-scope="props">
        <td>{{ props.rowData.type.capitalize() }}</td>
        <td>
          <Tag :color="props.rowData.color">{{ props.rowData.tag }}</Tag>
        </td>
        <td class="text-right">
          <YButton
            @click.prevent="$refs.tagActions.openEditModal(props.rowData)"
            class="mr-2 text-blue-600 hover:bg-blue-100"
            >Edit</YButton
          >
          <YConfirm
            @confirm="$refs.tagActions.delete([props.rowData])"
            :message="
              `You are about to delete the ${props.rowData.type} tag: ${props.rowData.tag}`
            "
          >
            <YButton @click.prevent class="text-red-600 hover:bg-red-100"
              >Delete</YButton
            >
          </YConfirm>
        </td>
      </template>
    </YTable>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import TagActions from './TagActions'

export default {
  components: {
    TagActions
  },
  props: {},
  apollo: {
    tags: {
      query: gql`
        query getYdnsTagConfigs($filters: YdnsTagConfigsFilterInput) {
          tags: ydnsTagConfigs(filters: $filters) {
            id
            type
            tag
            color
          }
        }
      `,
      variables() {
        return {}
      }
    }
  },
  data() {
    return {
      tags: []
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.$events.listen('refreshYdnsTags', () => {
      this.$apollo.queries.tags.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refreshYdnsTags')
  },
  updated() {},

  methods: {}
}
</script>

<style scoped></style>
