<template>
  <Widget>
    <WidgetBody class="p-3 p-md-4">
      <div class="flex items-center justify-between">
        <div class="flex items-center mb-3">
          <div class="mr-3">
            <h5 class="text-md font-bold text-inverse-800">
              {{ provider.name }}
            </h5>
          </div>
          <div>
            <Tag
              :color="provider.type == 'general' ? 'blue' : 'orange'"
              class="mr-1"
              :key="`${provider.type}`"
            >
              {{ provider.type.capitalize() }}
            </Tag>
          </div>
        </div>
        <div>
          <provider-actions
            ref="providerActions"
            :providers="[provider]"
          ></provider-actions>
        </div>
      </div>
      <slot></slot>
    </WidgetBody>
  </Widget>
</template>

<script>
import ProviderUpdate from './ProviderUpdate'
import ServerCreate from './ServerCreate'
import ProviderActions from './ProviderActions'

export default {
  components: {
    ProviderActions
  },
  props: {
    value: {
      type: Object
    },
    showServersByDefault: {
      type: Boolean,
      default: false
    }
  },
  apollo: {},
  data() {
    return {
      isLoading: false,
      showServers: this.showServersByDefault,
      provider: JSON.parse(JSON.stringify(this.value))
    }
  },
  watch: {
    showServersByDefault: function() {
      this.showServers = this.showServersByDefault
    },
    value: function(newValue) {
      this.provider = newValue
    },
    provider: {
      handler: function(newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    }
  },
  computed: {
    // serverTypes(){
    //   if(!this.provider.servers){
    //     return []
    //   }
    //   let types = this.provider.servers.map((server)=>{return server.type})
    //   types = [...new Set(types)].sort()
    //   return types
    // }
  },
  mounted() {},
  updated() {},

  methods: {
    popUpdateProviderModal() {
      this.$modal.show(
        ProviderUpdate,
        {
          value: this.provider
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 1000,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    popCreateServerModal() {
      this.$modal.show(
        ServerCreate,
        {
          provider: this.provider
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 1000,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  }
}
</script>

<style></style>
