export default {
  methods: {
    topLevelPayouts(offer) {
      if (offer.length == 1) {
        return '$' + parseFloat(offer[0].value).toFixed(2)
      } else {
        return this.findTopLevelWithMultiple(offer)
      }
    },

    findTopLevelWithMultiple(offers) {
      let results = offers.filter(function(offer) {
        return (
          !offer.campaign &&
          !offer.redirect &&
          !offer.source_id &&
          offer.account &&
          offer.account.yleadType !== 'lead'
        )
      })

      if (results.length === 1) {
        return '$' + parseFloat(results[0].value).toFixed(2)
      } else {
        return 'Multiple'
      }
    }
  }
}
