var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-popover', {
    staticClass: "flex justify-center",
    class: [''],
    attrs: {
      "offset": "10",
      "placement": "left-start",
      "autoHide": true,
      "popoverClass": "",
      "popoverBaseClass": "popover-menu sidebar-popover-menu",
      "popoverInnerClass": "popover-menu-inner animated animated-speed1 fadeIn"
    }
  }, [_c('domains-actions', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "domainsActions"
  }), _c('div', {
    staticClass: "inline-block tooltip-target"
  }, [_vm._t("default", function () {
    return [_c('YButton', {
      staticClass: "text-blue-600 hover:bg-blue-100 px-4"
    }, [_c('Icon', {
      attrs: {
        "name": "dotsVertical",
        "size": 4
      }
    })], 1)];
  })], 2), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "list-group"
  }, [_c('button', {
    staticClass: "btn-sm list-group-item list-group-item-action",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.enable(_vm.ips);
      }
    }
  }, [_c('span', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "zmdi zmdi-check mr-2"
  }), _vm._v(" Enable")])]), _c('button', {
    staticClass: "btn-sm list-group-item list-group-item-action",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.disable(_vm.ips);
      }
    }
  }, [_c('span', {
    staticClass: "text-warning"
  }, [_c('i', {
    staticClass: "zmdi zmdi-block mr-2"
  }), _vm._v(" Disable")])]), _c('YConfirm', {
    attrs: {
      "message": `Deleting ${_vm.ips.length} IPs`,
      "color": "red"
    },
    on: {
      "confirm": function ($event) {
        return _vm.deleteItems(_vm.ips);
      }
    }
  }, [_c('button', {
    staticClass: "btn-sm list-group-item list-group-item-action",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_c('i', {
    staticClass: "zmdi zmdi-delete mr-2"
  }), _vm._v(" Delete")])])]), _c('button', {
    staticClass: "btn-sm list-group-item list-group-item-action",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.openDomainEditModal(_vm.selectedDomainRecords);
      }
    }
  }, [_c('span', {
    staticClass: "text-primary"
  }, [_c('i', {
    staticClass: "zmdi zmdi-edit mr-2"
  }), _vm._v(" Edit Records")])])], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }