export const state = {
  globalLoader: 0,
  activeTabs: {}
}

export const getters = {
  activeTabs: state => state.activeTabs
}

export const mutations = {
  UPDATE_GLOBAL_LOADER(state, value) {
    state.globalLoader = state.globalLoader + value
  },
  UPDATE_ACTIVE_TABS(state, { persistAs, activeTabName }) {
    state.activeTabs[persistAs] = activeTabName
  }
}

export const actions = {
  updateGlobalLoader({ commit }, countModifier) {
    commit('UPDATE_GLOBAL_LOADER', countModifier)
  },
  updateActiveTabs({ commit }, { persistAs, activeTabName }) {
    commit('UPDATE_ACTIVE_TABS', {
      persistAs: persistAs,
      activeTabName: activeTabName
    })
  }
}
