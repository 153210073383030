<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <div class="flex items-center">
        <Icon name="pencil" :size="4" class="mr-2"></Icon>
        <template>
          Edit
          <span class="text-blue-500 mx-2">{{
            this.ability.toUpperCase()
          }}</span>
          Permissions for {{ this.modelClass.capitalize() }}(s)</template
        >
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <div class="mb-4">
        <div class="mb-1 font-bold">
          {{ this.modelClass.capitalize() }} IDs:
        </div>
        <div>
          <span v-for="id in modelIds" :key="id" class="mx-1">
            {{ id }}
          </span>
        </div>
      </div>
      <div>
        <div class="mb-1 font-bold">Publishers:</div>
        <div class="flex flex-col">
          <YCheckbox
            v-model="allPublishers"
            label="All Publishers"
            class="flex items-center"
          />
          <YCheckbox
            v-model="sendEmail"
            label="Send Domain Update Notification Email"
            class="flex items-center mb-2"
          />
          <Superselect
            v-show="allPublishers"
            title="Except"
            v-model="exceptPublishers"
            :modifiers="{}"
            :settings="{}"
            :options="[]"
            placeholder="Select"
            track-by="id"
            label="label"
            :close-on-select="false"
            :query="publisherOptionsQuery"
            :max-height="80"
            :query-variables="{
              first: 20,
              filters: {
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            edit-placeholder="Paste Publishers"
          ></Superselect>
          <Superselect
            v-show="!allPublishers"
            title="Only"
            no-result="No Users Found"
            v-model="onlyPublishers"
            :modifiers="{}"
            :settings="{}"
            :options="[]"
            placeholder="Select"
            track-by="id"
            label="label"
            :close-on-select="false"
            :query="publisherOptionsQuery"
            :max-height="80"
            :query-variables="{
              first: 20,
              filters: {
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            edit-placeholder="Paste Publishers"
          ></Superselect>
        </div>
      </div>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <y-checkbox
        class="flex items-center"
        v-model="closeOnComplete"
        label="Close on Complete"
      ></y-checkbox>
      <YButton
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="updatePermissions"
        >Update</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import SYNC_ABILITIES_MUTATION from '@/graphql/Permission/SyncAbilitiesMutation.gql'

export default {
  props: {
    modelIds: {
      type: Array,
      required: true
    },
    modelClass: {
      type: String,
      required: true
    },
    ability: {
      type: String,
      required: true
    },
    all: {
      type: Boolean,
      default: false
    },
    only: {
      type: Array,
      default: () => []
    },
    except: {
      type: Array,
      default: () => []
    },
    onSuccess: {
      type: Function
    }
  },
  components: {},
  data() {
    return {
      publisherOptionsQuery: PUBLISHER_OPTIONS_QUERY,
      role: 'publisher',
      allPublishers: this.all,
      onlyPublishers: this.only,
      exceptPublishers: this.except,
      closeOnComplete: true,
      isSubmitting: false,
      sendEmail: true
    }
  },
  watch: {},
  mounted() {},
  methods: {
    updatePermissions() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: SYNC_ABILITIES_MUTATION,
          variables: {
            input: {
              users: {
                all: this.allPublishers,
                only: !this.allPublishers
                  ? this.onlyPublishers.map(publisher => publisher.id)
                  : [],
                except: this.allPublishers
                  ? this.exceptPublishers.map(publisher => publisher.id)
                  : []
              },
              role: 'publisher',
              ability: this.ability,
              models: {
                ids: this.modelIds,
                class: this.modelClass
              },
              notify: this.sendEmail
            }
          }
        })
        .then(result => {
          this.$toastr.s('Permissions Edited', 'Success!')
          this.errors = {}
          this.isSubmitting = false
          this.$events.emit('refreshYdnsDomains')
          this.$emit('success', result)
          if (this.onSuccess) {
            this.onSuccess()
          }
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false

          if (Object.entries(this.errors).length <= 0) {
            this.$toastr.e(error, 'Oops!')
          }
        })
    }
  },
  close() {
    this.$emit('close')
  }
}
</script>
<style></style>
