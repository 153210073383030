var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "top"
    }
  }, [_c('widget', {
    staticClass: "mb-2"
  }, [_c('widget-header', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('RedirectFilters', {
    attrs: {
      "status": _vm.status
    }
  })], 1)], 2)], 1), _c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "flex justify-between mb-1"
  }, [_c('div'), _c('div', {
    staticClass: "flex items-center"
  }, [_c('YButton', {
    staticClass: "mr-1",
    attrs: {
      "color": "light-blue"
    },
    on: {
      "click": function ($event) {
        _vm.shouldAutoEmail = !_vm.shouldAutoEmail;
      }
    }
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('YCheckbox', {
    staticClass: "-ml-2 pointer-events-none",
    attrs: {
      "id": "auto-email"
    },
    model: {
      value: _vm.shouldAutoEmail,
      callback: function ($$v) {
        _vm.shouldAutoEmail = $$v;
      },
      expression: "shouldAutoEmail"
    }
  }), _c('div', [_vm._v(" Auto Email ")])], 1)]), _vm.showBulkRedirectActions ? [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        _vm.openStatusModal({
          modelName: 'Redirect',
          modelId: _vm.selectedRowIds,
          currentStatus: {
            name: 'subscribed'
          },
          enumName: 'RedirectStatusEnum',
          onSuccess: function (result) {
            if (_vm.shouldAutoEmail) {
              _vm.sendStatusUpdateEmails(_vm.selectedRowIds);
            }
          }
        });
      }
    }
  }, [_vm._v(" Update Statuses ")])] : _vm._e()], 2)]), _vm.redirects ? _c('YTable', {
    attrs: {
      "data": _vm.redirects.data || [],
      "bottom-loading": _vm.$apolloData.queries.redirects.loading,
      "selectable": true,
      "selectBy": "id",
      "selected": _vm.selectedRowIds
    },
    on: {
      "update:selected": function ($event) {
        _vm.selectedRowIds = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _props$rowData$user, _props$rowData$user2;
        return [_c('td', [props.rowData.id ? [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.id) + " ")])] : _vm._e()], 2), _c('td', [props.rowData.id ? [_c('div', {
          staticClass: "flex items-center gap-1"
        }, [_c('a', {
          staticClass: "link",
          attrs: {
            "href": 'https://' + _vm.offerLinks(props.rowData),
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(_vm.offerLinks(props.rowData)) + " ")])])] : _vm._e()], 2), _c('td', [props.rowData.campaign ? [_c('router-link', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: props.rowData.campaign.id,
            expression: "props.rowData.campaign.id"
          }],
          staticClass: "link-wrap",
          attrs: {
            "to": {
              name: 'Campaign',
              params: {
                id: props.rowData.campaign.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.campaign.name) + " ")])] : _vm._e()], 2), _c('td', [props.rowData.user ? [_c('router-link', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: props.rowData.user.id,
            expression: "props.rowData.user.id"
          }],
          staticClass: "link",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.user.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.user.email) + " ")])] : _vm._e()], 2), _c('td', [_c('div', {
          staticClass: "w-12"
        }, [props.rowData.user && props.rowData.user.accountManager ? [_c('span', {
          staticClass: "capitalize text-gray-800"
        }, [_vm._v(" " + _vm._s(props.rowData.user.accountManager.email.split('@')[0]))])] : _vm._e()], 2)]), _c('td', [((_props$rowData$user = props.rowData.user) === null || _props$rowData$user === void 0 ? void 0 : _props$rowData$user.status) == 'fraud' ? _c('div', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `User marked as fraud`,
            expression: "`User marked as fraud`"
          }]
        }, [_c('Tag', {
          attrs: {
            "color": "gray"
          }
        }, [_vm._v("unsubscribed "), _c('Icon', {
          staticClass: "inline-block",
          staticStyle: {
            "margin-top": "-4px"
          },
          attrs: {
            "name": "account",
            "size": "3",
            "color": "red"
          }
        })], 1)], 1) : _vm._e(), ((_props$rowData$user2 = props.rowData.user) === null || _props$rowData$user2 === void 0 ? void 0 : _props$rowData$user2.status) !== 'fraud' ? _c('div', {
          staticClass: "flex items-center"
        }, [_c('div', {
          staticClass: "mr-1 flex w-4"
        }, [!props.rowData.userTrafficTagsMatchCampaign ? _c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `No matching traffic tags`,
            expression: "`No matching traffic tags`"
          }],
          staticClass: "text-gray-400",
          attrs: {
            "name": "tagOff"
          }
        }) : _vm._e()], 1), props.rowData.statusInfo ? _c('Tag', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: props.rowData.statusInfo.reason,
            expression: "props.rowData.statusInfo.reason"
          }],
          key: `${props.rowData.id}:${props.rowData.statusInfo.name}`,
          on: {
            "click": function ($event) {
              _vm.openStatusModal({
                modelName: 'Redirect',
                modelId: parseInt(props.rowData.id),
                currentStatus: props.rowData.statusInfo,
                enumName: 'RedirectStatusEnum',
                onSuccess: function (result) {
                  if (_vm.shouldAutoEmail) {
                    _vm.sendStatusUpdateEmails([props.rowData.id]);
                  } else {
                    _vm.openSendRedirectStatusUpdateEmailModal(props.rowData.id);
                  }
                }
              });
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.statusInfo.name) + " ")]) : _vm._e(), props.rowData.status == 'requested' ? _c('div', {
          staticClass: "ml-1 flex"
        }, [_c('Tag', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Subscribe`,
            expression: "`Subscribe`"
          }],
          staticClass: "rounded-r-none",
          attrs: {
            "color": "green"
          },
          on: {
            "click": function ($event) {
              _vm.openApproveSubscriptionRequestModal({
                id: parseInt(props.rowData.id),
                reason: props.rowData.statusInfo.reason,
                onSuccess: function (result) {
                  if (_vm.shouldAutoEmail) {
                    _vm.sendStatusUpdateEmails([props.rowData.id]);
                  } else {
                    _vm.openSendRedirectStatusUpdateEmailModal(props.rowData.id);
                  }
                }
              });
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "check",
            "size": 3
          }
        })], 1), _c('Tag', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Decline`,
            expression: "`Decline`"
          }],
          staticClass: "rounded-l-none",
          attrs: {
            "color": "red"
          },
          on: {
            "click": function ($event) {
              _vm.openStatusModal({
                modelName: 'Redirect',
                modelId: parseInt(props.rowData.id),
                newStatus: {
                  name: 'declined'
                },
                currentStatus: props.rowData.statusInfo,
                onSuccess: function (result) {
                  if (_vm.shouldAutoEmail) {
                    _vm.sendStatusUpdateEmails([props.rowData.id]);
                  } else {
                    _vm.openSendRedirectStatusUpdateEmailModal(props.rowData.id);
                  }
                }
              });
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "close",
            "size": 3
          }
        })], 1)], 1) : _vm._e(), props.rowData.status == 'declined' ? _c('div', {
          staticClass: "ml-1 flex"
        }, [_c('Tag', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Restore`,
            expression: "`Restore`"
          }],
          attrs: {
            "color": "blue"
          },
          on: {
            "click": function ($event) {
              _vm.openStatusModal({
                modelName: 'Redirect',
                modelId: parseInt(props.rowData.id),
                newStatus: {
                  name: 'available'
                },
                currentStatus: props.rowData.statusInfo,
                onSuccess: function (result) {
                  if (_vm.shouldAutoEmail) {
                    _vm.sendStatusUpdateEmails([props.rowData.id]);
                  } else {
                    _vm.openSendRedirectStatusUpdateEmailModal(props.rowData.id);
                  }
                }
              });
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "refresh",
            "size": 3
          }
        })], 1)], 1) : _vm._e()], 1) : _vm._e()]), _c('td', [_c('button', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip.top",
            value: {
              content: props.rowData.isAbyssed ? 'Is Abyssed' : 'Active'
            },
            expression: "{\n              content: props.rowData.isAbyssed ? 'Is Abyssed' : 'Active'\n            }",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "mr-1 hover:bg-gray-200 p-1 rounded",
          on: {
            "click": function ($event) {
              return _vm.openActiveModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-2 h-2",
          class: props.rowData.isAbyssed ? 'text-red-600' : 'text-green-500',
          attrs: {
            "name": props.rowData.isAbyssed ? 'closeThick' : 'checkBold'
          }
        })], 1)])];
      }
    }, {
      key: "after",
      fn: function () {
        return [_vm.redirects.paginatorInfo ? _c('YPagination', {
          key: _vm.totalPages,
          attrs: {
            "data": _vm.redirects.paginatorInfo
          },
          on: {
            "load-page": _vm.loadPage,
            "next-page": _vm.nextPage,
            "previous-page": _vm.previousPage,
            "per-page": _vm.perPageSelection
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 3330184108)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_c('OrderByItem', {
    attrs: {
      "column": "id",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("ID")])], 1), _c('th', [_vm._v("Link")]), _c('th', [_vm._v("Campaign")]), _c('th', [_vm._v("Publisher")]), _c('th', [_vm._v("AM")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Active")])])], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }