<template>
  <div class="sticky-content-panel-container">
    <div
      class="sticky-content-panel-bg"
      @click="
        () => {
          this.$emit('close')
        }
      "
    ></div>
    <div class="sticky-content-panel">
      <widget class="h-full mb-0">
        <div class="sticky-content-header-container" ref="header-container">
          <slot name="header">
            <div class="sticky-content-header bg-white">
              <div class="flex items-center justify-between w-full px-4 py-4">
                <h4>{{ title }}</h4>
                <h4 slot="right" class="mb-0">
                  <close-x
                    @click="
                      () => {
                        this.$emit('close')
                      }
                    "
                  ></close-x>
                </h4>
              </div>
            </div>
          </slot>
        </div>
        <div
          class="sticky-content-body"
          :style="`padding-top: ${headerHeight}px`"
        >
          <widget-body class="bg-light px-4" ref="widget-body">
            <slot>
              <portal-target name="sticky-content-panel-body"></portal-target>
            </slot>
          </widget-body>
        </div>
        <ActionBar>
						<slot name="action-bar"></slot>
        </ActionBar>
      </widget>
    </div>
  </div>
</template>

<script>
import ActionBar from './ActionBar'
export default {
  components: {
    ActionBar
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      headerHeight: 20
    }
  },
  computed: {},
  mounted() {
    setTimeout(() => {
      this.headerHeight = this.$refs['header-container'].offsetHeight
    }, 1)
  },
  updated() {},
  methods: {}
}
</script>
<style scoped></style>
