export default class ValidationErrors {
  constructor() {
    this.errors = {}
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0]
    }
  }

  push(field, value) {
    this.errors[field] = [value]
  }

  record(errors) {
    this.errors = errors
  }

  clear(field) {
    delete this.errors[field]
  }

  clearAll() {
    this.errors = {}
  }

  extractErrorsFrom(error) {
    let graphQlErrors = error.graphQLErrors
    let validationErrors =
      graphQlErrors &&
      graphQlErrors[0] &&
      graphQlErrors[0].extensions.category == 'validation'
        ? graphQlErrors[0].extensions.validation
        : undefined

    if (validationErrors) {
      validationErrors = Object.entries(validationErrors)
        .map(keyValue => {
          let key = keyValue[0]
          let keyEnd = key.split('.').pop()
          let values = keyValue[1].map(value => {
            return value.replace(key, keyEnd)
          })
          return [keyEnd, values]
        })
        .reduce((object, [key, value]) => {
          object[key] = value
          return object
        }, {})
    }
    this.errors = {
      validation: validationErrors,
      extentions: graphQlErrors ? graphQlErrors[0].extensions : undefined,
      message: graphQlErrors ? graphQlErrors[0].message : undefined
    }
  }
}
