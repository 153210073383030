var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['y-table', _vm.scrollX ? '-mx-4 sm:-mx-8 px-4 sm:px-8 pb-0 overflow-hidden' : '']
  }, [_c('div', {
    staticClass: "block min-w-full shadow rounded-md overflow-x-auto",
    class: [_vm.loading ? 'x-loading' : ''],
    style: _vm.blockStyle
  }, [_c('table', {
    staticClass: "min-w-full leading-normal"
  }, [_c('colgroup', [_vm.selectable ? _c('col', {
    staticStyle: {
      "width": "25px"
    }
  }) : _vm._e(), _vm._t("colgroup", null, {
    "data": _vm.data
  })], 2), _c('thead', {
    class: _vm.headerClass
  }, [_vm._t("header", function () {
    return [_c('tr', [_vm.selectable ? _c('th', [!_vm.selectAllDisabled ? _c('input', {
      ref: "select-checkbox-global",
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "indeterminate": _vm.selected.length != 0 && _vm.selected.length != _vm.data.length
      },
      on: {
        "click": _vm.toggleSelectAll
      }
    }) : _vm._e()]) : _vm._e(), _vm._t("header-row", null, {
      "data": _vm.data
    })], 2)];
  }, {
    "data": _vm.data,
    "selected": _vm.selected
  }), _vm._t("secondHeader", function () {
    return [_vm.$slots.secondHeaderRow ? _c('tr', [_vm.selectable ? _c('th') : _vm._e(), _vm._t("secondHeaderRow")], 2) : _vm._e()];
  })], 2), _c('tbody', {
    class: _vm.bodyClass
  }, [_vm._t("beforeBody", function () {
    return [_vm.$slots.beforeBodyRow ? _c('tr', [_vm.selectable ? _c('td') : _vm._e(), _vm._t("beforeBodyRow")], 2) : _vm._e()];
  }), _vm._t("body", function () {
    return _vm._l(_vm.data, function (rowData, index) {
      return _c('tr', {
        key: rowData.id || rowData.uniqueKey,
        class: [_vm.selected.includes(rowData[_vm.selectBy]) ? 'row-selected' : '', rowData[_vm.deletedAtField] ? _vm.deletedAtClass : ''].concat(_vm.rowClasses(rowData))
      }, [_vm.selectable ? _c('td', [_vm.selectableRequirement(rowData) ? _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.selectedLocal,
          expression: "selectedLocal"
        }],
        staticClass: "select-checkbox",
        attrs: {
          "type": "checkbox",
          "id": `select-checkbox-${index}`
        },
        domProps: {
          "value": rowData[_vm.selectBy],
          "checked": Array.isArray(_vm.selectedLocal) ? _vm._i(_vm.selectedLocal, rowData[_vm.selectBy]) > -1 : _vm.selectedLocal
        },
        on: {
          "change": function ($event) {
            var $$a = _vm.selectedLocal,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;
            if (Array.isArray($$a)) {
              var $$v = rowData[_vm.selectBy],
                $$i = _vm._i($$a, $$v);
              if ($$el.checked) {
                $$i < 0 && (_vm.selectedLocal = $$a.concat([$$v]));
              } else {
                $$i > -1 && (_vm.selectedLocal = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.selectedLocal = $$c;
            }
          }
        }
      }) : _vm._e()]) : _vm._e(), _vm._t("row", null, {
        "rowData": rowData,
        "rowIndex": index
      })], 2);
    });
  }, {
    "data": _vm.data,
    "selected": _vm.selected
  })], 2), _vm.data.length == 0 && !_vm.isLoading ? _c('tfoot', {
    staticClass: "text-muted mt-2 text-center"
  }, [_c('tr', [_c('td', {
    staticClass: "font-italic py-4",
    attrs: {
      "colspan": "100%"
    }
  }, [_vm._v(_vm._s(_vm.emptyText))])])]) : _vm._e()])]), _vm._t("after", function () {
    return [_vm.bottomLoading ? _c('div', {
      staticClass: "w-100 py-8",
      class: [_vm.bottomLoading ? 'visibile' : 'invisible']
    }, [_c('Icon', {
      staticClass: "w-10 h-10 m-auto text-green-500 spin-animation block",
      attrs: {
        "name": "loading"
      }
    })], 1) : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }