<template>
  <div>
    <Superselect
      v-if="type == 'offer'"
      key="offer"
      :id="id"
      :title="title"
      v-model="inputValue"
      :options="[]"
      track-by="id"
      label="label"
      :multiple="false"
      no-result="Sorry, no offers found."
      :required="required"
      :tooltip="tooltip"
      :query="OFFER_OPTIONS_QUERY"
      :query-variables="{
        first: 10,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: { matchType: 'contains' }
          }
        }
      }"
    >
      <template v-slot:beforeControlIcons>
        <span class="text-xs link pt-2 pr-3" @click="toggleType">Offer</span>
      </template>
      <template v-slot:singleLabel="props">
        <div class="flex items-center">
          <YImage
            :src="props.option.imageThumbnailUrl"
            :width="6"
            :height="6"
            class="rounded mr-2"
          />
          <div>
            <div>{{ props.option.label }}</div>
            <div class="text-gray-700 text-xxs">
              {{ props.option.encodedId }}
            </div>
          </div>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="flex items-center -mx-2">
          <YImage
            :src="props.option.imageThumbnailUrl"
            :width="12"
            :height="12"
            class="rounded mr-2"
          />
          <div>
            <div>{{ props.option.label }}</div>
            <div class="text-gray-700 text-xxs">
              {{ props.option.encodedId }}
            </div>
          </div>
        </div>
      </template>
    </Superselect>
    <Superselect
      v-if="type == 'campaign'"
      key="campaign"
      :id="id"
      :title="title"
      v-model="inputValue"
      :options="[]"
      track-by="id"
      label="label"
      :multiple="false"
      no-result="Sorry, no campaigns found."
      :required="required"
      :tooltip="tooltip"
      :query="CAMPAIGN_OPTIONS_QUERY"
      :query-variables="{
        first: 10,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: { matchType: 'contains' }
          },
          isSmartLink: true
        }
      }"
    >
      <template v-slot:beforeControlIcons>
        <span class="text-xs link pt-2 pr-3" @click="toggleType">Campaign</span>
      </template>
      <template v-slot:singleLabel="props">
        <div class="flex items-center">
          <YImage
            :src="props.option.imageThumbnailUrl"
            :width="6"
            :height="6"
            class="rounded mr-2"
          />
          <div>
            <div>{{ props.option.label }}</div>
            <div class="text-gray-700 text-xxs">
              {{ props.option.encodedId }}
            </div>
          </div>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="flex items-center -mx-2">
          <YImage
            :src="props.option.imageThumbnailUrl"
            :width="12"
            :height="12"
            class="rounded mr-2"
          />
          <div>
            <div>{{ props.option.label }}</div>
            <div class="text-gray-700 text-xxs">
              {{ props.option.encodedId }}
            </div>
          </div>
        </div>
      </template>
    </Superselect>
    <Superselect
      v-if="type == 'feed'"
      key="feed"
      :id="id"
      :title="title"
      v-model="inputValue"
      :options="[]"
      track-by="id"
      label="label"
      :multiple="false"
      no-result="Sorry, no feeds found."
      :required="required"
      :tooltip="tooltip"
      :query="OFFER_FEEDS_QUERY"
      :query-variables="{
        first: 10,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: { matchType: 'contains' }
          },
          isSmartLink: true
        }
      }"
    >
      <template v-slot:singleLabel="props">
        <div class="flex items-center">
          <YImage
            :src="props.option.imageThumbnailUrl"
            :width="6"
            :height="6"
            class="rounded mr-2"
          />
          <div>
            <div>{{ props.option.label }}</div>
            <div class="text-gray-700 text-xxs">
              {{ props.option.encodedId }}
            </div>
          </div>
        </div>
      </template>
      <template slot="option" slot-scope="props">
        <div class="flex items-center -mx-2">
          <YImage
            :src="props.option.imageThumbnailUrl"
            :width="12"
            :height="12"
            class="rounded mr-2"
          />
          <div>
            <div>{{ props.option.label }}</div>
            <div class="text-gray-700 text-xxs">
              {{ props.option.encodedId }}
            </div>
          </div>
        </div>
      </template>
    </Superselect>
  </div>
</template>

<script>
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import OFFER_FEEDS_QUERY from '@/graphql/OfferFeed/OfferFeedsQuery.gql'

export default {
  props: {
    id: {},
    title: {},
    required: {},
    tooltip: {},
    value: {
      type: Object
    },
    types: {
      type: Array,
      default: () => ['offer', 'campaign']
    }
  },
  components: {},
  mounted() {},
  data() {
    return {
      OFFER_OPTIONS_QUERY,
      CAMPAIGN_OPTIONS_QUERY,
      OFFER_FEEDS_QUERY,
      type: this.types.includes('feed') ? 'feed' : 'offer'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue?.__typename == 'Offer') {
          this.type = 'offer'
        }

        if (newValue?.__typename == 'Campaign') {
          this.type = 'campaign'
        }
      }
    }
  },
  computed: {
    inputValue: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        this.updateValue(newValue)
      }
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    },
    toggleType() {
      if (this.type == 'offer') {
        this.type = 'campaign'
        return
      }

      this.type = 'offer'
    }
  }
}
</script>

<style scoped></style>
