var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('y-table', {
    staticClass: "w-100 table table-sm transparency-borders table-hover m-0",
    attrs: {
      "header-class": "tiny-table-header",
      "data": _vm.records,
      "emptyText": "No Records"
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (recordProps) {
        return [_c('td', {
          staticStyle: {
            "width": "20px"
          }
        }, [_c('content-editable', {
          model: {
            value: _vm.records[recordProps.rowIndex].type,
            callback: function ($$v) {
              _vm.$set(_vm.records[recordProps.rowIndex], "type", $$v);
            },
            expression: "records[recordProps.rowIndex].type"
          }
        })], 1), _c('td', {
          staticStyle: {
            "width": "75px"
          }
        }, [_c('content-editable', {
          model: {
            value: _vm.records[recordProps.rowIndex].subdomain,
            callback: function ($$v) {
              _vm.$set(_vm.records[recordProps.rowIndex], "subdomain", $$v);
            },
            expression: "records[recordProps.rowIndex].subdomain"
          }
        })], 1), _c('td', {
          staticStyle: {
            "width": "150px"
          }
        }, [recordProps.rowData.ip && recordProps.rowData.ip.server && recordProps.rowData.ip.server.provider ? [_vm._v(" " + _vm._s(recordProps.rowData.ip.server.provider.name) + " (" + _vm._s(recordProps.rowData.ip.server.id) + ") ")] : _vm._e()], 2), _c('td', {
          staticStyle: {
            "width": "250px"
          }
        }, [_c('content-editable', {
          staticStyle: {
            "max-width": "250px",
            "white-space": "normal"
          },
          model: {
            value: _vm.records[recordProps.rowIndex].content,
            callback: function ($$v) {
              _vm.$set(_vm.records[recordProps.rowIndex], "content", $$v);
            },
            expression: "records[recordProps.rowIndex].content"
          }
        })], 1), _c('td', {
          staticStyle: {
            "width": "20px"
          }
        }, [_c('content-editable', {
          model: {
            value: _vm.records[recordProps.rowIndex].ttl,
            callback: function ($$v) {
              _vm.$set(_vm.records[recordProps.rowIndex], "ttl", $$v);
            },
            expression: "records[recordProps.rowIndex].ttl"
          }
        })], 1), _c('td', [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.removeRecord(recordProps.rowData);
            }
          }
        }, [_vm._v("⛔")])])];
      }
    }])
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Type")]), _c('th', [_vm._v("Subdomain")]), _c('th', [_vm._v("Provider")]), _c('th', [_vm._v("Content")]), _c('th', [_vm._v("TTL")]), _c('th')])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }