<template>
  <div class="w-full">
    <div class="flex gap-2 p-1">
      <YInput v-model="filters.name.value" label="Name" class="w-1/2"></YInput>
      <Superselect
        title="Tags"
        v-model="filters.tags.value"
        placeholder="Tags"
        :options="filterOptions.verticals"
        class="w-1/2"
      ></Superselect>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'

export default {
  apollo: {
    verticalTags: {
      query: TAGS_QUERY,
      update: data => data.tags,
      variables() {
        return {
          first: 999,
          page: 1,
          filters: {
            type: {
              value: ['vertical']
            }
          }
        }
      },
      result: function(result) {
        this.filterOptions.verticals = result.data.tags.data.map(
          tag => tag.name
        )
      }
    }
  },
  data() {
    return {
      filterOptions: {
        verticals: []
      }
    }
  },
  methods: {},
  computed: {
    ...mapState({
      filters: state => state.images.filters
    })
  },
  mounted() {}
}
</script>

<style></style>
