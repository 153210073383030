var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "py-6 border-b",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "flex justify-between items-center w-100 text-sm text-gray-800 font-extrabold"
  }, [_c('div', {}, [_vm._v(" Add IPs to " + _vm._s(`${_vm.server.provider.name} - ${_vm.server.id}`) + " (" + _vm._s(_vm.server.provider.type.capitalize()) + ") ")])])])], 2), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('YFormGroup', {
    staticClass: "mb-0",
    attrs: {
      "error-strings": this.tooManyCidrIps ? [] : null
    }
  }, [_c('div', {
    staticClass: "font-semibold my-2"
  }, [_c('y-radio', {
    attrs: {
      "label": "IP CIDR",
      "name": "add-ips-mode",
      "radio-value": "cidr",
      "color": "info"
    },
    model: {
      value: _vm.addIpsMode,
      callback: function ($$v) {
        _vm.addIpsMode = $$v;
      },
      expression: "addIpsMode"
    }
  })], 1), _vm.addIpsMode == 'cidr' ? _c('YInput', {
    staticClass: "form-control ml-4 mb-2",
    attrs: {
      "label": "IP CIDR",
      "placeholder": "eg. 50.165.190.0/23"
    },
    model: {
      value: _vm.ipCidrInput,
      callback: function ($$v) {
        _vm.ipCidrInput = $$v;
      },
      expression: "ipCidrInput"
    }
  }) : _vm._e()], 1), _vm.addIpsMode == 'cidr' ? _c('div', {
    staticClass: "ml-4"
  }, [_vm.ipCidr ? _c('y-alert', {
    staticClass: "mb-2",
    attrs: {
      "type": "info",
      "allow-close": false
    }
  }, [_vm._v(" " + _vm._s(_vm.ipCidr.range[0]) + " - " + _vm._s(_vm.ipCidr.range[1]) + " "), _c('span', {
    class: _vm.tooManyCidrIps ? ['text-danger'] : ['text-success'],
    domProps: {
      "textContent": _vm._s(_vm.tooManyCidrIps ? '(Too Many IPs)' : '(' + _vm.ipCidr.array.length + ' IPs)')
    }
  })]) : _vm._e(), _c('y-checkbox', {
    attrs: {
      "label": "Add as disabled",
      "type": "danger"
    },
    model: {
      value: _vm.addAsDisabled,
      callback: function ($$v) {
        _vm.addAsDisabled = $$v;
      },
      expression: "addAsDisabled"
    }
  })], 1) : _vm._e()], 1), _c('YFormGroup', [_c('div', {
    staticClass: "font-semibold my-2"
  }, [_c('y-radio', {
    attrs: {
      "label": "Individual IPs",
      "name": "add-ips-mode",
      "radio-value": "singles",
      "color": "info"
    },
    model: {
      value: _vm.addIpsMode,
      callback: function ($$v) {
        _vm.addIpsMode = $$v;
      },
      expression: "addIpsMode"
    }
  })], 1), _vm.addIpsMode == 'singles' ? _c('Superselect', {
    staticClass: "ml-4 mt-1",
    attrs: {
      "title": "IPs",
      "hide-label": true,
      "multiple": true,
      "edit-placeholder": "",
      "forceEdit": true
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('YCheckbox', {
          staticClass: "mt-1",
          attrs: {
            "label": "Add as disabled",
            "type": "info"
          },
          model: {
            value: _vm.addAsDisabled,
            callback: function ($$v) {
              _vm.addAsDisabled = $$v;
            },
            expression: "addAsDisabled"
          }
        })];
      },
      proxy: true
    }], null, false, 75185732),
    model: {
      value: _vm.individualIpsInput,
      callback: function ($$v) {
        _vm.individualIpsInput = $$v;
      },
      expression: "individualIpsInput"
    }
  }) : _vm._e()], 1), _c('YCheckbox', {
    staticClass: "py-3",
    attrs: {
      "label": "Check Spamhaus Daily"
    },
    model: {
      value: _vm.checkSpamhausDaily,
      callback: function ($$v) {
        _vm.checkSpamhausDaily = $$v;
      },
      expression: "checkSpamhausDaily"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('y-checkbox', {
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "color": "blue",
      "disabled": !_vm.canAdd ? true : false
    },
    on: {
      "click": _vm.create,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_vm._v("Add")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }