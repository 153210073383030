<template>
  <li class="font-semibold text-sm list-none block cursor:pointer">
    <div
      class="flex items-center justify-between mb-2 cursor-pointer  pr-1 rounded-md hover:bg-inverse-700"
      v-if="parent.name !== 'Settings'"
      :class="
        parent.name == currentRoute ? ['bg-inverse-500'] : ['bg-inverse-900']
      "
    >
      <router-link
        :to="{ name: parent.name }"
        :target="parent.meta.newTable ? '_blank' : ''"
        class="w-10/12 py-2 pl-4 rounded-md"
      >
        {{ title }}
      </router-link>

      <div @click="toggleShow" class="hover:bg-inverse-500 rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          :class="{ 'rotate-90': showMenu }"
          class="dropdown-menu"
          viewBox="0 0 24 24"
        >
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path
            d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
            fill="#fffffe"
          />
        </svg>
      </div>
    </div>

    <div
      class="flex items-center justify-between mb-2 cursor-pointer  pr-1 rounded-md hover:bg-inverse-700 py-2 pl-4 rounded-md"
      v-if="parent.name == 'Settings'"
      @click="toggleShow"
    >
      {{ title }}

      <div class="hover:bg-inverse-600 rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          :class="{ 'rotate-90': showMenu }"
          class="dropdown-menu"
          viewBox="0 0 24 24"
        >
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path
            d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
            fill="#fffffe"
          />
        </svg>
      </div>
    </div>

    <ul
      class="ml-4 overflow-hidden dropdown-menu font-normal"
      :class="this.class"
    >
      <slot></slot>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    height: {
      type: String
    },
    parent: {
      type: Object
    },
    currentRoute: {
      type: String
    }
  },
  data() {
    return {
      showMenu: false
    }
  },

  methods: {
    toggleShow() {
      this.showMenu = !this.showMenu
    }
  },

  computed: {
    class() {
      return this.showMenu ? this.height : 'h-0'
    }
  }
}
</script>

<style scoped>
.dropdown-menu {
  transition: 300ms;
}

svg.rotate-90 {
  transform: rotate(90deg);
}
</style>
