var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('div', {
    staticClass: "flex justify-right"
  }, [_c('YButton', {
    staticClass: "btn btn-blue",
    on: {
      "click": _vm.openCreateModal
    }
  }, [_vm._v(" New Notification ")])], 1)]), _vm.notifications ? _c('YTable', {
    staticClass: "table-striped table-hover overflow-hidden",
    attrs: {
      "block-style": "min-height: 35rem;",
      "bottom-loading": _vm.$apolloData.queries.notifications.loading,
      "data": _vm.notifications
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(" " + _vm._s(props.rowData.name) + " ")]), _c('td', [_c('div', {
          staticClass: "w-56"
        }, [_vm._v(" " + _vm._s(props.rowData.routes && props.rowData.routes.map(function (route) {
          return route.replace('Publisher ', '');
        }).join(', ')) + " ")])]), props.rowData.users ? _c('td', _vm._l(props.rowData.users, function (user) {
          return _c('div', {
            key: user.id
          }, [_vm._v(" " + _vm._s(user.email) + " ")]);
        }), 0) : _vm._e(), _c('td', [_c('Tag', {
          attrs: {
            "color": props.rowData.color
          }
        }, [_vm._v(" " + _vm._s(props.rowData.color) + " ")])], 1), _c('td', [_vm._v(" " + _vm._s(_vm._f("luxon")(props.rowData.startDate)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("luxon")(props.rowData.expiryDate)) + " ")]), _c('td', [_c('div', {
          staticClass: "inline-flex items-center"
        }, [_c('a', {
          staticClass: "link",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openEditModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "pencil"
          }
        })], 1), _c('a', {
          staticClass: "ml-2 text-red-500 hover:text-red-600",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDeleteModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "trashCan"
          }
        })], 1)])])];
      }
    }], null, false, 4243920254)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Routes")]), _c('th', [_vm._v("Users")]), _c('th', [_vm._v("Color")]), _c('th', [_vm._v("Start Date")]), _c('th', [_vm._v("Expiry Date")]), _c('th')]), _c('template', {
    slot: "secondHeaderRow"
  }, [_c('td', [_c('YInput', {
    staticClass: "-mx-3",
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "mb-2",
    attrs: {
      "title": "Routes",
      "options": _vm.routeOptions || [],
      "track-by": "name",
      "label": "label"
    },
    model: {
      value: _vm.routes,
      callback: function ($$v) {
        _vm.routes = $$v;
      },
      expression: "routes"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Users",
      "label": "label",
      "track-by": "value",
      "query": _vm.USER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      }
    },
    model: {
      value: _vm.users,
      callback: function ($$v) {
        _vm.users = $$v;
      },
      expression: "users"
    }
  })], 1), _c('td'), _c('td'), _c('td'), _c('td')])], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }