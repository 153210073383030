var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "py-6 border-b",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "flex justify-between items-center w-100 text-sm text-gray-800 font-extrabold"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "mr-1",
    attrs: {
      "name": "filePlus",
      "size": 4
    }
  }), _vm._v("Add Domains ")], 1)])])], 2), _vm.section == 'primary' ? _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "Domains",
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "id",
      "label": "label"
    },
    model: {
      value: _vm.domainsSelect,
      callback: function ($$v) {
        _vm.domainsSelect = $$v;
      },
      expression: "domainsSelect"
    }
  }), _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "Status",
      "options": _vm.domainStatusOptions,
      "multiple": false
    },
    model: {
      value: _vm.domainStatus,
      callback: function ($$v) {
        _vm.domainStatus = $$v;
      },
      expression: "domainStatus"
    }
  }), _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "Type",
      "options": _vm.domainTypeOptions,
      "multiple": false
    },
    model: {
      value: _vm.domainType,
      callback: function ($$v) {
        _vm.domainType = $$v;
      },
      expression: "domainType"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Tags",
      "options": _vm.tagOptions,
      "id": "tagConfigId",
      "label": "tag",
      "multiple": true
    },
    model: {
      value: _vm.domainTags,
      callback: function ($$v) {
        _vm.domainTags = $$v;
      },
      expression: "domainTags"
    }
  }), _vm.domainType != 'nameserver' ? _c('div', [_c('YCheckbox', {
    staticClass: "py-3",
    attrs: {
      "label": "Check Spamhaus Daily"
    },
    model: {
      value: _vm.checkSpamhausDaily,
      callback: function ($$v) {
        _vm.checkSpamhausDaily = $$v;
      },
      expression: "checkSpamhausDaily"
    }
  }), _c('div', {
    staticClass: "flex items-center justify-between mb-1 mt-3"
  }, [_c('div', [_c('span', {
    staticClass: "mb-0 font-semibold"
  }, [_vm._v("Add With Records")])]), _c('div', [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-100",
    on: {
      "click": function () {
        _this.section = 'addRecords';
      }
    }
  }, [_vm._v("Add IP Records")]), _c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-100",
    on: {
      "click": function () {
        _this.records = [{
          type: 'A',
          subdomain: null,
          content: null,
          ttl: 300
        }].concat(_this.records);
      }
    }
  }, [_vm._v("Add Custom Record")])], 1)]), _c('records-table-editable', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.records && _vm.records.length > 0,
      expression: "records && records.length > 0"
    }],
    model: {
      value: _vm.records,
      callback: function ($$v) {
        _vm.records = $$v;
      },
      expression: "records"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.section == 'addRecords' ? _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('records-new-helper', {
    ref: "recordsNewHelper",
    attrs: {
      "domain-type": _vm.domainType
    },
    on: {
      "update": function (records) {
        _this.newRecords = records;
      }
    }
  })], 1) : _vm._e(), _vm.section == 'primary' ? _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('y-checkbox', {
    staticClass: "flex items-center",
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue",
      "disabled": !_vm.domains || _vm.domains && _vm.domains.length == 0
    },
    on: {
      "click": _vm.create,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_vm._v("Add Domains")])], 1) : _vm._e(), _vm.section == 'addRecords' ? _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-200",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button"
    },
    on: {
      "click": function () {
        _this.section = 'primary';
      },
      "keydown": function ($event) {
        var _this2 = this;
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return function () {
          _this2.section = 'primary';
        }.apply(null, arguments);
      }
    }
  }, [_vm._v("Back")]), _c('div', [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-200 mr-1",
    on: {
      "click": function ($event) {
        return _vm.$refs.recordsNewHelper.clear();
      }
    }
  }, [_vm._v("Clear")]), _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue",
      "disabled": !this.newRecords || this.newRecords && this.newRecords.length == 0
    },
    on: {
      "click": _vm.addRecords,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addRecords.apply(null, arguments);
      }
    }
  }, [_vm._v("Add Records")])], 1)], 1) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }