<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <template slot="rawContent">
        <div
          class="flex justify-between items-center w-100 text-sm text-gray-800 font-extrabold"
        >
          <div class="flex items-center">
            <Icon name="filePlus" :size="4" class="mr-1" />Add Domains
          </div>
        </div>
      </template>
    </WidgetHeader>
    <WidgetBody v-if="section == 'primary'" class="pt-5">
      <Superselect
        title="Domains"
        v-model="domainsSelect"
        editPlaceholder
        :forceEdit="true"
        track-by="id"
        label="label"
        class="mb-1"
      ></Superselect>

      <Superselect
        title="Status"
        v-model="domainStatus"
        :options="domainStatusOptions"
        :multiple="false"
        class="mb-1"
      ></Superselect>

      <Superselect
        title="Type"
        v-model="domainType"
        :options="domainTypeOptions"
        :multiple="false"
        class="mb-1"
      ></Superselect>
      <Superselect
        title="Tags"
        v-model="domainTags"
        :options="tagOptions"
        id="tagConfigId"
        label="tag"
        :multiple="true"
      ></Superselect>
      <div v-if="domainType != 'nameserver'">
        <YCheckbox
          class="py-3"
          label="Check Spamhaus Daily"
          v-model="checkSpamhausDaily"
        />
        <div class="flex items-center justify-between mb-1 mt-3">
          <div>
            <span class="mb-0 font-semibold">Add With Records</span>
          </div>
          <div>
            <YButton
              class="text-blue-600 hover:bg-gray-100"
              @click="
                () => {
                  this.section = 'addRecords'
                }
              "
              >Add IP Records</YButton
            >
            <YButton
              class="text-blue-600 hover:bg-gray-100"
              @click="
                () => {
                  this.records = [
                    { type: 'A', subdomain: null, content: null, ttl: 300 },
                    ...this.records
                  ]
                }
              "
              >Add Custom Record</YButton
            >
          </div>
        </div>
        <records-table-editable
          v-show="records && records.length > 0"
          v-model="records"
        ></records-table-editable>
      </div>
    </WidgetBody>
    <WidgetBody v-if="section == 'addRecords'" class="pt-5">
      <records-new-helper
        ref="recordsNewHelper"
        @update="
          records => {
            this.newRecords = records
          }
        "
        :domain-type="domainType"
      ></records-new-helper>
    </WidgetBody>
    <WidgetFooter
      v-if="section == 'primary'"
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <y-checkbox
        class="flex items-center"
        v-model="closeOnComplete"
        label="Close on Complete"
      ></y-checkbox>
      <YButton
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="create"
        @keydown.enter="create"
        :disabled="!domains || (domains && domains.length == 0)"
        >Add Domains</YButton
      >
    </WidgetFooter>
    <WidgetFooter
      v-if="section == 'addRecords'"
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <YButton
        :is-loading="isSubmitting"
        type="button"
        class="text-blue-600 hover:bg-gray-200"
        @click="
          () => {
            this.section = 'primary'
          }
        "
        @keydown.enter="
          () => {
            this.section = 'primary'
          }
        "
        >Back</YButton
      >
      <div>
        <YButton
          class="text-blue-600 hover:bg-gray-200 mr-1"
          @click="$refs.recordsNewHelper.clear()"
          >Clear</YButton
        >
        <YButton
          :is-loading="isSubmitting"
          type="button"
          color="blue"
          @click="addRecords"
          @keydown.enter="addRecords"
          :disabled="
            !this.newRecords || (this.newRecords && this.newRecords.length == 0)
          "
          >Add Records</YButton
        >
      </div>
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import RecordsNewHelper from './RecordsNewHelper'
import RecordsTableEditable from './RecordsTableEditable'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import MUTATE_SPAMHAUS_CHECK_MUTATION from '@/graphql/Domain/MutateSpamhausCheckMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    defaultGroup: {
      type: String,
      default: 'Default'
    },
    type: {
      type: String
    },
    status: {
      type: String
    }
  },
  components: {
    RecordsNewHelper,
    RecordsTableEditable
  },
  apollo: {
    tagOptions: {
      query: gql`
        query getYdnsTagConfigs($filters: YdnsTagConfigsFilterInput) {
          tagOptions: ydnsTagConfigs(filters: $filters) {
            id
            tag
            color
          }
        }
      `,
      variables: {
        filters: {
          type: {
            value: ['domain']
          }
        }
      },
      result(result) {
        let output = result.data.tagOptions.map(option => ({
          tag: option.tag,
          tagConfigId: option.id
        }))

        this.tagOptions = output
      }
    },
    providerTypeOptions: {
      query: gql`
        query {
          providerTypeOptions: __type(name: "YdnsProviderTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.providerTypeOptions = data.providerTypeOptions.enumValues.map(
          enumValue => ({
            id: enumValue.name,
            label: enumValue.name.capitalize()
          })
        )
      }
    }
  },
  data() {
    return {
      closeOnComplete: true,
      domainsSelect: [],
      domainType: this.type ?? null,
      domainStatus: this.status ?? 'ready',
      domainTypeOptions: ['mini', 'redirect', 'offer', 'nameserver'],
      checkSpamhausDaily: false,
      domainStatusOptions: ['active', 'flagged', 'ready'],
      domainTags: [],
      tagOptions: ['Let Expire'],
      isSubmitting: false,
      newRecords: [],
      records: [],
      section: 'primary',
      useRandomIp: true,
      createCount: 0
    }
  },
  watch: {
    domainType: function() {
      this.records = []
    }
  },
  computed: {
    domains() {
      return this.domainsSelect.map(domain => domain.id)
    }
  },
  methods: {
    createDomain(domain) {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation YdnsCreateDomain($input: YdnsCreateDomainInput!) {
              ydnsCreateDomain(input: $input) {
                id
                registrar
                type
                records {
                  type
                  subdomain
                  domain {
                    id
                    type
                  }
                  content
                  ttl
                  ip {
                    id
                  }
                  priority
                  disabled
                }
                tags {
                  id
                  tagConfig {
                    id
                    tag
                    color
                  }
                }
              }
            }
          `,
          variables: {
            input: {
              id: domain,
              type: this.domainType,
              status: this.domainStatus ?? 'ready',
              records: {
                create: this.records.map(record => ({
                  type: record.type,
                  content: record.content,
                  subdomain: record.subdomain,
                  ttl: record.ttl,
                  priority: record.priority,
                  disabled: record.disabled
                }))
              },
              tags: {
                create: this.domainTags.map(tag => ({
                  tagConfig: { connect: tag.tagConfigId }
                }))
              }
            }
          }
        })
        .then(() => {
          this.createCount++
          if (this.checkSpamhausDaily) {
            this.mutateSpamhausCheck(domain)
          }
          if (this.createCount == this.domains.length) {
            this.isSubmitting = false
            this.$toastr.s('Domains Added', 'Success!')
            if (this.domainType == 'nameserver') {
              this.$events.emit('refreshDnsDomains', true)
            } else {
              this.$events.emit('refreshYdnsDomains')
            }

            if (this.closeOnComplete) {
              this.$emit('close')
            }
          }
        })
        .catch(error => {
          this.isSubmitting = false

          this.setValidationErrors(error)

          // if (Object.entries(this.validationErrors).length <= 0) {
          //   this.$toastr.e(error, 'Oops!')
          // }
        })
    },
    create() {
      this.createCount = 0
      this.domains.forEach(domain => {
        this.createDomain(domain)
      })
    },
    mutateSpamhausCheck(id) {
      this.$apollo
        .mutate({
          mutation: MUTATE_SPAMHAUS_CHECK_MUTATION,
          variables: {
            input: {
              model: 'Domain',
              checkId: id,
              method: 'create'
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('Automatic Spamhaus Check Enabled', 'Success!')
        })
        .catch(error => {
          this.setValidationErrors(error)
        })
    },
    removeRecord(record) {
      this.records = this.records.filter(existingRecord => {
        return existingRecord != record
      })
    },
    addRecords() {
      this.records = [...this.records, ...this.newRecords]
      this.$refs.recordsNewHelper.clear()
      this.section = 'primary'
    },
    close() {
      this.$emit('close')
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style>
.publisher-postbacks-table-actions-header {
  width: 3rem;
}
.text-opacity-light {
  opacity: 0.7;
}
</style>
