<template>
  <div contenteditable @input="update"></div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: [String, Number]
    },
    tag: {
      type: String,
      default: 'div'
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {
    this.$el.innerText = this.value
  },
  updated() {},
  methods: {
    update: function(event) {
      this.$emit('input', event.target.innerText)
    }
  }
}
</script>
<style></style>
