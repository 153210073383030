var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tabs', {
    attrs: {
      "persist-as": "domainManager"
    }
  }, [_c('tab', {
    attrs: {
      "name": "Domains",
      "active": true
    }
  }, [_c('domains-table-wrapper')], 1), _c('tab', {
    attrs: {
      "name": "Providers"
    }
  }, [_c('providers')], 1), _c('tab', {
    attrs: {
      "name": "DNS"
    }
  }, [_c('dns')], 1), _c('tab', {
    attrs: {
      "name": "Tags"
    }
  }, [_c('tags')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }