<template>
  <div>
    <Widget>
      <WidgetBody class="bg-gray-100">
        <div class="flex justify-between">
          <div></div>
          <y-button
            class="btn btn-blue"
            @click="openCreateAdvertiserAccountModal"
          >
            <i class="zmdi zmdi-file-plus pr-2"></i> New Account
          </y-button>
        </div>
      </WidgetBody>

      <YTable
        v-if="advertiserAccounts"
        :data="advertiserAccounts.data || []"
        :bottom-loading="$apolloData.queries.advertiserAccounts.loading"
      >
        <template slot="header-row">
          <th>ID</th>
          <th>yLead Type</th>
          <th>Conversion Type</th>
          <th>User Name</th>
          <th>Password</th>
          <th>Duplication Handler</th>
          <th>Actions</th>
        </template>
        <template slot="row" slot-scope="props">
          <td>{{ props.rowData.id }}</td>
          <td>
            <editable-advertiser-account-ylead-type :id="props.rowData.id">{{
              props.rowData.yleadType
            }}</editable-advertiser-account-ylead-type>
          </td>
          <td>
            <editable-advertiser-account-conversion-type :id="props.rowData.id">
              <template v-if="props.rowData.conversionType">
                {{ props.rowData.conversionType?.name }}
              </template>
              <span v-else class="text-gray-500">None</span>
            </editable-advertiser-account-conversion-type>
          </td>
          <!--<td>{{ props.rowData.userName }}</td>-->
          <td>
            <editable-advertiser-account-username :id="props.rowData.id">{{
              props.rowData.userName
            }}</editable-advertiser-account-username>
          </td>
          <td>
            <editable-advertiser-account-password :id="props.rowData.id">{{
              props.rowData.password
            }}</editable-advertiser-account-password>
          </td>
          <!--<td>{{ props.rowData.yleadType }}</td>-->
          <td>
            <editable-advertiser-account-duplication-handler
              :id="props.rowData.id"
              >{{
                props.rowData.duplicationHandler
              }}</editable-advertiser-account-duplication-handler
            >
          </td>
          <!--<td>
            {{ props.rowData.duplicationHandler }}
          </td>-->
          <td>
            <div class="text-right">
              <div class="inline-flex items-center">
                <a v-if="props.rowData.conversionType?.name == 'Revshare Action' || props.rowData.conversionType?.name == 'Revshare Deposit'"
                  href="#"
                  @click.prevent="openRevsharePullModel(props.rowData.id,props.rowData.advertiserAccountRevshareHelper?.id, props.rowData.conversionType?.slug)"
                  class="link x-action-icon"
                >
                  <Icon name="information" class="w-4 h-4" />
                </a>
                <a
                  href="#"
                  @click.prevent="openAccountKeyModel(props.rowData.id)"
                  class="link x-action-icon"
                >
                  <Icon name="key" class="w-4 h-4" />
                </a>
                <a
                  href="#"
                  @click.prevent="openPixelModel(props.rowData.id)"
                  class="link x-action-icon"
                >
                  <Icon name="paperClip" class="w-4 h-4" />
                </a>
                <a
                  href="#"
                  @click.prevent="
                    openDeleteModal(props.rowData.id, props.rowData.userName)
                  "
                  class="text-red-600 hover:text-red-700 x-action-icon"
                >
                  <Icon name="trashCan" class="w-4 h-4" />
                </a>
              </div>
            </div>
          </td>
        </template>
      </YTable>
    </Widget>
  </div>
</template>

<script>
import CreateAdvertiserAccount from '@/views/Admin/Advertiser/CreateAdvertiserAccount.vue'
import EditableAdvertiserAccountUsername from '@/views/Admin/Advertiser/EditableAdvertiserAccountUsername.vue'
import EditableAdvertiserAccountPassword from '@/views/Admin/Advertiser/EditableAdvertiserAccountPassword.vue'
import EditableAdvertiserAccountYleadType from '@/views/Admin/Advertiser/EditableAdvertiserAccountYleadType.vue'
import EditableAdvertiserAccountConversionType from '@/views/Admin/Advertiser/EditableAdvertiserAccountConversionType.vue'
import EditableAdvertiserAccountDuplicationHandler from '@/views/Admin/Advertiser/EditableAdvertiserAccountDuplicationHandler.vue'
import AdvertiserAccountFormDelete from '@/views/Admin/Advertiser/AdvertiserAccountFormDelete.vue'
import AdvertiserAccountFormPixel from '@/views/Admin/Advertiser/AdvertiserAccountFormPixel.vue'
import AdvertiserAccountKeyForm from '@/views/Admin/Advertiser/AdvertiserAccountKeyForm.vue'
import AdvertiserAccountRevshareHelperForm from '@/views/Admin/Advertiser/AdvertiserAccountRevshareHelperForm.vue'
import ADVERTISER_ACCOUNTS_QUERY from '@/graphql/AdvertiserAccount/AdvertiserAccountsQuery.gql'
export default {
  components: {
    EditableAdvertiserAccountUsername,
    EditableAdvertiserAccountPassword,
    EditableAdvertiserAccountYleadType,
    EditableAdvertiserAccountConversionType,
    EditableAdvertiserAccountDuplicationHandler
  },
  apollo: {
    advertiserAccounts: {
      query: ADVERTISER_ACCOUNTS_QUERY,
      variables() {
        return {
          filters: {
            userId: { value: [this.id] }
          },
          first: 1000000
        }
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    tab: {
      type: String,
      default: 'stats'
    }
  },
  data() {
    return {}
  },
  methods: {
    openCreateAdvertiserAccountModal() {
      this.$modal.show(
        CreateAdvertiserAccount,
        { user_id: parseInt(this.id) },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true,
          classes: ['overflow-visible-important']
        }
      )
    },
    openRevsharePullModel(accountId,id,conversionTypeSlug) {
      this.$modal.show(
        AdvertiserAccountRevshareHelperForm,
        {
          accountId: parseInt(accountId),
          id: parseInt(id),
          conversionType: conversionTypeSlug
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openAccountKeyModel(accountId) {
      this.$modal.show(
        AdvertiserAccountKeyForm,
        {
          advertiserId: parseInt(this.id),
          accountId: parseInt(accountId)
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openPixelModel(accountId) {
      this.$modal.show(
        AdvertiserAccountFormPixel,
        {
          advertiserId: parseInt(this.id),
          accountId: parseInt(accountId)
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDeleteModal(id, name) {
      this.$modal.show(
        AdvertiserAccountFormDelete,
        { id: parseInt(id), username: name },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  },
  computed: {},
  mounted() {
    this.$events.listen('refreshAdvertiserAccounts', value => {
      console.log(value)
      this.$apollo.queries.advertiserAccounts.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refreshAdvertiserAccounts')
  }
}
</script>
<style scoped>
.x-action-icon {
  @apply py-1 px-1;
}
</style>
