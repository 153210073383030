var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', [_c('WidgetBody', {
    staticClass: "bg-gray-100"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('div'), _c('y-button', {
    staticClass: "btn btn-blue",
    on: {
      "click": _vm.openCreateAdvertiserContactModal
    }
  }, [_c('i', {
    staticClass: "zmdi zmdi-file-plus pr-2"
  }), _vm._v(" New Contact ")])], 1)]), _vm.contacts ? _c('YTable', {
    attrs: {
      "data": _vm.contacts.data || [],
      "bottom-loading": _vm.$apolloData.queries.contacts.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [props.rowData.id ? [_vm._v(" " + _vm._s(props.rowData.name) + " ")] : _vm._e()], 2), _c('td', [props.rowData.id ? [_vm._v(" " + _vm._s(props.rowData.email) + " ")] : _vm._e()], 2), _c('td', [_c('div', {
          staticClass: "inline-flex items-center"
        }, [_c('a', {
          staticClass: "link",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openEditModal(props.rowData.id, props.rowData.name, props.rowData.email);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "pencil"
          }
        })], 1), _c('a', {
          staticClass: "text-red-600 hover:text-red-700",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDeleteModal(props.rowData.id, props.rowData.name, props.rowData.email);
            }
          }
        }, [_c('Icon', {
          staticClass: "inline",
          attrs: {
            "name": "trashCan",
            "size": 4
          }
        })], 1)])])];
      }
    }], null, false, 3011902240)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Email")]), _c('th', [_vm._v("Actions")])])], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }