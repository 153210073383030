var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group",
    class: {
      'has-danger': _vm.hasErrors
    }
  }, [_vm._t("label", function () {
    return [_vm.toggleable ? [_c('y-checkbox', {
      staticClass: "col-form-label fw-500",
      class: _vm.labelClasses,
      attrs: {
        "label": _vm.label ? _vm.label : undefined,
        "type": "info"
      },
      model: {
        value: _vm.activeToggle,
        callback: function ($$v) {
          _vm.activeToggle = $$v;
        },
        expression: "activeToggle"
      }
    })] : [_vm.label ? _c('label', {
      staticClass: "col-form-label",
      class: _vm.labelClasses,
      style: _vm.inlineStyle
    }, [!_vm.hideLabel ? [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.markRequired ? _c('sup', {
      staticClass: "text-danger fw-600"
    }, [_vm._v("*")]) : _vm._e(), _vm.labelNote ? _c('span', {
      staticClass: "ml-1 fw-400",
      staticStyle: {
        "opacity": "0.5",
        "font-style": "italic"
      }
    }, [_vm._v(_vm._s(_vm.labelNote))]) : _vm._e()] : _vm._e(), _vm.hideLabel ? [_vm._v("   ")] : _vm._e()], 2) : _vm._e()]];
  }), _vm.active ? _vm._t("default", null, {
    "disabled": _vm.disabled
  }) : _vm._e(), _vm.info ? _c('div', {
    staticClass: "form-control-feedback"
  }, [_c('small', {
    staticClass: "text-muted",
    domProps: {
      "textContent": _vm._s(_vm.info)
    }
  })]) : _vm._e(), _vm.error ? [!_vm.error.minLength && _vm.error.$params.minLength ? _c('div', {
    staticClass: "form-control-feedback"
  }, [_c('small', [_vm._v("Must have at least " + _vm._s(_vm.error.$params.minLength.min) + " letters.")])]) : !_vm.error.email && _vm.error.$params.email ? _c('div', {
    staticClass: "form-control-feedback"
  }, [_c('small', [_vm._v("Invalid email")])]) : _vm._e(), !_vm.error.minLength ? _c('div', {
    staticClass: "error"
  }) : _vm._e()] : _vm._e(), _vm.errorStrings ? _vm._l(_vm.errorStrings, function (error, index) {
    return _c('div', {
      key: index,
      staticClass: "form-control-feedback text-danger"
    }, [_c('small', {
      staticClass: "fw-500"
    }, [_vm._v(_vm._s(error))])]);
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }