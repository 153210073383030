var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_vm._l(_vm.vuetable.tableFields, function (field, fieldIndex) {
    return [field.visible ? [_vm.vuetable.isFieldComponent(field.name) ? [_c(field.name, {
      key: fieldIndex,
      tag: "component",
      class: _vm.headerClass('vuetable-th-component', field),
      style: {
        width: field.width
      },
      attrs: {
        "row-field": field,
        "is-header": true,
        "title": _vm.renderTitle(field),
        "vuetable": _vm.vuetable
      },
      on: {
        "vuetable:header-event": _vm.vuetable.onHeaderEvent,
        "click": function ($event) {
          return _vm.onColumnHeaderClicked(field, $event);
        }
      }
    })] : _vm.vuetable.isFieldSlot(field.name) ? [_c('th', {
      key: fieldIndex,
      class: _vm.headerClass('vuetable-th-slot', field),
      style: {
        width: field.width
      },
      on: {
        "click": function ($event) {
          return _vm.onColumnHeaderClicked(field, $event);
        }
      }
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(_vm.renderTitle(field))
      }
    }), field && _vm.getTitle(field) > 0 && _vm.isInCurrentSortGroup(field) || _vm.hasSortableIcon(field) ? _c('Icon', {
      class: [_vm.currentSortOrderPosition(field) !== false ? 'opacity-100 text-blue-600' : 'opacity-25', 'ml-2'],
      attrs: {
        "name": _vm.sortName(field)
      }
    }) : _vm._e()], 1)])] : [_c('th', {
      key: fieldIndex,
      class: _vm.headerClass('vuetable-th', field),
      style: {
        width: field.width
      },
      attrs: {
        "id": '_' + field.name
      },
      on: {
        "click": function ($event) {
          return _vm.onColumnHeaderClicked(field, $event);
        }
      }
    }, [_c('div', {
      staticClass: "flex items-center"
    }, [_c('div', {
      domProps: {
        "innerHTML": _vm._s(_vm.renderTitle(field))
      }
    }), field && _vm.getTitle(field) > 0 && _vm.isInCurrentSortGroup(field) || _vm.hasSortableIcon(field) ? _c('Icon', {
      class: [_vm.currentSortOrderPosition(field) !== false ? 'opacity-100 text-blue-600' : 'opacity-25', 'ml-2'],
      attrs: {
        "name": _vm.sortName(field)
      }
    }) : _vm._e()], 1)])]] : _vm._e()];
  }), _vm.vuetable.scrollVisible ? _c('vuetable-col-gutter') : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }