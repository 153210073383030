var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "bg-red-600 border-red-700 text-white"
  }, [_c('WidgetHeader', {
    staticClass: "bg-red-600"
  }, [_c('span', {
    staticClass: "text-white"
  }, [_vm._v("Are you sure?")])]), _c('WidgetBody', [_c('h5', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v("This action cannot be undone.")]), _c('h5', {
    staticClass: "mb-2"
  }, [_vm._v(" You are about to delete the postback for: ")]), _c('ul', {
    staticClass: "my-4"
  }, [_c('li', {
    staticClass: "font-semibold text-md"
  }, [_vm.postback.redirect ? [_vm.postback.redirect.campaign ? [_vm._v(" " + _vm._s(_vm.postback.redirect.campaign.id) + " - " + _vm._s(_vm.postback.redirect.campaign.name) + " ")] : [_vm._v(" " + _vm._s(_vm.postback.redirect.id) + " - " + _vm._s(_vm.postback.redirect.subdomain) + "." + _vm._s(_vm.postback.redirect.domain) + " ")]] : [_vm._v("Account Level Postback")]], 2), _c('li', {
    staticClass: "italic"
  }, [_vm._v(_vm._s(_vm.postback.protocol) + "://" + _vm._s(_vm.postback.url))])])]), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 flex p-4 justify-between"
  }, [_c('YButton', {
    staticClass: "hover:bg-red-700 focus:bg-red-700",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    staticClass: "hover:bg-red-700 focus:bg-red-700",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Delete Postback")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }