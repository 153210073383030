var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "options": _vm.providerTypeOptions,
      "title": "Select Provider Type",
      "track-by": "id",
      "multiple": false,
      "label": "label"
    },
    on: {
      "select": function () {
        ;
        _this.selectedProvider = null, _this.selectedServer = null, _this.selectedIps = null;
      }
    },
    model: {
      value: _vm.selectedProviderType,
      callback: function ($$v) {
        _vm.selectedProviderType = $$v;
      },
      expression: "selectedProviderType"
    }
  }), _vm.selectedProviderType ? _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "options": _vm.providerOptions,
      "title": "Select Provider",
      "multiple": false,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "select": function () {
        ;
        _this.selectedServer = null, _this.selectedIps = null;
      }
    },
    model: {
      value: _vm.selectedProvider,
      callback: function ($$v) {
        _vm.selectedProvider = $$v;
      },
      expression: "selectedProvider"
    }
  }) : _vm._e(), _vm.selectedProvider ? _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "options": _vm.serverOptions,
      "multiple": false,
      "title": "Select Server",
      "track-by": "id",
      "label": "label"
    },
    on: {
      "select": function () {
        _this.selectedIps = null;
      }
    },
    model: {
      value: _vm.selectedServer,
      callback: function ($$v) {
        _vm.selectedServer = $$v;
      },
      expression: "selectedServer"
    }
  }) : _vm._e(), _vm.selectedProviderType && !_vm.useRandomIp ? _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "multiple": true,
      "options": _vm.ipOptions,
      "title": "Select IP",
      "track-by": "id",
      "label": "id"
    },
    model: {
      value: _vm.selectedIps,
      callback: function ($$v) {
        _vm.selectedIps = $$v;
      },
      expression: "selectedIps"
    }
  }) : _vm._e(), _vm.selectedProviderType ? _c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_c('y-checkbox', {
    attrs: {
      "label": "Use Random IP",
      "type": "info"
    },
    model: {
      value: _vm.useRandomIp,
      callback: function ($$v) {
        _vm.useRandomIp = $$v;
      },
      expression: "useRandomIp"
    }
  })], 1), _c('div', [_vm.randomIp && _vm.useRandomIp ? _c('Tag', {
    attrs: {
      "color": "blue"
    }
  }, [_vm._v(_vm._s(_vm.randomIp.id))]) : _vm._e()], 1)]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectOutput,
      expression: "selectOutput"
    }],
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": _vm.selectOutput
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.selectOutput = $event.target.value;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }