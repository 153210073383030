<template>
  <div id="top">
    <Widget class="mb-2">
      <WidgetHeader class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <ImageFilters />
        </template>
      </WidgetHeader>
    </Widget>

    <ImageRepositoryTable :data="images ? images.data : []" :key="refetch" />

    <YPagination
      v-if="images.paginatorInfo"
      :data="images.paginatorInfo"
      @load-page="loadPage"
      @next-page="nextPage"
      @previous-page="previousPage"
      :key="totalPages"
      @per-page="perPageSelection"
    >
    </YPagination>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ImageRepositoryTable from '@/views/Admin/ImageRepository/ImageRepositoryTable.vue'
import ImageFilters from '@/views/Admin/ImageRepository/ImageFilters.vue'
import IMAGES_QUERY from '@/graphql/ImageRepository/ImagesQuery.gql'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'

export default {
  mixins: [BackToTop],
  components: {
    ImageRepositoryTable,
    ImageFilters,
    YPagination
  },
  apollo: {
    images: {
      query: IMAGES_QUERY,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          first: this.perPage,
          page: this.currentPage,
          orderBy: this.orderBy,
          filters: {
            name: this.cleanFilter(this.filters.name),
            tags: this.cleanFilter(this.filters.tags)
          }
        }
      },
      result() {
        this.refetch = false
      }
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      images: {},
      refetch: false,
      orderBy: [
        {
          column: 'id',
          order: 'DESC'
        }
      ]
    }
  },
  mounted() {
    this.refetch = false
    this.$events.listen('refreshImages', () => {
      this.$apollo.queries.images.refetch()
      this.refetch = true
    })
  },
  updated() {},
  computed: {
    ...mapState({
      filters: state => state.images.filters
    })
  },
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    ...mapActions(['updateGlobalLoader']),
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    }
  },
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  }
}
</script>

<style scoped>
li.highlight {
  @apply font-extrabold;
}
</style>
