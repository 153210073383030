<template>
  <div class="relative y-input">
    <span v-if="required" class="absolute right-0 pr-1 text-2xl text-red-500"
      >*</span
    >
    <Icon
          v-if="alertIcon"
          name="pencil"
          class="absolute right-0 top-0 mt-1 mr-1 text-pink-400 bg-white"
          :size="4"
          v-tooltip="'Modified from LLM Translation'"
        />
    <div class="absolute right-0 bottom-0 mb-2 mr-3 flex">
        <Icon
        v-if="tooltip"
        name="helpCircle"
        class="mr-1 text-gray-400 bg-white"
        :size="4"
        v-tooltip="tooltip"
      />
      <Icon
        v-for="icon in icons"
        :key="icon.name"
        :name="icon.name"
        class="text-gray-400 bg-white"
        :size="4"
        v-tooltip="icon ? icon.tooltip : null"
      />
    </div>
    <textarea
      class="input"
      :class="[inputClass, internalValue ? 'filled' : '']"
      ref="input"
      :id="id"
      :name="label ? label.toLowerCase() : null"
      :type="type"
      autofocus
      v-model="internalValue"
      @paste="event => $emit('paste', event)"
      @blur="event => $emit('blur', event)"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :required="required"
      :alertIcon="alertIcon"
      @keypress="event => $emit('keypress', event)"
      :maxlength="characterLimit"
    />
    <label
      :for="label ? label.toLowerCase() : null"
      class="pointer-events-none label absolute mb-0 -mt-2 pt-4 pl-3 leading-tighter text-gray-400 text-base mt-2 cursor-text"
      >{{ label }}</label
    >
    <div
      class="text-red-800 flex items-center gap-1"
      v-if="characterLimitReached"
    >
      <Icon name="alertCircle" :size="4" />Character Limit Reached
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: () => {
        // If no ID is passed. Generate a random one to link label and input
        return Math.floor(Math.random(9999999) * 100000000).toString()
      }
    },
    label: {
      type: String,
      default: null,
      required: true
    },
    value: {
      type: [String, Number, Object],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    inputClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    alertIcon: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String
    },
    characterLimit: {
      type: Number
    },
    icons: {
      type: Array,
      default: ()=>[]
    }
  },
  data() {
    return {}
  },
  methods: {
    focusInput() {
      this.$refs.input.focus()
    }
  },
  mounted() {},
  computed: {
    internalValue: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        if (newValue == '') {
          newValue = null
        }

        this.$emit('input', newValue)
      }
    },
    characterLimitReached() {
      if (this.characterLimit !== undefined && this.internalValue !== null) {
        return this.internalValue.length === this.characterLimit
      }
      return false
    }
  }
}
</script>

<style scoped></style>
