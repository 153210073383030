<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <div class="flex items-center">
        <Icon name="pencil" :size="4" class="mr-2"></Icon>
        <template>Bulk Status Edit</template>
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <h5 class="font-bold">Selected Domains:</h5>
      <ul class="text-xs mt-1 mb-3">
        <li v-for="domain in domains" :key="domain.id">
          {{ domain.id }}
        </li>
      </ul>
      <Superselect
        title="Status"
        v-model="status"
        :multiple="false"
        placeholder="Select"
        :allow-empty="false"
        :query="INTROSPECTION_TYPE_QUERY"
        :query-variables="{ name: 'DomainStatusEnum' }"
        :query-result-function="
          data => {
            return data.__type.enumValues.map(value => value.name)
          }
        "
      ></Superselect>
      <Superselect
        v-if="
          filterDomainType && status == 'flagged' && readyDomains.length > 0
        "
        class="mt-2"
        title="Replacement Domain"
        v-model="replacementDomain"
        :options="readyDomains"
        placeholder="Select"
        :multiple="false"
        track-by="id"
        label="label"
      ></Superselect>
      <div
        v-if="
          filterDomainType && status == 'flagged' && readyDomains.length <= 0
        "
        class="pt-1 text-center"
      >
        <small class="text-muted" style="font-style: italic;"
          >No Domains Available</small
        >
      </div>
      <div
        v-if="!filterDomainType && status == 'flagged'"
        class="pt-1 text-center"
      >
        <small class="text-muted" style="font-style: italic;"
          >Please select the same domain types to set a replacement
          domain</small
        >
      </div>
      <Superselect
        v-if="status == 'active'"
        class="mt-2"
        title="Domain Type"
        v-model="domainType"
        :options="typeOptions"
        placeholder="Select"
        :multiple="false"
      ></Superselect>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <YCheckbox
        class="flex items-center"
        v-model="closeOnComplete"
        label="Close on Complete"
      ></YCheckbox>
      <YButton
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="updateDomain"
        :disabled="!canSubmit"
        >Update</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import INTROSPECTION_TYPE_QUERY from '@/graphql/Introspection/IntrospectionTypeQuery.gql'
import DOMAIN_OPTIONS_QUERY from '@/graphql/Domain/DomainOptionsQuery.gql'
import UPDATE_DOMAIN_STATUS_MUTATION from '@/graphql/Domain/UpdateDomainStatusMutation.gql'

export default {
  props: {
    domains: {
      type: Array,
      required: true
    }
  },
  mixins: [ValidationErrorsMixin],
  apollo: {
    ydnsDomains: {
      query: gql`
        query ydnsDomains(
          $filters: YdnsDomainFilterInput
          $recordFilters: YdnsRecordFilterInput
        ) {
          ydnsDomains(filters: $filters) {
            id
            registrar
            type
            records(filters: $recordFilters) {
              id
              type
              subdomain
              ip {
                id
                server {
                  id
                }
              }
              content
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            type: this.filterDomainType
              ? { value: [this.filterDomainType, null] }
              : { value: [null] },
            status: { value: ['ready', null] },
            hasRecords: this.filterDomainType == 'mini' ? false : undefined
          }
        }
      },
      result({ data }) {
        this.readyDomains = data.ydnsDomains.map(domain => ({
          id: domain.id,
          label: `${domain.id} - ${domain.registrar}`
        }))
      }
    }
  },
  components: {},
  data() {
    return {
      INTROSPECTION_TYPE_QUERY,
      DOMAIN_OPTIONS_QUERY,
      readyDomains: [],
      typeOptions: ['mini', 'redirect', 'offer', 'No Change'],
      statusOptions: ['ready', 'active', 'flagged'],
      replacementDomain: null,
      domainType: 'No Change',
      status: null,
      closeOnComplete: true,
      isSubmitting: false
    }
  },
  watch: {},
  computed: {
    canSubmit() {
      if (!this.status) {
        return false
      }
      return true
    },
    input() {
      let domainInputs = []

      this.domains.forEach(domain => {
        domainInputs.push({
          domain: domain.id,
          status: this.status,
          type:
            this.status != 'active'
              ? undefined
              : this.domainType == 'No Change'
              ? domain.type
              : this.domainType,
          replacementDomain:
            this.status == 'flagged' && this.replacementDomain
              ? this.replacementDomain.id
              : undefined
        })
      })

      return domainInputs
    },
    filterDomainType() {
      var firstDomainType = this.domains[0].type

      var numberOfDomainsWithFirstType = this.domains.filter(
        domain => domain.type === firstDomainType
      ).length

      if (numberOfDomainsWithFirstType === this.domains.length) {
        return firstDomainType
      } else {
        return null
      }
    }
  },
  mounted() {},
  methods: {
    updateDomain() {
      this.clearValidationErrors()
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_DOMAIN_STATUS_MUTATION,
          variables: {
            input: this.input
          }
        })
        .then(result => {
          this.$toastr.s('Domain Status Updated', 'Success!')
          this.clearValidationErrors()
          this.isSubmitting = false
          this.$emit('success', result)
          this.$events.emit('refreshYdnsDomains')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
        })
    }
  },
  close() {
    this.$emit('close')
  }
}
</script>
<style></style>
