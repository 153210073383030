<template>
  <div
    class="widget-footer d-flex justify-content-between align-items-center flex-sm-row"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {},
  mounted() {},

  updated() {},

  methods: {}
}
</script>
<style></style>
