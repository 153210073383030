var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$apollo.queries.campaignTopPublisherStats.loading ? _c('div', {
    staticClass: "text-center"
  }, [_c('Spinner', {
    staticClass: "mb-4",
    attrs: {
      "size": 8,
      "color": "green"
    }
  })], 1) : _vm.campaignTopPublisherStats && _vm.campaignTopPublisherStats.length > 0 ? _c('div', _vm._l(_vm.campaignTopPublisherStats, function (stats) {
    return _c('div', {
      key: stats.publisher.id,
      staticClass: "px-4 xitem"
    }, [_c('div', {
      staticClass: "mb-2"
    }, [_c('router-link', {
      staticClass: "link",
      attrs: {
        "to": {
          name: 'User',
          params: {
            id: stats.publisher.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(stats.publisher.label) + " ")])], 1), _c('div', {
      staticClass: "grid grid-rows-2 grid-flow-col gap-y-0 gap-x-2"
    }, [_c('div', {
      staticClass: "xlabel",
      staticStyle: {
        "text-transform": "uppercase"
      }
    }, [_vm._v(" Today ")]), _c('div', {
      staticClass: "xstat"
    }, [_vm._v(_vm._s(_vm.$formatMoney(stats.todaysRevenue)))]), _c('div', {
      staticClass: "xlabel",
      staticStyle: {
        "text-transform": "uppercase"
      }
    }, [_vm._v(" This Week ")]), _c('div', {
      staticClass: "xstat"
    }, [_vm._v(_vm._s(_vm.$formatMoney(stats.thisWeeksRevenue)))]), _c('div', {
      staticClass: "xlabel",
      staticStyle: {
        "text-transform": "uppercase"
      }
    }, [_vm._v(" Last Week ")]), _c('div', {
      staticClass: "xstat"
    }, [_vm._v(_vm._s(_vm.$formatMoney(stats.lastWeeksRevenue)))]), _c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: `This Week / Last Week`,
        expression: "`This Week / Last Week`"
      }],
      staticClass: "xlabel",
      staticStyle: {
        "text-transform": "uppercase"
      }
    }, [_vm._v(" Change ")]), _c('div', {
      staticClass: "xstat",
      class: stats.percentChange > 1 ? 'text-success' : 'text-danger'
    }, [_vm._v(" " + _vm._s(_vm.$formatPercent(stats.percentChange - 1)) + " ")])])]);
  }), 0) : _c('div', {
    staticClass: "text-center text-gray-600 pb-8 pt-4"
  }, [_vm._v(" Sorry, no top publishers are available. ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }