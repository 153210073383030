<template>
  <div id="top">
    <portal to="top-bar-right">
      <router-link :to="{ name: 'Offer', params: { action: 'create' } }">
        <button class="btn btn-blue">Create Offer</button>
      </router-link>
    </portal>
    <div v-show="!action">
      <Widget>
        <WidgetHeader class="bg-gray-200 p-1 border-b">
          <template slot="rawContent">
            <OfferFilters></OfferFilters>
          </template>
        </WidgetHeader>
      </Widget>
      <OffersTable
        v-if="offers && offers.data"
        :offers="offers.data || []"
        :loading="$apollo.queries.offers.loading"
        class="pt-4"
      ></OffersTable>
      <YPagination
        v-if="offers && offers.data"
        :data="offers.paginatorInfo"
        @load-page="loadPage"
        @next-page="nextPage"
        @previous-page="previousPage"
        :key="totalPages"
        @per-page="perPageSelection"
      >
      </YPagination>
    </div>
  </div>
</template>

<script>
import OFFERS_QUERY from '@/graphql/Offer/OffersQuery.gql'
import OfferFilters from '@/views/Admin/Offers/OfferFilters'
import { mapState, mapActions } from 'vuex'
import OffersTable from '@/views/Admin/Offers/OffersTable'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'

export default {
  mixins: [BackToTop],
  components: {
    OfferFilters,
    OffersTable,
    YPagination
  },
  props: {
    id: {
      type: [Number, String]
    },
    action: {
      type: String
    }
  },
  apollo: {
    offers: {
      query: OFFERS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          first: this.perPage,
          page: this.currentPage,
          filters: {
            idAndKeyedOfferId: this.cleanFilter(this.filters.id)
              ? this.filters.id['value']
              : undefined,
            name: this.cleanFilter(this.filters.name),
            offer: this.filters.offer.value || undefined,
            advertisers: {
              id:
                this.filters.advertisers.value &&
                this.filters.advertisers.value.length > 0
                  ? {
                      value: this.filters.advertisers.value.map(
                        advertiser => advertiser.id
                      )
                    }
                  : undefined
            },
            verticals: this.filters.verticalTags.value
              ? {
                  value: this.filters.verticalTags.value.map(tag => tag.slug)
                }
              : undefined,
            payoutType: this.filters.payoutTypeTags.value
              ? {
                  value: this.filters.payoutTypeTags.value.map(tag => tag.slug)
                }
              : undefined,
            template: this.filters.template.value
              ? this.filters.template.value
              : undefined,
            destinationType: this.filters.destinationType.value
              ? {
                  value: this.filters.destinationType.value
                }
              : undefined,
            mobileDesktop: this.filters.mobileDesktopTags.value
              ? {
                  value: this.filters.mobileDesktopTags.value.map(
                    tag => tag.slug
                  )
                }
              : undefined,
            status: this.filters.status.value ?? undefined,
            ignoreTimeout: this.filters.ignoreTimeout.value
              ? this.filters.ignoreTimeout.value == 'Yes'
                ? true
                : false
              : undefined,
            isYleaderType: this.filters.yleaderStatus.value
              ? this.filters.yleaderStatus.value == 'yLeader'
                ? true
                : false
              : undefined,
            countryCode: this.filters.countryCodeTags.value
              ? {
                  value: this.filters.countryCodeTags.value.map(tag => tag.slug)
                }
              : undefined,
            regionCode: this.filters.regionCodeTags.value
              ? {
                  value: this.filters.regionCodeTags.value.map(tag => tag.slug)
                }
              : undefined,
            trafficType: this.filters.trafficTypeTags.value
              ? {
                  value: this.filters.trafficTypeTags.value.map(tag => tag.slug)
                }
              : undefined,
            offerType: this.filters.offerTypeTags.value
              ? {
                  value: this.filters.offerTypeTags.value.map(tag => tag.slug)
                }
              : undefined,
            traffic: this.emulateTraffic
              ? {
                  publisherId: this.filters.publisher.value
                    ? this.filters.publisher.value.id
                    : undefined,
                  redirectId: this.filters.redirect.value
                    ? this.filters.redirect.value.id
                    : undefined,
                  campaignId: this.filters.campaign.value
                    ? this.filters.campaign.value.id
                    : undefined,
                  sourceId: this.filters.source.value || undefined,
                  tags: this.trafficTagsFilter
                }
              : undefined
          },
          orderBy: this.orderBy
        }
      },
      result({ data }, key) {
        if (data) {
          this.totalPages = data[key].paginatorInfo.lastPage
        }
      },
      watchLoading(isLoading, countModifier) {
        this.$store.dispatch('updateGlobalLoader', countModifier)
      },
      deep: true
    }
  },
  data() {
    return {
      offers: [],
      emulateTraffic: false,
      currentPage: 1,
      perPage: 10,
      totalPages: 1
    }
  },
  updated() {},
  computed: {
    ...mapState({
      filters: state => state.offer.filters
    }),
    trafficTagsFilter() {
      let tagsByType = []
      if (this.filters.trafficTags && this.filters.trafficTags.value) {
        this.filters.trafficTags.value.forEach(tag => {
          tagsByType[tag.type] = tag.name
        })
      }
      if (
        this.filters.countryCodeTrafficTags &&
        this.filters.countryCodeTrafficTags.value
      ) {
        this.filters.countryCodeTrafficTags.value.forEach(tag => {
          tagsByType[tag.type] = tag.name
        })
      }
      if (
        this.filters.mobileDesktopTrafficTags &&
        this.filters.mobileDesktopTrafficTags.value
      ) {
        this.filters.mobileDesktopTrafficTags.value.forEach(tag => {
          tagsByType[tag.type] = tag.name
        })
      }

      return Object.keys(tagsByType).map(type => ({
        type: type,
        name: tagsByType[type]
      }))
    },
    ruleFilter() {
      if (this.filters.rule.value) {
        return {
          id: this.filters.rule.value ? this.filters.rule.value.id : undefined
          // trafficTags: this.filters.trafficTags
          //   ? this.filters.trafficTags.value
          //   : undefined
        }
      } else {
        return undefined
      }
    },
    offerIds() {
      console.log('calculating offer ids', this.offers)
      return this.offers ? this.offers.map(offer => offer.id) : []
    },
    orderBy: {
      get() {
        return this.$store.state.offer.orderBy
      },
      set(value) {
        this.$store.state.offer.orderBy = JSON.parse(JSON.stringify(value))
      }
    }
  },
  created() {},
  mounted() {
    this.$tabEvent.on('refreshOffersTable', () => {
      this.$apollo.queries.offers.refetch()
    })
    this.$events.listen('offerTrafficked', () => {
      this.$apollo.queries.offers.refetch()
    })
    this.$events.listen('offerDeleted', () => {
      this.$apollo.queries.offers.refetch()
    })
    this.$events.listen('offerDeleted', () => {
      this.$apollo.queries.offers.refetch()
    })
    this.$events.listen('offerCreated', () => {
      this.$apollo.queries.offers.refetch()
    })
    this.$events.listen('emulateTraffic', () => {
      this.emulateTraffic = !this.emulateTraffic
      this.$apollo.queries.offers.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('offerTrafficked')
  },
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  },
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    ...mapActions({
      clearMutateOffer: 'offer/clearMutateOffer'
    }),
    cleanFilter(filter) {
      // Return undefined on null values
      // And make sure value is always an array
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    }
  }
}
</script>

<style lang="scss" scoped></style>
