var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.visibilityChanged,
      expression: "visibilityChanged"
    }],
    staticClass: "grid grid-cols-12"
  }, [_c('div', {
    staticClass: "mb-2 col-span-7"
  }, [_c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "Payment Frequency",
      "multiple": false,
      "options": _vm.paymentFrequencyOptions
    },
    model: {
      value: _vm.localUser.billingContact.paymentFrequency,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.billingContact, "paymentFrequency", $$v);
      },
      expression: "localUser.billingContact.paymentFrequency"
    }
  }), _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "Payment Terms",
      "multiple": false,
      "options": _vm.paymentTermsOptions
    },
    model: {
      value: _vm.localUser.billingContact.paymentTerms,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.billingContact, "paymentTerms", $$v);
      },
      expression: "localUser.billingContact.paymentTerms"
    }
  }), _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "Tax Type",
      "multiple": false,
      "options": _vm.taxTypeOptions
    },
    model: {
      value: _vm.localUser.billingContact.taxType,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.billingContact, "taxType", $$v);
      },
      expression: "localUser.billingContact.taxType"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Tax Number"
    },
    model: {
      value: _vm.localUser.billingContact.taxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.billingContact, "taxNumber", $$v);
      },
      expression: "localUser.billingContact.taxNumber"
    }
  })], 1), _vm.validationErrors && _vm.isVisible ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e(), _vm.hasChanges && _vm.isVisible ? _c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('YButton', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }, [_vm._v("Update Billing Details")])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }