<template>
  <div>
    <ul
      role="tablist"
      class="nav-tabs overflow-x-auto"
      :class="tabsClass"
      :style="tabsStyle"
    >
      <li v-for="tab in tabs" class="nav-item" :key="tab.slug">
        <div class="flex items-center">
          <a
            :href="route ? `./${tab.slug}` : `#${tab.slug}`"
            role="tab"
            :aria-control="`${tab.name}`"
            class="nav-link flex items-center"
            :class="{ active: tab.isActive }"
            aria-expanded="true"
            @click.prevent="selectTab(tab)"
            :disabled="tab.disabled ? 'disabled' : false"
          >
            <Icon v-if="tab.icon" :name="tab.icon" :class="tab.iconClass" />
            <span v-if="!tab.hideName" v-html="tab.name"></span>
            <Tag v-if="tab.tag" class="ml-2" :color="tab.tagColor">
              {{ tab.tag }}
            </Tag>
          </a>
          <template v-if="tab.$slots.aside">
            <vnodes :vnodes="tab.$slots.aside" />
          </template>
        </div>
      </li>
      <li
        v-if="this.$slots.topRight"
        class="nav-item"
        style="margin-left: auto;"
      >
        <slot name="topRight"></slot>
      </li>
    </ul>
    <div class="tab-details h-full flex" :class="tabDetailsClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  props: {
    tabsClass: {
      type: String,
      default: null
    },
    tabsStyle: {
      type: String
    },
    tabDetailsClass: {
      type: String
    },
    showType: {
      type: String,
      default: 'if'
    },
    persistAs: {
      type: String
    },
    route: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      children: []
    }
  },
  watch: {
    route: {
      immediate: true,
      deep: true,
      handler(newRoute) {
        this.selectTabByRoute(newRoute)
      }
    }
  },
  computed: {
    tabs() {
      return this.children.filter(child => {
        return child.$vnode.tag.endsWith('-Tab')
      })
    }
  },
  created() {
    this.children = this.$children
  },
  mounted() {
    if (this.persistAs && this.$store.getters.activeTabs[this.persistAs]) {
      this.selectTabBySlug(this.$store.getters.activeTabs[this.persistAs])
    }
    if (this.route) {
      this.selectTabByRoute(this.route)
    }
  },
  updated() {},
  methods: {
    selectTab(selectedTab) {
      if (!selectedTab.disabled) {
        if (this.route) {
          let route = this.route
          route.params.tab = selectedTab.slug
          this.$router.push(route).catch(() => {})
        } else {
          this.tabs.forEach(tab => {
            tab.isActive = tab.slug == selectedTab.slug
            if (tab.isActive) {
              this.persistTab(tab.slug)
            }
          })
        }
      }
    },
    selectTabByRoute(route) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.slug == route.params.tab
      })
    },
    selectTabBySlug(slug) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.slug == slug
        if (tab.isActive) {
          this.persistTab(slug)
        }
      })
    },
    persistTab(slug) {
      if (this.persistAs) {
        this.$store.dispatch('updateActiveTabs', {
          persistAs: this.persistAs,
          activeTabName: slug
        })
      }
    },
    next() {
      let activeTabIndex
      let nextTabIndex
      this.tabs.forEach((tab, index) => {
        if (tab.isActive) {
          activeTabIndex = index
          nextTabIndex = index + 1
        }
      })

      if (this.tabs[nextTabIndex] && !this.tabs[nextTabIndex].disabled) {
        this.tabs[activeTabIndex].isActive = false
        this.tabs[nextTabIndex].isActive = true
      }
    },
    previous() {
      let activeTabIndex
      let nextTabIndex
      this.tabs.forEach((tab, index) => {
        if (tab.isActive) {
          activeTabIndex = index
          nextTabIndex = index - 1
        }
      })

      if (this.tabs[nextTabIndex]) {
        this.tabs[activeTabIndex].isActive = false
        this.tabs[nextTabIndex].isActive = true
      }
    }
  }
}
</script>
<style scoped>
.nav-link[disabled='disabled'] {
  opacity: 0.3;
}
</style>
