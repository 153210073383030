var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', _vm._l(_vm.variantDefinitions, function (definition) {
    return _c('div', {
      key: definition.key,
      staticClass: "mb-1"
    }, [_vm._t("default", null, {
      "definition": definition,
      "show": _vm.showStates[definition.key]
    })], 2);
  }), 0), _c('a', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.someCanBeHidden,
      expression: "someCanBeHidden"
    }],
    staticClass: "link text-xs block",
    on: {
      "click": _vm.toggle
    }
  }, [_c('span', {
    staticClass: "flex items-center justify-between"
  }, [_c('span'), !_vm.isExpanded ? _c('span', {
    staticClass: "flex items-center"
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v("(" + _vm._s(_vm.hiddenStatesCount) + ")")]), _c('Icon', {
    attrs: {
      "name": "plusBoxOutline",
      "width": 4,
      "height": 4
    }
  })], 1) : _c('span', [_c('Icon', {
    attrs: {
      "name": "minusBoxOutline",
      "width": 4,
      "height": 4
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }