import { cache } from '@/vue-apollo'

export const state = {
  token: localStorage.getItem('apollo-token') || '',
  authId: localStorage.getItem('authId') || null,
  impersonationBy: localStorage.getItem('impersonationBy') || null,
  impersonationToken: localStorage.getItem('impersonationToken') || null,
  impersonationExitPath: localStorage.getItem('impersonationExitPath') || null
}

export const getters = {
  authId: state => state.authId,
  isAuthenticated: state => !!state.token && !!state.authId,
  isImpersonating: state =>
    !!state.impersonationBy || !!state.impersonationToken
}

export const mutations = {
  UPDATE_TOKEN(state, value) {
    if (value == null || value == 'null') {
      localStorage.removeItem('apollo-token')
      state.token = ''
      return
    }

    localStorage.setItem('apollo-token', value)
    state.token = value
  },
  UPDATE_AUTH_ID(state, value) {
    if (value == null || value == 'null') {
      localStorage.removeItem('authId')
      state.authId = null
      return
    }
    localStorage.setItem('authId', value)
    state.authId = value
  },
  UPDATE_ROLE(state, value) {
    localStorage.setItem('role', value)
    state.role = value
  },
  UPDATE_IMPERSONATION(state, value) {
    value.authId
      ? localStorage.setItem('impersonationBy', value.authId)
      : localStorage.removeItem('impersonationBy', value.authId)

    value.token
      ? localStorage.setItem('impersonationToken', value.token)
      : localStorage.removeItem('impersonationToken', value.token)

    value.exitPath
      ? localStorage.setItem('impersonationExitPath', value.exitPath)
      : localStorage.removeItem('impersonationExitPath', value.exitPath)

    localStorage.setItem('role', value.role)
    state.role = value.role
    state.impersonationBy = value.authId
    state.impersonationToken = value.token
    state.impersonationExitPath = value.exitPath
  },
  DELETE_IMPERSONATION(state) {
    localStorage.removeItem('impersonationBy')
    localStorage.removeItem('impersonationToken')
    localStorage.removeItem('impersonationExitPath')
    state.impersonationBy = null
    state.impersonationToken = null
    state.impersonationExitPath = null
  }
}

export const actions = {
  updateLoginAuth({ commit }, value) {
    cache.reset()
    commit('UPDATE_TOKEN', value.token)
    commit('UPDATE_AUTH_ID', value.authId)
    commit('UPDATE_ROLE', value.role)
  },
  logout({ commit }) {
    commit('UPDATE_TOKEN', '')
    commit('UPDATE_AUTH_ID', null)
    commit('DELETE_IMPERSONATION')
    cache.reset()
  },
  refetchAuthFromStorage({ commit, state }) {
    let oldAuthId = null
    if (state.authId != localStorage.getItem('authId')) {
      oldAuthId = state.authId
    }
    commit('UPDATE_TOKEN', localStorage.getItem('apollo-token'))
    commit('UPDATE_AUTH_ID', localStorage.getItem('authId'))
    commit('UPDATE_IMPERSONATION', {
      authId: localStorage.getItem('impersonationBy') || null,
      token: localStorage.getItem('impersonationToken') || null,
      exitPath: localStorage.getItem('impersonationExitPath') || null
    })
    if (oldAuthId) {
      cache.data.delete(`User:${state.authId}`)
    }
  },
  impersonate({ commit, state }, value) {
    commit('UPDATE_IMPERSONATION', {
      authId: state.authId,
      role: state.role,
      token: state.token,
      exitPath: value.exitPath
    })
    commit('UPDATE_TOKEN', value.token)
    commit('UPDATE_AUTH_ID', value.authId)
  },
  endImpersonate({ commit, state }) {
    commit('UPDATE_TOKEN', state.impersonationToken)
    commit('UPDATE_AUTH_ID', state.impersonationBy)
    commit('UPDATE_ROLE', 'admin')
    commit('DELETE_IMPERSONATION')
  }
}
