<template>
  <div>
    <div class="table-scroll-container">
      <vuetable
        ref="vuetable"
        :fields="fields"
        :api-mode="false"
        data-path="data"
        :data-mode="true"
        :data="tableData"
        :css="css"
        cellspacing="0"
        width="100%"
        table-wrapper="vuetable-wrapper"
        class="y-table tr-td-px-2 tr-td-x-edge-4"
      >
        <div slot="status-slot" slot-scope="props" class="w-2">
          <template v-if="props.rowData.status.toLowerCase() == 'up'">
            <span>✅</span>
            <!-- <y-tag type="success" text="✅"></y-tag> -->
          </template>
          <template v-else>
            <span v-tooltip.left="props.rowData.status">❌</span>
          </template>
        </div>
        <div slot="uptime-slot" slot-scope="props" class="inline">
          <span
            class="font-semibold"
            :class="[
              props.rowData.uptime >= 99 ? 'text-green-500' : 'text-red-500'
            ]"
          >
            {{ props.rowData.uptime }}%</span
          >
        </div>
        <div slot="link-slot" slot-scope="props" style="max-width: 15rem;">
          <a
            :href="props.rowData.websiteUrl"
            target="_blank"
            class="link truncate block"
            >{{ props.rowData.websiteName }}</a
          >
        </div>
        <!-- <div slot="id-slot" slot-scope="props">
          <a
            :href="
              `https://app.statuscake.com/UptimeStatus.php?tid=${props.rowData.id}`
            "
            target="_blank"
            >{{ props.rowData.id }}</a
          >
        </div> -->
        <div slot="actions-slot" slot-scope="props" class="flex">
          <a
            :href="
              `https://app.statuscake.com/UptimeStatus.php?tid=${props.rowData.id}`
            "
            v-tooltip="`StatusCake`"
            target="_blank"
            class="link"
            ><Icon name="openInNew" :size="4"
          /></a>
        </div>
      </vuetable>
    </div>
  </div>
</template>

<script>
import Vuetable from '@/components/legacy/vuetable-2-2-custom/src/components/Vuetable'
import gql from 'graphql-tag'

export default {
  components: {
    Vuetable
  },
  props: {},
  apollo: {
    tableData: {
      query: gql`
        {
          statusCakeTests {
            id
            status
            websiteName
            websiteUrl
            uptime
          }
        }
      `,
      update: data => data.statusCakeTests,
      watchLoading(isLoading, countModifier) {
        console.log(countModifier)
        if (isLoading == true) {
          this.$emit('loading')
          this.isLoading = true
        } else {
          this.$emit('loaded')
          this.isLoading = false
        }
      }
    }
  },
  data() {
    return {
      tableData: [],
      fields: [
        {
          name: 'status-slot',
          title: '',
          //sortField: 'status',
          dataClass: '',
          titleClass: '',
          visible: true
        },
        {
          name: 'link-slot',
          title: 'Link',
          dataClass: '',
          titleClass: '',
          visible: true
        },
        {
          name: 'uptime-slot',
          title: 'Uptime',
          //sortField: 'uptime',
          dataClass: '',
          titleClass: '',
          width: '1rem',
          visible: true
        },
        {
          name: 'actions-slot',
          title: '',
          //sortField: 'testId',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      ],
      css: {
        tableClass: 'table table-sm table-striped dataTable',
        loadingClass: 'loading',
        ascendingIcon: 'custom-table-sort-arrow-up',
        descendingIcon: 'custom-table-sort-arrow-down',
        detailRowClass: 'vuetable-detail-row',
        sortHandleIcon: 'grey sidebar icon'
      },
      moreParams: {},
      isLoading: true
    }
  },
  mounted() {},
  updated() {},
  computed: {},
  watch: {},
  methods: {}
}
</script>
<style></style>
