var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    class: [_vm.config.backgroundClass, _vm.config.textColorClass, _vm.config.borderClass]
  }, [_c('WidgetHeader', {
    attrs: {
      "closeable": true,
      "close-class": "text-white"
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('span', {
    class: [_vm.config.textColorClass]
  }, [_vm._v("Are you sure?")])]), _c('WidgetBody', [_vm.type == 'danger' ? _c('h5', {
    staticStyle: {
      "color": "#ffc107 !important"
    }
  }, [_vm._v(" This action cannot be undone. ")]) : _vm._e(), _vm.message ? _c('p', [_vm._v(_vm._s(_vm.message))]) : _vm._e()]), _c('WidgetFooter', {
    staticClass: "flex justify-between items-center p-1",
    class: this.config.widgetFooterClass
  }, [_c('YButton', {
    staticClass: "footer-hover-btn2",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" No, cancel this action ")]), _c('YButton', {
    staticClass: "footer-hover-btn",
    on: {
      "click": _vm.confirmClicked
    }
  }, [_vm._v(" Yes, I'm sure ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }