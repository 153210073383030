<template>
  <div>
    <Spinner
      :size="12"
      v-if="$apollo.queries.dailyGraphData.loading"
      color="green"
    ></Spinner>

    <YTable v-if="dailyGraphData" :data="dailyGraphData.data || []">
      <template slot="header-row">
        <th>Date</th>
        <th>Impressions</th>
        <th>Conversions</th>
        <th v-if="dailyGraphData.data[0]?.hasOwnProperty('payout')">Payout</th>
        <th>Revenue</th>
        <th v-if="dailyGraphData.data[0]?.hasOwnProperty('netRevenue')">
          Net Revenue
        </th>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          <template v-if="props.rowData.event_date">{{
            props.rowData.event_date
          }}</template>
        </td>
        <td>
          <template>{{
            props.rowData.visits.toLocaleString('en-US')
          }}</template>
        </td>
        <td>
          <template>{{
            props.rowData.conversions.toLocaleString('en-US')
          }}</template>
        </td>
        <td v-if="props.rowData.hasOwnProperty('payout')">
          ${{ props.rowData.payout.toFixed(2).toLocaleString('en-US') }}
        </td>
        <td>
          <template
            >${{ props.rowData.revenue.toLocaleString('en-US') }}</template
          >
        </td>
        <td v-if="props.rowData.hasOwnProperty('netRevenue')">
          ${{ props.rowData.netRevenue.toFixed(2).toLocaleString('en-US') }}
        </td>
      </template>
    </YTable>
  </div>
</template>

<script>
import gql from 'graphql-tag'
export default {
  components: {},
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Array,
      default: () => ['day', 'visits', 'revenue', 'conversions']
    }
  },
  apollo: {
    dailyGraphData: {
      query: function() {
        // Use a method to define the query
        return gql`
          query getReport(
            $filters: AdminReportFilters!
            $sortOrder: [SortItem!]
            $export: Boolean
            $fields: [String]!
          ) {
            dailyGraphData: adminReport(
              filters: $filters
              sortOrder: $sortOrder
              export: $export
              fields: $fields
            ) {
              totals {
                ${this.generateFields('totals', this.fields)}
              }
              data {
                event_date: day
                ${this.generateFields('data', this.fields)}
              }
            }
          }
        `
      },
      variables() {
        return {
          filters: this.computedFilters,
          pagination: {
            perPage:
              this.$dateTime
                .fromISO(this.dateRange[1])
                .diff(this.$dateTime.fromISO(this.dateRange[0]), 'days')
                .toObject().days + 1,
            currentPage: 1
          },
          sortOrder: [{ field: 'day', sortField: 'day', direction: 'desc' }],
          export: false,
          fields: this.fields // Use the props value for 'fields'
        }
      },
      deep: true
    }
  },
  data() {
    return {
      dateRange: [
        this.$dateTime
          .local()
          .minus({ days: 14 })
          .toFormat('yyyy-LL-dd'),
        this.$dateTime.local().toFormat('yyyy-LL-dd')
      ]
    }
  },
  methods: {
    generateFields(type, fields) {
      return fields
        .map(
          field => `
        ${field}
      `
        )
        .join('\n')
    }
  },
  computed: {
    computedFilters() {
      let filters = this._.cloneDeep(this.filters)
      filters.dateRange = this.dateRange
      return filters
    }
  }
}
</script>
