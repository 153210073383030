var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', [_vm._v("Add a Postback URL")]), _c('WidgetBody', {
    staticClass: "pt-0"
  }, [!_vm.lockRedirectId && !_vm.postback.redirect && _vm.postbackLevel == 'Account' ? _c('YAlert', {
    staticClass: "mb-4",
    attrs: {
      "allow-close": false
    }
  }, [_vm._v(" Account Level Postbacks fire conversions on campaigns when no Campaign Level Postback is set. ")]) : _vm._e(), _c('form', {
    ref: "form",
    staticClass: "grid gap-2",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "bg-gray-100 p-1 rounded-md"
  }, [_vm.allowAccountLevel && !_vm.hideLevel ? _c('Superselect', {
    attrs: {
      "title": "Level",
      "multiple": false,
      "options": ['Account', 'Campaign']
    },
    on: {
      "select": _vm.accountLevelSelected
    },
    model: {
      value: _vm.postbackLevel,
      callback: function ($$v) {
        _vm.postbackLevel = $$v;
      },
      expression: "postbackLevel"
    }
  }) : _vm._e(), !_vm.lockRedirectId && _vm.postbackLevel == 'Campaign' ? _c('Superselect', {
    attrs: {
      "title": "Campaign",
      "track-by": "id",
      "label": "campaignLabel",
      "multiple": false,
      "options": _vm.redirects || []
    },
    model: {
      value: _vm.postback.redirect,
      callback: function ($$v) {
        _vm.$set(_vm.postback, "redirect", $$v);
      },
      expression: "postback.redirect"
    }
  }) : _vm._e(), _c('div', [_c('div', {
    staticClass: "flex"
  }, [_c('Superselect', {
    staticClass: "flex-shrink",
    attrs: {
      "title": "Protocol",
      "multiple": false,
      "options": _vm.protocolOptions,
      "allow-empty": false,
      "select-class": "border-r-0 rounded-r-none"
    },
    model: {
      value: _vm.postback.protocol,
      callback: function ($$v) {
        _vm.$set(_vm.postback, "protocol", $$v);
      },
      expression: "postback.protocol"
    }
  }), _c('YInput', {
    staticClass: "mb-0 flex-grow",
    attrs: {
      "label": `your-postback-url.com/?s1={S1}&revenue={REVENUE}`,
      "input-class": "border-l-0 rounded-l-none"
    },
    model: {
      value: _vm.postback.url,
      callback: function ($$v) {
        _vm.$set(_vm.postback, "url", $$v);
      },
      expression: "postback.url"
    }
  })], 1), _c('span', {
    staticClass: "text-gray-600 text-xs p-2 pb-1"
  }, [_vm._v(" Available parameters are: "), _c('span', {
    staticClass: "font-semibold text-blue-700"
  }, [_vm._v("{S1} {S2} {S3} {S4} {S5} {REVENUE}")]), _vm._v(". Case insensitive.")])])], 1), [_c('div', {
    staticClass: "bg-gray-200 p-1 rounded-md"
  }, [_vm.conversionTypes ? _c('div', [_c('Superselect', {
    attrs: {
      "title": "Conversion Type",
      "multiple": false,
      "placeholder": "Select",
      "options": _vm.conversionTypeOptions
    },
    model: {
      value: _vm.conversionType,
      callback: function ($$v) {
        _vm.conversionType = $$v;
      },
      expression: "conversionType"
    }
  })], 1) : _c('div', [_c('Superselect', {
    attrs: {
      "title": "Conversion Type",
      "multiple": false,
      "placeholder": "Select",
      "options": _vm.allConversionTypes
    },
    model: {
      value: _vm.conversionType,
      callback: function ($$v) {
        _vm.conversionType = $$v;
      },
      expression: "conversionType"
    }
  })], 1), _vm.conversionType ? _c('div', {
    staticClass: "pt-2 text-gray-900 flex align-center"
  }, [_c('Icon', {
    staticClass: "mr-1 text-indigo-500",
    attrs: {
      "name": "information",
      "size": "5"
    }
  }), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.conversionTypeDescription(_vm.conversionType))
    }
  })], 1) : _vm._e()])]], 2)], 1), _c('WidgetFooter', {
    staticClass: "bg-gray-100 px-4 py-2 flex justify-between"
  }, [_c('YButton', {
    staticClass: "btn-link",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSubmitting,
      "disabled": !_vm.canSubmit
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Create Postback")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }