import EditableActions from './EditableActions'
//import ValidationErrors from './../../classes/ValidationErrors'
import SvgLoader from '../SvgLoader'

export default {
  components: {
    EditableActions,
    SvgLoader
  },
  props: {
    id: {
      cast: Number
    },
    propValue: {
      type: [Object, Array]
    },
    displayClass: {
      type: String,
      default: 'd-inline-block'
    },
    editActionDisplayType: {
      type: String,
      default: 'icon'
    }
  },
  data() {
    return {
      actionsState: 'default',
      errors: {},
      result: null,
      isSubmitting: false,
      mutation: null,
      mutationContext: {},
      value: this.propValue ? this.propValue : null,
      minWidth: 0,
      actualDisplayClass: this.displayClass
    }
  },
  computed: {
    classes() {
      return this.actualDisplayClass
    },
    styles() {
      return `min-width: ${this.minWidth}`
    }
  },
  watch: {
    value: function() {
      this.errors = {}
    }
  },
  mounted() {},
  methods: {
    detectAndSetMinWidth() {
      this.clearMinWidth()
      let slotElement = this.$el
      let slotElementWidth = slotElement.getBoundingClientRect().width
      this.minWidth = slotElementWidth + 'px'
    },
    clearMinWidth() {
      this.minWidth = 0
    },
    submit() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: this.mutation,
          variables: this.mutationVariables,
          context: this.mutationContext
        })
        .then(result => {
          this.closeEdit()
          //toastr.success('Field Updated', 'Success!')
          this.isSubmitting = false
          this.result = result.data[Object.keys(result.data)[0]]
          this.onMutationSuccess()
          this.$emit('success', this.result)
          this.$emit('close')
        })
      /*.catch(error => {
          //let errors = new ValidationErrors()
          //errors.extractErrorsFrom(error)
          this.errors = errors.errors

          //toastr.error(error, 'Oops!')
          this.isSubmitting = false
        })*/
    },
    onMutationSuccess() {
      // Leave empty
      // This is for child editables to use as a callback in their own component
    },
    onEditState() {
      // Leave empty
      // This is for child editables to use as a callback in their own component
    },
    cancel() {
      this.closeEdit()
    },
    edit() {
      this.detectAndSetMinWidth()
      this.actionsState = 'edit'
      setTimeout(() => {
        if (this.$refs.input) {
          this.$refs.input.focus()
        }
        this.onEditState()
      })
    },
    closeEdit() {
      this.clearMinWidth()
      this.actionsState = 'default'
    }
  }
}
