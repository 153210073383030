var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', [_vm._v("Create Secondary Contact")]), _c('WidgetBody', [_c('div', {
    staticClass: "grid grid-cols-1 gap-2"
  }, [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.input.name,
      callback: function ($$v) {
        _vm.$set(_vm.input, "name", $$v);
      },
      expression: "input.name"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.input.email,
      callback: function ($$v) {
        _vm.$set(_vm.input, "email", $$v);
      },
      expression: "input.email"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Messenger Handle"
    },
    model: {
      value: _vm.input.messengerHandle,
      callback: function ($$v) {
        _vm.$set(_vm.input, "messengerHandle", $$v);
      },
      expression: "input.messengerHandle"
    }
  }), _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "Messenger App",
      "multiple": false,
      "options": ['telegram', 'skype']
    },
    model: {
      value: _vm.input.messengerType,
      callback: function ($$v) {
        _vm.$set(_vm.input, "messengerType", $$v);
      },
      expression: "input.messengerType"
    }
  })], 1), _c('div', {
    staticClass: "w-full flex justify-end gap-3 mt-4"
  }, [_c('YButton', {
    attrs: {
      "color": "gray"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Save")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }