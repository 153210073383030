<template>
  <div class="grid grid-cols-7 gap-1">
    <div v-show="false">
      <Superselect
        title="Offers"
        v-model="offers"
        track-by="id"
        label="label"
        :modifiers="offerModifiers"
        :options="offersToEdit"
      />
    </div>
    <div>
      <Superselect
        title="Account"
        v-model="accounts"
        track-by="id"
        label="label"
        :options="advertiserAccountOptions"
        :required="true"
      >
        <template slot="option" slot-scope="props">
          <div class="flex justify-between">
            <span>{{ props.option.id }} - {{ props.option.userName }}</span>
            <span
              v-if="
                props.option.yleadType === 'real' ||
                  props.option.yleadType === 'lead'
              "
              class="text-xxs"
              >{{ props.option.yleadType.toUpperCase() }}
            </span>
          </div>
        </template>
      </Superselect>
    </div>
    <div>
      <Superselect
        title="Countries"
        v-model="countries"
        :options="countryOptions"
        :modifiers="countryModifiers"
        :close-on-select="false"
        track-by="code"
        label="code"
        class=""
      />
    </div>
    <div>
      <Superselect
        title="Campaigns"
        v-model="campaigns"
        :options="[]"
        track-by="id"
        label="label"
        :modifiers="campaignModifiers"
        :disabled="redirects.length > 0 || sourceIds.length > 0"
        :query="CAMPAIGN_OPTIONS_QUERY"
        :close-on-select="false"
        :query-variables="{
          first: 100,
          filters: {
            label: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            }
          }
        }"
        class=""
      />
    </div>
    <div>
      <Superselect
        title="Redirects"
        v-model="redirects"
        :options="[]"
        track-by="id"
        label="label"
        :modifiers="redirectModifiers"
        :close-on-select="false"
        :disabled="campaigns.length > 0 || sourceIds.length > 0"
        :query="REDIRECT_OPTIONS_QUERY"
        :query-variables="{
          first: 100,
          filters: {
            label: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            }
          }
        }"
        class=""
      />
    </div>
    <div>
      <Superselect
        title="Sources"
        v-model="sourceIds"
        :modifiers="sourceModifiers"
        :options="[]"
        :forceEdit="true"
        :disabled="campaigns.length > 0 || redirects.length > 0"
        class=""
      />
    </div>
    <div>
      <YInput label="Value" class="" v-model="value" :required="true" />
    </div>
    <div class="flex justify-between">
      <div></div>
      <div>
        <YButton
          color="red"
          class="p-1"
          @click="deleteSet"
          :is-loading="isUpserting"
          ><Icon name="closeThick"
        /></YButton>
      </div>
    </div>
  </div>
</template>

<script>
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import UPDATE_REVENUE_MUTATION from '@/graphql/Revenue/UpdateRevenueMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    offersToEdit: {
      type: Array,
      required: true
    }
  },
  apollo: {
    countryOptions: {
      query: COUNTRIES_QUERY,
      update: data => data.countries,
      variables() {
        return {
          filters: this.countryFilters
        }
      }
    }
  },
  watch: {
    summary: {
      immediate: true,
      handler(newValue) {
        this.$emit('summaryUpdated', {
          id: this.id,
          data: newValue
        })
      }
    },
    upsertRevenueObjects: {
      immediate: true,
      handler(newValue) {
        this.$emit('updated', {
          id: this.id,
          data: newValue
        })
      }
    },
    value: {
      handler(value) {
        this.valueWarning(value)
      }
    }
  },
  data() {
    return {
      countryFilters: {},
      OFFER_OPTIONS_QUERY,
      CAMPAIGN_OPTIONS_QUERY,
      REDIRECT_OPTIONS_QUERY,
      UPDATE_REVENUE_MUTATION,
      accountOptions: [],
      countryOptions: [],
      offers: this.offersToEdit,
      accounts: [],
      countries: [],
      campaigns: [],
      redirects: [],
      sourceIds: [],
      value: null,
      isUpserting: false,
      valueLimit: 300,
      offerModifiers: {
        edit: false
      },
      accountModifiers: {
        edit: false
      },
      campaignModifiers: {
        edit: false
      },
      countryModifiers: {
        edit: false
      },
      redirectModifiers: {
        edit: false
      },
      sourceModifiers: {
        edit: false
      }
    }
  },
  mounted() {
    this.$events.listen('revenueDeleted', () => {
      this.$apollo.queries.revenues.refetch()
    })
    this.$events.listen('revenueUpserted', () => {
      this.$apollo.queries.revenues.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('revenueDeleted')
    this.$events.remove('revenueUpserted')
    this.$events.remove('internalValue')
  },
  updated() {},
  computed: {
    summary() {
      return {
        offers: this.offers,
        accounts: this.accounts,
        countries: this.countries,
        campaigns: this.campaigns,
        redirects: this.redirects,
        sources: this.sourceIds,
        value: this.value
      }
    },
    advertiserAccountOptions() {
      if (!this.offers || this.offers.length < 0) {
        return []
      }

      return this.offers[0].advertisers[0].advertiserAccounts
    },
    itemTemplate() {
      return {
        accountId: null,
        countryCode: null,
        campaignId: null,
        redirectId: null,
        sourceIds: null,
        value: this.value
      }
    },
    offerIds() {
      return this.offers.map(offer => offer.id)
    },
    accountIds() {
      return this.accounts.map(account => account.id)
    },
    countryCodes() {
      return this.countries.map(country => country.code)
    },
    campaignIds() {
      return this.campaigns.map(campaign => campaign.id)
    },
    redirectIds() {
      return this.redirects.map(redirect => redirect.id)
    },
    upsertRevenueKeys() {
      return this.getPermutations(
        this.getPermutations(
          this.getPermutations(
            this.getPermutations(
              this.getPermutations(this.offerIds, this.accountIds),
              this.countryCodes
            ),
            this.campaignIds
          ),
          this.redirectIds
        ),
        this.sourceIds
      )
    },
    upsertRevenueObjects() {
      let keyOrder = [
        'offerId',
        'accountId',
        'countryCode',
        'campaignId',
        'redirectId',
        'sourceId'
      ]

      let output = this.upsertRevenueKeys.map(key => {
        let values = key.split('-')
        let output = {}
        values.forEach((value, index) => {
          output[keyOrder[index]] = value || null
        })
        output.value = this.value
        return output
      })

      return output
    }
  },
  methods: {
    deleteSet() {
      this.$emit('deleted', this.id)
    },
    valueWarning(value) {
      if (value > this.valueLimit) {
        this.$toastr.Add({
          type: 'error',
          title: 'High Value Entered',
          msg: 'Are your sure you entered the correct amount?',
          position: 'toast-bottom-right',
          timeout: '7000',
          progressbar: false
        })
      }
    },
    getPermutations(array1, array2) {
      if (array2.length > 0) {
        var result = array1.reduce((a, v) => {
          return [...a, ...array2.map(x => v + '-' + x)]
        }, [])
        return result
      } else {
        return array1.map(v => v + '-')
      }
    }
  }
}
</script>

<style scoped>
ul.x-activity-list > li {
  @apply border-b;
}

ul.x-activity-list > li:last-child {
  @apply border-none;
}

/* ul.x-activity-list > li:nth-child(odd) {
  @apply bg-gray-100;
} */

.x-activity-item {
  @apply border-b;
}

.x-activity-item:last-child {
  @apply border-none;
}
</style>
