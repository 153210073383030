<template>
  <div class="grid grid-flow-row">
    <div class="col-span-1">
      {{ rowData.level.capitalize() }}
    </div>
    <div class="col-span-1" v-if="link">
      <template v-if="link"
        >(<router-link
          :to="{
            name: this.rowData.level.capitalize(),
            params: { id: link.id, tab: 'payouts' }
          }"
          class="link"
          >{{ link.id }}</router-link
        >)</template
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {}
  },
  computed: {
    link() {
      let level = this.rowData.level.toLowerCase()
      let id
      let href
      switch (level) {
        case 'source':
          id = this.rowData.sourceId
          href = '/admin/source/' + this.rowData.sourceId
          break
        case 'redirect':
          id = this.rowData.redirectId
          href = '/admin/redirect/' + this.rowData.redirectId
          break
        case 'default campaign':
        case 'user campaign':
          id = this.rowData.campaignId
          href = '/admin/campaign/' + this.rowData.campaignId
          break
      }
      return id && href ? { id: id, href: href } : null
    }
  },
  mounted() {},
  updated() {},

  methods: {}
}
</script>
<style scoped></style>
