var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-right flex gap-3 justify-start items-center"
  }, [_c('div', [_c('v-popover', {
    staticClass: "inline-block h-4 cursor-pointer relative",
    staticStyle: {
      "top": "3px"
    },
    attrs: {
      "placement": "left",
      "offset": "4"
    }
  }, [_c('a', {
    staticClass: "tooltip-target inline-block text-gray-400 hover:text-gray-500"
  }, [_c('Icon', {
    attrs: {
      "name": "history"
    }
  })], 1), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "px-2 rounded"
  }, [_vm.rowData.activity && _vm.rowData.activity.length > 0 ? _vm._l(_vm.rowData.activity, function (activity) {
    return _c('div', {
      key: activity.id,
      staticClass: "x-activity-item text-xs py-2"
    }, [_c('div', {
      staticClass: "flex items-center justify-between"
    }, [_c('div', {
      staticClass: "font-bold mr-6"
    }, [_vm._v(" " + _vm._s(_vm._f("luxon")(activity.updatedAt, {
      output: {
        format: 'yyyy-MM-dd @ h:mm a'
      }
    })) + " ")]), _c('div', {
      staticClass: "font-semibold text-blue-600"
    }, [_vm._v(" " + _vm._s(activity.description.capitalize()) + " ")])]), _c('div', {
      staticClass: "flex justify-between"
    }, [_c('div', {
      staticClass: "text-gray-500 italic"
    }, [activity.causer ? [_vm._v(" " + _vm._s(activity.causer.email) + " ")] : _vm._e()], 2), _c('ul', {
      staticClass: "x-activity-list ml-6"
    }, _vm._l(activity.properties, function (property, key) {
      return _c('li', {
        key: key
      }, [_c('span', {
        staticClass: "text-gray-500"
      }, [_vm._v(_vm._s(key) + ":")]), _vm._v(" " + _vm._s(property) + " ")]);
    }), 0)])]);
  }) : _c('div', {
    staticClass: "text-gray-400 py-2"
  }, [_vm._v("No History")])], 2)])], 2)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }