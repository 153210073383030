var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "bg-red-600 border-red-700 text-white"
  }, [_c('WidgetHeader', {
    staticClass: "bg-red-600"
  }, [_c('span', {
    staticClass: "text-white"
  }, [_vm._v("Are you sure?")])]), _c('WidgetBody', [_c('h5', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v("This action cannot be undone.")]), _c('h5', [_vm._v(" Please confirm you'd like to delete the Postback with the following details: ")]), _c('ul', {
    staticClass: "text-xs my-4"
  }, [_c('li', [_vm._v("ID: " + _vm._s(this.id))]), _c('li', [_vm._v("Postback: " + _vm._s(this.url))])])]), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 flex p-4 justify-between"
  }, [_c('y-button', {
    staticClass: "hover:bg-red-700 focus:bg-red-700",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('y-button', {
    staticClass: "hover:bg-red-700 focus:bg-red-700",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Delete Postback")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }