<template
  ><div class="">
    <!-- Legacy User, agree to updated t&c's -->
    <YAlert
      v-if="
        me &&
          me.isAPublisher &&
          !me.userDetails.agreedToTerms &&
          me.userDetails.legacyAgreedToTerms
      "
      color="red"
      :allow-close="false"
      class="mt-0 mb-0 px-6"
    >
      <span class="font-bold">
        🔔 Important Notice: Updated Terms and Conditions</span
      >
      <p>
        Our
        <router-link
          to="/publisher/account/terms"
          class="underline font-semibold"
          >terms and conditions</router-link
        >
        have been updated and will be effective from July 1st, 2024. Please
        review and accept the new
        <router-link
          to="/publisher/account/terms"
          class="underline font-semibold"
          >terms</router-link
        >
        as soon as possible to continue using our services without interruption.
      </p>
    </YAlert>

    <!-- New User Alert -->
    <YAlert
      :allow-close="false"
      color="blue"
      v-if="
        me &&
          me.isAPublisher &&
          !me.userDetails.agreedToTerms &&
          !me.userDetails.legacyAgreedToTerms
      "
      class="mt-0 mb-0 px-6"
    >
      <div class="flex justify-between items-center">
        <div>
          <h6 class="mb-0 font-weight-semibold">
            You must accept
            <span class="font-bold">YTZ Terms & Conditions</span> before viewing
            campaigns. You will be redirected to the dashboard upon acceptance
            of terms.
          </h6>
        </div>
      </div>
    </YAlert>
  </div>
</template>

<script>
import ME_QUERY from '@/graphql/User/MeQuery.gql'

export default {
  apollo: {
    me: {
      query: ME_QUERY
    }
  },
  methods: {}
}
</script>

<style></style>
