<template>
  <div>
    <div class="row">
      <div class="col">
        <Widget class="mb-1">
          <WidgetHeader class="bg-gray-100">Upload Balance CSV</WidgetHeader>
          <WidgetBody class="pt-0 bg-gray-100">
           <template>
                <form
                    id="new-bulk-rule-form"
                    name="bulk-rule-form"
                    @submit="submitForm"
                    ref="form"
                    @keydown="errors.clear($event.target.name)"
                    @change="errors.clear($event.target.name)"
                >
                    <div>
                    <div class="flex w-full items-center justify-center mb-3">
                        <label
                        :class=buttonCSV
                        >
                        <svg
                            class="w-8 h-8"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path
                            d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                            />
                        </svg>
                        <span v-if="csvFileName" class="mt-2 text-base leading-normal">{{
                            csvFileName
                        }}</span>
                        <span v-else class="mt-2 text-base leading-normal uppercase"
                            >Upload CSV</span
                        >
                        <input
                            type="file"
                            class="hidden"
                            @change="onCsvChange"
                            ref="bulkCsvInput"
                        />
                        </label>
                    </div>
                    </div>
                    <div class="flex justify-end">
                    <YButton v-if="uploadAvailable == true && uploadCount == 0" class="link mr-2" @click.prevent="uploadCSV">Upload CSV</YButton>
                    <YButton v-if="uploadCount > 0" class="link mr-2">Uploads Remaining {{ this.uploadCount}} </YButton>
                    <YButton class="link mr-2" @click.prevent="clearForm">Clear CSV</YButton>
                    </div>
                </form>
                </template>
          </WidgetBody>
        </Widget>
      </div>
    </div>
  </div>
</template>

<script>

class Errors {
  constructor() {
    this.errors = {}
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0]
    }
  }

  record(errors) {
    this.errors = errors
  }

  clear(field) {
    delete this.errors[field]
  }
}

import Papa from 'papaparse'
import gql from 'graphql-tag'

export default {
  components: {},
  props: {},
  apollo: {
    $subscribe: {
      balanceUploaded: {
        query: gql`
          subscription UserBalanceUpdatedSubscription {
            userBalanceUpdatedSubscription{
                status
                errors
            }
          }
        `,
        variables() {
          return {}
        },
        result(data) {
          this.uploadCount = this.uploadCount - 1
          let result = data.data.userBalanceUpdatedSubscription
          if (result.error != null){
              this.$toastr.e('', result.errors)
          }else {
              let resultsArray = result.status.split(",")
              this.updatedIds.push(...resultsArray)
              this.$toastr.s('','Success: ' + resultsArray.length + " User balance's updated")
          }
          if(this.uploadCount == 0){
            this.resetBlanace()
            this.clearForm()
          }
        }
      }
    }
  },
  data() {
    let initialData = {
      bulk: true,
      type: 'payout',
      csvData: null,
      notes: null
    }

    return {
      data: this._.clone(initialData, true),
      initialData: this._.clone(initialData, true),
      csvFileName: null,
      errors: new Errors(),
      uploadAvailable: false,
      uploadCount:0,
      uploadData: [],
      updatedIds: [],
    }
  },
  computed: {
    buttonCSV(){
      if (this.uploadCount > 0){
         return "w-64 flex flex-col items-center px-4 py-6 bg-white text-grey-600 rounded-lg shadow-lg tracking-wide border border-grey-500 cursor-pointer hover:bg-grey-600 hover:text-black"
      }     
      return "w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-600 rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-600 hover:text-white"
    },
    userBalanceCSVParse() {
      let rows = this.data.csvData
      let data = []
      if (rows) {
        var i = 1
        var account = ''
        for (let row of rows) {
          if (row.Type != 'Bill') {
            continue
          }
          account = row["Name E-Mail"]
          if (data[account] == undefined){
            data[account] = []
          }
           if (data[account][i] == undefined){
             data[account][i] = {}
          }

          data[account][i].type = row.Type
          data[account][i].date = row["Date"]
          data[account][i].openBalance = row["Open Balance"]
          i++
        }
      }
      return data
    }
  },
  methods: {
    parseCsv() {
      this.uploadData = this.userBalanceCSVParse
      this.uploadAvailable = true
    },
    clearPreview() {
       this.uploadAvailable = false
       this.uploadData = {}
    },
    resetBlanace(){
      this.$apollo.mutate({
        mutation: gql`
            mutation ResetUserBalanceByIds($input: ResetUserBalanceInput) {
                resetUserBalanceByIds(input: $input) {
                    status
                    errors
                }
            }
        `,
        variables: {
            input: {
              'ids': this.updatedIds
            }
        }
      })
      .then(() => {
        this.$toastr.i("User balances not in csv reset");
        this.updatedIds = []
      })
    },
    uploadCSV() {
        this.uploadAvailable = false
        let input = []
        let remaingCount = 0 
        //for some reason the legnth on the array is allways 0 so having to do this jank to get the lenght
        //and limits
        /* eslint-disable no-unused-vars */
        for (const key in this.uploadData) {
          //yay ts-lint
          remaingCount++
        }
        console.log(remaingCount)
        for (const key in this.uploadData) {
           input.push({
                        userEmail: key,
                        balanceInfo: this.uploadData[key].filter(n => n)
                      })
            //so we can have a total of 206 this insure we send in batches of atleast 20 untill there is
            //an amount less than 10 remaning then fire the rest         
            remaingCount--          
            if (input.length >= 200 || remaingCount == 0){
              this.uploadCount++
              this.$apollo.mutate({
                mutation: gql`
                    mutation UpdateUserBalanceInput($input: [UpdateUserBalanceInput]) {
                        broadcastUserBalanceUpdate(input: $input) {
                            status
                            errors
                        }
                    }
                `,
                variables: {
                    input: input
                }
              })
              .then(() => {
                this.$toastr.i("Upload queued ");
              })
              input = []
              setTimeout(() => {console.log('working...')  }, 3000);
            }          
        }
        console.log(this.uploadCount)
    },
    submitForm(event) {
      event.preventDefault()
    },
    onCsvChange(event) {
      let files = event.target.files || event.dataTransfer.files
      if (files[0]) {
        var myReader = new FileReader()
        myReader.onload = () => {
          var csv = myReader.result

          var results = Papa.parse(csv, {
            header: true,
            skipEmptyLines: true
          })
          this.data.csvData = results.data
          this.csvFileName = files[0].name
        }

        myReader.readAsText(files[0])
        this.$nextTick(() => {
          setTimeout(() => {
            this.parseCsv()
          }, 100)
        })
      }
    },
    catchErrors(errors) {
      if (errors.form == this.$refs.form) {
        this.errors.record(errors.errors)
      }
    },
    clearForm() {
      this.data = this._.clone(this.initialData, true)
      this.csvFileName = null
      this.$refs.bulkCsvInput.value = null
      this.uploadCount = 0
      this.clearPreview()
    }
  },
  mounted() {
    this.$events.listen('submitUploadForm', eventData =>
      this.enableLoadingPreview(eventData)
    )
    this.$events.listen('disableUploadLoadingPreview', eventData =>
      this.disableLoadingPreview(eventData)
    )
  },
  beforeDestroy() {
    this.$events.remove('submitUploadForm')
    this.$events.remove('disableUploadLoadingPreview')
  },
  updated() {}
}
</script>
<style scoped>
.form-group .multiselect {
  margin-bottom: 0.5rem;
}
.form-group .multiselect:last-child {
  margin-bottom: 0rem;
}

.widget-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.tiny-forms .input-group-btn .btn {
  line-height: 1.3;
}
</style>
