<template>
  <div>
    <portal to="top-bar-right">
      <YButton class="btn btn-blue" @click="addImage">Add New Image</YButton>
    </portal>
    <ImageRepositoryTableWrapper></ImageRepositoryTableWrapper>
  </div>
</template>

<script>
import ImageRepositoryTableWrapper from '@/views/Admin/ImageRepository/ImageRepositoryTableWrapper.vue'
import AddImage from '@/views/Admin/ImageRepository/AddImageToRepositoryModal.vue'

export default {
  props: {},
  components: {
    ImageRepositoryTableWrapper
  },

  methods: {
    addImage() {
      this.$modal.show(
        AddImage,
        {},
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  },
  updated() {}
}
</script>
