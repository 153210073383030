<template>
  <Widget>
    <WidgetHeader @close="$emit('close')">
      Send Email?
    </WidgetHeader>
    <WidgetBody class="pt-2">
      <template v-if="redirect">
        <p class="mb-2">
          Would you like to send a status update email for:
        </p>
        <ul>
          <li>
            <span class="label-column">User:</span>
            {{ redirect.user.label }}
          </li>
          <li>
            <span class=" label-column">Redirect:</span>
            {{ `${redirect.subdomain}.${redirect.domain}` }} ({{ redirectId }})
          </li>
          <li>
            <span class="label-column">Status:</span>
            <span
              class="font-bold"
              :class="[
                TextColorMap[redirect.status]
                  ? `text-${TextColorMap[redirect.status]}-500`
                  : ''
              ]"
              >{{ redirect.status.toUpperCase() }}</span
            >
          </li>
        </ul>
      </template>
      <Spinner v-else color="green" class="my-4" :size="12" />
    </WidgetBody>
    <WidgetFooter class="bg-gray-100 px-4 py-2">
      <div class="flex justify-between">
        <YButton class="btn-link" @click="$emit('close')">Do Not Send</YButton>
        <YButton
          class="btn-blue"
          @click="sendStatusUpdateEmail(redirectId)"
          :is-loading="isSubmitting"
        >
          Send Email
        </YButton>
      </div>
    </WidgetFooter>
  </Widget>
</template>

<script>
import SEND_REDIRECT_STATUS_UPDATE_EMAILS_MUTATION from '@/graphql/Redirect/SendRedirectStatusUpdateEmailsMutation.gql'
import REDIRECT_QUERY from '@/graphql/Redirect/RedirectQuery.gql'
import TextColorMap from '@/assets/js/TextColorMap'

export default {
  props: {
    redirectId: {
      type: Number,
      required: true
    }
  },
  apollo: {
    redirect: {
      query: REDIRECT_QUERY,
      variables() {
        return {
          id: this.redirectId
        }
      }
    }
  },
  data() {
    return {
      isSubmitting: false,
      TextColorMap
    }
  },
  methods: {
    sendStatusUpdateEmail(redirectId) {
      if (!redirectId) {
        return
      }
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: SEND_REDIRECT_STATUS_UPDATE_EMAILS_MUTATION,
          variables: {
            redirectIds: [redirectId]
          }
        })
        .then(result => {
          this.isSubmitting = false
          this.$toastr.s('', 'Email Added To Queue')
          console.log(result)
          this.$emit('close')
        })
        .catch(error => {
          this.isSubmitting = false
          this.$toastr.e(
            'Email failed for redirect ID:  ' + redirectId,
            'Oops!'
          )
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>
.label-column {
  @apply text-right w-24 inline-block mr-2 text-xs uppercase text-indigo-600;
}
ul li {
  @apply mb-1;
}
</style>
