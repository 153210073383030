var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('y-multiselect', {
    class: _vm.className,
    attrs: {
      "value": _vm.value,
      "id": _vm.id,
      "tag-placeholder": "Add",
      "placeholder": _vm.placeholder,
      "label": _vm.label,
      "options": _vm.options,
      "multiple": true,
      "track-by": _vm.trackBy,
      "taggable": true,
      "close-on-select": false,
      "max": _vm.max,
      "addTagOnBlur": true,
      "disabled": _vm.disabled
    },
    on: {
      "tag": _vm.addTag,
      "remove": _vm.removeTag,
      "paste": _vm.processPaste,
      "input": function ($event) {
        return _vm.updateValue(_vm.value);
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }