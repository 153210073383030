<template>
  <div v-show="hasDefaultSlot" @click="impersonate">
    <slot></slot>
  </div>
</template>

<script>
import IMPERSONATE_MUTATION from '@/graphql/Auth/ImpersonateMutation.gql'

export default {
  props: {
    userId: {
      type: [Number, String],
      required: true
    },
    path: {
      type: String,
      default: null
    }
  },
  methods: {
    impersonate() {
      let currentRoutePath = this.$route.path
      this.$apollo
        .mutate({
          // Query
          mutation: IMPERSONATE_MUTATION,
          // Parameters
          variables: {
            input: {
              userId: this.userId
            }
          }
        })
        .then(data => {
          this.$store
            .dispatch('impersonate', {
              token: data.data.impersonate.access_token,
              authId: data.data.impersonate.user.id,
              role: data.data.impersonate.user.roles[0].name,
              exitPath: currentRoutePath
            })
            .then(() => {
              if (this.path) {
                let scope = data.data.impersonate.user.isAnAdmin
                  ? 'admin'
                  : data.data.impersonate.user.isAPublisher
                  ? 'publisher'
                  : data.data.impersonate.user.isAnAdvertiser
                  ? 'advertiser'
                  : null
                this.$router.push(this.path.replace('{scope}', scope))
              } else {
                data.data.impersonate.user.userDetails.agreedToTerms
                  ? this.$router.push({ name: 'Publisher Dashboard' })
                  : this.$router.push('/publisher/account/terms')
              }
            })
        })
        .catch(error => {
          //this.$store.dispatch('logout')
          // Error
          console.error(error)
        })
    }
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    }
  }
}
</script>

<style></style>
