<template>
  <span :class="classes" :style="styles">
    <template v-if="actionsState == 'default'">
      <a @click="edit" class="editable-hover d-inline-block">
        <slot></slot>
      </a>
      <editable-actions
        :state="actionsState"
        @edit="edit"
        @submit="submit"
        @cancel="cancel"
        :edit-action-display-type="editActionDisplayType"
      ></editable-actions>
    </template>
    <template v-if="actionsState == 'edit'">
      <y-form-group :error-strings="errors.name" class="mb-1">
        <input
          class="form-control"
          type="text"
          v-model="value"
          @keydown.enter="submit"
          @keydown.esc="cancel"
        />
      </y-form-group>
      <editable-actions
        :submitting="isSubmitting"
        :state="actionsState"
        @edit="edit"
        @submit="submit"
        @cancel="cancel"
      ></editable-actions>
    </template>
  </span>
</template>

<script>
import EditableMixin from '@/components/legacy/Editables/EditableMixin.js'
import gql from 'graphql-tag'

export default {
  mixins: [EditableMixin],
  apollo: {
    advertiserAccount: {
      query: gql`
        query getAdvertiserAccount($id: ID!) {
          advertiserAccount(id: $id) {
            id
            password
          }
        }
      `,
      variables() {
        return {
          id: this.id
        }
      },
      result(result) {
        this.value = result.data.advertiserAccount.password
      },
      fetchPolicy: 'network-only',
      skip() {
        return this.actionsState != 'edit'
      }
    }
  },
  data() {
    return {
      mutation: gql`
        mutation UpdateAdvertiserAccount(
          $input: UpdateAdvertiserAccountInput!
        ) {
          updateAdvertiserAccount(input: $input) {
            id
            password
          }
        }
      `
    }
  },
  computed: {
    mutationVariables() {
      return {
        input: {
          id: this.id,
          password: this.value
        }
      }
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
