<template>
  <div>
    <vuetable
      ref="vuetable"
      class="header-gray-100"
      :api-mode="false"
      :fields="fields"
      :data="data"
      pagination-path="pagination"
      data-path="data"
      :css="css"
      :sort-order="sortOrder"
      :multi-sort="multiSort"
      :per-page="perPage"
      multi-sort-key="shift"
      cellspacing="0"
      width="100%"
      table-wrapper="vuetable-wrapper"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:loading="loading"
      @vuetable:loaded="loaded"
    >
      <div slot="id-slot" slot-scope="props">
        <router-link
          :to="{ name: 'Source', params: { id: props.rowData.id } }"
          class="link"
          >{{ props.rowData.id }}</router-link
        >
      </div>
      <div slot="user-slot" slot-scope="props">
        <template v-if="props.rowData.user">
          <router-link
            :to="{ name: 'User', params: { id: props.rowData.user.id } }"
            class="link"
            >{{ props.rowData.user.label }}</router-link
          >
        </template>
      </div>
      <div slot="redirect-slot" slot-scope="props">
        <template v-if="props.rowData.redirect">
          <div>
            <router-link
              :to="{
                name: 'Redirect',
                params: { id: props.rowData.redirect.id }
              }"
              class="link"
              >{{ props.rowData.redirect.label }}</router-link
            >
          </div>
          <!-- <div>
            <i
              v-if="props.rowData.rules && props.rowData.rules.length > 0"
              class="zmdi zmdi-alert-triangle text-warning pr-2"
              v-tooltip="
                `This source has active group rules. The redirect group service listed here may not always be used for this source.`
              "
            ></i>
          </div> -->
        </template>
      </div>
      <div slot="details-slot" slot-scope="props">
        <div>
          <Tag
            v-if="props.rowData.isAbyssed"
            type="danger"
            style="margin-top:1px; margin-bottom: 1px;"
            color="pink"
          >
            <i class="zmdi zmdi-block pr-1"></i> Abyssed
          </Tag>
        </div>
        <div>
          <Tag
            v-if="props.rowData.payouts"
            type="info"
            style="margin-top:1px; margin-bottom: 1px;"
            color="purple"
          >
            <i class="zmdi zmdi-check pr-1"></i> Payouts
          </Tag>
        </div>
      </div>
      <div slot="stats-slot" slot-scope="props">
        <div class="flex items-center">
          <div class="x-stat-wrapper">
            <Tag
              v-if="props.rowData.impressions"
              color="light"
              v-tooltip="`Impressions`"
              style="margin-top:1px; margin-bottom: 1px;"
              class="mr-2"
              >{{ $formatInt(props.rowData.impressions) }}</Tag
            >
          </div>
          <div class="x-stat-wrapper">
            <Tag
              v-if="props.rowData.revenue"
              color="teal"
              v-tooltip="`Revenue`"
              style="margin-top:1px; margin-bottom: 1px;"
              >{{ $formatMoney(props.rowData.revenue) }}</Tag
            >
          </div>
        </div>
      </div>
    </vuetable>
  </div>
</template>

<script>
import Vuetable from '@/components/legacy/vuetable-2-2-custom/src/components/Vuetable'

export default {
  components: {
    Vuetable
  },
  props: {
    data: {
      default: () => {
        ;[]
      },
      type: Array
    }
  },
  data() {
    return {
      fields: [
        {
          name: 'id-slot',
          title: 'ID'
        },
        {
          name: 'keyword',
          title: 'Keyword'
        },
        {
          name: 'user-slot',
          title: 'Publisher'
        },
        {
          name: 'redirect-slot',
          title: 'Redirect'
        },
        {
          name: 'details-slot',
          title: ''
        },
        {
          name: 'stats-slot',
          title: 'Stats'
        }
      ],
      perPage: 50,
      sortOrder: [],
      multiSort: false,
      isLoading: false,
      filters: {},
      css: {
        tableClass:
          'table table-sm table-striped dt-responsive nowrap dataTable',
        loadingClass: 'loading',
        ascendingIcon: 'custom-table-sort-arrow-up',
        descendingIcon: 'custom-table-sort-arrow-down',
        detailRowClass: 'vuetable-detail-row',
        sortHandleIcon: 'grey sidebar icon'
      }
    }
  },
  computed: {},
  mounted() {},

  updated() {},

  methods: {
    onPaginationData() {
      this.$events.emit('onPaginationData', true)
    },
    onChangePage(page) {
      this.$events.emit('changeReportPage', page)
    },
    loading() {
      this.$events.emit('loading', true)
    },
    loaded() {
      this.$events.emit('loaded', true)
    },
    transformDate(value) {
      var d = new Date(value)
      var n = d
        .toDateString()
        .split(' ')
        .slice(1)
        .join(' ')
      return n
    }
  }
}
</script>
<style scoped>
.x-stat-wrapper {
  @apply w-20;
}
</style>
