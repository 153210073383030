var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_vm.me && _vm.me.isAPublisher && !_vm.me.userDetails.agreedToTerms && _vm.me.userDetails.legacyAgreedToTerms ? _c('YAlert', {
    staticClass: "mt-0 mb-0 px-6",
    attrs: {
      "color": "red",
      "allow-close": false
    }
  }, [_c('span', {
    staticClass: "font-bold"
  }, [_vm._v(" 🔔 Important Notice: Updated Terms and Conditions")]), _c('p', [_vm._v(" Our "), _c('router-link', {
    staticClass: "underline font-semibold",
    attrs: {
      "to": "/publisher/account/terms"
    }
  }, [_vm._v("terms and conditions")]), _vm._v(" have been updated and will be effective from July 1st, 2024. Please review and accept the new "), _c('router-link', {
    staticClass: "underline font-semibold",
    attrs: {
      "to": "/publisher/account/terms"
    }
  }, [_vm._v("terms")]), _vm._v(" as soon as possible to continue using our services without interruption. ")], 1)]) : _vm._e(), _vm.me && _vm.me.isAPublisher && !_vm.me.userDetails.agreedToTerms && !_vm.me.userDetails.legacyAgreedToTerms ? _c('YAlert', {
    staticClass: "mt-0 mb-0 px-6",
    attrs: {
      "allow-close": false,
      "color": "blue"
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_c('h6', {
    staticClass: "mb-0 font-weight-semibold"
  }, [_vm._v(" You must accept "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("YTZ Terms & Conditions")]), _vm._v(" before viewing campaigns. You will be redirected to the dashboard upon acceptance of terms. ")])])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }