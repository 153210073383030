var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "relative"
  }, [_c('Superselect', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.bulkPasteEnabled,
      expression: "!bulkPasteEnabled"
    }],
    attrs: {
      "title": _vm.title,
      "modifiers": _vm.modifiers,
      "multiple": _vm.multiple,
      "searchable": true,
      "internal-search": false,
      "clear-on-select": true,
      "close-on-select": _vm.closeOnSelect,
      "options-limit": _vm.optionsLimit,
      "allow-empty": _vm.allowEmpty,
      "limit": _vm.limit,
      "limit-text": function (count) {
        return `and ${count} more`;
      },
      "show-no-results": true,
      "hide-selected": _vm.hideSelected,
      "label": "name",
      "track-by": _vm.trackBy,
      "options": _vm.tags ? _vm.tags.data || [] : [],
      "loading": _vm.$apollo.queries.tags.loading
    },
    on: {
      "search-change": _vm.filterTags,
      "select": _vm.onSelect,
      "remove": _vm.onRemove
    },
    scopedSlots: _vm._u([_vm.allowBulkPaste ? {
      key: "beforeControlIcons",
      fn: function () {
        return [_c('ToggleLink', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.isPasting,
            expression: "!isPasting"
          }],
          staticClass: "control-icon-btn py-1 px-px cursor-pointer block",
          on: {
            "input": _vm.bulkPasteEnabledUpdated
          },
          model: {
            value: _vm.bulkPasteEnabled,
            callback: function ($$v) {
              _vm.bulkPasteEnabled = $$v;
            },
            expression: "bulkPasteEnabled"
          }
        }, [!_vm.$apollo.queries.tags.loading ? _c('Icon', {
          class: ['h-4 w-4', _vm.bulkPasteEnabled ? 'text-blue-500 hover:text-blue-600' : 'text-gray-400 hover:text-gray-500'],
          attrs: {
            "name": "pencil"
          }
        }) : _vm._e()], 1), _c('a', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.isPasting,
            expression: "!isPasting"
          }],
          staticClass: "control-icon-btn py-1 px-px cursor-pointer block text-gray-400 hover:text-red-500",
          on: {
            "click": function () {
              return _vm.localValue = [];
            }
          }
        }, [!_vm.$apollo.queries.tags.loading ? _c('Icon', {
          class: ['h-4 w-4'],
          attrs: {
            "name": "trashCan"
          }
        }) : _vm._e()], 1), _c('Spinner', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isPasting,
            expression: "isPasting"
          }],
          staticClass: "my-1 mx-px",
          attrs: {
            "color": "blue"
          }
        })];
      },
      proxy: true
    } : null, {
      key: "option",
      fn: function (_ref) {
        var option = _ref.option;
        return [_c('div', {
          staticClass: "flex justify-between items-center"
        }, [_c('Tag', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `${option.type.capitalize()}`,
            expression: "`${option.type.capitalize()}`"
          }],
          attrs: {
            "color": option.color
          }
        }, [_vm._v(" " + _vm._s(option.name) + " ")]), _vm.showOptionsTypeLabel ? _c('div', [_vm._v(_vm._s(option.type.capitalize()))]) : _vm._e()], 1)];
      }
    }, {
      key: "tag",
      fn: function (props) {
        return [_c('Tag', {
          staticClass: "inline-flex",
          staticStyle: {
            "margin": "1px"
          },
          attrs: {
            "color": _vm.isNotTypes.includes(`${props.option.type}`) || _vm.modifiers.exclude === true ? 'red' : 'blue'
          },
          scopedSlots: _vm._u([_vm.enableNotToggles ? {
            key: "icon",
            fn: function (iconProps) {
              return [_c('button', {
                class: `hover:bg-${iconProps.color}-200 text-${iconProps.color}-${iconProps.color == 'red' ? '600' : '400'} hover:text-${iconProps.color}-${iconProps.color == 'red' ? '800' : '600'} -mr-3 rounded-full relative block outline-none-important`,
                staticStyle: {
                  "padding-left": "0.35rem",
                  "padding-right": "0.35rem"
                },
                on: {
                  "click": function ($event) {
                    return _vm.toggleIsNot(`${props.option.type}`);
                  }
                }
              }, [_c('Icon', {
                staticClass: "w-3 h-3",
                attrs: {
                  "name": "exclamationThick"
                }
              })], 1)];
            }
          } : null, {
            key: "afterIcon",
            fn: function (afterIconProps) {
              return [_c('button', {
                class: `hover:bg-${afterIconProps.color}-300 -ml-2 rounded-full outline-none-important`,
                staticStyle: {
                  "padding-left": "0.35rem",
                  "padding-right": "0.35rem"
                },
                on: {
                  "click": function ($event) {
                    return props.remove(props.option);
                  }
                }
              }, [_c('Icon', {
                staticClass: "w-3 h-3 block",
                attrs: {
                  "name": "closeThick"
                }
              })], 1)];
            }
          }], null, true)
        }, [_vm._v(" " + _vm._s(props.option.name) + " ")])];
      }
    }], null, true),
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v;
      },
      expression: "localValue"
    }
  }), _c('YInput', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.bulkPasteEnabled,
      expression: "bulkPasteEnabled"
    }],
    ref: "pasteInput",
    attrs: {
      "label": _vm.title,
      "placeholder": _vm.pasteInputPlaceholder,
      "value": ""
    },
    on: {
      "paste": _vm.tagsPasted,
      "keypress": function (event) {
        event.preventDefault();
      },
      "blur": function () {
        _vm.bulkPasteEnabled = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }