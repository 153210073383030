var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.campaigns ? _c('YTable', {
    attrs: {
      "data": _vm.campaigns.data || []
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_c('campaign-info-cell', {
          key: `campaign-info-${props.rowData.id}`,
          staticClass: "-ml-4 -my-1",
          attrs: {
            "row-data": props.rowData,
            "row-index": props.rowIndex,
            "minimalView": true
          }
        })], 1)];
      }
    }], null, false, 3636913378)
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }