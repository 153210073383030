<template>
    <widget :loading="!offers.data" class="pt-5">
      <widget-header @close="$emit('close')">
        Deactivate Offers
      </widget-header>
  
      <widget-body v-if="offers.data">
        <div class="flex flex-col shadow-md mb-4  rounded-lg">
          <div
            class=" flex items-center justify-center h-12 bg-red-600 text-white rounded-t-lg "
          >
            <Icon name="alertCircleOutline" size="8" />
          </div>
          <div class=" text-center text-lg pt-8">
            Are you sure you want to deactivate
            <span class="font-extrabold text-red-700">{{
              this.offers.data.length
            }}</span>
            offers?
          </div>
          <div class=" text-center space-x-10 py-6">
            <YButton color="gray" @click="$emit('close')">Cancel</YButton>
            <YButton color="red" @click="deactivateOffers">Deactivate Traffic</YButton>
          </div>
        </div>
  
        <YTable v-if="offers.data" :data="offers.data">
          <template slot="header-row">
            <th>id</th>
            <th>Offer</th>
          </template>
  
          <template slot="row" slot-scope="props">
            <td>
              {{ props.rowData.id }}
            </td>
            <td>
              {{ props.rowData.name }}
            </td>
          </template>
        </YTable>
      </widget-body>
    </widget>
  </template>
  
  <script>
  import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
  import OFFERS_QUERY from '@/graphql/Offer/OffersQuery.gql'
  import DEACTIVATE_OFFERS_MUTATION from '@/graphql/Offer/DeactivateOffersMutation.gql'
  
  export default {
    mixins: [ValidationErrorsMixin],
    props: {
      selectedRowIds: {
        type: Array,
        required: true
      }
    },
    mounted() {},
    updated() {},
    components: {},
    apollo: {
      offers: {
        query: OFFERS_QUERY,
        variables() {
          return {
            first: 100,
            filters: {
              id: {
                value: this.selectedRowIds
              }
            },
            orderBy: [
              {
                column: 'id',
                order: 'DESC'
              }
            ]
          }
        }
      }
    },
    data() {
      return {
        offers: [],
        isSubmitting: false
      }
    },
    watch: {},
    computed: {},
    methods: {
        deactivateOffers() {
            this.isLoading = true
            this.$apollo
            .mutate({
                mutation: DEACTIVATE_OFFERS_MUTATION,
                variables: {
                input: { ids: this.offers.data.map(item => item.id) }
                }
            })
            .then(() => {
                this.$toastr.s('Offers deactivated')
                this.$tabEvent.emit('refreshOffersTable')
                this.isSubmitting = false
                this.close()
            })
            .catch(error => {
                this.setValidationErrors(error)
                this.isSubmitting = false
                this.$toastr.e('Offers not paused')
            })
            console.log('etf')
        },
  
        close() {
            this.$emit('close')
        }
    }
  }
  </script>
  <style scoped></style>
  