var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "py-6 border-b",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center w-full"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "filePlus"
    }
  }), _vm._v("Add Provider ")], 1)])]), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.provider.name,
      callback: function ($$v) {
        _vm.$set(_vm.provider, "name", $$v);
      },
      expression: "provider.name"
    }
  }), _c('Superselect', {
    attrs: {
      "options": _vm.typeOptions,
      "label": "label",
      "title": "Type",
      "track-by": "id",
      "multiple": false
    },
    model: {
      value: _vm.provider.type,
      callback: function ($$v) {
        _vm.$set(_vm.provider, "type", $$v);
      },
      expression: "provider.type"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex items-center px-4 bg-gray-100"
  }, [_c('YCheckbox', {
    staticClass: "items-center flex",
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    staticClass: "ml-auto",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "color": "blue"
    },
    on: {
      "click": _vm.create,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_vm._v("Add")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }