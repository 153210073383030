var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('ToggleButton', {
    staticStyle: {
      "margin": "0px"
    },
    attrs: {
      "color": {
        checked: '#41b883',
        unchecked: '#888888',
        disabled: '#CCCCCC'
      },
      "labels": {
        checked: 'Active',
        unchecked: 'Paused'
      },
      "width": _vm.width,
      "height": _vm.height,
      "font-size": _vm.fontSize
    },
    on: {
      "change": _vm.updateStatus
    },
    model: {
      value: _vm.toggleStatus,
      callback: function ($$v) {
        _vm.toggleStatus = $$v;
      },
      expression: "toggleStatus"
    }
  }), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }