var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Edit Nameserver Domain ")]), _c('WidgetBody', [_c('Superselect', {
    attrs: {
      "title": "Nameserver Domain",
      "multiple": false,
      "placeholder": "Select",
      "track-by": "id",
      "label": "id",
      "options": _vm.nameserverDomainOptions || [],
      "loading": _vm.$apollo.queries.domainOptions.loading
    },
    model: {
      value: _vm.nameserverDomain,
      callback: function ($$v) {
        _vm.nameserverDomain = $$v;
      },
      expression: "nameserverDomain"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "bg-gray-100 py-2 px-4 text-right"
  }, [_c('YButton', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.updateNameserverDomain,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.updateNameserverDomain.apply(null, arguments);
      }
    }
  }, [_vm._v("Update")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }