var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-popover', {
    class: [''],
    attrs: {
      "offset": "10",
      "placement": "left-start",
      "autoHide": true,
      "popoverClass": "",
      "popoverBaseClass": "popover-menu sidebar-popover-menu",
      "popoverInnerClass": "popover-menu-inner animated animated-speed1 fadeIn"
    }
  }, [_c('div', {
    staticClass: "inline-block tooltip-target"
  }, [_vm._t("default", function () {
    return [_c('YButton', {
      staticClass: "radius-full hover:bg-blue-100 text-blue-600 px-4"
    }, [_c('Icon', {
      attrs: {
        "name": "dotsVertical",
        "size": 4
      }
    })], 1)];
  })], 2), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "list-group"
  }, [_c('button', {
    staticClass: "btn-sm list-group-item list-group-item-action",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.openDeleteModal(_vm.providers[0]);
      }
    }
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_c('i', {
    staticClass: "zmdi zmdi-delete mr-2"
  }), _vm._v(" Delete")])])])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }