<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <template slot="rawContent">
        <div class="flex justify-between items-center w-100 ts-4">
          <div class="font-bold">
            <i class="zmdi -file-plzmdius pr-2"></i>
            Replace Domain ({{ domain.id }})
          </div>
          <close-x @click="$emit('close')" class="text-white"></close-x>
        </div>
      </template>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <YInput :value="domain.id" label="Domain" readonly class="mb-1" />
      <Superselect
        title="Registrars"
        v-model="selectedRegistrar"
        :options="registrars"
        :multiple="false"
        @select="replacementDomain = null"
      ></Superselect>
      <div>
        <div class="flex items-center h-12">
          <y-radio
            label="Auto Assign Replacement"
            name="replacement-domain-mode"
            radio-value="auto"
            v-model="replacementDomainMode"
            color="info"
            class="mr-4"
          >
          </y-radio>
          <y-radio
            label="Select Replacement"
            name="replacement-domain-mode"
            radio-value="manual"
            v-model="replacementDomainMode"
            color="info"
          >
          </y-radio>
        </div>
        <template v-if="replacementDomainMode == 'manual'">
          <multiselect
            v-if="domainOptions.length > 0"
            title="Domain"
            placeholder="Select Domain"
            v-model="replacementDomain"
            :options="domainOptions"
            track-by="id"
            label="label"
            :multiple="false"
          ></multiselect>
          <div v-else class="pt-1 text-center">
            <small class="text-muted" style="font-style: italic;"
              >No Domains Available</small
            >
          </div>
        </template>
      </div>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <y-checkbox
        v-model="closeOnComplete"
        label="Close on Complete"
      ></y-checkbox>
      <YButton
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="submit"
        @keydown.enter="submit"
        :disabled="!canSubmit"
        >Submit</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import yRadio from '../yRadio'

export default {
  props: {
    domain: {
      type: Object,
      required: true
    }
  },
  components: {
    yRadio
  },
  apollo: {
    ydnsDomains: {
      query: gql`
        query ydnsDomains(
          $filters: YdnsDomainFilterInput
          $recordFilters: YdnsRecordFiltPDerInput
        ) {
          ydnsDomains(filters: $filters) {
            id
            registrar
            type
            records(filters: $recordFilters) {
              id
              type
              subdomain
              ip {
                id
                server {
                  id
                }
              }
              content
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            type: { value: ['mini'] },
            registrar: this.selectedRegistrar
              ? { value: this.selectedRegistrar }
              : undefined,
            hasRecords: false
          }
        }
      },
      result({ data }) {
        this.domainOptions = this.$cleanApolloData(data.ydnsDomains).map(
          domain => ({
            id: domain.id,
            label: `${domain.id} - ${domain.registrar}`
          })
        )
      }
    },

    registrars: {
      query: gql`
        {
          registrars: ydnsRegistrars
        }
      `
    }
  },
  data() {
    return {
      domainOptions: [],
      closeOnComplete: true,
      isSubmitting: false,
      replacementDomain: null,
      replacementDomainMode: 'auto',
      selectedRegistrar: null,
      specifiedDomain: null,
      domainToReplace: this.domain.id,
      registrars: []
    }
  },
  watch: {},
  computed: {
    canSubmit() {
      return this.replacementDomain || this.replacementDomainMode == 'auto'
    }
  },
  mounted() {},
  methods: {
    submit() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation yreplaceQueue($input: YreplaceInput!) {
              yreplaceQueue(input: $input) {
                result
                error
              }
            }
          `,
          variables: {
            input: {
              replacementValue: this.domainToReplace,
              replacementType: 'mini',
              replacementDomain:
                this.replacementDomainMode == 'manual'
                  ? this.replacementDomain.id
                  : undefined,
              specifiedRegistrar: this.selectedRegistrar
            }
          }
        })
        .then(result => {
          this.$toastr.success(
            'Replaced: ' + result.data.yreplaceQueue[0]['result'],
            'Success!'
          )
          this.errors = {}
          this.isSubmitting = false
          this.$emit('success', result)
          this.$events.emit('refreshDomainmanagerDomains', true)
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.$toastr.error(error, 'Oops!')
        })
    }
  },

  close() {
    this.$emit('close')
  }
}
</script>
<style>
.publisher-postbacks-table-actions-header {
  width: 3rem;
}
.text-opacity-light {
  opacity: 0.7;
}
</style>
