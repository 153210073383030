<template>
  <div class="relative">
    <Superselect
      v-show="!bulkPasteEnabled"
      :title="title"
      v-model="localValue"
      :modifiers="modifiers"
      :multiple="multiple"
      :searchable="true"
      :internal-search="false"
      :clear-on-select="true"
      :close-on-select="closeOnSelect"
      :options-limit="optionsLimit"
      :allow-empty="allowEmpty"
      :limit="limit"
      :limit-text="count => `and ${count} more`"
      :show-no-results="true"
      :hide-selected="hideSelected"
      @search-change="filterTags"
      @select="onSelect"
      @remove="onRemove"
      label="name"
      :track-by="trackBy"
      :options="tags ? tags.data || [] : []"
      :loading="$apollo.queries.tags.loading"
    >
      <template v-slot:beforeControlIcons v-if="allowBulkPaste">
        <ToggleLink
          v-show="!isPasting"
          v-model="bulkPasteEnabled"
          @input="bulkPasteEnabledUpdated"
          class="control-icon-btn py-1 px-px cursor-pointer block"
        >
          <Icon
            v-if="!$apollo.queries.tags.loading"
            name="pencil"
            :class="[
              'h-4 w-4',
              bulkPasteEnabled
                ? 'text-blue-500 hover:text-blue-600'
                : 'text-gray-400 hover:text-gray-500'
            ]"
          />
        </ToggleLink>
        <a
          v-show="!isPasting"
          @click="() => (localValue = [])"
          class="control-icon-btn py-1 px-px cursor-pointer block text-gray-400 hover:text-red-500"
        >
          <Icon
            v-if="!$apollo.queries.tags.loading"
            name="trashCan"
            :class="['h-4 w-4']"
          />
        </a>
        <Spinner v-show="isPasting" class="my-1 mx-px" color="blue" />
      </template>
      <template slot="option" slot-scope="{ option }">
        <div class="flex justify-between items-center">
          <Tag v-tooltip="`${option.type.capitalize()}`" :color="option.color">
            {{ option.name }}
          </Tag>
          <div v-if="showOptionsTypeLabel">{{ option.type.capitalize() }}</div>
        </div>
      </template>
      <template v-slot:tag="props">
        <Tag
          style="margin: 1px;"
          :color="
            isNotTypes.includes(`${props.option.type}`) ||
            modifiers.exclude === true
              ? 'red'
              : 'blue'
          "
          class="inline-flex"
        >
          <template v-slot:icon="iconProps" v-if="enableNotToggles">
            <button
              @click="toggleIsNot(`${props.option.type}`)"
              :class="
                `hover:bg-${iconProps.color}-200 text-${iconProps.color}-${
                  iconProps.color == 'red' ? '600' : '400'
                } hover:text-${iconProps.color}-${
                  iconProps.color == 'red' ? '800' : '600'
                } -mr-3 rounded-full relative block outline-none-important`
              "
              style="padding-left: 0.35rem; padding-right:0.35rem;"
            >
              <Icon name="exclamationThick" class="w-3 h-3" />
            </button>
          </template>
          {{ props.option.name }}
          <template v-slot:afterIcon="afterIconProps">
            <button
              @click="props.remove(props.option)"
              :class="
                `hover:bg-${afterIconProps.color}-300 -ml-2 rounded-full outline-none-important`
              "
              style="padding-left: 0.35rem; padding-right:0.35rem;"
            >
              <Icon name="closeThick" class="w-3 h-3 block" />
            </button>
          </template>
        </Tag>
      </template>
    </Superselect>
    <YInput
      ref="pasteInput"
      :label="title"
      v-show="bulkPasteEnabled"
      :placeholder="pasteInputPlaceholder"
      value=""
      @paste="tagsPasted"
      @keypress="
        event => {
          event.preventDefault()
        }
      "
      @blur="
        () => {
          bulkPasteEnabled = false
        }
      "
    ></YInput>
  </div>
</template>

<script>
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import ToggleLink from '@/components/legacy/ToggleLink'

export default {
  components: { ToggleLink },
  apollo: {
    tags: {
      query: TAGS_QUERY,
      variables() {
        return {
          first: this.perPage,
          filters: {
            name: this.tagsFilter,
            type: this.filterTypes ? { value: this.filterTypes } : undefined
          }
        }
      }
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: [Array, Object]
    },
    modifiers: {
      type: Object,
      default: () => ({})
    },
    perPage: {
      type: Number,
      default: 20
    },
    enableNotToggles: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    closeOnSelect: {
      type: Boolean,
      default: false
    },
    trackBy: {
      type: String,
      default: 'id'
    },
    optionsLimit: {
      type: Number,
      default: 300
    },
    filterTypes: {
      type: Array,
      default: null
    },
    defaultIsNot: {
      type: Boolean,
      default: true
    },
    showOptionsTypeLabel: {
      type: Boolean,
      default: true
    },
    hideSelected: {
      type: Boolean,
      default: true
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    allowBulkPaste: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      isPasting: false,
      bulkPasteEnabled: false,
      pasteInputPlaceholder: 'Paste Tags',
      localValue: this.value,
      tagOptionsFilterValue: null,
      isNotTypes: [],
      isNotTypesThatHaveChanged: []
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (JSON.stringify(this.localValue) != JSON.stringify(newValue)) {
          this.localValue = newValue

          // initialize isNotTags array
          if (Array.isArray(this.localValue)) {
            this.isNotTypes = this.localValue
              .filter(tag => tag.isNot)
              .map(tag => `${tag.type}`)
              .filter((v, i, a) => a.indexOf(v) === i)
          }
        }
      }
    },
    localValue: {
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      }
    },
    isNotTypes: {
      deep: true,
      handler(newValue) {
        this.localValue = this.updateIsNotTypes(newValue, this.localValue)
        this.isNotTypesThatHaveChanged = [
          ...this.isNotTypesThatHaveChanged,
          ...newValue
        ].filter((v, i, a) => a.indexOf(v) === i)
      }
    }
  },
  mounted() {
    if (this.localValue && this.localValue.length > 0) {
      this.localValue = this.updateIsNotTypes(this.isNotTypes, this.localValue)
    }
  },
  methods: {
    bulkPasteEnabledUpdated(value) {
      if (value == true) {
        this.$nextTick(() => {
          this.$refs.pasteInput.focusInput()
        })
      }
    },
    tagsPasted(event) {
      event.stopPropagation()
      event.preventDefault()
      let pastedData = event.clipboardData.getData('Text')
      let pastedArray = pastedData.match(/[^\r\n]+/g)
      this.isPasting = true
      this.pasteInputPlaceholder = 'Pasting...'
      this.$apollo
        .query({
          query: TAGS_QUERY,
          variables: {
            first: 10000,
            filters: {
              name: {
                value: pastedArray,
                modifiers: { matchType: 'exact' }
              },
              type: this.filterTypes ? { value: this.filterTypes } : undefined
            }
          }
        })
        .then(({ data }) => {
          this.localValue = Array.from(
            new Set([...this.localValue, ...data.tags.data])
          )
          this.isPasting = false
          this.pasteInputPlaceholder = 'Paste Tags'
          this.bulkPasteEnabled = false
        })
        .catch(error => {
          console.error(error)
          this.isPasting = false
          this.pasteInputPlaceholder = 'Paste Tags'
        })
    },
    onSelect(value) {
      if (
        this.defaultIsNot === false &&
        !this.isNotTypesThatHaveChanged.includes(value.type)
      ) {
        this.isNotTypes.push(value.type)
      }
      value.isNot = this.isNotTypes.includes(value.type)
      this.$emit('select', value)
    },
    onRemove(value) {
      this.$emit('remove', value)
    },
    filterTags(value) {
      this.tagOptionsFilterValue = value
    },
    toggleIsNot(type) {
      if (this.isNotTypes.includes(type)) {
        this.isNotTypes = this.isNotTypes.filter(typeKey => typeKey != type)
      } else {
        this.isNotTypes.push(type)
      }
    },
    updateIsNotTypes(types, tags) {
      return tags.map(tag => {
        tag.isNot = types.includes(`${tag.type}`)
        return tag
      })
    }
  },
  computed: {
    tagsFilter() {
      if (this.tagOptionsFilterValue) {
        return {
          value: this.tagOptionsFilterValue,
          modifiers: { matchType: 'contains' }
        }
      } else {
        return undefined
      }
    }
  }
}
</script>

<style></style>
