var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showType == 'if' && _vm.isActive ? _c('div', {
    staticClass: "flex-grow max-w-full"
  }, [_vm._t("default")], 2) : _vm.showType == 'show' ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isActive,
      expression: "isActive"
    }],
    staticClass: "flex-grow max-w-full"
  }, [_vm._t("default")], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }