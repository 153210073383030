<template>
  <a
    href="#"
    :class="classes"
    @click.exact.prevent="toggleValue(controllerValue)"
    @click.shift.exact.prevent="togglePushValue(controllerValue)"
    @click.meta.exact.prevent="toggleValue(controllerValue), submit()"
    @click.meta.shift.exact.prevent="togglePushValue(controllerValue), submit()"
    @click.ctrl.exact.prevent="toggleValue(controllerValue), submit()"
    @click.ctrl.shift.exact.prevent="togglePushValue(controllerValue), submit()"
  >
    <Icon name="target" :size="size" />
  </a>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    controllerValue: {
      type: [Object],
      required: true
    },
    size: {
      type: Number,
      default: 4
    }
  },
  computed: {
    filterValue: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        this.$emit('input', newValue)
      }
    },
    classes() {
      let classes = []
      if (this.filterValueHasControllerValue) {
        classes.push('')
      } else {
        classes.push('opacity-25 hover:opacity-75 focus:opacity-75')
      }
      return classes
    },
    filterValueHasControllerValue() {
      if (this.filterValueIsArray) {
        return (
          this.filterValue.filter(
            filterValue => filterValue.id === this.controllerValue.id
          ).length > 0
        )
      } else {
        return (
          this.filterValue && this.filterValue.id == this.controllerValue.id
        )
      }
    },
    filterValueIsArray() {
      return Array.isArray(this.filterValue)
    }
  },
  methods: {
    doNothing() {},
    submit() {},
    toggleValue(value) {
      if (!this.filterValueHasControllerValue || this.filterValue.length > 1) {
        if (this.filterValueIsArray) {
          this.filterValue = [value]
        } else {
          this.filterValue = value
        }
      } else {
        if (this.filterValueIsArray) {
          this.filterValue = []
        } else {
          this.filterValue = null
        }
      }
    },
    togglePushValue(value) {
      if (this.filterValueIsArray) {
        if (!this.filterValueHasControllerValue) {
          this.filterValue.push(value)
        } else {
          this.filterValue = this.filterValue.filter(
            filterValue => filterValue.id !== this.controllerValue.id
          )
        }
      } else {
        this.toggleValue(value)
      }
    }
  }
}
</script>
