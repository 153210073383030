import supportedBrowsers from './generated/supportedBrowsers'

if (!supportedBrowsers.test(navigator.userAgent)) {
  document.getElementById('browserNotSupported').style.display = 'flex'
  throw new Error('Unsupported Browser.')
}

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/styles/main.css'
import '@/globalComponents'
import '@/vuePlugins'
import { DateTime } from 'luxon'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  Vue,
  dsn: 'https://c9d7c01285b949ae98ba4c860b6b14db@o208147.ingest.sentry.io/5866972',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0
})

// Prototype manipulation
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

Vue.prototype.$formatInt = value => {
  return value ? value.toLocaleString('en-US') : value
}
Vue.prototype.$formatPercent = (value, decimals = 0) => {
  return value ? (value * 100).toFixed(decimals) + '%' : value
}
Vue.prototype.$formatFloat = (value, decimals = 2) => {
  return value
    ? parseFloat(value)
        .toFixed(decimals)
        .toLocaleString('en-CA')
    : value
}
Vue.prototype.$formatMoney = value => {
  let output = parseFloat(value)
  output =
    output || output === 0
      ? output.toLocaleString('en-CA', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: 'currency',
          currency: 'CAD',
          currencyDisplay: 'symbol'
        })
      : output
  return output
}

// OLD PACKAGES FROM OLD YTRACK
import _ from './assets/js/lodash.js'
_.mixin({
  chunkObj: function(input, size) {
    return _.chain(input)
      .toPairs()
      .chunk(size)
      .map(_.object)
      .value()
  }
})
import VueLodash from 'vue-lodash'

// name is optional
Vue.use(VueLodash, { name: 'custom', lodash: _ })
import omitDeep from 'omit-deep-lodash'
Vue.prototype.$omitDeep = omitDeep

Vue.prototype.$cleanApolloData = data => {
  let output = data
  output = omitDeep(output, '__typename')
  output = omitDeep(output, 'Symbol(id)')
  output = JSON.parse(JSON.stringify(output))
  return output
}

// Luxon Functions
Vue.prototype.$dateTime = DateTime

// Returns an Array of Dates
Vue.prototype.$getDateRange = (startDate, endDate) => {
  const start = Vue.prototype.$dateTime.fromISO(startDate)
  const end = Vue.prototype.$dateTime.fromISO(endDate)
  const result = end.diff(start, 'days').toObject()
  var days = result['days']

  const dateArray = []

  while (days > 0) {
    dateArray.push(
      Vue.prototype.$dateTime
        .fromISO(endDate)
        .minus({ days: days })
        .toFormat('yyyy-LL-dd')
    )
    days -= 1
  }

  dateArray.push(
    Vue.prototype.$dateTime.fromISO(endDate).toFormat('yyyy-LL-dd')
  )

  return dateArray
}

import { createProvider } from './vue-apollo'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
