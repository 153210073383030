<template>
  <div>
    <div
      class="grid grid-cols-1 gap-1 m-auto bg-white pt-5 pb-8 px-6 rounded-b-lg shadow-xl border-b border-l border-r"
      style="max-width: 24rem;"
    >
      <YInput label="Email" v-model="username" class="mb-1 text-left" />
      <YInput
        label="Password"
        v-model="password"
        type="password"
        class="mb-1 text-left"
      />
      <div class="flex justify-start pt-0 mb-10">
        <div></div>
        <router-link :to="{ name: 'Forgot Password' }" class="link"
          >Forgot a Password?</router-link
        >
      </div>
      <YButton
        :is-loading="isLoading"
        class="w-full"
        color="pink"
        @click="login"
        >Login</YButton
      >

      <div class="flex justify-center border-t border-gray-500 pt-4 mt-3">
        <p class="text-gray-700">Don't have an account?</p>
        <router-link :to="{ name: 'Register' }">
          <p class="link ml-1">Sign Up</p>
        </router-link>
      </div>
      <portal-target name="login-errors"></portal-target>
      <ValidationErrors
        v-if="validationErrors"
        :errors="validationErrors"
        portal-to="login-errors"
        alert-class="text-xs"
        :icon-size="12"
        :icon="null"
        color="yellow"
      ></ValidationErrors>
    </div>
    <div
      class="w-100 m-auto flex justify-end pr-3 pt-1"
      style="max-width: 24rem;"
    >
      <img style="height:26px;" :src="YtrackLogo" />
    </div>
  </div>
</template>

<script>
import LOGIN_MUTATION from '@/graphql/Auth/LoginMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import YtrackLogo from '@/assets/images/logo/logo-ytrack-gray.png'

export default {
  mixins: [ValidationErrorsMixin],
  data() {
    return {
      username: null,
      password: null,
      isLoading: false,
      YtrackLogo: YtrackLogo
    }
  },
  methods: {
    login() {
      this.isLoading = true
      this.$apollo
        .mutate({
          // Query
          mutation: LOGIN_MUTATION,
          // Parameters
          variables: {
            input: {
              username: this.username,
              password: this.password
            }
          }
        })
        .then(data => {
          this.isLoading = false
          this.$store
            .dispatch('updateLoginAuth', {
              token: data.data.login.access_token,
              authId: data.data.login.user.id,
              role: data.data.login.user.roles[0].name
            })
            .then(() => {
              let isAdmin =
                data.data.login.user.roles.filter(role => role.name == 'admin')
                  .length > 0

              if (isAdmin) {
                this.$router.push('/admin/dashboard')
              } else {
                data.data.login.user.userDetails.agreedToTerms
                  ? this.$router.push('/publisher/dashboard')
                  : this.$router.push('/publisher/account/terms')
              }
            })
        })
        .catch(error => {
          this.isLoading = false
          this.$store.dispatch('logout')
          this.setValidationErrors(error)

          // Error
          console.error(error)
        })
    },
    detectEnterKey(event) {
      if (event.code === 'Enter') {
        this.login()
      }
    }
  },
  mounted() {
    window.addEventListener('keyup', this.detectEnterKey)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.detectEnterKey)
  }
}
</script>

<style></style>
