<template>
  <div>
    <div class="text-right flex gap-3 justify-start items-center">
      <div>
        <v-popover
          class="inline-block h-4 cursor-pointer relative"
          style="top: 3px;"
          placement="left"
          offset="4"
        >
          <a
            class=" tooltip-target inline-block text-gray-400 hover:text-gray-500"
            ><Icon name="history"
          /></a>

          <!-- This will be the content of the popover -->
          <template slot="popover">
            <div class="px-2 rounded">
              <template v-if="rowData.activity && rowData.activity.length > 0">
                <div
                  v-for="activity in rowData.activity"
                  :key="activity.id"
                  class="x-activity-item text-xs py-2"
                >
                  <div class="flex items-center justify-between">
                    <div class="font-bold mr-6">
                      {{
                        activity.updatedAt
                          | luxon({
                            output: { format: 'yyyy-MM-dd @ h:mm a' }
                          })
                      }}
                    </div>
                    <div class="font-semibold text-blue-600">
                      {{ activity.description.capitalize() }}
                    </div>
                  </div>
                  <div class="flex justify-between">
                    <div class="text-gray-500 italic">
                      <template v-if="activity.causer">
                        {{ activity.causer.email }}
                      </template>
                    </div>
                    <ul class="x-activity-list ml-6">
                      <li
                        v-for="(property, key) of activity.properties"
                        :key="key"
                      >
                        <span class="text-gray-500">{{ key }}:</span>
                        {{ property }}
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <div v-else class="text-gray-400 py-2">No History</div>
            </div>
          </template>
        </v-popover>
      </div>
    </div>
  </div>
</template>

<script>
import UPDATE_PAYOUT_MUTATION from '@/graphql/Payout/UpdatePayoutMutation.gql'

export default {
  components: {},
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      UPDATE_PAYOUT_MUTATION
    }
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {}
}
</script>
<style scoped></style>
