<template>
  <Widget>
    <div class="background-gradient-green-blue" style="height: 6px;"></div>
    <WidgetHeader @close="close">
      Save Preset
    </WidgetHeader>
    <WidgetBody>
      <div class="input-group">
        <YInput
          label="Overwrite Existing"
          type="text"
          class="form-control"
          :value="`${presetFilter.originalName}`"
          readonly
        />
        <div class="input-group-append">
          <YButton
            class="btn btn-blue btn-width rounded-l-none btn-width"
            type="button"
            @click="overwritePreset"
            :isLoading="isOverwriting"
            :disabled="isOverwriting || isSaving"
          >
            Overwrite
          </YButton>
        </div>
      </div>

      <div class="input-group mt-2">
        <YInput
          label="Save As New Preset"
          type="text"
          class="form-control"
          placeholder="Preset Name"
          v-model="presetName"
        />
        <div class="input-group-append">
          <YButton
            class="btn btn-green rounded-l-none btn-width"
            type="button"
            @click="savePreset"
            :isLoading="isSaving"
            :disabled="!presetName"
          >
            Save
          </YButton>
        </div>
      </div>
      <div class="flex mt-2">
        <YCheckbox
          id="preset-is-public-checkbox"
          v-model="isPublic"
          label="Available for everyone"
          :inline="true"
          class="flex items-center mr-2"
          type="info"
        ></YCheckbox>
        <YCheckbox
          id="preset-include-date-checkbox"
          v-model="includeDate"
          label="Include Date"
          class="flex items-center"
          :inline="true"
          type="info"
        ></YCheckbox>
      </div>
    </WidgetBody>
  </Widget>
</template>

<script>
import CREATE_REPORT_PRESET from '@/graphql/ReportPreset/CreateReportPresetMutation.gql'
import UPDATE_REPORT_PRESET from '@/graphql/ReportPreset/UpdateReportPresetMutation.gql'
import REPORT_PRESET_QUERY from '@/graphql/ReportPreset/ReportPresetQuery.gql'

export default {
  apollo: {
    reportPreset: {
      query: REPORT_PRESET_QUERY,
      update: data => data.reportPreset,
      variables() {
        return {
          id: this.presetFilter.originalId
        }
      },
      result({ data }) {
        this.includeDate = JSON.parse(data.reportPreset.data).filters.dateTimeRange ? true : false
        this.isPublic = data.reportPreset.public
      },
      skip() {
        return !this.presetFilter
      }
    }
  },
  components: {},
  props: {
    presetFilter: {
      type: Object
    },
    report: {
      type: String
    },
    globalPreset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSaving: false,
      isOverwriting: false,
      presetName: null,
      isPublic: this.globalPreset,
      authorId: null,
      includeDate: false
    }
  },
  computed: {},
  methods: {
    savePreset() {
      this.isSaving = true

      let report = JSON.parse(this.report)

      if (!this.includeDate) {
        delete report.filters.dateTimeRange
      }

      report = JSON.stringify(report)

      this.$apollo
        .mutate({
          mutation: CREATE_REPORT_PRESET,
          variables: {
            input: {
              name: this.presetName,
              authorId: this.$store.getters.authId,
              data: report,
              public: this.isPublic
            }
          }
        })
        .then(result => {
          this.$toastr.s('Preset has been saved', 'Success!')
          this.isSaving = false
          this.$emit('success', result.data.createReportPreset)
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSaving = false
        })
    },
    overwritePreset() {
      this.isOverwriting = true

      let report = JSON.parse(this.report)

      if (!this.includeDate) {
        delete report.filters.dateTimeRange
      }

      report = JSON.stringify(report)

      this.$apollo
        .mutate({
          mutation: UPDATE_REPORT_PRESET,
          variables: {
            input: {
              id: this.presetFilter.originalId,
              authorId: this.$store.getters.authId,
              data: report,
              public: this.isPublic
            }
          }
        })
        .then(result => {
          this.$toastr.s('Preset has been saved', 'Success!')
          this.isOverwriting = false
          this.$emit('success', result.data.updateReportPreset)
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isOverwriting = false
        })
    },
    close() {
      this.$emit('close')
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.label-cell {
  width: 4rem;
  font-size: 85%;
}
.or-text {
  position: absolute;
  width: 3rem;
  line-height: 2rem;
  height: 2rem;
  background-color: rgb(255, 255, 255);
  margin: 0;
  margin-left: -1.5rem;
  /*	    margin-top: -1rem;*/
  left: 50%;
  top: 50%;
  text-align: center;
  font-weight: 400;
  color: #999;
}
.or-line {
  margin: 2rem auto;
  width: 10rem;
}
.btn-width {
  width: 7.7rem;
}
input:read-only {
  border-color: #eee;
  background-color: #eee;
}
</style>
