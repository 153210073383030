<template>
  <div
    class="relative"
    :class="[!valueEmpty ? 'not-empty' : '', disabled ? 'opacity-50' : '']"
  >
    <div>
      <span v-if="required" class="absolute right-0 pr-1 text-2xl text-red-500"
        >*</span
      >
      <Icon
        v-if="tooltip"
        name="helpCircle"
        class="absolute right-0 bottom-0 mb-1 mr-1 text-gray-400 bg-white"
        :size="4"
        v-tooltip="tooltip"
      />
      <div
        class="absolute right-0 bottom-0 mr-1 mb-1"
        v-if="multiple == true && inputValue && inputValue.length > limit"
      >
        <ToggleLink
          v-model="showAllValues"
          class="control-icon-btn restrict block p-1 cursor-pointer"
        >
          <Icon
            name="expandAll"
            :class="[
              'h-4 w-4',
              showAllValues
                ? 'text-blue-600 hover:text-blue-700'
                : 'text-gray-400 hover:text-gray-500'
            ]"
          />
        </ToggleLink>
      </div>
      <div
        v-if="isLoading"
        class="absolute right-0 flex mr-1 mt-1"
        style="z-index: 10"
      >
        <Spinner color="green" :size="5" />
      </div>
      <div
        v-show="!isLoading"
        class="absolute right-0 flex"
        style="z-index: 10"
        :class="
          (hasEnable && !localModifiers.enable) || disabled
            ? 'disabled-and-faded'
            : ''
        "
      >
        <slot name="beforeControlIcons"></slot>
        <ToggleLink
          v-if="localModifiers.exclude != undefined && !hideModifiers"
          v-model="localModifiers.exclude"
          class="control-icon-btn restrict block p-1 cursor-pointer"
        >
          <Icon
            name="cancel"
            :class="[
              'h-4 w-4',
              localModifiers.exclude
                ? 'text-red-600 hover:text-red-700'
                : 'text-gray-400 hover:text-gray-500'
            ]"
          />
        </ToggleLink>
        <ToggleLink
          v-if="localModifiers.matchType != undefined"
          v-model="localModifiers.matchType"
          :toggle-values="['exact', 'contains']"
          class="control-icon-btn restrict block p-1 cursor-pointer"
          v-tooltip="{
            content: `${localModifiers.matchType.capitalize()}`,
            delay: { show: 600, hide: 0 }
          }"
        >
          <Icon
            name="formatLetterMatches"
            :class="[
              'h-4 w-4',
              localModifiers.matchType == 'exact'
                ? 'text-blue-600 hover:text-blue-700'
                : 'text-gray-400 hover:text-gray-500'
            ]"
          />
        </ToggleLink>
        <ToggleLink
          v-if="editSelectAvailable"
          v-model="editModeState"
          @input="updateEditMode"
          class="control-icon-btn p-1 cursor-pointer"
        >
          <Icon
            name="pencil"
            :class="[
              'h-4 w-4',
              editModeState
                ? 'text-blue-500 hover:text-blue-600'
                : 'text-gray-400 hover:text-gray-500  '
            ]"
          />
        </ToggleLink>

        <ToggleLink
          v-if="localModifiers.isNot != undefined && !hideModifiers"
          v-model="localModifiers.isNot"
          class="control-icon-btn restrict block p-1 cursor-pointer"
          v-tooltip="'Restricted'"
        >
          <Icon
            name="alertCircleOutline"
            :class="[
              'h-4 w-4',
              localModifiers.isNot
                ? 'text-red-600 hover:text-red-700'
                : 'text-gray-400 hover:text-gray-500'
            ]"
          />
        </ToggleLink>
      </div>
      <div class="absolute right-0 bottom-0 flex" style="z-index: 9">
        <slot name="bottom-right"></slot>
      </div>
    </div>
    <multiselect
      v-if="!forceEdit"
      ref="multiselect"
      v-show="!editModeState"
      v-model="inputValue"
      :id="!editModeState ? id : undefined"
      :class="[
        localModifiers.exclude || localModifiers.isNot || forceRedTags
          ? 'exclude'
          : '',
        selectClass
      ]"
      :options="
        queryOptions ||
          (options ? [...options, ...internalOptions] : null) ||
          []
      "
      :custom-label="customLabel"
      :multiple="multiple"
      :placeholder="computedPlaceholder"
      :track-by="trackBy"
      :label="label"
      :group-values="groupValues"
      :group-label="groupLabel"
      selectedLabel
      selectLabel
      deselectLabel
      :allow-empty="allowEmpty"
      :close-on-select="closeOnSelect"
      :options-limit="optionsLimit"
      :searchable="searchable"
      :internal-search="internalSearch"
      :clear-on-select="clearOnSelect"
      :limit="localLimit"
      :limit-text="limitText"
      :max-height="maxHeight"
      :show-no-results="showNoResults"
      :hide-selected="hideSelected"
      :taggable="taggable"
      @tag="addTag"
      @search-change="searchChangeMethod"
      @select="onSelect"
      @close="onClose"
      @remove="onRemove"
      :disabled="(hasEnable && !localModifiers.enable) || disabled == true"
    >
      <template slot="singleLabel" slot-scope="props">
        <slot name="singleLabel" :option="props.option"></slot>
      </template>

      <template slot="noOptions">
        <slot name="noOptions">
          <span class="text-gray-400 hover:text-blue-400"
            >Type to search&hellip;</span
          >
        </slot>
      </template>
      <template slot="option" slot-scope="props">
        <slot name="option" :option="props.option">
          <div v-if="showCheckBoxes">
            <input type="checkbox" :value="props.option" v-model="inputValue" />
            {{ props.option }}
          </div>
        </slot>
      </template>

      <span slot="noResult">{{ computedNoResult }}</span>
      <template v-slot:tag="props">
        <slot
          name="tag"
          :option="props.option"
          :search="props.search"
          :remove="props.remove"
        >
          <Tag
            v-if="enableNotToggles"
            style="margin: 1px;"
            :color="isNotItems.includes(props.option[trackBy]) ? 'red' : 'blue'"
            class="inline-flex"
          >
            <template v-slot:icon="iconProps" v-if="enableNotToggles">
              <button
                @click="toggleIsNot(props.option[trackBy])"
                :class="
                  `hover:bg-${iconProps.color}-200 text-${iconProps.color}-${
                    iconProps.color == 'red' ? '600' : '400'
                  } hover:text-${iconProps.color}-${
                    iconProps.color == 'red' ? '800' : '600'
                  } -mr-3 rounded-full relative block outline-none-important`
                "
                style="padding-left: 0.35rem; padding-right:0.35rem;"
              >
                <Icon name="exclamationThick" class="w-3 h-3" />
              </button>
            </template>
            {{ props.option[label] }}
            <template v-slot:afterIcon="afterIconProps">
              <button
                @click="props.remove(props.option)"
                :class="
                  `hover:bg-${afterIconProps.color}-300 -ml-2 rounded-full outline-none-important`
                "
                style="padding-left: 0.35rem; padding-right:0.35rem;"
              >
                <Icon name="closeThick" class="w-3 h-3 block" />
              </button>
            </template>
          </Tag>
        </slot>
      </template>
    </multiselect>
    <y-multitags
      ref="multitags"
      v-if="multiple"
      v-show="editModeState"
      v-model="inputValue"
      :class="[selectClass]"
      :id="editModeState ? id : undefined"
      :group-values="groupValues"
      :group-label="groupLabel"
      :exclude="modifiers.exclude"
      :placeholder="editPlaceholder"
      :allow-empty="allowEmpty"
      :track-by="trackBy != undefined ? trackBy : undefined"
      :label="label != undefined ? label : undefined"
      :options="options"
      :disabled="(hasEnable && !modifiers.enable) || disabled == true"
      :option-required-on-tag="optionRequiredOnTag"
      @remove="onRemove"
    ></y-multitags>
    <label :for="id" class="multiselect-label">{{ title }}</label>
    <slot name="footer"></slot>
  </div>
</template>

<script>
import ToggleLink from '@/components/legacy/ToggleLink'

export default {
  components: {
    ToggleLink
  },
  props: {
    query: {
      type: Object
    },
    queryVariables: {
      type: Object
    },
    queryResultMap: {
      type: Object
    },
    queryResultFunction: {
      type: Function
    },
    queryDefaultInputText: {
      type: String
    },
    queryFetchPolicy: {
      type: String
    },
    value: [String, Object, Array, Number, Boolean],
    title: {
      type: String
    },
    customLabel: {
      type: Function
    },
    hideModifiers: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: () => {
        // If no ID is passed. Generate a random one to link label and input
        return Math.floor(Math.random(9999999) * 100000000).toString()
      }
    },
    allowEmpty: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    groupValues: {
      type: String,
      default: null
    },
    groupLabel: {
      type: String,
      default: null
    },
    trackBy: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    modifiers: {
      type: Object,
      default: () => ({})
    },
    settings: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array
    },
    forceEdit: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: null
    },
    hideSelected: {
      type: Boolean,
      default: false
    },
    noResult: {
      type: String
    },
    showNoResults: {
      type: Boolean,
      default: true
    },
    editPlaceholder: {
      type: String,
      default: 'Paste IDs'
    },
    info: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    showExcludeWithTitle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    optionsLimit: {
      type: Number,
      default: 1000
    },
    limit: {
      type: Number,
      default: 99999
    },
    limitText: {},
    searchable: {
      type: Boolean,
      default: true
    },
    internalSearch: {
      type: Boolean,
      default: true
    },
    clearOnSelect: {
      type: Boolean,
      default: true
    },
    searchChange: {},
    optionRequiredOnTag: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    enableNotToggles: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String
    },
    selectClass: {
      type: String
    },
    maxHeight: {
      type: Number
    },
    focusOnMount: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    forceRedTags: {
      type: Boolean
    },
    showCheckBoxes: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    queryOptions: {
      query() {
        return this.query
      },
      debounce: 250,
      update: data => {
        return data[Object.keys(data)[0]] // return first key
      },
      variables() {
        return this.computedQueryVariables
          ? this.computedQueryVariables
          : this.queryVariables
      },
      result({ data }) {
        let isPaginated = data[Object.keys(data)[0]].data ? true : false

        let queryOptions

        if (isPaginated) {
          queryOptions = data[Object.keys(data)[0]].data
        } else {
          queryOptions = data[Object.keys(data)[0]]
        }

        if (this.queryResultMap) {
          //  TODO:   queryResultMap only supports id and label.
          //          Need to have this programatically gp through
          //          the queryResultMap key / value pairs to be more flexible
          this.queryOptions = queryOptions.map(option => {
            return {
              id: option[this.queryResultMap.id],
              label: option[this.queryResultMap.label],
              secondaryId: option[this.queryResultMap.relationId] !== undefined ? option[this.queryResultMap.relationId].id : undefined,
              secondaryLabel: option[this.queryResultMap.relationLabel] !== undefined ? option[this.queryResultMap.relationLabel].label : undefined
            }
          })
        } else if (this.queryResultFunction) {
          this.queryOptions = this.queryResultFunction(data)
        } else {
          this.queryOptions = queryOptions
        }
      },
      skip() {
        if (this.queryVariablesAreDynamic) {
          return !(this.query && this.computedQueryVariables)
        } else {
          return !this.query
        }
      }
    }
  },
  data() {
    return {
      editModeState:
        this.forceEdit || this.modifiers.edit || this.settings.edit,
      isNotItems: [],
      localModifiers: this.modifiers,
      inputText: null,
      queryOptions: null,
      showAllValues: false,
      localLimit: this.limit,
      internalOptions: []
    }
  },
  watch: {
    showAllValues(shouldShowAll) {
      if (shouldShowAll) {
        this.localLimit = undefined
      } else {
        this.localLimit = this.limit
      }
    },
    isNotItems: {
      deep: true,
      handler(newValue) {
        this.inputValue = this.inputValue.map(item => {
          item.isNot = newValue.includes(item[this.trackBy])
          return item
        })
      }
    },
    modifiers: {
      deep: true,
      handler(newValue) {
        if (JSON.stringify(newValue) != JSON.stringify(this.localModifiers)) {
          this.localModifiers = newValue
        }
      }
    },
    localModifiers: {
      deep: true,
      handler(newValue) {
        this.$emit('update:modifiers', newValue)
      }
    }
  },
  computed: {
    isLoading() {
      return this.$apollo.queries.queryOptions.loading || this.loading
    },
    queryVariablesAreDynamic() {
      if (this.queryVariables) {
        return JSON.stringify(this.queryVariables).includes('{input}')
      } else {
        return false
      }
    },
    computedQueryVariables() {
      if (
        this.queryVariables &&
        this.queryVariablesAreDynamic &&
        (this.inputText || typeof this.queryDefaultInputText !== 'undefined')
      ) {
        return JSON.parse(
          JSON.stringify(this.queryVariables).replace(
            '{input}',
            this.inputText ? this.inputText : this.queryDefaultInputText
          )
        )
      } else {
        return null
      }
    },
    valueEmpty() {
      return (
        !this.inputValue || (this.inputValue && this.inputValue.length == 0)
      )
    },
    hasEnable() {
      return this.modifiers &&
        this.modifiers.enable !== null &&
        this.modifiers.enable !== undefined
        ? true
        : false
    },
    inputValue: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        this.updateValue(newValue)
      }
    },
    pluralTitle() {
      const title = this.title
      let plural = ''
      if (title.substr(title.length - 1).toLowerCase() == 'y') {
        // ends in 'y'
        plural =
          title
            .split('')
            .slice(0, -1)
            .join('') + 'ies'
      } else if (title.substr(title.length - 1).toLowerCase() == 's') {
        // ends in 's'
        plural = title
      } else {
        plural = title + 's'
      }
      return plural
    },
    computedPlaceholder() {
      if (this.placeholder) {
        return this.placeholder
      } else {
        return 'Select'
      }
    },
    computedNoResult() {
      if (this.noResult) {
        return this.noResult
      } else {
        return 'No ' + this.pluralTitle + ' Found'
      }
    },
    editSelectAvailable() {
      return (
        (this.modifiers.edit != undefined && !this.forceEdit) ||
        (this.settings.edit != undefined && !this.forceEdit)
      )
    }
  },

  mounted() {
    if (this.focusOnMount) {
      this.$refs.multiselect.$el.focus()
    }
    if (this.queryFetchPolicy) {
      this.$apollo.queries.queryOptions.setOptions({
        fetchPolicy: this.queryFetchPolicy
      })
    }
  },

  updated() {},

  methods: {
    addTag(newTag) {
      let tag
      if (this.trackBy) {
        tag = {}
        tag[this.trackBy] = newTag
        tag[this.label] = newTag
      } else {
        tag = newTag
      }
      this.internalOptions.push(tag)
      if (this.multiple) {
        this.inputValue.push(tag)
      } else {
        this.inputValue = tag
      }
    },
    toggleIsNot(value) {
      if (this.isNotItems.includes(value)) {
        this.isNotItems = this.isNotItems.filter(item => item != value)
      } else {
        this.isNotItems.push(value)
      }
    },
    onSelect(value) {
      this.$emit('select', value)
    },
    onClose(value) {
      this.$emit('close', value)
    },
    onRemove(removedOption) {
      this.$emit('remove', removedOption)
    },
    searchChangeMethod(value) {
      this.inputText = value
      this.$emit('search-change', value)
    },
    updateEditMode() {
      if (this.modifiers.edit) {
        setTimeout(() => {
          this.$refs.multitags.$el.focus()
        }, 0)
      }
    },
    updateValue(value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style scoped>
.control-icons.control-icons-right {
  position: absolute;
  position: right;
}
</style>
