var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "px-4 py-6",
    staticStyle: {
      "background": "#FFF"
    }
  }, [_vm.userDetails.tipaltiDetails.publisherDetails ? _c('iframe', {
    staticStyle: {
      "border": "none"
    },
    attrs: {
      "src": _vm.userDetails.tipaltiDetails.publisherDetails,
      "height": "800",
      "width": "800"
    }
  }) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }