var render = function render(){
  var _vm$smartWeightQueueI, _vm$smartWeightQueueI2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-gray-100 p-1"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 sm:grid-cols-6 gap-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Country",
      "modifiers": _vm.filters.country.modifiers,
      "options": [],
      "placeholder": "Select",
      "track-by": "code",
      "label": "label",
      "multiple": true,
      "close-on-select": false,
      "query": _vm.COUNTRIES_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          withoutAll: true
        }
      },
      "edit-placeholder": "Paste Countries"
    },
    model: {
      value: _vm.filters.country.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.country, "value", $$v);
      },
      expression: "filters.country.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Device",
      "modifiers": _vm.filters.device.modifiers,
      "options": ['Desktop', 'Mobile'],
      "placeholder": "Select",
      "multiple": true,
      "close-on-select": true
    },
    model: {
      value: _vm.filters.device.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.device, "value", $$v);
      },
      expression: "filters.device.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Offer",
      "modifiers": _vm.filters.offer.modifiers,
      "options": [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "multiple": true,
      "close-on-select": false,
      "query": _vm.OFFER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": "Paste Offers"
    },
    model: {
      value: _vm.filters.offer.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.offer, "value", $$v);
      },
      expression: "filters.offer.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Redirect",
      "modifiers": _vm.filters.redirect.modifiers,
      "options": [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "multiple": true,
      "close-on-select": false,
      "query": _vm.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": "Paste Redirects"
    },
    model: {
      value: _vm.filters.redirect.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.redirect, "value", $$v);
      },
      expression: "filters.redirect.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Algorithms",
      "modifiers": _vm.filters.algorithm.modifiers,
      "options": [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "id",
      "multiple": true,
      "close-on-select": false,
      "query": _vm.ALGORITHMS_QUERY,
      "edit-placeholder": "Paste Algorithms"
    },
    model: {
      value: _vm.filters.algorithm.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.algorithm, "value", $$v);
      },
      expression: "filters.algorithm.value"
    }
  })], 1)]), _c('div', {
    staticClass: "flex justify-between my-1"
  }, [_c('div'), _c('div', [_c('YButton', {
    attrs: {
      "disabled": _vm.selectedRowIds.length == 0,
      "color": "red",
      "is-loading": _vm.isDeleting
    },
    on: {
      "click": _vm.deleteItems
    }
  }, [_vm._v("Delete")])], 1)]), _vm.smartWeightQueueItems ? _c('YTable', {
    attrs: {
      "data": ((_vm$smartWeightQueueI = _vm.smartWeightQueueItems) === null || _vm$smartWeightQueueI === void 0 ? void 0 : _vm$smartWeightQueueI.data) || [],
      "loading": _vm.$apolloData.queries.smartWeightQueueItems.loading,
      "selectable": true,
      "selectBy": "id",
      "selected": _vm.selectedRowIds
    },
    on: {
      "update:selected": function ($event) {
        _vm.selectedRowIds = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _props$rowData, _props$rowData$offer, _props$rowData$redire, _props$rowData2, _props$rowData2$redir, _props$rowData3, _props$rowData4, _props$rowData4$count, _props$rowData5, _props$rowData5$devic, _props$rowData6, _props$rowData6$algor, _props$rowData7, _props$rowData7$algor;
        return [_c('td', [_vm._v(_vm._s((_props$rowData = props.rowData) === null || _props$rowData === void 0 ? void 0 : (_props$rowData$offer = _props$rowData.offer) === null || _props$rowData$offer === void 0 ? void 0 : _props$rowData$offer.label))]), _c('td', [_vm._v(" " + _vm._s((_props$rowData$redire = (_props$rowData2 = props.rowData) === null || _props$rowData2 === void 0 ? void 0 : (_props$rowData2$redir = _props$rowData2.redirect) === null || _props$rowData2$redir === void 0 ? void 0 : _props$rowData2$redir.label) !== null && _props$rowData$redire !== void 0 ? _props$rowData$redire : (_props$rowData3 = props.rowData) === null || _props$rowData3 === void 0 ? void 0 : _props$rowData3.redirectId) + " ")]), _c('td', [_vm._v(_vm._s((_props$rowData4 = props.rowData) === null || _props$rowData4 === void 0 ? void 0 : (_props$rowData4$count = _props$rowData4.country) === null || _props$rowData4$count === void 0 ? void 0 : _props$rowData4$count.toUpperCase()))]), _c('td', [_vm._v(_vm._s((_props$rowData5 = props.rowData) === null || _props$rowData5 === void 0 ? void 0 : (_props$rowData5$devic = _props$rowData5.device) === null || _props$rowData5$devic === void 0 ? void 0 : _props$rowData5$devic.capitalize()))]), _c('td', [_vm._v(" " + _vm._s((_props$rowData6 = props.rowData) === null || _props$rowData6 === void 0 ? void 0 : (_props$rowData6$algor = _props$rowData6.algorithm) === null || _props$rowData6$algor === void 0 ? void 0 : _props$rowData6$algor.id) + " - " + _vm._s((_props$rowData7 = props.rowData) === null || _props$rowData7 === void 0 ? void 0 : (_props$rowData7$algor = _props$rowData7.algorithm) === null || _props$rowData7$algor === void 0 ? void 0 : _props$rowData7$algor.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(props.rowData.retryDate) + " ")]), _c('td', [_vm._v(" " + _vm._s(props.rowData.createdAt) + " ")])];
      }
    }], null, false, 1054347307)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Offer")]), _c('th', [_vm._v("Redirect")]), _c('th'), _c('th'), _c('th', [_vm._v("Algorithm")]), _c('th', [_vm._v("Retry Date")]), _c('th', [_vm._v("Queued On")])])], 2) : _vm._e(), (_vm$smartWeightQueueI2 = _vm.smartWeightQueueItems) !== null && _vm$smartWeightQueueI2 !== void 0 && _vm$smartWeightQueueI2.data ? _c('YPagination', {
    key: _vm.totalPages,
    attrs: {
      "data": _vm.smartWeightQueueItems.paginatorInfo
    },
    on: {
      "load-page": _vm.loadPage,
      "next-page": _vm.nextPage,
      "previous-page": _vm.previousPage,
      "per-page": _vm.perPageSelection
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }