<template>
  <div class="widget-body relative flex-grow" :class="classes">
    <div
      :class="[
        'widget-body-inner',
        scrollable ? 'absolute h-full w-full is-scrollable left-0' : '',
        innerWidgetClass
      ]"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    scrollable: {
      type: Boolean,
      default: false
    },
    innerWidgetClass: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    classes() {
      let classes = []
      // if (this.scrollable) {
      //   classes.push('is-scrollable')
      // }
      return classes
    }
  },
  mounted() {},

  updated() {},

  methods: {}
}
</script>
<style scoped>
.is-scrollable {
  overflow-y: scroll;
}
</style>
