var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('Widget', {
    staticClass: "mb-1"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-100"
  }, [_vm._v("Upload ")]), _c('WidgetBody', {
    staticClass: "pt-0 bg-gray-100"
  }, [_c('BulkPayoutUpload', {
    attrs: {
      "loading-preview": _vm.loadingPreview
    }
  })], 1)], 1), _c('UploadPreview', {
    attrs: {
      "loadingPreview": _vm.loadingPreview
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }