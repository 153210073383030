import ValidationErrors from '@/components/ui/ValidationErrors'
import ValidationErrorsClass from '@/assets/js/ValidationErrors.js'

export default {
  components: {
    ValidationErrors
  },
  data() {
    return {
      validationErrors: null
    }
  },
  methods: {
    initValidationErrors() {
      return new ValidationErrorsClass()
    },
    setValidationErrors(error) {
      let errors = this.initValidationErrors()
      errors.extractErrorsFrom(error)
      this.validationErrors = errors.errors
    },
    clearValidationErrors() {
      this.validationErrors = null
    }
  }
}
