var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center",
    class: _vm.className
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.radioButtonValue,
      expression: "radioButtonValue"
    }],
    staticClass: "mr-1",
    attrs: {
      "id": _vm.id,
      "type": "radio",
      "name": _vm.name,
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.radioValue,
      "checked": _vm._q(_vm.radioButtonValue, _vm.radioValue)
    },
    on: {
      "change": function ($event) {
        _vm.radioButtonValue = _vm.radioValue;
      }
    }
  }), _c('label', {
    attrs: {
      "for": _vm.id
    }
  }, [_vm._v(_vm._s(_vm.label))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }