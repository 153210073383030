var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('portal', {
    attrs: {
      "to": "alerts"
    }
  }, [_vm.publisher && _vm.publisher.isQuarantined ? _c('div', {
    staticClass: "bg-yellow-200 border-b border-yellow-300 text-yellow-800 px-4 py-4",
    attrs: {
      "allow-close": false
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('div', {
    staticClass: "flex items-center mr-6 flex-shrink"
  }, [_c('Icon', {
    staticClass: "h-8 w-8",
    attrs: {
      "name": "information"
    }
  })], 1), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "uppercase font-bold flex justify-between"
  }, [_vm._v(" You are currently under Quarantine ")]), _c('div', {
    staticClass: "text-xs"
  }, [_vm._v(" You can subscribe to a maximum of "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("3 campaigns")]), _vm._v(" per month. ")])]), _c('div')]), _c('div', {
    staticClass: "text-center ml-6"
  }, [_c('div', {
    staticClass: "text-xs font-semibold"
  }, [_vm._v("USED")]), _c('div', {
    staticClass: "text-base font-bold"
  }, [_vm._v(" " + _vm._s(_vm.publisher.quarantine.campaignSubscribeCount) + "/3 ")])])])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }