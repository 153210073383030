var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "bg-red-600 border-red-700 text-white"
  }, [_c('WidgetHeader', {
    staticClass: "tiny-forms pt-4 pb-4",
    attrs: {
      "closeable": true,
      "closeClass": "text-white"
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "text-white"
  }, [_vm._v(" Delete Server - " + _vm._s(this.server.provider.name) + " - " + _vm._s(this.server.id) + " ")])]), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('h5', [_c('strong', {
    staticClass: "text-warning",
    staticStyle: {
      "color": "#ffc107 !important"
    }
  }, [_vm._v("Are you sure? This action cannot be undone")])]), _vm.serverHasRecords ? _c('h5', [_vm._v(" This server has records associated with it and cannot be deleted. ")]) : _vm._e()]), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 px-4 flex justify-between bg-red-700"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center",
    attrs: {
      "label": "Close on Complete",
      "type": "primary"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('y-button', {
    staticClass: "bg-red-800 hover:bg-red-900 text-white ml-auto",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "disabled": !_vm.ydnsServers || _vm.serverHasRecords
    },
    on: {
      "click": function ($event) {
        return _vm.deleteItems([_vm.server.id]);
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.deleteItems([_vm.server.id]);
      }
    }
  }, [_vm._v(" Delete ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }