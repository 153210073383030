var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Icon', {
    class: ['spinner', _vm.color ? `text-${_vm.color}-500` : '', _vm.size ? `w-${_vm.size} h-${_vm.size}` : ''],
    attrs: {
      "name": "loading"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }