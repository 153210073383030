<template>
    <widget>
      <widget-header @close="$emit('close')">
        Create Offer Studio User
      </widget-header>
      <widget-body>
        <form class="row mb-2" @submit.prevent="update" ref="form">
  
          <YInput v-model="user.name" label="User Name" class="mb-2"></YInput>
          <YInput v-model="user.email" label="Email" class="mb-2"></YInput>
          <YInput v-model="user.password" label="Password" class="mb-2"></YInput>
        </form>
      </widget-body>
      <widget-footer class="bg-gray-100 p-4 text-right">
        <y-button
          class="btn btn-blue"
          :is-loading="isSubmitting"
          @click="create"
          @keydown.enter="create"
          :disabled="!canSubmit"
          >Create User</y-button
        >
      </widget-footer>
      <ValidationErrors
        v-if="validationErrors"
        :errors="validationErrors"
      ></ValidationErrors>
    </widget>
  </template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {},
  components: {},
  mounted() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#@$%';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 10) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    this.user.password = result;
  },
  updated() {},
  data() {
    return {
      user: {
        name: null,
        password: null,
        email: null,
      },
      isSubmitting: false,
      colSize: 'col-xl-3 col-lg-6',
      colSize2: 'col-xl-6 col-lg-12',
      protocolOptions: ['http', 'https'],
      conversionTypeOptions: [],
      useDefaultConversionType: true
    }
  },
  watch: {},
  computed: {
    canSubmit() {
      return true
    },
    makeId() {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#@$%';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 10) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    },
    
  },
  methods: {
    create() {
      this.isSubmitting = true
      let input = {
        email: this.user.email,
        name: this.user.name,
        password: this.user.password,
        password_confirmation: this.user.password
      }
      console.log(input)
      this.$apollo
        .mutate({
          mutation: gql`
            mutation RegisterOfferStudioUser($input: RegisterOfferStudioUser) {
                registerOfferStudioUser(input: $input){
                    id
                    email
              }
            }
          `,
          variables: {
            input: input
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('User Created', 'Success!')
          this.isSubmitting = false
          this.$emit('close')
        })
        .catch(error => {
          console.log('error')
          this.isSubmitting = false
          this.setValidationErrors(error)
        })
    }
  },
  close() {
    this.$emit('close')
  },
}
</script>
