<template>
  <Tag
    v-if="isGroup"
    :color="'white'"
    :class="[
      'condition-group',
      `${type}-condition-tag condition-tag`,
      `${isNot ? 'condition-is-not' : 'condition-is'}`,
      `${differentialChange ? 'condition-differential-change' : ''}`,
      'items-center',
      'select-none',
      nestLevel == 1 ? 'condition-base-level' : ''
    ]"
    :differential="localDifferential"
    :nestLevel="nestLevel + 1"
  >
    <div>
      <div class="inline-flex items-center">
        <v-popover offset="0" class="p-0">
          <button class="p-1  option-button outline-none-important" v-if="edit">
            <Icon name="dotsVertical" class="w-3 h-3" />
          </button>
          <template slot="popover">
            <ul class="text-xs">
              <li>
                <YButton class="w-full" @click="toggleAndOr()"
                  >Toggle And / Or ({{ isAnd ? 'And' : 'Or' }})</YButton
                >
              </li>
              <li>
                <YButton class="w-full" @click="toggleNot()"
                  >Toggle Not ({{ isNot }})</YButton
                >
              </li>
              <li>
                <YButton class="w-full" @click="addGroup('and')"
                  >Add New Group</YButton
                >
              </li>
            </ul>
          </template>
        </v-popover>
        <span v-if="isNot" class="text-red-600 mr-2">NOT</span>
        <template v-for="(childCondition, index) in childConditions">
          <template v-if="childCondition">
            <span
              v-if="index > 0"
              :key="
                childCondition.tag
                  ? childCondition.tag.name + nestLevel
                  : index + nestLevel
              "
              class="condition-operator"
            >
              <span>
                {{ condition.or ? '|' : '&' }}
              </span>
            </span>
            <Condition
              :key="childCondition.tag ? childCondition.tag.name : index"
              :nestLevel="nestLevel + 1"
              :differential="localDifferential"
              v-model="childConditions[index]"
              @remove="removeCondition"
              :edit="edit"
            />
          </template>
        </template>
        <v-popover v-if="edit" offset="0" class="p-0">
          <button
            class="px-2 outline-none-important"
            :class="[
              `hover:bg-${trueColor}-300`,
              `focus:bg-${trueColor}-300`,
              `border-${trueColor}- 00`,
              `border-dashed`,
              'border',
              'py-1',
              'px-3',
              'ml-2',
              'rounded-full',
              'relative'
            ]"
          >
            ADD
          </button>
          <template slot="popover">
            <TagsSelect
              ref="tagsSelect"
              title="Tags"
              :value="[]"
              :multiple="true"
              :options-limit="5"
              :close-on-select="true"
              :filterTypes="trafficTagTypes"
              @select="
                value => {
                  addTag(value)
                }
              "
              style="min-width: 15rem;"
            ></TagsSelect>
            <!-- <Superselect
                  title="Group"
                  :options="['and', 'or']"
                  @select="
                    value => {
                      addGroup(value)
                    }
                  "
                >
              </Superselect> -->
            <!-- <Superselect
              title="Campaign"
              :multiple="true"
              :value="[]"
              :options="campaignOptions ? campaignOptions.data : []"
              :options-limit="5"
              track-by="id"
              label="label"
              @select="
                value => {
                  addCampaign(value)
                }
              "
            >
            </Superselect>
            <Superselect
              title="Redirect"
              :multiple="true"
              :value="[]"
              :options="redirectOptions ? redirectOptions.data : []"
              :options-limit="5"
              track-by="id"
              label="label"
              @select="
                value => {
                  addRedirect(value)
                }
              "
            >
            </Superselect>
            <Superselect
              title="Publisher"
              :multiple="true"
              :value="[]"
              :options="publisherOptions ? publisherOptions.data : []"
              :options-limit="5"
              track-by="id"
              label="label"
              @select="
                value => {
                  addPublisher(value)
                }
              "
            >
            </Superselect> -->
          </template>
        </v-popover>
      </div>
    </div>
    <template v-slot:afterIcon>
      <button
        v-if="nestLevel != 1 && edit"
        class="py-1 px-2 -ml-2 outline-none-important relative rounded-full"
        :class="[
          `hover:bg-${trueColor}-300`,
          `focus:bg-${trueColor}-300`,
          `focus:shadow-sm`
        ]"
        @click="removeCondition()"
      >
        <Icon name="close" class="w-3 h-3 cursor-pointer edit-control" />
      </button>
    </template>
  </Tag>
  <component
    :is="tagNest ? 'Tag' : 'div'"
    v-else-if="condition.tag"
    v-tooltip="condition.tag.type"
    :color="trueColor"
    :differential="localDifferential"
    :class="[
      `${type}-condition-tag condition-tag`,
      `${isNot ? 'condition-is-not' : ''}`,
      `${differentialChange ? 'condition-differential-change' : ''}`,
      'inline-flex',
      'border-' + trueColor + '-300'
    ]"
  >
    <template v-slot:icon>
      <button
        v-if="edit"
        class="py-1 px-2 -mr-2 outline-none-important relative rounded-full"
        :class="[
          `hover:bg-${trueColor}-300`,
          `focus:bg-${trueColor}-300`,
          `focus:shadow-sm`
        ]"
        @click="toggleNot()"
      >
        <Icon name="exclamation" class="w-3 h-3  cursor-pointer" />
      </button>
    </template>
    <span class="text-red-600">{{ condition.not ? 'NOT' : '' }}</span>
    {{ condition.tag.name }}
    <template v-slot:afterIcon>
      <button
        v-if="edit"
        class="py-1 px-2 -ml-2 outline-none-important relative rounded-full"
        :class="[
          `hover:bg-${trueColor}-300`,
          `focus:bg-${trueColor}-300`,
          `focus:shadow-sm`
        ]"
        @click="removeCondition()"
      >
        <Icon name="close" class="w-3 h-3  cursor-pointer" />
      </button>
    </template>
  </component>
  <div v-else-if="condition.tag !== undefined" class="inline-flex pl-1">
    <v-popover offset="0" class="p-0">
      <button
        class="px-2 outline-none-important"
        :class="[
          `bg-${trueColor}-500`,
          `hover:bg-${trueColor}-600`,
          `focus:bg-${trueColor}-600`,
          `border-${trueColor}-600`,
          'border-l',
          'border-t',
          'border-b',
          'text-white',
          'py-1',
          'px-2',
          'pl-3',
          'rounded-l-sm'
        ]"
      >
        SELECT
      </button>
      <template slot="popover">
        <TagsSelect
          ref="tagsSelect"
          title="Tags2"
          :value="[]"
          :multiple="true"
          :close-on-select="true"
          @select="
            value => {
              localValue.tag = value
            }
          "
          style="min-width: 15rem;"
        ></TagsSelect>
      </template>
    </v-popover>
    <button
      :class="[
        'outline-none-important',
        `bg-${trueColor}-500`,
        `hover:bg-${trueColor}-600`,
        `focus:bg-${trueColor}-600`,
        `border-${trueColor}-600`,
        'border-r',
        'border-t',
        'border-b',
        'text-white',
        'py-1',
        'px-1',
        'rounded-r-sm'
      ]"
      @click="removeCondition()"
    >
      <Icon v-if="edit" name="close" class="w-3 h-3  cursor-pointer" />
    </button>
  </div>
</template>

<script>
import TagsSelect from '@/views/Admin/Tags/TagsSelect'
// import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
// import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
// import USER_OPTIONS_QUERY from '@/graphql/User/UserOptionsQuery.gql'
import TRAFFIC_TAG_TYPES_QUERY from '@/graphql/Tag/TrafficTagTypesQuery.gql'

export default {
  apollo: {
    trafficTagTypes: {
      query: TRAFFIC_TAG_TYPES_QUERY,
      result({ data }) {
        this.trafficTagTypes = data.trafficTagTypes.enumValues.map(
          value => value.name
        )
      }
    }
  },
  // apollo: {
  //   campaignOptions: {
  //     query: CAMPAIGN_OPTIONS_QUERY,
  //     variables: {
  //       first: 100000
  //     }
  //   },
  //   redirectOptions: {
  //     query: REDIRECT_OPTIONS_QUERY,
  //     variables: {
  //       first: 100000
  //     }
  //   },
  //   publisherOptions: {
  //     query: USER_OPTIONS_QUERY,
  //     update: data => data.userOptions,
  //     variables: {
  //       first: 100000,
  //       filters: {
  //         roles: {
  //           name: {
  //             value: ['Publishers']
  //           }
  //         }
  //       }
  //     }
  //   }
  // },
  components: {
    TagsSelect
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    tagNest: {
      type: Boolean,
      default: true
    },
    color: {
      type: String
    },
    differential: {
      type: Boolean,
      default: false
    },
    nestLevel: {
      type: Number,
      default: 1
    },
    edit: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    }
  },
  data() {
    return {
      localValue: this.value,
      tag: {},
      trafficTagTypes: []
    }
  },
  mounted() {},
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (JSON.stringify(newValue) != JSON.stringify(this.localValue)) {
          this.localValue = newValue
        }
      }
    },
    localValue: {
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue)
      }
    },
    childConditions: {
      deep: true,
      handler(newValue) {
        // remove null values
        if (newValue) {
          let values = newValue
          values = values.filter(value => {
            return value !== null
          })
          setTimeout(() => {
            if (JSON.stringify(values) != JSON.stringify(newValue)) {
              if (this.isAnd) {
                this.localValue.and = values
              } else if (this.isOr) {
                this.localValue.or = values
              }
            }
          }, 1)
        }
      }
    }
  },
  computed: {
    condition() {
      return this.value
    },
    isGroup() {
      return this.condition.and || this.condition.or
    },
    isAnd() {
      return this.condition.and
    },
    isOr() {
      return this.condition.or
    },
    isTag() {
      return this.condition.tag
    },
    isNot() {
      return this.condition.not ? true : false
    },
    localDifferential() {
      return this.isNot ? !this.differential : this.differential
    },
    differentialChange() {
      return this.localDifferential != this.differential
    },
    trueColor() {
      return this.isNot ? 'blue' : 'blue'
    },
    differentialColor() {
      if (this.localDifferential) {
        return 'blue'
      }
      if (!this.localDifferential) {
        return 'blue'
      }
      return ''
    },
    type() {
      return this.condition.tag
        ? 'tag'
        : this.condition.and || this.condition.or
        ? 'group'
        : 'other'
    },
    childConditions: {
      get() {
        return this.condition.or || this.condition.and
      }
    }
  },
  methods: {
    removeCondition() {
      this.localValue = null
      this.$emit('input', this.localValue)
    },
    toggleNot() {
      if (this.localValue.not !== undefined && this.localValue.not === true) {
        delete this.localValue.not
      } else {
        this.localValue.not = true
      }
      this.localValue = JSON.parse(JSON.stringify(this.localValue))
    },
    toggleAndOr() {
      if (this.isAnd) {
        this.localValue.or = JSON.parse(JSON.stringify(this.localValue.and))
        this.localValue.and = null
        this.localValue = JSON.parse(JSON.stringify(this.localValue))
      } else {
        this.localValue.and = JSON.parse(JSON.stringify(this.localValue.or))
        this.localValue.or = null
        this.localValue = JSON.parse(JSON.stringify(this.localValue))
      }
    },
    addTag(tag) {
      if (this.isGroup) {
        if (this.isAnd) {
          this.localValue.and.push({
            tag: tag
              ? { name: tag.name, type: tag.type, isNot: tag.isNot }
              : null
          })
        } else if (this.isOr) {
          this.localValue.or.push({
            tag: tag
              ? { name: tag.name, type: tag.type, isNot: tag.isNot }
              : null
          })
        }
      }
    },
    addCampaign(campaign) {
      if (this.isGroup) {
        if (this.isAnd) {
          this.localValue.and.push({
            campaign: campaign ? { id: campaign.id } : null
          })
        } else if (this.isOr) {
          this.localValue.or.push({
            campaign: campaign ? { id: campaign.id } : null
          })
        }
      }
    },
    addRedirect(redirect) {
      if (this.isGroup) {
        if (this.isAnd) {
          this.localValue.and.push({
            redirect: redirect ? { id: redirect.id } : null
          })
        } else if (this.isOr) {
          this.localValue.or.push({
            redirect: redirect ? { id: redirect.id } : null
          })
        }
      }
    },
    addPublisher(publisher) {
      if (this.isGroup) {
        if (this.isAnd) {
          this.localValue.and.push({
            publisher: publisher ? { id: publisher.id } : null
          })
        } else if (this.isOr) {
          this.localValue.or.push({
            publisher: publisher ? { id: publisher.id } : null
          })
        }
      }
    },
    addSource(sourceId) {
      if (this.isGroup) {
        if (this.isAnd) {
          this.localValue.and.push({
            source: { id: sourceId }
          })
        } else if (this.isOr) {
          this.localValue.or.push({
            source: { id: sourceId }
          })
        }
      }
    },
    addGroup(type = 'and') {
      let typeObject = type == 'and' ? { and: [] } : { or: [] }
      if (this.isGroup) {
        if (this.isAnd) {
          this.localValue.and.push(typeObject)
        } else if (this.isOr) {
          this.localValue.or.push(typeObject)
        }
      }
    }
  }
}
</script>
<style scoped>
/* .condition-group .option-button {
  display: none;
}
.condition-group:hover > span > div > div > .option-button {
  display: block;
} */

.edit .condition-tag:hover {
  /* @apply pl-8; */
}
.condition-tag {
  @apply inline-flex border;
  transition: padding 0.3s, width 0.3s;
}

.group-condition-tag > .tag > .condition-tag:first-child {
  @apply -ml-2;
}

.group-condition-tag > .tag > .condition-tag:last-child {
  @apply -mr-2;
}

.condition-base-level.tag > .tag:first-child {
  @apply -ml-2;
}

.condition-base-level.tag > .tag:last-child {
  @apply -mr-2;
}

.condition-operator {
  @apply flex w-4 relative items-center;
}

.condition-differential-change {
  /* @apply mx-3; */
}
.condition-operator > span {
  @apply w-full text-center;
}

.condition-base-level > .condition-operator {
  @apply mx-0 text-center;
}
.condition-base-level.condition-is {
  @apply bg-transparent border-transparent;
}
.condition-group {
  @apply border;
}

.rule-popover {
  background: transparent;
}
</style>
