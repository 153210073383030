<template>
  <div class="shadow rounded-lg">
    <YTable v-if="data" :data="data || []">
      <template v-slot:header-row>
        <th></th>
        <th>name</th>
        <th>
          last updated
        </th>
        <th>tags</th>
        <th></th>
      </template>

      <template v-slot:row="props">
        <td class="w-40">
          <EditableImage
            class="rounded"
            :imageUrl="props.rowData.imageUrl"
            :thumbnailUrl="props.rowData.imageThumbnailUrl"
            :height="32"
            :width="32"
            :blankHeight="20"
            :blankWidth="20"
            :editable-only-when-empty="true"
            modelClass="Image"
            :modelId="props.rowData.id"
          ></EditableImage>
        </td>
        <td>
          <span class="font-bold">{{ props.rowData.name }}</span>
          <a
            href="#"
            class="inline-block"
            @click.prevent="copyToClipboard(props.rowData.name)"
          >
            <Icon
              v-tooltip.top="`Copy to Clipboard`"
              name="contentCopy"
              :size="4"
              class="ml-4 inline-block text-blue-600"
            />
          </a>
        </td>
        <td>
          <span class="text-gray-700">{{ date(props.rowData.updatedAt) }}</span>
        </td>
        <td>
          <div>
            <EditableValue
              ref="tagsEditable"
              :value="props.rowData.tags.filter(tag => tag.type === 'vertical')"
              type="slot"
              :mutation="SYNC_TAGS_WITH_TYPE_MUTATION"
              sortBy="id"
              :transformValueForQuery="
                value => {
                  return value.map(tag => tag.name)
                }
              "
              trackBy="type"
              :variables="{
                input: {
                  type: 'vertical',
                  taggableType: 'image',
                  taggableId: props.rowData.id,
                  tags: '{value}'
                }
              }"
            >
              <template v-slot:default="slotProps">
                <div v-if="slotProps.value && slotProps.value.length > 0">
                  <Tag
                    v-for="tag in slotProps.value"
                    :key="tag.id"
                    class="mr-1 my-1"
                    :color="tag.color"
                    tabindex="0"
                    @focus="
                      () => {
                        slotProps.focus()
                      }
                    "
                    >{{ tag.name }}</Tag
                  >
                </div>
                <div
                  v-else
                  @click="
                    () => {
                      slotProps.focus()
                    }
                  "
                >
                  <span
                    class="italic text-gray-500 hover:bg-blue-200 cursor-pointer"
                    >None</span
                  >
                </div>
              </template>
              <template v-slot:focusSlot="slotProps">
                <Superselect
                  title="Tags"
                  :value="slotProps.value"
                  @input="
                    value => {
                      slotProps.updateInternalValue(value)
                    }
                  "
                  @close="
                    () => {
                      //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
                    }
                  "
                  :focusOnMount="true"
                  :multiple="true"
                  track-by="slug"
                  label="name"
                  placeholder="Select"
                  class="box-width min-width-full"
                  :query="TAGS_QUERY"
                  :query-variables="{
                    filters: {
                      type: { value: ['vertical'] }
                    },
                    first: 100
                  }"
                ></Superselect>
              </template>
            </EditableValue>
          </div>
        </td>
        <td class="text-right">
          <div class="inline-flex gap-1">
            <span @click="openImageEditModal(props.rowData)">
              <Icon
                name="pencil"
                size="4"
                class="text-blue-600 cursor-pointer"
              />
            </span>

            <span @click="openConfirmationModal(props.rowData.id)">
              <Icon
                name="trashCan"
                size="4"
                class="text-red-600 hover:text-red-700 cursor-pointer"
              />
            </span>
          </div>
        </td>
      </template>
    </YTable>

    <!-- Delete Modal -->
    <modal name="delete-modal" :width="364" :height="140">
      <Widget class="bg-red-600 text-white border-red-700">
        <WidgetHeader class="bg-red-600 text-white"
          ><span class="text-white"
            >Are you sure you want to delete this image?</span
          ></WidgetHeader
        >
        <WidgetBody>
          <h5 class="text-yellow-400 font-bold">
            This action cannot be undone.
          </h5>
        </WidgetBody>
        <WidgetFooter
          class="bg-dark-transparent-1 flex justify-end gap-6 py-2 px-4"
        >
          <YButton
            class="border hover:bg-red-500 focus:bg-red-700"
            @click="$modal.hide('delete-modal')"
            >Cancel</YButton
          >
          <YButton
            class="bg-red-700 hover:bg-red-800 border focus:bg-red-700"
            @click="confirmationOfDelete"
            >Delete</YButton
          >
        </WidgetFooter>
      </Widget>
    </modal>
  </div>
</template>

<script>
import EditableImage from '@/components/utilities/EditableImage'
import EditImageModal from '@/views/Admin/ImageRepository/EditImageModal'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import SYNC_TAGS_WITH_TYPE_MUTATION from '@/graphql/Tag/SyncTagsWithTypeMutation.gql'
import DELETE_IMAGE from '@/graphql/ImageRepository/DeleteImageMutation.gql'

export default {
  mounted() {},
  updated() {},
  components: {
    EditableImage
  },
  props: {
    data: {
      default: () => {
        ;[]
      },
      type: Array
    }
  },
  data() {
    return {
      TAGS_QUERY,
      SYNC_TAGS_WITH_TYPE_MUTATION,
      deleteItemId: null
    }
  },
  methods: {
    date(value) {
      return value.slice(0, 10)
    },
    copyToClipboard(content) {
      this.$copyText(content).then(
        this.$toastr.s('Copied to Clipboard', 'Success!')
      )
    },
    openConfirmationModal(value) {
      this.deleteItemId = value
      this.$modal.show('delete-modal')
    },
    confirmationOfDelete() {
      this.$apollo
        .mutate({
          mutation: DELETE_IMAGE,
          variables: {
            id: this.deleteItemId
          }
        })
        .then(() => {
          this.$events.emit('refreshImages')
          this.$modal.hide('delete-modal')
          this.$toastr.s('Image Deleted')
        })
        .catch(error => {
          console.error(error)
        })
    },
    openImageEditModal(data) {
      this.$modal.show(
        EditImageModal,
        { imageData: data },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  },
  computed: {}
}
</script>
