<template>
  <div>
    <div
      v-if="$apollo.queries.campaignTopPublisherStats.loading"
      class="text-center"
    >
      <Spinner :size="8" color="green" class="mb-4" />
    </div>
    <div
      v-else-if="
        campaignTopPublisherStats && campaignTopPublisherStats.length > 0
      "
    >
      <div
        v-for="stats in campaignTopPublisherStats"
        :key="stats.publisher.id"
        class="px-4 xitem"
      >
        <div class="mb-2">
          <router-link
            :to="{ name: 'User', params: { id: stats.publisher.id } }"
            class="link"
          >
            {{ stats.publisher.label }}
          </router-link>
        </div>
        <div class="grid grid-rows-2 grid-flow-col gap-y-0 gap-x-2">
          <div class="xlabel" style="text-transform: uppercase;">
            Today
          </div>
          <div class="xstat">{{ $formatMoney(stats.todaysRevenue) }}</div>

          <div class="xlabel" style="text-transform: uppercase;">
            This Week
          </div>
          <div class="xstat">{{ $formatMoney(stats.thisWeeksRevenue) }}</div>

          <div class="xlabel" style="text-transform: uppercase;">
            Last Week
          </div>
          <div class="xstat">{{ $formatMoney(stats.lastWeeksRevenue) }}</div>

          <div
            class="xlabel"
            style="text-transform: uppercase;"
            v-tooltip="`This Week / Last Week`"
          >
            Change
          </div>
          <div
            class="xstat"
            :class="stats.percentChange > 1 ? 'text-success' : 'text-danger'"
          >
            {{ $formatPercent(stats.percentChange - 1) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center text-gray-600 pb-8 pt-4">
      Sorry, no top publishers are available.
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  components: {},
  props: {
    campaignId: {
      type: Number,
      required: true
    }
  },
  apollo: {
    campaignTopPublisherStats: {
      query: gql`
        query getStats($campaignId: ID!) {
          campaignTopPublisherStats(campaignId: $campaignId) {
            publisher {
              id
              label
            }
            todaysRevenue
            thisWeeksRevenue
            lastWeeksRevenue
            percentChange
          }
        }
      `,
      variables() {
        return {
          campaignId: this.campaignId
        }
      }
    }
  },
  data() {
    return {
      campaignTopPublisherStats: []
    }
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {}
}
</script>
<style scoped>
.xitem {
  @apply py-4;
}

.xitem:nth-child(odd) {
  @apply bg-gray-200;
}

.xitem:last-child {
  @apply mb-0;
}

.xlabel {
  @apply text-gray-500 uppercase;
  font-size: 0.7rem;
}
.xstat {
  @apply text-blue-600 font-semibold text-sm;
}
</style>
