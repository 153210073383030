<template>
  <div
    class="flex flex-col w-8/12 xl:w-4/12 m-auto justify-center text-sm bg-white px-4 pt-12 pb-16 rounded-lg border border-gray-100 shadow-xl text-center relative"
  >
    <div class="flex flex-col">
      <div
        class="text-3xl font-bold mb-1"
        :class="{
          'text-red-800': loadingMessage.status == 'error'
        }"
      >
        {{ loadingMessage.title }}
      </div>
      <div class="text-lg mb-8 text-gray-950">
        <p
          class="mb-2 font-light text-gray-900"
          :class="{
            'text-red-700': loadingMessage.status == 'error'
          }"
        >
          {{ loadingMessage.headline }}
        </p>
        <p>{{ loadingMessage.text }}</p>
      </div>
    </div>
    <div class="mx-auto w-full relative" style="max-width: 24rem;">
      <div
        v-if="loadingMessage.loginInput"
        class="border-t"
        :class="{
          'fade-in': loadingMessage.loginInput
        }"
      >
        <Login />
      </div>
      <div
        v-if="!loadingMessage.loginInput"
        class="w-full w-full border rounded-lg p-5"
      >
        <div class="flex flex-col gap-3">
          <div class="skeleton-input is-loading"></div>
          <div class="skeleton-input is-loading"></div>
          <div class="w-32 h-4 rounded is-loading"></div>
          <div class="skeleton-input is-loading mt-6"></div>
          <div
            class="flex justify-center border-t border-gray-500 pt-4 mt-3"
          ></div>
          <div class="mx-auto w-9/12 h-4 rounded is-loading"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import Login from '@/views/Auth/Login.vue'
import VERIFY_USER_EMAIL from '@/graphql/User/VerifyUserEmailMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],
  components: {
    Login
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isSubmitting: false,
      loadingMessage: {
        status: '',
        title: '',
        headline: 'Validating...',
        text: '',
        loginInput: false
      },
      loadedMessage: {
        TOKEN_NOT_FOUND: {
          title: undefined,
          headline: 'Something went wrong.',
          text:
            "Please try logging in again. If you're still experiencing problems, contact us at contact@ytz.com",
          loginInput: true,
          status: 'error'
        },
        USER_NOT_FOUND: {
          title: 'Something went wrong',
          headline: 'Please try logging in again.',
          text:
            "If you're still experiencing problems, contact us at contact@ytz.com",
          loginInput: true,
          status: 'error'
        },
        EMAIL_VERIFY_SUCCESS: {
          title: 'Email Verified!',
          headline: undefined,
          text: 'Log in to get started',
          loginInput: true,
          status: 'success'
        }
      }
    }
  },
  mounted() {
    this.verifyEmail()
  },
  methods: {
    verifyEmail() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: VERIFY_USER_EMAIL,
          variables: {
            input: {
              token: this.token
            }
          }
        })
        .then(result => {
          this.isSubmitting = false
          let status = result.data.verifyUserEmail.status
          this.loadingMessage = this.loadedMessage[status]
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
          this.loadingMessage = this.loadedMessage['USER_NOT_FOUND']
        })
    }
  }
}
</script>

<style scoped>
.fade-in {
  opacity: 0;
  animation: fadeIn 0.3s forwards;
  animation-timing-function: ease-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.hiddenElement {
  visibility: hidden;
}
.skeleton-input {
  @apply h-12 w-full rounded-lg;
}
.skeleton-label {
  @apply h-6 w-3/5 rounded-lg;
}
.is-loading {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 75% 100%;
  animation-duration: 1500ms;
  animation-name: headerShine;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  animation-timing-function: ease;
  background-position: 0 0;
  background-color: #e4e4e7;
  background-blend-mode: overlay;
}
@keyframes headerShine {
  0% {
    background-position: -300% 0;
  }
  100% {
    background-position: 500% 0;
  }
}
</style>
