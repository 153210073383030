var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Redirect Pause Functionality")]), _c('WidgetBody', [_c('div', {
    staticClass: "flex flex-col items-center gap-4 px-4"
  }, [_c('YButton', {
    staticClass: "w-1/2",
    attrs: {
      "color": _vm.redirect.isAbyssed ? 'green' : 'red'
    },
    on: {
      "click": _vm.abyssRedirect
    }
  }, [_vm._v(_vm._s(_vm.redirect.isAbyssed ? 'Undo Abyss' : 'Abyss'))])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }