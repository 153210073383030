<template>
  <Widget>
    <WidgetHeader class="pt-4 pb-4" :closeable="true" @close="$emit('close')">
      <template>
        <div class="flex justify-between items-center w-full">
          <Icon name="filePlus" class="mr-2" />
          Add Server
        </div>
      </template>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <YInput label="Hostname" v-model="hostname" class="mb-1" />
      <YInput label="Server/ Monitor IP" v-model="serverIp" />
      <YInput label="SSH Port" v-model="sshPort" />
      <Superselect
        v-model="serverType"
        :options="typeOptions"
        label="label"
        title="Type"
        track-by="id"
        :multiple="false"
      ></Superselect>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <YCheckbox
        v-model="closeOnComplete"
        class="flex items-center"
        label="Close on Complete"
      ></YCheckbox>
      <YButton
        :is-loading="isSubmitting"
        class="ml-auto"
        color="blue"
        @click="create"
        @keydown.enter="create"
        >Add</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    provider: {
      type: Object,
      required: true
    }
  },
  components: {},
  apollo: {
    typeOptions: {
      query: gql`
        query {
          typeOptions: __type(name: "YdnsServerTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.typeOptions = data.typeOptions.enumValues.map(enumValue => ({
          id: enumValue.name,
          label: enumValue.name.capitalize()
        }))
      }
    }
  },
  data() {
    return {
      closeOnComplete: true,
      isSubmitting: false,
      server: {
        type: null,
        name: null
      },
      hostname: null,
      serverIp: null,
      serverType: null,
      sshPort: 22,
      typeOptions: [],
    }
  },
  watch: {},
  computed: {},
  methods: {
    create() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation YdnsCreateServer($input: YdnsCreateServerInput!) {
              ydnsCreateServer(input: $input) {
                id
                hostname
                serverIp
                provider {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            input: {
              hostname: this.hostname,
              serverIp: this.serverIp,
              sshPort: this.sshPort,
              type: this.serverType? this.serverType.id : null,
              provider: {
                connect: this.provider.id
              }
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('Server Added', 'Success!')
          this.$events.emit('refreshYdnsServers')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false

          this.setValidationErrors(error)
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
