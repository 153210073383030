var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grid grid-cols-1 gap-3 m-auto bg-white rounded-b-xl mb-12 border-l border-r border-b shadow-md p-6",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_c('h4', {
    staticClass: "font-bold text-base uppercase text-inverse-800"
  }, [_vm._v(" Reset Password ")]), !_vm.successMessage ? [_c('p', {
    staticClass: "text-gray-700"
  }, [_vm._v(" Enter the email address associated with your account and a set a new password. ")]), _c('YInput', {
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('YInput', {
    attrs: {
      "label": "New Password",
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Confirm Password",
      "type": "password"
    },
    model: {
      value: _vm.confirmPassword,
      callback: function ($$v) {
        _vm.confirmPassword = $$v;
      },
      expression: "confirmPassword"
    }
  }), _c('YButton', {
    staticClass: "w-full mt-6",
    attrs: {
      "color": "pink",
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("Submit")])] : _vm._e(), _vm.successMessage ? _c('YAlert', {
    staticClass: "mt-2",
    attrs: {
      "color": "green",
      "allow-close": false
    }
  }, [_vm._v(" " + _vm._s(_vm.successMessage) + " "), _c('router-link', {
    staticClass: "link mt-2 block",
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v("Return to login.")])], 1) : _vm._e(), _vm.errorMessage ? _c('YAlert', {
    staticClass: "mt-2",
    attrs: {
      "color": "red",
      "allow-close": false
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " "), _c('router-link', {
    staticClass: "link mt-2 block",
    attrs: {
      "to": {
        name: 'Forgot Password'
      }
    }
  }, [_vm._v("Try resending a password reset email.")])], 1) : _vm._e(), _c('portal-target', {
    attrs: {
      "name": "errors"
    }
  }), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors,
      "portal-to": "errors",
      "alert-class": "text-xs",
      "icon-size": 12,
      "icon": null,
      "color": "yellow"
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }