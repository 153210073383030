var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', [_c('WidgetHeader', [_vm._v(" Adjustment Preview ")]), _c('WidgetBody', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col table-scroll-container"
  }, [_c('vuetable', {
    ref: "vuetable",
    attrs: {
      "id": "reporting-table",
      "api-mode": false,
      "data": _vm.adjustmentsData,
      "pagination-path": "",
      "fields": _vm.fields,
      "css": _vm.css,
      "load-on-start": false,
      "cellspacing": "0",
      "width": "100%",
      "table-wrapper": "vuetable-wrapper",
      "append-params": _vm.appendParams
    },
    on: {
      "vuetable:load-error": _vm.onLoadError
    },
    scopedSlots: _vm._u([{
      key: "custom-actions-slot",
      fn: function (props) {
        return _c('div', {}, [_c('adjustments-preview-custom-actions', {
          attrs: {
            "row-data": props.rowData,
            "row-index": props.rowIndex
          }
        })], 1);
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "flex justify-end mt-3"
  }, [_c('YButton', {
    staticClass: "border border-blue-600 hover:bg-blue-600 focus:bg-blue-600 text-blue-600 hover:text-white focus:text-white mr-3 outline-none-important",
    attrs: {
      "type": "button",
      "disabled": _vm.isLoading || _vm.hasNoData
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearPreview.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear All")]), _c('YButton', {
    staticClass: "bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 text-white mr-3 outline-none-important",
    attrs: {
      "disabled": _vm.hasNoData,
      "is-loading": _vm.isLoading
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submitAdjustments.apply(null, arguments);
      }
    }
  }, [_vm._v("Submit")])], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }