var render = function render(){
  var _this$paymentSummary$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.paymentSummary ? _c('div', [_c('widget', {
    staticClass: "mb-4 p-1 text-base bg-gray-100"
  }, [_c('widget-body', [_c('div', {
    staticClass: "grid grid-cols-2 gap-3"
  }, [_c('div', [_c('span', {
    staticClass: "font-bold mr-2"
  }, [_vm._v(" Payment Frequency: ")]), _vm._v(" " + _vm._s(_vm.frequency) + " ")]), _c('div', [_vm.paymentMethod ? _c('span', {
    staticClass: "font-bold mr-2"
  }, [_vm._v(" Payment Method:")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.paymentMethod) + " ")]), _c('div', [_c('span', {
    staticClass: "font-bold mr-2"
  }, [_vm._v(" Payment Terms: ")]), _vm._v(" " + _vm._s(_vm.paymentTerms) + " ")]), _c('div', [_vm.threshold ? _c('span', {
    staticClass: "font-bold mr-2"
  }, [_vm._v(" Payment Threshold: ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.threshold) + " ")]), this.paymentSummary.paymentBalance ? _c('div', [_c('span', {
    staticClass: "font-bold mr-2"
  }, [_vm._v(" Outstanding Balance: ")]), _c('span', [_vm._v(_vm._s(Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(this.paymentSummary.paymentBalance)) + " ("), _c('span', {
    staticClass: "text-xs italic"
  }, [_vm._v("as of " + _vm._s(this.paymentSummary.confirmedAsDate))]), _vm._v(")")])]) : _c('div', [_c('span', {
    staticClass: "font-bold mr-2"
  }, [_vm._v(" Outstanding Balance: ")]), _vm._v("$0 ")]), _c('div', [_c('span', {
    staticClass: "font-bold mr-2"
  }, [_vm._v(" Next Payment Date: ")]), _vm._v(" " + _vm._s(_vm.paymentMethod == 'No Payment Method' || _vm.paymentMethod == 'Hold My Payments' ? _vm.paymentMethod : (_this$paymentSummary$ = this.paymentSummary.nextDate) !== null && _this$paymentSummary$ !== void 0 ? _this$paymentSummary$ : 'N/A') + " ")])]), _c('div', {
    staticClass: "w-full text-left pt-1"
  }, [_c('span', {
    staticClass: "text-xs italic"
  }, [_vm._v(" Please allow 2-3 business days for changes to payment information to be updated in your account.")])])])], 1), _c('div', {
    staticClass: "grid grid-cols-1 lg:grid-cols-7 2xl:grid-cols-5 gap-6"
  }, [_c('div', {
    staticClass: "col-span-5 2xl:col-span-3 flex gap-6"
  }, [_c('Tabs', {
    attrs: {
      "show-type": "show",
      "route": _vm.tabRoute
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Payment Info",
      "active": true
    }
  }, [_c('PublisherPaymentDetails', {
    attrs: {
      "user": _vm.me
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Payment History"
    }
  }, [_c('PublisherPaymentHistory', {
    attrs: {
      "user": _vm.me
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "col-span-2 2xl:col-span-2 flex flex-col gap-6 pt-8"
  }, [_vm.canadianResident ? _c('div', {
    staticClass: "flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
  }, [_vm._m(0), _c('form', {
    staticClass: "w-full",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "flex flex-col gap-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Tax Type",
      "multiple": false,
      "options": _vm.taxTypeOptions,
      "required": true
    },
    model: {
      value: _vm.localUser.billingContact.taxType,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.billingContact, "taxType", $$v);
      },
      expression: "localUser.billingContact.taxType"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Tax Number",
      "required": true
    },
    model: {
      value: _vm.localUser.billingContact.taxNumber,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.billingContact, "taxNumber", $$v);
      },
      expression: "localUser.billingContact.taxNumber"
    }
  }), _c('YButton', {
    staticClass: "mt-1 self-end w-32",
    attrs: {
      "color": "blue"
    }
  }, [_vm._v("Update")])], 1)])]) : _vm._e(), _c('div', {
    staticClass: "flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
  }, [_c('div', {
    staticClass: "uppercase text-xs font-semibold text-gray-800 flex justify-between items-center"
  }, [_vm._v(" Payment Methods & Thresholds "), _c('Icon', {
    staticClass: "text-teal-600",
    attrs: {
      "name": "information",
      "size": "6"
    }
  })], 1), _c('div', {
    staticClass: "text-xs text-teal-600"
  }, [_vm._v(" Payments process upon reaching the minimum thresholds for your chosen method. For payment inquiries, confirm your selected option. ")]), _c('table', {
    staticClass: "w-full bg-gray-100 mt-4"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.paymentMethodsTableValues, function (payment, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('div', {
      staticClass: "flex flex-col"
    }, [_c('p', [_vm._v(_vm._s(payment.name))]), _c('p', {
      staticClass: "text-xs"
    }, [_vm._v(_vm._s(payment.locationAvailable))]), _c('p', {
      staticClass: "text-xs text-gray-800 2xl:hidden"
    }, [_vm._v(" Currency Option: " + _vm._s(payment.currencyOption) + " ")])])]), _c('td', [_vm._v(_vm._s(payment.threshold))]), _c('td', {
      staticClass: "hidden 2xl:table-cell"
    }, [_vm._v(" " + _vm._s(payment.currencyOption) + " ")])]);
  }), 0)])])])])], 1) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "uppercase text-xs font-semibold text-gray-800 mb-2"
  }, [_vm._v(" canadian publishers only "), _c('span', {
    staticClass: "text-red-600"
  }, [_vm._v("*")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', {
    staticClass: "text-gray-800"
  }, [_c('th', [_vm._v("Payment Method")]), _c('th', [_vm._v("Threshold (USD)")]), _c('th', {
    staticClass: "hidden 2xl:block"
  }, [_vm._v("Currency Options")])])]);

}]

export { render, staticRenderFns }