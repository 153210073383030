<template>
  <tabs persist-as="redirects">
    <tab name="Redirects" :active="true">
      <RedirectsTable 
      :override-filters=this.overrideFilters 
      :overrideGqlFilters=this.overrideGqlFilters 
      :status=this.status
      >
      </RedirectsTable></tab>
    <tab name="Auto Abyssed"> <AbyssedRedirects></AbyssedRedirects></tab>
  </tabs>
</template>

<script>
import RedirectsTable from '@/views/Admin/Redirects/RedirectsTable.vue'
import AbyssedRedirects from '@/views/Admin/Redirects/AbyssedRedirects.vue'

export default {
  props: {
    overrideFilters: {
      type: Object
    },
    overrideGqlFilters: {
      type: Object
    },
    status: {
      type: String
    }
  },
  components: {
    RedirectsTable,
    AbyssedRedirects
  },
  apollo: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {},
  updated() {}
}
</script>
