export default {
  methods: {
    tagDefaultColour(type) {
      switch (type) {
        case 'admin':
          return 'black'

        case 'campaign':
          return 'purple'

        case 'campaignAllowedRestricted':
          return 'white'

        case 'campaignBrowser':
        case 'campaignOS':
          return 'orange'

        case 'countryCode':
        case 'geoLocation':
        case 'in-person':
          return 'green'

        case 'mobileDesktop':
        case 'offerType':
        case 'templateImage':
        case 'trafficType':
        case 'email':
          return 'blue'

        case 'referredOption':
        case 'referredType':
        case 'phone':
          return 'gray'

        case 'vertical':
        case 'messenger':
          return 'indigo'

        default:
          return 'blue'
      }
    }
  }
}
