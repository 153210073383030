<template>
  <Widget class="bg-red-600 text-white border-red-700">
    <WidgetHeader class="bg-red-600 text-white"
      ><span class="text-white">Are you sure?</span></WidgetHeader
    >
    <WidgetBody>
      <h5 class="text-yellow-400 font-bold">This action cannot be undone.</h5>
      <h5>
        Please confirm you'd like to
        <span class="text-yellow-400 font-bold"
          >DELETE
          <span class="px-1 underline text-lg">
            {{ this.rows.length }} items</span
          ></span
        >
        selected from Template Images.
      </h5>
    </WidgetBody>
    <WidgetFooter class="bg-dark-transparent-1 flex justify-between py-2 px-4">
      <YButton class="hover:bg-red-700 focus:bg-red-700" @click="$emit('close')"
        >Cancel</YButton
      >
      <YButton
        @click="deleteImage"
        class="hover:bg-red-700 focus:bg-red-700"
        :is-loading="isSubmitting"
        @keydown.enter="deleteImage"
        >Delete Template Images</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import DELETE_TEMPLATE_IMAGES from '@/graphql/TemplateImages/DeleteTemplateImages.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    rows: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isSubmitting: false
    }
  },
  methods: {
    deleteImage() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: DELETE_TEMPLATE_IMAGES,
          variables: {
            id: this.rows
          }
        })
        .then(() => {
          this.$events.emit('refreshTemplateImages')
          this.$toastr.s('Template Image Deleted', 'Success!')
          this.$emit('close')
          this.isSubmitting = false
        })
        .catch(error => {
          this.isSubmitting = false
          console.log('ERROR', error)
        })
    }
  },

  updated() {}
}
</script>
<style scoped>
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
