<template>
  <widget>
    <widget-header class="background-gradient-green-blue text-white tiny-forms">
      <i class="zmdi zmdi-file-plus pr-2"></i> Delete Alf Listing
      <h4 slot="right">
        <close-x @click="$emit('close')" class="text-white"></close-x>
      </h4>
    </widget-header>
    <widget-body>
      <form @submit.prevent="update" ref="form" class="x-form">
        <YInput
          :readonly="true"
           v-if="alfListing.contry_code"
          v-model="alfListing.country_code"
          label="Country"
        ></YInput>
        <YInput
          :readonly="true"
           v-if="alfListing.device"
          v-model="alfListing.device"
          label="Mobile/Desktop"
        ></YInput>
        <YInput
          :readonly="true"
          v-if="alfListing.subdomain"
          v-model="alfListing.subdomain"
          label="Redirect"
        ></YInput>
         <YInput
          :readonly="true"
          v-if="alfListing.domain"
          v-model="alfListing.domain"
          label="Domain"
        ></YInput>
        <YInput
          :readonly="true"
           v-if="alfListing.usageType"
          v-model="alfListing.usageType"
          label="usage Type"
        ></YInput>
        <YInput :readonly="true"  v-if="alfListing.isp" v-model="alfListing.isp" label="Isp"></YInput>
        <YInput :readonly="true"  v-if="alfListing.ip" v-model="alfListing.ip" label="Ip"></YInput>
      </form>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <y-button
        class="btn btn-red"
        :is-loading="isSubmitting"
        @click="remove"
        @keydown.enter="remove"
        >Delete</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],

  props: {
    alfListing: {
      type: Object,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    remove() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteAlfListing($id: ID!) {
                deleteAlfListing(id: $id) {
                    id
                }
            }
          `,
          variables: {
            id: this.alfListing.id
          }
        })
        .then(result => {
          console.log(result)
          this.isSubmitting = false
          this.clearValidationErrors()
          this.$events.emit('refreshAlfListings', true)
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.x-form > *:not(:last-child) {
  @apply mb-2;
}
</style>
