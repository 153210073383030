var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.visibilityChanged,
      expression: "visibilityChanged"
    }],
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "mb-2 mt-2"
  }, [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Login",
      "readonly": true,
      "disabled": true
    },
    model: {
      value: _vm.localUser.email,
      callback: function ($$v) {
        _vm.$set(_vm.localUser, "email", $$v);
      },
      expression: "localUser.email"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.localUser.primaryContact.name,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "name", $$v);
      },
      expression: "localUser.primaryContact.name"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Password"
    },
    model: {
      value: _vm.localUser.password,
      callback: function ($$v) {
        _vm.$set(_vm.localUser, "password", $$v);
      },
      expression: "localUser.password"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Confirm Password"
    },
    model: {
      value: _vm.confirm,
      callback: function ($$v) {
        _vm.confirm = $$v;
      },
      expression: "confirm"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Contact Email"
    },
    model: {
      value: _vm.localUser.primaryContact.email,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "email", $$v);
      },
      expression: "localUser.primaryContact.email"
    }
  }), _c('div', {
    staticClass: "flex mb-1"
  }, [_c('Superselect', {
    staticClass: "flex-shrink",
    attrs: {
      "title": "App",
      "multiple": false,
      "options": ['telegram', 'skype'],
      "select-class": "border-r-0 rounded-r-none"
    },
    model: {
      value: _vm.messengerApp,
      callback: function ($$v) {
        _vm.messengerApp = $$v;
      },
      expression: "messengerApp"
    }
  }), _c('YInput', {
    staticClass: "flex-grow",
    attrs: {
      "input-class": "rounded-l-none border-l-0",
      "label": "Messenger Handle"
    },
    model: {
      value: _vm.messengerHandle,
      callback: function ($$v) {
        _vm.messengerHandle = $$v;
      },
      expression: "messengerHandle"
    }
  })], 1)], 1), _vm.validationErrors && _vm.isVisible ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e(), _vm.hasChanges && _vm.isVisible ? _c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('YButton', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }, [_vm._v("Update Primary Contact")])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }