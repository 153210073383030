var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 sm:grid-cols-6 gap-1"
  }, [_c('YInput', {
    attrs: {
      "label": "ID"
    },
    model: {
      value: _vm.filters.id.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.id, "value", $$v);
      },
      expression: "filters.id.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Subdomain"
    },
    model: {
      value: _vm.filters.subdomain.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.subdomain, "value", $$v);
      },
      expression: "filters.subdomain.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Domain",
      "track-by": "id",
      "label": "id",
      "modifiers": _vm.filters.domain.modifiers,
      "multiple": true,
      "options": _vm.ydnsDomains || []
    },
    model: {
      value: _vm.filters.domain.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.domain, "value", $$v);
      },
      expression: "filters.domain.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Publisher",
      "modifiers": _vm.filters.user.modifiers,
      "options": _vm.publisherOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "multiple": true,
      "close-on-select": false,
      "query": _vm.PUBLISHER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": "Paste Publishers"
    },
    model: {
      value: _vm.filters.user.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.user, "value", $$v);
      },
      expression: "filters.user.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Campaign",
      "track-by": "id",
      "label": "label",
      "modifiers": _vm.filters.campaign.modifiers,
      "multiple": true,
      "options": [],
      "query": _vm.CAMPAIGN_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.filters.campaign.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.campaign, "value", $$v);
      },
      expression: "filters.campaign.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Manager",
      "modifiers": _vm.filters.accountManager.modifiers,
      "multiple": true,
      "track-by": "id",
      "label": "label",
      "query": _vm.ACCOUNT_MANAGER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: [],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      }
    },
    model: {
      value: _vm.filters.accountManager.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.accountManager, "value", $$v);
      },
      expression: "filters.accountManager.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Status",
      "modifiers": _vm.filters.status.modifiers,
      "multiple": true,
      "options": ['requested', 'approved', 'subscribed', 'unsubscribed', 'fraud']
    },
    model: {
      value: _vm.filters.status.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }