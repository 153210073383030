<template>
  <div
    v-if="user"
    class="text-white text-center leading-10 rounded-full font-extrabold flex items-center justify-center"
    :class="
      `w-${height} h-${height} ${getColorClassFromString(
        user.primaryContact.name
      )}`
    "
  >
    {{ initials }}
  </div>
  <Spinner v-else color="blue" :size="8" />
</template>

<script>
import StringToCssColor from '@/assets/js/StringToCssColor'
import USER_QUERY from '@/graphql/User/UserQuery.gql'

export default {
  apollo: {
    user: {
      query: USER_QUERY,
      variables() {
        return {
          id: this.id
        }
      }
    }
  },
  props: {
    height: {
      type: String
    },
    id: {
      type: [String, Number]
    }
  },
  computed: {
    initials() {
      var nameArray = this.user.primaryContact.name.trim().split(' ')
      var firstNameArray = nameArray[0].split('')
      var lastNameArray = nameArray[nameArray.length - 1].split('')
      return firstNameArray[0] + lastNameArray[0]
    }
  },
  methods: {
    getColorClassFromString(string) {
      let stringToColor = new StringToCssColor()
      return stringToColor.get(string)
    }
  }
}
</script>

<style scoped></style>
