var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Create New Tag ")]), _c('widget-body', [_c('form', {
    ref: "form",
    staticClass: "row",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "col"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "input-group",
    class: _vm.colSize
  }, [_c('YInput', {
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.tag.name,
      callback: function ($$v) {
        _vm.$set(_vm.tag, "name", $$v);
      },
      expression: "tag.name"
    }
  })], 1)]), _c('br'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "input-group",
    class: _vm.colSize
  }, [_c('SuperSelect', {
    attrs: {
      "title": "Type",
      "multiple": false,
      "options": _vm.typeOptions
    },
    model: {
      value: _vm.tag.type,
      callback: function ($$v) {
        _vm.$set(_vm.tag, "type", $$v);
      },
      expression: "tag.type"
    }
  })], 1)]), _c('br')])])]), _c('widget-footer', {
    staticClass: "bg-gray-100 py-2 px-4 text-right"
  }, [_c('YButton', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.create,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_vm._v("Create Tag")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }