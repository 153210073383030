var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "bg-blue-500 text-white border-blue-600"
  }, [_c('WidgetHeader', {
    attrs: {
      "close-class": "text-white"
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('span', {
    staticClass: "text-white"
  }, [_vm._v("What now?")])]), _c('WidgetBody', [_c('p', {
    staticClass: "text-yellow-400 font-semibold mb-2"
  }, [_vm._v(" Campaign #" + _vm._s(_vm.id) + " has been re-activated. ")]), [_c('p', {
    staticClass: "mb-2"
  }, [_vm._v("You may want to:")]), !_vm.emailsSent ? _c('YButton', {
    staticClass: "btn-blue bg-blue-600 mx-auto block my-6",
    attrs: {
      "is-loading": _vm.isSendingEmails,
      "type": "button"
    },
    on: {
      "click": _vm.send
    }
  }, [_vm._v(" Send Campaign Re-activation Emails ")]) : _vm._e(), _vm.emailsSent ? _c('div', {
    staticClass: "text-center"
  }, [_c('Tag', {
    staticClass: "mt-2 mx-auto",
    attrs: {
      "type": "success"
    }
  }, [_c('span', {
    staticClass: "fw-500 flex items-center mx-auto"
  }, [_c('Icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "check"
    }
  }), _vm._v(" Emails Sent")], 1)])], 1) : _vm._e()]], 2), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 px-4 py-2"
  }, [_c('YButton', {
    staticClass: "btn-blue bg-blue-500 hover:bg-blue-600 focus:bg-blue-600",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }