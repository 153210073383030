<template>
  <div>
    <portal to="top-bar-left-header">
      Bulk Payout Updates
      <template v-if="offersLoaded"
        >for
        <router-link
          class="link"
          :to="{
            name: 'Advertiser',
            params: { id: offers.data[0].advertisers[0].id }
          }"
          >{{ offers.data[0].advertisers[0].label }}</router-link
        ></template
      >
    </portal>
    <template v-for="setId of setIds">
      <div class="bg-gray-100 p-1 m-1 rounded shadow-sm" :key="setId">
        <OfferRevenueUpsertRow
          :key="setId"
          :id="setId"
          v-if="offersLoaded"
          :offers-to-edit="offers.data"
          @summaryUpdated="updateSummary"
          @updated="updateSet"
          @deleted="deleteSet"
        />
      </div>
    </template>
    <div class="flex justify-between my-4">
      <div></div>
      <div><YButton color="blue" @click="addSet">Add New Row</YButton></div>
    </div>
    <div class="mb-2">
      <span class="uppercase text-gray-600 font-bold">Update Preview</span>
    </div>
    <YTable
      v-if="summaries"
      :data="Object.values(summaries)"
      class="table-striped table-hover overflow-hidden"
      block-style="min-height: 25rem;"
    >
      <template slot="header-row">
        <th>
          Offers
        </th>
        <th>
          Account
        </th>
        <th>
          Countries
        </th>
        <th>
          Campaigns
        </th>
        <th>
          Redirects
        </th>
        <th>
          Sources
        </th>
        <th>
          Value
        </th>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          <template v-if="props.rowData.offers">
            <div v-for="offer in props.rowData.offers" :key="offer.id">
              <router-link
                class="link"
                :to="{ name: 'Offer Edit', params: { id: offer.id } }"
              >
                {{ offer.label }}
              </router-link>
            </div>
          </template>
        </td>
        <td>
          <template v-if="props.rowData.accounts">
            <div v-for="account in props.rowData.accounts" :key="account.id">
              {{ account.label }}
            </div>
          </template>
        </td>
        <td>
          <template v-if="props.rowData.countries">
            <div v-for="country in props.rowData.countries" :key="country.code">
              {{ country.code }}
            </div>
          </template>
        </td>
        <td>
          <template v-if="props.rowData.campaigns">
            <div v-for="campaign in props.rowData.campaigns" :key="campaign.id">
              <router-link
                class="link"
                :to="{ name: 'Campaign', params: { id: campaign.id } }"
              >
                {{ campaign.label }}
              </router-link>
            </div>
          </template>
        </td>
        <td>
          <template v-if="props.rowData.redirects">
            <div v-for="redirect in props.rowData.redirects" :key="redirect.id">
              <router-link
                class="link"
                :to="{ name: 'Redirect', params: { id: redirect.id } }"
              >
                {{ redirect.label }}
              </router-link>
            </div>
          </template>
        </td>
        <td>
          <template v-if="props.rowData.sources">
            <div v-for="source in props.rowData.sources" :key="source">
              {{ source }}
            </div>
          </template>
        </td>
        <td>
          {{ props.rowData.value }}
        </td>
      </template>
    </YTable>
    <portal to="top-bar-right">
      <div class="flex items-center">
        <YButton
          color="blue"
          @click="save"
          class="mr-1"
          :disabled="saveDisabled || isUpserting"
          :is-loading="isUpserting"
          >Save
        </YButton>
      </div>
    </portal>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import OfferRevenueUpsertRow from '@/views/Admin/Offers/OfferRevenueUpsertRow'
import OFFERS_QUERY from '@/graphql/Offer/OffersQuery.gql'
import UPSERT_REVENUES_MUTATION from '@/graphql/Revenue/UpsertRevenuesMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    selectedOffers: {
      type: String
    }
  },
  components: {
    OfferRevenueUpsertRow
  },
  apollo: {
    offers: {
      query: OFFERS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          first: this.offerIds.length,
          page: 1,
          filters: {
            id: { value: this.offerIds }
          }
        }
      },
      result() {},
      watchLoading(isLoading, countModifier) {
        this.$store.dispatch('updateGlobalLoader', countModifier)
      }
    }
  },
  computed: {
    offerIds() {
      return this.selectedOffers.split(',')
    },
    offersLoaded() {
      return this.offers.data && this.offers.data.length > 0
    }
  },
  data() {
    return {
      offers: {},
      setIds: [this.$uuid.v4()],
      sets: {},
      saveDisabled: false,
      isUpserting: false,
      summaries: {},
      summaryRows: []
    }
  },
  methods: {
    addSet() {
      this.setIds.push(this.$uuid.v4())
    },
    deleteSet(id) {
      this.setIds = this.setIds.filter(setId => setId != id)
      delete this.sets[id]
      delete this.summaries[id]
    },
    reset() {
      this.setIds = []
      this.sets = {}
      this.summaries = {}
      this.addSet()
    },
    save() {
      this.upsertRevenues()
    },
    upsertRevenues() {
      this.isUpserting = true
      let input = []
      Object.values(this.sets).forEach(function(values) {
        input = [...input, ...values]
      })

      this.$apollo
        .mutate({
          mutation: UPSERT_REVENUES_MUTATION,
          variables: {
            input: input
          }
        })
        .then(({ data }) => {
          let updateCount = data.upsertRevenues.length
          this.clearValidationErrors()
          this.reset()
          this.isUpserting = false
          this.$toastr.s('', updateCount + ' Offer Payouts Updated')
          this.$events.emit('revenueUpserted')
        })
        .catch(error => {
          this.isUpserting = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    updateSet(set) {
      this.sets[set.id] = set.data
    },
    updateSummary(set) {
      this.summaries = Object.assign({}, this.summaries, { [set.id]: set.data })
    }
  }
}
</script>

<style></style>
