<template>
  <Widget class="bg-red-600 text-white border-red-700">
    <WidgetHeader class="bg-red-600 text-white"
      ><span class="text-white"
        >Are you sure you want to delete {{ selectedRowIds.length }} rule<span
          v-if="selectedRowIds.length > 1"
          >s</span
        >?</span
      ></WidgetHeader
    >
    <WidgetBody>
      <h5 class="text-yellow-400 font-bold">
        This action cannot be undone.
      </h5>
    </WidgetBody>
    <WidgetFooter
      class="bg-dark-transparent-1 flex justify-end gap-6 py-2 px-4"
    >
      <YButton
        class="border hover:bg-red-500 focus:bg-red-700"
        @click="$emit('close')"
        >Cancel</YButton
      >
      <YButton
        class="bg-red-700 hover:bg-red-800 border focus:bg-red-700"
        @click="deleteRules"
        >Delete</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import DELETE_RULES_MUTATION from '@/graphql/Rule/DeleteRulesMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    selectedRowIds: {
      type: Array,
      required: true
    }
  },
  apollo: {},
  mounted() {},
  updated() {},
  components: {},
  data() {
    return {
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    deleteRules() {
      this.isSubmitting = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: DELETE_RULES_MUTATION,
          variables: {
            input: { ids: this.selectedRowIds }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$store.commit('rule/RESET_SELECTED_ROW_IDS')
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Rules Deleted', 'Success!')
          this.$events.emit('ruleDeleted')
          this.$tabEvent.emit('ruleDeleted')
          this.$tabEvent.emit('refreshAlgorithmsData')
          this.close()
        })
        .catch(error => {
          this.$store.commit('rule/RESET_SELECTED_ROW_IDS')
          this.close()
          this.isSubmitting = false
          this.$store.dispatch('updateGlobalLoader', -1)
          console.error(error)
        })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped></style>
