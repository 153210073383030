<template>
  <div>
    <portal to="top-bar-right">
      <div class="flex">
        <YButton class="btn btn-blue mr-2" @click="openCreateDnsModal">
          New Nameserver Relation
        </YButton>

        <YButton class="btn btn-blue" @click="openCreateDomainModal">
          New Domain
        </YButton>
      </div>
    </portal>
    <Widget class="mb-1 overflow-visible">
      <WidgetHeader class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <div class="w-full grid grid-cols-3 gap-1">
            <Superselect
              title="Domain"
              v-model="dnsFilters.id.value"
              :modifiers="dnsFilters.id.modifiers"
              :settings="dnsFilters.id.settings"
              :options="domainOptions || []"
              placeholder="Select"
              track-by="id"
              label="id"
              :close-on-select="false"
              :query="DOMAIN_OPTIONS_QUERY"
              :query-variables="{
                first: 20,
                filters: {
                  id: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  },
                  type: {
                    value: ['nameserver']
                  }
                }
              }"
              edit-placeholder="Paste Domains"
            ></Superselect>

            <Superselect
              title="Provider"
              v-model="dnsFilters.nameServerProvider.value"
              :modifiers="dnsFilters.nameServerProvider.modifiers"
              :settings="dnsFilters.nameServerProvider.settings"
              :options="providerOptions || []"
              :loading="$apollo.queries.providerOptions.loading"
              placeholder="Select"
              track-by="name"
              label="label"
            ></Superselect>

            <Superselect
              title="Ip"
              v-model="dnsFilters.nameServerIp.value"
              :modifiers="dnsFilters.nameServerIp.modifiers"
              :settings="dnsFilters.nameServerIp.settings"
              placeholder="Select"
              :forceEdit="true"
              editPlaceholder="Type IPs"
              track-by="id"
              label="id"
            ></Superselect>
          </div>
        </template>
      </WidgetHeader>
    </Widget>
    <YTable
      v-if="domains"
      :data="domains || []"
      :bottom-loading="$apolloData.queries.domains.loading"
    >
      <template slot="header-row">
        <th>Domain</th>
        <th>Registrar</th>
        <th>Assigned Domains</th>
        <th>Servers</th>
        <th></th>
      </template>
      <template slot="row" slot-scope="props">
        <td>{{ props.rowData.id }}</td>
        <td>
          <div class="flex flex-col">
            <div>
              {{ props.rowData.registrar }}
            </div>
            <div
              v-if="props.rowData.registrarAccount"
              class="text-xs italic -mt-1"
            >
              {{ props.rowData.registrarAccount }}
            </div>
          </div>
        </td>
        <td v-if="props.rowData.nameServers[0]">
          <li
            v-for="dnsLinkedDomain in props.rowData.nameServers[0]
              .dnsLinkedDomains"
            :key="dnsLinkedDomain.id"
          >
            {{ dnsLinkedDomain.id }}
          </li>
        </td>
        <td v-else>
          No Assigned Domains
        </td>
        <td>
          <YTable
            v-if="props.rowData.nameServers"
            class="w-100 w-min-100 table-hover size-xs"
            :scroll-x="false"
            header-class="text-xs"
            :data="props.rowData.nameServers"
          >
            <template slot="header-row">
              <th>Prefix</th>
              <th>Host Name</th>
              <th>Ip</th>
              <th></th>
            </template>
            <template slot="row" slot-scope="serverProps">
              <td>
                {{ serverProps.rowData.domainPrefix }}
              </td>
              <td>
                {{ serverProps.rowData.server.hostname }}
              </td>
              <td>
                {{ serverProps.rowData.server.serverIp }}
              </td>
              <td>
                <div class="text-right">
                  <div class="inline-flex items-center">
                    <a
                      href="#"
                      @click.prevent="
                        openEditModal(
                          serverProps.rowData.id,
                          props.rowData.id,
                          serverProps.rowData.domainPrefix,
                          serverProps.rowData.server
                        )
                      "
                      class="link x-action-icon"
                    >
                      <Icon name="pencil" class="w-4 h-4" />
                    </a>
                    <a
                      href="#"
                      @click.prevent="
                        openDeleteModal(
                          serverProps.rowData.id,
                          serverProps.rowData.server.hostname,
                          serverProps.rowData.server.serverIp,
                          serverProps.rowData.domainPrefix
                        )
                      "
                      class="text-red-600 hover:text-red-700 x-action-icon"
                    >
                      <Icon name="trashCan" class="w-4 h-4" />
                    </a>
                  </div>
                </div>
              </td>
              <td></td>
            </template>
          </YTable>
        </td>
        <td>
          <div class="inline-flex float-right">
            <a
              href="#"
              class="link"
              @click.prevent="openDomainEditModal(props.rowData)"
            >
              <Icon name="pencil" class="w-4 h-4" />
            </a>
            <a href="#" class="ml-2 text-red-500 hover:text-red-600">
              <Icon name="trashCan" class="w-4 h-4" />
            </a>
          </div>
        </td>
      </template>
    </YTable>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import DNS_DOMAINS from '@/graphql/Domain/DnsDomainsQuery.gql'
import DnsDelete from '@/views/Admin/DomainManager/DnsDelete.vue'
import DnsEdit from '@/views/Admin/DomainManager/DnsEdit.vue'
import DnsCreate from '@/views/Admin/DomainManager/DnsCreate.vue'
import DomainCreate from '@/components/legacy/Domainmanager/DomainsCreate.vue'
import DomainRegistrarEdit from '@/views/Admin/DomainManager/DomainRegistrarEdit.vue'
import DOMAIN_OPTIONS_QUERY from '@/graphql/Domain/DomainOptionsQuery.gql'
import PROVIDERS_QUERY from '@/graphql/DomainProvider/ProvidersQuery.gql'

export default {
  components: {},
  apollo: {
    domains: {
      query: DNS_DOMAINS,
      variables() {
        return {
          first: this.perPage,
          page: this.page,
          filters: this.domainFilters,
          recordFilters: null,
          orderBy: this.$store.getters['ydns/domainsTableSortOrder'],
          trashed: null
        }
      },
      deep: true,
      result(result) {
        this.domains = JSON.parse(JSON.stringify(result.data.domains.data))
        this.domainsPaginator = JSON.parse(
          JSON.stringify(result.data.domains.paginatorInfo)
        )
      }
    },
    providerOptions: {
      query: PROVIDERS_QUERY,
      update: data => data.providers
    }
  },

  data() {
    return {
      DNS_DOMAINS,
      DOMAIN_OPTIONS_QUERY,
      expandFilters: false,
      domains: [],
      domainsPaginator: {},
      filterDisplayMode: 'simple',
      fields: [],
      page: 1,
      perPage: 500,
      perPageOptions: [10, 25, 50, 100, 500],
      sortOrder: [],
      multiSort: false,
      isLoading: false,
      domainOptions: [],
      registrarOptions: [],
      typeOptions: [],
      providerOptions: [],
      serverOptions: [],
      tagOptions: [],
      dnsFilters: this.$store.getters['ydns/domainFilters']
    }
  },
  computed: {
    domainFilters() {
      let dnsFilters = JSON.parse(JSON.stringify(this.dnsFilters))
      delete dnsFilters.status
      delete dnsFilters.nameserverId
      delete dnsFilters.publisher
      dnsFilters.type.value.pop()
      dnsFilters.type.value.push({ id: 'nameserver', label: 'Nameserver' })
      Object.keys(dnsFilters).map(function(name) {
        if (name == 'publisher') {
          if (dnsFilters['publisher'] && dnsFilters['publisher'].id) {
            dnsFilters['publisher'] = dnsFilters['publisher'].id
          } else {
            delete dnsFilters[name]
          }
        } else {
          let filterValues = dnsFilters[name].value.map(function(value) {
            return value.id
          })
          if (
            filterValues.length > 0 &&
            dnsFilters[name].settings.filtersDomains
          ) {
            dnsFilters[name] = {
              value: filterValues,
              modifiers: dnsFilters[name].modifiers
            }
          } else {
            delete dnsFilters[name]
          }
        }
      })
      return dnsFilters
    }
  },
  mounted() {
    this.$events.listen('refreshDnsDomains', () => {
      this.$apollo.queries.domains.refetch()
    })
  },
  beforeDestroy() {},
  updated() {},
  methods: {
    ...mapActions(['updateGlobalLoader']),
    loadMore() {
      this.perPage = this.perPage + this.loadMoreIncrement
    },
    openCreateDnsModal() {
      this.$modal.show(
        DnsCreate,
        {},
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openCreateDomainModal() {
      this.$modal.show(
        DomainCreate,
        {
          type: 'nameserver',
          status: 'active'
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openEditModal(id, domainId, prefix, server) {
      this.$modal.show(
        DnsEdit,
        {
          id: parseInt(id),
          domainId: domainId,
          prefix: prefix,
          server: server
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDeleteModal(id, name, prefix, ip) {
      this.$modal.show(
        DnsDelete,
        { id: parseInt(id), hostname: name, prefix: prefix, ip: ip },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDomainEditModal(domain) {
      this.$modal.show(
        DomainRegistrarEdit,
        { domain: domain },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>
