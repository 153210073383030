<template>
  <div class="mt-3">
    <div class="flex items-center justify-between">
      <YButton
        @click="showIps = !showIps"
        class="text-blue-600 hover:bg-blue-100 rounded text-left block mr-2 truncate flex-grow"
      >
        <span class="flex items-center">
          <Icon v-if="showIps" name="menuDown" :size="4" />
          <Icon v-else name="menuRight" :size="4" />
          IPs
        </span>
      </YButton>

      <div>
        <YButton
          class="text-blue-600 hover:bg-blue-100"
          @click="popCreateIpsModal"
        >
          <span class="text-nowrap">Add IPs</span>
        </YButton>
      </div>
    </div>
    <div
      v-if="showIps"
      class="ts-1 mt-2 ip-list w-100"
      style="overflow-x:scroll;"
    >
      <YTable
        :data="ips"
        :selectable="true"
        selectBy="id"
        :selected.sync="selectedIps"
        class="w-100"
      >
        <template slot="colgroup">
          <col />
          <col />
        </template>
        <template slot="header-row">
          <th></th>
          <th></th>
          <th
            style="text-align:right; padding: 0 !important; width: 0.1%; white-space: nowrap;"
          >
            <ip-actions
              ref="ipActions"
              :ips="selectedIpObjects"
              @delete="removeIps"
            ></ip-actions>
          </th>
        </template>
        <template slot="row" slot-scope="props">
          <td :class="[props.rowData.enabled ? '' : 'disabled-column']">
            <div class="flex">
              <Icon
                name="circleMedium"
                :size="5"
                class="inline"
                :class="
                  ipIsUp(props.rowData.checks)
                    ? 'text-green-500'
                    : 'text-red-500'
                "
              />
              {{ props.rowData.id }}
              <img
                v-if="
                  props.rowData.spamhausCheck &&
                    props.rowData.spamhausCheck.lastRunOutput
                "
                v-tooltip="
                  `<div class='text-center'>Listed <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`
                "
                src="@/assets/images/logo/spamhaus-logo-red.png"
                class="h-3 ml-1"
              />
              <img
                v-else-if="props.rowData.spamhausCheck"
                v-tooltip="
                  `<div class='text-center'>Unlisted <br>Last Run Date: ${props.rowData.spamhausCheck.lastRunAt}<div>`
                "
                src="@/assets/images/logo/spamhaus-logo.png"
                class="h-3 ml-1"
                style="margin-top: 3px;"
              />
              <span
                v-if="
                  props.rowData.spamhausCheck &&
                    props.rowData.spamhausCheck.enabled
                "
              >
                *
              </span>
            </div>
          </td>
          <td
            style="text-align:right"
            :class="[props.rowData.enabled ? '' : 'disabled-column']"
          >
            <Tag
              v-if="props.rowData.records && props.rowData.records.length > 0"
              :color="props.rowData.enabled ? 'blue' : 'red'"
              :style="props.rowData.enabled ? '' : 'opacity: 0.4'"
              class="mr-2"
              >{{ `${props.rowData.records.length} Records` }}</Tag
            >
            <div v-if="assignedPublishers(props.rowData.id).length > 0">
              <Tag
                v-for="publisher in assignedPublishers(props.rowData.id)"
                :key="publisher.id"
                color="blue"
                class="mr-2"
              >
                {{ publisher.email }}
              </Tag>
            </div>
          </td>
          <td class="bg-gray-200" style="padding: 0;">
            <v-popover
              offset="10"
              placement="left-start"
              :autoHide="true"
              popoverClass
              :class="['sidebar-user-popover']"
              popoverBaseClass="popover-menu sidebar-popover-menu"
              popoverInnerClass="popover-menu-inner animated animated-speed1 fadeIn"
            >
              <YButton class="btn-transparent btn-rounded tooltip-target">
                <Icon name="dotsVertical" :size="4" />
              </YButton>

              <!-- This will be the content of the popover -->
              <template slot="popover">
                <div class="list-group">
                  <SpamhausCheckPopover
                    v-if="props.rowData.enabled"
                    model="Ip"
                    :rowData="props.rowData"
                  />
                  <YButton
                    v-if="props.rowData.enabled"
                    class="list-group-item list-group-item-action"
                    @click="
                      openEditPublishersModal(
                        props.rowData,
                        assignedPublishers(props.rowData.id)
                      )
                    "
                  >
                    <span class="flex items-center">
                      <Icon
                        name="accountMultipleOutline"
                        :size="4"
                        class="mr-2"
                      />
                      Edit Publishers
                    </span>
                  </YButton>
                  <YButton
                    v-if="props.rowData.enabled"
                    class="list-group-item list-group-item-action"
                    @click="$refs.ipActions.openReplaceModal(props.rowData.id)"
                  >
                    <span class="flex items-center">
                      <Icon name="sync" :size="4" class="mr-2" />
                      Replace
                    </span>
                  </YButton>
                  <YButton
                    v-if="props.rowData.enabled"
                    class="list-group-item list-group-item-action text-red-600 hover:bg-red-100"
                    @click="$refs.ipActions.disable([props.rowData])"
                  >
                    <span class="flex items-center">
                      <Icon name="cancel" :size="4" class="mr-2" />
                      Disable
                    </span>
                  </YButton>
                  <YButton
                    v-else
                    class="list-group-item list-group-item-action"
                    @click="$refs.ipActions.enable([props.rowData])"
                  >
                    <span class="flex items-center">
                      <Icon name="power" :size="4" class="mr-2" />
                      Enable
                    </span>
                  </YButton>
                </div>
              </template>
            </v-popover>
          </td>
        </template>
      </YTable>
    </div>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import IpsCreate from './IpsCreate'
import IpActions from './IpActions'
import USER_IP_QUERY from '@/graphql/Ip/UserIpsQuery.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import ProvidersPublishersEdit from '@/views/Admin/DomainManager/ProvidersPublishersEdit.vue'

export default {
  mixins: [ValidationErrorsMixin],
  components: {
    IpActions
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    server: {
      type: Object
    },
    showIpsByDefault: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    userIps: {
      query: USER_IP_QUERY,
      variables() {
        return {
          filters: {
            ip: {
              value: this.value.map(ip => ip.id)
            }
          }
        }
      }
    }
  },
  data() {
    return {
      isLoading: false,
      showIps: this.showIpsByDefault,
      selectedIps: []
    }
  },
  computed: {
    ips() {
      return this.value
    },
    selectedIpObjects() {
      if (this.selectedIps && this.selectedIps.length > 0) {
        return this.ips
          .filter(ip => this.selectedIps.includes(ip.id))
          .map(ip => ip)
      } else {
        return []
      }
    }
  },
  watch: {
    showIpsByDefault: function() {
      this.showIps = this.showIpsByDefault
    }
  },
  mounted() {
    this.$events.listen('refreshUserIps', () => {
      this.$apollo.queries.userIps.refetch()
    })
  },
  updated() {},
  methods: {
    assignedPublishers(ip) {
      return this.userIps
        .filter(userIp => userIp.ip_id == ip)
        .map(userIp => userIp.user)
    },
    ipIsUp(checks) {
      var numberOfSuccessfulChecks = checks.filter(
        check => check['status'] === 'success'
      ).length
      return checks.length === numberOfSuccessfulChecks
    },
    popCreateIpsModal() {
      this.$modal.show(
        IpsCreate,
        {
          server: this.server
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 1000,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    removeIps() {
      this.$events.emit('refreshYdnsServers')
    },
    openEditPublishersModal(ip, assignedPublishers) {
      this.$modal.show(
        ProvidersPublishersEdit,
        { ip: ip, assignedPublishers: assignedPublishers },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  }
}
</script>

<style scoped>
.disabled-column {
  opacity: 0.4;
}
.ip-row:hover {
  background-color: #bce0fb;
}
</style>
