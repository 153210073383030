<template>
  <div>
    <portal to="top-bar-right">
      <div class="flex justify-right">
        <YButton
          class="btn btn-blue "
          @click="createNewCampaign"
          :is-loading="isCreating"
        >
          New Campaign
        </YButton>
      </div>
    </portal>
    <CampaignsTableWrapper></CampaignsTableWrapper>
  </div>
</template>

<script>
import CampaignsTableWrapper from '@/components/legacy/Campaigns/AdminCampaignsTableWrapper'
import CREATE_CAMPAIGN_MUTATION from '@/graphql/Campaign/CreateCampaignMutation.gql'

export default {
  components: {
    CampaignsTableWrapper
  },
  data() {
    return {
      isCreating: false
    }
  },

  mounted() {},

  updated() {},

  methods: {
    createNewCampaign() {
      this.isCreating = true
      this.$apollo
        .mutate({
          mutation: CREATE_CAMPAIGN_MUTATION,
          variables: {
            input: {}
          }
        })
        .then(result => {
          this.$toastr.s('Campaign Created', 'Success!')
          this.isCreating = false
          this.$router.push({
            name: 'Campaign',
            params: { id: result.data.createCampaign.id }
          })
          this.$emit('success', result)
          this.$emit('close')
        })
        .catch(error => {
          console.log('error')

          this.$toastr.e(error, 'Oops!')
          this.isCreating = false
        })
    }
  }
}
</script>
<style></style>
