export default {
  methods: {
    conversionTypeDescription(string) {
      switch (string) {
        case 'Default':
          return 'Default postbacks fire <span class="font-bold">payable</span> conversions when no conversion type postback is set or available.'
        default:
          return (
            string +
            ' postbacks fire <span class="font-bold">all </span>' +
            string +
            ' conversions regardless of payout.'
          )
      }
    }
  }
}
