import {
  assign,
  assignIn,
  capitalize,
  chain,
  chunkObj,
  clone,
  cloneDeep,
  debounce,
  each,
  forEach,
  filter,
  fromPairs,
  head,
  indexOf,
  identity,
  isEqual,
  includes,
  isNil,
  kebabCase,
  last,
  map,
  mapValues,
  merge,
  mixin,
  omitBy,
  partition,
  pickBy,
  startCase,
  sortBy,
  sum
} from 'lodash'

export default {
  assign,
  assignIn,
  capitalize,
  chain,
  chunkObj,
  clone,
  cloneDeep,
  debounce,
  each,
  forEach,
  fromPairs,
  filter,
  head,
  indexOf,
  identity,
  isEqual,
  includes,
  isNil,
  kebabCase,
  last,
  map,
  mapValues,
  mixin,
  merge,
  omitBy,
  partition,
  pickBy,
  startCase,
  sortBy,
  sum
}
