var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('img', {
    class: [`w-${_vm.size}`],
    attrs: {
      "src": _vm.getFlagUrl()
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }