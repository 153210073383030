var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order-by-item cursor-pointer",
    class: ['text-blue-600 hover:text-blue-700'],
    on: {
      "click": [function ($event) {
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        return _vm.click.apply(null, arguments);
      }, function ($event) {
        if (!$event.metaKey) return null;
        return _vm.ctrlClick.apply(null, arguments);
      }, function ($event) {
        if (!$event.ctrlKey) return null;
        return _vm.ctrlClick.apply(null, arguments);
      }]
    }
  }, [_c('span', [_vm._t("default")], 2), _c('Icon', {
    staticClass: "w-3 h-3 inline-block ml-2",
    class: [!_vm.itemExists ? 'text-gray-400' : ''],
    attrs: {
      "name": _vm.itemOrder == 'ASC' ? 'arrowUp' : 'arrowDown'
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }