<template>
  <div class="w-full">
    <div class="w-full">
      <span class="uppercase font-bold text-gray-600 text-xs"
        >Filter By Traffic Conditions</span
      >
      <div class="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-6 gap-1">
        <Superselect
          v-show="!hide.includes('types')"
          title="Types"
          v-model="filters.types.value"
          :modifiers="{}"
          :options="['publisher', 'campaign', 'redirect', 'source']"
          :multiple="true"
        ></Superselect>
        <Superselect
          v-show="!hide.includes('campaigns')"
          title="Campaigns"
          v-model="filters.campaigns.value"
          track-by="id"
          label="label"
          :modifiers="{}"
          :multiple="true"
          :options="[]"
          :query="CAMPAIGN_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
        <Superselect
          v-show="!hide.includes('redirects')"
          title="Redirects"
          v-model="filters.redirects.value"
          track-by="id"
          label="label"
          :modifiers="{}"
          :multiple="true"
          :options="[]"
          :query="REDIRECT_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
        <Superselect
          v-show="!hide.includes('publishers')"
          title="Publishers"
          v-model="filters.publishers.value"
          track-by="id"
          label="label"
          :modifiers="{}"
          :multiple="true"
          :options="[]"
          :query="PUBLISHER_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        >
          <template v-slot:beforeControlIcons>
            <ToggleLink
              v-tooltip="`Include publishers' redirect rules`"
              v-model="filters.publishers.modifiers.includeRedirects"
              class="control-icon-btn p-1 cursor-pointer"
            >
              <Icon
                :class="[
                  'h-4 w-4',
                  filters.publishers.modifiers.includeRedirects
                    ? 'text-blue-600 hover:text-blue-700'
                    : 'text-gray-400 hover:text-gray-500'
                ]"
                name="link"
              />
            </ToggleLink>
          </template>
        </Superselect>
        <YInput
          v-show="!hide.includes('sources')"
          v-model="filters.sources.value"
          label="Source"
        />
        <Superselect
          v-show="!hide.includes('countries')"
          title="Countries"
          v-model="filters.countries.value"
          :modifiers="filters.countries.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['countryCode'] }
            },
            first: 100
          }"
        ></Superselect>
        <Superselect
          v-show="!hide.includes('mobileDesktop')"
          title="Mobile / Desktop"
          v-model="filters.mobileDesktop.value"
          :modifiers="filters.mobileDesktop.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['mobileDesktop'] }
            },
            first: 100
          }"
        ></Superselect>
        <!-- <YInput v-model="filters.trafficConditions.value" label="Traffic Conditions" tooltip="Accepts: Tag Names"/>
        <YInput v-model="filters.offerFilters.value" label="Offer Filters" tooltip="Accepts: Offer ID, Advertiser ID, Tag Names"/> -->
      </div>
    </div>
    <div class="w-full mt-2" v-show="!hideAllOfferFilters">
      <span class="uppercase font-bold text-gray-600 text-xs"
        >Filter By Offer Filters</span
      >
      <div class="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-7 gap-1">
        <Superselect
          v-show="!hide.includes('filterTypes')"
          title="Filter Types"
          v-model="filters.filterTypes.value"
          :modifiers="{}"
          track-by="key"
          label="label"
          :options="filterTypeOptions"
          :multiple="true"
        ></Superselect>
        <Superselect
          v-show="!hide.includes('offers')"
          title="Offers"
          v-model="filters.offers.value"
          track-by="id"
          label="label"
          :modifiers="{}"
          :multiple="true"
          :options="[]"
          :query="OFFER_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
        <Superselect
          v-show="!hide.includes('advertisers')"
          title="Advertisers"
          v-model="filters.advertisers.value"
          track-by="id"
          label="label"
          :modifiers="{}"
          :multiple="true"
          :options="[]"
          :query="ADVERTISER_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              advertiserLabel: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
        <Superselect
          v-show="!hide.includes('offers')"
          title="Advertiser Accounts"
          v-model="filters.advertiserAccounts.value"
          track-by="id"
          label="label"
          :modifiers="{}"
          :multiple="true"
          :query="ADVERTISER_ACCOUNT_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
        <Superselect
          title="Verticals"
          v-model="filters.verticals.value"
          :modifiers="filters.verticals.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['vertical'] }
            },
            first: 100
          }"
        ></Superselect>
        <Superselect
          title="Templates"
          v-model="filters.templates.value"
          :modifiers="{}"
          :multiple="true"
          track-by="id"
          label="label"
          :close-on-select="false"
          :query="TEMPLATES_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              paths: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
      </div>
    </div>
  </div>
</template>

<script>
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import TEMPLATES_QUERY from '@/graphql/Template/TemplatesQuery.gql'
import ADVERTISER_OPTIONS_QUERY from '@/graphql/Advertiser/AdvertiserOptionsQuery.gql'
import ADVERTISER_ACCOUNT_OPTIONS_QUERY from '@/graphql/AdvertiserAccount/AdvertiserAccountOptionsQuery.gql'
import RULES_QUERY from '@/graphql/Rule/RulesQuery.gql'
import ToggleLink from '@/components/legacy/ToggleLink'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'

export default {
  props: {
    currentPage: {
      default: 1
    },
    perPage: {
      default: 20
    },
    orderBy: {
      default: () => {
        return []
      }
    },
    defaultValues: {
      type: Object,
      default: () => ({})
    },
    hide: {
      type: Array,
      default: () => []
    },
    hideAllOfferFilters: {
      type: Boolean,
      default: false
    },
    ruleablePolarity: {},
    filterablePolarity: {}
  },
  components: {
    ToggleLink
  },
  apollo: {
    rules: {
      query: RULES_QUERY,
      debounce: 250,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          first: this.perPage,
          page: this.internalCurrentPage,
          orderBy: this.orderBy,
          filters: {
            ruleableTypes: this.filters.types.value ?? undefined,
            campaigns:
              this.filters.campaigns.value &&
              this.filters.campaigns.value.length > 0
                ? {
                    value: this.filters.campaigns.value.map(
                      campaign => campaign.id
                    )
                  }
                : undefined,
            redirects:
              this.filters.redirects.value &&
              this.filters.redirects.value.length > 0
                ? {
                    value: this.filters.redirects.value.map(
                      redirect => redirect.id
                    )
                  }
                : undefined,
            publishers:
              !this.filters.publishers.modifiers.includeRedirects &&
              this.filters.publishers.value &&
              this.filters.publishers.value.length > 0
                ? {
                    value: this.filters.publishers.value.map(
                      publisher => publisher.id
                    )
                  }
                : undefined,
            publishersWithRedirects:
              this.filters.publishers.modifiers.includeRedirects &&
              this.filters.publishers.value &&
              this.filters.publishers.value.length > 0
                ? this.filters.publishers.value.map(publisher => publisher.id)
                : undefined,
            sources: this.filters.sources.value
              ? {
                  value: [this.filters.sources.value]
                }
              : undefined,
            countryCodes:
              this.filters.countries.value &&
              this.filters.countries.value.length > 0
                ? {
                    value: this.filters.countries.value.map(
                      countryTag => countryTag.id
                    ),
                    modifiers: {
                      exclude: false
                    }
                  }
                : undefined,
            mobileDesktop:
              this.filters.mobileDesktop.value &&
              this.filters.mobileDesktop.value.length > 0
                ? {
                    value: this.filters.mobileDesktop.value.map(
                      mobileDesktopTag => mobileDesktopTag.id
                    )
                  }
                : undefined,
            filterableTypes: this.filters?.filterTypes?.value?.map(
              type => type.key
            ),
            offers:
              this.filters.offers.value && this.filters.offers.value.length > 0
                ? {
                    value: this.filters.offers.value.map(offer => offer.id)
                  }
                : undefined,
            advertisers:
              this.filters.advertisers.value &&
              this.filters.advertisers.value.length > 0
                ? {
                    value: this.filters.advertisers.value.map(
                      advertiser => advertiser.id
                    )
                  }
                : undefined,
            advertiserAccounts:
              this.filters.advertiserAccounts.value &&
              this.filters.advertiserAccounts.value.length > 0
                ? {
                    value: this.filters.advertiserAccounts.value.map(
                      advertiserAccount => advertiserAccount.id
                    )
                  }
                : undefined,
            templates:
              this.filters.templates.value &&
              this.filters.templates.value.length > 0
                ? {
                    value: this.filters.templates.value.map(
                      template => template.id
                    )
                  }
                : undefined,
            filterableTags:
              this.filters.verticals.value &&
              this.filters.verticals.value.length > 0
                ? {
                    value: this.filters.verticals.value.map(
                      vertical => vertical.id
                    )
                  }
                : undefined,
            filterablePolarity: this.filters.filterablePolarity,
            ruleablePolarity: this.filters.ruleablePolarity
          }
        }
      },
      result({ data }, key) {
        if (data) {
          this.$emit('rulesFetched', data[key])
        }
      },
      watchLoading(isLoading, countModifier) {
        this.$emit('loading', isLoading)
        this.$store.dispatch('updateGlobalLoader', countModifier)
      },
      deep: true
    }
  },
  data() {
    return {
      CAMPAIGN_OPTIONS_QUERY,
      REDIRECT_OPTIONS_QUERY,
      PUBLISHER_OPTIONS_QUERY,
      OFFER_OPTIONS_QUERY,
      ADVERTISER_OPTIONS_QUERY,
      ADVERTISER_ACCOUNT_OPTIONS_QUERY,
      TEMPLATES_QUERY,
      TAGS_QUERY,
      internalCurrentPage: this.currentPage,
      filterTypeOptions: [
        {
          key: 'offer',
          label: 'Offer'
        },
        {
          key: 'advertiser',
          label: 'Advertiser'
        },
        {
          key: 'advertiserAccount',
          label: 'Advertiser Account'
        },
        {
          key: 'vertical',
          label: 'Vertical'
        },
        {
          key: 'template',
          label: 'Template'
        }
      ],
      filters: {
        types: {
          value: this.defaultValues.types ?? null,
          modifiers: {},
          title: 'Types',
          type: 'select'
        },
        publishers: {
          value: this.defaultValues.publishers ?? null,
          modifiers: {
            includeRedirects: true
          },
          title: 'Publishers',
          type: 'select'
        },
        campaigns: {
          value: this.defaultValues.campaigns ?? null,
          modifiers: {},
          title: 'Campaigns',
          type: 'select'
        },
        redirects: {
          value: this.defaultValues.redirects ?? null,
          modifiers: {},
          title: 'Redirects',
          type: 'select'
        },
        sources: {
          value: this.defaultValues.sources ?? null,
          modifiers: {},
          title: 'Sources',
          type: 'input'
        },
        countries: {
          value: this.defaultValues.countries ?? null,
          modifiers: {},
          title: 'Countries',
          type: 'tagSelect'
        },
        mobileDesktop: {
          value: this.defaultValues.mobileDesktop ?? null,
          modifiers: {},
          title: 'Mobile / Desktop',
          type: 'tagSelect'
        },
        filterTypes: {
          value: this.defaultValues.filterTypes ?? null,
          title: 'Filter Types',
          type: 'select'
        },
        offers: {
          value: this.defaultValues.offers ?? null,
          modifiers: {},
          title: 'Offers',
          type: 'select'
        },
        advertisers: {
          value: this.defaultValues.advertisers ?? null,
          modifiers: {},
          title: 'Advertisers',
          type: 'select'
        },
        advertiserAccounts: {
          value: this.defaultValues.advertiserAccounts ?? null,
          modifiers: {},
          title: 'Advertiser Accounts',
          type: 'select'
        },
        verticals: {
          value: this.defaultValues.verticals ?? null,
          modifiers: {},
          title: 'Verticals',
          type: 'select'
        },
        templates: {
          value: this.defaultValues.templates ?? null,
          modifiers: {},
          title: 'Templates',
          type: 'select'
        },
        filterablePolarity: this.filterablePolarity,
        ruleablePolarity: this.ruleablePolarity
      }
    }
  },
  watch: {
    currentPage(value) {
      this.internalCurrentPage = value
    },
    filters: {
      deep: true,
      handler() {
        this.internalCurrentPage = 1
      }
    }
  },
  methods: {},
  computed: {},
  mounted() {
    this.$tabEvent.on('rulesCreated', () => {
      this.$apollo.queries.rules.refetch()
    })
    this.$events.listen('rulesCreated', () => {
      this.$apollo.queries.rules.refetch()
    })
    this.$tabEvent.on('ruleDeleted', () => {
      this.$apollo.queries.rules.refetch()
    })
    this.$events.listen('ruleDeleted', () => {
      this.$apollo.queries.rules.refetch()
    })
  },
  destroy() {
    this.$tabEvent.off('rulesCreated')
  }
}
</script>

<style></style>
