var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-center"
  }, [_c('div', {
    staticClass: "grid grid-cols-12 gap-3 flex-grow max-w-screen-2xl"
  }, [_c('div', {
    staticClass: "col-span-12 lg:col-span-8 row-span-1 rounded-md grid grid-cols-12 gap-5"
  }, [_c('div', {
    staticClass: "col-span-12 lg:col-span-9 shadow-md"
  }, [_c('div', {
    staticClass: "header-container header-border"
  }, [_vm._v("Daily Summary")]), _c('simple-report', {
    attrs: {
      "filters": _vm.reportFilters
    }
  })], 1), _c('div', {
    staticClass: "hidden lg:grid lg:col-span-3 flex flex-col gap-4"
  }, [_c('div', {
    staticClass: "data-block",
    staticStyle: {
      "background": "#ef4565",
      "color": "#fffffe"
    }
  }, [_c('h2', [_vm._v("net revenue")]), _c('h3', [_vm._v("$" + _vm._s(_vm.totalRevenue))])]), _c('div', {
    staticClass: "data-block",
    staticStyle: {
      "background": "#ef4565",
      "color": "#fffffe"
    }
  }, [_c('h2', [_vm._v("impressions")]), _c('h3', [_vm._v(_vm._s(_vm.totalImpressions))])]), _c('div', {}, [_c('router-link', {
    attrs: {
      "to": {
        name: 'RequestedRedirects',
        params: {
          status: 'requested'
        }
      }
    }
  }, [_c('div', {
    staticClass: "data-block",
    staticStyle: {
      "background": "#3da9fc"
    }
  }, [_c('span', {
    staticClass: "flex flex-row justify-center"
  }, [_c('h2', [_vm._v("campaign requests")]), _c('Icon', {
    staticClass: "mt-1 ml-1",
    attrs: {
      "name": "openInNew",
      "size": 3
    }
  })], 1), _vm.$apollo.queries.campaignRequests.loading ? _c('Spinner', {
    staticClass: "mt-2 ml-0 absolute",
    attrs: {
      "size": 6
    }
  }) : _c('span', {
    staticClass: "text-xl font-extrabold"
  }, [_c('h3', [_vm._v(_vm._s(_vm.campaignRequests.paginatorInfo.total))])])], 1)])], 1), _c('div', {}, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Users',
        params: {
          filterValues: {
            status: 'Pending'
          }
        }
      }
    }
  }, [_c('div', {
    staticClass: "data-block",
    staticStyle: {
      "background": "#3da9fc"
    }
  }, [_c('span', {
    staticClass: "flex flex-row justify-center"
  }, [_c('h2', [_vm._v("pending users")]), _c('Icon', {
    staticClass: "mt-1 ml-1",
    attrs: {
      "name": "openInNew",
      "size": 3
    }
  })], 1), _vm.$apollo.queries.pendingUsers.loading ? _c('Spinner', {
    staticClass: "mt-2 ml-0 mb-0 absolute",
    attrs: {
      "size": 6
    }
  }) : _c('span', {
    staticClass: "text-xl font-extrabold"
  }, [_c('h3', [_vm._v(_vm._s(_vm.pendingUsers.paginatorInfo.total))])])], 1)])], 1)])]), _c('div', {
    staticClass: "col-span-12 lg:col-span-4 lg:row-span-2 rounded-md flex flex-col justify-between gap-3 px-3 py-8 shadow-md bg-gray-100"
  }, [_c('div', {
    staticClass: "rounded-md col-span-4 rounded-md py-2 px-4 border",
    staticStyle: {
      "background": "#fffffe"
    }
  }, [_vm._m(0), _vm.$apollo.queries.publishersNewRevenue.loading ? _c('Spinner', {
    staticClass: "mt-2 ml-0 mb-0 absolute",
    attrs: {
      "size": 6
    }
  }) : _vm._e(), _vm._l(_vm.publishersNewRevenue, function (publisher) {
    return _c('ul', {
      key: publisher.id,
      staticClass: "pl-2"
    }, [_c('li', {
      staticClass: "flex justify-between pt-1"
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: publisher.user.id,
        expression: "publisher.user.id"
      }]
    }, [_c('router-link', {
      staticClass: "hover:text-blue-800",
      attrs: {
        "to": {
          name: 'User',
          params: {
            id: publisher.user.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(publisher.user.email) + " ")])], 1), _c('div', {
      staticClass: "text-xs font-bold text-gray-900"
    }, [_vm._v(" " + _vm._s(_vm.$formatMoney(publisher.realRevenue)) + " ")])])]);
  })], 2), _c('div', {
    staticClass: "rounded-md col-span-4 rounded-md py-2 px-4 border",
    staticStyle: {
      "background": "#fffffe"
    }
  }, [_vm._m(1), _vm.$apollo.queries.advertisersNetRevenue.loading ? _c('Spinner', {
    staticClass: "mt-2 ml-0 mb-0 absolute",
    attrs: {
      "size": 6
    }
  }) : _vm._e(), _vm._l(_vm.advertisersNetRevenue, function (advertiser) {
    return _c('ul', {
      key: advertiser.user.id,
      staticClass: "pl-2"
    }, [_c('li', {
      staticClass: "flex justify-between pt-1"
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: advertiser.user.id,
        expression: "advertiser.user.id"
      }]
    }, [_c('router-link', {
      staticClass: "hover:text-blue-800",
      attrs: {
        "to": {
          name: 'User',
          params: {
            id: advertiser.user.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(advertiser.user.label.split(' - ').slice(1).join(' - ')) + " ")])], 1), _c('div', {
      staticClass: "text-xs font-bold text-gray-900",
      class: advertiser.netRevenue >= 0 ? 'text-green-600' : 'text-pink-600'
    }, [advertiser.netRevenue > 0 ? _c('span', [_vm._v("+")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$formatMoney(advertiser.netRevenue)) + " ")])])]);
  }), _c('span', {
    staticClass: "text-xs text-gray-800 block w-full text-right italic"
  }, [_vm._v(" Net revenue = revenue - payout")])], 2), _c('div', {
    staticClass: "rounded-md col-span-4 rounded-md py-2 px-4 border",
    staticStyle: {
      "background": "#fffffe"
    }
  }, [_vm._m(2), _vm.$apollo.queries.userNetRevenueVariance.loading ? _c('Spinner', {
    staticClass: "mt-2 ml-0 mb-0 absolute",
    attrs: {
      "size": 6
    }
  }) : _vm._e(), _vm._l(_vm.userNetRevenueVariance, function (publisher) {
    return _c('ul', {
      key: publisher.user.id,
      staticClass: "pl-2"
    }, [_c('li', {
      staticClass: "flex justify-between pt-1"
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: publisher.user.id,
        expression: "publisher.user.id"
      }]
    }, [_c('router-link', {
      staticClass: "hover:text-blue-800",
      attrs: {
        "to": {
          name: 'User',
          params: {
            id: publisher.user.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(publisher.user.email) + " ")])], 1), _c('div', {
      staticClass: "text-xs font-bold text-gray-900",
      class: publisher.netRevenue > 0 ? 'text-green-600' : 'text-pink-600'
    }, [publisher.netRevenue > 0 ? _c('span', [_vm._v("+")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$formatMoney(publisher.netRevenue)) + " ")])])]);
  }), _c('span', {
    staticClass: "text-xs text-gray-800 block w-full text-right italic"
  }, [_vm._v(" Net revenue = revenue - payout")])], 2)]), _c('div', {
    staticClass: "hidden lg:col-span-8 lg:grid lg:grid-cols-8 gap-4 rounded-md"
  }, [_c('div', {
    staticClass: "rounded-md col-span-4 bg-white shadow-md"
  }, [_c('div', {
    staticClass: "header-container header-border"
  }, [_vm._v("Status Cake")]), _c('div', {
    staticClass: "border-l border-b rounded-b-md overflow-y-scroll",
    staticStyle: {
      "height": "14rem"
    }
  }, [_c('StatusCakeTable')], 1)]), _c('div', {
    staticClass: "rounded-md col-span-4 bg-white shadow-md"
  }, [_c('div', {
    staticClass: "header-container header-border"
  }, [_vm._v("Logins")]), _c('div', {
    staticClass: "border-l border-b rounded-b-md overflow-y-scroll",
    staticStyle: {
      "height": "14rem"
    }
  }, [_c('YTable', {
    attrs: {
      "scrollX": false,
      "data": _vm.recentLogins || [],
      "bottom-loading": _vm.$apolloData.queries.recentLogins.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('router-link', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: props.rowData.causer.id,
            expression: "props.rowData.causer.id"
          }],
          staticClass: "link inline-flex items-center",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.causer.id
              }
            }
          }
        }, [_c('span', {
          staticClass: "truncate",
          staticStyle: {
            "max-width": "15rem"
          }
        }, [_vm._v(" " + _vm._s(props.rowData.causer.email) + " ")])]), _c('div', [_c('span', {
          staticClass: "font-semibold text-gray-800 text-xs pr-2"
        }, [_vm._v("IP:")]), _c('a', {
          staticClass: "link inline-flex items-center",
          attrs: {
            "href": `https://www.ip2location.com/demo/${props.rowData.properties.ip}`,
            "target": "_blank"
          }
        }, [props.rowData.properties.countryCode ? _c('Flag', {
          staticClass: "mr-2 rounded-sm overflow-hidden",
          attrs: {
            "country": props.rowData.properties.countryCode
          }
        }) : _vm._e(), _c('span', {
          staticClass: "truncate",
          staticStyle: {
            "max-width": "9rem"
          }
        }, [_vm._v(" " + _vm._s(props.rowData.properties.ip) + " ")])], 1)])], 1)]), _c('td', [_vm._v(" " + _vm._s(_vm._f("luxonRelative")(props.rowData.createdAt)) + " ")])];
      }
    }])
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("User")]), _c('th', [_vm._v("last login")])])], 2)], 1)])]), _c('div', {
    staticClass: "grid col-span-12 rounded-md p-2 shadow-md bg-gray-100 w-full"
  }, [_c('div', {
    staticClass: "flex lg:h-64 w-full"
  }, [_vm._m(3), _c('div', {
    staticClass: "flex-grow w-full flex flex-col lg:grid lg:grid-cols-12 gap-3 py-2"
  }, [_c('div', {
    staticClass: "col-span-4 border rounded-md bg-white mr-1"
  }, [_vm.$apollo.queries.topPublisherDaily.loading ? _c('Spinner', {
    staticClass: "mt-2 ml-0 mb-0 absolute",
    attrs: {
      "size": 6
    }
  }) : _vm._e(), _c('YTable', {
    attrs: {
      "data": _vm.topPublisherDaily || []
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_c('div', {
          staticClass: "truncate w-32 2xl:w-full"
        }, [_c('router-link', {
          staticClass: "hover:text-blue-800",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.user.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.user.label.split('-')[1]) + " ")])], 1)]), _c('td', [_c('span', {
          staticClass: "text-xs font-bold text-gray-700"
        }, [_vm._v(" $" + _vm._s(props.rowData.realRevenue) + " ")])]), _c('td', [_c('span', {
          staticClass: "text-xs font-bold",
          class: props.rowData.revenue < 0 ? 'text-red-500' : 'text-gray-900'
        }, [_vm._v(" $" + _vm._s(props.rowData.netRevenue) + " ")])])];
      }
    }])
  }, [_c('template', {
    slot: "secondHeader"
  }, [_c('td', {
    staticClass: "leaderboard-container"
  }, [_vm._v("Publishers")]), _c('td', [_c('span', {
    staticClass: "second-header-text"
  }, [_vm._v("Real")])]), _c('td', [_c('span', {
    staticClass: "second-header-text"
  }, [_vm._v("Net")])])])], 2)], 1), _c('div', {
    staticClass: "col-span-4 border rounded-md bg-white mr-1"
  }, [_vm.$apollo.queries.topAdvertiserDaily.loading ? _c('Spinner', {
    staticClass: "mt-2 ml-0 mb-0 absolute",
    attrs: {
      "size": 6
    }
  }) : _vm._e(), _vm.topAdvertiserDaily ? _c('YTable', {
    attrs: {
      "data": _vm.topAdvertiserDaily || []
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_c('div', {
          staticClass: "truncate w-32 2xl:w-full"
        }, [_c('router-link', {
          staticClass: "hover:text-blue-800",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.user.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.user.label.split('-').pop()) + " ")])], 1)]), _c('td', [_c('span', {
          staticClass: "text-xs font-bold text-gray-700"
        }, [_vm._v(" $" + _vm._s(props.rowData.realRevenue) + " ")])]), _c('td', [_c('span', {
          staticClass: "text-xs font-bold",
          class: props.rowData.revenue < 0 ? 'text-red-500' : 'text-gray-900'
        }, [_vm._v(" $" + _vm._s(props.rowData.netRevenue) + " ")])])];
      }
    }], null, false, 765333414)
  }, [_c('template', {
    slot: "secondHeader"
  }, [_c('td', {
    staticClass: "leaderboard-container"
  }, [_vm._v("Advertisers")]), _c('td', [_c('span', {
    staticClass: "second-header-text"
  }, [_vm._v("Real")])]), _c('td', [_c('span', {
    staticClass: "second-header-text"
  }, [_vm._v("Net")])])])], 2) : _vm._e()], 1), _c('div', {
    staticClass: "col-span-4 border rounded-md bg-white mr-3"
  }, [_vm.$apollo.queries.topDirectCampaignsDaily.loading ? _c('Spinner', {
    staticClass: "mt-2 ml-0 mb-0 absolute",
    attrs: {
      "size": 6
    }
  }) : _vm._e(), _vm.topDirectCampaignsDaily ? _c('YTable', {
    attrs: {
      "data": _vm.topDirectCampaignsDaily || []
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_c('div', {
          staticClass: "truncate w-32 2xl:w-full"
        }, [_c('router-link', {
          staticClass: "hover:text-blue-800",
          attrs: {
            "to": {
              name: 'Campaign',
              params: {
                id: props.rowData.campaign.split('-')[0].trim()
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.campaign.split('-')[1]) + " ")])], 1)]), _c('td', [_c('span', {
          staticClass: "text-xs font-bold text-gray-700"
        }, [_vm._v("$" + _vm._s(props.rowData.realRevenue))])]), _c('td', [_c('span', {
          staticClass: "text-xs font-bold",
          class: props.rowData.revenue < 0 ? 'text-red-500' : 'text-gray-900'
        }, [_vm._v("$" + _vm._s(props.rowData.netRevenue))])])];
      }
    }], null, false, 1278528766)
  }, [_c('template', {
    slot: "secondHeader"
  }, [_c('td', {
    staticClass: "leaderboard-container"
  }, [_vm._v("Direct Campaigns")]), _c('td', [_c('span', {
    staticClass: "second-header-text"
  }, [_vm._v("Real")])]), _c('td', [_c('span', {
    staticClass: "second-header-text"
  }, [_vm._v("Net")])])])], 2) : _vm._e()], 1)])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-between items-center mb-2"
  }, [_c('div', {
    staticClass: "font-bold text-base",
    staticStyle: {
      "color": "#094067"
    }
  }, [_vm._v(" New Publishers Top Revenue ")]), _c('div', {
    staticClass: "text-xs font-semibold",
    staticStyle: {
      "color": "#094067"
    }
  }, [_vm._v(" 90 days ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-between items-center mb-2"
  }, [_c('div', {
    staticClass: "font-bold text-base",
    staticStyle: {
      "color": "#094067"
    }
  }, [_vm._v(" New Advertisers Net Revenue ")]), _c('div', {
    staticClass: "text-xs font-semibold",
    staticStyle: {
      "color": "#094067"
    }
  }, [_vm._v(" 90 days ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-between items-center mb-2"
  }, [_c('div', {
    staticClass: "font-bold text-base",
    staticStyle: {
      "color": "#094067"
    }
  }, [_vm._v(" Net Revenue ")]), _c('div', {
    staticClass: "text-xs font-semibold",
    staticStyle: {
      "color": "#094067"
    }
  }, [_vm._v(" 30 days ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hidden lg:block lg:relative w-8"
  }, [_c('div', {
    staticClass: "text-lg font-bold uppercase relative transform -rotate-90 py-2 px-6 rounded-md",
    staticStyle: {
      "transform-origin": "left",
      "left": "12px",
      "top": "214px",
      "width": "max-content",
      "color": "#094067"
    }
  }, [_vm._v(" Daily Leaderboard ")])]);

}]

export { render, staticRenderFns }