var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Upload Image to Repository ")]), _c('widget-body', [_c('form', {
    ref: "upload",
    staticClass: "row",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addImage.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row flex items-center mb-3"
  }, [_c('div', {
    class: _vm.style.columnTitle
  }, [_vm._v(" Choose Image ")]), _c('div', {
    staticClass: "col"
  }, [_c('ImageInput', {
    key: _vm.isSubmitting,
    staticClass: "rounded",
    attrs: {
      "imageUrl": null,
      "thumbnailImageUrl": null,
      "height": 32,
      "width": 32,
      "blankHeight": 20,
      "blankWidth": 20,
      "editable": true,
      "editable-only-when-empty": false,
      "file": _vm.imageFile
    },
    on: {
      "update:file": function ($event) {
        _vm.imageFile = $event;
      }
    }
  })], 1)]), _c('div', {
    staticClass: "row flex items-center mb-3"
  }, [_c('div', {
    class: _vm.style.columnTitle
  }, [_vm._v(" Name ")]), _c('div', {
    staticClass: "col w-2/3"
  }, [_c('YInput', {
    attrs: {
      "label": "Name",
      "required": true
    },
    model: {
      value: _vm.imageName,
      callback: function ($$v) {
        _vm.imageName = $$v;
      },
      expression: "imageName"
    }
  })], 1)]), _c('div', {
    staticClass: "row flex items-center mb-3"
  }, [_c('div', {
    class: _vm.style.columnTitle
  }, [_vm._v(" Tags ")]), _c('div', {
    staticClass: "col w-2/3"
  }, [_c('Superselect', {
    attrs: {
      "title": "Tags",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['vertical']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.tagSelection,
      callback: function ($$v) {
        _vm.tagSelection = $$v;
      },
      expression: "tagSelection"
    }
  })], 1)]), _c('div', {
    staticClass: "w-full flex justify-end pt-2"
  }, [_c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSubmitting
    }
  }, [_vm._v("Add Image")])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }