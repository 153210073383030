<template>
  <form
    id="new-bulk-rule-form"
    name="bulk-rule-form"
    @submit="submitForm"
    ref="form"
    @keydown="errors.clear($event.target.name)"
    @change="errors.clear($event.target.name)"
  >
    <div>
      <div class="flex w-full items-center justify-center mb-3">
        <label
          class="w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-600 rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-600 hover:text-white"
        >
          <svg
            class="w-8 h-8"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
            />
          </svg>
          <span v-if="csvFileName" class="mt-2 text-base leading-normal">{{
            csvFileName
          }}</span>
          <span v-else class="mt-2 text-base leading-normal uppercase"
            >Upload CSV</span
          >
          <input
            type="file"
            class="hidden"
            @change="onCsvChange"
            ref="bulkCsvInput"
          />
        </label>
      </div>
      <div class="text-center">
        <small
          ><a
            class="link inline-flex items-center"
            href="https://ytrack-documents.s3.amazonaws.com/payouts-example.csv"
            ><Icon name="download" :size="4" class="mr-1" /> Download CSV
            Example</a
          ></small
        >
      </div>
    </div>
    <div class="flex justify-end">
      <YButton class="link mr-2" @click.prevent="clearForm">Clear CSV</YButton>
      <!-- <YButton
        color="blue"
        :is-loading="loading"
        :disabled="!csvFileName"
        @click.prevent="addToPreview"
        >Add Rows From CSV</YButton
      > -->
    </div>
  </form>
</template>

<script>
class Errors {
  constructor() {
    this.errors = {}
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0]
    }
  }

  record(errors) {
    this.errors = errors
  }

  clear(field) {
    delete this.errors[field]
  }
}

import Papa from 'papaparse'

const camelize = function(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export default {
  components: {},
  props: ['loadingPreview'],
  data() {
    let initialData = {
      bulk: true,
      type: 'payout',
      csvData: null,
      notes: null
    }

    return {
      data: this._.clone(initialData, true),
      initialData: this._.clone(initialData, true),
      csvFileName: null,
      errors: new Errors()
    }
  },
  computed: {
    loading() {
      return this.loadingPreview
    },
    payoutsData() {
      return this.data.csvData.map(payout => ({
        level: this.getLevelFromCsvRow(payout),
        campaignId: payout.campaignID,
        redirectId: payout.redirectID,
        sourceId: payout.sourceID,
        type: payout?.type?.toLowerCase(),
        conversionType: payout.conversionType,
        countryCode: payout.country,
        value: payout.value ? Number(payout.value) : null,
        floorValue: payout.floorValue ? Number(payout.floorValue) : null,
        startAt: payout.startAt,
        isPaused: payout.status?.toLowerCase() == 'paused' ? true : false
      }))
    }
  },
  methods: {
    getLevelFromCsvRow(row) {
      if (row.sourceID && row.redirectID) {
        return 'Source'
      }

      if (row.redirectID) {
        return 'Redirect'
      }

      if (row.campaignID) {
        return 'Campaign'
      }

      return 'Error'
    },
    addToPreview() {
      this.$events.fire('addBulkPayoutsToPreview', {
        data: this.payoutsData
      })
    },
    clearPreview() {
      this.$events.fire('clearBulkPayoutsPreview', {
        data: this.payoutsData
      })
    },
    submitForm(event) {
      event.preventDefault()
      this.$events.fire('submitPayoutForm', {
        form: this.$refs.form,
        data: this.data
      })
    },
    onCsvChange(event) {
      let files = event.target.files || event.dataTransfer.files
      if (files[0]) {
        var myReader = new FileReader()
        myReader.onload = () => {
          var csv = myReader.result

          var results = Papa.parse(csv, {
            header: true,
            transformHeader: camelize,
            transform: value => (value == '' ? null : value),
            skipEmptyLines: true
          })
          this.data.csvData = results.data
          this.csvFileName = files[0].name
        }

        myReader.readAsText(files[0])
        this.$nextTick(() => {
          setTimeout(() => {
            this.addToPreview()
          }, 100)
        })
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.clearPreview()
        }, 1)
      })
    },
    catchErrors(errors) {
      if (errors.form == this.$refs.form) {
        this.errors.record(errors.errors)
      }
    },
    clearForm() {
      this.data = this._.clone(this.initialData, true)
      this.csvFileName = null
      this.$refs.bulkCsvInput.value = null
      this.clearPreview()
    }
  },
  mounted() {},

  updated() {}
}
</script>
<style scoped>
.form-group .multiselect {
  margin-bottom: 0.5rem;
}
.form-group .multiselect:last-child {
  margin-bottom: 0rem;
}

.widget-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.tiny-forms .input-group-btn .btn {
  line-height: 1.3;
}
</style>
