export const state = {
  filters: {
    tag: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'Tag',
      type: 'input'
    },
    type: {
      title: 'Type',
      type: 'select',
      multiple: false,
      optionLabelKey: 'id',
      trackByKey: 'id',
      value: null
    }
  },
  orderBy: [
    {
      column: 'created_at',
      order: 'ASC'
    }
  ]
}
