var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Update Default Conversion Types ")]), _c('WidgetBody', {
    staticClass: "pt-2"
  }, [_c('div', [_c('div', [_c('Superselect', {
    staticClass: "mb-2",
    attrs: {
      "title": "Conversion Type",
      "multiple": false,
      "taggable": true,
      "options": _vm.conversionTypeOptions,
      "required": true
    },
    model: {
      value: _vm.conversionType,
      callback: function ($$v) {
        _vm.conversionType = $$v;
      },
      expression: "conversionType"
    }
  })], 1)])]), _c('WidgetFooter', {
    staticClass: "bg-gray-100 flex justify-between p-4 items-center"
  }, [_c('div'), _c('YButton', {
    staticClass: "btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button"
    },
    on: {
      "click": _vm.submit,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_vm._v(" Update ")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }