<template>
  <v-popover
    v-if="subscribedTasks.length > 0 || dbTasks.length > 0"
    offset="12"
    class="p-0"
    placement="bottom"
    @apply-show="opened"
    @apply-hide="closed"
  >
    <YButton
      class="link hover:bg-gray-100 focus:bg-gray-100 outline-none-important px-2 py-2 ml-2"
      style="position: relative;"
    >
      <div
        v-if="pendingSubscribedCount > 0"
        style="border-radius: 0.35rem; font-size: 0.75rem; line-height: 1.15rem; position: absolute; right: 0; top: 0; z-index: 99"
        class="bg-blue-400 text-white h-5 px-1 text-center"
      >
        {{ pendingSubscribedCount }}
      </div>
      <div
        v-if="completedSubscribedCount > 0"
        style="border-radius: 0.35rem; font-size: 0.75rem; line-height: 1.15rem; position: absolute; right: 0; bottom: 0; z-index: 99"
        class="bg-green-400 text-white h-5 px-1 text-center"
      >
        {{ completedSubscribedCount }}
      </div>
      <TasksIcon class="w-8 h-8" :loading="pendingSubscribedCount > 0" />
    </YButton>
    <!-- This will be the content of the popover -->
    <template slot="popover">
      <!-- Top bar -->
      <div
        class="bg-gray-100 pt-1 pb-2 px-4 border-b text-xxs font-bold flex justify-between"
      >
        <div></div>
        <div>
          <button
            class="uppercase text-blue-500 hover:text-blue-600"
            @click="deleteFinished"
          >
            Clear Finished
          </button>
        </div>
      </div>
      <div style="width: 28rem;">
        <Expandable
          :initial-state="hasPendingTasks ? true : false"
          clickable-class="px-4 py-2 text-xxs text-blue-500 hover:text-blue-600 uppercase bg-gray-100 border-b"
        >
          <template v-slot:title class="flex items-center">
            In Progress
            <!-- <div
              v-if="pendingCount > 0"
              style="border-radius: 0.35rem; font-size: 0.75rem; line-height: 1.15rem;"
              class="bg-blue-400 text-white h-5 px-1 text-center mx-1"
            >
              {{ pendingCount }}
            </div> -->
          </template>
          <div style="max-height: 30rem; overflow-y: auto;">
            <div v-show="pendingSubscribed.length > 0">
              <Task
                v-for="task in pendingSubscribed"
                :key="task.id"
                :value="task"
                :emphasized="true"
                @deleted="removeTask"
              />
            </div>
            <div v-show="pendingDb.length > 0">
              <Task
                v-for="task in pendingDb"
                :key="task.id"
                :value="task"
                @deleted="removeTask"
              />
            </div>
            <div
              v-show="pending.length <= 0"
              class="text-gray-400 text-center py-1"
            >
              No Tasks In Progress
            </div>
          </div>
        </Expandable>
        <Expandable
          :initial-state="hasCompletedTasks ? true : false"
          clickable-class="px-4 py-2 text-xxs text-blue-500 hover:text-blue-600 uppercase bg-gray-100 border-b"
        >
          <template v-slot:title class="flex items-center">
            Finished
            <!-- <div
              v-if="completedCount > 0"
              style="border-radius: 0.35rem; font-size: 0.75rem; line-height: 1.15rem;"
              class="bg-green-400 text-white h-5 px-1 text-center mx-1"
            >
              {{ completedCount }}
            </div> -->
          </template>
          <div style="max-height: 30rem; overflow-y: auto;">
            <div v-show="completed.length > 0">
              <Task
                v-for="task in completed"
                :key="task.id"
                :value="task"
                :emphasized="subscribedTaskIds.includes(task.id)"
                :show-progress="false"
                @deleted="removeTask"
              />
            </div>
            <div
              v-show="completed.length <= 0"
              class="text-gray-400 text-center py-1"
            >
              No Tasks In Progress
            </div>
          </div>
        </Expandable>
      </div>
    </template>
  </v-popover>
</template>

<script>
import TasksIcon from '@/components/general/TasksIcon'
import Expandable from '@/components/ui/Expandable'

import Task from '@/views/Admin/Tasks/Task'
import TASKS_QUERY from '@/graphql/Task/TasksQuery.gql'
import DELETE_TASKS_MUTATION from '@/graphql/Task/DeleteTasksMutation.gql'

export default {
  components: {
    TasksIcon,
    Task,
    Expandable
  },
  apollo: {
    tasks: {
      fetchPolicy() {
        return 'cache-and-network'
      },
      query: TASKS_QUERY,
      variables() {
        return {
          first: 20,
          page: 1,
          filters: {
            ownOnly: true
          }
        }
      },
      pollInterval() {
        return this.hasPendingDbTasks ? 15000 : 1000 * 60
      },
      result({ data }) {
        if (data && data.tasks && data.tasks.data) {
          let newTasks = data.tasks.data

          this.getJustCompleted(this.dbTasks, newTasks).forEach(task => {
            this.processCompletedTask(task)
          })

          this.dbTasks = newTasks.filter(
            task => this.pushedTaskIdMap[task.id] == undefined
          )
        }
      }
    }
  },
  data() {
    return {
      subscribedTasks: [],
      dbTasks: [],
      rightIconHover: {},
      isOpen: false
    }
  },
  computed: {
    hasSubscribedTasks() {
      return this.subscribedTasks.length > 0
    },
    subscribedTaskIds() {
      return this.subscribedTasks.map(task => task.id)
    },
    hasPendingTasks() {
      return this.pending.length > 0
    },
    hasCompletedTasks() {
      return this.completed.length > 0
    },
    hasDbTasks() {
      return this.dbTasks.length > 0
    },
    hasPendingDbTasks() {
      return this.pendingDb.length > 0
    },
    pendingSubscribed() {
      return this.subscribedTasks.filter(task => task.status == 'pending')
    },
    pendingSubscribedCount() {
      return this.pendingSubscribed.length
    },
    pending() {
      return this.pendingSubscribed.concat(this.pendingDb)
    },
    pendingCount() {
      return this.pending.length
    },
    completedSubscribed() {
      return this.subscribedTasks.filter(task => task.isFinished)
    },
    completedSubscribedCount() {
      return this.completedSubscribed.length
    },
    completed() {
      return this.completedSubscribed
        .concat(this.completedDb)
        .sort((a, b) => new Date(b.completedAt) - new Date(a.completedAt))
    },
    completedCount() {
      return this.completed.length
    },
    pendingDb() {
      return this.dbTasks.filter(task => task.status == 'pending')
    },
    pendingCountDb() {
      return this.pendingDb.length
    },
    completedDb() {
      return this.dbTasks.filter(task => task.isFinished)
    },
    completedCountDb() {
      return this.completedDb.length
    },
    pushedTaskIdMap() {
      const map = {}
      this.subscribedTasks.forEach((task, index) => {
        map[task.id] = index
      })
      return map
    }
  },
  mounted() {
    this.$events.listen('pushBackgroundTask', task => {
      if (task.isFinished) {
        this.processCompletedTask(task)
      }
      this.pushTask(task)
    })
  },
  beforeDestroy() {
    this.$events.off('pushBackgroundTask')
  },
  methods: {
    getJustCompleted(oldTasks, newTasks) {
      const oldPending = oldTasks.filter(task => !task.isFinished)
      const newCompleted = newTasks.filter(task => task.isFinished)

      const oldPendingIds = oldPending.map(task => task.id)
      const newCompletedIds = newCompleted.map(task => task.id)

      const justCompletedIds = newCompletedIds.filter(id =>
        oldPendingIds.includes(id)
      )

      return newCompleted.filter(task => justCompletedIds.includes(task.id))
    },
    processCompletedTask(task) {
      if (task.type == 'trafficking') {
        this.$events.emit('offerTrafficked')
      }
      if (task.type == 'adminReport') {
        console.log('task', task)
        this.$events.emit('adminReportReady', {
          reportId: task.meta.reportId,
          appInstance: task.appInstance
        })
      }
    },
    opened() {
      this.isOpen = true
      this.$apollo.queries.tasks.refetch()
    },
    closed() {
      this.isOpen = false
      this.subscribedTasks = this.subscribedTasks.filter(
        task => !task.isFinished
      )
      this.$apollo.queries.tasks.refetch()
    },
    pushTask(task) {
      if (this.pushedTaskIdMap[task.id] == undefined) {
        this.subscribedTasks.unshift(task)
        return
      }

      if (!task.results) {
        task.results = this.subscribedTasks[
          this.pushedTaskIdMap[task.id]
        ]?.results
      }

      this.subscribedTasks[this.pushedTaskIdMap[task.id]] = task
      this.subscribedTasks = [...this.subscribedTasks]
    },
    removeTask(id) {
      this.subscribedTasks = this.subscribedTasks.filter(task => task.id != id)
      this.dbTasks = this.dbTasks.filter(task => task.id != id)
      this.dbTasks = this.dbTasks.filter(task => task.id != id)
    },
    deleteFinished() {
      let ids = [
        ...this.completed.map(task => task.id),
        ...this.completedDb.map(task => task.id)
      ]

      if (ids.length <= 0) {
        return
      }

      this.isDeletingAll = true
      this.$apollo
        .mutate({
          mutation: DELETE_TASKS_MUTATION,
          variables: {
            ids: [
              ...this.completed.map(task => task.id),
              ...this.completedDb.map(task => task.id)
            ]
          }
        })
        .then(() => {
          this.isDeletingAll = false
          this.$apollo.queries.tasks.refetch()
          this.subscribedTasks = this.subscribedTasks.filter(
            task => !task.isFinished
          )
        })
        .catch(() => {
          this.isDeletingAll = false
        })
    }
  }
}
</script>

<style scoped>
.fadePulse {
  opacity: 1;
  animation: fade 2s linear infinite;
  webkit-animation: fade 2s linear infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
}

@webkit-keyframes fade {
  0%,
  100% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
}
</style>
