var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', [_c('WidgetHeader', [_vm._v("Merge into Primary User Account")]), _c('WidgetBody', [_c('div', {
    staticClass: "grid grid-cols-1 gap-2"
  }, [_c('div', [_c('span', {
    staticClass: "text-red-400 font-bold"
  }, [_vm._v("Caution:")]), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v(" " + _vm._s(_vm.user.label))]), _vm._v(" will be merged into the selected account as a secondary contact. "), _c('br'), _vm._v("User " + _vm._s(_vm.user.id) + " will be "), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("deleted")]), _vm._v(" and no longer have an individual profile. ")]), _c('Superselect', {
    attrs: {
      "title": "Join to User",
      "multiple": false,
      "track-by": "id",
      "label": "label",
      "query": _vm.OPEN_USERS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        orderBy: [{
          column: 'id',
          order: 'DESC'
        }],
        first: 25,
        page: 1
      }
    },
    model: {
      value: _vm.primaryUser,
      callback: function ($$v) {
        _vm.primaryUser = $$v;
      },
      expression: "primaryUser"
    }
  })], 1), _c('div', {
    staticClass: "w-full flex justify-end gap-3 mt-4"
  }, [_c('YButton', {
    attrs: {
      "color": "gray"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.createSecondaryContact
    }
  }, [_vm._v("Merge with Primary")])], 1)])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }