export const state = {
  filters: {
    name: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'Name',
      type: 'input'
    },
    tags: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'Tags',
      type: 'input'
    }
  },
  orderBy: [
    {
      column: 'created_at',
      order: 'DESC'
    }
  ]
}
