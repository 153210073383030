var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grid grid-flow-row"
  }, [_c('div', {
    staticClass: "col-span-1"
  }, [_vm._v(" " + _vm._s(_vm.rowData.level.capitalize()) + " ")]), _vm.link ? _c('div', {
    staticClass: "col-span-1"
  }, [_vm.link ? [_vm._v("("), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: this.rowData.level.capitalize(),
        params: {
          id: _vm.link.id,
          tab: 'payouts'
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.link.id))]), _vm._v(")")] : _vm._e()], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }