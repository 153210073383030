var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', {
    staticClass: "mb-1 overflow-visible",
    attrs: {
      "loading": _vm.$apolloData.queries.offers.loading
    }
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('BulkOfferTransformer', {
    ref: "bulkOfferTransformer",
    attrs: {
      "offers-to-edit": _vm.offers ? _vm.offers.data : []
    },
    on: {
      "offersTransformed": _vm.updateModifiedOffers,
      "warningToggled": _vm.warningToggled
    },
    scopedSlots: _vm._u([{
      key: "beforeButtons",
      fn: function () {
        return [_c('YButton', {
          staticClass: "mx-2",
          attrs: {
            "color": "blue",
            "disabled": _vm.saveDisabled,
            "is-loading": _vm.isSubmitting
          },
          on: {
            "click": _vm.bulkUpdateOffers
          }
        }, [_vm._v("Save")])];
      },
      proxy: true
    }])
  })], 1)], 2)], 1), _vm.offersModified ? _c('YTable', {
    attrs: {
      "data": _vm.offersModified,
      "loading": _vm.$apolloData.queries.offers.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_c('div', {
          staticClass: "flex items-center"
        }, [_c('div', {
          staticClass: "flex justify-between items-center flex-1",
          staticStyle: {
            "max-width": "35rem"
          }
        }, [_c('div', {
          staticClass: "mx-4"
        }, [_c('router-link', {
          staticClass: "font-bold break-words without-truncate inline-block",
          class: props.rowData.isCapped ? 'text-red-600 hover:text-red-700' : 'link',
          attrs: {
            "to": {
              name: 'Offer',
              params: {
                id: props.rowData.id,
                action: 'edit'
              }
            }
          }
        }, [props.rowData.id ? [_vm._v(_vm._s(props.rowData.id) + " - ")] : _vm._e(), _vm._v(" " + _vm._s(props.rowData.name) + " ")], 2), _c('sup', {
          staticClass: "text-gray-500 font-semibold"
        }, [_vm._v(" " + _vm._s(props.rowData.encodedId) + " ")])], 1)])])]), _c('td', [_c('div', {
          domProps: {
            "innerHTML": _vm._s(props.rowData.highlightedDestination || props.rowData.destination)
          }
        })]), _c('td', [props.rowData.verticals && props.rowData.verticals.tags ? _vm._l(props.rowData.verticals.tags, function (tagName) {
          return _c('Tag', {
            key: tagName,
            staticStyle: {
              "margin": "1px"
            },
            attrs: {
              "color": props.rowData.verticals.isNot ? 'red' : 'blue'
            }
          }, [_vm._v(" " + _vm._s(tagName) + " ")]);
        }) : _vm._e()], 2), _c('td', [props.rowData.mobileDesktop && props.rowData.mobileDesktop.tags ? _vm._l(props.rowData.mobileDesktop.tags, function (tagName) {
          return _c('Tag', {
            key: tagName,
            staticStyle: {
              "margin": "1px"
            },
            attrs: {
              "color": props.rowData.mobileDesktop.isNot ? 'red' : 'blue'
            }
          }, [_vm._v(" " + _vm._s(tagName) + " ")]);
        }) : _vm._e()], 2), _c('td', [props.rowData.countries && props.rowData.countries.tags ? _vm._l(props.rowData.countries.tags, function (tagName) {
          return _c('Tag', {
            key: tagName,
            staticStyle: {
              "margin": "1px"
            },
            attrs: {
              "color": props.rowData.countries.isNot ? 'red' : 'blue'
            }
          }, [_vm._v(" " + _vm._s(tagName) + " ")]);
        }) : _vm._e()], 2)];
      }
    }], null, false, 884612226)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th'), _c('th', [_vm._v("Destination")]), _c('th', [_vm._v("Vertical")]), _c('th', [_vm._v("Mobile / Desktop")]), _c('th', [_vm._v("Geo")])])], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }