<template>
  <div>
    <YButton color="blue" @click="openCreateModal">
      <div class="flex items-center">
        New Tag
      </div>
    </YButton>
  </div>
</template>

<script>
import TagCreate from './TagCreate'
import TagEdit from './TagEdit'
import gql from 'graphql-tag'

export default {
  components: {},
  props: {
    tag: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},

  updated() {},
  methods: {
    openCreateModal() {
      this.$modal.show(
        TagCreate,
        {},
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openEditModal(tag) {
      this.$modal.show(
        TagEdit,
        { tag: tag },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    delete(tags) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteTags($id: [ID!]!) {
              ydnsDeleteTags(id: $id) {
                id
                type
                tag
                color
              }
            }
          `,
          variables: {
            id: tags.map(tag => tag.id)
          }
        })
        .then(() => {
          this.$toastr.s('Tags Deleted', 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshYdnsTags')
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  }
}
</script>
<style></style>
