var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.visibilityChanged,
      expression: "visibilityChanged"
    }],
    staticClass: "mt-4"
  }, [_vm.layout == 'stacked' ? _c('div', [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "KW"
    },
    model: {
      value: _vm.structure.kw,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "kw", $$v);
      },
      expression: "structure.kw"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "S1"
    },
    model: {
      value: _vm.structure.s1,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "s1", $$v);
      },
      expression: "structure.s1"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "S2"
    },
    model: {
      value: _vm.structure.s2,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "s2", $$v);
      },
      expression: "structure.s2"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "S3"
    },
    model: {
      value: _vm.structure.s3,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "s3", $$v);
      },
      expression: "structure.s3"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "S4"
    },
    model: {
      value: _vm.structure.s4,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "s4", $$v);
      },
      expression: "structure.s4"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "S5"
    },
    model: {
      value: _vm.structure.s5,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "s5", $$v);
      },
      expression: "structure.s5"
    }
  })], 1) : _vm.layout == 'inline' ? _c('div', {
    staticClass: "inline-flex"
  }, [_c('YInput', {
    attrs: {
      "label": "KW",
      "inputClass": "border-r-0 rounded-r-none"
    },
    model: {
      value: _vm.structure.kw,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "kw", $$v);
      },
      expression: "structure.kw"
    }
  }), _c('YInput', {
    attrs: {
      "label": "S1",
      "inputClass": "border-r-0 border-l-0 rounded-none"
    },
    model: {
      value: _vm.structure.s1,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "s1", $$v);
      },
      expression: "structure.s1"
    }
  }), _c('YInput', {
    attrs: {
      "label": "S2",
      "inputClass": "border-r-0 border-l-0 rounded-none"
    },
    model: {
      value: _vm.structure.s2,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "s2", $$v);
      },
      expression: "structure.s2"
    }
  }), _c('YInput', {
    attrs: {
      "label": "S3",
      "inputClass": "border-r-0 border-l-0 rounded-none"
    },
    model: {
      value: _vm.structure.s3,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "s3", $$v);
      },
      expression: "structure.s3"
    }
  }), _c('YInput', {
    attrs: {
      "label": "S4",
      "inputClass": "border-r-0 border-l-0 rounded-none"
    },
    model: {
      value: _vm.structure.s4,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "s4", $$v);
      },
      expression: "structure.s4"
    }
  }), _c('YInput', {
    attrs: {
      "label": "S5",
      "inputClass": "border-l-0 rounded-l-none"
    },
    model: {
      value: _vm.structure.s5,
      callback: function ($$v) {
        _vm.$set(_vm.structure, "s5", $$v);
      },
      expression: "structure.s5"
    }
  })], 1) : _vm._e(), _vm.validationErrors && _vm.isVisible ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e(), _vm.hasChanges && _vm.isVisible && !_vm.disableSaving ? _c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('YButton', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }, [_vm._v("Update Redirect Params")])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }