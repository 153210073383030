<template>
  <a
    class="close-x animated"
    :class="classes"
    href="#"
    @click.prevent="$emit('click')"
  >
    <Icon name="close" :size="4" />
  </a>
</template>

<script>
export default {
  components: {},
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    slotClass() {
      return this.loading ? 'invisible' : ''
    },
    loading() {
      return this.isLoading
    },
    disabled() {
      return this.loading
    },
    classes() {
      return []
    }
  },

  mounted() {},

  updated() {},

  methods: {}
}
</script>
<style lang="css" scoped>
.close-x:hover {
  rotatez: '90deg';
}
</style>
