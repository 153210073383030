var render = function render(){
  var _vm$task$name;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border-b"
  }, [_c('div', [_c('div', {
    staticClass: "flex items-center w-full"
  }, [_c('div', {
    staticClass: "rounded ml-4 -mr-1",
    class: [_vm.emphasized ? 'bg-green-500' : 'bg-gray-300', _vm.emphasized ? '' : 'invisible'],
    staticStyle: {
      "width": "5px",
      "height": "5px"
    }
  }), _c('div', {
    staticClass: "flex justify-between px-4 py-2 w-full"
  }, [_c('div', [_c('div', {
    staticClass: "font-bold text-gray-900"
  }, [_vm._v(" " + _vm._s((_vm$task$name = _vm.task.name) !== null && _vm$task$name !== void 0 ? _vm$task$name : 'Unnamed Task') + " ")]), _c('div', {
    staticClass: "text-gray-500 pb-1"
  }, [_c('div', {
    staticClass: "mt-0 uppercase text-gray-500 text-xxs font-semibold"
  }, [_vm._v(" STARTED " + _vm._s(_vm._f("luxon")(_vm.task.createdAt, {
    output: {
      format: 'yyyy-MM-dd @ h:mm a'
    }
  })) + " ")]), _vm.task.isFinished ? _c('div', {
    staticClass: "mt-0 uppercase text-green-500 text-xxs font-semibold"
  }, [_vm._v(" COMPLETED " + _vm._s(_vm._f("luxon")(_vm.task.completedAt, {
    output: {
      format: 'yyyy-MM-dd @ h:mm a'
    }
  })) + " ")]) : _vm._e(), _vm.task.type == 'adminReport' && _vm.task.isFinished ? _c('div', {
    staticClass: "mt-1"
  }, [_c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'Report',
        params: {
          id: _vm.task.meta.reportId
        }
      }
    }
  }, [_vm._v("View Report")])], 1) : _vm._e()])]), _c('div', {
    staticClass: "pt-0"
  }, [_c('div', {
    staticClass: "h-8 w-8 text-right",
    on: {
      "mouseover": function ($event) {
        return _vm.handleRightIconMouseover(_vm.task.id);
      },
      "mouseleave": function ($event) {
        return _vm.handleRightIconMouseleave(_vm.task.id);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.rightIconHover[_vm.task.id],
      expression: "rightIconHover[task.id]"
    }]
  }, [_c('button', {
    staticClass: "h-8 w-8 flex flex-row-reverse items-center text-gray-500 hover:text-blue-500",
    on: {
      "click": function ($event) {
        return _vm.clear(_vm.task.id);
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "close",
      "size": "6"
    }
  })], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.rightIconHover[_vm.task.id],
      expression: "!rightIconHover[task.id]"
    }]
  }, [_vm.task.status == 'pending' ? _c('div', {
    staticClass: "fadePulse"
  }, [_c('Spinner', {
    attrs: {
      "color": "gray",
      "size": "6"
    }
  })], 1) : _vm._e(), _vm.task.status == 'success' ? _c('Icon', {
    staticClass: "text-green-500",
    attrs: {
      "name": "check",
      "size": "8"
    }
  }) : _vm._e(), _vm.task.status == 'error' ? _c('Icon', {
    staticClass: "text-red-500",
    attrs: {
      "name": "alert",
      "size": "6"
    }
  }) : _vm._e()], 1)])])]), _vm.task.hasResults ? _c('div', {
    staticClass: "-mt-2"
  }, [_c('Expandable', {
    attrs: {
      "title": _vm.task.resultsCount + ' Result' + (_vm.task.resultsCount > 1 ? 's' : ''),
      "clickable-class": "px-4 text-xxs text-blue-500 hover:text-blue-600 uppercase"
    },
    on: {
      "expanded": function ($event) {
        return _vm.fetchTask(_vm.task.id);
      }
    }
  }, [_vm.taskIsFetching[_vm.task.id] ? _c('div', {
    staticClass: "text-center"
  }, [_c('Spinner', {
    attrs: {
      "color": "gray",
      "size": "4"
    }
  })], 1) : _c('div', [_vm.task.results ? _c('JSONView', {
    staticClass: "text-xxs",
    attrs: {
      "root-key": "Data",
      "data": _vm.task.results,
      "max-depth": 0
    }
  }) : _vm._e()], 1)])], 1) : _vm._e(), _vm.showProgress ? _c('ProgressBar', {
    attrs: {
      "bar-color": "#0b89ea",
      "val": _vm.task.isFinished ? 100 : _vm.task.progress * 100,
      "size": "small",
      "bar-border-radius": 5
    }
  }) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }