<template>
  <v-popover
    offset="10"
    placement="left-start"
    :autoHide="true"
    popoverClass=""
    :class="['']"
    popoverBaseClass="popover-menu sidebar-popover-menu"
    popoverInnerClass="popover-menu-inner animated animated-speed1 fadeIn"
  >
    <!-- This will be the popover target (for the events and position) -->
    <div class="d-inline-block tooltip-target">
      <slot>
        <YButton class="text-blue-600 hover:bg-blue-100 px-4"
          ><Icon name="dotsVertical"
        /></YButton>
      </slot>
    </div>
    <!-- This will be the content of the popover -->
    <template slot="popover">
      <div class="list-group">
        <y-button
          v-if="provider"
          class="btn-sm list-group-item list-group-item-action"
          @click="openCreateModal(provider.id)"
        >
          <span class="text-success"
            ><i class="zmdi zmdi-check mr-2"></i> Add</span
          >
        </y-button>
        <y-button
          class="btn-sm list-group-item list-group-item-action"
          @click="openDeleteModal(servers[0])"
        >
          <span class="text-danger"
            ><i class="zmdi zmdi-delete mr-2"></i> Delete</span
          >
        </y-button>
      </div>
    </template>
  </v-popover>
</template>

<script>
import gql from 'graphql-tag'
import ServerCreate from './ServerCreate'
import ServerDelete from './ServerDelete'

export default {
  components: {},
  props: {
    servers: {
      type: Array,
      default: () => null
    },
    provider: {
      type: Object,
      default: () => null
    }
  },
  apollo: {},
  data() {
    return {
      selectedDomainRecords: []
    }
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {
    openCreateModal(providerId) {
      this.$modal.show(
        ServerCreate,
        {
          provider: providerId
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    openEditModal() {},
    openDeleteModal(server) {
      this.$modal.show(
        ServerDelete,
        {
          server: server
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    deleteItems(servers = []) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteServers($id: [ID!]!) {
              ydnsDeleteServers(id: $id) {
                id
                provider {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: servers.map(server => server.id)
          }
        })
        .then(result => {
          this.$toastr.s(`Servers Deleted`, 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshYdnsServers', true)
          this.$emit('deleted', result.data.ydnsDeleteServers)
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  }
}
</script>
<style></style>
