<template>
  <div
    class="p-5 flex items-start flex-grow bg-gray-100 h-full ml-4 mr-4"
    style="transform-origin: top;"
    :style="{ transform: `scale(${scaleWindow})` }"
  >
    <div
      class="text-gray-500 text-center w-full"
      :style="{ width: '100vw', height: '100vh', position: 'relative' }"
    >
      <iframe
        :src="previewLink"
        style="
          width: 100%;
          height: 100%;
          
        "
      ></iframe>
      <div class="flex m-2 rounded-lg overflow-hidden">
        <button
          v-if="!autoRefresh"
          class="flex items-center rounded-l-lg px-3 bg-inverse-900 hover-bg-inverse-700 text-white cursor-pointer shadow-md"
          @click="refresh"
        >
          <Icon name="refresh" size="4"></Icon>
        </button>
        <CopyTextButton :text="previewLink" divClass="rounded-r-lg shadow-md" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      required: true
    },
    keyValuePairs: {
      type: Object
    },
    autoRefresh: {
      type: Boolean,
      default: false
    },
    scaleWindow: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      redirectLink: process.env.VUE_APP_TEMPLATE_URL,
      previewLink: ''
    }
  },
  mounted() {
    this.previewLink = this.redirectLink + '/' + this.path
    this.refresh()
  },
  watch: {
    keyValuePairs: {
      deep: true,
      handler() {
        if (this.autoRefresh) {
          this.$nextTick(() => this.refresh())
        }
      }
    },
    path: {
      handler() {
        this.refresh()
      }
    }
  },
  methods: {
    refresh() {
      if (this.keyValuePairs) {
        const keysArray = Object.keys(this.keyValuePairs)
        let previewKeys = ''
        keysArray.forEach(key => {
          if (this.keyValuePairs[key]) {
            previewKeys +=
              '&' + key + '=' + encodeURIComponent(this.keyValuePairs[key])
          }
        })
        this.previewLink =
          this.redirectLink + '/' + this.path + '?' + previewKeys
      }
    }
  }
}
</script>

<style scoped></style>
