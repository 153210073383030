<template>
  <div class="relative">
    <Superselect
      v-show="!bulkPasteEnabled"
      :title="title"
      v-model="localValue"
      :modifiers="modifiers"
      :multiple="multiple"
      :searchable="true"
      :internal-search="true"
      :clear-on-select="true"
      :close-on-select="closeOnSelect"
      :options-limit="optionsLimit"
      :allow-empty="allowEmpty"
      :limit="limit"
      :limit-text="count => `and ${count} more`"
      :show-no-results="true"
      :hide-selected="hideSelected"
      @search-change="filterTags"
      @select="onSelect"
      @remove="onRemove"
      :options="options"
      :loading="
        $apollo.queries.tags.loading || $apollo.queries.elasticValueSet.loading
      "
    >
      <template v-slot:beforeControlIcons v-if="allowBulkPaste">
        <ToggleLink
          v-show="!isPasting"
          v-model="bulkPasteEnabled"
          @input="bulkPasteEnabledUpdated"
          class="control-icon-btn py-1 px-px cursor-pointer block"
        >
          <Icon
            v-if="!$apollo.queries.tags.loading"
            name="pencil"
            :class="[
              'h-4 w-4',
              bulkPasteEnabled
                ? 'text-blue-500 hover:text-blue-600'
                : 'text-gray-400 hover:text-gray-500'
            ]"
          />
        </ToggleLink>
        <a
          v-show="!isPasting"
          @click="() => (localValue = [])"
          class="control-icon-btn py-1 px-px cursor-pointer block text-gray-400 hover:text-red-500"
        >
          <Icon
            v-if="!$apollo.queries.tags.loading"
            name="trashCan"
            :class="['h-4 w-4']"
          />
        </a>
        <Spinner v-show="isPasting" class="my-1 mx-px" color="blue" />
      </template>
      <template slot="option" slot-scope="{ option }">
        <div class="flex justify-between items-center">
          <Tag :color="isNot ? 'red' : 'blue'">
            {{ option }}
          </Tag>
        </div>
      </template>
      <template v-slot:tag="props">
        <Tag
          style="margin: 1px;"
          :color="isNot ? 'red' : 'blue'"
          class="inline-flex"
        >
          <template v-slot:icon="iconProps" v-if="enableNotToggles">
            <button
              @click="toggleIsNot()"
              :class="
                `hover:bg-${iconProps.color}-200 text-${iconProps.color}-${
                  iconProps.color == 'red' ? '600' : '400'
                } hover:text-${iconProps.color}-${
                  iconProps.color == 'red' ? '800' : '600'
                } -mr-3 rounded-full relative block outline-none-important`
              "
              style="padding-left: 0.35rem; padding-right:0.35rem;"
            >
              <Icon name="exclamationThick" class="w-3 h-3" />
            </button>
          </template>
          {{ props.option }}
          <template v-slot:afterIcon="afterIconProps">
            <button
              @click="props.remove(props.option)"
              :class="
                `hover:bg-${afterIconProps.color}-300 -ml-2 rounded-full outline-none-important`
              "
              style="padding-left: 0.35rem; padding-right:0.35rem;"
            >
              <Icon name="closeThick" class="w-3 h-3 block" />
            </button>
          </template>
        </Tag>
      </template>
    </Superselect>
    <YInput
      ref="pasteInput"
      :label="title"
      v-show="bulkPasteEnabled"
      :placeholder="pasteInputPlaceholder"
      value=""
      @paste="tagsPasted"
      @keypress="
        event => {
          event.preventDefault()
        }
      "
      @blur="
        () => {
          bulkPasteEnabled = false
        }
      "
    ></YInput>
  </div>
</template>

<script>
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import ELASTIC_VALUE_SET_QUERY from '@/graphql/ElasticSearch/ElasticValueSetQuery.gql'
import ToggleLink from '@/components/legacy/ToggleLink'

export default {
  components: { ToggleLink },
  apollo: {
    tags: {
      query: TAGS_QUERY,
      variables() {
        return {
          first: this.perPage,
          filters: {
            name: this.tagsFilter,
            type: { value: [this.type] }
          }
        }
      },
      skip() {
        return this.elasticOptionsField ? true : false
      },
      result({ data }) {
        this.options = data.tags.data.map(tag => tag.name)
      }
    },
    elasticValueSet: {
      query: ELASTIC_VALUE_SET_QUERY,
      variables() {
        return {
          input: {
            field: this.elasticOptionsField,
            limit: this.perPage,
            search: this.elasticSearch
              ? this.tagOptionsFilterValue ?? undefined
              : undefined,
            normalizer: this.elasticNormalizer ?? undefined
          }
        }
      },
      skip() {
        return this.elasticOptionsField ? false : true
      },
      result({ data }) {
        let options = data?.elasticValueSet?.values ?? []
        this.options = [...options]
      }
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Object
    },
    modifiers: {
      type: Object,
      default: () => ({})
    },
    perPage: {
      type: Number,
      default: 20
    },
    elasticSearch: {
      type: Boolean,
      default: false
    },
    elasticNormalizer: {
      type: String
    },
    elasticOptionsField: {
      type: String
    },
    enableNotToggles: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    closeOnSelect: {
      type: Boolean,
      default: false
    },
    optionsLimit: {
      type: Number,
      default: 300
    },
    type: {
      type: String,
      required: true
    },
    defaultIsNot: {
      type: Boolean,
      default: true
    },
    showOptionsTypeLabel: {
      type: Boolean,
      default: true
    },
    hideSelected: {
      type: Boolean,
      default: true
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    allowBulkPaste: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      isPasting: false,
      bulkPasteEnabled: false,
      pasteInputPlaceholder: 'Paste Tags',
      isNot: this.value?.isNot ?? false,
      localValue: this.value?.tags ?? [],
      tagOptionsFilterValue: null,
      options: []
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          JSON.stringify({ isNot: this.isNot, tags: this.localValue }) !=
          JSON.stringify(newValue)
        ) {
          this.isNot = newValue?.isNot ?? false
          this.localValue = newValue?.tags ?? []
        }
      }
    },
    localValue: {
      deep: true,
      handler(newValue) {
        this.$emit('input', { isNot: this.isNot, tags: newValue })
      }
    },
    isNot: {
      handler(newValue) {
        this.$emit('input', { isNot: newValue, tags: this.localValue })
      }
    }
  },
  mounted() {},
  methods: {
    bulkPasteEnabledUpdated(value) {
      if (value == true) {
        this.$nextTick(() => {
          this.$refs.pasteInput.focusInput()
        })
      }
    },
    tagsPasted(event) {
      event.stopPropagation()
      event.preventDefault()
      let pastedData = event.clipboardData.getData('Text')
      let pastedArray = pastedData.match(/[^\r\n]+/g)
      this.localValue = Array.from(
        new Set([...this.localValue, ...pastedArray])
      )
      this.bulkPasteEnabled = false
    },
    onSelect(value) {
      //this.localValue.push(value)
      this.$emit('select', value)
    },
    onRemove(value) {
      this.$emit('remove', value)
    },
    filterTags(value) {
      this.tagOptionsFilterValue = value
    },
    toggleIsNot() {
      this.isNot = !this.isNot
    }
  },
  computed: {
    tagsFilter() {
      if (this.tagOptionsFilterValue) {
        return {
          value: this.tagOptionsFilterValue,
          modifiers: { matchType: 'contains' }
        }
      } else {
        return undefined
      }
    }
  }
}
</script>

<style></style>
