<template>
  <widget>
    <widget-header @close="$emit('close')">
      Add Template Image
    </widget-header>
    <widget-body>
      <form class="row" ref="form">
        <div class="row flex items-center mb-3">
          <div :class="style.columnTitle">
            Name
          </div>
          <div class="col w-2/3">
            <YInput label="Name" v-model="imageName" :required="true"></YInput>
          </div>
        </div>
        <div class="row flex items-center mb-3">
          <div :class="style.columnTitle">
            Choose Image
          </div>
          <div class="col">
            <ImageInput
              class="rounded"
              :imageUrl="null"
              :thumbnailImageUrl="null"
              :height="32"
              :width="32"
              :blankHeight="20"
              :blankWidth="20"
              :editable="true"
              :editable-only-when-empty="false"
              :file.sync="imageFile"
              :key="isSubmitting"
            ></ImageInput>
          </div>
        </div>
        <div class="row flex items-center mb-3">
          <div :class="style.columnTitle">
            Tags
          </div>
          <div class="col w-2/3">
            <SuperSelect
              title="Tags"
              :options="tags || []"
              v-model="tagSelection"
              :settings="{ edit: false }"
              editPlaceholder="Add New Tag"
            ></SuperSelect>
          </div>
        </div>
      </form>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <YButton
        class="mr-8 border-2 border-blue-600 text-blue-600 hover:bg-blue-100"
        :disabled="submitIsDisabled"
        :is-loading="isSubmitting"
        @click="createTemplateImage('addMore')"
      >
        Add More
      </YButton>

      <YButton
        color="blue"
        :disabled="submitIsDisabled"
        @click="createTemplateImage('close')"
        :is-loading="isSubmitting"
        >Add Image
      </YButton>
    </widget-footer>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import ImageInput from '@/components/utilities/ImageInput'
import SuperSelect from '@/components/ui/Superselect.vue'
import CREATE_TEMPLATE_IMAGE from '@/graphql/TemplateImages/CreateTemplateImage.gql'
import SYNC_TAGS_WITH_TYPE_MUTATION from '@/graphql/Tag/SyncTagsWithTypeMutation.gql'

import gql from 'graphql-tag'

export default {
  mixins: [ValidationErrorsMixin],
  components: {
    ImageInput,
    SuperSelect
  },
  data() {
    return {
      imageName: null,
      imageFile: null,
      tagSelection: [],
      style: {
        columnTitle: 'col w-1/3 px-4 my-3 font-bold text-gray-700'
      },
      isSubmitting: false
    }
  },
  mounted() {},
  updated() {},
  apollo: {
    tags: {
      query: gql`
        query Tags(
          $first: Int!
          $page: Int
          $filters: TagFiltersInput
          $orderBy: [OrderByClause!]
        ) {
          tags(
            first: $first
            page: $page
            filters: $filters
            orderBy: $orderBy
          ) {
            data {
              id
              name
              type
            }
          }
        }
      `,
      variables: {
        first: 100,
        filters: {
          type: {
            value: ['vertical']
          }
        }
      },
      result(result) {
        let output = result.data.tags.data.map(tag => {
          return tag.name
        })

        this.tags = output
      }
    }
  },

  computed: {
    submitIsDisabled() {
      return !this.imageName || !this.imageFile
    },
    selectedTags: {
      get() {
        return this.$store.state.tags
      },
      set(value) {
        let addTag = value[0]
        this.tagSelection.push(addTag)
      }
    }
  },
  methods: {
    createTemplateImage(value) {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: CREATE_TEMPLATE_IMAGE,
          variables: {
            input: {
              name: this.imageName,
              file: this.imageFile
            }
          }
        })
        .then(data => {
          if (value === 'close') {
            this.imageID = data.data.createTemplateImage.id
            this.addTags()
            this.isSubmitting = false
            this.$events.emit('refreshTemplateImages')
            this.close()
          }
          if (value === 'addMore') {
            this.imageID = data.data.createTemplateImage.id
            this.addTags()
            this.isSubmitting = false
            this.$events.emit('refreshTemplateImages')
            this.imageName = null
            this.imageFile = null
            this.tagSelection = []
          }
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
          console.log('ERROR', error)
        })
    },
    close() {
      this.clearValidationErrors()
      this.$emit('close')
    },
    addTags() {
      if (this.tags.length > 0) {
        this.$apollo.mutate({
          mutation: SYNC_TAGS_WITH_TYPE_MUTATION,
          variables: {
            input: {
              taggableId: this.imageID,
              taggableType: 'templateImage',
              type: 'templateImage',
              tags: this.tagSelection
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped></style>
