<template>
  <div v-if="data && data.lastPage > 1" :class="css.wrapperClass">
    <div role="group" aria-label="Pagination" class="btn-group">
      <button
        v-if="!hideFirstLast"
        @click="loadPage(1)"
        type="button"
        :class="['', css.linkClass, isOnFirstPage ? css.disabledClass : '']"
      >
        <Icon name="skipPrevious" :size="4" />
      </button>
      <button
        v-if="!hideNextPrevious"
        @click="loadPage(data.currentPage - 1)"
        type="button"
        :class="['', css.linkClass, isOnFirstPage ? css.disabledClass : '']"
      >
        <Icon name="chevronLeft" :size="4" />
      </button>
      <template v-if="!hidePageNumbers">
        <template v-if="notEnoughPages">
          <template v-for="n in totalPage">
            <button
              :key="n"
              @click="loadPage(n)"
              type="button"
              :class="[
                '',
                css.pageClass,
                isCurrentPage(n) ? css.activeClass : ''
              ]"
              v-html="n"
            ></button>
          </template>
        </template>
        <template v-else>
          <template v-for="n in windowSize">
            <button
              :key="n"
              @click="loadPage(windowStart + n - 1)"
              type="button"
              :class="[
                '',
                css.pageClass,
                isCurrentPage(windowStart + n - 1) ? css.activeClass : ''
              ]"
              v-html="windowStart + n - 1"
            ></button>
          </template>
        </template>
      </template>
      <button
        v-if="!hideNextPrevious"
        @click="loadPage(data.currentPage + 1)"
        type="button"
        :class="['', css.linkClass, isOnLastPage ? css.disabledClass : '']"
      >
        <Icon name="chevronRight" :size="4" />
      </button>
      <button
        v-if="!hideFirstLast"
        @click="loadPage(totalPage)"
        :class="['', css.linkClass, isOnLastPage ? css.disabledClass : '']"
      >
        <Icon name="skipNext" :size="4" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    },
    css: {
      type: Object,
      default() {
        return {
          wrapperClass: 'y-pagination pagination',
          activeClass: 'active',
          disabledClass: 'disabled',
          pageClass: 'btn-pagination-page',
          linkClass: 'btn-pagination-link',
          paginationClass: '',
          paginationInfoClass: 'left floated left aligned six wide column',
          dropdownClass: 'ui search dropdown',
          icons: {
            first: 'zmdi zmdi-skip-previous',
            prev: 'zmdi zmdi-chevron-left',
            next: 'zmdi zmdi-chevron-right',
            last: 'zmdi zmdi-skip-next'
          }
        }
      }
    },
    onEachSide: {
      type: Number,
      default() {
        return 2
      }
    },
    hideNextPrevious: {
      type: Boolean,
      default: false
    },
    hideFirstLast: {
      type: Boolean,
      default: false
    },
    hidePageNumbers: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    totalPage() {
      return this.data === null ? 0 : this.data.lastPage
    },
    isOnFirstPage() {
      return this.data === null ? false : this.data.currentPage === 1
    },
    isOnLastPage() {
      return this.data === null
        ? false
        : this.data.currentPage === this.data.lastPage
    },
    notEnoughPages() {
      return this.totalPage < this.onEachSide * 2 + 4
    },
    windowSize() {
      return this.onEachSide * 2 + 1
    },
    windowStart() {
      if (!this.data || this.data.currentPage <= this.onEachSide) {
        return 1
      } else if (this.data.currentPage >= this.totalPage - this.onEachSide) {
        return this.totalPage - this.onEachSide * 2
      }

      return this.data.currentPage - this.onEachSide
    }
  },
  mounted() {},
  updated() {},
  methods: {
    loadPage(page) {
      this.$emit('change-page', page)
    },
    isCurrentPage(page) {
      return page === this.data.currentPage
    },
    setPaginationData(data) {
      this.data = data
    },
    resetData() {
      this.data = null
    }
  }
}
</script>
<style scoped>
.y-pagination.pagination {
  float: none;
}
</style>
