var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "x-top-manager-widget overflow-x-hidden h-full"
  }, [_c('WidgetHeader', [_vm._v(" Top Campaigns "), _c('router-link', {
    staticClass: "link text-xs",
    attrs: {
      "to": {
        name: 'Publisher Campaigns'
      }
    }
  }, [_vm._v(" VIEW CAMPAIGNS ")])], 1), _c('WidgetBody', {
    staticClass: "pt-0 pb-0 relative",
    attrs: {
      "scrollable": true
    }
  }, [_c('div', {
    staticClass: "h-full w-full relative"
  }, [_vm.$apollo.queries.topCampaigns.loading ? _c('div', {
    staticClass: "h-full w-full flex items-center"
  }, [_c('Spinner', {
    attrs: {
      "size": 10,
      "color": "green"
    }
  })], 1) : _c('div', {
    staticClass: "x-campaigns"
  }, _vm._l(_vm.topCampaigns, function (campaign) {
    return _c('div', {
      key: campaign.id,
      staticClass: "py-2 x-campaign px-4"
    }, [_c('router-link', {
      staticClass: "media",
      attrs: {
        "to": {
          name: 'Publisher Campaign',
          params: {
            id: campaign.id
          }
        }
      }
    }, [_c('div', [_c('span', {
      staticClass: "link-wrap"
    }, [_vm._v(_vm._s(campaign.name))]), _c('div', {
      staticClass: "flex items-center py-2"
    }, [_c('div', {
      staticClass: "flex-shrink"
    }, [_c('small', {
      staticClass: "text-muted fw-500 pr-1"
    }, [_vm._v("Top Geos:")])]), _c('div', {
      staticClass: "flex-grow"
    }, _vm._l(campaign.topGeos, function (geo) {
      return _c('img', {
        directives: [{
          name: "tooltip",
          rawName: "v-tooltip",
          value: geo.code,
          expression: "geo.code"
        }],
        key: geo.id,
        staticClass: "inline",
        staticStyle: {
          "margin": "2px"
        },
        attrs: {
          "src": _vm.getFlag(geo.code),
          "width": "16"
        }
      });
    }), 0)])])])], 1);
  }), 0)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }