<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <template slot="rawContent">
        <div
          class="flex justify-between items-center w-100 text-sm text-gray-800 font-extrabold"
        >
          <div class="">
            Add IPs to {{ `${server.provider.name} - ${server.id}` }} ({{
              server.provider.type.capitalize()
            }})
          </div>
        </div>
      </template>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <div class="mb-3">
        <YFormGroup
          :error-strings="this.tooManyCidrIps ? [] : null"
          class="mb-0"
        >
          <div class="font-semibold  my-2">
            <y-radio
              label="IP CIDR"
              name="add-ips-mode"
              radio-value="cidr"
              v-model="addIpsMode"
              color="info"
            >
            </y-radio>
          </div>
          <YInput
            v-model="ipCidrInput"
            v-if="addIpsMode == 'cidr'"
            label="IP CIDR"
            class="form-control ml-4 mb-2"
            placeholder="eg. 50.165.190.0/23"
          />
        </YFormGroup>
        <div v-if="addIpsMode == 'cidr'" class="ml-4">
          <y-alert v-if="ipCidr" type="info" :allow-close="false" class="mb-2">
            {{ ipCidr.range[0] }} - {{ ipCidr.range[1] }}
            <span
              :class="tooManyCidrIps ? ['text-danger'] : ['text-success']"
              v-text="
                tooManyCidrIps
                  ? '(Too Many IPs)'
                  : '(' + ipCidr.array.length + ' IPs)'
              "
            >
            </span>
          </y-alert>
          <y-checkbox
            label="Add as disabled"
            type="danger"
            v-model="addAsDisabled"
          ></y-checkbox>
        </div>
      </div>
      <YFormGroup>
        <div class="font-semibold  my-2">
          <y-radio
            label="Individual IPs"
            name="add-ips-mode"
            radio-value="singles"
            v-model="addIpsMode"
            color="info"
          >
          </y-radio>
        </div>
        <Superselect
          v-if="addIpsMode == 'singles'"
          title="IPs"
          v-model="individualIpsInput"
          :hide-label="true"
          class="ml-4 mt-1"
          :multiple="true"
          edit-placeholder=""
          :forceEdit="true"
        >
          <template v-slot:footer>
            <YCheckbox
              class="mt-1"
              label="Add as disabled"
              type="info"
              v-model="addAsDisabled"
            ></YCheckbox>
          </template>
        </Superselect>
      </YFormGroup>
      <YCheckbox
        class="py-3"
        label="Check Spamhaus Daily"
        v-model="checkSpamhausDaily"
      />
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <y-checkbox
        v-model="closeOnComplete"
        label="Close on Complete"
      ></y-checkbox>
      <YButton
        :is-loading="isSubmitting"
        color="blue"
        @click="create"
        @keydown.enter="create"
        :disabled="!canAdd ? true : false"
        >Add</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import yRadio from '../yRadio'
import IPCIDR from 'ip-cidr'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import MUTATE_SPAMHAUS_CHECK_MUTATION from '@/graphql/Domain/MutateSpamhausCheckMutation.gql'

export default {
  props: {
    server: {
      type: Object,
      required: true
    }
  },
  mixins: [ValidationErrorsMixin],
  components: {
    yRadio
  },
  apollo: {
    typeOptions: {
      query: gql`
        query {
          typeOptions: __type(name: "YdnsDomainTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.typeOptions = data.typeOptions.enumValues.map(enumValue => ({
          id: enumValue.name,
          label: enumValue.name.capitalize()
        }))
        this.ipType = this.typeOptions[0]
      }
    }
  },
  data() {
    return {
      ipType: null,
      typeOptions: [],
      closeOnComplete: true,
      isSubmitting: false,
      ipCidrInput: '',
      individualIpsInput: [],
      addIpsMode: 'cidr',
      individualIpOptions: [],
      addAsDisabled: this.server.type == 'redirect' ? true : false,
      checkSpamhausDaily: false
    }
  },
  watch: {},
  computed: {
    ipCidr() {
      let cidr = new IPCIDR(this.ipCidrInput)

      if (!cidr.isValid()) {
        return null
      }
      return {
        cidr: this.ipCidrInput,
        range: cidr.toRange(),
        array: cidr.toArray({
          from: 0,
          limit: 512
        })
      }
    },
    tooManyCidrIps() {
      if (this.ipCidr) {
        // ipCidr array limited to 512 results... check if there are more than 512
        let lastFromArray = this.ipCidr.array[this.ipCidr.array.length - 1]
        let lastFromRange = this.ipCidr.range[1]
        return lastFromArray != lastFromRange
      }
      return false
    },
    ips() {
      if (this.addIpsMode == 'cidr' && this.ipCidr && !this.tooManyCidrIps) {
        return this.ipCidr.array
      } else if (this.addIpsMode == 'singles') {
        return this.individualIpsInput.map(ip => ip)
      }
      return null
    },
    canAdd() {
      return !this.tooManyCidrIps && this.ips && this.ips.length > 0
    }
  },
  methods: {
    create() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation YdnsUpdateServers($input: [YdnsUpdateServerInput!]!) {
              ydnsUpdateServers(input: $input) {
                id
                provider {
                  id
                  name
                }
                ips {
                  id
                  version
                  enabled
                }
              }
            }
          `,
          variables: {
            input: [
              {
                id: this.server.id,
                ips: {
                  create: this.ips.map(ip => ({
                    id: ip,
                    enabled: this.addAsDisabled ? false : true
                  }))
                }
              }
            ]
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('IPs Added', 'Success!')
          this.$events.emit('refreshYdnsServers')
          if (this.checkSpamhausDaily) {
            this.ips.forEach(ip => this.mutateSpamhausCheck(ip))
          }
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
        })
    }
  },
  mutateSpamhausCheck(id) {
    this.$apollo
      .mutate({
        mutation: MUTATE_SPAMHAUS_CHECK_MUTATION,
        variables: {
          input: {
            model: 'Ip',
            checkId: id,
            method: 'create'
          }
        }
      })
      .then(() => {
        this.clearValidationErrors()
        this.$toastr.s('Automatic Spamhaus Check Enabled', 'Success!')
      })
      .catch(error => {
        this.setValidationErrors(error)
      })
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
