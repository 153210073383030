var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [[_c('div', {
    staticClass: "flex"
  }, [_c('YInput', {
    attrs: {
      "title": "Default Scrub",
      "label": "Default Scrub",
      "required": true
    },
    model: {
      value: _vm.campaignScrub,
      callback: function ($$v) {
        _vm.campaignScrub = $$v;
      },
      expression: "campaignScrub"
    }
  }), _c('YButton', {
    staticClass: "ml-2 h-12",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isUpserting
    },
    on: {
      "click": function ($event) {
        return _vm.upsertCampaignScrub(_vm.campaignScrub);
      }
    }
  }, [_vm._v("Set")]), _c('YButton', {
    staticClass: "ml-2 h-12",
    attrs: {
      "color": "red",
      "is-loading": _vm.isUpserting
    },
    on: {
      "click": function ($event) {
        return _vm.deleteCampaignScrub();
      }
    }
  }, [_vm._v("Clear")])], 1)], _vm.redirects ? _c('YTable', {
    staticClass: "table-striped table-hover overflow-hidden",
    attrs: {
      "data": _vm.redirects.data
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('span', [_vm._v(_vm._s(props.rowData.label))]), _c('span', {
          staticClass: "text-xs italic text-gray-700"
        }, [_vm._v(_vm._s(props.rowData.user.label))])])]), _c('td', [_vm._v(_vm._s(props.rowData.aSPercentage) + "%")]), _c('td'), _c('td', [_c('div', {
          staticClass: "text-right"
        }, [_c('a', {
          class: 'text-red-600 hover:text-red-700',
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              return _vm.deleteRedirectScrub(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          staticClass: "inline",
          attrs: {
            "name": "trashCan",
            "size": 4
          }
        })], 1)])])];
      }
    }], null, false, 3039433175)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Redirect")]), _c('th', [_vm._v("Scrub Percentage")]), _c('th'), _c('th')]), _c('template', {
    slot: "secondHeaderRow"
  }, [_c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "required": true,
      "title": "Redirect",
      "track-by": "id",
      "label": "label",
      "query": _vm.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        first: 100,
        filters: {
          campaignId: {
            value: [_vm.campaignId]
          },
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.redirectOptions,
      callback: function ($$v) {
        _vm.redirectOptions = $$v;
      },
      expression: "redirectOptions"
    }
  })], 1), _c('td', [_c('YInput', {
    staticClass: "-mx-3",
    attrs: {
      "label": "Value",
      "required": true
    },
    model: {
      value: _vm.triggerValue,
      callback: function ($$v) {
        _vm.triggerValue = $$v;
      },
      expression: "triggerValue"
    }
  })], 1), _c('td', [_c('div', {
    staticClass: "flex items-center -ml-3 -mr-3"
  }, [_c('YButton', {
    staticClass: "px-3",
    attrs: {
      "color": "link",
      "is-loading": _vm.isUpserting
    },
    on: {
      "click": function ($event) {
        return _vm.upsertRedirectScrub(_vm.redirectOptions, _vm.triggerValue);
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "plus"
    }
  })], 1)], 1)]), _c('td', [_vm.$apollo.queries.redirects.loading ? _c('Spinner', {
    attrs: {
      "color": "blue",
      "size": 6
    }
  }) : _vm._e()], 1)])], 2) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }