<template>
  <div v-if="publisherCampaigns">
    <template>
      <div
        v-for="(publisherCampaign, index) in publisherCampaigns"
        :key="publisherCampaign.id"
      >
        <Widget class="mb-2">
          <WidgetBody>
            <CampaignPreview
              v-model="publisherCampaigns[index]"
              :editable="editable"
              :publisherOptions="publisherOptions"
            ></CampaignPreview>
          </WidgetBody>
        </Widget>
      </div>
    </template>
  </div>
</template>

<script>
import CampaignPreview from './CampaignPreview'

export default {
  components: {
    CampaignPreview
  },
  apollo: {},
  props: {
    value: {
      type: Array,
      default: () => []
    },
    editable: {
      type: Boolean,
      default: false
    },
    publisherOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      publisherCampaigns: this._.cloneDeep(this.value)
    }
  },
  computed: {},
  watch: {
    value: {
      handler: function(newValue) {
        this.campaigns = this._.cloneDeep(newValue)
      },
      deep: true
    },
    publisherCampaigns: {
      handler: function(newValue) {
        if (!this._.isEqual(newValue, this.value)) {
          this.$emit('input', this._.cloneDeep(this.publisherCampaigns))
        }
      },
      deep: true
    }
  },
  mounted() {},
  updated() {},
  methods: {}
}
</script>
<style scoped></style>
