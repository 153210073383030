var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "bg-red-600 text-white border-red-700"
  }, [_c('WidgetHeader', {
    staticClass: "bg-red-600 text-white"
  }, [_c('span', {
    staticClass: "text-white"
  }, [_vm._v("Are you sure you want to delete " + _vm._s(_vm.selectedRowIds.length) + " rule"), _vm.selectedRowIds.length > 1 ? _c('span', [_vm._v("s")]) : _vm._e(), _vm._v("?")])]), _c('WidgetBody', [_c('h5', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v(" This action cannot be undone. ")])]), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 flex justify-end gap-6 py-2 px-4"
  }, [_c('YButton', {
    staticClass: "border hover:bg-red-500 focus:bg-red-700",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    staticClass: "bg-red-700 hover:bg-red-800 border focus:bg-red-700",
    on: {
      "click": _vm.deleteRules
    }
  }, [_vm._v("Delete")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }