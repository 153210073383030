<template>
  <div>
    <YTable
      v-if="caps"
      class="table-striped table-hover overflow-hidden"
      block-style="min-height: 25rem;"
      :scroll-x="false"
      :data="caps.data"
    >
      <template slot="header-row">
        <th>
          Frequency
        </th>
        <th>
          Type
        </th>
        <th>
          Redirect
        </th>
        <th>
          Source
        </th>
        <th>
          Value
        </th>
        <th></th>
        <th>
          <v-popover
            class="flex items-center rounded cursor-pointer -ml-3 -mr-3"
          >
            <div class="flex items-center hover:text-blue-500">
              <YButton class="px-3"><Icon name="eye"/></YButton>
            </div>
            <!-- This will be the content of the popover -->
            <template slot="popover">
              <div class="flex flex-col">
                <YButton
                  @click="showActiveCaps"
                  class="rounded-b-none"
                  :color="trashedInput === 'WITHOUT' ? 'red' : 'link'"
                  >Show Active</YButton
                >
                <YButton
                  @click="showDeletedCaps"
                  class="rounded-none"
                  :color="trashedInput === 'ONLY' ? 'red' : 'link'"
                  >Show Deleted</YButton
                >
                <YButton
                  @click="showAllCaps"
                  class="rounded-t-none"
                  :color="trashedInput === 'WITH' ? 'red' : 'link'"
                  >Show All</YButton
                >
              </div>
            </template>
          </v-popover>
        </th>
        <th v-if="cappableType === 'Campaign'"></th>
      </template>
      <template slot="secondHeaderRow">
        <td>
          <Superselect
            title="Frequency"
            v-model="frequency"
            :required="true"
            label="label"
            track-by="value"
            :options="frequency.length == 0 ? frequencyOptions : []"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Type"
            v-model="type"
            :required="true"
            :options="
              type.length > 0
                ? []
                : cappableType == 'Campaign'
                ? campaignTypeOptions
                : typeOptions
            "
            label="label"
            track-by="value"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Redirects"
            v-model="redirects"
            track-by="id"
            label="label"
            :disabled="sourceIds.length > 0"
            :query="REDIRECT_OPTIONS_QUERY"
            :query-variables="{
              first: 100,
              filters: {
                campaignId:
                  cappableType == 'Campaign'
                    ? {
                        value: [cappableId]
                      }
                    : undefined,
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Sources"
            v-model="sourceIds"
            :options="[]"
            :forceEdit="true"
            :disabled="redirects.length > 0"
            class="-mx-3"
          />
        </td>
        <td>
          <YInput
            label="Value"
            v-model="triggerValue"
            class="-mx-3"
            :required="true"
          />
        </td>
        <td>
          <div class="flex items-center -ml-3 -mr-3">
            <YButton
              @click="upsertCaps(upsertCapObjects)"
              color="link"
              class="px-3"
              :is-loading="isUpserting"
              ><Icon name="plus"
            /></YButton>
          </div>
        </td>
        <td>
          <Spinner v-if="$apollo.queries.caps.loading" color="blue" :size="6" />
        </td>
        <td v-if="cappableType === 'Campaign'"></td>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          {{ props.rowData.frequency.capitalize() }}
        </td>
        <td>
          {{ props.rowData.type.capitalize() }}
        </td>
        <td>
          <div class="flex flex-col">
            <span>
              {{
                props.rowData.triggerable &&
                props.rowData.triggerable.__typename === 'Redirect'
                  ? props.rowData.triggerable.label
                  : ''
              }}
            </span>
            <span class="text-xs italic text-gray-700">{{
              props.rowData.triggerable && props.rowData.triggerable.user
                ? props.rowData.triggerable.user.label
                : ''
            }}</span>
          </div>
        </td>
        <td>
          {{
            props.rowData.triggerable &&
            props.rowData.triggerable.__typename === 'Source'
              ? props.rowData.triggerable.id
              : ''
          }}
        </td>
        <td>
          <span class="-mr-1">
            {{
              props.rowData.type === 'revenue' ? '$' : ''
            }}
            <EditableValue
              :key="props.rowData.id"
              type="contentEditable"
              :isNumberValue="true"
              :value="props.rowData.currentValue"
              :mutation="UPDATE_CAP_MUTATION"
              :variables="{
                input: { id: props.rowData.id, currentValue: '{value}' }
              }"
              placeholder="0"
              class=""
              value-class="px-1"
              :edit-focus-icon-enabled="false"
            />
          </span>
          /<EditableValue
            :key="props.rowData.id"
            type="contentEditable"
            :isNumberValue="true"
            :value="props.rowData.triggerValue"
            :mutation="UPDATE_CAP_MUTATION"
            :variables="{
              input: { id: props.rowData.id, triggerValue: '{value}' }
            }"
            placeholder="0"
            value-class="px-1"
            :edit-focus-icon-enabled="false"
          />
          {{
            capPercentage(
              props.rowData.currentValue,
              props.rowData.triggerValue
            )
          }}
        </td>
        <td>
          <v-popover
            v-if="!props.rowData.deletedAt"
            v-tooltip="{
              content: props.rowData.isIgnored
                ? 'Paused'
                : !props.rowData.isCapped
                ? 'Uncapped'
                : props.rowData.cappedBy
                ? 'Manually Capped'
                : 'Capped',
              delay: { show: 1000 }
            }"
            class="inline-block hover:bg-blue-200 rounded cursor-pointer"
          >
            <div class="flex items-center">
              <span
                class="h-2 w-2 m-2 rounded-full"
                :class="
                  props.rowData.isIgnored
                    ? 'bg-gray-500'
                    : !props.rowData.isCapped
                    ? 'bg-green-500'
                    : props.rowData.cappedBy
                    ? 'bg-yellow-500'
                    : 'bg-red-500'
                "
              >
              </span>
            </div>

            <!-- This will be the content of the popover -->
            <template slot="popover">
              <div class="flex flex-col">
                <YButton
                  v-if="!props.rowData.isIgnored && !props.rowData.isCapped"
                  @click="toggleManualCap(props.rowData)"
                  color="link"
                  >Cap</YButton
                >
                <YButton
                  v-if="!props.rowData.isIgnored && props.rowData.cappedBy"
                  @click="toggleManualCap(props.rowData)"
                  color="link"
                  >Uncap</YButton
                >
                <YButton @click="togglePauseCap(props.rowData)" color="link">{{
                  !props.rowData.isIgnored ? 'Pause' : 'Unpause'
                }}</YButton>
              </div>
            </template>
          </v-popover>
          <a
            v-else
            class="flex items-center text-gray-500 hover:text-gray-900 rounded cursor-pointer"
            @click.prevent="restoreCap(props.rowData.id)"
          >
            <Icon name="restore" />
          </a>
        </td>
        <td v-if="cappableType === 'Campaign'">
          <a
            class="link"
            @click.prevent="
              openEditFallbackModal(
                props.rowData.triggerable.id,
                props.rowData.triggerable.fallbackCampaignId,
                props.rowData.triggerable.fallbackOfferId
              )
            "
          >
            <Icon
              :class="
                props.rowData.triggerable &&
                props.rowData.triggerable.fallbackCampaignId
                  ? 'text-blue-400 hover:text-blue-500'
                  : 'text-gray-400 hover:text-blue-400'
              "
              name="shield"
            />
          </a>
        </td>
        <td>
          <a
            v-if="!props.rowData.deletedAt"
            class="text-gray-400 hover:text-red-500 cursor-pointer"
            @click.prevent="deleteCap(props.rowData.id)"
          >
            <Icon name="trashCan" />
          </a>
        </td>
      </template>
    </YTable>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import CAPS_QUERY from '@/graphql/Caps/CapsQuery.gql'
import Superselect from '../../../components/ui/Superselect.vue'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import UPSERT_CAPS_MUTATION from '@/graphql/Caps/UpsertCapsMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import DELETE_CAP_MUTATION from '@/graphql/Caps/DeleteCapMutation.gql'
import UPDATE_CAP_MUTATION from '@/graphql/Caps/UpdateCapMutation.gql'
import RESTORE_CAP_MUTATION from '@/graphql/Caps/RestoreCapMutation.gql'
import UpdateCampaignFallbackModal from '@/views/Admin/Campaign/UpdateCampaignFallbackModal'

export default {
  mixins: [ValidationErrorsMixin],
  components: { Superselect },
  props: {
    cappableId: {
      type: String
    },
    cappableType: {
      type: String
    },
    fallbackCampaignId: {
      type: String
    }
  },
  apollo: {
    caps: {
      query: CAPS_QUERY,
      variables() {
        return {
          filters: {
            cappableId: { value: [this.cappableId] },
            cappableType: this.cappableType,
            frequency:
              this.frequency.length != 0
                ? { value: this.frequency[0].value }
                : undefined,
            type:
              this.type.length != 0 ? { value: this.type[0].value } : undefined,
            triggerableId: this.cleanObjectFilter(this.redirects, 'id')
          },
          first: 300,
          trashed: this.trashedInput,
          orderBy: [
            {
              column: 'capped_at',
              order: 'DESC'
            },
            {
              column: 'capped_by',
              order: 'ASC'
            },
            {
              column: 'ignored_at',
              order: 'ASC'
            }
          ]
        }
      }
    }
  },
  data() {
    return {
      UPDATE_CAP_MUTATION,
      REDIRECT_OPTIONS_QUERY,
      isUpserting: false,
      frequencyOptions: [
        {
          value: 'daily',
          label: 'Daily'
        },
        {
          value: 'monthly',
          label: 'Monthly'
        },
        {
          value: 'total',
          label: 'Total',
        }
      ],
      typeOptions: [
        {
          value: 'actions',
          label: 'Actions'
        },
        {
          value: 'revenue',
          label: 'Revenue'
        }
      ],
      campaignTypeOptions: [
        {
          value: 'publisherActions',
          label: 'Publisher Actions'
        },
        {
          value: 'payout',
          label: 'Payout'
        }
      ],
      frequency: [],
      type: [],
      redirects: [],
      sourceIds: [],
      triggerValue: null,
      trashedInput: 'WITHOUT'
    }
  },
  mounted() {
    this.$events.listen('capDeleted', () => {
      this.$apollo.queries.caps.refetch()
    })
    this.$events.listen('capUpserted', () => {
      this.$apollo.queries.caps.refetch()
    })
    this.$events.listen('capDefaultCreated', () => {
      this.$apollo.queries.caps.refetch()
    })
    this.$events.listen('capDefaultUpdated', () => {
      this.$apollo.queries.caps.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('CapDefaultCreated')
    this.$events.remove('CapDefaultUpdated')
    this.$events.remove('capDeleted')
    this.$events.remove('capUpserted')
  },
  computed: {
    redirectIds() {
      return this.redirects.map(redirect => redirect.id)
    },
    upsertCapKeys() {
      return this.getPermutations(
        this.getPermutations(
          this.getPermutations(
            this.getPermutations(
              [this.cappableId + '-' + this.cappableType],
              [this.frequency[0].value]
            ),
            [this.type[0].value]
          ),
          this.redirectIds.length > this.sourceIds.length
            ? this.redirectIds
            : this.sourceIds.length > 0
            ? this.sourceIds
            : ''
        ),
        this.redirectIds.length > this.sourceIds.length
          ? ['Redirect']
          : this.sourceIds.length > 0
          ? ['Source']
          : ['']
      )
    },
    upsertCapObjects() {
      let keyOrder = [
        'cappableId',
        'cappableType',
        'frequency',
        'type',
        'triggerableId',
        'triggerableType'
      ]
      return this.upsertCapKeys.map(key => {
        let values = key.split('-')
        let output = {}
        values.forEach((value, index) => {
          output[keyOrder[index]] = value !== '' ? value : null
        })
        output.triggerValue = Number(this.triggerValue)
        return output
      })
    }
  },
  methods: {
    openEditFallbackModal(id, fallbackCampaignId, fallbackOfferId) {
      this.$modal.show(
        UpdateCampaignFallbackModal,
        {
          id: id,
          fallbackCampaignId: fallbackCampaignId,
          fallbackOfferId: fallbackOfferId
        },
        {
          height: 'auto',
          width: '100%',
          maxWidth: 1000,
          adaptive: true,
          scrollable: true
        }
      )
    },
    showDeletedCaps() {
      this.trashedInput = 'ONLY'
    },
    showAllCaps() {
      this.trashedInput = 'WITH'
    },
    showActiveCaps() {
      this.trashedInput = 'WITHOUT'
    },
    toggleShowSoftDeletedCaps() {
      if (this.trashedInput === 'WITHOUT') {
        this.trashedInput = 'WITH'
      } else {
        this.trashedInput = 'WITHOUT'
      }

      this.$apollo.queries.caps.refetch()
    },
    cleanObjectFilter(filter, pluck) {
      if (filter.length === 0) {
        return undefined
      }
      console.log({
        value: filter.map(value => value[pluck]),
        modifiers: filter.modifiers
      })
      return {
        value: filter.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    capPercentage(currentValue, triggerValue) {
      return `(${((currentValue / triggerValue) * 100).toFixed(1)}%)`
    },
    getPermutations(array1, array2) {
      if (array2.length > 0) {
        var result = array1.reduce((a, v) => {
          return [...a, ...array2.map(x => v + '-' + x)]
        }, [])
        return result
      } else {
        return array1.map(v => v + '-')
      }
    },
    clearUpsertValues() {
      this.frequency = []
      this.type = []
      this.redirects = []
      this.sourceIds = []
      this.triggerValue = null
    },
    upsertCaps(input) {
      this.isUpserting = true

      this.$apollo
        .mutate({
          mutation: UPSERT_CAPS_MUTATION,
          variables: {
            input: input
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.clearUpsertValues()
          this.isUpserting = false
          this.$toastr.s('Offer Caps Updated', 'Success')
          this.$nextTick(() => {
            this.$events.emit('capUpserted')
          })
        })
        .catch(error => {
          this.isUpserting = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    deleteCap(id) {
      this.$apollo
        .mutate({
          mutation: DELETE_CAP_MUTATION,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.Add({
            title: 'Deleted',
            msg: 'Cap deleted',
            preventDuplicates: true
          })
          this.$events.emit('capDeleted')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    toggleManualCap(capData) {
      this.$apollo
        .mutate({
          mutation: UPDATE_CAP_MUTATION,
          variables: {
            input: {
              id: capData.id,
              isCapped: !capData.isCapped
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('Offer Cap Updated', 'Success')
          this.$events.emit('capUpserted')
          this.$events.emit('refreshCampaigns')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    togglePauseCap(capData) {
      this.$apollo
        .mutate({
          mutation: UPDATE_CAP_MUTATION,
          variables: {
            input: {
              id: capData.id,
              isIgnored: !capData.isIgnored
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('Offer Cap Paused', 'Success')
          this.$events.emit('capUpserted')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    restoreCap(id) {
      this.$apollo
        .mutate({
          mutation: RESTORE_CAP_MUTATION,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('Offer Cap Restored', 'Success')
          this.$events.emit('capUpserted')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    }
  }
}
</script>

<style scoped></style>
