var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 sm:grid-cols-3 gap-1 mb-1"
  }, [_c('YInput', {
    attrs: {
      "label": "Campaign"
    },
    model: {
      value: _vm.filters.campaign.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.campaign, "value", $$v);
      },
      expression: "filters.campaign.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Verticals",
      "modifiers": _vm.filters.verticals.modifiers,
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['vertical']
          },
          name: {
            value: ['general', 'nutra', 'sweepstakes', 'vod', 'casino', 'sports betting']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.verticals.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.verticals, "value", $$v);
      },
      expression: "filters.verticals.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Countries",
      "placeholder": "Select Countries",
      "track-by": "code",
      "label": "label",
      "options": [],
      "query": _vm.COUNTRIES_QUERY,
      "query-variables": {
        first: 300
      }
    },
    model: {
      value: _vm.filters.countries.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.countries, "value", $$v);
      },
      expression: "filters.countries.value"
    }
  })], 1), _c('div', {
    staticClass: "grid grid-cols-1 sm:grid-cols-3 gap-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Offer Type",
      "custom-label": _vm.capitalizeAllWords,
      "options": _vm.offerTypes,
      "multiple": false
    },
    model: {
      value: _vm.filters.offerType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.offerType, "value", $$v);
      },
      expression: "filters.offerType.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Status",
      "custom-label": _vm.capitalize,
      "options": _vm.statusOptions
    },
    model: {
      value: _vm.filters.status.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Payout Conversion Type",
      "placeholder": "Select One",
      "modifiers": _vm.filters.payoutConversionType.modifiers,
      "multiple": true,
      "track-by": "id",
      "label": "label",
      "query": _vm.CONVERSION_TYPE_OPTIONS_QUERY,
      "queryDefaultInputText": "",
      "queryVariables": {
        filters: {
          name: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          slug: {
            value: ['first-time-deposit', 'qualified-first-time-deposit', 'registration']
          }
        },
        first: 25
      },
      "query-result-function": function (data) {
        var options = data.conversionTypes.data.map(function (value) {
          return {
            id: value.slug,
            label: value.name
          };
        });
        _vm.option = [{
          id: null,
          label: 'Default'
        }].concat(options);
        return _vm.option;
      }
    },
    model: {
      value: _vm.filters.payoutConversionType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.payoutConversionType, "value", $$v);
      },
      expression: "filters.payoutConversionType.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Allowed Traffic Types",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['trafficType']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.allowed.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.allowed, "value", $$v);
      },
      expression: "filters.allowed.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Allowed OS Type",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['campaignOS']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.campaignOS.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.campaignOS, "value", $$v);
      },
      expression: "filters.campaignOS.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Allowed Browser Type",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['campaignBrowser']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.campaignBrowser.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.campaignBrowser, "value", $$v);
      },
      expression: "filters.campaignBrowser.value"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }