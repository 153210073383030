var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', [_c('WidgetHeader', {
    staticClass: "bg-gray-100",
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('YButton', {
          attrs: {
            "color": "blue",
            "is-loading": _vm.isLoading,
            "disabled": _vm.hasNoData
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submitPayouts.apply(null, arguments);
            }
          }
        }, [_vm._v("Save Upload")])];
      },
      proxy: true
    }])
  }, [_vm._v(" Upload Preview ")]), _c('WidgetBody', {
    staticClass: "widget-edge"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col table-scroll-container"
  }, [_c('vuetable', {
    ref: "vuetable",
    attrs: {
      "id": "reporting-table",
      "api-mode": false,
      "data": _vm.payoutsData,
      "pagination-path": "",
      "fields": _vm.fields,
      "css": _vm.css,
      "load-on-start": false,
      "cellspacing": "0",
      "width": "100%",
      "table-wrapper": "vuetable-wrapper",
      "append-params": _vm.appendParams
    },
    on: {
      "vuetable:load-error": _vm.onLoadError
    }
  })], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }