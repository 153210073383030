<template>
  <span>
    <template v-if="state == 'default'">
      <button
        v-if="editActionDisplayType == 'button'"
        class="btn btn-primary btn-sm"
        @click.prevent="$emit('edit')"
      >
        Edit
      </button>
      <a v-else class="pl-1 text-info" href="#" @click.prevent="$emit('edit')"
        ><i class="zmdi zmdi-edit"></i
      ></a>
    </template>
    <template v-if="state == 'edit'">
      <div class="editable-edit-buttons text-right ts-1 my-1">
        <YButton
          :disabled="submitting"
          class="bg-gray-100"
          @click.prevent="$emit('cancel')"
          >Cancel</YButton
        >
        <YButton
          :is-loading="submitting"
          class="btn-green"
          @click.prevent="$emit('submit')"
          >Submit</YButton
        >
      </div>
    </template>
  </span>
</template>

<script>
export default {
  components: {},
  props: {
    state: {
      type: String
    },
    submitting: {
      type: Boolean,
      default: false
    },
    editActionDisplayType: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},

  updated() {},

  methods: {}
}
</script>
<style scoped>
.editable-edit-buttons button {
  margin-left: 0.25rem;
}
.editable-edit-buttons button:first-child {
  margin-left: 0rem;
}
</style>
