var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', [_vm._v("Edit Postback")]), _c('WidgetBody', [_c('form', {
    ref: "form",
    staticClass: "row",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('Superselect', {
    staticClass: "flex-shrink",
    attrs: {
      "title": "Protocol",
      "multiple": false,
      "options": _vm.protocolOptions,
      "select-class": "rounded-r-none border-r-0"
    },
    model: {
      value: _vm.protocol,
      callback: function ($$v) {
        _vm.protocol = $$v;
      },
      expression: "protocol"
    }
  }), _c('YInput', {
    staticClass: "flex-grow",
    attrs: {
      "input-class": "rounded-l-none border-l-0",
      "label": `your-postback-url.com/?sub1={SUB1}&rev={REVENUE}`
    },
    model: {
      value: _vm.url,
      callback: function ($$v) {
        _vm.url = $$v;
      },
      expression: "url"
    }
  })], 1)]), _c('YAlert', {
    staticClass: "mt-2",
    attrs: {
      "type": "info",
      "allow-close": false
    }
  }, [_vm._v(" Available parameters are: "), _c('span', {
    staticClass: "font-weight-semibold"
  }, [_vm._v("{S1} {S2} {S3} {S4} {S5} {REVENUE}")]), _vm._v(". Case insensitive. ")])], 1), _c('WidgetFooter', {
    staticClass: "bg-gray-100 py-2 px-4 flex justify-between"
  }, [_c('YButton', {
    attrs: {
      "color": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Edit Postback")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }