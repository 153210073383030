var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.me ? _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }), _vm._m(0), _c('div', {
    staticClass: "flex flex-row"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('Tabs', {
    attrs: {
      "show-type": "show",
      "route": _vm.tabRoute
    }
  }, [_c('Tab', {
    staticStyle: {
      "max-width": "640px"
    },
    attrs: {
      "name": "Account Details",
      "active": true
    }
  }, [_c('PrimaryContactDetails', {
    attrs: {
      "user": _vm.me
    }
  })], 1), _vm.termsAcceptedShowWidget ? _c('Tab', {
    staticStyle: {
      "max-width": "640px"
    },
    attrs: {
      "name": "Redirect Params"
    }
  }, [_c('RedirectParameters', {
    attrs: {
      "user": _vm.me
    }
  })], 1) : _vm._e(), _vm.termsAcceptedShowWidget ? _c('Tab', {
    attrs: {
      "name": "Referrals"
    }
  }, [_c('Referrals', {
    attrs: {
      "me": _vm.me
    }
  })], 1) : _vm._e(), _c('Tab', {
    attrs: {
      "name": "Terms"
    }
  }, [_c('Terms')], 1), _c('Tab', {
    attrs: {
      "name": "Settings"
    }
  }, [_c('PublisherSettings', {
    attrs: {
      "me": _vm.me
    }
  })], 1)], 1)], 1)]), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-row align-items-center mb-2"
  }, [_c('div')]);

}]

export { render, staticRenderFns }