<template>
  <div>
    <div class="box-width">
      <div
        class="overflow-y-auto rounded cursor-pointer hover:shadow-md focus:shadow-md outline-none"
        style="max-height: 8rem;"
        @click="openPermissionsModal"
        @keydown.enter="openPermissionsModal"
        tabindex="0"
      >
        <Tag
          v-if="rowData.publisherAbilitySummary.all"
          color="gray"
          :shade="100"
          class="block py-2 rounded-none w-56"
        >
          All Publishers
        </Tag>
        <Tag
          v-for="only of rowData.publisherAbilitySummary.only"
          color="blue"
          :shade="100"
          :key="only.id"
          class="block py-2 rounded-none"
        >
          {{ only.label }}
        </Tag>
        <Tag
          v-for="except of rowData.publisherAbilitySummary.except"
          color="red"
          :shade="100"
          :key="except.id"
          class="block py-2 rounded-none"
        >
          {{ except.label }}
        </Tag>
      </div>
      <div class="text-right">
        <small
          v-if="rowData.publisherAbilitySummary.except.length"
          class="font-semibold text-red-600"
          >{{ rowData.publisherAbilitySummary.except.length }} Excluded</small
        >
        <small
          v-if="rowData.publisherAbilitySummary.only.length"
          class="font-semibold text-blue-600"
          >{{ rowData.publisherAbilitySummary.only.length }} Users</small
        >
      </div>
    </div>
  </div>
</template>

<script>
import EditModelPublisherPermissions from '@/views/Admin/Permissions/EditModelPublisherPermissions'

export default {
  components: {},
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {
    openPermissionsModal() {
      this.$modal.show(
        EditModelPublisherPermissions,
        {
          modelIds: [this.rowData.id],
          modelClass: 'campaign',
          ability: 'query',
          all: this.rowData.publisherAbilitySummary.all,
          only: this.rowData.publisherAbilitySummary.only,
          except: this.rowData.publisherAbilitySummary.except,
          onSuccess: () => {}
        },
        {
          classes: ['overflow-visible-important'],
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  }
}
</script>
<style scoped>
.box-width {
  max-width: 14rem;
}
</style>
