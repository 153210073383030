<template>
  <div class="mt-2 mb-24 ml-4">
    <Tabs>
      <Tab name="Variables" :active="true">
        <div class="flex w-full gap-8 2xl:gap-12">
          <div
            class="w-full flex flex-col gap-3 pt-4"
            :style="existsTranslatedLanguages ? '' : 'max-width: 70rem'"
          >
            <div class="grid grid-cols-4 gap-3">
              <YInput label="Name of Offer" v-model="localOffer.name"></YInput>
              <YInput label="Category" v-model="localOffer.category"></YInput>
              <YInput label="Brand" v-model="localOffer.brand"></YInput>
              <YInput
                label="Review URL"
                v-model="localOffer.reviewUrl"
              ></YInput>
            </div>

            <div
              :class="
                existsTranslatedLanguages || existsRegionalDescriptions
                  ? 'grid grid-cols-7 gap-4'
                  : ''
              "
            >
              <div
                class="pt-2"
                :class="
                  existsTranslatedLanguages || existsRegionalDescriptions
                    ? 'col-span-3'
                    : 'grid grid-cols-2 gap-2 '
                "
              >
                <div
                  class="w-full font-bold text-lg mb-5 text-gray-900"
                  :class="existsTranslatedLanguages ? '' : 'hidden'"
                >
                  English
                </div>
                <YTextarea
                  v-model="localOffer.description.value"
                  :label="
                    'Offer Description. Max ' + characterLimit + ' characters'
                  "
                  :characterLimit="characterLimit"
                ></YTextarea>

                <div
                  class="flex flex-col gap-2"
                  :class="
                    existsTranslatedLanguages || existsRegionalDescriptions
                      ? 'mt-4'
                      : ''
                  "
                >
                  <div
                    class="col-span-3 border rounded p-3 pt-1 bg-gray-50"
                    style="min-height: 3rem"
                    :key="saveFeature"
                  >
                    <span style="color: #667eea; font-size: 0.75rem">
                      Features</span
                    >
                    <ul
                      v-for="(feature, index) in localOffer.features"
                      :key="index"
                    >
                      <li>
                        <div class="flex space-between">
                          <div class="w-full">{{ feature }}</div>
                          <div>
                            <button @click="removeFeature(index)">
                              <Icon
                                name="close"
                                :size="4"
                                color="red"
                                class="inline"
                              />
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="flex gap-2 flex-start items-center">
                    <YInput
                      label="Enter Offer Feature"
                      v-model="attributeFeature"
                      class="w-full"
                    />
                    <button
                      @click="saveAttributeFeature"
                      class="bg-inverse-700 text-white h-8 w-8 rounded flex justify-center items-center"
                    >
                      <Icon name="plus" size="4" />
                    </button>
                  </div>
                  <div class="mt-2 flex gap-2 flex-start items-center">
                    <YInput
                      label="Enter Terms of Service"
                      v-model="localOffer.serviceTerms"
                      class="w-full"
                    />
                  </div>
                </div>
              </div>

              <!-- Translated Languages -->
              <div
                class="bg-gray-100 rounded-lg py-2 px-4 col-span-4 flex flex-col"
                :class="existsTranslatedLanguages ? '' : 'hidden'"
              >
                <div
                  class="w-full font-bold text-lg text-gray-900 flex justify-between"
                >
                  <div>
                    Language:
                    <span class="uppercase">{{ selectedLanguage }}</span>
                  </div>
                </div>
                <div class="flex text-xs text-gray-900">
                  <span class="w-1/2 pl-1">Original LLM</span>
                  <span class="w-1/2 pl-1">Manual Values</span>
                </div>
                <div class="grid grid-cols-2 gap-2 w-100 flex-1">
                  <YTextarea
                    v-model="descriptionLlmForSelectedLanguage"
                    :label="'Description_' + selectedLanguage"
                    disabled
                  ></YTextarea>
                  <div>
                    <YTextarea
                      v-model="manualDescriptionEntry"
                      :label="'Manual Description_' + selectedLanguage"
                    ></YTextarea>
                  </div>
                </div>

                <div
                  class="grid grid-cols-2 gap-2 w-100 flex-1"
                  v-if="featuresLlmForSelectedLanguage"
                >
                  <div class="rounded" style="min-height: 3rem">
                    <span
                      style="color: #667eea; font-size: 0.75rem"
                      class="py-1 pl-1 w-100 block border-t border-l border-r rounded-t"
                    >
                      Features_{{ selectedLanguage }}</span
                    >
                    <div
                      v-for="(feature, index) in featuresLlmForSelectedLanguage"
                      :key="index"
                    >
                      <YInput
                        disabled
                        class="w-full mb-1"
                        :label="
                          'feature_' + (index + 1) + '_' + selectedLanguage
                        "
                        :placeholder="feature"
                        :value="feature"
                      />
                    </div>
                  </div>

                  <div class="rounded bg-white" style="min-height: 3rem">
                    <span
                      class="py-1 pl-1 w-100 block border-t border-l border-r rounded-t"
                      style="color: #667eea; font-size: 0.75rem"
                    >
                      Manual Features_{{ selectedLanguage }}</span
                    >
                    <div
                      v-for="(value, key) in existsManualTranslatedFeatures"
                      :key="key"
                      class="mb-1"
                    >
                      <YInput
                        :label="'feature_' + (key + 1) + '_' + selectedLanguage"
                        placeholder="placeholder"
                        :value="value"
                        v-model="manualFeatureEntry[key]"
                        :alertIcon="
                          manualFeatureEntry[key] !==
                            featuresLlmForSelectedLanguage[key]
                        "
                        :muteInput="
                          manualFeatureEntry[key] ==
                            featuresLlmForSelectedLanguage[key]
                        "
                      />
                    </div>
                  </div>
                </div>

                <YButton
                  v-if="isSubmittingTranslations"
                  disabled
                  color="blue"
                  class="self-end mt-2 w-24"
                  style="width: 14rem"
                >
                  <Spinner color="white" :size="5" />
                </YButton>

                <YButton
                  v-if="!isSubmittingTranslations"
                  @click="saveManualTranslations"
                  color="blue"
                  class="self-end mt-2"
                  style="width: 14rem"
                  >Save Manual Translations</YButton
                >
              </div>

              <!-- Offer Regional Descriptions -->
              <div
                class="bg-gray-100 rounded-lg py-2 px-4 col-span-4 flex flex-col"
                :class="setExistingRegionalDescription ? '' : 'hidden'"
              >
                <OfferMetaRegionalDescriptions
                  v-if="offerMeta"
                  :offerMeta="offerMeta.data"
                  @update-description-regions="updateDescriptionsByRegion"
                />
              </div>
            </div>

            <!-- Theme Colors -->
            <div class="grid grid-cols-7 items-start gap-3 mt-2">
              <div class="minor-header">Theme Color</div>
              <div class="col-span-2 gap-4">
                <div class="grid grid-cols-2">
                  <div>
                    <ToggleButton
                      v-model="toggleThemeColor"
                      :color="{
                        checked: localOffer.themeColor,
                        unchecked: '#2a425a',
                        disabled: '#CCCCCC'
                      }"
                      :labels="{ checked: 'Custom', unchecked: 'Default' }"
                      :width="92"
                      :height="30"
                      :font-size="12"
                    />
                  </div>
                  <div class="flex justify-start items-center">
                    <template v-if="toggleThemeColor">
                      <Icon
                        name="colorFill"
                        :size="6"
                        class="inline text-gray-800 mr-1"
                      />
                      <verte
                        picker="square"
                        model="hex"
                        v-model="localOffer.themeColor"
                      ></verte>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-span-4">
                <span class="block text-gray-600 text-xs mt-1"
                  ><Icon
                    name="alertCircleOutline"
                    :size="4"
                    class="inline mr-2"
                  />
                  <span
                    >Default color is white. Controls background color of
                    images</span
                  >
                </span>
              </div>
            </div>
            <div class="grid grid-cols-7 items-start gap-3 mt-2">
              <div class="minor-header">Button Color</div>
              <div class="col-span-2 gap-4">
                <div class="grid grid-cols-2">
                  <div>
                    <ToggleButton
                      v-model="toggleThemeButtonColor"
                      :color="{
                        checked: localOffer.themeButtonColor,
                        unchecked: '#2a425a',
                        disabled: '#CCCCCC'
                      }"
                      :labels="{ checked: 'Custom', unchecked: 'Default' }"
                      :width="92"
                      :height="30"
                      :font-size="12"
                    />
                  </div>
                  <div class="flex justify-start items-center">
                    <template v-if="toggleThemeButtonColor">
                      <Icon
                        name="colorFill"
                        :size="6"
                        class="inline text-gray-800 mr-1"
                      />
                      <verte
                        picker="square"
                        model="hex"
                        v-model="localOffer.themeButtonColor"
                      ></verte>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-span-4">
                <span class="block text-gray-600 text-xs mt-1"
                  ><Icon
                    name="alertCircleOutline"
                    :size="4"
                    class="inline mr-2"
                  />
                  <span>Default is set by individual templates.</span>
                </span>
              </div>
            </div>

            <!-- Image -->
            <div class="flex items-center gap-6 mt-2">
              <div class="minor-header w-32 ">Image</div>

              <div class="flex-1">
                <Superselect
                  title="Select Image"
                  v-model="imageFile"
                  :options="[]"
                  track-by="allImageData"
                  label="name"
                  :multiple="false"
                  no-result="Sorry, no images found."
                  :query="IMAGES_QUERY"
                  :query-variables="{
                    first: 10,
                    filters: {
                      name: {
                        value: ['{input}'],
                        modifiers: { matchType: 'contains' }
                      }
                    }
                  }"
                >
                  <template v-slot:singleLabel>
                    <div class="flex items-center">
                      <div
                        :style="{ backgroundColor: localOffer.themeColor }"
                        class="rounded w-24 overflow-hidden flex justify-center"
                      >
                        <YImage
                          :src="imageFile.imageThumbnailUrl"
                          :width="null"
                          :height="24"
                          class="rounded object-cover h-full m-1"
                        />
                      </div>
                      <div class="ml-2">{{ imageFile.name }}</div>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="flex items-center -mx-2">
                      <YImage
                        :src="props.option.imageThumbnailUrl"
                        :width="null"
                        :height="16"
                        class="rounded"
                      />
                      <div class="ml-2">{{ props.option.name }}</div>
                    </div>
                  </template></Superselect
                >
              </div>

              <div class="flex justify-end">
                <div>
                  <Spinner
                    :size="12"
                    v-if="isSubmitting"
                    color="green"
                  ></Spinner>
                </div>
                <YButton @click="updateOrCreate" color="green"
                  >Save Offer Changes</YButton
                >
              </div>
            </div>
          </div>

          <!-- Translated available, filter -->
          <div
            class="flex-end pt-3"
            :class="existsTranslatedLanguages ? 'flex' : 'hidden'"
            style="width: 18rem"
          >
            <div
              class="w-full rounded-lg border-2 border-blue-500 p-1"
              style="height: fit-content"
            >
              <Superselect
                class="w-full"
                title="Available Translations"
                v-model="selectedLanguage"
                :options="templateTranslatedLanguages"
                :multiple="false"
              />
            </div>
          </div>

          <!-- Regional Descriptions, enabled -->
          <div
            class="flex flex-end pt-3"
            :class="!existsTranslatedLanguages ? 'flex' : 'hidden'"
            style="width: 18rem"
          >
            <div
              class="w-full rounded-lg border-2 border-blue-500 p-1"
              style="height: fit-content"
              v-if="offerMeta?.data"
            >
              <YCheckbox
                id="preset-is-public-checkbox"
                v-model="setExistingRegionalDescription"
                label="Set Regional Descriptions"
                :inline="true"
                class="flex items-center"
                type="info"
                :disabled="existsRegionalDescriptions"
              ></YCheckbox>
            </div>
          </div>
        </div>
      </Tab>
      <Tab name="Languages" :disabled="existsRegionalDescriptions">
        <div class="mt-4 flex flex-col gap-3" style="width: 28rem">
          <YButton v-if="isSubmitting" disabled color="blue">
            <Spinner color="white" :size="5" />
          </YButton>

          <YButton
            v-if="!isSubmitting"
            color="blue"
            @click="updateOrCreateTranslations"
          >
            Create or Update Translations</YButton
          >
          <Superselect
            title="Available Languages"
            v-model="localSelectedTranslatableLanguages"
            placeholder="Select Languages"
            :options="translatableLanguages"
            :custom-label="customLabelLanguages"
            track-by="code"
            :multiple="true"
            :closeOnSelect="false"
          ></Superselect>
          <div class="w-20">
            <YButton
              color="link"
              @click="
                localSelectedTranslatableLanguages = [...translatableLanguages]
              "
              >Select All</YButton
            >
          </div>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import IMAGES_QUERY from '@/graphql/ImageRepository/ImagesQuery.gql'
import UPDATE_CREATE_OFFER_META from '@/graphql/Offer/UpdateOrCreateOfferMeta.gql'
import UPDATE_TRANSLATION from '@/graphql/Offer/UpdateTranslation.gql'
import SYNC_OFFER_TRANSLATE_LANGS from '@/graphql/Offer/SyncOfferTranslationLangs.gql'
import { ToggleButton } from 'vue-js-toggle-button'
import Verte from 'verte'
import 'verte/dist/verte.css'
import OfferMetaRegionalDescriptions from '@/views/Admin/Offers/OfferMetaRegionalDescriptions'

export default {
  mounted() {},
  updated() {},
  components: {
    ToggleButton,
    Verte,
    OfferMetaRegionalDescriptions
  },
  props: {
    offerMeta: {
      type: Object
    },
    offerTranslations: {
      type: Array
    },
    offerId: {
      type: String
    },
    translatableLanguages: {
      type: Array
    }
  },

  data() {
    let parsedOfferMetaData = {
      name: '',
      category: '',
      brand: '',
      reviewUrl: '',
      description: { value: '' },
      features: [],
      images: [],
      themeColor: '',
      themeButtonColor: '',
      serviceTerms: ''
    }

    if (typeof this.offerMeta?.data === 'string') {
      try {
        parsedOfferMetaData = JSON.parse(this.offerMeta.data)
      } catch (error) {
        console.error(error)
      }
    }

    return {
      localOffer: {
        ...parsedOfferMetaData
      },
      IMAGES_QUERY,
      attributeFeature: '',
      saveFeature: true,
      isSubmitting: false,
      isSubmittingTranslations: false,
      imageFile: null,
      imageType: 'logo',
      characterLimit: 70,
      selectedLanguage: null,
      existsTranslatedLanguages: this.offerTranslations.length > 0,
      setExistingRegionalDescription: false,
      manualDescriptionEntry: undefined,
      localSelectedTranslatableLanguages: [],
      manualFeatureEntry: [],
      toggleThemeColor:
        this.offerMeta?.data && JSON.parse(this.offerMeta.data).themeColor
          ? true
          : false,
      toggleThemeButtonColor:
        this.offerMeta?.data && JSON.parse(this.offerMeta.data).themeButtonColor
          ? true
          : false
    }
  },
  methods: {
    customLabelLanguages(option) {
      return option.name + ' - ' + option.code.toUpperCase()
    },
    updateDescriptionsByRegion(localizedDescriptions) {
      const regions = localizedDescriptions.reduce((acc, desc) => {
        const key = Object.keys(desc)[0]
        const value = desc[key]
        acc[key] = value
        return acc
      }, {})

      this.setExistingRegionalDescription = Object.keys(regions).length > 0

      if (Object.keys(regions).length > 0) {
        this.localOffer.description.regions = regions
      } else {
        this.localOffer.description.regions = undefined
      }
    },
    removeFeature(index) {
      this.localOffer.features.splice(index, 1)
      this.saveFeature = !this.saveFeature
    },
    saveAttributeFeature() {
      if (this.attributeFeature) {
        this.localOffer.features
          ? this.localOffer.features.push(this.attributeFeature)
          : (this.localOffer.features = [this.attributeFeature])

        this.attributeFeature = ''
        this.saveFeature = !this.saveFeature
      } else {
        this.$toastr.e('Attribute feature cannot be blank')
      }
    },
    updateOrCreate() {
      this.isSubmitting = true

      let themeColor = this.toggleThemeColor ? this.localOffer.themeColor : ''
      let themeButtonColor = this.toggleThemeButtonColor
        ? this.localOffer.themeButtonColor
        : ''

      let descriptions = {
        value: this.localOffer.description.value,
        regions: this.localOffer.description.regions
      }

      let data = {
        name: this.localOffer.name ?? undefined,
        category: this.localOffer.category ?? null,
        brand: this.localOffer.brand ?? null,
        reviewUrl: this.localOffer.reviewUrl ?? null,
        description: descriptions ?? null,
        serviceTerms: this.localOffer.serviceTerms ?? null,
        features: this.localOffer.features ?? null,
        themeColor: themeColor,
        themeButtonColor: themeButtonColor
      }

      let images = this.imageFile
        ? [{ id: this.imageFile.id, type: this.imageType }]
        : undefined

      this.$apollo
        .mutate({
          mutation: UPDATE_CREATE_OFFER_META,
          variables: {
            input: {
              offerId: this.offerId,
              data: data,
              images: images
            }
          }
        })
        .then(() => {
          this.$toastr.s('Attributes saved')
          this.$events.emit('offerTrafficked')
          this.$tabEvent.emit('refreshOffersTable')
          this.isSubmitting = false
        })
        .catch(error => {
          console.log(error)
          this.isSubmitting = false

          this.$toastr.e('Attribues not saved')
        })
    },
    saveManualTranslations() {
      let updateFeatures =
        JSON.stringify(this.manualFeatureEntry) !==
        JSON.stringify(this.featuresLlmForSelectedLanguage)

      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_TRANSLATION,
          variables: {
            input: {
              id: this.selectedTranslationId,
              dataOverride: {
                description: this.manualDescriptionEntry ?? undefined,
                features: updateFeatures ? this.manualFeatureEntry : undefined
              }
            }
          }
        })
        .then(() => {
          this.$toastr.s('Manual Changes Saved')
          this.isSubmitting = false
        })
        .catch(error => {
          console.log(error)
          this.isSubmitting = false
          this.$toastr.e('Manual Changes not saved')
        })
    },
    updateOrCreateTranslations() {
      this.isSubmitting = true

      let languageCodes = this.localSelectedTranslatableLanguages.map(
        value => value.code
      )

      this.$apollo
        .mutate({
          mutation: SYNC_OFFER_TRANSLATE_LANGS,
          variables: {
            input: {
              id: this.offerId,
              languages: languageCodes
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
        })
        .catch(error => {
          console.log(error)
          this.isSubmitting = false
        })
    },
    deepCloneLocalOffer(newVal, oldVal) {
      if (newVal === oldVal) return true

      if (typeof newVal !== 'object' || typeof oldVal !== 'object') return false

      const keys1 = Object.keys(newVal)
      const keys2 = Object.keys(oldVal)

      if (keys1.length !== keys2.length) return false

      for (let key of keys1) {
        if (
          !keys2.includes(key) ||
          !this.deepCloneLocalOffer(newVal[key], oldVal[key])
        )
          return false
      }

      return true
    }
  },
  computed: {
    existsRegionalDescriptions() {
      if (
        (this.offerMeta?.data &&
          JSON.parse(this.offerMeta.data).description?.regions) ||
        this.setExistingRegionalDescription
      ) {
        return true
      }
      return false
    },
    imageFileComputed() {
      return this.offerMeta?.images
        .filter(image => image.type === 'logo')
        .map(image => {
          return {
            id: image.id,
            name: image.name,
            imageThumbnailUrl: image.conversions.find(
              image => image.conversion === 'thumb'
            ).storageUrl
          }
        })
    },
    selectedTranslationId() {
      if (this.offerTranslations) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )
        return translation.id
      }
      return null
    },
    existsManualTranslatedDescription() {
      if (this.offerTranslations) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )

        if (
          translation &&
          translation.dataOverride &&
          translation.dataOverride.description
        ) {
          return translation.dataOverride.description
        }
      }
      return undefined
    },
    existsManualTranslatedFeatures() {
      if (this.offerTranslations) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )

        if (
          translation &&
          translation.dataOverride &&
          translation.dataOverride.features
        ) {
          return translation.dataOverride.features
        }
        return translation.data.features
      }
      return null
    },
    templateTranslatedLanguages() {
      if (this.offerTranslations) {
        return this.offerTranslations
          .map(item => item.language)
          .slice()
          .sort()
      }
      return null
    },
    descriptionLlmForSelectedLanguage() {
      if (this.selectedLanguage) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )
        return translation ? translation.data.description.value : ''
      }
      return ''
    },
    featuresLlmForSelectedLanguage() {
      if (this.selectedLanguage) {
        const translation = this.offerTranslations.find(
          item => item.language === this.selectedLanguage
        )
        return translation ? translation.data.features : ''
      }
      return ''
    }
  },
  created() {
    if (
      this.templateTranslatedLanguages &&
      this.templateTranslatedLanguages.length > 0
    ) {
      this.selectedLanguage = this.templateTranslatedLanguages[0]

      this.manualFeatureEntry = this.existsManualTranslatedFeatures

      this.localSelectedTranslatableLanguages = this.translatableLanguages.filter(
        lang => this.templateTranslatedLanguages.includes(lang.code)
      )
    }

    if (this.offerMeta && this.imageFileComputed.length > 0) {
      this.imageFile = this.imageFileComputed[0]
    }
  },
  watch: {
    clonedLocalOffer: {
      handler(newVal, oldVal) {
        if (!this.deepCloneLocalOffer(newVal, oldVal)) {
          this.hasChanges = true
        } else {
          this.hasChanges = false
        }
      },
      deep: true
    },
    existsManualTranslatedDescription(newVal) {
      this.manualDescriptionEntry = newVal || []
    },
    selectedLanguage() {
      if (!this.existsManualTranslatedDescription) {
        this.manualDescriptionEntry = undefined
      }
      this.manualFeatureEntry = this.existsManualTranslatedFeatures
    }
  }
}
</script>

<style scoped>
.minor-header {
  @apply text-gray-800 font-semibold capitalize;
}
.toggleTransition {
  transition: all 0.3s ease-in-out;
  transform: rotate(-180deg);
}
</style>
