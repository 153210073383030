var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inline-flex items-center select-none",
    class: _vm.classes
  }, [_c('input', {
    ref: "checkbox",
    staticClass: "mr-2",
    attrs: {
      "id": _vm.computedId,
      "type": "checkbox",
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.value,
      "checked": _vm.value
    },
    on: {
      "change": function ($event) {
        return _vm.updateValue(_vm.value);
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.updateValue(_vm.value);
      },
      "blur": function ($event) {
        return _vm.$emit('blur');
      }
    }
  }), _c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": _vm.computedId
    }
  }, [_vm._t("label", function () {
    return [_vm._v(_vm._s(_vm.label))];
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }