<template>
  <div v-observe-visibility="visibilityChanged">
    <div class="text-xs text-gray-600 font-bold mb-1 mt-4">
      Default Redirect Parameters
    </div>
    <div class="grid grid-cols-6 gap-0">
      <YInput
        input-class="rounded-r-none border-r-0"
        label="?kw="
        v-model="kw"
      />
      <YInput
        input-class="rounded-none border-l-0 border-r-0"
        label="&s1="
        v-model="s1"
      />
      <YInput
        input-class="rounded-none border-l-0 border-r-0"
        label="&s2="
        v-model="s2"
      />
      <YInput
        input-class="rounded-none border-l-0 border-r-0"
        label="&s3="
        v-model="s3"
      />
      <YInput
        input-class="rounded-none border-l-0 border-r-0"
        label="&s4="
        v-model="s4"
      />
      <YInput
        input-class="rounded-l-none border-l-0"
        label="&s5="
        v-model="s5"
      />
    </div>
    <portal to="top-bar-right" v-if="hasChanges && isVisible">
      <YButton @click="update()" color="green">Update Details</YButton>
    </portal>
  </div>
</template>

<script>
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'

export default {
  apollo: {},
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localUser: JSON.parse(JSON.stringify(this.user)),
      isVisible: true,
      isUpdating: false
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
    },
    update() {
      this.isUpdating = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          // Query
          mutation: UPDATE_USER_MUTATION,
          // Parameters
          variables: {
            input: {
              id: this.user.id,
              userDetails: {
                update: {
                  id: this.user.userDetails.id,
                  defaultRedirectParameters: this.localUser.userDetails
                    .defaultRedirectParameters
                }
              }
            }
          }
        })
        .then(data => {
          // Result
          console.log(data)
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Success')
        })
        .catch(error => {
          // Error
          console.error(error)
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.e('Error')
        })
    }
  },
  computed: {
    hasChanges() {
      return (
        JSON.stringify(this.localUser.userDetails) !=
        JSON.stringify(this.user.userDetails)
      )
    },
    kw: {
      // getter
      get: function() {
        return this.localUser.userDetails.defaultRedirectParameters
          ? JSON.parse(this.localUser.userDetails.defaultRedirectParameters).kw
          : null
      },
      // setter
      set: function(newValue) {
        this.localUser.userDetails.defaultRedirectParameters = JSON.stringify({
          kw: newValue,
          s1: this.s1,
          s2: this.s2,
          s3: this.s3,
          s4: this.s4,
          s5: this.s5
        })
      }
    },
    s1: {
      // getter
      get: function() {
        return this.localUser.userDetails.defaultRedirectParameters
          ? JSON.parse(this.localUser.userDetails.defaultRedirectParameters).s1
          : null
      },
      // setter
      set: function(newValue) {
        this.localUser.userDetails.defaultRedirectParameters = JSON.stringify({
          kw: this.kw,
          s1: newValue,
          s2: this.s2,
          s3: this.s3,
          s4: this.s4,
          s5: this.s5
        })
      }
    },
    s2: {
      // getter
      get: function() {
        return this.localUser.userDetails.defaultRedirectParameters
          ? JSON.parse(this.localUser.userDetails.defaultRedirectParameters).s2
          : null
      },
      // setter
      set: function(newValue) {
        this.localUser.userDetails.defaultRedirectParameters = JSON.stringify({
          kw: this.kw,
          s1: this.s1,
          s2: newValue,
          s3: this.s3,
          s4: this.s4,
          s5: this.s5
        })
      }
    },
    s3: {
      // getter
      get: function() {
        return this.localUser.userDetails.defaultRedirectParameters
          ? JSON.parse(this.localUser.userDetails.defaultRedirectParameters).s3
          : null
      },
      // setter
      set: function(newValue) {
        this.localUser.userDetails.defaultRedirectParameters = JSON.stringify({
          kw: this.kw,
          s1: this.s1,
          s2: this.s2,
          s3: newValue,
          s4: this.s4,
          s5: this.s5
        })
      }
    },
    s4: {
      // getter
      get: function() {
        return this.localUser.userDetails.defaultRedirectParameters
          ? JSON.parse(this.localUser.userDetails.defaultRedirectParameters).s4
          : null
      },
      // setter
      set: function(newValue) {
        this.localUser.userDetails.defaultRedirectParameters = JSON.stringify({
          kw: this.kw,
          s1: this.s1,
          s2: this.s2,
          s3: this.s3,
          s4: newValue,
          s5: this.s5
        })
      }
    },
    s5: {
      // getter
      get: function() {
        return this.localUser.userDetails.defaultRedirectParameters
          ? JSON.parse(this.localUser.userDetails.defaultRedirectParameters).s5
          : null
      },
      // setter
      set: function(newValue) {
        this.localUser.userDetails.defaultRedirectParameters = JSON.stringify({
          kw: this.kw,
          s1: this.s1,
          s2: this.s2,
          s3: this.s3,
          s4: this.s4,
          s5: newValue
        })
      }
    }
  }
}
</script>

<style scoped></style>
