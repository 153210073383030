<template>
  <Widget>
    <WidgetHeader @close="$emit('close')">
      Set Auto Redirect
    </WidgetHeader>
    <WidgetBody class="pt-0">
      <Superselect
        v-model="autoRedirectStatus"
        :multiple="false"
        class="mb-2"
        title="Auto Redirect"
        :options="['Yes', 'No']"
      />

      <Superselect
        :disabled="autoRedirectStatus !== 'Yes'"
        title="Open Redirects"
        :options="autoRedirectOptions"
        v-model="selectedAutoRedirect"
        :multiple="false"
      >
      </Superselect>
    </WidgetBody>

    <WidgetFooter class="bg-gray-100 px-4 py-2">
      <div class="flex justify-between">
        <YButton class="btn btn-link" @click="$emit('close')">
          Cancel
        </YButton>
        <YButton
          class="btn btn-blue"
          @click="setAutoRedirect"
          :is-loading="isSubmitting"
          :disabled="!autoRedirectStatus"
        >
          Set Auto Redirect
        </YButton>
      </div>
    </WidgetFooter>
    <!--<ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>-->
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import ASSIGN_USER_TO_DOMAIN from '@/graphql/Redirect/AssignUserToDomain.gql'
export default {
  props: {
    modelId: {
      type: Array,
      required: true
    }
  },
  apollo: {
    ydnsDomains: {
      query: gql`
        query YdnsDomains($filters: YdnsDomainFilterInput) {
          ydnsDomains(filters: $filters) {
            id
            type
            externalId
            tags {
              tagConfig {
                tag
              }
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            tagsByName: { value: 'open' }
          }
        }
      },
      result(data) {
        this.redirectOptions(data.data.ydnsDomains)
      }
    }
  },
  mounted() {},
  updated() {},
  data() {
    return {
      isSubmitting: false,
      autoRedirectStatus: '',
      autoRedirectOptions: [],
      selectedAutoRedirect: ''
    }
  },
  methods: {
    redirectOptions(redirects) {
      this.autoRedirectOptions = redirects.map(redirect => redirect.id)
    },
    setAutoRedirect() {
      if (this.autoRedirectStatus == 'Yes') {
        this.isSubmitting = true
        this.$apollo
          .mutate({
            mutation: ASSIGN_USER_TO_DOMAIN,
            variables: {
              input: {
                userId: this.modelId[0],
                domainId: this.selectedAutoRedirect
              }
            }
          })
          .then(() => {
            this.$toastr.s('User Assigned to Domain')
            this.$emit('close')
          })
      } else {
        this.$emit('close')
      }
      this.isSubmitting = false
    }
  }
}
</script>

<style></style>
