<template>
  <div>
    <div>
      <Spinner
        v-if="$apolloData.queries.algorithmsDataBucketKeys.loading"
        color="green"
        :size="10"
        class="mt-6"
      />
      <YTable
        v-if="displayedRows"
        :data="displayedRows"
        :loading="$apolloData.queries.algorithmsDataBucketKeys.loading"
      >
        <template slot="header-row">
          <th>Algo</th>
          <th>Device</th>
          <th>Geo</th>
          <th>Region</th>
          <th>Redirect</th>
          <th>Campaign</th>
          <th>Views</th>
          <th>RPM</th>
          <th>Top RPM</th>
          <th>
            <div class="flex items-center gap-1" @click="toggleSort('revenue')">
              Revenue

              <Icon
                name="chevronDown"
                size="5"
                class="text-gray-400 cursor-pointer"
                :class="{
                  'text-blue-600': paginationData.sortBy === 'revenue'
                }"
                :style="{
                  transform:
                    paginationData.orderBy === 'ASC'
                      ? 'rotate(-180deg)'
                      : 'rotate(0)'
                }"
              />
            </div>
          </th>

          <th>
            <div class="flex items-center gap-1" @click="toggleSort('profit')">
              Profit

              <Icon
                name="chevronDown"
                size="5"
                class="text-gray-400 cursor-pointer"
                :class="{
                  'text-blue-600': paginationData.sortBy === 'profit'
                }"
                :style="{
                  transform:
                    paginationData.orderBy === 'ASC'
                      ? 'rotate(-180deg)'
                      : 'rotate(0)'
                }"
              />
            </div>
          </th>
          <th>
            <div class="flex items-center gap-1" @click="toggleSort('spend')">
              Spend

              <Icon
                name="chevronDown"
                size="5"
                class="text-gray-400 cursor-pointer"
                :class="{
                  'text-blue-600': paginationData.sortBy === 'spend'
                }"
                :style="{
                  transform:
                    paginationData.orderBy === 'ASC'
                      ? 'rotate(-180deg)'
                      : 'rotate(0)'
                }"
              />
            </div>
          </th>
          <th>Weight</th>
          <th>Min</th>
          <th>Max</th>
          <th>Expiry Date</th>
          <th>Fallback</th>
        </template>

        <template slot="secondHeaderRow">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th style="padding: 0.2rem 1rem; color: #000;">
            {{ formatDollarValue(algorithmsDataBucketKeys?.totalRevenue) }}
          </th>

          <th style="padding: 0.2rem 1rem; color: #000;">
            {{ formatDollarValue(algorithmsDataBucketKeys?.totalProfit) }}
          </th>
          <th style="padding: 0.2rem 1rem; color: #000;">
            {{ formatDollarValue(algorithmsDataBucketKeys?.totalSpend) }}
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </template>

        <template slot="row" slot-scope="props">
          <td class="text-center">
            <span class="font-semibold text-xs">
              {{ props.rowData.algoId }}</span
            >
          </td>
          <td>
            <Tag color="blue">{{ props.rowData.device }}</Tag>
          </td>
          <td>{{ props.rowData.country }}</td>
          <td>{{ props.rowData.region }}</td>
          <td>
            <router-link
              v-if="props.rowData.redirect"
              :to="{
                name: 'Redirect',
                params: { id: props.rowData.redirect.id }
              }"
              class="link"
            >
              {{ props.rowData.redirect.label }}
            </router-link>
          </td>
          <td>
            <router-link
              v-if="props.rowData.campaign"
              :to="{
                name: 'Campaign',
                params: { id: props.rowData.campaign.id }
              }"
              class="link"
            >
              {{ props.rowData.campaign.label }}
            </router-link>
          </td>

          <td>{{ props.rowData.views }}</td>

          <td :class="props.rowData.rpm < 0 ? 'text-red-500' : ''">
            {{ formatDollarValue(props.rowData.rpm) }}
          </td>
          <td
            :class="props.rowData.topRpm < 0 ? 'text-black' : 'text-gray-800'"
          >
            {{ formatDollarValue(props.rowData.topRpm) }}
          </td>
          <td :class="props.rowData.revenue < 0 ? 'text-red-500' : ''">
            {{ formatDollarValue(props.rowData.revenue) }}
          </td>
          <td :class="props.rowData.profit < 0 ? 'text-red-500' : ''">
            {{ formatDollarValue(props.rowData.profit) }}
          </td>
          <td :class="props.rowData.spend < 0 ? 'text-red-500' : ''">
            {{ formatDollarValue(props.rowData.spend) }}
          </td>
          <td>{{ props.rowData.weight }}</td>
          <td>{{ props.rowData.min }}</td>
          <td>{{ props.rowData.max }}</td>
          <td>{{ props.rowData.expiry }}</td>
          <td>{{ props.rowData.fallback }}</td>
        </template>
      </YTable>
      <YPagination
        v-if="algorithmsDataBucketKeys"
        :data="paginationData"
        @load-page="loadPage"
        @next-page="nextPage"
        @previous-page="previousPage"
        @per-page="changePerPage"
      />
    </div>
  </div>
</template>

<script>
import ALGO_DATA_BUCKET_KEYS from '@/graphql/Algorithm/AlgorithmsDataBucketKeysQuery.gql'
import YPagination from '@/components/ui/YPagination.vue'

export default {
  components: { YPagination },
  props: {
    offer: {
      type: Object,
      required: true
    }
  },
  apollo: {
    algorithmsDataBucketKeys: {
      query: ALGO_DATA_BUCKET_KEYS,
      variables() {
        return {
          filters: {
            offerId: this.offer.id,
            enrich: true,
            orderBy: this.paginationData.orderBy,
            sortBy: this.paginationData.sortBy
          }
        }
      }
    }
  },
  data() {
    return {
      paginationData: {
        currentPage: 1,
        lastPage: 1,
        perPage: 10,
        sortBy: 'revenue',
        orderBy: 'DESC'
      }
    }
  },
  computed: {
    displayedRows() {
      const startIndex =
        (this.paginationData.currentPage - 1) * this.paginationData.perPage
      const endIndex = startIndex + this.paginationData.perPage
      return (
        this.algorithmsDataBucketKeys?.buckets?.slice(startIndex, endIndex) ||
        []
      )
    }
  },
  watch: {
    algorithmsDataBucketKeys: {
      handler() {
        this.updatePaginationData()
      },
      deep: true
    }
  },
  methods: {
    toggleSort(value) {
      this.paginationData.orderBy =
        this.paginationData.orderBy === 'DESC' ? 'ASC' : 'DESC'
      this.paginationData.sortBy = value

      this.$apollo.queries.algorithmsDataBucketKeys.refetch({
        filters: {
          offerId: this.offer.id,
          enrich: true,
          orderBy: this.paginationData.orderBy,
          sortBy: this.paginationData.sortBy
        }
      })
    },
    updatePaginationData() {
      // Calculate the total number of pages based on the number of rows and items per page
      this.paginationData.lastPage = Math.ceil(
        (this.algorithmsDataBucketKeys?.buckets?.length || 1) /
          this.paginationData.perPage
      )
      this.paginationData.lastPage = Math.max(1, this.paginationData.lastPage)

      this.loadPage(this.paginationData.currentPage)
    },
    loadPage(page) {
      this.paginationData.currentPage = page
    },
    nextPage() {
      if (this.paginationData.currentPage < this.paginationData.lastPage) {
        this.loadPage(this.paginationData.currentPage + 1)
      }
    },
    previousPage() {
      if (this.paginationData.currentPage > 1) {
        this.loadPage(this.paginationData.currentPage - 1)
      }
    },
    changePerPage(value) {
      this.paginationData.perPage = value
      this.updatePaginationData()
    },
    formatDollarValue(value) {
      if (value === '0' || value === null) {
        return '$0.00'
      } else {
        return (
          '$' +
          Number(value).toLocaleString(undefined, { minimumFractionDigits: 2 })
        )
      }
    }
  }
}
</script>
