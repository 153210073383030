<template>
  <div>
    <div class="block w-full" style="min-height:65px">
      <portal to="content-top-bar-left-header">
        <div class="flex items-center">
          <router-link
            :to="{
              name: 'Login'
            }"
          >
            <YButton color="inverse">
              <Icon
                name="arrowLeft"
                size="4"
                class="inline mr-1"
                style="margin-bottom: 3px"
              />Back to Login</YButton
            >
          </router-link>
        </div>
      </portal>
    </div>
    <div class="mx-auto w-full xl:w-3/5 2xl:w-2/5 mb-12">
      <div
        class=" bg-white rounded-md pt-4 pb-8 px-2 sm:px-4 md:px-10  shadow-md flex flex-col"
      >
        <h1 class="font-bold text-2xl text-center text-inverse-700 mb-2">
          Welcome to YTZ 👋
        </h1>

        <!-- required details -->
        <div class="mt-3">
          <p class="pb-2 font-semibold uppercase text-inverse-700">
            Contact Details
          </p>
          <div class="grid grid-rows-1 gap-3">
            <YInput label="Name" v-model="userName" :required="true" />
            <YInput label="Company" v-model="company" />

            <p class="font-semibold uppercase text-inverse-700">
              Preferred Contact Method
              <span class="ml-1 text-lg text-red-500 inline">*</span>
            </p>
            <div class="grid grid-cols-1">
              <div class="flex flex-row gap-5">
                <div class="mr-6 flex items-center mb-2">
                  <input
                    checked
                    type="checkbox"
                    id="email"
                    value="email"
                    v-model="contactTypeEmail"
                  />
                  <label class="text-base capitalize" for="type">Email</label>
                </div>

                <div class="mr-6 flex items-center mb-2">
                  <input
                    type="radio"
                    id="skype"
                    value="skype"
                    v-model="contactTypeMessenger"
                  />
                  <label class="text-base capitalize" for="type">Skype</label>
                </div>
                <div class="mr-6 flex items-center mb-2">
                  <input
                    type="radio"
                    id="telgram"
                    value="telegram"
                    v-model="contactTypeMessenger"
                  />
                  <label class="text-base capitalize" for="type"
                    >telegram</label
                  >
                </div>
              </div>
              <div class="col-span-3">
                <YInput
                  label="Email"
                  v-model="contactEmail"
                  class="capitalize"
                  :class="contactTypeEmail ? 'block' : 'hidden'"
                />
                <YInput
                  label="Skype"
                  v-model="contactMessenger"
                  class="capitalize mt-2"
                  :class="contactTypeMessenger == 'skype' ? 'block' : 'hidden'"
                />
                <YInput
                  label="Telegram"
                  v-model="contactMessenger"
                  class="capitalize mt-2"
                  :class="
                    contactTypeMessenger == 'telegram' ? 'block' : 'hidden'
                  "
                />
              </div>
            </div>

            <div class="flex items-center mt-1">
              <div class="w-40">
                <p class="font-semibold uppercase text-inverse-700">
                  Role
                  <span class="ml-1 text-lg text-red-500 inline">*</span>
                </p>
              </div>
              <div
                class="flex flex-col flex-wrap md:grid md:grid-cols-2 w-full"
              >
                <div v-for="leadType in leadTypeOptions" :key="leadType">
                  <input
                    type="checkbox"
                    :id="leadType"
                    :value="leadType"
                    v-model="selectedLeadType"
                  />
                  <label class="text-base capitalize" :for="leadType">{{
                    leadType
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4 border-t pt-4">
          <YAccordion class="mb-6">
            <template v-slot:title>
              Payout Types (optional)
            </template>
            <template v-slot:content>
              <div class="grid grid-cols-2 md:grid-cols-4 gap-y-5 gap-x-3 mb-8">
                <div v-for="payoutType in payoutTypeOptions" :key="payoutType">
                  <input
                    type="checkbox"
                    :id="payoutType"
                    :value="payoutType"
                    v-model="selectedPayoutType"
                  />
                  <label class="text-base capitalize" :for="payoutType">{{
                    payoutType
                  }}</label>
                </div>
              </div>
            </template>
          </YAccordion>
          <YAccordion class="mb-6">
            <template v-slot:title>
              Traffic Types (optional)
            </template>
            <template v-slot:content>
              <div class="grid grid-cols-2 md:grid-cols-4 gap-y-5 gap-x-3 mb-8">
                <div
                  v-for="trafficType in trafficTagsOptions"
                  :key="trafficType"
                >
                  <input
                    type="checkbox"
                    :id="trafficType"
                    :value="trafficType"
                    v-model="selectedTrafficType"
                  />
                  <label class="text-base capitalize" :for="trafficType">{{
                    trafficType
                  }}</label>
                </div>
              </div>
            </template>
          </YAccordion>
          <YAccordion class="mb-6">
            <template v-slot:title>
              Verticals (optional)
            </template>
            <template v-slot:content>
              <div class="grid grid-cols-2 md:grid-cols-4 gap-y-5 gap-x-3 mb-8">
                <div
                  v-for="verticalType in verticalTagsOptions"
                  :key="verticalType"
                >
                  <input
                    type="checkbox"
                    :id="verticalType"
                    :value="verticalType"
                    v-model="selectedVerticalType"
                  />
                  <label class="text-base capitalize" :for="verticalType">{{
                    verticalType
                  }}</label>
                </div>
              </div>
            </template>
          </YAccordion>
          <YAccordion class="mb-6">
            <template v-slot:title>
              Geos (optional)
            </template>
            <template v-slot:content>
              <div class="grid grid-cols-2 md:grid-cols-4 gap-y-5 gap-x-3 mb-8">
                <div v-for="geoType in geoTagsOptions" :key="geoType">
                  <input
                    type="checkbox"
                    :id="geoType"
                    :value="geoType"
                    v-model="selectedGeoType"
                  />
                  <label class="text-base capitalize" :for="geoType">{{
                    geoType
                  }}</label>
                </div>
              </div>
            </template>
          </YAccordion>
          <YAccordion class="mb-6">
            <template v-slot:title>
              Leave a note (optional)
            </template>
            <template v-slot:content>
              <div class="w-full mb-8">
                <textarea
                  class="px-3 py-3 w-full rounded mb-2 border block"
                  v-model="message"
                  placeholder="Type here..."
                  @keydown.exact.prevent.enter="addComment()"
                />
              </div>
            </template>
          </YAccordion>
        </div>
        <YButton
          color="pink"
          @click="registerUser"
          class="w-full xl:w-2/5 mx-auto mt-16"
          :disabled="!contactMethodProvided"
          :is-loading="isSubmitting"
          >Submit</YButton
        >
      </div>
      <div>
        <portal-target name="registration-errors"></portal-target>
      </div>
    </div>

    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
      portal-to="registration-errors"
      alert-class="text-xs"
      :icon-size="12"
      :icon="null"
      color="yellow"
    ></ValidationErrors>
  </div>
</template>

<script>
import REGISTER_LEAD_MUTATION from '@/graphql/Auth/RegisterLeadMutation.gql'
import YAccordion from '@/components/ui/YAccordion.vue'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  components: {
    YAccordion
  },
  apollo: {},
  data() {
    return {
      isSubmitting: false,
      leadTypeOptions: ['publisher', 'advertiser', 'network', 'affiliate'],
      trafficTagsOptions: [
        'email',
        'native',
        'push',
        'SMS',
        'display',
        'social',
        'search',
        'co-reg',
        'adult',
        'incent'
      ],
      verticalTagsOptions: [
        'sweepstakes',
        'casino',
        'finance',
        'nutra',
        'ecom',
        'downloads',
        'dating/adult',
        'mobile apps'
      ],
      geoTagsOptions: [
        'north america',
        'europe',
        'APAC',
        'LATAM',
        'Middle East',
        'Africa',
        'all locations'
      ],
      payoutTypeOptions: ['CPA', 'CPL', 'CPC', 'RevShare', 'Hybrid'],
      userName: undefined,
      company: undefined,
      contactEmail: undefined,
      selectedLeadType: [],
      contactTypeEmail: true,
      contactTypeMessenger: undefined,
      contactMessenger: undefined,
      selectedTrafficType: [],
      selectedVerticalType: [],
      selectedGeoType: [],
      selectedPayoutType: [],
      message: ''
    }
  },
  computed: {
    contactMethodProvided() {
      return (
        (this.contactEmail || this.contactMessenger) &&
        this.selectedLeadType.length > 0 &&
        this.userName
      )
    }
  },
  methods: {
    registerUser() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: REGISTER_LEAD_MUTATION,
          variables: {
            input: {
              name: this.userName,
              company: this.company,
              email: this.contactEmail,
              leadType: this.selectedLeadType,
              trafficType: this.selectedTrafficType,
              verticalTypeTags: this.selectedVerticalType,
              geoLocation: this.selectedGeoType,
              payoutType: this.selectedPayoutType,
              messengerType: this.contactTypeMessenger,
              messengerHandle: this.contactMessenger,
              message: this.message
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.clearValidationErrors()
          this.$router.push('/crm-completed')
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
          console.error(error)
        })
    }
  }
}
</script>

<style scoped>
input[type='radio'] {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}

input[type='checkbox'] {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}
</style>
