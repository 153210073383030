var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('YAlert', {
    staticClass: "my-3"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('span', {
    staticClass: "pr-4",
    staticStyle: {
      "font-size": "1.5rem"
    }
  }, [_vm._v("🎉")]), _c('p', {
    staticStyle: {
      "margin-bottom": "0px"
    }
  }, [_c('span', {
    staticClass: "font-bold"
  }, [_vm._v("YTZ")]), _vm._v(" is happy to offer our publishers a referral program. "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("The YTZ Publisher Referral Program")]), _vm._v(" is easy to use; simply grab the account specific referral link below, pass it on to anyone you know who has traffic they are looking to monetize and start making money from that referral. It's that simple. ")])])]), _c('div', {
    staticClass: "p-4 pt-2"
  }, [_vm._m(0), _vm._m(1), _c('p', [_vm._v(" Please feel free to reach out to us anytime if you have questions about the referral program. ")]), _c('YInput', {
    staticClass: "my-4",
    attrs: {
      "readonly": true,
      "value": _vm.referralLink,
      "label": "Your Referral Link"
    }
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', [_c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("A few things to note:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ol', [_c('li', [_vm._v("Your account-specific referral link is below")]), _c('li', [_vm._v(" Referral program offers "), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("2.5% based on the referral account's "), _c('span', {
    staticClass: "underline"
  }, [_vm._v("lifetime revenue")]), _vm._v(".")])]), _c('li', [_vm._v(" Real time referral stats are available in the report section. Use the "), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("\"Referral Revenue From\"")]), _vm._v(" filter to breakdown your stats by publisher. ")]), _c('li', [_vm._v("No self-referrals.")])]);

}]

export { render, staticRenderFns }