<template>
  <v-popover
    offset="0"
    v-if="localOffer"
    class="flex items-center px-2 py-2 hover:bg-blue-100 rounded cursor-pointer"
  >
    <div class="flex items-center">
      <span
        class="h-2 w-2 m-2 rounded-full"
        :class="
          localOffer.status == 'active'
            ? 'bg-green-500'
            : localOffer.status == 'paused'
              ? 'bg-yellow-500'
              : localOffer.status == 'created'
              ? 'bg-gray-500'
              : 'bg-red-500'
        "
      ></span>
      <div
        v-if="!hideLabel"
        class="flex-grow font-medium pl-1 pr-2 truncate uppercase text-gray-700"
      >
        {{ localOffer.status == 'paused' ? 'Traffic Paused': 'Traffic '+localOffer.status.capitalize() }}
      </div>
    </div>
    <!-- This will be the content of the popover -->
    <template slot="popover">
      <div class="flex flex-col">
        <YButton v-if="localOffer.status == 'active'" color="yellow" @click="pauseOffer">Pause</YButton>
        <YButton v-if="localOffer.status == 'active' || localOffer.status == 'paused'" color="red" @click="deactivateOffer">Deactivate</YButton>
        <YButton color="green" @click="trafficOffer">Traffic</YButton>
      </div>
    </template>
  </v-popover>
</template>

<script>
import TrafficOffer from '@/views/Admin/Offers/TrafficOffer'
import PauseOffer from '@/views/Admin/Offers/OfferPause'
import DeactivateOffer from '@/views/Admin/Offers/OffersDeactivate'

import OFFER_QUERY from '@/graphql/Offer/OfferQuery.gql'

export default {
  props: {
    id: {
      type: [Number, String]
    },
    offer: {
      type: Object,
      default: null
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    localOffer: {
      query: OFFER_QUERY,
      update: data => data.offer,
      variables() {
        return {
          id: this.id
        }
      },
      skip() {
        return !this.id
      }
    }
  },
  data() {
    return {
      localOffer: this.offer
    }
  },
  watch: {
    offer: {
      deep: true,
      handler(newValue) {
        this.localOffer = newValue
      }
    }
  },
  mounted() {
    this.$events.listen('refreshOfferData', () => {
      this.$apollo.queries.localOffer.refetch()
    })
  },
  methods: {
    trafficOffer() {
      this.$modal.show(
        TrafficOffer,
        { offerId: this.localOffer.id },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    pauseOffer() {
      this.$modal.show(
        PauseOffer,
        { offerId: this.localOffer.id },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    deactivateOffer() {
      this.$modal.show(
        DeactivateOffer,
        { selectedRowIds: [this.localOffer.id] },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>

<style></style>
