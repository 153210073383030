function zeroPad(n, w) {
  while (n.toString().length < w) n = '0' + n
  return parseInt(n)
}

function toNumbers(s) {
  var nums = ''
  for (var i = 0; i < s.length; i++) {
    nums += zeroPad(s.charCodeAt(i), 3)
  }
  return parseInt(nums)
}

const colorClasses = [
  'bg-blue-500',
  'bg-grey-500',
  'bg-green-500',
  'bg-purple-600',
  'bg-pink-600',
  'bg-yellow-500',
  'bg-orange-600',
  'bg-teal-600'
]

export default class StringToCssColor {
  constructor() {
    // this.colorClass = null
  }

  get(string) {
    return colorClasses[toNumbers(string) % colorClasses.length]
  }
}
