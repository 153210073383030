var render = function render(){
  var _vm$dailyGraphData$da, _vm$dailyGraphData$da2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$apollo.queries.dailyGraphData.loading ? _c('Spinner', {
    attrs: {
      "size": 12,
      "color": "green"
    }
  }) : _vm._e(), _vm.dailyGraphData ? _c('YTable', {
    attrs: {
      "data": _vm.dailyGraphData.data || []
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [props.rowData.event_date ? [_vm._v(_vm._s(props.rowData.event_date))] : _vm._e()], 2), _c('td', [[_vm._v(_vm._s(props.rowData.visits.toLocaleString('en-US')))]], 2), _c('td', [[_vm._v(_vm._s(props.rowData.conversions.toLocaleString('en-US')))]], 2), props.rowData.hasOwnProperty('payout') ? _c('td', [_vm._v(" $" + _vm._s(props.rowData.payout.toFixed(2).toLocaleString('en-US')) + " ")]) : _vm._e(), _c('td', [[_vm._v("$" + _vm._s(props.rowData.revenue.toLocaleString('en-US')))]], 2), props.rowData.hasOwnProperty('netRevenue') ? _c('td', [_vm._v(" $" + _vm._s(props.rowData.netRevenue.toFixed(2).toLocaleString('en-US')) + " ")]) : _vm._e()];
      }
    }], null, false, 340563759)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Date")]), _c('th', [_vm._v("Impressions")]), _c('th', [_vm._v("Conversions")]), (_vm$dailyGraphData$da = _vm.dailyGraphData.data[0]) !== null && _vm$dailyGraphData$da !== void 0 && _vm$dailyGraphData$da.hasOwnProperty('payout') ? _c('th', [_vm._v("Payout")]) : _vm._e(), _c('th', [_vm._v("Revenue")]), (_vm$dailyGraphData$da2 = _vm.dailyGraphData.data[0]) !== null && _vm$dailyGraphData$da2 !== void 0 && _vm$dailyGraphData$da2.hasOwnProperty('netRevenue') ? _c('th', [_vm._v(" Net Revenue ")]) : _vm._e()])], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }