var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-body relative flex-grow",
    class: _vm.classes
  }, [_c('div', {
    class: ['widget-body-inner', _vm.scrollable ? 'absolute h-full w-full is-scrollable left-0' : '', _vm.innerWidgetClass]
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }