<template>
  <YTable
    :data="rules"
    :bottom-loading="bottomLoading"
    :selectable="true"
    :selected.sync="selectedRowIds"
  >
    <template slot="header-row">
      <th v-if="!hide.includes('filterable')"></th>
      <th></th>
      <th colspan="2">Traffic Conditions</th>
      <th></th>
    </template>
    <template slot="secondHeaderRow">
      <th v-if="!hide.includes('filterable')"></th>
      <th>
        <div class="flex">
          <div class="flex items-center mr-5">
            <div class="rounded-full w-2 h-2 bg-red-600 mr-2 shadow-md"></div>
            <span class="text-gray-500">Blocked</span>
          </div>
          <div class="flex items-center">
            <div class="rounded-full w-2 h-2 bg-blue-600 mr-2 shadow-md"></div>
            <span class="text-gray-500">Only</span>
          </div>
        </div>
      </th>
      <th><span class="text-gray-500">Countries</span></th>
      <th><span class="text-gray-500">Mobile / Desktop</span></th>
      <th></th>
    </template>
    <template slot="row" slot-scope="props">
      <td
        v-if="!hide.includes('filterable')"
        :class="
          filterableColumnPolarity(
            props.rowData.ruleablePolarity,
            props.rowData.filterablePolarity
          )
            ? ''
            : 'blocked-column'
        "
      >
        <span class="uppercase text-xs font-semibold text-gray-600">
          <template
            v-if="
              props.rowData.filterableType == 'tag' && props.rowData.filterable
            "
          >
            {{ props.rowData.filterable.type }}
          </template>
          <template v-else>
            {{ props.rowData.filterableType }}
          </template>
        </span>
        <div v-if="props.rowData.filterableType == 'offer'">
          <router-link
            v-if="props.rowData.filterable"
            :to="{
              name: 'Offer Edit',
              params: { id: props.rowData.filterableId }
            }"
            class="link"
          >
            <template>{{ props.rowData.filterable.label }}</template>
          </router-link>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.filterableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.filterableType == 'advertiser'">
          <router-link
            v-if="props.rowData.filterable"
            :to="{
              name: 'Advertiser',
              params: { id: props.rowData.filterableId }
            }"
            class="link"
          >
            <template>{{ props.rowData.filterable.label }}</template>
          </router-link>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.filterableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.filterableType == 'advertiserAccount'">
          <router-link
            v-if="props.rowData.filterable"
            :to="{
              name: 'Advertiser',
              params: { id: props.rowData.filterable.userId }
            }"
            class="link"
          >
            <template>{{ props.rowData.filterable.label }}</template>
          </router-link>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.filterableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.filterableType == 'template'">
          <div
            v-if="props.rowData.filterable"
            :class="
              filterableColumnPolarity(
                props.rowData.ruleablePolarity,
                props.rowData.filterablePolarity
              )
                ? 'text-blue-600'
                : 'text-red-600'
            "
          >
            {{ props.rowData.filterable.path }}
          </div>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.filterableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.filterableType == 'tag'">
          <template v-if="props.rowData.filterable">
            <Tag :color="props.rowData.filterablePolarity ? 'blue' : 'red'">{{
              props.rowData.filterable.name
            }}</Tag>
          </template>
          <template v-else>
            <i class="text-gray-500"
              >No record found for Tag ID {{ props.rowData.filterableId }}</i
            >
          </template>
        </div>
      </td>
      <td
        :class="
          ruleableColumnPolarity(
            props.rowData.ruleablePolarity,
            props.rowData.filterablePolarity
          )
            ? ''
            : 'blocked-column'
        "
      >
        <span class="uppercase text-xs font-semibold text-gray-600">
          {{ props.rowData.ruleableType }}
        </span>
        <div v-if="props.rowData.ruleableType == 'redirect'">
          <template v-if="props.rowData.ruleable">
            <div>
              <router-link
                :to="{
                  name: 'Redirect',
                  params: { id: props.rowData.ruleableId }
                }"
                class="link"
              >
                <template>{{ props.rowData.ruleable.label }}</template>
              </router-link>
            </div>
            <span class="italic text-xs text-gray-500">{{
              props.rowData.ruleable.user.label
            }}</span>
          </template>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.ruleableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.ruleableType == 'campaign'">
          <router-link
            v-if="props.rowData.ruleable"
            :to="{
              name: 'Campaign',
              params: { id: props.rowData.ruleableId }
            }"
            class="link"
          >
            <template>{{ props.rowData.ruleable.label }}</template>
          </router-link>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.ruleableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.ruleableType == 'publisher'">
          <router-link
            v-if="props.rowData.ruleable"
            :to="{
              name: 'User',
              params: { id: props.rowData.ruleableId }
            }"
            class="link"
          >
            <template>{{ props.rowData.ruleable.label }}</template>
          </router-link>
          <template v-else>
            <i class="text-gray-500"
              >No record found for ID {{ props.rowData.ruleableId }}</i
            >
          </template>
        </div>
        <div v-if="props.rowData.ruleableType == 'source'">
          <router-link
            v-if="props.rowData.ruleableId"
            :to="{
              name: 'Source',
              params: { id: props.rowData.ruleableId }
            }"
            class="link"
          >
            <template>{{ props.rowData.ruleableId }}</template>
          </router-link>
        </div>
      </td>
      <td>
        <template
          v-for="tagGroup in props.rowData.tagsByType.filter(
            tagGroup => tagGroup.type == 'countryCode'
          )"
        >
          <template v-for="tag in tagGroup.tags">
            <Tag
              :key="tag.id"
              class="m-1"
              :color="tag.isNot ? 'red' : 'blue'"
              >{{ tag.name }}</Tag
            >
          </template>
        </template>
      </td>
      <td>
        <template
          v-for="tagGroup in props.rowData.tagsByType.filter(
            tagGroup => tagGroup.type == 'mobileDesktop'
          )"
        >
          <template v-for="tag in tagGroup.tags">
            <Tag
              :key="tag.id"
              class="m-1"
              :color="tag.isNot ? 'red' : 'blue'"
              >{{ tag.name }}</Tag
            >
          </template>
        </template>
      </td>
      <td style="width: 4rem;">
        <div class="flex">
          <v-popover class="p-0 flex items-center mr-2" placement="right">
            <a
              class="tooltip-target inline-block text-gray-400 hover:text-gray-500 mt-1"
              ><Icon name="history"
            /></a>

            <!-- This will be the content of the popover -->
            <template slot="popover">
              <div
                v-if="props.rowData.createdBy"
                class="flex flex-col p-2 text-xs"
              >
                <span class="font-bold">
                  {{
                    props.rowData.createdAt
                      | luxon({
                        output: { format: 'yyyy-MM-dd @ h:mm a', zone: 'local' }
                      })
                  }}
                </span>
                <span class="text-gray-500 italic">
                  {{ props.rowData.createdBy.email }}
                </span>
              </div>
              <div v-else class="text-gray-400 p-2">No History</div>
            </template>
          </v-popover>
          <v-popover offset="2" class="p-0" placement="right">
            <YButton
              class="hover:bg-gray-100 focus:bg-gray-100 outline-none-important text-gray-500 hover:text-blue-600"
            >
              <Icon name="dotsVertical" class="w-3 h-3" />
            </YButton>
            <!-- This will be the content of the popover -->
            <template slot="popover">
              <ul>
                <li>
                  <router-link
                    :to="{
                      name: 'Edit Rule',
                      params: { id: props.rowData.id }
                    }"
                  >
                    <YButton
                      class="text-blue-600 hover:text-blue-800 truncate w-full"
                      >Edit</YButton
                    >
                  </router-link>
                </li>
                <li>
                  <ClickAndReplace :duration="5000">
                    <YButton class="text-red-500 hover:bg-red-100"
                      >Delete</YButton
                    >
                    <template v-slot:replacement>
                      <YButton
                        class="bg-red-500 text-white hover:bg-red-600"
                        @click="deleteRule(props.rowData.id)"
                        :is-loading="isDeleting"
                        >Confirm Delete</YButton
                      >
                    </template>
                  </ClickAndReplace>
                </li>
              </ul>
            </template>
          </v-popover>
        </div>
      </td>
    </template>
  </YTable>
</template>

<script>
import DELETE_RULE_MUTATION from '@/graphql/Rule/DeleteRuleMutation.gql'
import ClickAndReplace from '@/components/ui/ClickAndReplace'

export default {
  apollo: {},
  components: {
    ClickAndReplace
  },
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    orderBy: {
      type: Array,
      default: () => []
    },
    bottomLoading: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isDeleting: false,
      selectedRowIds: []
    }
  },
  watch: {
    selectedRowIds: {
      handler(newValue) {
        this.$store.dispatch('rule/updateSelectedRowIds', newValue)
      },
      deep: true
    }
  },
  mounted() {
    this.$events.listen('ruleDeleted', () => {
      this.selectedRowIds = []
    })
  },
  methods: {
    ruleableColumnPolarity(ruleablePolarity, filterablePolarity) {
      if (ruleablePolarity == true && filterablePolarity == false) {
        return false
      }
      if (ruleablePolarity == false && filterablePolarity == false) {
        return true
      }

      return false
    },
    filterableColumnPolarity(ruleablePolarity, filterablePolarity) {
      if (ruleablePolarity == true && filterablePolarity == false) {
        return true
      }
      if (ruleablePolarity == false && filterablePolarity == false) {
        return true
      }

      return false
    },
    deleteRule(id) {
      this.isDeleting = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: DELETE_RULE_MUTATION,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.isDeleting = false
          this.$store.dispatch('rule/invalidateCache')
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Rule Deleted', 'Success!')
          this.$events.emit('ruleDeleted')
          this.$events.emit('refreshAlgorithmsData')
          this.$tabEvent.emit('ruleDeleted')
          this.$tabEvent.emit('refreshAlgorithmsData')
        })
        .catch(error => {
          this.isDeleting = false
          this.$store.dispatch('updateGlobalLoader', -1)
          console.error(error)
        })
    }
  },
  beforeDestroy() {
    if (this.isDeleting == true) {
      this.$store.dispatch('updateGlobalLoader', -1)
    }
  },
  computed: {}
}
</script>

<style lang="postcss" scoped>
.blocked-column .link {
  @apply text-red-600;
}
.blocked-colomn .link:hover {
  @apply text-red-800;
}
</style>
