<template>
  <Tabs show-type="show">
    <Tab name="Alf Listings" :active="true">
      <AlfListings />
    </Tab>
    <Tab name="Alf Exceptions">
      <AlfExceptions />
    </Tab>
  </Tabs>
</template>

<script>
import AlfListings from '@/views/Admin/Alf/AlfListings'
import AlfExceptions from '@/views/Admin/Alf/AlfExceptions'

export default {
  components: {
      AlfListings,
      AlfExceptions
  },
  props: {
    tab: {
      type: String,
      default: 'alf-listings'
    }
  },
  computed: {
    tabRoute() {
      return { name: 'Alf', params: { tab: this.tab } }
    }
  }
}
</script>

<style></style>
