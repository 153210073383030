<template>
  <div class="pt-24 relative">
    <div class="absolute top-0 w-full py-2">
      <YButton
        color="blue"
        class="w-full block"
        @click="save()"
        :is-loading="isSaving"
        :disabled="!hasChanges"
        >Save Config</YButton
      >
      <div class="add-options pt-2">
        <Tag color="green" @click="addGeo">+ Geo</Tag>
        <Tag color="indigo" @click="addLanguage">+ Lang</Tag>
        <Tag color="blue" @click="addMobile">+ Mobile</Tag>
        <Tag color="blue" @click="addDesktop">+ Desktop</Tag>
      </div>
    </div>
    <div class="flex justify-between">
      <div class="uppercase font-semibold text-gray-700">Root Var</div>
      <div>
        <input
          class="w-10 pl-2 py-1"
          type="number"
          v-model="rootCount"
          min="1"
        />
      </div>
    </div>
    <div v-if="addedGeos && addedGeos.length > 0">
      <div class="uppercase font-semibold text-gray-700">Geos</div>
      <div v-for="(geo, index) in addedGeos" :key="geo.value">
        <div>
          <Superselect
            title="Geo"
            v-model="geo.value"
            :options="countryOptions"
            :multiple="false"
            class="mb-2"
          >
            <template v-slot:beforeControlIcons>
              <input
                class="w-10 mr-1 mt-1 pl-2"
                type="number"
                v-model="geo.count"
                min="1"
              />
            </template>
            <template v-slot:bottom-right>
              <div class="mb-1 mr-1">
                <a
                  class="link text-gray-400 hover:text-red-600"
                  @click="deleteGeo(index)"
                >
                  <Icon name="trashCan" class="h-4 w-4" />
                </a>
              </div>
            </template>
          </Superselect>
        </div>
      </div>
    </div>
    <div v-if="addedLanguages && addedLanguages.length > 0">
      <div class="uppercase font-semibold text-gray-700">Languages</div>
      <div v-for="(lang, index) in addedLanguages" :key="lang.value">
        <div>
          <Superselect
            title="Language"
            v-model="lang.value"
            :options="languageOptions"
            :multiple="false"
            class="mb-2"
          >
            <template v-slot:beforeControlIcons>
              <input
                class="w-10 mr-1 mt-1 pl-2"
                type="number"
                v-model="lang.count"
                min="1"
              />
            </template>
            <template v-slot:bottom-right>
              <div class="mb-1 mr-1">
                <a
                  class="link text-gray-400 hover:text-red-600"
                  @click="deleteLanguage(index)"
                >
                  <Icon name="trashCan" class="h-4 w-4" />
                </a>
              </div>
            </template>
          </Superselect>
        </div>
      </div>
    </div>
    <div v-if="addedMobile" class="mt-4 mb-4">
      <div class="flex justify-between align-items-center">
        <div class="uppercase font-semibold text-gray-700">Mobile</div>
        <div class="flex align-items-center">
          <input
            class="w-10 mr-2 pl-2"
            type="number"
            v-model="mobileCount"
            min="1"
          />
          <a
            class="link text-gray-400 hover:text-red-600 block"
            @click="deleteMobile()"
          >
            <Icon name="trashCan" class="h-4 w-4" />
          </a>
        </div>
      </div>
    </div>
    <div v-if="addedDesktop" class="mb-2">
      <div class="flex justify-between align-items-center">
        <div class="uppercase font-semibold text-gray-700">Desktop</div>
        <div class="flex align-items-center">
          <input
            class="w-10 mr-2 pl-2"
            type="number"
            v-model="desktopCount"
            min="1"
          />
          <a
            class="link text-gray-400 hover:text-red-600 block"
            @click="deleteDesktop()"
          >
            <Icon name="trashCan" class="h-4 w-4" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import SAVE_OFFER_VARS_CONFIG_MUTATION from '@/graphql/Offer/SaveOfferVarsConfigMutation.gql'
import TEMPLATE_SUPPORTED_LANGUAGES_QUERY from '@/graphql/Template/TemplateSupportedLanguagesQuery.gql'

export default {
  props: {
    offer: {
      type: Object
    }
  },
  apollo: {
    countries: {
      query: COUNTRIES_QUERY,
      result() {
        this.meLoaded = true
      }
    },
    templateSupportedLanguages: {
      query: TEMPLATE_SUPPORTED_LANGUAGES_QUERY
    }
  },
  mounted() {
    this.initializeData()
  },
  data() {
    return {
      rootCount: 1,
      addedGeos: [],
      addedLanguages: [],
      addedMobile: false,
      addedDesktop: false,
      countries: [],
      mobileCount: 1,
      desktopCount: 1,
      templateSupportedLanguages: [],
      isSaving: false,
      savedConfig: {}
    }
  },
  computed: {
    config: {
      get() {
        const configuration = {
          rootCount: this.rootCount,
          geos: this.addedGeos?.filter(geo => geo.value) ?? undefined,
          languages:
            this.addedLanguages?.filter(language => language.value) ??
            undefined,
          mobile: this.addedMobile ? this.mobileCount : null,
          desktop: this.addedDesktop ? this.desktopCount : null
        }
        return configuration
      },
      set(config) {
        this.rootCount = config.rootCount
        this.addedGeos = config.geos ?? []
        this.addedLanguages = config.languages ?? []
        this.addedMobile = this.mobile ? true : false
        this.mobileCount = this.mobile ?? 1
        this.addedDesktop = this.desktop ? true : false
        this.mobileCount = this.desktop ?? 1
      }
    },
    hasChanges() {
      return !this._.isEqual(
        JSON.parse(JSON.stringify(this.savedConfig)),
        JSON.parse(JSON.stringify(this.config))
      )
    },
    keyEndings() {
      const endings = []
      for (let i = 1; i <= this.config.rootCount; i++) {
        if (i == 1) {
          continue
        }
        endings.push('_' + i)
      }

      if (this.config.geos > 0) {
        this.config.geos.forEach(function(geo) {
          endings.push('_' + geo.value)
          for (let i = 1; i <= this.geo.count; i++) {
            if (i == 1) {
              continue
            }
            endings.push('_' + geo.value + '_' + i)
          }
        })
      }

      if (this.config.languages > 0) {
        this.config.languages.forEach(function(language) {
          endings.push('_lang_' + language.value)
          for (let i = 1; i <= this.language.count; i++) {
            if (i == 1) {
              continue
            }
            endings.push('_lang_' + language.value + '_' + i)
          }
        })
      }

      if (this.config.mobile) {
        endings.push('_mobile')
        for (let i = 1; i <= this.config.mobile; i++) {
          if (i == 1) {
            continue
          }
          endings.push('_mobile_' + i)
        }
      }

      if (this.config.desktop) {
        endings.push('_desktop')
        for (let i = 1; i <= this.config.desktop; i++) {
          if (i == 1) {
            continue
          }
          endings.push('_desktop_' + i)
        }
      }

      return endings
    },
    addedGeoCodes() {
      return this.addedGeos?.map(geo => geo.value) ?? []
    },
    addedLanguageCodes() {
      return this.addedLanguages?.map(lang => lang.value) ?? []
    },
    countryOptions() {
      return this.countries
        .filter(
          country =>
            !this.addedGeoCodes.includes(country.code) && country.code != '?'
        )
        .map(country => country.code?.toLowerCase())
    },
    languageOptions() {
      return this.templateSupportedLanguages.filter(
        language => !this.addedLanguageCodes.includes(language)
      )
    }
  },
  methods: {
    save() {
      this.isSaving = true
      this.$apollo
        .mutate({
          mutation: SAVE_OFFER_VARS_CONFIG_MUTATION,
          variables: {
            input: {
              offerId: this.offer.id,
              config: this.config
            }
          }
        })
        .then(({ data }) => {
          this.$emit('updated', data.saveOfferVarsConfig)
          this.savedConfig = data.saveOfferVarsConfig.config
          this.isSaving = false
        })
        .catch(error => {
          this.isSaving = false
          console.error(error)
        })
    },
    initializeData() {
      if (this.offer?.varsConfig?.config) {
        this.config = this.offer?.varsConfig?.config
        this.savedConfig = JSON.parse(
          JSON.stringify(this.offer?.varsConfig?.config ?? {})
        )
      }
    },
    addGeo() {
      this.addedGeos.push({ value: null, count: 1 })
    },
    addLanguage() {
      this.addedLanguages.push({ value: null, count: 1 })
    },
    addMobile() {
      this.addedMobile = true
    },
    addDesktop() {
      this.addedDesktop = true
    },
    deleteGeo(index) {
      this.$delete(this.addedGeos, index)
    },
    deleteLanguage(index) {
      this.$delete(this.addedLanguages, index)
    },
    deleteMobile() {
      this.addedMobile = false
    },
    deleteDesktop() {
      this.addedDesktop = false
    }
  }
}
</script>

<style scoped>
.add-options > span {
  @apply mr-1;
}
</style>
