<template>
  <YTable
    v-if="autoAbyssedRedirects.data"
    :data="autoAbyssedRedirects.data || []"
    :bottom-loading="$apolloData.queries.autoAbyssedRedirects.loading"
  >
    <template slot="header-row">
      <th>Redirect</th>
      <th>Campaign</th>
      <th>revenue</th>
      <th>previous revenue</th>
      <th>visits</th>
      <th>previous visits</th>
      <th>reset</th>
    </template>
    <template v-slot:row="props">
      <td>
          <router-link
                :to="{
                  name: 'Redirect',
                  params: { id: props.rowData.redirect.id }
                }"
                class="link"
              >
                {{props.rowData.redirect.label}}
          </router-link>
          <br>
           <router-link
                :to="{
                  name: 'User',
                  params: { id: props.rowData.redirect.user.id }
                }"
                class="link"
              >
                {{props.rowData.redirect.user.label}}
          </router-link>
         
      </td>
      <td>
         <router-link
                :to="{
                  name: 'Campaign',
                  params: { id: props.rowData.redirect.campaign.id }
                }"
                class="link"
              >
                {{props.rowData.redirect.campaign.label}}
          </router-link>
      </td>
      <td>{{ props.rowData.revenue }}</td>
      <td>{{ props.rowData.previousRevenue }}</td>
      <td>{{ props.rowData.visits }}</td>
      <td>{{ props.rowData.previousVisits }}</td>
      <td v-if="props.rowData.reset">{{ props.rowData.reset }}</td>
      <td v-else>Indefinite</td>
    </template>
  </YTable>
</template>

<script>
import AUTO_ABYSSED_REDIRECT from '@/graphql/Redirect/AutoAbyssedRedirectQuery.gql'
export default {
  data() {
    return {}
  },
  apollo: {
    autoAbyssedRedirects: {
      query: AUTO_ABYSSED_REDIRECT,
      variables() {
        return {
          first: 10000,
          filters: {}
        }
      }
    }
  }
}
</script>
