var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Replace Subdomain ")]), _c('widget-body', [_c('div', {
    staticClass: "mb-1"
  }, [_c('div', {
    staticClass: "text-xs text-indigo-600"
  }, [_vm._v("Current Subdomain")]), _c('div', [_vm._v(_vm._s(_vm.redirect.subdomain))])]), _c('div', {
    staticClass: "mb-1"
  }, [_c('div', {
    staticClass: "text-xs text-indigo-600"
  }, [_vm._v("New Subdomain")]), _c('div', [_vm._v(_vm._s(_vm.subdomainGenerator.subdomain))])])]), _c('widget-footer', {
    staticClass: "bg-gray-100 py-2 px-4 text-right"
  }, [_c('y-button', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.saveSubdomain,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.saveSubdomain.apply(null, arguments);
      }
    }
  }, [_vm._v("Replace Subdomain")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }