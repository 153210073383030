var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.className
  }, _vm._l(_vm.radios, function (radio) {
    return _c('y-radio', {
      key: radio.key ? radio.key : _vm.toKebabCase(radio.label),
      class: _vm.radioClassName,
      attrs: {
        "name": _vm.name,
        "radio-value": radio.value,
        "label": radio.label,
        "disabled": _vm.disabled,
        "inline": _vm.radiosInline,
        "data-id": radio.id ? radio.id : _vm.name + '__' + _vm.toKebabCase(radio.label)
      },
      model: {
        value: _vm.radioButtonValue,
        callback: function ($$v) {
          _vm.radioButtonValue = $$v;
        },
        expression: "radioButtonValue"
      }
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }