<template>
  <div class="w-full grid grid-cols-2 gap-1">
    <div>
      <YInput
        v-model="filters.conversionType.value"
        label="Conversion Type"
      ></YInput>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  apollo: {},
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapState({
      filters: state => state.conversionType.filters
    })
  }
}
</script>

<style></style>
