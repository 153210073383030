<template>
  <div style="background: #f8fafc " v-if="user">
    <div class="container p-6 m-auto">
      <div class="flex gap-5 py-6">
        <Widget class="w-1/3">
          <WidgetHeader>
            User Details
          </WidgetHeader>
          <WidgetBody class="pt-0">
            <ul>
              <li>
                <span class="font-semibold ">Contact:</span>
                {{ user.primaryContact.name }}
              </li>
              <li>
                <span class="font-semibold ">Status:</span>
                {{ user.status.toUpperCase() }}
              </li>
              <li v-if="user.lastLogin">
                <span class="font-semibold ">Last Login:</span>
                {{
                  user.lastLogin.createdAt
                    .split('')
                    .slice(0, 10)
                    .join('')
                }}
              </li>
            </ul>
          </WidgetBody>
        </Widget>
        <Widget class="w-1/3">
          <WidgetHeader>
            <div class="flex">
              Total Revenue
              <span
                class="ml-3 flex "
                :class="{
                  'text-red-600': this.revenuePercentChange < 0,
                  'text-green-600': this.revenuePercentChange >= 0
                }"
                v-show="this.revenuePercentChange"
              >
                <Icon
                  class="w-4 h-4 mt-1"
                  :name="
                    this.revenuePercentChange < 0 ? 'arrowDown' : 'arrowUp'
                  "/>
                <animated-number
                  :value="revenuePercentChange"
                  :duration="1000"
                  :formatValue="formatPercentage"
                  easing="easeOutQuad"
                ></animated-number
              ></span>
            </div>
          </WidgetHeader>
          <WidgetBody class="pt-0">
            <div class="text-2xl md:text-4xl">
              <animated-number
                :value="totalRevenue"
                :formatValue="toLocaleCurrency"
                :duration="1000"
                easing="easeOutExpo"
              ></animated-number></div
          ></WidgetBody>
        </Widget>

        <Widget class="w-1/3">
          <WidgetHeader>
            <div class="flex">
              Overall Impressions
              <span
                class="ml-1 flex"
                :class="{
                  'text-red-600': this.visitorPercentChange < 0,
                  'text-green-600': this.visitorPercentChange >= 0
                }"
                v-show="this.visitorPercentChange"
              >
                <Icon
                  class="w-4 h-4 mt-1"
                  :name="
                    this.visitorPercentChange < 0 ? 'arrowDown' : 'arrowUp'
                  "/>
                <animated-number
                  :value="visitorPercentChange"
                  :duration="1000"
                  :formatValue="formatPercentage"
                  easing="easeOutQuad"
                ></animated-number
              ></span>
            </div>
          </WidgetHeader>
          <WidgetBody class="pt-0">
            <div class="text-2xl md:text-4xl">
              <animated-number
                :value="totalVisitors"
                :formatValue="toLocaleString"
                :duration="1000"
                easing="easeOutExpo"
              ></animated-number></div
          ></WidgetBody>
        </Widget>
      </div>

      <!-- second row -->
      <div class="flex gap-5 py-6">
        <Widget
          :loading="$apollo.queries.dailyGraphData.loading"
          class="w-full"
        >
          <WidgetHeader>
            Snapshot - Last 20 days
          </WidgetHeader>
          <WidgetBody>
            <div class="w-full h-auto gap-5">
              <div
                style="
          width:100%;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
                     "
              >
                <y-chart
                  v-if="!$apollo.queries.dailyGraphData.loading"
                  :chart-data="chartData"
                  :date-range="dateRange"
                  :height="300"
                ></y-chart>
              </div>
            </div>
          </WidgetBody>
        </Widget>
      </div>

      <div class="grid grid-cols-5 gap-4">
        <div class="col-span-2">
          <Widget>
            <!--quality metrices, with ips -->
            <WidgetHeader>Quality Metrics</WidgetHeader>
            <WidgetBody>
              <div class="w-100 mb-2">
                <span class="font-bold">Duplicate IP Log</span>
              </div>
              <div v-if="user.confidenceScoreQuality?.ipHistory">
                <div
                  v-for="(history, index) in user.confidenceScoreQuality
                    .ipHistory"
                  :key="index"
                  class="mb-3"
                >
                  <h3 class="font-semibold mb-1 truncate">{{ history.ip }}</h3>
                  <table class="w-full bg-gray-100">
                    <thead>
                      <tr class="text-gray-700">
                        <th>User Id</th>
                        <th>Action Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(match, index) in history.matches"
                        :key="index"
                        style="border-bottom: 1px solid #efefef;"
                      >
                        <td class="pl-2">
                          <router-link
                            :to="{
                              name: 'User',
                              params: { id: match.causerId }
                            }"
                            class="link block"
                            >{{ match.causerId }}</router-link
                          >
                        </td>
                        <td class="text-center">{{ match.type }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-else>
                No duplicates reported
              </div>
            </WidgetBody>
          </Widget>
        </div>
        <div class="col-span-3 col-start-3">
          <div class="flex flex-col gap-4">
            <Widget>
              <!-- registration score -->
              <WidgetHeader class="pb-0">
                Registration Score

                <a
                  href="https://docs.ytrack.io/sales/publishers/user-auto-rejection"
                  target="_blank"
                  class="inline-block"
                >
                  <Icon
                    name="openInNew"
                    class="text-gray-700 w-2 h-2 ml-1 cursor-pointer"
                    v-tooltip="'See yDocs'"
                /></a>
              </WidgetHeader>
              <WidgetBody
                v-if="user.confidenceScoreAtRegistration"
                class="pt-0"
              >
                <div class="w-100 flex justify-end">
                  <span class="text-gray-800 text-xs"
                    >*Auto Qualifers Only</span
                  >
                </div>
                <table class="min-w-full leading-normal">
                  <tr>
                    <th class="text-left">qualifier</th>
                    <th class="text-left">Result</th>
                  </tr>

                  <tr
                    v-for="qualifier in filteredQualifiers"
                    :key="qualifier.name"
                    class="border-b"
                  >
                    <td>
                      <div class="w-full flex">
                        {{ qualifier.name }}
                        <Icon
                          name="information"
                          class="text-gray-400 ml-4"
                          v-tooltip="qualifier.description"
                        />
                      </div>
                    </td>
                    <td>
                      {{ qualifier.status ? 'Fail' : 'Pass' }}
                    </td>
                  </tr>
                </table>
              </WidgetBody>
              <WidgetBody v-if="!user.confidenceScoreAtRegistration"
                ><span class="font-bold">Registration:</span> Nothing to
                Report</WidgetBody
              >
            </Widget>
            <Widget>
              <!--traffic score -->
              <WidgetHeader class="pb-0">
                Traffic Score
              </WidgetHeader>
              <WidgetBody>
                <table
                  class="min-w-full leading-normal"
                  v-if="user.confidenceScoreQuality"
                >
                  <tr>
                    <th class="text-left">qualifier</th>
                    <th>count</th>
                  </tr>

                  <tr
                    v-for="qualifier in filteredQualityScore"
                    :key="qualifier.qualifierName"
                    class="border-b"
                  >
                    <td>
                      {{ qualifier.name }}
                    </td>
                    <td style="text-align: center;">
                      <ul class="py-1" v-if="qualifier?.meta">
                        <li
                          v-for="(value, key) in qualifier.meta"
                          :key="key"
                          class="m-1"
                        >
                          {{ metaData(value, key) }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>

                <!--//!-->
                <table
                  class="min-w-full leading-normal"
                  v-if="user.confidenceScoreQuality"
                >
                  <tr
                    v-for="type in qualityCommentTypes"
                    :key="type"
                    class="m-1 border-b"
                  >
                    <td>
                      <div class="flex content-center">
                        <div class="w-40 self-center capitalize">
                          {{
                            type == 'positive'
                              ? type + ' Feedback'
                              : type + ' Complaint'
                          }}
                        </div>
                        <div class="flex-initial mx-6 capitalize">
                          <button
                            @click="openQualityCommentsModal(type)"
                            class="mt-1"
                          >
                            <Icon
                              name="plusBox"
                              :size="6"
                              class="text-blue-600"
                            />
                          </button>
                          <button
                            class="mt-1 ml-3"
                            v-tooltip="'View History'"
                            @click="openQualityCommentsHistoryModal(type)"
                          >
                            <Icon
                              name="history"
                              :size="6"
                              class="text-gray-600"
                            />
                          </button>
                        </div>
                      </div>
                    </td>

                    <td style="width:100px;">
                      {{
                        user.commentsQuality.filter(
                          comment => comment.commentType == type
                        ).length
                      }}
                    </td>
                  </tr>
                </table>
              </WidgetBody>
              <WidgetBody v-if="!user.confidenceScoreQuality"
                ><span class="font-bold">Quality:</span>Nothing to
                Report</WidgetBody
              >
            </Widget>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import AnimatedNumber from 'animated-number-vue'
import YChart from '@/views/Admin/Dashboard/YChart'
import AddQualityComment from '@/views/Admin/User/AddQualityComment.vue'
import QualityCommentHistory from '@/views/Admin/User/QualityCommentHistory.vue'

export default {
  components: {
    AnimatedNumber,
    YChart
  },
  mounted() {},
  updated() {},
  props: {
    user: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    defaultDateRange: {
      type: Object,
      default: function() {
        return {
          start: this.$dateTime
            .local()
            .minus({ days: 20 })
            .toFormat('yyyy-LL-dd'),
          end: this.$dateTime.local().toFormat('yyyy-LL-dd')
        }
      }
    }
  },
  apollo: {
    dailyGraphData: {
      query: gql`
        query getReport(
          $filters: AdminReportFilters!
          $sortOrder: [SortItem!]
          $export: Boolean
          $fields: [String]!
        ) {
          dailyGraphData: adminReport(
            filters: $filters
            sortOrder: $sortOrder
            export: $export
            fields: $fields
          ) {
            data {
              event_date: day
              visits
              revenue
            }
            totals {
              visits
              revenue
            }
          }
        }
      `,
      variables() {
        return {
          filters: this.computedFilters,
          pagination: {
            perPage:
              this.$dateTime
                .fromISO(this.dateRange[1])
                .diff(this.$dateTime.fromISO(this.dateRange[0]), 'days')
                .toObject().days + 1,
            currentPage: 1
          },
          sortOrder: [{ field: 'day', sortField: 'day', direction: 'asc' }],
          export: false,
          fields: ['day', 'visits', 'revenue']
        }
      },
      deep: true
    }
  },
  data() {
    return {
      qualityCommentTypes: ['fraud', 'positive', 'compliance', 'quality'],
      dateRange: [this.defaultDateRange.start, this.defaultDateRange.end],
      filterQualityNames: [
        'High Risk Country',
        'High Risk GeoIP Usage Type',
        'Duplicate Messenger Fraud',
        'Duplicate Login IP Fraud',
        'GeoIP Mismatch',
        'Duplicate IP Limit',
        'Duplicate IP Quarantined'
      ]
    }
  },
  computed: {
    filteredQualifiers() {
      return this.user.confidenceScoreAtRegistration.qualifiers.filter(
        qualifier => this.filterQualityNames.includes(qualifier.name)
      )
    },
    filteredQualityScore() {
      return this.user.confidenceScoreQuality?.qualifiers.filter(
        qualifier => qualifier.name !== 'Quality Comments'
      )
    },
    computedFilters() {
      let filters = this._.cloneDeep(this.filters)
      filters.dateRange = this.dateRange
      return filters
    },
    dailyGraphDataParsed() {
      let output = []
      if (this.dailyGraphData) {
        const range = this.$getDateRange(this.dateRange[0], this.dateRange[1])
        for (let day of range) {
          let entry = {
            revenue: null,
            visits: null
          }
          for (let item of this.dailyGraphData.data) {
            if (day == item.event_date) {
              entry = {
                revenue: item.revenue,
                visits: item.visits
              }
              break
            }
          }
          output.push(entry)
        }
      }
      return output
    },
    yChartDatasetRevenue() {
      if (this.dailyGraphDataParsed.length > 0) {
        let output = this.dailyGraphDataParsed.map(data => data.revenue)
        return output
      } else {
        return []
      }
    },
    yChartDatasetVisitors() {
      if (this.dailyGraphDataParsed.length > 0) {
        let output = this.dailyGraphDataParsed.map(data => data.visits)
        return output
      } else {
        return []
      }
    },
    chartData: function() {
      var output = {
        datasets: [
          {
            label: 'Revenue',
            data: this.yChartDatasetRevenue,
            formatType: 'currency'
          }
        ]
      }
      return output
    },
    totalRevenue: function() {
      var output = this._.sum(this.yChartDatasetRevenue)
      // output = this.toLocaleCurrency(output)
      return output
    },
    revenuePercentChange: function() {
      var first = this._.head(this.yChartDatasetRevenue)
      var last = this._.last(this.yChartDatasetRevenue)
      var increase = last - first
      var percent = (increase / first) * 100
      return percent
    },
    visitorPercentChange: function() {
      var first = this._.head(this.yChartDatasetVisitors)
      var last = this._.last(this.yChartDatasetVisitors)
      var increase = last - first
      var percent = (increase / first) * 100
      return percent
    },
    totalVisitors: function() {
      var output = this._.sum(this.yChartDatasetVisitors)
      return output
    }
  },
  methods: {
    transformText(text) {
      let result = text.replace(/([A-Z])\w+/g, '')
      if (result !== 'positive') {
        return result + ' Complaint'
      } else {
        return result + ' Feedback'
      }
    },
    openQualityCommentsModal(type) {
      this.$modal.show(
        AddQualityComment,
        { userId: this.user.id, eventType: type },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openQualityCommentsHistoryModal(type) {
      this.$modal.show(
        QualityCommentHistory,
        {
          userId: this.user.id,
          eventType: type,
          data: this.user.commentsQuality
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    metaData(value, key) {
      if (key == 'aboveAveragePayoutCount') {
        return 'Above ' + value
      } else if (key == 'belowAveragePayoutCount') {
        return 'Below ' + value
      } else {
        return value
      }
    },
    toLocaleString(num) {
      num = parseInt(num)
      let output = num.toLocaleString()
      return output
    },
    toLocaleCurrency(num) {
      num = parseFloat(num)
      return num.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    formatPercentage(num) {
      var output = Math.round(num)
      output = output < 0 ? output * -1 : output
      output = output == Infinity ? '∞' : output + '%'
      return output
    }
  }
}
</script>

<style scoped>
th {
  @apply uppercase bg-gray-100 border-b;
}
</style>
