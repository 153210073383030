var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-auto bg-white rounded-b-xl mb-12 border-l border-r border-b",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [!_vm.successMessage ? [_c('form', {
    staticClass: "w-full",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "flex flex-col px-6"
  }, [_c('div', {
    staticClass: "mt-6 font-bold text-lg text-inverse-700"
  }, [_vm._v(" Publisher Professional References ")]), _vm._m(0), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "pt-5 pb-2 font-semibold uppercase text-inverse-700"
  }, [_vm._v(" Reference One ")]), _c('div', {
    staticClass: "grid grid-rows-1 gap-3"
  }, [_c('YInput', {
    attrs: {
      "required": "",
      "label": "Company Name",
      "type": "text"
    },
    model: {
      value: _vm.reference1.company,
      callback: function ($$v) {
        _vm.$set(_vm.reference1, "company", $$v);
      },
      expression: "reference1.company"
    }
  }), _c('YInput', {
    attrs: {
      "required": "",
      "label": "Contact Name",
      "type": "text"
    },
    model: {
      value: _vm.reference1.contact,
      callback: function ($$v) {
        _vm.$set(_vm.reference1, "contact", $$v);
      },
      expression: "reference1.contact"
    }
  }), _c('YInput', {
    attrs: {
      "required": "",
      "label": "Company Email",
      "type": "email"
    },
    model: {
      value: _vm.reference1.email,
      callback: function ($$v) {
        _vm.$set(_vm.reference1, "email", $$v);
      },
      expression: "reference1.email"
    }
  })], 1)]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "pt-5 pb-2 font-semibold uppercase text-inverse-700"
  }, [_vm._v(" Reference Two ")]), _c('div', {
    staticClass: "grid grid-rows-1 gap-3"
  }, [_c('YInput', {
    attrs: {
      "required": "",
      "label": "Company Name",
      "type": "text"
    },
    model: {
      value: _vm.reference2.company,
      callback: function ($$v) {
        _vm.$set(_vm.reference2, "company", $$v);
      },
      expression: "reference2.company"
    }
  }), _c('YInput', {
    attrs: {
      "required": "",
      "label": "Contact Name",
      "type": "text"
    },
    model: {
      value: _vm.reference2.contact,
      callback: function ($$v) {
        _vm.$set(_vm.reference2, "contact", $$v);
      },
      expression: "reference2.contact"
    }
  }), _c('YInput', {
    attrs: {
      "required": "",
      "label": "Company Email",
      "type": "email"
    },
    model: {
      value: _vm.reference2.email,
      callback: function ($$v) {
        _vm.$set(_vm.reference2, "email", $$v);
      },
      expression: "reference2.email"
    }
  })], 1)]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "pt-5 pb-2 font-semibold uppercase text-inverse-700"
  }, [_vm._v(" Reference Three ")]), _c('div', {
    staticClass: "grid grid-rows-1 gap-3"
  }, [_c('YInput', {
    attrs: {
      "required": "",
      "label": "Company Name",
      "type": "text"
    },
    model: {
      value: _vm.reference3.company,
      callback: function ($$v) {
        _vm.$set(_vm.reference3, "company", $$v);
      },
      expression: "reference3.company"
    }
  }), _c('YInput', {
    attrs: {
      "required": "",
      "label": "Contact Name",
      "type": "text"
    },
    model: {
      value: _vm.reference3.contact,
      callback: function ($$v) {
        _vm.$set(_vm.reference3, "contact", $$v);
      },
      expression: "reference3.contact"
    }
  }), _c('YInput', {
    attrs: {
      "required": "",
      "label": "Company Email",
      "type": "email"
    },
    model: {
      value: _vm.reference3.email,
      callback: function ($$v) {
        _vm.$set(_vm.reference3, "email", $$v);
      },
      expression: "reference3.email"
    }
  })], 1)]), _c('div', {
    staticClass: "mt-10 mb-8"
  }, [_c('YButton', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "color": "pink",
      "is-loading": _vm.isSubmitting
    }
  }, [_vm._v("Submit References")])], 1)])])] : _vm._e(), _vm.successMessage ? [_c('div', {
    staticClass: "text-center py-10 px-4"
  }, [_c('h4', {
    staticClass: "font-semibold text-lg mb-1"
  }, [_vm._v(_vm._s(_vm.successMessage))]), _c('p', [_vm._v(" You application will be considered with this additional information. ")])])] : _vm._e(), _vm.errorMessage ? [_c('div', {
    staticClass: "text-center pt-4 pb-10 px-4"
  }, [_c('h4', {
    staticClass: "text-red-500 font-semibold text-lg mb-1"
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]), _c('p', [_vm._v(" Please try again later. ")])])] : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors,
      "portal-to": "errors",
      "alert-class": "text-xs",
      "icon-size": 12,
      "icon": null,
      "color": "yellow"
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-2"
  }, [_vm._v(" We will verify the provided references using your "), _c('strong', [_vm._v("name")]), _vm._v(" and "), _c('strong', [_vm._v(" email address")]), _vm._v(" from your application. Consistency is crucial for approval. ")]);

}]

export { render, staticRenderFns }