var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', {
    staticClass: "text-white text-center leading-10 rounded-full font-extrabold flex items-center justify-center",
    class: `w-${_vm.height} h-${_vm.height} ${_vm.getColorClassFromString(_vm.user.primaryContact.name)}`
  }, [_vm._v(" " + _vm._s(_vm.initials) + " ")]) : _c('Spinner', {
    attrs: {
      "color": "blue",
      "size": 8
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }