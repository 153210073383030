var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Edit Image ")]), _c('widget-body', [_c('form', {
    ref: "upload",
    staticClass: "row",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateImage.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "row flex items-center mb-3"
  }, [_c('div', {
    class: _vm.style.columnTitle
  }, [_vm._v(" Name ")]), _c('div', {
    staticClass: "col w-2/3"
  }, [_c('YInput', {
    attrs: {
      "label": "Name",
      "disabled": ""
    },
    model: {
      value: _vm.imageData.name,
      callback: function ($$v) {
        _vm.$set(_vm.imageData, "name", $$v);
      },
      expression: "imageData.name"
    }
  })], 1)]), _c('div', {
    staticClass: "row flex items-center mb-3"
  }, [_c('div', {
    class: _vm.style.columnTitle
  }, [_vm._v(" Choose Image ")]), _c('div', {
    staticClass: "col"
  }, [_c('ImageInput', {
    key: _vm.isSubmitting,
    staticClass: "rounded",
    attrs: {
      "imageUrl": _vm.thumbnailImageUrl,
      "thumbnailImageUrl": _vm.thumbnailImageUrl,
      "height": 32,
      "width": 32,
      "blankHeight": 20,
      "blankWidth": 20,
      "editable": true,
      "editable-only-when-empty": false,
      "file": _vm.imageFile
    },
    on: {
      "update:imageUrl": function ($event) {
        _vm.thumbnailImageUrl = $event;
      },
      "update:image-url": function ($event) {
        _vm.thumbnailImageUrl = $event;
      },
      "update:file": function ($event) {
        _vm.imageFile = $event;
      }
    }
  })], 1)]), _c('div', {
    staticClass: "w-full flex justify-end pt-2"
  }, [_c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSubmitting
    }
  }, [_vm._v("Update Image")])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }