import Vue from 'vue'
import Icon from '@/components/ui/Icon.vue'
import CloseX from '@/components/ui/CloseX.vue'
import Widget from '@/components/ui/Widget.vue'
import WidgetHeader from '@/components/ui/WidgetHeader.vue'
import WidgetBody from '@/components/ui/WidgetBody.vue'
import WidgetFooter from '@/components/ui/WidgetFooter.vue'
import YFormGroup from '@/components/ui/YFormGroup.vue'
import YCheckbox from '@/components/ui/YCheckbox.vue'
import YInput from '@/components/ui/YInput.vue'
import Multiselect from 'vue-multiselect'
import Superselect from '@/components/ui/Superselect.vue'
import YMultitags from '@/components/ui/YMultitags.vue'
import YMultiselect from '@/components/ui/YMultiselect/src/ymultiselect.vue'
import YTable from '@/components/ui/YTable.vue'
import Tag from '@/components/ui/Tag.vue'
import LoaderDots from '@/components/ui/LoaderDots.vue'
import OrderByItem from '@/components/ui/OrderByItem.vue'
import Spinner from '@/components/ui/Spinner.vue'
import Tabs from '@/components/ui/Tabs.vue'
import Tab from '@/components/ui/Tab.vue'
import Flag from '@/components/ui/Flag.vue'
import YButton from '@/components/ui/YButton.vue'
import YAlert from '@/components/ui/YAlert.vue'
import Condition from '@/views/Admin/Rules/Condition.vue'
import PayoutsHistoryRow from '@/components/legacy/VuetableDetailRows/PayoutsHistoryRow.vue'
import YRadios from '@/components/legacy/yRadios'
import YConfirm from '@/components/legacy/yConfirm.vue'
import YConfirmModal from '@/components/legacy/yConfirmModal.vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import EditableValue from '@/components/utilities/EditableValue.vue'
import YTextarea from '@/components/ui/YTextarea.vue'
import ActiveUser from '@/components/ui/ActiveUser.vue'
import SideBarRightItem from '@/components/layout/SideBarRightItem.vue'
import SideBarComments from '@/views/Admin/Comments/SideBarComments.vue'
import YImage from '@/components/ui/YImage.vue'
import YRichText from '@/components/ui/YRichText.vue'
import UserAvatar from '@/components/ui/UserAvatar.vue'
import CopyTextButton from '@/components/ui/CopyTextButton.vue'
import ProgressBar from 'vue-simple-progress'
import PublisherAlert from '@/components/general/PublisherAlert.vue'
import SpamhausCheckPopover from '@/components/ui/SpamhausCheckPopover.vue'

Vue.component('Icon', Icon)
Vue.component('CloseX', CloseX)
Vue.component('Widget', Widget)
Vue.component('WidgetHeader', WidgetHeader)
Vue.component('WidgetBody', WidgetBody)
Vue.component('WidgetFooter', WidgetFooter)
Vue.component('YFormGroup', YFormGroup)
Vue.component('YCheckbox', YCheckbox)
Vue.component('YInput', YInput)
Vue.component('Multiselect', Multiselect)
Vue.component('Superselect', Superselect)
Vue.component('YMultitags', YMultitags)
Vue.component('YMultiselect', YMultiselect)
Vue.component('YTable', YTable)
Vue.component('Tag', Tag)
Vue.component('Flag', Flag)
Vue.component('LoaderDots', LoaderDots)
Vue.component('OrderByItem', OrderByItem)
Vue.component('Spinner', Spinner)
Vue.component('Tabs', Tabs)
Vue.component('Tab', Tab)
Vue.component('YButton', YButton)
Vue.component('YAlert', YAlert)
Vue.component('Condition', Condition)
Vue.component('EditableValue', EditableValue)
Vue.component('YTextarea', YTextarea)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
Vue.component('ActiveUser', ActiveUser)
Vue.component('SideBarRightItem', SideBarRightItem)
Vue.component('SideBarComments', SideBarComments)
Vue.component('YImage', YImage)
Vue.component('YRichText', YRichText)
Vue.component('UserAvatar', UserAvatar)
Vue.component('CopyTextButton', CopyTextButton)
Vue.component('ProgressBar', ProgressBar)
Vue.component('PublisherAlert', PublisherAlert)
Vue.component('SpamhausCheckPopover', SpamhausCheckPopover)

//Legacy Imports
Vue.component('YRadios', YRadios)
Vue.component('PayoutsHistoryRow', PayoutsHistoryRow)
Vue.component('YConfirmModal', YConfirmModal)
Vue.component('YConfirm', YConfirm)
