<template>
  <div :class="className">
    <y-radio
      v-for="radio in radios"
      :name="name"
      :class="radioClassName"
      :radio-value="radio.value"
      :label="radio.label"
      v-model="radioButtonValue"
      :disabled="disabled"
      :key="radio.key ? radio.key : toKebabCase(radio.label)"
      :inline="radiosInline"
      :data-id="radio.id ? radio.id : name + '__' + toKebabCase(radio.label)"
    ></y-radio>
  </div>
</template>

<script>
import yRadio from './yRadio'

export default {
  components: {
    yRadio
  },
  props: {
    value: {},
    radios: {
      type: Array
    },
    name: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    inline: {
      type: Boolean
    },
    radiosInline: {
      type: Boolean
    },
    label: {
      type: String
    },
    color: {
      type: String
    },
    radioClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    className() {
      let className = ''
      className = this.color ? className + ' radio-' + this.color : className
      className = this.disabled ? className + ' disabled' : className
      className = this.radiosInline ? className + ' inline-flex' : className
      return className
    },
    radioClassName() {
      let className = this.radioClass
      className = this.color ? className + ' radio-' + this.color : className
      className = this.disabled ? className + ' disabled' : className
      className = this.radiosInline ? className + ' mr-2' : className

      return className
    },
    radioButtonValue: {
      get() {
        return this.value
      },
      set(value) {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit('input', value)
      }
    }
  },

  mounted() {},

  updated() {},

  methods: {
    updateValue(value) {
      var newValue = value
      // Emit the number value through the input event
      this.$emit('input', newValue)
    },
    toKebabCase(string) {
      return this._.kebabCase(string)
    }
  }
}
</script>
<style></style>
