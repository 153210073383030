var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "py-6 border-b",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('i', {
    staticClass: "zmdi zmdi-file-plus pr-2"
  }), _vm._v(" Edit " + _vm._s(_vm.tagType.label) + " Tag ")])]), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Tag Text"
    },
    model: {
      value: _vm.tagText,
      callback: function ($$v) {
        _vm.tagText = $$v;
      },
      expression: "tagText"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Color",
      "options": _vm.colorOptions,
      "multiple": false,
      "allow-empty": false,
      "max-height": 80
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_c('Tag', {
          attrs: {
            "color": props.option
          }
        }, [_vm._v(_vm._s(props.option))])];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_c('Tag', {
          attrs: {
            "color": props.option
          }
        }, [_vm._v(_vm._s(props.option))])];
      }
    }]),
    model: {
      value: _vm.tagColor,
      callback: function ($$v) {
        _vm.tagColor = $$v;
      },
      expression: "tagColor"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 px-4 bg-gray-100 flex justify-between"
  }, [_c('y-checkbox', {
    staticClass: "flex items-center",
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    staticClass: "ml-auto",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "color": "blue"
    },
    on: {
      "click": _vm.update,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_vm._v("Update")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }