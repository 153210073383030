<template>
  <widget>
    <!-- <div class="background-gradient-green-blue widget-top-graphic" style="height: 6px;"></div> -->
    <widget-header
      >Pixel
      <template v-slot:right>
        <Superselect
          v-model="campaignType"
          :options="campaignTypeOptions"
          title="Campaign Type"
          :multiple="false"
          :allow-empty="false"
          @close="onCampaignTypeChange"
          style="min-width: 8rem;"
        ></Superselect>
      </template>
    </widget-header>
    <widget-body>
      <form @submit.prevent="update" ref="form" class="block">
        <div class="x-row">
          <div class="x-col-1">
            <small>yTrack</small>
          </div>
          <textarea class="x-col-2" :value="yTrackMessage" readonly> </textarea>
        </div>
        <div class="x-row">
          <div class="x-col-1">
            <small>Image</small>
          </div>
          <textarea class="x-col-2" :value="imageMessage" readonly> </textarea>
        </div>
        <div class="x-row">
          <div class="x-col-1">
            <small>Iframe</small>
          </div>
          <textarea class="x-col-2" :value="iframeMessage" readonly> </textarea>
        </div>
      </form>
    </widget-body>
    <WidgetFooter class="bg-gray-100 py-2 px-4 text-right">
      <YButton @click="$emit('close')" color="blue">Close</YButton>
    </WidgetFooter>
  </widget>
</template>

<script>
export default {
  props: {
    advertiserId: {
      type: Number,
      required: true
    },
    accountId: {
      type: Number,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      yTrackMessage:
        'https://ac.ytrack.io/tracking/set_server_pixel/?adv=' +
        this.advertiserId +
        '-' +
        this.accountId +
        '&s2s={S2S}',
      imageMessage:
        '<img src="https://ac.ytrack.io/tracking/set_server_pixel/?type=img&adv=' +
        this.advertiserId +
        '-' +
        this.accountId +
        '&s2s={S2S}" width="1" height="1" style="display:none;">',
      iframeMessage:
        '<iframe src="https://ac.ytrack.io/tracking/set_server_pixel/?type=img&adv=' +
        this.advertiserId +
        '-' +
        this.accountId +
        '&s2s={S2S}" width="1" height="1" style="display:none;">',
      campaignType: 'CPA',
      isSubmitting: false,
      typeOptions: ['All', 'S2S', 'Image', 'iFrame'],
      protocolOptions: ['http', 'https'],
      campaignTypeOptions: ['CPA', 'Revshare']
    }
  },
  watch: {},
  computed: {},
  methods: {
    onCampaignTypeChange() {
      this.yTrackMessage = this.imageMessage = this.iframeMessage = ''
      let campaignType = ''
      if (this.campaignType && this.campaignType == 'Revshare') {
        campaignType = '&value={PAYOUT}'
      }

      let image =
        '<img src="https://ac.ytrack.io/tracking/set_server_pixel/?type=img&adv=' +
        this.advertiserId +
        '-' +
        this.accountId +
        '&s2s={S2S}' +
        campaignType +
        '" width=1 height=1 style="display:none;">'
      let iFrame =
        '<iframe src="https://ac.ytrack.io/tracking/set_server_pixel/?type=img&adv=' +
        this.advertiserId +
        '-' +
        this.accountId +
        '&s2s={S2S}' +
        campaignType +
        '" width=1 height=1 style="display:none;">'
      let yTrackUrl =
        'https://ac.ytrack.io/tracking/set_server_pixel/?adv=' +
        this.advertiserId +
        '-' +
        this.accountId +
        '&s2s={S2S}' +
        campaignType +
        ''

      this.yTrackMessage = yTrackUrl
      this.imageMessage = image
      this.iframeMessage = iFrame
    },
    mounted() {},
    updated() {}
  }
}
</script>
<style scoped>
.x-row {
  @apply flex items-center my-2;
}
.x-col-1 {
  @apply text-indigo-600 mr-2 w-24 text-right uppercase block min-w-0 pr-4;
}
.x-col-2 {
  @apply w-auto flex-grow p-2 rounded border bg-gray-100;
  min-height: 4.7rem;
}
</style>
