var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 sm:grid-cols-3 gap-1"
  }, [_c('YInput', {
    attrs: {
      "label": "ID"
    },
    model: {
      value: _vm.filters.id.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.id, "value", $$v);
      },
      expression: "filters.id.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.filters.name.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.name, "value", $$v);
      },
      expression: "filters.name.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Manager",
      "multiple": false,
      "track-by": "id",
      "label": "label",
      "query": _vm.ACCOUNT_MANAGER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: [],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      }
    },
    model: {
      value: _vm.filters.manager.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.manager, "value", $$v);
      },
      expression: "filters.manager.value"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }