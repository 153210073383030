<template>
  <widget>
    <widget-header @close="$emit('close')">
      Upload Image to Repository
    </widget-header>
    <widget-body>
      <form v-on:submit.prevent="addImage" class="row" ref="upload">
        <div class="row flex items-center mb-3">
          <div :class="style.columnTitle">
            Choose Image
          </div>
          <div class="col">
            <ImageInput
              class="rounded"
              :imageUrl="null"
              :thumbnailImageUrl="null"
              :height="32"
              :width="32"
              :blankHeight="20"
              :blankWidth="20"
              :editable="true"
              :editable-only-when-empty="false"
              :file.sync="imageFile"
              :key="isSubmitting"
            ></ImageInput>
          </div>
        </div>
        <div class="row flex items-center mb-3">
          <div :class="style.columnTitle">
            Name
          </div>
          <div class="col w-2/3">
            <YInput label="Name" v-model="imageName" :required="true"></YInput>
          </div>
        </div>
        <div class="row flex items-center mb-3">
          <div :class="style.columnTitle">
            Tags
          </div>
          <div class="col w-2/3">
            <Superselect
              title="Tags"
              v-model="tagSelection"
              label="name"
              track-by="name"
              :query="TAGS_QUERY"
              :query-variables="{
                filters: {
                  type: { value: ['vertical'] }
                },
                first: 100
              }"
            ></Superselect>
          </div>
        </div>

        <div class="w-full flex justify-end pt-2">
          <YButton color="blue" :is-loading="isSubmitting">Add Image</YButton>
        </div>
      </form>
    </widget-body>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import ImageInput from '@/components/utilities/ImageInput'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import ADD_IMAGE from '@/graphql/ImageRepository/AddImageMutation.gql'
import SYNC_TAGS_WITH_TYPE_MUTATION from '@/graphql/Tag/SyncTagsWithTypeMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],
  components: {
    ImageInput
  },
  props: {},
  apollo: {},
  data() {
    return {
      TAGS_QUERY,
      imageName: '',
      imageFile: null,
      tagSelection: [],
      isSubmitting: false,
      style: {
        columnTitle: 'col w-1/3 px-4 my-3 font-bold text-gray-700'
      }
    }
  },
  methods: {
    addImage() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: ADD_IMAGE,
          variables: {
            input: {
              name: this.imageName,
              file: this.imageFile
            }
          }
        })
        .then(result => {
          if (this.tagSelection.length > 0) {
            return this.syncTagsWithType(result.data.addImage.id)
          } else {
            return Promise.resolve()
          }
        })
        .then(() => {
          this.$events.emit('refreshImages')
          this.$emit('close')
          this.isSubmitting = false
        })
        .catch(error => {
          console.log('ERROR', error)
          this.isSubmitting = false
        })
    },
    syncTagsWithType(id) {
      const tags = this.tagSelection.map(tag => tag.name)
      this.$apollo.mutate({
        mutation: SYNC_TAGS_WITH_TYPE_MUTATION,
        variables: {
          input: {
            taggableId: id,
            taggableType: 'image',
            type: 'vertical',
            tags: tags
          }
        }
      })
    },
    close() {
      this.clearValidationErrors()
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
