var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    attrs: {
      "id": "new-advertiser-adjustments-form",
      "name": "bulk-publisher-adjustments-form"
    },
    on: {
      "submit": _vm.submitForm,
      "keydown": function ($event) {
        return _vm.errors.clear($event.target.name);
      },
      "change": function ($event) {
        return _vm.errors.clear($event.target.name);
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "flex w-full items-center justify-center mb-3"
  }, [_c('label', {
    staticClass: "w-64 flex flex-col items-center px-4 py-6 bg-white text-blue-600 rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-blue-600 hover:text-white"
  }, [_c('svg', {
    staticClass: "w-8 h-8",
    attrs: {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
    }
  })]), _vm.csvFileName ? _c('span', {
    staticClass: "mt-2 text-base leading-normal"
  }, [_vm._v(_vm._s(_vm.csvFileName))]) : _c('span', {
    staticClass: "mt-2 text-base leading-normal uppercase"
  }, [_vm._v("Upload CSV")]), _c('input', {
    ref: "bulkCsvInput",
    staticClass: "hidden",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onCsvChange
    }
  })])]), _c('div', {
    staticClass: "text-center"
  }, [_c('small', [_c('a', {
    staticClass: "link inline-flex items-center",
    attrs: {
      "href": "https://ytrack-documents.s3.amazonaws.com/adjustments-example.csv"
    }
  }, [_c('Icon', {
    staticClass: "mr-1",
    attrs: {
      "name": "download",
      "size": 4
    }
  }), _vm._v(" Download CSV Example")], 1)])])]), _c('div', {
    staticClass: "flex justify-end"
  }, [_c('YButton', {
    staticClass: "link mr-2",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearForm.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear CSV")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }