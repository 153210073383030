var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Edit Template Image ")]), _c('widget-body', [_c('div', {
    staticClass: "row flex items-center mb-3"
  }, [_c('div', {
    class: _vm.style.columnTitle
  }, [_vm._v(" Name ")]), _c('div', {
    staticClass: "col",
    domProps: {
      "textContent": _vm._s(this.imageName)
    }
  })]), _c('div', {
    staticClass: "row flex items-center mb-3"
  }, [_c('div', {
    class: _vm.style.columnTitle
  }, [_vm._v(" Change Image ")]), _c('div', {
    staticClass: "col"
  }, [_c('ImageInput', {
    key: _vm.imageInputKey,
    staticClass: "rounded",
    attrs: {
      "imageUrl": _vm.imageUrl,
      "thumbnailImageUrl": _vm.imageThumbnailUrl,
      "height": 32,
      "width": 32,
      "blankHeight": 20,
      "blankWidth": 20,
      "editable": true,
      "editable-only-when-empty": false,
      "file": _vm.file,
      "show-loader": _vm.isSubmitting
    },
    on: {
      "update:file": function ($event) {
        _vm.file = $event;
      }
    }
  })], 1)]), _c('div', {
    staticClass: "row flex items-center mb-3"
  }, [_c('div', {
    class: _vm.style.columnTitle
  }, [_vm._v(" Tags ")]), _c('div', {
    staticClass: "col w-2/3"
  }, [_c('SuperSelect', {
    attrs: {
      "title": "Tags",
      "options": _vm.tags,
      "settings": {
        edit: false
      },
      "editPlaceholder": "Add New Tag"
    },
    model: {
      value: _vm.tagSelection,
      callback: function ($$v) {
        _vm.tagSelection = $$v;
      },
      expression: "tagSelection"
    }
  })], 1)])]), _c('widget-footer', {
    staticClass: "px-4 py-2 bg-gray-100 text-right"
  }, [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Update")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }