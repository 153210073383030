<template>
  <Widget class="bg-red-600 border-red-700 text-white">
    <WidgetHeader class="bg-red-600"
      ><span class="text-white">Are you sure?</span></WidgetHeader
    >
    <WidgetBody>
      <h5 class="text-yellow-400 font-bold">This action cannot be undone.</h5>
      <h5 class="mb-2">
        You are about to delete the postback for:
      </h5>
      <ul class=" my-4">
        <li class="font-semibold text-md">
          <template v-if="postback.redirect">
            <template v-if="postback.redirect.campaign">
              {{ postback.redirect.campaign.id }} -
              {{ postback.redirect.campaign.name }}
            </template>
            <template v-else>
              {{ postback.redirect.id }} - {{ postback.redirect.subdomain }}.{{
                postback.redirect.domain
              }}
            </template>
          </template>
          <template v-else>Account Level Postback</template>
        </li>
        <li class="italic">{{ postback.protocol }}://{{ postback.url }}</li>
      </ul>
    </WidgetBody>

    <WidgetFooter class="bg-dark-transparent-1 flex p-4 justify-between">
      <YButton
        type="button"
        class="hover:bg-red-700 focus:bg-red-700"
        @click="$emit('close')"
        >Cancel</YButton
      >
      <YButton
        class="hover:bg-red-700 focus:bg-red-700"
        @click="update"
        :is-loading="isSubmitting"
        >Delete Postback</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import DELETE_POSTBACK_MUTATION from '@/graphql/Postback/DeletePostbackMutation.gql'

export default {
  props: {
    postback: {
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    update() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: DELETE_POSTBACK_MUTATION,
          variables: {
            id: this.postback.id
          }
        })
        .then(result => {
          this.$toastr.s('Postback Deleted', 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshPostbacks', true) // deprecated
          this.$events.emit('postbackDeleted', result.data.deletePostback)
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
