<template>
  <div v-observe-visibility="visibilityChanged" class="grid grid-cols-12">
    <div class="mb-2 col-span-7">
      <Superselect
        title="Payment Frequency"
        v-model="localUser.billingContact.paymentFrequency"
        :multiple="false"
        :options="paymentFrequencyOptions"
        class="mb-1"
      ></Superselect>
      <Superselect
        title="Payment Terms"
        v-model="localUser.billingContact.paymentTerms"
        :multiple="false"
        :options="paymentTermsOptions"
        class="mb-1"
      ></Superselect>
      <Superselect
        title="Tax Type"
        v-model="localUser.billingContact.taxType"
        :multiple="false"
        :options="taxTypeOptions"
        class="mb-1"
      ></Superselect>
      <YInput
        class="mb-1"
        label="Tax Number"
        v-model="localUser.billingContact.taxNumber"
      />
      <!-- <YInput
        class="mb-1"
        label="Payment Method"
        v-model="localUser.billingContact.paymentMethod"
      /> -->
      <!-- <YInput
        class="mb-1"
        label="Name"
        v-model="localUser.billingContact.name"
      />
      <YInput
        class="mb-1"
        label="Company"
        v-model="localUser.billingContact.company"
      />
      <YInput
        class="mb-1"
        label="Email"
        v-model="localUser.billingContact.email"
      />
      <YInput
        class="mb-1"
        label="Phone"
        v-model="localUser.billingContact.phone"
      />
      <YInput
        class="mb-1"
        label="Address"
        v-model="localUser.billingContact.address"
      />
      <YInput
        class="mb-1"
        label="Address 2"
        v-model="localUser.billingContact.address2"
      />
      <YInput
        class="mb-1"
        label="City"
        v-model="localUser.billingContact.city"
      />
      <Superselect
        title="Country"
        v-model="localUser.billingContact.country"
        :multiple="false"
        track-by="code"
        label="label"
        :options="countries"
        class="mb-1"
      ></Superselect>
      <YInput
        class="mb-1"
        label="Postal Code"
        v-model="localUser.billingContact.postalCode"
      /> -->
    </div>
    <ValidationErrors
      v-if="validationErrors && isVisible"
      :errors="validationErrors"
    ></ValidationErrors>
    <portal to="top-bar-right" v-if="hasChanges && isVisible">
      <YButton @click="update()" color="green">Update Billing Details</YButton>
    </portal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  apollo: {
    paymentFrequencyOptions: {
      query: gql`
        {
          paymentFrequencyOptions: __type(name: "ContactPaymentFrequencyEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        if (data) {
          this.paymentFrequencyOptions = data.paymentFrequencyOptions.enumValues.map(
            option => option.name
          )
        }
      }
    },
    paymentTermsOptions: {
      query: gql`
        {
          paymentTermsOptions: __type(name: "ContactPaymentTermsEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        if (data) {
          this.paymentTermsOptions = data.paymentTermsOptions.enumValues.map(
            option => option.name
          )
        }
      }
    },
    taxTypeOptions: {
      query: gql`
        {
          taxTypeOptions: __type(name: "CanadianTaxTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        if (data) {
          this.taxTypeOptions = data.taxTypeOptions.enumValues.map(
            option => option.name
          )
        }
      }
    },
    countries: {
      query: gql`
        {
          countries {
            code
            name
            label
          }
        }
      `
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localUser: JSON.parse(JSON.stringify(this.user)),
      isVisible: true,
      isUpdating: false,
      countries: [],
      paymentFrequencyOptions: [],
      paymentTermsOptions: [],
      taxTypeOptions: [],
      errors: null
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
    },
    update() {
      this.clearValidationErrors()
      this.isUpdating = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          // Query
          mutation: UPDATE_USER_MUTATION,
          // Parameters
          variables: {
            input: {
              id: this.user.id,
              contacts: {
                update: [
                  {
                    id: this.user.billingContact.id,
                    name: this.localUser.billingContact.name,
                    email: this.localUser.billingContact.email,
                    address: this.localUser.billingContact.address,
                    address2: this.localUser.billingContact.address2,
                    phone: this.localUser.billingContact.phone,
                    city: this.localUser.billingContact.city,
                    region: this.localUser.billingContact.region,
                    countryCode: this.localUser.billingContact.country
                      ? this.localUser.billingContact.country.code
                      : null,
                    postalCode: this.localUser.billingContact.postalCode,
                    messenger: this.localUser.billingContact.messenger,
                    company: this.localUser.billingContact.company,
                    paymentFrequency: this.localUser.billingContact
                      .paymentFrequency,
                    paymentTerms: this.localUser.billingContact.paymentTerms,
                    paymentMethod: this.localUser.billingContact.paymentMethod,
                    taxType: this.localUser.billingContact.taxType,
                    taxNumber: this.localUser.billingContact.taxNumber
                  }
                ]
              }
            }
          }
        })
        .then(() => {
          // Result
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Success')
        })
        .catch(error => {
          // Error
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.setValidationErrors(error)
        })
    }
  },
  computed: {
    hasChanges() {
      return (
        JSON.stringify(this.localUser.billingContact) !=
        JSON.stringify(this.user.billingContact)
      )
    }
  }
}
</script>

<style></style>
