<template>
  <Widget class="bg-red-600 border-red-700 text-white">
    <WidgetHeader>
      <template slot="rawContent">
        <div>
          <h4 class="widget-title text-white">Delete Preset</h4>
          <p class="mt-0 mb-0 text-yellow-400 font-bold">
            <i
              ><span class="font-bold">WARNING:</span> This action cannot be
              undone.</i
            >
          </p>
        </div>
      </template>
      <h4 slot="right">
        <close-x @click="close"></close-x>
      </h4>
    </WidgetHeader>
    <WidgetBody>
      <table class="table w-full">
        <tbody>
          <tr>
            <td class="label-cell text-muted">Name</td>
            <td>{{ presetFilter.name }}</td>
          </tr>
          <tr>
            <td class="label-cell text-muted">ID</td>
            <td>{{ presetFilter.id }}</td>
          </tr>
          <tr>
            <td class="label-cell text-muted">Author</td>
            <td>{{ presetFilter.author.primaryContact.name }}</td>
          </tr>
        </tbody>
      </table>
      <!-- 			<rich-html-list>
				<rich-html-list-item label="Name" :label-width="labelWidth">{{preset.label}}</rich-html-list-item>
				<rich-html-list-item label="ID" :label-width="labelWidth">{{preset.id}}</rich-html-list-item>
				<rich-html-list-item label="Author" :label-width="labelWidth">Chris Radigan</rich-html-list-item>
			</rich-html-list> -->
    </WidgetBody>
    <WidgetFooter class="py-3 px-3">
      <div class="mr-auto"></div>
      <div class="flex items-center justify-between">
        <YButton
          type="button"
          class="hover:bg-red-700 focus:bg-red-700 outline-none-important"
          @click="close"
        >
          Cancel
        </YButton>
        <YButton
          class="bg-red-700 hover:bg-red-800 focus:bg-red-800 outline-none-important"
          @click="deletePreset"
          :isLoading="isDeleting"
          >Delete</YButton
        >
      </div>
    </WidgetFooter>
  </Widget>
</template>

<script>
import DELETE_REPORT_PRESET_MUTATION from '@/graphql/ReportPreset/DeleteReportPresetMutation.gql'

export default {
  components: {},
  props: {
    presetFilter: {
      type: Object
    }
  },
  data() {
    return {
      isDeleting: false
    }
  },
  computed: {},
  methods: {
    deletePreset() {
      this.isDeleting = true

      this.$apollo
        .mutate({
          mutation: DELETE_REPORT_PRESET_MUTATION,
          variables: {
            id: this.presetFilter.id
          }
        })
        .then(result => {
          this.$toastr.s('Preset has been deleted', 'Deleted')
          this.isSaving = false
          this.$emit('success', result)
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSaving = false
        })
    },
    close() {
      this.$emit('close')
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.label-cell {
  width: 4rem;
  font-size: 85%;
}
</style>
