var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "close-x animated",
    class: _vm.classes,
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('click');
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "close",
      "size": 4
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }