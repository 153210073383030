var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.me ? [_c('portal', {
    attrs: {
      "to": "admin-alerts"
    }
  }, [_vm.isImpersonating && _vm.routeScope == 'admin' && _vm.me.isAPublisher ? _c('YAlert', {
    attrs: {
      "allow-close": false,
      "color": "red"
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_vm._v(" You are currently impersonating "), _c('strong', [_vm._v(_vm._s(_vm.me.email))]), _vm._v(" on an "), _c('strong', [_vm._v("ADMIN PAGE")]), _vm._v(". This page will not function properly until impersonation has ended. ")]), _c('EndImpersonate', {
    attrs: {
      "user-id": _vm.prevAuthId
    }
  }, [_c('YButton', {
    attrs: {
      "color": "red"
    }
  }, [_vm._v("End Impersonation")])], 1)], 1)]) : _vm._e()], 1), _c('portal', {
    attrs: {
      "to": "publisher-alerts"
    }
  }, [!_vm.isImpersonating && _vm.routeScope == 'publisher' && _vm.me.isAnAdmin && _vm.prevAuthId ? _c('YAlert', {
    attrs: {
      "allow-close": false,
      "color": "red"
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_vm._v(" You are no longer impersonating "), _c('strong', [_vm._v("User " + _vm._s(_vm.prevAuthId))]), _vm._v(". This page will not function properly. "), _c('router-link', {
    staticClass: "link font-bold ml-4",
    attrs: {
      "to": {
        name: 'Dashboard'
      }
    }
  }, [_vm._v("Return to Admin Section")])], 1), _c('div', [_c('Impersonate', {
    staticClass: "ml-4",
    attrs: {
      "user-id": _vm.prevAuthId,
      "path": _vm.$route.path
    }
  }, [_c('YButton', {
    attrs: {
      "color": "red"
    }
  }, [_vm._v("Re-Impersonate")])], 1)], 1)])]) : _vm._e()], 1)] : _vm._e(), _c('portal', {
    attrs: {
      "to": "alerts"
    }
  }, [!_vm.isAuthenticated && !_vm.routeIsPublic ? _c('YAlert', {
    attrs: {
      "allow-close": false,
      "color": "yellow"
    }
  }, [_c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('div', [_vm._v(" You are no longer logged in. ")]), _c('div', [_c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('YButton', {
    attrs: {
      "color": "yellow"
    }
  }, [_vm._v("Go To Login")])], 1)], 1)])]) : _vm._e()], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }