<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <div class="flex items-center">
        <i class="zmdi zmdi-file-plus pr-2"></i>
        Edit {{ tagType.label }} Tag
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <YInput label="Tag Text" v-model="tagText" class="mb-1" />
      <Superselect
        title="Color"
        v-model="tagColor"
        :options="colorOptions"
        :multiple="false"
        :allow-empty="false"
        :max-height="80"
      >
        <template slot="singleLabel" slot-scope="props">
          <Tag :color="props.option">{{ props.option }}</Tag>
        </template>
        <template slot="option" slot-scope="props">
          <Tag :color="props.option">{{ props.option }}</Tag>
        </template>
      </Superselect>
    </WidgetBody>
    <WidgetFooter class="pt-4 pb-4 px-4 bg-gray-100 flex justify-between">
      <y-checkbox
        class="flex items-center"
        v-model="closeOnComplete"
        label="Close on Complete"
      ></y-checkbox>
      <YButton
        :is-loading="isSubmitting"
        color="blue"
        class="ml-auto"
        @click="update"
        @keydown.enter="update"
        >Update</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrors from '@/assets/js/ValidationErrors'

export default {
  props: {
    tag: {
      type: Object,
      required: true
    }
  },
  components: {},
  apollo: {
    typeOptions: {
      query: gql`
        query {
          typeOptions: __type(name: "YdnsTagConfigTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.typeOptions = data.typeOptions.enumValues.map(enumValue => ({
          id: enumValue.name,
          label: enumValue.name.capitalize()
        }))
      }
    },
    colorOptions: {
      query: gql`
        query {
          colorOptions: __type(name: "YdnsTagConfigColorEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.colorOptions = data.colorOptions.enumValues.map(
          enumValue => enumValue.name
        )
      }
    }
  },
  data() {
    return {
      closeOnComplete: true,
      isSubmitting: false,
      tagType: {
        id: this.tag.type,
        label: this.tag.type.capitalize()
      },
      tagColor: this.tag.color,
      tagText: this.tag.tag,
      typeOptions: [],
      colorOptions: [],
      validationErrors: {}
    }
  },
  watch: {},
  computed: {},
  methods: {
    update() {
      this.isSubmitting = true

      let input = {
        id: this.tag.id,
        tag: this.tagText,
        color: this.tagColor,
        type: this.tagType.id
      }

      console.log(input)

      this.$apollo
        .mutate({
          mutation: gql`
            mutation YdnsUpdateTags($input: [YdnsUpdateTagConfigInput!]!) {
              ydnsUpdateTags(input: $input) {
                id
                type
                tag
                color
              }
            }
          `,
          variables: {
            input: [input]
          }
        })
        .then(result => {
          console.log(result)
          this.isSubmitting = false
          this.$toastr.s('Tag Updated', 'Success!')
          this.$events.emit('refreshYdnsTags')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false

          // this.validationErrors = error.extentions.validation ? error.extentions.validation : null;
          let errors = new ValidationErrors()
          errors.extractErrorsFrom(error)
          this.validationErrors = errors.errors

          // if (Object.entries(this.errors).length <= 0) {
          //   toastr.error(error, "Oops!");
          // }
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
