<template>
  <v-popover
    offset="10"
    placement="left-start"
    :autoHide="true"
    popoverClass=""
    :class="['']"
    popoverBaseClass="popover-menu sidebar-popover-menu"
    popoverInnerClass="popover-menu-inner animated animated-speed1 fadeIn"
    class="flex justify-center"
  >
    <domains-actions v-show="false" ref="domainsActions"></domains-actions>
    <!-- This will be the popover target (for the events and position) -->
    <div class="inline-block tooltip-target">
      <slot>
        <YButton class="text-blue-600 hover:bg-blue-100 px-4"
          ><Icon name="dotsVertical" :size="4"
        /></YButton>
      </slot>
    </div>
    <!-- This will be the content of the popover -->
    <template slot="popover">
      <div class="list-group">
        <button
          type="button"
          class="btn-sm list-group-item list-group-item-action"
          @click="enable(ips)"
        >
          <span class="text-success"
            ><i class="zmdi zmdi-check mr-2"></i> Enable</span
          >
        </button>
        <button
          type="button"
          class="btn-sm list-group-item list-group-item-action"
          @click="disable(ips)"
        >
          <span class="text-warning"
            ><i class="zmdi zmdi-block mr-2"></i> Disable</span
          >
        </button>
        <!--               <button type="button" class="btn-sm list-group-item list-group-item-action">
                 <span class="text-link"><i class="zmdi zmdi-search-replace mr-2"></i>  Replace Domain</span>
              </button> -->
        <YConfirm
          @confirm="deleteItems(ips)"
          :message="`Deleting ${ips.length} IPs`"
          color="red"
        >
          <button
            type="button"
            class="btn-sm list-group-item list-group-item-action"
          >
            <span class="text-danger"
              ><i class="zmdi zmdi-delete mr-2"></i> Delete</span
            >
          </button>
        </YConfirm>
        <button
          type="button"
          class="btn-sm list-group-item list-group-item-action"
          @click="openDomainEditModal(selectedDomainRecords)"
        >
          <span class="text-primary"
            ><i class="zmdi zmdi-edit mr-2"></i> Edit Records</span
          >
        </button>
      </div>
    </template>
  </v-popover>
</template>

<script>
import gql from 'graphql-tag'
import DomainsActions from './DomainsActions'
import YReplaceIp from './YReplaceIp'

export default {
  components: {
    DomainsActions
  },
  props: {
    server: {
      type: Object,
      default: () => null
    },
    ips: {
      type: Array,
      default: () => []
    }
  },
  apollo: {
    domains: {
      query: gql`
        query getDomains(
          $filters: YdnsDomainFilterInput
          $recordFilters: YdnsRecordFilterInput
          $orderBy: [YdnsDomainsOrderByOrderByClause!]
          $trashed: Trashed
        ) {
          domains: ydnsDomains(
            filters: $filters
            orderBy: $orderBy
            trashed: $trashed
          ) {
            id
            registrar
            type
            expiry
            records(filters: $recordFilters) {
              id
              type
              subdomain
              ip {
                id
                server {
                  id
                  provider {
                    id
                    name
                  }
                }
              }
              content
              ttl
              priority
            }
            deletedAt
          }
        }
      `,
      variables() {
        let ipAddresses = this.ips.map(ip => ip.id)
        console.log(ipAddresses)
        return {
          filters: {
            recordContent: {
              value: ipAddresses
            },
            hasRecords: true
          },
          recordFilters: {
            content: {
              value: ipAddresses
            }
          }
        }
      },
      deep: true,
      skip() {
        let noneSelected = (this.ips && this.ips.length == 0) || !this.ips
        if (noneSelected) {
          this.selectedDomainRecords = []
        }
        return noneSelected
      },
      result(result) {
        this.selectedDomainRecords = result.data.domains
      }
    }
  },
  data() {
    return {
      selectedDomainRecords: []
    }
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {
    openCreateModal() {},
    openDomainEditModal(domainsWithRecords) {
      this.$refs.domainsActions.openEditModal(domainsWithRecords)
    },
    disable(ips = []) {
      this.enable(ips, false)
    },
    enable(ips = [], enable = true) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DisableIps($input: [YdnsUpdateIpInput!]!) {
              ydnsUpdateIps(input: $input) {
                id
                version
                enabled
              }
            }
          `,
          variables: {
            input: ips.map(ip => ({
              id: ip.id,
              enabled: enable
            }))
          }
        })
        .then(result => {
          this.$toastr.s(`IPs ${enable ? 'Enabled' : 'Disabled'}`, 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshYdnsServers', true)
          this.$emit('close')
          this.$emit('update', result.data.ydnsUpdateIps)
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    },
    openReplaceModal(ip) {
      this.$modal.show(
        YReplaceIp,
        { ip: ip },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 1000,
          minWidth: 200,
          adaptive: true
        }
      )
    },
    deleteItems(ips = []) {
      console.log(ips)
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteIps($id: [ID!]!) {
              ydnsDeleteIps(id: $id) {
                id
                version
                enabled
              }
            }
          `,
          variables: {
            id: ips.map(ip => ip.id)
          }
        })
        .then(result => {
          this.$toastr.s(`IPs Deleted`, 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshYdnsServers', true)
          this.$emit('delete', result.data.ydnsDeleteIps)
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  }
}
</script>
<style></style>
