import { render, staticRenderFns } from "./EditImageModal.vue?vue&type=template&id=f70c5050&scoped=true"
import script from "./EditImageModal.vue?vue&type=script&lang=js"
export * from "./EditImageModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f70c5050",
  null
  
)

export default component.exports