export default {
  methods: {
    priorityIconType(value) {
      switch (value) {
        case 'rejected':
          return 'close'
        case 'high':
          return 'chevronDoubleUp'
        case 'regular':
          return 'equal'
        default:
          return 'equal'
      }
    },
    priorityIconColor(value) {
      switch (value) {
        case 'rejected':
          return 'text-blue-200'
        case 'high':
          return 'text-orange-800'
        case 'regular':
          return 'text-gray-400'
        default:
          return 'text-gray-400'
      }
    },
    priorityFollowUp(value) {
      if (value) {
        return 'Follow Up On: ' + this.$luxon(value)
      }
    }
  }
}
