<template>
  <div class="mt-0 pt-2">
    <div>
      <div class="flex w-full mt-2">
        <QuarantineLevelCard
          class="w-full mr-6"
          :level="quarantine.level"
          :payout="currentPayout"
        />
        <div class="h-full flex-grow rounded-md p-3 text-gray-900 border">
          <div class="font-semibold uppercase mb-3">
            Subscription Limit
          </div>
          <div class="text-left text-xs italic text-gray-500">
            You can only subscribe to a maxium of 3 campaigns per month
          </div>
          <div class="text-gray-800 font-bold" style="font-size: 1.5rem">
            {{ quarantine.campaignSubscribeCount }} /
            {{ quarantineConfig?.campaignSubscribeLimit }}
          </div>
        </div>
      </div>
      <div>
        <div class="text-center ">
          <YButton
            class="mt-16"
            color="red"
            @click="deleteQuarantine(quarantine.id)"
            :is-loading="isDeleting"
            >Remove Quarantine</YButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QUARANTINE_CONFIG_QUERY from '@/graphql/Quarantine/QuarantineConfigQuery.gql'
import QuarantineLevelCard from '@/components/general/QuarantineLevelCard.vue'
import DELETE_QUARANTINE_MUTATION from '@/graphql/Quarantine/DeleteQuarantineMutation.gql'

export default {
  components: {
    QuarantineLevelCard
  },
  apollo: {
    quarantineConfig: {
      query: QUARANTINE_CONFIG_QUERY
    }
  },
  props: {
    quarantine: {
      type: Object,
      required: true
    },
    currentPayout: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isDeleting: false
    }
  },
  methods: {
    deleteQuarantine(id) {
      this.isDeleting = true
      this.$apollo
        .mutate({
          mutation: DELETE_QUARANTINE_MUTATION,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.isDeleting = false
          this.$emit('deleted')
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

<style scoped>
.inactive {
  opacity: 0.3;
}
</style>
