<template>
  <div>
    <Widget>
      <WidgetHeader class="bg-gray-200 p-1">
        <template slot="rawContent">
          <div class="w-full">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-1">
              <YInput
                class="form-control"
                type="text"
                label="ID"
                v-model="filters.id"
              />
              <YInput
                class="form-control"
                type="text"
                label="Keyword"
                v-model="filters.keyword"
              />
              <Superselect
                title="Publisher"
                v-model="filters.user.value"
                :options="[]"
                track-by="id"
                label="label"
                :multiple="false"
                :query="PUBLISHER_OPTIONS_QUERY"
                :query-variables="{
                  first: 20,
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  }
                }"
              ></Superselect>
              <Superselect
                title="Redirect"
                v-model="filters.redirect.value"
                :modifiers="filters.redirect.modifiers"
                :options="[]"
                track-by="id"
                label="label"
                :multiple="false"
                :query="REDIRECT_OPTIONS_QUERY"
                :query-variables="{
                  filters: {
                    label: {
                      value: ['{input}'],
                      modifiers: { matchType: 'contains' }
                    }
                  },
                  first: 25
                }"
                :queryResultMap="{
                  id: 'id',
                  label: 'label'
                }"
              ></Superselect>
            </div>
          </div>
        </template>
      </WidgetHeader>
      <WidgetHeader style="border-top: 1px solid #D9D9D9;">
        <template slot="rawContent">
          <div>
            <div class="flex">
              <label class="mb-0 mr-1">Search Range</label
              ><Icon
                v-tooltip="
                  `Sources will only appear if they have stats within the search range.`
                "
                name="helpCircle"
                class="w-4 h-4 text-blue-500"
              />
            </div>
            <YRadios
              color="info"
              class="inline-flex mt-2"
              v-model="searchRange"
              radio-class="mr-3"
              :radios="searchRangeRadios"
              :inline="true"
            ></YRadios>
          </div>
          <div>
            <YButton
              color="blue"
              @click="submit()"
              :is-loading="$apollo.queries.sourcesReport.loading"
              >Submit</YButton
            >
          </div>
        </template>
      </WidgetHeader>
    </Widget>
    <y-alert v-if="responseMessage" :type="responseMessage.type">{{
      responseMessage.content
    }}</y-alert>
    <tabs class="mt-4">
      <tab
        name="All Results"
        :tag="sourcesReport.data ? sourcesReport.data.length : null"
        :active="true"
      >
        <Widget :loading="$apollo.queries.sourcesReport.loading">
          <WidgetBody>
            <admin-sources-table
              :data="sourcesReport.data"
              class="-m-4"
            ></admin-sources-table>
            <!-- 	        <div class="d-flex justify-content-between">
							<div>
								<multiselect v-model="perPage" :options="perPageOptions"></multiselect>
							</div>
							<div class="align-item-right">
								<y-pagination :data="paginationData" @change-page="changePage"></y-pagination>
							</div>
						</div> -->
          </WidgetBody>
        </Widget>
      </tab>
      <tab
        name="With Payouts"
        :tag="sourcesWithPayouts ? sourcesWithPayouts.length : null"
        :disabled="
          sourcesWithPayouts && sourcesWithPayouts.length > 0 ? false : true
        "
      >
        <Widget>
          <WidgetBody>
            <admin-sources-table
              v-if="!$apollo.queries.sourcesReport.loading"
              :data="sourcesWithPayouts"
            ></admin-sources-table>
          </WidgetBody>
        </Widget>
      </tab>
      <tab
        name="Abyssed"
        :tag="sourcesWithAbyss ? sourcesWithAbyss.length : null"
        :disabled="
          sourcesWithAbyss && sourcesWithAbyss.length > 0 ? false : true
        "
      >
        <Widget>
          <WidgetBody>
            <admin-sources-table
              v-if="!$apollo.queries.sourcesReport.loading"
              :data="sourcesWithAbyss"
            ></admin-sources-table>
          </WidgetBody>
        </Widget>
      </tab>
    </tabs>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import AdminSourcesTable from './AdminSourcesTable'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'

export default {
  components: {
    AdminSourcesTable
  },
  props: {},
  apollo: {
    redirectOptions: {
      query: REDIRECT_OPTIONS_QUERY,
      result({ data }) {
        let output = data.redirectOptions.data
        this.redirectOptions = output
      },
      variables() {
        return {
          filters: {
            label: {
              value: [this.searchedRedirect],
              modifiers: { matchType: 'contains' }
            }
          },
          first: 50
        }
      },
      skip() {
        return this.searchedRedirect ? false : true
      }
    },
    sourcesReport: {
      query: gql`
        query getSourcesReport($filters: SourceFiltersInput) {
          sourcesReport(filters: $filters) {
            data {
              id
              keyword
              user {
                id
                label
              }
              redirect {
                id
                label
              }
              isAbyssed
              payouts {
                id
              }
              impressions
              revenue
              statsDateRange
            }
            message {
              type
              content
            }
          }
        }
      `,
      variables() {
        return {
          filters: this.activeFilters
        }
      },
      result(result) {
        if (result.data.sourcesReport.message) {
          this.responseMessage = result.data.sourcesReport.message
        }
        this.sourcesReport = result.data.sourcesReport
      },
      deep: true,
      skip() {
        return this.skipSourcesLoad
      }
    }
  },
  data() {
    return {
      PUBLISHER_OPTIONS_QUERY,
      REDIRECT_OPTIONS_QUERY,
      orderBy: {
        column: 'id',
        order: 'DESC',
        label: 'Newest'
      },
      orderByOptions: [
        {
          label: 'Newest',
          column: 'id',
          order: 'DESC'
        },
        {
          label: 'Oldest',
          column: 'id',
          order: 'ASC'
        }
      ],
      fields: [],
      searchedRedirect: null,
      redirectOptions: [],
      sourcesReport: {},
      page: 1,
      perPage: 25,
      perPageOptions: [10, 25, 50, 100, 500],
      sortOrder: [],
      isLoading: false,
      publisherOptions: [],
      responseMessage: null,
      filters: {
        id: null,
        keyword: null,
        user: {},
        redirect: {},
        searchRange: null
      },
      skipSourcesLoad: true,
      activeFilters: {},
      searchRange: 'last-7-days',
      searchRangeRadios: [
        {
          label: 'Today',
          value: 'today'
        },
        {
          label: 'Last 7 Days',
          value: 'last-7-days'
        },
        {
          label: 'All',
          value: 'all'
        }
      ]
    }
  },
  computed: {
    sourcesWithAbyss() {
      if (this.sourcesReport.data) {
        return this.sourcesReport.data.filter(source => {
          return source.isAbyssed
        })
      }
      return null
    },
    sourcesWithPayouts() {
      if (this.sourcesReport.data) {
        return this.sourcesReport.data.filter(source => {
          return source.payouts
        })
      }
      return null
    },
    gqlOrderBy() {
      let orderBy = this._.clone(this.orderBy)
      delete orderBy.label
      return [orderBy]
    }
  },
  mounted() {},

  updated() {},

  methods: {
    transformedFilters() {
      let filters = this._.cloneDeep(this.filters)
      filters.userId = filters.user.value ? filters.user.value.id : null
      delete filters.user
      filters.redirectId = filters.redirect.value
        ? filters.redirect.value.id
        : null
      delete filters.redirect
      filters.searchRange = this.searchRange
      filters = this._.pickBy(filters, this._.identity)
      return filters
    },
    submit() {
      this.skipSourcesLoad = false
      this.responseMessage = null
      this.activeFilters = this._.cloneDeep(this.transformedFilters())
    },
    asyncRedirectSearch(value) {
      if (value.length > 2) {
        this.searchedRedirect = value
      } else {
        if (this.filters.redirect.value) {
          this.redirectOptions = [
            {
              id: this.filters.redirect.value.id,
              label: this.filters.redirect.value.label
            }
          ]
        } else {
          this.redirectOptions = []
        }
        this.searchedRedirect = null
      }
    },
    changePage(page) {
      this.page = page
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$events.emit('changeReportPage', page)
    },
    onLoading() {
      this.isLoading = true
    },
    onLoaded() {
      this.isLoading = false
    }
  }
}
</script>
