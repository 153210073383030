<template>
  <div>
    <div class="flex items-center">
      <div class="mr-6 relative">
        <div>
          <div v-if="rowData.legacyImageUrl" class="mr-4 hidden sm:block">
            <EditableImage
              class="rounded mr-2x"
              :imageUrl="rowData.legacyImageUrl"
              :thumbnailUrl="rowData.legacyImageUrl"
              :height="12"
              :width="12"
              :blankHeight="12"
              :blankWidth="12"
              :editable-only-when-empty="true"
              :modelId="rowData.id"
              :deleteExisting="true"
              :edit-focus-icon-enabled="false"
              :editable="false"
              modelClass="campaign"
            ></EditableImage>
          </div>
          <div v-else class="mr-4 hidden sm:block">
            <EditableImage
              class="rounded mr-2x"
              :imageUrl="imageFileComputed(rowData.images)?.original || null"
              :thumbnailUrl="
                imageFileComputed(rowData.images)?.imageThumbnailUrl || null
              "
              :height="12"
              :width="12"
              :blankHeight="12"
              :blankWidth="12"
              :modelId="rowData.id"
              :editable="false"
              modelClass="campaign"
            ></EditableImage>
          </div>
        </div>
      </div>
      <ul class="campaign-cell-list">
        <li :class="!minimalView ? 'max-width' : ''">
          <router-link
            class="link inline-block without-truncate"
            :to="{ name: 'Campaign', params: { id: rowData.id } }"
          >
            {{ rowData.name }}
          </router-link>
          <sup class="ts-1 ml-1 text-gray-500">{{ rowData.id }}</sup>
        </li>
        <li v-if="!minimalView">
          <Tag v-if="rowData.isCapped" color="darkRed" class="mr-1 mb-1"
            >CAPPED</Tag
          >

          <Tag
            v-for="tag in verticalTags"
            :key="tag.id"
            :color="tag.color"
            class="my-1 mr-1"
            v-tooltip="`Vertical`"
            >{{ tag.name }}</Tag
          >

          <Tag
            v-for="tag in adminTags"
            :key="tag.id"
            :color="tag.color"
            class="my-1 mr-1"
            v-tooltip="`Tag`"
            >{{ tag.name }}</Tag
          >

          <Tag v-if="rowData.payoutType" :color="`purple`" class="mr-1">
            {{ rowData.payoutType.toUpperCase() }}
          </Tag>
          <Tag v-if="rowData.approvalRequired" :color="`red`" class="mt-1 mr-1"
            >Approval Required</Tag
          >
        </li>
        <li v-if="!minimalView"></li>
        <li v-if="!minimalView"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import EditableImage from '@/components/utilities/EditableImage'

export default {
  components: {
    EditableImage
  },
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    },
    minimalView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    verticalTags() {
      if (this.rowData.tags) {
        return this.rowData.tags.filter(tag => tag.type == 'vertical')
      }
      return null
    },
    adminTags() {
      if (this.rowData.tags) {
        return this.rowData.tags.filter(tag => tag.type == 'admin')
      }
      return null
    }
  },
  mounted() {},
  updated() {},

  methods: {
    imageFileComputed(value) {
      if (value) {
        let result = value
          .filter(image => image.type === 'template')
          .map(image => {
            return {
              id: image.id,
              name: image.name,
              imageThumbnailUrl:
                image.conversions.find(image => image.conversion === 'thumb')
                  ?.storageUrl || null,
              original:
                image.conversions.find(image => image.conversion === 'full')
                  ?.storageUrl || null
            }
          })
        return result[0]
      }
      return null
    }
  }
}
</script>
<style scoped>
.max-width {
  max-width: 20rem;
}

ul.campaign-cell-list {
  list-style: none;
  margin-bottom: 0;
  padding-bottom: 0;
  padding: 0.5rem 0;
}

ul.campaign-cell-list li {
  padding-bottom: 0.3rem;
}

ul.campaign-cell-list li:last-child {
  padding-bottom: 0;
}
</style>
