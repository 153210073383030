var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm._t("default", null, {
    "parsed": _vm.parsed
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }