<template>
  <div class="w-full">
    <div class="grid grid-cols-1 md:grid-cols-6 gap-1">
      <YInput v-model="filters.id.value" label="ID"></YInput>
      <YInput v-model="filters.name.value" label="Contact Name"></YInput>
      <YInput v-model="filters.companyName.value" label="Company"></YInput>
      <YInput v-model="filters.contactEmail.value" label="Email"></YInput>
      <YInput
        v-model="filters.instantMessengerHandle.value"
        label="IM Handle"
      ></YInput>
      <Superselect
        title="Lead Type"
        v-model="filters.leadType.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['leadType'] }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        title="Payout Type"
        v-model="filters.payoutType.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['payoutType'] }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        title="Traffic Type"
        v-model="filters.trafficType.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['trafficType'] }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        title="Verticals"
        v-model="filters.verticalTypeTags.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['vertical'] }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        title="Geos"
        v-model="filters.geoLocation.value"
        label="name"
        track-by="name"
        :query="TAGS_QUERY"
        :query-variables="{
          filters: {
            type: { value: ['geoLocation'] }
          },
          first: 100
        }"
      ></Superselect>
      <Superselect
        title="Priority Status"
        v-model="filters.priorityStatus.value"
        :multiple="false"
        :options="['regular', 'high', 'rejected']"
        class="capitalize"
      ></Superselect>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'

export default {
  updated() {},

  apollo: {},
  data() {
    return {
      TAGS_QUERY,
      filterOptions: {
        status: ['Active', 'Pending', 'Rejected', 'Fraud', 'Inactive'],
        role: ['Admin', 'Publisher', 'Advertiser']
      },
      orderBy: {
        column: 'created_at',
        order: 'DESC',
        label: 'Newest'
      },
      orderByOptions: [
        {
          label: 'Newest',
          column: 'created_at',
          order: 'DESC'
        },
        {
          label: 'Oldest',
          column: 'created_at',
          order: 'ASC'
        }
      ]
    }
  },
  methods: {},
  computed: {
    ...mapState({
      filters: state => state.lead.filters
    })
  }
}
</script>

<style></style>
