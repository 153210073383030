<template>
  <Widget>
    <WidgetHeader>Clone Offer Options</WidgetHeader>
    <WidgetBody>
      <div class="flex flex-col gap-4 px-4">
        <div class="flex flex-col w-full">
          <div class="flex flex-col">
            Select which options you would like to clone with the offer.
          </div>
        </div>

        <div class="grid gap-2 w-full items-start bg-gray-100 rounded p-4">
          <YCheckbox
            v-model="withRules"
            label="Clone with Rules"
            :inline="true"
            class="flex items-center mr-2"
            type="info"
          ></YCheckbox>
          <YCheckbox
            v-if="offerData.destinationType == 'direct'"
            v-model="withRevenues"
            label="Clone with Payouts"
            :inline="true"
            class="flex items-center mr-2"
            type="info"
          ></YCheckbox>
          <YCheckbox
            v-if="offerData.destinationType == 'direct' && offerData.yleader"
            v-model="withYleader"
            label="Clone with yLeader"
            :inline="true"
            class="flex items-center mr-2"
            type="info"
          ></YCheckbox>

          <YCheckbox
            v-if="offerData.destinationType == 'direct'"
            v-model="withAttributes"
            label="Clone with Attributes"
            :inline="true"
            class="flex items-center mr-2"
            type="info"
          ></YCheckbox>
        </div>
      </div>

      <div class="w-full flex justify-end gap-3 mt-6">
        <YButton color="gray" @click="$emit('close')">Cancel</YButton>
        <YButton color="blue" @click="cloneOffer">Clone Offer</YButton>
      </div>
    </WidgetBody>
  </Widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import CLONE_OFFER_MUTATION from '@/graphql/Offer/CloneOfferMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    id: {
      type: String,
      required: true
    },
    offerData: {
      type: Object
    }
  },
  data() {
    return {
      withRevenues: false,
      withRules: false,
      withYleader: false,
      withAttributes: false
    }
  },
  methods: {
    cloneOffer() {
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: CLONE_OFFER_MUTATION,
          variables: {
            input: {
              id: this.id,
              withRevenues: this.withRevenues,
              withRules: this.withRules,
              withYleader: this.withYleader,
              withAttributes: this.withAttributes
            }
          }
        })
        .then(() => {
          this.$events.emit('offerCreated')
          this.$emit('close')
          this.$toastr.s('Offer Successfully Cloned.')
          this.$store.dispatch('updateGlobalLoader', -1)
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.$store.dispatch('updateGlobalLoader', -1)
        })
    }
  }
}
</script>
<style>
.publisher-postbacks-table-actions-header {
  width: 3rem;
}
.text-opacity-light {
  opacity: 0.7;
}
</style>
