<template>
  <div>
    <div class="bg-gray-200 p-1 border-b mb-2">
      <template>
        <div class="w-full">
          <div class="grid grid-cols-1 grid-cols-6 gap-1">
            <YInput v-model="filters.subdomain.value" label="Subdomain"></YInput>
             <Superselect
              title="Exception Type"
              v-model="filters.level"
              :multiple="false"
              :options="options.level"
            ></Superselect>
             <Superselect
              title="Country"
              v-model="filters.country.value"
              :multiple="false"
              label="label"
              track-by="code"
              :loading="$apollo.queries.countries.loading"
              :options="countries"
            ></Superselect>
            <YButton
              color="blue"
              @click.prevent="openCreateModal()"
              >Create Alf Exception</YButton
            >
          </div>
        </div>
      </template>
    </div>
    <div class="w-100">
      <YTable
        v-if="alfExceptions"
        deletedAtField="isCapped"
        deletedAtClass="text-red-500 font-medium"
        :data="alfExceptions.data || []"
        :bottom-loading="$apolloData.queries.alfExceptions.loading"
      >
        <template slot="header-row">
          <th>Subdomain</th>
          <th>User</th>
          <th>Country</th>
          <th>Actions</th>
        </template>
        <template slot="row" slot-scope="props">
          <td>{{ props.rowData.subdomain }}</td>
          <td><router-link
                :to="{
                  name: 'User',
                  params: { id: props.rowData.user.id, action: 'edit' }
                }"
                :class="
                  props.rowData.isCapped
                    ? 'text-red-600 hover:text-red-700 font-medium'
                    : 'link'
                "
              >
                {{ props.rowData.user.label }}
              </router-link>
          </td>
          <td>{{props.rowData.country}} </td>
          <td>
            <div class="text-right">
              <a
                href="#"
                :class="'text-red-600 hover:text-red-700'"
                @click.prevent="openDeleteModal(props.rowData)"
              >
                <Icon class="inline" name="trashCan" :size="4" />
              </a>
            </div>
          </td>
        </template>
         <template v-slot:after>
           <YPagination
           v-if="alfExceptions.paginatorInfo"
            :data="alfExceptions.paginatorInfo"
            @load-page="loadPage"
            @next-page="nextPage"
            @previous-page="previousPage"
            :key="totalPages"
            @per-page="perPageSelection"
          ></YPagination>
        </template>
      </YTable>
    </div>
  </div>
</template>

<script>
import ALF_LISTINGS_QUERY from '@/graphql/Alf/AlfExceptionsQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import CreateAlfException from '@/views/Admin/Alf/CreateAlfException.vue'
import DeleteAlfException from '@/views/Admin/Alf/DeleteAlfException.vue'
import YPagination from '@/components/ui/YPagination.vue'
import BackToTop from '@/mixins/BackToTopMixin.js'

export default {
  mixins: [BackToTop],
  components: {
    YPagination
  },
  apollo: {
   alfExceptions: {
      query: ALF_LISTINGS_QUERY,
      variables() {
        return {
          page: this.currentPage,
          first: this.perPage,
          filters: {
            country: this.filters.country.value ? {"value": this.filters.country.value} : undefined,
            subdomain: this.cleanFilter(this.filters.subdomain),
            excptionType: this.filters.level ? (this.filters.level == 'Redirect'? {"value": 'App\\Models\\Redirect'} : {"value": 'App\\Models\\User'}): undefined
          }
        }
      },
      result({ data }) {
        this.totalPages = data.alfExceptions.paginatorInfo.lastPage
      },
      fetchPolicy: 'network-only',
      deep: true
    },
    countries: {
      query: COUNTRIES_QUERY
    },
  },
  props: {},
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      totalPages: 0,
      verticalOptions: [],
      filters: {
        country: {
          value: null,
          title: 'Country',
          type: 'select',
          multiple: true,
          optionLabelKey: 'label',
          trackByKey: 'code'
        },
        level: "User",
        subdomain: {
            value: null,
            modifiers: {
                matchType: 'contains'
            },
            title: 'subdomain',
            type: 'input'
        },
        redirect: {
          value: null,
          title: 'Redirect',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
      },
      options: {
        level: ['Redirect', 'User'],
      },
      queries: {
        REDIRECT_OPTIONS_QUERY,
        COUNTRIES_QUERY
      }
    }
  },
  computed: {},
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    openDeleteModal(alfException) {
      this.$modal.show(
        DeleteAlfException,
        { alfException: alfException },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openCreateModal() {
      this.$modal.show(
        CreateAlfException,
        { },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    cleanObjectFilterValue(filter, pluck) {
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }
      return this.cleanFilter(filter).value.map(value => value[pluck])[0]
    }
  },
  mounted() {
    this.$events.listen('refreshAlfExceptions', () => {
      this.$apollo.queries.alfExceptions.refetch()
    })
    this.$tabEvent.on('refreshAlfExceptions', () => {
      this.$apollo.queries.alfExceptions.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refreshAlfExceptions')
    this.$tabEvent.off('refreshAlfExceptions')
  }
}
</script>
