var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-2 bg-inverse-900 hover:bg-inverse-700 text-white cursor-pointer",
    class: _vm.divClass,
    on: {
      "click": _vm.copyToClipboard
    }
  }, [_c('v-popover', {
    staticClass: "v-popover--block"
  }, [_c('Icon', {
    attrs: {
      "name": "link",
      "size": "6"
    }
  }), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "py-1 px-2 text-inverse-900"
  }, [_c('p', [_vm._v("Link copied!")])])])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }