var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex items-center justify-left mt-4 mb-3"
  }, [_c('tag-actions', {
    ref: "tagActions"
  })], 1), _c('YTable', {
    attrs: {
      "data": _vm.tags
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(_vm._s(props.rowData.type.capitalize()))]), _c('td', [_c('Tag', {
          attrs: {
            "color": props.rowData.color
          }
        }, [_vm._v(_vm._s(props.rowData.tag))])], 1), _c('td', {
          staticClass: "text-right"
        }, [_c('YButton', {
          staticClass: "mr-2 text-blue-600 hover:bg-blue-100",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$refs.tagActions.openEditModal(props.rowData);
            }
          }
        }, [_vm._v("Edit")]), _c('YConfirm', {
          attrs: {
            "message": `You are about to delete the ${props.rowData.type} tag: ${props.rowData.tag}`
          },
          on: {
            "confirm": function ($event) {
              return _vm.$refs.tagActions.delete([props.rowData]);
            }
          }
        }, [_c('YButton', {
          staticClass: "text-red-600 hover:bg-red-100",
          on: {
            "click": function ($event) {
              $event.preventDefault();
            }
          }
        }, [_vm._v("Delete")])], 1)], 1)];
      }
    }])
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v(" Type ")]), _c('th', [_vm._v(" Tag ")]), _c('th')])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }