export const state = {
  filters: {
    id: {
      value: null,
      modifiers: null,
      title: 'id',
      type: 'input'
    },
    subdomain: {
      value: null,
      modifiers: {
        matchType: 'contains'
      },
      title: 'subdomain',
      type: 'input'
    },
    user: {
      title: 'User',
      type: 'select',
      multiple: false,
      optionLabelKey: 'label',
      trackByKey: 'id',
      value: null
    },
    domain: {
      title: 'Domain',
      type: 'select',
      multiple: true,
      optionLabelKey: 'id',
      trackByKey: 'id',
      value: null
    },
    campaign: {
      title: 'Campaign',
      type: 'select',
      multiple: true,
      optionLabelKey: 'id',
      trackByKey: 'id',
      value: null
    },
    accountManager: {
      title: 'Account Manager',
      type: 'select',
      multiple: true,
      optionLabelKey: 'label',
      trackByKey: 'id',
      value: null
    },
    status: {
      title: 'Status',
      type: 'select',
      multiple: true,
      value: null
    }
  },
  orderBy: [
    {
      column: 'id',
      order: 'DESC'
    }
  ]
}

export const mutations = {
  UPDATE_FILTER_VALUE(state, payload) {
    state.filters[payload.filterKey].value = payload.value
  }
}

export const actions = {
  updateRedirectFilterValue({ commit }, value) {
    commit('UPDATE_FILTER_VALUE', value)
  }
}
