import { Line, mixins } from 'vue-chartjs'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  mounted() {
    this.renderChart(this.chartData, this.options)
    var ctx = this.$data._chart.ctx
    var originalStroke = ctx.stroke
    ctx.stroke = function() {
      ctx.save()
      ctx.shadowColor = 'rgba(0,0,0,0.1)'
      ctx.shadowBlur = 0
      ctx.shadowOffsetX = 3
      ctx.shadowOffsetY = 3
      originalStroke.apply(this, arguments)
      ctx.restore()
    }
  }
}
