var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "w-100 m-auto flex justify-end pr-3 pt-2",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('img', {
    staticStyle: {
      "height": "26px"
    },
    attrs: {
      "src": _vm.YtrackLogo
    }
  })])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-wrap w-3/4 m-auto justify-center text-sm bg-white p-6 rounded-lg border shadow-xl text-center",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_c('p', {
    staticClass: "text-lg mb-4 text-inverse-700"
  }, [_c('strong', [_vm._v("You have successfully updated your password and your account is now active!")])]), _c('p', {
    staticClass: "text-gray-900"
  }, [_vm._v(" You will be automatically redirected to the login page momentarily. ")]), _c('div', {
    staticClass: "progress mt-5"
  }, [_c('div', {
    staticClass: "progress-value"
  })])]);

}]

export { render, staticRenderFns }