<template>
  <div>
    <div
      class="flex flex-wrap w-3/4 m-auto justify-center text-sm bg-white p-6 rounded-lg border shadow-xl text-center"
      style="max-width: 24rem;"
    >
      <p class="text-lg mb-4 text-inverse-700">
        <strong
          >You have successfully updated your password and your account is now
          active!</strong
        >
      </p>
      <p class="text-gray-900">
        You will be automatically redirected to the login page momentarily.
      </p>
      <div class="progress mt-5">
        <div class="progress-value"></div>
      </div>
    </div>
    <div
      class="w-100 m-auto flex justify-end pr-3 pt-2"
      style="max-width: 24rem;"
    >
      <router-link :to="{ name: 'Login' }">
        <img style="height:26px;" :src="YtrackLogo" />
      </router-link>
    </div>
  </div>
</template>

<script>
import YtrackLogo from '@/assets/images/logo/logo-ytrack-gray.png'
export default {
  created() {
    setTimeout(() => this.$router.push({ name: 'Login' }), 7000)
  },
  data() {
    return {
      YtrackLogo: YtrackLogo
    }
  }
}
</script>

<style scoped>
.progress {
  background: #fff;
  justify-content: flex-start;
  border-radius: 10px;
  align-items: center;
  position: relative;
  padding: 1px;
  display: flex;
  height: 4px;
  width: 500px;
}
.progress-value {
  animation: loadingbar 10s linear forwards;
  border-radius: 10px;
  background: #e5386f;
  height: 4px;
}
@keyframes loadingbar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>
Footer
