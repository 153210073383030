var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.subscribedTasks.length > 0 || _vm.dbTasks.length > 0 ? _c('v-popover', {
    staticClass: "p-0",
    attrs: {
      "offset": "12",
      "placement": "bottom"
    },
    on: {
      "apply-show": _vm.opened,
      "apply-hide": _vm.closed
    }
  }, [_c('YButton', {
    staticClass: "link hover:bg-gray-100 focus:bg-gray-100 outline-none-important px-2 py-2 ml-2",
    staticStyle: {
      "position": "relative"
    }
  }, [_vm.pendingSubscribedCount > 0 ? _c('div', {
    staticClass: "bg-blue-400 text-white h-5 px-1 text-center",
    staticStyle: {
      "border-radius": "0.35rem",
      "font-size": "0.75rem",
      "line-height": "1.15rem",
      "position": "absolute",
      "right": "0",
      "top": "0",
      "z-index": "99"
    }
  }, [_vm._v(" " + _vm._s(_vm.pendingSubscribedCount) + " ")]) : _vm._e(), _vm.completedSubscribedCount > 0 ? _c('div', {
    staticClass: "bg-green-400 text-white h-5 px-1 text-center",
    staticStyle: {
      "border-radius": "0.35rem",
      "font-size": "0.75rem",
      "line-height": "1.15rem",
      "position": "absolute",
      "right": "0",
      "bottom": "0",
      "z-index": "99"
    }
  }, [_vm._v(" " + _vm._s(_vm.completedSubscribedCount) + " ")]) : _vm._e(), _c('TasksIcon', {
    staticClass: "w-8 h-8",
    attrs: {
      "loading": _vm.pendingSubscribedCount > 0
    }
  })], 1), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "bg-gray-100 pt-1 pb-2 px-4 border-b text-xxs font-bold flex justify-between"
  }, [_c('div'), _c('div', [_c('button', {
    staticClass: "uppercase text-blue-500 hover:text-blue-600",
    on: {
      "click": _vm.deleteFinished
    }
  }, [_vm._v(" Clear Finished ")])])]), _c('div', {
    staticStyle: {
      "width": "28rem"
    }
  }, [_c('Expandable', {
    attrs: {
      "initial-state": _vm.hasPendingTasks ? true : false,
      "clickable-class": "px-4 py-2 text-xxs text-blue-500 hover:text-blue-600 uppercase bg-gray-100 border-b"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" In Progress ")];
      },
      proxy: true
    }], null, false, 134835687)
  }, [_c('div', {
    staticStyle: {
      "max-height": "30rem",
      "overflow-y": "auto"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pendingSubscribed.length > 0,
      expression: "pendingSubscribed.length > 0"
    }]
  }, _vm._l(_vm.pendingSubscribed, function (task) {
    return _c('Task', {
      key: task.id,
      attrs: {
        "value": task,
        "emphasized": true
      },
      on: {
        "deleted": _vm.removeTask
      }
    });
  }), 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pendingDb.length > 0,
      expression: "pendingDb.length > 0"
    }]
  }, _vm._l(_vm.pendingDb, function (task) {
    return _c('Task', {
      key: task.id,
      attrs: {
        "value": task
      },
      on: {
        "deleted": _vm.removeTask
      }
    });
  }), 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pending.length <= 0,
      expression: "pending.length <= 0"
    }],
    staticClass: "text-gray-400 text-center py-1"
  }, [_vm._v(" No Tasks In Progress ")])])]), _c('Expandable', {
    attrs: {
      "initial-state": _vm.hasCompletedTasks ? true : false,
      "clickable-class": "px-4 py-2 text-xxs text-blue-500 hover:text-blue-600 uppercase bg-gray-100 border-b"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" Finished ")];
      },
      proxy: true
    }], null, false, 3917372111)
  }, [_c('div', {
    staticStyle: {
      "max-height": "30rem",
      "overflow-y": "auto"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.completed.length > 0,
      expression: "completed.length > 0"
    }]
  }, _vm._l(_vm.completed, function (task) {
    return _c('Task', {
      key: task.id,
      attrs: {
        "value": task,
        "emphasized": _vm.subscribedTaskIds.includes(task.id),
        "show-progress": false
      },
      on: {
        "deleted": _vm.removeTask
      }
    });
  }), 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.completed.length <= 0,
      expression: "completed.length <= 0"
    }],
    staticClass: "text-gray-400 text-center py-1"
  }, [_vm._v(" No Tasks In Progress ")])])])], 1)])], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }