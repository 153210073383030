<template>
  <Widget>
    <WidgetHeader @close="$emit('close')">
      Edit Redirect Fallback
    </WidgetHeader>

    <WidgetBody>
      <UpdateCampaignFallback
        :id="id"
        :fallbackCampaignId="fallbackCampaignId"
        :fallbackOfferId="fallbackOfferId"
        type="Redirect"
        @close="$emit('close')"
      />
    </WidgetBody>
  </Widget>
</template>

<script>
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import CAMPAIGN_QUERY from '@/graphql/Campaign/CampaignQuery.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import UPDATE_CAMPAIGN_MUTATION from '@/graphql/Campaign/UpdateCampaignMutation.gql'
import OFFER_QUERY from '@/graphql/Offer/OfferQuery.gql'
import UpdateCampaignFallback from '@/views/Admin/Campaign/UpdateCampaignFallback'

export default {
  props: {
    id: {
      type: String
    },
    fallbackCampaignId: {
      type: String,
      default: null
    },
    fallbackOfferId: {
      type: String,
      default: null
    }
  },
  components: {
    UpdateCampaignFallback
  },
  mixins: [ValidationErrorsMixin],
  apollo: {
    campaign: {
      query: CAMPAIGN_QUERY,
      variables() {
        return {
          id: this.selectedCampaign
            ? this.selectedCampaign.id
            : this.fallbackCampaignId
        }
      },
      result(result) {
        this.selectedCampaign = {
          __typename: 'Campaign',
          id: result.data.campaign.id,
          label: result.data.campaign.label,
          name: result.data.campaign.name
        }

        this.fallbackOfferOptions = result.data.campaign.offers

        if (
          !this.fallbackOfferId ||
          (this.fallbackCampaignId &&
            this.fallbackCampaignId !== this.selectedCampaign.id)
        ) {
          this.selectedOffer = result.data.campaign.offers[0]
        }
      }
    },
    offer: {
      query: OFFER_QUERY,
      variables() {
        return {
          id: this.fallbackOfferId
        }
      },
      result(result) {
        this.selectedOffer = result.data.offer
      }
    }
  },
  data() {
    return {
      CAMPAIGN_OPTIONS_QUERY,
      selectedCampaign: null,
      selectedOffer: null,
      fallbackOfferOptions: [],
      isUpdating: false
    }
  },
  watch: {
    selectedCampaign() {
      if (this.$apollo.queries.campaign.skip) {
        this.$apollo.queries.campaign.skip = false
      }
    }
  },
  mounted() {
    if (!this.fallbackCampaignId) {
      this.$apollo.queries.campaign.skip = true
    }
  },
  methods: {
    clearFallbackValues() {
      this.selectedCampaign = null
      this.selectedOffer = null
      this.fallbackOfferOptions = []
    },
    saveFallback() {
      this.isUpdating = true
      this.$apollo
        .mutate({
          mutation: UPDATE_CAMPAIGN_MUTATION,
          variables: {
            input: {
              id: this.id,

              fallbackCampaignId: this.selectedCampaign
                ? this.selectedCampaign.id
                : '',
              fallbackOfferId: this.selectedOffer ? this.selectedOffer.id : ''
            }
          }
        })
        .then(() => {
          this.isUpdating = false
          this.clearValidationErrors()
          this.$toastr.s('Fallback Campaign Updated', 'Success')
          this.$events.emit('fallbackCampaignUpdated')
        })
        .catch(error => {
          this.isUpdating = false
          this.setValidationErrors(error)
          console.error(error)
        })
    }
  }
}
</script>

<style></style>
