<template>
  <div class="border-b">
    <div>
      <div class="flex items-center w-full">
        <div
          class="rounded ml-4 -mr-1"
          :class="[
            emphasized ? 'bg-green-500' : 'bg-gray-300',
            emphasized ? '' : 'invisible'
          ]"
          style="width: 5px; height: 5px;"
        ></div>
        <div class="flex justify-between px-4 py-2 w-full">
          <div>
            <div class="font-bold text-gray-900">
              {{ task.name ?? 'Unnamed Task' }}
            </div>
            <div class="text-gray-500 pb-1">
              <div class="mt-0 uppercase text-gray-500 text-xxs font-semibold">
                STARTED
                {{
                  task.createdAt
                    | luxon({
                      output: { format: 'yyyy-MM-dd @ h:mm a' }
                    })
                }}
              </div>
              <div
                v-if="task.isFinished"
                class="mt-0 uppercase text-green-500 text-xxs font-semibold"
              >
                COMPLETED
                {{
                  task.completedAt
                    | luxon({
                      output: { format: 'yyyy-MM-dd @ h:mm a' }
                    })
                }}
              </div>
              <div
                v-if="task.type == 'adminReport' && task.isFinished"
                class="mt-1"
              >
                <router-link
                  class="link"
                  :to="{ name: 'Report', params: { id: task.meta.reportId } }"
                  >View Report</router-link
                >
              </div>
            </div>
          </div>
          <div class="pt-0">
            <div
              class="h-8 w-8 text-right"
              v-on:mouseover="handleRightIconMouseover(task.id)"
              v-on:mouseleave="handleRightIconMouseleave(task.id)"
            >
              <div v-show="rightIconHover[task.id]">
                <button
                  @click="clear(task.id)"
                  class="h-8 w-8 flex flex-row-reverse items-center text-gray-500 hover:text-blue-500"
                >
                  <Icon name="close" size="6" />
                </button>
              </div>
              <div v-show="!rightIconHover[task.id]">
                <div v-if="task.status == 'pending'" class="fadePulse">
                  <Spinner color="gray" size="6" />
                </div>
                <Icon
                  v-if="task.status == 'success'"
                  name="check"
                  class="text-green-500"
                  size="8"
                />
                <Icon
                  v-if="task.status == 'error'"
                  name="alert"
                  class="text-red-500"
                  size="6"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="-mt-2" v-if="task.hasResults">
          <Expandable
            :title="
              task.resultsCount + ' Result' + (task.resultsCount > 1 ? 's' : '')
            "
            clickable-class="px-4 text-xxs text-blue-500 hover:text-blue-600 uppercase"
            @expanded="fetchTask(task.id)"
          >
            <div v-if="taskIsFetching[task.id]" class="text-center">
              <Spinner color="gray" size="4" />
            </div>
            <div v-else>
              <JSONView
                v-if="task.results"
                class="text-xxs"
                root-key="Data"
                :data="task.results"
                :max-depth="0"
              />
            </div>
          </Expandable>
        </div>
        <ProgressBar
          v-if="showProgress"
          bar-color="#0b89ea"
          :val="task.isFinished ? 100 : task.progress * 100"
          size="small"
          :bar-border-radius="5"
        ></ProgressBar>
      </div>
    </div>
  </div>
</template>

<script>
import Expandable from '@/components/ui/Expandable'
import { JSONView } from 'vue-json-component'
import TASK_QUERY from '@/graphql/Task/TaskQuery.gql'
import DELETE_TASK_MUTATION from '@/graphql/Task/DeleteTaskMutation.gql'

export default {
  components: {
    Expandable,
    JSONView
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    showProgress: {
      type: Boolean,
      default: true
    },
    emphasized: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rightIconHover: {},
      taskIsFetching: {},
      task: this.value,
      isDeleting: false
    }
  },
  watch: {
    value: {
      handler: function(value) {
        this.task = value
      },
      deep: true
    }
  },
  methods: {
    clear(taskId) {
      this.isDeleting = true
      this.$apollo
        .mutate({
          mutation: DELETE_TASK_MUTATION,
          variables: {
            id: taskId
          }
        })
        .then(({ data }) => {
          this.isDeleting = false
          this.$emit('deleted', data.deleteTask.id)
        })
        .catch(err => {
          this.isDeleting = false
          console.log(err)
        })
    },
    updateTask(task) {
      this.task = task
    },
    fetchTask(taskId) {
      this.taskIsFetching[taskId] = true
      this.taskIsFetching = { ...this.taskIsFetching }
      this.$apollo
        .mutate({
          mutation: TASK_QUERY,
          variables: {
            id: taskId
          }
        })
        .then(({ data }) => {
          this.updateTask(data.task)
          delete this.taskIsFetching[taskId]
          this.taskIsFetching = { ...this.taskIsFetching }
        })
        .catch(err => {
          console.log(err)
          delete this.taskIsFetching[taskId]
          this.taskIsFetching = { ...this.taskIsFetching }
        })
    },
    handleRightIconMouseover(id) {
      if (this.task.status == 'pending') {
        return
      }
      if (this.rightIconHover[id] == true) {
        return
      }
      this.rightIconHover[id] = true
      this.rightIconHover = { ...this.rightIconHover }
    },
    handleRightIconMouseleave(id) {
      if (this.task.status == 'pending') {
        return
      }
      this.rightIconHover[id] = false
      this.rightIconHover = { ...this.rightIconHover }
    }
  }
}
</script>

<style></style>
