<template>
  <Widget>
    <WidgetHeader>Set Lead Priority</WidgetHeader>
    <WidgetBody>
      <div class="flex flex-row gap-6 px-4">
        <div class="flex w-2/5">
          <div class="flex flex-col w-full">
            <div class="flex flex-col text-gray-900">
              <span v-if="!user.advertiserId">
                <span class="block font-semibold text-gray-800 text-lg">{{
                  user.name
                }}</span>
                <span class=""
                  >{{ user.id }}
                  <span class="inline" v-if="user.primaryContact.company">
                    - {{ user.primaryContact.company }}</span
                  >
                </span>
              </span>
              <span v-else
                ><span class="block font-semibold text-gray-800 text-lg">{{
                  user.primaryContact.company
                }}</span>
                <span class="inline">{{ user.label }}</span>
              </span>

              <span class="block" v-if="user.email">{{ user.email }}</span>
              <div
                v-if="user.primaryContact.messenger"
                class="flex items-center text-xs"
              >
                <Icon
                  :name="
                    JSON.parse(user.primaryContact.messenger.toLowerCase()).app
                  "
                  class="w-4 h-4 text-gray-600"
                />
                <span class="text-gray-600">
                  {{ JSON.parse(user.primaryContact.messenger).handle }}
                </span>
              </div>
              <div class="flex flex-row mt-2">
                <div>
                  <Tag
                    v-for="tag in user.tags.filter(
                      item => item.type == 'leadType'
                    )"
                    :key="tag.id"
                    class="mr-2"
                  >
                    {{ tag.name }}
                  </Tag>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid gap-2 w-full items-start bg-gray-100 rounded p-4">
          <Superselect
            title="Priority Status"
            class="w-full capitalize"
            v-model="priorityStatus"
            :multiple="false"
            :options="['regular', 'high', 'rejected']"
          ></Superselect>

          <div class="relative">
            <div class="absolute top-0 right-0 z-50 pr-1 text-2xl text-red-500">
              *
            </div>
            <VueCtkDateTimePicker
              v-model="followUpDate"
              :onlyDate="true"
              :format="'YYYY-MM-DD'"
              :formatted="'ll'"
              :label="'Follow Up Date for High Priority'"
              locale="en"
              :range="false"
              :inititale-value="initialDateValue"
              class="mb-2"
            >
            </VueCtkDateTimePicker>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-end gap-3 mt-4">
        <YButton color="gray" @click="$emit('close')">Cancel</YButton>
        <YButton color="blue" @click="save">Save</YButton>
      </div>
    </WidgetBody>
  </Widget>
</template>

<script>
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      isUpdating: false,
      priorityStatus: this.user.userDetails.priorityStatus,
      followUpDate: this.$luxon(this.user.userDetails.followUpAt),
      initialDateValue: this.$luxon(this.user.userDetails.followUpAt)
    }
  },
  methods: {
    save() {
      this.isUpdating = true
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            input: {
              id: this.user.id,
              userDetails: {
                update: {
                  id: this.user.userDetails.id,
                  priorityStatus: this.priorityStatus,
                  followUpAt: this.followUpDate ? this.followUpDate : null
                }
              }
            }
          }
        })
        .then(() => {
          this.isUpdating = false
          this.$emit('close')
        })
        .catch(error => {
          console.error(error)
          this.isUpdating = false
          this.$toastr.e('Error')
        })
    }
  }
}
</script>
