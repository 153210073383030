var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget",
    class: _vm.backgroundClass,
    staticStyle: {
      "position": "relative"
    },
    on: {
      "mouseenter": function ($event) {
        _vm.isHovered = true;
      },
      "mouseleave": function ($event) {
        _vm.isHovered = false;
      }
    }
  }, [_vm.disabled ? _vm._t("disabledOverlay") : _vm._e(), _vm.loading ? _c('Spinner', {
    staticClass: "m-4 absolute",
    staticStyle: {
      "top": "0",
      "right": "0"
    },
    attrs: {
      "size": 12,
      "color": "blue"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "widget-inner flex flex-col h-full"
  }, [_c('transition', {
    attrs: {
      "css": false
    },
    on: {
      "before-enter": _vm.animateHoverActionsBeforeEnter,
      "enter": _vm.animateHoverActionsEnter,
      "leave": _vm.animateHoverActionsLeave
    }
  }, [_vm.actions != undefined && _vm.actions.type == 'horizontal-hover' ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isHovered,
      expression: "isHovered"
    }],
    staticClass: "absolute m-3 actions animated",
    staticStyle: {
      "top": "0",
      "right": "0"
    }
  }, [_c('transition-group', {
    attrs: {
      "css": false
    },
    on: {
      "before-enter": _vm.animateHoverActionsBeforeEnter,
      "enter": _vm.animateHoverActionsEnter,
      "leave": _vm.animateHoverActionsLeave
    }
  }, [_vm._l(_vm.actions.actions, function (item) {
    return [item.type == 'button' ? _c('button', {
      key: item.id,
      staticClass: "btn btn-sm btn-dark-o ml-1",
      on: {
        "click": item.onClick
      }
    }, [item.iconClass != undefined ? _c('i', {
      staticClass: "mr-2",
      class: item.iconClass,
      staticStyle: {
        "width": "10px"
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(item.text) + " ")]) : _vm._e()];
  })], 2)], 1) : _vm._e()]), _vm._t("default")], 2)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }