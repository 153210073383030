var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "inline-flex items-center editable-component",
    class: [_vm.isLoading ? 'loading' : '', _vm.hasUnsavedChanges ? 'has-unsaved-changes' : '']
  }, [_vm.defaultSlotVisible ? [_vm._t("default", function () {
    return [_vm.type == 'contentEditable' ? _c('span', {
      ref: "contentEditable",
      staticClass: "outline-none-important focus:shadow-md hover:bg-yellow-200 focus:bg-yellow-200 editable-field",
      class: [_vm.valueClass, _vm.hasUnsavedChanges ? 'bg-yellow-200' : '', _vm.inputClass],
      style: _vm.inputStyle,
      attrs: {
        "contenteditable": "true",
        "placeholder": _vm.placeholder
      },
      domProps: {
        "textContent": _vm._s(_vm.value)
      },
      on: {
        "input": _vm.handleInput,
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.keyDownEnter.apply(null, arguments);
        },
        "focus": _vm.toggleFocus,
        "blur": _vm.toggleFocus
      }
    }) : _vm.type == 'checkbox' ? _c('YCheckbox', {
      ref: "checkbox",
      class: _vm.inputClass,
      style: _vm.inputStyle,
      on: {
        "blur": _vm.unfocus
      },
      model: {
        value: _vm.internalValue,
        callback: function ($$v) {
          _vm.internalValue = $$v;
        },
        expression: "internalValue"
      }
    }) : _vm.type == 'richText' ? _c('ckeditor', {
      ref: "richText",
      class: _vm.inputClass,
      style: _vm.inputStyle,
      attrs: {
        "editor": _vm.editor
      },
      on: {
        "blur": _vm.unfocus
      },
      model: {
        value: _vm.internalValue,
        callback: function ($$v) {
          _vm.internalValue = $$v;
        },
        expression: "internalValue"
      }
    }) : _vm._e()];
  }, {
    "value": _vm.internalValue,
    "updateInternalValue": _vm.updateInternalValue,
    "hasUnsavedChanges": _vm.hasUnsavedChanges,
    "focusOnSave": _vm.focusOnSave,
    "focus": _vm.focus,
    "unfocus": _vm.unfocus
  })] : _vm._e(), _vm.focusSlotVisible ? [_vm._t("focusSlot", null, {
    "value": _vm.internalValue,
    "updateInternalValue": _vm.updateInternalValue,
    "hasUnsavedChanges": _vm.hasUnsavedChanges,
    "unfocusOnNoChanges": _vm.unfocusOnNoChanges,
    "focusOnSave": _vm.focusOnSave,
    "focus": _vm.focus,
    "unfocus": _vm.unfocus
  })] : _vm._e(), !_vm.hasUnsavedChanges && _vm.editFocusIconEnabled ? _c('sup', {
    on: {
      "click": _vm.toggleFocus
    }
  }, [_c('Icon', {
    staticClass: "ml-1 cursor-pointer",
    class: [_vm.focused ? 'text-blue-600' : 'text-blue-300'],
    attrs: {
      "name": "pencil",
      "size": 3
    }
  })], 1) : _vm._e(), _vm.hasUnsavedChanges ? _c('span', {
    staticClass: "inline-flex"
  }, [_vm._t("actions", function () {
    return [_vm.isLoading ? _c('Spinner', {
      staticClass: "ml-1",
      attrs: {
        "size": 6,
        "color": "green"
      }
    }) : _c('span', {
      staticClass: "inline-flex items-center rounded-lg shadow-md ml-2 overflow-hidden"
    }, [_c('span', {
      ref: "saveAction",
      staticClass: "cursor-pointer px-1 bg-green-300 hover:bg-green-400 focus:bg-green-400 text-green-700 outline-none-important",
      attrs: {
        "tabindex": "0"
      },
      on: {
        "click": _vm.save,
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.save.apply(null, arguments);
        }
      }
    }, [_c('Icon', {
      attrs: {
        "name": "contentSave"
      }
    })], 1), _c('span', {
      staticClass: "cursor-pointer bg-red-300 hover:bg-red-400 focus:bg-red-400 text-red-700 px-1 outline-none-important",
      attrs: {
        "tabindex": "0"
      },
      on: {
        "click": _vm.cancel,
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.save.apply(null, arguments);
        }
      }
    }, [_c('Icon', {
      attrs: {
        "name": "close"
      }
    })], 1)])];
  })], 2) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }