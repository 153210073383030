<template>
  <div class="slide-height">
    <agile
      ref="carousel"
      :navButtons="false"
      :dots="false"
      @after-change="showCurrentSlide($event)"
    >
      <div class="slide">
        <div class="slide-container">
          <img src="@/assets/images/publisher/marketingScene.svg" />

          <span class="slide-headline">Welcome to yTrack!</span>
          <div class="slide-text">
            The yTrack app makes it nice and easy to make more money from your
            valuable traffic. Take a quick tour to see where useful features are
            so you can be up and running in no time!
          </div>
        </div>
      </div>
      <div class="slide">
        <div class="slide-container">
          <img
            src="@/assets/images/publisher/campaigns.gif"
            class="m-auto"
            style="min-width:500px;"
          />

          <span class="slide-headline">Finding Campaigns</span>
          <div class="slide-text">
            To find the right campaign for your traffic, browse our campaign
            section with the ability to filter by vertical, country and more.
          </div>
        </div>
      </div>
      <div class="slide">
        <div class="slide-container">
          <img
            src="@/assets/images/publisher/postback.gif"
            class="m-auto"
            style="min-width:500px;"
          />

          <span class="slide-headline">Add a Postback</span>
          <div class="slide-text">
            Once you've found a campaign, make sure to add your postback link so
            that you can track your conversions. A global postback link will
            fire conversions for
            <span class="font-bold">all</span> campaigns.
            <br />
            <span class="leading-9"
              >Available parameters are: {S1} {S2} {S3} {S4} {S5}
              {REVENUE}.</span
            >
            <br />
            Sources should be passed through the KW field in your offer links.
          </div>
        </div>
      </div>
      <div class="slide">
        <div class="slide-container">
          <img
            src="@/assets/images/publisher/finance.gif"
            class="m-auto"
            style="min-width:500px;"
          />

          <span class="slide-headline">Set Up Payments</span>
          <div class="slide-text">
            Our default payment terms are semi-monthly net 15.
            <br />
            <span class="leading-9"
              >Fill out your payment information as soon as possible to avoid
              any payment delays.
            </span>
            <br />In this section you will find available payment methods and
            minimum revenue thresholds.
          </div>
        </div>
      </div>

      <div class="slide">
        <div class="slide-container">
          <svg
            class="checkmark m-auto mb-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              :class="{ checkmark__circle: showFinalSlide }"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              :class="{ checkmark__check: showFinalSlide }"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>

          <span class="slide-headline">You're all set!</span>
          <div class="slide-text">
            We've shown you the basics. You can find these tips any time in the
            <span class="font-bold">yDocs</span> section.
          </div>
          <YButton
            v-if="user"
            @click="tutorialFinish"
            color="blue"
            class="mt-10 w-3/4 m-auto text-lg"
            >Got it
          </YButton>
          <router-link
            v-if="!user"
            :to="{
              name: 'Publisher Dashboard'
            }"
          >
            <YButton color="blue" class="mt-10 w-3/4 m-auto text-lg"
              >Got it
            </YButton>
          </router-link>
        </div>
      </div>
    </agile>

    <!-- Carousel Navigation -->
    <div
      class="flex justify-between items-end w-100 bg-white px-12 pt-3 pb-8"
      v-if="!showFinalSlide"
    >
      <YButton
        @click="previousBtn"
        color="link"
        :disabled="disablePreviewBtn"
        class="w-32 text-lg"
        >Previous</YButton
      >
      <button
        v-if="user"
        class="text-blue-600 cursor-pointer underline text-xs "
        @click="tutorialFinish"
      >
        Skip Tutorial
      </button>
      <YButton @click="nextBtn" color="blue" class="w-32 text-lg">Next</YButton>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'

export default {
  components: {
    agile: VueAgile
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  mounted() {},
  updated() {},
  beforeDestroy() {
    if (this.$refs.carousel) {
      this.$refs.carousel.$destroy()
    }
  },
  data() {
    return {
      showFinalSlide: false,
      nextSlide: 0,
      totalSlides: 4,
      tutorialViewed: false,
      isSubmitting: false
    }
  },
  computed: {
    disablePreviewBtn() {
      return this.nextSlide === 1
    }
  },
  methods: {
    nextBtn() {
      if (this.nextSlide == this.totalSlides) {
        this.showFinalSlide = true
        this.$refs.carousel.goToNext()
      } else {
        this.$refs.carousel.goToNext()
      }
    },
    previousBtn() {
      this.disablePreviewBtn ? null : this.$refs.carousel.goToPrev()
    },
    showCurrentSlide(slide) {
      this.nextSlide = slide.currentSlide + 1
    },
    tutorialFinish() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            input: {
              id: this.user.id,
              userDetails: {
                update: {
                  id: this.user.userDetails.id,
                  tutorialViewed: true
                }
              }
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$emit('close')
          this.$events.emit('closeModal')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.submitting = false
        })
    }
  }
}
</script>

<style scoped>
.slide {
  @apply px-12 py-8;
}
.slide-container {
  @apply flex flex-col justify-center content-center text-center;
}
.slide-headline {
  @apply text-4xl font-bold tracking-wider mt-5;
}
.slide-text {
  @apply my-1 px-4 text-lg;
}
.slide-container img {
  @apply m-auto;
  height: 325px;
}
.checkmark {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4fa94f;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4fa94f;
  fill: #4fa94f;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4fa94f;
  }
}
</style>
