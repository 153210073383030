var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "my-2 p-3",
    staticStyle: {
      "border": "1px solid rgba(0,0,0,0.1)",
      "border-radius": "3px",
      "box-shadow": "1px 1px 4px rgba(0,0,0,0.06)"
    }
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('div', {
    staticClass: "flex-grow-md-1 w-full w-md-auto"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', [_c('div', {
    staticClass: "flex items-center mb-2"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('h6', {
    staticClass: "mb-0 font-bold"
  }, [_c('Icon', {
    staticClass: "inline -mx-1",
    class: _vm.serverIsUp ? 'text-green-500' : 'text-red-500',
    attrs: {
      "name": "circleMedium",
      "size": 6
    }
  }), _vm.server.hostname ? [_vm._v(" " + _vm._s(_vm.server.hostname) + " ")] : [_vm._v(" " + _vm._s(_vm.server.provider.name) + " - " + _vm._s(_vm.server.id) + " ")]], 2)])]), _vm.server.ipSummary ? [_c('div', {
    staticClass: "text-gray-600 text-xs"
  }, [_vm._v(" Server IP: "), _c('EditableValue', {
    key: _vm.server.id,
    staticClass: "text-blue-600 font-semibold",
    attrs: {
      "type": "contentEditable",
      "value": _vm.server.serverIp,
      "mutation": _vm.UPDATE_SERVERS_MUTATION,
      "variables": {
        input: [{
          id: _vm.server.id,
          serverIp: '{value}'
        }]
      },
      "edit-focus-icon-enabled": false
    }
  })], 1), _vm.server.type != 'nameserver' ? _c('div', {
    staticClass: "text-gray-600 text-xs"
  }, [_vm._v(" Total IPs: "), _c('span', {
    staticClass: "text-blue-600 font-semibold"
  }, [_vm._v(_vm._s(_vm.server.ipSummary.count))])]) : _vm._e(), _vm.server.type != 'nameserver' ? _c('div', {
    staticClass: "text-gray-600 text-xs"
  }, [_vm._v(" Enabled IPs: "), _c('span', {
    staticClass: "text-green-600 font-semibold"
  }, [_vm._v(_vm._s(_vm.server.ipSummary.enabledCount))])]) : _vm._e(), _c('div', {
    staticClass: "text-gray-600 text-xs"
  }, [_vm._v(" Server Type: "), _vm.server.type == null ? _c('span', {
    staticClass: "text-blue-600 font-semibold"
  }, [_vm._v("proxy")]) : _c('span', {
    staticClass: "text-blue-600 font-semibold"
  }, [_vm._v(_vm._s(_vm.server.type))])])] : _vm._e()], 2), _c('div', [_c('div', {
    staticClass: "block text-right"
  }, [_c('server-actions', {
    ref: "serverActions",
    attrs: {
      "servers": [_vm.server],
      "provider": _vm.provider
    }
  })], 1)])]), _vm.server.type != 'nameserver' ? _c('div', [_c('ips', {
    attrs: {
      "show-ips-by-default": _vm.showIpsByDefault,
      "server": _vm.server,
      "value": _vm.server.ips
    }
  })], 1) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }