var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block md:flex items-center",
    attrs: {
      "role": "group"
    }
  }, [_c('VueCtkDateTimePicker', {
    ref: "input",
    attrs: {
      "id": "date-preset-picker",
      "format": "YYYY-MM-DD hh:mm a",
      "formatted": "YYYY-MM-DD hh:mm a",
      "range": true,
      "no-value-to-custom-elem": true,
      "no-header": true,
      "no-button": true,
      "auto-close": true,
      "color": "#0e76bc",
      "button-color": "#41b883",
      "custom-shortcuts": _vm.ranges
    },
    model: {
      value: _vm.mutableValue,
      callback: function ($$v) {
        _vm.mutableValue = $$v;
      },
      expression: "mutableValue"
    }
  }, [_c('YButton', {
    staticClass: "btn-inverse mb-4 md:mb-0 mr-0 flex items-center justify-center",
    attrs: {
      "type": "button"
    }
  }, [_c('Icon', {
    staticClass: "inline-flex",
    attrs: {
      "name": "calendar",
      "size": 4
    }
  }), _c('span', {
    staticClass: "inline ml-2"
  }, [_vm._v("Date Range")])], 1)], 1), _c('div', {
    staticClass: "flex items-center flex-wrap md:flex-no-wrap"
  }, [_c('VueCtkDateTimePicker', {
    key: _vm.startTimeKey,
    attrs: {
      "locale": "en",
      "label": "Start Date",
      "no-button-now": true,
      "format": "YYYY-MM-DD hh:mm a",
      "formatted": "llll",
      "color": "#0e76bc",
      "button-color": "#41b883"
    },
    model: {
      value: _vm.startTime,
      callback: function ($$v) {
        _vm.startTime = $$v;
      },
      expression: "startTime"
    }
  }, [_c('YButton', {
    staticClass: "btn-white mb-2 md:mb-0 md:ml-2 nowrap outline-none-important hover:bg-gray-100 rounded md:rounded-r-none"
  }, [_vm._v(" Start Time ")])], 1), _c('VueCtkDateTimePicker', {
    key: _vm.endTimeKey,
    attrs: {
      "locale": "en",
      "label": "End Date",
      "no-button-now": true,
      "format": "YYYY-MM-DD hh:mm a",
      "formatted": "llll",
      "color": "#0e76bc",
      "button-color": "#41b883"
    },
    model: {
      value: _vm.endTime,
      callback: function ($$v) {
        _vm.endTime = $$v;
      },
      expression: "endTime"
    }
  }, [_c('YButton', {
    staticClass: "btn btn-white nowrap outline-none-important rounded md:rounded-l-none"
  }, [_vm._v(" End Time ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }