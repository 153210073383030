var render = function render(){
  var _vm$reportId;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: (_vm$reportId = _vm.reportId) !== null && _vm$reportId !== void 0 ? _vm$reportId : 'default'
  }, [_c('ReportFilters', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilters,
      expression: "showFilters"
    }],
    ref: "form",
    attrs: {
      "link": this,
      "master-column-data": _vm.masterColumnData,
      "create-vuetable-columns": _vm.createVuetableColumns,
      "persist-state": _vm.persistState,
      "auto-load": _vm.autoLoad,
      "default-date-range": _vm.defaultDateRange,
      "default-filters": _vm.defaultFilters,
      "enablePresets": _vm.enablePresets,
      "initial-preset-id": _vm.initialPresetId,
      "reportId": _vm.reportId
    }
  }), _c('Widget', {
    class: [_vm.hasEverLoaded ? '' : 'disabled', 'mt-2'],
    attrs: {
      "loading": _vm.isLoading
    }
  }, [_c('WidgetHeader', [_c('template', {
    slot: "rawContent"
  }, [_vm._t("table-header", function () {
    return [_c('h4', {
      staticClass: "widget-title"
    }, [_vm._v(_vm._s(_vm.readableDateRange))])];
  }), _vm._t("right", function () {
    return [_vm.isLoading ? _c('Spinner', {
      attrs: {
        "size": 35,
        "color": `green`
      }
    }) : _c('div', {
      staticClass: "flex"
    }, [_c('YButton', {
      staticClass: "mr-1 border border-blue-600 hover:bg-blue-600 focus:bg-blue-600 text-blue-600 hover:text-white focus:text-white mr-3 outline-none-important",
      attrs: {
        "type": "button",
        "disabled": _vm.isExporting || _vm.isLoading,
        "is-loading": _vm.isExporting
      },
      on: {
        "click": _vm.exportData
      }
    }, [_vm._v("Export")]), _c('v-popover', {
      class: ['sidebar-user-popover'],
      attrs: {
        "offset": "10",
        "placement": "left-start",
        "autoHide": true,
        "popoverClass": "",
        "popoverBaseClass": "popover-menu sidebar-popover-menu",
        "popoverInnerClass": "popover-menu-inner animated animated-speed1 fadeIn"
      }
    }, [_c('YButton', {
      staticClass: "btn btn-transparent btn-rounded tooltip-target"
    }, [_c('Icon', {
      attrs: {
        "name": "dotsVertical",
        "size": 4
      }
    })], 1), _c('template', {
      slot: "popover"
    }, [_c('ul', {
      staticClass: "list-group popover-menu-listgroup"
    }, [_c('li', {
      staticClass: "list-group-item"
    }, [_c('a', {
      staticClass: "list-group-link",
      on: {
        "click": function ($event) {
          return _vm.$modal.show('report-load-times');
        }
      }
    }, [_c('i', {
      staticClass: "zmdi zmdi-time mr-2"
    }), _vm._v(" View Load Times")])])])])], 2)], 1)];
  })], 2)], 2), _c('WidgetBody', {
    class: [_vm.$refs.form && _vm.$refs.form.isSubmitting ? 'reports-loading' : '']
  }, [_vm._t("table-body-start"), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col overflow-x-scroll"
  }, [_c('vuetable', {
    ref: "vuetable",
    staticClass: "reports-table table-striped table-hover",
    attrs: {
      "api-mode": false,
      "fields": _vm.fields,
      "data": _vm.vuetableData,
      "data-total": _vm.dataTotal,
      "pagination-path": "pagination",
      "data-path": "data",
      "css": _vm.css,
      "sort-order": _vm.sortOrder,
      "multi-sort": _vm.multiSort,
      "per-page": _vm.perPage,
      "multi-sort-key": "shift",
      "cellspacing": "0",
      "width": "100%",
      "table-wrapper": "vuetable-wrapper"
    },
    on: {
      "vuetable:loading": _vm.onLoading,
      "vuetable:loaded": _vm.onLoaded
    },
    scopedSlots: _vm._u([{
      key: "tableTotals",
      fn: function (props) {
        return [_c('tr', {
          staticClass: "spacer"
        }), _c('tr', _vm._l(props.fields, function (field) {
          return _c('td', {
            key: field.name
          }, [_c('span', {
            domProps: {
              "textContent": _vm._s(_vm.formatTotalField(field))
            }
          })]);
        }), 0), _c('tr', {
          staticClass: "spacer h-3"
        })];
      }
    }, {
      key: "publisher-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.publisher ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.publisher ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.publisher.split(' - ')[0],
              label: props.rowData.publisher
            }
          },
          model: {
            value: _vm.$refs.form.filters.publisher.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.publisher, "value", $$v);
            },
            expression: "$refs.form.filters.publisher.value"
          }
        }) : _vm._e(), _c('router-link', {
          staticClass: "link truncate block",
          staticStyle: {
            "max-width": "13rem"
          },
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.publisher.split(' - ')[0]
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.publisher) + " ")])], 1)] : _vm._e()], 2);
      }
    }, {
      key: "conversion-type-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.conversionType ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.conversionType ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.conversionType,
              label: props.rowData.conversionType
            }
          },
          model: {
            value: _vm.$refs.form.filters.conversionType.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.conversionType, "value", $$v);
            },
            expression: "$refs.form.filters.conversionType.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.conversionType)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "payout-conversion-type-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.payoutConversionType ? [_c('div', {
          staticClass: "report-value"
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.payoutConversionType)
          }
        })])] : _vm._e()], 2);
      }
    }, {
      key: "postback-conversion-type-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.postbackConversionType ? [_c('div', {
          staticClass: "report-value"
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.postbackConversionType)
          }
        })])] : _vm._e()], 2);
      }
    }, {
      key: "publisher-status-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.publisherStatus ? [_c('Tag', {
          key: props.rowData.publisherStatus
        }, [_vm._v(_vm._s(props.rowData.publisherStatus))])] : _vm._e()], 2);
      }
    }, {
      key: "advertiser-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.advertiser ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.advertiser ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.advertiser.split(' - ')[0],
              label: props.rowData.advertiser
            }
          },
          model: {
            value: _vm.$refs.form.filters.advertiser.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.advertiser, "value", $$v);
            },
            expression: "$refs.form.filters.advertiser.value"
          }
        }) : _vm._e(), _c('router-link', {
          staticClass: "link truncate block",
          staticStyle: {
            "max-width": "13rem"
          },
          attrs: {
            "to": {
              name: 'Advertiser',
              params: {
                id: props.rowData.advertiser.split(' - ')[0],
                tab: 'details'
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.advertiser) + " ")])], 1)] : _vm._e()], 2);
      }
    }, {
      key: "country-slot",
      fn: function (props) {
        return _c('div', {}, [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.country ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.country,
              label: props.rowData.country
            }
          },
          model: {
            value: _vm.$refs.form.filters.country.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.country, "value", $$v);
            },
            expression: "$refs.form.filters.country.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.country)
          }
        })], 1)]);
      }
    }, {
      key: "campaign-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.campaign ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.campaign ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.campaign.split(' - ')[0],
              label: props.rowData.campaign
            }
          },
          model: {
            value: _vm.$refs.form.filters.campaign.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.campaign, "value", $$v);
            },
            expression: "$refs.form.filters.campaign.value"
          }
        }) : _vm._e(), _c('router-link', {
          staticClass: "link without-truncate block",
          staticStyle: {
            "max-width": "13rem"
          },
          attrs: {
            "to": {
              name: 'Campaign',
              params: {
                id: props.rowData.campaign.split(' - ')[0]
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.campaign) + " ")])], 1)] : _vm._e()], 2);
      }
    }, {
      key: "redirect-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.redirect ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.redirect ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.redirect.split(' - ')[0],
              label: props.rowData.redirect
            }
          },
          model: {
            value: _vm.$refs.form.filters.redirect.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.redirect, "value", $$v);
            },
            expression: "$refs.form.filters.redirect.value"
          }
        }) : _vm._e(), props.rowData.hasOwnProperty('redirect') && props.rowData.hasOwnProperty('redirectLink') && props.rowData.redirect.split(' - ')[1] != props.rowData.redirectLink ? _c('i', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Domain Mismatch`,
            expression: "`Domain Mismatch`"
          }],
          staticClass: "zmdi zmdi-alert-triangle text-danger pr-2"
        }) : _vm._e(), _c('router-link', {
          staticClass: "link truncate block",
          staticStyle: {
            "max-width": "13rem"
          },
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.redirect.split(' - ')[0]
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect) + " ")])], 1)] : _vm._e()], 2);
      }
    }, {
      key: "redirect-link-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.redirectLink ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.redirectLink ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.redirectLink,
              label: props.rowData.redirectLink
            }
          },
          model: {
            value: _vm.$refs.form.filters.redirectLink.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.redirectLink, "value", $$v);
            },
            expression: "$refs.form.filters.redirectLink.value"
          }
        }) : _vm._e(), props.rowData.hasOwnProperty('redirect') && props.rowData.hasOwnProperty('redirectLink') && props.rowData.redirect.split(' - ')[1] != props.rowData.redirectLink ? _c('i', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Domain Mismatch`,
            expression: "`Domain Mismatch`"
          }],
          staticClass: "zmdi zmdi-alert-triangle text-danger pr-2"
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.redirectLink)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "mini-domain-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.miniDomain ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.miniDomain ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.miniDomain,
              label: props.rowData.miniDomain
            }
          },
          model: {
            value: _vm.$refs.form.filters.miniDomain.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.miniDomain, "value", $$v);
            },
            expression: "$refs.form.filters.miniDomain.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.miniDomain)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "offer-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.offer ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.offer ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.offer.split(' - ')[0],
              label: props.rowData.offer
            }
          },
          model: {
            value: _vm.$refs.form.filters.offer.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.offer, "value", $$v);
            },
            expression: "$refs.form.filters.offer.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.offer)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "source-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.source ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.source ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.source.split(' - ')[0],
              label: props.rowData.source
            }
          },
          model: {
            value: _vm.$refs.form.filters.source.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.source, "value", $$v);
            },
            expression: "$refs.form.filters.source.value"
          }
        }) : _vm._e(), _c('router-link', {
          staticClass: "link truncate block",
          staticStyle: {
            "max-width": "13rem"
          },
          attrs: {
            "to": {
              name: 'Source',
              params: {
                id: props.rowData.source.split(' - ')[0]
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.source) + " ")])], 1)] : _vm._e()], 2);
      }
    }, {
      key: "template-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.template ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.template ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.template.split(' - ')[0],
              label: props.rowData.template
            }
          },
          model: {
            value: _vm.$refs.form.filters.template.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.template, "value", $$v);
            },
            expression: "$refs.form.filters.template.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.template)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "group-service-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.groupService ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.groupService ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.groupService.split(' - ')[0],
              label: props.rowData.groupService
            }
          },
          model: {
            value: _vm.$refs.form.filters.groupService.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.groupService, "value", $$v);
            },
            expression: "$refs.form.filters.groupService.value"
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(props.rowData.groupService) + " ")], 1)] : _vm._e()], 2);
      }
    }, {
      key: "mini-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.mini ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.mini ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.mini,
              label: props.rowData.mini
            }
          },
          model: {
            value: _vm.$refs.form.filters.mini.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.mini, "value", $$v);
            },
            expression: "$refs.form.filters.mini.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.mini)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "vertical-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.vertical ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.vertical ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.vertical.split(' - ')[0],
              label: props.rowData.vertical
            }
          },
          model: {
            value: _vm.$refs.form.filters.vertical.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.vertical, "value", $$v);
            },
            expression: "$refs.form.filters.vertical.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.vertical)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "mobile-desktop-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.mobileDesktop ? [_c('div', {
          staticClass: "report-value"
        }, [_c('a', {
          staticClass: "mr-2",
          class: [props.rowData.mobileDesktop == 'Mobile' && _vm.$refs.form.filters.mobileDesktop.modifiers.exclude != false || props.rowData.mobileDesktop == 'Desktop' && _vm.$refs.form.filters.mobileDesktop.modifiers.exclude == false ? 'text-muted' : ''],
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              props.rowData.mobileDesktop == 'Mobile' ? _vm.$refs.form.filters.mobileDesktop.modifiers.exclude = false : _vm.$refs.form.filters.mobileDesktop.modifiers.exclude = true;
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "target",
            "size": 4
          }
        })], 1), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.mobileDesktop)
          }
        })])] : _vm._e()], 2);
      }
    }, {
      key: "is-yleader-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.isYleader ? [_c('div', {
          staticClass: "report-value"
        }, [_c('a', {
          staticClass: "mr-2",
          class: [props.rowData.isYleader && _vm.$refs.form.filters.isYleader.modifiers.exclude != false ? 'text-muted' : ''],
          attrs: {
            "href": "#"
          }
        }, [_c('Icon', {
          attrs: {
            "name": "target",
            "size": 4
          }
        })], 1), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.isYleader)
          }
        })])] : _vm._e()], 2);
      }
    }, {
      key: "campaign-smart-link-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.campaignSmartLink ? [_c('div', {
          staticClass: "report-value"
        }, [_c('a', {
          staticClass: "mr-2",
          class: [props.rowData.campaignSmartLink && _vm.$refs.form.filters.campaignSmartLink.modifiers.exclude != false ? 'text-muted' : ''],
          attrs: {
            "href": "#"
          }
        }, [_c('Icon', {
          attrs: {
            "name": "target",
            "size": 4
          }
        })], 1), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.campaignSmartLink)
          }
        })])] : _vm._e()], 2);
      }
    }, {
      key: "region-code-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.regionCode ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.regionCode ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.regionCode,
              label: props.rowData.regionCode
            }
          },
          model: {
            value: _vm.$refs.form.filters.regionCode.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.regionCode, "value", $$v);
            },
            expression: "$refs.form.filters.regionCode.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.regionCode)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "browser-family-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.browserFamily ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.browserFamily ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.browserFamily,
              label: props.rowData.browserFamily
            }
          },
          model: {
            value: _vm.$refs.form.filters.browserFamily.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.browserFamily, "value", $$v);
            },
            expression: "$refs.form.filters.browserFamily.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.browserFamily)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "browser-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.browser ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.browser ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.browser,
              label: props.rowData.browser
            }
          },
          model: {
            value: _vm.$refs.form.filters.browser.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.browser, "value", $$v);
            },
            expression: "$refs.form.filters.browser.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.browser)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "algorithm-id-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.algorithmId ? [_c('div', {
          staticClass: "report-value"
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.algorithmId)
          }
        }, [_vm._v(_vm._s(props.rowData.algorithmId))])])] : _vm._e()], 2);
      }
    }, {
      key: "os-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.os ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.os ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.os,
              label: props.rowData.os
            }
          },
          model: {
            value: _vm.$refs.form.filters.os.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.os, "value", $$v);
            },
            expression: "$refs.form.filters.os.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.os)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "device-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.device ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.device ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.device,
              label: props.rowData.device
            }
          },
          model: {
            value: _vm.$refs.form.filters.device.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.device, "value", $$v);
            },
            expression: "$refs.form.filters.device.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.device)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "ip-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.ip ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.ip ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.ip,
              label: props.rowData.ip
            }
          },
          model: {
            value: _vm.$refs.form.filters.ip.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.ip, "value", $$v);
            },
            expression: "$refs.form.filters.ip.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.ip)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "forwarded-for-ip-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.forwardedForIp ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.forwardedForIp ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.forwardedForIp,
              label: props.rowData.forwardedForIp
            }
          },
          model: {
            value: _vm.$refs.form.filters.forwardedForIp.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.forwardedForIp, "value", $$v);
            },
            expression: "$refs.form.filters.forwardedForIp.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.forwardedForIp)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "usage-type-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.usageType ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.usageType ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.usageType,
              label: props.rowData.usageType
            }
          },
          model: {
            value: _vm.$refs.form.filters.usageType.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.usageType, "value", $$v);
            },
            expression: "$refs.form.filters.usageType.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.usageType)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "connection-type-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.connectionType ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.connectionType ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.connectionType,
              label: props.rowData.connectionType
            }
          },
          model: {
            value: _vm.$refs.form.filters.connectionType.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.connectionType, "value", $$v);
            },
            expression: "$refs.form.filters.connectionType.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.connectionType)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "isp-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.isp ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.isp ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.isp,
              label: props.rowData.isp
            }
          },
          model: {
            value: _vm.$refs.form.filters.isp.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.isp, "value", $$v);
            },
            expression: "$refs.form.filters.isp.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.isp)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "language-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.language ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.language ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.language,
              label: props.rowData.language
            }
          },
          model: {
            value: _vm.$refs.form.filters.language.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.language, "value", $$v);
            },
            expression: "$refs.form.filters.language.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.language)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "keyword-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.keyword ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.keyword ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.keyword,
              label: props.rowData.keyword
            }
          },
          model: {
            value: _vm.$refs.form.filters.keyword.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.keyword, "value", $$v);
            },
            expression: "$refs.form.filters.keyword.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.keyword)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "subid-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.subId ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.subId ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.subId,
              label: props.rowData.subId
            }
          },
          model: {
            value: _vm.$refs.form.filters.subId.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.subId, "value", $$v);
            },
            expression: "$refs.form.filters.subId.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.subId)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "referer-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.referer ? [_c('div', {
          staticClass: "report-value"
        }, [_c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.referer)
          }
        })])] : _vm._e()], 2);
      }
    }, {
      key: "impression-id-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.impressionId ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.impressionId ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.impressionId,
              label: props.rowData.impressionId
            }
          },
          model: {
            value: _vm.$refs.form.filters.impressionId.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.impressionId, "value", $$v);
            },
            expression: "$refs.form.filters.impressionId.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.impressionId)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "click-id-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.clickId ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.clickId ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.clickId,
              label: props.rowData.clickId
            }
          },
          model: {
            value: _vm.$refs.form.filters.clickId.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.clickId, "value", $$v);
            },
            expression: "$refs.form.filters.clickId.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.clickId)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "advertiser-account-manager-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.advertiserAccountManager ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.advertiserAccountManager ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.advertiserAccountManager.split(' - ')[0],
              label: props.rowData.advertiserAccountManager
            }
          },
          model: {
            value: _vm.$refs.form.filters.advertiserAccountManager.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.advertiserAccountManager, "value", $$v);
            },
            expression: "\n                        $refs.form.filters.advertiserAccountManager.value\n                      "
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(props.rowData.advertiserAccountManager) + " ")], 1)] : _vm._e()], 2);
      }
    }, {
      key: "publisher-account-manager-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.publisherAccountManager ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.publisherAccountManager ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.publisherAccountManager.split(' - ')[0],
              label: props.rowData.publisherAccountManager
            }
          },
          model: {
            value: _vm.$refs.form.filters.publisherAccountManager.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.publisherAccountManager, "value", $$v);
            },
            expression: "$refs.form.filters.publisherAccountManager.value"
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(props.rowData.publisherAccountManager) + " ")], 1)] : _vm._e()], 2);
      }
    }, {
      key: "advertiser-tracking-platform-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.advertiserTrackingPlatform ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.advertiserTrackingPlatform ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.advertiserTrackingPlatform,
              label: props.rowData.advertiserTrackingPlatform
            }
          },
          model: {
            value: _vm.$refs.form.filters.advertiserTrackingPlatform.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.advertiserTrackingPlatform, "value", $$v);
            },
            expression: "\n                        $refs.form.filters.advertiserTrackingPlatform.value\n                      "
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(props.rowData.advertiserTrackingPlatform) + " ")], 1)] : _vm._e()], 2);
      }
    }, {
      key: "publisher-tracking-platform-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.publisherTrackingPlatform ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.publisherTrackingPlatform ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.publisherTrackingPlatform,
              label: props.rowData.publisherTrackingPlatform
            }
          },
          model: {
            value: _vm.$refs.form.filters.publisherTrackingPlatform.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.publisherTrackingPlatform, "value", $$v);
            },
            expression: "\n                        $refs.form.filters.publisherTrackingPlatform.value\n                      "
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(props.rowData.publisherTrackingPlatform) + " ")], 1)] : _vm._e()], 2);
      }
    }])
  })], 1)])], 2)], 1), _c('modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loadTimes,
      expression: "loadTimes"
    }],
    attrs: {
      "name": "report-load-times",
      "width": "95%",
      "height": "auto",
      "max-width": 600,
      "adaptive": true
    }
  }, [_c('y-time-log', {
    attrs: {
      "times": JSON.parse(_vm.loadTimes),
      "title": "Report Load Times"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }