<template>
  <Widget>
    <WidgetHeader>
      Add Image
    </WidgetHeader>
    <WidgetBody class="py-0">
      <YAlert color="red" v-if="deleteExisting">
        This will overwrite any existing images.
      </YAlert>
    </WidgetBody>
    <WidgetBody>
      <div class="flex items-center">
        <YImage
          :src="thumbnailUrl || url"
          :width="20"
          :height="20"
          class="rounded mr-4"
        />
        <div>
          <div>Type: {{ modelClass }}</div>
          <div>ID: {{ modelId }}</div>
        </div>
      </div>
    </WidgetBody>
    <WidgetFooter
      class="bg-gray-100 py-2 px-4 flex justify-between items-center"
    >
      <YButton color="link" @click="$emit('close')">Cancel</YButton>
      <YButton
        color="blue"
        @click="addImageFromUrl()"
        :is-loading="isSubmitting"
        >Save</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import ADD_MEDIA_FROM_URL_MUTATION from '@/graphql/Media/AddMediaFromUrlMutation.gql'

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    thumbnailUrl: {
      type: String
    },
    modelClass: {
      type: String,
      required: true
    },
    modelId: {
      type: [Number, String],
      required: true
    },
    deleteExisting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSubmitting: false
    }
  },
  methods: {
    addImageFromUrl() {
      if (!this.url) {
        return
      }
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: ADD_MEDIA_FROM_URL_MUTATION,
          variables: {
            input: {
              modelClass: this.modelClass,
              modelId: this.modelId,
              url: this.url,
              deleteExisting: this.deleteExisting
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('', 'Image Added!')
          this.$emit('close')
        })
        .catch(error => {
          this.isSubmitting = false
          console.error(error)
        })
    }
  }
}
</script>

<style></style>
