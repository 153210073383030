var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('YButton', {
    staticClass: "btn btn-blue mr-2",
    on: {
      "click": _vm.openCreateDnsModal
    }
  }, [_vm._v(" New Nameserver Relation ")]), _c('YButton', {
    staticClass: "btn btn-blue",
    on: {
      "click": _vm.openCreateDomainModal
    }
  }, [_vm._v(" New Domain ")])], 1)]), _c('Widget', {
    staticClass: "mb-1 overflow-visible"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "w-full grid grid-cols-3 gap-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Domain",
      "modifiers": _vm.dnsFilters.id.modifiers,
      "settings": _vm.dnsFilters.id.settings,
      "options": _vm.domainOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "id",
      "close-on-select": false,
      "query": _vm.DOMAIN_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          id: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          type: {
            value: ['nameserver']
          }
        }
      },
      "edit-placeholder": "Paste Domains"
    },
    model: {
      value: _vm.dnsFilters.id.value,
      callback: function ($$v) {
        _vm.$set(_vm.dnsFilters.id, "value", $$v);
      },
      expression: "dnsFilters.id.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Provider",
      "modifiers": _vm.dnsFilters.nameServerProvider.modifiers,
      "settings": _vm.dnsFilters.nameServerProvider.settings,
      "options": _vm.providerOptions || [],
      "loading": _vm.$apollo.queries.providerOptions.loading,
      "placeholder": "Select",
      "track-by": "name",
      "label": "label"
    },
    model: {
      value: _vm.dnsFilters.nameServerProvider.value,
      callback: function ($$v) {
        _vm.$set(_vm.dnsFilters.nameServerProvider, "value", $$v);
      },
      expression: "dnsFilters.nameServerProvider.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Ip",
      "modifiers": _vm.dnsFilters.nameServerIp.modifiers,
      "settings": _vm.dnsFilters.nameServerIp.settings,
      "placeholder": "Select",
      "forceEdit": true,
      "editPlaceholder": "Type IPs",
      "track-by": "id",
      "label": "id"
    },
    model: {
      value: _vm.dnsFilters.nameServerIp.value,
      callback: function ($$v) {
        _vm.$set(_vm.dnsFilters.nameServerIp, "value", $$v);
      },
      expression: "dnsFilters.nameServerIp.value"
    }
  })], 1)])], 2)], 1), _vm.domains ? _c('YTable', {
    attrs: {
      "data": _vm.domains || [],
      "bottom-loading": _vm.$apolloData.queries.domains.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(_vm._s(props.rowData.id))]), _c('td', [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('div', [_vm._v(" " + _vm._s(props.rowData.registrar) + " ")]), props.rowData.registrarAccount ? _c('div', {
          staticClass: "text-xs italic -mt-1"
        }, [_vm._v(" " + _vm._s(props.rowData.registrarAccount) + " ")]) : _vm._e()])]), props.rowData.nameServers[0] ? _c('td', _vm._l(props.rowData.nameServers[0].dnsLinkedDomains, function (dnsLinkedDomain) {
          return _c('li', {
            key: dnsLinkedDomain.id
          }, [_vm._v(" " + _vm._s(dnsLinkedDomain.id) + " ")]);
        }), 0) : _c('td', [_vm._v(" No Assigned Domains ")]), _c('td', [props.rowData.nameServers ? _c('YTable', {
          staticClass: "w-100 w-min-100 table-hover size-xs",
          attrs: {
            "scroll-x": false,
            "header-class": "text-xs",
            "data": props.rowData.nameServers
          },
          scopedSlots: _vm._u([{
            key: "row",
            fn: function (serverProps) {
              return [_c('td', [_vm._v(" " + _vm._s(serverProps.rowData.domainPrefix) + " ")]), _c('td', [_vm._v(" " + _vm._s(serverProps.rowData.server.hostname) + " ")]), _c('td', [_vm._v(" " + _vm._s(serverProps.rowData.server.serverIp) + " ")]), _c('td', [_c('div', {
                staticClass: "text-right"
              }, [_c('div', {
                staticClass: "inline-flex items-center"
              }, [_c('a', {
                staticClass: "link x-action-icon",
                attrs: {
                  "href": "#"
                },
                on: {
                  "click": function ($event) {
                    $event.preventDefault();
                    return _vm.openEditModal(serverProps.rowData.id, props.rowData.id, serverProps.rowData.domainPrefix, serverProps.rowData.server);
                  }
                }
              }, [_c('Icon', {
                staticClass: "w-4 h-4",
                attrs: {
                  "name": "pencil"
                }
              })], 1), _c('a', {
                staticClass: "text-red-600 hover:text-red-700 x-action-icon",
                attrs: {
                  "href": "#"
                },
                on: {
                  "click": function ($event) {
                    $event.preventDefault();
                    return _vm.openDeleteModal(serverProps.rowData.id, serverProps.rowData.server.hostname, serverProps.rowData.server.serverIp, serverProps.rowData.domainPrefix);
                  }
                }
              }, [_c('Icon', {
                staticClass: "w-4 h-4",
                attrs: {
                  "name": "trashCan"
                }
              })], 1)])])]), _c('td')];
            }
          }], null, true)
        }, [_c('template', {
          slot: "header-row"
        }, [_c('th', [_vm._v("Prefix")]), _c('th', [_vm._v("Host Name")]), _c('th', [_vm._v("Ip")]), _c('th')])], 2) : _vm._e()], 1), _c('td', [_c('div', {
          staticClass: "inline-flex float-right"
        }, [_c('a', {
          staticClass: "link",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDomainEditModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "pencil"
          }
        })], 1), _c('a', {
          staticClass: "ml-2 text-red-500 hover:text-red-600",
          attrs: {
            "href": "#"
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "trashCan"
          }
        })], 1)])])];
      }
    }], null, false, 2256492993)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Domain")]), _c('th', [_vm._v("Registrar")]), _c('th', [_vm._v("Assigned Domains")]), _c('th', [_vm._v("Servers")]), _c('th')])], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }