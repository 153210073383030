<template>
  <widget>
    <widget-header class="background-gradient-green-blue text-white tiny-forms">
      <i class="zmdi zmdi-file-plus pr-2"></i> Create Alf Exception
      <h4 slot="right">
        <close-x @click="$emit('close')" class="text-white"></close-x>
      </h4>
    </widget-header>
    <widget-body>
      <form @submit.prevent="update" ref="form" class="x-form">
        <Superselect
            title="Redirect"
            v-if="alfException.user == null"
            :multiple="false"
            v-model="alfException.redirect"
            placeholder="Select Redirect"
            track-by="id"
            label="label"
            :query="queries.REDIRECT_OPTIONS_QUERY"
            :query-variables="{
            filters: {
                label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
                }
            },
            first: 25
            }"
            :queryResultMap="{
            id: 'id',
            label: 'label'
            }"
        ></Superselect>

        <Superselect
            title="User"
            :multiple="false"
            v-model="alfException.user"
            placeholder="Select User"
            track-by="id"
            label="label"
            :query="queries.USER_OPTIONS_QUERY"
            :query-variables="{
            filters: {
                label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
                }
            },
            first: 25
            }"
            :queryResultMap="{
            id: 'id',
            label: 'label'
            }"
        ></Superselect>
      
        <Superselect
              title="Country"
              v-model="alfException.country"
              :multiple="false"
              label="label"
              track-by="code"
              :options="countries"
              :loading="$apollo.queries.countries.loading"
            ></Superselect>
      </form>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <y-button
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="create"
        @keydown.enter="create"
        >Create</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import USER_OPTIONS_QUERY from '@/graphql/User/UserOptionsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'

export default {
  mixins: [ValidationErrorsMixin],

  props: {
  },
  components: {},
  apollo: {
      countries: {
        query: COUNTRIES_QUERY
    },
  },
  data() {
    return {
      alfException: {},
      isSubmitting: false,
      queries: {
        REDIRECT_OPTIONS_QUERY,
        USER_OPTIONS_QUERY,
      },
      options: {},
    }
  },
  watch: {},
  computed: {},
  methods: {
    create() {
      this.isSubmitting = true
      let input = {
            user: this.alfException.user ? this.alfException.user.id : undefined,
            redirect: this.alfException.redirect ? this.alfException.redirect.id : undefined,
            country: this.alfException.country ? this.alfException.country.code  : undefined,
      }

      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateAlfException($input: CreateAlfExceptionInput) {
                createAlfException(input: $input) {
                    id
                }
            }
          `,
          variables: {
            input: input
          }
        })
        .then(result => {
          console.log(result)
          this.isSubmitting = false
          this.clearValidationErrors()
          this.$events.emit('refreshAlfExceptions', true)
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.x-form > *:not(:last-child) {
  @apply mb-2;
}
</style>
