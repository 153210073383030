<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <div class="flex items-center">
        <Icon name="pencil" :size="4" class="mr-2"></Icon>
        <template> Bulk Tags Edit </template>
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <h5 class="font-bold">Selected Domains:</h5>
      <ul class="text-xs mt-1 mb-3">
        <li v-for="domain in domains" :key="domain.id">
          {{ domain.id }}
        </li>
      </ul>
      <Superselect
        title="Tags to Add"
        v-model="selectedTagsToCreate"
        :options="tagOptions"
        :multiple="true"
        track-by="tagConfigId"
        label="tag"
      >
      </Superselect>
      <Superselect
        title="Tags to Delete"
        class="mt-2"
        v-model="selectedTagsToDelete"
        :options="tagOptions"
        :multiple="true"
        track-by="tagConfigId"
        label="tag"
      >
      </Superselect>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-end items-center px-4 bg-gray-100"
    >
      <YButton
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="updateDomain"
        >Update</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    domains: {
      type: Array,
      required: true
    }
  },
  components: {},
  apollo: {
    tagOptions: {
      query: gql`
        query getYdnsTagConfigs($filters: YdnsTagConfigsFilterInput) {
          tagOptions: ydnsTagConfigs(filters: $filters) {
            id
            tag
            color
          }
        }
      `,
      variables: {
        filters: {
          type: {
            value: ['domain']
          }
        }
      },
      result(result) {
        let output = result.data.tagOptions.map(option => ({
          tag: option.tag,
          tagConfigId: option.id
        }))

        this.tagOptions = output
      }
    }
  },
  data() {
    return {
      isSubmitting: false,
      selectedTagsToCreate: [],
      selectedTagsToDelete: [],
      tagOptions: []
    }
  },
  watch: {},
  computed: {
    input() {
      let output = []

      this.domains.forEach(domain => {
        output.push({
          id: domain.id,
          tags: {
            create: this.tagsToCreate(domain.tags),
            delete: this.tagsToDelete(domain.tags)
          }
        })
      })

      return output
    }
  },
  mounted() {},
  methods: {
    tagsToCreate(selectedDomainTags) {
      let output = []
      let selectedDomainTagNames = selectedDomainTags.map(
        tag => tag.tagConfig.tag
      )

      this.selectedTagsToCreate.forEach(tag => {
        if (!selectedDomainTagNames.includes(tag.tag)) {
          output.push({
            tagConfig: {
              connect: tag.tagConfigId
            }
          })
        }
      })

      return output
    },
    tagsToDelete(selectedDomainTags) {
      let output = []
      let selectedTagsToDeleteNames = this.selectedTagsToDelete.map(
        tag => tag.tag
      )

      selectedDomainTags.forEach(tag => {
        if (selectedTagsToDeleteNames.includes(tag.tagConfig.tag)) {
          output.push(tag.id)
        }
      })

      return output
    },
    updateDomain() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateDomainTags($input: [YdnsUpdateDomainInput!]!) {
              ydnsUpdateDomains(input: $input) {
                id
                registrar
                type
                tags {
                  id
                  tagConfig {
                    id
                    tag
                    color
                  }
                }
              }
            }
          `,
          variables: {
            input: this.input
          }
        })
        .then(result => {
          this.$toastr.s('Domain Tags Edited', 'Success!')
          this.errors = {}
          this.isSubmitting = false
          this.$emit('success', result)
          this.$events.emit('refreshYdnsDomains')
          this.$emit('close')
        })
        .catch(error => {
          this.isSubmitting = false

          if (Object.entries(this.errors).length <= 0) {
            this.$toastr.e(error, 'Oops!')
          }
        })
    }
  },
  close() {
    this.$emit('close')
  }
}
</script>
<style></style>
