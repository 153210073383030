<template>
  <span>
    <slot :parsed="parsed"> </slot>
  </span>
</template>

<script>
import uaParser from 'ua-parser-js'

export default {
  props: {
    input: {
      type: String
    }
  },
  computed: {
    parsed() {
      return this.input ? uaParser(this.input) : null
    }
  }
}
</script>

<style></style>
