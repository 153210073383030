var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('Widget', {
    staticClass: "mb-1"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-100"
  }, [_vm._v("Upload Balance CSV")]), _c('WidgetBody', {
    staticClass: "pt-0 bg-gray-100"
  }, [[_c('form', {
    ref: "form",
    attrs: {
      "id": "new-bulk-rule-form",
      "name": "bulk-rule-form"
    },
    on: {
      "submit": _vm.submitForm,
      "keydown": function ($event) {
        return _vm.errors.clear($event.target.name);
      },
      "change": function ($event) {
        return _vm.errors.clear($event.target.name);
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "flex w-full items-center justify-center mb-3"
  }, [_c('label', {
    class: _vm.buttonCSV
  }, [_c('svg', {
    staticClass: "w-8 h-8",
    attrs: {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
    }
  })]), _vm.csvFileName ? _c('span', {
    staticClass: "mt-2 text-base leading-normal"
  }, [_vm._v(_vm._s(_vm.csvFileName))]) : _c('span', {
    staticClass: "mt-2 text-base leading-normal uppercase"
  }, [_vm._v("Upload CSV")]), _c('input', {
    ref: "bulkCsvInput",
    staticClass: "hidden",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onCsvChange
    }
  })])])]), _c('div', {
    staticClass: "flex justify-end"
  }, [_vm.uploadAvailable == true && _vm.uploadCount == 0 ? _c('YButton', {
    staticClass: "link mr-2",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.uploadCSV.apply(null, arguments);
      }
    }
  }, [_vm._v("Upload CSV")]) : _vm._e(), _vm.uploadCount > 0 ? _c('YButton', {
    staticClass: "link mr-2"
  }, [_vm._v("Uploads Remaining " + _vm._s(this.uploadCount) + " ")]) : _vm._e(), _c('YButton', {
    staticClass: "link mr-2",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearForm.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear CSV")])], 1)])]], 2)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }