<template>
  <div class="flex items-center" :class="className">
    <input
      :id="id"
      type="radio"
      :name="name"
      :value="radioValue"
      v-model="radioButtonValue"
      :disabled="disabled"
      class="mr-1"
    />
    <label :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {},
    radioValue: {},
    label: {
      type: String
    },
    name: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    inline: {
      type: Boolean
    },
    dataId: {
      type: String
    },
    color: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    className() {
      var className = ''
      className = this.disabled ? 'disabled' : className
      className = this.inline ? className + ' form-check-inline' : className
      className = this.color ? className + ' radio-' + this.color : className
      return className
    },
    id() {
      return this.dataId
        ? this.name + '__' + this.dataId
        : this.name + '__' + this.radioValue
    },
    radioButtonValue: {
      get() {
        return this.value
      },
      set() {
        // Communicate the change to parent component so that selectedValue can be updated
        this.$emit('input', this.radioValue)
      }
    }
  },

  mounted() {},

  updated() {},

  methods: {}
}
</script>
<style></style>
