<template>
  <div class="flex py-4">
    <div class="w-1/12">
      <Superselect
        v-model="perPage"
        title="Per Page"
        :multiple="false"
        :options="perPageOptions"
        @input="pageSelection"
        style="min-width:4.6rem; border: none;"
      ></Superselect>
    </div>
    <div class="block flex flex-grow items-center justify-center w-3/4">
      <YButton
        color="link"
        @click="goToFirstPage"
        :disabled="data.currentPage === 1"
      >
        <Icon name="chevronDoubleLeft" size="6"></Icon>
      </YButton>

      <YButton
        color="link"
        @click="previousPage"
        :disabled="data.currentPage === 1"
      >
        <Icon name="chevronLeft" size="6"></Icon>
      </YButton>

      <div :key="data.total">
        <div v-if="lastPage >= 5">
          <template v-for="n in windowSize">
            <button
              :key="n"
              @click="loadPage(windowStart + n - 1)"
              v-html="windowStart + n - 1"
              class="link px-3 hover:bg-blue-100 rounded-md p-2 text-base"
              :class="{ highlight: isCurrentPage(windowStart + n - 1) }"
            ></button>
          </template>
        </div>
        <div v-else>
          <template v-for="page in lastPage">
            <button
              :key="page"
              @click="loadPage(page)"
              v-html="page"
              class="link px-3 hover:bg-blue-100 rounded-md p-2 text-base"
              :class="{ highlight: isCurrentPage(page) }"
            ></button>
          </template>
        </div>
      </div>

      <YButton
        color="link"
        @click="nextPage"
        :disabled="data.currentPage === lastPage"
      >
        <Icon name="chevronRight" size="6"></Icon>
      </YButton>

      <YButton
        color="link"
        @click="goToLastPage"
        :disabled="data.currentPage === lastPage"
      >
        <Icon name="chevronDoubleRight" size="6"></Icon>
      </YButton>
    </div>

    <div class="w-1/12"></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      require: true
    },
    onEachSide: {
      type: Number,
      default: 3
    }
  },
  components: {},
  data() {
    return {
      lastPage: this.data.lastPage,
      perPageOptions: [10, 20, 50, 100],
      perPage: this.data.perPage
    }
  },
  computed: {
    windowSize() {
      return Math.min(this.lastPage, this.onEachSide * 2 + 1)
    },
    windowStart() {
      if (!this.data || this.data.currentPage <= this.onEachSide) {
        return 1
      } else if (this.data.currentPage >= this.lastPage - this.onEachSide) {
        return Math.max(1, this.lastPage - this.windowSize + 1)
      } else {
        const diff = this.windowSize - (this.lastPage - this.data.currentPage)
        return Math.max(
          1,
          this.data.currentPage - this.onEachSide - Math.max(0, diff)
        )
      }
    }
  },
  mounted() {},
  updated() {},
  created() {},
  methods: {
    isCurrentPage(page) {
      return page === this.data.currentPage
    },
    loadPage(page) {
      this.$emit('load-page', page)
    },
    nextPage() {
      if (this.data.currentPage < this.data.lastPage) {
        this.$emit('next-page')
      }
    },
    previousPage() {
      if (this.data.currentPage > 1) {
        this.$emit('previous-page')
      }
    },
    goToFirstPage() {
      this.$emit('load-page', 1)
    },
    goToLastPage() {
      this.$emit('load-page', this.data.lastPage)
    },
    pageSelection(value) {
      this.$emit('per-page', value)
    }
  }
}
</script>
<style scoped>
button.highlight {
  @apply font-extrabold;
}
</style>
