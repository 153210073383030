var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "py-6 border-b",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "pencil",
      "size": 4
    }
  }), [_vm._v("Edit Publishers")]], 2)]), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('Superselect', {
    attrs: {
      "title": "Publishers",
      "query": _vm.PUBLISHER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "max-height": 80
    },
    model: {
      value: _vm.publisher,
      callback: function ($$v) {
        _vm.publisher = $$v;
      },
      expression: "publisher"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center",
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue"
    },
    on: {
      "click": _vm.updateIpPublishers
    }
  }, [_vm._v("Update")]), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }