<template>
  <div :class="classes" class="inline-flex items-center select-none">
    <input
      :id="computedId"
      type="checkbox"
      class="mr-2"
      :value="value"
      :checked="value"
      v-on:change="updateValue(value)"
      @keydown.enter="updateValue(value)"
      @blur="$emit('blur')"
      ref="checkbox"
      :disabled="disabled"
    />
    <label :for="computedId" class="col-form-label"
      ><slot name="label">{{ label }}</slot></label
    >
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean
    },
    id: {
      type: String
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    inline: {
      type: Boolean
    },
    type: {
      type: String,
      default: null
    },
    whiteMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    classes() {
      let classes = ['checkbox']

      if (this.disabled) {
        classes.push('disabled')
      }

      if (this.inline) {
        classes.push('form-check-inline')
      }

      if (this.type) {
        classes.push('checkbox-' + this.type)
      }

      if (this.whiteMode) {
        classes.push('white-mode')
      }

      return classes
    },
    computedId() {
      if (this.id) {
        return this.id
      }
      return 'checkbox-id-' + this.getRandomIntBetween(100000, 999999)
    }
  },
  mounted() {},
  updated() {},
  methods: {
    updateValue(value) {
      var newValue = !value
      this.$refs.checkbox.value = newValue
      // Emit the number value through the input event
      this.$emit('input', newValue)
    },
    toggleValue() {
      this.updateValue(this.value)
    },
    getRandomIntBetween(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min)) + min
    }
  }
}
</script>
<style scoped>
.checkbox.fw-500 label {
  font-weight: 500;
}
.checkbox.fw-600 label {
  font-weight: 600;
}

.checkbox-sm.checkbox.white-mode input:not(:checked) + label::before,
.checkbox.white-mode input:not(:checked) + label::before {
  background-color: #fff;
  border-color: #fff;
}
</style>
