<template>
  <Icon
    name="loading"
    :class="[
      'spinner',
      color ? `text-${color}-500` : '',
      size ? `w-${size} h-${size}` : ''
    ]"
  />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'blue'
    },
    size: {
      type: [Number, String]
    }
  }
}
</script>

<style></style>
