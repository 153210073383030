var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "top"
    }
  }, [_c('div', {
    staticClass: "bg-gray-200 p-1 border-b mb-2"
  }, [[_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-6 gap-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Level",
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "placeholder": "Select",
      "query": _vm.queries.INTROSPECTION_TYPE_QUERY,
      "query-variables": {
        name: 'YleaderHistoryLevelEnum'
      },
      "query-result-function": function (data) {
        return data.__type.enumValues.map(function (value) {
          return {
            id: value.name,
            label: value.name
          };
        });
      }
    },
    model: {
      value: _vm.filters.level.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.level, "value", $$v);
      },
      expression: "filters.level.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Country",
      "multiple": false,
      "label": "label",
      "track-by": "code",
      "options": _vm.countries,
      "loading": _vm.$apollo.queries.countries.loading
    },
    model: {
      value: _vm.filters.country.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.country, "value", $$v);
      },
      expression: "filters.country.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Offer",
      "options": _vm.options.offer,
      "placeholder": "Select Offer",
      "options-limit": 100,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.OFFER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.offer.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.offer, "value", $$v);
      },
      expression: "filters.offer.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Redirect",
      "options": _vm.options.redirect,
      "placeholder": "Select Redirect",
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      },
      "queryResultMap": {
        id: 'id',
        label: 'label'
      }
    },
    model: {
      value: _vm.filters.redirect.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.redirect, "value", $$v);
      },
      expression: "filters.redirect.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Source"
    },
    model: {
      value: _vm.filters.source.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.source, "value", $$v);
      },
      expression: "filters.source.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Advertiser",
      "modifiers": _vm.filters.advertiser.modifiers,
      "options": _vm.options.advertiser,
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "close-on-select": false,
      "query": _vm.queries.ADVERTISER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          advertiserLabel: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.filters.advertiser.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.advertiser, "value", $$v);
      },
      expression: "filters.advertiser.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Campaign",
      "track-by": "id",
      "label": "label",
      "modifiers": _vm.filters.campaign.modifiers,
      "multiple": false,
      "options": _vm.options.campaign,
      "query": _vm.queries.CAMPAIGN_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.filters.campaign.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.campaign, "value", $$v);
      },
      expression: "filters.campaign.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Publisher",
      "modifiers": _vm.filters.user.modifiers,
      "options": _vm.options.user,
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "close-on-select": false,
      "query": _vm.queries.PUBLISHER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": "Paste Publishers"
    },
    model: {
      value: _vm.filters.user.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.user, "value", $$v);
      },
      expression: "filters.user.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Smart link",
      "placeholder": "Select",
      "multiple": false,
      "close-on-select": false,
      "options": _vm.options.smartlink,
      "track-by": "id",
      "label": "label"
    },
    model: {
      value: _vm.filters.smartlink.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.smartlink, "value", $$v);
      },
      expression: "filters.smartlink.value"
    }
  }), _c('y-radios', {
    staticClass: "flex",
    attrs: {
      "radios": [{
        label: 'All',
        value: 'null'
      }, {
        label: 'Alert',
        value: 1
      }],
      "radio-class": "m-3",
      "color": "info"
    },
    model: {
      value: _vm.filterDisplayMode,
      callback: function ($$v) {
        _vm.filterDisplayMode = $$v;
      },
      expression: "filterDisplayMode"
    }
  }), _c('YButton', {
    attrs: {
      "disabled": _vm.selected.length > 0 ? false : true,
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        return _vm.clearYleaderAlerts(_vm.selected);
      }
    }
  }, [_c('span', {
    staticClass: "text-center"
  }, [_vm._v("Clear Alerts")])])], 1)])]], 2), _c('div', {
    staticClass: "w-100"
  }, [_vm.YleaderHistorys ? _c('YTable', {
    attrs: {
      "selectable": true,
      "selectBy": "id",
      "selected": _vm.selected,
      "data": _vm.YleaderHistorys.data || [],
      "loading": _vm.$apolloData.queries.YleaderHistorys.loading
    },
    on: {
      "update:selected": function ($event) {
        _vm.selected = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [props.rowData.notify == 1 ? [_c('a', {
          staticClass: "text-red-600",
          attrs: {
            "href": "#"
          }
        }, [_c('Icon', {
          attrs: {
            "name": "alert"
          }
        })], 1)] : _vm._e(), props.rowData.acknowledged != null ? [_c('a', {
          staticClass: "text-green-600",
          attrs: {
            "href": "#"
          }
        }, [_c('Icon', {
          attrs: {
            "name": "flag"
          }
        })], 1)] : _vm._e()], 2), _c('td', [props.rowData.offer ? [_c('div', {
          staticClass: "flex",
          staticStyle: {
            "width": "18rem"
          }
        }, [_c('router-link', {
          class: props.rowData.isCapped ? 'text-red-600 hover:text-red-700 font-medium' : 'link-wrap',
          attrs: {
            "to": {
              name: 'Offer',
              params: {
                id: props.rowData.offer.id,
                action: 'edit'
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.offer.label) + " ")])], 1)] : _vm._e()], 2), _c('td', [_vm._v(_vm._s(props.rowData.countryCode))]), _c('td', [props.rowData.redirect ? [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.redirect.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.label) + " ")])] : _vm._e()], 2), _c('td', [props.rowData.advertiserAccount && props.rowData.advertiserAccount.advertiser ? [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Advertiser',
              params: {
                id: props.rowData.advertiserAccount.advertiser.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.advertiserAccount.advertiser.name) + " ")])] : _vm._e()], 2), _c('td', [_c('div', {
          staticStyle: {
            "width": "15rem"
          }
        }, [props.rowData.redirect && props.rowData.redirect.campaign ? [_c('router-link', {
          staticClass: "link-wrap",
          attrs: {
            "to": {
              name: 'Campaign',
              params: {
                id: props.rowData.redirect.campaign.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.campaign.label) + " ")])] : _vm._e()], 2)]), _c('td', [props.rowData.redirect ? [_c('router-link', {
          staticClass: "link-wrap",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.redirect.user.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.user.label) + " ")])] : _vm._e()], 2), _c('td', [_vm._v(_vm._s(props.rowData.sourceId))]), _c('td', {
          staticClass: "text-green-600"
        }, [_vm._v("$" + _vm._s(props.rowData.yCPA))]), _c('td', [_vm._v("$" + _vm._s(props.rowData.yCPL))]), _c('td', [_vm._v(" " + _vm._s(parseInt(props.rowData.conversions) - parseInt(props.rowData.YleaderConversions)) + " ")]), _c('td', [_vm._v("$" + _vm._s(props.rowData.revenueValue))]), _c('td', {
          style: {
            color: props.rowData.profit >= 0 ? 'green' : 'red'
          }
        }, [_vm._v(" $" + _vm._s(props.rowData.profit) + " ")]), _c('td', [_vm._v(_vm._s(props.rowData.updatedAt))])];
      }
    }, {
      key: "after",
      fn: function () {
        return [_vm.YleaderHistorys && _vm.YleaderHistorys.data ? _c('YPagination', {
          key: _vm.totalPages,
          attrs: {
            "data": _vm.YleaderHistorys.paginatorInfo
          },
          on: {
            "load-page": _vm.loadPage,
            "next-page": _vm.nextPage,
            "previous-page": _vm.previousPage,
            "per-page": _vm.perPageSelection
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 393553030)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th'), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "offer_id",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Offer")])], 1), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "country_code",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Geo")])], 1), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "redirect_id",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Redirect")])], 1), _c('th', [_vm._v("Advertiser")]), _c('th', [_vm._v("Campaign")]), _c('th', [_vm._v("Publisher")]), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "source_id",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Source")])], 1), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "yCPA",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("yCPA")])], 1), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "yCPL",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("yCPL")])], 1), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "conversions",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Lead Conversions")])], 1), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "revenue",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Revenue")])], 1), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "profit",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Profit")])], 1), _c('th', [_c('OrderByItem', {
    attrs: {
      "column": "updated_at",
      "order": "DESC"
    },
    model: {
      value: _vm.orderBy,
      callback: function ($$v) {
        _vm.orderBy = $$v;
      },
      expression: "orderBy"
    }
  }, [_vm._v("Last Updated")])], 1)])], 2) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }