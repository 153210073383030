<template>
  <div class="grid grid-cols-2 gap-6">
    <div
      class="h-full rounded-md px-4 py-3 bg-yellow-200 border border-yellow-300 mb-2 text-yellow-800 flex items-center"
    >
      <div class="flex items-center mr-6">
        <Icon name="information" class="h-12 w-12" />
      </div>
      <div>
        <div class="uppercase font-extrabold flex justify-between">
          You are in Quarantine level {{ level }}
        </div>
        <div class="text-xs">
          <div class="italic mt-2">
            You will be
            <span class="font-extrabold"
              >capped at ${{ payoutLimit }} revenue</span
            >
            per month<br />
            You can subscribe to a maximum of
            <span class="font-extrabold">3 campaigns</span> per month
            <div class="pt-2">
              Hit your revenue objective this month to progress to the next
              level
            </div>
          </div>
        </div>
      </div>
      <!---->
    </div>
    <div class="h-full flex-grow rounded-md p-3 text-gray-900 border">
      <div class="font-semibold uppercase mb-3">
        Quarantine Revenue Objective
      </div>
      <div class="text-left text-xs italic text-gray-500">
        Hit your
        <span
          class="bg-blue-500 inline-block w-2 h-2 rounded-lg shadow-md"
          style="margin-right: 2px;"
        ></span>
        <span class="text-blue-500 font-bold">revenue objective</span> this
        month to increase your
        <span
          class="bg-red-500 inline-block w-2 h-2 rounded-lg shadow-md"
          style="margin-right: 2px;"
        ></span>
        <span class="text-red-500 font-bold">cap limit</span> next month.
      </div>
      <ProgressSegments
        class="mt-3"
        :value="payout"
        :startValue="0"
        :endValue="payoutLimit"
        tickClass="text-gray-500 font-bold"
        :indicatorLabelMethod="value => '$' + value"
        :segments="[
          {
            start: 0,
            end: payoutObjective,
            color: '#7ac3ff',
            leftColor: '#7ac3ff',
            rightColor: '#EEE',
            tickLabel: '$' + payoutObjective,
            tickLabelAlign: 'center',
            tickReachedColor: '#0b89ea',
            tickLabelColor: '#0b89ea',
            tickLabelDotColor: '#0b89ea'
          },
          {
            start: payoutObjective,
            end: payoutLimit,
            color: '#0b89ea',
            leftColor: '#0b89ea',
            rightColor: '#EEE',
            tickLabel: '$' + payoutLimit,
            tickLabelAlign: 'right',
            tickReachedColor: '#f54747',
            tickLabelDotColor: '#f54747',
            tickLabelColor: '#f54747'
          }
        ]"
      />
    </div>
  </div>
</template>

<script>
import QUARANTINE_CONFIG_QUERY from '@/graphql/Quarantine/QuarantineConfigQuery.gql'
import ProgressSegments from '@/components/ui/ProgressSegments'

export default {
  components: {
    ProgressSegments
  },
  apollo: {
    quarantineConfig: {
      query: QUARANTINE_CONFIG_QUERY
    }
  },
  props: {
    level: {
      type: Number,
      required: true
    },
    payout: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      quarantineConfig: {}
    }
  },
  computed: {
    payoutLimit() {
      if (this.quarantineConfig?.capLimitsPerLevel == null) {
        return 0
      }

      return this.quarantineConfig.capLimitsPerLevel.find(
        item => item.level === this.level
      ).limit
    },
    payoutObjective() {
      if (this.quarantineConfig?.objectivesPerLevel == null) {
        return 0
      }

      return this.quarantineConfig.objectivesPerLevel.find(
        item => item.level === this.level
      ).limit
    },
    objectiveValue() {
      return (this.currentPayout / this.payoutObjective) * 100
    },
    limitValue() {
      let value =
        ((this.currentPayout - this.payoutObjective) /
          (this.payoutLimit - this.payoutObjective)) *
        100
      return value < 0 ? 0 : value
    },
    objectivePercentage() {
      return `${(this.payoutObjective / this.payoutLimit) * 100}%`
    },
    limitPercentage() {
      return `${((this.payoutLimit - this.payoutObjective) / this.payoutLimit) *
        100}%`
    },
    objectiveTickColor() {
      return this.objectiveValue >= 100 ? '#0b89ea' : 'rgb(238, 238, 238)'
    },
    objectiveLabelColor() {
      return this.objectiveValue >= 100 ? '#0b89ea' : '#CCC'
    },
    limitTickColor() {
      return this.limitValue >= 100 ? '#faca15' : 'rgb(238, 238, 238)'
    },
    limitLabelColor() {
      return this.limitValue >= 100 ? '#faca15' : '#CCC'
    }
  }
}
</script>

<style></style>
