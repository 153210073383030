var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "progress-bar-container"
  }, [_c('div', {
    staticClass: "progress-bar-wrapper"
  }, [_c('div', {
    staticClass: "progress-bar"
  }, [_vm._l(_vm.segmentStyles, function (segmentStyle, index) {
    return [_c('div', {
      key: `left-${index}`,
      staticClass: "progress-bar-segment",
      class: ['pb-segment-' + _vm.segmentStatus[index]],
      style: segmentStyle[0]
    }), _c('div', {
      key: `right-${index}`,
      staticClass: "progress-bar-segment",
      class: ['pb-segment-' + _vm.segmentStatus[index]],
      style: segmentStyle[1]
    })];
  }), _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.indicatorLabelMethod(_vm.value.toString()),
      expression: "indicatorLabelMethod(value.toString())"
    }],
    staticClass: "progress-bar-indicator",
    style: {
      left: _vm.getValuePercentage(_vm.value) + '%'
    }
  }, [_c('div', {
    staticClass: "progress-bar-indicator-label"
  }, [_vm._v(" " + _vm._s('$' + _vm.value) + " ")])])], 2), _c('div', {
    staticClass: "ticks-container"
  }, _vm._l(_vm.segments, function (segment, index) {
    var _vm$segmentStyles$ind, _segment$tickLabelAli, _segment$tickLabelCol, _segment$tickLabel;
    return _c('div', {
      key: 'tick-' + index,
      staticClass: "tick",
      class: [_vm.tickClass, 'pb-tick-' + _vm.segmentStatus[index]],
      style: {
        left: _vm.getTickPosition(segment) + '%',
        backgroundColor: _vm.segmentStatus[index] == 'completed' ? (_vm$segmentStyles$ind = _vm.segmentStyles[index][0].tickReachedColor) !== null && _vm$segmentStyles$ind !== void 0 ? _vm$segmentStyles$ind : _vm.segmentStyles[index][0].backgroundColor : _vm.segmentStyles[index][1].backgroundColor
      }
    }, [_c('div', {
      staticClass: "tick-label flex items-center",
      class: `tick-label-${(_segment$tickLabelAli = segment.tickLabelAlign) !== null && _segment$tickLabelAli !== void 0 ? _segment$tickLabelAli : 'center'}`,
      style: {
        color: (_segment$tickLabelCol = segment.tickLabelColor) !== null && _segment$tickLabelCol !== void 0 ? _segment$tickLabelCol : undefined
      }
    }, [segment.tickLabelDotColor ? _c('span', {
      staticClass: "tick-label-dot",
      style: `background-color: ${segment.tickLabelDotColor}`
    }) : _vm._e(), _c('span', [_vm._v(_vm._s((_segment$tickLabel = segment.tickLabel) !== null && _segment$tickLabel !== void 0 ? _segment$tickLabel : segment.end))])])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }