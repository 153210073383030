// AppIcon.vue
<template>
  <svg viewBox="0 0 24 24" class="icon fill-current" :class="classes">
    <path :d="path" />
  </svg>
</template>

<script>
import icons from '@/assets/js/icons.js'

export default {
  name: 'AppIcon',
  props: {
    name: String,
    size: {
      type: [String, Number],
      default: 4
    }
  },
  computed: {
    path() {
      return icons[this.name]
    },
    classes() {
      return [this.size ? `w-${this.size} h-${this.size}` : '']
    }
  }
}
</script>

<style></style>
