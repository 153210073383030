<template>
  <Widget v-show="!autoSubmit">
    <WidgetHeader @close="$emit('close')">
      Set Account Manager
    </WidgetHeader>
    <WidgetBody>
      <Superselect
        title="Manager"
        v-model="accountManager"
        :multiple="false"
        track-by="id"
        label="label"
        :query="ACCOUNT_MANAGER_OPTIONS_QUERY"
        :query-variables="{
          filters: {
            label: {
              value: [],
              modifiers: { matchType: 'contains' }
            }
          },
          first: 25
        }"
      ></Superselect>
    </WidgetBody>
    <WidgetFooter class="py-2 px-4 flex justify-between bg-gray-100">
      <YButton color="link" @click="$emit('close')">Cancel</YButton>
      <YButton
        color="blue"
        @click="update"
        :disabled="!accountManager"
        :is-loading="isUpdating"
        >Set Manager</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import ACCOUNT_MANAGER_OPTIONS_QUERY from '@/graphql/AccountManager/AccountManagerOptionsQuery.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    defaultManager: {
      type: Object,
      default: () => null
    },
    userId: {
      type: Number,
      required: true
    },
    autoSubmit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ACCOUNT_MANAGER_OPTIONS_QUERY,
      accountManager: this.defaultManager,
      isUpdating: false
    }
  },
  mounted() {
    if (this.autoSubmit) {
      this.update()
    }
  },
  methods: {
    update() {
      this.isUpdating = true
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            input: {
              id: this.userId,
              accountManagers: {
                sync: [this.accountManager.id]
              }
            }
          }
        })
        .then(() => {
          this.isUpdating = false
          this.$toastr.s('', 'Account Manager Saved')
          this.$emit('close')
          this.clearValidationErrors()
        })
        .catch(error => {
          this.isUpdating = false
          this.setValidationErrors(error)
        })
    }
  }
}
</script>

<style></style>
