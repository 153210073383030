var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "pt-4 pb-4",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [[_c('div', {
    staticClass: "flex justify-between items-center w-full"
  }, [_c('Icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "filePlus"
    }
  }), _vm._v(" Add Server ")], 1)]], 2), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Hostname"
    },
    model: {
      value: _vm.hostname,
      callback: function ($$v) {
        _vm.hostname = $$v;
      },
      expression: "hostname"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Server/ Monitor IP"
    },
    model: {
      value: _vm.serverIp,
      callback: function ($$v) {
        _vm.serverIp = $$v;
      },
      expression: "serverIp"
    }
  }), _c('YInput', {
    attrs: {
      "label": "SSH Port"
    },
    model: {
      value: _vm.sshPort,
      callback: function ($$v) {
        _vm.sshPort = $$v;
      },
      expression: "sshPort"
    }
  }), _c('Superselect', {
    attrs: {
      "options": _vm.typeOptions,
      "label": "label",
      "title": "Type",
      "track-by": "id",
      "multiple": false
    },
    model: {
      value: _vm.serverType,
      callback: function ($$v) {
        _vm.serverType = $$v;
      },
      expression: "serverType"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center",
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    staticClass: "ml-auto",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "color": "blue"
    },
    on: {
      "click": _vm.create,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_vm._v("Add")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }