var render = function render(){
  var _vm$userDetails;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.campaignsPreview && _vm.campaignsPreview.data ? _c('div', [_c('div', {
    staticClass: "m-auto",
    staticStyle: {
      "max-width": "60rem"
    }
  }, [_c('div', {
    staticClass: "block sm:flex text-center justify-between mb-5"
  }, [_c('div', [_c('span', {
    staticClass: "fw-500 mr-2"
  }, [_vm._v("For:")]), _vm.campaignsPreview.data ? _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v(_vm._s(_vm.campaignsPreviewData.mail.toEmail))]) : _vm._e()]), _c('div', {
    staticClass: "font-semibold"
  }, [_c('i', {
    staticClass: "text-blue-500 fw-500"
  }, [_vm._v("* Details as of " + _vm._s(_vm.prettyCreatedAt))])])]), _c('campaign-previews', {
    attrs: {
      "value": _vm.campaignsPreviewData.campaigns,
      "publisherOptions": ((_vm$userDetails = _vm.userDetails) === null || _vm$userDetails === void 0 ? void 0 : _vm$userDetails.data[0]) || {}
    }
  }), _c('portal', {
    attrs: {
      "to": "content-header"
    }
  }, [_c('div', {
    staticClass: "text-center w-full py-4 bg-blue-100 border-b border-blue-200 mb-4 text-gray-800"
  }, [_c('div', [_c('img', {
    staticClass: "p-4 m-auto",
    staticStyle: {
      "width": "15rem",
      "max-width": "100%"
    },
    attrs: {
      "src": _vm.ytzLogo
    }
  })]), _c('div', {
    staticClass: "-mt-4"
  }, [_c('h3', {
    staticClass: "mb-3 text-base mt-2 font-bold"
  }, [_vm._v("Campaign Previews")])])])]), _c('portal', {
    attrs: {
      "to": "content-header-right"
    }
  })], 1)]) : _vm._e(), !_vm.campaignsPreview.data || !_vm.id ? _c('div', {
    staticClass: "mb-4 bg-red-200 border-t-4 border-red-800 rounded-b text-red-800 px-4 py-3 shadow-md font-bold"
  }, [_c('h2', [_vm._v(" Unfortunately the offer previews have expired. Please contact your AM for updated details. ")])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }