<template>
  <div class="w-full grid grid-cols-2 gap-1">
    <div>
      <YInput v-model="filters.tag.value" label="Tag"></YInput>
    </div>
    <div>
      <SuperSelect
        title="Type"
        v-model="filters.type.value"
        :multiple="false"
        :options="tagOptions"
      ></SuperSelect>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SuperSelect from '@/components/ui/Superselect.vue'
import TAG_TYPES_QUERY from '@/graphql/Tag/TagTypesQuery.gql'

export default {
  components: {
    SuperSelect
  },
  apollo: {
    tagTypes: {
      query: TAG_TYPES_QUERY,
      result(result) {
        this.tagOptions = result.data.tagTypes
      }
    }
  },
  data() {
    return {
      tagOptions: []
    }
  },
  methods: {},
  computed: {
    ...mapState({
      filters: state => state.tag.filters
    })
  }
}
</script>

<style></style>
