<template>
  <div class="payoutContainer">
    <template v-if="payouts.length == 1">
      <div>
        {{ payouts[0] }}
      </div>
    </template>
    <template v-if="payouts.length > 1">
      <div>
        {{ payouts[0] }} &hellip;
        <v-popover
          class="inline relative"
          style="top: -2px"
          trigger="hover"
          placement="top"
        >
          <button class="inline">
            <Icon name="chevronDoubleRight" size="4" class="inline link"></Icon>
          </button>
          <template slot="popover">
            <a v-close-popover>
              <div style="width:250px;" class="bg-gray-100 rounded-md p-4">
                <span class="w-100 border-b-2 font-semibold block">Rates</span>
                <ul class="mt-1">
                  <li
                    v-for="(description, index) in payouts"
                    :key="index"
                    class="py-1"
                    style="border-bottom: 1px solid lightgray"
                  >
                    {{ description }}
                  </li>
                </ul>
              </div>
            </a>
          </template>
        </v-popover>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    payouts: {
      type: Array,
      required: true
    }
  },
  mounted() {},
  computed() {},
  methods: {}
}
</script>

<style scoped>
.payoutContainer {
  max-width: 200px;
  overflow-wrap: break-word;
}
</style>
