var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-4 gap-2"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 gap-2"
  }, [_c('div', [_c('Tabs', {
    attrs: {
      "show-type": "show"
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Overview",
      "active": true
    }
  }, [_c('Widget', {
    staticClass: "rounded-t-none border-t-0"
  }, [_c('WidgetBody', [_c('div', {
    staticClass: "overflow-x-auto"
  }, [_c('div', {
    staticClass: "py-1"
  }, [_c('label', {
    staticClass: "text-muted d-block mb-0"
  }, [_vm._v("ID:")]), _vm._v(" " + _vm._s(_vm.id) + " ")]), _c('div', {
    staticClass: "py-1"
  }, [_c('label', {
    staticClass: "text-muted d-block mb-0"
  }, [_vm._v("Keyword:")]), _vm.$apollo.queries.source.loading ? _c('Spinner', {
    attrs: {
      "active": true,
      "size": 8
    }
  }) : [_vm.source.keyword ? [_vm._v(" " + _vm._s(_vm.source.keyword) + " ")] : _c('span', {
    staticClass: "font-italic text-muted"
  }, [_vm._v("None")])]], 2), _vm.source.user ? _c('div', {
    staticClass: "py-1"
  }, [_c('label', {
    staticClass: "text-muted d-block mb-0"
  }, [_vm._v("Publisher:")]), _vm.$apollo.queries.source.loading ? _c('Spinner', {
    attrs: {
      "active": true,
      "size": 8
    }
  }) : _vm._e(), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'User',
        params: {
          id: _vm.source.user.id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.source.user.label))])], 1) : _vm._e(), _vm.source.redirect ? _c('div', {
    staticClass: "py-1"
  }, [_c('label', {
    staticClass: "text-muted d-block mb-0"
  }, [_vm._v("Redirect:")]), _vm.$apollo.queries.source.loading ? _c('Spinner', {
    attrs: {
      "active": true,
      "size": 8
    }
  }) : _vm._e(), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'Redirect',
        params: {
          id: _vm.source.redirect.id
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.source.redirect.label))])], 1) : _vm._e(), _c('span', {
    staticClass: "ts-2"
  }, [_vm.isAbyssed ? _c('tag', {
    staticClass: "mr-1",
    attrs: {
      "color": "red"
    }
  }, [_vm._v("Abyssed")]) : _vm._e()], 1), _c('div', {
    staticClass: "py-1"
  }, [_vm.isAbyssed ? _c('YButton', {
    staticClass: "btn-green",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.unabyssSource
    }
  }, [_vm._v("Undo Abyss")]) : _vm.isAbyssed != null && !_vm.isAbyssed ? _c('YButton', {
    staticClass: "btn-red",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.abyssSource
    }
  }, [_vm._v("Abyss")]) : _vm._e()], 1)])])], 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "col-span-3"
  }, [_c('Tabs', {
    attrs: {
      "show-type": "show"
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Stats",
      "active": true
    }
  }, [_c('div', [_c('simple-report', {
    staticClass: "col-span-4",
    attrs: {
      "filters": _vm.filters
    }
  }, [_c('h4', {
    staticClass: "widget-title",
    attrs: {
      "slot": "table-header"
    },
    slot: "table-header"
  }, [_vm._v(" Daily Summary ")]), _c('div', {
    attrs: {
      "slot": "right"
    },
    slot: "right"
  }, [_c('router-link', {
    staticClass: "link text-xs",
    attrs: {
      "to": {
        name: 'Reports'
      }
    }
  }, [_vm._v(" VIEW REPORTS ")])], 1)])], 1)]), _c('Tab', {
    attrs: {
      "name": "Payouts"
    }
  }, [_vm.source && _vm.source.payouts && _vm.source.redirect ? _c('Payouts', {
    attrs: {
      "payouts": _vm.source.payouts,
      "sourceId": this.id,
      "redirectId": Number(this.source.redirect.id),
      "level": "source"
    }
  }) : _vm._e()], 1)], 1)], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }