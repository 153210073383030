<template>
  <Widget>
    <WidgetHeader>Create Notification</WidgetHeader>
    <WidgetBody>
      <YInput class="mb-2" label="Name" v-model="name"></YInput>
      <Superselect
        title="Routes"
        class="mb-2"
        v-model="routes"
        :options="routeOptions || []"
        track-by="name"
        label="label"
      ></Superselect>
      <Superselect
        title="Users"
        class="mb-2"
        v-model="users"
        track-by="id"
        label="label"
        :query="USER_OPTIONS_QUERY"
        :query-variables="{
          filters: {
            label: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            }
          },
          first: 25
        }"
      ></Superselect>
      <div class="flex">
        <YInput
          type="date"
          v-model="startDate"
          dateFormat="YYYY-MM-DD"
          dateFormatted="ll"
          label="Start Date"
          class="mb-2 mr-2 w-1/2"
        ></YInput>
        <YInput
          type="date"
          v-model="expiryDate"
          dateFormat="YYYY-MM-DD"
          dateFormatted="ll"
          label="Expiry Date"
          class="mb-2 w-1/2"
        ></YInput>
      </div>
      <Superselect
        title="Color"
        class="mb-2"
        v-model="color"
        :options="colorOptions || []"
        :multiple="false"
        label="label"
        track-by="value"
      ></Superselect>
      <YTextarea v-model="message" label="Message" required></YTextarea>
    </WidgetBody>
    <WidgetFooter class="bg-gray-100 flex justify-end p-4 items-center">
      <YButton
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="create"
        @keydown.enter="create"
        >Create Notification</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import CREATE_NOTIFICATION_MUTATION from '@/graphql/Notifications/CreateNotificationMutation.gql'
import USER_OPTIONS_QUERY from '@/graphql/User/UserOptionsQuery.gql'

export default {
  mixins: [ValidationErrorsMixin],
  data() {
    return {
      USER_OPTIONS_QUERY,
      isSubmitting: false,
      name: null,
      message: null,
      users: [],
      routes: null,
      color: null,
      colorOptions: [
        {
          value: 'blue',
          label: 'Blue'
        },
        {
          value: 'red',
          label: 'Red'
        },
        {
          value: 'yellow',
          label: 'Yellow'
        }
      ],
      startDate: null,
      expiryDate: null,
      routeList: this.$router.options.routes
    }
  },
  computed: {
    routeOptions() {
      return this.routeList
        .filter(route => route.meta.scope == 'publisher')
        .map(route => {
          return {
            label: route.name,
            name: route.name,
            path: route.path
          }
        })
    }
  },
  methods: {
    create() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: CREATE_NOTIFICATION_MUTATION,
          variables: {
            input: {
              name: this.name,
              message: this.message,
              color: this.color.value,
              routes:
                this.routes?.length > 0
                  ? this.routes.map(route => route.name)
                  : [],
              startDate: this.startDate,
              expiryDate: this.expiryDate,
              users: {
                connect: this.users.map(user => user.id)
              }
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$events.emit('refreshNotifications')
          this.$toastr.s('Notification Created', 'Success!')
          this.$emit('close')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  }
}
</script>

<style></style>
