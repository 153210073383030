<template>
  <div class="expandable-cell-container">
    <template>
      <div
        v-for="([country, payoutGroup], index) of visiblePayoutGroups"
        :key="'payout-group-' + country"
        class="inline-block"
      >
        <span
          v-if="country == 'null'"
          v-tooltip="getTooltipFromGroup(payoutGroup, country)"
          v-text="`All Geos`"
          class="payout-item-from-expandable"
          :class="visiblePayoutGroups.length == index + 1 ? '' : 'pr-1'"
        ></span>
        <span
          v-else
          v-tooltip="getTooltipFromGroup(payoutGroup, country)"
          v-text="country"
          class="payout-item-from-expandable"
          :class="visiblePayoutGroups.length == index + 1 ? '' : 'pr-1'"
        ></span>
      </div>
    </template>
    <template
      v-if="
        computedPayoutGroups.length > visiblePayoutGroups.length || isExpanded
      "
    >
      <template v-if="!isExpanded">&hellip;</template>
    </template>
    <div
      v-if="
        computedPayoutGroups.length > visiblePayoutGroups.length || isExpanded
      "
    >
      <a href="#" @click.prevent="toggleExpand" class="link">
        <template v-if="!isExpanded"
          >Show All ({{ computedPayoutGroups.length }})</template
        >
        <template v-if="isExpanded">Hide</template>
      </a>
    </div>
  </div>
</template>

<script>
const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export default {
  components: {},
  props: {
    payouts: {
      type: Array
    },
    showCount: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    computedPayouts() {
      return this.payouts.filter(
        payout =>
          !payout.isPaused && (payout.value > 0 || payout.value === null)
      )
    },
    computedPayoutGroups() {
      return Object.entries(groupBy(this.computedPayouts, 'countryCode'))
    },
    visiblePayoutGroups() {
      if (this.isExpanded) {
        return this.computedPayoutGroups
      } else {
        return this.computedPayoutGroups.slice(0, this.showCount)
      }
    }
  },
  mounted() {},
  updated() {},

  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded
    },
    getTooltipFromGroup(payoutGroup, country) {
      if (country == 'null') {
        return payoutGroup
          .map(
            payout =>
              (payout.rate ? payout.rate + ' - ' : '') + payout.prettyType
          )
          .join('<br/>')
      }
      return payoutGroup
        .map(
          payout => (payout.rate ? payout.rate + ' - ' : '') + payout.prettyType
        )
        .join('<br/>')
    }
  }
}
</script>
<style scoped>
.expandable-cell-container {
  max-width: 200px;
  overflow-wrap: break-word;
}
.payout-item-from-expandable {
  display: inline-block;
}
</style>
