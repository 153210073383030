var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    on: {
      "click": function ($event) {
        return _vm.$emit('input', !_vm.value);
      }
    }
  }, [_vm.value ? _vm._t("true") : _vm._e(), !_vm.value ? _vm._t("false") : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }