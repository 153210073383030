var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('chart-js', {
    attrs: {
      "chart-data": _vm.yChartData.data,
      "options": _vm.yChartData.options,
      "height": _vm.height ? _vm.height : 500
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }