var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "bg-red-600 text-white border-red-700"
  }, [_c('WidgetHeader', {
    staticClass: "bg-red-600 text-white"
  }, [_c('span', {
    staticClass: "text-white"
  }, [_vm._v("Are you sure?")])]), _c('WidgetBody', [_c('h5', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v("This action cannot be undone.")]), _c('h5', [_vm._v(" Please confirm you'd like to "), _c('span', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v("DELETE")]), _vm._v(" the Template Image with the following details: ")]), _c('ul', {
    staticClass: "text-xs mt-4 mb-3"
  }, [_c('li', {
    staticClass: "mb-3"
  }, [_vm._v(" Name: "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(this.name))])]), _c('li', [_c('EditableImage', {
    staticClass: "rounded",
    attrs: {
      "imageUrl": _vm.imageUrl,
      "thumbnailUrl": _vm.imageThumbnailUrl,
      "height": 32,
      "width": 32,
      "blankHeight": 20,
      "blankWidth": 20,
      "editable-only-when-empty": true,
      "modelId": _vm.id,
      "modelClass": "TemplateImage"
    }
  })], 1)])]), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 flex justify-between py-2 px-4"
  }, [_c('YButton', {
    staticClass: "hover:bg-red-700 focus:bg-red-700",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    staticClass: "hover:bg-red-700 focus:bg-red-700",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.deleteImage,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.deleteImage.apply(null, arguments);
      }
    }
  }, [_vm._v("Delete Template Image")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }