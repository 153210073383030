import { render, staticRenderFns } from "./AdvertiserAccountKeyForm.vue?vue&type=template&id=197b4907&scoped=true"
import script from "./AdvertiserAccountKeyForm.vue?vue&type=script&lang=js"
export * from "./AdvertiserAccountKeyForm.vue?vue&type=script&lang=js"
import style0 from "./AdvertiserAccountKeyForm.vue?vue&type=style&index=0&id=197b4907&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197b4907",
  null
  
)

export default component.exports