<template>
  <div>
    <Widget>
      <WidgetHeader @close="$emit('close')">
        Offer <sup>#</sup>{{ offer.id }} Campaigns
      </WidgetHeader>
      <WidgetBody>
        <ul>
          <li v-for="campaign in offer.campaigns" :key="campaign.id">
            <router-link
              class="link"
              :to="{ name: 'Campaign', params: { id: campaign.id } }"
              >{{ campaign.label }}</router-link
            >
          </li>
          <li v-for="offer in offer.offers" :key="offer.id">
            <router-link
              v-for="campaign in offer.campaigns"
              :key="campaign.id"
              class="link"
              :to="{ name: 'Campaign', params: { id: campaign.id } }"
              >{{ campaign.label }}</router-link
            >
          </li>
        </ul>
      </WidgetBody>
    </Widget>
  </div>
</template>

<script>
export default {
  props: {
    offer: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
