<template>
  <Widget class="bg-red-600 text-white border-red-700">
    <WidgetHeader class="bg-red-600 text-white"
      ><span class="text-white">Are you sure?</span></WidgetHeader
    >
    <WidgetBody>
      <h5 class="text-yellow-400 font-bold">This action cannot be undone.</h5>
      <h5>
        Please confirm you'd like to
        <span class="text-yellow-400 font-bold">DELETE</span> the Template Image
        with the following details:
      </h5>
      <ul class="text-xs mt-4 mb-3">
        <li class="mb-3">
          Name: <span class="font-bold">{{ this.name }}</span>
        </li>
        <li>
          <EditableImage
            class="rounded"
            :imageUrl="imageUrl"
            :thumbnailUrl="imageThumbnailUrl"
            :height="32"
            :width="32"
            :blankHeight="20"
            :blankWidth="20"
            :editable-only-when-empty="true"
            :modelId="id"
            modelClass="TemplateImage"
          ></EditableImage>
        </li>
      </ul>
    </WidgetBody>
    <WidgetFooter class="bg-dark-transparent-1 flex justify-between py-2 px-4">
      <YButton class="hover:bg-red-700 focus:bg-red-700" @click="$emit('close')"
        >Cancel</YButton
      >
      <YButton
        @click="deleteImage"
        class="hover:bg-red-700 focus:bg-red-700"
        :is-loading="isSubmitting"
        @keydown.enter="deleteImage"
        >Delete Template Image</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import DELETE_TEMPLATE_IMAGE from '@/graphql/TemplateImages/DeleteTemplateImage.gql'
import EditableImage from '@/components/utilities/EditableImage'

export default {
  mixins: [ValidationErrorsMixin],
  components: {
    EditableImage
  },
  props: {
    templateImage: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: this.templateImage.name,
      id: this.templateImage.id,
      imageUrl: this.templateImage.imageUrl,
      imageThumbnailUrl: this.templateImage.imageThumbnailUrl,
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    deleteImage() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: DELETE_TEMPLATE_IMAGE,
          variables: {
            id: this.templateImage.id
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$events.emit('refreshTemplateImages')
          this.$toastr.s('Template Image Deleted', 'Success!')
          this.$emit('close')
          this.isSubmitting = false
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
