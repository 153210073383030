var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Subscribe User ")]), _c('WidgetBody', [_c('Superselect', {
    attrs: {
      "title": "Publishers",
      "multiple": true,
      "track-by": "id",
      "label": "label",
      "focusOnMount": true,
      "max-height": "10rem",
      "query": _vm.PUBLISHERS_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      }
    },
    model: {
      value: _vm.publishers,
      callback: function ($$v) {
        _vm.publishers = $$v;
      },
      expression: "publishers"
    }
  }), _c('div', {
    staticClass: "mt-4 ml-3 font-semibold"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center mr-2",
    attrs: {
      "id": "preset-is-public-checkbox",
      "label": "Disable Reminder Emails for this Campaign",
      "inline": true,
      "type": "info"
    },
    model: {
      value: _vm.disableReminderEmails,
      callback: function ($$v) {
        _vm.disableReminderEmails = $$v;
      },
      expression: "disableReminderEmails"
    }
  })], 1)], 1), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center",
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue"
    },
    on: {
      "click": _vm.subscribe
    }
  }, [_vm._v(" Subscribe")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }