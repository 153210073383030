<template>
  <Widget class="bg-red-600 text-white border-red-700">
    <WidgetHeader class="bg-red-600 text-white"
      ><span class="text-white">Are you sure?</span></WidgetHeader
    >
    <WidgetBody>
      <h5 class="text-yellow-400 font-bold">This action cannot be undone.</h5>
      <h5>
        Please confirm you'd like to
        <span class="text-yellow-400 font-bold">DELETE</span> the Campaign with
        the following details:
      </h5>
      <template v-if="!campaignObject">
        <Spinner color="white" :size="8" />
      </template>
      <ul v-else class="text-xs mt-4 mb-3">
        <li>ID: {{ campaignId }}</li>
        <li>Name: {{ campaignName }}</li>
      </ul>
    </WidgetBody>
    <WidgetFooter class="bg-dark-transparent-1 flex justify-between py-2 px-4">
      <YButton class="hover:bg-red-700 focus:bg-red-700" @click="$emit('close')"
        >Cancel</YButton
      >
      <YButton
        @click="update"
        class="hover:bg-red-700 focus:bg-red-700"
        :is-loading="isSubmitting"
        >Delete Campaign</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import DELETE_CAMPAIGN_MUTATION from '@/graphql/Campaign/DeleteCampaignMutation.gql'

export default {
  props: {
    id: {
      cast: Number,
      required: true
    },
    name: {
      cast: String,
      required: true
    },
    campaign: {
      cast: Object,
      required: true
    }
  },
  components: {
    /*CloseX,
    SvgLoader*/
  },
  apollo: {},
  data() {
    return {
      campaignId: this.id,
      campaignName: this.name,
      campaignObject: this.campaign,
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    update() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: DELETE_CAMPAIGN_MUTATION,
          variables: {
            id: this.campaign.id
          }
        })
        .then(result => {
          this.$toastr.s('Campaign Deleted', 'Success!')
          console.log(result)
          this.isSubmitting = false
          this.$events.emit('campaignDeleted', this.campaign.id)
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
