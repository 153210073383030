<template>
  <div>
    <div>
      <div
        v-for="definition of variantDefinitions"
        :key="definition.key"
        class="mb-1"
      >
        <slot
          :definition="definition"
          :show="showStates[definition.key]"
        ></slot>
      </div>
    </div>
    <a v-show="someCanBeHidden" class="link text-xs block" @click="toggle">
      <span class="flex items-center justify-between">
        <span></span>
        <span v-if="!isExpanded" class="flex items-center">
          <span class="mr-1">({{ hiddenStatesCount }})</span>
          <Icon name="plusBoxOutline" :width="4" :height="4" />
        </span>
        <span v-else>
          <Icon name="minusBoxOutline" :width="4" :height="4" />
        </span>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    variantDefinitions: {
      type: Object,
      required: true
    },
    inputs: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    count() {
      return Object.keys(this.linkedDefinitions).length
    },
    allAreVisible() {
      return this.count == this.showStatesCount
    },
    showStatesCount() {
      return Object.values(this.showStates)?.filter(state => state)?.length ?? 0
    },
    hiddenStatesCount() {
      return (
        Object.values(this.showStates)?.filter(state => state === false)
          ?.length ?? 0
      )
    },
    someCanBeHidden() {
      return (
        Object.values(this.canBeHiddenStates).filter(state => state).length > 0
      )
    },
    canBeHiddenStates() {
      let states = {}
      Object.keys(this.variantDefinitions).forEach(key => {
        if (this.inputs[key] && this.inputs[key] != null) {
          states[key] = false
          return
        }
        states[key] = true
      })
      return states
    },
    showStates() {
      let states = {}
      Object.keys(this.variantDefinitions).forEach(key => {
        if (this.isExpanded == true) {
          states[key] = true
          return
        }

        if (this.inputs[key] && this.inputs[key] != null) {
          states[key] = true
          return
        }
        states[key] = false
      })
      return states
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>

<style></style>
