<template>
  <div>
    <div class="flex justify-between items-center">
      <div><h4 class="text-base font-bold">Queued Invalidations</h4></div>
      <div>
        <YButton
          class="mr-1"
          color="red"
          @click="flushYredirCache()"
          :is-loading="isFlushing"
          >Flush Yredir</YButton
        >
        <YButton
          class="mr-1"
          color="red"
          @click="flushCache()"
          :is-loading="isFlushing"
          >Flush Cache</YButton
        >
        <YButton
          color="blue"
          @click="processQueue()"
          :is-loading="isProcessingQueue"
          >Process Queue</YButton
        >
      </div>
    </div>
    <div>
      <YAlert class="mt-4"
        >Currently will only display first {{ first }} invalidations</YAlert
      >
      <YTable
        class="mt-4"
        empty-text="No Invalidations Queued"
        :data="queuedInvalidations"
        :loading="$apollo.queries.queuedInvalidations.loading"
      >
        <template slot="header-row">
          <th>Key</th>
          <th>Target</th>
          <th>Invalidate At</th>
        </template>
        <template slot="row" slot-scope="props">
          <td>{{ props.rowData.key }}</td>
          <td>{{ props.rowData.target }}</td>
          <td>{{ props.rowData.invalidateAt }}</td>
        </template>
      </YTable>
    </div>
  </div>
</template>

<script>
import FLUSH_CACHE_MUTATION from '@/graphql/Cache/FlushCacheMutation.gql'
import FLUSH_YREDIR_CACHE_MUTATION from '@/graphql/Cache/FlushYredirCacheMutation.gql'
import PROCESS_INVALIDATION_QUEUE_MUTATION from '@/graphql/QueuedInvalidation/ProcessInvalidationQueueMutation.gql'
import QUEUED_INVALIDATIONS_QUERY from '@/graphql/QueuedInvalidation/QueuedInvalidationsQuery.gql'

export default {
  apollo: {
    queuedInvalidations: {
      query: QUEUED_INVALIDATIONS_QUERY,
      variables() {
        return {
          first: this.first
        }
      },
      watchLoading(isLoading, countModifier) {
        this.$store.dispatch('updateGlobalLoader', countModifier)
      },
      result({ data }) {
        this.queuedInvalidations = data.queuedInvalidations.data
      }
    }
  },
  data() {
    return {
      isFlushing: false,
      isProcessingQueue: false,
      queuedInvalidations: [],
      first: 100
    }
  },
  methods: {
    flushCache() {
      this.isFlushing = true
      this.$apollo
        .mutate({
          mutation: FLUSH_CACHE_MUTATION,
          variables: {}
        })
        .then(() => {
          this.$toastr.s('Cache Flushed')
          this.isFlushing = false
          setTimeout(this.$apollo.queries.queuedInvalidations.refetch(), 500)
        })
        .catch(error => {
          this.isFlushing = false
          this.$toastr.s('Error Flushing Cache')
          console.error(error)
        })
    },
    flushYredirCache() {
      this.isFlushing = true
      this.$apollo
        .mutate({
          mutation: FLUSH_YREDIR_CACHE_MUTATION,
          variables: {}
        })
        .then(() => {
          this.$toastr.s('Yredir Cache Flushed')
          this.isFlushing = false
        })
        .catch(error => {
          this.isFlushing = false
          this.$toastr.s('Error Flushing Cache')
          console.error(error)
        })
    },    
    processQueue() {
      this.isProcessingQueue = true
      this.$apollo
        .mutate({
          mutation: PROCESS_INVALIDATION_QUEUE_MUTATION,
          variables: {}
        })
        .then(() => {
          this.$toastr.s('Invalidations Processed')
          this.isProcessingQueue = false
          this.$apollo.queries.queuedInvalidations.refetch()
        })
        .catch(error => {
          this.isProcessingQueue = false
          this.$toastr.s('Error Flushing Cache')
          console.error(error)
        })
    }
  }
}
</script>

<style></style>
