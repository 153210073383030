<template>
  <div class="flex">
    <Superselect
      title="Fallback Campaign"
      class="w-1/3"
      track-by="id"
      label="label"
      v-model="selectedCampaign"
      :multiple="false"
      :required="true"
      :options="[]"
      :query="CAMPAIGN_OPTIONS_QUERY"
      :query-variables="{
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: { matchType: 'contains' }
          },
          status: ['active']
        }
      }"
    ></Superselect>
    <Superselect
      v-if="fallbackOfferOptions.length > 0"
      title="Fallback Offer"
      :multiple="false"
      :required="true"
      v-model="selectedOffer"
      track-by="id"
      label="label"
      class="ml-2 w-1/3"
      :options="fallbackOfferOptions"
    ></Superselect>
    <YButton
      v-if="
        (selectedOffer && selectedOffer.id !== fallbackOfferId) ||
          (selectedCampaign && selectedCampaign.id !== fallbackCampaignId) ||
          (fallbackCampaignId && !selectedCampaign)
      "
      @click="
        type === 'Redirect' ? saveRedirectFallback() : saveCampaignFallback()
      "
      color="blue"
      class="ml-2 h-12"
      :is-loading="isUpdating"
      >Save</YButton
    >
    <YButton
      v-if="selectedCampaign"
      color="blue"
      @click="clearFallbackValues"
      :is-loading="isUpdating"
      class="ml-2 h-12"
      >Clear</YButton
    >
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import CAMPAIGN_QUERY from '@/graphql/Campaign/CampaignQuery.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import UPDATE_CAMPAIGN_MUTATION from '@/graphql/Campaign/UpdateCampaignMutation.gql'
import UPDATE_REDIRECT_MUTATION from '@/graphql/Redirect/UpdateRedirectMutation.gql'
import OFFER_QUERY from '@/graphql/Offer/OfferQuery.gql'

export default {
  props: {
    id: {
      type: String
    },
    fallbackCampaignId: {
      type: String,
      default: null
    },
    fallbackOfferId: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'Campaign'
    }
  },
  mixins: [ValidationErrorsMixin],
  apollo: {
    campaign: {
      query: CAMPAIGN_QUERY,
      variables() {
        return {
          id: this.selectedCampaign
            ? this.selectedCampaign.id
            : this.fallbackCampaignId
        }
      },
      result(result) {
        this.selectedCampaign = {
          __typename: 'Campaign',
          id: result.data.campaign.id,
          label: result.data.campaign.label,
          name: result.data.campaign.name
        }

        this.fallbackOfferOptions = result.data.campaign.offers

        if (
          !this.fallbackOfferId ||
          (this.fallbackCampaignId &&
            this.fallbackCampaignId !== this.selectedCampaign.id)
        ) {
          this.selectedOffer = result.data.campaign.offers[0]
        }
      }
    },
    offer: {
      query: OFFER_QUERY,
      variables() {
        return {
          id: this.fallbackOfferId
        }
      },
      result(result) {
        this.selectedOffer = result.data.offer
      }
    }
  },
  data() {
    return {
      CAMPAIGN_OPTIONS_QUERY,
      selectedCampaign: null,
      selectedOffer: null,
      fallbackOfferOptions: [],
      isUpdating: false
    }
  },
  watch: {
    selectedCampaign() {
      if (this.$apollo.queries.campaign.skip) {
        this.$apollo.queries.campaign.skip = false
      }
    }
  },
  mounted() {
    if (!this.fallbackCampaignId) {
      this.$apollo.queries.campaign.skip = true
    }
  },
  methods: {
    clearFallbackValues() {
      this.selectedCampaign = null
      this.selectedOffer = null
      this.fallbackOfferOptions = []
    },
    saveCampaignFallback() {
      this.isUpdating = true
      this.$apollo
        .mutate({
          mutation: UPDATE_CAMPAIGN_MUTATION,
          variables: {
            input: {
              id: this.id,
              fallbackCampaignId: this.selectedCampaign
                ? this.selectedCampaign.id
                : null,
              fallbackOfferId: this.selectedOffer ? this.selectedOffer.id : null
            }
          }
        })
        .then(() => {
          this.isUpdating = false
          this.clearValidationErrors()
          this.$emit('close')
          this.$toastr.s('Fallback Campaign Updated', 'Success')
          this.$events.emit('fallbackCampaignUpdated')
        })
        .catch(error => {
          this.isUpdating = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    saveRedirectFallback() {
      this.isUpdating = true
      this.$apollo
        .mutate({
          mutation: UPDATE_REDIRECT_MUTATION,
          variables: {
            id: this.id,
            input: {
              fallbackCampaignId: this.selectedCampaign
                ? this.selectedCampaign.id
                : '',
              fallbackOfferId: this.selectedOffer
                ? this.selectedOffer.id
                : undefined
            }
          }
        })
        .then(() => {
          this.isUpdating = false
          this.clearValidationErrors()
          this.$emit('close')
          this.$toastr.s('Fallback Campaign Updated', 'Success')
          this.$events.emit('fallbackCampaignUpdated')
        })
        .catch(error => {
          this.isUpdating = false
          this.setValidationErrors(error)
          console.error(error)
        })
    }
  }
}
</script>

<style></style>
