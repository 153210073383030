var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "top"
    }
  }, [_c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Offer',
        params: {
          action: 'create'
        }
      }
    }
  }, [_c('button', {
    staticClass: "btn btn-blue"
  }, [_vm._v("Create Offer")])])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.action,
      expression: "!action"
    }]
  }, [_c('Widget', [_c('WidgetHeader', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('OfferFilters')], 1)], 2)], 1), _vm.offers && _vm.offers.data ? _c('OffersTable', {
    staticClass: "pt-4",
    attrs: {
      "offers": _vm.offers.data || [],
      "loading": _vm.$apollo.queries.offers.loading
    }
  }) : _vm._e(), _vm.offers && _vm.offers.data ? _c('YPagination', {
    key: _vm.totalPages,
    attrs: {
      "data": _vm.offers.paginatorInfo
    },
    on: {
      "load-page": _vm.loadPage,
      "next-page": _vm.nextPage,
      "previous-page": _vm.previousPage,
      "per-page": _vm.perPageSelection
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }