var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', [_vm._v("Set Lead Priority")]), _c('WidgetBody', [_c('div', {
    staticClass: "flex flex-row gap-6 px-4"
  }, [_c('div', {
    staticClass: "flex w-2/5"
  }, [_c('div', {
    staticClass: "flex flex-col w-full"
  }, [_c('div', {
    staticClass: "flex flex-col text-gray-900"
  }, [!_vm.user.advertiserId ? _c('span', [_c('span', {
    staticClass: "block font-semibold text-gray-800 text-lg"
  }, [_vm._v(_vm._s(_vm.user.name))]), _c('span', {}, [_vm._v(_vm._s(_vm.user.id) + " "), _vm.user.primaryContact.company ? _c('span', {
    staticClass: "inline"
  }, [_vm._v(" - " + _vm._s(_vm.user.primaryContact.company))]) : _vm._e()])]) : _c('span', [_c('span', {
    staticClass: "block font-semibold text-gray-800 text-lg"
  }, [_vm._v(_vm._s(_vm.user.primaryContact.company))]), _c('span', {
    staticClass: "inline"
  }, [_vm._v(_vm._s(_vm.user.label))])]), _vm.user.email ? _c('span', {
    staticClass: "block"
  }, [_vm._v(_vm._s(_vm.user.email))]) : _vm._e(), _vm.user.primaryContact.messenger ? _c('div', {
    staticClass: "flex items-center text-xs"
  }, [_c('Icon', {
    staticClass: "w-4 h-4 text-gray-600",
    attrs: {
      "name": JSON.parse(_vm.user.primaryContact.messenger.toLowerCase()).app
    }
  }), _c('span', {
    staticClass: "text-gray-600"
  }, [_vm._v(" " + _vm._s(JSON.parse(_vm.user.primaryContact.messenger).handle) + " ")])], 1) : _vm._e(), _c('div', {
    staticClass: "flex flex-row mt-2"
  }, [_c('div', _vm._l(_vm.user.tags.filter(function (item) {
    return item.type == 'leadType';
  }), function (tag) {
    return _c('Tag', {
      key: tag.id,
      staticClass: "mr-2"
    }, [_vm._v(" " + _vm._s(tag.name) + " ")]);
  }), 1)])])])]), _c('div', {
    staticClass: "grid gap-2 w-full items-start bg-gray-100 rounded p-4"
  }, [_c('Superselect', {
    staticClass: "w-full capitalize",
    attrs: {
      "title": "Priority Status",
      "multiple": false,
      "options": ['regular', 'high', 'rejected']
    },
    model: {
      value: _vm.priorityStatus,
      callback: function ($$v) {
        _vm.priorityStatus = $$v;
      },
      expression: "priorityStatus"
    }
  }), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "absolute top-0 right-0 z-50 pr-1 text-2xl text-red-500"
  }, [_vm._v(" * ")]), _c('VueCtkDateTimePicker', {
    staticClass: "mb-2",
    attrs: {
      "onlyDate": true,
      "format": 'YYYY-MM-DD',
      "formatted": 'll',
      "label": 'Follow Up Date for High Priority',
      "locale": "en",
      "range": false,
      "inititale-value": _vm.initialDateValue
    },
    model: {
      value: _vm.followUpDate,
      callback: function ($$v) {
        _vm.followUpDate = $$v;
      },
      expression: "followUpDate"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "w-full flex justify-end gap-3 mt-4"
  }, [_c('YButton', {
    attrs: {
      "color": "gray"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }