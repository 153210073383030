var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.visibilityChanged,
      expression: "visibilityChanged"
    }],
    staticClass: "grid grid-cols-12 gap-4"
  }, [_c('div', {
    staticClass: "mb-2 col-span-6"
  }, [_c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.localUser.primaryContact.name,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "name", $$v);
      },
      expression: "localUser.primaryContact.name"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Company"
    },
    model: {
      value: _vm.localUser.primaryContact.company,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "company", $$v);
      },
      expression: "localUser.primaryContact.company"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.localUser.primaryContact.email,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "email", $$v);
      },
      expression: "localUser.primaryContact.email"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Messenger Handle"
    },
    model: {
      value: _vm.messengerHandle,
      callback: function ($$v) {
        _vm.messengerHandle = $$v;
      },
      expression: "messengerHandle"
    }
  }), _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "Messenger App",
      "multiple": false,
      "options": ['telegram', 'skype']
    },
    model: {
      value: _vm.messengerApp,
      callback: function ($$v) {
        _vm.messengerApp = $$v;
      },
      expression: "messengerApp"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Phone"
    },
    model: {
      value: _vm.localUser.primaryContact.phone,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "phone", $$v);
      },
      expression: "localUser.primaryContact.phone"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Address"
    },
    model: {
      value: _vm.localUser.primaryContact.address,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "address", $$v);
      },
      expression: "localUser.primaryContact.address"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Address 2"
    },
    model: {
      value: _vm.localUser.primaryContact.address2,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "address2", $$v);
      },
      expression: "localUser.primaryContact.address2"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "City"
    },
    model: {
      value: _vm.localUser.primaryContact.city,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "city", $$v);
      },
      expression: "localUser.primaryContact.city"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Region"
    },
    model: {
      value: _vm.localUser.primaryContact.region,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "region", $$v);
      },
      expression: "localUser.primaryContact.region"
    }
  }), _c('Superselect', {
    staticClass: "mb-1",
    attrs: {
      "title": "Country",
      "multiple": false,
      "track-by": "code",
      "label": "label",
      "options": _vm.countries
    },
    model: {
      value: _vm.localUser.primaryContact.country,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "country", $$v);
      },
      expression: "localUser.primaryContact.country"
    }
  }), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Postal Code"
    },
    model: {
      value: _vm.localUser.primaryContact.postalCode,
      callback: function ($$v) {
        _vm.$set(_vm.localUser.primaryContact, "postalCode", $$v);
      },
      expression: "localUser.primaryContact.postalCode"
    }
  })], 1), _c('div', {
    staticClass: "mb-2 col-span-6"
  }, [_vm.professionalReferences ? _c('div', {
    staticClass: "p-2 border rounded border-gray-400 mb-1"
  }, [_c('p', {
    staticClass: "text-indigo-600 mb-1"
  }, [_vm._v("Professional References")]), _vm._l(_vm.professionalReferences, function (reference, key) {
    return _c('div', {
      key: key,
      staticClass: "mb-3"
    }, [_c('p', [_c('span', {
      staticClass: "text-gray-700"
    }, [_vm._v("Company:")]), _vm._v(" " + _vm._s(reference.company) + " ")]), _c('p', [_c('span', {
      staticClass: "text-gray-700"
    }, [_vm._v("Contact:")]), _vm._v(" " + _vm._s(reference.contact) + " ")]), _c('p', [_c('span', {
      staticClass: "text-gray-700"
    }, [_vm._v("Email:")]), _vm._v(" " + _vm._s(reference.email) + " ")])]);
  })], 2) : _vm._e(), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Networks",
      "disabled": true,
      "value": _vm.registrationQuestions ? _vm.registrationQuestions.question1.answer : ''
    }
  })], 1), _vm.validationErrors && _vm.isVisible ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e(), _vm.hasChanges && _vm.isVisible ? _c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('YButton', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }, [_vm._v("Update Primary Contact")])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }