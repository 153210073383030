<template>
  <Widget>
    <WidgetHeader @close="$emit('close')">
      Update Password
    </WidgetHeader>
    <WidgetBody>
      <YInput label="New Password" v-model="password" type="password" name="new_password"/>
      <YInput label="Confirm Password" v-model="passwordConfirmation" type="password" name="confirm_password" class="mt-1"/>
    </WidgetBody>
    <WidgetFooter class="py-2 px-4 flex justify-between bg-gray-100">
      <YButton color="link" @click="$emit('close')">Cancel</YButton>
      <YButton
        color="blue"
        @click="update"
        :disabled="!(password && passwordConfirmation && password == passwordConfirmation)"
        :is-loading="isUpdating"
        >Update</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import ADMIN_UPDATE_PASSWORD_MUTATION from '@/graphql/Auth/AdminUpdatePasswordMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    userId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      password: null,
      passwordConfirmation: null,
      isUpdating: false
    }
  },
  mounted(){},
  methods: {
    update() {
      this.isUpdating = true
      this.$apollo
        .mutate({
          mutation: ADMIN_UPDATE_PASSWORD_MUTATION,
          variables: {
            input: {
              userId: this.userId,
              password: this.password,
              password_confirmation: this.passwordConfirmation
            }
          }
        })
        .then(({data}) => {
          this.clearValidationErrors()
          this.isUpdating = false
          this.$toastr.s('', data.adminUpdatePassword.message)
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
          this.isUpdating = false
          this.setValidationErrors(error)
        })
    }
  }
}
</script>

<style></style>
