var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "block w-full",
    staticStyle: {
      "min-height": "65px"
    }
  }, [_c('portal', {
    attrs: {
      "to": "content-top-bar-left-header"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Onboarding'
      }
    }
  }, [_c('YButton', {
    attrs: {
      "color": "inverse"
    }
  }, [_c('Icon', {
    staticClass: "inline mr-1",
    staticStyle: {
      "margin-bottom": "3px"
    },
    attrs: {
      "name": "arrowLeft",
      "size": "4"
    }
  }), _vm._v("Back to CRM")], 1)], 1)], 1)])], 1), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-wrap w-3/4 m-auto justify-center text-sm bg-white p-6 rounded-lg border shadow-xl text-center",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_c('p', {
    staticClass: "text-lg mb-4 text-inverse-700"
  }, [_c('strong', [_vm._v("Thank you for registering your contact information with YTZ!")])]), _c('p', {
    staticClass: "text-gray-900"
  }, [_vm._v(" Our team will reach out soon to give you a personalized overview of our platform. ")]), _c('div', {
    staticClass: "progress mt-5"
  }, [_c('div', {
    staticClass: "progress-value"
  })])]);

}]

export { render, staticRenderFns }