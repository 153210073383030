var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SetStatusWidget', {
    attrs: {
      "header": _vm.header,
      "modelName": _vm.modelName,
      "modelIds": _vm.modelIds,
      "currentStatus": _vm.currentStatus,
      "newStatus": _vm.newStatus,
      "options": _vm.options,
      "autoSubmit": _vm.autoSubmit,
      "enumName": _vm.enumName,
      "userWasAutoRejected": _vm.userWasAutoRejected,
      "modelType": "domain"
    },
    on: {
      "success": _vm.setStatusOnSuccess,
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }