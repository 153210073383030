var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', [_vm._v(" Add Image ")]), _c('WidgetBody', {
    staticClass: "py-0"
  }, [_vm.deleteExisting ? _c('YAlert', {
    attrs: {
      "color": "red"
    }
  }, [_vm._v(" This will overwrite any existing images. ")]) : _vm._e()], 1), _c('WidgetBody', [_c('div', {
    staticClass: "flex items-center"
  }, [_c('YImage', {
    staticClass: "rounded mr-4",
    attrs: {
      "src": _vm.thumbnailUrl || _vm.url,
      "width": 20,
      "height": 20
    }
  }), _c('div', [_c('div', [_vm._v("Type: " + _vm._s(_vm.modelClass))]), _c('div', [_vm._v("ID: " + _vm._s(_vm.modelId))])])], 1)]), _c('WidgetFooter', {
    staticClass: "bg-gray-100 py-2 px-4 flex justify-between items-center"
  }, [_c('YButton', {
    attrs: {
      "color": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": function ($event) {
        return _vm.addImageFromUrl();
      }
    }
  }, [_vm._v("Save")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }