var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data && _vm.data.lastPage > 1 ? _c('div', {
    class: _vm.css.wrapperClass
  }, [_c('div', {
    staticClass: "btn-group",
    attrs: {
      "role": "group",
      "aria-label": "Pagination"
    }
  }, [!_vm.hideFirstLast ? _c('button', {
    class: ['', _vm.css.linkClass, _vm.isOnFirstPage ? _vm.css.disabledClass : ''],
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.loadPage(1);
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "skipPrevious",
      "size": 4
    }
  })], 1) : _vm._e(), !_vm.hideNextPrevious ? _c('button', {
    class: ['', _vm.css.linkClass, _vm.isOnFirstPage ? _vm.css.disabledClass : ''],
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.loadPage(_vm.data.currentPage - 1);
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "chevronLeft",
      "size": 4
    }
  })], 1) : _vm._e(), !_vm.hidePageNumbers ? [_vm.notEnoughPages ? [_vm._l(_vm.totalPage, function (n) {
    return [_c('button', {
      key: n,
      class: ['', _vm.css.pageClass, _vm.isCurrentPage(n) ? _vm.css.activeClass : ''],
      attrs: {
        "type": "button"
      },
      domProps: {
        "innerHTML": _vm._s(n)
      },
      on: {
        "click": function ($event) {
          return _vm.loadPage(n);
        }
      }
    })];
  })] : [_vm._l(_vm.windowSize, function (n) {
    return [_c('button', {
      key: n,
      class: ['', _vm.css.pageClass, _vm.isCurrentPage(_vm.windowStart + n - 1) ? _vm.css.activeClass : ''],
      attrs: {
        "type": "button"
      },
      domProps: {
        "innerHTML": _vm._s(_vm.windowStart + n - 1)
      },
      on: {
        "click": function ($event) {
          return _vm.loadPage(_vm.windowStart + n - 1);
        }
      }
    })];
  })]] : _vm._e(), !_vm.hideNextPrevious ? _c('button', {
    class: ['', _vm.css.linkClass, _vm.isOnLastPage ? _vm.css.disabledClass : ''],
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.loadPage(_vm.data.currentPage + 1);
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "chevronRight",
      "size": 4
    }
  })], 1) : _vm._e(), !_vm.hideFirstLast ? _c('button', {
    class: ['', _vm.css.linkClass, _vm.isOnLastPage ? _vm.css.disabledClass : ''],
    on: {
      "click": function ($event) {
        return _vm.loadPage(_vm.totalPage);
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "skipNext",
      "size": 4
    }
  })], 1) : _vm._e()], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }