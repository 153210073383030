import Vue from 'vue'
import VTooltip from 'v-tooltip'
import VueEvents from 'vue-events'
import PortalVue from 'portal-vue'
import Toastr from 'vue-toastr'
// import Filesaver from 'file-saver'
// Vue.prototype.$filesaver = Filesaver
import VModal from 'vue-js-modal'
import VueObserveVisibility from 'vue-observe-visibility'
import UUID from 'vue-uuid'
import VueCastProps from 'vue-cast-props'
import VueClipboard from 'vue-clipboard2'
import VueImg from 'v-img'
import vueTabevents from 'vue-tabevents'

// Luxon
import VueLuxon from 'vue-luxon'
Vue.use(VueLuxon, {
  input: {
    zone: 'utc',
    format: 'sql'
  },
  output: {
    zone: 'utc',
    format: 'yyyy-MM-dd'
  }
})

Vue.use(VTooltip)
Vue.use(VueEvents)
Vue.use(PortalVue)
Vue.use(Toastr)
Vue.use(VModal, { dynamic: true, injectModalsContainer: true })
Vue.use(VueObserveVisibility)
Vue.use(UUID)
Vue.use(VueCastProps)
Vue.use(VueClipboard)
Vue.use(VueImg)
Vue.use(vueTabevents)
