<template>
  <div>
    <Widget class="mb-2">
      <WidgetHeader class="p-1 bg-gray-200">
        <template slot="rawContent">
          <div class="grid grid-cols-1 md:grid-cols-6 gap-1 w-full">
            <Superselect
              title="Domain"
              v-model="filters.id.value"
              :modifiers="filters.id.modifiers"
              :settings="filters.id.settings"
              :options="domainOptions || []"
              placeholder="Select"
              track-by="id"
              label="id"
              :close-on-select="false"
              :query="DOMAIN_OPTIONS_QUERY"
              :query-variables="{
                first: 20,
                filters: {
                  id: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  }
                }
              }"
              edit-placeholder="Paste Domains"
            ></Superselect>

            <Superselect
              title="Registrar"
              v-model="filters.registrar.value"
              :modifiers="filters.registrar.modifiers"
              :settings="filters.registrar.settings"
              :options="registrarOptions || []"
              placeholder="Select"
              track-by="id"
              label="label"
              :close-on-select="false"
              edit-placeholder="Paste Registrars"
              :loading="$apollo.queries.registrarOptions.loading"
            ></Superselect>

            <Superselect
              title="Type"
              v-model="filters.type.value"
              :modifiers="filters.type.modifiers"
              :options="typeOptions || []"
              placeholder="Select"
              track-by="id"
              label="label"
              :loading="$apollo.queries.typeOptions.loading"
            ></Superselect>

            <Superselect
              title="Status"
              v-model="filters.status.value"
              :modifiers="filters.status.modifiers"
              :options="statusOptions || []"
              placeholder="Select"
              track-by="id"
              label="label"
              :loading="$apollo.queries.statusOptions.loading"
            ></Superselect>

            <Superselect
              title="Tags"
              v-model="filters.tags.value"
              :modifiers="filters.tags.modifiers"
              :options="tagOptions || []"
              placeholder="Select"
              track-by="id"
              label="tag"
              :loading="$apollo.queries.tagOptions.loading"
            ></Superselect>

            <Superselect
              title="Publisher Status"
              v-model="filters.publisherStatus.value"
              :modifiers="filters.publisherStatus.modifiers"
              :options="publisherStatusOptions || []"
              placeholder="Select"
              track-by="id"
              label="label"
              :loading="$apollo.queries.publisherStatusOptions.loading"
            ></Superselect>

            <Superselect
              title="Publisher"
              v-model="filters.publisher"
              :options="publisherOptions || []"
              placeholder="Select"
              track-by="id"
              label="label"
              :multiple="false"
              :query="PUBLISHER_OPTIONS_QUERY"
              :query-variables="{
                filters: {
                  label: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  }
                },
                first: 100
              }"
            ></Superselect>
            <Superselect
              title="Record Type"
              v-model="filters.recordType.value"
              placeholder="Select"
              :modifiers="filters.recordType.modifiers"
              :options="recordTypeOptions || []"
              :settings="filters.recordType.settings"
              track-by="id"
              label="label"
              :loading="$apollo.queries.recordTypeOptions.loading"
            >
              <template v-slot:footer>
                <div v-show="filterDisplayMode == 'advanced'" class="mt-1 flex">
                  <y-toggle
                    v-model="filters.recordType.settings.filtersDomains"
                    v-tooltip="`Filter Domains`"
                    class="mr-1"
                  >
                    <template v-slot:true>
                      <Icon name="earth" :size="4" class="text-blue-600" />
                    </template>
                    <template v-slot:false>
                      <Icon name="earth" :size="4" class="text-gray-400" />
                    </template>
                  </y-toggle>
                  <y-toggle
                    v-model="filters.recordType.settings.filtersRecords"
                    v-tooltip="`Filter Records`"
                  >
                    <template v-slot:true>
                      <Icon name="dns" :size="4" class="text-blue-600" />
                    </template>
                    <template v-slot:false>
                      <Icon name="dns" :size="4" class="text-gray-400" />
                    </template>
                  </y-toggle>
                </div>
              </template>
            </Superselect>

            <Superselect
              title="Subdomain"
              :force-edit="true"
              :edit-placeholder="``"
              v-model="filters.recordSubdomain.value"
              :modifiers="filters.recordSubdomain.modifiers"
              track-by="id"
              label="label"
            >
              <template v-slot:footer>
                <div v-show="filterDisplayMode == 'advanced'" class="mt-1 flex">
                  <y-toggle
                    v-model="filters.recordSubdomain.settings.filtersDomains"
                    v-tooltip="`Filter Domains`"
                    class="mr-1"
                  >
                    <template v-slot:true>
                      <Icon name="earth" :size="4" class="text-blue-600" />
                    </template>
                    <template v-slot:false>
                      <Icon name="earth" :size="4" class="text-gray-400" />
                    </template>
                  </y-toggle>
                  <y-toggle
                    v-model="filters.recordSubdomain.settings.filtersRecords"
                    v-tooltip="`Filter Records`"
                  >
                    <template v-slot:true>
                      <Icon name="dns" :size="4" class="text-blue-600" />
                    </template>
                    <template v-slot:false>
                      <Icon name="dns" :size="4" class="text-gray-400" />
                    </template>
                  </y-toggle>
                </div>
              </template>
            </Superselect>

            <Superselect
              title="Provider"
              v-model="filters.recordProvider.value"
              :modifiers="filters.recordProvider.modifiers"
              :options="providerOptions || []"
              :settings="filters.recordProvider.settings"
              track-by="id"
              label="label"
              placeholder="Select"
              :close-on-select="false"
              edit-placeholder="Paste Providers"
              :loading="$apollo.queries.providerOptions.loading"
            >
              <template v-slot:footer>
                <div v-show="filterDisplayMode == 'advanced'" class="mt-1 flex">
                  <y-toggle
                    v-model="filters.recordProvider.settings.filtersDomains"
                    v-tooltip="`Filter Domains`"
                    class="mr-1"
                  >
                    <template v-slot:true>
                      <Icon name="earth" :size="4" class="text-blue-600" />
                    </template>
                    <template v-slot:false>
                      <Icon name="earth" :size="4" class="text-gray-400" />
                    </template>
                  </y-toggle>
                  <y-toggle
                    v-model="filters.recordProvider.settings.filtersRecords"
                    v-tooltip="`Filter Records`"
                  >
                    <template v-slot:true>
                      <Icon name="dns" :size="4" class="text-blue-600" />
                    </template>
                    <template v-slot:false>
                      <Icon name="dns" :size="4" class="text-gray-400" />
                    </template>
                  </y-toggle>
                </div>

                <Superselect
                  v-show="filters.recordProvider.value.length > 0"
                  class="mt-1"
                  title="Box"
                  placeholder="Select Server"
                  v-model="filters.recordServer.value"
                  :modifiers="filters.recordServer.modifiers"
                  :options="serverOptions || []"
                  track-by="id"
                  label="label"
                  :hide-label="true"
                >
                  <template v-slot:footer>
                    <div
                      v-show="filterDisplayMode == 'advanced'"
                      class="mt-1 flex"
                    >
                      <y-toggle
                        v-model="filters.recordServer.settings.filtersDomains"
                        v-tooltip="`Filter Domains`"
                        class="mr-1"
                      >
                        <template v-slot:true>
                          <Icon name="earth" :size="4" class="text-blue-600" />
                        </template>
                        <template v-slot:false>
                          <Icon name="earth" :size="4" class="text-gray-400" />
                        </template>
                      </y-toggle>
                      <y-toggle
                        v-model="filters.recordServer.settings.filtersRecords"
                        v-tooltip="`Filter Records`"
                      >
                        <template v-slot:true>
                          <Icon name="dns" :size="4" class="text-blue-600" />
                        </template>
                        <template v-slot:false>
                          <Icon name="dns" :size="4" class="text-gray-400" />
                        </template>
                      </y-toggle>
                    </div>
                  </template>
                </Superselect>
              </template>
            </Superselect>

            <Superselect
              title="Record Content"
              :force-edit="true"
              :edit-placeholder="``"
              v-model="filters.recordContent.value"
              :modifiers="filters.recordContent.modifiers"
              :settings="filters.recordContent.settings"
              :options="recordContentOptions || []"
              track-by="id"
              label="label"
            >
              <template v-slot:footer>
                <div
                  v-show="filterDisplayMode == 'advanced'"
                  class="mt-1 flex mb-1"
                >
                  <y-toggle
                    v-model="filters.recordContent.settings.filtersDomains"
                    v-tooltip="`Filter Domains`"
                    class="mr-1"
                  >
                    <template v-slot:true>
                      <Icon name="earth" :size="4" class="text-blue-600" />
                    </template>
                    <template v-slot:false>
                      <Icon name="earth" :size="4" class="text-gray-400" />
                    </template>
                  </y-toggle>
                  <y-toggle
                    v-model="filters.recordContent.settings.filtersRecords"
                    v-tooltip="`Filter Records`"
                  >
                    <template v-slot:true>
                      <Icon name="dns" :size="4" class="text-blue-600" />
                    </template>
                    <template v-slot:false>
                      <Icon name="dns" :size="4" class="text-gray-400" />
                    </template>
                  </y-toggle>
                </div>
                <y-radios
                  v-show="filterDisplayMode == 'advanced'"
                  name="content-filter-type"
                  class="flex"
                  :radios="[
                    { label: 'Exact', value: 'exact' },
                    { label: 'Contains', value: 'contains' }
                  ]"
                  v-model="filters.recordContent.modifiers.matchType"
                  :inline="true"
                  :radios-inline="true"
                  radio-class="mr-2"
                  color="success"
                ></y-radios>
              </template>
            </Superselect>

            <Superselect
              title="Spamhaus Status"
              v-model="spamhausCheck"
              :options="spamhausCheckOptions || []"
              placeholder="Select"
              track-by="value"
              label="label"
            ></Superselect>

            <Superselect
              title="Nameserver Domain"
              v-model="filters.nameserverId.value"
              :settings="filters.nameserverId.settings"
              :options="domainOptions || []"
              placeholder="Select"
              track-by="id"
              label="id"
              :close-on-select="false"
              :query="DOMAIN_OPTIONS_QUERY"
              :query-variables="{
                first: 20,
                filters: {
                  id: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  },
                  type: {
                    value: ['nameserver']
                  }
                }
              }"
              edit-placeholder="Paste Domains"
            ></Superselect>

            <Superselect
              title="Nameserver Provider"
              v-model="filters.nameServerProvider.value"
              :modifiers="filters.nameServerProvider.modifiers"
              :settings="filters.nameServerProvider.settings"
              :options="providerOptions || []"
              :loading="$apollo.queries.providerOptions.loading"
              placeholder="Select"
              track-by="name"
              label="label"
            ></Superselect>

            <Superselect
              title="Nameserver Ip"
              v-model="filters.nameServerIp.value"
              :modifiers="filters.nameServerIp.modifiers"
              :settings="filters.nameServerIp.settings"
              placeholder="Select"
              :forceEdit="true"
              editPlaceholder="Type IPs"
              track-by="id"
              label="id"
            ></Superselect>

            <div v-if="filterDisplayMode == 'advanced'" class="col-12 col-md">
              <Superselect
                title="Trash Status"
                v-model="trashedFilter"
                :multiple="false"
                :modifiers="{}"
                :settings="{}"
                :options="trashedOptions || []"
                track-by="id"
                label="label"
              ></Superselect>
            </div>
          </div>
        </template>
      </WidgetHeader>
      <WidgetHeader style="border-top: 1px solid #D9D9D9;">
        <template slot="rawContent">
          <div class="flex items-center justify-between w-full">
            <div class="flex items-center">
              <y-radios
                v-model="filterDisplayMode"
                :radios="[
                  { label: 'Simple', value: 'simple' },
                  { label: 'Advanced', value: 'advanced' }
                ]"
                class="flex"
                radio-class="mr-3"
                color="info"
              ></y-radios>
            </div>
            <div>
              <YButton
                class="bg-white hover:bg-gray-100 text-blue-600"
                @click="resetFilters()"
                >Reset Filters</YButton
              >
            </div>
          </div>
        </template>
      </WidgetHeader>
    </Widget>

    <DomainsTable
      v-if="domains"
      v-show="!$apollo.queries.domains.loading"
      :data="domains"
      :publisherPermissions="abilitySummaries"
    ></DomainsTable>
    <div v-if="$apollo.queries.domains.loading" style="text-align: center;">
      <Spinner :active="true" :size="10" color="green"></Spinner>
    </div>
    <div class="flex justify-between items-center mt-2">
      <div class="flex items-center">
        <div class="inline-block">
          <Superselect
            style="min-width:4.6rem; border: none;"
            selectClass="border-0"
            v-model="perPage"
            title="Per Page"
            :multiple="false"
            :options="perPageOptions || []"
          ></Superselect>
        </div>
        <div class="inline-block ml-3 text-gray-600 italic">
          {{ domainsPaginator.firstItem }} - {{ domainsPaginator.lastItem }} of
          {{ domainsPaginator.total }}
        </div>
      </div>
      <div>
        <y-pagination
          :data="domainsPaginator"
          @change-page="changePage"
        ></y-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import DomainsTable from './DomainsTable'
import gql from 'graphql-tag'
import yPagination from '../yPagination'
import yToggle from '../yToggle'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import DOMAINS_QUERY from '@/graphql/Domain/DomainsQuery.gql'
import DOMAIN_OPTIONS_QUERY from '@/graphql/Domain/DomainOptionsQuery.gql'
import REGISTRARS_QUERY from '@/graphql/Domain/RegistrarsQuery.gql'
import PROVIDERS_QUERY from '@/graphql/DomainProvider/ProvidersQuery.gql'
import RECORD_TYPES_QUERY from '@/graphql/DomainRecord/RecordTypesQuery.gql'
import ABILITY_SUMMARIES_QUERY from '@/graphql/Permission/AbilitySummariesQuery.gql'

export default {
  components: {
    DomainsTable,
    yPagination,
    yToggle
  },
  props: {},
  apollo: {
    tagOptions: {
      query: gql`
        query getYdnsTagConfigs($filters: YdnsTagConfigsFilterInput) {
          tagOptions: ydnsTagConfigs(filters: $filters) {
            id
            tag
            color
          }
        }
      `,
      variables: {
        filters: {
          type: {
            value: ['domain']
          }
        }
      }
    },
    domains: {
      query: DOMAINS_QUERY,
      variables() {
        return {
          first: this.perPage,
          page: this.page,
          filters: this.domainFilters,
          recordFilters: this.recordFilters,
          orderBy: this.$store.getters['ydns/domainsTableSortOrder'],
          trashed: this.trashedFilter.id
        }
      },
      deep: true,
      result(result) {
        this.domains = JSON.parse(JSON.stringify(result.data.domains.data))
        this.domainsPaginator = JSON.parse(
          JSON.stringify(result.data.domains.paginatorInfo)
        )
        this.$apollo.queries.abilitySummaries.refetch()
      }
    },
    abilitySummaries: {
      query: ABILITY_SUMMARIES_QUERY,
      variables() {
        return {
          input: {
            ability: 'use',
            models: {
              ids: this.domains
                .filter(domain => domain.permission)
                .map(domain => domain.id),
              class: 'domain'
            },
            role: 'publisher'
          }
        }
      },
      deep: true
    },
    registrarOptions: {
      query: REGISTRARS_QUERY,
      update: data => data.registrars,
      result(result) {
        this.registrarOptions = result.data.registrars.map(registrar => ({
          id: registrar,
          label: registrar
        }))
      }
    },
    providerOptions: {
      query: PROVIDERS_QUERY,
      update: data => data.providers
    },
    recordTypeOptions: {
      query: RECORD_TYPES_QUERY,
      update: data => data.recordTypes,
      result({ data }) {
        this.recordTypeOptions = data.recordTypes.enumValues.map(value => ({
          id: value.name,
          label: value.name
        }))
      }
    },
    typeOptions: {
      query: gql`
        {
          typeOptions: __type(name: "YdnsDomainTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.typeOptions = data.typeOptions.enumValues.map(value => ({
          id: value.name,
          label: value.name.capitalize()
        }))
      }
    },
    publisherStatusOptions: {
      query: gql`
        {
          publisherStatusOptions: __type(
            name: "RedirectDomainPublisherStatusEnum"
          ) {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.publisherStatusOptions = data.publisherStatusOptions.enumValues.map(
          value => ({ id: value.name, label: value.name.capitalize() })
        )
      }
    },
    statusOptions: {
      query: gql`
        {
          statusOptions: __type(name: "DomainStatusEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.statusOptions = data.statusOptions.enumValues.map(value => ({
          id: value.name,
          label: value.name.capitalize()
        }))
      }
    },
    serverOptions: {
      query: gql`
        query getServers($filters: YdnsServerFilterInput) {
          serverOptions: ydnsServers(filters: $filters) {
            id
            provider {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            provider: {
              value: this.filters.recordProvider.value.map(
                provider => provider.id
              )
            }
          }
        }
      },
      deep: true,
      result(result) {
        this.serverOptions = result.data.serverOptions.map(server => ({
          id: server.id,
          label: `${server.provider.name} (${server.id})`
        }))
      }
    }
  },
  data() {
    return {
      PUBLISHER_OPTIONS_QUERY,
      DOMAIN_OPTIONS_QUERY,
      trashedFilter: {
        id: 'WITHOUT',
        label: 'Active Only'
      },
      expandFilters: false,
      domains: [],
      domainsPaginator: {},
      filterDisplayMode: 'simple',
      fields: [],
      page: 1,
      perPage: 25,
      perPageOptions: [10, 25, 50, 100, 500],
      sortOrder: [],
      multiSort: false,
      isLoading: false,
      domainOptions: [],
      registrarOptions: [],
      typeOptions: [],
      publisherStatusOptions: [],
      spamhausCheckOptions: [
        {
          id: 1,
          label: 'Listed',
          value: true
        },
        {
          id: 2,
          label: 'Unlisted',
          value: false
        }
      ],
      spamhausCheck: [],
      statusOptions: [],
      publisherOptions: [],
      providerOptions: [],
      recordContentOptions: [],
      recordSubdomainOptions: [],
      serverOptions: [],
      recordTypeOptions: [],
      tagOptions: [],
      trashedOptions: [
        {
          id: 'WITHOUT',
          label: 'Active Only'
        },
        {
          id: 'ONLY',
          label: 'Trashed Only'
        },
        {
          id: 'WITH',
          label: 'Both'
        }
      ],
      filters: this.$store.getters['ydns/domainFilters']
    }
  },
  watch: {
    filters: {
      handler: function(value) {
        this.$store.dispatch('ydns/updateDomainFilters', value)
      },
      deep: true
    }
  },
  computed: {
    domainFilters() {
      let filters = JSON.parse(JSON.stringify(this.filters))
      delete filters.linkedNameServerDomains
      Object.keys(filters).map(function(name) {
        if (name == 'publisher') {
          if (filters['publisher'] && filters['publisher'].id) {
            filters['publisher'] = filters['publisher'].id
          } else {
            delete filters[name]
          }
        } else {
          let filterValues = filters[name].value.map(function(value) {
            return value.id
          })
          if (
            filterValues.length > 0 &&
            filters[name].settings.filtersDomains
          ) {
            filters[name] = {
              value: filterValues,
              modifiers: filters[name].modifiers
            }
          } else {
            delete filters[name]
          }
        }
      })

      if (this.spamhausCheck.length > 0) {
        filters['spamhausCheck'] = this.spamhausCheck[0].value
      }

      return filters
    },
    recordFilters() {
      let filters = JSON.parse(JSON.stringify(this.filters))

      Object.keys(filters).map(function(name) {
        if (name == 'publisher') {
          delete filters[name]
        } else {
          let filterValues = filters[name].value.map(function(value) {
            return value.id
          })
          let recordKey = name.replace('record', '').toLowerCase()

          if (
            filterValues.length > 0 &&
            filters[name].settings.filtersRecords
          ) {
            filters[recordKey] = {
              value: filterValues,
              modifiers: filters[name].modifiers
            }
            delete filters[name]
          } else {
            delete filters[name]
          }
        }
      })

      return filters
    }
    // gqlOrderBy() {
    //   let orderBy = JSON.parse(JSON.stringify(this.orderBy))
    //   delete orderBy.label
    //   return [orderBy]
    // }
  },
  mounted() {
    this.$events.listen('refreshYdnsDomains', this.refresh)
  },
  beforeDestroy() {
    this.$events.remove('refreshYdnsDomains')
  },
  updated() {},
  methods: {
    refresh() {
      this.$apollo.queries.domains.refetch()
      this.$apollo.queries.registrarOptions.refetch()
    },
    resetFilters() {
      this.filters = JSON.parse(
        JSON.stringify(this.$store.getters['ydns/domainFiltersDefault'])
      )
    },
    changePage(page) {
      this.page = page
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$events.emit('changeReportPage', page)
    },
    onLoading() {
      this.isLoading = true
    },
    onLoaded() {
      this.isLoading = false
    }
  }
}
</script>

<style>
.ydns-filter-radios {
  margin-bottom: -1rem;
}
</style>
