var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-6 gap-1"
  }, [_c('YInput', {
    attrs: {
      "label": "ID"
    },
    model: {
      value: _vm.filters.id.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.id, "value", $$v);
      },
      expression: "filters.id.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Contact Name"
    },
    model: {
      value: _vm.filters.name.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.name, "value", $$v);
      },
      expression: "filters.name.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Company"
    },
    model: {
      value: _vm.filters.companyName.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.companyName, "value", $$v);
      },
      expression: "filters.companyName.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.filters.contactEmail.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.contactEmail, "value", $$v);
      },
      expression: "filters.contactEmail.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "IM Handle"
    },
    model: {
      value: _vm.filters.instantMessengerHandle.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.instantMessengerHandle, "value", $$v);
      },
      expression: "filters.instantMessengerHandle.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Lead Type",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['leadType']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.leadType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.leadType, "value", $$v);
      },
      expression: "filters.leadType.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Payout Type",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['payoutType']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.payoutType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.payoutType, "value", $$v);
      },
      expression: "filters.payoutType.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Traffic Type",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['trafficType']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.trafficType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.trafficType, "value", $$v);
      },
      expression: "filters.trafficType.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Verticals",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['vertical']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.verticalTypeTags.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.verticalTypeTags, "value", $$v);
      },
      expression: "filters.verticalTypeTags.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Geos",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['geoLocation']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.geoLocation.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.geoLocation, "value", $$v);
      },
      expression: "filters.geoLocation.value"
    }
  }), _c('Superselect', {
    staticClass: "capitalize",
    attrs: {
      "title": "Priority Status",
      "multiple": false,
      "options": ['regular', 'high', 'rejected']
    },
    model: {
      value: _vm.filters.priorityStatus.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.priorityStatus, "value", $$v);
      },
      expression: "filters.priorityStatus.value"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }