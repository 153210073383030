var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_vm.title ? _c('widget-header', [_c('h4', {
    staticClass: "widget-title"
  }, [_vm._v(_vm._s(_vm.title))])]) : _vm._e(), _c('widget-body', _vm._l(_vm.times, function (_ref) {
    var label = _ref[0],
      time = _ref[1];
    return _c('div', {
      key: label,
      staticClass: "mb-4"
    }, [_c('div', {
      staticClass: "mb-1"
    }, [_c('span', {
      staticClass: "fw-500"
    }, [_vm._v(_vm._s(label))]), _vm._v(" - " + _vm._s(time - _vm.startTime > 1 ? parseFloat(time - _vm.startTime).toFixed(3) + 's' : parseFloat(time - _vm.startTime).toFixed(3) * 1000 + 'ms') + " ")]), _c('div', {
      staticClass: "progress"
    }, [_c('div', {
      staticClass: "progress-bar bg-info",
      style: `width: ${_vm.timePercentage(time)}%;`
    })])]);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }