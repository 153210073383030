var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full grid grid-cols-2 gap-1"
  }, [_c('div', [_c('YInput', {
    attrs: {
      "label": "Conversion Type"
    },
    model: {
      value: _vm.filters.conversionType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.conversionType, "value", $$v);
      },
      expression: "filters.conversionType.value"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }