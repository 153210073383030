var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('span', {
    staticClass: "upperCase",
    domProps: {
      "textContent": _vm._s(_vm.transformText)
    }
  }), _c('Icon', {
    staticClass: "ml-2",
    class: _vm.eventType == 'positive' ? 'text-blue-600' : 'text-red-600',
    attrs: {
      "name": _vm.eventType == 'positive' ? 'alertCircleOutline' : 'alertOctagon',
      "size": "6"
    }
  })], 1)]), _c('WidgetBody', [_vm.dataByEventName ? _c('YTable', {
    attrs: {
      "data": _vm.dataByEventName || []
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', {
          staticStyle: {
            "width": "175px"
          }
        }, [_c('div', {
          staticClass: "flex justify-start items-center"
        }, [_c('UserAvatar', {
          attrs: {
            "height": "8",
            "id": props.rowData.commenter.id
          }
        }), _c('span', {
          staticClass: "pl-3 text-gray-600 text-xs"
        }, [_vm._v(" " + _vm._s(_vm._f("luxon")(props.rowData.createdAt, {
          output: {
            format: 'yyyy-MM-dd'
          }
        })) + " ")])], 1)]), _c('td', [_vm._v(_vm._s(props.rowData.message))]), _c('td', {
          staticStyle: {
            "justify-content": "end"
          }
        }, [_c('span', {
          attrs: {
            "is-loading": _vm.isDeleting
          },
          on: {
            "click": function ($event) {
              return _vm.openConfirmationModal(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          staticClass: "text-red-600 hover:text-red-700 cursor-pointer",
          attrs: {
            "name": "trashCan",
            "size": "4"
          }
        })], 1)])];
      }
    }], null, false, 3275821626)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Created")]), _c('th', [_vm._v("Comment")]), _c('th')])], 2) : _vm._e(), _c('modal', {
    attrs: {
      "name": "confirmation",
      "width": 364,
      "height": 140
    }
  }, [_c('Widget', {
    staticClass: "bg-red-600 text-white border-red-700"
  }, [_c('WidgetHeader', {
    staticClass: "bg-red-600 text-white"
  }, [_c('span', {
    staticClass: "text-white"
  }, [_vm._v("Are you sure?")])]), _c('WidgetBody', [_c('h5', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v(" This action cannot be undone. ")])]), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 flex justify-end gap-6 py-2 px-4"
  }, [_c('YButton', {
    staticClass: "border hover:bg-red-500 focus:bg-red-700",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('confirmation');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    staticClass: "bg-red-700 hover:bg-red-800 border focus:bg-red-700",
    on: {
      "click": _vm.confirmationOfDelete
    }
  }, [_vm._v("Delete")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }