<template>
  <Widget>
    <WidgetHeader @close="$emit('close')">
      Subscribe User
    </WidgetHeader>
    <WidgetBody>
      <Superselect
        title="Publishers"
        v-model="publishers"
        :multiple="true"
        track-by="id"
        label="label"
        :focusOnMount="true"
        max-height="10rem"
        :query="PUBLISHERS_OPTIONS_QUERY"
        :query-variables="{
          filters: {
            label: { value: ['{input}'], modifiers: { matchType: 'contains' } }
          },
          first: 25
        }"
      ></Superselect>
      <div class="mt-4 ml-3 font-semibold">
        <YCheckbox
          id="preset-is-public-checkbox"
          v-model="disableReminderEmails"
          label="Disable Reminder Emails for this Campaign"
          :inline="true"
          class="flex items-center mr-2"
          type="info"
        ></YCheckbox>
      </div>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <YCheckbox
        class="flex items-center"
        v-model="closeOnComplete"
        label="Close on Complete"
      ></YCheckbox>
      <YButton
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="subscribe"
      >
        Subscribe</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import PUBLISHERS_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import SUBSCRIBE_USERS_TO_CAMPAIGNS_MUTATION from '@/graphql/Campaign/SubscribeUsersToCampaignsMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    campaignId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      PUBLISHERS_OPTIONS_QUERY: PUBLISHERS_OPTIONS_QUERY,
      publishers: null,
      closeOnComplete: true,
      isSubmitting: false,
      disableReminderEmails: true
    }
  },
  methods: {
    subscribe() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: SUBSCRIBE_USERS_TO_CAMPAIGNS_MUTATION,
          variables: {
            input: {
              users: this.publishers.map(publisher => publisher.id),
              campaigns: [this.campaignId],
              disableReminderEmails: this.disableReminderEmails
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('User Subscribed')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
        })
    }
  }
}
</script>

<style></style>
