<template>
  <widget>
    <widget-header @close="$emit('close')">
      Create New Contact
    </widget-header>
    <widget-body>
      <form @submit.prevent="update" ref="form" class="x-form">
        <YInput v-model="contact.name" label="User Name"></YInput>
        <YInput v-model="contact.email" label="Email"></YInput>
      </form>
    </widget-body>
    <widget-footer class="bg-gray-100 py-2 px-4 text-right">
      <y-button
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="saveAdvertiserContact"
        @keydown.enter="saveAdvertiserContact"
        >Create Account</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    user_id: {
      type: Number,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      contact: {},
      isSubmitting: false,
      colSize: 'col-xl-3 col-lg-6',
      colSize2: 'col-xl-6 col-lg-12',
      yLeadtypeOptions: ['lead', 'real', 'none']
    }
  },
  watch: {},
  computed: {},
  methods: {
    saveAdvertiserContact() {
      this.isSubmitting = true
      let input = {
        user_id: this.user_id,
        name: this.contact.name,
        email: this.contact.email,
        type: 'advertiser'
      }

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createContact($input: CreateContactInput!) {
              createContact(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: input
          }
        })
        .then(result => {
          this.clearValidationErrors()
          this.$toastr.s('', 'Advertiser Contact Created!')
          console.log(result)
          this.isSubmitting = false
          this.$events.emit('refreshAdvertiserContacts', true)
          this.$emit('close')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },

  mounted() {},
  updated() {}
}
</script>
<style scoped>
.x-form > *:not(:last-child) {
  @apply mb-2;
}
</style>
