var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    class: _vm.classes,
    attrs: {
      "href": "#"
    },
    on: {
      "click": [function ($event) {
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        return _vm.toggleValue(_vm.controllerValue);
      }, function ($event) {
        if (!$event.shiftKey) return null;
        if ($event.ctrlKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        return _vm.togglePushValue(_vm.controllerValue);
      }, function ($event) {
        if (!$event.metaKey) return null;
        if ($event.ctrlKey || $event.shiftKey || $event.altKey) return null;
        $event.preventDefault();
        _vm.toggleValue(_vm.controllerValue), _vm.submit();
      }, function ($event) {
        if (!$event.metaKey) return null;
        if (!$event.shiftKey) return null;
        if ($event.ctrlKey || $event.altKey) return null;
        $event.preventDefault();
        _vm.togglePushValue(_vm.controllerValue), _vm.submit();
      }, function ($event) {
        if (!$event.ctrlKey) return null;
        if ($event.shiftKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        _vm.toggleValue(_vm.controllerValue), _vm.submit();
      }, function ($event) {
        if (!$event.ctrlKey) return null;
        if (!$event.shiftKey) return null;
        if ($event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        _vm.togglePushValue(_vm.controllerValue), _vm.submit();
      }]
    }
  }, [_c('Icon', {
    attrs: {
      "name": "target",
      "size": _vm.size
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }