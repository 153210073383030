var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.openCreateModal
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_vm._v(" New Tag ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }