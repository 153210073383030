<template>
  <span class="badge" :class="classes">
    <slot>
      <i v-if="icon" :class="['zmdi', 'zmdi-' + icon, 'mr-1']"></i>
      <span v-if="text != ''" v-text="text"></span>
      <span v-if="html" v-html="html"></span>
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    html: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    classes() {
      let classes = []
      if (this.type) {
        classes.push('badge-' + this.type)
      } else {
        let type = null
        switch (this.text.toLowerCase()) {
          case 'active':
          case 'success':
          case 'approved':
          case 'subscribed':
          case 'admin':
            type = 'success'
            break
          case 'pending':
          case 'requested':
            type = 'info'
            break
          case 'publisher':
            type = 'primary'
            break
          case 'failed':
          case 'fraud':
          case 'inactive':
          case 'rejected':
          case 'expired':
          case 'declined':
            type = 'danger'
            break
          default:
            type = 'primary'
        }

        classes.push('badge-' + type)
      }

      return classes
    }
  },
  mounted() {},
  updated() {},
  methods: {}
}
</script>
<style scoped>
.list-group-divider .list-group-item:first-child {
  border-color: transparent;
}
.list-group-no-border .list-group-item {
  border-color: #eee;
}

.badge-purple {
  color: #fff;
}
</style>
