var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_vm.$apollo.loading ? _c('div', {
          staticClass: "w-6"
        }, [_c('Spinner', {
          attrs: {
            "color": "blue",
            "size": 6
          }
        })], 1) : _c('div', [_c('CloseX', {
          on: {
            "click": function ($event) {
              return _vm.$emit('close');
            }
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" Request Approval ")]), _c('WidgetBody', {
    staticClass: "pt-0"
  }, [this.requestLimitHit ? [_c('YAlert', {
    attrs: {
      "allow-close": false,
      "color": "yellow"
    }
  }, [_vm._v(" You have reached the maximum allowed number of campaign requests. ")])] : [_c('div', {
    staticClass: "text-gray-600 mb-2"
  }, [_vm._v(" Please describe your traffic and how you plan to promote it. ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reason,
      expression: "reason"
    }],
    staticClass: "w-full p-4 bg-gray-100 rounded",
    attrs: {
      "placeholder": "Type here..."
    },
    domProps: {
      "value": _vm.reason
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.reason = $event.target.value;
      }
    }
  })]], 2), _c('WidgetFooter', {
    staticClass: "bg-gray-100 flex justify-between px-4 py-2"
  }, [_c('div'), _c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSubmitting,
      "disabled": !_vm.canSubmit
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("Submit Request")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }