var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-sans antialiased h-screen flex"
  }, [_c('div', {
    staticClass: "flex-1 flex flex-col bg-white",
    staticStyle: {
      "background-attachment": "fixed",
      "background-size": "cover",
      "background-repeat": "no-repeat",
      "overflow-y": "scroll"
    },
    style: `background-image: url(${_vm.bgImage}); `
  }, [_c('div', {
    staticClass: "border-b w-full flex px-6 py-4 align-center items-center"
  }, [_c('div', {
    staticClass: "w-1/2 flex"
  }, [_c('portal-target', {
    attrs: {
      "name": "content-top-bar-left"
    }
  }, [_c('h3', {
    staticClass: "text-gray-800 font-extrabold flex"
  }, [_c('portal-target', {
    attrs: {
      "name": "content-top-bar-left-header-before"
    }
  }), _c('portal-target', {
    attrs: {
      "name": "content-top-bar-left-header"
    }
  }), _c('portal-target', {
    attrs: {
      "name": "content-top-bar-left-header-after"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "hidden md:flex w-1/2 justify-end"
  }, [_c('div', [_c('img', {
    staticStyle: {
      "max-width": "9rem"
    },
    attrs: {
      "src": _vm.logo
    }
  })])])]), _c('div', {
    staticClass: "w-full flex flex-col py-6 px-4 sm:px-8 md:px-12"
  }, [_c('div', {
    staticClass: "w-full m-auto"
  }, [_vm._t("default", function () {
    return [_c('router-view')];
  })], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }