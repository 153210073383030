<template>
  <y-multiselect
    :class="className"
    :value="value"
    :id="id"
    tag-placeholder="Add"
    :placeholder="placeholder"
    :label="label"
    :options="options"
    :multiple="true"
    :track-by="trackBy"
    :taggable="true"
    @tag="addTag"
    @remove="removeTag"
    @paste="processPaste"
    @input="updateValue(value)"
    :close-on-select="false"
    :max="max"
    :addTagOnBlur="true"
    :disabled="disabled"
  ></y-multiselect>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Array
    },
    id: {
      type: String
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    max: {
      type: Number
    },
    exclude: {
      type: Boolean
    },
    groupValues: {
      type: String,
      default: null
    },
    groupLabel: {
      type: String,
      default: null
    },
    trackBy: {
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    optionRequiredOnTag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    tags: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        this.updateValue(newValue)
      }
    },
    className() {
      var className = 'ymultitags'
      className = this.exclude ? className + ' exclude' : className
      className = this.disabled ? className + ' disabled' : className
      return className
    },
    computedOptions() {
      let options = []

      if (this.groupLabel && this.groupValues) {
        for (var optionsGroup of this.options) {
          for (var option of optionsGroup[this.groupValues]) {
            options.push(option)
          }
        }
      } else {
        options = this.options
      }
      return options
    }
  },

  mounted() {},

  updated() {},

  methods: {
    addTag(newTag) {
      this.tags = this.value
      let tags = this.tags ? this.tags : []
      let tagExists = false
      let tag = {}

      let tagLabel = this.label
      let tagId = this.trackBy

      if (this.trackBy) {
        tag[tagLabel] = newTag
        tag[tagId] = newTag
      } else {
        tag = newTag
      }

      // Check if options exists
      // If options exists but tag ID is not an option ID return false

      let options = this.computedOptions
      if (options.length > 0) {
        const match = options.filter(option => {
          if (this.trackBy) {
            return (
              (option.id + '').toLowerCase() == (newTag + '').toLowerCase() ||
              (option.label + '').toLowerCase() ==
                (newTag + '').toLowerCase() ||
              (option.label + '').toLowerCase().split(' - ')[1] ==
                (newTag + '').toLowerCase()
            )
          } else {
            return tag == option
          }
        })
        if (match.length > 0) {
          tag = match[0]
        } else {
          if (this.optionRequiredOnTag) {
            return false // REMOVE THIS IF YOU WANT TO ENABLE ADDING TAGS NOT IN OPTIONS LIST
          }
        }
      }

      // check if tag already exists
      for (var i = 0; i < tags.length; i++) {
        if (this.trackBy) {
          if (tags[i][tagId] == tag[tagId]) {
            tagExists = true
          }
        } else {
          if (tags[i] == tag) {
            tagExists = true
          }
        }
      }

      if (!tagExists) {
        tags.push(tag)
        this.updateValue(tags)
      }
    },
    removeTag(selectedTag) {
      var tags = this.tags
      let tagIdKey = this.trackBy ? this.trackBy : this.label

      if (selectedTag != undefined) {
        for (var i = 0; i < tags.length; i++) {
          if (
            (this.trackBy && tags[i][tagIdKey] == selectedTag[tagIdKey]) ||
            (!this.trackBy && tags[i] == selectedTag)
          ) {
            tags.splice(i, 1)
            this.tags = tags
            return true
          }
        }

        return false
      }
    },
    updateValue(newValue) {
      this.$emit('input', newValue)
    },
    processPaste(params) {
      var tags = params[0]
      // var deactivate = params[1];
      for (var tag in tags) {
        this.addTag(tags[tag])
      }
    }
  }
}
</script>
<style></style>
