<template>
  <Widget class="bg-red-600 border-red-700 text-white">
    <WidgetHeader class="bg-red-600"
      ><span class="text-white">Are you sure?</span></WidgetHeader
    >
    <WidgetBody>
      <h5 class="text-yellow-400 font-bold">This action cannot be undone.</h5>
      <h5>
        Please confirm you'd like to delete the Postback with the following
        details:
      </h5>
      <ul class="text-xs my-4">
        <li>ID: {{ this.id }}</li>
        <li>Postback: {{ this.url }}</li>
      </ul>
    </WidgetBody>

    <WidgetFooter class="bg-dark-transparent-1 flex p-4 justify-between">
      <y-button
        type="button"
        class="hover:bg-red-700 focus:bg-red-700"
        @click="$emit('close')"
        >Cancel</y-button
      >
      <y-button
        type="button"
        class="hover:bg-red-700 focus:bg-red-700"
        @click="update"
        >Delete Postback</y-button
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import DELETE_POSTBACK_MUTATION from '@/graphql/Postback/DeletePostbackMutation.gql'

export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {
    update() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: DELETE_POSTBACK_MUTATION,
          variables: {
            id: this.id
          }
        })
        .then(result => {
          this.$toastr.s('Postback Deleted', 'Success!')
          console.log(result)
          this.isSubmitting = false
          this.$events.emit('refreshPostbacks', true)
          this.$emit('close')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
