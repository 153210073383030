var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.type == 'offer' ? _c('Superselect', {
    key: "offer",
    attrs: {
      "id": _vm.id,
      "title": _vm.title,
      "options": [],
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "no-result": "Sorry, no offers found.",
      "required": _vm.required,
      "tooltip": _vm.tooltip,
      "query": _vm.OFFER_OPTIONS_QUERY,
      "query-variables": {
        first: 10,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "beforeControlIcons",
      fn: function () {
        return [_c('span', {
          staticClass: "text-xs link pt-2 pr-3",
          on: {
            "click": _vm.toggleType
          }
        }, [_vm._v("Offer")])];
      },
      proxy: true
    }, {
      key: "singleLabel",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('YImage', {
          staticClass: "rounded mr-2",
          attrs: {
            "src": props.option.imageThumbnailUrl,
            "width": 6,
            "height": 6
          }
        }), _c('div', [_c('div', [_vm._v(_vm._s(props.option.label))]), _c('div', {
          staticClass: "text-gray-700 text-xxs"
        }, [_vm._v(" " + _vm._s(props.option.encodedId) + " ")])])], 1)];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center -mx-2"
        }, [_c('YImage', {
          staticClass: "rounded mr-2",
          attrs: {
            "src": props.option.imageThumbnailUrl,
            "width": 12,
            "height": 12
          }
        }), _c('div', [_c('div', [_vm._v(_vm._s(props.option.label))]), _c('div', {
          staticClass: "text-gray-700 text-xxs"
        }, [_vm._v(" " + _vm._s(props.option.encodedId) + " ")])])], 1)];
      }
    }], null, false, 748745717),
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }) : _vm._e(), _vm.type == 'campaign' ? _c('Superselect', {
    key: "campaign",
    attrs: {
      "id": _vm.id,
      "title": _vm.title,
      "options": [],
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "no-result": "Sorry, no campaigns found.",
      "required": _vm.required,
      "tooltip": _vm.tooltip,
      "query": _vm.CAMPAIGN_OPTIONS_QUERY,
      "query-variables": {
        first: 10,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          isSmartLink: true
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "beforeControlIcons",
      fn: function () {
        return [_c('span', {
          staticClass: "text-xs link pt-2 pr-3",
          on: {
            "click": _vm.toggleType
          }
        }, [_vm._v("Campaign")])];
      },
      proxy: true
    }, {
      key: "singleLabel",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('YImage', {
          staticClass: "rounded mr-2",
          attrs: {
            "src": props.option.imageThumbnailUrl,
            "width": 6,
            "height": 6
          }
        }), _c('div', [_c('div', [_vm._v(_vm._s(props.option.label))]), _c('div', {
          staticClass: "text-gray-700 text-xxs"
        }, [_vm._v(" " + _vm._s(props.option.encodedId) + " ")])])], 1)];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center -mx-2"
        }, [_c('YImage', {
          staticClass: "rounded mr-2",
          attrs: {
            "src": props.option.imageThumbnailUrl,
            "width": 12,
            "height": 12
          }
        }), _c('div', [_c('div', [_vm._v(_vm._s(props.option.label))]), _c('div', {
          staticClass: "text-gray-700 text-xxs"
        }, [_vm._v(" " + _vm._s(props.option.encodedId) + " ")])])], 1)];
      }
    }], null, false, 3441595667),
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }) : _vm._e(), _vm.type == 'feed' ? _c('Superselect', {
    key: "feed",
    attrs: {
      "id": _vm.id,
      "title": _vm.title,
      "options": [],
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "no-result": "Sorry, no feeds found.",
      "required": _vm.required,
      "tooltip": _vm.tooltip,
      "query": _vm.OFFER_FEEDS_QUERY,
      "query-variables": {
        first: 10,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          isSmartLink: true
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('YImage', {
          staticClass: "rounded mr-2",
          attrs: {
            "src": props.option.imageThumbnailUrl,
            "width": 6,
            "height": 6
          }
        }), _c('div', [_c('div', [_vm._v(_vm._s(props.option.label))]), _c('div', {
          staticClass: "text-gray-700 text-xxs"
        }, [_vm._v(" " + _vm._s(props.option.encodedId) + " ")])])], 1)];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center -mx-2"
        }, [_c('YImage', {
          staticClass: "rounded mr-2",
          attrs: {
            "src": props.option.imageThumbnailUrl,
            "width": 12,
            "height": 12
          }
        }), _c('div', [_c('div', [_vm._v(_vm._s(props.option.label))]), _c('div', {
          staticClass: "text-gray-700 text-xxs"
        }, [_vm._v(" " + _vm._s(props.option.encodedId) + " ")])])], 1)];
      }
    }], null, false, 3372076017),
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }