<template>
  <svg
    version="1.1"
    id="L4"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 50 100"
    enable-background="new 0 0 0 0"
    xml:space="preserve"
    :width="width"
    :height="height"
    v-if="active"
    :class="className"
  >
    <circle :fill="color" stroke="none" cx="4" cy="50" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.1"
      />
    </circle>
    <circle :fill="color" stroke="none" cx="24" cy="50" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.2"
      />
    </circle>
    <circle :fill="color" stroke="none" cx="44" cy="50" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.3"
      />
    </circle>
  </svg>
</template>

<script>
export default {
  components: {},
  props: {
    active: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 25
    },
    align: {
      type: String,
      default: ''
    },
    colorHex: {
      type: String,
      default: '#25293D'
    }
  },
  data() {
    return {
      color: this.colorHex
    }
  },
  computed: {
    width() {
      return this.size
    },
    height() {
      return this.size
    },
    className() {
      let output = []
      if (this.align == 'absolute-center') {
        output.push('absolute-center')
      }
      return output
    }
  },

  mounted() {},

  updated() {},

  methods: {}
}
</script>
<style lang="css" scoped>
.absolute-center {
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
}
</style>
