<template>
  <y-table
    class="w-100 table table-sm transparency-borders table-hover m-0"
    header-class="tiny-table-header"
    :data="records"
    emptyText="No Records"
  >
    <template slot="header-row">
      <th>Type</th>
      <th>Subdomain</th>
      <th>Provider</th>
      <th>Content</th>
      <th>TTL</th>
      <!--           <th>Priority</th>   -->
      <th></th>
    </template>
    <template slot="row" slot-scope="recordProps">
      <td style="width:20px;">
        <content-editable
          v-model="records[recordProps.rowIndex].type"
        ></content-editable>
      </td>
      <td style="width: 75px;">
        <content-editable
          v-model="records[recordProps.rowIndex].subdomain"
        ></content-editable>
      </td>
      <td style="width: 150px;">
        <template
          v-if="
            recordProps.rowData.ip &&
              recordProps.rowData.ip.server &&
              recordProps.rowData.ip.server.provider
          "
        >
          {{ recordProps.rowData.ip.server.provider.name }} ({{
            recordProps.rowData.ip.server.id
          }})
        </template>
      </td>
      <td style="width: 250px;">
        <content-editable
          v-model="records[recordProps.rowIndex].content"
          style="max-width:250px; white-space: normal;"
        ></content-editable>
      </td>
      <td style="width:20px;">
        <content-editable
          v-model="records[recordProps.rowIndex].ttl"
        ></content-editable>
      </td>
      <td>
        <a href="#" @click.prevent="removeRecord(recordProps.rowData)">⛔</a>
      </td>
    </template>
  </y-table>
</template>

<script>
import yTable from '../yTable'
import ContentEditable from '../ContentEditable'

export default {
  components: {
    yTable,
    ContentEditable
  },
  props: {
    value: {
      default: () => {
        ;[]
      },
      type: Array
    }
  },
  data() {
    return {
      records: JSON.parse(JSON.stringify(this.value))
    }
  },
  watch: {
    records: {
      handler: function(newValue) {
        this.$emit('input', JSON.parse(JSON.stringify(newValue)))
      },
      deep: true
    },
    value: {
      handler: function(newValue, oldValue) {
        if (!this._.isEqual(newValue, oldValue)) {
          this.records = JSON.parse(JSON.stringify(newValue))
        }
      },
      deep: true
    }
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {
    removeRecord(record) {
      this.records = this.records.filter(existingRecord => {
        return existingRecord != record
      })
      this.$emit('removed', record)
    }
  }
}
</script>
<style scoped></style>
