<template>
  <Widget>
    <WidgetHeader class="py-6 border-b" :closeable="true" @close="close()">
      <div class="flex justify-between items-center w-full">
        <div class="flex items-center">
          <Icon name="filePlus" class="mr-2" />Add Provider
        </div>
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <YInput label="Name" v-model="provider.name" class="mb-1" />
      <Superselect
        v-model="provider.type"
        :options="typeOptions"
        label="label"
        title="Type"
        track-by="id"
        :multiple="false"
      ></Superselect>
    </WidgetBody>
    <WidgetFooter class="pt-4 pb-4 flex items-center px-4 bg-gray-100">
      <YCheckbox v-model="closeOnComplete" label="Close on Complete" class="items-center flex"></YCheckbox>
      <YButton
        :is-loading="isSubmitting"
        color="blue"
        class="ml-auto"
        @click="create"
        @keydown.enter="create"
      >Add</YButton>
    </WidgetFooter>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrors from '@/assets/js/ValidationErrors'

export default {
  props: {},
  components: {},
  apollo: {
    typeOptions: {
      query: gql`
        query {
          typeOptions: __type(name: "YdnsProviderTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.typeOptions = data.typeOptions.enumValues.map(enumValue => ({
          id: enumValue.name,
          label: enumValue.name.capitalize()
        }))
        this.provider.type = this.provider.type
          ? this.provider.type
          : this.typeOptions[0]
      }
    }
  },
  data() {
    return {
      closeOnComplete: true,
      isSubmitting: false,
      provider: {
        type: null,
        name: null
      },
      typeOptions: [],
      validationErrors: {}
    }
  },
  watch: {},
  computed: {},
  methods: {
    create() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation YdnsCreateProvider($input: YdnsCreateProviderInput!) {
              ydnsCreateProvider(input: $input) {
                id
                name
                type
              }
            }
          `,
          variables: {
            input: {
              name: this.provider.name,
              type: this.provider.type.id
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('Provider Added', 'Success!')
          this.$events.emit('refreshYdnsProviders')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false

          // this.validationErrors = error.extentions.validation ? error.extentions.validation : null;
          let errors = new ValidationErrors()
          errors.extractErrorsFrom(error)
          this.validationErrors = errors.errors

          // if (Object.entries(this.errors).length <= 0) {
          //   toastr.error(error, "Oops!");
          // }
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
