var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full grid grid-cols-2 gap-1"
  }, [_c('div', [_c('YInput', {
    attrs: {
      "label": "Tag"
    },
    model: {
      value: _vm.filters.tag.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.tag, "value", $$v);
      },
      expression: "filters.tag.value"
    }
  })], 1), _c('div', [_c('SuperSelect', {
    attrs: {
      "title": "Type",
      "multiple": false,
      "options": _vm.tagOptions
    },
    model: {
      value: _vm.filters.type.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.type, "value", $$v);
      },
      expression: "filters.type.value"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }