<template>
  <div v-observe-visibility="visibilityChanged" class="mt-4">
    <div class="mb-2 mt-2">
      <YInput
        class="mb-1"
        label="Login"
        v-model="localUser.email"
        :readonly="true"
        :disabled="true"
      />
      <YInput
        class="mb-1"
        label="Name"
        v-model="localUser.primaryContact.name"
      />
      <YInput class="mb-1" label="Password" v-model="localUser.password" />
      <YInput class="mb-1" label="Confirm Password" v-model="confirm" />
      <!-- <YInput
        class="mb-1"
        label="Company"
        v-model="localUser.primaryContact.company"
        :readonly="true"
      /> -->
      <YInput
        label="Contact Email"
        v-model="localUser.primaryContact.email"
        class="mb-1"
      />

      <div class="flex mb-1">
        <Superselect
          title="App"
          v-model="messengerApp"
          :multiple="false"
          :options="['telegram', 'skype']"
          class="flex-shrink"
          select-class="border-r-0 rounded-r-none"
        ></Superselect>
        <YInput
          class="flex-grow"
          input-class="rounded-l-none border-l-0"
          label="Messenger Handle"
          v-model="messengerHandle"
        />
      </div>

      <!-- <YInput
        class="mb-1"
        label="Phone"
        v-model="localUser.primaryContact.phone"
      />
      <YInput
        class="mb-1"
        label="Address"
        v-model="localUser.primaryContact.address"
      />
      <YInput
        class="mb-1"
        label="Address 2"
        v-model="localUser.primaryContact.address2"
      />
      <YInput
        class="mb-1"
        label="City"
        v-model="localUser.primaryContact.city"
      />
      <YInput
        class="mb-1"
        label="Region"
        v-model="localUser.primaryContact.region"
      />
      <Superselect
        title="Country"
        v-model="localUser.primaryContact.country"
        :multiple="false"
        track-by="code"
        label="label"
        :options="countries"
        class="mb-1"
      ></Superselect>
      <YInput
        class="mb-1"
        label="Postal Code"
        v-model="localUser.primaryContact.postalCode"
      /> -->
    </div>
    <ValidationErrors
      v-if="validationErrors && isVisible"
      :errors="validationErrors"
    ></ValidationErrors>
    <portal to="top-bar-right" v-if="hasChanges && isVisible">
      <YButton @click="update()" color="green">Update Primary Contact</YButton>
    </portal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  apollo: {
    countries: {
      query: gql`
        {
          countries {
            code
            name
            label
          }
        }
      `
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localUser: JSON.parse(JSON.stringify(this.user)),
      isVisible: true,
      isUpdating: false,
      countries: [],
      confirm: null
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
    },
    update() {
      if (
        this.localUser.password != null &&
        this.localUser.password !== this.confirm
      ) {
        console.log(this.localUser.password)
        console.log(this.localUser.confirm)
        this.$toastr.e('New password and confirmation do not match', 'Oops!')
      } else if (
        this.localUser.password != null &&
        this.localUser.password.length < 8
      ) {
        this.$toastr.e('New password must be 8 or more chars', 'Oops!')
      } else {
        this.validationErrors = null
        this.isUpdating = true
        this.$store.dispatch('updateGlobalLoader', 1)
        let input = {
          id: this.user.id,
          contacts: {
            update: [
              {
                id: this.user.primaryContact.id,
                name: this.localUser.primaryContact.name,
                email: this.localUser.primaryContact.email,
                address: this.localUser.primaryContact.address,
                address2: this.localUser.primaryContact.address2,
                phone: this.localUser.primaryContact.phone,
                city: this.localUser.primaryContact.city,
                region: this.localUser.primaryContact.region,
                countryCode: this.localUser.primaryContact.country.code,
                postalCode: this.localUser.primaryContact.postalCode,
                messenger: this.localUser.primaryContact.messenger,
                company: this.localUser.primaryContact.company
              }
            ]
          }
        }

        if (this.localUser.password != null || this.localUser.password != '') {
          input = {
            id: this.user.id,
            password: this.localUser.password,
            contacts: {
              update: [
                {
                  id: this.user.primaryContact.id,
                  name: this.localUser.primaryContact.name,
                  email: this.localUser.primaryContact.email,
                  address: this.localUser.primaryContact.address,
                  address2: this.localUser.primaryContact.address2,
                  phone: this.localUser.primaryContact.phone,
                  city: this.localUser.primaryContact.city,
                  region: this.localUser.primaryContact.region,
                  countryCode: this.localUser.primaryContact.country.code,
                  postalCode: this.localUser.primaryContact.postalCode,
                  messenger: this.localUser.primaryContact.messenger,
                  company: this.localUser.primaryContact.company
                }
              ]
            }
          }
        }

        this.$apollo
          .mutate({
            // Query
            mutation: UPDATE_USER_MUTATION,
            // Parameters
            variables: {
              input: input
            }
          })
          .then(() => {
            // Result
            this.isUpdating = false
            this.$store.dispatch('updateGlobalLoader', -1)
            this.$toastr.s('', 'Details Updated')
          })
          .catch(error => {
            this.isUpdating = false
            this.$store.dispatch('updateGlobalLoader', -1)
            this.setValidationErrors(error)
          })
      }
    }
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.localUser) != JSON.stringify(this.user)
    },
    messengerApp: {
      // getter
      get: function() {
        return this.localUser.primaryContact.messenger
          ? JSON.parse(this.localUser.primaryContact.messenger).app
          : null
      },
      // setter
      set: function(newValue) {
        this.localUser.primaryContact.messenger = JSON.stringify({
          handle: this.messengerHandle,
          app: newValue
        })
      }
    },
    messengerHandle: {
      // getter
      get: function() {
        return this.localUser.primaryContact.messenger
          ? JSON.parse(this.localUser.primaryContact.messenger).handle
          : null
      },
      // setter
      set: function(newValue) {
        this.localUser.primaryContact.messenger = JSON.stringify({
          handle: newValue,
          app: this.messengerApp
        })
      }
    }
  }
}
</script>

<style></style>
