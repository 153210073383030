<template>
  <div>
    <template v-if="me">
      <portal to="admin-alerts">
        <YAlert
          v-if="isImpersonating && routeScope == 'admin' && me.isAPublisher"
          :allow-close="false"
          color="red"
        >
          <div class="flex justify-between items-center">
            <div>
              You are currently impersonating <strong>{{ me.email }}</strong> on
              an <strong>ADMIN PAGE</strong>. This page will not function
              properly until impersonation has ended.
            </div>
            <EndImpersonate :user-id="prevAuthId"
              ><YButton color="red">End Impersonation</YButton></EndImpersonate
            >
          </div>
        </YAlert>
      </portal>
      <portal to="publisher-alerts">
        <YAlert
          v-if="
            !isImpersonating &&
              routeScope == 'publisher' &&
              me.isAnAdmin &&
              prevAuthId
          "
          :allow-close="false"
          color="red"
        >
          <div class="flex justify-between items-center">
            <div>
              You are no longer impersonating
              <strong>User {{ prevAuthId }}</strong
              >. This page will not function properly.
              <router-link
                :to="{ name: 'Dashboard' }"
                class="link font-bold ml-4"
                >Return to Admin Section</router-link
              >
            </div>
            <div>
              <Impersonate
                :user-id="prevAuthId"
                :path="$route.path"
                class="ml-4"
                ><YButton color="red">Re-Impersonate</YButton></Impersonate
              >
            </div>
          </div>
        </YAlert>
      </portal>
    </template>
    <portal to="alerts">
      <YAlert
        v-if="!isAuthenticated && !routeIsPublic"
        :allow-close="false"
        color="yellow"
      >
        <div class="flex items-center justify-between">
          <div>
            You are no longer logged in.
          </div>
          <div>
            <router-link :to="{ name: 'Login' }"
              ><YButton color="yellow">Go To Login</YButton></router-link
            >
          </div>
        </div>
      </YAlert>
    </portal>
  </div>
</template>

<script>
import ME_QUERY from '@/graphql/User/MeQuery.gql'
import Impersonate from '@/views/Auth/Impersonate.vue'
import EndImpersonate from '@/views/Auth/EndImpersonate.vue'

export default {
  components: {
    Impersonate,
    EndImpersonate
  },
  apollo: {
    me: {
      query: ME_QUERY,
      result({ data }) {
        if (!data) {
          console.error('Auth data not found')
          if (!this.routeIsPublic) {
            this.$router.push({ name: 'Login' })
          }
        }

        this.$emit('update', data.me)
        if (data.me.isAnAdmin) {
          this.reconnectDeadWebsocket()
        } else {
          this.closeWebSocket()
        }
        this.handleRedirects()
      }
    }
  },
  data() {
    return {
      me: null,
      prevAuthId: null
    }
  },
  created() {
    window.addEventListener('focus', this.onWindowFocus)
  },
  mounted() {
    this.$events.listen('refetchMe', () => {
      this.$apollo.queries.me.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refetchMe')
    window.removeEventListener('focus', this.onWindowFocus)
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    isImpersonating() {
      return this.$store.getters.isImpersonating
    },
    routeIsPublic() {
      return this.$route.meta?.scope === 'public'
    },
    authId() {
      return this.$store.getters.authId
    },
    routeScope() {
      return this.$route.meta.scope
    }
  },
  watch: {
    authId: function(newId, oldId) {
      if (newId != oldId) {
        this.prevAuthId = oldId
        this.$apollo.queries.me.refetch()
      }
    },
    isAuthenticated(newValue) {
      if (newValue == false) {
        this.closeWebSocket()
      }
    }
  },
  methods: {
    openWebSocket() {
      if (window.pusherLink) {
        window.pusherLink.pusher.connect()
      }
    },
    closeWebSocket() {
      if (window.pusherLink) {
        window.pusherLink.pusher.disconnect()
      }
    },
    reconnectDeadWebsocket() {
      if (window.pusherLink.pusher.connection.state !== 'connected') {
        this.closeWebSocket()
        this.openWebSocket()
      }
    },
    onWindowFocus() {
      if (this.me?.isAnAdmin) {
        this.reconnectDeadWebsocket()
      }
      if (this.authId != localStorage.getItem('authId')) {
        // Auth is out of sync
        this.resyncAuth()
      }
    },
    resyncAuth() {
      this.$store.dispatch('refetchAuthFromStorage') // refetch proper auth right away
    },
    handleRedirects() {
      if (
        this.me.isAPublisher &&
        this.routeScope === 'admin' &&
        !this.isImpersonating
      ) {
        this.$router.push({ name: 'Publisher Dashboard' }) // redirect real publishers if they ever try hitting an admin route
      }
      if (
        this.me.isAnAdmin &&
        this.routeScope === 'publisher' &&
        !this.prevAuthId
      ) {
        this.$router.push({ name: 'Dashboard' })
      }
    }
  }
}
</script>

<style></style>
