<template>
  <span :class="classes">
    <template v-if="actionsState == 'default'">
      <a @click="edit" class="editable-hover">
        <slot></slot>
      </a>
      <editable-actions
        :state="actionsState"
        @edit="edit"
        @submit="submit"
        @cancel="cancel"
        :edit-action-display-type="editActionDisplayType"
      ></editable-actions>
    </template>
    <template v-if="actionsState == 'edit'">
      <y-form-group :error-strings="errors.type" class="mb-1">
        <y-form-group :error-strings="errors.verticalId" class="mb-1">
          <multiselect
            v-model="value"
            :options="options"
            placeholder="Select One"
            :show-labels="false"
            :allow-empty="false"
            :loading="$apollo.loading"
            @input="submit"
          ></multiselect>
        </y-form-group>
      </y-form-group>
      <editable-actions
        :submitting="isSubmitting"
        :state="actionsState"
        @edit="edit"
        @submit="submit"
        @cancel="cancel"
      ></editable-actions>
    </template>
  </span>
</template>

<script>
import EditableMixin from '@/components/legacy/Editables/EditableMixin.js'
import ADVERTISER_ACCOUNT_QUERY from '@/graphql/AdvertiserAccount/AdvertiserAccountQuery.gql'
import UPDATE_ADVERTISER_ACCOUNT_MUTATION from '@/graphql/AdvertiserAccount/UpdateAdvertiserAccountMutation.gql'

export default {
  mixins: [EditableMixin],
  apollo: {
    advertiserAccount: {
      query: ADVERTISER_ACCOUNT_QUERY,
      variables() {
        return {
          id: this.id
        }
      },
      result(result) {
        this.value = result.data.advertiserAccount.yleadType
      },
      fetchPolicy: 'network-only',
      skip() {
        return this.actionsState != 'edit'
      }
    }
  },
  data() {
    return {
      mutation: UPDATE_ADVERTISER_ACCOUNT_MUTATION,
      options: ['lead', 'real', 'none']
    }
  },
  computed: {
    mutationVariables() {
      return {
        input: {
          id: this.id,
          yleadType: this.value
        }
      }
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
