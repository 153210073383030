<template>
  <div>
    <y-radio
      label="Add IP Records"
      name="add-records-mode"
      radio-value="ip"
      v-model="addRecordsMode"
      color="info"
      class="mb-2"
    >
    </y-radio>
    <div v-if="addRecordsMode == 'ip'" class="ml-4 mb-5">
      <ip-select-helper
        class="mb-1"
        ref="ipSelectHelper"
        @select="
          ips => {
            this.selectedIps = ips
          }
        "
        :domain-type="domainType"
      >
      </ip-select-helper>
      <superselect
        title="Select Subdomains"
        v-model="selectedSubdomains"
        :forceEdit="true"
        edit-placeholder="Enter Subdomain"
        track-by="id"
        label="id"
      >
      </superselect>
    </div>
    <!--     <y-radio 
      label="Add Custom Record" 
      name="add-records-mode" 
      radio-value="custom" 
      color="info"
      v-model="addRecordsMode">
    </y-radio>
    <div v-if="addRecordsMode == 'custom'">

    </div> -->
    <input type="hidden" :value="records" />
  </div>
</template>

<script>
// import ValidationErrors from '@/assets/js/ValidationErrors'
import yRadio from '../yRadio'
import IpSelectHelper from './IpSelectHelper'
import { uuid } from 'vue-uuid'

export default {
  props: {
    domains: {
      type: Array,
      default: null
    },
    domainType: {
      type: String,
      default: null
    }
  },
  components: {
    yRadio,
    IpSelectHelper
  },
  apollo: {},
  data() {
    return {
      addRecordsMode: 'ip',
      selectedSubdomains: [{ id: '*' }],
      selectedIps: null,
      recordTemplate: {
        subdomain: null,
        domain: null,
        type: null,
        content: null,
        ip: null,
        ttl: 300
      }
    }
  },
  watch: {},
  computed: {
    records() {
      let records = []
      if (this.addRecordsMode == 'ip' && this.selectedIps) {
        if (this.domains) {
          this.domains.forEach(domain => {
            // when passing in domains multiply the records per domain
            this.recordsWithoutDomain.forEach(record => {
              record.domain = domain
              records = [...records, record]
            })
          })
        } else {
          records = this.recordsWithoutDomain
        }
      }
      this.$emit('update', records)
      return records
    },
    recordsWithoutDomain() {
      let records = []
      this.selectedIps.forEach(ip => {
        let rootDomainRecord = Object.assign({}, this.recordTemplate, {
          type: ip.version == 'ipv6' ? 'AAAA' : 'A',
          content: ip.id,
          ip: ip,
          uniqueKey: uuid.v4()
        })
        records = [...records, rootDomainRecord]
        this.selectedSubdomains.forEach(subdomain => {
          let subdomainRecord = Object.assign({}, rootDomainRecord, {
            subdomain: subdomain.id,
            uniqueKey: uuid.v4()
          })
          records = [...records, subdomainRecord]
        })
      })
      return records
    },
    randomIp() {
      if (!this.ipOptions) {
        return null
      }
      return this.ipOptions[Math.floor(Math.random() * this.ipOptions.length)]
    },
    selectOutput() {
      let output =
        this.useRandomIp && this.randomIp ? [this.randomIp] : this.selectedIps

      output = JSON.parse(JSON.stringify(output))

      if (output && this.mapperFunction) {
        output = output.map(this.mapperFunction)
      }
      this.$emit('select', output)
      return output
    }
  },
  methods: {
    getRecordForIps() {},
    clear() {
      this.$refs.ipSelectHelper.clear()
      // ALSO ADD CLEAR FOR CUSTOM
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
