var render = function render(){
  var _vm$imageFileComputed, _vm$imageFileComputed2, _vm$imageFileComputed3, _vm$imageFileComputed4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.campaign ? _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_vm.campaign.legacyImageUrl ? _c('div', {
    staticClass: "mr-4 hidden sm:block"
  }, [_c('EditableImage', {
    staticClass: "rounded mr-2x",
    attrs: {
      "imageUrl": _vm.campaign.legacyImageUrl,
      "thumbnailUrl": _vm.campaign.legacyImageThumbnailUrl,
      "height": 12,
      "width": 12,
      "blankHeight": 12,
      "blankWidth": 12,
      "editable-only-when-empty": true,
      "modelId": _vm.campaign.id,
      "deleteExisting": true,
      "edit-focus-icon-enabled": false,
      "editable": false,
      "modelClass": "campaign"
    }
  })], 1) : _c('div', {
    staticClass: "mr-4 hidden sm:block"
  }, [_c('EditableImage', {
    staticClass: "rounded mr-2x",
    attrs: {
      "imageUrl": ((_vm$imageFileComputed = _vm.imageFileComputed) === null || _vm$imageFileComputed === void 0 ? void 0 : _vm$imageFileComputed.original) || null,
      "thumbnailUrl": ((_vm$imageFileComputed2 = _vm.imageFileComputed) === null || _vm$imageFileComputed2 === void 0 ? void 0 : _vm$imageFileComputed2.imageThumbnailUrl) || null,
      "height": 12,
      "width": 12,
      "blankHeight": 12,
      "blankWidth": 12,
      "modelId": _vm.campaign.id,
      "editable": false,
      "modelClass": "campaign"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.campaign.name) + " "), _c('small', {
    staticClass: "text-gray-600 font-sm"
  }, [_c('sup', [_vm._v("#")]), _vm._v(" " + _vm._s(_vm.campaign.id) + " ")])])]), _c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "flex-grow w-1/2 lg:w-full"
  }, [_c('div', {
    staticClass: "flex flex-wrap flex-row-reverse sm:items-center mb-4"
  }, [_vm.campaign.status ? _c('div', {
    staticClass: "ml-auto flex-shrink"
  }, [_c('div', {
    staticClass: "ts-4 text-right"
  }, [_c('CampaignStatusToggleButton', {
    attrs: {
      "campaign": _vm.campaign
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "flex items-center mr-auto"
  }, [_vm.campaign.legacyImageUrl ? _c('div', {
    staticClass: "mr-4 hidden sm:block"
  }, [_c('EditableImage', {
    staticClass: "rounded mr-2x",
    attrs: {
      "imageUrl": _vm.campaign.legacyImageUrl,
      "thumbnailUrl": _vm.campaign.legacyImageThumbnailUrl,
      "height": 48,
      "width": 48,
      "blankHeight": 36,
      "blankWidth": 36,
      "editable-only-when-empty": true,
      "modelId": _vm.campaign.id,
      "deleteExisting": true,
      "edit-focus-icon-enabled": false,
      "editable": false,
      "modelClass": "campaign"
    }
  })], 1) : _c('div', {
    staticClass: "mr-4 hidden sm:block"
  }, [_c('EditableImage', {
    staticClass: "rounded mr-2x",
    attrs: {
      "imageUrl": ((_vm$imageFileComputed3 = _vm.imageFileComputed) === null || _vm$imageFileComputed3 === void 0 ? void 0 : _vm$imageFileComputed3.original) || null,
      "thumbnailUrl": ((_vm$imageFileComputed4 = _vm.imageFileComputed) === null || _vm$imageFileComputed4 === void 0 ? void 0 : _vm$imageFileComputed4.imageThumbnailUrl) || null,
      "height": 48,
      "width": 48,
      "blankHeight": 36,
      "blankWidth": 36,
      "modelId": _vm.campaign.id,
      "editable": false,
      "modelClass": "campaign"
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "px-2"
  }, [_c('h3', {
    staticClass: "mb-0 text-lg font-bold"
  }, [_c('EditableValue', {
    ref: "editableCampaignName",
    staticClass: "mr-2",
    attrs: {
      "type": "contentEditable",
      "value": _vm.campaign.name,
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "variables": {
        input: {
          id: _vm.campaign.id,
          name: '{value}'
        }
      },
      "placeholder": "No Campaign Name",
      "edit-focus-icon-enabled": false
    }
  }), _c('small', {
    staticClass: "text-gray-600 font-sm"
  }, [_c('sup', [_vm._v("#")]), _vm._v(" " + _vm._s(_vm.campaign.id) + " ")])], 1), _c('div', {
    staticClass: "flex items-center mt-2"
  }, [_c('EditableValue', {
    attrs: {
      "value": _vm.campaign.payoutType,
      "type": "slot",
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "variables": {
        input: {
          id: _vm.campaign.id,
          payoutType: '{value}'
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (slotProps) {
        return [_vm.campaign.isCapped ? _c('Tag', {
          staticClass: "mr-1",
          attrs: {
            "color": "darkRed"
          }
        }, [_vm._v("CAPPED")]) : _vm._e(), _c('Tag', {
          attrs: {
            "icon": "currencyUsd",
            "color": "purple",
            "tabindex": "0"
          },
          on: {
            "focus": function () {
              slotProps.focus();
            }
          }
        }, [_vm._v(_vm._s(slotProps.value))])];
      }
    }, {
      key: "focusSlot",
      fn: function (slotProps) {
        return [_c('Superselect', {
          staticStyle: {
            "min-width": "10rem"
          },
          attrs: {
            "title": "Payout Type",
            "value": {
              id: slotProps.value,
              label: slotProps.value.toUpperCase()
            },
            "focusOnMount": true,
            "multiple": false,
            "placeholder": "Select",
            "track-by": "id",
            "label": "label",
            "allow-empty": false,
            "query": _vm.INTROSPECTION_TYPE_QUERY,
            "query-variables": {
              name: 'CampaignPayoutTypeEnum'
            },
            "query-result-function": function (data) {
              return data.__type.enumValues.map(function (value) {
                return {
                  id: value.name,
                  label: value.name.toUpperCase()
                };
              });
            }
          },
          on: {
            "input": function (value) {
              slotProps.updateInternalValue(value.id);
            },
            "close": function () {
              slotProps.unfocusOnNoChanges() || slotProps.focusOnSave();
            }
          }
        })];
      }
    }], null, false, 1026773045)
  })], 1)]), _c('div', {
    staticClass: "my-3"
  }, [_c('EditableValue', {
    ref: "descriptionEditable",
    attrs: {
      "value": _vm.campaign.description,
      "mutation": _vm.UPDATE_CAMPAIGN_MUTATION,
      "input-style": "min-width: 10rem; max-width: 100%;",
      "type": "richText",
      "variables": {
        input: {
          id: _vm.campaign.id,
          description: '{value}'
        }
      }
    }
  })], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('Tabs', {
    attrs: {
      "show-type": "show",
      "route": _vm.tabRoute
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Details",
      "active": true
    }
  }, [_c('CampaignDetails', {
    attrs: {
      "id": _vm.id
    }
  })], 1), !_vm.campaign.smartLink ? _c('Tab', {
    attrs: {
      "name": "Offers",
      "tag": _vm.campaign && _vm.campaign.offers ? _vm.campaign.offers.length : undefined,
      "tag-color": "blue"
    }
  }, [_c('CampaignOffers', {
    attrs: {
      "campaignId": _vm.campaign.id
    }
  })], 1) : _vm._e(), _vm.campaign.smartLink ? _c('Tab', {
    attrs: {
      "name": "Prelander"
    }
  }, [_c('CampaignPrelander', {
    attrs: {
      "campaignId": _vm.campaign.id
    }
  })], 1) : _vm._e(), _c('Tab', {
    attrs: {
      "name": "Stats"
    }
  }, [_c('simple-report', {
    staticClass: "col-span-4 mt-4",
    attrs: {
      "filters": _vm.reportFilters,
      "fields": ['day', 'visits', 'revenue', 'conversions', 'payout', 'netRevenue']
    }
  }, [_c('h4', {
    staticClass: "widget-title",
    attrs: {
      "slot": "table-header"
    },
    slot: "table-header"
  }, [_vm._v(" Daily Summary ")])])], 1), _c('Tab', {
    attrs: {
      "name": "Payouts"
    }
  }, [_vm.campaign && _vm.campaign.payouts ? _c('Payouts', {
    attrs: {
      "payouts": _vm.campaign.payouts,
      "campaignId": parseInt(_vm.id),
      "level": "campaign"
    }
  }) : _vm._e()], 1), _c('Tab', {
    attrs: {
      "name": "Daypart"
    }
  }, [_c('Dayparting', {
    attrs: {
      "daypartable-id": _vm.campaign.id,
      "daypartable-type": "Campaign"
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Redirects"
    }
  }, [_c('Redirects', {
    attrs: {
      "overrideFilters": {
        campaign: {
          value: [{
            id: _vm.campaign.id
          }]
        }
      }
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Caps"
    }
  }, [_c('UpdateCampaignFallback', {
    staticClass: "px-1 pt-3 bg-gray-100",
    attrs: {
      "id": _vm.id,
      "fallbackCampaignId": _vm.campaign.fallbackCampaignId,
      "fallbackOfferId": _vm.campaign.fallbackOfferId
    }
  }), _vm.id ? _c('Caps', {
    attrs: {
      "cappableId": _vm.id,
      "cappableType": "Campaign",
      "fallbackCampaignId": _vm.campaign && _vm.campaign.fallbackCampaignId
    }
  }) : _vm._e()], 1), _c('Tab', {
    attrs: {
      "name": "Cap Defaults"
    }
  }, [_vm.id ? _c('CapDefaults', {
    attrs: {
      "cappableId": _vm.id,
      "cappableType": "Campaign",
      "triggerableType": "Redirect"
    }
  }) : _vm._e()], 1), _c('Tab', {
    attrs: {
      "name": "Action Scubs"
    }
  }, [_vm.id ? _c('ActionScrubs', {
    attrs: {
      "campaignId": _vm.id,
      "defaultScrub": _vm.campaign.aSPercentage
    }
  }) : _vm._e()], 1), _c('Tab', {
    attrs: {
      "name": "Rules"
    }
  }, [_c('RulesTableWrapper', {
    attrs: {
      "default-filter-values": {
        campaigns: [_vm.campaign]
      },
      "hide-filters": ['types', 'redirects', 'campaigns', 'publishers', 'sources']
    },
    scopedSlots: _vm._u([{
      key: "beforeTable",
      fn: function () {
        return [_c('div', {
          staticClass: "flex justify-between items-center mt-4 px-4 py-4 rounded-lg bg-gray-200"
        }, [_c('div', {
          staticClass: "text-muted"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.selectedCount) + " " + _vm._s(_vm.selectedCount == 1 ? 'item' : 'items') + " selected ")])]), _c('div', {
          staticClass: "flex gap-4"
        }, [_c('YButton', {
          attrs: {
            "color": "red",
            "disabled": !_vm.rowsAreSelected
          },
          on: {
            "click": _vm.openDeleteRulesModal
          }
        }, [_vm._v("Delete")]), _c('YButton', {
          attrs: {
            "color": "blue",
            "disabled": _vm.rowsAreSelected
          },
          on: {
            "click": _vm.openCreateRulesModal
          }
        }, [_vm._v("Create Rules")])], 1)])];
      },
      proxy: true
    }], null, false, 704410060)
  })], 1)], 1)], 1)])]), _c('portal', {
    attrs: {
      "to": "sidebar-right"
    }
  }, [_c('SideBarRightItem', {
    staticClass: "flex-1",
    attrs: {
      "title": "Top Publishers",
      "icon": "chartLine"
    }
  }, [_c('CampaignTopPublisherStats', {
    attrs: {
      "campaignId": parseInt(_vm.campaign.id)
    }
  })], 1)], 1)], 1)], 1) : _c('div', [_vm.$apollo.queries.campaign.loading ? _c('Spinner', {
    attrs: {
      "size": 12,
      "color": "green"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }