var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "pt-4 pb-4"
  }, [_c('div', {
    staticClass: "flex justify-between items-center w-full"
  }, [_c('Icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "videoInputAntenna"
    }
  }), _vm._v(" Replace IP ")], 1)]), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('div', [_vm._v(" Click "), _c('span', {
    staticClass: "text-blue-600 font-semibold"
  }, [_vm._v("Replace")]), _vm._v(" to automatically replace "), _c('span', {
    staticClass: "text-blue-600 font-semibold"
  }, [_vm._v(_vm._s(_vm.ip))])])]), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('y-checkbox', {
    staticClass: "flex items-center",
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    staticClass: "ml-auto",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "color": "blue"
    },
    on: {
      "click": _vm.submit,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm._v("Replace")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }