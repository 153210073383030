<template>
  <button
    class="btn"
    :class="[color ? 'btn-' + color : '']"
    style="position: relative;"
    :disabled="isDisabled"
    @click="$emit('click', $event)"
  >
    <span v-show="loading" class="top-0 left-0 absolute h-full w-full flex">
      <Spinner class="w-8 h-8 m-auto spin-animation block" />
    </span>
    <span :class="slotClass"><slot></slot></span>
  </button>
</template>

<script>
export default {
  components: {},
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    status: {
      type: [String, Boolean],
      default: () => ''
    },
    color: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localLoader: false
    }
  },
  computed: {
    slotClass() {
      return this.loading ? 'invisible' : ''
    },
    loading() {
      return this.isLoading || this.localLoader
    },
    isDisabled() {
      return this.loading || this.disabled
    }
  },

  mounted() {},

  updated() {},

  methods: {
    preventDefault() {}
  }
}
</script>
<style lang="css" scoped>
.invisible {
  visibility: hidden;
}
.icon-container {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
}

.btn-white-transparent {
  background: rgba(255, 255, 255, 0.8);
}

.bg-danger .btn-white:hover {
  box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.3);
}
</style>
