export const namespaced = true

export const state = {
  filters: {
    types: {
      value: null,
      modifiers: {},
      title: 'Types',
      type: 'select'
    },
    publishers: {
      value: null,
      modifiers: {},
      title: 'Publishers',
      type: 'select'
    },
    campaigns: {
      value: null,
      modifiers: {},
      title: 'Campaigns',
      type: 'select'
    },
    redirects: {
      value: null,
      modifiers: {},
      title: 'Redirects',
      type: 'select'
    },
    sources: {
      value: null,
      modifiers: {},
      title: 'Sources',
      type: 'input'
    },
    countries: {
      value: null,
      modifiers: {},
      title: 'Countries',
      type: 'tagSelect'
    },
    mobileDesktop: {
      value: null,
      modifiers: {},
      title: 'Mobile / Desktop',
      type: 'tagSelect'
    },
    filterTypes: {
      value: null,
      title: 'Filter Types',
      type: 'select'
    },
    offers: {
      value: null,
      modifiers: {},
      title: 'Offers',
      type: 'select'
    },
    advertisers: {
      value: null,
      modifiers: {},
      title: 'Advertisers',
      type: 'select'
    },
    advertiserAccounts: {
      value: null,
      modifiers: {},
      title: 'Advertiser Accounts',
      type: 'select'
    },
    verticals: {
      value: null,
      modifiers: {},
      title: 'Verticals',
      type: 'select'
    },
    templates: {
      value: null,
      modifiers: {},
      title: 'Templates',
      type: 'select'
    }
  },
  cacheValid: null,
  selectedRowIds: []
}

export const getters = {}

export const mutations = {
  UPDATE_CACHE_VALIDITY(state, payload) {
    state.cacheValid = payload
  },
  SET_SELECTED_ROW_IDS(state, selectedRowIds) {
    state.selectedRowIds = selectedRowIds
  },
  RESET_SELECTED_ROW_IDS(state) {
    state.selectedRowIds = []
  }
}

export const actions = {
  validateCache({ commit }) {
    commit('UPDATE_CACHE_VALIDITY', true)
  },
  invalidateCache({ commit }) {
    commit('UPDATE_CACHE_VALIDITY', false)
  },
  updateSelectedRowIds({ commit }, selectedRowIds) {
    commit('SET_SELECTED_ROW_IDS', selectedRowIds)
  }
}
