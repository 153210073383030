<template>
  <span @click="confirm">
    <slot></slot>
  </span>
</template>

<script>
import YConfirmModal from '@/components/legacy/yConfirmModal.vue'
export default {
  props: {
    message: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'danger'
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    config() {
      let config = {}
      if (this.type == 'danger') {
        config = {
          borderClass: 'border-red-700',
          backgroundClass: 'bg-red-600',
          backgroundClassDarker: 'bg-red-700',
          backgroundClassDarkest: 'bg-red-800',
          textColorClass: 'text-white',
          colorHex: '#FFF',
          widgetFooterClass: 'bg-red-700'
        }
      }
      return config
    }
  },
  methods: {
    confirm() {
      this.$modal.show(
        YConfirmModal,
        {
          onConfirm: () => {
            this.onConfirm()
          },
          type: this.type,
          message: this.message
        },
        {
          height: 'auto'
        }
      )
    },
    onConfirm() {
      this.$emit('confirm')
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
