var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ImageInput', {
    staticClass: "rounded-lg",
    attrs: {
      "width": _vm.width,
      "height": _vm.height,
      "blank-width": _vm.blankWidth,
      "blank-height": _vm.blankHeight,
      "editable-only-when-empty": _vm.editableOnlyWhenEmpty,
      "editable": _vm.editable,
      "image-url": _vm.imageUrl,
      "thumbnail-url": _vm.thumbnailUrl,
      "file": _vm.file,
      "show-loader": _vm.isUploading
    },
    on: {
      "update:file": function ($event) {
        _vm.file = $event;
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }