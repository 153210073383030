<template>
  <div class="font-sans antialiased flex" style="height: 100dvh;">
    <AuthManagement />
    <!-- Overlay -->
    <div
      v-if="!sidebarIsCollapsed"
      @click="toggleSidebar()"
      class="x-overlay fixed block md:hidden w-full h-full cursor-pointer top-0 bottom-0 right-0 left-0"
    ></div>
    <!-- Sidebar / Nav -->
    <SideBar
      :isCollapsed="sidebarIsCollapsed"
      @toggleSidebar="toggleSidebar()"
    ></SideBar>
    <!-- Main Container -->
    <div class="flex-1 flex flex-col bg-white overflow-hidden">
      <!-- Top bar -->
      <TopBar
        :scope="$route.meta.scope"
        :hasBurger="sidebarIsCollapsed"
        @toggleSidebar="toggleSidebar()"
      ></TopBar>
      <!-- Main Section -->
      <portal-target
        v-if="$route.meta.scope == 'admin'"
        name="admin-alerts"
        style="position: relative; z-index:2;"
      ></portal-target>
      <portal-target
        v-if="$route.meta.scope == 'publisher'"
        name="publisher-alerts"
        style="position: relative; z-index:2;"
      ></portal-target>
      <portal-target
        name="alerts"
        style="position: relative; z-index:2;"
      ></portal-target>
      <portal-target name="top-bar-secondary">
        <TermsAlert></TermsAlert>
        <PaymentAlert></PaymentAlert>
      </portal-target>
      <UserNotifications />

      <SubscriptionManager />
      <div class="md:flex flex-row flex-1 overflow-y-scroll md:overflow-hidden">
        <div
          class="flex flex-col md:flex-1 px-6 py-4 md:overflow-y-auto md:h-full overflow-x-hidden"
          @scroll="onScroll"
        >
          <div class="-mx-6 -mt-4 mb-4">
            <!-- <portal-target name="content-header"> </portal-target> -->
          </div>
          <portal-target name="main-content-start"></portal-target>
          <slot>
            <router-view />
          </slot>
          <portal-target name="main-content-end"> </portal-target>
        </div>
        <SideBarRight> </SideBarRight>
      </div>
      <portal-target name="sticky-footer"></portal-target>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/layout/SideBar.vue'
import SideBarRight from '@/components/layout/SideBarRight.vue'
import TopBar from '@/components/layout/TopBar.vue'
import TermsAlert from '@/components/general/TermsAlert.vue'
import PaymentAlert from '@/components/general/PaymentAlert.vue'
import AuthManagement from '@/components/general/AuthManagement.vue'
import UserNotifications from '@/views/Admin/Notifications/UserNotifications.vue'
import SubscriptionManager from '@/views/Admin/Subscriptions/SubscriptionManager.vue'

export default {
  components: {
    SideBar,
    SideBarRight,
    TopBar,
    TermsAlert,
    PaymentAlert,
    AuthManagement,
    UserNotifications,
    SubscriptionManager
  },
  data() {
    return {
      sidebarIsCollapsed: false
    }
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$events.emit('scrolledToBottom')
      }
    },
    toggleSidebar() {
      this.sidebarIsCollapsed = !this.sidebarIsCollapsed
    }
  },
  created() {
    if (window.innerWidth < 640) {
      this.sidebarIsCollapsed = !this.sidebarIsCollapsed
    }
  }
}
</script>

<style scoped>
.x-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  animation: fadein 1s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
