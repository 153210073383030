<template>
  <div class="w-full" v-if="tags">
    <div class="flex flex-row">
      <div class="w-1/2 pt-2 px-2 ">
        <div class="flex align-middle">
          <div class="w-full">
            <TagsSelect
              title="Vertical "
              v-model="verticals"
              :enable-not-toggles="true"
              :filter-types="['vertical']"
              :close-on-select="false"
              class="text-black"
            >
            </TagsSelect>
          </div>
          <div class="m-auto px-2 flex space-x-4">
            <button
              @click="updateAction('vertical', 'add')"
              v-tooltip="`Add to Offer`"
            >
              <Icon
                name="filePlus"
                class="text-blue-600"
                :class="
                  actions.vertical == 'add'
                    ? 'text-opacity-100'
                    : 'text-opacity-50'
                "
                :size="6"
              />
            </button>
            <button
              @click="updateAction('vertical', 'replace')"
              v-tooltip="`Replace all`"
            >
              <Icon
                name="fileReplace"
                class="text-red-600"
                :class="
                  actions.vertical == 'replace'
                    ? 'text-opacity-100'
                    : 'text-opacity-50'
                "
                :size="6"
              />
            </button>
          </div>
        </div>
      </div>

      <div class="w-1/2 pt-2 px-2 ">
        <div class="flex align-middle ">
          <div class="w-full">
            <TagsSelect
              title="Country "
              v-model="countries"
              :enable-not-toggles="true"
              :filter-types="['countryCode']"
              :close-on-select="false"
              :allow-bulk-paste="true"
              :limit="300"
              class="text-black"
            >
            </TagsSelect>
          </div>
          <div class="m-auto px-2 flex space-x-4">
            <button
              @click="updateAction('countryCode', 'add')"
              v-tooltip="`Add to Offer`"
            >
              <Icon
                name="filePlus"
                class="text-blue-600"
                :class="
                  actions.countryCode == 'add'
                    ? 'text-opacity-100'
                    : 'text-opacity-50'
                "
                :size="6"
              />
            </button>
            <!-- <button
              @click="updateAction('countryCode', 'remove')"
              v-tooltip="`Remove from Offer`"
            >
              <Icon
                name="fileRemove"
                class="text-orange-600"
                :class="
                  actions.countryCode == 'remove'
                    ? 'text-opacity-100'
                    : 'text-opacity-50'
                "
                :size="6"
              />
            </button> -->
            <button
              @click="updateAction('countryCode', 'replace')"
              v-tooltip="`Replace all`"
            >
              <Icon
                name="fileReplace"
                class="text-red-600"
                :class="
                  actions.countryCode == 'replace'
                    ? 'text-opacity-100'
                    : 'text-opacity-50'
                "
                :size="6"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row">
      <div class="w-1/2 pt-2 px-2 ">
        <div class="flex align-middle">
          <div class="w-full">
            <TagsSelect
              title="Mobile / Desktop "
              v-model="mobileDesktop"
              :enable-not-toggles="true"
              :filter-types="['mobileDesktop']"
              :close-on-select="false"
              class="text-black"
            >
            </TagsSelect>
          </div>
          <div class="m-auto px-2 flex space-x-4">
            <button
              @click="updateAction('mobileDesktop', 'add')"
              v-tooltip="`Add to Offer`"
            >
              <Icon
                name="filePlus"
                class="text-blue-600"
                :class="
                  actions.mobileDesktop == 'add'
                    ? 'text-opacity-100'
                    : 'text-opacity-50'
                "
                :size="6"
              />
            </button>
            <!-- <button
              @click="updateAction('mobileDesktop', 'remove')"
              v-tooltip="`Remove from Offer`"
            >
              <Icon
                name="fileRemove"
                class="text-orange-600"
                :class="
                  actions.mobileDesktop == 'remove'
                    ? 'text-opacity-100'
                    : 'text-opacity-50'
                "
                :size="6"
              />
            </button> -->
            <button
              @click="updateAction('mobileDesktop', 'replace')"
              v-tooltip="`Replace all`"
            >
              <Icon
                name="fileReplace"
                class="text-red-600"
                :class="
                  actions.mobileDesktop == 'replace'
                    ? 'text-opacity-100'
                    : 'text-opacity-50'
                "
                :size="6"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-2 mt-2 uppercase font-bold text-gray-600 text-xs">
      Destination URL
    </div>
    <div class="flex mx-2 mb-2">
      <Superselect
        title="Action"
        style="min-width:7rem;"
        :options="destinationActionOptions"
        select-class="rounded-r-none border-l-1 border-r-0"
        :multiple="false"
        track-by="id"
        label="label"
        v-model="destinationAction"
      />
      <YInput
        v-if="destinationAction.id == 'append'"
        input-class="rounded-l-none"
        label="Append"
        v-model="appendToDestination"
      />
      <YInput
        v-if="destinationAction.id == 'replace'"
        input-class="rounded-none"
        label="Find"
        v-model="findInDestination"
      />
      <YInput
        v-if="destinationAction.id == 'replace'"
        input-class="rounded-l-none border-l-0"
        label="Replace"
        v-model="replaceInDestination"
      />
    </div>
    <YAlert
      class="mx-2 my-4"
      v-if="removedOnlyTagsExposesAllRule"
      type="danger"
      color="red"
      :allow-close="false"
    >
      <strong
        >Removing these tags will expose one or more rows as an ALL
        rule!</strong
      >
    </YAlert>
    <div class="flex flex row mt-6 ml-4">
      <span class="font-bold text-gray-800 uppercase"
        >PREVIEW YOUR CHANGES</span
      >
    </div>
    <div class="flex flex-row">
      <div class="w-4/6  px-2">
        <div
          class="mt-2 flex w-full mx-auto overflow-hidden bg-white rounded-lg shadow-md "
        >
          <div
            class="flex items-center justify-center w-12"
            :class="getPreviewActionsClass(verticals, 'vertical')"
          >
            <Icon name="alertCircleOutline" size="6" v-if="actions.vertical" />

            <Icon name="plus" size="6" v-else />
          </div>

          <div class="px-4 py-2 -mx-3">
            <div class="mx-3">
              <p
                :class="getPreviewActionsTextClass(verticals, 'vertical')"
                v-text="
                  getPreviewActionsText(verticals, 'vertical', 'Verticals')
                "
              ></p>
              <span
                class="font-bold text-lg"
                v-for="(vertical, index) in verticals"
                :key="vertical.id"
              >
                {{ vertical.name }}
                <span v-if="index + 1 < verticals.length">, </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="w-2/6  px-2 flex items-center justify-between">
        <div>
          <slot name="beforeButtons"> </slot>
          <YButton color="gray" @click="clearSelections">
            Clear Selection
          </YButton>
          <slot name="afterButtons"> </slot>
        </div>
        <div></div>
      </div>
    </div>

    <div class="flex flex-row">
      <div class="w-4/6 pt-2 px-2">
        <div
          class="flex w-full mx-auto overflow-hidden bg-white rounded-lg shadow-md "
        >
          <div
            class="flex items-center justify-center w-12"
            :class="getPreviewActionsClass(countries, 'countryCode')"
          >
            <Icon
              name="alertCircleOutline"
              size="6"
              v-if="actions.countryCode"
            />

            <Icon name="plus" size="6" v-else />
          </div>

          <div class="px-4 py-2 -mx-3">
            <div class="mx-3">
              <p
                :class="getPreviewActionsTextClass(countries, 'countryCode')"
                v-text="
                  getPreviewActionsText(countries, 'countryCode', 'Countries')
                "
              ></p>
              <span
                class="font-bold text-lg"
                v-for="(country, index) in countries"
                :key="country.id"
              >
                {{ country.name }}
                <span v-if="index + 1 < countries.length">, </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row">
      <div class="w-4/6 pt-2 px-2">
        <div
          class="flex w-full mx-auto overflow-hidden bg-white rounded-lg shadow-md "
        >
          <div
            class="flex items-center justify-center w-12"
            :class="getPreviewActionsClass(mobileDesktop, 'mobileDesktop')"
          >
            <Icon
              name="alertCircleOutline"
              size="6"
              v-if="actions.mobileDesktop"
            />

            <Icon name="plus" size="6" v-else />
          </div>

          <div class="px-4 py-2 -mx-3">
            <div class="mx-3">
              <p
                :class="
                  getPreviewActionsTextClass(mobileDesktop, 'mobileDesktop')
                "
                v-text="
                  getPreviewActionsText(
                    mobileDesktop,
                    'mobileDesktop',
                    'Mobile / Desktop'
                  )
                "
              ></p>

              <span
                class="font-bold text-lg"
                v-for="(mobile, index) in mobileDesktop"
                :key="mobile.id"
              >
                {{ mobile.name }}
                <span v-if="index + 1 < mobileDesktop.length">, </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import TagsSelect from '@/views/Admin/Tags/TagsSelect'

export default {
  components: {
    TagsSelect
  },
  props: {
    offersToEdit: {
      type: Array,
      default: () => []
    }
  },
  updated() {},
  apollo: {
    tags: {
      query: TAGS_QUERY,
      variables() {
        return {
          first: 999,
          filters: {}
        }
      }
    }
  },
  data() {
    let destinationActionOptions = [
      { id: 'append', label: 'Append' },
      { id: 'replace', label: 'Find & Replace' }
    ]

    return {
      actions: {
        vertical: 'add',
        countryCode: 'add',
        mobileDesktop: 'add',
        url: 'append'
      },
      verticals: [],
      countries: [],
      mobileDesktop: [],
      removedOnlyTagsExposesAllRule: false,
      appendToDestination: '',
      findInDestination: '',
      replaceInDestination: '',
      destinationActionOptions,
      destinationAction: destinationActionOptions[1]
    }
  },
  mounted() {},
  methods: {
    getPreviewActionsKey(items, itemName) {
      let isNot = items && items.length > 0 ? items[0].isNot : false

      if (this.actions[itemName] == 'add' && isNot) {
        return 'remove'
      }

      if (this.actions[itemName] == 'add') {
        return 'add'
      }

      if (this.actions[itemName] == 'replace') {
        return 'replace'
      }

      return ''
    },
    getPreviewActionsText(items, itemName, displayName) {
      let key = this.getPreviewActionsKey(items, itemName)
      return key == 'add'
        ? 'ADD to ' + displayName
        : key == 'remove'
        ? 'REMOVE from ' + displayName
        : key == 'replace'
        ? 'REMOVE ALL AND REPLACE in ' + displayName
        : 'No Change to ' + displayName
    },
    getPreviewActionsTextClass(items, itemName) {
      let key = this.getPreviewActionsKey(items, itemName)
      return key == 'add'
        ? 'addText'
        : key == 'remove'
        ? 'removeText'
        : key == 'replace'
        ? 'replaceText'
        : 'text-gray-600'
    },
    getPreviewActionsClass(items, itemName) {
      let key = this.getPreviewActionsKey(items, itemName)
      return key != '' ? key : 'bg-gray-500 text-gray-700'
    },
    clearSelections() {
      this.verticals = []
      this.actions.vertical = 'add'
      this.countries = []
      this.actions.countryCode = 'add'
      this.mobileDesktop = []
      this.actions.mobileDesktop = 'add'
      this.findInDestination = ''
      this.replaceInDestination = ''
      this.appendToDestination = ''
      this.destinationAction = this.destinationActionOptions[0]
    },
    tagsTypeReducer(type, tags) {
      tags = tags.filter(tag => tag.type == type)
      let tagNames = tags.map(tag => tag.name)
      return {
        isNot: tags[0]?.isNot ?? false,
        tags: tagNames
      }
    },
    append(value, append) {
      return value + (append ?? '')
    },
    findAndReplace(value, find, replace = '', highlight = false) {
      if (!find) {
        return value
      }

      if (highlight) {
        if (!replace || replace == '') {
          replace =
            '<span class="bg-yellow-400 inline-block" style="max-width: 0.15rem;">&nbsp;</span>'
        } else {
          replace = '<span class="bg-yellow-400">' + (replace ?? '') + '</span>'
        }
      }

      return value.replaceAll(find, replace ?? '')
    },
    recalculateOffers() {
      this.removedOnlyTagsExposesAllRule = false
      let offers = this._.cloneDeep(this.originalOffers).map(offer => {
        if (offer.destinationType == 'direct') {
          if (this.destinationAction.id == 'append') {
            offer.destination = this.append(
              offer.destination,
              this.appendToDestination
            )
          }
          if (this.destinationAction.id == 'replace') {
            let originalDestination = offer.destination
            offer.destination = this.findAndReplace(
              originalDestination,
              this.findInDestination,
              this.replaceInDestination
            )
            offer.highlightedDestination = this.findAndReplace(
              originalDestination,
              this.findInDestination,
              this.replaceInDestination,
              true
            )
          }
        }
        offer.verticals = this.mergeTagGroups(
          offer.verticals,
          this.verticalTagGroup,
          this.actions.vertical
        )
        offer.countries = this.mergeTagGroups(
          offer.countries,
          this.countryCodeTagGroup,
          this.actions.countryCode
        )
        offer.mobileDesktop = this.mergeTagGroups(
          offer.mobileDesktop,
          this.mobileDesktopTagGroup,
          this.actions.mobileDesktop
        )
        return offer
      })

      this.$emit('offersTransformed', offers)

      return offers
    },
    updateAction(type, action) {
      this.actions[type] = action
      this.recalculateOffers()
    },
    uniqueMerge(array1, array2) {
      array2.forEach(function(value) {
        if (array1.indexOf(value) === -1) {
          array1.push(value)
        }
      })
      return array1
    },
    arrayDifference(array1, array2) {
      return array1.filter(x => !array2.includes(x))
    },
    mergeTagGroups(originalGroup, newGroup, action = 'add') {
      if (action == 'replace') {
        return (
          newGroup ?? {
            type: originalGroup.type,
            tags: [],
            isNot: false
          }
        )
      }

      if (newGroup == null || !newGroup.tags || newGroup.tags.length <= 0) {
        return originalGroup
      }

      let tags = originalGroup.tags

      if (action == 'add') {
        if (originalGroup.tags.length <= 0) {
          if (newGroup.isNot) {
            return newGroup
          } else {
            return originalGroup
          }
        }
        if (!!originalGroup.isNot === !!newGroup.isNot) {
          tags = this.uniqueMerge(tags, newGroup.tags)
        } else {
          console.log(originalGroup, newGroup)
          let startsEmpty = tags.length <= 0
          tags = this.arrayDifference(tags, newGroup.tags)
          let endsEmpty = tags.length <= 0
          console.log(
            'array Differences fired',
            !startsEmpty,
            endsEmpty,
            !!originalGroup.isNot == false
          )
          if (!startsEmpty && endsEmpty && !!originalGroup.isNot == false) {
            console.log('found')
            this.removedOnlyTagsExposesAllRule = true
          }
        }
      }

      return {
        type: originalGroup.type,
        tags: tags,
        isNot: action == 'replace' ? newGroup.isNot : originalGroup.isNot
      }
    },
    convertToTagGroup(tags) {
      if (!tags || tags.length <= 0) {
        return null
      }

      return {
        type: tags[0].type,
        tags: tags.map(tag => tag.name),
        isNot: tags[0].isNot ?? false
      }
    }
  },
  computed: {
    verticalTagGroup() {
      return this.convertToTagGroup(this.verticals)
    },
    countryCodeTagGroup() {
      return this.convertToTagGroup(this.countries)
    },
    mobileDesktopTagGroup() {
      return this.convertToTagGroup(this.mobileDesktop)
    },
    originalOffers() {
      return this.offersToEdit.map(offer => {
        return {
          id: offer.id,
          name: offer.name,
          label: offer.label,
          destination: offer.destination,
          destinationType: offer.destinationType,
          verticals: this.tagsTypeReducer('vertical', offer.tags),
          countries: this.tagsTypeReducer('countryCode', offer.tags),
          mobileDesktop: this.tagsTypeReducer('mobileDesktop', offer.tags)
        }
      })
    }
  },
  watch: {
    removedOnlyTagsExposesAllRule(newValue) {
      this.$emit('warningToggled', newValue)
    },
    destinationAction() {
      this.recalculateOffers()
    },
    appendToDestination() {
      this.recalculateOffers()
    },
    findInDestination() {
      this.recalculateOffers()
    },
    replaceInDestination() {
      this.recalculateOffers()
    },
    verticals() {
      this.recalculateOffers()
    },
    countries() {
      this.recalculateOffers()
    },
    mobileDesktop() {
      this.recalculateOffers()
    },
    offersToEdit() {
      this.recalculateOffers()
    }
  }
}
</script>
<style scoped>
.add {
  @apply bg-blue-600 text-white;
}
.addText {
  @apply font-bold text-blue-600;
}
.remove {
  @apply bg-orange-600 text-white;
}
.removeText {
  @apply font-bold text-orange-600;
}
.replace {
  @apply bg-red-600 text-white;
}
.replaceText {
  @apply font-bold text-red-600;
}
</style>
