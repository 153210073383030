var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "table-scroll-container"
  }, [_c('vuetable', {
    ref: "vuetable",
    staticClass: "y-table tr-td-px-2 tr-td-x-edge-4",
    attrs: {
      "fields": _vm.fields,
      "api-mode": false,
      "data-path": "data",
      "data-mode": true,
      "data": _vm.tableData,
      "css": _vm.css,
      "cellspacing": "0",
      "width": "100%",
      "table-wrapper": "vuetable-wrapper"
    },
    scopedSlots: _vm._u([{
      key: "status-slot",
      fn: function (props) {
        return _c('div', {
          staticClass: "w-2"
        }, [props.rowData.status.toLowerCase() == 'up' ? [_c('span', [_vm._v("✅")])] : [_c('span', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip.left",
            value: props.rowData.status,
            expression: "props.rowData.status",
            modifiers: {
              "left": true
            }
          }]
        }, [_vm._v("❌")])]], 2);
      }
    }, {
      key: "uptime-slot",
      fn: function (props) {
        return _c('div', {
          staticClass: "inline"
        }, [_c('span', {
          staticClass: "font-semibold",
          class: [props.rowData.uptime >= 99 ? 'text-green-500' : 'text-red-500']
        }, [_vm._v(" " + _vm._s(props.rowData.uptime) + "%")])]);
      }
    }, {
      key: "link-slot",
      fn: function (props) {
        return _c('div', {
          staticStyle: {
            "max-width": "15rem"
          }
        }, [_c('a', {
          staticClass: "link truncate block",
          attrs: {
            "href": props.rowData.websiteUrl,
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(props.rowData.websiteName))])]);
      }
    }, {
      key: "actions-slot",
      fn: function (props) {
        return _c('div', {
          staticClass: "flex"
        }, [_c('a', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `StatusCake`,
            expression: "`StatusCake`"
          }],
          staticClass: "link",
          attrs: {
            "href": `https://app.statuscake.com/UptimeStatus.php?tid=${props.rowData.id}`,
            "target": "_blank"
          }
        }, [_c('Icon', {
          attrs: {
            "name": "openInNew",
            "size": 4
          }
        })], 1)]);
      }
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }