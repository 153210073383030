<template>
  <div>
    <portal to="top-bar-right">
      <div class="flex justify-right">
        <YButton color="blue" @click="openCreateModal">
          Add Account Level Postback
        </YButton>
      </div>
    </portal>
    <YTable
      :data="postbacks.data"
      :bottom-loading="$apollo.queries.postbacks.loading"
    >
      <template slot="header-row">
        <th>Target</th>
        <th>
          <div class="flex cursor-pointer">
            Conversion Type
            <v-popover offset="10" placement="top" trigger="hover">
              <Icon
                name="information"
                size="4"
                class="ml-1 text-gray-500 self-center"
              />

              <template slot="popover">
                <div
                  class="bg-gray-100 rounded-md p-4"
                  style="max-width:600px;"
                >
                  <span class="font-semibold block mb-2">
                    Campaign Level Postbacks take priority over Account Level
                    Postbacks.</span
                  >
                  <span class="block"
                    ><strong>DEFAULT</strong> - This postback will fire
                    <span class="text-pink-700 font-bold">payable</span>
                    conversions when no conversion type postback is set or
                    available.</span
                  >
                  <span class="block"
                    ><strong>TYPE SELECTED</strong> - This postback will fire
                    <span class="text-pink-700 font-bold">all</span> conversions
                    for the specific conversion type set regardless of
                    payout.</span
                  >
                </div>
              </template>
            </v-popover>
          </div>
        </th>
        <th>Url</th>
        <th></th>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          <template v-if="props.rowData.redirect">
            <template v-if="props.rowData.redirect.campaign">
              <router-link
                :to="{
                  name: 'Publisher Campaign',
                  params: { id: props.rowData.redirect.campaign.id }
                }"
                class="link"
              >
                {{ props.rowData.redirect.campaign.id }} -
                {{ props.rowData.redirect.campaign.name }}
              </router-link>
            </template>
            <template v-else>
              {{ props.rowData.redirect.id }} -
              {{ props.rowData.redirect.subdomain }}.{{
                props.rowData.redirect.domain
              }}
            </template>
          </template>
          <template v-else
            ><span class="font-semibold text-gray-800"
              >Account Level Postback</span
            ></template
          >
        </td>
        <td>
          <Tag v-if="props.rowData.conversionType" color="blue">{{
            props.rowData.conversionType?.name
          }}</Tag>
          <Tag v-else color="orange">Default</Tag>
        </td>
        <td>
          <template
            >{{ props.rowData.protocol }}://{{ props.rowData.url }}</template
          >
        </td>
        <td>
          <div class="flex items-center text-right justify-between">
            <div></div>
            <div class="flex items-center">
              <a
                href="#"
                class="link p-1"
                @click.prevent="openEditModal(props.rowData)"
              >
                <Icon name="pencil" class="w-4 h-4" />
              </a>
              <a
                href="#"
                class="text-red-500 hover:text-red-600 p-1"
                @click.prevent="openDeleteModal(props.rowData)"
              >
                <Icon name="trashCan" class="w-4 h-4" />
              </a>
            </div>
          </div>
        </td>
      </template>
    </YTable>
  </div>
</template>
<script>
import PostbackDelete from '@/views/Publisher/Postbacks/PostbackDelete'
import PostbackEdit from '@/views/Publisher/Postbacks/PostbackEdit'
import PostbackCreate from '@/views/Publisher/Postbacks/PostbackCreate'
import POSTBACKS_QUERY from '@/graphql/Postback/PostbacksQuery.gql'

export default {
  components: {},
  apollo: {
    postbacks: {
      query: POSTBACKS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          filters: {
            userId: { value: this.$store.getters.authId }
          },
          orderBy: [
            {
              column: 'user_id',
              order: 'ASC'
            },
            {
              column: 'redirect_id',
              order: 'ASC'
            },
            {
              column: 'conversion_type',
              order: 'ASC'
            }
          ],
          first: 100000
        }
      }
    }
  },
  data() {
    return {
      postbacks: {
        data: []
      },
      referrerOptions: [],
      page: 1,
      perPage: 25,
      loadMoreIncrement: 25,
      perPageOptions: [10, 25, 50, 100, 500]
    }
  },
  methods: {
    postbacksContainAccountLevel(postbacks) {
      return (
        postbacks.filter(postback => {
          return !postback.redirect
        }).length > 0
      )
    },
    openDeleteModal(postback) {
      this.$modal.show(
        PostbackDelete,
        { postback: postback },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openEditModal(postback) {
      this.$modal.show(
        PostbackEdit,
        { postback: postback },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openCreateModal() {
      this.$modal.show(
        PostbackCreate,
        {
          userId: this.$store.getters.authId,
          allowAccountLevel: true
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true,
          classes: ['overflow-visible-important']
        }
      )
    }
  },
  computed: {},
  mounted() {
    this.$events.listen('postbackCreated', () => {
      this.$apollo.queries.postbacks.refetch()
    })
    this.$events.listen('postbackDeleted', () => {
      this.$apollo.queries.postbacks.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('postbackCreated')
    this.$events.remove('postbackDeleted')
  },
  updated() {}
}
</script>
