<template>
  <Widget class="bg-red-600 border-red-700 text-white">
    <WidgetHeader
      class="tiny-forms pt-4 pb-4"
      :closeable="true"
      @close="$emit('close')"
      closeClass="text-white"
    >
      <div class="text-white">
        Delete Server - {{ this.server.provider.name }} -
        {{ this.server.id }}
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <h5>
        <strong class="text-warning" style="color: #ffc107 !important;"
          >Are you sure? This action cannot be undone</strong
        >
      </h5>
      <h5 v-if="serverHasRecords">
        This server has records associated with it and cannot be deleted.
      </h5>
    </WidgetBody>
    <WidgetFooter class="pt-4 pb-4 px-4 flex justify-between bg-red-700">
      <YCheckbox
        v-model="closeOnComplete"
        label="Close on Complete"
        type="primary"
        class="flex items-center"
      ></YCheckbox>
      <y-button
        :is-loading="isSubmitting"
        type="button"
        class="bg-red-800 hover:bg-red-900 text-white ml-auto"
        :disabled="!ydnsServers || serverHasRecords"
        @click="deleteItems([server.id])"
        @keydown.enter="deleteItems([server.id])"
      >
        Delete
      </y-button>
    </WidgetFooter>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  props: {
    server: {
      required: true
    }
  },
  mixins: [ValidationErrorsMixin],
  components: {},
  apollo: {
    ydnsServers: {
      query: gql`
        query getServers($filters: YdnsServerFilterInput) {
          ydnsServers(filters: $filters) {
            id
          }
        }
      `,
      variables() {
        return {
          filters: {
            id: { value: [this.server.id] },
            hasRecords: true
          }
        }
      },
      deep: true
    }
  },
  data() {
    return {
      closeOnComplete: true,
      isSubmitting: false,
      typeOptions: [],
      validationErrors: {}
    }
  },
  watch: {},
  computed: {
    serverHasRecords() {
      return this.ydnsServers && this.ydnsServers.length > 0
    }
  },
  methods: {
    deleteItems(serverIds) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteServers($id: [ID!]!) {
              ydnsDeleteServers(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: serverIds
          }
        })
        .then(result => {
          this.$toastr.s(`Servers Deleted`, 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshYdnsServers', true)
          this.$emit('deleted', result.data.ydnsDeleteServers)
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
