var render = function render(){
  var _vm$imageFileComputed, _vm$imageFileComputed2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "mr-6 relative"
  }, [_c('div', [_vm.rowData.legacyImageUrl ? _c('div', {
    staticClass: "mr-4 hidden sm:block"
  }, [_c('EditableImage', {
    staticClass: "rounded mr-2x",
    attrs: {
      "imageUrl": _vm.rowData.legacyImageUrl,
      "thumbnailUrl": _vm.rowData.legacyImageUrl,
      "height": 12,
      "width": 12,
      "blankHeight": 12,
      "blankWidth": 12,
      "editable-only-when-empty": true,
      "modelId": _vm.rowData.id,
      "deleteExisting": true,
      "edit-focus-icon-enabled": false,
      "editable": false,
      "modelClass": "campaign"
    }
  })], 1) : _c('div', {
    staticClass: "mr-4 hidden sm:block"
  }, [_c('EditableImage', {
    staticClass: "rounded mr-2x",
    attrs: {
      "imageUrl": ((_vm$imageFileComputed = _vm.imageFileComputed(_vm.rowData.images)) === null || _vm$imageFileComputed === void 0 ? void 0 : _vm$imageFileComputed.original) || null,
      "thumbnailUrl": ((_vm$imageFileComputed2 = _vm.imageFileComputed(_vm.rowData.images)) === null || _vm$imageFileComputed2 === void 0 ? void 0 : _vm$imageFileComputed2.imageThumbnailUrl) || null,
      "height": 12,
      "width": 12,
      "blankHeight": 12,
      "blankWidth": 12,
      "modelId": _vm.rowData.id,
      "editable": false,
      "modelClass": "campaign"
    }
  })], 1)])]), _c('ul', {
    staticClass: "campaign-cell-list"
  }, [_c('li', {
    class: !_vm.minimalView ? 'max-width' : ''
  }, [_c('router-link', {
    staticClass: "link inline-block without-truncate",
    attrs: {
      "to": {
        name: 'Campaign',
        params: {
          id: _vm.rowData.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.rowData.name) + " ")]), _c('sup', {
    staticClass: "ts-1 ml-1 text-gray-500"
  }, [_vm._v(_vm._s(_vm.rowData.id))])], 1), !_vm.minimalView ? _c('li', [_vm.rowData.isCapped ? _c('Tag', {
    staticClass: "mr-1 mb-1",
    attrs: {
      "color": "darkRed"
    }
  }, [_vm._v("CAPPED")]) : _vm._e(), _vm._l(_vm.verticalTags, function (tag) {
    return _c('Tag', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: `Vertical`,
        expression: "`Vertical`"
      }],
      key: tag.id,
      staticClass: "my-1 mr-1",
      attrs: {
        "color": tag.color
      }
    }, [_vm._v(_vm._s(tag.name))]);
  }), _vm._l(_vm.adminTags, function (tag) {
    return _c('Tag', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: `Tag`,
        expression: "`Tag`"
      }],
      key: tag.id,
      staticClass: "my-1 mr-1",
      attrs: {
        "color": tag.color
      }
    }, [_vm._v(_vm._s(tag.name))]);
  }), _vm.rowData.payoutType ? _c('Tag', {
    staticClass: "mr-1",
    attrs: {
      "color": `purple`
    }
  }, [_vm._v(" " + _vm._s(_vm.rowData.payoutType.toUpperCase()) + " ")]) : _vm._e(), _vm.rowData.approvalRequired ? _c('Tag', {
    staticClass: "mt-1 mr-1",
    attrs: {
      "color": `red`
    }
  }, [_vm._v("Approval Required")]) : _vm._e()], 2) : _vm._e(), !_vm.minimalView ? _c('li') : _vm._e(), !_vm.minimalView ? _c('li') : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }