var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetBody', {
    staticClass: "p-3 p-md-4"
  }, [_c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('div', {
    staticClass: "flex items-center mb-3"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('h5', {
    staticClass: "text-md font-bold text-inverse-800"
  }, [_vm._v(" " + _vm._s(_vm.provider.name) + " ")])]), _c('div', [_c('Tag', {
    key: `${_vm.provider.type}`,
    staticClass: "mr-1",
    attrs: {
      "color": _vm.provider.type == 'general' ? 'blue' : 'orange'
    }
  }, [_vm._v(" " + _vm._s(_vm.provider.type.capitalize()) + " ")])], 1)]), _c('div', [_c('provider-actions', {
    ref: "providerActions",
    attrs: {
      "providers": [_vm.provider]
    }
  })], 1)]), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }