var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-auto bg-white rounded-b-xl mb-12 border-l border-r border-b",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isLoading ? _c('form', {
    staticClass: "w-full",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.register.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "flex flex-col px-6"
  }, [_c('div', {
    staticClass: "mt-6 font-bold text-lg text-inverse-700"
  }, [_vm._v(" Publisher Registration ")]), _c('div', {
    staticClass: "mt-3"
  }, [_c('p', {
    staticClass: "pb-2 font-semibold uppercase text-inverse-700"
  }, [_vm._v(" Login Details ")]), _c('div', {
    staticClass: "grid grid-rows-1 gap-3"
  }, [_c('YInput', {
    attrs: {
      "label": "Email",
      "required": true
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Password",
      "type": "password",
      "required": true
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Confirm Password",
      "type": "password",
      "required": true
    },
    model: {
      value: _vm.passwordConfirmation,
      callback: function ($$v) {
        _vm.passwordConfirmation = $$v;
      },
      expression: "passwordConfirmation"
    }
  })], 1)]), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "pt-5 pb-2 font-semibold uppercase text-inverse-700"
  }, [_vm._v(" User Details ")]), _c('div', {
    staticClass: "grid grid-rows-1 gap-3"
  }, [_c('YInput', {
    attrs: {
      "label": "Full Name",
      "required": true
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Company"
    },
    model: {
      value: _vm.company,
      callback: function ($$v) {
        _vm.company = $$v;
      },
      expression: "company"
    }
  }), _c('Superselect', {
    attrs: {
      "name": "country",
      "title": "Country",
      "label": "name",
      "track-by": "code",
      "options": _vm.countries,
      "multiple": false,
      "required": true
    },
    model: {
      value: _vm.country,
      callback: function ($$v) {
        _vm.country = $$v;
      },
      expression: "country"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Region",
      "disabled": _vm.isDisabled,
      "required": true
    },
    model: {
      value: _vm.region,
      callback: function ($$v) {
        _vm.region = $$v;
      },
      expression: "region"
    }
  }), _c('div', {
    staticClass: "flex"
  }, [_c('Superselect', {
    attrs: {
      "name": "Messenger Type",
      "title": "Type",
      "label": "name",
      "track-by": "id",
      "options": _vm.types,
      "multiple": false,
      "select-class": "rounded rounded-r-none border-r-0",
      "required": true
    },
    model: {
      value: _vm.messengerType,
      callback: function ($$v) {
        _vm.messengerType = $$v;
      },
      expression: "messengerType"
    }
  }), _c('YInput', {
    staticClass: "w-full",
    attrs: {
      "label": "Messenger Handle",
      "input-class": "rounded-l-none border-l-0",
      "required": true
    },
    model: {
      value: _vm.messengerHandle,
      callback: function ($$v) {
        _vm.messengerHandle = $$v;
      },
      expression: "messengerHandle"
    }
  })], 1), _c('YInput', {
    attrs: {
      "label": "Phone"
    },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  })], 1)]), _c('div', {
    staticClass: "mt-8"
  }, [_c('p', {
    staticClass: "x-question-header"
  }, [_vm._v("Where did you hear about YTZ?")]), _c('div', [_c('Superselect', {
    attrs: {
      "title": "Referred by",
      "options": _vm.referredTypeOptions.map(function (ref) {
        return ref.type;
      }),
      "multiple": false,
      "required": true
    },
    model: {
      value: _vm.referredByQuestion,
      callback: function ($$v) {
        _vm.referredByQuestion = $$v;
      },
      expression: "referredByQuestion"
    }
  })], 1), _c('div', {
    staticClass: "hidden",
    class: {
      referralOptionsHidden: _vm.referralOptionsHidden
    }
  }, [!_vm.referralInput ? _c('div', {
    staticClass: "mt-2"
  }, [_c('Superselect', {
    staticClass: "mt-1",
    attrs: {
      "title": "Referral Options",
      "options": _vm.referralOptions,
      "disabled": !_vm.referredByQuestion,
      "multiple": false,
      "required": true
    },
    model: {
      value: _vm.referredByResponse,
      callback: function ($$v) {
        _vm.referredByResponse = $$v;
      },
      expression: "referredByResponse"
    }
  })], 1) : _vm._e(), _vm.referralInput || _vm.referredByResponse == 'Other' ? _c('div', {
    staticClass: "mt-2"
  }, [_c('YTextarea', {
    attrs: {
      "label": "How did you hear about us",
      "required": ""
    },
    model: {
      value: _vm.newReferral,
      callback: function ($$v) {
        _vm.newReferral = $$v;
      },
      expression: "newReferral"
    }
  })], 1) : _vm._e()]), _c('p', {
    staticClass: "x-question-header"
  }, [_vm._v(" What type of offers / verticals are you interested in? ")]), _c('Superselect', {
    attrs: {
      "title": "Offers / Verticals",
      "options": _vm.verticalTagsOptions,
      "required": true
    },
    model: {
      value: _vm.verticalTypeTags,
      callback: function ($$v) {
        _vm.verticalTypeTags = $$v;
      },
      expression: "verticalTypeTags"
    }
  }), _c('p', {
    staticClass: "x-question-header"
  }, [_vm._v("What geos are you interested in?")]), _c('Superselect', {
    attrs: {
      "title": "Geos",
      "options": _vm.geoTagsOptions,
      "required": true
    },
    model: {
      value: _vm.geoLocations,
      callback: function ($$v) {
        _vm.geoLocations = $$v;
      },
      expression: "geoLocations"
    }
  }), _c('p', {
    staticClass: "x-question-header",
    staticStyle: {
      "display": "inline"
    },
    domProps: {
      "textContent": _vm._s(_vm.question1)
    }
  }), _c('span', {
    staticClass: "x-asterisk"
  }, [_vm._v("*")]), _c('YTextarea', {
    attrs: {
      "required": "",
      "label": "List other networks"
    },
    model: {
      value: _vm.response1,
      callback: function ($$v) {
        _vm.response1 = $$v;
      },
      expression: "response1"
    }
  }), _c('p', {
    staticClass: "x-question-header"
  }, [_vm._v("Traffic Sources")]), _c('Superselect', {
    attrs: {
      "title": "Traffic Sources",
      "options": _vm.trafficTagsOptions,
      "required": true
    },
    model: {
      value: _vm.trafficType,
      callback: function ($$v) {
        _vm.trafficType = $$v;
      },
      expression: "trafficType"
    }
  })], 1), _c('div', {
    staticClass: "mt-10"
  }, [_c('YButton', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "color": "pink",
      "is-loading": _vm.isSubmitting,
      "disabled": _vm.fieldsCheck
    }
  }, [_vm._v("Register")]), _c('div', {
    staticClass: "flex justify-between border-t border-gray-500 pt-4 pb-8 px-2 mt-3"
  }, [_c('p', {
    staticClass: "text-gray-700"
  }, [_vm._v("Already have an account?")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('p', {
    staticClass: "link"
  }, [_vm._v("Sign In")])])], 1)], 1)]), _c('div', {
    staticClass: "px-10"
  }, [_c('portal-target', {
    attrs: {
      "name": "registration-errors"
    }
  })], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors,
      "portal-to": "registration-errors",
      "alert-class": "text-xs",
      "icon-size": 12,
      "icon": null,
      "color": "yellow"
    }
  }) : _vm._e()], 1) : _vm._e()]), !_vm.isLoading ? _c('div', {
    staticClass: "w-full"
  }, [_vm._m(0)]) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col gap-3 rounded-2xl p-5 pt-8"
  }, [_c('div', {
    staticClass: "h-6 w-4/5 rounded-lg is-loading"
  }), _c('div', {
    staticClass: "skeleton-label is-loading"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "skeleton-label is-loading mt-6"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "skeleton-label is-loading mt-6"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "h-20 w-full rounded-lg is-loading mt-6"
  })]);

}]

export { render, staticRenderFns }