var render = function render(){
  var _vm$campaign$payoutDe;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border flex flex-col justify-start rounded-md shadow",
    staticStyle: {
      "height": "27rem"
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Publisher Campaign',
        params: {
          id: _vm.campaign.id
        }
      }
    }
  }, [_vm.campaign.legacyImageUrl ? _c('img', {
    staticClass: "rounded-t-md object-cover h-48 w-full hover:opacity-75 transition ease-in duration-300",
    attrs: {
      "src": _vm.campaign.legacyImageUrl
    }
  }) : _vm._e(), _vm.campaign.images.length > 0 ? _c('img', {
    staticClass: "rounded-t-md object-cover h-48 w-full hover:opacity-75 transition ease-in duration-300",
    attrs: {
      "src": _vm.campaign.images[0].conversions.filter(function (image) {
        return image.conversion === 'thumb';
      })[0].storageUrl
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "px-4 mt-5 flex flex-col flex-grow"
  }, [_c('div', {
    staticClass: "overflow-hidden",
    staticStyle: {
      "height": "3.005rem"
    }
  }, [_vm.campaign.isFeatured ? _c('Icon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Featured',
      expression: "'Featured'"
    }],
    staticClass: "mr-2 mt-1 text-orange-400 flex-shrink inline-flex leading-relaxed",
    staticStyle: {
      "margin-top": "-6px"
    },
    attrs: {
      "name": "star"
    }
  }) : _vm._e(), _c('span', {
    staticClass: "font-bold text-lg inline leading-5"
  }, [_vm._v(_vm._s(_vm.campaign.name))]), _c('span', {
    staticClass: "text-gray-700 text-xs inline"
  }, [_vm._v(" - " + _vm._s(_vm.campaign.id))])], 1), _c('div', {
    staticClass: "w-100 flex justify-start gap-x-3 gap-y-1 mt-1 flex-wrap"
  }, [_c('div', {
    staticClass: "flex justify-center"
  }, _vm._l(_vm.campaign.verticalTags, function (vertical) {
    return _c('tag', {
      key: vertical.name,
      staticClass: "mr-1",
      attrs: {
        "color": vertical.color
      }
    }, [_vm._v(_vm._s(vertical.name))]);
  }), 1)]), _c('div', {
    staticClass: "flex flex-col mt-1"
  }, [_c('div', {
    staticClass: "mt-3 text-sm text-gray-700"
  }, [_c('Icon', {
    staticClass: "mapMarker",
    attrs: {
      "name": "mapMarkerMultiple",
      "size": "6"
    }
  }), _c('expandable-countries-from-payouts', {
    staticClass: "inline",
    attrs: {
      "payouts": _vm.campaign.payouts
    }
  })], 1), _c('div', {
    staticClass: "mt-1 text-sm text-gray-700 flex"
  }, [_c('div', [_c('Icon', {
    staticClass: "mapMarker",
    attrs: {
      "name": "finance",
      "size": "6"
    }
  })], 1), (_vm$campaign$payoutDe = _vm.campaign.payoutDescriptions) !== null && _vm$campaign$payoutDe !== void 0 && _vm$campaign$payoutDe.length ? _c('div', [_c('ExpandablePayouts', {
    attrs: {
      "payouts": _vm.campaign.payoutDescriptions
    }
  })], 1) : _vm._e()])])]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Publisher Campaign',
        params: {
          id: _vm.campaign.id
        }
      }
    }
  }, [_c('div', {
    staticClass: "w-full text-center flex items-center justify-center font-bold uppercase flex-grow py-2 rounded-b-md",
    class: _vm.statusButton(_vm.campaign.status)
  }, [_vm._v(" " + _vm._s(_vm.campaign.status) + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }