<template>
  <div class="w-full">
    <div class="flex ">
      <YInput
        v-model="filters.name.value"
        label="Name"
        class="flex-auto m-1"
      ></YInput>
      <YInput
        v-model="filters.tags.value"
        label="Tags"
        class="flex-auto m-1"
      ></YInput>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  methods: {},
  computed: {
    ...mapState({
      filters: state => state.templateImages.filters
    })
  },
  mounted() {}
}
</script>

<style></style>
