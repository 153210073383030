<template>
  <div class="inline-flex cursor-pointer" @click="setNewSortOrder">
    <slot> </slot>
    <Icon :name="icon" :size="4" class="ml-1" :class="classes" />
  </div>
</template>

<script>
export default {
  props: {
    sortOrder: {
      type: Array,
      required: true
    },
    column: {
      type: String,
      required: true
    },
    order: {
      type: String,
      default: 'DESC'
    }
  },
  components: {},
  data() {
    return {
      sortItem: {
        column: this.column,
        order: this.order
      }
    }
  },
  computed: {
    icon() {
      if (this.sortItem.order == 'ASC') {
        return 'arrowUp'
      } else {
        return 'arrowDown'
      }
    },
    classes() {
      let classes = []

      if (this.isActive) {
        classes.push('text-blue-600')
      } else {
        classes.push('text-gray-400')
      }

      return classes
    },
    isActive() {
      return (
        this.sortOrder.filter(sortItem => sortItem.column == this.column).length >
        0
      ) // exists in sortOrder
    }
  },
  mounted() {},
  updated() {},
  methods: {
    toggleOrderDirection() {
      this.sortItem = {
        column: this.sortItem.column,
        order: this.sortItem.order == 'ASC' ? 'DESC' : 'ASC'
      }
    },
    setNewSortOrder() {
      if (this.isActive) {
        this.toggleOrderDirection()
      }
      this.$emit('sort', [this.sortItem])
    },
    addToSortOrder() {
      // TODO: enable mutiple sortBys at once
    }
  }
}
</script>
<style scoped></style>
