var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('span', {
    staticClass: "upperCase",
    domProps: {
      "textContent": _vm._s(_vm.transformText)
    }
  }), _c('Icon', {
    staticClass: "ml-2",
    class: _vm.eventType == 'positive' ? 'text-blue-600' : 'text-red-600',
    attrs: {
      "name": _vm.eventType == 'positive' ? 'alertCircleOutline' : 'alertOctagon',
      "size": "6"
    }
  })], 1)]), _c('widget-body', [_vm.eventType ? _c('div', {
    staticClass: "x-form px-3"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.message,
      expression: "message"
    }],
    staticClass: "px-3 py-3 w-full rounded mb-2 border block",
    attrs: {
      "placeholder": "Type here..."
    },
    domProps: {
      "value": _vm.message
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        return _vm.saveAndClose();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.message = $event.target.value;
      }
    }
  })]) : _vm._e()]), _c('widget-footer', {
    staticClass: "px-4 py-2 bg-gray-100 text-right"
  }, [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.saveAndClose
    }
  }, [_vm._v("Add "), _c('span', {
    staticClass: "upperCase",
    domProps: {
      "textContent": _vm._s(_vm.transformText)
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }