<template>
  <widget>
    <widget-header @close="$emit('close')">
      Edit Tag
    </widget-header>
    <widget-body>
      <form class="row" @submit.prevent="update" ref="form">
        <div class="col">
          <div class="row">
            <div class="input-group" :class="colSize">
              <YInput v-model="tag.name" label="Name"></YInput>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="input-group" :class="colSize">
              <SuperSelect
                title="Type"
                v-model="tag.type"
                :multiple="false"
                :allow-empty="false"
                :options="typeOptions"
              ></SuperSelect>
            </div>
          </div>
          <br />
        </div>
      </form>
    </widget-body>
    <widget-footer class="bg-gray-100 py-2 px-4 text-right">
      <YButton
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="editTag"
        @keydown.enter="editTag"
        >Edit Tag</YButton
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import SuperSelect from '@/components/ui/Superselect.vue'
import UPDATE_TAG_MUTATION from '@/graphql/Tag/UpdateTagMutation.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import TagDefaultColors from '@/mixins/TagDefaultColours.js'

export default {
  mixins: [ValidationErrorsMixin, TagDefaultColors],
  components: {
    SuperSelect
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    color: {
      type: String,
      requied: true
    }
  },

  apollo: {
    tags: {
      query: TAGS_QUERY,
      variables: {
        first: 500
      },
      result(result) {
        let output = result.data.tags.data.map(tag => {
          let result = tag.type

          return result
        })
        this.typeOptions = [...new Set(output)].sort()
      }
    }
  },
  data() {
    return {
      tag: {
        name: this.name,
        type: this.type,
        color: this.color
      },
      isSubmitting: false,
      colSize: 'col-xl-3 col-lg-6',
      colSize2: 'col-xl-6 col-lg-12',
      typeOptions: []
    }
  },
  watch: {},
  computed: {},
  methods: {
    editTag() {
      this.isSubmitting = true

      let input = {
        id: this.id,
        name: this.tag.name,
        color: this.tagDefaultColour(this.tag.type),
        type: this.tag.type
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_TAG_MUTATION,
          variables: {
            input: input
          }
        })
        .then(result => {
          console.log(result)
          this.clearValidationErrors()
          this.$events.emit('refreshTags')
          this.$toastr.s('Tag Updated', 'Success!')
          this.$emit('close')
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)

          console.log(error)
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
