<template>
  <Widget>
    <WidgetHeader>Create Secondary Contact</WidgetHeader>
    <WidgetBody>
      <div class="grid grid-cols-1 gap-2">
        <YInput class="mb-1" label="Name" v-model="input.name" />
        <YInput class="mb-1" label="Email" v-model="input.email" />
        <YInput
          class="mb-1"
          label="Messenger Handle"
          v-model="input.messengerHandle"
        />
        <Superselect
          title="Messenger App"
          v-model="input.messengerType"
          :multiple="false"
          :options="['telegram', 'skype']"
          class="mb-1"
        ></Superselect>
      </div>
      <div class="w-full flex justify-end gap-3 mt-4">
        <YButton color="gray" @click="$emit('close')">Cancel</YButton>
        <YButton color="blue" @click="update">Save</YButton>
      </div>
    </WidgetBody>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    user_id: {
      type: String,
      required: true
    }
  },
  apollo: {},
  data() {
    return {
      isUpdating: false,
      countries: [],
      input: {
        name: undefined,
        email: undefined,
        messengerType: undefined,
        messengerHandle: undefined
      }
    }
  },
  computed: {},
  methods: {
    update() {
      this.validationErrors = null
      this.isUpdating = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createContact($input: CreateContactInput!) {
              createContact(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: {
              type: 'secondary',
              user_id: this.user_id,
              name: this.input.name ?? undefined,
              email: this.input.email ?? undefined,
              messenger:
                this.input.messengerType && this.input.messengerHandle
                  ? JSON.stringify({
                      handle: this.input.messengerHandle,
                      app: this.input.messengerType
                    })
                  : undefined
            }
          }
        })
        .then(() => {
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Secondary Contact Details Added')
          this.$emit('close')
          this.$events.emit('refreshCommunications')
        })
        .catch(error => {
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.setValidationErrors(error)
          this.$emit('close')
        })
    }
  }
}
</script>
