<template>
  <div>
    <div class="block w-full" style="min-height:65px">
      <portal to="content-top-bar-left-header">
        <div class="flex items-center">
          <router-link
            :to="{
              name: 'Onboarding'
            }"
          >
            <YButton color="inverse">
              <Icon
                name="arrowLeft"
                size="4"
                class="inline mr-1"
                style="margin-bottom: 3px"
              />Back to CRM</YButton
            >
          </router-link>
        </div>
      </portal>
    </div>

    <div
      class="flex flex-wrap w-3/4 m-auto justify-center text-sm bg-white p-6 rounded-lg border shadow-xl text-center"
      style="max-width: 24rem;"
    >
      <p class="text-lg mb-4 text-inverse-700">
        <strong
          >Thank you for registering your contact information with YTZ!</strong
        >
      </p>
      <p class="text-gray-900">
        Our team will reach out soon to give you a personalized overview of our
        platform.
      </p>
      <div class="progress mt-5">
        <div class="progress-value"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    setTimeout(() => this.$router.push({ path: '/crm' }), 18000)
  }
}
</script>

<style scoped>
.progress {
  background: #fff;
  justify-content: flex-start;
  border-radius: 10px;
  align-items: center;
  position: relative;
  padding: 1px;
  display: flex;
  height: 4px;
  width: 500px;
}

.progress-value {
  animation: loadingbar 20s linear forwards;
  border-radius: 10px;
  background: #e5386f;
  height: 4px;
}
@keyframes loadingbar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>
