<template>
  <YAlert color="red" v-if="me && me.isAPublisher" class="mt-0 mb-0 px-6">
    <div class="flex justify-between items-center">
      <div>
        <h6 class="mb-0 font-weight-semibold">
          Due to system maintenance today's stats (May 3rd) may be delayed. This
          will be fixed within 24 hours. Links and postbacks are working
          normally. Thanks for your understanding.
        </h6>
      </div>
    </div>
  </YAlert>
</template>

<script>
import ME_QUERY from '@/graphql/User/MeQuery.gql'

export default {
  apollo: {
    me: {
      query: ME_QUERY
    }
  }
}
</script>

<style></style>
