<template>
  <Widget class="bg-blue-500 text-white border-blue-600">
    <WidgetHeader @close="$emit('close')" close-class="text-white">
      <span class="text-white">What now?</span>
    </WidgetHeader>
    <WidgetBody>
      <p class="text-yellow-400 font-semibold mb-2">
        Campaign #{{ id }} has been re-activated.
      </p>
      <template>
        <p class="mb-2">You may want to:</p>
        <YButton
          v-if="!emailsSent"
          :is-loading="isSendingEmails"
          type="button"
          class="btn-blue bg-blue-600 mx-auto block my-6"
          @click="send"
        >
          Send Campaign Re-activation Emails
        </YButton>
        <div v-if="emailsSent" class="text-center">
          <Tag class="mt-2 mx-auto" type="success">
            <span class="fw-500 flex items-center mx-auto"
              ><Icon name="check" class="mr-2" /> Emails Sent</span
            >
          </Tag>
        </div>
      </template>
    </WidgetBody>
    <WidgetFooter class="bg-dark-transparent-1 px-4 py-2">
      <YButton
        type="button"
        class="btn-blue bg-blue-500 hover:bg-blue-600 focus:bg-blue-600"
        @click="$emit('close')"
      >
        Close
      </YButton>
    </WidgetFooter>
  </Widget>
</template>

<script>
import SEND_CAMPAIGN_STATUS_UPDATE_EMAILS_MUTATION from '@/graphql/Campaign/SendCampaignStatusUpdateEmailsMutation.gql'

export default {
  props: {
    id: {
      id: Number,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      isSendingEmails: false,
      emailsSent: false,
      campaign: null
    }
  },
  watch: {},
  computed: {},
  methods: {
    send() {
      this.isSendingEmails = true

      this.$apollo
        .mutate({
          mutation: SEND_CAMPAIGN_STATUS_UPDATE_EMAILS_MUTATION,
          variables: {
            campaignId: this.id
          }
        })
        .then(result => {
          this.$toastr.s('Emails Sent', 'Success!')
          console.log(result)
          this.isSendingEmails = false
          this.emailsSent = true
          this.$events.emit('campaignReactivatedEmailsSent', this.id)
          //this.$emit('close')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSendingEmails = false
        })

      //this.$emit('close')
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-primary .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
