<template>
  <widget>
    <widget-header @close="$emit('close')">
      Edit Template Image
    </widget-header>
    <widget-body>
      <div class="row flex items-center mb-3">
        <div :class="style.columnTitle">
          Name
        </div>
        <div class="col" v-text="this.imageName"></div>
      </div>
      <div class="row flex items-center mb-3">
        <div :class="style.columnTitle">
          Change Image
        </div>
        <div class="col">
          <ImageInput
            class="rounded"
            :imageUrl="imageUrl"
            :thumbnailImageUrl="imageThumbnailUrl"
            :height="32"
            :width="32"
            :blankHeight="20"
            :blankWidth="20"
            :editable="true"
            :editable-only-when-empty="false"
            :file.sync="file"
            :key="imageInputKey"
            :show-loader="isSubmitting"
          ></ImageInput>
        </div>
      </div>
      <div class="row flex items-center mb-3">
        <div :class="style.columnTitle">
          Tags
        </div>
        <div class="col w-2/3">
          <SuperSelect
            title="Tags"
            :options="tags"
            v-model="tagSelection"
            :settings="{ edit: false }"
            editPlaceholder="Add New Tag"
          ></SuperSelect>
        </div>
      </div>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <YButton color="blue" @click="update">Update</YButton>
    </widget-footer>
  </widget>
</template>

<script>
import ImageInput from '@/components/utilities/ImageInput'
import SuperSelect from '@/components/ui/Superselect.vue'
import UPDATE_TEMPLATE_IMAGE from '@/graphql/TemplateImages/UpdateTemplateImage.gql'

import gql from 'graphql-tag'

export default {
  components: {
    ImageInput,
    SuperSelect
  },
  data() {
    return {
      imageName: this.templateImage.name,
      imageID: this.templateImage.id,
      tagSelection: ['manualTags'],
      file: null,
      isSubmitting: false,
      style: {
        columnTitle: 'col w-1/3 px-4 my-3 font-bold text-gray-700'
      },
      imageInputKey: this.generateNewImageInputKey()
    }
  },
  props: {
    templateImage: {
      type: Object,
      required: true
    }
  },
  updated() {},
  methods: {
    generateNewImageInputKey() {
      return Math.floor(Math.random() * (10000 - 0 + 1)) + 0
    },
    update() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_TEMPLATE_IMAGE,
          variables: {
            input: {
              id: this.imageID,
              tags: this.tagSelection
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$events.emit('refreshTemplateImages')
          this.$toastr.s('Template Updated')
          this.$emit('close')
        })
        .catch(error => {
          this.isSumitting = false
          console.error(error)
        })
    }
  },
  watch: {
    file: function(file) {
      this.isSubmitting = true
      if (file) {
        this.$apollo
          .mutate({
            mutation: UPDATE_TEMPLATE_IMAGE,
            variables: {
              input: {
                id: this.imageID,
                file: file
              }
            }
          })
          .then(() => {
            this.isSubmitting = false
            this.$toastr.s('Image Uploaded')
            this.imageInputKey = this.generateNewImageInputKey()
          })
          .catch(error => {
            this.isSumitting = false
            console.error(error)
          })
      }
    }
  },
  mounted() {
    this.tagSelection = this.templateImage.tags.map(tag => tag.name)
  },
  apollo: {
    tags: {
      query: gql`
        query Tags(
          $first: Int!
          $page: Int
          $filters: TagFiltersInput
          $orderBy: [OrderByClause!]
        ) {
          tags(
            first: $first
            page: $page
            filters: $filters
            orderBy: $orderBy
          ) {
            data {
              id
              name
              type
            }
          }
        }
      `,
      variables: {
        first: 100,
        filters: {
          type: {
            value: ['vertical']
          }
        }
      },
      result(result) {
        let output = result.data.tags.data.map(tag => {
          return tag.name
        })

        this.tags = output
      }
    }
  },
  computed: {
    imageUrl() {
      return this.templateImage.imageUrl + '?v=' + this.imageInputKey
    },
    imageThumbnailUrl() {
      return this.templateImage.imageThumbnailUrl + '?v=' + this.imageInputKey
    },
    selectedTags: {
      get() {
        return this.$store.state.tags
      },
      set(value) {
        let addTag = value[0]
        this.tagSelection.push(addTag)
      }
    }
  }
}
</script>
<style scoped></style>
