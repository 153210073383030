var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "contenteditable": ""
    },
    on: {
      "input": _vm.update
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }