var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-popover', {
    staticClass: "relative inline-block",
    attrs: {
      "autoHide": true
    }
  }, [_c('YButton', {
    staticClass: "px-5 text-blue-600 hover:bg-blue-100",
    class: _vm.model === 'Domain' ? 'font-normal' : ''
  }, [_c('span', {
    staticClass: "flex items-center"
  }, [_c('img', {
    staticClass: "-ml-1 mr-2 h-2",
    attrs: {
      "src": require("@/assets/images/logo/spamhaus-logo.png")
    }
  }), _vm._v(" Check Spamhaus ")])]), _c('template', {
    slot: "popover"
  }, [_c('ul', [_c('li', [_c('YButton', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "text-blue-600 hover:bg-blue-100 w-full",
    on: {
      "click": function ($event) {
        return _vm.runSpamhausCheck(_vm.rowData.id);
      }
    }
  }, [_vm._v(" Manual Check ")])], 1), _c('li', [_c('YButton', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }],
    staticClass: "text-blue-600 bg-blue-100 hover:bg-blue-200 rounded-t-none",
    on: {
      "click": function ($event) {
        return _vm.mutateSpamhausCheck(_vm.rowData);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.rowData.spamhausCheck && _vm.rowData.spamhausCheck.enabled ? 'Disable' : 'Enable') + " Auto Check ")])], 1)])]), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }