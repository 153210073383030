var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_vm._m(0), _c('div', [_c('YButton', {
    staticClass: "mr-1",
    attrs: {
      "color": "red",
      "is-loading": _vm.isFlushing
    },
    on: {
      "click": function ($event) {
        return _vm.flushYredirCache();
      }
    }
  }, [_vm._v("Flush Yredir")]), _c('YButton', {
    staticClass: "mr-1",
    attrs: {
      "color": "red",
      "is-loading": _vm.isFlushing
    },
    on: {
      "click": function ($event) {
        return _vm.flushCache();
      }
    }
  }, [_vm._v("Flush Cache")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": _vm.isProcessingQueue
    },
    on: {
      "click": function ($event) {
        return _vm.processQueue();
      }
    }
  }, [_vm._v("Process Queue")])], 1)]), _c('div', [_c('YAlert', {
    staticClass: "mt-4"
  }, [_vm._v("Currently will only display first " + _vm._s(_vm.first) + " invalidations")]), _c('YTable', {
    staticClass: "mt-4",
    attrs: {
      "empty-text": "No Invalidations Queued",
      "data": _vm.queuedInvalidations,
      "loading": _vm.$apollo.queries.queuedInvalidations.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(_vm._s(props.rowData.key))]), _c('td', [_vm._v(_vm._s(props.rowData.target))]), _c('td', [_vm._v(_vm._s(props.rowData.invalidateAt))])];
      }
    }])
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Key")]), _c('th', [_vm._v("Target")]), _c('th', [_vm._v("Invalidate At")])])], 2)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', {
    staticClass: "text-base font-bold"
  }, [_vm._v("Queued Invalidations")])]);

}]

export { render, staticRenderFns }