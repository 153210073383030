<template>
  <Widget>
    <WidgetHeader class="pt-4 pb-4">
      <div class="flex justify-between items-center w-full">
        <Icon name="videoInputAntenna" class="mr-2" />
        Replace IP
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <div>
        Click <span class="text-blue-600 font-semibold">Replace</span> to
        automatically replace
        <span class="text-blue-600 font-semibold">{{ ip }}</span>
      </div>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <y-checkbox
        v-model="closeOnComplete"
        label="Close on Complete"
        class="flex items-center"
      ></y-checkbox>
      <YButton
        :is-loading="isSubmitting"
        color="blue"
        class="ml-auto"
        @click="submit"
        @keydown.enter="submit"
        >Replace</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    ip: {
      type: String,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      closeOnComplete: true,
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    submit() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation yreplaceQueue($input: YreplaceInput!) {
              yreplaceQueue(input: $input) {
                result
                error
              }
            }
          `,
          variables: {
            input: {
              replacementValue: this.ip,
              replacementType: 'ip'
            }
          }
        })
        .then(result => {
          this.$toastr.success(
            'Replaced: ' + result.data.yreplaceQueue[0]['result'],
            'Success!'
          )
          this.errors = {}
          this.isSubmitting = false
          this.$emit('success', result)
          this.$events.emit('refreshDomainmanagerDomains', true)
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.$toastr.error(error, 'Oops!')
        })
    }
  },

  close() {
    this.$emit('close')
  }
}
</script>
<style></style>
