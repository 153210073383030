let providerFilters = {
  id: {
    value: [],
    settings: {
      filtersProviders: true,
      filtersServers: false,
      filtersIps: false
    }
  },
  type: {
    value: [],
    settings: {
      filtersProviders: true,
      filtersServers: false,
      filtersIps: false
    }
  },
  server: {
    value: [],
    settings: {
      filtersProviders: true,
      filtersServers: true,
      filtersIps: false
    }
  },
  serverIpType: {
    value: [],
    settings: {
      filtersProviders: true,
      filtersServers: true,
      filtersIps: true
    }
  },
  serverIp: {
    value: [],
    modifiers: {
      matchType: 'exact'
    },
    settings: {
      filtersProviders: true,
      filtersServers: true,
      filtersIps: true
    }
  }
}

let domainFilters = {
  id: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: false,
      edit: false
    }
  },
  registrar: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: false,
      edit: false
    }
  },
  type: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: false
    }
  },
  status: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: false
    }
  },
  publisherStatus: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: false
    }
  },
  tags: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: false
    }
  },
  publisher: null,
  recordProvider: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: true,
      edit: false
    }
  },
  recordServer: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: true
    }
  },
  recordContent: {
    value: [],
    modifiers: {
      matchType: 'exact'
    },
    settings: {
      filtersDomains: true,
      filtersRecords: true
    }
  },
  recordSubdomain: {
    value: [],
    modifiers: {
      matchType: 'exact'
    },
    settings: {
      filtersDomains: true,
      filtersRecords: true
    }
  },
  recordType: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: true
    }
  },
  linkedNameServerDomains: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: false
    }
  },
  nameserverId:{
    value: [],
    modifiers: {
      matchType: 'exact'
    },
    settings: {
      filtersDomains: true,
      filtersRecords: false
    }
  },
  nameServerIp: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: false
    }
  },
  nameServerProvider: {
    value: [],
    settings: {
      filtersDomains: true,
      filtersRecords: false
    }
  },

}

const transformFiltersForGraphql = function(
  filters,
  filterKeyToExtract,
  transformKey = null
) {
  Object.keys(filters).map(function(name) {
    let filterValues = filters[name].value.map(
      value => value[filterKeyToExtract]
    )
    let filterKey = transformKey ? transformKey(name) : name
    if (filterValues.length > 0) {
      filters[filterKey] = {
        value: filterValues,
        modifiers: filters[name].modifiers
      }
      if (filterKey != name) {
        delete filters[name]
      }
    } else {
      delete filters[name]
    }
  })
  return filters
}

const filterFiltersBySettings = function(filters, settings) {
  Object.keys(filters).forEach(filterKey => {
    for (let settingKey in settings) {
      if (filters[filterKey].settings[settingKey] !== settings[settingKey]) {
        delete filters[filterKey]
      }
    }
  })
  return filters
}

export const namespaced = true

export const state = {
  providerFiltersDefault: JSON.stringify(providerFilters),
  providerFilters: JSON.stringify(providerFilters),
  domainFiltersDefault: JSON.stringify(domainFilters),
  domainFilters: JSON.stringify(domainFilters),
  domainsTableSortOrder: [{ column: 'expiry', order: 'ASC' }],
  domainsShowColumnsFilter: [
    {
      id: 'id',
      label: 'Domain'
    },
    {
      id: 'registrar',
      label: 'Registrar'
    },
    {
      id: 'type',
      label: 'Type'
    },
    {
      id: 'expiry',
      label: 'Expiry'
    },
    {
      id: 'tags',
      label: 'Tags'
    },
    {
      id: 'status',
      label: 'Status'
    },
    {
      id: 'publishers',
      label: 'Publishers'
    },
    {
      id: 'records',
      label: 'Records'
    },
    {
      id: 'records:type',
      label: 'Records:Type'
    },
    {
      id: 'records:provider',
      label: 'Records:Provider'
    },
    {
      id: 'publisherHistory',
      label: 'Publisher History'
    },
    {
      id: 'dns',
      label: 'DNS'
    }
  ],
  domainsShowColumnsFilterOptions: [
    {
      id: 'id',
      label: 'Domain'
    },
    {
      id: 'registrar',
      label: 'Registrar'
    },
    {
      id: 'type',
      label: 'Type'
    },
    {
      id: 'expiry',
      label: 'Expiry'
    },
    {
      id: 'tags',
      label: 'Tags'
    },
    {
      id: 'status',
      label: 'Status'
    },
    {
      id: 'publishers',
      label: 'Current Publishers'
    },
    {
      id: 'records',
      label: 'Records'
    },
    {
      id: 'records:type',
      label: 'Records:Type'
    },
    {
      id: 'records:provider',
      label: 'Records:Provider'
    },
    {
      id: 'records:ttl',
      label: 'Records:TTL'
    },
    {
      id: 'records:priority',
      label: 'Records:Priority'
    },
    {
      id: 'publisherHistory',
      label: 'Publisher History'
    },
    {
      id: 'dns',
      label: 'DNS'
    }
  ]
}

export const getters = {
  domainsTableSortOrder: state => state.domainsTableSortOrder,
  providerFilters: state => JSON.parse(state.providerFilters),
  providerFiltersDefault: state => JSON.parse(state.providerFiltersDefault),
  providerFiltersGql: state => {
    let filters = JSON.parse(state.providerFilters)
    filters = filterFiltersBySettings(filters, { filtersProviders: true })
    filters = transformFiltersForGraphql(filters, 'id')
    return filters
  },
  providerServerFiltersGql: state => {
    let filters = JSON.parse(state.providerFilters)
    filters = filterFiltersBySettings(filters, { filtersServers: true })
    filters = transformFiltersForGraphql(filters, 'id', function(key) {
      let value = key.replace('server', '')
      return value.charAt(0).toLowerCase() + value.slice(1)
    })
    return filters
  },
  providerServerIpFiltersGql: state => {
    let filters = JSON.parse(state.providerFilters)
    filters = filterFiltersBySettings(filters, { filtersIps: true })
    filters = transformFiltersForGraphql(filters, 'id', function(key) {
      let value = key.replace('serverIp', '')
      return value == '' ? 'id' : value.charAt(0).toLowerCase() + value.slice(1)
    })
    return filters
  },
  domainFilters: state => JSON.parse(state.domainFilters),
  domainFiltersDefault: state => JSON.parse(state.domainFiltersDefault),
  domainsShowColumnsFilter: state =>
    JSON.parse(JSON.stringify(state.domainsShowColumnsFilter)),
  domainsShowColumnsFilterOptions: state =>
    JSON.parse(JSON.stringify(state.domainsShowColumnsFilterOptions))
}

export const mutations = {
  UPDATE_PROVIDER_FILTERS(state, filters) {
    state.providerFilters = JSON.stringify(filters)
  },
  UPDATE_DOMAIN_FILTERS(state, filters) {
    state.domainFilters = JSON.stringify(filters)
  },
  UPDATE_DOMAINS_TABLE_SORT_ORDER(state, sortOrder) {
    state.domainsTableSortOrder = sortOrder
  },
  UPDATE_DOMAINS_SHOW_COLUMNS_FILTER(state, showFilters) {
    state.domainsShowColumnsFilter = showFilters
  }
}

export const actions = {
  updateProviderFilters({ commit }, filters) {
    commit('UPDATE_PROVIDER_FILTERS', filters)
  },
  updateDomainFilters({ commit }, filters) {
    commit('UPDATE_DOMAIN_FILTERS', filters)
  },
  updateDomainsTableSortOrder({ commit }, sortOrder) {
    commit('UPDATE_DOMAINS_TABLE_SORT_ORDER', sortOrder)
  },
  updateDomainsShowColumnsFilter({ commit }, showFilters) {
    commit('UPDATE_DOMAINS_SHOW_COLUMNS_FILTER', showFilters)
  }
}
