<template>
  <div class="w-full">
    <div class="w-full mb-1">
      <div class="grid grid-cols-1 md:grid-cols-6 gap-1">
        <YInput v-model="filters.id.value" label="ID"></YInput>
        <YInput v-model="filters.offer.value" label="Name"></YInput>
        <Superselect
          title="Advertisers"
          v-model="filters.advertisers.value"
          :modifiers="{}"
          track-by="id"
          label="label"
          :options="advertiserOptions ? advertiserOptions.data : []"
          :multiple="true"
        ></Superselect>
        <Superselect
          title="Verticals"
          v-model="filters.verticalTags.value"
          :modifiers="filters.verticalTags.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['vertical'] }
            },
            first: 100
          }"
        ></Superselect>
        <Superselect
          :title="filters.destinationType.title"
          class="col-span-1"
          :multiple="false"
          :options="['direct', 'template']"
          :hide-selected="false"
          v-model="filters.destinationType.value"
          :modifiers="filters.destinationType.modifiers"
        ></Superselect>
        <YInput
          v-model="filters.template.value"
          :label="filters.template.title"
        ></YInput>
        <Superselect
          title="Status"
          v-model="filters.status.value"
          :modifiers="{}"
          :options="['Active', 'Created', 'Paused', 'Inactive']"
          :multiple="false"
        ></Superselect>
        <Superselect
          :title="filters.mobileDesktopTags.title"
          v-model="filters.mobileDesktopTags.value"
          :modifiers="filters.mobileDesktopTags.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['mobileDesktop'] }
            },
            first: 100
          }"
        ></Superselect>
        <Superselect
          :title="filters.countryCodeTags.title"
          v-model="filters.countryCodeTags.value"
          :modifiers="filters.countryCodeTags.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['countryCode'] }
            },
            first: 300
          }"
        ></Superselect>
        <Superselect
          :title="filters.regionCodeTags.title"
          v-model="filters.regionCodeTags.value"
          :modifiers="filters.regionCodeTags.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['regionCode'] }
            },
            first: 300
          }"
        ></Superselect>
        <Superselect
          :title="filters.trafficTypeTags.title"
          v-model="filters.trafficTypeTags.value"
          :modifiers="filters.trafficTypeTags.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['trafficType'] }
            },
            first: 100
          }"
        ></Superselect>
        <Superselect
          :title="filters.offerTypeTags.title"
          v-model="filters.offerTypeTags.value"
          :modifiers="filters.offerTypeTags.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['offerType'] }
            },
            first: 100
          }"
        ></Superselect>
        <Superselect
          :title="filters.yleaderStatus.title"
          v-model="filters.yleaderStatus.value"
          :modifiers="filters.yleaderStatus.modifiers"
          :options="['yLeader', 'Regular']"
          :multiple="false"
          class="col-span-1"
        ></Superselect>
        <Superselect
          :title="filters.ignoreTimeout.title"
          v-model="filters.ignoreTimeout.value"
          :modifiers="filters.ignoreTimeout.modifiers"
          :options="['No', 'Yes']"
          :multiple="false"
          class="col-span-1"
        ></Superselect>
      </div>
    </div>
    <div class="w-full mt-3">
      <div class="flex">
        <YCheckbox v-model="showTrafficFilters">
          <template v-slot:label>
            <span class="text-gray-600 font-semibold uppercase text-xs my-1">
              Emulate Traffic
            </span>
          </template>
        </YCheckbox>
      </div>
      <div
        v-if="showTrafficFilters"
        class="grid grid-cols-6 sm:grid-cols6 gap-1"
      >
        <Superselect
          title="Publisher"
          v-model="filters.publisher.value"
          :modifiers="filters.publisher.modifiers"
          :options="[]"
          track-by="id"
          label="label"
          :multiple="false"
          :query="PUBLISHER_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>

        <Superselect
          title="Campaign"
          v-model="filters.campaign.value"
          track-by="id"
          label="label"
          :modifiers="filters.campaign.modifiers"
          :multiple="false"
          :options="[]"
          :query="CAMPAIGN_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
        <Superselect
          title="Redirect"
          v-model="filters.redirect.value"
          track-by="id"
          label="label"
          :modifiers="{}"
          :multiple="false"
          :options="[]"
          :query="REDIRECT_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
        ></Superselect>
        <YInput v-model="filters.source.value" label="Source"></YInput>
        <Superselect
          :title="filters.mobileDesktopTrafficTags.title"
          v-model="filters.mobileDesktopTrafficTags.value"
          :modifiers="filters.mobileDesktopTrafficTags.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['mobileDesktop'] }
            },
            first: 100
          }"
        ></Superselect>
        <Superselect
          :title="filters.countryCodeTrafficTags.title"
          v-model="filters.countryCodeTrafficTags.value"
          :modifiers="filters.countryCodeTrafficTags.modifiers"
          label="name"
          track-by="name"
          :query="TAGS_QUERY"
          :query-variables="{
            filters: {
              type: { value: ['countryCode'] }
            },
            first: 100
          }"
        ></Superselect>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ADVERTISER_OPTIONS_QUERY from '@/graphql/Advertiser/AdvertiserOptionsQuery.gql'
import USER_OPTIONS_QUERY from '@/graphql/User/UserOptionsQuery.gql'
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import TRAFFIC_TAG_TYPES_QUERY from '@/graphql/Tag/TrafficTagTypesQuery.gql'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
export default {
  components: {},
  apollo: {
    trafficTagTypes: {
      query: TRAFFIC_TAG_TYPES_QUERY,
      result({ data }) {
        this.trafficTagTypes = data.trafficTagTypes.enumValues.map(
          value => value.name
        )
      }
    },
    advertiserOptions: {
      query: ADVERTISER_OPTIONS_QUERY,
      variables: {
        first: 100000,
        filters: {
          roles: {
            name: { value: 'advertiser' }
          }
        }
      }
    },
    redirectOptions: {
      query: REDIRECT_OPTIONS_QUERY,
      variables: {
        first: 1000000
      }
    }
  },
  data() {
    return {
      PUBLISHER_OPTIONS_QUERY,
      CAMPAIGN_OPTIONS_QUERY,
      REDIRECT_OPTIONS_QUERY,
      ADVERTISER_OPTIONS_QUERY,
      USER_OPTIONS_QUERY,
      TAGS_QUERY,
      countries: [],
      showTrafficFilters: false
    }
  },
  methods: {},
  watch: {
    showTrafficFilters: {
      handler() {
        this.$events.emit('emulateTraffic')
      }
    }
  },
  computed: {
    ...mapState({
      filters: state => state.offer.filters
    })
  }
}
</script>

<style></style>
