var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "bg-red-600 text-white border-red-700"
  }, [_c('WidgetHeader', {
    staticClass: "bg-red-600 text-white"
  }, [_c('span', {
    staticClass: "text-white"
  }, [_vm._v("Are you sure?")])]), _c('WidgetBody', [_c('h5', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v("This action cannot be undone.")]), _c('h5', [_vm._v(" Please confirm you'd like to "), _c('span', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v("DELETE "), _c('span', {
    staticClass: "px-1 underline text-lg"
  }, [_vm._v(" " + _vm._s(this.rows.length) + " items")])]), _vm._v(" selected from Template Images. ")])]), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 flex justify-between py-2 px-4"
  }, [_c('YButton', {
    staticClass: "hover:bg-red-700 focus:bg-red-700",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    staticClass: "hover:bg-red-700 focus:bg-red-700",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.deleteImage,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.deleteImage.apply(null, arguments);
      }
    }
  }, [_vm._v("Delete Template Images")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }