var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-5 gap-1"
  }, [_c('YInput', {
    attrs: {
      "label": "ID"
    },
    model: {
      value: _vm.filters.id.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.id, "value", $$v);
      },
      expression: "filters.id.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Login"
    },
    model: {
      value: _vm.filters.email.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.email, "value", $$v);
      },
      expression: "filters.email.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.filters.contactEmail.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.contactEmail, "value", $$v);
      },
      expression: "filters.contactEmail.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Manager",
      "modifiers": _vm.filters.accountManager.modifiers,
      "multiple": false,
      "track-by": "id",
      "label": "label",
      "query": _vm.ACCOUNT_MANAGER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: [],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      }
    },
    model: {
      value: _vm.filters.accountManager.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.accountManager, "value", $$v);
      },
      expression: "filters.accountManager.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "IM Handle"
    },
    model: {
      value: _vm.filters.instantMessengerHandle.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.instantMessengerHandle, "value", $$v);
      },
      expression: "filters.instantMessengerHandle.value"
    }
  }), _c('superselect', {
    attrs: {
      "title": "Country",
      "modifiers": _vm.filters.country.modifiers,
      "multiple": true,
      "label": "label",
      "track-by": "code",
      "options": _vm.countries,
      "loading": _vm.$apollo.queries.countries.loading
    },
    model: {
      value: _vm.filters.country.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.country, "value", $$v);
      },
      expression: "filters.country.value"
    }
  }), _c('superselect', {
    attrs: {
      "title": "Role",
      "modifiers": _vm.filters.role.modifiers,
      "multiple": false,
      "options": _vm.filterOptions.role
    },
    model: {
      value: _vm.filters.role.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.role, "value", $$v);
      },
      expression: "filters.role.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Referrer",
      "modifiers": _vm.filters.referrer.modifiers,
      "options": _vm.publisherOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "multiple": true,
      "close-on-select": false,
      "query": _vm.PUBLISHER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": "Paste Publishers"
    },
    model: {
      value: _vm.filters.referrer.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.referrer, "value", $$v);
      },
      expression: "filters.referrer.value"
    }
  }), _c('superselect', {
    attrs: {
      "title": "Status",
      "modifiers": _vm.filters.status.modifiers,
      "multiple": false,
      "options": _vm.filterOptions.status
    },
    model: {
      value: _vm.filters.status.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Traffic Sources",
      "modifiers": _vm.filters.trafficType.modifiers,
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['trafficType']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.trafficType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.trafficType, "value", $$v);
      },
      expression: "filters.trafficType.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Verticals",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['vertical']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.verticals.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.verticals, "value", $$v);
      },
      expression: "filters.verticals.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Referred Type",
      "modifiers": _vm.filters.referredOption.modifiers,
      "options": _vm.referedTypeFilter()
    },
    model: {
      value: _vm.filters.referredOption.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.referredOption, "value", $$v);
      },
      expression: "filters.referredOption.value"
    }
  }), _c('Superselect', {
    staticClass: "capitalize",
    attrs: {
      "title": "Priority Status",
      "multiple": false,
      "options": ['regular', 'high', 'rejected']
    },
    model: {
      value: _vm.filters.priorityStatus.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.priorityStatus, "value", $$v);
      },
      expression: "filters.priorityStatus.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Tracking Platform",
      "label": "name",
      "track-by": "name",
      "query": _vm.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['trackingPlatform']
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.trackingPlatform.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.trackingPlatform, "value", $$v);
      },
      expression: "filters.trackingPlatform.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Complaints",
      "label": "label",
      "track-by": "id",
      "options": [{
        id: 'qualityFlag',
        label: 'Has Quality Complaints'
      }, {
        id: 'fraudFlag',
        label: 'Has Fraud Complaints'
      }, {
        id: 'complianceFlag',
        label: 'Has Compliance Complaints'
      }]
    },
    model: {
      value: _vm.filters.qualityMetrics.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.qualityMetrics, "value", $$v);
      },
      expression: "filters.qualityMetrics.value"
    }
  })], 1), _c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-5 gap-1 h-10 items-center"
  }, [_c('div', {
    staticClass: "ml-1 flex items-center"
  }, [_c('label', {
    staticClass: "inline-flex items-center"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.ipHistory.value,
      expression: "filters.ipHistory.value"
    }],
    staticClass: "w-4 h-4 rounded",
    attrs: {
      "type": "checkbox",
      "id": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.filters.ipHistory.value) ? _vm._i(_vm.filters.ipHistory.value, null) > -1 : _vm.filters.ipHistory.value
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.filters.ipHistory.value,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.filters.ipHistory, "value", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.filters.ipHistory, "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.filters.ipHistory, "value", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Duplicate IP Logged")])])]), _c('div', {
    staticClass: "ml-1 flex items-center"
  }, [_c('label', {
    staticClass: "inline-flex items-center"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.autoRejected.value,
      expression: "filters.autoRejected.value"
    }],
    staticClass: "w-4 h-4 rounded",
    attrs: {
      "type": "checkbox",
      "id": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.filters.autoRejected.value) ? _vm._i(_vm.filters.autoRejected.value, null) > -1 : _vm.filters.autoRejected.value
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.filters.autoRejected.value,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.filters.autoRejected, "value", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.filters.autoRejected, "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.filters.autoRejected, "value", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Auto-Rejected Users")])])]), _c('div', {
    staticClass: "ml-1 flex items-center"
  }, [_c('label', {
    staticClass: "inline-flex items-center"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.autoApproved.value,
      expression: "filters.autoApproved.value"
    }],
    staticClass: "w-4 h-4 rounded",
    attrs: {
      "type": "checkbox",
      "id": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.filters.autoApproved.value) ? _vm._i(_vm.filters.autoApproved.value, null) > -1 : _vm.filters.autoApproved.value
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.filters.autoApproved.value,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.filters.autoApproved, "value", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.filters.autoApproved, "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.filters.autoApproved, "value", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Auto-Approved Users")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }