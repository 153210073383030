var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "top"
    }
  }, [_c('Widget', {
    staticClass: "mb-2"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('ImageFilters')], 1)], 2)], 1), _c('ImageRepositoryTable', {
    key: _vm.refetch,
    attrs: {
      "data": _vm.images ? _vm.images.data : []
    }
  }), _vm.images.paginatorInfo ? _c('YPagination', {
    key: _vm.totalPages,
    attrs: {
      "data": _vm.images.paginatorInfo
    },
    on: {
      "load-page": _vm.loadPage,
      "next-page": _vm.nextPage,
      "previous-page": _vm.previousPage,
      "per-page": _vm.perPageSelection
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }