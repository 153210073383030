var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.localOffer ? _c('v-popover', {
    staticClass: "flex items-center px-2 py-2 hover:bg-blue-100 rounded cursor-pointer",
    attrs: {
      "offset": "0"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('span', {
    staticClass: "h-2 w-2 m-2 rounded-full",
    class: _vm.localOffer.status == 'active' ? 'bg-green-500' : _vm.localOffer.status == 'paused' ? 'bg-yellow-500' : _vm.localOffer.status == 'created' ? 'bg-gray-500' : 'bg-red-500'
  }), !_vm.hideLabel ? _c('div', {
    staticClass: "flex-grow font-medium pl-1 pr-2 truncate uppercase text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.localOffer.status == 'paused' ? 'Traffic Paused' : 'Traffic ' + _vm.localOffer.status.capitalize()) + " ")]) : _vm._e()]), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_vm.localOffer.status == 'active' ? _c('YButton', {
    attrs: {
      "color": "yellow"
    },
    on: {
      "click": _vm.pauseOffer
    }
  }, [_vm._v("Pause")]) : _vm._e(), _vm.localOffer.status == 'active' || _vm.localOffer.status == 'paused' ? _c('YButton', {
    attrs: {
      "color": "red"
    },
    on: {
      "click": _vm.deactivateOffer
    }
  }, [_vm._v("Deactivate")]) : _vm._e(), _c('YButton', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.trafficOffer
    }
  }, [_vm._v("Traffic")])], 1)])], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }