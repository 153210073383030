var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-wrap w-3/4 m-auto justify-center text-sm bg-white pt-5 pb-8 px-6 rounded-b-lg border-b border-l border-r shadow-xl",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_c('p', {
    staticClass: "text-lg mb-4 mt-3"
  }, [_c('strong', [_vm._v("Thank you for signing up to YTZ International.")])]), _c('p', [_vm._v(" Your registration is waiting approval by one of our staff, and "), _c('strong', [_vm._v("you will be contacted within 48 hours.")])]), _c('div', {
    staticClass: "flex border-t border-gray-400 mt-3 pt-8 text-inverse-700 w-full text-right justify-end"
  }, [_c('div', {
    staticClass: "text-right"
  }, [_c('p', [_c('strong', [_vm._v("YTZ Affiliates Team")])]), _c('p', [_vm._v("affiliates@ytz.com")])])])]);

}]

export { render, staticRenderFns }