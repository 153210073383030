<template>
  <div class="x-item">
    <slot name="root">
      <div class="x-header">
        <div class="x-header-text">
          {{ title }}
        </div>
        <div class="x-icon-container">
          <div v-if="count" class="x-counter" :class="countClass">
            {{ count }}
          </div>
          <Icon v-if="icon" :name="icon" :size="10"></Icon>
        </div>
      </div>
      <div class="x-body">
        <slot></slot>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String
    },
    count: {
      type: Number
    },
    countClass: {
      type: String
    }
  }
}
</script>

<style scoped>
.x-item {
  @apply border-b px-3 py-2 flex flex-col;
}
.x-header {
  @apply flex items-center justify-between py-2;
}

.x-header-text {
  @apply font-bold mr-3 text-gray-600 uppercase;
}

.x-icon-container {
  @apply relative flex text-gray-300 -mr-2;
}

.x-counter {
  @apply w-5 h-5 rounded-full bg-blue-600 absolute top-0 right-0 -mr-1 -mt-1 text-white text-center text-xs;
}

.x-body {
  @apply flex flex-col flex-1 -mx-3 py-2;
}
</style>
