  export const mergePreviousDataWithMoreData = (previousData, moreData) => {
  return {
    __typename: previousData.__typename,
    data: [...previousData.data, ...moreData.data],
    paginatorInfo: {
      __typename: previousData.paginatorInfo.__typename,
      count: previousData.paginatorInfo.count + moreData.paginatorInfo.count,
      currentPage: 1,
      firstItem: 1,
      hasMorePages: moreData.paginatorInfo.hasMorePages,
      lastItem: moreData.paginatorInfo.lastItem,
      lastPage: moreData.paginatorInfo.lastPage,
      perPage: previousData.paginatorInfo.count + moreData.paginatorInfo.count,
      total: moreData.paginatorInfo.total
    }
  }
}