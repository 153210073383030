<template>
  <widget>
    <widget-header>
      <i class="zmdi zmdi-file-plus pr-2"></i> Traffic Offer
      <h4 slot="right">
        <close-x @click="$emit('close')" class="text-white"></close-x>
      </h4>
    </widget-header>
    <widget-body>
      <div class="m-auto" style="max-width:25rem;">
        <div class="mb-4">
          <RadioBox label="Smart Weight" :value="true" v-model="smartWeight" />
          <!--<div v-show="smartWeight" class="mb-6 mt-4">
            <Superselect
              title="Algo Target"
              v-model="algorithmIds"
              :multiple="false"
              label="label"
              track-by="label"
             :options="[
                  { label: '2, 6', value: [2, 6, 7] },
                  { label: '2', value: [2] },
                  { label: '6', value: [6] },
                  { label: '7', value: [7] },
                ]"
              class="mb-1"
            ></Superselect>
          </div>-->
        </div>
        <div class="mb-1">
          <RadioBox
            label="Manual Weight"
            :value="false"
            v-model="smartWeight"
          />
          <div v-show="!smartWeight" class="mb-6 mt-4">
            <div class="flex mb-2">
              <YInput
                v-model="weight"
                label="Weight"
                class="w-1/2"
                input-class="border-r-0 rounded-r-none"
              ></YInput>
              <YInput
                v-model="min"
                label="Min"
                class="w-1/4"
                input-class="border-l-0 rounded-l-none border-r-0 rounded-r-none"
              ></YInput>
              <YInput
                v-model="max"
                label="Max"
                class="w-1/4"
                input-class="border-l-0 rounded-l-none"
              ></YInput>
            </div>
            <YInput
              label="Expiry Date"
              v-model="expiry"
              dateFormat="YYYY-MM-DD"
              dateFormatted="ll"
              type="date"
              class="mb-2"
            />
            <YInput
              v-if="expiry"
              v-model="fallback"
              label="Fallback value"
              class="mb-2"
            ></YInput>
            <!--<Superselect
              title="Algo Target"
              v-model="algorithmIds"
              :multiple="false"
              label="label"
              track-by="label"
              :options="[
                  { label: '2, 6', value: [2, 6] },
                  { label: '2', value: [2] },
                  { label: '5', value: [5] },
                  { label: '6', value: [6] },
                  { label: '7', value: [7] },
                ]"
            ></Superselect>-->
          </div>
        </div>
      </div>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <y-button
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="traffic(offerId)"
        @keydown.enter="traffic(offerId)"
        >Traffic</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import TRAFFIC_OFFERS_MUTATION from '@/graphql/Offer/TrafficOffersMutation.gql'
import RadioBox from '@/components/ui/RadioBox'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    offerId: {
      type: [Number, String],
      required: true
    },
    exitWarning: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RadioBox
  },
  data() {
    return {
      min: 0.1,
      max: 100.0,
      weight: 1.0,
      fallback: null,
      expiry: null,
      algorithmIds: null,
      smartWeight: true,
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    setExitWarning() {
      window.onbeforeunload = function() {
        return 'Retraffic before leaving.'
      }
    },
    clearExitWarning() {
      window.onbeforeunload = function() {}
    },
    traffic(offerId) {
      this.isSubmitting = true

      let input = {
        offerIds: [offerId],
        algorithmIds: this.algorithmIds?.value ?? null,
        smartWeight: this.smartWeight,
        data: {
          min: this.min,
          max: this.max,
          weight: this.weight,
          expiry: this.expiry,
          fallback: this.fallback ?? undefined
        }
      }
      this.$apollo
        .mutate({
          mutation: TRAFFIC_OFFERS_MUTATION,
          variables: {
            input: input
          }
        })
        .then(() => {
          console.log('trafficking')
          this.isSubmitting = false
          this.clearValidationErrors()
          this.$toastr.s('Trafficking Offers...')

          this.$emit('close')
        })
        .catch(error => {
          console.log('error', error)
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {
    if (this.exitWarning) {
      this.setExitWarning()
    }
  },
  beforeDestroy() {
    if (this.exitWarning) {
      this.clearExitWarning()
    }
  },
  updated() {}
}
</script>
<style scoped>
.x-form > *:not(:last-child) {
  @apply mb-2;
}
</style>
