var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "bg-red-600 border-red-700 text-white"
  }, [_c('WidgetHeader', [_c('template', {
    slot: "rawContent"
  }, [_c('div', [_c('h4', {
    staticClass: "widget-title text-white"
  }, [_vm._v("Delete Preset")]), _c('p', {
    staticClass: "mt-0 mb-0 text-yellow-400 font-bold"
  }, [_c('i', [_c('span', {
    staticClass: "font-bold"
  }, [_vm._v("WARNING:")]), _vm._v(" This action cannot be undone.")])])])]), _c('h4', {
    attrs: {
      "slot": "right"
    },
    slot: "right"
  }, [_c('close-x', {
    on: {
      "click": _vm.close
    }
  })], 1)], 2), _c('WidgetBody', [_c('table', {
    staticClass: "table w-full"
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticClass: "label-cell text-muted"
  }, [_vm._v("Name")]), _c('td', [_vm._v(_vm._s(_vm.presetFilter.name))])]), _c('tr', [_c('td', {
    staticClass: "label-cell text-muted"
  }, [_vm._v("ID")]), _c('td', [_vm._v(_vm._s(_vm.presetFilter.id))])]), _c('tr', [_c('td', {
    staticClass: "label-cell text-muted"
  }, [_vm._v("Author")]), _c('td', [_vm._v(_vm._s(_vm.presetFilter.author.primaryContact.name))])])])])]), _c('WidgetFooter', {
    staticClass: "py-3 px-3"
  }, [_c('div', {
    staticClass: "mr-auto"
  }), _c('div', {
    staticClass: "flex items-center justify-between"
  }, [_c('YButton', {
    staticClass: "hover:bg-red-700 focus:bg-red-700 outline-none-important",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Cancel ")]), _c('YButton', {
    staticClass: "bg-red-700 hover:bg-red-800 focus:bg-red-800 outline-none-important",
    attrs: {
      "isLoading": _vm.isDeleting
    },
    on: {
      "click": _vm.deletePreset
    }
  }, [_vm._v("Delete")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }