<template>
  <Widget>
    <WidgetHeader>
      Request Approval
      <template v-slot:right>
        <div v-if="$apollo.loading" class="w-6">
          <Spinner color="blue" :size="6" />
        </div>
        <div v-else>
          <CloseX @click="$emit('close')"></CloseX>
        </div>
      </template>
    </WidgetHeader>
    <WidgetBody class="pt-0">
      <template v-if="this.requestLimitHit">
        <YAlert :allow-close="false" color="yellow">
          You have reached the maximum allowed number of campaign requests.
        </YAlert>
      </template>
      <template v-else>
        <div class="text-gray-600 mb-2">
          Please describe your traffic and how you plan to promote it.
        </div>
        <textarea
          v-model="reason"
          class="w-full p-4 bg-gray-100 rounded"
          placeholder="Type here..."
        ></textarea>
      </template>
    </WidgetBody>
    <WidgetFooter class="bg-gray-100 flex justify-between px-4 py-2">
      <div></div>
      <YButton
        color="blue"
        @click="submit"
        :is-loading="isSubmitting"
        :disabled="!canSubmit"
        >Submit Request</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import REQUEST_CAMPAIGN_APPROVAL_MUTATION from '@/graphql/PublisherCampaign/RequestCampaignApprovalMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import PUBLISHER_CAMPAIGNS_QUERY from '@/graphql/PublisherCampaign/PublisherCampaignsQuery.gql'

export default {
  mixins: [ValidationErrorsMixin],
  apollo: {
    publisherCampaigns: {
      query: PUBLISHER_CAMPAIGNS_QUERY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          first: 1,
          filters: {
            publisherStatus: {
              value: ['pending approval']
            }
          }
        }
      },
      result({ data }) {
        this.requestedCount = data.publisherCampaigns.paginatorInfo.total
      }
    }
  },
  props: {
    campaignId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      reason: null,
      isSubmitting: false,
      requestedCount: null,
      requestedLimit: 10
    }
  },
  computed: {
    canSubmit() {
      return !this.$apollo.loading && !this.requestLimitHit
    },
    requestLimitHit() {
      if (this.requestedCount === null) {
        return false
      } else if (this.requestedCount >= this.requestedLimit) {
        return true
      }

      return false
    }
  },
  methods: {
    submit() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: REQUEST_CAMPAIGN_APPROVAL_MUTATION,
          variables: {
            input: {
              campaignId: this.campaignId,
              reason: this.reason
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()

          this.isSubmitting = false
          this.$emit('close')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  }
}
</script>

<style></style>
