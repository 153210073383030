var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-12"
  }, [_vm._m(0), _c('ul', _vm._l(_vm.rowData.previous, function (payout) {
    return _c('li', {
      key: payout.id
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_c('span', {
      staticClass: "text-sm text-indigo-600"
    }, [_vm._v("Rate:")]), _vm._v(" " + _vm._s(payout.rate) + " ")]), _c('span', {
      staticClass: "mr-2"
    }, [_c('span', {
      staticClass: "text-sm text-indigo-600"
    }, [_vm._v("Start:")]), _vm._v(" " + _vm._s(_vm._f("luxon")(payout.startDate)) + " ")]), _c('span', {
      staticClass: "mr-2"
    }, [_c('span', {
      staticClass: "text-sm text-indigo-600"
    }, [_vm._v("End:")]), _vm._v(" " + _vm._s(_vm._f("luxon")(payout.endDate)) + " ")])]);
  }), 0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticClass: "font-bold uppercase text-gray-500 mb-1"
  }, [_c('small', [_vm._v("Previous Payouts")])]);

}]

export { render, staticRenderFns }