<template>
  <div>
    <YAlert class="my-3">
      <div class="flex items-center">
        <span class="pr-4" style="font-size: 1.5rem;">🎉</span>
        <p style="margin-bottom: 0px;">
          <span class="font-bold">YTZ</span> is happy to offer our publishers a
          referral program.
          <span class="font-bold">The YTZ Publisher Referral Program</span> is
          easy to use; simply grab the account specific referral link below,
          pass it on to anyone you know who has traffic they are looking to
          monetize and start making money from that referral. It's that simple.
        </p>
      </div>
    </YAlert>
    <div class="p-4 pt-2">
      <p><span class="font-semibold">A few things to note:</span></p>
      <ol>
        <li>Your account-specific referral link is below</li>
        <li>
          Referral program offers
          <span class="font-semibold"
            >2.5% based on the referral account's
            <span class="underline">lifetime revenue</span>.</span
          >
        </li>
        <li>
          Real time referral stats are available in the report section. Use the
          <span class="font-semibold">"Referral Revenue From"</span> filter to
          breakdown your stats by publisher.
        </li>
        <li>No self-referrals.</li>
      </ol>
      <p>
        Please feel free to reach out to us anytime if you have questions about
        the referral program.
      </p>
      <YInput
        :readonly="true"
        :value="referralLink"
        label="Your Referral Link"
        class="my-4"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    me: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      referralUrlBase: 'https://ytz.com/signup/?ref=',
      copyText: 'Copy To Clipboard',
      user: this.me,
      referralLink: 'https://ytz.com/signup/?ref=' + this.me.id
    }
  },
  mounted() {},
  updated() {},
  computed: {},
  methods: {
    copyToClipboard: function(content, onSuccess) {
      this.$copyText(content).then(
        e => {
          console.log(e)
          this.copyText = `Copied`

          setTimeout(() => {
            this.copyText = 'Copy To Clipboard'
          }, 2000)

          if (onSuccess != undefined) {
            onSuccess()
          }
        },
        e => {
          console.log(e)
          this.copyText = `Error`

          setTimeout(() => {
            this.copyText = 'Copy To Clipboard'
          }, 2000)
        }
      )
    }
  }
}
</script>
<style scoped>
p {
  @apply mb-2;
}
ol {
  @apply ml-8 mb-2;
  list-style-type: decimal;
}
ol li {
}
</style>
