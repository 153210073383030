<template>
  <span :class="classes" :style="styles">
    <template v-if="actionsState == 'default'">
      <a @click="edit" class="editable-hover d-inline-block">
        <slot></slot>
      </a>
      <editable-actions
        :state="actionsState"
        @edit="edit"
        @submit="submit"
        @cancel="cancel"
        :edit-action-display-type="editActionDisplayType"
      ></editable-actions>
    </template>
    <template v-if="actionsState == 'edit'">
      <YInput
        class="form-control"
        label="URL"
        v-model="value"
        @keydown.enter="submit"
        @keydown.esc="cancel"
      />
      <editable-actions
        :submitting="isSubmitting"
        :state="actionsState"
        @edit="edit"
        @submit="submit"
        @cancel="cancel"
      ></editable-actions>
    </template>
  </span>
</template>

<script>
import EditableMixin from '@/components/legacy/Editables/EditableMixin.js'
import UPDATE_POSTBACK_MUTATION from '@/graphql/Postback/UpdatePostbackMutation.gql'

export default {
  mixins: [EditableMixin],
  apollo: {},
  props: {
    id: {
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      value: this.url,
      mutation: UPDATE_POSTBACK_MUTATION
    }
  },
  computed: {
    mutationVariables() {
      return {
        input: {
          id: this.id,
          url: this.value
        }
      }
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
