<template>
  <widget>
    <widget-header @close="$emit('close')">
      Edit Image
    </widget-header>
    <widget-body>
      <form v-on:submit.prevent="updateImage" class="row" ref="upload">
        <div class="row flex items-center mb-3">
          <div :class="style.columnTitle">
            Name
          </div>
          <div class="col w-2/3">
            <YInput label="Name" v-model="imageData.name" disabled></YInput>
          </div>
        </div>
        <div class="row flex items-center mb-3">
          <div :class="style.columnTitle">
            Choose Image
          </div>
          <div class="col">
            <ImageInput
              class="rounded"
              :imageUrl.sync="thumbnailImageUrl"
              :thumbnailImageUrl="thumbnailImageUrl"
              :height="32"
              :width="32"
              :blankHeight="20"
              :blankWidth="20"
              :editable="true"
              :editable-only-when-empty="false"
              :file.sync="imageFile"
              :key="isSubmitting"
            ></ImageInput>
          </div>
        </div>

        <div class="w-full flex justify-end pt-2">
          <YButton color="blue" :is-loading="isSubmitting"
            >Update Image</YButton
          >
        </div>
      </form>
    </widget-body>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import ImageInput from '@/components/utilities/ImageInput'
import UPDATE_IMAGE from '@/graphql/ImageRepository/UpdateImageMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],
  components: {
    ImageInput
  },
  props: {
    imageData: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      thumbnailImageUrl: this.imageData.imageThumbnailUrl,
      imageFile: null,
      isSubmitting: false,
      style: {
        columnTitle: 'col w-1/3 px-4 my-3 font-bold text-gray-700'
      }
    }
  },
  computed: {},
  methods: {
    updateImage() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_IMAGE,
          variables: {
            input: {
              id: this.imageData.id,
              file: this.imageFile
            }
          }
        })
        .then(() => {
          this.$events.emit('refreshImages')
          this.$emit('close')
          this.isSubmitting = false
        })
        .catch(error => {
          console.log('ERROR', error)
          this.isSubmitting = false
        })
    },
    close() {
      this.clearValidationErrors()
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
