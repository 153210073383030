<template>
  <div>
    <Widget>
      <WidgetHeader>Merge into Primary User Account</WidgetHeader>
      <WidgetBody>
        <div class="grid grid-cols-1 gap-2">
          <div>
            <span class="text-red-400 font-bold">Caution:</span
            ><span class="font-semibold"> {{ user.label }}</span> will be merged
            into the selected account as a secondary contact. <br />User
            {{ user.id }} will be <span class="font-semibold">deleted</span> and
            no longer have an individual profile.
          </div>
          <Superselect
            title="Join to User"
            v-model="primaryUser"
            :multiple="false"
            track-by="id"
            label="label"
            :query="OPEN_USERS_QUERY"
            :query-variables="{
              filters: {
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              },
              orderBy: [{ column: 'id', order: 'DESC' }],
              first: 25,
              page: 1
            }"
          />
        </div>
        <div class="w-full flex justify-end gap-3 mt-4">
          <YButton color="gray" @click="$emit('close')">Cancel</YButton>
          <YButton color="blue" @click="createSecondaryContact"
            >Merge with Primary</YButton
          >
        </div>
      </WidgetBody>
    </Widget>

    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import OPEN_USERS_QUERY from '@/graphql/User/OpenUsersQuery.gql'
import CREATE_SECONDARY_CONTACT from '@/graphql/Contact/MergeContactAsSecondary.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
export default {
  mixins: [ValidationErrorsMixin],
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      OPEN_USERS_QUERY,
      primaryUser: [],
      isSubmitting: false
    }
  },
  mounted() {},
  computed: {
    redirectOnClose() {
      return this.user.status == 'lead' ? 'Leads' : 'Users'
    }
  },
  methods: {
    createSecondaryContact() {
      this.validationErrors = null
      this.isSubmitting = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: CREATE_SECONDARY_CONTACT,
          variables: {
            input: {
              primary_id: this.primaryUser.id,
              secondary_id: this.user.id
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('Second Contact Created')
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$router.push({
            name: this.redirectOnClose
          })
          this.$emit('close')
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$emit('close')
        })
    }
  }
}
</script>
