<template>
  <div
    class="px-2 bg-inverse-900 hover:bg-inverse-700 text-white cursor-pointer"
    :class="divClass"
    @click="copyToClipboard"
  >
    <v-popover class="v-popover--block">
      <Icon name="link" size="6"></Icon>
      <template slot="popover">
        <div class="py-1 px-2 text-inverse-900">
          <p>Link copied!</p>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    divClass: {
      type: String
    }
  },
  methods: {
    copyToClipboard() {
      this.$copyText(this.text)
    }
  }
}
</script>

<style lang="scss" scoped></style>
