var render = function render(){
  var _vm$offerMeta;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-2 mb-24 ml-4"
  }, [_c('Tabs', [_c('Tab', {
    attrs: {
      "name": "Variables",
      "active": true
    }
  }, [_c('div', {
    staticClass: "flex w-full gap-8 2xl:gap-12"
  }, [_c('div', {
    staticClass: "w-full flex flex-col gap-3 pt-4",
    style: _vm.existsTranslatedLanguages ? '' : 'max-width: 70rem'
  }, [_c('div', {
    staticClass: "grid grid-cols-4 gap-3"
  }, [_c('YInput', {
    attrs: {
      "label": "Name of Offer"
    },
    model: {
      value: _vm.localOffer.name,
      callback: function ($$v) {
        _vm.$set(_vm.localOffer, "name", $$v);
      },
      expression: "localOffer.name"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Category"
    },
    model: {
      value: _vm.localOffer.category,
      callback: function ($$v) {
        _vm.$set(_vm.localOffer, "category", $$v);
      },
      expression: "localOffer.category"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Brand"
    },
    model: {
      value: _vm.localOffer.brand,
      callback: function ($$v) {
        _vm.$set(_vm.localOffer, "brand", $$v);
      },
      expression: "localOffer.brand"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Review URL"
    },
    model: {
      value: _vm.localOffer.reviewUrl,
      callback: function ($$v) {
        _vm.$set(_vm.localOffer, "reviewUrl", $$v);
      },
      expression: "localOffer.reviewUrl"
    }
  })], 1), _c('div', {
    class: _vm.existsTranslatedLanguages || _vm.existsRegionalDescriptions ? 'grid grid-cols-7 gap-4' : ''
  }, [_c('div', {
    staticClass: "pt-2",
    class: _vm.existsTranslatedLanguages || _vm.existsRegionalDescriptions ? 'col-span-3' : 'grid grid-cols-2 gap-2 '
  }, [_c('div', {
    staticClass: "w-full font-bold text-lg mb-5 text-gray-900",
    class: _vm.existsTranslatedLanguages ? '' : 'hidden'
  }, [_vm._v(" English ")]), _c('YTextarea', {
    attrs: {
      "label": 'Offer Description. Max ' + _vm.characterLimit + ' characters',
      "characterLimit": _vm.characterLimit
    },
    model: {
      value: _vm.localOffer.description.value,
      callback: function ($$v) {
        _vm.$set(_vm.localOffer.description, "value", $$v);
      },
      expression: "localOffer.description.value"
    }
  }), _c('div', {
    staticClass: "flex flex-col gap-2",
    class: _vm.existsTranslatedLanguages || _vm.existsRegionalDescriptions ? 'mt-4' : ''
  }, [_c('div', {
    key: _vm.saveFeature,
    staticClass: "col-span-3 border rounded p-3 pt-1 bg-gray-50",
    staticStyle: {
      "min-height": "3rem"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "#667eea",
      "font-size": "0.75rem"
    }
  }, [_vm._v(" Features")]), _vm._l(_vm.localOffer.features, function (feature, index) {
    return _c('ul', {
      key: index
    }, [_c('li', [_c('div', {
      staticClass: "flex space-between"
    }, [_c('div', {
      staticClass: "w-full"
    }, [_vm._v(_vm._s(feature))]), _c('div', [_c('button', {
      on: {
        "click": function ($event) {
          return _vm.removeFeature(index);
        }
      }
    }, [_c('Icon', {
      staticClass: "inline",
      attrs: {
        "name": "close",
        "size": 4,
        "color": "red"
      }
    })], 1)])])])]);
  })], 2), _c('div', {
    staticClass: "flex gap-2 flex-start items-center"
  }, [_c('YInput', {
    staticClass: "w-full",
    attrs: {
      "label": "Enter Offer Feature"
    },
    model: {
      value: _vm.attributeFeature,
      callback: function ($$v) {
        _vm.attributeFeature = $$v;
      },
      expression: "attributeFeature"
    }
  }), _c('button', {
    staticClass: "bg-inverse-700 text-white h-8 w-8 rounded flex justify-center items-center",
    on: {
      "click": _vm.saveAttributeFeature
    }
  }, [_c('Icon', {
    attrs: {
      "name": "plus",
      "size": "4"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-2 flex gap-2 flex-start items-center"
  }, [_c('YInput', {
    staticClass: "w-full",
    attrs: {
      "label": "Enter Terms of Service"
    },
    model: {
      value: _vm.localOffer.serviceTerms,
      callback: function ($$v) {
        _vm.$set(_vm.localOffer, "serviceTerms", $$v);
      },
      expression: "localOffer.serviceTerms"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "bg-gray-100 rounded-lg py-2 px-4 col-span-4 flex flex-col",
    class: _vm.existsTranslatedLanguages ? '' : 'hidden'
  }, [_c('div', {
    staticClass: "w-full font-bold text-lg text-gray-900 flex justify-between"
  }, [_c('div', [_vm._v(" Language: "), _c('span', {
    staticClass: "uppercase"
  }, [_vm._v(_vm._s(_vm.selectedLanguage))])])]), _c('div', {
    staticClass: "flex text-xs text-gray-900"
  }, [_c('span', {
    staticClass: "w-1/2 pl-1"
  }, [_vm._v("Original LLM")]), _c('span', {
    staticClass: "w-1/2 pl-1"
  }, [_vm._v("Manual Values")])]), _c('div', {
    staticClass: "grid grid-cols-2 gap-2 w-100 flex-1"
  }, [_c('YTextarea', {
    attrs: {
      "label": 'Description_' + _vm.selectedLanguage,
      "disabled": ""
    },
    model: {
      value: _vm.descriptionLlmForSelectedLanguage,
      callback: function ($$v) {
        _vm.descriptionLlmForSelectedLanguage = $$v;
      },
      expression: "descriptionLlmForSelectedLanguage"
    }
  }), _c('div', [_c('YTextarea', {
    attrs: {
      "label": 'Manual Description_' + _vm.selectedLanguage
    },
    model: {
      value: _vm.manualDescriptionEntry,
      callback: function ($$v) {
        _vm.manualDescriptionEntry = $$v;
      },
      expression: "manualDescriptionEntry"
    }
  })], 1)], 1), _vm.featuresLlmForSelectedLanguage ? _c('div', {
    staticClass: "grid grid-cols-2 gap-2 w-100 flex-1"
  }, [_c('div', {
    staticClass: "rounded",
    staticStyle: {
      "min-height": "3rem"
    }
  }, [_c('span', {
    staticClass: "py-1 pl-1 w-100 block border-t border-l border-r rounded-t",
    staticStyle: {
      "color": "#667eea",
      "font-size": "0.75rem"
    }
  }, [_vm._v(" Features_" + _vm._s(_vm.selectedLanguage))]), _vm._l(_vm.featuresLlmForSelectedLanguage, function (feature, index) {
    return _c('div', {
      key: index
    }, [_c('YInput', {
      staticClass: "w-full mb-1",
      attrs: {
        "disabled": "",
        "label": 'feature_' + (index + 1) + '_' + _vm.selectedLanguage,
        "placeholder": feature,
        "value": feature
      }
    })], 1);
  })], 2), _c('div', {
    staticClass: "rounded bg-white",
    staticStyle: {
      "min-height": "3rem"
    }
  }, [_c('span', {
    staticClass: "py-1 pl-1 w-100 block border-t border-l border-r rounded-t",
    staticStyle: {
      "color": "#667eea",
      "font-size": "0.75rem"
    }
  }, [_vm._v(" Manual Features_" + _vm._s(_vm.selectedLanguage))]), _vm._l(_vm.existsManualTranslatedFeatures, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "mb-1"
    }, [_c('YInput', {
      attrs: {
        "label": 'feature_' + (key + 1) + '_' + _vm.selectedLanguage,
        "placeholder": "placeholder",
        "value": value,
        "alertIcon": _vm.manualFeatureEntry[key] !== _vm.featuresLlmForSelectedLanguage[key],
        "muteInput": _vm.manualFeatureEntry[key] == _vm.featuresLlmForSelectedLanguage[key]
      },
      model: {
        value: _vm.manualFeatureEntry[key],
        callback: function ($$v) {
          _vm.$set(_vm.manualFeatureEntry, key, $$v);
        },
        expression: "manualFeatureEntry[key]"
      }
    })], 1);
  })], 2)]) : _vm._e(), _vm.isSubmittingTranslations ? _c('YButton', {
    staticClass: "self-end mt-2 w-24",
    staticStyle: {
      "width": "14rem"
    },
    attrs: {
      "disabled": "",
      "color": "blue"
    }
  }, [_c('Spinner', {
    attrs: {
      "color": "white",
      "size": 5
    }
  })], 1) : _vm._e(), !_vm.isSubmittingTranslations ? _c('YButton', {
    staticClass: "self-end mt-2",
    staticStyle: {
      "width": "14rem"
    },
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.saveManualTranslations
    }
  }, [_vm._v("Save Manual Translations")]) : _vm._e()], 1), _c('div', {
    staticClass: "bg-gray-100 rounded-lg py-2 px-4 col-span-4 flex flex-col",
    class: _vm.setExistingRegionalDescription ? '' : 'hidden'
  }, [_vm.offerMeta ? _c('OfferMetaRegionalDescriptions', {
    attrs: {
      "offerMeta": _vm.offerMeta.data
    },
    on: {
      "update-description-regions": _vm.updateDescriptionsByRegion
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "grid grid-cols-7 items-start gap-3 mt-2"
  }, [_c('div', {
    staticClass: "minor-header"
  }, [_vm._v("Theme Color")]), _c('div', {
    staticClass: "col-span-2 gap-4"
  }, [_c('div', {
    staticClass: "grid grid-cols-2"
  }, [_c('div', [_c('ToggleButton', {
    attrs: {
      "color": {
        checked: _vm.localOffer.themeColor,
        unchecked: '#2a425a',
        disabled: '#CCCCCC'
      },
      "labels": {
        checked: 'Custom',
        unchecked: 'Default'
      },
      "width": 92,
      "height": 30,
      "font-size": 12
    },
    model: {
      value: _vm.toggleThemeColor,
      callback: function ($$v) {
        _vm.toggleThemeColor = $$v;
      },
      expression: "toggleThemeColor"
    }
  })], 1), _c('div', {
    staticClass: "flex justify-start items-center"
  }, [_vm.toggleThemeColor ? [_c('Icon', {
    staticClass: "inline text-gray-800 mr-1",
    attrs: {
      "name": "colorFill",
      "size": 6
    }
  }), _c('verte', {
    attrs: {
      "picker": "square",
      "model": "hex"
    },
    model: {
      value: _vm.localOffer.themeColor,
      callback: function ($$v) {
        _vm.$set(_vm.localOffer, "themeColor", $$v);
      },
      expression: "localOffer.themeColor"
    }
  })] : _vm._e()], 2)])]), _c('div', {
    staticClass: "col-span-4"
  }, [_c('span', {
    staticClass: "block text-gray-600 text-xs mt-1"
  }, [_c('Icon', {
    staticClass: "inline mr-2",
    attrs: {
      "name": "alertCircleOutline",
      "size": 4
    }
  }), _c('span', [_vm._v("Default color is white. Controls background color of images")])], 1)])]), _c('div', {
    staticClass: "grid grid-cols-7 items-start gap-3 mt-2"
  }, [_c('div', {
    staticClass: "minor-header"
  }, [_vm._v("Button Color")]), _c('div', {
    staticClass: "col-span-2 gap-4"
  }, [_c('div', {
    staticClass: "grid grid-cols-2"
  }, [_c('div', [_c('ToggleButton', {
    attrs: {
      "color": {
        checked: _vm.localOffer.themeButtonColor,
        unchecked: '#2a425a',
        disabled: '#CCCCCC'
      },
      "labels": {
        checked: 'Custom',
        unchecked: 'Default'
      },
      "width": 92,
      "height": 30,
      "font-size": 12
    },
    model: {
      value: _vm.toggleThemeButtonColor,
      callback: function ($$v) {
        _vm.toggleThemeButtonColor = $$v;
      },
      expression: "toggleThemeButtonColor"
    }
  })], 1), _c('div', {
    staticClass: "flex justify-start items-center"
  }, [_vm.toggleThemeButtonColor ? [_c('Icon', {
    staticClass: "inline text-gray-800 mr-1",
    attrs: {
      "name": "colorFill",
      "size": 6
    }
  }), _c('verte', {
    attrs: {
      "picker": "square",
      "model": "hex"
    },
    model: {
      value: _vm.localOffer.themeButtonColor,
      callback: function ($$v) {
        _vm.$set(_vm.localOffer, "themeButtonColor", $$v);
      },
      expression: "localOffer.themeButtonColor"
    }
  })] : _vm._e()], 2)])]), _c('div', {
    staticClass: "col-span-4"
  }, [_c('span', {
    staticClass: "block text-gray-600 text-xs mt-1"
  }, [_c('Icon', {
    staticClass: "inline mr-2",
    attrs: {
      "name": "alertCircleOutline",
      "size": 4
    }
  }), _c('span', [_vm._v("Default is set by individual templates.")])], 1)])]), _c('div', {
    staticClass: "flex items-center gap-6 mt-2"
  }, [_c('div', {
    staticClass: "minor-header w-32"
  }, [_vm._v("Image")]), _c('div', {
    staticClass: "flex-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Select Image",
      "options": [],
      "track-by": "allImageData",
      "label": "name",
      "multiple": false,
      "no-result": "Sorry, no images found.",
      "query": _vm.IMAGES_QUERY,
      "query-variables": {
        first: 10,
        filters: {
          name: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function () {
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('div', {
          staticClass: "rounded w-24 overflow-hidden flex justify-center",
          style: {
            backgroundColor: _vm.localOffer.themeColor
          }
        }, [_c('YImage', {
          staticClass: "rounded object-cover h-full m-1",
          attrs: {
            "src": _vm.imageFile.imageThumbnailUrl,
            "width": null,
            "height": 24
          }
        })], 1), _c('div', {
          staticClass: "ml-2"
        }, [_vm._v(_vm._s(_vm.imageFile.name))])])];
      },
      proxy: true
    }, {
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "flex items-center -mx-2"
        }, [_c('YImage', {
          staticClass: "rounded",
          attrs: {
            "src": props.option.imageThumbnailUrl,
            "width": null,
            "height": 16
          }
        }), _c('div', {
          staticClass: "ml-2"
        }, [_vm._v(_vm._s(props.option.name))])], 1)];
      }
    }]),
    model: {
      value: _vm.imageFile,
      callback: function ($$v) {
        _vm.imageFile = $$v;
      },
      expression: "imageFile"
    }
  })], 1), _c('div', {
    staticClass: "flex justify-end"
  }, [_c('div', [_vm.isSubmitting ? _c('Spinner', {
    attrs: {
      "size": 12,
      "color": "green"
    }
  }) : _vm._e()], 1), _c('YButton', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.updateOrCreate
    }
  }, [_vm._v("Save Offer Changes")])], 1)])]), _c('div', {
    staticClass: "flex-end pt-3",
    class: _vm.existsTranslatedLanguages ? 'flex' : 'hidden',
    staticStyle: {
      "width": "18rem"
    }
  }, [_c('div', {
    staticClass: "w-full rounded-lg border-2 border-blue-500 p-1",
    staticStyle: {
      "height": "fit-content"
    }
  }, [_c('Superselect', {
    staticClass: "w-full",
    attrs: {
      "title": "Available Translations",
      "options": _vm.templateTranslatedLanguages,
      "multiple": false
    },
    model: {
      value: _vm.selectedLanguage,
      callback: function ($$v) {
        _vm.selectedLanguage = $$v;
      },
      expression: "selectedLanguage"
    }
  })], 1)]), _c('div', {
    staticClass: "flex flex-end pt-3",
    class: !_vm.existsTranslatedLanguages ? 'flex' : 'hidden',
    staticStyle: {
      "width": "18rem"
    }
  }, [(_vm$offerMeta = _vm.offerMeta) !== null && _vm$offerMeta !== void 0 && _vm$offerMeta.data ? _c('div', {
    staticClass: "w-full rounded-lg border-2 border-blue-500 p-1",
    staticStyle: {
      "height": "fit-content"
    }
  }, [_c('YCheckbox', {
    staticClass: "flex items-center",
    attrs: {
      "id": "preset-is-public-checkbox",
      "label": "Set Regional Descriptions",
      "inline": true,
      "type": "info",
      "disabled": _vm.existsRegionalDescriptions
    },
    model: {
      value: _vm.setExistingRegionalDescription,
      callback: function ($$v) {
        _vm.setExistingRegionalDescription = $$v;
      },
      expression: "setExistingRegionalDescription"
    }
  })], 1) : _vm._e()])])]), _c('Tab', {
    attrs: {
      "name": "Languages",
      "disabled": _vm.existsRegionalDescriptions
    }
  }, [_c('div', {
    staticClass: "mt-4 flex flex-col gap-3",
    staticStyle: {
      "width": "28rem"
    }
  }, [_vm.isSubmitting ? _c('YButton', {
    attrs: {
      "disabled": "",
      "color": "blue"
    }
  }, [_c('Spinner', {
    attrs: {
      "color": "white",
      "size": 5
    }
  })], 1) : _vm._e(), !_vm.isSubmitting ? _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.updateOrCreateTranslations
    }
  }, [_vm._v(" Create or Update Translations")]) : _vm._e(), _c('Superselect', {
    attrs: {
      "title": "Available Languages",
      "placeholder": "Select Languages",
      "options": _vm.translatableLanguages,
      "custom-label": _vm.customLabelLanguages,
      "track-by": "code",
      "multiple": true,
      "closeOnSelect": false
    },
    model: {
      value: _vm.localSelectedTranslatableLanguages,
      callback: function ($$v) {
        _vm.localSelectedTranslatableLanguages = $$v;
      },
      expression: "localSelectedTranslatableLanguages"
    }
  }), _c('div', {
    staticClass: "w-20"
  }, [_c('YButton', {
    attrs: {
      "color": "link"
    },
    on: {
      "click": function ($event) {
        _vm.localSelectedTranslatableLanguages = [].concat(_vm.translatableLanguages);
      }
    }
  }, [_vm._v("Select All")])], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }