<template>
  <div id="top">
    <portal to="top-bar-right">
      <YButton color="blue" @click="openCreateModal">Create Rules</YButton>
    </portal>
    <div>
      <RulesTableWrapper />
    </div>
  </div>
</template>

<script>
import RulesTableWrapper from '@/views/Admin/Rules/RulesTableWrapper'
import CreateRules from '@/views/Admin/Rules/CreateRules'

export default {
  components: {
    RulesTableWrapper
  },
  apollo: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {
    openCreateModal() {
      this.$modal.show(
        CreateRules,
        {
          // defaults: this.defaultsForCreate ?? {},
          // hide: ['filterables']
        },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>

<style scoped></style>
