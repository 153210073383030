<template>
  <div>
    <div class="px-4 py-6 overflow-auto" style="background: #FFF;">
      <iframe
        v-if="userDetails.tipaltiDetails.paymentHistoryLink"
        :src="userDetails.tipaltiDetails.paymentHistoryLink"
        height="800"
        width="1000"
        style="border: none;"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userDetails: this.user
    }
  },
  watch: {},
  computed: {},
  methods: {},
  mounted() {},
  updated() {}
}
</script>
<style scoped></style>
