var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payoutContainer"
  }, [_vm.payouts.length == 1 ? [_c('div', [_vm._v(" " + _vm._s(_vm.payouts[0]) + " ")])] : _vm._e(), _vm.payouts.length > 1 ? [_c('div', [_vm._v(" " + _vm._s(_vm.payouts[0]) + " … "), _c('v-popover', {
    staticClass: "inline relative",
    staticStyle: {
      "top": "-2px"
    },
    attrs: {
      "trigger": "hover",
      "placement": "top"
    }
  }, [_c('button', {
    staticClass: "inline"
  }, [_c('Icon', {
    staticClass: "inline link",
    attrs: {
      "name": "chevronDoubleRight",
      "size": "4"
    }
  })], 1), _c('template', {
    slot: "popover"
  }, [_c('a', {
    directives: [{
      name: "close-popover",
      rawName: "v-close-popover"
    }]
  }, [_c('div', {
    staticClass: "bg-gray-100 rounded-md p-4",
    staticStyle: {
      "width": "250px"
    }
  }, [_c('span', {
    staticClass: "w-100 border-b-2 font-semibold block"
  }, [_vm._v("Rates")]), _c('ul', {
    staticClass: "mt-1"
  }, _vm._l(_vm.payouts, function (description, index) {
    return _c('li', {
      key: index,
      staticClass: "py-1",
      staticStyle: {
        "border-bottom": "1px solid lightgray"
      }
    }, [_vm._v(" " + _vm._s(description) + " ")]);
  }), 0)])])])], 2)], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }