<template>
  <div>
    <Superselect
      v-model="selectedProviderType"
      :options="providerTypeOptions"
      title="Select Provider Type"
      track-by="id"
      :multiple="false"
      label="label"
      class="mb-1"
      @select="
        () => {
          ;(this.selectedProvider = null),
            (this.selectedServer = null),
            (this.selectedIps = null)
        }
      "
    >
    </Superselect>
    <Superselect
      v-if="selectedProviderType"
      class="mb-1"
      v-model="selectedProvider"
      :options="providerOptions"
      title="Select Provider"
      :multiple="false"
      track-by="id"
      label="label"
      @select="
        () => {
          ;(this.selectedServer = null), (this.selectedIps = null)
        }
      "
    >
    </Superselect>
    <Superselect
      v-if="selectedProvider"
      class="mb-1"
      v-model="selectedServer"
      :options="serverOptions"
      :multiple="false"
      title="Select Server"
      track-by="id"
      label="label"
      @select="
        () => {
          this.selectedIps = null
        }
      "
    >
    </Superselect>
    <Superselect
      v-if="selectedProviderType && !useRandomIp"
      class="mb-1"
      v-model="selectedIps"
      :multiple="true"
      :options="ipOptions"
      title="Select IP"
      track-by="id"
      label="id"
    >
    </Superselect>
    <div v-if="selectedProviderType" class="flex justify-between items-center">
      <div>
        <y-checkbox
          label="Use Random IP"
          type="info"
          v-model="useRandomIp"
        ></y-checkbox>
      </div>
      <div>
        <Tag v-if="randomIp && useRandomIp" color="blue">{{ randomIp.id }}</Tag>
      </div>
    </div>
    <input type="hidden" v-model="selectOutput" />
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    domainType: {
      type: String,
      default: null
    },
    mapper: {
      type: Function,
      default: null
    }
  },
  components: {},
  apollo: {
    providerTypeOptions: {
      query: gql`
        query {
          providerTypeOptions: __type(name: "YdnsProviderTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.providerTypeOptions = data.providerTypeOptions.enumValues.map(
          enumValue => ({
            id: enumValue.name,
            label: enumValue.name.capitalize()
          })
        )
      }
    },
    providerOptions: {
      query: gql`
        query getProviders($filters: YdnsProviderFilterInput) {
          providerOptions: ydnsProviders(filters: $filters) {
            id
            name
            type
          }
        }
      `,
      variables() {
        return {
          filters: {
            type: {
              value: [this.selectedProviderType.id]
            },
            /*serverIpType: {
              value: [this.domainType]
            }*/
          }
        }
      },
      skip() {
        return this.selectedProviderType == null
      },
      deep: true,
      result({ data }) {
        this.providerOptions = data.providerOptions.map(option => ({
          id: option.id,
          label: `${option.name} (${option.type.capitalize()})`
        }))
      }
    },
    serverOptions: {
      query: gql`
        query getServers($filters: YdnsServerFilterInput) {
          serverOptions: ydnsServers(filters: $filters) {
            id
            provider {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            provider: {
              value: this.selectedProvider ? [this.selectedProvider.id] : [0]
            },
            /*ipType: {
              value: [this.domainType]
            }*/
          }
        }
      },
      deep: true,
      result({ data }) {
        this.selectedServer = null
        this.serverOptions = data.serverOptions.map(server => ({
          id: server.id,
          label: `${server.provider.name} - ${server.id}`
        }))
      }
    },
    ipOptions: {
      query: gql`
        query userAvailableIps($filters: UserAvailableIpsInput) {
          ipOptions: userAvailableIps(filters: $filters) {
            id
          }
        }
      `,
      variables() {
        let filters = {
          providerType: {
            value: this.selectedProviderType
              ? [this.selectedProviderType.id]
              : []
          },
          provider: {
            value: this.selectedProvider ? [this.selectedProvider.id] : []
          },
          server: {
            value: this.selectedServer ? [this.selectedServer.id] : []
          },
          enabled: {
            value: true
          }
        }

        if (this.domainType && this.domainType == 'mini') {
          filters.hasRecords = false
        }

        return {
          filters: filters
        }
      },
      skip() {
        return this.selectedProviderType == null
      },
      deep: true,
      result() {
        this.selectedIps = null
      }
    }
  },
  data() {
    return {
      selectedProviderType: null,
      selectedProvider: null,
      selectedServer: null,
      providerTypeOptions: [],
      providerOptions: [],
      selectedIps: null,
      useRandomIp: true,
      mapperFunction: this.mapper
    }
  },
  watch: {},
  computed: {
    randomIp() {
      if (!this.ipOptions) {
        return null
      }
      return this.ipOptions[Math.floor(Math.random() * this.ipOptions.length)]
    },
    selectOutput() {
      let output =
        this.useRandomIp && this.randomIp ? [this.randomIp] : this.selectedIps

      output = JSON.parse(JSON.stringify(output))

      if (output && this.mapperFunction) {
        output = output.map(this.mapperFunction)
      }
      this.$emit('select', output)
      return output
    }
  },
  methods: {
    clear() {
      this.selectedProviderType = null
      this.selectedProvider = null
      this.selectedServer = null
      this.$emit('select', [])
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
