var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', {
    staticClass: "pt-5",
    attrs: {
      "loading": !_vm.offers.data
    }
  }, [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Traffic Offers ")]), _vm.offers.data ? _c('widget-body', [_c('div', {
    staticClass: "flex flex-col shadow-md mb-4 rounded-lg"
  }, [_c('div', {
    staticClass: "flex items-center justify-center h-12 bg-blue-600 text-white rounded-t-lg"
  }, [_c('Icon', {
    attrs: {
      "name": "alertCircleOutline",
      "size": "8"
    }
  })], 1), _c('div', {
    staticClass: "text-center text-lg py-6"
  }, [_vm._v(" Are you sure you want to traffic "), _c('span', {
    staticClass: "font-extrabold text-blue-700"
  }, [_vm._v(_vm._s(this.offers.data.length))]), _vm._v(" offers? ")]), _c('div', {
    staticClass: "w-1/2 m-auto"
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('div', [_c('RadioBox', {
    attrs: {
      "label": "Smart Weight",
      "value": true
    },
    model: {
      value: _vm.smartWeight,
      callback: function ($$v) {
        _vm.smartWeight = $$v;
      },
      expression: "smartWeight"
    }
  })], 1)]), _c('div', {
    staticClass: "mb-4"
  }, [_c('div', [_c('RadioBox', {
    attrs: {
      "label": "Manual Weight",
      "value": false
    },
    model: {
      value: _vm.smartWeight,
      callback: function ($$v) {
        _vm.smartWeight = $$v;
      },
      expression: "smartWeight"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.smartWeight,
      expression: "!smartWeight"
    }],
    staticClass: "mb-6 mt-4 x-form"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('YInput', {
    staticClass: "w-1/2",
    attrs: {
      "label": "Weight",
      "input-class": "border-r-0 rounded-r-none"
    },
    model: {
      value: _vm.offer.weight,
      callback: function ($$v) {
        _vm.$set(_vm.offer, "weight", $$v);
      },
      expression: "offer.weight"
    }
  }), _c('YInput', {
    staticClass: "w-1/4",
    attrs: {
      "label": "Min",
      "input-class": "border-l-0 rounded-l-none border-r-0 rounded-r-none"
    },
    model: {
      value: _vm.offer.min,
      callback: function ($$v) {
        _vm.$set(_vm.offer, "min", $$v);
      },
      expression: "offer.min"
    }
  }), _c('YInput', {
    staticClass: "w-1/4",
    attrs: {
      "label": "Max",
      "input-class": "border-l-0 rounded-l-none"
    },
    model: {
      value: _vm.offer.max,
      callback: function ($$v) {
        _vm.$set(_vm.offer, "max", $$v);
      },
      expression: "offer.max"
    }
  })], 1), _c('YInput', {
    staticClass: "mb-2",
    attrs: {
      "label": "Expiry Date",
      "dateFormat": "YYYY-MM-DD",
      "dateFormatted": "ll",
      "type": "date"
    },
    model: {
      value: _vm.offer.expiry,
      callback: function ($$v) {
        _vm.$set(_vm.offer, "expiry", $$v);
      },
      expression: "offer.expiry"
    }
  }), _vm.offer.expiry ? _c('YInput', {
    attrs: {
      "label": "Fallback value"
    },
    model: {
      value: _vm.offer.fallback,
      callback: function ($$v) {
        _vm.$set(_vm.offer, "fallback", $$v);
      },
      expression: "offer.fallback"
    }
  }) : _vm._e()], 1)])]), _c('div', {
    staticClass: "text-center space-x-10 py-6"
  }, [_c('YButton', {
    attrs: {
      "color": "gray"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "is-loading": this.isSubmitting
    },
    on: {
      "click": _vm.trafficOffers
    }
  }, [_vm._v("Traffic")])], 1)]), _vm.offers.data ? _c('YTable', {
    attrs: {
      "data": _vm.offers.data
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(" " + _vm._s(props.rowData.id) + " ")]), _c('td', [_vm._v(" " + _vm._s(props.rowData.name) + " ")])];
      }
    }], null, false, 1289814875)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("id")]), _c('th', [_vm._v("Offer")])])], 2) : _vm._e()], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }