var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('WidgetHeader', {
    staticClass: "py-6 border-b",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "flex justify-between items-center w-100 text-sm text-gray-800 font-extrabold"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "mr-1",
    attrs: {
      "name": "pencil",
      "size": 4
    }
  }), _vm.domains.length == 1 ? [_vm._v(" Edit " + _vm._s(_vm.domains[0].id) + " ")] : [_vm._v(" Edit Domain" + _vm._s(_vm.domains.length > 1 ? 's' : '') + " ")]], 2)])])], 2), _vm.section == 'primary' ? _c('WidgetBody', [_vm.domains && _vm.domains.length > 0 ? _c('div', [_vm.domains.length == 1 ? _c('div', [_c('div', {
    staticClass: "flex items-center justify-between mb-1 mt-0"
  }, [_c('div', [_c('span', {
    staticClass: "mb-0 font-semibold"
  }, [_vm._v("Add With Records")])]), _c('div', [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-100",
    on: {
      "click": function () {
        _this.section = 'addRecords';
      }
    }
  }, [_vm._v(" Add IP Records ")]), _c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-100",
    on: {
      "click": _vm.addNewRecord
    }
  }, [_vm._v(" Add Custom Record ")])], 1)]), _c('div', [_c('div', {
    staticStyle: {
      "width": "100%",
      "overflow-x": "scroll"
    }
  }, [_c('records-table-editable', {
    on: {
      "removed": _vm.markRecordForDeletion
    },
    model: {
      value: _vm.recordsToMutate,
      callback: function ($$v) {
        _vm.recordsToMutate = $$v;
      },
      expression: "recordsToMutate"
    }
  })], 1)])]) : _vm._e(), _vm.domains.length > 1 ? _c('div', [_vm.recordSelections.length > 0 ? _c('YAlert', {
    staticClass: "-mt-2",
    attrs: {
      "allowClose": false,
      "type": "danger",
      "color": "red"
    }
  }, [_vm._v(" Warning! This will permanently delete (" + _vm._s(_vm.recordSelections.length) + ") selected records. ")]) : _vm._e(), _vm.multipleDomainTypesSelected ? _c('YAlert', {
    attrs: {
      "allowClose": false,
      "type": "info",
      "color": "blue"
    }
  }, [_vm._v(" Multiple domain types selected. "), _c('span', {
    staticClass: "fw-500"
  }, [_vm._v("Some functionality has been disabled.")])]) : _vm._e(), _c('div', {
    staticClass: "flex items-center justify-between mb-4 mt-4"
  }, [_c('div', [_c('h6', {
    staticClass: "mb-0 font-bold"
  }, [_vm._v(" Replace " + _vm._s(_vm.recordSelections.length) + " record"), _vm.recordSelections.length != 1 ? [_vm._v("s")] : _vm._e(), _vm._v(" across " + _vm._s(_vm.domains.length) + " domains ")], 2)]), _c('div', [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-100 mr-1",
    attrs: {
      "disabled": _vm.multipleDomainTypesSelected
    },
    on: {
      "click": function () {
        _this.section = 'addRecords';
      }
    }
  }, [_vm._v(" Add IP Records ")]), _c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-100",
    on: {
      "click": _vm.addNewRecord
    }
  }, [_vm._v(" Add Custom Record ")])], 1)]), _c('records-table-editable', {
    staticClass: "w-full",
    on: {
      "removed": _vm.markRecordForDeletion
    },
    model: {
      value: _vm.recordsToCreate,
      callback: function ($$v) {
        _vm.recordsToCreate = $$v;
      },
      expression: "recordsToCreate"
    }
  })], 1) : _vm._e()]) : _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v("No Domains Selected")])])]) : _vm._e(), _vm.section == 'addRecords' ? _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('records-new-helper', {
    ref: "recordsNewHelper",
    attrs: {
      "domain-type": _vm.domainType
    },
    on: {
      "update": function (records) {
        _this.newIpRecords = records;
      }
    }
  })], 1) : _vm._e(), _vm.section == 'primary' ? _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('y-checkbox', {
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _vm.updateRecordsMode == 'replace' ? _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue"
    },
    on: {
      "click": _vm.replaceRecords,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.replaceRecords.apply(null, arguments);
      }
    }
  }, [_vm._v("Replace Records")]) : _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue"
    },
    on: {
      "click": _vm.saveRecords,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.saveRecords.apply(null, arguments);
      }
    }
  }, [_vm._v("Save")])], 1) : _vm._e(), _vm.section == 'addRecords' ? _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-200",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": function () {
        _this.section = 'primary';
      },
      "keydown": function ($event) {
        var _this2 = this;
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return function () {
          _this2.section = 'primary';
        }.apply(null, arguments);
      }
    }
  }, [_vm._v("Back")]), _c('div', [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-gray-200 mr-1",
    on: {
      "click": function ($event) {
        return _vm.$refs.recordsNewHelper.clear();
      }
    }
  }, [_vm._v("Clear")]), _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue",
      "disabled": !this.newIpRecords || this.newIpRecords && this.newIpRecords.length == 0
    },
    on: {
      "click": _vm.addIpRecords,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addIpRecords.apply(null, arguments);
      }
    }
  }, [_vm._v("Add Records")])], 1)], 1) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }