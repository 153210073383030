export default {
  data() {
    return {
      referredTypeOptions: [
        {
          type: 'Conference',
          options: [
            'Affiliate Summit West',
            'Affiliate Grand Slam',
            'Affiliate World Dubai',
            'London Affiliate Conference',
            'iGB/ICE',
            'Affiliate Summit East',
            'Affiliate World Europe',
            'Mailcon'
          ]
        },
        {
          type: 'YTZ Employee',
          options: ['Kate', 'Devin', 'Megan', 'Marissa', 'Nicole']
        },

        { type: 'YouTube', options: [] },
        { type: 'Facebook', options: [] },
        { type: 'LinkedIn', options: [] },
        { type: 'Newsletter', options: [] },
        { type: 'Online Search', options: [] },
        {
          type: 'Referred by YTZ User',
          options: []
        },
        { type: 'Affiliate Forum', options: [] },
        { type: 'Other', options: [] }
      ]
    }
  },
  methods: {
    referedTypeFilter() {
      let referredOptions = []

      this.referredTypeOptions
        .map(item => {
          if (item.options.length > 0) {
            referredOptions.push(item.options)
          } else {
            referredOptions.push(item.type)
          }
        })
        .flat(1)

      return referredOptions.flat(1)
    }
  }
}
