<template>
  <div>
    <div class="bg-gray-100 p-1">
      <div class="grid grid-cols-1 sm:grid-cols-6 gap-1">
        <Superselect
          title="Country"
          v-model="filters.country.value"
          :modifiers="filters.country.modifiers"
          :options="[]"
          placeholder="Select"
          track-by="code"
          label="label"
          :multiple="true"
          :close-on-select="false"
          :query="COUNTRIES_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              withoutAll: true
            }
          }"
          edit-placeholder="Paste Countries"
        ></Superselect>
        <Superselect
          title="Device"
          v-model="filters.device.value"
          :modifiers="filters.device.modifiers"
          :options="['Desktop', 'Mobile']"
          placeholder="Select"
          :multiple="true"
          :close-on-select="true"
        ></Superselect>
        <Superselect
          title="Offer"
          v-model="filters.offer.value"
          :modifiers="filters.offer.modifiers"
          :options="[]"
          placeholder="Select"
          track-by="id"
          label="label"
          :multiple="true"
          :close-on-select="false"
          :query="OFFER_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
          edit-placeholder="Paste Offers"
        ></Superselect>
        <Superselect
          title="Redirect"
          v-model="filters.redirect.value"
          :modifiers="filters.redirect.modifiers"
          :options="[]"
          placeholder="Select"
          track-by="id"
          label="label"
          :multiple="true"
          :close-on-select="false"
          :query="REDIRECT_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
          edit-placeholder="Paste Redirects"
        ></Superselect>
        <Superselect
          title="Algorithms"
          v-model="filters.algorithm.value"
          :modifiers="filters.algorithm.modifiers"
          :options="[]"
          placeholder="Select"
          track-by="id"
          label="id"
          :multiple="true"
          :close-on-select="false"
          :query="ALGORITHMS_QUERY"
          edit-placeholder="Paste Algorithms"
        ></Superselect>
      </div>
    </div>
    <div class="flex justify-between my-1">
      <div></div>
      <div>
        <YButton
          :disabled="selectedRowIds.length == 0"
          color="red"
          @click="deleteItems"
          :is-loading="isDeleting"
          >Delete</YButton
        >
      </div>
    </div>
    <YTable
      v-if="smartWeightQueueItems"
      :data="smartWeightQueueItems?.data || []"
      :loading="$apolloData.queries.smartWeightQueueItems.loading"
      :selectable="true"
      selectBy="id"
      :selected.sync="selectedRowIds"
    >
      <template slot="header-row">
        <th>Offer</th>
        <th>Redirect</th>
        <th></th>
        <th></th>
        <th>Algorithm</th>
        <th>Retry Date</th>
        <th>Queued On</th>
      </template>
      <template slot="row" slot-scope="props">
        <td>{{ props.rowData?.offer?.label }}</td>
        <td>
          {{ props.rowData?.redirect?.label ?? props.rowData?.redirectId }}
        </td>
        <td>{{ props.rowData?.country?.toUpperCase() }}</td>
        <td>{{ props.rowData?.device?.capitalize() }}</td>
        <td>
          {{ props.rowData?.algorithm?.id }} -
          {{ props.rowData?.algorithm?.name }}
        </td>
        <td>
          {{ props.rowData.retryDate }}
        </td>
        <td>
          {{ props.rowData.createdAt }}
        </td>
      </template>
    </YTable>
    <YPagination
      v-if="smartWeightQueueItems?.data"
      :data="smartWeightQueueItems.paginatorInfo"
      @load-page="loadPage"
      @next-page="nextPage"
      @previous-page="previousPage"
      :key="totalPages"
      @per-page="perPageSelection"
    >
    </YPagination>
  </div>
</template>

<script>
import SMART_WEIGHT_QUEUE_ITEMS_QUERY from '@/graphql/SmartWeightQueue/SmartWeightQueueItemsQuery.gql'
import DELETE_SMART_WEIGHT_QUEUE_ITEMS_MUTATION from '@/graphql/SmartWeightQueue/DeleteSmartWeightQueueItemsMutation.gql'
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import ALGORITHMS_QUERY from '@/graphql/Algorithm/AlgorithmsQuery.gql'
import YPagination from '@/components/ui/YPagination.vue'
import BackToTop from '@/mixins/BackToTopMixin.js'

export default {
  mixins: [BackToTop],
  components: {
    YPagination
  },
  apollo: {
    smartWeightQueueItems: {
      query: SMART_WEIGHT_QUEUE_ITEMS_QUERY,
      variables() {
        return {
          first: this.perPage,
          page: this.currentPage,
          filters: {
            offer: this.filters.offer.value
              ? { value: this.filters.offer.value?.map(offer => offer.id) }
              : undefined,
            redirect: this.filters.redirect.value
              ? {
                  value: this.filters.redirect.value?.map(
                    redirect => redirect.id
                  )
                }
              : undefined,
            algorithm: this.filters.algorithm.value
              ? { value: this.filters.algorithm.value?.map(algo => algo.id) }
              : undefined,
            country: this.filters.country.value
              ? {
                  value: this.filters.country.value?.map(
                    country => country.code
                  )
                }
              : undefined,
            device: this.filters.device.value
              ? {
                  value: this.filters.device.value?.map(device =>
                    device.toLowerCase()
                  )
                }
              : undefined
          }
        }
      }
    }
  },
  data() {
    return {
      OFFER_OPTIONS_QUERY,
      REDIRECT_OPTIONS_QUERY,
      COUNTRIES_QUERY,
      ALGORITHMS_QUERY,
      smartWeightQueueItems: {},
      selectedRowIds: [],
      isDeleting: false,
      filters: {
        offer: {},
        redirect: {},
        country: {},
        device: {},
        algorithm: {}
      },
      currentPage: 1,
      perPage: 10,
      totalPages: 1
    }
  },
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  },
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    deleteItems() {
      const ids = this.selectedRowIds
      this.isDeleting = true
      this.$apollo
        .mutate({
          mutation: DELETE_SMART_WEIGHT_QUEUE_ITEMS_MUTATION,
          variables: {
            ids: ids
          }
        })
        .then(() => {
          this.$toastr.s('Smart Weight Queue Items Deleted', 'Success!')
          this.isDeleting = false
          this.$apollo.queries.smartWeightQueueItems.refetch()
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isDeleting = false
        })
    }
  }
}
</script>

<style></style>
