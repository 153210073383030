import { mergePreviousDataWithMoreData } from '@/assets/js/ApolloHelpers.js'

export default {
  data() {
    return {
      page: 1,
      perPage: 20,
      showMoreEnabled: false,
      fetchMoreOnScrollToBottom: null
    }
  },
  mounted() {
    if (this.fetchMoreOnScrollToBottom) {
      this.$events.listen('scrolledToBottom', () => {
        this.fetchMore(this.$apollo.queries[this.fetchMoreOnScrollToBottom])
      })
    }
  },
  beforeDestroy() {
    this.$events.remove('scrolledToBottom')
  },
  methods: {
    fetchMore(query) {
      this.page++
      query.fetchMore({
        variables: {
          first: this.perPage,
          page: this.page
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.showMoreEnabled =
            fetchMoreResult[query.key].paginatorInfo.hasMorePages
          return {
            [query.key]: mergePreviousDataWithMoreData(
              previousResult[query.key],
              fetchMoreResult[query.key]
            )
          }
        }
      })
    }
  }
}
