var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-popover', {
    class: [''],
    attrs: {
      "offset": "10",
      "placement": "left-start",
      "autoHide": true,
      "popoverClass": "",
      "popoverBaseClass": "popover-menu sidebar-popover-menu",
      "popoverInnerClass": "popover-menu-inner animated animated-speed1 fadeIn"
    }
  }, [_c('div', {
    staticClass: "d-inline-block tooltip-target"
  }, [_vm._t("default", function () {
    return [_c('YButton', {
      staticClass: "text-blue-600 hover:bg-blue-100 px-4"
    }, [_c('Icon', {
      attrs: {
        "name": "dotsVertical"
      }
    })], 1)];
  })], 2), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "list-group"
  }, [_vm.provider ? _c('y-button', {
    staticClass: "btn-sm list-group-item list-group-item-action",
    on: {
      "click": function ($event) {
        return _vm.openCreateModal(_vm.provider.id);
      }
    }
  }, [_c('span', {
    staticClass: "text-success"
  }, [_c('i', {
    staticClass: "zmdi zmdi-check mr-2"
  }), _vm._v(" Add")])]) : _vm._e(), _c('y-button', {
    staticClass: "btn-sm list-group-item list-group-item-action",
    on: {
      "click": function ($event) {
        return _vm.openDeleteModal(_vm.servers[0]);
      }
    }
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_c('i', {
    staticClass: "zmdi zmdi-delete mr-2"
  }), _vm._v(" Delete")])])], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }