<template>
  <div v-show="hasDefaultSlot" @click="endImpersonate">
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {},
  methods: {
    endImpersonate() {
      let exitPath = this.$route.meta.scope === 'admin' ? this.$route.path : this.$store.state.auth.impersonationExitPath
      
      this.$store.dispatch('endImpersonate').then(() => {
        this.$router.push(exitPath)
      })
    }
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    }
  }
}
</script>

<style></style>
