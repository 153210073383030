<template>
  <div>
    <Widget>
      <WidgetHeader>
        Adjustment Preview
      </WidgetHeader>
      <WidgetBody>
        <div class="row">
          <div class="col table-scroll-container">
            <vuetable
              ref="vuetable"
              id="reporting-table"
              :api-mode="false"
              :data="adjustmentsData"
              pagination-path=""
              :fields="fields"
              :css="css"
              :load-on-start="false"
              cellspacing="0"
              width="100%"
              table-wrapper="vuetable-wrapper"
              :append-params="appendParams"
              @vuetable:load-error="onLoadError"
            >
              <div slot="custom-actions-slot" slot-scope="props">
                <adjustments-preview-custom-actions
                  :row-data="props.rowData"
                  :row-index="props.rowIndex"
                ></adjustments-preview-custom-actions>
              </div>
            </vuetable>
          </div>
        </div>
        <div class="flex justify-end mt-3">
          <YButton
            type="button"
            class="border border-blue-600 hover:bg-blue-600 focus:bg-blue-600 text-blue-600 hover:text-white focus:text-white mr-3 outline-none-important"
            @click.prevent="clearPreview"
            :disabled="isLoading || hasNoData"
            >Clear All</YButton
          >
          <YButton
            class="bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 text-white mr-3 outline-none-important"
            @click.prevent="submitAdjustments"
            :disabled="hasNoData"
            :is-loading="isLoading"
            >Submit</YButton
          >
        </div>
      </WidgetBody>
    </Widget>
  </div>
</template>

<script>
import Vuetable from './vuetable-2-2-custom/src/components/Vuetable'
import AdjustmentsPreviewCustomActions from './AdjustmentsPreviewCustomActions'
//import AdminAdjustmentsTableItem from './AdminAdjustmentsTableItem'
//import AdminAdjustmentsTableDetails from './AdminAdjustmentsTableDetails'
import gql from 'graphql-tag'

//Vue.component('admin-adjustments-table-item', AdminAdjustmentsTableItem)
//Vue.component('admin-adjustments-table-details', AdminAdjustmentsTableDetails)

export default {
  components: {
    Vuetable,
    AdjustmentsPreviewCustomActions
  },
  props: {
    filters: {
      default: false,
      type: Boolean
    }
  },
  data() {
    let masterColumnData = {
      publisher: {
        filter: {
          id: 'show-publisher',
          label: 'Publisher',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'publisher',
          title: 'Publisher',
          formatter: this.transformLabel,
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      advertiser: {
        filter: {
          id: 'show-advertiser',
          label: 'Advertiser',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'advertiser',
          title: 'Advertiser',
          formatter: this.transformLabel,
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      campaign: {
        filter: {
          id: 'show-campaign',
          label: 'Campaign',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'campaign',
          title: 'Campaign',
          formatter: this.transformLabel,
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      payout: {
        filter: {
          id: 'show-payout',
          label: 'Payout',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'payout',
          title: 'Payout',
          formatter: value => {
            return value ? this.$formatMoney(value) : undefined
          },
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      revenue: {
        filter: {
          id: 'show-revenue',
          label: 'Revenue',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'revenue',
          title: 'Revenue',
          formatter: value => {
            return value ? this.$formatMoney(value) : undefined
          },
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      actions: {
        filter: {
          id: 'show-actions',
          label: 'Actions',
          state: true,
          visible: false,
          disabled: false
        },
        column: {
          name: 'custom-actions-slot',
          title: '',
          //sortField: 'actions',
          callback: null,
          dataClass: 'text-right',
          titleClass: '',
          visible: true
        }
      }
    }

    let columns = this.createVuetableColumns(masterColumnData)

    return {
      masterColumnData: masterColumnData,
      adjustmentsData: [],
      fields: columns,
      css: {
        tableClass: 'table table-sm table-striped dt-responsive nowrap',
        loadingClass: 'loading',
        ascendingIcon: 'custom-table-sort-arrow-up',
        descendingIcon: 'custom-table-sort-arrow-down',
        detailRowClass: 'vuetable-detail-row',
        sortHandleIcon: 'grey sidebar icon'
      },
      sortOrder: [
        {
          field: 'date',
          sortField: 'date',
          direction: 'desc'
        }
      ],
      multiSort: false,
      appendParams: {},
      perPage: 12,
      isLoading: false,
      vuetable: this,
      submittedForm: ''
    }
  },
  computed: {
    hasNoData: function() {
      return this.adjustmentsData.length <= 0
    }
  },
  mounted() {
    // Listen to Form Submits
    this.$events.listen('submitAdjustmentsForm', eventData =>
      this.submitForm(eventData)
    )
    this.$events.listen('addAdjustmentToPreview', eventData =>
      this.addAdjustmentToPreview(eventData)
    )
  },
  beforeDestroy() {
    this.$events.remove('submitAdjustmentsForm')
    this.$events.remove('addAdjustmentToPreview')
  },
  updated() {},
  methods: {
    addAdjustmentToPreview(data) {
      if (data.data.constructor === Array) {
        for (let row in data.data) {
          this.adjustmentsData.push(data.data[row])
        }
      } else {
        this.adjustmentsData.push(data.data)
      }
    },
    submitForm(data) {
      console.log(data)
      console.log('submit AdjustmentsPreview.vue')
      /*this.submittedForm = data.form
      let apiUrl = '/api/v1/admin/adjustments/preview'
      this.axios
        .get(apiUrl, {
          params: data.data
        })
        .then(response => {
          for (let value of response.data.data) {
            this.adjustmentsData.push(value)
          }
          this.$events.fire('disableAdjustmentsLoadingPreview', {})
        })
        .catch(error => {
          this.onLoadError(error)
          this.$events.fire('disableAdjustmentsLoadingPreview', {})
        })

      //this.appendParams = data.data
      this.refreshVuetable()*/
    },
    submitAdjustments() {
      this.isLoading = true

      const mutation = gql`
        mutation AddAdjustments($adjustments: [AdjustmentInput]) {
          addAdjustments(adjustments: $adjustments) {
            result
            error
          }
        }
      `

      this.$apollo
        .mutate({
          mutation: mutation,
          variables: {
            adjustments: this.transformAdjustmentsForGraphQL(
              this.adjustmentsData
            )
          }
        })
        .then(result => {
          console.log(result)
          this.isLoading = false
          if (result.data.addAdjustments.error) {
            this.$toastr.e(result.data.addAdjustments.error)
          } else {
            this.adjustmentsData = []
            this.$toastr.s(result.data.addAdjustments.result)
          }
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
          this.$toastr.e(error, 'Oops!')
        })
    },
    transformAdjustmentsForGraphQL() {
      return this.adjustmentsData.map(row => ({
        publisherId: row.publisher ? row.publisher.id : null,
        advertiserId: row.advertiser ? row.advertiser.id : null,
        campaignId: row.campaign ? row.campaign.id : null,
        campaignName: row.campaign ? row.campaign.name : null,
        payout: row.payout,
        revenue: row.revenue
      }))
    },
    clearPreview() {
      this.adjustmentsData = []
      this.refreshVuetable()
    },
    onLoadError(error) {
      this.$events.fire('returnAdjustmentsFormErrors', {
        form: this.submittedForm,
        errors: error.response.data
      })
    },
    refreshVuetable() {
      this.Vue.nextTick(() => {
        this.$refs.vuetable.normalizeFields()
        this.$refs.vuetable.refresh()
      })
    },
    transformIdLabel(object) {
      return object ? `${object.id} - ${object.label}` : null
    },
    transformLabel(object) {
      if (object !== null) {
        return typeof object.label !== 'undefined'
          ? object.label
          : `<i class="text-muted">none</i>`
      }
      return `<i class="text-muted">none</i>`
    },
    capitalizeFirstLetter(string) {
      console.log(string)
      let output = string
      if (string) {
        output = string.charAt(0).toUpperCase() + string.slice(1)
      } else {
        output = null
      }
      return output
    },
    createVuetableColumns(master) {
      var vuetableColumns = []
      for (var column in master) {
        if (master[column].filter.state == true) {
          vuetableColumns.push(master[column].column)
        }
      }
      return vuetableColumns
    }
  }
}
</script>
<style></style>
