var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    staticClass: "background-gradient-green-blue text-white tiny-forms"
  }, [_c('i', {
    staticClass: "zmdi zmdi-file-plus pr-2"
  }), _vm._v(" Delete Alf Listing "), _c('h4', {
    attrs: {
      "slot": "right"
    },
    slot: "right"
  }, [_c('close-x', {
    staticClass: "text-white",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  })], 1)]), _c('widget-body', [_c('form', {
    ref: "form",
    staticClass: "x-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_vm.alfListing.contry_code ? _c('YInput', {
    attrs: {
      "readonly": true,
      "label": "Country"
    },
    model: {
      value: _vm.alfListing.country_code,
      callback: function ($$v) {
        _vm.$set(_vm.alfListing, "country_code", $$v);
      },
      expression: "alfListing.country_code"
    }
  }) : _vm._e(), _vm.alfListing.device ? _c('YInput', {
    attrs: {
      "readonly": true,
      "label": "Mobile/Desktop"
    },
    model: {
      value: _vm.alfListing.device,
      callback: function ($$v) {
        _vm.$set(_vm.alfListing, "device", $$v);
      },
      expression: "alfListing.device"
    }
  }) : _vm._e(), _vm.alfListing.subdomain ? _c('YInput', {
    attrs: {
      "readonly": true,
      "label": "Redirect"
    },
    model: {
      value: _vm.alfListing.subdomain,
      callback: function ($$v) {
        _vm.$set(_vm.alfListing, "subdomain", $$v);
      },
      expression: "alfListing.subdomain"
    }
  }) : _vm._e(), _vm.alfListing.domain ? _c('YInput', {
    attrs: {
      "readonly": true,
      "label": "Domain"
    },
    model: {
      value: _vm.alfListing.domain,
      callback: function ($$v) {
        _vm.$set(_vm.alfListing, "domain", $$v);
      },
      expression: "alfListing.domain"
    }
  }) : _vm._e(), _vm.alfListing.usageType ? _c('YInput', {
    attrs: {
      "readonly": true,
      "label": "usage Type"
    },
    model: {
      value: _vm.alfListing.usageType,
      callback: function ($$v) {
        _vm.$set(_vm.alfListing, "usageType", $$v);
      },
      expression: "alfListing.usageType"
    }
  }) : _vm._e(), _vm.alfListing.isp ? _c('YInput', {
    attrs: {
      "readonly": true,
      "label": "Isp"
    },
    model: {
      value: _vm.alfListing.isp,
      callback: function ($$v) {
        _vm.$set(_vm.alfListing, "isp", $$v);
      },
      expression: "alfListing.isp"
    }
  }) : _vm._e(), _vm.alfListing.ip ? _c('YInput', {
    attrs: {
      "readonly": true,
      "label": "Ip"
    },
    model: {
      value: _vm.alfListing.ip,
      callback: function ($$v) {
        _vm.$set(_vm.alfListing, "ip", $$v);
      },
      expression: "alfListing.ip"
    }
  }) : _vm._e()], 1)]), _c('widget-footer', {
    staticClass: "px-4 py-2 bg-gray-100 text-right"
  }, [_c('y-button', {
    staticClass: "btn btn-red",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.remove,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.remove.apply(null, arguments);
      }
    }
  }, [_vm._v("Delete")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }