<template>
  <widget>
    <widget-header class="background-gradient-green-blue text-white tiny-forms">
      <i class="zmdi zmdi-file-plus pr-2"></i> Create Alf Listing
      <h4 slot="right">
        <close-x @click="$emit('close')" class="text-white"></close-x>
      </h4>
    </widget-header>
    <widget-body>
      <form @submit.prevent="update" ref="form" class="x-form">
        <Superselect
              title="Country"
              v-model="alfListing.country"
              :multiple="false"
              label="label"
              track-by="code"
              :options="countries"
              :loading="$apollo.queries.countries.loading"
            ></Superselect>
       <Superselect
              title="Device"
              v-model="alfListing.device"
              :multiple="false"
              :options="options.devices"
            ></Superselect>
        <YInput v-model="alfListing.usageType" label="usage Type"></YInput>
        <YInput v-model="alfListing.isp" label="Isp"></YInput>
        <YInput v-model="alfListing.ip" label="Ip"></YInput>
        <YInput v-if="alfListing.redirect == null && alfListing.user == null" v-model="alfListing.subdomain" label="Subdomain"></YInput>
        <Superselect
            title="Redirect"
            v-if="alfListing.user == null"
            :multiple="false"
            v-model="alfListing.redirect"
            placeholder="Select Redirect"
            track-by="subdomain"
            label="label"
            :query="queries.REDIRECT_OPTIONS_QUERY"
            :query-variables="{
            filters: {
                label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
                }
            },
            first: 25
            }"
            :queryResultMap="{
            id: 'id',
            label: 'label',
            subdomain: 'subdomain',
            }"
        ></Superselect>

        <Superselect
            title="User"
            :multiple="false"
            v-model="alfListing.user"
            placeholder="Select User"
            track-by="id"
            label="label"
            :query="queries.USER_OPTIONS_QUERY"
            :query-variables="{
            filters: {
                label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
                }
            },
            first: 25
            }"
            :queryResultMap="{
            id: 'id',
            label: 'label'
            }"
        ></Superselect>
        <YInput v-model="alfListing.domain" label="Domain"></YInput>
        <YInput v-model="alfListing.destination" label="Destination"></YInput>
      </form>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <y-button
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="create"
        @keydown.enter="create"
        >Create</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import USER_OPTIONS_QUERY from '@/graphql/User/UserOptionsQuery.gql'

export default {
  mixins: [ValidationErrorsMixin],

  props: {
  },
  components: {},
  apollo: {
      countries: {
        query: COUNTRIES_QUERY
    },
  },
  data() {
    return {
      alfListing: {
        destination:'127.0.0.1'
      },
      isSubmitting: false,
      queries: {
        REDIRECT_OPTIONS_QUERY,
        USER_OPTIONS_QUERY,
      },
      options: {
        devices: ['Mobile', 'Desktop'],
      },
    }
  },
  watch: {},
  computed: {},
  methods: {
    create() {
      this.isSubmitting = true

      let input = {
            subdomain: this.alfListing.redirect ? this.alfListing.redirect.label.split(' ')[2].split(".")[0] : this.alfListing.subdomain ?? undefined,
            userId: this.alfListing.user ? this.alfListing.user.id : undefined,
            domain: this.alfListing.domain ?? undefined,
            isp: this.alfListing.isp ?? undefined,
            ip: this.alfListing.ip ?? undefined,
            usageType: this.alfListing.usageType ?? undefined,
            destination: this.alfListing.destination?? '127.0.0.1',
            device: this.alfListing.device ??undefined ,
            country: this.alfListing.country ? this.alfListing.country.code  : undefined,
            type: 'listing'
      }

      if (input.userId != undefined){
        delete input.subdomain
        input.type =  'parent'
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateAlfListing($input: CreateAlfListingInput) {
                createAlfListing(input: $input) {
                    id
                }
            }
          `,
          variables: {
            input: input
          }
        })
        .then(result => {
          console.log(result)
          this.isSubmitting = false
          this.clearValidationErrors()
          this.$events.emit('refreshAlfListings', true)
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.x-form > *:not(:last-child) {
  @apply mb-2;
}
</style>
