<template>
  <Widget>
    <WidgetHeader
      class="background-gradient-green-blue text-white tiny-forms"
      @close="$emit('close')"
    >
      Are you sure you want to pause?
    </WidgetHeader>
    <WidgetBody
      ><p class="mb-2">
        Pausing
        <span class="font-bold" v-if="this.offer">{{ this.offer.label }}</span>
        will:
      </p>
      <ul class="list-disc ml-8">
        <li>Delete any active weight rules for this offer</li>
        <li v-if="this.offersUsing">
          <p>
            Pause
            <span class="font-bold text-blue-600">{{
              this.offersUsing.length
            }}</span>
            offers pointing to this offer.
          </p>
          <ul v-if="showOffersUsing" class="list-disc ml-8">
            <li v-for="item in offersUsing" :key="item.id">
              <span @click="$emit('close')">
                <router-link
                  :to="{
                    name: 'Offer',
                    params: { id: item.id, action: 'edit' }
                  }"
                  class="link"
                  >{{ item.label }}</router-link
                >
              </span>
            </li>
          </ul>
          <p>
            <a class="link text-xs" href="#" @click.prevent="toggleOffersUsing"
              ><template v-if="showOffersUsing">Hide</template
              ><template v-else>Show</template> Offers</a
            >
          </p>
        </li>
      </ul>
    </WidgetBody>
    <WidgetFooter class="px-4 py-2 bg-gray-100 text-right">
      <YButton
        class="btn btn-red"
        :is-loading="isSubmitting"
        @click="remove"
        @keydown.enter="remove"
        >Pause</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import PAUSE_OFFER_MUTATION from '@/graphql/Offer/PauseOfferMutation.gql'
import OFFER_QUERY from '@/graphql/Offer/OfferQuery.gql'
import OFFERS_USING_QUERY from '@/graphql/Offer/OffersUsingQuery.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    offerId: {
      type: [Number, String],
      required: true
    }
  },
  components: {},
  apollo: {
    offer: {
      query: OFFER_QUERY,
      variables() {
        return {
          id: this.offerId
        }
      }
    },
    offersUsing: {
      query: OFFERS_USING_QUERY,
      variables() {
        return {
          id: this.offerId
        }
      }
    }
  },
  data() {
    return {
      isSubmitting: false,
      showOffersUsing: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    toggleOffersUsing() {
      this.showOffersUsing = !this.showOffersUsing
    },
    remove() {
      this.isSubmitting = true
      let input = {
        offerId: this.offerId
      }

      this.$apollo
        .mutate({
          mutation: PAUSE_OFFER_MUTATION,
          variables: {
            input: input
          }
        })
        .then(result => {
          this.isSubmitting = false
          this.clearValidationErrors()
          this.$events.emit('offerPaused', result.data.pauseOffer)
          this.$tabEvent.emit('refreshAlgorithmsData')
          this.$tabEvent.emit('refreshOffersTable')
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.x-form > *:not(:last-child) {
  @apply mb-2;
}
</style>
