var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-gray-100 p-1"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 sm:grid-cols-6 gap-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Country",
      "options": [],
      "placeholder": "Select",
      "track-by": "code",
      "label": "label",
      "multiple": false,
      "close-on-select": false,
      "query": _vm.COUNTRIES_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          withoutAll: true
        }
      },
      "edit-placeholder": "Paste Countries"
    },
    model: {
      value: _vm.filters.country.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.country, "value", $$v);
      },
      expression: "filters.country.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Device",
      "multiple": false,
      "options": ['Desktop', 'Mobile']
    },
    model: {
      value: _vm.filters.device,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "device", $$v);
      },
      expression: "filters.device"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Offer",
      "options": [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "close-on-select": false,
      "query": _vm.OFFER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": "Paste Offers"
    },
    model: {
      value: _vm.filters.offer.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.offer, "value", $$v);
      },
      expression: "filters.offer.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Redirect",
      "options": [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "close-on-select": false,
      "query": _vm.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": "Paste Redirects"
    },
    model: {
      value: _vm.filters.redirect.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.redirect, "value", $$v);
      },
      expression: "filters.redirect.value"
    }
  })], 1)]), _vm.algorithmDataCleaned ? _c('YTable', {
    attrs: {
      "data": _vm.algorithmDataCleaned || [],
      "loading": _vm.$apolloData.queries.algorithmDataCleaned.loading,
      "selected": _vm.selectedRowIds
    },
    on: {
      "update:selected": function ($event) {
        _vm.selectedRowIds = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _props$rowData, _props$rowData$offer, _props$rowData$redire, _props$rowData2, _props$rowData2$redir, _props$rowData3, _props$rowData3$redir, _props$rowData4, _props$rowData4$count, _props$rowData5, _props$rowData5$devic, _props$rowData6;
        return [_c('td', [_vm._v(_vm._s((_props$rowData = props.rowData) === null || _props$rowData === void 0 ? void 0 : (_props$rowData$offer = _props$rowData.offer) === null || _props$rowData$offer === void 0 ? void 0 : _props$rowData$offer.label))]), _c('td', [_vm._v(" " + _vm._s((_props$rowData$redire = (_props$rowData2 = props.rowData) === null || _props$rowData2 === void 0 ? void 0 : (_props$rowData2$redir = _props$rowData2.redirect) === null || _props$rowData2$redir === void 0 ? void 0 : _props$rowData2$redir.label) !== null && _props$rowData$redire !== void 0 ? _props$rowData$redire : (_props$rowData3 = props.rowData) === null || _props$rowData3 === void 0 ? void 0 : (_props$rowData3$redir = _props$rowData3.redirect) === null || _props$rowData3$redir === void 0 ? void 0 : _props$rowData3$redir.id) + " ")]), _c('td', [_vm._v(_vm._s((_props$rowData4 = props.rowData) === null || _props$rowData4 === void 0 ? void 0 : (_props$rowData4$count = _props$rowData4.country) === null || _props$rowData4$count === void 0 ? void 0 : _props$rowData4$count.toUpperCase()))]), _c('td', [_vm._v(_vm._s((_props$rowData5 = props.rowData) === null || _props$rowData5 === void 0 ? void 0 : (_props$rowData5$devic = _props$rowData5.device) === null || _props$rowData5$devic === void 0 ? void 0 : _props$rowData5$devic.capitalize()))]), _c('td', [_vm._v(" " + _vm._s((_props$rowData6 = props.rowData) === null || _props$rowData6 === void 0 ? void 0 : _props$rowData6.algorithmId) + " ")]), _c('td', [_vm._v(" " + _vm._s(props.rowData.cleanedAt) + " ")])];
      }
    }], null, false, 1441685883)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Offer")]), _c('th', [_vm._v("Redirect")]), _c('th'), _c('th'), _c('th', [_vm._v("Algorithm")]), _c('th', [_vm._v("Cleaned Date")])])], 2) : _vm._e(), _c('div', {
    staticClass: "mt-2"
  }, [_c('YButton', {
    staticClass: "block m-auto",
    attrs: {
      "color": "link",
      "is-loading": _vm.$apollo.queries.algorithmDataCleaned.loading
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v("LOAD MORE")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }