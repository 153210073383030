<template>
  <widget>
    <widget-header @close="$emit('close')"
      >Create New Conversion Type
    </widget-header>
    <widget-body>
      <form class="row" @submit.prevent="update" ref="form">
        <div class="col">
          <div class="row">
            <div class="input-group">
              <YInput v-model="conversionType.name" label="Name"></YInput>
            </div>
          </div>
        </div>
      </form>
    </widget-body>
    <widget-footer class="bg-gray-100 py-2 px-4 text-right">
      <YButton
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="create"
        @keydown.enter="create"
        >Create Conversion Type</YButton
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import CREATE_CONVERSION_TYPE_MUTATION from '@/graphql/ConversionType/CreateConversionType.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {},
  components: {},
  apollo: {},
  data() {
    return {
      conversionType: {},
      isSubmitting: false
    }
  },
  methods: {
    create() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: CREATE_CONVERSION_TYPE_MUTATION,
          variables: {
            input: {
              name: this.conversionType.name
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$events.emit('refreshConversionTypes')
          this.$toastr.s('Conversion Type Created', 'Success!')
          this.$emit('close')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false

          console.log(error)
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
