<template>
  <div>
    <YTable
      v-if="yleaderEstimatedRevenues"
      :data="yleaderEstimatedRevenues.data"
      class="table-striped table-hover overflow-hidden"
      block-style="min-height: 25rem;"
      :scroll-x="false"
      :selectable="true"
      selectBy="id"
      :selected.sync="selectedRowIds"
    >
      <template slot="header-row">
        <th v-if="showOfferId">
          Offer ID
        </th>
        <th>
          Account
        </th>
        <th>
          Country
        </th>
        <th>
          Campaign
        </th>
        <th>
          Redirect
        </th>
        <th>
          Source
        </th>
        <th>
          Value
        </th>
        <th>
          <!-- Actions -->
          <YButton
            v-if="selectedRowIds.length > 0"
            color="red"
            @click="bulkDeleteYleaders"
            >Bulk Delete</YButton
          >
        </th>
      </template>
      <template slot="secondHeaderRow">
        <td v-if="showOfferId">-</td>
        <td>
          <Superselect
            title="Account"
            class="-mx-3"
            v-model="accounts"
            track-by="id"
            label="label"
            :options="accountOptions"
            :required="true"
          >
            <template slot="option" slot-scope="props">
              <div class="flex justify-between">
                <span>{{ props.option.id }} - {{ props.option.userName }}</span>
                <span
                  v-if="
                    props.option.yleadType === 'real' ||
                      props.option.yleadType === 'lead'
                  "
                  class="text-xxs"
                  >{{ props.option.yleadType.toUpperCase() }}
                </span>
              </div>
            </template>
          </Superselect>
        </td>
        <td>
          <Superselect
            title="Countries"
            v-model="countries"
            :options="countryOptions"
            track-by="code"
            label="code"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Campaigns"
            v-model="campaigns"
            :options="[]"
            track-by="id"
            label="label"
            :disabled="redirects.length > 0 || sourceIds.length > 0"
            :query="CAMPAIGN_OPTIONS_QUERY"
            :query-variables="{
              first: 100,
              filters: {
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Redirects"
            v-model="redirects"
            :options="[]"
            track-by="id"
            label="label"
            :disabled="campaigns.length > 0 || sourceIds.length > 0"
            :query="REDIRECT_OPTIONS_QUERY"
            :query-variables="{
              first: 100,
              filters: {
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            class="-mx-3"
          />
        </td>
        <td>
          <Superselect
            title="Sources"
            v-model="sourceIds"
            :options="[]"
            :forceEdit="true"
            :disabled="campaigns.length > 0 || redirects.length > 0"
            class="-mx-3"
          />
        </td>
        <td>
          <YInput
            label="Value"
            class="-mx-3"
            v-model="value"
            :required="true"
          />
        </td>
        <td class="">
          <div class="flex items-center -ml-3 -mr-3">
            <YButton
              color="link"
              class="px-3"
              @click="addRevenueRow(upsertRevenueObjects)"
              :is-loading="isUpserting"
              ><Icon name="plus"
            /></YButton>
          </div>
        </td>
      </template>

      <template slot="row" slot-scope="props">
        <td v-if="showOfferId">
          {{ props.rowData.offer.id }}
        </td>
        <td>
          <div v-if="props.rowData.account" class="flex items-center">
            <span class="mr-2">{{
              props.rowData.account ? `${props.rowData.account.id}` : ''
            }}</span>
            <Tag
              v-tooltip="
                props.rowData.account
                  ? props.rowData.account.userName
                  : undefined
              "
              :color="
                props.rowData.account.yleadType == 'real'
                  ? 'purple'
                  : props.rowData.account.yleadType == 'lead'
                  ? 'pink'
                  : 'blue'
              "
              >{{ props.rowData.account.yleadType }}</Tag
            >
          </div>
          <div v-else>
            {{ props.rowData.accountId }}
          </div>
        </td>
        <td>{{ props.rowData.country ? props.rowData.country.code : '' }}</td>
        <td>
          <div>
            {{
              props.rowData.campaign
                ? props.rowData.campaign.label
                : props.rowData.redirect && props.rowData.redirect.campaign
                ? props.rowData.redirect.campaign.label
                : ''
            }}
          </div>
        </td>
        <td>
          <div class="flex flex-col">
            <div>
              <span class="font-semibold">
                {{
                  props.rowData.redirect ? props.rowData.redirect.id : ''
                }}</span
              >
              <span>
                {{
                  props.rowData.redirect
                    ? '(' + props.rowData.redirect.subdomain + ')'
                    : ''
                }}
              </span>
            </div>
            <span class="text-xs italic text-gray-700">{{
              props.rowData.redirect ? props.rowData.redirect.user.label : ''
            }}</span>
          </div>
        </td>
        <td>{{ props.rowData.sourceId }}</td>
        <td>${{ props.rowData.value }}</td>
        <td>
          <div class="text-right">
            <v-popover class="inline-block" placement="left" offset="4">
              <a
                class=" tooltip-target inline-block text-gray-400 hover:text-gray-500"
                ><Icon name="history"
              /></a>

              <!-- This will be the content of the popover -->
              <template slot="popover">
                <div
                  class="px-2 rounded overflow-auto"
                  style="max-height: 20rem"
                >
                  <template
                    v-if="
                      props.rowData.activityPaginated &&
                        props.rowData.activityPaginated.length > 0
                    "
                  >
                    <div
                      v-for="activity in props.rowData.activityPaginated"
                      :key="activity.id"
                      class="x-activity-item text-xs py-2 "
                    >
                      <div class="flex items-center justify-between">
                        <div class="font-bold mr-6">
                          {{
                            activity.updatedAt
                              | luxon({
                                output: { format: 'yyyy-MM-dd @ h:mm a' }
                              })
                          }}
                        </div>
                        <div class="font-semibold text-blue-600">
                          {{ activity.description.capitalize() }}
                        </div>
                      </div>
                      <div class="flex justify-between">
                        <div class="text-gray-500 italic">
                          <template v-if="activity.causer">
                            {{ activity.causer.email }}
                          </template>
                        </div>
                        <ul class="x-activity-list ml-6">
                          <li
                            v-for="(property, key) of activity.properties"
                            :key="key"
                          >
                            <span class="text-gray-500">{{ key }}:</span>
                            {{ property }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </template>
                  <div v-else class="text-gray-400 py-2">No History</div>
                </div>
              </template>
            </v-popover>

            <a
              class="text-gray-400 hover:text-red-500 cursor-pointer inline-block ml-1"
              @click.prevent="deleteRevenue(props.rowData.id)"
            >
              <Icon name="trashCan" />
            </a>
          </div>
        </td>
      </template>
    </YTable>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import YLEADER_ESTIMATED_REVENUES_QUERY from '@/graphql/Yleader/YleaderEstimatedRevenuesQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import UPSERT_ESTIMATE_MUTATION from '@/graphql/Yleader/UpsertYleaderEstimatedRevenues.gql'
import REVENUES_QUERY from '@/graphql/Revenue/RevenuesQuery.gql'
import DELETE_YLEADER_EST_REVENUE from '@/graphql/Yleader/DeleteYleaderEstimatedRevenue.gql'
import DELETE_YLEADER_EST_REVENUES from '@/graphql/Yleader/DeleteYleaderEstimatedRevenues.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    offer: {
      type: Object,
      required: true
    },
    showOfferId: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    revenues: {
      query: REVENUES_QUERY,
      variables() {
        return {
          filters: {
            offer: { value: [this.offer.id] },
            country: this.cleanObjectFilter(this.countries, 'code'),
            account: this.cleanObjectFilter(this.accounts, 'id'),
            redirect: this.cleanObjectFilter(this.redirects, 'id'),
            campaign: this.cleanObjectFilter(this.campaigns, 'id')
          },
          first: 300
        }
      },
      result(data) {
        if (!this.capturedRevenue && data?.data && !data?.loading) {
          this.fallbackValueAmount(data)
        }
      },
      fetchPolicy: 'cache-and-network'
    },
    yleaderEstimatedRevenues: {
      query: YLEADER_ESTIMATED_REVENUES_QUERY,
      variables() {
        return {
          filters: {
            offer: { value: [this.offer.id] },
            country: this.cleanObjectFilter(this.countries, 'code'),
            account: this.cleanObjectFilter(this.accounts, 'id'),
            redirect: this.cleanObjectFilter(this.redirects, 'id'),
            campaign: this.cleanObjectFilter(this.campaigns, 'id')
          },
          first: 300
        }
      },
      result() {},
      fetchPolicy: 'cache-and-network'
    },
    countryOptions: {
      query: COUNTRIES_QUERY,
      update: data => data.countries,
      variables() {
        return {
          filters: this.countryFilters
        }
      }
    }
  },
  watch: {
    offer: {
      deep: true,
      immediate: true,
      handler(value) {
        this.accountOptions =
          value.advertisers && value.advertisers[0]
            ? this.yleaderTypeCheck(value.advertisers[0].advertiserAccounts)
            : []

        this.offerCountryExclude = value.tagNamesByType?.countryCode?.isNot

        this.offerCountryCodes = value.tagNamesByType?.countryCode?.tags
        this.countryFilters = this.offerCountryCodes?.includes('ALL')
          ? {}
          : {
              code: {
                value: this.offerCountryCodes ?? [],
                modifiers: {
                  exclude: this.offerCountryExclude
                }
              }
            }
      }
    },
    value: {
      handler(value) {
        this.valueWarning(value)
      }
    }
  },
  data() {
    return {
      selectedRowIds: [],
      countryFilters: {},
      CAMPAIGN_OPTIONS_QUERY,
      REDIRECT_OPTIONS_QUERY,
      accountOptions: [],
      countryOptions: [],
      offerCountryCodes: [],
      offerCountryExclude: false,
      accounts: [],
      countries: [],
      campaigns: [],
      redirects: [],
      sourceIds: [],
      value: null,
      isUpserting: false,
      valueLimit: 300,
      capturedRevenue: false,
      fallbackAmount: null
    }
  },
  mounted() {
    this.$events.listen('estimateDeleted', () => {
      this.$apollo.queries.yleaderEstimatedRevenues.refetch()
    }),
      this.$events.listen('revenueDeleted', () => {
        this.$apollo.queries.revenues.refetch()
        this.clearUpsertValues()
      })
    this.$events.listen('revenueUpserted', () => {
      this.$apollo.queries.revenues.refetch()
      this.clearUpsertValues()
    })
  },
  beforeDestroy() {
    this.$events.remove('estimateDeleted')
    this.$events.remove('internalValue')
  },
  updated() {},
  computed: {
    itemTemplate() {
      return {
        accountId: null,
        countryCode: null,
        campaignId: null,
        redirectId: null,
        sourceIds: null,
        value: this.value
      }
    },
    accountIds() {
      return this.accounts.map(account => account.id)
    },
    countryCodes() {
      return this.countries.map(country => country.code)
    },
    campaignIds() {
      return this.campaigns.map(campaign => campaign.id)
    },
    redirectIds() {
      return this.redirects.map(redirect => redirect.id)
    },
    upsertEstimateKeys() {
      return this.getPermutations(
        this.getPermutations(
          this.getPermutations(
            this.getPermutations(
              this.getPermutations([this.offer.id], this.accountIds),
              this.countryCodes
            ),
            this.campaignIds
          ),
          this.redirectIds
        ),
        this.sourceIds
      )
    },
    upsertRevenueObjects() {
      let keyOrder = [
        'offerId',
        'accountId',
        'countryCode',
        'campaignId',
        'redirectId',
        'sourceId'
      ]
      return this.upsertEstimateKeys.map(key => {
        let values = key.split('-')
        let output = {}
        values.forEach((value, index) => {
          output[keyOrder[index]] = value || null
        })
        output.value = this.value
        return output
      })
    }
  },
  methods: {
    fallbackValueAmount(payoutRevenue) {
      const payouts = payoutRevenue.data.revenues.data
      let result = payouts.filter(
        item =>
          item.account.yleadType === 'real' &&
          item.campaign === null &&
          item.country === null &&
          item.redirect === null &&
          item.sourceId === null &&
          item.value > 0
      )

      this.fallbackAmount = result?.length > 0 ? Number(result[0].value) : null
      this.capturedRevenue = true
    },
    yleaderTypeCheck(value) {
      return value.filter(account => account.yleadType == 'lead')
    },
    bulkDeleteYleaders() {
      this.$apollo
        .mutate({
          mutation: DELETE_YLEADER_EST_REVENUES,
          variables: {
            input: {
              ids: this.selectedRowIds
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('yLeader Estimated Revenues Deleted', 'Success')
          this.selectedRowIds = []
          this.$events.emit('estimateDeleted')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.$toastr.s('yLeader Estimated Revenues were not deleted', 'Error')
          console.error(error)
        })
    },
    valueWarning(value) {
      if (value > this.valueLimit) {
        this.$toastr.Add({
          type: 'error',
          title: 'High Value Entered',
          msg: 'Are your sure you entered the correct amount?',
          position: 'toast-bottom-right',
          timeout: '7000',
          progressbar: false
        })
      }
    },
    clearUpsertValues() {
      this.accounts = []
      this.countries = []
      this.campaigns = []
      this.redirects = []
      this.sourceIds = []
      this.value = null
      this.capturedRevenue = false
    },
    getPermutations(array1, array2) {
      if (array2.length > 0) {
        var result = array1.reduce((a, v) => {
          return [...a, ...array2.map(x => v + '-' + x)]
        }, [])
        return result
      } else {
        return array1.map(v => v + '-')
      }
    },
    deleteRevenue(id) {
      this.$apollo
        .mutate({
          mutation: DELETE_YLEADER_EST_REVENUE,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.Add({
            title: 'Deleted',
            msg: 'Offer deleted.',
            preventDuplicates: true
          })
          this.$events.emit('estimateDeleted')
        })
        .catch(error => {
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    addRevenueRow(input) {
      const checkInput = [
        'campaignId',
        'countryCode',
        'redirectId',
        'sourceId'
      ].every(key => input[0][key] === null)

      const inputValue = Number(input[0].value)

      if (
        this.fallbackAmount &&
        inputValue > this.fallbackAmount &&
        checkInput
      ) {
        this.$toastr.e(
          'Check real payout value and try again',
          'yLeader Value Error'
        )
      } else {
        this.upsertRevenues(input)
      }
    },
    upsertRevenues(input) {
      this.isUpserting = true
      this.$apollo
        .mutate({
          mutation: UPSERT_ESTIMATE_MUTATION,
          variables: {
            input: input
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.clearUpsertValues()
          this.isUpserting = false
          this.$toastr.s('Estimate Updated', 'Success')
          this.$events.emit('revenueUpserted')
        })
        .catch(error => {
          this.isUpserting = false
          this.capturedRevenue = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      if (filter.length === 0) {
        return undefined
      }
      //console.log({
      //  value: filter.map(value => value[pluck]),
      //  modifiers: filter.modifiers
      //})
      return {
        value: filter.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    }
  }
}
</script>

<style scoped>
ul.x-activity-list > li {
  @apply border-b;
}
ul.x-activity-list > li:last-child {
  @apply border-none;
}
/* ul.x-activity-list > li:nth-child(odd) {
  @apply bg-gray-100;
} */
.x-activity-item {
  @apply border-b;
}
.x-activity-item:last-child {
  @apply border-none;
}
</style>
