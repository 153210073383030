<template>
  <div>
    <div class="bg-gray-100 p-1">
      <div class="grid grid-cols-1 sm:grid-cols-6 gap-1">
        <Superselect
          title="Country"
          v-model="filters.country.value"
          :options="[]"
          placeholder="Select"
          track-by="code"
          label="label"
          :multiple="false"
          :close-on-select="false"
          :query="COUNTRIES_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              withoutAll: true
            }
          }"
          edit-placeholder="Paste Countries"
        ></Superselect>
        <Superselect
          title="Device"
          v-model="filters.device"
          :multiple="false"
          :options="['Desktop', 'Mobile']"
        ></Superselect>
        <Superselect
          title="Offer"
          v-model="filters.offer.value"
          :options="[]"
          placeholder="Select"
          track-by="id"
          label="label"
          :multiple="false"
          :close-on-select="false"
          :query="OFFER_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
          edit-placeholder="Paste Offers"
        ></Superselect>
        <Superselect
          title="Redirect"
          v-model="filters.redirect.value"
          :options="[]"
          placeholder="Select"
          track-by="id"
          label="label"
          :multiple="false"
          :close-on-select="false"
          :query="REDIRECT_OPTIONS_QUERY"
          :query-variables="{
            first: 20,
            filters: {
              label: {
                value: ['{input}'],
                modifiers: { matchType: 'contains' }
              }
            }
          }"
          edit-placeholder="Paste Redirects"
        ></Superselect>
        <!--<Superselect
          title="Algorithms"
          v-model="filters.algorithm.value"
          :modifiers="filters.algorithm.modifiers"
          :options="[]"
          placeholder="Select"
          track-by="id"
          label="id"
          :multiple="true"
          :close-on-select="false"
          :query="ALGORITHMS_QUERY"
          edit-placeholder="Paste Algorithms"
        ></Superselect>-->
      </div>
    </div>

    <YTable
      v-if="algorithmDataCleaned"
      :data="algorithmDataCleaned || []"
      :loading="$apolloData.queries.algorithmDataCleaned.loading"
      :selected.sync="selectedRowIds"
    >
      <template slot="header-row">
        <th>Offer</th>
        <th>Redirect</th>
        <th></th>
        <th></th>
        <th>Algorithm</th>
        <th>Cleaned Date</th>
      </template>
      <template slot="row" slot-scope="props">
        <td>{{ props.rowData?.offer?.label }}</td>
        <td>
          {{ props.rowData?.redirect?.label ?? props.rowData?.redirect?.id }}
        </td>
        <td>{{ props.rowData?.country?.toUpperCase() }}</td>
        <td>{{ props.rowData?.device?.capitalize() }}</td>
        <td>
          {{ props.rowData?.algorithmId }}
        </td>
        <td>
          {{ props.rowData.cleanedAt }}
        </td>
      </template>
    </YTable>
    <div class="mt-2">
      <YButton
        class="block m-auto"
        color="link"
        @click="loadMore"
        :is-loading="$apollo.queries.algorithmDataCleaned.loading"
        >LOAD MORE</YButton
      >
    </div>
  </div>
</template>
<script>
import ALGORITHMS_DATA_CLEANED_ITEMS_QUERY from '@/graphql/AlgorithmsDataCleaned/AlgorithmsDataCleaned.gql'
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import ALGORITHMS_QUERY from '@/graphql/Algorithm/AlgorithmsQuery.gql'
export default {
  apollo: {
    algorithmDataCleaned: {
      query: ALGORITHMS_DATA_CLEANED_ITEMS_QUERY,
      variables() {
        return {
          filters: {
            offerId:
              this.filters.offer.value !== null
                ? this.filters.offer.value.id
                : undefined,
            redirectId:
              this.filters.redirect.value !== null
                ? this.filters.redirect.value.id
                : undefined,
            countryCode:
              this.filters.country.value !== null
                ? this.filters.country.value.code
                : undefined,
            mobileDesktop: this.filters.device
              ? this.filters.device
              : undefined,
            paginate: this.perPage
          }
        }
      }
    }
  },
  data() {
    return {
      OFFER_OPTIONS_QUERY,
      REDIRECT_OPTIONS_QUERY,
      COUNTRIES_QUERY,
      ALGORITHMS_QUERY,
      algorithmDataCleaned: {},
      selectedRowIds: [],
      perPage: 50,
      filters: {
        device: 'Mobile',
        level: 'General',
        country: {
          value: null,
          title: 'Country',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'code'
        },
        redirect: {
          value: null,
          title: 'Redirect',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
        offer: {
          value: null,
          title: 'Offer',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        }
      }
    }
  },
  methods: {
    loadMore() {
      this.perPage = this.perPage + 25
    },
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilterValue(filter, pluck) {
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }
      return this.cleanFilter(filter).value.map(value => value[pluck])[0]
    }
  }
}
</script>
<style></style>
