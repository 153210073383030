<template>
  <div class="shadow rounded-lg">
    <div class="flex justify-between items-center bg-gray-100 px-4 pt-2">
      <div class="text-muted">
        <span>
          {{ selectedCount }}
          {{ selectedCount == 1 ? 'item' : 'items' }} selected
          <small class="pl-3" v-if="rowsAreSelected">
            <a
              href="#"
              @click.prevent="clearAllSelected"
              class="link"
              style="border-radius: 2rem; padding: 0.1rem 0.6rem"
              >CLEAR</a
            >
          </small>
        </span>
      </div>
      <div class="text-right">
        <YButton
          class="text-blue-600 hover:bg-blue-100"
          :disabled="!rowsAreSelected"
          @click="exportRows"
        >
          Export Wizard
        </YButton>
        <YButton class="text-red-600 hover:bg-red-100" :disabled="true">
          Delete
        </YButton>
      </div>
    </div>
    <YTable
      v-if="data"
      :selectable="true"
      selectBy="id"
      :selected.sync="selectedRowIds"
      :loading="isLoading"
      :data="data || []"
    >
      <template slot="header-row">
        <th></th>
        <th>Front-end</th>
        <th>
          Advertisers
        </th>
        <th>
          Countries
        </th>
        <th>
          <div class="flex flex-col 2xl:flex-row 2xl:justify-around text-left">
            <div class="w-1/2">Access Rule</div>
            <div class="w-1/2 mt-1 2xl:mt-0 2xl:pl-2">Subscribers</div>
          </div>
        </th>
        <th>
          Status
        </th>
        <th></th>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          <campaign-info-cell
            :row-data="props.rowData"
            :row-index="props.rowIndex"
            :key="`campaign-info-${props.rowData.id}`"
          ></campaign-info-cell>
        </td>
        <td>
          <div v-if="props.rowData.showFrontend && props.rowData.showFrontend == 'yTrack'" >
            <Tag color="blue" class="mr-1 mb-1"> {{ props.rowData.showFrontend }} </Tag>
          </div>
          <div v-if="props.rowData.showFrontend && props.rowData.showFrontend == 'All In Affiliates'" >
            <Tag color="indigo" class="mr-1 mb-1"> {{ props.rowData.showFrontend }} </Tag>
          </div>
          <div v-if="props.rowData.showFrontend && props.rowData.showFrontend == 'Hidden'" >
            <Tag color="darkRed" class="mr-1 mb-1"> {{ props.rowData.showFrontend }} </Tag>
          </div>
        </td>
        <td>
          <ul>
            <li
              v-for="advertiser in props.rowData.advertisers"
              :key="advertiser.id"
            >
              <router-link
                :to="{
                  name: 'Advertiser',
                  params: { id: advertiser.advertiserId }
                }"
                class="link"
              >
                {{ advertiser.name }}
              </router-link>
            </li>
          </ul>
        </td>
        <td>
          <div class="w-24">
            <expandable-countries-from-payouts
              :payouts="props.rowData.payouts"
              :show-count="20"
              :key="`campaign-countries-${props.rowData.id}`"
            ></expandable-countries-from-payouts>
          </div>
        </td>
        <td>
          <div class="flex flex-col 2xl:flex-row 2xl:justify-start gap-3 py-1">
            <div class="w-auto 2xl:w-1/2">
              <campaign-access-rules-cell
                :row-data="props.rowData"
                :row-index="props.rowIndex"
                :key="`access-rules-${props.rowData.id}`"
              ></campaign-access-rules-cell>
            </div>
            <div class="w-1/2">
              <campaign-subscribers-cell
                :row-data="props.rowData"
                :row-index="props.rowIndex"
                :key="`subscribers-${props.rowData.id}`"
              ></campaign-subscribers-cell>
            </div>
          </div>
        </td>
        <td>
          <campaign-status-toggle-button
            :campaign="props.rowData"
            :height="30"
            :width="80"
            :font-size="11"
            :key="`status-toggle-button-${props.rowData.id}`"
          ></campaign-status-toggle-button>
        </td>
        <td style="padding:0;">
          <admin-campaign-actions
            :campaign="props.rowData"
            :key="`campaign-actions-${props.rowData.id}`"
          ></admin-campaign-actions>
        </td>
      </template>
    </YTable>
    <campaign-export-tool
      v-if="this.exportCampaignIds.length > 0"
      :campaigns="this.exportCampaignIds"
      @close="
        () => {
          this.exportCampaignIds = []
        }
      "
    ></campaign-export-tool>
  </div>
</template>

<script>
import CampaignInfoCell from '../VuetableCells/CampaignInfoCell'
import ExpandableCountriesFromPayouts from '@/components/general/ExpandableCountriesFromPayouts'
import CampaignSubscribersCell from '../VuetableCells/CampaignSubscribersCell'
import CampaignAccessRulesCell from '../VuetableCells/CampaignAccessRulesCell'
import AdminCampaignActions from './AdminCampaignActions'
import CampaignStatusToggleButton from './CampaignStatusToggleButton'
import VuetableFieldYCheckbox from '../VuetableComponentFields/VuetableFieldYCheckbox.vue'
import CampaignExportTool from './CampaignExportTool'

export default {
  components: {
    CampaignInfoCell,
    ExpandableCountriesFromPayouts,
    CampaignSubscribersCell,
    CampaignAccessRulesCell,
    AdminCampaignActions,
    CampaignStatusToggleButton,
    CampaignExportTool
  },
  props: {
    data: {
      default: () => {
        ;[]
      },
      type: Array
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      selectedRowIds: [],
      fields: [
        {
          name: VuetableFieldYCheckbox
        },
        {
          name: 'campaign-info-slot',
          title: ''
        },
        {
          name: 'advertiser-slot',
          title: 'Advertiser'
        },
        {
          name: 'countries-slot',
          title: 'Countries'
        },
        {
          name: 'access-rules-slot',
          title: 'Access Rules'
        },
        {
          name: 'subscribers-slot',
          title: 'Subscribers'
        },
        {
          name: 'status-slot',
          title: 'Status'
        },
        {
          name: 'campaign-actions-slot',
          title: ''
        }
      ],
      exportCampaignIds: [],
      perPage: 50,
      sortOrder: [],
      multiSort: false,
      filters: {},
      css: {
        tableClass: 'table table-sm table-striped dt-responsive dataTable',
        loadingClass: 'loading',
        ascendingIcon: 'custom-table-sort-arrow-up',
        descendingIcon: 'custom-table-sort-arrow-down',
        detailRowClass: 'vuetable-detail-row',
        sortHandleIcon: 'grey sidebar icon'
      }
    }
  },
  computed: {
    rowsAreSelected() {
      return this.selectedRowIds.length > 0
    },
    selectedCount() {
      return this.selectedRowIds.length
    }
  },
  mounted() {},
  updated() {},

  methods: {
    checkboxToggled() {
      this.selectedRowIds = this.$refs.vuetable.selectedTo
    },
    clearAllSelected() {
      this.selectedRowIds = []
      this.$refs.vuetable.selectedTo = []
    },
    onPaginationData() {
      this.$events.emit('onPaginationData', true)
    },
    onChangePage(page) {
      this.$events.emit('changeReportPage', page)
    },
    loading() {
      this.$events.emit('loading', true)
    },
    loaded() {
      this.$events.emit('loaded', true)
    },
    transformAccountManager(value) {
      if (!value) {
        return null
      }
      return `<a href="/admin/user/${value.id}">${value.name}</a>`
    },
    transformUser(value) {
      if (!value) {
        return null
      }
      return `<a href="/admin/user/${value.id}">${value.id} - ${value.login}</a>`
    },
    exportRows() {
      this.exportCampaignIds = this.selectedRowIds
    }
  }
}
</script>
<style scoped></style>
