var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.enablePresets ? _c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('Superselect', {
    staticClass: "admin-report-multiselect w-30 md:w-64",
    attrs: {
      "title": "Presets",
      "options": _vm.reportPresetOptions,
      "placeholder": "Select Preset",
      "track-by": "id",
      "label": "name",
      "selectedLabel": "",
      "selectLabel": "",
      "deselectLabel": "",
      "allow-empty": false,
      "multiple": false,
      "loading": _vm.$apollo.queries.requestedPreset.loading || _vm.$apollo.queries.reportPresetOptions.loading,
      "disabled": _vm.$apollo.queries.requestedPreset.loading
    },
    on: {
      "select": _vm.requestReportPreset,
      "remove": _vm.clearReportPreset
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (props) {
        return [_c('div', {
          staticClass: "option__desc"
        }, [_c('div', {
          staticClass: "option__title"
        }, [_c('div', {
          staticClass: "flex justify-between items-center"
        }, [_c('span', [_vm._v(_vm._s(props.option.name))]), !props.option.public ? _c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `PRIVATE`,
            expression: "`PRIVATE`"
          }],
          staticStyle: {
            "opacity": "0.6"
          },
          attrs: {
            "name": "eye",
            "size": 4
          }
        }) : _vm._e()], 1)])])];
      }
    }], null, false, 2089959016),
    model: {
      value: _vm.reportPresetFilter,
      callback: function ($$v) {
        _vm.reportPresetFilter = $$v;
      },
      expression: "reportPresetFilter"
    }
  }), _c('div', {
    staticClass: "report-preset-buttons flex items-stretch overflow-hidden"
  }, [_c('div', {
    staticClass: "btn-group ml-2",
    attrs: {
      "role": "group"
    }
  }, [_c('transition', {
    attrs: {
      "css": false
    },
    on: {
      "before-enter": _vm.animatePresetButtonsBeforeEnter,
      "enter": _vm.animatePresetButtonsEnter,
      "leave": _vm.animatePresetButtonsLeave
    }
  }, [_vm.reportPresetFilter.id != 'default' && _vm.presetStatus == 'active' ? _c('div', {
    staticClass: "btn-wrapper"
  }, [_c('YButton', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltipOptions('Copy To Clipboard', ''),
      expression: "tooltipOptions('Copy To Clipboard', '')"
    }],
    staticClass: "btn-inverse",
    attrs: {
      "disabled": _vm.$apollo.queries.requestedPreset.loading
    },
    on: {
      "click": _vm.copyPresetLinkToClipboard
    }
  }, [_c('Icon', {
    attrs: {
      "name": "link",
      "size": 4
    }
  })], 1)], 1) : _vm._e()]), _c('transition', {
    attrs: {
      "css": false
    },
    on: {
      "before-enter": _vm.animatePresetButtonsBeforeEnter,
      "enter": _vm.animatePresetButtonsEnter,
      "leave": _vm.animatePresetButtonsLeave
    }
  }, [_vm.reportPresetFilter.id != 'default' && _vm.presetStatus == 'active' ? _c('div', {
    staticClass: "btn-wrapper"
  }, [_c('YButton', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltipOptions('Delete', 'danger'),
      expression: "tooltipOptions('Delete', 'danger')"
    }],
    staticClass: "btn-inverse",
    attrs: {
      "disabled": _vm.$apollo.queries.requestedPreset.loading
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('delete-preset');
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "trashCan",
      "size": 4
    }
  })], 1)], 1) : _vm._e()]), _c('transition', {
    attrs: {
      "css": false
    },
    on: {
      "before-enter": _vm.animatePresetButtonsBeforeEnter,
      "enter": _vm.animatePresetButtonsEnter,
      "leave": _vm.animatePresetButtonsLeave
    }
  }, [_vm.presetStatus == 'modified' ? _c('div', {
    staticClass: "btn-wrapper"
  }, [_c('YButton', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltipOptions('Save As', ''),
      expression: "tooltipOptions('Save As', '')"
    }],
    staticClass: "btn btn-inverse",
    attrs: {
      "disabled": _vm.$apollo.queries.requestedPreset.loading
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('save-preset');
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "contentSave",
      "size": 4
    }
  })], 1)], 1) : _vm._e()])], 1)])], 1)]) : _vm._e(), _c('Widget', {
    staticClass: "reports-form"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-200"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "block lg:flex items-center lg:justify-between w-full"
  }, [_c('YDateTimeRangePicker', {
    model: {
      value: _vm.filters.dateTimeRange,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "dateTimeRange", $$v);
      },
      expression: "filters.dateTimeRange"
    }
  }), _c('div', {
    staticClass: "flex align-items-center mt-2 lg:mt-0"
  }, [_c('div', [_c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.presetStatus == 'modified' ? 'Modified' : null,
      expression: "presetStatus == 'modified' ? 'Modified' : null"
    }],
    staticClass: "status-ball mr-3",
    class: _vm.presetStatus == 'active' ? 'bg-success' : 'bg-warning'
  })])]), _c('modal', {
    attrs: {
      "name": "delete-preset",
      "width": "95%",
      "height": "auto",
      "max-width": 600,
      "adaptive": true
    }
  }, [_c('AdminReportsPresetDialogDelete', {
    attrs: {
      "preset-filter": _vm.reportPresetFilter
    },
    on: {
      "close": function ($event) {
        return _vm.$modal.hide('delete-preset');
      },
      "success": _vm.presetDeleted
    }
  })], 1), _c('modal', {
    attrs: {
      "name": "save-preset",
      "width": "95%",
      "height": "auto",
      "max-width": 600,
      "adaptive": true
    }
  }, [_c('AdminReportsPresetDialogSave', {
    attrs: {
      "preset-filter": _vm.reportPresetFilter,
      "report": JSON.stringify(_vm.report)
    },
    on: {
      "close": function ($event) {
        return _vm.$modal.hide('save-preset');
      },
      "success": _vm.presetSaved
    }
  })], 1)], 1)])], 2), _c('transition', {
    attrs: {
      "css": false
    },
    on: {
      "before-enter": _vm.animateShowFiltersBeforeEnter,
      "enter": _vm.animateShowFiltersEnter,
      "leave": _vm.animateShowFiltersLeave
    }
  }, [_c('WidgetBody', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilters,
      expression: "showFilters"
    }],
    staticClass: "pt-4 bg-gray-100",
    staticStyle: {
      "border-top": "1px solid #D9D9D9"
    }
  }, [_c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-6 gap-1"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.publisher,
      expression: "columnData.publisher"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Publisher",
      "modifiers": _vm.filters.publisher.modifiers,
      "options": _vm.options.publisher,
      "track-by": "id",
      "label": "label",
      "edit-placeholder": "Paste IDs / Logins",
      "optionRequiredOnTag": true,
      "query": _vm.queries.PUBLISHER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      },
      "query-result-map": {
        id: 'id',
        label: 'label'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.publisher, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.publisher.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.publisher, "value", $$v);
      },
      expression: "filters.publisher.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.advertiser && _vm.masterColumnData.advertiser.filter.visible,
      expression: "\n              columnData.advertiser &&\n                masterColumnData.advertiser.filter.visible\n            "
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Advertiser",
      "modifiers": _vm.filters.advertiser.modifiers,
      "options": _vm.options.advertisers,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.advertiser, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.advertiser.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.advertiser, "value", $$v);
      },
      expression: "filters.advertiser.value"
    }
  }), _vm.filters.advertiser.value.length > 0 ? _c('Superselect', {
    staticClass: "mt-1",
    attrs: {
      "title": "Advertiser Account",
      "modifiers": _vm.filters.advertiserAccount.modifiers,
      "options": _vm.advertiserAccountOptions,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.advertiserAccount, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.advertiserAccount.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.advertiserAccount, "value", $$v);
      },
      expression: "filters.advertiserAccount.value"
    }
  }) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.country,
      expression: "columnData.country"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Country",
      "editPlaceholder": "Paste Country Codes",
      "modifiers": _vm.filters.country.modifiers,
      "options": _vm.options.country,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.COUNTRIES_QUERY,
      "query-result-map": {
        id: 'code',
        label: 'label'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.country, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.country.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.country, "value", $$v);
      },
      expression: "filters.country.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.offer,
      expression: "columnData.offer"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Direct Offer",
      "modifiers": _vm.filters.offer.modifiers,
      "options": _vm.options.offer,
      "placeholder": "Select Offer",
      "options-limit": 100,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.OFFER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          destinationType: {
            value: ['direct']
          }
        },
        first: 100
      },
      "query-result-map": {
        id: 'id',
        label: 'label'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.offer, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.offer.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.offer, "value", $$v);
      },
      expression: "filters.offer.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.template,
      expression: "columnData.template"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Template Offer",
      "modifiers": _vm.filters.template.modifiers,
      "options": _vm.options.template,
      "placeholder": "Select Offer",
      "options-limit": 100,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.OFFER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          destinationType: {
            value: ['template']
          }
        },
        first: 100
      },
      "query-result-map": {
        id: 'id',
        label: 'label'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.template, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.template.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.template, "value", $$v);
      },
      expression: "filters.template.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.source,
      expression: "columnData.source"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Source",
      "modifiers": _vm.filters.source.modifiers,
      "options": _vm.options.source,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.source, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.source.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.source, "value", $$v);
      },
      expression: "filters.source.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.redirect,
      expression: "columnData.redirect"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Redirect",
      "modifiers": _vm.filters.redirect.modifiers,
      "options": _vm.options.redirect,
      "placeholder": "Select Redirect",
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      },
      "queryResultMap": {
        id: 'id',
        label: 'label'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.redirect, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.redirect.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.redirect, "value", $$v);
      },
      expression: "filters.redirect.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.redirectLink,
      expression: "columnData.redirectLink"
    }]
  }, [_vm.filters.redirectLink ? _c('Superselect', {
    attrs: {
      "title": "Redirect Link",
      "modifiers": _vm.filters.redirectLink.modifiers,
      "forceEdit": true,
      "editPlaceholder": "",
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.redirectLink, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.redirectLink.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.redirectLink, "value", $$v);
      },
      expression: "filters.redirectLink.value"
    }
  }) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.miniDomain,
      expression: "columnData.miniDomain"
    }]
  }, [_vm.filters.miniDomain ? _c('Superselect', {
    attrs: {
      "title": "Mini Domain",
      "modifiers": _vm.filters.miniDomain.modifiers,
      "forceEdit": true,
      "editPlaceholder": "",
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.miniDomain, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.miniDomain.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.miniDomain, "value", $$v);
      },
      expression: "filters.miniDomain.value"
    }
  }) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.vertical,
      expression: "columnData.vertical"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Verticals",
      "modifiers": _vm.filters.vertical.modifiers,
      "options": _vm.options.vertical,
      "editPlaceholder": "Paste Verticals",
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['vertical'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 100
      },
      "query-result-map": {
        id: 'slug',
        label: 'name'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.vertical, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.vertical.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.vertical, "value", $$v);
      },
      expression: "filters.vertical.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.campaign,
      expression: "columnData.campaign"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Campaign",
      "modifiers": _vm.filters.campaign.modifiers,
      "options": _vm.options.campaign,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.CAMPAIGN_OPTIONS_QUERY,
      "queryResultMap": {
        id: 'id',
        label: 'label'
      },
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.campaign, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.campaign.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.campaign, "value", $$v);
      },
      expression: "filters.campaign.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.mobileDesktop,
      expression: "columnData.mobileDesktop"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Mobile / Desktop",
      "placeholder": "Select One",
      "modifiers": _vm.filters.mobileDesktop.modifiers,
      "multiple": false,
      "options": _vm.options.mobileDesktop,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.mobileDesktop, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.mobileDesktop.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.mobileDesktop, "value", $$v);
      },
      expression: "filters.mobileDesktop.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.algorithmId,
      expression: "columnData.algorithmId"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Algorithm Id",
      "modifiers": _vm.filters.algorithmId.modifiers,
      "placeholder": "Select One",
      "options": _vm.options.algorithmId,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.algorithmId, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.algorithmId.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.algorithmId, "value", $$v);
      },
      expression: "filters.algorithmId.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.browser,
      expression: "columnData.browser"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Browser",
      "editPlaceholder": "",
      "modifiers": _vm.filters.browser.modifiers,
      "forceEdit": true,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.browser, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.browser.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.browser, "value", $$v);
      },
      expression: "filters.browser.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.os,
      expression: "columnData.os"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "OS",
      "modifiers": _vm.filters.os.modifiers,
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.os, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.os.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.os, "value", $$v);
      },
      expression: "filters.os.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.device,
      expression: "columnData.device"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Device",
      "modifiers": _vm.filters.device.modifiers,
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.device, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.device.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.device, "value", $$v);
      },
      expression: "filters.device.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.ip,
      expression: "columnData.ip"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "IP Address",
      "modifiers": _vm.filters.ip.modifiers,
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.ip, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.ip.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.ip, "value", $$v);
      },
      expression: "filters.ip.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.forwardedForIp,
      expression: "columnData.forwardedForIp"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Forwarded For IP",
      "modifiers": _vm.filters.forwardedForIp.modifiers,
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.forwardedForIp, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.forwardedForIp.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.forwardedForIp, "value", $$v);
      },
      expression: "filters.forwardedForIp.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.usageType,
      expression: "columnData.usageType"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Usage Type",
      "modifiers": _vm.filters.usageType.modifiers,
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.usageType, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.usageType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.usageType, "value", $$v);
      },
      expression: "filters.usageType.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.connectionType,
      expression: "columnData.connectionType"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Connection Type",
      "modifiers": _vm.filters.connectionType.modifiers,
      "options": _vm.options.connectionType,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.connectionType, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.connectionType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.connectionType, "value", $$v);
      },
      expression: "filters.connectionType.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.isp,
      expression: "columnData.isp"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "ISP",
      "modifiers": _vm.filters.isp.modifiers,
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.isp, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.isp.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.isp, "value", $$v);
      },
      expression: "filters.isp.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.language,
      expression: "columnData.language"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Language",
      "modifiers": _vm.filters.language.modifiers,
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.language, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.language.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.language, "value", $$v);
      },
      expression: "filters.language.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.keyword,
      expression: "columnData.keyword"
    }]
  }, [_vm.filters.keyword ? _c('Superselect', {
    attrs: {
      "title": "Keyword",
      "modifiers": _vm.filters.keyword.modifiers,
      "forceEdit": true,
      "editPlaceholder": "",
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.keyword, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.keyword.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.keyword, "value", $$v);
      },
      expression: "filters.keyword.value"
    }
  }) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.subId,
      expression: "columnData.subId"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Sub ID",
      "modifiers": _vm.filters.subId.modifiers,
      "forceEdit": true,
      "editPlaceholder": "",
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.subId, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.subId.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.subId, "value", $$v);
      },
      expression: "filters.subId.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.referer,
      expression: "columnData.referer"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Referer",
      "modifiers": _vm.filters.referer.modifiers,
      "forceEdit": true,
      "editPlaceholder": "",
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.referer, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.referer.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.referer, "value", $$v);
      },
      expression: "filters.referer.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.impressionId,
      expression: "columnData.impressionId"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Impression ID",
      "modifiers": _vm.filters.impressionId.modifiers,
      "forceEdit": true,
      "editPlaceholder": "",
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.impressionId, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.impressionId.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.impressionId, "value", $$v);
      },
      expression: "filters.impressionId.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.clickId,
      expression: "columnData.clickId"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Click ID",
      "modifiers": _vm.filters.clickId.modifiers,
      "forceEdit": true,
      "editPlaceholder": "",
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.clickId, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.clickId.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.clickId, "value", $$v);
      },
      expression: "filters.clickId.value"
    }
  })], 1), _c('div', {
    class: _vm.colClass
  }, [_c('Superselect', {
    attrs: {
      "title": "Domain Mismatch",
      "placeholder": "Select One",
      "modifiers": _vm.filters.domainMismatch.modifiers,
      "multiple": false,
      "options": _vm.options.domainMismatch,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.domainMismatch, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.domainMismatch.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.domainMismatch, "value", $$v);
      },
      expression: "filters.domainMismatch.value"
    }
  })], 1), _c('div', {
    class: _vm.colClass
  }, [_c('Superselect', {
    attrs: {
      "title": "yLeader",
      "placeholder": "Select One",
      "modifiers": _vm.filters.isYleader.modifiers,
      "multiple": false,
      "options": _vm.options.isYleader,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.isYleader, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.isYleader.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.isYleader, "value", $$v);
      },
      expression: "filters.isYleader.value"
    }
  })], 1), _c('div', {
    class: _vm.colClass
  }, [_c('Superselect', {
    attrs: {
      "title": "Smart Link",
      "placeholder": "Select One",
      "modifiers": _vm.filters.campaignSmartLink.modifiers,
      "multiple": false,
      "options": _vm.options.campaignSmartLink,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.campaignSmartLink, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.campaignSmartLink.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.campaignSmartLink, "value", $$v);
      },
      expression: "filters.campaignSmartLink.value"
    }
  })], 1), _c('div', {
    class: _vm.colClass
  }, [_c('Superselect', {
    attrs: {
      "title": "Advertiser Account Manager",
      "placeholder": "Select One",
      "multiple": true,
      "modifiers": _vm.filters.advertiserAccountManager.modifiers,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.ACCOUNT_MANAGER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: [],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      },
      "query-result-map": {
        id: 'id',
        label: 'label'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.advertiserAccountManager, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.advertiserAccountManager.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.advertiserAccountManager, "value", $$v);
      },
      expression: "filters.advertiserAccountManager.value"
    }
  })], 1), _c('div', {
    class: _vm.colClass
  }, [_c('Superselect', {
    attrs: {
      "title": "Publisher Account Manager",
      "placeholder": "Select One",
      "multiple": true,
      "modifiers": _vm.filters.publisherAccountManager.modifiers,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.ACCOUNT_MANAGER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: [],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      },
      "query-result-map": {
        id: 'id',
        label: 'label'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.publisherAccountManager, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.publisherAccountManager.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.publisherAccountManager, "value", $$v);
      },
      expression: "filters.publisherAccountManager.value"
    }
  })], 1), _c('div', {
    class: _vm.colClass
  }, [_c('Superselect', {
    attrs: {
      "title": "Advertiser Tracking Platform",
      "placeholder": "Select One",
      "multiple": true,
      "modifiers": _vm.filters.advertiserTrackingPlatform.modifiers,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['trackingPlatform'],
            modifiers: {
              matchType: 'exact'
            }
          }
        },
        first: 100
      },
      "query-result-map": {
        id: 'name',
        label: 'name'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.advertiserTrackingPlatform, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.advertiserTrackingPlatform.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.advertiserTrackingPlatform, "value", $$v);
      },
      expression: "filters.advertiserTrackingPlatform.value"
    }
  })], 1), _c('div', {
    class: _vm.colClass
  }, [_c('Superselect', {
    attrs: {
      "title": "Publisher Tracking Platform",
      "placeholder": "Select One",
      "multiple": true,
      "modifiers": _vm.filters.publisherTrackingPlatform.modifiers,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.TAGS_QUERY,
      "query-variables": {
        filters: {
          type: {
            value: ['trackingPlatform'],
            modifiers: {
              matchType: 'exact'
            }
          }
        },
        first: 100
      },
      "query-result-map": {
        id: 'slug',
        label: 'name'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.publisherTrackingPlatform, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.publisherTrackingPlatform.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.publisherTrackingPlatform, "value", $$v);
      },
      expression: "filters.publisherTrackingPlatform.value"
    }
  })], 1), _c('div', {
    class: _vm.colClass
  }, [_c('Superselect', {
    attrs: {
      "title": "Browser Family",
      "options": _vm.options.browserFamily,
      "modifiers": _vm.filters.browserFamily.modifiers,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.browserFamily, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.browserFamily.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.browserFamily, "value", $$v);
      },
      expression: "filters.browserFamily.value"
    }
  })], 1), _c('div', {
    class: _vm.colClass
  }, [_c('Superselect', {
    attrs: {
      "title": "Region",
      "options": _vm.options.regionCode,
      "modifiers": _vm.filters.regionCode.modifiers,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.regionCode, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.regionCode.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.regionCode, "value", $$v);
      },
      expression: "filters.regionCode.value"
    }
  })], 1), _c('div', {
    class: _vm.colClass
  }, [_c('Superselect', {
    attrs: {
      "title": "Conversion Type",
      "placeholder": "Select One",
      "multiple": true,
      "modifiers": _vm.filters.conversionType.modifiers,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.CONVERSION_TYPES_QUERY,
      "queryDefaultInputText": "",
      "query-variables": {
        filters: {
          name: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      },
      "query-result-map": {
        id: 'name',
        label: 'name'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.conversionType, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.conversionType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.conversionType, "value", $$v);
      },
      expression: "filters.conversionType.value"
    }
  })], 1)]), _c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-3 pt-5 gap-1"
  }, [_c('div', [_c('Superselect', {
    attrs: {
      "title": "Show",
      "options": _vm.showColumnsOptions,
      "multiple": true,
      "placeholder": "Select Columns",
      "track-by": "id",
      "label": "label",
      "selectedLabel": "",
      "selectLabel": "",
      "deselectLabel": "",
      "close-on-select": false
    },
    on: {
      "select": _vm.columnSelected,
      "remove": _vm.columnRemoved
    },
    model: {
      value: _vm.showColumnsFilter,
      callback: function ($$v) {
        _vm.showColumnsFilter = $$v;
      },
      expression: "showColumnsFilter"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("No Columns Found")])])], 1), _c('div', [_c('Superselect', {
    attrs: {
      "title": "Sort By",
      "options": _vm.sortByOptions,
      "multiple": true,
      "placeholder": "Sort By",
      "track-by": "value",
      "allow-empty": false,
      "label": "label",
      "selectedLabel": "",
      "selectLabel": "",
      "deselectLabel": ""
    },
    model: {
      value: _vm.sortByFilter,
      callback: function ($$v) {
        _vm.sortByFilter = $$v;
      },
      expression: "sortByFilter"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("No Columns Found")])])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: true,
      expression: "true"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Options",
      "options": _vm.configOptions,
      "multiple": true,
      "placeholder": "Select Options",
      "track-by": "id",
      "label": "label",
      "selectedLabel": "",
      "selectLabel": "",
      "deselectLabel": "",
      "close-on-select": false
    },
    model: {
      value: _vm.configFilter,
      callback: function ($$v) {
        _vm.configFilter = $$v;
      },
      expression: "configFilter"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("No Option Found")])])], 1)])])], 1), _c('WidgetHeader', {
    staticClass: "bg-opacity-faded flex-wrap",
    staticStyle: {
      "border-top": "1px solid #D9D9D9"
    }
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "mb-1 sm:mb-0"
  }, [_c('YButton', {
    staticClass: "hover:bg-gray-100 focus:bg-blue-100 text-blue-600 hover:text-blue-700 flex items-center outline-none-important",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.showFilters = !_vm.showFilters;
      }
    }
  }, [_c('Icon', {
    staticClass: "inline mr-1",
    attrs: {
      "name": _vm.showFilters ? 'minus' : 'plus',
      "size": 4
    }
  }), _vm._v(" " + _vm._s(_vm.showFilters ? 'Hide' : 'Show Filters'))], 1)], 1), _c('div', {
    staticClass: "flex items-center flex-row-reverse"
  }, [_c('YButton', {
    staticClass: "hover:bg-gray-100 focus:bg-blue-100 text-blue-600 hover:text-blue-700 flex items-center outline-none-important mt-2 px-3 md:px-6",
    attrs: {
      "disabled": _vm.formButtonsDisabled
    },
    on: {
      "click": function ($event) {
        return _vm.resetFilters();
      }
    }
  }, [_vm._v("Reset Filters")]), _c('div', {
    staticClass: "flex mt-2 md:mt-0"
  }, [_c('YButton', {
    staticClass: "bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 text-white mr-1 md:mr-3 outline-none-important",
    attrs: {
      "disabled": _vm.formButtonsDisabled,
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": function ($event) {
        return _vm.doFilter();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1)])], 2)], 1), _vm.responseMessage ? _c('YAlert', {
    attrs: {
      "type": _vm.responseMessage.type
    }
  }, [_vm._v(_vm._s(_vm.responseMessage.content))]) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }