var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "py-6 border-b",
    attrs: {
      "closeable": true
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "mr-2",
    attrs: {
      "name": "pencil",
      "size": 4
    }
  }), [_vm._v("Edit " + _vm._s(_vm.domain.id))]], 2)]), _c('WidgetBody', {
    staticClass: "pt-5"
  }, [_c('Superselect', {
    attrs: {
      "title": "Status",
      "multiple": false,
      "placeholder": "Select",
      "allow-empty": false,
      "query": _vm.INTROSPECTION_TYPE_QUERY,
      "query-variables": {
        name: 'DomainStatusEnum'
      },
      "query-result-function": function (data) {
        return data.__type.enumValues.map(function (value) {
          return value.name;
        });
      }
    },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }), _vm.status == 'flagged' && _vm.readyDomains.length > 0 ? _c('Superselect', {
    staticClass: "mt-2",
    attrs: {
      "title": "Replacement Domain",
      "options": _vm.readyDomains,
      "placeholder": "Select",
      "multiple": false,
      "track-by": "id",
      "label": "label"
    },
    model: {
      value: _vm.replacementDomain,
      callback: function ($$v) {
        _vm.replacementDomain = $$v;
      },
      expression: "replacementDomain"
    }
  }) : _vm._e(), _vm.status == 'flagged' && _vm.readyDomains.length <= 0 ? _c('div', {
    staticClass: "pt-1 text-center"
  }, [_c('small', {
    staticClass: "text-muted",
    staticStyle: {
      "font-style": "italic"
    }
  }, [_vm._v("No Domains Available")])]) : _vm._e(), _c('Superselect', {
    staticClass: "mt-2",
    attrs: {
      "title": "Domain Type",
      "options": _vm.typeOptions,
      "placeholder": "Select",
      "multiple": false
    },
    model: {
      value: _vm.domainType,
      callback: function ($$v) {
        _vm.domainType = $$v;
      },
      expression: "domainType"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center",
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('YButton', {
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button",
      "color": "blue",
      "disabled": !_vm.canSubmit
    },
    on: {
      "click": _vm.updateDomain
    }
  }, [_vm._v("Update")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }