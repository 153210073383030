<template>
  <div class="widget-heading flex justify-between items-center flex-shrink">
    <div class="items-center flex" v-if="$slots.default">
      <Icon v-show="icon" name="iconName" class="mr-4" :class="iconClass" />
      <h4 class="widget-title">
        <slot></slot>
      </h4>
      <div v-if="badge" class="ml-3" :class="'badge badge-' + badge.style">
        {{ badge.text }}
      </div>
    </div>
    <slot name="rawContent"></slot>
    <slot name="right">
      <h4 v-if="hasCloseListener" class="ml-4" :class="closeClass">
        <CloseX @click="$emit('close')"></CloseX>
      </h4>
    </slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    closeable: {
      // @deprecated  Use HasCloseListener computed prop instead
      type: Boolean,
      default: false
    },
    icon: {},
    badge: {},
    iconStyle: {},
    closeClass: {
      type: String,
      default: 'text-blue-600 hover:text-blue-700'
    }
  },
  data() {
    return {}
  },
  computed: {
    hasCloseListener() {
      return this.$listeners && this.$listeners.close
    },
    iconClass() {
      if (this.iconStyle && this.iconStyle == 2) {
        return this.icon + ' fs-1'
      } else {
        return this.icon + ' fs-3'
      }
    },
    iconStyleCode() {
      if (this.iconStyle && this.iconStyle == 2) {
        return ''
      } else {
        return 'opacity: 0.5'
      }
    }
  },
  mounted() {},

  updated() {},

  methods: {}
}
</script>
<style></style>
