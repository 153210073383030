<template>
  <div>
    <div class="mt-3 flex items-center justify-between">
      <YButton
        class="text-blue-600 hover:bg-blue-100 rounded text-left block mr-2 truncate flex-grow"
        @click="showServers = !showServers"
      >
        <span class="flex items-center">
          <Icon v-if="showServers" name="menuDown" :size="4" />
          <Icon v-else name="menuRight" :size="4" />
          Servers
        </span>
        <Spinner
          v-if="$apollo.queries.servers.loading"
          :size="8"
          color="blue"
        />
      </YButton>
      <div>
        <server-actions
          v-show="false"
          ref="serverActions"
          :provider="provider"
        ></server-actions>
        <YButton
          v-if="provider"
          class="text-blue-600 hover:bg-blue-100 rounded"
          @click="$refs.serverActions.openCreateModal(provider)"
        >
          <span class="text-nowrap">Add Server</span>
        </YButton>
      </div>
    </div>
    <template v-if="showServers">
      <server
        v-for="(server, index) in servers"
        :key="server.id"
        :value="servers[index]"
        :provider="provider"
        :show-ips-by-default="servers.length == 1"
      ></server>
    </template>
  </div>
</template>

<script>
import Server from './Server'
import ServerActions from './ServerActions'
import gql from 'graphql-tag'

export default {
  components: {
    Server,
    ServerActions
  },
  props: {
    filters: {
      type: Object
    },
    ipFilters: {
      type: Object
    },
    provider: {
      type: Object
    },
    showServersByDefault: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    servers: {
      query: gql`
        query getServers(
          $filters: YdnsServerFilterInput
          $ipFilters: YdnsIpFilterInput
        ) {
          servers: ydnsServers(filters: $filters) {
            id
            type
            checks {
              id
              type
              status
            }
            hostname
            serverIp
            ips(filters: $ipFilters) {
              id
              checks {
                id
                type
                status
              }
              spamhausCheck {
                id
                enabled
                checkId
                lastRunAt
                lastRunOutput
              }
              records {
                id
                domain {
                  id
                }
                type
                content
                subdomain
                ttl
                priority
              }
              version
              enabled
            }
            ipSummary {
              ipv4Range {
                start {
                  id
                }
                end {
                  id
                }
              }
              ipv6Range {
                start {
                  id
                }
                end {
                  id
                }
              }
              count
              enabledCount
              enabledIps {
                id
                version
                enabled
              }
            }
            provider {
              id
              name
              type
            }
          }
        }
      `,
      variables() {
        return {
          filters: this.filters,
          ipFilters: this.ipFiltersGql
        }
      },
      skip() {
        return !this.showServers
      },
      deep: true,
      result(result) {
        this.servers = result.data.servers
      }
    }
  },
  data() {
    return {
      isLoading: false,
      showServers: this.showServersByDefault,
      servers: []
    }
  },
  watch: {
    showServersByDefault: function() {
      this.showServers = this.showServersByDefault
    },
    showServers: function() {
      this.$apollo.queries.servers.refresh()
    }
  },
  computed: {
    filtersGql() {
      return this.$store.getters.ydnsProviderServerFiltersGql
    },
    ipFiltersGql() {
      return this.$store.getters.ydnsProviderServerIpFiltersGql
    }
  },
  mounted() {
    this.$events.listen('refreshYdnsServers', () => {
      this.$apollo.queries.servers.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refreshYdnsServers')
  },
  updated() {},
  methods: {}
}
</script>

<style></style>
