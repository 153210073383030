var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', {
    staticClass: "mb-2"
  }, [_c('WidgetHeader', {
    staticClass: "p-1 bg-gray-200"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-6 gap-1 w-full"
  }, [_c('Superselect', {
    attrs: {
      "title": "Domain",
      "modifiers": _vm.filters.id.modifiers,
      "settings": _vm.filters.id.settings,
      "options": _vm.domainOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "id",
      "close-on-select": false,
      "query": _vm.DOMAIN_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          id: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": "Paste Domains"
    },
    model: {
      value: _vm.filters.id.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.id, "value", $$v);
      },
      expression: "filters.id.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Registrar",
      "modifiers": _vm.filters.registrar.modifiers,
      "settings": _vm.filters.registrar.settings,
      "options": _vm.registrarOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "close-on-select": false,
      "edit-placeholder": "Paste Registrars",
      "loading": _vm.$apollo.queries.registrarOptions.loading
    },
    model: {
      value: _vm.filters.registrar.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.registrar, "value", $$v);
      },
      expression: "filters.registrar.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Type",
      "modifiers": _vm.filters.type.modifiers,
      "options": _vm.typeOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "loading": _vm.$apollo.queries.typeOptions.loading
    },
    model: {
      value: _vm.filters.type.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.type, "value", $$v);
      },
      expression: "filters.type.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Status",
      "modifiers": _vm.filters.status.modifiers,
      "options": _vm.statusOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "loading": _vm.$apollo.queries.statusOptions.loading
    },
    model: {
      value: _vm.filters.status.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.status, "value", $$v);
      },
      expression: "filters.status.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Tags",
      "modifiers": _vm.filters.tags.modifiers,
      "options": _vm.tagOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "tag",
      "loading": _vm.$apollo.queries.tagOptions.loading
    },
    model: {
      value: _vm.filters.tags.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.tags, "value", $$v);
      },
      expression: "filters.tags.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Publisher Status",
      "modifiers": _vm.filters.publisherStatus.modifiers,
      "options": _vm.publisherStatusOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "loading": _vm.$apollo.queries.publisherStatusOptions.loading
    },
    model: {
      value: _vm.filters.publisherStatus.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.publisherStatus, "value", $$v);
      },
      expression: "filters.publisherStatus.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Publisher",
      "options": _vm.publisherOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "query": _vm.PUBLISHER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.publisher,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "publisher", $$v);
      },
      expression: "filters.publisher"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Record Type",
      "placeholder": "Select",
      "modifiers": _vm.filters.recordType.modifiers,
      "options": _vm.recordTypeOptions || [],
      "settings": _vm.filters.recordType.settings,
      "track-by": "id",
      "label": "label",
      "loading": _vm.$apollo.queries.recordTypeOptions.loading
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.filterDisplayMode == 'advanced',
            expression: "filterDisplayMode == 'advanced'"
          }],
          staticClass: "mt-1 flex"
        }, [_c('y-toggle', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Filter Domains`,
            expression: "`Filter Domains`"
          }],
          staticClass: "mr-1",
          scopedSlots: _vm._u([{
            key: "true",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-blue-600",
                attrs: {
                  "name": "earth",
                  "size": 4
                }
              })];
            },
            proxy: true
          }, {
            key: "false",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-gray-400",
                attrs: {
                  "name": "earth",
                  "size": 4
                }
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filters.recordType.settings.filtersDomains,
            callback: function ($$v) {
              _vm.$set(_vm.filters.recordType.settings, "filtersDomains", $$v);
            },
            expression: "filters.recordType.settings.filtersDomains"
          }
        }), _c('y-toggle', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Filter Records`,
            expression: "`Filter Records`"
          }],
          scopedSlots: _vm._u([{
            key: "true",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-blue-600",
                attrs: {
                  "name": "dns",
                  "size": 4
                }
              })];
            },
            proxy: true
          }, {
            key: "false",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-gray-400",
                attrs: {
                  "name": "dns",
                  "size": 4
                }
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filters.recordType.settings.filtersRecords,
            callback: function ($$v) {
              _vm.$set(_vm.filters.recordType.settings, "filtersRecords", $$v);
            },
            expression: "filters.recordType.settings.filtersRecords"
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.recordType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.recordType, "value", $$v);
      },
      expression: "filters.recordType.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Subdomain",
      "force-edit": true,
      "edit-placeholder": ``,
      "modifiers": _vm.filters.recordSubdomain.modifiers,
      "track-by": "id",
      "label": "label"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.filterDisplayMode == 'advanced',
            expression: "filterDisplayMode == 'advanced'"
          }],
          staticClass: "mt-1 flex"
        }, [_c('y-toggle', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Filter Domains`,
            expression: "`Filter Domains`"
          }],
          staticClass: "mr-1",
          scopedSlots: _vm._u([{
            key: "true",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-blue-600",
                attrs: {
                  "name": "earth",
                  "size": 4
                }
              })];
            },
            proxy: true
          }, {
            key: "false",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-gray-400",
                attrs: {
                  "name": "earth",
                  "size": 4
                }
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filters.recordSubdomain.settings.filtersDomains,
            callback: function ($$v) {
              _vm.$set(_vm.filters.recordSubdomain.settings, "filtersDomains", $$v);
            },
            expression: "filters.recordSubdomain.settings.filtersDomains"
          }
        }), _c('y-toggle', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Filter Records`,
            expression: "`Filter Records`"
          }],
          scopedSlots: _vm._u([{
            key: "true",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-blue-600",
                attrs: {
                  "name": "dns",
                  "size": 4
                }
              })];
            },
            proxy: true
          }, {
            key: "false",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-gray-400",
                attrs: {
                  "name": "dns",
                  "size": 4
                }
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filters.recordSubdomain.settings.filtersRecords,
            callback: function ($$v) {
              _vm.$set(_vm.filters.recordSubdomain.settings, "filtersRecords", $$v);
            },
            expression: "filters.recordSubdomain.settings.filtersRecords"
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.recordSubdomain.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.recordSubdomain, "value", $$v);
      },
      expression: "filters.recordSubdomain.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Provider",
      "modifiers": _vm.filters.recordProvider.modifiers,
      "options": _vm.providerOptions || [],
      "settings": _vm.filters.recordProvider.settings,
      "track-by": "id",
      "label": "label",
      "placeholder": "Select",
      "close-on-select": false,
      "edit-placeholder": "Paste Providers",
      "loading": _vm.$apollo.queries.providerOptions.loading
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.filterDisplayMode == 'advanced',
            expression: "filterDisplayMode == 'advanced'"
          }],
          staticClass: "mt-1 flex"
        }, [_c('y-toggle', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Filter Domains`,
            expression: "`Filter Domains`"
          }],
          staticClass: "mr-1",
          scopedSlots: _vm._u([{
            key: "true",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-blue-600",
                attrs: {
                  "name": "earth",
                  "size": 4
                }
              })];
            },
            proxy: true
          }, {
            key: "false",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-gray-400",
                attrs: {
                  "name": "earth",
                  "size": 4
                }
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filters.recordProvider.settings.filtersDomains,
            callback: function ($$v) {
              _vm.$set(_vm.filters.recordProvider.settings, "filtersDomains", $$v);
            },
            expression: "filters.recordProvider.settings.filtersDomains"
          }
        }), _c('y-toggle', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Filter Records`,
            expression: "`Filter Records`"
          }],
          scopedSlots: _vm._u([{
            key: "true",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-blue-600",
                attrs: {
                  "name": "dns",
                  "size": 4
                }
              })];
            },
            proxy: true
          }, {
            key: "false",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-gray-400",
                attrs: {
                  "name": "dns",
                  "size": 4
                }
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filters.recordProvider.settings.filtersRecords,
            callback: function ($$v) {
              _vm.$set(_vm.filters.recordProvider.settings, "filtersRecords", $$v);
            },
            expression: "filters.recordProvider.settings.filtersRecords"
          }
        })], 1), _c('Superselect', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.filters.recordProvider.value.length > 0,
            expression: "filters.recordProvider.value.length > 0"
          }],
          staticClass: "mt-1",
          attrs: {
            "title": "Box",
            "placeholder": "Select Server",
            "modifiers": _vm.filters.recordServer.modifiers,
            "options": _vm.serverOptions || [],
            "track-by": "id",
            "label": "label",
            "hide-label": true
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function () {
              return [_c('div', {
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: _vm.filterDisplayMode == 'advanced',
                  expression: "filterDisplayMode == 'advanced'"
                }],
                staticClass: "mt-1 flex"
              }, [_c('y-toggle', {
                directives: [{
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: `Filter Domains`,
                  expression: "`Filter Domains`"
                }],
                staticClass: "mr-1",
                scopedSlots: _vm._u([{
                  key: "true",
                  fn: function () {
                    return [_c('Icon', {
                      staticClass: "text-blue-600",
                      attrs: {
                        "name": "earth",
                        "size": 4
                      }
                    })];
                  },
                  proxy: true
                }, {
                  key: "false",
                  fn: function () {
                    return [_c('Icon', {
                      staticClass: "text-gray-400",
                      attrs: {
                        "name": "earth",
                        "size": 4
                      }
                    })];
                  },
                  proxy: true
                }]),
                model: {
                  value: _vm.filters.recordServer.settings.filtersDomains,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.recordServer.settings, "filtersDomains", $$v);
                  },
                  expression: "filters.recordServer.settings.filtersDomains"
                }
              }), _c('y-toggle', {
                directives: [{
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: `Filter Records`,
                  expression: "`Filter Records`"
                }],
                scopedSlots: _vm._u([{
                  key: "true",
                  fn: function () {
                    return [_c('Icon', {
                      staticClass: "text-blue-600",
                      attrs: {
                        "name": "dns",
                        "size": 4
                      }
                    })];
                  },
                  proxy: true
                }, {
                  key: "false",
                  fn: function () {
                    return [_c('Icon', {
                      staticClass: "text-gray-400",
                      attrs: {
                        "name": "dns",
                        "size": 4
                      }
                    })];
                  },
                  proxy: true
                }]),
                model: {
                  value: _vm.filters.recordServer.settings.filtersRecords,
                  callback: function ($$v) {
                    _vm.$set(_vm.filters.recordServer.settings, "filtersRecords", $$v);
                  },
                  expression: "filters.recordServer.settings.filtersRecords"
                }
              })], 1)];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filters.recordServer.value,
            callback: function ($$v) {
              _vm.$set(_vm.filters.recordServer, "value", $$v);
            },
            expression: "filters.recordServer.value"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.recordProvider.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.recordProvider, "value", $$v);
      },
      expression: "filters.recordProvider.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Record Content",
      "force-edit": true,
      "edit-placeholder": ``,
      "modifiers": _vm.filters.recordContent.modifiers,
      "settings": _vm.filters.recordContent.settings,
      "options": _vm.recordContentOptions || [],
      "track-by": "id",
      "label": "label"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.filterDisplayMode == 'advanced',
            expression: "filterDisplayMode == 'advanced'"
          }],
          staticClass: "mt-1 flex mb-1"
        }, [_c('y-toggle', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Filter Domains`,
            expression: "`Filter Domains`"
          }],
          staticClass: "mr-1",
          scopedSlots: _vm._u([{
            key: "true",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-blue-600",
                attrs: {
                  "name": "earth",
                  "size": 4
                }
              })];
            },
            proxy: true
          }, {
            key: "false",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-gray-400",
                attrs: {
                  "name": "earth",
                  "size": 4
                }
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filters.recordContent.settings.filtersDomains,
            callback: function ($$v) {
              _vm.$set(_vm.filters.recordContent.settings, "filtersDomains", $$v);
            },
            expression: "filters.recordContent.settings.filtersDomains"
          }
        }), _c('y-toggle', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Filter Records`,
            expression: "`Filter Records`"
          }],
          scopedSlots: _vm._u([{
            key: "true",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-blue-600",
                attrs: {
                  "name": "dns",
                  "size": 4
                }
              })];
            },
            proxy: true
          }, {
            key: "false",
            fn: function () {
              return [_c('Icon', {
                staticClass: "text-gray-400",
                attrs: {
                  "name": "dns",
                  "size": 4
                }
              })];
            },
            proxy: true
          }]),
          model: {
            value: _vm.filters.recordContent.settings.filtersRecords,
            callback: function ($$v) {
              _vm.$set(_vm.filters.recordContent.settings, "filtersRecords", $$v);
            },
            expression: "filters.recordContent.settings.filtersRecords"
          }
        })], 1), _c('y-radios', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.filterDisplayMode == 'advanced',
            expression: "filterDisplayMode == 'advanced'"
          }],
          staticClass: "flex",
          attrs: {
            "name": "content-filter-type",
            "radios": [{
              label: 'Exact',
              value: 'exact'
            }, {
              label: 'Contains',
              value: 'contains'
            }],
            "inline": true,
            "radios-inline": true,
            "radio-class": "mr-2",
            "color": "success"
          },
          model: {
            value: _vm.filters.recordContent.modifiers.matchType,
            callback: function ($$v) {
              _vm.$set(_vm.filters.recordContent.modifiers, "matchType", $$v);
            },
            expression: "filters.recordContent.modifiers.matchType"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.recordContent.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.recordContent, "value", $$v);
      },
      expression: "filters.recordContent.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Spamhaus Status",
      "options": _vm.spamhausCheckOptions || [],
      "placeholder": "Select",
      "track-by": "value",
      "label": "label"
    },
    model: {
      value: _vm.spamhausCheck,
      callback: function ($$v) {
        _vm.spamhausCheck = $$v;
      },
      expression: "spamhausCheck"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Nameserver Domain",
      "settings": _vm.filters.nameserverId.settings,
      "options": _vm.domainOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "id",
      "close-on-select": false,
      "query": _vm.DOMAIN_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          id: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          type: {
            value: ['nameserver']
          }
        }
      },
      "edit-placeholder": "Paste Domains"
    },
    model: {
      value: _vm.filters.nameserverId.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.nameserverId, "value", $$v);
      },
      expression: "filters.nameserverId.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Nameserver Provider",
      "modifiers": _vm.filters.nameServerProvider.modifiers,
      "settings": _vm.filters.nameServerProvider.settings,
      "options": _vm.providerOptions || [],
      "loading": _vm.$apollo.queries.providerOptions.loading,
      "placeholder": "Select",
      "track-by": "name",
      "label": "label"
    },
    model: {
      value: _vm.filters.nameServerProvider.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.nameServerProvider, "value", $$v);
      },
      expression: "filters.nameServerProvider.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Nameserver Ip",
      "modifiers": _vm.filters.nameServerIp.modifiers,
      "settings": _vm.filters.nameServerIp.settings,
      "placeholder": "Select",
      "forceEdit": true,
      "editPlaceholder": "Type IPs",
      "track-by": "id",
      "label": "id"
    },
    model: {
      value: _vm.filters.nameServerIp.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.nameServerIp, "value", $$v);
      },
      expression: "filters.nameServerIp.value"
    }
  }), _vm.filterDisplayMode == 'advanced' ? _c('div', {
    staticClass: "col-12 col-md"
  }, [_c('Superselect', {
    attrs: {
      "title": "Trash Status",
      "multiple": false,
      "modifiers": {},
      "settings": {},
      "options": _vm.trashedOptions || [],
      "track-by": "id",
      "label": "label"
    },
    model: {
      value: _vm.trashedFilter,
      callback: function ($$v) {
        _vm.trashedFilter = $$v;
      },
      expression: "trashedFilter"
    }
  })], 1) : _vm._e()], 1)])], 2), _c('WidgetHeader', {
    staticStyle: {
      "border-top": "1px solid #D9D9D9"
    }
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "flex items-center justify-between w-full"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('y-radios', {
    staticClass: "flex",
    attrs: {
      "radios": [{
        label: 'Simple',
        value: 'simple'
      }, {
        label: 'Advanced',
        value: 'advanced'
      }],
      "radio-class": "mr-3",
      "color": "info"
    },
    model: {
      value: _vm.filterDisplayMode,
      callback: function ($$v) {
        _vm.filterDisplayMode = $$v;
      },
      expression: "filterDisplayMode"
    }
  })], 1), _c('div', [_c('YButton', {
    staticClass: "bg-white hover:bg-gray-100 text-blue-600",
    on: {
      "click": function ($event) {
        return _vm.resetFilters();
      }
    }
  }, [_vm._v("Reset Filters")])], 1)])])], 2)], 1), _vm.domains ? _c('DomainsTable', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$apollo.queries.domains.loading,
      expression: "!$apollo.queries.domains.loading"
    }],
    attrs: {
      "data": _vm.domains,
      "publisherPermissions": _vm.abilitySummaries
    }
  }) : _vm._e(), _vm.$apollo.queries.domains.loading ? _c('div', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('Spinner', {
    attrs: {
      "active": true,
      "size": 10,
      "color": "green"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "flex justify-between items-center mt-2"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "inline-block"
  }, [_c('Superselect', {
    staticStyle: {
      "min-width": "4.6rem",
      "border": "none"
    },
    attrs: {
      "selectClass": "border-0",
      "title": "Per Page",
      "multiple": false,
      "options": _vm.perPageOptions || []
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1), _c('div', {
    staticClass: "inline-block ml-3 text-gray-600 italic"
  }, [_vm._v(" " + _vm._s(_vm.domainsPaginator.firstItem) + " - " + _vm._s(_vm.domainsPaginator.lastItem) + " of " + _vm._s(_vm.domainsPaginator.total) + " ")])]), _c('div', [_c('y-pagination', {
    attrs: {
      "data": _vm.domainsPaginator
    },
    on: {
      "change-page": _vm.changePage
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }