<template>
  <widget>
    <widget-header class="tiny-forms bg-primary blue pt-4 pb-4 text-white">
      <template slot="rawContent">
        <div
          class="d-flex justify-content-between align-items-center w-100 ts-4"
        >
          <div class="">
            <i class="zmdi zmdi-file-plus pr-2"></i>
            Edit Provider
          </div>
          <close-x @click="$emit('close')" class="text-white"></close-x>
        </div>
      </template>
    </widget-header>
    <widget-body class="pt-5">
      <y-form-group label="Name" :error-strings="validationErrors.name">
        <input type="text" v-model="provider.name" class="form-control" />
      </y-form-group>
      <y-form-group label="Type">
        <multiselect
          v-model="provider.type"
          :options="typeOptions"
          label="label"
          track-by="id"
          :multiple="false"
        ></multiselect>
      </y-form-group>
    </widget-body>
    <widget-footer class="pt-4 pb-4">
      <y-checkbox
        v-model="closeOnComplete"
        label="Close on Complete"
      ></y-checkbox>
      <y-button
        :is-loading="isSubmitting"
        type="button"
        class="btn btn-primary btn-sm ml-auto"
        @click="update"
        @keydown.enter="update"
        >Update</y-button
      >
    </widget-footer>
  </widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrors from '@/assets/js/ValidationErrors'

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {},
  apollo: {
    typeOptions: {
      query: gql`
        query {
          typeOptions: __type(name: "YdnsProviderTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        this.typeOptions = data.typeOptions.enumValues.map(enumValue => ({
          id: enumValue.name,
          label: enumValue.name.capitalize()
        }))
        this.provider.type = this.provider.type
          ? { id: this.provider.type, label: this.provider.type.capitalize() }
          : this.typeOptions[0]
      }
    }
  },
  data() {
    return {
      closeOnComplete: true,
      isSubmitting: false,
      typeOptions: [],
      provider: JSON.parse(JSON.stringify(this.value)),
      validationErrors: {}
    }
  },
  watch: {},
  computed: {},
  methods: {
    update() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation YdnsUpdateProvider($input: YdnsUpdateProviderInput!) {
              ydnsUpdateProvider(input: $input) {
                id
                name
                type
              }
            }
          `,
          variables: {
            input: {
              id: this.provider.id,
              name: this.provider.name,
              type: this.provider.type.id
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.success('ProviderUpdated', 'Success!')
          this.$events.emit('refreshYdnsProviders')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false

          // this.validationErrors = error.extentions.validation ? error.extentions.validation : null;
          let errors = new ValidationErrors()
          errors.extractErrorsFrom(error)
          this.validationErrors = errors.errors

          // if (Object.entries(this.errors).length <= 0) {
          //   toastr.error(error, "Oops!");
          // }
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
