<template>
  <div
    :class="[
      'y-table',
      scrollX ? '-mx-4 sm:-mx-8 px-4 sm:px-8 pb-0 overflow-hidden' : ''
    ]"
  >
    <div
      class="block min-w-full shadow rounded-md overflow-x-auto"
      :class="[loading ? 'x-loading' : '']"
      :style="blockStyle"
    >
      <table class="min-w-full leading-normal">
        <colgroup>
          <col v-if="selectable" style="width: 25px;" />
          <slot name="colgroup" :data="data"></slot>
        </colgroup>
        <thead :class="headerClass">
          <slot name="header" :data="data" :selected="selected">
            <tr>
              <th v-if="selectable">
                <input
                  v-if="!selectAllDisabled"
                  ref="select-checkbox-global"
                  type="checkbox"
                  @click="toggleSelectAll"
                  :indeterminate.prop="
                    selected.length != 0 && selected.length != data.length
                  "
                />
              </th>
              <slot name="header-row" :data="data"></slot>
            </tr>
          </slot>

          <slot name="secondHeader">
            <tr v-if="$slots.secondHeaderRow">
              <th v-if="selectable"></th>
              <slot name="secondHeaderRow"></slot>
            </tr>
          </slot>
        </thead>
        <tbody :class="bodyClass">
          <slot name="beforeBody">
            <tr v-if="$slots.beforeBodyRow">
              <td v-if="selectable"></td>
              <slot name="beforeBodyRow"></slot>
            </tr>
          </slot>

          <slot name="body" :data="data" :selected="selected">
            <tr
              v-for="(rowData, index) in data"
              :class="[
                selected.includes(rowData[selectBy]) ? 'row-selected' : '',
                rowData[deletedAtField] ? deletedAtClass : '',
                ...rowClasses(rowData)
              ]"
              :key="rowData.id || rowData.uniqueKey"
            >
              <td v-if="selectable">
                <input
                  v-if="selectableRequirement(rowData)"
                  type="checkbox"
                  class="select-checkbox"
                  :id="`select-checkbox-${index}`"
                  :value="rowData[selectBy]"
                  v-model="selectedLocal"
                />
              </td>
              <slot name="row" :row-data="rowData" :row-index="index"></slot>
            </tr>
          </slot>
        </tbody>
        <tfoot
          v-if="data.length == 0 && !isLoading"
          class="text-muted mt-2 text-center"
        >
          <tr>
            <td colspan="100%" class="font-italic py-4">{{ emptyText }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <slot name="after">
      <div
        class="w-100 py-8"
        v-if="bottomLoading"
        :class="[bottomLoading ? 'visibile' : 'invisible']"
      >
        <Icon
          name="loading"
          class="w-10 h-10 m-auto text-green-500 spin-animation block"
        />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    deletedAtField: {
      type: String,
      default: 'deletedAt'
    },
    deletedAtClass: {
      type: String,
      default: 'deletedAtRow text-danger'
    },
    data: {
      type: Array,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selectAllDisabled: {
      type: Boolean,
      default: false
    },
    bottomLoading: {
      type: Boolean,
      default: false
    },
    showBottomMore: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectBy: {
      type: String,
      default: 'id'
    },
    headerClass: {
      type: [String, Array],
      default: () => []
    },
    bodyClass: {
      type: [String, Array],
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    selectableRequirement: {
      type: Function,
      default: () => true
    },
    rowClasses: {
      type: Function,
      default: () => []
    },
    emptyText: {
      type: String,
      default: 'No items found'
    },
    scrollX: {
      type: Boolean,
      default: true
    },
    blockStyle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    isLoading() {
      return this.bottomLoading || this.loading
    },
    selectedLocal: {
      // getter
      get: function() {
        return this.selected
      },
      // setter
      set: function(newArray) {
        let oldArray = this.selected
        let newValues = newArray.filter(value => {
          return !oldArray.includes(value)
        })
        let removedValues = oldArray.filter(value => {
          return !newArray.includes(value)
        })
        this.$emit('select', newValues)
        this.$emit('deselect', removedValues)
        this.$emit('update:selected', newArray)
      }
    }
  },
  mounted() {},
  updated() {},
  methods: {
    toggleSelectAll(event) {
      let isChecked = event.target.checked
      if (isChecked) {
        this.selectedLocal = this.data.map(item => {
          return item[this.selectBy]
        })
      } else {
        this.selectedLocal = []
      }
    }
  }
}
</script>
<style scoped>
.deletedAtRow {
  opacity: 0.5;
}

.x-loading tr td {
  @apply opacity-25;
  -webkit-filter: blur(1px);
  filter: blur(1px);
}
.row-selected {
  @apply bg-gray-100 border-gray-200;
}
</style>
