<template>
  <div v-observe-visibility="visibilityChanged" class="grid grid-cols-12 gap-4">
    <div class="mb-2 col-span-6">
      <YInput
        class="mb-1"
        label="Name"
        v-model="localUser.primaryContact.name"
      />
      <YInput
        class="mb-1"
        label="Company"
        v-model="localUser.primaryContact.company"
      />
      <YInput
        class="mb-1"
        label="Email"
        v-model="localUser.primaryContact.email"
      />
      <YInput class="mb-1" label="Messenger Handle" v-model="messengerHandle" />
      <Superselect
        title="Messenger App"
        v-model="messengerApp"
        :multiple="false"
        :options="['telegram', 'skype']"
        class="mb-1"
      ></Superselect>
      <YInput
        class="mb-1"
        label="Phone"
        v-model="localUser.primaryContact.phone"
      />
      <YInput
        class="mb-1"
        label="Address"
        v-model="localUser.primaryContact.address"
      />
      <YInput
        class="mb-1"
        label="Address 2"
        v-model="localUser.primaryContact.address2"
      />
      <YInput
        class="mb-1"
        label="City"
        v-model="localUser.primaryContact.city"
      />
      <YInput
        class="mb-1"
        label="Region"
        v-model="localUser.primaryContact.region"
      />
      <Superselect
        title="Country"
        v-model="localUser.primaryContact.country"
        :multiple="false"
        track-by="code"
        label="label"
        :options="countries"
        class="mb-1"
      ></Superselect>
      <YInput
        class="mb-1"
        label="Postal Code"
        v-model="localUser.primaryContact.postalCode"
      />
    </div>
    <div class="mb-2 col-span-6">
      <div
        class="p-2 border rounded border-gray-400 mb-1"
        v-if="professionalReferences"
      >
        <p class="text-indigo-600 mb-1">Professional References</p>
        <div
          v-for="(reference, key) in professionalReferences"
          :key="key"
          class="mb-3"
        >
          <p>
            <span class="text-gray-700">Company:</span> {{ reference.company }}
          </p>
          <p>
            <span class="text-gray-700">Contact:</span> {{ reference.contact }}
          </p>
          <p>
            <span class="text-gray-700">Email:</span>
            {{ reference.email }}
          </p>
        </div>
      </div>

      <YInput
        class="mb-1"
        label="Networks"
        :disabled="true"
        :value="
          registrationQuestions ? registrationQuestions.question1.answer : ''
        "
      />
    </div>
    <ValidationErrors
      v-if="validationErrors && isVisible"
      :errors="validationErrors"
    ></ValidationErrors>
    <portal to="top-bar-right" v-if="hasChanges && isVisible">
      <YButton @click="update()" color="green">Update Primary Contact</YButton>
    </portal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  apollo: {
    countries: {
      query: gql`
        {
          countries {
            code
            name
            label
          }
        }
      `
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  mounted() {},
  data() {
    return {
      localUser: JSON.parse(JSON.stringify(this.user)),
      isVisible: true,
      isUpdating: false,
      countries: [],
      registrationQuestions: JSON.parse(
        this.user.userDetails.registrationQuestions
      ),
      professionalReferences: JSON.parse(this.user.userDetails.references)
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
    },
    update() {
      this.validationErrors = null
      this.isUpdating = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          // Query
          mutation: UPDATE_USER_MUTATION,
          // Parameters
          variables: {
            input: {
              id: this.user.id,
              contacts: {
                update: [
                  {
                    id: this.user.primaryContact.id,
                    name: this.localUser.primaryContact.name ?? undefined,
                    email: this.localUser.primaryContact.email ?? undefined,
                    address: this.localUser.primaryContact.address ?? undefined,
                    address2:
                      this.localUser.primaryContact.address2 ?? undefined,
                    phone: this.localUser.primaryContact.phone ?? undefined,
                    city: this.localUser.primaryContact.city ?? undefined,
                    region: this.localUser.primaryContact.region ?? undefined,
                    countryCode: this.localUser.primaryContact.country
                      ? this.localUser.primaryContact.country.code
                      : undefined,
                    postalCode:
                      this.localUser.primaryContact.postalCode ?? undefined,
                    messenger:
                      this.localUser.primaryContact.messenger ?? undefined,
                    company: this.localUser.primaryContact.company ?? undefined
                  }
                ]
              }
            }
          }
        })
        .then(() => {
          // Result
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$toastr.s('Success')
        })
        .catch(error => {
          // Error

          // console.error(error);
          this.isUpdating = false
          this.$store.dispatch('updateGlobalLoader', -1)
          this.setValidationErrors(error)

          // this.$toastr.Add({
          //   name: "UniqueToastName",
          //   title: "Easy Toast",
          //   msg: "Hi",
          //   timeout: 0,
          //   position: "toast-bottom-full-width",
          //   type: "error",
          //   preventDuplicates: true
          // });
        })
    }
  },
  computed: {
    hasChanges() {
      return (
        JSON.stringify(this.localUser.primaryContact) !=
        JSON.stringify(this.user.primaryContact)
      )
    },
    messengerApp: {
      // getter
      get: function() {
        return this.localUser.primaryContact.messenger
          ? JSON.parse(this.localUser.primaryContact.messenger).app
          : null
      },
      // setter
      set: function(newValue) {
        this.localUser.primaryContact.messenger = JSON.stringify({
          handle: this.messengerHandle,
          app: newValue
        })
      }
    },
    messengerHandle: {
      // getter
      get: function() {
        return this.localUser.primaryContact.messenger
          ? JSON.parse(this.localUser.primaryContact.messenger).handle
          : null
      },
      // setter
      set: function(newValue) {
        this.localUser.primaryContact.messenger = JSON.stringify({
          handle: newValue,
          app: this.messengerApp
        })
      }
    }
  }
}
</script>

<style></style>
