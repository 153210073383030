<template>
  <widget>
    <widget-header @close="$emit('close')">
      Replace Subdomain
    </widget-header>
    <widget-body>
        <div class="mb-1">
            <div class="text-xs text-indigo-600">Current Subdomain</div>
            <div>{{ redirect.subdomain }}</div>
        </div>
        <div class="mb-1">
            <div class="text-xs text-indigo-600">New Subdomain</div>
            <div>{{ subdomainGenerator.subdomain }}</div>
        </div>
    </widget-body>
    <widget-footer class="bg-gray-100 py-2 px-4 text-right">
      <y-button
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="saveSubdomain"
        @keydown.enter="saveSubdomain"
        >Replace Subdomain</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import SUBDOMAIN_GENERATOR_QUERY from '@/graphql/Redirect/SubdomainGeneratorQuery.gql'
import UPDATE_SUBDOMAIN_MUTATION from '@/graphql/Redirect/UpdateSubdomainMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    redirect: {
      type: Object,
      required: true
    },
  },
  components: {},
  apollo: {
      subdomainGenerator: {
      query: SUBDOMAIN_GENERATOR_QUERY,
      variables() {
        return {
          id: this.redirect.id
        }
      },
      fetchPolicy: 'network-only'
    },
  },
  data() {
    return {
        isSubmitting: false,
        colSize: 'col-xl-3 col-lg-6',
        colSize2: 'col-xl-6 col-lg-12',
    }
  },
  watch: {},
  computed: {},
  methods: {
    saveSubdomain() {
      this.isSubmitting = true
      let input = {
        id: this.redirect.id,
        subdomain: this.subdomainGenerator.subdomain,
      }

      this.$apollo
        .mutate({
          mutation: UPDATE_SUBDOMAIN_MUTATION,
          variables: {
            input: input
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('', 'Subdomain updated')
          this.isSubmitting = false
          this.$events.emit('refreshRedirect', true)
          this.$events.emit('refreshSubdomainHistory', true)
          this.$emit('close')
        })
        .catch(() => {
          this.isSubmitting = false
        })
    }
  },

  mounted() {},
  updated() {}
}
</script>
<style scoped>
.x-form > *:not(:last-child) {
  @apply mb-2;
}
</style>
