<template>
  <div>
    <Widget>
      <WidgetBody class="bg-gray-100">
        <div class="flex justify-between">
          <div></div>
          <y-button
            class="btn btn-blue"
            @click="openCreatePostback(user_id, redirect_id)"
            >New Postback
          </y-button>
        </div>
      </WidgetBody>
      <YTable
        v-if="postbacks"
        :data="postbacks.data || []"
        :bottom-loading="$apolloData.queries.postbacks.loading"
      >
        <template slot="header-row">
          <th>ID</th>
          <th>Conversion Type</th>
          <th v-if="!redirect_id">Redirect</th>
          <th>Protocol</th>
          <th>Url</th>
          <th></th>
        </template>
        <template slot="row" slot-scope="props">
          <td>{{ props.rowData.id }}</td>
          <td>
            <Tag v-if="props.rowData.conversionType" color="blue">{{
              props.rowData.conversionType?.name
            }}</Tag>
            <Tag v-else color="orange">Default</Tag>
          </td>
          <td v-if="!redirect_id">{{ props.rowData.redirectId }}</td>
          <td>
            <editable-postback-protocol
              :id="props.rowData.id"
              :protocol="props.rowData.protocol"
              >{{ props.rowData.protocol }}</editable-postback-protocol
            >
          </td>
          <td>
            <editable-postback-url
              :id="props.rowData.id"
              :url="props.rowData.url"
              >{{ props.rowData.url }}</editable-postback-url
            >
          </td>
          <td class="text-right">
            <a
              href="#"
              class="text-red-500 text-red-600"
              @click.prevent="
                openDeleteModal(
                  props.rowData.id,
                  props.rowData.protocol + '://' + props.rowData.url
                )
              "
            >
              <Icon name="trashCan" class="inline-block" />
            </a>
          </td>
        </template>
      </YTable>
    </Widget>
  </div>
</template>

<script>
import PostbackCreate from '@/views/Admin/Postbacks/PostbackCreate.vue'
import PostbackDelete from '@/views/Admin/Postbacks/PostbackDelete.vue'
import EditablePostbackProtocol from '@/views/Admin/Postbacks/EditablePostbackProtocol.vue'
import EditablePostbackUrl from '@/views/Admin/Postbacks/EditablePostbackUrl.vue'

import POSTBACKS_QUERY from '@/graphql/Postback/PostbacksQuery.gql'

export default {
  components: {
    EditablePostbackProtocol,
    EditablePostbackUrl
  },
  apollo: {
    postbacks: {
      query: POSTBACKS_QUERY,
      variables() {
        return {
          filters: this.createFilter(),
          first: 1000000
        }
      }
    }
  },
  props: {
    userId: {
      type: Number,
      required: false
    },
    redirectId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      user_id: this.userId,
      redirect_id: this.redirectId
    }
  },
  methods: {
    openDeleteModal(id, url) {
      this.$modal.show(
        PostbackDelete,
        { id: parseInt(id), url: url },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openCreatePostback(user_id, redirect_id) {
      this.$modal.show(
        PostbackCreate,
        {
          userId: user_id,
          redirectId: redirect_id
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true,
          classes: ['overflow-visible-important']
        }
      )
    },
    createFilter() {
      if (
        typeof this.redirectId !== 'undefined' &&
        typeof this.userId !== 'undefined'
      ) {
        return {
          redirectId: { value: this.redirectId },
          userId: { value: this.userId }
        }
      }
      if (this.userId != null) {
        return { userId: { value: this.userId } }
      }
      if (this.redirectId != null) {
        return {
          redirectId: { value: this.redirectId }
        }
      }
      return null
    }
  },
  computed: {},
  mounted() {
    this.$events.listen('refreshPostbacks', () => {
      this.$apollo.queries.postbacks.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refreshPostbacks')
  }
}
</script>
