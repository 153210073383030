var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Send Email? ")]), _c('WidgetBody', {
    staticClass: "pt-2"
  }, [_vm.redirect ? [_c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Would you like to send a status update email for: ")]), _c('ul', [_c('li', [_c('span', {
    staticClass: "label-column"
  }, [_vm._v("User:")]), _vm._v(" " + _vm._s(_vm.redirect.user.label) + " ")]), _c('li', [_c('span', {
    staticClass: "label-column"
  }, [_vm._v("Redirect:")]), _vm._v(" " + _vm._s(`${_vm.redirect.subdomain}.${_vm.redirect.domain}`) + " (" + _vm._s(_vm.redirectId) + ") ")]), _c('li', [_c('span', {
    staticClass: "label-column"
  }, [_vm._v("Status:")]), _c('span', {
    staticClass: "font-bold",
    class: [_vm.TextColorMap[_vm.redirect.status] ? `text-${_vm.TextColorMap[_vm.redirect.status]}-500` : '']
  }, [_vm._v(_vm._s(_vm.redirect.status.toUpperCase()))])])])] : _c('Spinner', {
    staticClass: "my-4",
    attrs: {
      "color": "green",
      "size": 12
    }
  })], 2), _c('WidgetFooter', {
    staticClass: "bg-gray-100 px-4 py-2"
  }, [_c('div', {
    staticClass: "flex justify-between"
  }, [_c('YButton', {
    staticClass: "btn-link",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Do Not Send")]), _c('YButton', {
    staticClass: "btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": function ($event) {
        return _vm.sendStatusUpdateEmail(_vm.redirectId);
      }
    }
  }, [_vm._v(" Send Email ")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }