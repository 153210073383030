var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }), _c('Widget', {
    staticClass: "reports-form"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-200"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "block lg:flex items-center lg:justify-between w-full"
  }, [_c('YDateTimeRangePicker', {
    model: {
      value: _vm.filters.dateTimeRange,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "dateTimeRange", $$v);
      },
      expression: "filters.dateTimeRange"
    }
  })], 1)])], 2), _c('transition', {
    attrs: {
      "css": false
    },
    on: {
      "before-enter": _vm.animateShowFiltersBeforeEnter,
      "enter": _vm.animateShowFiltersEnter,
      "leave": _vm.animateShowFiltersLeave
    }
  }, [_c('WidgetBody', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilters,
      expression: "showFilters"
    }],
    staticClass: "pt-4 bg-gray-100",
    staticStyle: {
      "border-top": "1px solid #D9D9D9"
    }
  }, [_c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-6 gap-1"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.country,
      expression: "columnData.country"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Country",
      "editPlaceholder": "Paste Country Codes",
      "modifiers": _vm.filters.country.modifiers,
      "options": _vm.options.country,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.COUNTRIES_QUERY,
      "query-result-map": {
        id: 'code',
        label: 'label'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.country, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.country.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.country, "value", $$v);
      },
      expression: "filters.country.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.campaign,
      expression: "columnData.campaign"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Campaign",
      "modifiers": _vm.filters.campaign.modifiers,
      "track-by": "id",
      "label": "label",
      "multiple": true,
      "options": _vm.campaigns || []
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.campaign, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.campaign.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.campaign, "value", $$v);
      },
      expression: "filters.campaign.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.usageType,
      expression: "columnData.usageType"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Usage Type",
      "modifiers": _vm.filters.usageType.modifiers,
      "editPlaceholder": "",
      "forceEdit": true,
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.usageType, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.usageType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.usageType, "value", $$v);
      },
      expression: "filters.usageType.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.keyword,
      expression: "columnData.keyword"
    }]
  }, [_vm.filters.keyword ? _c('Superselect', {
    attrs: {
      "title": "Keyword",
      "modifiers": _vm.filters.keyword.modifiers,
      "forceEdit": true,
      "editPlaceholder": "",
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.keyword, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.keyword.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.keyword, "value", $$v);
      },
      expression: "filters.keyword.value"
    }
  }) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.subId,
      expression: "columnData.subId"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Sub ID",
      "modifiers": _vm.filters.subId.modifiers,
      "forceEdit": true,
      "editPlaceholder": "",
      "track-by": "id",
      "label": "label"
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.subId, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.subId.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.subId, "value", $$v);
      },
      expression: "filters.subId.value"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.columnData.payoutConversionType,
      expression: "columnData.payoutConversionType"
    }]
  }, [_c('Superselect', {
    attrs: {
      "title": "Conversion Type",
      "placeholder": "Select One",
      "multiple": true,
      "modifiers": _vm.filters.payoutConversionType.modifiers,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.CONVERSION_TYPE_OPTIONS_QUERY,
      "queryDefaultInputText": "",
      "queryVariables": {
        filters: {
          name: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          slug: {
            value: ['first-time-deposit', 'qualified-first-time-deposit', 'registration']
          }
        },
        first: 25
      },
      "queryResultMap": {
        id: 'name',
        label: 'name'
      }
    },
    on: {
      "update:modifiers": function ($event) {
        return _vm.$set(_vm.filters.payoutConversionType, "modifiers", $event);
      }
    },
    model: {
      value: _vm.filters.payoutConversionType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.payoutConversionType, "value", $$v);
      },
      expression: "filters.payoutConversionType.value"
    }
  })], 1)]), _c('div', {
    staticClass: "grid grid-cols-1 md:grid-cols-3 pt-5 gap-1"
  }, [_c('div', [_c('Superselect', {
    attrs: {
      "title": "Show",
      "options": _vm.showColumnsOptions,
      "multiple": true,
      "placeholder": "Select Columns",
      "track-by": "id",
      "label": "label",
      "selectedLabel": "",
      "selectLabel": "",
      "deselectLabel": "",
      "group-values": "values",
      "group-label": "label",
      "close-on-select": false
    },
    on: {
      "select": _vm.columnSelected,
      "remove": _vm.columnRemoved
    },
    model: {
      value: _vm.showColumnsFilter,
      callback: function ($$v) {
        _vm.showColumnsFilter = $$v;
      },
      expression: "showColumnsFilter"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("No Columns Found")])])], 1)])])], 1), _c('WidgetHeader', {
    staticClass: "bg-opacity-faded flex-wrap",
    staticStyle: {
      "border-top": "1px solid #D9D9D9"
    }
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "mb-1 sm:mb-0"
  }, [_c('YButton', {
    staticClass: "hover:bg-gray-100 focus:bg-blue-100 text-blue-600 hover:text-blue-700 flex items-center outline-none-important",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.showFilters = !_vm.showFilters;
      }
    }
  }, [_c('Icon', {
    staticClass: "inline mr-1",
    attrs: {
      "name": _vm.showFilters ? 'minus' : 'plus',
      "size": 4
    }
  }), _vm._v(" " + _vm._s(_vm.showFilters ? 'Hide' : 'Show Filters') + " ")], 1)], 1), _c('div', {
    staticClass: "flex items-center flex-row-reverse"
  }, [_c('YButton', {
    staticClass: "hover:bg-gray-100 focus:bg-blue-100 text-blue-600 hover:text-blue-700 flex items-center outline-none-important mt-2 px-3 md:px-6",
    attrs: {
      "disabled": _vm.formButtonsDisabled
    },
    on: {
      "click": function ($event) {
        return _vm.resetFilters();
      }
    }
  }, [_vm._v("Reset Filters")]), _c('div', {
    staticClass: "flex mt-2 md:mt-0"
  }, [_c('YButton', {
    staticClass: "bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 text-white mr-3 outline-none-important",
    attrs: {
      "disabled": _vm.formButtonsDisabled,
      "isLoading": _vm.$apollo.queries.report.loading
    },
    on: {
      "click": function ($event) {
        return _vm.doFilter();
      }
    }
  }, [_vm._v("Submit")]), _c('YButton', {
    staticClass: "border border-blue-600 hover:bg-blue-600 focus:bg-blue-600 text-blue-600 hover:text-white focus:text-white mr-3 outline-none-important",
    attrs: {
      "type": "button",
      "disabled": _vm.formButtonsDisabled,
      "isLoading": _vm.isExporting
    },
    on: {
      "click": function ($event) {
        _vm.doFilter({
          type: 'export'
        }), _vm.isExporting = true;
      }
    }
  }, [_vm._v("Export")])], 1)], 1)])], 2)], 1), _vm.responseMessage ? _c('y-alert', {
    attrs: {
      "type": _vm.responseMessage.type
    }
  }, [_vm._v(_vm._s(_vm.responseMessage.content))]) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }