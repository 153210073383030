<template>
  <div>
    <div
      :class="
        `w-${width} h-${height} flex items-center overflow-hidden relative`
      "
    >
      <Spinner v-if="showLoader" :size="8" color="blue" class="m-auto" />
      <label
        :class="
          `text-gray-300 hover:text-blue-400 ${
            isEditable ? 'x-hoverable' : ''
          } w-${width} h-${height} flex items-center`
        "
        v-else
      >
        <div v-if="imageUrl || localImageUrl" class="x-upload-image ">
          <div class="x-overlay">
            <svg
              class="x-overlay-icon"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
              />
            </svg>
          </div>
          <img
            v-if="isEditable"
            :src="imageUrl || localImageUrl"
            :class="`w-${width} h-${height} block object-contain`"
          />
          <img
            v-else
            v-img="{ src: imageUrl }"
            :src="thumbnailUrl || imageUrl || localImageUrl"
            :class="`w-${width} h-${height} block object-contain`"
          />
        </div>
        <svg
          v-else
          :class="
            `feather feather-image w-${blankWidth || width} h-${blankHeight ||
              height} block m-auto`
          "
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
          <circle cx="8.5" cy="8.5" r="1.5" />
          <polyline points="21 15 16 10 5 21" />
        </svg>
        <input
          type="file"
          class="hidden"
          @change="onImageChange"
          ref="imageInput"
          :disabled="!isEditable"
        />
      </label>
    </div>
    <div v-if="fileSizeExceedsLimit" class="text-red-500">
      File size exceeds {{ fileSizeLimit }}MB. Please choose a smaller file.
    </div>
    <div v-if="fileTypeNotAllowed" class="text-red-500">
      File type is not supported. Please use PNG to WEBP format
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    blankWidth: {
      type: Number
    },
    blankHeight: {
      type: Number
    },
    editableOnlyWhenEmpty: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    imageUrl: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    thumbnailUrl: {
      type: String
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  data() {
    return {
      file: null,
      localImageUrl: null,
      fileSizeExceedsLimit: false,
      fileTypeNotAllowed: false,
      fileSizeLimit: 3
    }
  },
  computed: {
    isEditable() {
      return (
        this.editable &&
        (!this.imageUrl || (!this.editableOnlyWhenEmpty && this.imageUrl))
      )
    }
  },
  methods: {
    onImageChange(event) {
      this.fileSizeExceedsLimit = false
      this.fileTypeNotAllowed = false

      let files = event.target.files || event.dataTransfer.files
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.localImageUrl = reader.result
          this.$emit('update:imageUrl', reader.result)
        },
        false
      )

      if (files[0]) {
        //check file size
        const fileSizeInBytes = files[0].size
        const maxSizeInBytes = this.fileSizeLimit * 1024 * 1024

        if (fileSizeInBytes > maxSizeInBytes) {
          this.fileSizeExceedsLimit = true
          return
        }

        //check file type
        if (files[0].type !== 'image/webp' && files[0].type !== 'image/png') {
          this.fileTypeNotAllowed = true
          return
        }

        this.file = files[0]
        reader.readAsDataURL(files[0])
        this.$emit('update:file', files[0])
      }
    }
  }
}
</script>

<style scoped>
.x-upload-image {
  @apply relative object-contain w-full;
}
.x-overlay {
  @apply absolute h-full w-full hidden items-center text-center;
  background: rgba(0, 0, 0, 0.75);
}
.x-overlay > .x-overlay-icon {
  width: 35%;
  margin: auto;
}
.x-hoverable {
  @apply cursor-pointer;
}
.x-hoverable .x-upload-image:hover .x-overlay,
.x-hoverable .x-upload-image:focus .x-overlay,
.x-hoverable .x-upload-image:active .x-overlay {
  @apply flex;
}
</style>
