var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('portal', {
    attrs: {
      "to": _vm.portalTo
    }
  }, [_c('YAlert', {
    key: _vm.uniqueKey,
    staticClass: "my-1",
    class: _vm.alertClass,
    staticStyle: {
      "z-index": "90000",
      "position": "relative"
    },
    attrs: {
      "color": _vm.color,
      "icon": _vm.icon,
      "iconSize": _vm.iconSize,
      "allow-close": _vm.allowClose
    }
  }, [_vm.validationErrors ? _c('ul', [_vm._l(_vm.validationErrors, function (fieldErrors) {
    return _vm._l(fieldErrors, function (error, index) {
      return _c('li', {
        key: index
      }, [_vm._v(" " + _vm._s(error) + " ")]);
    });
  })], 2) : _vm.extentionsErrors ? _c('div', [_vm._v(" " + _vm._s(_vm.extentionsErrors) + " ")]) : _vm.errors ? _c('div', [_vm._v(" " + _vm._s(_vm.message) + " ")]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }