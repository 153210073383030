var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "top"
    }
  }, [_c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('div', {
    staticClass: "flex justify-right"
  }, [_c('YButton', {
    staticClass: "btn btn-blue",
    on: {
      "click": _vm.openCreateAdvertiserModal
    }
  }, [_vm._v(" New Advertiser ")])], 1)]), _c('widget', {
    staticClass: "mb-2"
  }, [_c('widget-header', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('AdvertiserFilters')], 1)], 2)], 1), _vm.advertisers ? _c('YTable', {
    attrs: {
      "data": _vm.advertisers.data || [],
      "bottom-loading": _vm.$apolloData.queries.advertisers.loading,
      "row-classes": function (row) {
        return row.userDetails.followUpDue ? ['follow-up-row'] : [];
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', {
          staticStyle: {
            "padding-right": "0",
            "width": "20px"
          }
        }, [props.rowData.userDetails.followUpDue ? _c('span', [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: 'Follow Up Due',
            expression: "'Follow Up Due'"
          }],
          staticClass: "text-pink-500 rounded m-1",
          attrs: {
            "name": "exclamationThick",
            "size": 4
          }
        })], 1) : _vm._e()]), _c('td', {
          staticStyle: {
            "padding-left": "0.5rem"
          }
        }, [_c('div', {
          staticClass: "flex flex-row gap-1"
        }, [_c('div', {
          staticClass: "flex flex-col flex-grow"
        }, [_c('div', [_c('router-link', {
          staticClass: "link block",
          attrs: {
            "to": {
              name: 'Advertiser',
              params: {
                id: props.rowData.advertiserId
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.primaryContact.name ? props.rowData.primaryContact.name : props.rowData.primaryContact.company))])], 1), _c('div', {
          staticClass: "flex flex-row items-center text-xs text-gray-600 block"
        }, [_vm._v(" " + _vm._s(props.rowData.id) + " "), props.rowData.email ? _c('span', [_vm._v(" - " + _vm._s(props.rowData.email) + " ")]) : _vm._e()]), props.rowData.primaryContact.messenger ? _c('div', {
          staticClass: "flex items-center text-xs"
        }, [_c('Icon', {
          staticClass: "w-4 h-4 text-gray-600 mr-1",
          attrs: {
            "name": JSON.parse(props.rowData.primaryContact.messenger.toLowerCase()).app
          }
        }), _c('span', {
          staticClass: "text-gray-600"
        }, [_vm._v(" " + _vm._s(JSON.parse(props.rowData.primaryContact.messenger).handle) + " ")])], 1) : _vm._e()]), _c('div', {
          staticClass: "flex items-center"
        }, [_c('button', {
          staticClass: "hover:bg-gray-100 p-1 rounded",
          on: {
            "click": function ($event) {
              return _vm.openPriorityStatusModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.priorityFollowUp(props.rowData.userDetails.followUpAt),
            expression: "\n                  priorityFollowUp(props.rowData.userDetails.followUpAt)\n                "
          }],
          class: _vm.priorityIconColor(props.rowData.userDetails.priorityStatus),
          attrs: {
            "name": _vm.priorityIconType(props.rowData.userDetails.priorityStatus),
            "size": 6
          }
        })], 1)])])]), _c('td', [props.rowData.billingContact ? [_vm._v(" " + _vm._s(props.rowData.billingContact.paymentTerms) + " - " + _vm._s(props.rowData.billingContact.paymentFrequency) + " ")] : _vm._e()], 2), _c('td', [props.rowData.accountManager ? [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.accountManager.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.accountManager.primaryContact.name.split(' ')[0]) + " ")])] : _vm._e()], 2)];
      }
    }, {
      key: "after",
      fn: function () {
        return [_vm.advertisers.paginatorInfo ? _c('YPagination', {
          key: _vm.totalPages,
          attrs: {
            "data": _vm.advertisers.paginatorInfo
          },
          on: {
            "load-page": _vm.loadPage,
            "next-page": _vm.nextPage,
            "previous-page": _vm.previousPage,
            "per-page": _vm.perPageSelection
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 819782701)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th'), _c('th', [_vm._v("Advertiser")]), _c('th', [_vm._v("Payment Terms")]), _c('th', [_vm._v("Manager")])])], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }