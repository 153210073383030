<template>
  <widget>
    <widget-header @close="$emit('close')">
      Create Postback
    </widget-header>
    <widget-body>
      <form class="row mb-2" @submit.prevent="update" ref="form">
        <Superselect
          title="Protocol"
          v-model="postback.protocol"
          :multiple="false"
          :options="protocolOptions"
          class="mb-2"
        ></Superselect>

        <YInput v-model="postback.url" label="URL" class="mb-2"></YInput>

        <Superselect
          v-if="!this.redirectId"
          title="Redirect"
          v-model="postback.redirect"
          :multiple="false"
          track-by="id"
          label="label"
          :options="redirects ? redirects.data : []"
          class="mb-2"
        ></Superselect>
        <YCheckbox
          class="mt-2"
          v-model="useDefaultConversionType"
          label="Use default conversion type"
        ></YCheckbox>
        <Superselect
          v-if="!useDefaultConversionType"
          title="Conversion Type"
          v-model="postback.conversionType"
          :multiple="false"
          :taggable="true"
          :options="conversionTypeOptions"
        ></Superselect>
      </form>
    </widget-body>
    <widget-footer class="bg-gray-100 p-4 text-right">
      <y-button
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="create"
        @keydown.enter="create"
        :disabled="!canSubmit"
        >Create Postback</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import CONVERSION_TYPES_QUERY from '@/graphql/ConversionType/ConversionTypesQuery.gql'

import gql from 'graphql-tag'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    userId: {
      type: Number,
      required: false
    },
    redirectId: {
      type: Number,
      required: false
    }
  },
  components: {},
  apollo: {
    countries: {
      query: gql`
        {
          countries {
            code
            name
            label
          }
        }
      `
    },
    redirects: {
      query: gql`
        query redirects($filters: RedirectFiltersInput, $first: Int!) {
          redirects: redirects(filters: $filters, first: $first) {
            data {
              id
              label
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            userId: { value: this.userId }
          },

          first: 1000
        }
      },
      deep: true
    },
    conversionTypes: {
      query: CONVERSION_TYPES_QUERY,
      variables() {
        return {
          first: 999
        }
      },
      result({ data }) {
        this.conversionTypeOptions = data?.conversionTypes?.data.map(
          object => object.name
        )
      }
    }
  },
  data() {
    return {
      postback: {
        protocol: 'https'
      },
      isSubmitting: false,
      colSize: 'col-xl-3 col-lg-6',
      colSize2: 'col-xl-6 col-lg-12',
      protocolOptions: ['http', 'https'],
      conversionTypeOptions: [],
      useDefaultConversionType: true
    }
  },
  watch: {},
  computed: {
    canSubmit() {
      if (!this.postback.url) {
        return false
      }
      if (!this.useDefaultConversionType && !this.postback.conversionType) {
        return false
      }
      return true
    }
  },
  methods: {
    create() {
      this.isSubmitting = true
      let input = {
        userId: this.userId,
        redirectId: this.redirectId
          ? this.redirectId
          : this.postback.redirect
          ? this.postback.redirect.id
          : undefined,
        url: this.postback.url,
        protocol: this.postback.protocol,
        conversionType: this.useDefaultConversionType
          ? null
          : this.postback.conversionType
      }

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createPostback($input: CreatePostbackInput!) {
              createPostback(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: input
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('Postback Created', 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshPostbacks', true)
          this.$emit('close')
        })
        .catch(error => {
          console.log('error')
          this.isSubmitting = false
          this.setValidationErrors(error)
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
