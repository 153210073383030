var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex"
  }, [_c('Superselect', {
    staticClass: "w-1/3",
    attrs: {
      "title": "Fallback Campaign",
      "track-by": "id",
      "label": "label",
      "multiple": false,
      "required": true,
      "options": [],
      "query": _vm.CAMPAIGN_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          },
          status: ['active']
        }
      }
    },
    model: {
      value: _vm.selectedCampaign,
      callback: function ($$v) {
        _vm.selectedCampaign = $$v;
      },
      expression: "selectedCampaign"
    }
  }), _vm.fallbackOfferOptions.length > 0 ? _c('Superselect', {
    staticClass: "ml-2 w-1/3",
    attrs: {
      "title": "Fallback Offer",
      "multiple": false,
      "required": true,
      "track-by": "id",
      "label": "label",
      "options": _vm.fallbackOfferOptions
    },
    model: {
      value: _vm.selectedOffer,
      callback: function ($$v) {
        _vm.selectedOffer = $$v;
      },
      expression: "selectedOffer"
    }
  }) : _vm._e(), _vm.selectedOffer && _vm.selectedOffer.id !== _vm.fallbackOfferId || _vm.selectedCampaign && _vm.selectedCampaign.id !== _vm.fallbackCampaignId || _vm.fallbackCampaignId && !_vm.selectedCampaign ? _c('YButton', {
    staticClass: "ml-2 h-12",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isUpdating
    },
    on: {
      "click": function ($event) {
        _vm.type === 'Redirect' ? _vm.saveRedirectFallback() : _vm.saveCampaignFallback();
      }
    }
  }, [_vm._v("Save")]) : _vm._e(), _vm.selectedCampaign ? _c('YButton', {
    staticClass: "ml-2 h-12",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isUpdating
    },
    on: {
      "click": _vm.clearFallbackValues
    }
  }, [_vm._v("Clear")]) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }