<template>
  <span>
    <img :src="getFlagUrl()" :class="[`w-${size}`]" />
  </span>
</template>

<script>
export default {
  props: {
    country: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      default: 4
    },
    aspectRatio: {
      type: String,
      default: '4x3'
    }
  },
  methods: {
    getFlagUrl() {
      return require('@/assets/images/flags/svg/' +
        this.aspectRatio +
        '/' +
        this.country.toLowerCase() +
        '.svg')
    }
  }
}
</script>

<style></style>
