<template>
  <div>
    <div
      v-if="singleOfferView"
      class=" sticky flex justify-between items-center bg-gray-200 px-4 py-4
      shadow-md my-2"
      style="top: -20px; z-index: 10"
    >
      <div class="text-muted">
        <span>
          {{ selectedCount }}
          {{ selectedCount == 1 ? 'item' : 'items' }} selected
          <small class="pl-3" v-if="rowsAreSelected">
            <a
              href="#"
              @click.prevent="clearAllSelected"
              class="link"
              style="border-radius: 2rem; padding: 0.1rem 0.6rem"
              >CLEAR</a
            >
          </small>
        </span>
      </div>
      <div class="flex space-x-4">
        <YButton color="red" :disabled="!rowsAreSelected" @click="deleteOffers"
          >Bulk Delete</YButton
        >
        <YButton
          color="blue"
          :disabled="
            !rowsAreSelected ||
              !selectedHaveSingleAdvertiser ||
              selectedHasTemplate
          "
          @click="loadBulkPayouts(selectedOffers)"
        >
          Payouts
        </YButton>

        <YButton
          color="blue"
          @click="bulkDropdownOpen = !bulkDropdownOpen"
          :disabled="!rowsAreSelected"
        >
          Bulk Traffic
        </YButton>
        <div
          v-show="bulkDropdownOpen"
          @click="bulkDropdownOpen = false"
          class="fixed inset-0 h-full w-full z-10"
        ></div>

        <div
          v-show="bulkDropdownOpen"
          class="absolute py-2 bg-white rounded-md shadow-xl z-20 w-32 text-center font-bold"
          style="top: 65px; right: 99px; margin: 0"
        >
          <span
            class="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-200  "
            @click="pauseOffers"
          >
            Pause
          </span>
          <span
            class="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-200  "
            @click="deactivateOffers"
          >
            De-activate
          </span>
          <span
            class="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-200 "
            @click="trafficOffers"
          >
            Traffic
          </span>
        </div>

        <router-link
          :to="{
            name: 'Offers Edit',
            params: {
              selectedOffers: selectedOffers
            }
          }"
        >
          <YButton color="blue" :disabled="!rowsAreSelected">
            Edit
          </YButton>
        </router-link>
      </div>
    </div>
    <YTable
      :data="offers"
      :bottom-loading="bottomLoading"
      :selectable="true"
      selectBy="id"
      :selected.sync="selectedRowIds"
    >
      <template slot="header-row">
        <th>
          <OrderByItem column="id" order="DESC" v-model="orderBy"
            >Offer</OrderByItem
          >
        </th>
        <th>Destination</th>
        <th>Offer Links</th>
        <th>Advertiser</th>
        <th v-if="singleOfferView">Payouts</th>
        <th
          v-for="type of tagTypes"
          :key="type"
          v-bind:style="offers.length == 1 ? 'display: none' : ''"
        >
          {{ tagTypeNameMap[type] }}
        </th>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          <div class="flex items-center">
            <span>
              <OfferTrafficStatus
                :offer="props.rowData"
                :hide-label="true"
                class="mr-2 -ml-2"
              ></OfferTrafficStatus>
            </span>
            <EditableImage
              class="rounded mr-2"
              :imageUrl="props.rowData.legacyImageUrl"
              :thumbnailUrl="props.rowData.legacyImageThumbnailUrl"
              :height="12"
              :width="12"
              :blankHeight="12"
              :blankWidth="12"
              :editable-only-when-empty="true"
              modelClass="Offer"
              :modelId="props.rowData.id"
              :deleteExisting="true"
            ></EditableImage>
            <div
              class="flex justify-between items-center flex-1"
              style="max-width: 35rem;"
            >
              <div class="mx-4">
                <router-link
                  :to="{
                    name: 'Offer',
                    params: { id: props.rowData.id, action: 'edit' }
                  }"
                  class="font-bold break-words without-truncate inline-block"
                  :class="
                    props.rowData.isCapped
                      ? 'text-red-600 hover:text-red-700'
                      : 'link'
                  "
                >
                  <template v-if="props.rowData.id"
                    >{{ props.rowData.id }} -
                  </template>
                  {{ props.rowData.name }}
                </router-link>
                <sup class="text-gray-500 font-semibold">
                  {{ props.rowData.encodedId }}
                </sup>
              </div>
              <div class="flex items-center">
                <div>
                  <button
                    v-tooltip="
                      keyedOfferLinkedToCampaign(props.rowData)
                        ? 'Keyed Offer linked to Campaign'
                        : offerLinkedToCampaign(props.rowData)
                        ? 'Offer linked to Campaign'
                        : ''
                    "
                    @click="openOfferCampaignsModal(props.rowData)"
                    :class="[
                      'py-3 px-1 outline-none-important',
                      offerLinkedToCampaign(props.rowData)
                        ? 'text-blue-500 hover:text-blue-600'
                        : 'text-gray-300 cursor-default'
                    ]"
                  >
                    <Icon
                      v-if="keyedOfferLinkedToCampaign(props.rowData)"
                      name="linkPlus"
                      size="5"
                    />
                    <Icon v-else name="link" size="5" />
                  </button>
                </div>

                <v-popover
                  offset="2"
                  placement="right"
                  :autoHide="true"
                  popoverClass
                  :class="['sidebar-user-popover']"
                  popoverBaseClass="popover-menu sidebar-popover-menu"
                  popoverInnerClass="popover-menu-inner animated animated-speed1 fadeIn"
                >
                  <!-- This will be the popover target (for the events and position) -->
                  <YButton
                    class="btn btn-transparent btn-rounded tooltip-target "
                  >
                    <Icon name="dotsVertical" :size="4" />
                  </YButton>

                  <!-- This will be the content of the popover -->
                  <template slot="popover">
                    <div class="list-group flex flex-col">
                      <template>
                        <YButton color="link ">
                          <router-link
                            :to="{
                              name: 'Offer',
                              params: { action: 'edit' }
                            }"
                            >Edit</router-link
                          ></YButton
                        >
                      </template>
                      <template>
                        <YButton
                          color="link "
                          @click="
                            openOfferCloneModal(props.rowData.id, props.rowData)
                          "
                        >
                          Clone</YButton
                        >
                      </template>
                      <!--
                        <template v-if="props.rowData.status == 'active'">
                        <YButton
                          :disabled="props.rowData.status == 'active'"
                          class="text-red-500 hover:bg-red-100 w-full"
                          @click="setOfferStatus(props.rowData.id, 'inactive')"
                          v-tooltip="
                            'Setting offer to Inactive will cause it to be deleted 2 years after create_date (' +
                              props.rowData.createdAt +
                              ') if no stats occure'
                          "
                          >Set To Inactive</YButton
                        >
                      </template>
                      <template v-if="props.rowData.status == 'inactive'">
                        <YButton
                          class="text-red-500 hover:bg-red-100"
                          @click="setOfferStatus(props.rowData.id, 'active')"
                          v-tooltip="
                            'Setting offer to Active will prevent it from being deleted 2 years after create_date (' +
                              props.rowData.createdAt +
                              ') if no stats occure'
                          "
                          >Activate</YButton
                        >
                      </template>
                      -->
                      <template>
                        <ClickAndReplace
                          :disabled="
                            !!props.rowData.campaigns &&
                              props.rowData.campaigns.length > 0
                          "
                          :duration="5000"
                          v-tooltip="
                            offerLinkedToCampaign(props.rowData)
                              ? 'Remove Offer from Campaign to delete'
                              : ''
                          "
                        >
                          <YButton
                            :disabled="!!offerLinkedToCampaign(props.rowData)"
                            class="text-red-500 hover:bg-red-100 w-full"
                            >Delete</YButton
                          >
                          <template v-slot:replacement>
                            <YButton
                              class="bg-red-500 text-white hover:bg-red-600"
                              @click="deleteOffer(props.rowData.id)"
                              :is-loading="isDeleting"
                              >Confirm Delete</YButton
                            >
                          </template>
                        </ClickAndReplace>
                      </template>
                    </div>
                  </template>
                </v-popover>
              </div>
            </div>
          </div>
        </td>
        <td>
          <router-link
            v-if="
              props.rowData.destinationType == 'template' &&
                props.rowData.destination
            "
            :to="{
              name: 'Template',
              params: { path: props.rowData.destination }
            }"
            class="truncate"
            :class="
              props.rowData.isCapped
                ? 'text-red-600 hover:text-red-700'
                : 'text-blue-600 hover:text-blue-800'
            "
            >{{ props.rowData.destination }}</router-link
          >
          <template v-else-if="props.rowData.destinationType == 'template'">
            <span class="italic text-gray-500">(Template)</span>
          </template>
          <span v-else class="italic text-gray-500">(Direct)</span>
        </td>
        <td>
          <ul v-if="props.rowData.offers && props.rowData.offers.length > 0">
            <li v-for="offer in props.rowData.offers" :key="offer.id">
              <router-link
                :to="{
                  name: 'Offer',
                  params: { id: offer.id, action: 'edit' }
                }"
                class="block"
                :class="
                  props.rowData.isCapped
                    ? 'text-red-600 hover:text-red-700'
                    : 'link-wrap'
                "
                >{{ offer.label }}</router-link
              >
            </li>
          </ul>
        </td>
        <td>
          <ul
            v-if="
              props.rowData.advertisers && props.rowData.advertisers.length > 0
            "
          >
            <li
              v-for="advertiser in props.rowData.advertisers"
              :key="advertiser.id"
            >
              <router-link
                :to="{
                  name: 'User',
                  params: { id: advertiser.id }
                }"
                class="truncate"
                :class="
                  props.rowData.isCapped
                    ? 'text-red-600 hover:text-red-700'
                    : 'link-wrap'
                "
                >{{ advertiser.name }}</router-link
              >
            </li>
          </ul>
        </td>
        <td
          class="text-gray-600 text-xs"
          style="max-width:60px;"
          v-if="singleOfferView"
        >
          {{
            props.rowData.revenues && props.rowData.revenues.length > 0
              ? topLevelPayouts(props.rowData.revenues)
              : ''
          }}
        </td>
        <td
          v-for="type of tagTypes"
          :key="type"
          style="max-width:140px;"
          v-bind:style="offers.length == 1 ? 'display: none' : ''"
        >
          <div>
            <Tag
              v-for="tag in truncateOfferTagsByType(
                offerTagsByType[`${props.rowData.id}-${type}`]
              )"
              :color="tag.isNot ? 'red' : tag.color"
              :key="tag.name"
              style="margin: 1px;"
            >
              {{ tag.name }}
            </Tag>
            <span
              v-if="
                truncateTags(offerTagsByType[`${props.rowData.id}-${type}`])
              "
            >
              <router-link
                :to="{
                  name: 'Offer',
                  params: { id: props.rowData.id, action: 'edit' }
                }"
                class="block font-semibold text-blue-600 mt-1 ml-2 text-xs hover:text-blue-800"
                >More...
              </router-link>
            </span>
          </div>
        </td>
      </template>
    </YTable>
  </div>
</template>

<script>
import DELETE_OFFER_MUTATION from '@/graphql/Offer/DeleteOfferMutation.gql'
import SET_STATUS_MUTATION from '@/graphql/Status/SetStatusMutation.gql'
import OfferTrafficStatus from '@/views/Admin/Offers/OfferTrafficStatus'
import EditableImage from '@/components/utilities/EditableImage'
import OfferCampaignsModal from '@/views/Admin/Offers/OfferCampaignsModal'
import PauseOffers from '@/views/Admin/Offers/OffersPause.vue'
import DeactivateOffers from '@/views/Admin/Offers/OffersDeactivate.vue'
import TrafficOffers from '@/views/Admin/Offers/OffersTraffic.vue'
import TopLevelPayoutMixin from '@/mixins/TopLevelPayoutMixin.js'
import DeleteOffers from '@/views/Admin/Offers/OffersDelete.vue'
import OfferCloneModal from '@/views/Admin/Offers/OfferClone.vue'
import ClickAndReplace from '@/components/ui/ClickAndReplace'

export default {
  mixins: [TopLevelPayoutMixin],
  components: {
    EditableImage,
    OfferTrafficStatus,
    ClickAndReplace
  },
  props: {
    offers: {
      type: Array,
      default: () => []
    },
    bottomLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDeleting: false,
      tagTypeNameMap: {
        countryCode: 'Geo',
        vertical: 'Vertical'
      },
      selectedRowIds: [],
      view: {
        atTopOfPage: true
      },
      bulkDropdownOpen: false,
      truncateTagsValue: 4
    }
  },
  methods: {
    truncateOfferTagsByType(tags) {
      return tags ? tags.slice(0, this.truncateTagsValue) : tags
    },
    truncateTags(tags) {
      return tags && tags.length > this.truncateTagsValue
    },
    loadBulkPayouts(selectedOffers) {
      this.$router.push({
        name: 'Offers Payouts',
        params: { selectedOffers: selectedOffers }
      })
    },
    keyedOfferLinkedToCampaign(offer) {
      return (
        offer.offers &&
        offer.offers.length > 0 &&
        offer.offers.find(
          offer => offer.campaigns && offer.campaigns.length > 0
        )
      )
    },
    offerLinkedToCampaign(offer) {
      return (
        (offer.campaigns && offer.campaigns.length > 0) ||
        (offer.offers &&
          offer.offers.length > 0 &&
          offer.offers.find(
            offer => offer.campaigns && offer.campaigns.length > 0
          ))
      )
    },
    deleteOffer(id) {
      this.isDeleting = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: DELETE_OFFER_MUTATION,
          variables: {
            id: id
          }
        })
        .then(() => {
          this.$toastr.s('Offer Deleted', 'Success!')
          this.isDeleting = false
          this.$events.emit('offerDeleted')
          this.$store.dispatch('updateGlobalLoader', -1)
        })
        .catch(error => {
          this.isDeleting = false
          this.$store.dispatch('updateGlobalLoader', -1)
          console.error(error)
        })
    },
    setOfferStatus(offerId, status) {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: SET_STATUS_MUTATION,
          variables: {
            input: {
              modelName: 'Offer',
              modelIds: [offerId],
              name: status
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$toastr.s('Status Updated')
          this.$events.emit('refreshOffersTable', true)
        })
        .catch(error => {
          this.setValidationErrors(error)
        })
    },

    openOfferCampaignsModal(offer) {
      if (this.offerLinkedToCampaign(offer)) {
        this.$modal.show(
          OfferCampaignsModal,
          { offer: offer },
          {
            height: 'auto',
            scrollable: true,
            width: '80%',
            maxWidth: 600,
            minWidth: 200,
            adaptive: true
          }
        )
      }
    },
    clearAllSelected() {
      this.selectedRowIds = []
    },
    pauseOffers() {
      this.$modal.show(
        PauseOffers,
        { selectedRowIds: this.selectedRowIds },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 650,
          adaptive: true,
          scrollable: true
        }
      )
    },
    deactivateOffers() {
      this.$modal.show(
        DeactivateOffers,
        { selectedRowIds: this.selectedRowIds },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 650,
          adaptive: true,
          scrollable: true
        }
      )
    },
    trafficOffers() {
      this.$modal.show(
        TrafficOffers,
        { selectedRowIds: this.selectedRowIds },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 650,
          adaptive: true,
          scrollable: true
        }
      )
    },
    deleteOffers() {
      this.$modal.show(
        DeleteOffers,
        { selectedRowIds: this.selectedRowIds },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 650,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openOfferCloneModal(id, data) {
      this.$modal.show(
        OfferCloneModal,
        {
          id: id,
          offerData: data
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  },
  beforeDestroy() {
    if (this.isDeleting == true) {
      this.$store.dispatch('updateGlobalLoader', -1)
    }
  },
  computed: {
    selectedHaveSingleAdvertiser() {
      let advertisers = []
      this.selectedOfferObjects.forEach(offer => {
        advertisers = [...advertisers, ...offer.advertisers.map(adv => adv.id)]
      })

      return [...new Set(advertisers)].length == 1
    },
    selectedHasTemplate() {
      return (
        this.selectedOfferObjects.filter(
          offer => offer.destinationType == 'template'
        ).length > 0
      )
    },
    selectedOfferObjects() {
      return this.offers.filter(offer => this.selectedRowIds.includes(offer.id))
    },
    selectedOffers() {
      return this.selectedRowIds.toString()
    },
    orderBy: {
      get() {
        return this.$store.state.offer.orderBy
      },
      set(value) {
        this.$store.state.offer.orderBy = JSON.parse(JSON.stringify(value))
      }
    },
    localOffers() {
      return JSON.parse(JSON.stringify(this.offers))
    },
    offerTagsByType() {
      let offerTagsByType = []
      let tagTypeColumns = Object.keys(this.tagTypeNameMap)

      this.offers.forEach(offer => {
        if (offer.tags) {
          offer.tags.forEach(tag => {
            //return columns set from tagTypeNameMap
            let findTypeColumn = tagTypeColumns.find(item =>
              item.includes(tag.type)
            )
            if (findTypeColumn) {
              if (offerTagsByType[`${offer.id}-${tag.type}`]) {
                offerTagsByType[`${offer.id}-${tag.type}`] = [
                  ...offerTagsByType[`${offer.id}-${tag.type}`],
                  tag
                ]
              } else {
                offerTagsByType[`${offer.id}-${tag.type}`] = [tag]
              }
            }
          })
        }
      })
      return offerTagsByType
    },
    tagTypes() {
      let tagTypeColumns = Object.keys(this.tagTypeNameMap)
      let types = new Set()
      if (this.offers) {
        this.offers.forEach(offer => {
          if (offer.tags) {
            offer.tags.forEach(tag => {
              //return columns set from tagTypeNameMap
              let findTypeColumn = tagTypeColumns.find(item =>
                item.includes(tag.type)
              )

              if (findTypeColumn) {
                types.add(tag.type)
              }
            })
          }
        })
      }
      return [...types]
    },
    rowsAreSelected() {
      return this.selectedRowIds.length > 0
    },
    selectedCount() {
      return this.selectedRowIds.length
    },
    singleOfferView() {
      return this.offers.length > 1
    }
  }
}
</script>

<style>
.scrolled {
  @apply shadow-2xl;
  border-bottom: 5px solid hotpink;
}
</style>
