<template>
  <div>
    <div class="bg-gray-200 p-1 border-b mb-2">
      <template>
        <div class="w-full">
          <div class="grid grid-cols-1 grid-cols-7 gap-1">
            <Superselect
              title="Country"
              v-model="filters.country.value"
              :multiple="false"
              label="label"
              track-by="code"
              :options="countries"
              :loading="$apollo.queries.countries.loading"
            ></Superselect>
             <!-- <Superselect
              title="Region"
              v-model="filters.regionCode.value"
              :options="regionCode"
               :multiple="false"
              :loading="$apollo.queries.regionCodeOptions.loading"
              :modifiers.sync="filters.regionCode.modifiers"
              track-by="id"
              label="label"
            ></Superselect>  -->

            <!-- <TagsByTypeSelect
              title="Region Code"
              class="col-span-2 mb-1"
              v-model="filters.regionCode.value"
              :enable-not-toggles="false"
              :close-on-select="false"
              :allow-bulk-paste="false"
              :limit="1"
              :options="options.regions"
              :options-limit="1"
              :perPage="500"
              elastic-options-field="geo_region"
              :elastic-search="true"
              type="regionCode"
            /> -->
    
             <Superselect
              title="Region"
              v-model="filters.regionCode.value"
              :multiple="false"
              :options="options.regions"
            ></Superselect>

            <Superselect
              title="Device"
              v-model="filters.device"
              :multiple="false"
              :options="options.devices"
            ></Superselect>
            <Superselect
              title="Redirect"
              v-model="filters.redirect.value"
              :options="options.redirect"
              placeholder="Select Redirect"
              @select="redirectDropdownChange"
              :multiple="false"
              track-by="id"
              label="label"
              :query="queries.REDIRECT_OPTIONS_QUERY"
              :query-variables="{
                filters: {
                  label: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  }
                },
                first: 25
              }"
              :queryResultMap="{
                id: 'id',
                label: 'label',
                relationId:'campaign',
                relationLabel:'campaign'
              }"
            ></Superselect>
            <Superselect
              title="Smart Link Campaign"
              v-model="filters.campaign.value"
              :options="options.campaign"
              placeholder="Select Link Campaign"
              :multiple="false"
              track-by="id"
              label="label"
              :query="queries.CAMPAIGN_OPTIONS_QUERY"
              :query-variables="{
                filters: {
                  label: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  },
                  isSmartLink: true
                },
                first: 25
              }"
              :queryResultMap="{
                id: 'id',
                label: 'label'
              }"
            ></Superselect>
            <Superselect
              title="Offer"
              v-model="filters.offer.value"
              :options="options.offer"
              placeholder="Select Offer"
              :multiple="false"
              :options-limit="100"
              track-by="id"
              label="label"
              :query="queries.OFFER_OPTIONS_QUERY"
              :query-variables="{
                filters: {
                  label: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                  }
                },
                first: 100
              }"
            ></Superselect>
            <Superselect
              title="Vertical"
              v-model="filters.verticals.value"
              :options="verticalOptions"
              placeholder="Vertical"
            ></Superselect>
            <YButton
              v-if="filters.country.value && filters.level == 'Redirect'"
              color="blue"
              @click.prevent="openOptimizeModal()"
              >Run Optimization</YButton
            >
            <YButton
              color="blue"
              @click.prevent="toggleQualifiedRedirects()"
              >{{showButtonText}}</YButton
            >
          </div>
        </div>
      </template>
    </div>
    <div class="bg-gray-100 p-1 border-b mb-2">
      Notes:
      <EditableValue
          v-if="algorithmDescriptionV10"
          type="contentEditable"
          :mutation="mutations.UPDATE_ALGORITHM_MUTATION"
          :variables="{ input: { id: 10, description: '{value}' } }"
          :value="algorithmDescriptionV10"
    />
      <br>
      Last Pulled:  {{ this.lastUpdatedAtV10 }}
    </div>
    <!--Show qualifed redirects-->
    <div 
      v-if="showQualifiedRedirects == true" 
      class="bg-gray-100 p-1 border-b mb-2 overflow-y-scroll"
      style="height: 15rem"
    >
      <YTable
        v-if="qualifiedRedirectsV10"
        :data="qualifiedRedirectsV10.data || []"
        :loading="$apolloData.queries.qualifiedRedirectsV10.loading"
      >
        <template slot="header-row">
          <th>Redirect</th>
          <th>Campaign</th>
          <th>Geo</th>
          <th>Revenue</th>
        </template>
        <template slot="row" slot-scope="props">
          <td> 
            <template>
              <div class="flex" :class="'flex-row'">
                <a
                    href="#"
                    @click.prevent="updateFilters(props.rowData.redirect, props.rowData.campaign)"
                    class="link x-action-icon"
                  >
                  <Icon name="target" class="w-4 h-4" />
                </a>
                <div>
                  <router-link
                    :to="{
                      name: 'Redirect',
                      params: { id: props.rowData.redirect.id }
                    }"
                    class="link-wrap"
                  >
                    <span class="font-semibold">{{ props.rowData.redirect.id }}</span>
                    ({{ props.rowData.redirect.user.label }})
                  </router-link>
                </div>
              </div>
            </template>
          </td>
          <td>
            <template>
              <router-link
                :to="{
                  name: 'Campaign',
                  params: { id: props.rowData.campaign.id }
                }"
                class="link-wrap"
              >
                {{ props.rowData.campaign.label }}
              </router-link>
            </template>
          </td>
          <td>
            <div v-if="props.rowData.region">
              {{props.rowData.countryCode}} - {{ props.rowData.region }}
            </div>
            <div v-else>{{props.rowData.countryCode}}</div>
          </td>
          <td>${{props.rowData.revenue}}</td>
        </template>
      </YTable>
    </div>
    <div class="w-100">
      <YTable
        v-if="algorithmsDataV10"
        deletedAtField="isCapped"
        deletedAtClass="text-red-500 font-medium"
        :data="algorithmsDataV10 || []"
        :loading="$apolloData.queries.algorithmsDataV10.loading"
        :empty-text="`Not qualified`"
      >
        <template slot="header-row">
          <th>Offer</th>
          <th>Geo</th>
          <th>Region</th>
          <th v-if="filters.level != 'General'">Redirect</th>
          <th v-if="filters.level != 'General'">Campaign</th>
          <th v-if="filters.level != 'General'">Rpm</th>
          <th  v-if="filters.level != 'General'">Revenue</th>
          <th v-if="filters.level != 'General'" >Weight</th>
          <th v-if="filters.level != 'General'">Min</th>
          <th v-if="filters.level != 'General'">Max</th>
          <th v-if="filters.level != 'General'">Expiry Date</th>
          <th v-if="filters.level != 'General'">Fallback</th>
        </template>
        <template slot="row" slot-scope="props">
          <td>
            <div class="flex flex-row justify-between">
              <template v-if="props.rowData.offer">
                <div
                  class="flex "
                  :class="filters.level == 'All' ? 'w-full' : 'w-44 2xl:w-auto'"
                >
                  <router-link
                    :to="{
                      name: 'Offer',
                      params: { id: props.rowData.offer.id, action: 'edit' }
                    }"
                    :class="
                      props.rowData.isCapped
                        ? 'text-red-600 hover:text-red-700 font-medium'
                        : 'link-wrap'
                    "
                  >
                    {{ props.rowData.offer.label }}
                  </router-link>
                </div>
              </template>

              <div
                class="flex  ml-2 
                "
                :class="
                  filters.level == 'All'
                    ? 'w-24 flex-col justify-center 2xl:flex-row 2xl:w-auto'
                    : 'flex-row justify-end'
                "
              >
                <div v-for="tag in props.rowData.offer.tags" :key="tag.id">
                  <Tag
                    v-if="tag.type === 'vertical'"
                    :key="tag.id"
                    :color="tag.color"
                    class="mr-3 mb-1 2xl:mb-0"
                    v-tooltip="`Vertical`"
                    >{{ tag.name }}
                  </Tag>
                </div>

                <div>
                  <Tag
                    v-if="props.rowData.key.mobile_desktop == 'Desktop'"
                    color="blue"
                    >{{ props.rowData.key.mobile_desktop }}</Tag
                  >
                  <Tag
                    v-if="props.rowData.key.mobile_desktop == 'Mobile'"
                    color="blue"
                    >{{ props.rowData.key.mobile_desktop }}</Tag
                  >
                </div>
              </div>
            </div>
          </td>

          <td>{{ props.rowData.key.country_code }}</td>
          <td>{{ props.rowData.key.region_code}}</td>
          <td v-if="filters.level != 'General'">
            <template v-if="props.rowData.redirect">
              <router-link
                :to="{
                  name: 'Redirect',
                  params: { id: props.rowData.redirect.id }
                }"
                class="link"
              >
                {{ props.rowData.redirect.label }}
              </router-link>
            </template>
          </td>
          <td v-if="filters.level != 'General'">
            <template v-if="props.rowData.campaign">
              <router-link
                :to="{
                  name: 'Campaign',
                  params: { id: props.rowData.campaign.id }
                }"
                class="link"
              >
                {{ props.rowData.campaign.label }}
              </router-link>
            </template>
          </td>
          <td v-if="filters.level != 'General'">
            <div v-if="props.rowData.data?.rpm_average && props.rowData.data?.rpm_average !== 0">
              ${{ props.rowData.data.rpm_average ?? 0 }}
            </div>
            <div v-else>
              0
            </div>
          </td>
          <td v-if="filters.level != 'General'">
            <div v-if="props.rowData.data?.revenue && props.rowData.data?.revenue !== 0">
              ${{ props.rowData.data?.revenue }}
            </div>
            <div v-else>
              $0
            </div>
          </td>
          <td v-if="filters.level != 'General'">
            <div v-if="props.rowData.data?.weight">
              {{ props.rowData.data.weight }}
            </div>
          </td>
          <td v-if="filters.level != 'General'">
            <div v-if="props.rowData.data?.min">
              {{ props.rowData.data.min }}
            </div>
          </td>
          <td v-if="filters.level != 'General'">
            <div v-if="props.rowData.data?.max">
              {{ props.rowData.data.max }}
            </div>
          </td>
          <td v-if="filters.level != 'General'">
            <div v-if="props.rowData.data?.expiry">
              {{ props.rowData.data.expiry }}
            </div>
          </td>
          <td v-if="filters.level != 'General'">
            <div v-if="props.rowData.data?.fallback">
              {{ props.rowData.data.fallback }}
            </div>
          </td>
        </template>
      </YTable>
    </div>
    <div class="mt-2">
      <YButton
        class="block m-auto"
        color="link"
        @click="loadMore"
        :is-loading="algorithmsDataV10.loading"
        >LOAD MORE</YButton
      >
    </div>
  </div>
</template>

<script>
import ALGORITHMS_DATA_QUERY from '@/graphql/AlgorithmsData/AlgorithmsDataQuery.gql'
import QUALIFIED_REDIRECTS_QUERY from '@/graphql/Algorithm/AlgorithmQualifiedRedirectsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import AlgorithmOptimizeNine from '@/views/Admin/Algorithms/AlgorithmTen/AlgorithmOptimize.vue'
import ELASTIC_VALUE_SET_QUERY from '@/graphql/ElasticSearch/ElasticValueSetQuery.gql'
//import TagsByTypeSelect from '@/views/Admin/Tags/TagsByTypeSelect'
import UPDATE_ALGORITHM_MUTATION from '@/graphql/Algorithm/UpdateAlgorithmMutation.gql'
import gql from 'graphql-tag'

export default {
  components: {
    //TagsByTypeSelect
  },
  apollo: {
    algorithms: {
      query: gql`
        {
          algorithms {
            id
            metaData
            description
          }
        }
      `
    },
    regionCodeOptions: {
      query: ELASTIC_VALUE_SET_QUERY,
      variables() {
        return {
          input: {
            field: 'geo_region'
          }
        }
      },
      result(data) {
        this.regionCode = data.data.elasticValueSet.values.map(
          region => ({
            id: region,
            label: region
          })
        )
      },
      update: data => data.elasticValueSet
    },
    qualifiedRedirectsV10:{
      query: QUALIFIED_REDIRECTS_QUERY,
      update: data => data.algorithmQualifiedRedirects,
      variables() {
        return {
          first: 100,
          filters: {
            countryCode: this.filters.country.value ? {"value": [this.filters.country.value.code],"modifiers": {"matchType": "contains"}} : undefined,
            region: this.filters.regionCode.value
            ? {"value": [this.filters.regionCode.value],"modifiers": {"matchType": "equals"}}
            : {"value": [null],"modifiers": {"matchType": "euqals"}},
            algorithmId: {"value": [10],"modifiers": {"matchType": "equals"}},
          },
          orderBy: [{"column": "revenue","order": "DESC"}]
        }
      },
      fetchPolicy: 'network-only',
      deep: true
    },
    algorithmsDataV10: {
      query: ALGORITHMS_DATA_QUERY,
      update: data => data.algorithmsData,
      variables() {
        return {
          filters: {
            verticals: {
              value: this.filters.verticals.value
                ? this.filters.verticals.value.map(vertical => vertical)
                : null
            },
            countryCode: this.cleanObjectFilterValue(
              this.filters.country,
              'code'
            ),
            //regionCode: this.filters.regionCode.value ? this.genRegionCode(this.filters.regionCode) : null,
            regionCode: this.filters.regionCode.value ? {"modifiers": {"edit": false,"exclude": false},"value": [{"id": this.filters.regionCode.value,"label": this.filters.regionCode.value}]} : null,
            offerId: this.cleanObjectFilterValue(this.filters.offer, 'id'),
            redirectId: this.cleanObjectFilterValue(
              this.filters.redirect,
              'id'
            ),
            campaignId: this.cleanObjectFilterValue(
              this.filters.campaign,
              'id'
            ),
            level: this.filters.level ? this.filters.level : undefined,
            mobileDesktop: this.filters.device
              ? this.filters.device
              : undefined,
            paginate: this.perPage,
            algoId: 10
          }
        }
      },
      result: function(result) {
        if (result.data && result.data.algorithmsData) {
          this.lastUpdatedAtV10 = result.data.algorithmsData[0].updatedAt
        } else {
          this.$set(this, 'algorithmsDataV10', [])
        }
      },
      fetchPolicy: 'network-only',
      deep: true
    },
    countries: {
      query: COUNTRIES_QUERY
    },
    tags: {
      query: TAGS_QUERY,
      update: data => data.tags,
      variables() {
        return {
          first: 25,
          page: 1,
          filters: {
            type: {
              value: ['vertical']
            }
          }
        }
      },
      result: function(result) {
        this.verticalOptions = result.data.tags.data.map(tag => tag.name)
      }
    }
  },
  props: {},
  data() {
    return {
      perPage: 50,
      loadMoreIncrement: 25,
      verticalOptions: [],
      regionCode: null,
      showButtonText: 'Show Qualifed',
      showQualifiedRedirects: false,
      qualifiedRedirectsV10:null,
      lastUpdatedAtV10:null,
      filters: {
        device: 'Mobile',
        level: 'General',
        verticals: {
          value: null
        },
        regionCode: {
          value: null,
          modifiers: {
            exclude: false,
            edit: false
          }
        },
        country: {
          value: {
            code: 'CA',
            label: 'CA - Canada'
          },
          title: 'Country',
          type: 'select',
          multiple: true,
          optionLabelKey: 'label',
          trackByKey: 'code'
        },
        redirect: {
          value: null,
          title: 'Redirect',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
         campaign: {
          value: null,
          title: 'Campaign',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
        offer: {
          value: null,
          title: 'Offer',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        }
      },
      options: {
        offer: [],
        devices: ['Mobile', 'Desktop'],
        level: ['General', 'All'],
        regions: ['on','nj','al','ky','ct','mi','az','de','pa','fl','hi','wa','ga','ia','nv','il','la','id','ms','mt','nc','ga','oh','ok','sc','tn','tx','va','co','dc','ks','wy','md','ny'],
      },
      queries: {
        OFFER_OPTIONS_QUERY,
        REDIRECT_OPTIONS_QUERY,
        CAMPAIGN_OPTIONS_QUERY
      },
      mutations:{
        UPDATE_ALGORITHM_MUTATION
      }
    }
  },
  computed: {
    lastPulledV10(){
      if (this.algorithmsDataV10.data){
        return this.algorithmsDataV10.data.algorithmsData[0].updatedAt
      }
      return null
    },
    algorithmDescriptionV10() {
      if (
        !this.algorithms ||
        !this.algorithms[8] ||
        !this.algorithms[8].description
      ) {
        return null
      }
      return this.algorithms[8].description
    },
  },
  methods: {
    loadMore() {
      this.perPage = this.perPage + this.loadMoreIncrement
    },
    toggleQualifiedRedirects(){
      if (this.showQualifiedRedirects == true) {
        this.showQualifiedRedirects = false
        this.showButtonText =  'Show Qualifed'
      } else {
        this.showQualifiedRedirects = true
        this.showButtonText =  'Hide Qualifed'
      }
    },
    openOptimizeModal() {
      this.$modal.show(
        AlgorithmOptimizeNine,
        {
          filters: {
            mobileDesktop: this.filters.device
              ? this.filters.device
              : undefined,
            countryCode: this.cleanObjectFilterValue(
              this.filters.country,
              'code'
            ),
            regionCode: this.filters.regionCode?.value ? this.filters.regionCode?.value : undefined,
            redirectId: this.cleanObjectFilterValue(
              this.filters.redirect,
              'id'
            ),
            campaignId: this.cleanObjectFilterValue(this.filters.campaign, 'id')
          }
        },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 1200,
          adaptive: true,
          scrollable: true
        }
      )
    },
    /*openEditModal(algorithmsData) {
      this.$modal.show(
        AlgorithmsDataEdit,
        { algorithmData: algorithmsData },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 700,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDeleteModal(algorithmsData) {
      this.$modal.show(
        AlgorithmsDataDelete,
        { algorithmData: algorithmsData },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 700,
          adaptive: true,
          scrollable: true
        }
      )
    },*/
    cleanFilter(filter) {
      // Return undefined on null values
      // And make sure value is always an array
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }

      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    genRegionCode(filter){
      return filter.value.tags[0] ? {'modifiers':{'edit':false,'exclude':filter.value.isNot},'value':[{'id':filter.value.tags[0],'label':filter.value.tags[0]}]}: null
    },
    cleanObjectFilterValue(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }

      // pluck key from value
      return this.cleanFilter(filter).value.map(value => value[pluck])[0]
    },
    updateFilters(redirect,campaign){
      this.filters.level = 'Redirect'
      this.filters.redirect.value = [
          {
              "id": redirect.id,
              "label": redirect.label
          }
      ]
      this.filters.campaign.value = [
            {
                "id": campaign.id,
                "label": campaign.label
            }
          ]
    },
    redirectDropdownChange(){
      if (this.filters.redirect?.value !== null){        
        if (this.filters.redirect.value.secondaryId && this.filters.redirect.value.secondaryLabel){
          this.filters.campaign.value = [
            {
               "id": this.filters.redirect.value.secondaryId,
               "label": this.filters.redirect.value.secondaryLabel
            }
          ]
          this.filters.level = 'Redirect'
        }
      } else {
        this.filters.campaign.value = null
        this.filters.level = 'General'
      }
    },
  },
  mounted() {
    this.$events.listen('offerTrafficked', () => {
      this.$apollo.queries.algorithmsDataV10.refetch()
    })
    this.$events.listen('refreshAlgorithmsDataV10', () => {
      this.$apollo.queries.algorithmsDataV10.refetch()
    })
    this.$tabEvent.on('refreshAlgorithmsDataV10', () => {
      this.$apollo.queries.algorithmsDataV10.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('offerTrafficked')
    this.$events.remove('refreshAlgorithmsDataV10')
    this.$tabEvent.off('refreshAlgorithmsDataV10')
  }
}
</script>
