var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grid grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "h-full rounded-md px-4 py-3 bg-yellow-200 border border-yellow-300 mb-2 text-yellow-800 flex items-center"
  }, [_c('div', {
    staticClass: "flex items-center mr-6"
  }, [_c('Icon', {
    staticClass: "h-12 w-12",
    attrs: {
      "name": "information"
    }
  })], 1), _c('div', [_c('div', {
    staticClass: "uppercase font-extrabold flex justify-between"
  }, [_vm._v(" You are in Quarantine level " + _vm._s(_vm.level) + " ")]), _c('div', {
    staticClass: "text-xs"
  }, [_c('div', {
    staticClass: "italic mt-2"
  }, [_vm._v(" You will be "), _c('span', {
    staticClass: "font-extrabold"
  }, [_vm._v("capped at $" + _vm._s(_vm.payoutLimit) + " revenue")]), _vm._v(" per month"), _c('br'), _vm._v(" You can subscribe to a maximum of "), _c('span', {
    staticClass: "font-extrabold"
  }, [_vm._v("3 campaigns")]), _vm._v(" per month "), _c('div', {
    staticClass: "pt-2"
  }, [_vm._v(" Hit your revenue objective this month to progress to the next level ")])])])])]), _c('div', {
    staticClass: "h-full flex-grow rounded-md p-3 text-gray-900 border"
  }, [_c('div', {
    staticClass: "font-semibold uppercase mb-3"
  }, [_vm._v(" Quarantine Revenue Objective ")]), _vm._m(0), _c('ProgressSegments', {
    staticClass: "mt-3",
    attrs: {
      "value": _vm.payout,
      "startValue": 0,
      "endValue": _vm.payoutLimit,
      "tickClass": "text-gray-500 font-bold",
      "indicatorLabelMethod": function (value) {
        return '$' + value;
      },
      "segments": [{
        start: 0,
        end: _vm.payoutObjective,
        color: '#7ac3ff',
        leftColor: '#7ac3ff',
        rightColor: '#EEE',
        tickLabel: '$' + _vm.payoutObjective,
        tickLabelAlign: 'center',
        tickReachedColor: '#0b89ea',
        tickLabelColor: '#0b89ea',
        tickLabelDotColor: '#0b89ea'
      }, {
        start: _vm.payoutObjective,
        end: _vm.payoutLimit,
        color: '#0b89ea',
        leftColor: '#0b89ea',
        rightColor: '#EEE',
        tickLabel: '$' + _vm.payoutLimit,
        tickLabelAlign: 'right',
        tickReachedColor: '#f54747',
        tickLabelDotColor: '#f54747',
        tickLabelColor: '#f54747'
      }]
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-left text-xs italic text-gray-500"
  }, [_vm._v(" Hit your "), _c('span', {
    staticClass: "bg-blue-500 inline-block w-2 h-2 rounded-lg shadow-md",
    staticStyle: {
      "margin-right": "2px"
    }
  }), _c('span', {
    staticClass: "text-blue-500 font-bold"
  }, [_vm._v("revenue objective")]), _vm._v(" this month to increase your "), _c('span', {
    staticClass: "bg-red-500 inline-block w-2 h-2 rounded-lg shadow-md",
    staticStyle: {
      "margin-right": "2px"
    }
  }), _c('span', {
    staticClass: "text-red-500 font-bold"
  }, [_vm._v("cap limit")]), _vm._v(" next month. ")]);

}]

export { render, staticRenderFns }