<template>
  <widget :loading="!offers.data" class="pt-5">
    <widget-header @close="$emit('close')">
      Traffic Offers
    </widget-header>

    <widget-body v-if="offers.data">
      <div class="flex flex-col shadow-md mb-4 rounded-lg">
        <div
          class=" flex items-center justify-center h-12 bg-blue-600 text-white rounded-t-lg "
        >
          <Icon name="alertCircleOutline" size="8" />
        </div>
        <div class=" text-center text-lg py-6">
          Are you sure you want to traffic
          <span class="font-extrabold text-blue-700">{{
            this.offers.data.length
          }}</span>
          offers?
        </div>

        <div class="w-1/2 m-auto ">
          <div class="mb-4">
            <div>
              <RadioBox
                label="Smart Weight"
                :value="true"
                v-model="smartWeight"
              />
            </div>
            <!--<div v-show="smartWeight" class="mb-6 mt-4">
              <Superselect
                title="Algo Target"
                v-model="algorithmIds"
                :multiple="false"
                label="label"
                track-by="label"
                :options="[
                  { label: '2, 6', value: [2, 6] },
                  { label: '2', value: [2] },
                  { label: '5', value: [5] },
                  { label: '6', value: [6] },
                  { label: '7', value: [7] },
                ]"
                class="mb-1"
              ></Superselect>
            </div>-->
          </div>
          <div class="mb-4">
            <div>
              <RadioBox
                label="Manual Weight"
                :value="false"
                v-model="smartWeight"
              />
            </div>
            <div v-show="!smartWeight" class="mb-6 mt-4 x-form ">
              <div class="flex">
                <YInput
                  v-model="offer.weight"
                  label="Weight"
                  class="w-1/2"
                  input-class="border-r-0 rounded-r-none"
                ></YInput>
                <YInput
                  v-model="offer.min"
                  label="Min"
                  class="w-1/4"
                  input-class="border-l-0 rounded-l-none border-r-0 rounded-r-none"
                ></YInput>
                <YInput
                  v-model="offer.max"
                  label="Max"
                  class="w-1/4"
                  input-class="border-l-0 rounded-l-none"
                ></YInput>
              </div>
              <YInput
                label="Expiry Date"
                v-model="offer.expiry"
                dateFormat="YYYY-MM-DD"
                dateFormatted="ll"
                type="date"
                class="mb-2"
              />
              <YInput
                v-if="offer.expiry"
                v-model="offer.fallback"
                label="Fallback value"
              ></YInput>
              <!--<Superselect
                title="Algo Target"
                v-model="algorithmIds"
                :multiple="false"
                label="label"
                track-by="label"
                :options="[
                  { label: '2, 6', value: [2, 6, 7] },
                  { label: '2', value: [2] },
                  { label: '6', value: [6] },
                  { label: '7', value: [7] },
                ]"
                class="mb-1"
              ></Superselect>-->
            </div>
          </div>
        </div>

        <div class=" text-center space-x-10 py-6">
          <YButton color="gray" @click="$emit('close')">Cancel</YButton>
          <YButton
            color="blue"
            @click="trafficOffers"
            :is-loading="this.isSubmitting"
            >Traffic</YButton
          >
        </div>
      </div>

      <YTable v-if="offers.data" :data="offers.data">
        <template slot="header-row">
          <th>id</th>
          <th>Offer</th>
        </template>

        <template slot="row" slot-scope="props">
          <td>
            {{ props.rowData.id }}
          </td>
          <td>
            {{ props.rowData.name }}
          </td>
        </template>
      </YTable>
    </widget-body>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import TRAFFIC_OFFERS_MUTATION from '@/graphql/Offer/TrafficOffersMutation.gql'
import OFFERS_QUERY from '@/graphql/Offer/OffersQuery.gql'
import RadioBox from '@/components/ui/RadioBox'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    selectedRowIds: {
      type: Array,
      required: true
    }
  },
  mounted() {},
  updated() {},
  components: {
    RadioBox
  },
  apollo: {
    offers: {
      query: OFFERS_QUERY,
      variables() {
        return {
          first: 100,
          filters: {
            id: {
              value: this.selectedRowIds
            }
          },
          orderBy: [
            {
              column: 'id',
              order: 'DESC'
            }
          ]
        }
      }
    }
  },
  data() {
    return {
      offers: [],
      algorithmIds: [],
      smartWeight: false,
      offer: {
        min: 0.1,
        max: 100.0,
        weight: 1.0,
        fallback: null
      },
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    trafficOffers() {
      this.isSubmitting = true

      if (this.offer.fallback == null) {
        delete this.offer.fallback
      }
      let input = {
        offerIds: this.selectedRowIds,
        algorithmIds: this.algorithmIds?.value ?? [],
        smartWeight: this.smartWeight,
        data: this.offer
      }

      this.$apollo
        .mutate({
          mutation: TRAFFIC_OFFERS_MUTATION,
          variables: {
            input: input
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.clearValidationErrors()
          this.$toastr.s('Trafficking Offers...')
          this.close()
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.x-form > *:not(:last-child) {
  @apply mb-2;
}
</style>
