<template>
  <label class="rb-wrapper flex items-center text-sm">
    {{ label }}
    <input
      class="checkbox"
      type="radio"
      :checked="isChecked"
      :value="value"
      @change="$emit('change', cleanValue($event.target.value))"
    />
    <span class="checkmark"></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: { type: String, default: '', required: true },
    modelValue: { default: '' },
    value: { type: [String, Boolean], default: undefined }
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value
    }
  },
  methods: {
    cleanValue(value) {
      if (value === 'true') {
        return true
      }
      if (value === 'false') {
        return false
      }
      return value
    }
  }
}
</script>

<style lang="postcss" scoped>
/* Customize the label (the wrapper) */
.rb-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.95rem;
  color: #32363b;
}
/* Hide the browser's default radio button */
.rb-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #eee;
  box-shadow: inset 1px 1px 1px 0px rgba(0, 0, 0, 0.07);
  /* border: 1px solid #ccc; */
}
/* On mouse-over, add a grey background color */
.rb-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the radio button is checked, add a blue background */
.rb-wrapper input:checked ~ .checkmark {
  background-color: #1cd4a7;
  border-color: #1cd4a7;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.rb-wrapper input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.rb-wrapper .checkmark:after {
  top: 6px;
  left: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
}
</style>
