var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Update Password ")]), _c('WidgetBody', [_c('YInput', {
    attrs: {
      "label": "New Password",
      "type": "password",
      "name": "new_password"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('YInput', {
    staticClass: "mt-1",
    attrs: {
      "label": "Confirm Password",
      "type": "password",
      "name": "confirm_password"
    },
    model: {
      value: _vm.passwordConfirmation,
      callback: function ($$v) {
        _vm.passwordConfirmation = $$v;
      },
      expression: "passwordConfirmation"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "py-2 px-4 flex justify-between bg-gray-100"
  }, [_c('YButton', {
    attrs: {
      "color": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "disabled": !(_vm.password && _vm.passwordConfirmation && _vm.password == _vm.passwordConfirmation),
      "is-loading": _vm.isUpdating
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Update")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }