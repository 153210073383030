// CustomActions.vue

<template>
  <div class="custom-actions">
    <!--       <a href="#"  @click.prevent="itemAction('view-item', rowData, rowIndex)"><i class="zmdi zmdi-zoom-in"></i></a>
      <a href="#"  @click.prevent="itemAction('edit-item', rowData, rowIndex)"><i class="zmdi zmdi-edit"></i></a> -->
    <a
      href="#"
      class="text-danger"
      @click.prevent="itemAction('delete-item', rowData, rowIndex)"
      ><Icon name="trashCan" class="w-4 h-4"
    /></a>
  </div>
</template>

<script>
export default {
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  methods: {
    itemAction(action, data, index) {
      switch (action) {
        case 'delete-item':
          this.$emit('delete-row', {
            table: this.$parent,
            rowIndex: index
          })
          this.$delete(this.$parent.tableData, index)
          break
        case 'edit-item':
          break

        case 'view-item':
          break
      }
    }
  }
}
</script>

<style scoped>
.custom-actions a {
  padding: 4px;
  font-size: 125%;
}
</style>
