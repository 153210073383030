var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.rowData && _vm.rowData.subscribers ? [_c('div', {
    staticClass: "box-width"
  }, [_c('div', {
    staticClass: "overflow-y-auto rounded cursor-pointer hover:shadow-md focus:shadow-md outline-none",
    staticStyle: {
      "max-height": "8rem"
    }
  }, _vm._l(_vm.rowData.subscribers, function (user) {
    return _c('router-link', {
      key: user.id,
      staticClass: "link truncate block hover:underline",
      attrs: {
        "to": {
          name: 'User',
          params: {
            id: user.id
          }
        }
      }
    }, [_c('Tag', {
      staticClass: "block py-2 rounded-none",
      attrs: {
        "color": "blue",
        "shade": 100,
        "hover": true
      }
    }, [_vm._v(" " + _vm._s(user.label) + " ")])], 1);
  }), 1), _c('div', {
    staticClass: "flex justify-between px-2"
  }, [_c('div', [_c('small', [_c('a', {
    staticClass: "link cursor-pointer mt-1 block font-semibold",
    attrs: {
      "href": "#",
      "tabindex": "0"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openSubscribeUserModal.apply(null, arguments);
      }
    }
  }, [_vm._v("Add Subscribers")])])]), _c('div', [_vm.rowData.subscribers ? _c('small', {
    staticClass: "font-semibold text-gray-700 text-blue-600 hover:text-blue-500 cursor-pointer",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openSubscribeUserModal.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.rowData.subscribers.length) + " Subscriber"), _vm.rowData.subscribers.length > 1 ? [_vm._v("s")] : _vm._e()], 2) : _vm._e()])])])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }