var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-screen flex"
  }, [_c('div', {
    staticClass: "flex h-full w-full"
  }, [_c('div', {
    staticClass: "flex-shrink"
  }, [_c('div', {
    staticClass: "font-semibold px-3 py-3 uppercase text-gray-500"
  }, [_vm._v(" Templates ")]), _c('ul', {
    staticClass: "overflow-y-hidden"
  }, _vm._l(_vm.templates, function (template) {
    return _c('li', {
      key: template.path,
      staticClass: "py-1 px-3",
      class: template.path == _vm.path ? 'bg-blue-600 text-white' : 'hover:bg-blue-100'
    }, [_c('router-link', {
      staticClass: "link block py-2 px-4 -my-2 -mx-4",
      class: template.path == _vm.path ? 'text-white hover:text-white' : '',
      attrs: {
        "to": {
          name: 'Template',
          params: {
            path: template.path
          }
        }
      }
    }, [_vm._v(_vm._s(template.path))])], 1);
  }), 0)]), _c('div', {
    staticClass: "flex-grow pl-4"
  }, [_vm.template ? _c('Tabs', {
    staticClass: "h-full w-full",
    attrs: {
      "show-type": "show",
      "tabDetailsClass": "overflow-y-auto"
    }
  }, [_c('Tab', {
    key: _vm.template.path,
    staticClass: "flex-1 p-2",
    attrs: {
      "name": "Preview",
      "active": true
    }
  }, [_c('div', {
    staticClass: "flex h-full"
  }, [_c('div', {
    staticClass: "flex-shrink pr-6"
  }, [_c('TemplateVars', {
    attrs: {
      "definitions": _vm.template.varDefinitions,
      "initial-inputs": _vm.initialVarInputs
    },
    on: {
      "inputsUpdated": _vm.cacheVarInputs,
      "queryStringUpdated": _vm.updateVarQueryString
    }
  })], 1), _c('div', {
    staticClass: "flex-grow"
  }, [_c('iframe', {
    staticClass: "w-full h-full",
    attrs: {
      "src": this.templatePreviewLink + '/' + this.selectedPath + '?' + this.varQueryString
    }
  })])])]), _c('Tab', {
    staticClass: "flex-1 p-2",
    attrs: {
      "name": "Details"
    }
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('span', {
    staticClass: "font-semibold pr-3 uppercase text-gray-500"
  }, [_vm._v("Public Name:")]), _vm._v(" " + _vm._s(_vm.template.name) + " ")]), _c('div', {
    staticClass: "mb-2"
  }, [_c('span', {
    staticClass: "font-semibold pr-3 uppercase text-gray-500"
  }, [_vm._v("Enabled Extensions:")]), _vm._l(_vm.template.extensions, function (extension) {
    return _c('ul', {
      key: extension.name
    }, [_c('li', [_vm._v(_vm._s(extension.name))])]);
  })], 2), _c('div', {
    staticClass: "mb-2"
  }, [_c('span', {
    staticClass: "font-semibold pr-3 uppercase text-gray-500"
  }, [_vm._v("Enabled Variables:")]), _vm._l(_vm.template.varDefinitions, function (varDefinition) {
    return _c('ul', {
      key: varDefinition.key
    }, [_c('li', [_vm._v(_vm._s(varDefinition.key))])]);
  })], 2)])], 1) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }