var render = function render(){
  var _vm$offer$template$li, _vm$offer$varsConfig$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-secondary"
    }
  }, [_c('div', {
    staticClass: "flex justify-between p-2"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('router-link', {
    staticClass: "outline-none-important",
    attrs: {
      "to": {
        name: 'Offers'
      }
    }
  }, [_c('button', {
    staticClass: "btn text-gray-800 hover:text-blue-600 focus:bg-gray-100 cursor-pointer px-4 flex items-center outline-none-important"
  }, [_c('Icon', {
    staticClass: "w-4 h-4",
    attrs: {
      "name": "arrowLeft"
    }
  }), _c('h3', {
    staticClass: "font-extrabold pl-4"
  }, [_vm._v("Offers")])], 1)])], 1), _c('div', [_vm.offer ? _c('div', {
    staticClass: "flex items-center mr-4"
  }, [_vm.id ? _c('OfferTrafficStatus', {
    attrs: {
      "id": _vm.id
    }
  }) : _vm._e()], 1) : _vm._e()])])]), _vm.showCreateOffer ? _c('div', [_c('h4', {
    staticClass: "widget-title mb-4 ml-2"
  }, [_vm._v(" Create Offer ")]), _c('div', {
    staticClass: "grid grid-cols-3 gap-4",
    attrs: {
      "disabled": ""
    }
  }, [_c('Superselect', {
    attrs: {
      "title": "Destination Type",
      "options": ['direct', 'template'],
      "multiple": false,
      "disabled": _vm.action === 'edit' || _vm.action === 'payouts'
    },
    model: {
      value: _vm.destinationType,
      callback: function ($$v) {
        _vm.destinationType = $$v;
      },
      expression: "destinationType"
    }
  }), _vm.destinationType ? _c('div', {
    staticClass: "flex flex-col gap-2"
  }, [_vm.destinationType == 'direct' ? _c('Superselect', {
    attrs: {
      "title": "yLeader Status",
      "options": ['yLeader', 'regular'],
      "multiple": false,
      "disabled": _vm.action === 'edit' || _vm.yleaderStatusDisabled
    },
    model: {
      value: _vm.yleaderStatus,
      callback: function ($$v) {
        _vm.yleaderStatus = $$v;
      },
      expression: "yleaderStatus"
    }
  }) : _vm._e(), _vm.destinationType == 'direct' ? _c('YInput', {
    attrs: {
      "label": "Direct URL"
    },
    model: {
      value: _vm.destination,
      callback: function ($$v) {
        _vm.destination = $$v;
      },
      expression: "destination"
    }
  }) : _vm._e(), _vm.destinationType == 'template' ? _c('Superselect', {
    key: "template-select",
    attrs: {
      "title": "Template",
      "options": [],
      "track-by": "path",
      "label": "path",
      "multiple": false,
      "query": _vm.TEMPLATES_QUERY,
      "query-variables": {}
    },
    on: {
      "select": _vm.updateDestination
    },
    model: {
      value: _vm.destinationTemplate,
      callback: function ($$v) {
        _vm.destinationTemplate = $$v;
      },
      expression: "destinationTemplate"
    }
  }) : _vm._e(), _vm.destinationType == 'template' ? _c('Superselect', {
    attrs: {
      "title": "Is Prelander?",
      "options": ['Yes', 'No'],
      "multiple": false
    },
    model: {
      value: _vm.isPrelander,
      callback: function ($$v) {
        _vm.isPrelander = $$v;
      },
      expression: "isPrelander"
    }
  }) : _vm._e(), _vm.destinationType == 'template' ? _c('Superselect', {
    attrs: {
      "title": "Remove Timeout?",
      "options": ['Yes', 'No'],
      "multiple": false
    },
    model: {
      value: _vm.ignoreTimeout,
      callback: function ($$v) {
        _vm.ignoreTimeout = $$v;
      },
      expression: "ignoreTimeout"
    }
  }) : _vm._e(), _vm.destinationType == 'template' ? _c('Superselect', {
    attrs: {
      "title": "Tracking Override",
      "options": ['Yes'],
      "multiple": false
    },
    model: {
      value: _vm.trackingOverride,
      callback: function ($$v) {
        _vm.trackingOverride = $$v;
      },
      expression: "trackingOverride"
    }
  }) : _vm._e(), _c('YInput', {
    attrs: {
      "label": "Details"
    },
    model: {
      value: _vm.details,
      callback: function ($$v) {
        _vm.details = $$v;
      },
      expression: "details"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Domain",
      "label": "id",
      "track-by": "id",
      "options": _vm.domainOptions,
      "multiple": false
    },
    model: {
      value: _vm.domain,
      callback: function ($$v) {
        _vm.domain = $$v;
      },
      expression: "domain"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Advertisers",
      "hide-selected": true,
      "multiple": true,
      "label": "label",
      "track-by": "id",
      "options": _vm.advertiserOptions ? _vm.advertiserOptions.data || [] : [],
      "loading": _vm.$apollo.queries.advertiserOptions.loading
    },
    model: {
      value: _vm.advertisers,
      callback: function ($$v) {
        _vm.advertisers = $$v;
      },
      expression: "advertisers"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Vertical",
      "type": "vertical",
      "enable-not-toggles": true,
      "close-on-select": true
    },
    model: {
      value: _vm.verticalTags,
      callback: function ($$v) {
        _vm.verticalTags = $$v;
      },
      expression: "verticalTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Mobile / Desktop",
      "enable-not-toggles": true,
      "close-on-select": true,
      "type": "mobileDesktop"
    },
    model: {
      value: _vm.mobileDesktopTags,
      callback: function ($$v) {
        _vm.mobileDesktopTags = $$v;
      },
      expression: "mobileDesktopTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Offer Type",
      "enable-not-toggles": true,
      "type": "offerType",
      "close-on-select": true
    },
    model: {
      value: _vm.offerTypeTags,
      callback: function ($$v) {
        _vm.offerTypeTags = $$v;
      },
      expression: "offerTypeTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Country Code",
      "enable-not-toggles": true,
      "type": "countryCode",
      "close-on-select": false,
      "allow-bulk-paste": true,
      "limit": 300
    },
    model: {
      value: _vm.countryCodeTags,
      callback: function ($$v) {
        _vm.countryCodeTags = $$v;
      },
      expression: "countryCodeTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Region Code",
      "enable-not-toggles": true,
      "close-on-select": false,
      "allow-bulk-paste": true,
      "limit": 10,
      "options-limit": 10,
      "perPage": 500,
      "elastic-options-field": "geo_region",
      "elastic-search": true,
      "type": "regionCode"
    },
    model: {
      value: _vm.regionCodeTags,
      callback: function ($$v) {
        _vm.regionCodeTags = $$v;
      },
      expression: "regionCodeTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Traffic Type",
      "enable-not-toggles": true,
      "type": "trafficType",
      "close-on-select": true
    },
    model: {
      value: _vm.trafficTypeTags,
      callback: function ($$v) {
        _vm.trafficTypeTags = $$v;
      },
      expression: "trafficTypeTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Browser",
      "enable-not-toggles": true,
      "close-on-select": false,
      "allow-bulk-paste": true,
      "limit": 10,
      "options-limit": 10,
      "perPage": 500,
      "elastic-options-field": "browser_family",
      "elastic-search": true,
      "elastic-normalizer": "lowercase",
      "type": "browser"
    },
    model: {
      value: _vm.browserTags,
      callback: function ($$v) {
        _vm.browserTags = $$v;
      },
      expression: "browserTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "OS",
      "enable-not-toggles": true,
      "close-on-select": false,
      "allow-bulk-paste": true,
      "limit": 10,
      "options-limit": 10,
      "perPage": 500,
      "elastic-options-field": "os",
      "elastic-search": true,
      "elastic-normalizer": "lowercase",
      "type": "os"
    },
    model: {
      value: _vm.osTags,
      callback: function ($$v) {
        _vm.osTags = $$v;
      },
      expression: "osTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Languages",
      "enable-not-toggles": true,
      "close-on-select": false,
      "allow-bulk-paste": true,
      "limit": 10,
      "options-limit": 10,
      "perPage": 500,
      "elastic-options-field": "language_short",
      "elastic-search": true,
      "type": "language"
    },
    model: {
      value: _vm.languageTags,
      callback: function ($$v) {
        _vm.languageTags = $$v;
      },
      expression: "languageTags"
    }
  })], 1) : _vm._e()], 1)]) : _c('div', [_c('div', {
    staticClass: "mb-4"
  }, [_c('p', {
    staticClass: "capitalize font-semibold mb-1"
  }, [_vm._v(" " + _vm._s(_vm.destinationType) + " - " + _vm._s(_vm.id) + " ")]), _c('p', {
    staticClass: "capitalize text-lg font-semibold leading-tight"
  }, [_vm._v(_vm._s(_vm.name))]), _c('div', {
    staticClass: "flex items-center gap-6"
  }, [_vm.generateRedirectLink && _vm.encodedId ? _c('div', {
    staticClass: "flex gap-1 items-center text-gray-800"
  }, [_vm._v(" " + _vm._s(_vm.encodedId) + " "), _c('a', {
    staticClass: "text-gray-800 text-xs",
    attrs: {
      "href": `${_vm.generateRedirectLink.url}/o/${_vm.encodedId}`,
      "target": "_blank"
    }
  }, [_c('Icon', {
    staticClass: "w-3 h-3",
    attrs: {
      "name": "openInNew"
    }
  })], 1)]) : _vm._e(), _vm.yleaderStatus == 'yLeader' ? _c('div', {
    staticClass: "flex items-center text-gray-800"
  }, [_c('Icon', {
    staticClass: "mr-1 w-3 h-3",
    attrs: {
      "name": "bullseye"
    }
  }), _c('p', [_vm._v("yLeader")])], 1) : _vm._e()])]), _c('div', [_vm.offer.id ? _c('Tabs', {
    staticClass: "col-span-3",
    attrs: {
      "show-type": "show"
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Details",
      "active": true
    }
  }, [_c('div', {
    staticClass: "grid grid-cols-7 gap-4 m-4"
  }, [_c('div', {
    staticClass: "flex flex-col col-span-2 gap-2 p-2"
  }, [_c('Superselect', {
    attrs: {
      "title": "Destination Type",
      "options": ['direct', 'template'],
      "multiple": false,
      "disabled": _vm.action === 'edit' || _vm.action === 'payouts'
    },
    model: {
      value: _vm.destinationType,
      callback: function ($$v) {
        _vm.destinationType = $$v;
      },
      expression: "destinationType"
    }
  }), _vm.destinationType == 'direct' ? _c('YInput', {
    attrs: {
      "label": "Direct URL"
    },
    model: {
      value: _vm.destination,
      callback: function ($$v) {
        _vm.destination = $$v;
      },
      expression: "destination"
    }
  }) : _vm._e(), _vm.destinationType == 'template' ? _c('Superselect', {
    key: "template-select",
    attrs: {
      "title": "Template",
      "options": [],
      "track-by": "path",
      "label": "path",
      "multiple": false,
      "query": _vm.TEMPLATES_QUERY,
      "query-variables": {}
    },
    on: {
      "select": _vm.updateDestination
    },
    model: {
      value: _vm.destinationTemplate,
      callback: function ($$v) {
        _vm.destinationTemplate = $$v;
      },
      expression: "destinationTemplate"
    }
  }) : _vm._e(), _vm.destinationType == 'template' ? _c('Superselect', {
    attrs: {
      "title": "Is Prelander?",
      "options": ['Yes', 'No'],
      "multiple": false
    },
    model: {
      value: _vm.isPrelander,
      callback: function ($$v) {
        _vm.isPrelander = $$v;
      },
      expression: "isPrelander"
    }
  }) : _vm._e(), _vm.destinationType == 'template' ? _c('Superselect', {
    attrs: {
      "title": "Remove Timeout?",
      "options": ['Yes', 'No'],
      "multiple": false
    },
    model: {
      value: _vm.ignoreTimeout,
      callback: function ($$v) {
        _vm.ignoreTimeout = $$v;
      },
      expression: "ignoreTimeout"
    }
  }) : _vm._e(), _vm.destinationType == 'template' ? _c('Superselect', {
    attrs: {
      "title": "Tracking Override",
      "options": ['Yes'],
      "multiple": false
    },
    model: {
      value: _vm.trackingOverride,
      callback: function ($$v) {
        _vm.trackingOverride = $$v;
      },
      expression: "trackingOverride"
    }
  }) : _vm._e(), _c('YInput', {
    staticClass: "mb-1",
    attrs: {
      "label": "Details"
    },
    model: {
      value: _vm.details,
      callback: function ($$v) {
        _vm.details = $$v;
      },
      expression: "details"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Domain",
      "label": "id",
      "track-by": "id",
      "options": _vm.domainOptions,
      "multiple": false
    },
    model: {
      value: _vm.domain,
      callback: function ($$v) {
        _vm.domain = $$v;
      },
      expression: "domain"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Advertisers",
      "hide-selected": true,
      "multiple": true,
      "label": "label",
      "track-by": "id",
      "disabled": _vm.destinationType == 'template',
      "options": _vm.advertiserOptions ? _vm.advertiserOptions.data || [] : [],
      "loading": _vm.$apollo.queries.advertiserOptions.loading
    },
    model: {
      value: _vm.advertisers,
      callback: function ($$v) {
        _vm.advertisers = $$v;
      },
      expression: "advertisers"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Vertical",
      "type": "vertical",
      "enable-not-toggles": true,
      "close-on-select": true
    },
    model: {
      value: _vm.verticalTags,
      callback: function ($$v) {
        _vm.verticalTags = $$v;
      },
      expression: "verticalTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Mobile / Desktop",
      "enable-not-toggles": true,
      "close-on-select": true,
      "type": "mobileDesktop"
    },
    model: {
      value: _vm.mobileDesktopTags,
      callback: function ($$v) {
        _vm.mobileDesktopTags = $$v;
      },
      expression: "mobileDesktopTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Offer Type",
      "enable-not-toggles": true,
      "type": "offerType",
      "close-on-select": true
    },
    model: {
      value: _vm.offerTypeTags,
      callback: function ($$v) {
        _vm.offerTypeTags = $$v;
      },
      expression: "offerTypeTags"
    }
  })], 1), _c('div', {
    staticClass: "flex flex-col col-span-2 gap-2 p-2"
  }, [_c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Country Code",
      "enable-not-toggles": true,
      "type": "countryCode",
      "close-on-select": false,
      "allow-bulk-paste": true,
      "limit": 12
    },
    model: {
      value: _vm.countryCodeTags,
      callback: function ($$v) {
        _vm.countryCodeTags = $$v;
      },
      expression: "countryCodeTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Region Code",
      "enable-not-toggles": true,
      "close-on-select": false,
      "allow-bulk-paste": true,
      "limit": 10,
      "options-limit": 10,
      "perPage": 500,
      "elastic-options-field": "geo_region",
      "elastic-search": true,
      "type": "regionCode"
    },
    model: {
      value: _vm.regionCodeTags,
      callback: function ($$v) {
        _vm.regionCodeTags = $$v;
      },
      expression: "regionCodeTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Traffic Type",
      "enable-not-toggles": true,
      "type": "trafficType",
      "close-on-select": true
    },
    model: {
      value: _vm.trafficTypeTags,
      callback: function ($$v) {
        _vm.trafficTypeTags = $$v;
      },
      expression: "trafficTypeTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Browser",
      "enable-not-toggles": true,
      "close-on-select": false,
      "allow-bulk-paste": true,
      "limit": 10,
      "options-limit": 10,
      "perPage": 500,
      "elastic-options-field": "browser_family",
      "elastic-search": true,
      "elastic-normalizer": "lowercase",
      "type": "browser"
    },
    model: {
      value: _vm.browserTags,
      callback: function ($$v) {
        _vm.browserTags = $$v;
      },
      expression: "browserTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "OS",
      "enable-not-toggles": true,
      "close-on-select": false,
      "allow-bulk-paste": true,
      "limit": 10,
      "options-limit": 10,
      "perPage": 500,
      "elastic-options-field": "os",
      "elastic-search": true,
      "elastic-normalizer": "lowercase",
      "type": "os"
    },
    model: {
      value: _vm.osTags,
      callback: function ($$v) {
        _vm.osTags = $$v;
      },
      expression: "osTags"
    }
  }), _c('TagsByTypeSelect', {
    staticClass: "col-span-2 mb-1",
    attrs: {
      "title": "Languages",
      "enable-not-toggles": true,
      "close-on-select": false,
      "allow-bulk-paste": true,
      "limit": 10,
      "options-limit": 10,
      "perPage": 500,
      "elastic-options-field": "language_short",
      "elastic-search": true,
      "type": "language"
    },
    model: {
      value: _vm.languageTags,
      callback: function ($$v) {
        _vm.languageTags = $$v;
      },
      expression: "languageTags"
    }
  }), _c('div', {
    staticClass: "flex flex-col gap-2 p-2"
  }, [_c('label', {
    staticClass: "text-indigo-600 text-xs"
  }, [_vm._v("Image")]), _vm.offer.id ? _c('EditableImage', {
    staticClass: "rounded mx-auto",
    attrs: {
      "image-url": _vm.offer.imageUrl,
      "height": 40,
      "width": 40,
      "model-class": "Offer",
      "model-id": _vm.offer.id,
      "delete-existing": true
    },
    on: {
      "modelUpdated": _vm.updateImage
    }
  }) : _vm._e()], 1)], 1), _vm.destinationType == 'direct' ? _c('div', {
    staticClass: "flex flex-col gap-2 p-2"
  }) : _vm._e(), _vm.destinationType === 'template' ? _c('div', {
    class: {
      'col-span-3': _vm.destinationType === 'template'
    }
  }, [_c('TemplatePreview', {
    attrs: {
      "key-value-pairs": _vm.vars,
      "path": _vm.destination,
      "auto-refresh": true
    }
  })], 1) : _vm._e(), _vm.destinationType == 'direct' ? _c('div', {
    staticClass: "col-span-2 bg-gray-100 border rounded-md flex flex-col sidebar-right"
  }, [_c('SideBarRightItem', {
    attrs: {
      "title": "Comments",
      "icon": "message"
    }
  }, [_c('SideBarComments', {
    attrs: {
      "comments": _vm.offer.comments,
      "modelId": _vm.offer.id,
      "modelClass": "Offer"
    }
  })], 1)], 1) : _vm._e()])]), _vm.destinationType == 'direct' ? _c('Tab', {
    attrs: {
      "name": "Attributes"
    }
  }, [_c('OfferMetaAttributes', {
    key: _vm.editOffer.id,
    attrs: {
      "offerMeta": _vm.editOffer.offerMeta,
      "offerTranslations": _vm.editOffer.metaTranslations,
      "offerId": _vm.editOffer.id,
      "translatableLanguages": _vm.translatableLanguages
    }
  })], 1) : _vm._e(), _vm.offer.template ? _c('Tab', {
    attrs: {
      "name": "Properties"
    }
  }, [_c('div', {
    staticClass: "my-2 ml-4"
  }, [_c('Tabs', [_c('Tab', {
    attrs: {
      "name": "Offer Keys",
      "active": true
    }
  }, [_c('div', {
    staticClass: "grid grid-cols-3 gap-4"
  }, [_c('TemplateVars', {
    staticClass: "mt-4",
    attrs: {
      "definitions": _vm.offer.template.varDefinitions,
      "initial-inputs": _vm.offer.varInputs,
      "linked-vars": (_vm$offer$template$li = _vm.offer.template.linkedVars) !== null && _vm$offer$template$li !== void 0 ? _vm$offer$template$li : {},
      "var-endings": (_vm$offer$varsConfig$ = _vm.offer.varsConfig.endings) !== null && _vm$offer$varsConfig$ !== void 0 ? _vm$offer$varsConfig$ : []
    },
    on: {
      "queryStringUpdated": function () {},
      "updated": _vm.updateVars
    }
  }), _c('div', {
    staticClass: "bg-gray-100 col-span-2"
  }, [_c('TemplatePreview', {
    attrs: {
      "key-value-pairs": _vm.vars,
      "path": _vm.destination,
      "auto-refresh": true,
      "scaleWindow": 0.7
    }
  })], 1)], 1)]), _c('Tab', {
    attrs: {
      "name": "Available Translations",
      "disabled": _vm.editOffer.varsTranslations.length == 0
    }
  }, [_c('OfferTemplateTranslations', {
    attrs: {
      "offerTranslations": _vm.editOffer.varsTranslations,
      "offerId": _vm.editOffer.id,
      "selectedTranslatableLanguages": _vm.selectedTranslatableLanguages.slice().sort(),
      "offerVars": _vm.offer.vars,
      "definitions": _vm.offer.template.varDefinitions
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Languages"
    }
  }, [_c('div', {
    staticClass: "mt-4 flex flex-col gap-3",
    staticStyle: {
      "width": "28rem"
    }
  }, [_vm.isSubmitting ? _c('YButton', {
    attrs: {
      "disabled": "",
      "color": "blue"
    }
  }, [_c('Spinner', {
    attrs: {
      "color": "white",
      "size": 5
    }
  })], 1) : _vm._e(), !_vm.isSubmitting ? _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.updateOrCreateTranslations
    }
  }, [_vm._v("Create or Update Translations")]) : _vm._e(), _c('Superselect', {
    attrs: {
      "title": "Available Languages",
      "placeholder": "Select Languages",
      "options": _vm.translatableLanguages,
      "custom-label": _vm.customLabelLanguages,
      "track-by": "code",
      "multiple": true,
      "closeOnSelect": false
    },
    model: {
      value: _vm.localSelectedTranslatableLanguages,
      callback: function ($$v) {
        _vm.localSelectedTranslatableLanguages = $$v;
      },
      expression: "localSelectedTranslatableLanguages"
    }
  }), _c('div', {
    staticClass: "w-20"
  }, [_c('YButton', {
    attrs: {
      "color": "link"
    },
    on: {
      "click": function ($event) {
        _vm.localSelectedTranslatableLanguages = [].concat(_vm.translatableLanguages);
      }
    }
  }, [_vm._v("Select All")])], 1)], 1)]), _c('Tab', {
    attrs: {
      "name": "VarConfig"
    }
  }, [_c('div', {
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_c('OfferVarConfiguration', {
    attrs: {
      "offer": _vm.offer
    },
    on: {
      "updated": _vm.updateOfferVarsConfig
    }
  })], 1)])], 1)], 1)]) : _vm._e(), _vm.destinationType == 'direct' ? _c('Tab', {
    attrs: {
      "name": "Payouts"
    }
  }, [_vm.offer ? _c('OfferRevenue', {
    staticClass: "mt-4",
    attrs: {
      "offer": _vm.offer
    }
  }) : _vm._e()], 1) : _vm._e(), this.setYleaderStatus() == true ? _c('Tab', {
    attrs: {
      "name": "yLeader Estimates"
    }
  }, [_vm.offer ? _c('OfferYleaderEstimatedRevenues', {
    staticClass: "mt-4",
    attrs: {
      "offer": _vm.offer
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.destinationType == 'direct' ? _c('Tab', {
    attrs: {
      "name": "Caps"
    }
  }, [_c('Caps', {
    attrs: {
      "cappableId": _vm.offer.id,
      "cappableType": "Offer"
    }
  })], 1) : _vm._e(), _c('Tab', {
    attrs: {
      "name": "Daypart"
    }
  }, [_c('Dayparting', {
    attrs: {
      "daypartable-id": _vm.offer.id,
      "daypartable-type": "Offer"
    }
  })], 1), _vm.destinationType ? _c('Tab', {
    attrs: {
      "name": _vm.ruleTotalTabName
    }
  }, [_c('OfferPerspectiveRulesTableWrapper', {
    attrs: {
      "default-filter-values": {
        offers: [_vm.offer]
      },
      "hide-all-offer-filters": true,
      "defaults-for-create": {
        filterType: {
          name: 'offer',
          label: 'Offer'
        },
        filterables: [_vm.offer]
      }
    },
    on: {
      "totalRulesTab": _vm.totalRulesTab
    }
  })], 1) : _vm._e(), _vm.destinationType ? _c('Tab', {
    attrs: {
      "name": "Traffic"
    }
  }, [_c('OfferTraffic', {
    attrs: {
      "offer": _vm.offer
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)]), _c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_vm.requireRetraffic ? _c('YButton', {
    staticClass: "mr-1",
    attrs: {
      "color": "blue",
      "disabled": !this.hasChanges
    },
    on: {
      "click": _vm.saveAndTraffic
    }
  }, [_vm._v("Save & Retraffic ")]) : _c('YButton', {
    staticClass: "mr-1",
    attrs: {
      "color": "blue",
      "disabled": !this.hasChanges
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save ")]), _c('v-popover', {
    attrs: {
      "offset": "0"
    }
  }, [_c('YButton', {
    attrs: {
      "color": "link"
    }
  }, [_c('Icon', {
    attrs: {
      "name": "dotsVertical"
    }
  })], 1), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_c('YButton', {
    attrs: {
      "color": "link "
    },
    on: {
      "click": function ($event) {
        return _vm.openOfferCloneModal();
      }
    }
  }, [_vm._v("Clone")])], 1)])], 2)], 1)]), _c('portal', {
    attrs: {
      "to": "top-bar-left-header"
    }
  }, [_vm._v(" " + _vm._s(_vm.pageTitle) + " "), _vm.hasChanges ? _c('Tag', {
    staticClass: "ml-2",
    attrs: {
      "color": "pink"
    }
  }, [_vm._v("Unsaved Changes")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }