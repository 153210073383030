<template>
  <Widget class="bg-red-600 border-red-700 text-white">
    <WidgetHeader
      class="tiny-forms pt-4 pb-4"
      :closeable="true"
      @close="$emit('close')"
    >
      <template slot="rawContent">
        <div class="flex justify-between items-center w-full">
          <div class="">
            <i class="zmdi zmdi-file-plus pr-2"></i>
            Delete Provider - {{ this.provider.name }}
          </div>
        </div>
      </template>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <template v-if="providerHasRecords">
        <h5 class="font-bold" style="color: #ffc107 !important;">
          ** PROVIDER CAN NOT BE DELETED **
        </h5>
        <p>
          This provider has records associated with it and cannot be deleted.<br />
          Please delete these records first.
        </p>
      </template>
      <template v-else>
        <h5>
          <strong style="color: #ffc107 !important;"
            >Are you sure? This action cannot be undone</strong
          >
        </h5>
      </template>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 px-4 bg-red-700 text-white flex justify-between items-center"
    >
      <y-checkbox
        v-model="closeOnComplete"
        label="Close on Complete"
        type="primary"
        class="flex items-center"
      ></y-checkbox>
      <YButton
        :is-loading="isSubmitting"
        class="bg-red-800 hover:bg-red-900 text-white"
        :disabled="!ydnsProviders || providerHasRecords"
        @click="deleteItems([provider.id])"
        @keydown.enter="deleteItems([provider.id])"
      >
        Delete
      </YButton>
    </WidgetFooter>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'

export default {
  props: {
    provider: {
      required: true
    }
  },
  components: {},
  apollo: {
    ydnsProviders: {
      query: gql`
        query getProviders($filters: YdnsProviderFilterInput) {
          ydnsProviders(filters: $filters) {
            id
          }
        }
      `,
      variables() {
        return {
          filters: {
            id: { value: [this.provider.id] },
            hasRecords: true
          }
        }
      },
      deep: true
    }
  },
  data() {
    return {
      closeOnComplete: true,
      isSubmitting: false,
      typeOptions: [],
      validationErrors: {}
    }
  },
  watch: {},
  computed: {
    providerHasRecords() {
      return this.ydnsProviders && this.ydnsProviders.length > 0
    }
  },
  methods: {
    deleteItems(providerIds) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteProviders($id: [ID!]!) {
              ydnsDeleteProviders(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: providerIds
          }
        })
        .then(result => {
          this.$toastr.s(`Providers Deleted`, 'Success!')
          this.isSubmitting = false
          this.$events.emit('refreshYdnsProviders', true)
          this.$emit('deleted', result.data.ydnsDeleteProviders)
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
