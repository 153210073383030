var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('YTable', {
    attrs: {
      "data": _vm.rules,
      "bottom-loading": _vm.bottomLoading,
      "selectable": true,
      "selected": _vm.selectedRowIds
    },
    on: {
      "update:selected": function ($event) {
        _vm.selectedRowIds = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', {
          class: props.rowData.ruleablePolarity == true ? '' : 'blocked-column'
        }, [_c('span', {
          staticClass: "uppercase text-xs font-semibold text-gray-600"
        }, [_vm._v(" " + _vm._s(props.rowData.ruleableType) + " ")]), props.rowData.ruleableType == 'redirect' ? _c('div', [props.rowData.ruleable ? [_c('div', [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.ruleableId
              }
            }
          }
        }, [[_vm._v(_vm._s(props.rowData.ruleable.label))]], 2)], 1), _c('span', {
          staticClass: "italic text-xs text-gray-500"
        }, [_vm._v(_vm._s(props.rowData.ruleable.user.label))])] : [_c('i', {
          staticClass: "text-gray-500"
        }, [_vm._v("No record found for ID " + _vm._s(props.rowData.ruleableId))])]], 2) : _vm._e(), props.rowData.ruleableType == 'campaign' ? _c('div', [props.rowData.ruleable ? _c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Campaign',
              params: {
                id: props.rowData.ruleableId
              }
            }
          }
        }, [[_vm._v(_vm._s(props.rowData.ruleable.label))]], 2) : [_c('i', {
          staticClass: "text-gray-500"
        }, [_vm._v("No record found for ID " + _vm._s(props.rowData.ruleableId))])]], 2) : _vm._e(), props.rowData.ruleableType == 'publisher' ? _c('div', [props.rowData.ruleable ? _c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.ruleableId
              }
            }
          }
        }, [[_vm._v(_vm._s(props.rowData.ruleable.label))]], 2) : [_c('i', {
          staticClass: "text-gray-500"
        }, [_vm._v("No record found for ID " + _vm._s(props.rowData.ruleableId))])]], 2) : _vm._e(), props.rowData.ruleableType == 'source' ? _c('div', [props.rowData.ruleableId ? _c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Source',
              params: {
                id: props.rowData.ruleableId
              }
            }
          }
        }, [[_vm._v(_vm._s(props.rowData.ruleableId))]], 2) : _vm._e()], 1) : _vm._e()]), _c('td', [_c('div', {
          staticClass: "w-40"
        }, [_vm._l(props.rowData.tagsByType.filter(function (tagGroup) {
          return tagGroup.type == 'countryCode';
        }), function (tagGroup) {
          return [_vm._l(tagGroup.tags, function (tag) {
            return [_c('Tag', {
              key: tag.id,
              staticClass: "m-1",
              attrs: {
                "color": tag.isNot ? 'red' : 'green'
              }
            }, [_vm._v(_vm._s(tag.name))])];
          })];
        })], 2)]), _c('td', [_vm._l(props.rowData.tagsByType.filter(function (tagGroup) {
          return tagGroup.type == 'mobileDesktop';
        }), function (tagGroup) {
          return [_vm._l(tagGroup.tags, function (tag) {
            return [_c('Tag', {
              key: tag.id,
              staticClass: "m-1",
              attrs: {
                "color": tag.isNot ? 'red' : 'blue'
              }
            }, [_vm._v(_vm._s(tag.name))])];
          })];
        })], 2), _c('td', {
          class: !props.rowData.filterablePolarity ? 'filter-blocked' : ''
        }, [_c('span', {
          staticClass: "uppercase text-xs font-semibold text-gray-600"
        }, [props.rowData.filterableType == 'tag' && props.rowData.filterable ? [_vm._v(" " + _vm._s(props.rowData.filterable.type) + " ")] : [_vm._v(" " + _vm._s(props.rowData.filterableType) + " ")]], 2), props.rowData.filterableType == 'offer' ? _c('div', [props.rowData.filterable ? _c('router-link', {
          staticClass: "link-wrap",
          attrs: {
            "to": {
              name: 'Offer Edit',
              params: {
                id: props.rowData.filterableId
              }
            }
          }
        }, [[_vm._v(_vm._s(props.rowData.filterable.label))]], 2) : [_c('i', {
          staticClass: "text-gray-500"
        }, [_vm._v("No record found for ID " + _vm._s(props.rowData.filterableId))])]], 2) : _vm._e(), props.rowData.filterableType == 'advertiser' ? _c('div', [props.rowData.filterable ? _c('router-link', {
          staticClass: "link-wrap",
          attrs: {
            "to": {
              name: 'Advertiser',
              params: {
                id: props.rowData.filterableId
              }
            }
          }
        }, [[_vm._v(_vm._s(props.rowData.filterable.label))]], 2) : [_c('i', {
          staticClass: "text-gray-500"
        }, [_vm._v("No record found for ID " + _vm._s(props.rowData.filterableId))])]], 2) : _vm._e(), props.rowData.filterableType == 'advertiserAccount' ? _c('div', [props.rowData.filterable ? _c('router-link', {
          staticClass: "link-wrap",
          attrs: {
            "to": {
              name: 'Advertiser',
              params: {
                id: props.rowData.filterable.userId
              }
            }
          }
        }, [[_vm._v(_vm._s(props.rowData.filterable.label))]], 2) : [_c('i', {
          staticClass: "text-gray-500"
        }, [_vm._v("No record found for ID " + _vm._s(props.rowData.filterableId))])]], 2) : _vm._e(), props.rowData.filterableType == 'template' ? _c('div', [props.rowData.filterable ? _c('div', {
          class: props.rowData.filterablePolarity ? 'text-blue-600' : 'text-red-600'
        }, [_vm._v(" " + _vm._s(props.rowData.filterable.path) + " ")]) : [_c('i', {
          staticClass: "text-gray-500"
        }, [_vm._v("No record found for ID " + _vm._s(props.rowData.filterableId))])]], 2) : _vm._e(), props.rowData.filterableType == 'tag' ? _c('div', [props.rowData.filterable ? [_c('Tag', {
          attrs: {
            "color": props.rowData.filterablePolarity ? 'blue' : 'red'
          }
        }, [_vm._v(_vm._s(props.rowData.filterable.name))])] : [_c('i', {
          staticClass: "text-gray-500"
        }, [_vm._v("No record found for Tag ID " + _vm._s(props.rowData.filterableId))])]], 2) : _vm._e()]), _c('td', {
          staticStyle: {
            "width": "4rem"
          }
        }, [_c('div', {
          staticClass: "flex"
        }, [_c('v-popover', {
          staticClass: "p-0 flex items-center mr-2",
          attrs: {
            "placement": "right"
          }
        }, [_c('a', {
          staticClass: "tooltip-target inline-block text-gray-400 hover:text-gray-500 mt-1"
        }, [_c('Icon', {
          attrs: {
            "name": "history"
          }
        })], 1), _c('template', {
          slot: "popover"
        }, [props.rowData.createdBy ? _c('div', {
          staticClass: "flex flex-col p-2 text-xs"
        }, [_c('span', {
          staticClass: "font-bold"
        }, [_vm._v(" " + _vm._s(_vm._f("luxon")(props.rowData.createdAt, {
          output: {
            format: 'yyyy-MM-dd @ h:mm a',
            zone: 'local'
          }
        })) + " ")]), _c('span', {
          staticClass: "text-gray-500 italic"
        }, [_vm._v(" " + _vm._s(props.rowData.createdBy.email) + " ")])]) : _c('div', {
          staticClass: "text-gray-400 p-2"
        }, [_vm._v("No History")])])], 2), _c('v-popover', {
          staticClass: "p-0",
          attrs: {
            "offset": "2",
            "placement": "right"
          }
        }, [_c('YButton', {
          staticClass: "hover:bg-gray-100 focus:bg-gray-100 outline-none-important text-gray-500 hover:text-blue-600"
        }, [_c('Icon', {
          staticClass: "w-3 h-3",
          attrs: {
            "name": "dotsVertical"
          }
        })], 1), _c('template', {
          slot: "popover"
        }, [_c('ul', [_c('li', [_c('ClickAndReplace', {
          attrs: {
            "duration": 5000
          },
          scopedSlots: _vm._u([{
            key: "replacement",
            fn: function () {
              return [_c('YButton', {
                staticClass: "bg-red-500 text-white hover:bg-red-600",
                attrs: {
                  "is-loading": _vm.isDeleting
                },
                on: {
                  "click": function ($event) {
                    return _vm.deleteRule(props.rowData.id);
                  }
                }
              }, [_vm._v("Confirm Delete")])];
            },
            proxy: true
          }], null, true)
        }, [_c('YButton', {
          staticClass: "text-red-500 hover:bg-red-100"
        }, [_vm._v("Delete")])], 1)], 1)])])], 2)], 1)])];
      }
    }])
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Type")]), _c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("Traffic Conditions")]), _c('th', [_vm._v("Offer Filters")]), _c('th')]), _c('template', {
    slot: "secondHeaderRow"
  }, [_c('th'), _c('th', [_c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v("Countries")])]), _c('th', [_c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v("Mobile / Desktop")])]), _c('th', [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "flex items-center mr-5"
  }, [_c('div', {
    staticClass: "rounded-full w-2 h-2 bg-red-600 mr-2 shadow-md"
  }), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v("Blocked")])]), _c('div', {
    staticClass: "flex items-center"
  }, [_c('div', {
    staticClass: "rounded-full w-2 h-2 bg-blue-600 mr-2 shadow-md"
  }), _c('span', {
    staticClass: "text-gray-500"
  }, [_vm._v("Only")])])])]), _c('th')])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }