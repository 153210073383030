var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "bg-red-600 text-white border-red-700"
  }, [_c('WidgetHeader', {
    staticClass: "bg-red-600 text-white"
  }, [_c('span', {
    staticClass: "text-white"
  }, [_vm._v("Are you sure?")])]), _c('WidgetBody', [_c('h5', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v("This action cannot be undone.")]), _c('h5', [_vm._v(" Please confirm you'd like to "), _c('span', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v("DELETE")]), _vm._v(" the below Domains: ")]), _c('ul', {
    staticClass: "text-xs mt-4 mb-3"
  }, _vm._l(_vm.selections, function (selection) {
    return _c('li', {
      key: selection.id
    }, [_vm._v(" " + _vm._s(selection.id) + " ")]);
  }), 0)]), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 flex justify-between py-2 px-4"
  }, [_c('YButton', {
    staticClass: "hover:bg-red-700 focus:bg-red-700",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    staticClass: "hover:bg-red-700 focus:bg-red-700",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": function ($event) {
        return _vm.deleteDomains(_vm.selections);
      }
    }
  }, [_vm._v("Delete Domains")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }