<template>
<div>
     <div class="w-100">
       <YTable
        v-if="subdomainHistory"
        :data="subdomainHistory.data || []"
        :loading="$apolloData.queries.subdomainHistory.loading"
      >
      <template slot="header-row">
          <th>Subdomain</th>
          <th>Replaced</th>
      </template>
      <template slot="row" slot-scope="props">
        <td>{{ props.rowData.subdomain }}</td>
        <td>
          <span class="text-green-400" v-if="props.rowData.replaced == null">Acitve</span>
          <span class="text-red-400" v-else>
            {{props.rowData.replaced }}
          </span>
        </td>
        
      </template>
      </YTable>  
       </div>
</div>

</template>

<script>

import SUBDOMAIN_HISTORY_QUERY from '@/graphql/SubdomainHistory/SubdomainHistorysQuery.gql'
import HasFetchMorePaginationMixin from '@/mixins/HasFetchMorePaginationMixin'

export default {
  mixins: [HasFetchMorePaginationMixin],
  props: {
    redirect: {
      type: Object,
      required: true
    },
  },
  components: {},
  apollo: {
    subdomainHistory: {
      query: SUBDOMAIN_HISTORY_QUERY,
      variables() {
        return {
          filters: {
           redirect: { "value": this.redirect.id,
                        modifiers: {
                            matchType: 'equals'
                        },
                    }
          },
          first: this.perPage,
          page: 1,
          orderBy: this.orderBy
        }
      },
      result({data}, key){
        this.showMoreEnabled = data[key].paginatorInfo.hasMorePages
      },
      fetchPolicy: 'network-only',
      deep: true
    },
  },
  data() {
      return{
        perPage: 200,
        loadMoreIncrement: 20,
        filterDisplayMode: 1,
        selected: [],
        orderBy:[{"column": "id","order": "DESC"}],
        filters: {},
        options: {},
        queries: {}
      }
     
  },
  mounted(){
    this.$events.listen('refreshSubdomainHistory', () => {
      this.$apollo.queries.subdomainHistory.refetch()
    })
  },
  computed: {},
  methods: {}
}
</script>

<style>
.ydns-filter-radios {
  margin-bottom: -1rem;
}
</style>
