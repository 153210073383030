import { saveAs } from 'file-saver'

export const objectToCsv = object => {
  const items = object
  const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
  const header = Object.keys(items[0])
  let csv = items.map(row =>
    header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(',')
  )
  csv.unshift(header.join(','))
  csv = csv.join('\r\n')
  return csv
}
export const saveCsvString = (csvString, fileName = 'default.csv') => {
  let blob = new Blob([csvString], { type: 'text/csv' })
  if (!fileName.endsWith('.csv')) {
    const newFileName = fileName.concat('', '.csv')
    saveAs(blob, newFileName)
  } else {
    saveAs(blob, fileName)
  }
}
