<template>
  <widget>
    <widget-header class="background-gradient-green-blue text-white tiny-forms">
      <i class="zmdi zmdi-file-plus pr-2"></i> Delete Alf Listing
      <h4 slot="right">
        <close-x @click="$emit('close')" class="text-white"></close-x>
      </h4>
    </widget-header>
    <widget-body>
      <form @submit.prevent="update" ref="form" class="x-form">
        <YInput :readonly="true"  v-if="alfException.user" v-model="alfException.user.label" label="User"></YInput>
        <YInput :readonly="true"  v-if="alfException.subdomain" v-model="alfException.subdomain" label="Subdomain"></YInput>
      </form>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <y-button
        class="btn btn-red"
        :is-loading="isSubmitting"
        @click="remove"
        @keydown.enter="remove"
        >Delete</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],

  props: {
    alfException: {
      type: Object,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      isSubmitting: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    remove() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteAlfException($id: ID!) {
                deleteAlfException(id: $id) {
                    id
                }
            }
          `,
          variables: {
            id: this.alfException.id
          }
        })
        .then(result => {
          console.log(result)
          this.isSubmitting = false
          this.clearValidationErrors()
          this.$events.emit('refreshAlfExceptions', true)
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.x-form > *:not(:last-child) {
  @apply mb-2;
}
</style>
