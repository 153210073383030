var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "slide-height"
  }, [_c('agile', {
    ref: "carousel",
    attrs: {
      "navButtons": false,
      "dots": false
    },
    on: {
      "after-change": function ($event) {
        return _vm.showCurrentSlide($event);
      }
    }
  }, [_c('div', {
    staticClass: "slide"
  }, [_c('div', {
    staticClass: "slide-container"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/publisher/marketingScene.svg")
    }
  }), _c('span', {
    staticClass: "slide-headline"
  }, [_vm._v("Welcome to yTrack!")]), _c('div', {
    staticClass: "slide-text"
  }, [_vm._v(" The yTrack app makes it nice and easy to make more money from your valuable traffic. Take a quick tour to see where useful features are so you can be up and running in no time! ")])])]), _c('div', {
    staticClass: "slide"
  }, [_c('div', {
    staticClass: "slide-container"
  }, [_c('img', {
    staticClass: "m-auto",
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "src": require("@/assets/images/publisher/campaigns.gif")
    }
  }), _c('span', {
    staticClass: "slide-headline"
  }, [_vm._v("Finding Campaigns")]), _c('div', {
    staticClass: "slide-text"
  }, [_vm._v(" To find the right campaign for your traffic, browse our campaign section with the ability to filter by vertical, country and more. ")])])]), _c('div', {
    staticClass: "slide"
  }, [_c('div', {
    staticClass: "slide-container"
  }, [_c('img', {
    staticClass: "m-auto",
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "src": require("@/assets/images/publisher/postback.gif")
    }
  }), _c('span', {
    staticClass: "slide-headline"
  }, [_vm._v("Add a Postback")]), _c('div', {
    staticClass: "slide-text"
  }, [_vm._v(" Once you've found a campaign, make sure to add your postback link so that you can track your conversions. A global postback link will fire conversions for "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("all")]), _vm._v(" campaigns. "), _c('br'), _c('span', {
    staticClass: "leading-9"
  }, [_vm._v("Available parameters are: {S1} {S2} {S3} {S4} {S5} {REVENUE}.")]), _c('br'), _vm._v(" Sources should be passed through the KW field in your offer links. ")])])]), _c('div', {
    staticClass: "slide"
  }, [_c('div', {
    staticClass: "slide-container"
  }, [_c('img', {
    staticClass: "m-auto",
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "src": require("@/assets/images/publisher/finance.gif")
    }
  }), _c('span', {
    staticClass: "slide-headline"
  }, [_vm._v("Set Up Payments")]), _c('div', {
    staticClass: "slide-text"
  }, [_vm._v(" Our default payment terms are semi-monthly net 15. "), _c('br'), _c('span', {
    staticClass: "leading-9"
  }, [_vm._v("Fill out your payment information as soon as possible to avoid any payment delays. ")]), _c('br'), _vm._v("In this section you will find available payment methods and minimum revenue thresholds. ")])])]), _c('div', {
    staticClass: "slide"
  }, [_c('div', {
    staticClass: "slide-container"
  }, [_c('svg', {
    staticClass: "checkmark m-auto mb-5",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 52 52"
    }
  }, [_c('circle', {
    class: {
      checkmark__circle: _vm.showFinalSlide
    },
    attrs: {
      "cx": "26",
      "cy": "26",
      "r": "25",
      "fill": "none"
    }
  }), _c('path', {
    class: {
      checkmark__check: _vm.showFinalSlide
    },
    attrs: {
      "fill": "none",
      "d": "M14.1 27.2l7.1 7.2 16.7-16.8"
    }
  })]), _c('span', {
    staticClass: "slide-headline"
  }, [_vm._v("You're all set!")]), _c('div', {
    staticClass: "slide-text"
  }, [_vm._v(" We've shown you the basics. You can find these tips any time in the "), _c('span', {
    staticClass: "font-bold"
  }, [_vm._v("yDocs")]), _vm._v(" section. ")]), _vm.user ? _c('YButton', {
    staticClass: "mt-10 w-3/4 m-auto text-lg",
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.tutorialFinish
    }
  }, [_vm._v("Got it ")]) : _vm._e(), !_vm.user ? _c('router-link', {
    attrs: {
      "to": {
        name: 'Publisher Dashboard'
      }
    }
  }, [_c('YButton', {
    staticClass: "mt-10 w-3/4 m-auto text-lg",
    attrs: {
      "color": "blue"
    }
  }, [_vm._v("Got it ")])], 1) : _vm._e()], 1)])]), !_vm.showFinalSlide ? _c('div', {
    staticClass: "flex justify-between items-end w-100 bg-white px-12 pt-3 pb-8"
  }, [_c('YButton', {
    staticClass: "w-32 text-lg",
    attrs: {
      "color": "link",
      "disabled": _vm.disablePreviewBtn
    },
    on: {
      "click": _vm.previousBtn
    }
  }, [_vm._v("Previous")]), _vm.user ? _c('button', {
    staticClass: "text-blue-600 cursor-pointer underline text-xs",
    on: {
      "click": _vm.tutorialFinish
    }
  }, [_vm._v(" Skip Tutorial ")]) : _vm._e(), _c('YButton', {
    staticClass: "w-32 text-lg",
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.nextBtn
    }
  }, [_vm._v("Next")])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }