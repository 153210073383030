var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col w-8/12 xl:w-4/12 m-auto justify-center text-sm bg-white px-4 pt-12 pb-16 rounded-lg border border-gray-100 shadow-xl text-center relative"
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_c('div', {
    staticClass: "text-3xl font-bold mb-1",
    class: {
      'text-red-800': _vm.loadingMessage.status == 'error'
    }
  }, [_vm._v(" " + _vm._s(_vm.loadingMessage.title) + " ")]), _c('div', {
    staticClass: "text-lg mb-8 text-gray-950"
  }, [_c('p', {
    staticClass: "mb-2 font-light text-gray-900",
    class: {
      'text-red-700': _vm.loadingMessage.status == 'error'
    }
  }, [_vm._v(" " + _vm._s(_vm.loadingMessage.headline) + " ")]), _c('p', [_vm._v(_vm._s(_vm.loadingMessage.text))])])]), _c('div', {
    staticClass: "mx-auto w-full relative",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_vm.loadingMessage.loginInput ? _c('div', {
    staticClass: "border-t",
    class: {
      'fade-in': _vm.loadingMessage.loginInput
    }
  }, [_c('Login')], 1) : _vm._e(), !_vm.loadingMessage.loginInput ? _c('div', {
    staticClass: "w-full w-full border rounded-lg p-5"
  }, [_vm._m(0)]) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col gap-3"
  }, [_c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "skeleton-input is-loading"
  }), _c('div', {
    staticClass: "w-32 h-4 rounded is-loading"
  }), _c('div', {
    staticClass: "skeleton-input is-loading mt-6"
  }), _c('div', {
    staticClass: "flex justify-center border-t border-gray-500 pt-4 mt-3"
  }), _c('div', {
    staticClass: "mx-auto w-9/12 h-4 rounded is-loading"
  })]);

}]

export { render, staticRenderFns }