<template>
  <div id="top">
    <div>
      <portal to="top-bar-left-header">
        <div class="flex">
          <router-link
            :to="{
              name: 'Onboarding'
            }"
          >
            <YButton color="pink"> Quick Onboarding</YButton>
          </router-link>
        </div>
      </portal>
    </div>

    <Widget class="mb-2">
      <WidgetHeader class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <LeadFilters></LeadFilters>
        </template>
      </WidgetHeader>
    </Widget>

    <YTable
      v-if="leads"
      :data="leads.data || []"
      :bottom-loading="$apolloData.queries.leads.loading"
      :row-classes="
        row => (row.userDetails.followUpDue ? ['follow-up-row'] : [])
      "
    >
      <template slot="header-row">
        <th></th>
        <th>lead</th>
        <th></th>
        <th>manager</th>
        <th>
          <OrderByItem
            column="created_at"
            order="ASC"
            v-model="orderBy"
            class="text-right"
            >Created</OrderByItem
          >
        </th>
        <th>traffic</th>
        <th>verticals</th>
        <th>Last contact</th>
        <th>
          <OrderByItem
            column="updated_at"
            order="ASC"
            v-model="orderBy"
            class="flex justify-end"
            >updated</OrderByItem
          >
        </th>
      </template>
      <template slot="row" slot-scope="props">
        <td style="padding-right: 0; width: 20px;">
          <span v-if="props.rowData.userDetails.followUpDue">
            <Icon
              name="exclamationThick"
              class="text-pink-500 rounded m-1"
              :size="4"
              v-tooltip="'Follow Up Due'"
            />
          </span>
        </td>
        <td style="padding-left: 0.5rem;">
          <div class="flex flex-row gap-1">
            <div class="flex flex-col flex-grow">
              <div>
                <router-link
                  :to="{
                    name: 'Lead',
                    params: { id: props.rowData.id }
                  }"
                  class="link block"
                >
                  {{
                    props.rowData.primaryContact.company
                      ? props.rowData.primaryContact.company
                      : props.rowData.name
                  }}</router-link
                >
              </div>
              <div
                class="flex flex-row items-center text-xs text-gray-600 block"
              >
                {{ props.rowData.id }}
                <span v-if="props.rowData.email">
                  - {{ props.rowData.email }}
                </span>
              </div>
              <div
                v-if="props.rowData.primaryContact.messenger"
                class="flex items-center text-xs"
              >
                <Icon
                  :name="
                    JSON.parse(
                      props.rowData.primaryContact.messenger.toLowerCase()
                    ).app
                  "
                  class="w-4 h-4 text-gray-600 mr-1"
                />
                <span class="text-gray-600">
                  {{
                    JSON.parse(props.rowData.primaryContact.messenger).handle
                  }}
                </span>
              </div>
            </div>
            <div class="flex items-center">
              <button
                @click="openPriorityStatusModal(props.rowData)"
                class="hover:bg-gray-100 p-1 rounded"
              >
                <Icon
                  :name="
                    priorityIconType(props.rowData.userDetails.priorityStatus)
                  "
                  :size="6"
                  :class="
                    priorityIconColor(props.rowData.userDetails.priorityStatus)
                  "
                  v-tooltip="
                    priorityFollowUp(props.rowData.userDetails.followUpAt)
                  "
                />
              </button>
            </div>
          </div>
        </td>
        <td>
          <div>
            <Tag
              v-for="tag in props.rowData.tags.filter(
                item => item.type == 'leadType'
              )"
              :key="tag.id"
              class="mr-2"
            >
              {{ tag.name }}
            </Tag>
          </div>
        </td>
        <td>
          <span
            v-if="props.rowData.accountManager"
            class="text-gray-900 text-sm"
          >
            {{ props.rowData.accountManager.primaryContact.name.split(' ')[0] }}
          </span>
        </td>
        <td>
          <div class="mt-1 text-xs text-gray-900">
            {{ props.rowData.createdAt | luxon }}
          </div>
        </td>
        <td style="max-width: 10rem" class="truncate">
          <ul
            v-for="tag in props.rowData.tags.filter(
              item => item.type == 'trafficType'
            )"
            class="mr-1 my-1 text-xs text-gray-800"
            :key="tag.id"
          >
            <li>{{ tag.name }}</li>
          </ul>
        </td>
        <td style="max-width: 10rem" class="truncate">
          <ul
            v-for="tag in props.rowData.tags.filter(
              item => item.type == 'vertical'
            )"
            class="mr-1 my-1 text-xs text-gray-800 capitalize"
            :key="tag.id"
          >
            <li>{{ tag.name }}</li>
          </ul>
        </td>
        <td>
          <div class="flex flex-row justify-end items-center">
            <div
              class="flex flex-row text-xs flex-grow text-left text-gray-800 "
              v-if="props.rowData.commentsLead.length > 0"
            >
              <UserAvatar
                class="mb-1 mr-2"
                height="5"
                :id="props.rowData.commentsLead[0].commenter.id"
              />
              {{ props.rowData.commentsLead[0].createdAt | luxon }}
            </div>
          </div>
        </td>
        <td class="text-center" style="width:50px;">
          <v-popover offset="0" class="p-0">
            <button
              class="bg-white-600 hover:bg-gray-100 text-blue-600 font-bold p-1 rounded truncate"
            >
              <Icon class="w-6 h-6 text-blue-600" name="dotsVertical" />
            </button>

            <!-- This will be the content of the popover -->
            <template slot="popover">
              <ul>
                <li>
                  <YButton @click="openConvertToActiveModal(props.rowData)"
                    >Convert to Active</YButton
                  >
                </li>
              </ul>
            </template>
          </v-popover>
        </td>
      </template>
    </YTable>

    <YPagination
      v-if="leads"
      :data="leads.paginatorInfo"
      @load-page="loadPage"
      @next-page="nextPage"
      @previous-page="previousPage"
      :key="totalPages"
      @per-page="perPageSelection"
    >
    </YPagination>
  </div>
</template>

<script>
import LEADS_QUERY from '@/graphql/User/LeadsQuery.gql'
import LeadFilters from '@/views/Admin/Leads/LeadFilters.vue'
import { mapState, mapActions } from 'vuex'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'
import LeadToActive from '@/views/Admin/Leads/LeadToActive.vue'
import PriorityStyleMixin from '@/mixins/PriorityStyleMixin.js'
import PriorityStatusModal from '@/views/Admin/User/PriorityStatusModal.vue'

export default {
  mixins: [BackToTop, PriorityStyleMixin],
  components: {
    LeadFilters,
    YPagination
  },
  props: {
    filterValues: {
      type: Object
    }
  },
  apollo: {
    leads: {
      query: LEADS_QUERY,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          filters: {
            userAdvertiserId: this.cleanFilter(this.filters.id),
            email: this.cleanFilter(this.filters.contactEmail),
            name: this.cleanFilter(this.filters.name),
            companyName: this.cleanFilter(this.filters.companyName),
            messenger: this.cleanFilter(this.filters.instantMessengerHandle),
            leadType: this.cleanObjectFilter(this.filters.leadType, 'name'),
            payoutType: this.cleanObjectFilter(this.filters.payoutType, 'name'),
            trafficType: this.cleanObjectFilter(
              this.filters.trafficType,
              'name'
            ),
            verticalTypeTags: this.cleanObjectFilter(
              this.filters.verticalTypeTags,
              'name'
            ),
            geoLocation: this.cleanObjectFilter(
              this.filters.geoLocation,
              'name'
            ),
            priorityStatus: this.filters.priorityStatus.value
              ? { value: this.filters.priorityStatus.value.toLowerCase() }
              : undefined
          },
          orderBy: this.orderBy,
          first: this.perPage,
          page: this.currentPage
        }
      },
      debounce: 250,
      watchLoading(isLoading, countModifier) {
        this.$store.dispatch('updateGlobalLoader', countModifier)
      },
      result({ data }, key) {
        data ? (this.totalPages = data[key].paginatorInfo.lastPage) : null
      },
      deep: true
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalPages: 0,
      orderBy: []
    }
  },
  computed: {
    ...mapState({
      filters: state => state.lead.filters
    })
  },
  mounted() {
    // If Filter Values are passed in, we reset the defailt user filters and update with new values
    if (this.filterValues) {
      this.$store.dispatch('lead/resetFilters')
      for (const [key, value] of Object.entries(this.filterValues)) {
        this.$store.state.lead.filters[key].value = value
      }
    }
  },
  updated() {},
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  },
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    ...mapActions(['updateGlobalLoader']),
    cleanFilter(filter) {
      // Return undefined on null values
      // And make sure value is always an array
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }
      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    openConvertToActiveModal(data) {
      this.$modal.show(
        LeadToActive,
        { lead: data },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openPriorityStatusModal(data) {
      this.$modal.show(
        PriorityStatusModal,
        { user: data },
        {
          height: 'auto',
          width: '90%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>
<style>
.follow-up-row {
  @apply text-gray-900;
  background-color: #f9fafb;
}
</style>
