var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pt-24 relative"
  }, [_c('div', {
    staticClass: "absolute top-0 w-full py-2"
  }, [_c('YButton', {
    staticClass: "w-full block",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isSaving,
      "disabled": !_vm.hasChanges
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("Save Config")]), _c('div', {
    staticClass: "add-options pt-2"
  }, [_c('Tag', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": _vm.addGeo
    }
  }, [_vm._v("+ Geo")]), _c('Tag', {
    attrs: {
      "color": "indigo"
    },
    on: {
      "click": _vm.addLanguage
    }
  }, [_vm._v("+ Lang")]), _c('Tag', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.addMobile
    }
  }, [_vm._v("+ Mobile")]), _c('Tag', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.addDesktop
    }
  }, [_vm._v("+ Desktop")])], 1)], 1), _c('div', {
    staticClass: "flex justify-between"
  }, [_c('div', {
    staticClass: "uppercase font-semibold text-gray-700"
  }, [_vm._v("Root Var")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.rootCount,
      expression: "rootCount"
    }],
    staticClass: "w-10 pl-2 py-1",
    attrs: {
      "type": "number",
      "min": "1"
    },
    domProps: {
      "value": _vm.rootCount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.rootCount = $event.target.value;
      }
    }
  })])]), _vm.addedGeos && _vm.addedGeos.length > 0 ? _c('div', [_c('div', {
    staticClass: "uppercase font-semibold text-gray-700"
  }, [_vm._v("Geos")]), _vm._l(_vm.addedGeos, function (geo, index) {
    return _c('div', {
      key: geo.value
    }, [_c('div', [_c('Superselect', {
      staticClass: "mb-2",
      attrs: {
        "title": "Geo",
        "options": _vm.countryOptions,
        "multiple": false
      },
      scopedSlots: _vm._u([{
        key: "beforeControlIcons",
        fn: function () {
          return [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: geo.count,
              expression: "geo.count"
            }],
            staticClass: "w-10 mr-1 mt-1 pl-2",
            attrs: {
              "type": "number",
              "min": "1"
            },
            domProps: {
              "value": geo.count
            },
            on: {
              "input": function ($event) {
                if ($event.target.composing) return;
                _vm.$set(geo, "count", $event.target.value);
              }
            }
          })];
        },
        proxy: true
      }, {
        key: "bottom-right",
        fn: function () {
          return [_c('div', {
            staticClass: "mb-1 mr-1"
          }, [_c('a', {
            staticClass: "link text-gray-400 hover:text-red-600",
            on: {
              "click": function ($event) {
                return _vm.deleteGeo(index);
              }
            }
          }, [_c('Icon', {
            staticClass: "h-4 w-4",
            attrs: {
              "name": "trashCan"
            }
          })], 1)])];
        },
        proxy: true
      }], null, true),
      model: {
        value: geo.value,
        callback: function ($$v) {
          _vm.$set(geo, "value", $$v);
        },
        expression: "geo.value"
      }
    })], 1)]);
  })], 2) : _vm._e(), _vm.addedLanguages && _vm.addedLanguages.length > 0 ? _c('div', [_c('div', {
    staticClass: "uppercase font-semibold text-gray-700"
  }, [_vm._v("Languages")]), _vm._l(_vm.addedLanguages, function (lang, index) {
    return _c('div', {
      key: lang.value
    }, [_c('div', [_c('Superselect', {
      staticClass: "mb-2",
      attrs: {
        "title": "Language",
        "options": _vm.languageOptions,
        "multiple": false
      },
      scopedSlots: _vm._u([{
        key: "beforeControlIcons",
        fn: function () {
          return [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: lang.count,
              expression: "lang.count"
            }],
            staticClass: "w-10 mr-1 mt-1 pl-2",
            attrs: {
              "type": "number",
              "min": "1"
            },
            domProps: {
              "value": lang.count
            },
            on: {
              "input": function ($event) {
                if ($event.target.composing) return;
                _vm.$set(lang, "count", $event.target.value);
              }
            }
          })];
        },
        proxy: true
      }, {
        key: "bottom-right",
        fn: function () {
          return [_c('div', {
            staticClass: "mb-1 mr-1"
          }, [_c('a', {
            staticClass: "link text-gray-400 hover:text-red-600",
            on: {
              "click": function ($event) {
                return _vm.deleteLanguage(index);
              }
            }
          }, [_c('Icon', {
            staticClass: "h-4 w-4",
            attrs: {
              "name": "trashCan"
            }
          })], 1)])];
        },
        proxy: true
      }], null, true),
      model: {
        value: lang.value,
        callback: function ($$v) {
          _vm.$set(lang, "value", $$v);
        },
        expression: "lang.value"
      }
    })], 1)]);
  })], 2) : _vm._e(), _vm.addedMobile ? _c('div', {
    staticClass: "mt-4 mb-4"
  }, [_c('div', {
    staticClass: "flex justify-between align-items-center"
  }, [_c('div', {
    staticClass: "uppercase font-semibold text-gray-700"
  }, [_vm._v("Mobile")]), _c('div', {
    staticClass: "flex align-items-center"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mobileCount,
      expression: "mobileCount"
    }],
    staticClass: "w-10 mr-2 pl-2",
    attrs: {
      "type": "number",
      "min": "1"
    },
    domProps: {
      "value": _vm.mobileCount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.mobileCount = $event.target.value;
      }
    }
  }), _c('a', {
    staticClass: "link text-gray-400 hover:text-red-600 block",
    on: {
      "click": function ($event) {
        return _vm.deleteMobile();
      }
    }
  }, [_c('Icon', {
    staticClass: "h-4 w-4",
    attrs: {
      "name": "trashCan"
    }
  })], 1)])])]) : _vm._e(), _vm.addedDesktop ? _c('div', {
    staticClass: "mb-2"
  }, [_c('div', {
    staticClass: "flex justify-between align-items-center"
  }, [_c('div', {
    staticClass: "uppercase font-semibold text-gray-700"
  }, [_vm._v("Desktop")]), _c('div', {
    staticClass: "flex align-items-center"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.desktopCount,
      expression: "desktopCount"
    }],
    staticClass: "w-10 mr-2 pl-2",
    attrs: {
      "type": "number",
      "min": "1"
    },
    domProps: {
      "value": _vm.desktopCount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.desktopCount = $event.target.value;
      }
    }
  }), _c('a', {
    staticClass: "link text-gray-400 hover:text-red-600 block",
    on: {
      "click": function ($event) {
        return _vm.deleteDesktop();
      }
    }
  }, [_c('Icon', {
    staticClass: "h-4 w-4",
    attrs: {
      "name": "trashCan"
    }
  })], 1)])])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }