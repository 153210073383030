var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('YInput', {
    staticClass: "flex-auto m-1",
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.filters.name.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.name, "value", $$v);
      },
      expression: "filters.name.value"
    }
  }), _c('YInput', {
    staticClass: "flex-auto m-1",
    attrs: {
      "label": "Tags"
    },
    model: {
      value: _vm.filters.tags.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.tags, "value", $$v);
      },
      expression: "filters.tags.value"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }