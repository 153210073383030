var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sticky-content-panel-container"
  }, [_c('div', {
    staticClass: "sticky-content-panel-bg",
    on: {
      "click": function () {
        _this.$emit('close');
      }
    }
  }), _c('div', {
    staticClass: "sticky-content-panel"
  }, [_c('widget', {
    staticClass: "h-full mb-0"
  }, [_c('div', {
    ref: "header-container",
    staticClass: "sticky-content-header-container"
  }, [_vm._t("header", function () {
    var _this2 = this;
    return [_c('div', {
      staticClass: "sticky-content-header bg-white"
    }, [_c('div', {
      staticClass: "flex items-center justify-between w-full px-4 py-4"
    }, [_c('h4', [_vm._v(_vm._s(_vm.title))]), _c('h4', {
      staticClass: "mb-0",
      attrs: {
        "slot": "right"
      },
      slot: "right"
    }, [_c('close-x', {
      on: {
        "click": function () {
          _this2.$emit('close');
        }
      }
    })], 1)])])];
  })], 2), _c('div', {
    staticClass: "sticky-content-body",
    style: `padding-top: ${_vm.headerHeight}px`
  }, [_c('widget-body', {
    ref: "widget-body",
    staticClass: "bg-light px-4"
  }, [_vm._t("default", function () {
    return [_c('portal-target', {
      attrs: {
        "name": "sticky-content-panel-body"
      }
    })];
  })], 2)], 1), _c('ActionBar', [_vm._t("action-bar")], 2)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }