var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Widget', [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Offer "), _c('sup', [_vm._v("#")]), _vm._v(_vm._s(_vm.offer.id) + " Campaigns ")]), _c('WidgetBody', [_c('ul', [_vm._l(_vm.offer.campaigns, function (campaign) {
    return _c('li', {
      key: campaign.id
    }, [_c('router-link', {
      staticClass: "link",
      attrs: {
        "to": {
          name: 'Campaign',
          params: {
            id: campaign.id
          }
        }
      }
    }, [_vm._v(_vm._s(campaign.label))])], 1);
  }), _vm._l(_vm.offer.offers, function (offer) {
    return _c('li', {
      key: offer.id
    }, _vm._l(offer.campaigns, function (campaign) {
      return _c('router-link', {
        key: campaign.id,
        staticClass: "link",
        attrs: {
          "to": {
            name: 'Campaign',
            params: {
              id: campaign.id
            }
          }
        }
      }, [_vm._v(_vm._s(campaign.label))]);
    }), 1);
  })], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }