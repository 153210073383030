<template>
  <portal-target
    name="sidebar-right"
    v-if="$route.meta.hasRightSideBar"
    class="mx-6 md:mx-0 px-0 py-0 bg-gray-100 shadow-md border-l flex flex-col sidebar-right overflow-scroll"
  >
  </portal-target>
</template>

<script>
export default {}
</script>

<style scoped>
@screen md {
  .sidebar-right {
    width: 18rem;
  }
}
.x-item {
  @apply border-b px-3 py-2;
}
.x-header {
  @apply flex items-center justify-between py-2;
}

.x-header-text {
  @apply font-bold mr-3 text-gray-600 uppercase;
}

.x-icon-container {
  @apply relative text-gray-300;
}

.x-comments {
}

.x-comment {
  @apply py-3 px-3;
}

.x-comment:nth-child(odd) {
  @apply bg-gray-200;
}

.x-comment-header {
  @apply flex items-center mb-1;
}

.x-comment-header-text {
  @apply font-bold text-gray-800;
}

.x-comment-message {
  @apply leading-snug text-gray-800 py-1;
}

.x-counter {
  @apply w-5 h-5 rounded-full bg-green-600 absolute top-0 right-0 -mr-1 -mt-1 text-white text-center text-xs;
}

.x-comment-form {
  @apply mb-4;
}

.comment-footer {
  @apply text-right text-gray-500 italic py-1;
}

.x-body {
  @apply py-2;
}
</style>
