<template>
  <div class="progress-bar-container">
    <div class="progress-bar-wrapper">
      <div class="progress-bar">
        <template v-for="(segmentStyle, index) in segmentStyles">
          <div
            :key="`left-${index}`"
            class="progress-bar-segment"
            :class="['pb-segment-' + segmentStatus[index]]"
            :style="segmentStyle[0]"
          ></div>
          <div
            :key="`right-${index}`"
            class="progress-bar-segment"
            :class="['pb-segment-' + segmentStatus[index]]"
            :style="segmentStyle[1]"
          ></div>
        </template>
        <div
          class="progress-bar-indicator"
          v-tooltip="indicatorLabelMethod(value.toString())"
          :style="{ left: getValuePercentage(value) + '%' }"
        >
          <div class="progress-bar-indicator-label">
            {{ '$' + value }}
          </div>
        </div>
      </div>
      <div class="ticks-container">
        <div
          v-for="(segment, index) in segments"
          :key="'tick-' + index"
          class="tick"
          :class="[tickClass, 'pb-tick-' + segmentStatus[index]]"
          :style="{
            left: getTickPosition(segment) + '%',
            backgroundColor:
              segmentStatus[index] == 'completed'
                ? segmentStyles[index][0].tickReachedColor ??
                  segmentStyles[index][0].backgroundColor
                : segmentStyles[index][1].backgroundColor
          }"
        >
          <div
            class="tick-label flex items-center"
            :class="`tick-label-${segment.tickLabelAlign ?? 'center'}`"
            :style="{ color: segment.tickLabelColor ?? undefined }"
          >
            <span
              v-if="segment.tickLabelDotColor"
              class="tick-label-dot"
              :style="`background-color: ${segment.tickLabelDotColor}`"
            ></span>
            <span>{{ segment.tickLabel ?? segment.end }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    indicatorLabelMethod: {
      type: Function,
      default: value => {
        return value
      }
    },
    startValue: {
      type: Number,
      default: 0
    },
    endValue: {
      type: Number,
      default: 500
    },
    segments: {
      type: Array,
      default: () => [
        { start: 0, end: 300, color: '#4caf50' },
        { start: 301, end: 500, color: '#ff9800' }
      ]
    },
    value: {
      type: Number,
      default: 0
    },
    tickClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    segmentStatus() {
      return this.segments.map(segment => {
        if (this.value >= segment.end) {
          return 'completed'
        }
        if (this.value < segment.start) {
          return 'inactive'
        }
        return 'in-progress'
      })
    },
    segmentStyles() {
      return this.segments.map(segment => {
        const currentValuePercentage = this.getValuePercentage(this.value)
        const segmentStartPercentage = this.getTickPosition({
          ...segment,
          end: segment.start
        })
        const segmentEndPercentage = this.getTickPosition(segment)
        const withinSegment =
          currentValuePercentage >= segmentStartPercentage &&
          currentValuePercentage <= segmentEndPercentage

        let splitPercentage
        if (withinSegment) {
          splitPercentage =
            ((currentValuePercentage - segmentStartPercentage) /
              (segmentEndPercentage - segmentStartPercentage)) *
            100
        } else {
          splitPercentage =
            currentValuePercentage > segmentEndPercentage ? 100 : 0
        }

        const leftSegment = {
          width: `${(this.getPercentage(segment) * splitPercentage) / 100}%`,
          backgroundColor: segment.leftColor || segment.color,
          tickReachedColor: segment.tickReachedColor
        }

        const rightSegment = {
          width: `${(this.getPercentage(segment) * (100 - splitPercentage)) /
            100}%`,
          backgroundColor: segment.rightColor || segment.color,
          tickReachedColor: segment.tickReachedColor
        }

        return [leftSegment, rightSegment]
      })
    }
  },
  methods: {
    getPercentage(segment) {
      const total = this.endValue - this.startValue
      const segmentLength = segment.end - segment.start
      return (segmentLength / total) * 100
    },
    getValuePercentage(value) {
      const total = this.endValue - this.startValue
      return ((value - this.startValue) / total) * 100
    },
    getTickPosition(segment) {
      const total = this.endValue - this.startValue
      const segmentPosition = segment.end - this.startValue
      return (segmentPosition / total) * 100
    }
  }
}
</script>

<style scoped>
/* AI */
.progress-bar-container {
  padding-top: 0rem;
  padding-bottom: 0.4rem;
  overflow: hidden;
  position: relative;
}
.progress-bar-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 1rem;
}

.progress-bar {
  display: flex;
  width: 100%;
  height: 20px;
  background-color: #eee;
}

.progress-bar-segment {
  height: 100%;
}

.progress-bar-indicator {
  position: absolute;
  top: 0;
  height: 20px;
  width: 4px;
  z-index: 1;
  background-color: #000;
  transform: translateX(-50%);
}

.ticks-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 20px;
}

.tick {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #ccc;
}

.tick-label {
  position: absolute;
  top: 100%;
  font-size: 0.85rem;
}

.tick-label-center {
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.tick-label-right {
  right: 0;
  text-align: right;
}

.progress-bar-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Custom */
.progress-bar {
  overflow: hidden;
  border-radius: 10rem 0 0 10rem;
}

.progress-bar-indicator {
  /* visibility: hidden; */
  margin-left: -3px;
  width: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 3;
}

.tick {
  width: 6px;
  margin-left: -6px;
  top: 0;
  border-radius: 10rem;
  z-index: 2;
}

.ticks-container {
  position: absolute;
  top: -6px;
  padding-bottom: 12px;
  box-sizing: content-box;
}

.progress-bar-indicator-label {
  position: absolute;
  top: 0;
  height: 100%;
  color: #000;
  opacity: 0.2;
  font-weight: bold;
  font-size: 0.85rem;
  right: 0;
  text-align: left;
  left: 12px;
}

.tick-label-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  margin-right: 5px;
  background-color: rgb(170, 170, 170);
}
</style>
