<template>
  <div>
    <div
      v-for="userInterfaceNotification in userInterfaceNotifications"
      :key="userInterfaceNotification.id"
    >
      <YAlert :color="userInterfaceNotification.color" class="mt-0 mb-0 px-6">
        {{ userInterfaceNotification.message }}
      </YAlert>
    </div>
  </div>
</template>

<script>
import USER_INTERFACE_NOTIFICATIONS_QUERY from '@/graphql/Notifications/UserInterfaceNotificationsQuery.gql'

export default {
  apollo: {
    userInterfaceNotifications: {
      query: USER_INTERFACE_NOTIFICATIONS_QUERY,
      variables() {
        return {
          route: this.$route.name
        }
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style></style>
