<template>
  <div class="relative y-input" :class="[internalValue ? 'filled' : '']">
    <span v-if="required" class="absolute right-0 pr-1 text-2xl text-red-500"
      >*</span
    >
    <Icon
      v-if="tooltip"
      name="helpCircle"
      class="absolute right-0 bottom-0 mb-1 mr-1 text-gray-400 bg-white"
      :size="4"
      v-tooltip="tooltip"
    />

    <ckeditor
      class="input bg-white"
      :class="[inputClass]"
      :editor="editor"
      v-model="internalValue"
      :config="config"
      :disabled="disabled || readonly"
    ></ckeditor>

    <label
      :for="label ? label.toLowerCase() : null"
      class="pointer-events-none label absolute mb-0 -mt-2 pt-4 pl-3 leading-tighter text-gray-400 text-base mt-2 cursor-text"
      >{{ label }}</label
    >
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon'

export default {
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    id: {
      type: String,
      default: () => {
        // If no ID is passed. Generate a random one to link label and input
        return Math.floor(Math.random(9999999) * 100000000).toString()
      }
    },
    label: {
      type: String
    },
    inputClass: {
      type: [String, Array]
    },
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String
    }
  },
  data() {
    return {
      editor: BalloonEditor
    }
  },
  computed: {
    config() {
      return {
        placeholder: this.internalValue ? this.placeholder : undefined
      }
    },
    internalValue: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        if (newValue == '') {
          newValue = null
        }
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style scoped></style>
