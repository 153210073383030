var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isVisible ? _c('div', {
    class: `bg-${_vm.color}-200 border-t-4 border-${_vm.color}-800 rounded-b text-${_vm.color}-800 px-4 py-3 shadow-md`,
    attrs: {
      "role": "alert"
    }
  }, [_c('div', {
    staticClass: "flex"
  }, [_vm.icon ? _c('Icon', {
    staticClass: "mr-2",
    staticStyle: {
      "margin-top": "2px"
    },
    attrs: {
      "name": _vm.icon,
      "size": _vm.iconSize
    }
  }) : _vm._e(), _c('div', {
    staticClass: "flex-grow"
  }, [_vm._t("default", function () {
    return [_c('p', {
      staticClass: "font-bold"
    }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
      staticClass: "text-sm"
    }, [_vm._v(_vm._s(_vm.message))])];
  })], 2), _vm._t("right", function () {
    return [_vm.allowClose ? _c('close-x', {
      staticClass: "alert-link",
      on: {
        "click": _vm.close
      }
    }) : _vm._e()];
  })], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }