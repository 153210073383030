<template>
  <div>
    <Widget>
      <WidgetHeader class="bg-gray-100">
        Upload Preview
        <template v-slot:right>
          <YButton
            color="blue"
            @click.prevent="submitPayouts"
            :is-loading="isLoading"
            :disabled="hasNoData"
            >Save Upload</YButton
          >
        </template>
      </WidgetHeader>
      <WidgetBody class="widget-edge">
        <div class="row">
          <div class="col table-scroll-container">
            <vuetable
              ref="vuetable"
              id="reporting-table"
              :api-mode="false"
              :data="payoutsData"
              pagination-path=""
              :fields="fields"
              :css="css"
              :load-on-start="false"
              cellspacing="0"
              width="100%"
              table-wrapper="vuetable-wrapper"
              :append-params="appendParams"
              @vuetable:load-error="onLoadError"
            >
            </vuetable>
          </div>
        </div>
      </WidgetBody>
    </Widget>
  </div>
</template>

<script>
import Vuetable from '../vuetable-2-2-custom/src/components/Vuetable'
import gql from 'graphql-tag'

export default {
  components: {
    Vuetable
  },
  props: {
    filters: {
      default: false,
      type: Boolean
    }
  },
  data() {
    let masterColumnData = {
      type: {
        filter: {
          id: 'show-type',
          label: 'type',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'type',
          title: 'Type',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      level: {
        filter: {
          id: 'show-level',
          label: 'level',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'level',
          title: 'Level',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      campaignId: {
        filter: {
          id: 'show-campaign-id',
          label: 'CampaignId',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'campaignId',
          title: 'Campaign ID',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      redirectId: {
        filter: {
          id: 'show-redirect-id',
          label: 'Redirect ID',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'redirectId',
          title: 'Redirect ID',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sourceId: {
        filter: {
          id: 'show-source-id',
          label: 'Source ID',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'sourceId',
          title: 'Source ID',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      countryCode: {
        filter: {
          id: 'show-country-code',
          label: 'Country',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'countryCode',
          title: 'Country',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      value: {
        filter: {
          id: 'show-value',
          label: 'Value',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'value',
          title: 'Value',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      floorValue: {
        filter: {
          id: 'show-floor-value',
          label: 'Floor',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'floorValue',
          title: 'Floor',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      startAt: {
        filter: {
          id: 'show-start-at',
          label: 'startAt',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'startAt',
          title: 'Start At',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      isPaused: {
        filter: {
          id: 'show-is-paused',
          label: 'isPaused',
          state: true,
          visibile: false,
          disabled: false
        },
        column: {
          name: 'isPaused',
          title: 'Paused',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      }
    }

    let columns = this.createVuetableColumns(masterColumnData)

    return {
      count: null,
      masterColumnData: masterColumnData,
      payoutsData: [],
      fields: columns,
      css: {
        tableClass: 'table table-sm table-striped dt-responsive nowrap',
        loadingClass: 'loading',
        ascendingIcon: 'custom-table-sort-arrow-up',
        descendingIcon: 'custom-table-sort-arrow-down',
        detailRowClass: 'vuetable-detail-row',
        sortHandleIcon: 'grey sidebar icon'
      },
      sortOrder: [
        {
          field: 'date',
          sortField: 'date',
          direction: 'desc'
        }
      ],
      multiSort: false,
      appendParams: {},
      perPage: 12,
      isLoading: false,
      vuetable: this,
      submittedForm: ''
    }
  },
  computed: {
    hasNoData: function() {
      return this.payoutsData.length <= 0
    }
  },
  mounted() {
    // Listen to Form Submits

    this.$events.listen('addBulkPayoutsToPreview', eventData =>
      this.addPayoutToPreview(eventData)
    )
    this.$events.listen('clearBulkPayoutsPreview', () => {
      this.clearPreview()
    })
    this.$events.listen('uploadPayoutsFinished', data => {
      if (!data.error) {
        this.clearPreview()
      }
    })
    this.$events
  },
  beforeDestroy() {
    this.$events.remove('uploadPayoutsFinished')
    this.$events.remove('addBulkPayoutsToPreview')
    this.$events.remove('clearBulkPayoutsPreview')
  },
  updated() {},
  methods: {
    addPayoutToPreview(data) {
      if (data.data.constructor === Array) {
        data.data.forEach(payout => this.payoutsData.push(payout))
      }
    },

    submitPayouts() {
      this.isLoading = true
      this.count = this.payoutsData.length

      this.$apollo
        .mutate({
          mutation: gql`
            mutation UploadPayouts($input: UpsertPayoutsInput!) {
              uploadPayouts(input: $input) {
                result
                error
              }
            }
          `,
          variables: {
            input: {
              payouts: this.payoutsData,
              sendEmails: true
            }
          }
        })
        .then(result => {
          if (result.data.uploadPayouts.error) {
            this.$toastr.e(result.data.uploadPayouts.error)
          } else {
            this.payoutsData = []
            this.$toastr.s(result.data.uploadPayouts.result)
          }
        })
        .catch(error => {
          this.isLoading = false
          this.$toastr.e(error, 'Oops!')
        })
    },
    clearPreview() {
      this.payoutsData = []
      this.refreshVuetable()
    },
    onLoadError(error) {
      this.$events.fire('returnAdjustmentsFormErrors', {
        form: this.submittedForm,
        errors: error.response.data
      })
    },
    refreshVuetable() {
      this.$nextTick(() => {
        this.$refs.vuetable.normalizeFields()
        this.$refs.vuetable.refresh()
      })
    },

    createVuetableColumns(master) {
      var vuetableColumns = []
      for (var column in master) {
        if (master[column].filter.state == true) {
          vuetableColumns.push(master[column].column)
        }
      }
      return vuetableColumns
    }
  }
}
</script>
<style></style>
