var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.visibilityChanged,
      expression: "visibilityChanged"
    }]
  }, [_c('div', {
    staticClass: "text-xs text-gray-600 font-bold mb-1 mt-4"
  }, [_vm._v(" Default Redirect Parameters ")]), _c('div', {
    staticClass: "grid grid-cols-6 gap-0"
  }, [_c('YInput', {
    attrs: {
      "input-class": "rounded-r-none border-r-0",
      "label": "?kw="
    },
    model: {
      value: _vm.kw,
      callback: function ($$v) {
        _vm.kw = $$v;
      },
      expression: "kw"
    }
  }), _c('YInput', {
    attrs: {
      "input-class": "rounded-none border-l-0 border-r-0",
      "label": "&s1="
    },
    model: {
      value: _vm.s1,
      callback: function ($$v) {
        _vm.s1 = $$v;
      },
      expression: "s1"
    }
  }), _c('YInput', {
    attrs: {
      "input-class": "rounded-none border-l-0 border-r-0",
      "label": "&s2="
    },
    model: {
      value: _vm.s2,
      callback: function ($$v) {
        _vm.s2 = $$v;
      },
      expression: "s2"
    }
  }), _c('YInput', {
    attrs: {
      "input-class": "rounded-none border-l-0 border-r-0",
      "label": "&s3="
    },
    model: {
      value: _vm.s3,
      callback: function ($$v) {
        _vm.s3 = $$v;
      },
      expression: "s3"
    }
  }), _c('YInput', {
    attrs: {
      "input-class": "rounded-none border-l-0 border-r-0",
      "label": "&s4="
    },
    model: {
      value: _vm.s4,
      callback: function ($$v) {
        _vm.s4 = $$v;
      },
      expression: "s4"
    }
  }), _c('YInput', {
    attrs: {
      "input-class": "rounded-l-none border-l-0",
      "label": "&s5="
    },
    model: {
      value: _vm.s5,
      callback: function ($$v) {
        _vm.s5 = $$v;
      },
      expression: "s5"
    }
  })], 1), _vm.hasChanges && _vm.isVisible ? _c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('YButton', {
    attrs: {
      "color": "green"
    },
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }, [_vm._v("Update Details")])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }