<template>
  <div
    class="flex flex-wrap w-3/4 m-auto justify-center text-sm bg-white pt-5 pb-8 px-6 rounded-b-lg border-b border-l border-r shadow-xl"
    style="max-width: 24rem;"
  >
    <p class="text-lg mb-4 mt-3">
      <strong>Thank you for signing up to YTZ International.</strong>
    </p>
    <p>
      Your registration is waiting approval by one of our staff, and
      <strong>you will be contacted within 48 hours.</strong>
    </p>

    <div
      class="flex border-t border-gray-400 mt-3 pt-8 text-inverse-700 w-full text-right justify-end"
    >
      <div class="text-right">
        <p><strong>YTZ Affiliates Team</strong></p>
        <p>affiliates@ytz.com</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped></style>
