<template>
  <div>
    <YAlert
      v-if="showAlert && !me.billingContact.paymentMethod"
      :allow-close="false"
      color="orange"
      class="mt-0 mb-0 px-6"
    >
      <div class="flex justify-between items-center">
        <div>
          <h6 class="mb-0 font-weight-semibold">
            Please complete your account set up by providing a
            <span class="font-semibold">Payment Method</span> in the
            <span class="font-bold underline">
              <router-link
                :to="{
                  name: 'Publisher Finance'
                }"
              >
                Finance</router-link
              ></span
            >
            section.
          </h6>
        </div>
      </div>
    </YAlert>

    <YAlert
      v-if="showAlert && showCanadianTaxUpdate"
      :allow-close="true"
      color="orange"
      class="mt-0 mb-0 px-6"
    >
      <div class="flex justify-between items-center">
        <div>
          <h6 class="mb-0 font-weight-semibold">
            <span class="font-bold mr-2">NEW</span> Canadian Users are required
            to provide <span class="font-semibold">GST/HST</span> numbers in the
            <span class="font-semibold underline">
              <router-link
                :to="{
                  name: 'Publisher Finance'
                }"
              >
                Finance</router-link
              ></span
            >
            section.
          </h6>
        </div>
      </div>
    </YAlert>
  </div>
</template>

<script>
import ME_QUERY from '@/graphql/User/MeQuery.gql'

export default {
  apollo: {
    me: {
      query: ME_QUERY
    }
  },
  methods: {},
  computed: {
    showAlert() {
      return (
        this.me && this.me.isAPublisher && this.me.userDetails.agreedToTerms
      )
    },
    showCanadianTaxUpdate() {
      return (
        this.me.billingContact.paymentMethod &&
        this.me.billingContact.paymentMethod != 'NoPM' &&
        this.me.primaryContact.country.code == 'CA' &&
        !this.me.billingContact.taxNumber
      )
    }
  }
}
</script>

<style></style>
