var render = function render(){
  var _vm$user$confidenceSc;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', {
    staticStyle: {
      "background": "#f8fafc"
    }
  }, [_c('div', {
    staticClass: "container p-6 m-auto"
  }, [_c('div', {
    staticClass: "flex gap-5 py-6"
  }, [_c('Widget', {
    staticClass: "w-1/3"
  }, [_c('WidgetHeader', [_vm._v(" User Details ")]), _c('WidgetBody', {
    staticClass: "pt-0"
  }, [_c('ul', [_c('li', [_c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("Contact:")]), _vm._v(" " + _vm._s(_vm.user.primaryContact.name) + " ")]), _c('li', [_c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("Status:")]), _vm._v(" " + _vm._s(_vm.user.status.toUpperCase()) + " ")]), _vm.user.lastLogin ? _c('li', [_c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("Last Login:")]), _vm._v(" " + _vm._s(_vm.user.lastLogin.createdAt.split('').slice(0, 10).join('')) + " ")]) : _vm._e()])])], 1), _c('Widget', {
    staticClass: "w-1/3"
  }, [_c('WidgetHeader', [_c('div', {
    staticClass: "flex"
  }, [_vm._v(" Total Revenue "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: this.revenuePercentChange,
      expression: "this.revenuePercentChange"
    }],
    staticClass: "ml-3 flex",
    class: {
      'text-red-600': this.revenuePercentChange < 0,
      'text-green-600': this.revenuePercentChange >= 0
    }
  }, [_c('Icon', {
    staticClass: "w-4 h-4 mt-1",
    attrs: {
      "name": this.revenuePercentChange < 0 ? 'arrowDown' : 'arrowUp'
    }
  }), _c('animated-number', {
    attrs: {
      "value": _vm.revenuePercentChange,
      "duration": 1000,
      "formatValue": _vm.formatPercentage,
      "easing": "easeOutQuad"
    }
  })], 1)])]), _c('WidgetBody', {
    staticClass: "pt-0"
  }, [_c('div', {
    staticClass: "text-2xl md:text-4xl"
  }, [_c('animated-number', {
    attrs: {
      "value": _vm.totalRevenue,
      "formatValue": _vm.toLocaleCurrency,
      "duration": 1000,
      "easing": "easeOutExpo"
    }
  })], 1)])], 1), _c('Widget', {
    staticClass: "w-1/3"
  }, [_c('WidgetHeader', [_c('div', {
    staticClass: "flex"
  }, [_vm._v(" Overall Impressions "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: this.visitorPercentChange,
      expression: "this.visitorPercentChange"
    }],
    staticClass: "ml-1 flex",
    class: {
      'text-red-600': this.visitorPercentChange < 0,
      'text-green-600': this.visitorPercentChange >= 0
    }
  }, [_c('Icon', {
    staticClass: "w-4 h-4 mt-1",
    attrs: {
      "name": this.visitorPercentChange < 0 ? 'arrowDown' : 'arrowUp'
    }
  }), _c('animated-number', {
    attrs: {
      "value": _vm.visitorPercentChange,
      "duration": 1000,
      "formatValue": _vm.formatPercentage,
      "easing": "easeOutQuad"
    }
  })], 1)])]), _c('WidgetBody', {
    staticClass: "pt-0"
  }, [_c('div', {
    staticClass: "text-2xl md:text-4xl"
  }, [_c('animated-number', {
    attrs: {
      "value": _vm.totalVisitors,
      "formatValue": _vm.toLocaleString,
      "duration": 1000,
      "easing": "easeOutExpo"
    }
  })], 1)])], 1)], 1), _c('div', {
    staticClass: "flex gap-5 py-6"
  }, [_c('Widget', {
    staticClass: "w-full",
    attrs: {
      "loading": _vm.$apollo.queries.dailyGraphData.loading
    }
  }, [_c('WidgetHeader', [_vm._v(" Snapshot - Last 20 days ")]), _c('WidgetBody', [_c('div', {
    staticClass: "w-full h-auto gap-5"
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "padding-left": "1.5rem",
      "padding-right": "1.5rem"
    }
  }, [!_vm.$apollo.queries.dailyGraphData.loading ? _c('y-chart', {
    attrs: {
      "chart-data": _vm.chartData,
      "date-range": _vm.dateRange,
      "height": 300
    }
  }) : _vm._e()], 1)])])], 1)], 1), _c('div', {
    staticClass: "grid grid-cols-5 gap-4"
  }, [_c('div', {
    staticClass: "col-span-2"
  }, [_c('Widget', [_c('WidgetHeader', [_vm._v("Quality Metrics")]), _c('WidgetBody', [_c('div', {
    staticClass: "w-100 mb-2"
  }, [_c('span', {
    staticClass: "font-bold"
  }, [_vm._v("Duplicate IP Log")])]), (_vm$user$confidenceSc = _vm.user.confidenceScoreQuality) !== null && _vm$user$confidenceSc !== void 0 && _vm$user$confidenceSc.ipHistory ? _c('div', _vm._l(_vm.user.confidenceScoreQuality.ipHistory, function (history, index) {
    return _c('div', {
      key: index,
      staticClass: "mb-3"
    }, [_c('h3', {
      staticClass: "font-semibold mb-1 truncate"
    }, [_vm._v(_vm._s(history.ip))]), _c('table', {
      staticClass: "w-full bg-gray-100"
    }, [_c('thead', [_c('tr', {
      staticClass: "text-gray-700"
    }, [_c('th', [_vm._v("User Id")]), _c('th', [_vm._v("Action Type")])])]), _c('tbody', _vm._l(history.matches, function (match, index) {
      return _c('tr', {
        key: index,
        staticStyle: {
          "border-bottom": "1px solid #efefef"
        }
      }, [_c('td', {
        staticClass: "pl-2"
      }, [_c('router-link', {
        staticClass: "link block",
        attrs: {
          "to": {
            name: 'User',
            params: {
              id: match.causerId
            }
          }
        }
      }, [_vm._v(_vm._s(match.causerId))])], 1), _c('td', {
        staticClass: "text-center"
      }, [_vm._v(_vm._s(match.type))])]);
    }), 0)])]);
  }), 0) : _c('div', [_vm._v(" No duplicates reported ")])])], 1)], 1), _c('div', {
    staticClass: "col-span-3 col-start-3"
  }, [_c('div', {
    staticClass: "flex flex-col gap-4"
  }, [_c('Widget', [_c('WidgetHeader', {
    staticClass: "pb-0"
  }, [_vm._v(" Registration Score "), _c('a', {
    staticClass: "inline-block",
    attrs: {
      "href": "https://docs.ytrack.io/sales/publishers/user-auto-rejection",
      "target": "_blank"
    }
  }, [_c('Icon', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'See yDocs',
      expression: "'See yDocs'"
    }],
    staticClass: "text-gray-700 w-2 h-2 ml-1 cursor-pointer",
    attrs: {
      "name": "openInNew"
    }
  })], 1)]), _vm.user.confidenceScoreAtRegistration ? _c('WidgetBody', {
    staticClass: "pt-0"
  }, [_c('div', {
    staticClass: "w-100 flex justify-end"
  }, [_c('span', {
    staticClass: "text-gray-800 text-xs"
  }, [_vm._v("*Auto Qualifers Only")])]), _c('table', {
    staticClass: "min-w-full leading-normal"
  }, [_c('tr', [_c('th', {
    staticClass: "text-left"
  }, [_vm._v("qualifier")]), _c('th', {
    staticClass: "text-left"
  }, [_vm._v("Result")])]), _vm._l(_vm.filteredQualifiers, function (qualifier) {
    return _c('tr', {
      key: qualifier.name,
      staticClass: "border-b"
    }, [_c('td', [_c('div', {
      staticClass: "w-full flex"
    }, [_vm._v(" " + _vm._s(qualifier.name) + " "), _c('Icon', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: qualifier.description,
        expression: "qualifier.description"
      }],
      staticClass: "text-gray-400 ml-4",
      attrs: {
        "name": "information"
      }
    })], 1)]), _c('td', [_vm._v(" " + _vm._s(qualifier.status ? 'Fail' : 'Pass') + " ")])]);
  })], 2)]) : _vm._e(), !_vm.user.confidenceScoreAtRegistration ? _c('WidgetBody', [_c('span', {
    staticClass: "font-bold"
  }, [_vm._v("Registration:")]), _vm._v(" Nothing to Report")]) : _vm._e()], 1), _c('Widget', [_c('WidgetHeader', {
    staticClass: "pb-0"
  }, [_vm._v(" Traffic Score ")]), _c('WidgetBody', [_vm.user.confidenceScoreQuality ? _c('table', {
    staticClass: "min-w-full leading-normal"
  }, [_c('tr', [_c('th', {
    staticClass: "text-left"
  }, [_vm._v("qualifier")]), _c('th', [_vm._v("count")])]), _vm._l(_vm.filteredQualityScore, function (qualifier) {
    return _c('tr', {
      key: qualifier.qualifierName,
      staticClass: "border-b"
    }, [_c('td', [_vm._v(" " + _vm._s(qualifier.name) + " ")]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [qualifier !== null && qualifier !== void 0 && qualifier.meta ? _c('ul', {
      staticClass: "py-1"
    }, _vm._l(qualifier.meta, function (value, key) {
      return _c('li', {
        key: key,
        staticClass: "m-1"
      }, [_vm._v(" " + _vm._s(_vm.metaData(value, key)) + " ")]);
    }), 0) : _vm._e()])]);
  })], 2) : _vm._e(), _vm.user.confidenceScoreQuality ? _c('table', {
    staticClass: "min-w-full leading-normal"
  }, _vm._l(_vm.qualityCommentTypes, function (type) {
    return _c('tr', {
      key: type,
      staticClass: "m-1 border-b"
    }, [_c('td', [_c('div', {
      staticClass: "flex content-center"
    }, [_c('div', {
      staticClass: "w-40 self-center capitalize"
    }, [_vm._v(" " + _vm._s(type == 'positive' ? type + ' Feedback' : type + ' Complaint') + " ")]), _c('div', {
      staticClass: "flex-initial mx-6 capitalize"
    }, [_c('button', {
      staticClass: "mt-1",
      on: {
        "click": function ($event) {
          return _vm.openQualityCommentsModal(type);
        }
      }
    }, [_c('Icon', {
      staticClass: "text-blue-600",
      attrs: {
        "name": "plusBox",
        "size": 6
      }
    })], 1), _c('button', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: 'View History',
        expression: "'View History'"
      }],
      staticClass: "mt-1 ml-3",
      on: {
        "click": function ($event) {
          return _vm.openQualityCommentsHistoryModal(type);
        }
      }
    }, [_c('Icon', {
      staticClass: "text-gray-600",
      attrs: {
        "name": "history",
        "size": 6
      }
    })], 1)])])]), _c('td', {
      staticStyle: {
        "width": "100px"
      }
    }, [_vm._v(" " + _vm._s(_vm.user.commentsQuality.filter(function (comment) {
      return comment.commentType == type;
    }).length) + " ")])]);
  }), 0) : _vm._e()]), !_vm.user.confidenceScoreQuality ? _c('WidgetBody', [_c('span', {
    staticClass: "font-bold"
  }, [_vm._v("Quality:")]), _vm._v("Nothing to Report")]) : _vm._e()], 1)], 1)])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }