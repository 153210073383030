<template>
  <form
    id="publisher-adjustment-form"
    name="publisher-adjustment-form"
    @submit="submitForm"
    ref="form"
    @keydown="errors.clear($event.target.name)"
    @change="errors.clear($event.target.name)"
  >
    <input type="hidden" v-model="data.type" />
    <div class="row pt-3">
      <div class="grid grid-cols-1 sm:grid-cols-5 gap-1">
        <y-form-group>
          <Superselect
            title="Publisher"
            v-model="data.publisher"
            :options="publisherOptions || []"
            placeholder="Select"
            track-by="id"
            label="label"
            :close-on-select="false"
            :query="PUBLISHER_OPTIONS_QUERY"
            :query-variables="{
              first: 20,
              filters: {
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            edit-placeholder=""
          ></Superselect>
        </y-form-group>

        <y-form-group>
          <Superselect
            title="Advertiser"
            v-model="data.advertiser"
            :options="advertiserOptions || []"
            placeholder="Select"
            track-by="id"
            label="label"
            :close-on-select="false"
            :query="ADVERTISER_OPTIONS_QUERY"
            :query-variables="{
              first: 20,
              filters: {
                advertiserLabel: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            edit-placeholder=""
          ></Superselect>
        </y-form-group>

        <y-form-group>
          <Superselect
            title="Campaign"
            v-model="data.campaign"
            :options="campaignOptions || []"
            placeholder="Select"
            track-by="id"
            label="label"
            :close-on-select="false"
            :query="CAMPAIGN_OPTIONS_QUERY"
            :query-variables="{
              first: 20,
              filters: {
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            edit-placeholder=""
          ></Superselect>
        </y-form-group>

        <y-form-group>
          <YInput
            label="Payout"
            class="form-control"
            v-model="data.payout"
            placeholder="0.00"
            name="payout"
          ></YInput>
        </y-form-group>

        <y-form-group>
          <YInput
            label="Revenue"
            class="form-control"
            v-model="data.revenue"
            placeholder="0.00"
            name="revenue"
          ></YInput>
        </y-form-group>
      </div>
    </div>
    <div class="flex justify-end mt-3">
      <YButton
        type="button"
        class="border border-blue-600 hover:bg-blue-600 focus:bg-blue-600 text-blue-600 hover:text-white focus:text-white mr-3 outline-none-important"
        @click.prevent="clearForm"
        >Reset</YButton
      >
      <YButton
        class="bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 text-white mr-3 outline-none-important"
        @click.prevent="addToPreview"
        >Add to Preview</YButton
      >
    </div>
  </form>
</template>

<script>
class Errors {
  constructor() {
    this.errors = {}
  }

  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0]
    }
  }

  record(errors) {
    this.errors = errors
  }

  clear(field) {
    delete this.errors[field]
  }

  clearAll() {
    this.errors = {}
  }
}

import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import ADVERTISER_OPTIONS_QUERY from '@/graphql/Advertiser/AdvertiserOptionsQuery.gql'
import CAMPAIGN_OPTIONS_QUERY from '@/graphql/Campaign/CampaignOptionsQuery.gql'

export default {
  components: {},
  props: ['loadingPreview'],
  apollo: {},
  data() {
    let initialData = {
      publisher: null,
      advertiser: null,
      campaign: null,
      payout: null,
      revenue: null
    }

    return {
      PUBLISHER_OPTIONS_QUERY,
      ADVERTISER_OPTIONS_QUERY,
      CAMPAIGN_OPTIONS_QUERY,
      publisherOptions: [],
      advertiserOptions: [],
      campaignOptions: [],
      data: this._.clone(initialData, true),
      initialData: this._.clone(initialData, true),
      publisher: null,
      advertiser: null,
      campaign: null,
      errors: new Errors()
    }
  },
  computed: {
    loading() {
      return this.loadingPreview
    }
  },
  methods: {
    addToPreview() {
      this.data.publisher = this.data.publisher ? this.data.publisher[0] : null
      this.data.advertiser = this.data.advertiser ? this.data.advertiser[0] : null
      this.data.campaign = this.data.campaign ? this.data.campaign[0] : null
      this.data.redirect = this.data.redirect ? this.data.redirect[0] : null
      this.$events.fire('addAdjustmentToPreview', {
        data: this._.cloneDeep(this.data)
      })
    },
    submitForm(event) {
      event.preventDefault()
      this.$events.fire('addAdjustmentToPreview', {
        data: this.data
      })
    },
    catchErrors(errors) {
      if (errors.form == this.$refs.form) {
        this.errors.record(errors.errors)
      }
    },
    clearForm() {
      this.data = this._.clone(this.initialData, true)
      this.errors.clearAll()
    }
  },
  created() {},
  mounted() {
    // Listen for errors
    this.$events.listen('returnAdjustmentsFormErrors', eventData =>
      this.catchErrors(eventData)
    )
  },
  beforeDestroy() {
    this.$events.remove('returnAdjustmentsFormErrors')
  },
  updated() {}
}
</script>
<style scoped>
.has-danger .multiselect__tags {
  border-color: #f44336;
}

.form-group .multiselect {
  margin-bottom: 0.5rem;
}
.form-group .multiselect:last-child {
  margin-bottom: 0rem;
}

.widget-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.tiny-forms .input-group-btn .btn {
  line-height: 1.3;
}
</style>
