var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "shadow rounded-lg"
  }, [_vm.data ? _c('YTable', {
    attrs: {
      "data": _vm.data || []
    },
    scopedSlots: _vm._u([{
      key: "header-row",
      fn: function () {
        return [_c('th'), _c('th', [_vm._v("name")]), _c('th', [_vm._v(" last updated ")]), _c('th', [_vm._v("tags")]), _c('th')];
      },
      proxy: true
    }, {
      key: "row",
      fn: function (props) {
        return [_c('td', {
          staticClass: "w-40"
        }, [_c('EditableImage', {
          staticClass: "rounded",
          attrs: {
            "imageUrl": props.rowData.imageUrl,
            "thumbnailUrl": props.rowData.imageThumbnailUrl,
            "height": 32,
            "width": 32,
            "blankHeight": 20,
            "blankWidth": 20,
            "editable-only-when-empty": true,
            "modelClass": "Image",
            "modelId": props.rowData.id
          }
        })], 1), _c('td', [_c('span', {
          staticClass: "font-bold"
        }, [_vm._v(_vm._s(props.rowData.name))]), _c('a', {
          staticClass: "inline-block",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.copyToClipboard(props.rowData.name);
            }
          }
        }, [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip.top",
            value: `Copy to Clipboard`,
            expression: "`Copy to Clipboard`",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "ml-4 inline-block text-blue-600",
          attrs: {
            "name": "contentCopy",
            "size": 4
          }
        })], 1)]), _c('td', [_c('span', {
          staticClass: "text-gray-700"
        }, [_vm._v(_vm._s(_vm.date(props.rowData.updatedAt)))])]), _c('td', [_c('div', [_c('EditableValue', {
          ref: "tagsEditable",
          attrs: {
            "value": props.rowData.tags.filter(function (tag) {
              return tag.type === 'vertical';
            }),
            "type": "slot",
            "mutation": _vm.SYNC_TAGS_WITH_TYPE_MUTATION,
            "sortBy": "id",
            "transformValueForQuery": function (value) {
              return value.map(function (tag) {
                return tag.name;
              });
            },
            "trackBy": "type",
            "variables": {
              input: {
                type: 'vertical',
                taggableType: 'image',
                taggableId: props.rowData.id,
                tags: '{value}'
              }
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (slotProps) {
              return [slotProps.value && slotProps.value.length > 0 ? _c('div', _vm._l(slotProps.value, function (tag) {
                return _c('Tag', {
                  key: tag.id,
                  staticClass: "mr-1 my-1",
                  attrs: {
                    "color": tag.color,
                    "tabindex": "0"
                  },
                  on: {
                    "focus": function () {
                      slotProps.focus();
                    }
                  }
                }, [_vm._v(_vm._s(tag.name))]);
              }), 1) : _c('div', {
                on: {
                  "click": function () {
                    slotProps.focus();
                  }
                }
              }, [_c('span', {
                staticClass: "italic text-gray-500 hover:bg-blue-200 cursor-pointer"
              }, [_vm._v("None")])])];
            }
          }, {
            key: "focusSlot",
            fn: function (slotProps) {
              return [_c('Superselect', {
                staticClass: "box-width min-width-full",
                attrs: {
                  "title": "Tags",
                  "value": slotProps.value,
                  "focusOnMount": true,
                  "multiple": true,
                  "track-by": "slug",
                  "label": "name",
                  "placeholder": "Select",
                  "query": _vm.TAGS_QUERY,
                  "query-variables": {
                    filters: {
                      type: {
                        value: ['vertical']
                      }
                    },
                    first: 100
                  }
                },
                on: {
                  "input": function (value) {
                    slotProps.updateInternalValue(value);
                  },
                  "close": function () {
                    //slotProps.unfocusOnNoChanges() || slotProps.focusOnSave()
                  }
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('td', {
          staticClass: "text-right"
        }, [_c('div', {
          staticClass: "inline-flex gap-1"
        }, [_c('span', {
          on: {
            "click": function ($event) {
              return _vm.openImageEditModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "text-blue-600 cursor-pointer",
          attrs: {
            "name": "pencil",
            "size": "4"
          }
        })], 1), _c('span', {
          on: {
            "click": function ($event) {
              return _vm.openConfirmationModal(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          staticClass: "text-red-600 hover:text-red-700 cursor-pointer",
          attrs: {
            "name": "trashCan",
            "size": "4"
          }
        })], 1)])])];
      }
    }], null, false, 683234856)
  }) : _vm._e(), _c('modal', {
    attrs: {
      "name": "delete-modal",
      "width": 364,
      "height": 140
    }
  }, [_c('Widget', {
    staticClass: "bg-red-600 text-white border-red-700"
  }, [_c('WidgetHeader', {
    staticClass: "bg-red-600 text-white"
  }, [_c('span', {
    staticClass: "text-white"
  }, [_vm._v("Are you sure you want to delete this image?")])]), _c('WidgetBody', [_c('h5', {
    staticClass: "text-yellow-400 font-bold"
  }, [_vm._v(" This action cannot be undone. ")])]), _c('WidgetFooter', {
    staticClass: "bg-dark-transparent-1 flex justify-end gap-6 py-2 px-4"
  }, [_c('YButton', {
    staticClass: "border hover:bg-red-500 focus:bg-red-700",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('delete-modal');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    staticClass: "bg-red-700 hover:bg-red-800 border focus:bg-red-700",
    on: {
      "click": _vm.confirmationOfDelete
    }
  }, [_vm._v("Delete")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }