var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "top"
    }
  }, [_c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('div', {
    staticClass: "flex justify-right"
  }, [_c('YButton', {
    staticClass: "btn btn-blue",
    on: {
      "click": _vm.openCreateModal
    }
  }, [_vm._v("New Tag ")])], 1)]), _c('Widget', {
    staticClass: "mb-1 overflow-visible"
  }, [_c('WidgetHeader', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('TagFilters')], 1)], 2)], 1), _vm.tags ? _c('YTable', {
    staticClass: "mt-2",
    attrs: {
      "data": _vm.tags.data || [],
      "bottom-loading": _vm.$apolloData.queries.tags.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_c('tag', {
          attrs: {
            "color": props.rowData.color
          }
        }, [_vm._v(_vm._s(props.rowData.name))])], 1), _c('td', [_vm._v(" " + _vm._s(props.rowData.type) + " ")]), _c('td', {
          staticClass: "text-right"
        }, [_c('div', {
          staticClass: "inline-flex items-center"
        }, [_c('a', {
          staticClass: "link",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openEditModal(props.rowData.id, props.rowData.name, props.rowData.type, props.rowData.color);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "pencil"
          }
        })], 1), _c('a', {
          staticClass: "ml-2 text-red-500 hover:text-red-600",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDeleteModal(props.rowData.id, props.rowData.name, props.rowData.type);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "trashCan"
          }
        })], 1)])])];
      }
    }], null, false, 4165650816)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Tag")]), _c('th', [_vm._v("Type")]), _c('th')])], 2) : _vm._e(), _c('div', {
    staticClass: "w-100 py-4"
  }, [_vm.tags.paginatorInfo ? _c('YPagination', {
    key: _vm.totalPages,
    attrs: {
      "data": _vm.tags.paginatorInfo,
      "onEachSide": 2
    },
    on: {
      "load-page": _vm.loadPage,
      "next-page": _vm.nextPage,
      "previous-page": _vm.previousPage,
      "per-page": _vm.perPageSelection
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }