<template>
  <Widget class="bg-red-600 text-white border-red-700">
    <WidgetHeader class="bg-red-600 text-white">
      <span class="text-white" v-if="!conversionTypeInUse">Are you sure?</span>
      <span class="text-white" v-if="conversionTypeInUse"
        >{{ data.name }} is in use and cannot be deleted</span
      >
    </WidgetHeader>
    <WidgetBody v-if="!conversionTypeInUse">
      <h5 class="text-yellow-400 font-bold">This action cannot be undone.</h5>
      <h5>
        Please confirm you'd like to delete
        <span class="px-1 font-bold underline uppercase">{{ data.name }}</span>
        from Conversion Types.
      </h5>
    </WidgetBody>

    <WidgetBody v-if="conversionTypeInUse">
      <h5 class="text-white mb-3">
        Conversion types cannot be deleted when assigned to types.
      </h5>
      <div style="max-height:284px; overflow:hidden;">
        <table class="table-auto bg-white text-black w-full">
          <thead>
            <tr class="h-12 px-4 py-3 border-b-2 border-gray-200 bg-gray-100">
              <th class="">Advertiser Accounts</th>
              <th class="">Campaigns</th>
              <th class="">Postbacks</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ul>
                  <li
                    v-for="advertiser in data.advertiserAccounts"
                    :key="advertiser.userId"
                  >
                    <router-link
                      :to="{
                        name: 'Advertiser',
                        params: { id: advertiser.userId, tab: 'accounts' }
                      }"
                      class="link"
                      target="_blank"
                    >
                      {{ advertiser.id }}
                    </router-link>
                  </li>
                </ul>
              </td>

              <td>
                <ul>
                  <li v-for="payout in assignedPayouts" :key="payout">
                    <router-link
                      :to="{
                        name: 'Campaign',
                        params: { id: payout, tab: 'payouts' }
                      }"
                      class="link"
                      target="_blank"
                    >
                      {{ payout }}
                    </router-link>
                  </li>
                </ul>
              </td>

              <td>
                <ul>
                  <li
                    v-for="postback in data.postbacks"
                    :key="postback.campaignId"
                  >
                    <router-link
                      :to="{
                        name: 'Redirect',
                        params: { id: postback.redirectId, tab: 'postbacks' }
                      }"
                      class="link"
                      target="_blank"
                    >
                      {{ postback.redirectId }}
                    </router-link>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="maximumDisplayedResults" class="text-yellow-200 pl-2">
        ...to many results to display
      </div>
    </WidgetBody>

    <WidgetFooter class="bg-dark-transparent-1 flex justify-between py-2 px-4">
      <YButton class="hover:bg-red-700 focus:bg-red-700" @click="$emit('close')"
        >Cancel</YButton
      >
      <YButton
        @click="deleteConversionType"
        class="hover:bg-red-700 focus:bg-red-700"
        :is-loading="isSubmitting"
        :disabled="conversionTypeInUse"
        >Delete Conversion Type</YButton
      >
    </WidgetFooter>
  </Widget>
</template>

<script>
import DELETE_CONVERSION_TYPE_MUTATION from '@/graphql/ConversionType/DeleteConversionType.gql'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {},
  apollo: {},
  data() {
    return {
      isSubmitting: false
    }
  },
  watch: {},
  computed: {
    maximumDisplayedResults() {
      if (
        this.data.advertiserAccounts.length > 10 ||
        this.data.payouts.length > 10 ||
        this.data.postbacks.length > 10
      ) {
        return true
      }
      return false
    },
    conversionTypeInUse() {
      if (
        this.data.advertiserAccounts.length > 0 ||
        this.data.payouts.length > 0 ||
        this.data.postbacks.length > 0
      ) {
        return true
      }
      return false
    },
    assignedPayouts() {
      return [...new Set(this.data.payouts.map(payout => payout.campaignId))]
    }
  },
  methods: {
    deleteConversionType() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: DELETE_CONVERSION_TYPE_MUTATION,
          variables: {
            slug: this.data.slug
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$events.emit('refreshConversionTypes', true)
          this.$toastr.s('Conversion Type Deleted', 'Success!')
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
          this.isSubmitting = false
        })
    }
  },
  mounted() {
    console.log('this.data', this.data)
  },
  updated() {}
}
</script>

<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
th {
  @apply w-1/3 px-4 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider;
}
td {
  @apply py-2;
  vertical-align: top;
}
ul {
  @apply px-4 flex flex-col leading-6;
}
</style>
