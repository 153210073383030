var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "x-item"
  }, [_vm._t("root", function () {
    return [_c('div', {
      staticClass: "x-header"
    }, [_c('div', {
      staticClass: "x-header-text"
    }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
      staticClass: "x-icon-container"
    }, [_vm.count ? _c('div', {
      staticClass: "x-counter",
      class: _vm.countClass
    }, [_vm._v(" " + _vm._s(_vm.count) + " ")]) : _vm._e(), _vm.icon ? _c('Icon', {
      attrs: {
        "name": _vm.icon,
        "size": 10
      }
    }) : _vm._e()], 1)]), _c('div', {
      staticClass: "x-body"
    }, [_vm._t("default")], 2)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }