<template>
  <div class="py-6 w-full h-full bg-gray-200">
    <div class="container mx-auto">
      <div class="py-6 w-full h-full bg-gray-200">
        <div
          class="w-10/12 mx-auto mb-4 my-6 shadow sm:px-10 sm:py-6 py-4 px-4 bg-white rounded-md"
        >
          <div class="flex w-full justify-between">
            <p class="text-lg text-gray-800 font-semibold pb-3">
              Subscribe to Reminder Emails
            </p>
            <div class="h-6 cursor-pointer rounded-full relative">
              <input
                :checked="!this.me.userDetails.disableEngagementEmails"
                type="checkbox"
                name="toggle"
                id="disableEngemenetEmailToggle"
                @change="disableEngemenetEmailSubmit()"
                class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
              />
              <label
                for="disableEngemenetEmailToggle"
                class="toggle-label bg-gray-300 block w-12 h-6 overflow-hidden rounded-full cursor-pointer"
              ></label>
            </div>
          </div>
          <div class="w-full text-sm text-gray-600 font-normal  pb-3">
            <p class="pb-1">
              Receive helpful reminders to your account.
            </p>
            <p>
              Unsubscribing from reminders will not impact any system or
              campaign critical notifications.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'

export default {
  props: {
    me: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      submitting: false
    }
  },
  methods: {
    disableEngemenetEmailSubmit() {
      this.submitting = true
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            input: {
              id: this.me.id,
              userDetails: {
                update: {
                  id: this.me.userDetails.id,
                  disableEngagementEmails: !this.me.userDetails
                    .disableEngagementEmails
                }
              }
            }
          }
        })
        .then(() => {
          this.submitting = false
          this.$toastr.s('Email Preferences Updated')
        })
        .catch(error => {
          this.$toastr.e(error, 'Oops! Something went wrong.')
          this.submitting = false
        })
    }
  }
}
</script>

<style>
.checkbox:checked {
  right: 0;
}
.checkbox:checked + .toggle-label {
  @apply bg-blue-400;
}
</style>
