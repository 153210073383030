<template>
  <div class="h-screen flex">
    <div class="flex h-full w-full">
      <div class="flex-shrink">
        <div class="font-semibold px-3 py-3 uppercase text-gray-500">
          Templates
        </div>
        <ul class="overflow-y-hidden">
          <li
            v-for="template in templates"
            :key="template.path"
            class="py-1  px-3"
            :class="
              template.path == path
                ? 'bg-blue-600 text-white'
                : 'hover:bg-blue-100'
            "
          >
            <router-link
              class="link block py-2 px-4 -my-2 -mx-4"
              :class="
                template.path == path ? 'text-white hover:text-white' : ''
              "
              :to="{
                name: 'Template',
                params: { path: template.path }
              }"
              >{{ template.path }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="flex-grow pl-4">
        <Tabs
          v-if="template"
          class="h-full w-full"
          show-type="show"
          tabDetailsClass="overflow-y-auto"
        >
          <Tab
            name="Preview"
            :active="true"
            class="flex-1 p-2"
            :key="template.path"
          >
            <div class="flex h-full">
              <div class="flex-shrink pr-6">
                <TemplateVars
                  :definitions="template.varDefinitions"
                  :initial-inputs="initialVarInputs"
                  @inputsUpdated="cacheVarInputs"
                  @queryStringUpdated="updateVarQueryString"
                >
                </TemplateVars>
              </div>
              <div class="flex-grow">
                <iframe
                  class="w-full h-full"
                  :src="
                    this.templatePreviewLink +
                      '/' +
                      this.selectedPath +
                      '?' +
                      this.varQueryString
                  "
                ></iframe>
              </div>
            </div>
          </Tab>
          <Tab name="Details" class="flex-1 p-2">
            <div class="mb-2">
              <span class="font-semibold pr-3 uppercase text-gray-500"
                >Public Name:</span
              >
              {{ template.name }}
            </div>
            <div class="mb-2">
              <span class="font-semibold pr-3 uppercase text-gray-500"
                >Enabled Extensions:</span
              >
              <ul
                v-for="extension of template.extensions"
                :key="extension.name"
              >
                <li>{{ extension.name }}</li>
              </ul>
            </div>
            <div class="mb-2">
              <span class="font-semibold pr-3 uppercase text-gray-500"
                >Enabled Variables:</span
              >
              <ul
                v-for="varDefinition of template.varDefinitions"
                :key="varDefinition.key"
              >
                <li>{{ varDefinition.key }}</li>
              </ul>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>
</template>
<script>
import TEMPLATES_QUERY from '@/graphql/Template/TemplatesQuery.gql'
import OFFER_OPTIONS_QUERY from '@/graphql/Offer/OfferOptionsQuery.gql'
import TEMPLATE_IMAGES_QUERY from '@/graphql/TemplateImages/TemplateImagesQuery.gql'
import TemplateVars from '@/views/Admin/Templates/TemplateVars'

export default {
  props: {
    path: {
      type: String
    }
  },
  components: {
    TemplateVars
  },
  apollo: {
    templates: {
      query: TEMPLATES_QUERY,
      result({ data }) {
        if (!this.path) {
          this.selectedPath = data.templates[0].path
        }
      }
    }
  },
  data() {
    return {
      OFFER_OPTIONS_QUERY,
      TEMPLATE_IMAGES_QUERY,
      templates: [],
      selectedPath: null,
      vars: {},
      cachedVarInputsByTemplate: {},
      initialVarInputs: {},
      varQueryString: '',
      templatePreviewLink: process.env.VUE_APP_TEMPLATE_URL
    }
  },
  watch: {
    path: {
      immediate: true,
      handler(value) {
        this.selectedPath = value ?? null
      }
    },
    template: {
      handler(value) {
        this.initialVarInputs =
          this.cachedVarInputsByTemplate[value.path] ?? value.testVars ?? {}
      }
    }
  },
  computed: {
    template() {
      return (
        this.templates.filter(
          template => template.path == this.selectedPath
        )[0] ?? null
      )
    }
  },
  methods: {
    updateVarQueryString(queryString) {
      this.varQueryString = queryString
    },
    cacheVarInputs(value) {
      this.cachedVarInputsByTemplate[this.template.path] = value
    }
  }
}
</script>

<style scoped></style>
