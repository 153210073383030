<template>
  <v-popover class="relative inline-block" :autoHide="true">
    <YButton
      class="px-5 text-blue-600 hover:bg-blue-100"
      :class="model === 'Domain' ? 'font-normal' : ''"
    >
      <span class="flex items-center">
        <img
          src="@/assets/images/logo/spamhaus-logo.png"
          class="-ml-1 mr-2 h-2"
        />
        Check Spamhaus
      </span>
    </YButton>
    <template slot="popover">
      <ul>
        <li>
          <YButton
            class="text-blue-600 hover:bg-blue-100 w-full"
            @click="runSpamhausCheck(rowData.id)"
            v-close-popover
          >
            Manual Check
          </YButton>
        </li>
        <li>
          <YButton
            class="text-blue-600 bg-blue-100 hover:bg-blue-200 rounded-t-none"
            @click="mutateSpamhausCheck(rowData)"
            v-close-popover
          >
            {{
              rowData.spamhausCheck && rowData.spamhausCheck.enabled
                ? 'Disable'
                : 'Enable'
            }}
            Auto Check
          </YButton>
        </li>
      </ul>
    </template>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </v-popover>
</template>

<script>
import RUN_SPAMHAUS_CHECK_MUTATION from '@/graphql/Domain/RunSpamhausCheckMutation.gql'
import MUTATE_SPAMHAUS_CHECK_MUTATION from '@/graphql/Domain/MutateSpamhausCheckMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    rowData: {
      type: Object
    },
    model: {
      type: String
    }
  },
  methods: {
    mutateSpamhausCheck(data) {
      this.$apollo
        .mutate({
          mutation: MUTATE_SPAMHAUS_CHECK_MUTATION,
          variables: {
            input: {
              model: this.model,
              checkId: data.id,
              method:
                data.spamhausCheck && data.spamhausCheck.enabled
                  ? 'delete'
                  : 'create'
            }
          }
        })
        .then(({ data }) => {
          this.clearValidationErrors()

          if (this.model === 'Ip') {
            this.$events.emit('refreshYdnsServers')
          } else if (this.model === 'Domain') {
            this.$events.emit('refreshYdnsDomains')
          }

          if (!data.mutateSphausCheck.lastRunOutput) {
            this.$toastr.s(`${this.model} Unlisted`, 'Success')
          } else {
            this.$toastr.s(`${this.model} Listed`, 'Oops!')
          }
        })
        .catch(error => {
          this.setValidationErrors(error)
        })
    },
    runSpamhausCheck(id) {
      this.$apollo
        .mutate({
          mutation: RUN_SPAMHAUS_CHECK_MUTATION,
          variables: {
            input: {
              model: this.model,
              checkId: id
            }
          }
        })
        .then(({ data }) => {
          this.clearValidationErrors()

          if (this.model === 'Ip') {
            this.$events.emit('refreshYdnsServers')
          } else if (this.model === 'Domain') {
            this.$events.emit('refreshYdnsDomains')
          }
          if (data.runSphausCheck.lastRunOutput == undefined) {
            this.$toastr.s(`${this.model} Unlisted`, 'Success')
          } else {
            this.$toastr.s(`${this.model} Listed`, 'Oops!')
          }
        })
        .catch(error => {
          console.log('error', error)
          this.setValidationErrors(error)
        })
    }
  }
}
</script>
