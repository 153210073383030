<template>
  <Widget
    :class="[config.backgroundClass, config.textColorClass, config.borderClass]"
  >
    <WidgetHeader
      :closeable="true"
      @close="$emit('close')"
      close-class="text-white"
    >
      <span :class="[config.textColorClass]">Are you sure?</span>
    </WidgetHeader>
    <WidgetBody>
      <h5 v-if="type == 'danger'" style="color: #ffc107 !important;">
        This action cannot be undone.
      </h5>
      <p v-if="message">{{ message }}</p>
    </WidgetBody>
    <WidgetFooter
      :class="this.config.widgetFooterClass"
      class="flex justify-between items-center p-1"
    >
      <YButton @click="$emit('close')" class="footer-hover-btn2">
        No, cancel this action
      </YButton>
      <YButton class="footer-hover-btn" @click="confirmClicked">
        Yes, I'm sure
      </YButton>
    </WidgetFooter>
  </Widget>
</template>

<script>
export default {
  props: {
    onConfirm: {
      type: Function,
      required: true
    },
    message: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'danger'
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    config() {
      let config = {}
      if (this.type == 'danger') {
        config = {
          borderClass: 'border-red-700',
          backgroundClass: 'bg-red-600',
          backgroundClassDarker: 'bg-red-700',
          backgroundClassDarkest: 'bg-red-800',
          textColorClass: 'text-white',
          colorHex: '#FFF',
          widgetFooterClass: 'bg-red-700'
        }
      }
      return config
    }
  },
  methods: {
    confirmClicked() {
      this.onConfirm()
      this.$emit('close')
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}

.footer-hover-btn {
  background-color: rgba(0, 0, 0, 0.1);
}

.footer-hover-btn:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.footer-hover-btn2 {
}

.footer-hover-btn2:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
