export default {
  methods: {
    backToTop: function() {
      const el = document.getElementById('top')

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth'
        })
      }
    }
  }
}
