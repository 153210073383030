<template>
  <span :class="classes">
    <template v-if="actionsState == 'default'">
      <a @click="edit" class="editable-hover">
        <slot></slot>
      </a>
      <editable-actions
        :state="actionsState"
        @edit="edit"
        @submit="submit"
        @cancel="cancel"
        :edit-action-display-type="editActionDisplayType"
      ></editable-actions>
    </template>
    <template v-if="actionsState == 'edit'">
      <Superselect
        v-model="value"
        :options="options"
        placeholder="Select One"
        title="Protocol"
        :multiple="false"
        :show-labels="false"
        :allow-empty="false"
        :loading="$apollo.loading"
        @input="submit"
      ></Superselect>

      <editable-actions
        :submitting="isSubmitting"
        :state="actionsState"
        @edit="edit"
        @submit="submit"
        @cancel="cancel"
      ></editable-actions>
    </template>
  </span>
</template>

<script>
import EditableMixin from '@/components/legacy/Editables/EditableMixin.js'
import UPDATE_POSTBACK_MUTATION from '@/graphql/Postback/UpdatePostbackMutation.gql'

export default {
  mixins: [EditableMixin],
  props: {
    id: {
      required: true
    },
    protocol: {
      type: String,
      required: true
    }
  },
  apollo: {},
  data() {
    return {
      value: this.protocol,
      mutation: UPDATE_POSTBACK_MUTATION,
      options: ['http', 'https']
    }
  },
  computed: {
    mutationVariables() {
      return {
        input: {
          id: this.id,
          protocol: this.value
        }
      }
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
