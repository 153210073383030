export default {
  approved: 'green',
  requested: 'blue',
  available: 'blue',
  subscribed: 'green',
  declined: 'red',
  rejected: 'red',
  active: 'green',
  inactive: 'red',
  pending: 'blue',
  fraud: 'red',
  deleted: 'red'
}
