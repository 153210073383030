var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex py-4"
  }, [_c('div', {
    staticClass: "w-1/12"
  }, [_c('Superselect', {
    staticStyle: {
      "min-width": "4.6rem",
      "border": "none"
    },
    attrs: {
      "title": "Per Page",
      "multiple": false,
      "options": _vm.perPageOptions
    },
    on: {
      "input": _vm.pageSelection
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1), _c('div', {
    staticClass: "block flex flex-grow items-center justify-center w-3/4"
  }, [_c('YButton', {
    attrs: {
      "color": "link",
      "disabled": _vm.data.currentPage === 1
    },
    on: {
      "click": _vm.goToFirstPage
    }
  }, [_c('Icon', {
    attrs: {
      "name": "chevronDoubleLeft",
      "size": "6"
    }
  })], 1), _c('YButton', {
    attrs: {
      "color": "link",
      "disabled": _vm.data.currentPage === 1
    },
    on: {
      "click": _vm.previousPage
    }
  }, [_c('Icon', {
    attrs: {
      "name": "chevronLeft",
      "size": "6"
    }
  })], 1), _c('div', {
    key: _vm.data.total
  }, [_vm.lastPage >= 5 ? _c('div', [_vm._l(_vm.windowSize, function (n) {
    return [_c('button', {
      key: n,
      staticClass: "link px-3 hover:bg-blue-100 rounded-md p-2 text-base",
      class: {
        highlight: _vm.isCurrentPage(_vm.windowStart + n - 1)
      },
      domProps: {
        "innerHTML": _vm._s(_vm.windowStart + n - 1)
      },
      on: {
        "click": function ($event) {
          return _vm.loadPage(_vm.windowStart + n - 1);
        }
      }
    })];
  })], 2) : _c('div', [_vm._l(_vm.lastPage, function (page) {
    return [_c('button', {
      key: page,
      staticClass: "link px-3 hover:bg-blue-100 rounded-md p-2 text-base",
      class: {
        highlight: _vm.isCurrentPage(page)
      },
      domProps: {
        "innerHTML": _vm._s(page)
      },
      on: {
        "click": function ($event) {
          return _vm.loadPage(page);
        }
      }
    })];
  })], 2)]), _c('YButton', {
    attrs: {
      "color": "link",
      "disabled": _vm.data.currentPage === _vm.lastPage
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_c('Icon', {
    attrs: {
      "name": "chevronRight",
      "size": "6"
    }
  })], 1), _c('YButton', {
    attrs: {
      "color": "link",
      "disabled": _vm.data.currentPage === _vm.lastPage
    },
    on: {
      "click": _vm.goToLastPage
    }
  }, [_c('Icon', {
    attrs: {
      "name": "chevronDoubleRight",
      "size": "6"
    }
  })], 1)], 1), _c('div', {
    staticClass: "w-1/12"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }