var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('div', {
    staticClass: "background-gradient-green-blue",
    staticStyle: {
      "height": "6px"
    }
  }), _c('WidgetHeader', {
    on: {
      "close": _vm.close
    }
  }, [_vm._v(" Save Preset ")]), _c('WidgetBody', [_c('div', {
    staticClass: "input-group"
  }, [_c('YInput', {
    staticClass: "form-control",
    attrs: {
      "label": "Overwrite Existing",
      "type": "text",
      "value": `${_vm.presetFilter.originalName}`,
      "readonly": ""
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('YButton', {
    staticClass: "btn btn-blue btn-width rounded-l-none btn-width",
    attrs: {
      "type": "button",
      "isLoading": _vm.isOverwriting,
      "disabled": _vm.isOverwriting || _vm.isSaving
    },
    on: {
      "click": _vm.overwritePreset
    }
  }, [_vm._v(" Overwrite ")])], 1)], 1), _c('div', {
    staticClass: "input-group mt-2"
  }, [_c('YInput', {
    staticClass: "form-control",
    attrs: {
      "label": "Save As New Preset",
      "type": "text",
      "placeholder": "Preset Name"
    },
    model: {
      value: _vm.presetName,
      callback: function ($$v) {
        _vm.presetName = $$v;
      },
      expression: "presetName"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('YButton', {
    staticClass: "btn btn-green rounded-l-none btn-width",
    attrs: {
      "type": "button",
      "isLoading": _vm.isSaving,
      "disabled": !_vm.presetName
    },
    on: {
      "click": _vm.savePreset
    }
  }, [_vm._v(" Save ")])], 1)], 1), _c('div', {
    staticClass: "flex mt-2"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center mr-2",
    attrs: {
      "id": "preset-is-public-checkbox",
      "label": "Available for everyone",
      "inline": true,
      "type": "info"
    },
    model: {
      value: _vm.isPublic,
      callback: function ($$v) {
        _vm.isPublic = $$v;
      },
      expression: "isPublic"
    }
  }), _c('YCheckbox', {
    staticClass: "flex items-center",
    attrs: {
      "id": "preset-include-date-checkbox",
      "label": "Include Date",
      "inline": true,
      "type": "info"
    },
    model: {
      value: _vm.includeDate,
      callback: function ($$v) {
        _vm.includeDate = $$v;
      },
      expression: "includeDate"
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }