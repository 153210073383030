<template>
  <div
    class="order-by-item cursor-pointer"
    :class="['text-blue-600 hover:text-blue-700']"
    @click.exact="click"
    @click.meta="ctrlClick"
    @click.ctrl="ctrlClick"
  >
    <span><slot></slot></span>
    <Icon
      :name="itemOrder == 'ASC' ? 'arrowUp' : 'arrowDown'"
      class="w-3 h-3 inline-block ml-2"
      :class="[!itemExists ? 'text-gray-400' : '']"
    />
  </div>
</template>

<script>
export default {
  props: {
    column: {
      type: String,
      default: null,
      required: true
    },
    order: {
      type: String,
      default: 'ASC'
    },
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    orderBy() {
      return this.value
    },
    item() {
      if (this.itemExists) {
        return this.orderBy.filter(item => item.column == this.column)[0]
      } else {
        return {
          column: this.column,
          order: this.order
        }
      }
    },
    inverseItem() {
      return {
        column: this.column,
        order: this.itemOrder !== 'ASC' ? 'ASC' : 'DESC'
      }
    },
    itemExists() {
      let filtered = this.orderBy.filter(item => item.column == this.column)

      return filtered.length > 0 ? true : false
    },
    itemOrder() {
      return this.itemExists ? this.item.order : this.order
    }
  },
  methods: {
    click() {
      this.replaceItems(this.itemExists ? this.inverseItem : this.item)
    },
    ctrlClick() {
      this.addItem(this.itemExists ? this.inverseItem : this.item)
    },
    update(sortOrder) {
      this.$emit('input', sortOrder)
    },
    replaceItems(item) {
      this.update([item])
    },
    addItem(item) {
      let orderBy
      orderBy = this.orderBy.filter(item => item.column != this.column)
      this.update([item, ...orderBy])
    }
  }
}
</script>

<style></style>
