var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    staticClass: "flex items-center space-x-3 mb-6",
    attrs: {
      "aria-expanded": _vm.isOpen
    },
    on: {
      "click": function ($event) {
        return _vm.toggleAccordion();
      }
    }
  }, [_c('span', {
    staticClass: "titleText"
  }, [_vm._t("title")], 2), _c('Icon', {
    staticClass: "text-inverse-700",
    class: {
      toggleTransition: _vm.isOpen
    },
    attrs: {
      "name": "chevronDown",
      "size": "5"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOpen,
      expression: "isOpen"
    }]
  }, [_vm._t("content")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }