var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isGroup ? _c('Tag', {
    class: ['condition-group', `${_vm.type}-condition-tag condition-tag`, `${_vm.isNot ? 'condition-is-not' : 'condition-is'}`, `${_vm.differentialChange ? 'condition-differential-change' : ''}`, 'items-center', 'select-none', _vm.nestLevel == 1 ? 'condition-base-level' : ''],
    attrs: {
      "color": 'white',
      "differential": _vm.localDifferential,
      "nestLevel": _vm.nestLevel + 1
    },
    scopedSlots: _vm._u([{
      key: "afterIcon",
      fn: function () {
        return [_vm.nestLevel != 1 && _vm.edit ? _c('button', {
          staticClass: "py-1 px-2 -ml-2 outline-none-important relative rounded-full",
          class: [`hover:bg-${_vm.trueColor}-300`, `focus:bg-${_vm.trueColor}-300`, `focus:shadow-sm`],
          on: {
            "click": function ($event) {
              return _vm.removeCondition();
            }
          }
        }, [_c('Icon', {
          staticClass: "w-3 h-3 cursor-pointer edit-control",
          attrs: {
            "name": "close"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 2069380031)
  }, [_c('div', [_c('div', {
    staticClass: "inline-flex items-center"
  }, [_c('v-popover', {
    staticClass: "p-0",
    attrs: {
      "offset": "0"
    }
  }, [_vm.edit ? _c('button', {
    staticClass: "p-1 option-button outline-none-important"
  }, [_c('Icon', {
    staticClass: "w-3 h-3",
    attrs: {
      "name": "dotsVertical"
    }
  })], 1) : _vm._e(), _c('template', {
    slot: "popover"
  }, [_c('ul', {
    staticClass: "text-xs"
  }, [_c('li', [_c('YButton', {
    staticClass: "w-full",
    on: {
      "click": function ($event) {
        return _vm.toggleAndOr();
      }
    }
  }, [_vm._v("Toggle And / Or (" + _vm._s(_vm.isAnd ? 'And' : 'Or') + ")")])], 1), _c('li', [_c('YButton', {
    staticClass: "w-full",
    on: {
      "click": function ($event) {
        return _vm.toggleNot();
      }
    }
  }, [_vm._v("Toggle Not (" + _vm._s(_vm.isNot) + ")")])], 1), _c('li', [_c('YButton', {
    staticClass: "w-full",
    on: {
      "click": function ($event) {
        return _vm.addGroup('and');
      }
    }
  }, [_vm._v("Add New Group")])], 1)])])], 2), _vm.isNot ? _c('span', {
    staticClass: "text-red-600 mr-2"
  }, [_vm._v("NOT")]) : _vm._e(), _vm._l(_vm.childConditions, function (childCondition, index) {
    return [childCondition ? [index > 0 ? _c('span', {
      key: childCondition.tag ? childCondition.tag.name + _vm.nestLevel : index + _vm.nestLevel,
      staticClass: "condition-operator"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.condition.or ? '|' : '&') + " ")])]) : _vm._e(), _c('Condition', {
      key: childCondition.tag ? childCondition.tag.name : index,
      attrs: {
        "nestLevel": _vm.nestLevel + 1,
        "differential": _vm.localDifferential,
        "edit": _vm.edit
      },
      on: {
        "remove": _vm.removeCondition
      },
      model: {
        value: _vm.childConditions[index],
        callback: function ($$v) {
          _vm.$set(_vm.childConditions, index, $$v);
        },
        expression: "childConditions[index]"
      }
    })] : _vm._e()];
  }), _vm.edit ? _c('v-popover', {
    staticClass: "p-0",
    attrs: {
      "offset": "0"
    }
  }, [_c('button', {
    staticClass: "px-2 outline-none-important",
    class: [`hover:bg-${_vm.trueColor}-300`, `focus:bg-${_vm.trueColor}-300`, `border-${_vm.trueColor}- 00`, `border-dashed`, 'border', 'py-1', 'px-3', 'ml-2', 'rounded-full', 'relative']
  }, [_vm._v(" ADD ")]), _c('template', {
    slot: "popover"
  }, [_c('TagsSelect', {
    ref: "tagsSelect",
    staticStyle: {
      "min-width": "15rem"
    },
    attrs: {
      "title": "Tags",
      "value": [],
      "multiple": true,
      "options-limit": 5,
      "close-on-select": true,
      "filterTypes": _vm.trafficTagTypes
    },
    on: {
      "select": function (value) {
        _vm.addTag(value);
      }
    }
  })], 1)], 2) : _vm._e()], 2)])]) : _vm.condition.tag ? _c(_vm.tagNest ? 'Tag' : 'div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.condition.tag.type,
      expression: "condition.tag.type"
    }],
    tag: "component",
    class: [`${_vm.type}-condition-tag condition-tag`, `${_vm.isNot ? 'condition-is-not' : ''}`, `${_vm.differentialChange ? 'condition-differential-change' : ''}`, 'inline-flex', 'border-' + _vm.trueColor + '-300'],
    attrs: {
      "color": _vm.trueColor,
      "differential": _vm.localDifferential
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function () {
        return [_vm.edit ? _c('button', {
          staticClass: "py-1 px-2 -mr-2 outline-none-important relative rounded-full",
          class: [`hover:bg-${_vm.trueColor}-300`, `focus:bg-${_vm.trueColor}-300`, `focus:shadow-sm`],
          on: {
            "click": function ($event) {
              return _vm.toggleNot();
            }
          }
        }, [_c('Icon', {
          staticClass: "w-3 h-3 cursor-pointer",
          attrs: {
            "name": "exclamation"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "afterIcon",
      fn: function () {
        return [_vm.edit ? _c('button', {
          staticClass: "py-1 px-2 -ml-2 outline-none-important relative rounded-full",
          class: [`hover:bg-${_vm.trueColor}-300`, `focus:bg-${_vm.trueColor}-300`, `focus:shadow-sm`],
          on: {
            "click": function ($event) {
              return _vm.removeCondition();
            }
          }
        }, [_c('Icon', {
          staticClass: "w-3 h-3 cursor-pointer",
          attrs: {
            "name": "close"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('span', {
    staticClass: "text-red-600"
  }, [_vm._v(_vm._s(_vm.condition.not ? 'NOT' : ''))]), _vm._v(" " + _vm._s(_vm.condition.tag.name) + " ")]) : _vm.condition.tag !== undefined ? _c('div', {
    staticClass: "inline-flex pl-1"
  }, [_c('v-popover', {
    staticClass: "p-0",
    attrs: {
      "offset": "0"
    }
  }, [_c('button', {
    staticClass: "px-2 outline-none-important",
    class: [`bg-${_vm.trueColor}-500`, `hover:bg-${_vm.trueColor}-600`, `focus:bg-${_vm.trueColor}-600`, `border-${_vm.trueColor}-600`, 'border-l', 'border-t', 'border-b', 'text-white', 'py-1', 'px-2', 'pl-3', 'rounded-l-sm']
  }, [_vm._v(" SELECT ")]), _c('template', {
    slot: "popover"
  }, [_c('TagsSelect', {
    ref: "tagsSelect",
    staticStyle: {
      "min-width": "15rem"
    },
    attrs: {
      "title": "Tags2",
      "value": [],
      "multiple": true,
      "close-on-select": true
    },
    on: {
      "select": function (value) {
        _vm.localValue.tag = value;
      }
    }
  })], 1)], 2), _c('button', {
    class: ['outline-none-important', `bg-${_vm.trueColor}-500`, `hover:bg-${_vm.trueColor}-600`, `focus:bg-${_vm.trueColor}-600`, `border-${_vm.trueColor}-600`, 'border-r', 'border-t', 'border-b', 'text-white', 'py-1', 'px-1', 'rounded-r-sm'],
    on: {
      "click": function ($event) {
        return _vm.removeCondition();
      }
    }
  }, [_vm.edit ? _c('Icon', {
    staticClass: "w-3 h-3 cursor-pointer",
    attrs: {
      "name": "close"
    }
  }) : _vm._e()], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }