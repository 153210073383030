<template>
  <div
    class="border-b flex px-6 py-2 items-center flex-none"
    style="min-height: 4.1rem;"
  >
    <transition name="width">
      <div v-if="hasBurger" class="w-10 h-10 flex">
        <button
          class="x-toggle-sidebar-button flex -ml-5 text-gray-400 hover:text-gray-600 focus:outline-none"
          @click="toggleSidebar()"
        >
          <Icon class="mx-5 my-2" name="menu" :size="6"></Icon>
        </button>
      </div>
    </transition>

    <div class="flex flex-col">
      <portal-target name="top-bar-left">
        <h3 class="text-gray-800 font-extrabold flex">
          <portal-target name="top-bar-left-header-before"> </portal-target>
          <portal-target name="top-bar-left-header">
            {{ route.meta.publicName || route.name }}
          </portal-target>
          <portal-target name="top-bar-left-header-after"> </portal-target>
        </h3>
      </portal-target>
    </div>
    <div class="ml-auto block flex items-center">
      <Icon
        v-show="isLoading"
        name="loading"
        class="w-10 h-10 text-green-500 spin-animation block"
      />
      <div v-show="!isLoading" class="flex items-center">
        <portal-target name="top-bar-right">
          <ActiveUser
            impersonatingContentClass="bg-red-600 rounded-md text-white hover:text-white hover:bg-red-700 hidden md:flex"
            contentClass="text-gray-700 rounded-md hover:text-blue-400 hover:bg-blue-100 hidden md:flex"
            iconName="chevronDown"
            :enableResponsiveAvatar="true"
          />
        </portal-target>
      </div>
      <Tasks v-if="scope == 'admin'" />
    </div>
  </div>
</template>

<script>
import Tasks from '@/components/general/Tasks'

export default {
  props: {
    hasBurger: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String
    }
  },
  components: {
    Tasks
  },
  data() {
    return {}
  },
  computed: {
    route() {
      return this.$route
    },
    isLoading() {
      return this.$store.state.ui.globalLoader
    }
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggleSidebar')
    }
  }
}
</script>

<style scoped>
.x-toggle-sidebar-button {
  transition: 0.3s color;
}

.width-enter-active,
.width-leave-active {
  transition: 0.3s width;
}

.width-enter,
.width-leave-to {
  width: 0rem;
  opacity: 0;
}
</style>
