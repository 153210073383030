var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showAlert && !_vm.me.billingContact.paymentMethod ? _c('YAlert', {
    staticClass: "mt-0 mb-0 px-6",
    attrs: {
      "allow-close": false,
      "color": "orange"
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_c('h6', {
    staticClass: "mb-0 font-weight-semibold"
  }, [_vm._v(" Please complete your account set up by providing a "), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("Payment Method")]), _vm._v(" in the "), _c('span', {
    staticClass: "font-bold underline"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Publisher Finance'
      }
    }
  }, [_vm._v(" Finance")])], 1), _vm._v(" section. ")])])])]) : _vm._e(), _vm.showAlert && _vm.showCanadianTaxUpdate ? _c('YAlert', {
    staticClass: "mt-0 mb-0 px-6",
    attrs: {
      "allow-close": true,
      "color": "orange"
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_c('h6', {
    staticClass: "mb-0 font-weight-semibold"
  }, [_c('span', {
    staticClass: "font-bold mr-2"
  }, [_vm._v("NEW")]), _vm._v(" Canadian Users are required to provide "), _c('span', {
    staticClass: "font-semibold"
  }, [_vm._v("GST/HST")]), _vm._v(" numbers in the "), _c('span', {
    staticClass: "font-semibold underline"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Publisher Finance'
      }
    }
  }, [_vm._v(" Finance")])], 1), _vm._v(" section. ")])])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }