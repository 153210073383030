<template>
  <v-popover
    offset="10"
    placement="left-start"
    :autoHide="true"
    popoverClass=""
    :class="['']"
    popoverBaseClass="popover-menu sidebar-popover-menu"
    popoverInnerClass="popover-menu-inner animated animated-speed1 fadeIn"
  >
    <!-- This will be the popover target (for the events and position) -->
    <div class="inline-block tooltip-target">
      <slot>
        <YButton class="radius-full hover:bg-blue-100 text-blue-600 px-4"
          ><Icon name="dotsVertical" :size="4"
        /></YButton>
      </slot>
    </div>
    <!-- This will be the content of the popover -->
    <template slot="popover">
      <div class="list-group">
        <button
          type="button"
          class="btn-sm list-group-item list-group-item-action"
          @click="openDeleteModal(providers[0])"
        >
          <span class="text-danger"
            ><i class="zmdi zmdi-delete mr-2"></i> Delete</span
          >
        </button>
      </div>
    </template>
  </v-popover>
</template>

<script>
import ProviderDelete from './ProviderDelete'

export default {
  components: {},
  props: {
    providers: {
      type: Array,
      default: () => null
    }
  },
  apollo: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {
    openCreateModal() {},
    openDeleteModal(provider) {
      this.$modal.show(
        ProviderDelete,
        { provider: provider },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  }
}
</script>
<style></style>
