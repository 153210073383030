<template>
  <Widget>
    <WidgetHeader>Add a Postback URL</WidgetHeader>
    <WidgetBody class="pt-0">
      <YAlert
        class="mb-4"
        :allow-close="false"
        v-if="
          !lockRedirectId && !postback.redirect && postbackLevel == 'Account'
        "
      >
        Account Level Postbacks fire conversions on campaigns when no Campaign
        Level Postback is set.
      </YAlert>
      <form class="grid gap-2" @submit.prevent="update" ref="form">
        <div class="bg-gray-100 p-1 rounded-md">
          <Superselect
            v-if="allowAccountLevel && !hideLevel"
            title="Level"
            v-model="postbackLevel"
            :multiple="false"
            :options="['Account', 'Campaign']"
            @select="accountLevelSelected"
          ></Superselect>
          <Superselect
            v-if="!lockRedirectId && postbackLevel == 'Campaign'"
            title="Campaign"
            v-model="postback.redirect"
            track-by="id"
            label="campaignLabel"
            :multiple="false"
            :options="redirects || []"
          ></Superselect>
          <div>
            <div class="flex">
              <Superselect
                title="Protocol"
                v-model="postback.protocol"
                :multiple="false"
                :options="protocolOptions"
                :allow-empty="false"
                class="flex-shrink"
                select-class="border-r-0 rounded-r-none"
              ></Superselect>
              <YInput
                v-model="postback.url"
                :label="`your-postback-url.com/?s1={S1}&revenue={REVENUE}`"
                class="mb-0 flex-grow"
                input-class="border-l-0 rounded-l-none"
              ></YInput>
            </div>
            <span class="text-gray-600 text-xs p-2 pb-1 ">
              Available parameters are:
              <span class="font-semibold text-blue-700"
                >{S1} {S2} {S3} {S4} {S5} {REVENUE}</span
              >. Case insensitive.</span
            >
          </div>
        </div>
        <template>
          <div class="bg-gray-200 p-1 rounded-md">
            <div v-if="conversionTypes">
              <Superselect
                title="Conversion Type"
                :multiple="false"
                placeholder="Select"
                v-model="conversionType"
                :options="conversionTypeOptions"
              >
              </Superselect>
            </div>
            <div v-else>
              <Superselect
                title="Conversion Type"
                :multiple="false"
                placeholder="Select"
                v-model="conversionType"
                :options="allConversionTypes"
              >
              </Superselect>
            </div>
            <div
              class="pt-2 text-gray-900 flex align-center"
              v-if="conversionType"
            >
              <Icon name="information" size="5" class="mr-1 text-indigo-500" />
              <span v-html="conversionTypeDescription(conversionType)"></span>
            </div>
          </div>
        </template>
      </form>
    </WidgetBody>

    <WidgetFooter class="bg-gray-100 px-4 py-2 flex justify-between">
      <YButton @click="$emit('close')" class="btn-link">Cancel</YButton>
      <YButton
        @click="update"
        color="blue"
        :is-loading="isSubmitting"
        :disabled="!canSubmit"
        >Create Postback</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import PUBLISHER_REDIRECTS_QUERY from '@/graphql/Redirect/PublisherRedirectsQuery.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import CREATE_POSTBACK_MUTATION from '@/graphql/Postback/CreatePostbackMutation.gql'
import CONVERSION_TYPES_WITHOUT_RELATIONS_QUERY from '@/graphql/ConversionType/ConversionTypesWithoutRelationsQuery.gql'
import ConversionTypeDescription from '@/mixins/ConversionTypeDescriptionMixing.js'

export default {
  mixins: [ValidationErrorsMixin, ConversionTypeDescription],
  props: {
    userId: {
      required: true
    },
    lockRedirectId: {
      type: Number
    },
    allowAccountLevel: {
      type: Boolean,
      default: false
    },
    conversionTypes: {
      type: Array
    },
    addDefault: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  apollo: {
    allConversionTypes: {
      query: CONVERSION_TYPES_WITHOUT_RELATIONS_QUERY,
      variables() {
        return {
          first: 100,
          filters: {
            slug: {
              value: [
                'first-time-deposit',
                'qualified-first-time-deposit',
                'registration'
              ]
            }
          }
        }
      },
      update: data => data.conversionTypes,
      result({ data }) {
        this.allConversionTypes = data.conversionTypes.data.map(
          type => type.name
        )

        if (this.addDefault) {
          this.allConversionTypes = ['Default', ...this.allConversionTypes]
        }
      }
    },
    publisherRedirects: {
      query: PUBLISHER_REDIRECTS_QUERY,
      variables() {
        return {
          orderBy: this.orderBy,
          page: 1,
          first: 99999
        }
      },
      result({ data }) {
        this.redirects = data.publisherRedirects.data
          .map(redirect => ({
            id: redirect.id,
            label: redirect.label,
            campaignId: redirect.campaign ? redirect.campaign.id : null,
            campaignLabel: redirect.campaign ? redirect.campaign.label : null
          }))
          .filter(redirect => redirect.campaignId)
          .sort((a, b) =>
            parseInt(a.campaignId) < parseInt(b.campaignId) ? 1 : -1
          )
      },
      deep: true,
      skip() {
        return this.lockRedirectId ? true : false
      }
    }
  },
  data() {
    return {
      allConversionTypes: [],
      redirects: null,
      postback: {
        protocol: 'https://',
        redirect: this.lockRedirectId
      },
      hideLevel: true,
      postbackLevel: this.allowAccountLevel ? 'Account' : 'Campaign',
      protocolOptions: ['http://', 'https://'],
      isSubmitting: false,
      conversionType: this.addDefault ? 'Default' : null,
      useDefaultConversionType: this.default,
      conversionTypeOptions: this.conversionTypes ?? []
    }
  },
  watch: {},
  computed: {
    conversionTypeValue() {
      if (this.conversionType == null) {
        return null
      }

      if (typeof this.conversionType === 'object') {
        return this.conversionType.name
      }

      if (this.conversionType == 'Default') {
        return null
      }

      return this.conversionType
    },
    canSubmit() {
      if (!this.postback.url) {
        return false
      }
      if (this.postbackLevel == 'Campaign' && !this.postback.redirect) {
        return false
      }

      if (this.addDefault == false && this.conversionType == null) {
        return false
      }

      return true
    }
  },
  methods: {
    accountLevelSelected(value) {
      if (value == 'Campaign') {
        this.postback.redirect = undefined
      }
    },
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }
      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    update() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: CREATE_POSTBACK_MUTATION,
          variables: {
            input: {
              protocol: this.postback.protocol
                ? this.postback.protocol.replace('://', '')
                : 'http',
              url: this.postback.url,
              userId: this.userId ? this.userId : null,
              redirectId: this.lockRedirectId
                ? this.lockRedirectId
                : this.postback.redirect && this.postback.redirect.id
                ? this.postback.redirect.id
                : null,
              conversionType: this.conversionTypeValue
            }
          }
        })
        .then(result => {
          this.clearValidationErrors()
          this.$toastr.s('', 'Postback Created')
          this.isSubmitting = false
          this.$events.emit('postbackCreated', result.data.createPostback)
          this.$emit('close')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
        })
    }
  },
  mounted() {
    if (this.addDefault) {
      this.conversionTypeOptions = ['Default']
    }

    if (this.addDefault && this.conversionTypes) {
      this.conversionTypeOptions = ['Default', ...this.conversionTypes]
    }
  },
  updated() {}
}
</script>
<style scoped>
.text-opacity-light {
  opacity: 0.7;
}
.bg-danger .text-warning {
  color: #ffd800 !important;
}
.bg-dark-transparent-1 {
  background: rgba(0, 0, 0, 0.1);
}
</style>
