import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Dashboard from '../views/Admin/Dashboard/Dashboard.vue'
import Users from '../views/Admin/Users/Users.vue'
import User from '../views/Admin/User/User.vue'
import Rules from '../views/Admin/Rules/Rules.vue'
import CreateRule from '../views/Admin/Rules/CreateRule.vue'
import Offers from '../views/Admin/Offers/Offers.vue'
import OfferEditor from '../views/Admin/Offers/OfferEditor.vue'
import OffersEdit from '../views/Admin/Offers/OffersEdit.vue'
import Login from '../views/Auth/Login.vue'
import Register from '../views/Auth/Register.vue'
import Registered from '../views/Auth/Registered.vue'
import RegistrationComplete from '../views/Auth/RegistrationComplete.vue'
import References from '../views/Auth/References.vue'
import VerifiedEmail from '../views/Auth/VerifiedEmail.vue'
import ForgotPassword from '../views/Auth/ForgotPassword.vue'
import PasswordReset from '../views/Auth/PasswordReset.vue'
import CreatePassword from '../views/Auth/CreatePassword.vue'
import CreatePasswordComplete from '@/views/Auth/CreatePasswordComplete.vue'
import Advertiser from '../views/Admin/Advertiser/Advertiser.vue'
import Advertisers from '../views/Admin/Advertisers/Advertisers.vue'
import Redirects from '../views/Admin/Redirects/Redirects.vue'
import Redirect from '../views/Admin/Redirect/Redirect.vue'
import Sources from '../views/Admin/Sources/Sources.vue'
import Source from '../views/Admin/Source/Source.vue'
import DomainManager from '../views/Admin/DomainManager/DomainManager.vue'
import Tags from '../views/Admin/Tags/Tags.vue'
import Campaigns from '../views/Admin/Campaigns/Campaigns.vue'
import CampaignPreviews from '../views/Publisher/CampaignPreviews/CampaignPreviews.vue'
import Reports from '../views/Admin/Reports/Reports.vue'
import Templates from '../views/Admin/Templates/Templates.vue'
import TemplateImages from '../views/Admin/TemplateImages/TemplateImages.vue'
import Tools from '../views/Admin/Tools/Tools.vue'
import Algorithms from '../views/Admin/Algorithms/Algorithms.vue'
import SmartWeightQueue from '../views/Admin/Algorithms/SmartWeightQueue.vue'
import AlgorithmDataCleanUpHistory from '../views/Admin/Algorithms/CleanUpHistory.vue'
import Campaign from '../views/Admin/Campaign/Campaign.vue'
import Cache from '../views/Admin/Cache/Cache.vue'
import PublisherDashboard from '../views/Publisher/Dashboard/Dashboard.vue'
import PublisherWalkthrough from '../views/Publisher/Dashboard/WalkthroughTutorial.vue'
import PublisherPostbacks from '../views/Publisher/Postbacks/Postbacks.vue'
import PublisherReports from '../views/Publisher/Reports/Reports.vue'
import PublisherAccount from '../views/Publisher/Account/Account.vue'
import PublisherCampaigns from '../views/Publisher/Campaigns/PublisherCampaigns.vue'
import PublisherCampaign from '../views/Publisher/Campaign/Campaign.vue'
import PublisherFinance from '../views/Publisher/Finance/Finance.vue'
import Notifications from '../views/Admin/Notifications/Notifications.vue'
import Yleader from '../views/Admin/Yleader/YleaderHistory.vue'
import BulkRevenuesTool from '../views/Admin/Offers/BulkRevenuesTool.vue'
import Alf from '../views/Admin/Alf/Alf.vue'
import ConversionTypes from '../views/Admin/ConversionTypes/ConversionTypes.vue'
import Onboarding from '../views/Auth/Onboarding.vue'
import OnboardingComplete from '../views/Auth/OnboardingComplete.vue'
import Leads from '@/views/Admin/Leads/Leads.vue'
import Lead from '@/views/Admin/Leads/Lead.vue'
import ImageRepository from '@/views/Admin/ImageRepository/ImageRepository.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'auth',
      scope: 'auth',
      sidebar: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      layout: 'auth',
      scope: 'auth',
      sidebar: false,
      public: true
    },
    props: true
  },
  {
    path: '/registered',
    name: 'Registered',
    component: Registered,
    meta: {
      layout: 'contentOnly',
      scope: 'auth',
      sidebar: false,
      public: true
    }
  },
  {
    path: '/registration-complete',
    name: 'RegistrationComplete',
    component: RegistrationComplete,
    meta: {
      layout: 'auth',
      scope: 'auth',
      sidebar: false,
      public: true
    }
  },
  {
    path: '/verified-email/:token',
    name: 'VerifiedEmail',
    component: VerifiedEmail,
    meta: {
      layout: 'contentOnly',
      scope: 'auth',
      sidebar: false,
      public: true
    },
    props: true
  },
  {
    path: '/references/:token',
    name: 'References',
    component: References,
    meta: {
      layout: 'auth',
      scope: 'auth',
      sidebar: false,
      public: true
    },
    props: true
  },
  {
    path: '/crm',
    name: 'Onboarding',
    component: Onboarding,
    meta: {
      layout: 'contentOnly',
      scope: 'auth',
      sidebar: false,
      public: true
    },
    props: true
  },
  {
    path: '/crm-completed',
    name: 'Onboarding Complete',
    component: OnboardingComplete,
    meta: {
      layout: 'contentOnly',
      scope: 'auth',
      sidebar: false,
      public: true
    }
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    meta: {
      layout: 'auth',
      scope: 'auth',
      sidebar: false,
      public: true
    }
  },
  {
    path: '/password/reset/:token',
    name: 'Password Reset',
    component: PasswordReset,
    meta: {
      layout: 'auth',
      scope: 'auth',
      sidebar: false,
      public: true
    },
    props: true
  },
  {
    path: '/password/create/:token',
    name: 'Password Create',
    component: CreatePassword,
    meta: {
      layout: 'auth',
      scope: 'auth',
      sidebar: false,
      public: true
    },
    props: true
  },
  {
    path: '/password/completed',
    name: 'Password Successful',
    component: CreatePasswordComplete,
    meta: {
      layout: 'contentOnly',
      scope: 'auth',
      sidebar: false,
      public: true
    }
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    }
  },
  {
    path: '/admin/reports/:initialPresetId?',
    name: 'Reports',
    component: Reports,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    },
    sidebar: true,
    props: route => {
      return {
        enablePresets: true,
        initialPresetId: route.params.initialPresetId,
        reportId: route.params.reportId,
        showFilters: true,
        persistState: true
      }
      //showFilters: true
    }
  },
  {
    path: '/admin/report/:id?',
    name: 'Report',
    component: Reports,
    meta: {
      scope: 'admin',
      sidebar: false,
      parentMenu: 'Reports'
    },
    sidebar: true,
    props: route => {
      return {
        enablePresets: true,
        reportId: route.params.id,
        showFilters: true,
        persistState: true
      }
      //showFilters: true
    }
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: Users,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    },

    props: true
  },
  {
    path: '/admin/leads',
    name: 'Leads',
    component: Leads,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Users'
    }
  },
  {
    path: '/admin/lead/:id/:tab?/:urlAction?',
    name: 'Lead',
    component: Lead,
    props: true,
    meta: {
      scope: 'admin',
      sidebar: false,
      hasRightSideBar: true
    }
  },
  {
    path: '/admin/notifications',
    name: 'Notifications',
    component: Notifications,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Users'
    }
  },
  {
    path: '/admin/advertisers',
    name: 'Advertisers',
    component: Advertisers,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    }
  },
  {
    path: '/campaigns/preview/:id',
    name: 'Campaign Previews',
    component: CampaignPreviews,
    meta: {
      scope: 'public',
      sidebar: false
    },
    props: true
  },
  {
    path: '/admin/campaigns',
    name: 'Campaigns',
    component: Campaigns,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    }
  },
  {
    path: '/admin/campaign/:id/:tab?',
    name: 'Campaign',
    component: Campaign,
    meta: {
      scope: 'admin',
      sidebar: false,
      hasRightSideBar: true
    },
    props(route) {
      const props = { ...route.params }
      return props
    }
  },
  {
    path: '/admin/redirects',
    name: 'Redirects',
    component: Redirects,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    }
  },
  {
    path: '/admin/redirects/status/requested',
    name: 'RequestedRedirects',
    component: Redirects,
    props: {
      status: 'requested'
    },
    meta: {
      scope: 'admin',
      sidebar: false,
      publicName: 'Redirects'
    }
  },
  {
    path: '/admin/redirect/:id/:tab?',
    name: 'Redirect',
    component: Redirect,
    meta: {
      scope: 'admin',
      sidebar: false,
      hasRightSideBar: true
    },
    props: true
  },
  {
    path: '/admin/advertiser/:id/:tab?',
    name: 'Advertiser',
    component: Advertiser,
    meta: {
      scope: 'admin',
      sidebar: false,
      hasRightSideBar: true
    },
    props: true
  },
  {
    path: '/admin/sources',
    name: 'Sources',
    component: Sources,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Redirects'
    }
  },
  {
    path: '/admin/source/:id',
    name: 'Source',
    component: Source,
    meta: {
      scope: 'admin',
      sidebar: false
    },
    props: true
  },
  {
    path: '/admin/tags',
    name: 'Tags',
    component: Tags,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Settings'
    }
  },
  {
    path: '/admin/conversion-types',
    name: 'Conversion Types',
    component: ConversionTypes,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Settings'
    }
  },
  {
    path: '/admin/domain-manager',
    name: 'Domain Manager',
    component: DomainManager,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    }
  },
  {
    path: '/admin/user/:id/:tab?/:urlAction?',
    name: 'User',
    component: User,
    props: true,
    meta: {
      scope: 'admin',
      sidebar: false,
      hasRightSideBar: true
    }
  },
  {
    path: '/admin/rules',
    name: 'Rules',
    component: Rules,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Settings'
    }
  },
  {
    path: '/admin/offers/',
    name: 'Offers',
    component: Offers,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    }
  },
  {
    path: '/admin/offer/:id(\\d+)',
    name: 'Offer Edit',
    component: OfferEditor,
    meta: {
      scope: 'admin',
      sidebar: false
    },
    props: true
  },
  {
    path: '/admin/offer/:action/:id?',
    name: 'Offer',
    component: OfferEditor,
    meta: {
      scope: 'admin',
      sidebar: false
    },
    props: true
  },
  {
    path: '/admin/offers/payouts/:selectedOffers',
    name: 'Offers Payouts',
    component: BulkRevenuesTool,
    meta: {
      scope: 'admin',
      sidebar: false
    },
    props: true
  },
  {
    path: '/admin/offers/edit/:selectedOffers',
    name: 'Offers Edit',
    component: OffersEdit,
    meta: {
      scope: 'admin',
      sidebar: false
    },
    props: true
  },

  {
    path: '/admin/rule/create',
    name: 'Create Rule',
    component: CreateRule,
    meta: {
      scope: 'admin',
      sidebar: false
    }
  },
  {
    path: '/admin/yleader-history',
    name: 'yLeader',
    component: Yleader,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    }
  },
  {
    path: '/admin/templates',
    name: 'Templates',
    component: Templates,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Media'
    },
    props: true
  },
  {
    path: '/admin/template/:path',
    name: 'Template',
    component: Templates,
    meta: {
      scope: 'admin',
      sidebar: false
    },
    props: true
  },
  {
    path: '/admin/template-images',
    name: 'Template Images',
    component: TemplateImages,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Media'
    },
    props: true
  },
  {
    path: '/admin/media',
    name: 'Media',
    component: ImageRepository,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    },
    props: true
  },
  {
    path: '/admin/tools/:tab?',
    name: 'Tools',
    component: Tools,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Settings'
    },

    props: true
  },
  {
    path: '/admin/algorithms/:tab?',
    name: 'Algorithms',
    component: Algorithms,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    },
    props: true
  },
  {
    path: '/admin/smart-weight-queue',
    name: 'Smart Weight Queue',
    component: SmartWeightQueue,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Algorithms'
    },
    props: true
  },
  {
    path: '/admin/clean-up-history',
    name: 'Clean Up History',
    component: AlgorithmDataCleanUpHistory,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Algorithms'
    },
    props: true
  },
  {
    path: '/admin/alf/:tab?',
    name: 'Alf',
    component: Alf,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Settings'
    },
    props: true
  },
  {
    path: '/admin/cache',
    name: 'Cache',
    component: Cache,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenu: 'Settings'
    }
  },
  {
    path: '/publisher/dashboard',
    name: 'Publisher Dashboard',
    component: PublisherDashboard,
    meta: {
      publicName: 'Dashboard',
      scope: 'publisher',
      sidebar: true
    }
  },
  {
    path: '/publisher/walkthrough',
    name: 'Publisher Walkthrough',
    component: PublisherWalkthrough,
    meta: {
      publicName: 'Walkthrough',
      scope: 'publisher',
      sidebar: false
    }
  },
  {
    path: '/publisher/campaigns',
    name: 'Publisher Campaigns',
    component: PublisherCampaigns,
    meta: {
      publicName: 'Campaigns',
      scope: 'publisher',
      sidebar: true
    },

    props: false
  },
  {
    path: '/publisher/campaign/:id',
    name: 'Publisher Campaign',
    component: PublisherCampaign,
    meta: {
      publicName: 'Campaign',
      scope: 'publisher',
      sidebar: false
    },
    props: true
  },
  {
    path: '/publisher/reports',
    name: 'Publisher Reports',
    component: PublisherReports,
    meta: {
      publicName: 'Reports',
      scope: 'publisher',
      sidebar: true
    },
    props: false
  },
  {
    path: '/publisher/postbacks',
    name: 'Publisher Postbacks',
    component: PublisherPostbacks,
    meta: {
      publicName: 'Postbacks',
      scope: 'publisher',
      sidebar: true
    },
    props: false
  },
  {
    path: '/publisher/account/:tab?',
    name: 'Publisher Account',
    component: PublisherAccount,
    meta: {
      publicName: 'Account',
      scope: 'publisher',
      sidebar: true,
      alwaysShow: true
    },
    props: true
  },
  {
    path: '/publisher/finance/:tab?',
    name: 'Publisher Finance',
    component: PublisherFinance,
    meta: {
      publicName: 'Finance',
      scope: 'publisher',
      sidebar: true
    },
    props: true
  },
  {
    path: 'https://docs.ytrack.io',
    name: 'yDocs',
    meta: {
      publicName: 'yDocs',
      scope: 'all',
      sidebar: true,
      newTab: true,
      parentMenu: 'Settings'
    }
  },
  {
    path: '/admin/dashboard',
    name: 'Settings',
    component: Dashboard,
    meta: {
      scope: 'admin',
      sidebar: true,
      parentMenuItem: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Global Guards
router.beforeEach((to, from, next) => {
  store.dispatch('refetchAuthFromStorage')
  if (
    !store.getters.isAuthenticated &&
    !(to.meta.scope == 'public' || to.meta.scope == 'auth')
  )
    next({ name: 'Login' })
  else next()
})

export default router
