var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('button', {
    staticClass: "flex justify-between w-full items-center",
    class: _vm.clickableClass,
    on: {
      "click": _vm.toggleExpanded
    }
  }, [_c('span', {
    staticClass: "flex items-center"
  }, [_vm._t("title", function () {
    return [_vm._v(" " + _vm._s(_vm.title))];
  })], 2), _c('span', [_c('Icon', {
    attrs: {
      "name": _vm.icon,
      "size": _vm.iconSize
    }
  })], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.expanded,
      expression: "expanded"
    }],
    staticClass: "pt-1",
    class: _vm.contentClass
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }