<template>
  <div>
    <Widget
      class="mb-1 overflow-visible"
      :loading="$apolloData.queries.offers.loading"
    >
      <WidgetHeader class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <BulkOfferTransformer
            ref="bulkOfferTransformer"
            :offers-to-edit="offers ? offers.data : []"
            @offersTransformed="updateModifiedOffers"
            @warningToggled="warningToggled"
          >
            <template v-slot:beforeButtons>
              <YButton
                class="mx-2"
                color="blue"
                :disabled="saveDisabled"
                @click="bulkUpdateOffers"
                :is-loading="isSubmitting"
                >Save</YButton
              >
            </template>
          </BulkOfferTransformer>
        </template>
      </WidgetHeader>
    </Widget>

    <YTable
      v-if="offersModified"
      :data="offersModified"
      :loading="$apolloData.queries.offers.loading"
    >
      <template slot="header-row">
        <th></th>
        <th>Destination</th>
        <th>Vertical</th>
        <th>Mobile / Desktop</th>
        <th>Geo</th>
      </template>

      <template slot="row" slot-scope="props">
        <td>
          <div class="flex items-center">
            <div
              class="flex justify-between items-center flex-1"
              style="max-width: 35rem;"
            >
              <div class="mx-4">
                <router-link
                  :to="{
                    name: 'Offer',
                    params: { id: props.rowData.id, action: 'edit' }
                  }"
                  class="font-bold break-words without-truncate inline-block"
                  :class="
                    props.rowData.isCapped
                      ? 'text-red-600 hover:text-red-700'
                      : 'link'
                  "
                >
                  <template v-if="props.rowData.id"
                    >{{ props.rowData.id }} -
                  </template>
                  {{ props.rowData.name }}
                </router-link>
                <sup class="text-gray-500 font-semibold">
                  {{ props.rowData.encodedId }}
                </sup>
              </div>
            </div>
          </div>
        </td>

        <td>
          <div
            v-html="
              props.rowData.highlightedDestination || props.rowData.destination
            "
          ></div>
        </td>
        <td>
          <template
            v-if="props.rowData.verticals && props.rowData.verticals.tags"
          >
            <Tag
              v-for="tagName in props.rowData.verticals.tags"
              :color="props.rowData.verticals.isNot ? 'red' : 'blue'"
              :key="tagName"
              style="margin: 1px;"
            >
              {{ tagName }}
            </Tag>
          </template>
        </td>
        <td>
          <template
            v-if="
              props.rowData.mobileDesktop && props.rowData.mobileDesktop.tags
            "
          >
            <Tag
              v-for="tagName in props.rowData.mobileDesktop.tags"
              :color="props.rowData.mobileDesktop.isNot ? 'red' : 'blue'"
              :key="tagName"
              style="margin: 1px;"
            >
              {{ tagName }}
            </Tag>
          </template>
        </td>
        <td>
          <template
            v-if="props.rowData.countries && props.rowData.countries.tags"
          >
            <Tag
              v-for="tagName in props.rowData.countries.tags"
              :color="props.rowData.countries.isNot ? 'red' : 'blue'"
              :key="tagName"
              style="margin: 1px;"
            >
              {{ tagName }}
            </Tag>
          </template>
        </td>
        <!-- <td v-for="type of tagTypes" :key="type">
          <div
            v-if="
              offerTagsByType[`${props.rowData.id}-${type}`] &&
                offerTagsByType[`${props.rowData.id}-${type}`].length > 0
            "
          >
            <Tag
              v-for="tag in offerTagsByType[`${props.rowData.id}-${type}`]"
              :color="tag.isNot ? 'red' : 'gray'"
              :key="tag.name"
              style="margin: 1px;"
            >
              {{ tag.name }}
            </Tag>
          </div>
        </td> -->
      </template>
    </YTable>
  </div>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import OFFERS_QUERY from '@/graphql/Offer/OffersQuery.gql'
import BulkOfferTransformer from '@/views/Admin/Offers/BulkOfferTransformer'

import BULK_UPDATE_OFFERS_MUTATION from '@/graphql/Offer/BulkUpdateOffersMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],
  components: {
    BulkOfferTransformer
  },
  props: {
    selectedOffers: {
      type: String,
      required: true
    }
  },
  apollo: {
    offers: {
      query: OFFERS_QUERY,
      variables() {
        return {
          first: 100,
          filters: {
            id: {
              value: this.selectedOfferIdArray
            }
          },
          orderBy: [
            {
              column: 'id',
              order: 'DESC'
            }
          ]
        }
      }
    }
  },
  data() {
    return {
      offers: [],
      verticalSelected: [],
      countrySelected: [],
      mobileDesktopSelected: [],
      urlSelected: '',
      offersModified: [],
      saveDisabled: false,
      isSubmitting: false
    }
  },
  updated() {},
  mounted() {},
  computed: {
    selectedOfferIdArray() {
      return this.selectedOffers.split(',')
    }
  },

  methods: {
    warningToggled(value) {
      this.saveDisabled = value
    },
    updateModifiedOffers(offers) {
      this.offersModified = offers
    },
    bulkUpdateOffers() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: BULK_UPDATE_OFFERS_MUTATION,
          variables: {
            input: this.offersModified.map(offer => {
              delete offer.mobileDesktop.type
              delete offer.verticals.type
              delete offer.countries.type
              return {
                id: offer.id,
                destination: offer.destination,
                mobileDesktop: offer.mobileDesktop,
                verticals: offer.verticals,
                countries: offer.countries
              }
            })
          }
        })
        .then(() => {
          this.$toastr.s('Offers have been updated')
          this.$tabEvent.emit('refreshOffersTable')
          this.isSubmitting = false
          this.$refs.bulkOfferTransformer.clearSelections()
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
          this.$toastr.e('Offers did not update')
        })
    }
  }
}
</script>
