var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.autoSubmit,
      expression: "!autoSubmit"
    }]
  }, [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Set Account Manager ")]), _c('WidgetBody', [_c('Superselect', {
    attrs: {
      "title": "Manager",
      "multiple": false,
      "track-by": "id",
      "label": "label",
      "query": _vm.ACCOUNT_MANAGER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: [],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      }
    },
    model: {
      value: _vm.accountManager,
      callback: function ($$v) {
        _vm.accountManager = $$v;
      },
      expression: "accountManager"
    }
  })], 1), _c('WidgetFooter', {
    staticClass: "py-2 px-4 flex justify-between bg-gray-100"
  }, [_c('YButton', {
    attrs: {
      "color": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "blue",
      "disabled": !_vm.accountManager,
      "is-loading": _vm.isUpdating
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Set Manager")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }