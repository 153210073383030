var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    staticClass: "bg-gray-100",
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Create New Key ")]), _c('widget-body', {
    staticClass: "bg-gray-100"
  }, [_c('form', {
    ref: "form",
    staticClass: "flex",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_c('Superselect', {
    staticClass: "flex-grow",
    attrs: {
      "title": "Key Name",
      "multiple": false,
      "options": _vm.accountKeyTypeEnum,
      "inputClass": "border-r-0 border-l-0 rounded-l-none rounded-r-none"
    },
    model: {
      value: _vm.key.name,
      callback: function ($$v) {
        _vm.$set(_vm.key, "name", $$v);
      },
      expression: "key.name"
    }
  }), _c('YInput', {
    staticClass: "flex-grow",
    attrs: {
      "label": "Key Value",
      "inputClass": "border-r-0 border-l-0 rounded-l-none rounded-r-none"
    },
    model: {
      value: _vm.key.value,
      callback: function ($$v) {
        _vm.$set(_vm.key, "value", $$v);
      },
      expression: "key.value"
    }
  }), _c('y-button', {
    staticClass: "btn btn-blue rounded-l-none",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.saveKey,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.saveKey.apply(null, arguments);
      }
    }
  }, [_vm._v("Add Key")])], 1)]), _vm.advertiserAccountKeys ? _c('YTable', {
    attrs: {
      "data": _vm.advertiserAccountKeys.data || [],
      "bottom-loading": _vm.$apolloData.queries.advertiserAccountKeys.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [props.rowData.name ? [_vm._v(_vm._s(props.rowData.name))] : _vm._e()], 2), _c('td', [props.rowData.name ? [_vm._v(_vm._s(props.rowData.value))] : _vm._e()], 2), _c('td', {
          staticClass: "text-right"
        }, [_c('a', {
          staticClass: "text-red-600 hover:text-red-700",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.deleteKey(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          staticClass: "inline",
          attrs: {
            "name": "trashCan",
            "size": 4
          }
        })], 1)])];
      }
    }], null, false, 2765966990)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Value")]), _c('th')])], 2) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }