var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Tabs', {
    attrs: {
      "show-type": "show",
      "route": _vm.tabRoute
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Bulk Payouts",
      "active": true
    }
  }, [_c('bulk-payouts')], 1), _c('Tab', {
    attrs: {
      "name": "Add Adjustment"
    }
  }, [_c('adjustments')], 1), _c('Tab', {
    attrs: {
      "name": "Publisher Balance Upload"
    }
  }, [_c('publisher-balance-upload')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }