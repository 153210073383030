<template>
  <widget>
    <widget-header @close="$emit('close')">
      <div class="flex">
        <span class="upperCase" v-text="transformText"></span>

        <Icon
          :name="
            eventType == 'positive' ? 'alertCircleOutline' : 'alertOctagon'
          "
          size="6"
          class="ml-2"
          :class="eventType == 'positive' ? 'text-blue-600' : 'text-red-600'"
        />
      </div>
    </widget-header>
    <widget-body>
      <div class="x-form px-3" v-if="eventType">
        <textarea
          class="px-3 py-3 w-full rounded mb-2 border block"
          v-model="message"
          placeholder="Type here..."
          @keydown.exact.prevent.enter="saveAndClose()"
        />
      </div>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <YButton color="blue" @click="saveAndClose"
        >Add <span class="upperCase" v-text="transformText"></span
      ></YButton>
    </widget-footer>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import CREATE_COMMENT_MUTATION from '@/graphql/Comment/CreateCommentMutation.gql'

export default {
  components: {},
  mixins: [ValidationErrorsMixin],
  props: {
    userId: {
      type: String,
      required: true
    },
    eventType: {
      type: String
    }
  },
  data() {
    return {
      message: '',
      isSubmitting: false
    }
  },
  mounted() {
    console.log('props', this.userId, this.eventType)
  },
  updated() {},
  computed: {
    transformText() {
      let eventName = this.eventType.replace(/([A-Z])\w+/g, '')
      if (eventName !== 'positive') {
        return eventName + ' Complaint'
      } else {
        return eventName + ' Feedback'
      }
    }
  },
  methods: {
    saveAndClose() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: CREATE_COMMENT_MUTATION,
          variables: {
            input: {
              modelId: this.userId,
              modelClass: 'User',
              message: this.message,
              commentType: this.eventType,
              tags: null
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$emit('close')
          this.$events.emit('refreshCommunications')
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.isSubmitting = false
          console.log('ERROR', error)
        })
    }
  }
}
</script>

<style>
.upperCase {
  text-transform: capitalize;
}
</style>
