<template>
  <div>
    <div class="w-full">
      <div class="grid grid-cols-4 gap-2">
        <div class="grid grid-cols-1 gap-2">
          <div>
            <Tabs show-type="show">
              <Tab name="Overview" :active="true">
                <Widget class="rounded-t-none border-t-0">
                  <WidgetBody>
                    <div class="overflow-x-auto">
                      <div class="py-1">
                        <label class="text-muted d-block mb-0">ID:</label>
                        {{ id }}
                      </div>
                      <div class="py-1">
                        <label class="text-muted d-block mb-0">Keyword:</label>
                        <Spinner
                          v-if="$apollo.queries.source.loading"
                          :active="true"
                          :size="8"
                        ></Spinner>
                        <template v-else>
                          <template v-if="source.keyword">
                            {{ source.keyword }}
                          </template>
                          <span v-else class="font-italic text-muted"
                            >None</span
                          >
                        </template>
                      </div>
                      <div class="py-1" v-if="source.user">
                        <label class="text-muted d-block mb-0"
                          >Publisher:</label
                        >
                        <Spinner
                          v-if="$apollo.queries.source.loading"
                          :active="true"
                          :size="8"
                        ></Spinner>
                        <router-link
                          :to="{
                            name: 'User',
                            params: { id: source.user.id }
                          }"
                          class="link"
                          >{{ source.user.label }}</router-link
                        >
                      </div>
                      <div class="py-1" v-if="source.redirect">
                        <label class="text-muted d-block mb-0">Redirect:</label>
                        <Spinner
                          v-if="$apollo.queries.source.loading"
                          :active="true"
                          :size="8"
                        ></Spinner>
                        <router-link
                          :to="{
                            name: 'Redirect',
                            params: { id: source.redirect.id }
                          }"
                          class="link"
                          >{{ source.redirect.label }}</router-link
                        >
                      </div>
                      <span class="ts-2">
                        <tag v-if="isAbyssed" color="red" class="mr-1"
                          >Abyssed</tag
                        >
                      </span>
                      <div class="py-1">
                        <YButton
                          v-if="isAbyssed"
                          @click="unabyssSource"
                          type="button"
                          class="btn-green"
                          >Undo Abyss</YButton
                        >
                        <YButton
                          v-else-if="isAbyssed != null && !isAbyssed"
                          @click="abyssSource"
                          type="button"
                          class="btn-red"
                          >Abyss</YButton
                        >
                      </div>
                    </div>
                  </WidgetBody>
                </Widget>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div class="col-span-3">
          <Tabs show-type="show">
            <Tab name="Stats" :active="true">
              <div>
                <simple-report :filters="filters" class="col-span-4">
                  <h4 slot="table-header" class="widget-title">
                    Daily Summary
                  </h4>
                  <div slot="right">
                    <router-link
                      class="link text-xs"
                      :to="{
                        name: 'Reports'
                      }"
                    >
                      VIEW REPORTS
                    </router-link>
                  </div>
                </simple-report>
              </div>
            </Tab>
            <Tab name="Payouts">
              <Payouts
                v-if="source && source.payouts && source.redirect"
                :payouts="source.payouts"
                :sourceId="this.id"
                :redirectId="Number(this.source.redirect.id)"
                level="source"
              ></Payouts>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SimpleReport from '@/views/Admin/Reports/SimpleReport'
import Payouts from '@/views/Admin/Payouts/Payouts'
import SOURCE_QUERY from '@/graphql/Source/SourceQuery.gql'

export default {
  components: {
    Payouts,
    SimpleReport
  },
  apollo: {
    source: {
      query: SOURCE_QUERY,
      variables() {
        return {
          id: this.id,
          searchRange: this.searchRange
        }
      },
      deep: true,
      fetchPolicy: 'network-only',
      result({ data }) {
        this.source = data?.source ?? {}
        this.isAbyssed = data.source.isAbyssed
        if (!data.source.redirect) {
          this.searchRange = 'all'
        }
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filters: {
        source: {
          value: [{ label: this.id, id: this.id }],
          modifiers: { exclude: false, edit: true }
        }
      },
      isAbyssed: null,
      isAbyssing: false,
      isUnabyssing: false,
      sourceIdGo: null,
      source: {},
      searchRange: 'last-7-days',
      payouts: []
    }
  },
  mounted() {
    this.$events.listen('refetchPayouts', () => {
      this.$apollo.queries.source.refetch()
    })
  },
  methods: {
    abyssSource() {
      this.isAbyssing = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation SourceAbyss($sourceId: ID!) {
              sourceAbyss(sourceId: $sourceId) {
                id
                isAbyssed
              }
            }
          `,
          variables: {
            sourceId: this.id
          }
        })
        .then(() => {
          this.isAbyssing = false
        })
        .catch(() => {
          this.isAbyssing = false
        })
    },
    unabyssSource() {
      this.isAbyssing = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation SourceUnabyss($sourceId: ID!) {
              sourceUnabyss(sourceId: $sourceId) {
                id
                isAbyssed
              }
            }
          `,
          variables: {
            sourceId: this.id
          }
        })
        .then(() => {
          this.isAbyssing = false
        })
        .catch(() => {
          this.isAbyssing = false
        })
    }
  }
}
</script>

<style></style>
