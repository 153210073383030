<template>
  <div
    :class="`w-${width} h-${height} relative overflow-hidden text-gray-300`"
    @click="$emit('click')"
  >
    <img v-if="src" :src="src" :class="`w-${width} h-${height} block`" />
    <svg
      v-else
      :class="
        `feather feather-image w-${blankWidth || width} h-${blankHeight ||
          height} block m-auto`
      "
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
      <circle cx="8.5" cy="8.5" r="1.5" />
      <polyline points="21 15 16 10 5 21" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String
    },
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    blankWidth: {
      type: Number
    },
    blankHeight: {
      type: Number
    }
  }
}
</script>

<style></style>
