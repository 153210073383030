<template>
  <div v-if="showType == 'if' && isActive" class="flex-grow max-w-full">
    <slot></slot>
  </div>
  <div
    v-else-if="showType == 'show'"
    v-show="isActive"
    class="flex-grow max-w-full"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    name: {
      required: true
    },
    icon: {
      type: String
    },
    iconClass: {
      type: [String, Array]
    },
    active: {
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideName: {
      type: Boolean,
      default: false
    },
    showType: {
      type: String,
      default: function() {
        return this.$parent.showType
      }
    },
    tag: {
      default: null
    },
    tagColor: {
      default: 'light'
    }
  },
  data() {
    return {
      isActive: false
    }
  },
  computed: {
    slug() {
      return this.stringToSlug(this.name)
    }
  },
  mounted() {
    this.isActive = this.active
  },

  updated() {},

  methods: {
    stringToSlug(str) {
      str = String(str).toString()
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()

      return str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes
        .replace(/^-+/, '') // trim - from start of text
        .replace(/-+$/, '')
    }
  }
}
</script>
<style></style>
