var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tabs', {
    attrs: {
      "persist-as": "redirects"
    }
  }, [_c('tab', {
    attrs: {
      "name": "Redirects",
      "active": true
    }
  }, [_c('RedirectsTable', {
    attrs: {
      "override-filters": this.overrideFilters,
      "overrideGqlFilters": this.overrideGqlFilters,
      "status": this.status
    }
  })], 1), _c('tab', {
    attrs: {
      "name": "Auto Abyssed"
    }
  }, [_c('AbyssedRedirects')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }