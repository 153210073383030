var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    on: {
      "click": function ($event) {
        _vm.disabled ? null : _vm.click();
      }
    }
  }, [!_vm.showReplacement ? [_vm._t("default")] : _vm._e(), _vm.showReplacement ? [_vm._t("replacement")] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }