<template>
  <div>
    <span
      @click="toggleAccordion()"
      class="flex items-center space-x-3 mb-6"
      :aria-expanded="isOpen"
    >
      <span class="titleText"><slot name="title"/></span>
      <Icon
        name="chevronDown"
        size="5"
        :class="{ toggleTransition: isOpen }"
        class="text-inverse-700"
      />
    </span>

    <div v-show="isOpen">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false
    }
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped>
.toggleTransition {
  transition: all 0.3s ease-in-out;
  transform: rotate(-180deg);
}
.titleText {
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 600;
  color: #304b69;
}
</style>
