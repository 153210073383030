var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    staticClass: "tiny-forms bg-primary blue pt-4 pb-4 text-white"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 ts-4"
  }, [_c('div', {}, [_c('i', {
    staticClass: "zmdi -file-plzmdius pr-2"
  }), _vm._v(" yReplace ")]), _c('close-x', {
    staticClass: "text-white",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  })], 1)])], 2), _c('widget-body', {
    staticClass: "pt-5"
  }, [_c('div', [_c('y-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_c('multiselect', {
    attrs: {
      "title": "Type",
      "options": ['mini', 'ip'],
      "multiple": false
    },
    model: {
      value: _vm.replacementType,
      callback: function ($$v) {
        _vm.replacementType = $$v;
      },
      expression: "replacementType"
    }
  })], 1)], 1), _c('div', [_c('y-form-group', {
    attrs: {
      "label": "Value"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.replacementValue,
      expression: "replacementValue"
    }],
    staticClass: "form-control small-input-size",
    attrs: {
      "placeholder": "Replacement Value {IP/Mini Domain}"
    },
    domProps: {
      "value": _vm.replacementValue
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.replacementValue = $event.target.value;
      }
    }
  })])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.replacementType == 'mini',
      expression: "replacementType == 'mini'"
    }]
  }, [_c('y-form-group', {
    attrs: {
      "label": "Registrar (Empty Allowed)"
    }
  }, [_c('multiselect', {
    attrs: {
      "title": "Type",
      "options": _vm.registrars,
      "multiple": false
    },
    on: {
      "close": function ($event) {
        return _vm.updateDomains();
      }
    },
    model: {
      value: _vm.specifiedRegistrar,
      callback: function ($$v) {
        _vm.specifiedRegistrar = $$v;
      },
      expression: "specifiedRegistrar"
    }
  })], 1), _c('y-form-group', {
    attrs: {
      "label": "Domains (Leave Empty to Auto Select)"
    }
  }, [_c('multiselect', {
    attrs: {
      "title": "Domain",
      "options": _vm.options.ydnsDomains,
      "multiple": false,
      "track-by": "label",
      "label": "label"
    },
    model: {
      value: _vm.replacementDomain,
      callback: function ($$v) {
        _vm.replacementDomain = $$v;
      },
      expression: "replacementDomain"
    }
  })], 1)], 1)]), _c('widget-footer', {
    staticClass: "pt-4 pb-4"
  }, [_c('y-checkbox', {
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('y-button', {
    staticClass: "btn btn-primary btn-sm ml-auto",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button"
    },
    on: {
      "click": _vm.submit,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_vm._v("Submit")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }