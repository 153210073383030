<template>
  <widget>
    <widget-header class="background-gradient-green-blue text-white tiny-forms">
      <i class="zmdi zmdi-file-plus pr-2"></i> Create New Advertiser Account
      <h4 slot="right">
        <close-x @click="$emit('close')" class="text-white"></close-x>
      </h4>
    </widget-header>
    <widget-body>
      <form @submit.prevent="update" ref="form" class="x-form">
        <YInput v-model="advertiserAccount.username" label="User Name"></YInput>
        <YInput v-model="advertiserAccount.password" label="Password"></YInput>
        <Superselect
          title="yLeader Type"
          v-model="advertiserAccount.yleaderType"
          :multiple="false"
          :options="yLeadtypeOptions"
        ></Superselect>
        <Superselect
          title="Conversion Type"
          v-model="advertiserAccount.conversionType"
          :multiple="false"
          :taggable="true"
          :options="conversionTypeOptions"
        ></Superselect>
        <Superselect
          title="Duplication Handler"
          v-model="advertiserAccount.duplicationHandler"
          :multiple="false"
          :options="duplicationHandler"
        ></Superselect>
      </form>
    </widget-body>
    <widget-footer class="px-4 py-2 bg-gray-100 text-right">
      <y-button
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="create"
        @keydown.enter="create"
        >Create Account</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import CONVERSION_TYPES_QUERY from '@/graphql/ConversionType/ConversionTypesQuery.gql'

export default {
  mixins: [ValidationErrorsMixin],

  props: {
    user_id: {
      type: Number,
      required: true
    }
  },
  components: {},
  apollo: {
    conversionTypes: {
      query: CONVERSION_TYPES_QUERY,
      variables() {
        return {
          first: 999
        }
      },
      result({ data }) {
        this.conversionTypeOptions = data?.conversionTypes?.data.map(
          object => object.name
        )
      }
    }
  },
  data() {
    return {
      advertiserAccount: {},
      conversionTypeOptions: [],
      isSubmitting: false,
      colSize: 'col-xl-3 col-lg-6',
      colSize2: 'col-xl-6 col-lg-12',
      yLeadtypeOptions: ['lead', 'real', 'none'],
      duplicationHandler: [
        'ignore-duplicates',
        'allow-duplicates-diff',
        'allow-duplicates'
      ]
    }
  },
  watch: {},
  computed: {},
  methods: {
    create() {
      this.isSubmitting = true
      let input = {
        userId: this.user_id,
        userName: this.advertiserAccount.username,
        password: this.advertiserAccount.password,
        yleadType: this.advertiserAccount.yleaderType,
        conversionType: this.advertiserAccount.conversionType,
        duplicationHandler: this.advertiserAccount.duplicationHandler,
        active: true
      }

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createAdvertiserAccount(
              $input: CreateAdvertiserAccountInput!
            ) {
              createAdvertiserAccount(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: input
          }
        })
        .then(result => {
          /*toastr.success(
            'Advertiser data has been successfully saved',
            'Success!'
          )*/
          console.log(result)
          this.isSubmitting = false
          this.clearValidationErrors()
          this.$events.emit('refreshAdvertiserAccounts', true)
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
          this.setValidationErrors(error)
          //toastr.error(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  },
  close() {
    this.$emit('close')
  },
  mounted() {},
  updated() {}
}
</script>
<style scoped>
.x-form > *:not(:last-child) {
  @apply mb-2;
}
</style>
