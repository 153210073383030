<template>
  <div class="action-bar flex justify-between items-center px-4">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {}
}
</script>
<style scoped>
.action-bar {
  height: 4rem;
  background: #fff;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.05);
}
</style>
