<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import DefaultLayout from '@/components/layouts/Default.vue'
import AuthLayout from '@/components/layouts/Auth.vue'
import ContentOnlyLayout from '@/components/layouts/ContentOnly.vue'

export default {
  name: 'yTrack',
  components: {
    DefaultLayout,
    AuthLayout,
    ContentOnlyLayout
  },
  created() {
    this.$toastr.defaultPosition = 'toast-bottom-right' // default position: toast-top-right
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  },
  methods: {}
}
</script>
<style></style>
