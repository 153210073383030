<template>
  <div>
    <Widget>
      <WidgetBody class="bg-gray-100">
        <div class="flex justify-between">
          <div></div>
          <y-button
            class="btn btn-blue"
            @click="openCreateAdvertiserContactModal"
          >
            <i class="zmdi zmdi-file-plus pr-2"></i> New Contact
          </y-button>
        </div>
      </WidgetBody>

      <YTable
        v-if="contacts"
        :data="contacts.data || []"
        :bottom-loading="$apolloData.queries.contacts.loading"
      >
        <template slot="header-row">
          <th>Name</th>
          <th>Email</th>
          <th>Actions</th>
        </template>
        <template slot="row" slot-scope="props">
          <td>
            <template v-if="props.rowData.id">
              {{ props.rowData.name }}
            </template>
          </td>
          <td>
            <template v-if="props.rowData.id">
              {{ props.rowData.email }}
            </template>
          </td>

          <td>
            <div class="inline-flex items-center">
              <a
                href="#"
                class="link"
                @click.prevent="
                  openEditModal(
                    props.rowData.id,
                    props.rowData.name,
                    props.rowData.email
                  )
                "
              >
                <Icon name="pencil" class="w-4 h-4" />
              </a>
              <a
                href="#"
                class="text-red-600 hover:text-red-700"
                @click.prevent="
                  openDeleteModal(
                    props.rowData.id,
                    props.rowData.name,
                    props.rowData.email
                  )
                "
              >
                <Icon class="inline" name="trashCan" :size="4" />
              </a>
            </div>
          </td>
        </template>
      </YTable>
    </Widget>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import AdvertiserContactCreate from '@/views/Admin/Advertiser/AdvertiserContactCreate.vue'
import AdvertiserContactDelete from '@/views/Admin/Advertiser/AdvertiserContactDelete.vue'
import AdvertiserContactEdit from '@/views/Admin/Advertiser/AdvertiserContactEdit.vue'

export default {
  apollo: {
    contacts: {
      query: gql`
        query contacts($filters: ContactsFilterInput, $first: Int!) {
          contacts(filters: $filters, first: $first) {
            data {
              id
              user_id
              name
              email
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            userId: { value: this.id },
            type: { value: 'advertiser' }
          },
          first: 1000000
        }
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    tab: {
      type: String,
      default: 'stats'
    }
  },
  data() {
    return {}
  },
  methods: {
    openCreateAdvertiserContactModal() {
      this.$modal.show(
        AdvertiserContactCreate,
        { user_id: parseInt(this.id) },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDeleteModal(id, name, email) {
      this.$modal.show(
        AdvertiserContactDelete,
        { id: parseInt(id), name: name, email: email },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openEditModal(id, name, email) {
      this.$modal.show(
        AdvertiserContactEdit,
        { id: parseInt(id), name: name, email: email },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  },
  computed: {},
  mounted() {
    this.$events.listen('refreshAdvertiserContacts', () => {
      this.$apollo.queries.contacts.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refreshAdvertiserContacts')
  }
}
</script>
