<template>
  <div id="top">
    <Widget class="mb-2">
      <WidgetHeader class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <TemplateImagesFilters></TemplateImagesFilters>
        </template>
      </WidgetHeader>
    </Widget>

    <TemplateImagesTable
      :data="templateImages ? templateImages.data : []"
      :order-by="orderBy"
      :key="refetch"
    ></TemplateImagesTable>

    <YPagination
      v-if="templateImages.paginatorInfo"
      :data="templateImages.paginatorInfo"
      @load-page="loadPage"
      @next-page="nextPage"
      @previous-page="previousPage"
      :key="totalPages"
      @per-page="perPageSelection"
    >
    </YPagination>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TemplateImagesFilters from '@/views/Admin/TemplateImages/TemplateImagesFilters.vue'
import TemplateImagesTable from '@/views/Admin/TemplateImages/TemplateImagesTable'
import TEMPLATE_IMAGES_QUERY from '@/graphql/TemplateImages/TemplateImagesQuery.gql'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'

export default {
  mixins: [BackToTop],
  components: {
    TemplateImagesTable,
    TemplateImagesFilters,
    YPagination
  },
  apollo: {
    templateImages: {
      query: TEMPLATE_IMAGES_QUERY,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          first: this.perPage,
          page: this.currentPage,
          orderBy: this.orderBy,
          filters: {
            name: this.cleanFilter(this.filters.name),
            tags: this.cleanFilter(this.filters.tags)
          }
        }
      },
      result({ data }, key) {
        if (data) {
          this.totalPages = data[key].paginatorInfo.lastPage
          this.refetch = false
        }
      },
      deep: true,
      refetch: false
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      templateImages: {},
      refetch: false,
      orderBy: [
        {
          column: 'id',
          order: 'DESC'
        }
      ]
    }
  },
  mounted() {
    this.refetch = false
    this.$events.listen('refreshTemplateImages', () => {
      this.$apollo.queries.templateImages.refetch()
      this.refetch = true
    })
  },
  updated() {},
  computed: {
    ...mapState({
      filters: state => state.templateImages.filters
    })
  },
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    ...mapActions(['updateGlobalLoader']),
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    }
  },
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  }
}
</script>

<style scoped>
li.highlight {
  @apply font-extrabold;
}
</style>
