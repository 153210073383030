var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.caps ? _c('YTable', {
    staticClass: "table-striped table-hover overflow-hidden",
    attrs: {
      "block-style": "min-height: 25rem;",
      "scroll-x": false,
      "data": _vm.caps.data
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(" " + _vm._s(props.rowData.frequency.capitalize()) + " ")]), _c('td', [_vm._v(" " + _vm._s(props.rowData.type.capitalize()) + " ")]), _c('td', [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('span', [_vm._v(" " + _vm._s(props.rowData.triggerable && props.rowData.triggerable.__typename === 'Redirect' ? props.rowData.triggerable.label : '') + " ")]), _c('span', {
          staticClass: "text-xs italic text-gray-700"
        }, [_vm._v(_vm._s(props.rowData.triggerable && props.rowData.triggerable.user ? props.rowData.triggerable.user.label : ''))])])]), _c('td', [_vm._v(" " + _vm._s(props.rowData.triggerable && props.rowData.triggerable.__typename === 'Source' ? props.rowData.triggerable.id : '') + " ")]), _c('td', [_c('span', {
          staticClass: "-mr-1"
        }, [_vm._v(" " + _vm._s(props.rowData.type === 'revenue' ? '$' : '') + " "), _c('EditableValue', {
          key: props.rowData.id,
          attrs: {
            "type": "contentEditable",
            "isNumberValue": true,
            "value": props.rowData.currentValue,
            "mutation": _vm.UPDATE_CAP_MUTATION,
            "variables": {
              input: {
                id: props.rowData.id,
                currentValue: '{value}'
              }
            },
            "placeholder": "0",
            "value-class": "px-1",
            "edit-focus-icon-enabled": false
          }
        })], 1), _vm._v(" /"), _c('EditableValue', {
          key: props.rowData.id,
          attrs: {
            "type": "contentEditable",
            "isNumberValue": true,
            "value": props.rowData.triggerValue,
            "mutation": _vm.UPDATE_CAP_MUTATION,
            "variables": {
              input: {
                id: props.rowData.id,
                triggerValue: '{value}'
              }
            },
            "placeholder": "0",
            "value-class": "px-1",
            "edit-focus-icon-enabled": false
          }
        }), _vm._v(" " + _vm._s(_vm.capPercentage(props.rowData.currentValue, props.rowData.triggerValue)) + " ")], 1), _c('td', [!props.rowData.deletedAt ? _c('v-popover', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: {
              content: props.rowData.isIgnored ? 'Paused' : !props.rowData.isCapped ? 'Uncapped' : props.rowData.cappedBy ? 'Manually Capped' : 'Capped',
              delay: {
                show: 1000
              }
            },
            expression: "{\n            content: props.rowData.isIgnored\n              ? 'Paused'\n              : !props.rowData.isCapped\n              ? 'Uncapped'\n              : props.rowData.cappedBy\n              ? 'Manually Capped'\n              : 'Capped',\n            delay: { show: 1000 }\n          }"
          }],
          staticClass: "inline-block hover:bg-blue-200 rounded cursor-pointer"
        }, [_c('div', {
          staticClass: "flex items-center"
        }, [_c('span', {
          staticClass: "h-2 w-2 m-2 rounded-full",
          class: props.rowData.isIgnored ? 'bg-gray-500' : !props.rowData.isCapped ? 'bg-green-500' : props.rowData.cappedBy ? 'bg-yellow-500' : 'bg-red-500'
        })]), _c('template', {
          slot: "popover"
        }, [_c('div', {
          staticClass: "flex flex-col"
        }, [!props.rowData.isIgnored && !props.rowData.isCapped ? _c('YButton', {
          attrs: {
            "color": "link"
          },
          on: {
            "click": function ($event) {
              return _vm.toggleManualCap(props.rowData);
            }
          }
        }, [_vm._v("Cap")]) : _vm._e(), !props.rowData.isIgnored && props.rowData.cappedBy ? _c('YButton', {
          attrs: {
            "color": "link"
          },
          on: {
            "click": function ($event) {
              return _vm.toggleManualCap(props.rowData);
            }
          }
        }, [_vm._v("Uncap")]) : _vm._e(), _c('YButton', {
          attrs: {
            "color": "link"
          },
          on: {
            "click": function ($event) {
              return _vm.togglePauseCap(props.rowData);
            }
          }
        }, [_vm._v(_vm._s(!props.rowData.isIgnored ? 'Pause' : 'Unpause'))])], 1)])], 2) : _c('a', {
          staticClass: "flex items-center text-gray-500 hover:text-gray-900 rounded cursor-pointer",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.restoreCap(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "restore"
          }
        })], 1)], 1), _vm.cappableType === 'Campaign' ? _c('td', [_c('a', {
          staticClass: "link",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openEditFallbackModal(props.rowData.triggerable.id, props.rowData.triggerable.fallbackCampaignId, props.rowData.triggerable.fallbackOfferId);
            }
          }
        }, [_c('Icon', {
          class: props.rowData.triggerable && props.rowData.triggerable.fallbackCampaignId ? 'text-blue-400 hover:text-blue-500' : 'text-gray-400 hover:text-blue-400',
          attrs: {
            "name": "shield"
          }
        })], 1)]) : _vm._e(), _c('td', [!props.rowData.deletedAt ? _c('a', {
          staticClass: "text-gray-400 hover:text-red-500 cursor-pointer",
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.deleteCap(props.rowData.id);
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "trashCan"
          }
        })], 1) : _vm._e()])];
      }
    }], null, false, 730408407)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v(" Frequency ")]), _c('th', [_vm._v(" Type ")]), _c('th', [_vm._v(" Redirect ")]), _c('th', [_vm._v(" Source ")]), _c('th', [_vm._v(" Value ")]), _c('th'), _c('th', [_c('v-popover', {
    staticClass: "flex items-center rounded cursor-pointer -ml-3 -mr-3"
  }, [_c('div', {
    staticClass: "flex items-center hover:text-blue-500"
  }, [_c('YButton', {
    staticClass: "px-3"
  }, [_c('Icon', {
    attrs: {
      "name": "eye"
    }
  })], 1)], 1), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_c('YButton', {
    staticClass: "rounded-b-none",
    attrs: {
      "color": _vm.trashedInput === 'WITHOUT' ? 'red' : 'link'
    },
    on: {
      "click": _vm.showActiveCaps
    }
  }, [_vm._v("Show Active")]), _c('YButton', {
    staticClass: "rounded-none",
    attrs: {
      "color": _vm.trashedInput === 'ONLY' ? 'red' : 'link'
    },
    on: {
      "click": _vm.showDeletedCaps
    }
  }, [_vm._v("Show Deleted")]), _c('YButton', {
    staticClass: "rounded-t-none",
    attrs: {
      "color": _vm.trashedInput === 'WITH' ? 'red' : 'link'
    },
    on: {
      "click": _vm.showAllCaps
    }
  }, [_vm._v("Show All")])], 1)])], 2)], 1), _vm.cappableType === 'Campaign' ? _c('th') : _vm._e()]), _c('template', {
    slot: "secondHeaderRow"
  }, [_c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Frequency",
      "required": true,
      "label": "label",
      "track-by": "value",
      "options": _vm.frequency.length == 0 ? _vm.frequencyOptions : []
    },
    model: {
      value: _vm.frequency,
      callback: function ($$v) {
        _vm.frequency = $$v;
      },
      expression: "frequency"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Type",
      "required": true,
      "options": _vm.type.length > 0 ? [] : _vm.cappableType == 'Campaign' ? _vm.campaignTypeOptions : _vm.typeOptions,
      "label": "label",
      "track-by": "value"
    },
    model: {
      value: _vm.type,
      callback: function ($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Redirects",
      "track-by": "id",
      "label": "label",
      "disabled": _vm.sourceIds.length > 0,
      "query": _vm.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        first: 100,
        filters: {
          campaignId: _vm.cappableType == 'Campaign' ? {
            value: [_vm.cappableId]
          } : undefined,
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      }
    },
    model: {
      value: _vm.redirects,
      callback: function ($$v) {
        _vm.redirects = $$v;
      },
      expression: "redirects"
    }
  })], 1), _c('td', [_c('Superselect', {
    staticClass: "-mx-3",
    attrs: {
      "title": "Sources",
      "options": [],
      "forceEdit": true,
      "disabled": _vm.redirects.length > 0
    },
    model: {
      value: _vm.sourceIds,
      callback: function ($$v) {
        _vm.sourceIds = $$v;
      },
      expression: "sourceIds"
    }
  })], 1), _c('td', [_c('YInput', {
    staticClass: "-mx-3",
    attrs: {
      "label": "Value",
      "required": true
    },
    model: {
      value: _vm.triggerValue,
      callback: function ($$v) {
        _vm.triggerValue = $$v;
      },
      expression: "triggerValue"
    }
  })], 1), _c('td', [_c('div', {
    staticClass: "flex items-center -ml-3 -mr-3"
  }, [_c('YButton', {
    staticClass: "px-3",
    attrs: {
      "color": "link",
      "is-loading": _vm.isUpserting
    },
    on: {
      "click": function ($event) {
        return _vm.upsertCaps(_vm.upsertCapObjects);
      }
    }
  }, [_c('Icon', {
    attrs: {
      "name": "plus"
    }
  })], 1)], 1)]), _c('td', [_vm.$apollo.queries.caps.loading ? _c('Spinner', {
    attrs: {
      "color": "blue",
      "size": 6
    }
  }) : _vm._e()], 1), _vm.cappableType === 'Campaign' ? _c('td') : _vm._e()])], 2) : _vm._e(), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }