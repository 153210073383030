var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Create Offer Studio User ")]), _c('widget-body', [_c('form', {
    ref: "form",
    staticClass: "row mb-2",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('YInput', {
    staticClass: "mb-2",
    attrs: {
      "label": "User Name"
    },
    model: {
      value: _vm.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.user, "name", $$v);
      },
      expression: "user.name"
    }
  }), _c('YInput', {
    staticClass: "mb-2",
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  }), _c('YInput', {
    staticClass: "mb-2",
    attrs: {
      "label": "Password"
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1)]), _c('widget-footer', {
    staticClass: "bg-gray-100 p-4 text-right"
  }, [_c('y-button', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "disabled": !_vm.canSubmit
    },
    on: {
      "click": _vm.create,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.create.apply(null, arguments);
      }
    }
  }, [_vm._v("Create User")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }