<template>
  <div>
    <div class="bg-gray-200 p-1 border-b mb-2">
      <template>
        <div class="w-full">
          <div class="grid grid-cols-1 grid-cols-6 gap-1">
            <Superselect
              title="Country"
              v-model="filters.country.value"
              :multiple="false"
              label="label"
              track-by="code"
              :options="countries"
              :loading="$apollo.queries.countries.loading"
            ></Superselect>
            <Superselect
              title="Device"
              v-model="filters.device"
              :multiple="false"
              :options="options.devices"
            ></Superselect>
            <YInput v-model="filters.domain.value" label="Domain"></YInput>
            <YInput v-model="filters.subdomain.value" label="Subdomain"></YInput>
            <YInput v-model="filters.isp.value" label="Isp"></YInput>
            <YInput v-model="filters.ip.value" label="Ip"></YInput>
            <YInput v-model="filters.usageType.value" label="usage Type"></YInput>
            <YInput v-model="filters.destination.value" label="Destination"></YInput>
            <Superselect
                title="User"
                :multiple="false"
                v-model="filters.user.value"
                placeholder="Select User"
                track-by="id"
                label="label"
                :query="queries.USER_OPTIONS_QUERY"
                :query-variables="{
                filters: {
                    label: {
                    value: ['{input}'],
                    modifiers: { matchType: 'contains' }
                    }
                },
                first: 25
                }"
                :queryResultMap="{
                id: 'id',
                label: 'label'
                }"
            ></Superselect>
            <Superselect
              title="Redirect"
              :multiple="false"
              v-model="filters.redirect.value"
              placeholder="Select User"
              track-by="id"
              label="label"
              :query="queries.REDIRECT_OPTIONS_QUERY"
              :query-variables="{
              filters: {
                  label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                  }
              },
              first: 25
              }"
              :queryResultMap="{
              id: 'id',
              label: 'label'
              }"
            ></Superselect>
            <div v-if="filters.hideSubdomain == false">
             <Superselect
              title="Type"
              v-model="filters.type"
              :multiple="false"
              :options="options.type"
            ></Superselect>
            </div>
            <YCheckbox
              class="mb-1"
              label="Hide Subdomain Listings"
              type="checkbox"
              v-model="filters.hideSubdomain"
            />
           
          </div>
        <div class="grid grid-cols-1 grid-cols-6 gap-1">
           <YButton
              color="blue"
              @click.prevent="openCreateModal()"
              >Create Alf Listing</YButton
            >
        </div>
        </div>
      </template>
    </div>
    <div class="w-100">
      <YTable
        v-if="alfListings"
        deletedAtField="isCapped"
        deletedAtClass="text-red-500 font-medium"
        :data="alfListings.data || []"
        :loading="$apolloData.queries.alfListings.loading"
      >
        <template slot="header-row">
          <th>Country</th>
          <th>Subdomain</th>
          <th>Redirect</th>
          <th>Domain</th>
          <th>Ip</th>
          <th>Isp</th>
          <th>usage Type</th>
          <th>Device</th>
          <th>User</th>
          <th>Destination</th>
          <th>Actions</th>
        </template>
        <template slot="row" slot-scope="props">
          <td>{{ props.rowData.country }}</td>
          <td>{{ props.rowData.subdomain }}</td>
          <td>
            <div v-if="props.rowData.redirect && props.rowData.redirect.subdomain">
            <router-link
                :to="{
                  name: 'Redirect',
                  params: { id: props.rowData.redirect.id, action: 'edit' }
                }"
                :class="'link'"
              >
                {{ props.rowData.redirect.label }}
              </router-link>
            </div>
            <div v-else></div>
          </td>
          <td>{{ props.rowData.domain }}</td>
          <td>{{ props.rowData.ip }}</td>
          <td>{{ props.rowData.isp }}</td>
          <td>{{ props.rowData.usageType }}</td>
          <td>{{props.rowData.device}}</td>
          <td>
            <div v-if="props.rowData.user">
            <router-link
                :to="{
                  name: 'User',
                  params: { id: props.rowData.user.id, action: 'edit' }
                }"
                :class="'link'"
              >
                {{ props.rowData.user.label }}
              </router-link>
            </div>
            <div v-else></div>
          </td>
          <td>{{ props.rowData.destination }}</td>
          <td>
            <div class="text-right">
              <a
                href="#"
                :class="'text-red-600 hover:text-red-700'"
                @click.prevent="openDeleteModal(props.rowData)"
              >
                <Icon class="inline" name="trashCan" :size="4" />
              </a>
            </div>
          </td>
        </template>
        <template v-slot:after>
           <YPagination
           v-if="alfListings.paginatorInfo"
            :data="alfListings.paginatorInfo"
            @load-page="loadPage"
            @next-page="nextPage"
            @previous-page="previousPage"
            :key="totalPages"
            @per-page="perPageSelection"
          ></YPagination>
        </template>
      </YTable>
    </div>
  </div>
</template>

<script>
import ALF_LISTINGS_QUERY from '@/graphql/Alf/AlfListingsQuery.gql'
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import CreateAlfListing from '@/views/Admin/Alf/CreateAlfListing.vue'
import DeleteAlfListing from '@/views/Admin/Alf/DeleteAlfListing.vue'
import YPagination from '@/components/ui/YPagination.vue'
import BackToTop from '@/mixins/BackToTopMixin.js'
import USER_OPTIONS_QUERY from '@/graphql/User/UserOptionsQuery.gql'
export default {
  mixins: [BackToTop],
  components: {
     YPagination
  },
  apollo: {
   alfListings: {
      query: ALF_LISTINGS_QUERY,
      variables() {
        return {
          page: this.currentPage,
          first: this.perPage,
          filters: {
            subdomain: this.filters.hideSubdomain == true ? {"value": [null]} : this.cleanFilter(this.filters.subdomain),
            redirect: this.filters.redirect.value ? {"value": this.filters.redirect.value.label.split(' ')[2].split(".")[0]} : undefined,
            user: this.filters.user.value ? {"value": this.filters.user.value.id} : undefined,
            domain: this.cleanFilter(this.filters.domain),
            isp: this.cleanFilter(this.filters.isp),
            ip: this.cleanFilter(this.filters.ip),
            usageType: this.cleanFilter(this.filters.usageType),
            destination: this.cleanFilter(this.filters.destination),
            device: this.filters.device ? {"value": this.filters.device} : undefined,
            country: this.filters.country.value ? {"value": this.filters.country.value} : undefined,
            type:  this.filters.hideSubdomain == true ?  {"value": ['parent','listing']}  : this.filters.type ? this.filters.type == 'User Parent' ?  {"value": 'parent'} : {"value": this.filters.type.toLowerCase()}  :  {"value": 'listing'}
          },
          "orderBy": [
           {
            "column": "usage_type",
            "order": "DESC"
          },
          {
            "column": "isp",
            "order": "DESC"
          },
          {
            "column": "ip",
            "order": "ASC"
          },
          {
            "column": "subdomain",
            "order": "ASC"
          }
          ]
        }
      },
      result({ data }) {
        this.totalPages = data.alfListings.paginatorInfo.lastPage
      },
      fetchPolicy: 'network-only',
      deep: true
    },
    countries: {
      query: COUNTRIES_QUERY
    },
  },
  props: {},
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      totalPages: 0,
      verticalOptions: [],
      filters: {
        hideSubdomain: true,
        type: "Listing",
        user:{
          value:null,
          title:'User',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
        subdomain: {
            value: null,
            modifiers: {
                matchType: 'contains'
            },
            title: 'subdomain',
            type: 'input'
        },
        country: {
          value: null,
          title: 'Country',
          type: 'select',
          multiple: true,
          optionLabelKey: 'label',
          trackByKey: 'code'
        },
        redirect: {
          value: null,
          title: 'Redirect',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
        campaign: {
          value: null,
          title: 'Campaign',
          type: 'select',
          multiple: false,
          optionLabelKey: 'label',
          trackByKey: 'id'
        },
        domain: {
          value: null,
            modifiers: {
                matchType: 'contains'
            },
            title: 'domain',
            type: 'input'
        },
        isp: {
          value: null,
            modifiers: {
                matchType: 'contains'
            },
            type: 'input'
        },
        ip: {
          value: null,
            modifiers: {
                matchType: 'contains'
            },
            type: 'input'
        },
        usageType: {
          value: null,
            modifiers: {
                matchType: 'contains'
            },
            type: 'input'
        },
        destination: {
          value: null,
            modifiers: {
                matchType: 'contains'
            },
            type: 'input'
        },
      },
      options: {
        type : ['Listing','User Parent'],
        devices: ['Mobile', 'Desktop'],
      },
      queries: {
        REDIRECT_OPTIONS_QUERY,
        USER_OPTIONS_QUERY,
      }
    }
  },
  computed: {},
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    loadMore() {
      console.log( this.perPage)
      this.perPage = this.perPage + this.loadMoreIncrement
    },
    openDeleteModal(alfListing) {
      this.$modal.show(
        DeleteAlfListing,
        { alfListing: alfListing },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openCreateModal() {
      this.$modal.show(
        CreateAlfListing,
        { },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    cleanFilter(filter) {
      // Return undefined on null values
      // And make sure value is always an array
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }
      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    cleanObjectFilterValue(filter, pluck) {
      // defer to cleanFilter for undefined check and array cast
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }
      // pluck key from value
      return this.cleanFilter(filter).value.map(value => value[pluck])[0]
    }
  },
  mounted() {
    this.$events.listen('refreshAlfListings', () => {
      this.$apollo.queries.alfListings.refetch()
    })
    this.$tabEvent.on('refreshAlfListings', () => {
      this.$apollo.queries.alfListings.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refreshAlfListings')
    this.$tabEvent.off('refreshAlfListings')
  }
}
</script>