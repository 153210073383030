var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mt-3 flex items-center justify-between"
  }, [_c('YButton', {
    staticClass: "text-blue-600 hover:bg-blue-100 rounded text-left block mr-2 truncate flex-grow",
    on: {
      "click": function ($event) {
        _vm.showServers = !_vm.showServers;
      }
    }
  }, [_c('span', {
    staticClass: "flex items-center"
  }, [_vm.showServers ? _c('Icon', {
    attrs: {
      "name": "menuDown",
      "size": 4
    }
  }) : _c('Icon', {
    attrs: {
      "name": "menuRight",
      "size": 4
    }
  }), _vm._v(" Servers ")], 1), _vm.$apollo.queries.servers.loading ? _c('Spinner', {
    attrs: {
      "size": 8,
      "color": "blue"
    }
  }) : _vm._e()], 1), _c('div', [_c('server-actions', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "serverActions",
    attrs: {
      "provider": _vm.provider
    }
  }), _vm.provider ? _c('YButton', {
    staticClass: "text-blue-600 hover:bg-blue-100 rounded",
    on: {
      "click": function ($event) {
        return _vm.$refs.serverActions.openCreateModal(_vm.provider);
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Add Server")])]) : _vm._e()], 1)], 1), _vm.showServers ? _vm._l(_vm.servers, function (server, index) {
    return _c('server', {
      key: server.id,
      attrs: {
        "value": _vm.servers[index],
        "provider": _vm.provider,
        "show-ips-by-default": _vm.servers.length == 1
      }
    });
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }