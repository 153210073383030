<template>
  <div style="width: 75px;" class="text-right">
    <v-popover offset="0" class="p-0">
      <button
        class="bg-white-600 hover:bg-gray-100 text-blue-600 font-bold py-3 px-6 rounded truncate"
      >
        <Icon class="w-6 h-6 text-blue-600" name="dotsVertical" />
      </button>

      <!-- This will be the content of the popover -->
      <template slot="popover">
        <ul>
          <li>
            <YButton
              class="text-blue-600 bg-blue-100"
              href="#"
              @click.prevent="clone(campaign)"
              :is-loading="isCloning"
              >Clone</YButton
            >
          </li>
          <li>
            <YButton
              class="text-red-600 hover:bg-red-100"
              href="#"
              @click.prevent="deleteCampaign()"
              :is-loading="isCloning"
              >Delete</YButton
            >
          </li>
        </ul>
      </template>
    </v-popover>
  </div>
</template>

<script>
//import CloseX from '../CloseX'
//import SvgLoader from '../SvgLoader'
//import gql from 'graphql-tag'
import AdminCampaignFormDelete from './AdminCampaignFormDelete'
import CLONE_CAMPAIGN_MUTATION from '@/graphql/Campaign/CloneCampaignMutation.gql'

export default {
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  components: {
    //CloseX,
    //SvgLoader
  },
  apollo: {},
  data() {
    return {
      isSubmitting: false,
      isCloning: false
    }
  },
  watch: {},
  computed: {},
  methods: {
    clone(campaign) {
      this.isCloning = true
      this.$apollo
        .mutate({
          mutation: CLONE_CAMPAIGN_MUTATION,
          variables: {
            id: campaign.id
          }
        })
        .then(result => {
          console.log(result)
          this.isCloning = false
          this.$toastr.s('', 'Campaign Cloned')
          this.$events.emit('campaignCloned', true)
          this.$router.push({
            name: 'Campaign',
            params: { id: result.data.cloneCampaign.id }
          })
        })
        .catch(error => {
          console.log(error)
          this.isCloning = false
          this.$toastr.e(error, 'Oops!')
          /*if (Object.entries(errors.errors).length <= 0) {
            this.$toastr.e(error, 'Oops!')
          }*/
        })
    },
    deleteCampaign() {
      this.$modal.show(
        AdminCampaignFormDelete,
        {
          id: this.campaign.id,
          name: this.campaign.name,
          campaign: this.campaign
        },
        { height: 'auto', scrollable: true }
      )
    }
  },
  mounted() {},
  updated() {}
}
</script>
<style></style>
