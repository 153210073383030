<template>
  <div class="relative" :class="type !== 'date' ? 'y-input' : ''">
    <span v-if="required" class="absolute right-0 pr-1 text-2xl text-red-500"
      >*</span
    >
    <Icon
          v-if="alertIcon"
          name="pencil"
          class="absolute right-0 top-0 mt-1 mr-1 text-pink-400 bg-white"
          :size="4"
          v-tooltip="'Modified from LLM Translation'"
        />
    <div class="absolute right-0 bottom-0 mb-1 mr-1 flex">
      <Icon
        v-if="tooltip"
        name="helpCircle"
        class="mr-1 text-gray-400 bg-white"
        :size="4"
        v-tooltip="tooltip"
      />
      <Icon
        v-for="icon in icons"
        :key="icon.name"
        :name="icon.name"
        class="text-gray-400 bg-white"
        :size="4"
        v-tooltip="icon ? icon.tooltip : null"
      />
    </div>


    <input
      class="input"
      :class="[
        inputClass,
        internalValue ? 'filled' : '',
        muteInput ? 'text-gray-400' : ''
      ]"
      ref="input"
      :id="id"
      :name="label ? label.toLowerCase() : null"
      v-show="type !== 'date'"
      :type="type"
      autofocus
      v-model="internalValue"
      @paste="event => $emit('paste', event)"
      @blur="event => $emit('blur', event)"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :required="required"
      :alertIcon="alertIcon"
      @keypress="event => $emit('keypress', event)"
    />

    <VueCtkDateTimePicker
      v-if="type === 'date'"
      v-model="internalValue"
      :format="dateFormat"
      :formatted="dateFormatted"
      :minDate="minDate"
      :label="label"
      :onlyDate="true"
      color="#0e76bc"
      button-color="#41b883"
      :id="id"
    >
    </VueCtkDateTimePicker>

    <label
      v-show="type !== 'date'"
      :for="label ? label.toLowerCase() : null"
      class="pointer-events-none label absolute mb-0 -mt-2 pt-4 pl-3 leading-tighter text-gray-400 text-base mt-2 cursor-text"
      >{{ label }}</label
    >
  </div>
</template>

<script>
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  props: {
    id: {
      type: String,
      default: () => {
        // If no ID is passed. Generate a random one to link label and input
        return Math.floor(Math.random(9999999) * 100000000).toString()
      }
    },
    label: {
      type: String,
      default: null,
      required: true
    },
    value: {
      type: [String, Number, Object],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    inputClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    alertIcon: {
      type: Boolean,
      default: false
    },
    muteInput: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String
    },
    dateFormat: {
      type: String
    },
    dateFormatted: {
      type: String
    },
    icons: {
      type: Array,
      default: ()=>[]
    }
  },
  data() {
    return {
      minDate: this.$dateTime.local().toFormat('yyyy-LL-dd')
    }
  },
  methods: {
    focusInput() {
      this.$refs.input.focus()
    }
  },
  computed: {
    internalValue: {
      get: function() {
        return this.value
      },
      set: function(newValue) {
        if (newValue == '') {
          newValue = null
        }

        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style scoped></style>
