<template>
  <Widget v-show="!autoSubmit">
    <WidgetHeader @close="$emit('close')">
      Approve Redirect Subscription Request ({{ id }})
    </WidgetHeader>
    <WidgetBody class="pt-0">
      <textarea
        v-model="internalReason"
        class="w-full p-4 bg-gray-100 rounded-md border border-gray-200"
        placeholder="Write a reason..."
      ></textarea>
    </WidgetBody>
    <WidgetFooter class="bg-gray-100 px-4 py-2">
      <div class="flex justify-between">
        <YButton class="btn btn-link" @click="$emit('close')">
          Cancel
        </YButton>
        <YButton
          class="btn btn-blue"
          @click="setStatus"
          :is-loading="isSubmitting"
        >
          Subscribe Publisher
        </YButton>
      </div>
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import APPROVE_REDIRECT_SUBSCRIPTION_REQUEST_MUTATION from '@/graphql/Redirect/ApproveRedirectSubscriptionRequestMutation.gql'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  apollo: {},
  props: {
    id: {
      type: Array,
      required: true
    },
    reason: {
      type: String,
      default: ''
    },
    autoSubmit: {
      type: Boolean,
      default: false
    },
    onSuccess: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isSubmitting: false,
      internalReason: this.reason
    }
  },
  computed: {},
  mounted() {
    if (this.autoSubmit) {
      this.setStatus()
    }
  },
  methods: {
    setStatus() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: APPROVE_REDIRECT_SUBSCRIPTION_REQUEST_MUTATION,
          variables: {
            id: this.id,
            reason: this.internalReason
          }
        })
        .then(result => {
          // TODO: Should remove domain specific logic from here, and have it handled in the onSuccess
          if (this.modelName === 'User') {
            result.data.setStatus.forEach(user => {
              if (!user.accountManager && user.status == 'active') {
                this.$apollo.mutate({
                  mutation: UPDATE_USER_MUTATION,
                  variables: {
                    input: {
                      id: user.id,
                      accountManagers: {
                        sync: [this.$store.getters.authId]
                      }
                    }
                  }
                })
              }
            })
          }
          this.isSubmitting = false
          this.$toastr.s('Status Updated')
          this.$emit('success', this.statusName)
          this.$emit('close')
          this.clearValidationErrors()
          this.onSuccess(result)
        })
        .catch(error => {
          this.setValidationErrors(error)
        })
    }
  }
}
</script>

<style></style>
