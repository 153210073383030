var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon fill-current",
    class: _vm.classes,
    attrs: {
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "d": _vm.path
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }