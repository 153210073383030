var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$route.meta.hasRightSideBar ? _c('portal-target', {
    staticClass: "mx-6 md:mx-0 px-0 py-0 bg-gray-100 shadow-md border-l flex flex-col sidebar-right overflow-scroll",
    attrs: {
      "name": "sidebar-right"
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }