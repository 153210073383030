var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "rb-wrapper flex items-center text-sm"
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _c('input', {
    staticClass: "checkbox",
    attrs: {
      "type": "radio"
    },
    domProps: {
      "checked": _vm.isChecked,
      "value": _vm.value
    },
    on: {
      "change": function ($event) {
        _vm.$emit('change', _vm.cleanValue($event.target.value));
      }
    }
  }), _c('span', {
    staticClass: "checkmark"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }