var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "shadow rounded-lg"
  }, [_c('div', {
    staticClass: "flex justify-between items-center bg-gray-100 px-4 pt-2"
  }, [_c('div', {
    staticClass: "text-muted"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.selectedCount) + " " + _vm._s(_vm.selectedCount == 1 ? 'item' : 'items') + " selected "), _vm.rowsAreSelected ? _c('small', {
    staticClass: "pl-3"
  }, [_c('a', {
    staticClass: "link",
    staticStyle: {
      "border-radius": "2rem",
      "padding": "0.1rem 0.6rem"
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearAllSelected.apply(null, arguments);
      }
    }
  }, [_vm._v("CLEAR SELECTED")])]) : _vm._e()])]), _c('div', {
    staticClass: "text-right"
  }, [_c('YButton', {
    staticClass: "text-red-600 hover:bg-red-200",
    attrs: {
      "disabled": !_vm.rowsAreSelected
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openDeleteModalImages(_vm.selectedRowIds);
      }
    }
  }, [_vm._v(" Delete ")])], 1)]), _vm.data ? _c('YTable', {
    attrs: {
      "loading": _vm.isLoading,
      "data": _vm.data || [],
      "selectable": true,
      "selectBy": "id",
      "selected": _vm.selectedRowIds
    },
    on: {
      "update:selected": function ($event) {
        _vm.selectedRowIds = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "header-row",
      fn: function () {
        return [_c('th'), _c('th', [_vm._v("name")]), _c('th', [_vm._v(" slug ")]), _c('th', [_vm._v("tags")]), _c('th', [_vm._v(" last updated ")]), _c('th')];
      },
      proxy: true
    }, {
      key: "row",
      fn: function (props) {
        return [_c('td', {
          staticClass: "w-40"
        }, [_c('EditableImage', {
          staticClass: "rounded",
          attrs: {
            "imageUrl": _vm.formatUpdatedUrl(props.rowData.imageUrl, props.rowData.image.updatedAt),
            "thumbnailUrl": _vm.formatUpdatedUrl(props.rowData.imageThumbnailUrl, props.rowData.image.updatedAt),
            "height": 32,
            "width": 32,
            "blankHeight": 20,
            "blankWidth": 20,
            "editable-only-when-empty": true,
            "modelClass": "TemplateImage",
            "modelId": props.rowData.id
          }
        })], 1), _c('td', [_c('ul', [_c('li', {
          staticClass: "gray-700 font-bold",
          domProps: {
            "textContent": _vm._s(props.rowData.name)
          }
        })])]), _c('td', [_c('ul', [_c('li', [_vm._v(" " + _vm._s(props.rowData.slug) + " "), _c('a', {
          staticClass: "inline-block",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.copyToClipboard(props.rowData.slug);
            }
          }
        }, [_c('Icon', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip.top",
            value: `Copy to Clipboard`,
            expression: "`Copy to Clipboard`",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "ml-4 inline-block text-blue-600",
          attrs: {
            "name": "contentCopy",
            "size": 4
          }
        })], 1)])])]), _c('td', _vm._l(props.rowData.tags, function (tag) {
          return _c('Tag', {
            key: tag.id,
            staticClass: "mr-1 my-1",
            attrs: {
              "color": tag.color,
              "tabindex": "0"
            }
          }, [_vm._v(_vm._s(tag.name))]);
        }), 1), _c('td', [_vm._v(_vm._s(_vm.date(props.rowData.updatedAt)))]), _c('td', {
          staticClass: "text-right"
        }, [_c('div', {
          staticClass: "inline-flex"
        }, [_c('a', {
          staticClass: "link",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openEditModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "pencil"
          }
        })], 1), _c('a', {
          staticClass: "ml-2 text-red-500 hover:text-red-600",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDeleteModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "trashCan"
          }
        })], 1)])])];
      }
    }], null, false, 1787882244)
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }