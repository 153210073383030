var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-gray-200 p-1 border-b mb-2"
  }, [[_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 grid-cols-6 gap-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Country",
      "multiple": false,
      "label": "label",
      "track-by": "code",
      "options": _vm.countries,
      "loading": _vm.$apollo.queries.countries.loading
    },
    model: {
      value: _vm.filters.country.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.country, "value", $$v);
      },
      expression: "filters.country.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Device",
      "multiple": false,
      "options": _vm.options.devices
    },
    model: {
      value: _vm.filters.device,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "device", $$v);
      },
      expression: "filters.device"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Domain"
    },
    model: {
      value: _vm.filters.domain.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.domain, "value", $$v);
      },
      expression: "filters.domain.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Subdomain"
    },
    model: {
      value: _vm.filters.subdomain.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.subdomain, "value", $$v);
      },
      expression: "filters.subdomain.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Isp"
    },
    model: {
      value: _vm.filters.isp.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.isp, "value", $$v);
      },
      expression: "filters.isp.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Ip"
    },
    model: {
      value: _vm.filters.ip.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.ip, "value", $$v);
      },
      expression: "filters.ip.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "usage Type"
    },
    model: {
      value: _vm.filters.usageType.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.usageType, "value", $$v);
      },
      expression: "filters.usageType.value"
    }
  }), _c('YInput', {
    attrs: {
      "label": "Destination"
    },
    model: {
      value: _vm.filters.destination.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.destination, "value", $$v);
      },
      expression: "filters.destination.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "User",
      "multiple": false,
      "placeholder": "Select User",
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.USER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      },
      "queryResultMap": {
        id: 'id',
        label: 'label'
      }
    },
    model: {
      value: _vm.filters.user.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.user, "value", $$v);
      },
      expression: "filters.user.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Redirect",
      "multiple": false,
      "placeholder": "Select User",
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      },
      "queryResultMap": {
        id: 'id',
        label: 'label'
      }
    },
    model: {
      value: _vm.filters.redirect.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.redirect, "value", $$v);
      },
      expression: "filters.redirect.value"
    }
  }), _vm.filters.hideSubdomain == false ? _c('div', [_c('Superselect', {
    attrs: {
      "title": "Type",
      "multiple": false,
      "options": _vm.options.type
    },
    model: {
      value: _vm.filters.type,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "type", $$v);
      },
      expression: "filters.type"
    }
  })], 1) : _vm._e(), _c('YCheckbox', {
    staticClass: "mb-1",
    attrs: {
      "label": "Hide Subdomain Listings",
      "type": "checkbox"
    },
    model: {
      value: _vm.filters.hideSubdomain,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "hideSubdomain", $$v);
      },
      expression: "filters.hideSubdomain"
    }
  })], 1), _c('div', {
    staticClass: "grid grid-cols-1 grid-cols-6 gap-1"
  }, [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openCreateModal();
      }
    }
  }, [_vm._v("Create Alf Listing")])], 1)])]], 2), _c('div', {
    staticClass: "w-100"
  }, [_vm.alfListings ? _c('YTable', {
    attrs: {
      "deletedAtField": "isCapped",
      "deletedAtClass": "text-red-500 font-medium",
      "data": _vm.alfListings.data || [],
      "loading": _vm.$apolloData.queries.alfListings.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(_vm._s(props.rowData.country))]), _c('td', [_vm._v(_vm._s(props.rowData.subdomain))]), _c('td', [props.rowData.redirect && props.rowData.redirect.subdomain ? _c('div', [_c('router-link', {
          class: 'link',
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.redirect.id,
                action: 'edit'
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.label) + " ")])], 1) : _c('div')]), _c('td', [_vm._v(_vm._s(props.rowData.domain))]), _c('td', [_vm._v(_vm._s(props.rowData.ip))]), _c('td', [_vm._v(_vm._s(props.rowData.isp))]), _c('td', [_vm._v(_vm._s(props.rowData.usageType))]), _c('td', [_vm._v(_vm._s(props.rowData.device))]), _c('td', [props.rowData.user ? _c('div', [_c('router-link', {
          class: 'link',
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.user.id,
                action: 'edit'
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.user.label) + " ")])], 1) : _c('div')]), _c('td', [_vm._v(_vm._s(props.rowData.destination))]), _c('td', [_c('div', {
          staticClass: "text-right"
        }, [_c('a', {
          class: 'text-red-600 hover:text-red-700',
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDeleteModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "inline",
          attrs: {
            "name": "trashCan",
            "size": 4
          }
        })], 1)])])];
      }
    }, {
      key: "after",
      fn: function () {
        return [_vm.alfListings.paginatorInfo ? _c('YPagination', {
          key: _vm.totalPages,
          attrs: {
            "data": _vm.alfListings.paginatorInfo
          },
          on: {
            "load-page": _vm.loadPage,
            "next-page": _vm.nextPage,
            "previous-page": _vm.previousPage,
            "per-page": _vm.perPageSelection
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 151507250)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Country")]), _c('th', [_vm._v("Subdomain")]), _c('th', [_vm._v("Redirect")]), _c('th', [_vm._v("Domain")]), _c('th', [_vm._v("Ip")]), _c('th', [_vm._v("Isp")]), _c('th', [_vm._v("usage Type")]), _c('th', [_vm._v("Device")]), _c('th', [_vm._v("User")]), _c('th', [_vm._v("Destination")]), _c('th', [_vm._v("Actions")])])], 2) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }