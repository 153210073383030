var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', [_vm._v("Clone Offer Options")]), _c('WidgetBody', [_c('div', {
    staticClass: "flex flex-col gap-4 px-4"
  }, [_c('div', {
    staticClass: "flex flex-col w-full"
  }, [_c('div', {
    staticClass: "flex flex-col"
  }, [_vm._v(" Select which options you would like to clone with the offer. ")])]), _c('div', {
    staticClass: "grid gap-2 w-full items-start bg-gray-100 rounded p-4"
  }, [_c('YCheckbox', {
    staticClass: "flex items-center mr-2",
    attrs: {
      "label": "Clone with Rules",
      "inline": true,
      "type": "info"
    },
    model: {
      value: _vm.withRules,
      callback: function ($$v) {
        _vm.withRules = $$v;
      },
      expression: "withRules"
    }
  }), _vm.offerData.destinationType == 'direct' ? _c('YCheckbox', {
    staticClass: "flex items-center mr-2",
    attrs: {
      "label": "Clone with Payouts",
      "inline": true,
      "type": "info"
    },
    model: {
      value: _vm.withRevenues,
      callback: function ($$v) {
        _vm.withRevenues = $$v;
      },
      expression: "withRevenues"
    }
  }) : _vm._e(), _vm.offerData.destinationType == 'direct' && _vm.offerData.yleader ? _c('YCheckbox', {
    staticClass: "flex items-center mr-2",
    attrs: {
      "label": "Clone with yLeader",
      "inline": true,
      "type": "info"
    },
    model: {
      value: _vm.withYleader,
      callback: function ($$v) {
        _vm.withYleader = $$v;
      },
      expression: "withYleader"
    }
  }) : _vm._e(), _vm.offerData.destinationType == 'direct' ? _c('YCheckbox', {
    staticClass: "flex items-center mr-2",
    attrs: {
      "label": "Clone with Attributes",
      "inline": true,
      "type": "info"
    },
    model: {
      value: _vm.withAttributes,
      callback: function ($$v) {
        _vm.withAttributes = $$v;
      },
      expression: "withAttributes"
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "w-full flex justify-end gap-3 mt-6"
  }, [_c('YButton', {
    attrs: {
      "color": "gray"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Cancel")]), _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.cloneOffer
    }
  }, [_vm._v("Clone Offer")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }