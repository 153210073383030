<template>
  <div>
    <portal to="top-bar-right">
      <div class="flex">
        <YInput
          input-class="border-r-none rounded-r-none"
          label="Source ID"
          v-model="sourceId"
        />
        <YButton
          class="btn btn-blue text-white rounded-l-none "
          @click="goToSource"
          >Go</YButton
        >
      </div>
    </portal>
    <admin-sources-table-wrapper></admin-sources-table-wrapper>
  </div>
</template>

<script>
import AdminSourcesTableWrapper from '@/components/legacy/Sources/AdminSourcesTableWrapper'

export default {
  components: {
    AdminSourcesTableWrapper
  },
  data() {
    return {
      sourceId: null
    }
  },

  mounted() {},

  updated() {},

  methods: {
    goToSource() {
      this.$router.push({
        name: 'Source',
        params: {
          id: this.sourceId
        }
      })
    }
  }
}
</script>
<style></style>
