import SetStatusWidget from '@/views/Admin/Status/SetStatusWidget'
import SET_STATUS_MUTATION from '@/graphql/Status/SetStatusMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  data() {
    return {
      setStatusIsSubmitting: false
    }
  },
  methods: {
    setStatus({ modelName, modelIds, name, reason }) {
      this.setStatusIsSubmitting = true
      this.$apollo
        .mutate({
          mutation: SET_STATUS_MUTATION,
          variables: {
            input: {
              modelName: modelName,
              modelIds: modelIds,
              name: name,
              reason: reason
            }
          }
        })
        .then(result => {
          this.setStatusIsSubmitting = false
          this.clearValidationErrors()
          this.onSetStatusSuccess(result.data.setStatus)
        })
        .catch(error => {
          this.setValidationErrors(error)
          this.setStatusIsSubmitting = false
        })
    },
    onSetStatusSuccess() {
      this.$toastr.s('Status Updated')
      this.$emit('setStatusSuccess')
    },
    openStatusModal({
      modelName,
      modelId,
      currentStatus,
      newStatus,
      options,
      enumName,
      onSuccess
    }) {
      this.$modal.show(
        SetStatusWidget,
        {
          modelName: modelName,
          modelIds: Array.isArray(modelId) ? modelId : [modelId],
          currentStatus: currentStatus || undefined,
          newStatus: newStatus || undefined,
          options: options || undefined,
          enumName: enumName || undefined,
          onSuccess: onSuccess || undefined
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 600,
          minWidth: 200,
          adaptive: true
        }
      )
    }
  }
}
