<template>
  <Widget>
    <WidgetHeader @close="$emit('close')">
      Update Default Conversion Types
    </WidgetHeader>
    <WidgetBody class="pt-2">
      <div>
        <div>
          <Superselect
            class="mb-2"
            title="Conversion Type"
            v-model="conversionType"
            :multiple="false"
            :taggable="true"
            :options="conversionTypeOptions"
            :required="true"
          ></Superselect>
        </div>
      </div>
    </WidgetBody>
    <WidgetFooter class="bg-gray-100 flex justify-between p-4 items-center">
      <div></div>
      <YButton
        :is-loading="isSubmitting"
        type="button"
        class="btn-blue"
        @click="submit"
        @keydown.enter="update"
      >
        Update
      </YButton>
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import UPDATE_DEFAULT_CONVERSION_TYPES_MUTATION from '@/graphql/Payout/UpdateDefaultPayoutConversionTypesMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import CONVERSION_TYPES_QUERY from '@/graphql/ConversionType/ConversionTypesQuery.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    campaignId: {
      type: Number,
      required: true
    }
  },
  components: {},
  apollo: {
    conversionTypes: {
      query: CONVERSION_TYPES_QUERY,
      variables() {
        return {
          first: 999
        }
      },
      result({ data }) {
        this.conversionTypeOptions = data?.conversionTypes.data
          ?.filter(conversionType => conversionType.slug != 'default')
          ?.map(object => object.name)
      }
    }
  },
  data() {
    return {
      conversionTypeOptions: [],
      conversionType: null,
      isSubmitting: false
    }
  },
  computed: {},
  methods: {
    async submit() {
      this.isSubmitting = true
      return this.$apollo
        .mutate({
          mutation: UPDATE_DEFAULT_CONVERSION_TYPES_MUTATION,
          variables: {
            input: {
              campaignId: this.campaignId,
              conversionType: this.conversionType
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.close()
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
          console.log(error)
        })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
