<template>
  <div @click="disabled ? null : click()">
    <template v-if="!showReplacement">
      <slot></slot>
    </template>
    <template v-if="showReplacement">
      <slot name="replacement"></slot>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showReplacement: false
    }
  },
  methods: {
    click() {
      this.showReplacement = true
      if (this.duration) {
        setTimeout(() => {
          this.showReplacement = false
        }, this.duration)
      }
    }
  }
}
</script>

<style></style>
