var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('y-radio', {
    staticClass: "mb-2",
    attrs: {
      "label": "Add IP Records",
      "name": "add-records-mode",
      "radio-value": "ip",
      "color": "info"
    },
    model: {
      value: _vm.addRecordsMode,
      callback: function ($$v) {
        _vm.addRecordsMode = $$v;
      },
      expression: "addRecordsMode"
    }
  }), _vm.addRecordsMode == 'ip' ? _c('div', {
    staticClass: "ml-4 mb-5"
  }, [_c('ip-select-helper', {
    ref: "ipSelectHelper",
    staticClass: "mb-1",
    attrs: {
      "domain-type": _vm.domainType
    },
    on: {
      "select": function (ips) {
        _this.selectedIps = ips;
      }
    }
  }), _c('superselect', {
    attrs: {
      "title": "Select Subdomains",
      "forceEdit": true,
      "edit-placeholder": "Enter Subdomain",
      "track-by": "id",
      "label": "id"
    },
    model: {
      value: _vm.selectedSubdomains,
      callback: function ($$v) {
        _vm.selectedSubdomains = $$v;
      },
      expression: "selectedSubdomains"
    }
  })], 1) : _vm._e(), _c('input', {
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": _vm.records
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }