var render = function render(){
  var _vm$algorithmsDataBuc, _vm$algorithmsDataBuc2, _vm$algorithmsDataBuc3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_vm.$apolloData.queries.algorithmsDataBucketKeys.loading ? _c('Spinner', {
    staticClass: "mt-6",
    attrs: {
      "color": "green",
      "size": 10
    }
  }) : _vm._e(), _vm.displayedRows ? _c('YTable', {
    attrs: {
      "data": _vm.displayedRows,
      "loading": _vm.$apolloData.queries.algorithmsDataBucketKeys.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', {
          staticClass: "text-center"
        }, [_c('span', {
          staticClass: "font-semibold text-xs"
        }, [_vm._v(" " + _vm._s(props.rowData.algoId))])]), _c('td', [_c('Tag', {
          attrs: {
            "color": "blue"
          }
        }, [_vm._v(_vm._s(props.rowData.device))])], 1), _c('td', [_vm._v(_vm._s(props.rowData.country))]), _c('td', [_vm._v(_vm._s(props.rowData.region))]), _c('td', [props.rowData.redirect ? _c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.redirect.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.label) + " ")]) : _vm._e()], 1), _c('td', [props.rowData.campaign ? _c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Campaign',
              params: {
                id: props.rowData.campaign.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.campaign.label) + " ")]) : _vm._e()], 1), _c('td', [_vm._v(_vm._s(props.rowData.views))]), _c('td', {
          class: props.rowData.rpm < 0 ? 'text-red-500' : ''
        }, [_vm._v(" " + _vm._s(_vm.formatDollarValue(props.rowData.rpm)) + " ")]), _c('td', {
          class: props.rowData.topRpm < 0 ? 'text-black' : 'text-gray-800'
        }, [_vm._v(" " + _vm._s(_vm.formatDollarValue(props.rowData.topRpm)) + " ")]), _c('td', {
          class: props.rowData.revenue < 0 ? 'text-red-500' : ''
        }, [_vm._v(" " + _vm._s(_vm.formatDollarValue(props.rowData.revenue)) + " ")]), _c('td', {
          class: props.rowData.profit < 0 ? 'text-red-500' : ''
        }, [_vm._v(" " + _vm._s(_vm.formatDollarValue(props.rowData.profit)) + " ")]), _c('td', {
          class: props.rowData.spend < 0 ? 'text-red-500' : ''
        }, [_vm._v(" " + _vm._s(_vm.formatDollarValue(props.rowData.spend)) + " ")]), _c('td', [_vm._v(_vm._s(props.rowData.weight))]), _c('td', [_vm._v(_vm._s(props.rowData.min))]), _c('td', [_vm._v(_vm._s(props.rowData.max))]), _c('td', [_vm._v(_vm._s(props.rowData.expiry))]), _c('td', [_vm._v(_vm._s(props.rowData.fallback))])];
      }
    }], null, false, 1640762539)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Algo")]), _c('th', [_vm._v("Device")]), _c('th', [_vm._v("Geo")]), _c('th', [_vm._v("Region")]), _c('th', [_vm._v("Redirect")]), _c('th', [_vm._v("Campaign")]), _c('th', [_vm._v("Views")]), _c('th', [_vm._v("RPM")]), _c('th', [_vm._v("Top RPM")]), _c('th', [_c('div', {
    staticClass: "flex items-center gap-1",
    on: {
      "click": function ($event) {
        return _vm.toggleSort('revenue');
      }
    }
  }, [_vm._v(" Revenue "), _c('Icon', {
    staticClass: "text-gray-400 cursor-pointer",
    class: {
      'text-blue-600': _vm.paginationData.sortBy === 'revenue'
    },
    style: {
      transform: _vm.paginationData.orderBy === 'ASC' ? 'rotate(-180deg)' : 'rotate(0)'
    },
    attrs: {
      "name": "chevronDown",
      "size": "5"
    }
  })], 1)]), _c('th', [_c('div', {
    staticClass: "flex items-center gap-1",
    on: {
      "click": function ($event) {
        return _vm.toggleSort('profit');
      }
    }
  }, [_vm._v(" Profit "), _c('Icon', {
    staticClass: "text-gray-400 cursor-pointer",
    class: {
      'text-blue-600': _vm.paginationData.sortBy === 'profit'
    },
    style: {
      transform: _vm.paginationData.orderBy === 'ASC' ? 'rotate(-180deg)' : 'rotate(0)'
    },
    attrs: {
      "name": "chevronDown",
      "size": "5"
    }
  })], 1)]), _c('th', [_c('div', {
    staticClass: "flex items-center gap-1",
    on: {
      "click": function ($event) {
        return _vm.toggleSort('spend');
      }
    }
  }, [_vm._v(" Spend "), _c('Icon', {
    staticClass: "text-gray-400 cursor-pointer",
    class: {
      'text-blue-600': _vm.paginationData.sortBy === 'spend'
    },
    style: {
      transform: _vm.paginationData.orderBy === 'ASC' ? 'rotate(-180deg)' : 'rotate(0)'
    },
    attrs: {
      "name": "chevronDown",
      "size": "5"
    }
  })], 1)]), _c('th', [_vm._v("Weight")]), _c('th', [_vm._v("Min")]), _c('th', [_vm._v("Max")]), _c('th', [_vm._v("Expiry Date")]), _c('th', [_vm._v("Fallback")])]), _c('template', {
    slot: "secondHeaderRow"
  }, [_c('th'), _c('th'), _c('th'), _c('th'), _c('th'), _c('th'), _c('th'), _c('th'), _c('th'), _c('th', {
    staticStyle: {
      "padding": "0.2rem 1rem",
      "color": "#000"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDollarValue((_vm$algorithmsDataBuc = _vm.algorithmsDataBucketKeys) === null || _vm$algorithmsDataBuc === void 0 ? void 0 : _vm$algorithmsDataBuc.totalRevenue)) + " ")]), _c('th', {
    staticStyle: {
      "padding": "0.2rem 1rem",
      "color": "#000"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDollarValue((_vm$algorithmsDataBuc2 = _vm.algorithmsDataBucketKeys) === null || _vm$algorithmsDataBuc2 === void 0 ? void 0 : _vm$algorithmsDataBuc2.totalProfit)) + " ")]), _c('th', {
    staticStyle: {
      "padding": "0.2rem 1rem",
      "color": "#000"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDollarValue((_vm$algorithmsDataBuc3 = _vm.algorithmsDataBucketKeys) === null || _vm$algorithmsDataBuc3 === void 0 ? void 0 : _vm$algorithmsDataBuc3.totalSpend)) + " ")]), _c('th'), _c('th'), _c('th'), _c('th'), _c('th')])], 2) : _vm._e(), _vm.algorithmsDataBucketKeys ? _c('YPagination', {
    attrs: {
      "data": _vm.paginationData
    },
    on: {
      "load-page": _vm.loadPage,
      "next-page": _vm.nextPage,
      "previous-page": _vm.previousPage,
      "per-page": _vm.changePerPage
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }