var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-right",
    staticStyle: {
      "width": "75px"
    }
  }, [_c('v-popover', {
    staticClass: "p-0",
    attrs: {
      "offset": "0"
    }
  }, [_c('button', {
    staticClass: "bg-white-600 hover:bg-gray-100 text-blue-600 font-bold py-3 px-6 rounded truncate"
  }, [_c('Icon', {
    staticClass: "w-6 h-6 text-blue-600",
    attrs: {
      "name": "dotsVertical"
    }
  })], 1), _c('template', {
    slot: "popover"
  }, [_c('ul', [_c('li', [_c('YButton', {
    staticClass: "text-blue-600 bg-blue-100",
    attrs: {
      "href": "#",
      "is-loading": _vm.isCloning
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clone(_vm.campaign);
      }
    }
  }, [_vm._v("Clone")])], 1), _c('li', [_c('YButton', {
    staticClass: "text-red-600 hover:bg-red-100",
    attrs: {
      "href": "#",
      "is-loading": _vm.isCloning
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.deleteCampaign();
      }
    }
  }, [_vm._v("Delete")])], 1)])])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }