var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('div', {
    staticClass: "flex justify-right"
  }, [_c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": _vm.openCreateModal
    }
  }, [_vm._v(" Add Account Level Postback ")])], 1)]), _c('YTable', {
    attrs: {
      "data": _vm.postbacks.data,
      "bottom-loading": _vm.$apollo.queries.postbacks.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _props$rowData$conver;
        return [_c('td', [props.rowData.redirect ? [props.rowData.redirect.campaign ? [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Publisher Campaign',
              params: {
                id: props.rowData.redirect.campaign.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.campaign.id) + " - " + _vm._s(props.rowData.redirect.campaign.name) + " ")])] : [_vm._v(" " + _vm._s(props.rowData.redirect.id) + " - " + _vm._s(props.rowData.redirect.subdomain) + "." + _vm._s(props.rowData.redirect.domain) + " ")]] : [_c('span', {
          staticClass: "font-semibold text-gray-800"
        }, [_vm._v("Account Level Postback")])]], 2), _c('td', [props.rowData.conversionType ? _c('Tag', {
          attrs: {
            "color": "blue"
          }
        }, [_vm._v(_vm._s((_props$rowData$conver = props.rowData.conversionType) === null || _props$rowData$conver === void 0 ? void 0 : _props$rowData$conver.name))]) : _c('Tag', {
          attrs: {
            "color": "orange"
          }
        }, [_vm._v("Default")])], 1), _c('td', [[_vm._v(_vm._s(props.rowData.protocol) + "://" + _vm._s(props.rowData.url))]], 2), _c('td', [_c('div', {
          staticClass: "flex items-center text-right justify-between"
        }, [_c('div'), _c('div', {
          staticClass: "flex items-center"
        }, [_c('a', {
          staticClass: "link p-1",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openEditModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "pencil"
          }
        })], 1), _c('a', {
          staticClass: "text-red-500 hover:text-red-600 p-1",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDeleteModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "trashCan"
          }
        })], 1)])])])];
      }
    }])
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Target")]), _c('th', [_c('div', {
    staticClass: "flex cursor-pointer"
  }, [_vm._v(" Conversion Type "), _c('v-popover', {
    attrs: {
      "offset": "10",
      "placement": "top",
      "trigger": "hover"
    }
  }, [_c('Icon', {
    staticClass: "ml-1 text-gray-500 self-center",
    attrs: {
      "name": "information",
      "size": "4"
    }
  }), _c('template', {
    slot: "popover"
  }, [_c('div', {
    staticClass: "bg-gray-100 rounded-md p-4",
    staticStyle: {
      "max-width": "600px"
    }
  }, [_c('span', {
    staticClass: "font-semibold block mb-2"
  }, [_vm._v(" Campaign Level Postbacks take priority over Account Level Postbacks.")]), _c('span', {
    staticClass: "block"
  }, [_c('strong', [_vm._v("DEFAULT")]), _vm._v(" - This postback will fire "), _c('span', {
    staticClass: "text-pink-700 font-bold"
  }, [_vm._v("payable")]), _vm._v(" conversions when no conversion type postback is set or available.")]), _c('span', {
    staticClass: "block"
  }, [_c('strong', [_vm._v("TYPE SELECTED")]), _vm._v(" - This postback will fire "), _c('span', {
    staticClass: "text-pink-700 font-bold"
  }, [_vm._v("all")]), _vm._v(" conversions for the specific conversion type set regardless of payout.")])])])], 2)], 1)]), _c('th', [_vm._v("Url")]), _c('th')])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }