var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('portal', {
    attrs: {
      "to": "top-bar-right"
    }
  }, [_c('YButton', {
    staticClass: "btn btn-blue",
    on: {
      "click": _vm.addImage
    }
  }, [_vm._v("Add New Image")])], 1), _c('ImageRepositoryTableWrapper')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }