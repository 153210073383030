<template>
  <Widget>
    <WidgetHeader @close="$emit('close')"
      >Redirect Pause Functionality</WidgetHeader
    >
    <WidgetBody>
      <div class="flex flex-col items-center gap-4 px-4">
        <YButton
          class="w-1/2"
          :color="redirect.isAbyssed ? 'green' : 'red'"
          @click="abyssRedirect"
          >{{ redirect.isAbyssed ? 'Undo Abyss' : 'Abyss' }}</YButton
        >
      </div>
    </WidgetBody>
  </Widget>
</template>

<script>
import ABYSS_REDIRECT_MUTATION from '@/graphql/Redirect/AbyssRedirectMutation.gql'
import UpdateCampaignFallbackModal from '@/views/Admin/Campaign/UpdateCampaignFallbackModal'

export default {
  props: {
    redirect: {
      type: Object
    }
  },
  data() {
    return {
      isSubmitting: false,
      savedRedirect: null
    }
  },
  methods: {
    openEditFallbackModal() {
      this.$modal.show(
        UpdateCampaignFallbackModal,
        {
          id: this.redirect.id,
          fallbackCampaignId: this.redirect.fallbackCampaignId ?? null,
          fallbackOfferId: this.redirect.fallbackOfferId ?? null
        },
        {
          height: 'auto',
          width: '100%',
          maxWidth: 1000,
          adaptive: true,
          scrollable: true
        }
      )
    },
    selectedRedirectPayoutIds(redirect) {
      return redirect.payouts.map(payout => payout.id)
    },
    abyssRedirect() {
      this.isSubmitting = true
      this.$apollo
        .mutate({
          mutation: ABYSS_REDIRECT_MUTATION,
          variables: {
            input: {
              id: this.redirect.id,
              action: this.redirect.isAbyssed ? 'unabyss' : 'abyss'
            }
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$events.emit('refetchRedirects')
          this.$emit('close')
          this.$toastr.s('Redirect Abyssed')
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e('Abyss Failed')
          this.isSubmitting = false
        })
    }
  }
}
</script>
