<template>
  <span
    class="tag truncate"
    :class="[...classes, hasClickListener ? 'cursor-pointer' : '']"
    @focus="$emit('focus')"
    @click="$emit('click')"
  >
    <slot name="icon" :color="color">
      <Icon v-if="icon" :name="icon" class="ml-1 -mr-1 w-3 h-3" />
    </slot>
    <span class="tag-content" :class="tagContentClass">
      <slot></slot>
    </span>
    <slot name="afterIcon" :color="color">
      <Icon
        v-if="afterIcon"
        :name="afterIcon"
        class="fill-current -ml-1 w-3 h-3 mr-1"
      />
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null
    },
    afterIcon: {
      type: String,
      default: null
    },
    hover: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: null
    },
    shade: {
      type: Number,
      default: 200
    },
    tagContentClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    isHoverable() {
      return this.hover || this.hasClickListener
    },
    hasClickListener() {
      return this.$listeners && this.$listeners.click
    },
    text() {
      return this.$slots.default ? this.$slots.default[0].text || '' : ''
    },
    classes() {
      let classes = []
      let color = this.colors[this.color] || this.autoColor
      if (color) {
        classes.push(
          `bg-${color.color}${color.bgShade ? '-' + color.bgShade : ''}`
        )
        classes.push(
          `text-${color.textColor}${
            color.textShade ? '-' + color.textShade : ''
          }`
        )
        classes.push(
          `border-${color.color}${
            color.borderShade ? '-' + color.borderShade : ''
          }`
        )
        if (this.isHoverable) {
          classes.push(
            `hover:bg-${color.color}${
              color.hoverBgShade ? '-' + color.hoverBgShade : ''
            } cursor-pointer`
          )
        }
      }
      return classes
    },
    colors() {
      return {
        red: {
          color: 'red',
          bgShade: this.shade,
          textColor: 'red',
          textShade: this.shade + 400,
          hoverBgShade: this.shade + 100,
          borderShade: this.shade + 100
        },
        darkRed: {
          color: 'red',
          bgShade: '600',
          textColor: 'white',
          textShade: null,
          hoverBgShade: '700',
          borderShade: '700'
        },
        green: {
          color: 'green',
          textColor: 'green',
          bgShade: this.shade,
          textShade: this.shade + 400,
          hoverBgShade: this.shade + 100,
          borderShade: this.shade + 100
        },
        darkGreen: {
          color: 'green',
          bgShade: '600',
          textColor: 'white',
          textShade: null,
          hoverBgShade: '700',
          borderShade: '700'
        },
        purple: {
          color: 'purple',
          textColor: 'purple',
          bgShade: this.shade,
          textShade: this.shade + 400,
          hoverBgShade: this.shade + 100,
          borderShade: this.shade + 100
        },
        blue: {
          color: 'blue',
          textColor: 'blue',
          bgShade: this.shade,
          textShade: this.shade + 400,
          hoverBgShade: this.shade + 100,
          borderShade: this.shade + 100
        },
        pink: {
          color: 'pink',
          textColor: 'pink',
          bgShade: this.shade,
          textShade: this.shade + 400,
          hoverBgShade: this.shade + 100,
          borderShade: this.shade + 100
        },
        teal: {
          color: 'teal',
          textColor: 'teal',
          bgShade: this.shade,
          textShade: this.shade + 400,
          hoverBgShade: this.shade + 100,
          borderShade: this.shade + 100
        },
        indigo: {
          color: 'indigo',
          textColor: 'indigo',
          bgShade: this.shade,
          textShade: this.shade + 500,
          hoverBgShade: this.shade + 100,
          borderShade: this.shade + 100
        },
        yellow: {
          color: 'yellow',
          textColor: 'yellow',
          bgShade: this.shade,
          textShade: this.shade + 400,
          hoverBgShade: this.shade + 100,
          borderShade: this.shade + 100
        },
        orange: {
          color: 'orange',
          textColor: 'orange',
          bgShade: this.shade,
          textShade: this.shade + 400,
          hoverBgShade: this.shade + 100,
          borderShade: this.shade + 100
        },
        gray: {
          color: 'gray',
          textColor: 'gray',
          bgShade: this.shade,
          textShade: this.shade + 400,
          hoverBgShade: this.shade + 100,
          borderShade: this.shade + 100
        },
        white: {
          color: 'white',
          textColor: 'black',
          bgShade: null,
          textShade: null,
          hoverBgShade: null
        },
        black: {
          color: 'black',
          textColor: 'white',
          bgShade: null,
          textShade: null,
          hoverBgShade: null
        },
        light: {
          color: 'gray',
          textColor: 'gray',
          bgShade: 100,
          textShade: 800,
          hoverbgShade: this.shade
        }
      }
    },
    autoColor() {
      let output = null
      switch (this.text.toLowerCase().trim()) {
        case 'rejected':
        case 'fraud':
        case 'expired':
        case 'declined':
          output = this.colors.red
          break
        case 'active':
        case 'approved':
        case 'subscribed':
          output = this.colors.green
          break
        case 'admin':
        case 'network':
          output = this.colors.teal
          break
        case 'advertiser':
          output = this.colors.indigo
          break
        case 'super admin':
          output = {
            color: 'green',
            bgShade: 300,
            textShade: 700,
            hoverBgShade: 400
          }
          break
        case 'publisher':
        case 'requested':
          output = this.colors.purple
          break
        case 'pending':
        case 'pending approval':
        case 'available':
        case 'apply to run':
        case 'affiliate':
          output = this.colors.blue
          break
        default:
          output = this.colors.gray
          break
      }
      return output
    }
  }
}
</script>

<style></style>
