var render = function render(){
  var _vm$filters$redirect3, _vm$filters$redirect4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-gray-200 p-1 border-b mb-2"
  }, [[_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 grid-cols-6 gap-1"
  }, [_c('Superselect', {
    attrs: {
      "title": "Country",
      "multiple": false,
      "label": "label",
      "track-by": "code",
      "options": _vm.countries,
      "loading": _vm.$apollo.queries.countries.loading
    },
    model: {
      value: _vm.filters.country.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.country, "value", $$v);
      },
      expression: "filters.country.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Device",
      "multiple": false,
      "options": _vm.options.devices
    },
    model: {
      value: _vm.filters.device,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "device", $$v);
      },
      expression: "filters.device"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Redirect",
      "multiple": false,
      "options": _vm.options.redirect,
      "placeholder": "Select Redirect",
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.REDIRECT_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      },
      "queryResultMap": {
        id: 'id',
        label: 'label',
        relationId: 'campaign',
        relationLabel: 'campaign'
      }
    },
    on: {
      "select": _vm.redirectDropdownChange
    },
    model: {
      value: _vm.filters.redirect.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.redirect, "value", $$v);
      },
      expression: "filters.redirect.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Campaign",
      "options": _vm.options.campaign,
      "multiple": false,
      "placeholder": "Select Campaign",
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.CAMPAIGN_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 25
      },
      "queryResultMap": {
        id: 'id',
        label: 'label'
      }
    },
    model: {
      value: _vm.filters.campaign.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.campaign, "value", $$v);
      },
      expression: "filters.campaign.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Offer",
      "options": _vm.options.offer,
      "placeholder": "Select Offer",
      "options-limit": 100,
      "track-by": "id",
      "label": "label",
      "query": _vm.queries.OFFER_OPTIONS_QUERY,
      "query-variables": {
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        },
        first: 100
      }
    },
    model: {
      value: _vm.filters.offer.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.offer, "value", $$v);
      },
      expression: "filters.offer.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Vertical",
      "options": _vm.verticalOptions,
      "placeholder": "Vertical"
    },
    model: {
      value: _vm.filters.verticals.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.verticals, "value", $$v);
      },
      expression: "filters.verticals.value"
    }
  }), _vm.filters.country.value ? _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openOptimizeModal();
      }
    }
  }, [_vm._v("Run Optimization")]) : _vm._e(), _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.toggleQualifiedRedirects();
      }
    }
  }, [_vm._v(_vm._s(_vm.showButtonText))])], 1)])]], 2), _c('div', {
    staticClass: "bg-gray-100 p-1 border-b mb-2"
  }, [_vm._v(" Notes: "), _vm.algorithmDescription ? _c('EditableValue', {
    attrs: {
      "type": "contentEditable",
      "mutation": _vm.mutations.UPDATE_ALGORITHM_MUTATION,
      "variables": {
        input: {
          id: 2,
          description: '{value}'
        }
      },
      "value": _vm.algorithmDescription
    }
  }) : _vm._e(), _c('br'), _vm._v(" Last Pulled: " + _vm._s(this.lastUpdatedAt) + " ")], 1), _vm.showQualifiedRedirects == true ? _c('div', {
    staticClass: "bg-gray-100 p-1 border-b mb-2 overflow-y-scroll",
    staticStyle: {
      "height": "15rem"
    }
  }, [_vm.qualifiedRedirectsV2 ? _c('YTable', {
    attrs: {
      "data": _vm.qualifiedRedirectsV2.data || [],
      "loading": _vm.$apolloData.queries.qualifiedRedirectsV2.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _props$rowData$revenu;
        return [_c('td', [[_c('div', {
          staticClass: "flex",
          class: 'flex-row'
        }, [_c('a', {
          staticClass: "link x-action-icon",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.updateFilters(props.rowData.redirect, props.rowData.campaign);
            }
          }
        }, [_c('Icon', {
          staticClass: "w-4 h-4",
          attrs: {
            "name": "target"
          }
        })], 1), _c('div', [_c('router-link', {
          staticClass: "link-wrap",
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.redirect.id
              }
            }
          }
        }, [_c('span', {
          staticClass: "font-semibold"
        }, [_vm._v(_vm._s(props.rowData.redirect.id))]), _vm._v(" (" + _vm._s(props.rowData.redirect.user.label) + ") ")])], 1)])]], 2), _c('td', [[_c('router-link', {
          staticClass: "link-wrap",
          attrs: {
            "to": {
              name: 'Campaign',
              params: {
                id: props.rowData.campaign.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.campaign.label) + " ")])]], 2), _c('td', [_vm._v(_vm._s(props.rowData.countryCode))]), _c('td', [_vm._v("$" + _vm._s((_props$rowData$revenu = props.rowData.revenue) !== null && _props$rowData$revenu !== void 0 ? _props$rowData$revenu : 0))])];
      }
    }], null, false, 1289378848)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Redirect")]), _c('th', [_vm._v("Campaign")]), _c('th', [_vm._v("Geo")]), _c('th', [_vm._v("Revenue")])])], 2) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "w-100 h-50"
  }, [_vm.algorithmsDataV2 ? _c('YTable', {
    attrs: {
      "deletedAtField": "isCapped",
      "deletedAtClass": "text-red-500 font-medium",
      "data": _vm.algorithmsDataV2 || [],
      "loading": _vm.$apolloData.queries.algorithmsDataV2.loading,
      "empty-text": `Not qualified`
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _vm$filters$redirect, _vm$filters$redirect2, _props$rowData$data, _props$rowData$data2, _props$rowData$data3, _props$rowData$data4;
        return [_c('td', [_c('div', {
          staticClass: "flex flex-row justify-between"
        }, [props.rowData.offer ? [_c('div', {
          staticClass: "flex",
          class: _vm.filters.level == 'All' ? 'w-44 2xl:w-auto' : 'w-full'
        }, [_c('router-link', {
          class: props.rowData.isCapped ? 'text-red-600 hover:text-red-700 font-medium' : 'link-wrap',
          attrs: {
            "to": {
              name: 'Offer',
              params: {
                id: props.rowData.offer.id,
                action: 'edit'
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.offer.label) + " ")])], 1)] : _vm._e(), _c('div', {
          staticClass: "flex ml-2",
          class: _vm.filters.level == 'All' ? 'w-24 flex-col justify-center 2xl:flex-row 2xl:w-auto' : 'flex-row justify-end'
        }, [_vm._l(props.rowData.offer.tags, function (tag) {
          return _c('div', {
            key: tag.id
          }, [tag.type === 'vertical' ? _c('Tag', {
            directives: [{
              name: "tooltip",
              rawName: "v-tooltip",
              value: `Vertical`,
              expression: "`Vertical`"
            }],
            key: tag.id,
            staticClass: "mr-3 mb-1 2xl:mb-0",
            attrs: {
              "color": tag.color
            }
          }, [_vm._v(_vm._s(tag.name) + " ")]) : _vm._e()], 1);
        }), _c('div', [props.rowData.key.mobile_desktop == 'Desktop' ? _c('Tag', {
          attrs: {
            "color": "blue"
          }
        }, [_vm._v(_vm._s(props.rowData.key.mobile_desktop))]) : _vm._e(), props.rowData.key.mobile_desktop == 'Mobile' ? _c('Tag', {
          attrs: {
            "color": "blue"
          }
        }, [_vm._v(_vm._s(props.rowData.key.mobile_desktop))]) : _vm._e()], 1)], 2)], 2)]), _c('td', [_vm._v(_vm._s(props.rowData.key.country_code))]), ((_vm$filters$redirect = _vm.filters.redirect) === null || _vm$filters$redirect === void 0 ? void 0 : _vm$filters$redirect.value) !== null ? _c('td', [props.rowData.redirect ? [_c('router-link', {
          staticClass: "link-wrap",
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.redirect.id
              }
            }
          }
        }, [_c('span', {
          staticClass: "font-semibold"
        }, [_vm._v(" " + _vm._s(props.rowData.redirect.id))]), _vm._v(" (" + _vm._s(props.rowData.redirect.subdomain) + ") ")])] : _vm._e()], 2) : _vm._e(), ((_vm$filters$redirect2 = _vm.filters.redirect) === null || _vm$filters$redirect2 === void 0 ? void 0 : _vm$filters$redirect2.value) !== null ? _c('td', [props.rowData.campaign ? [_c('router-link', {
          staticClass: "link-wrap",
          attrs: {
            "to": {
              name: 'Campaign',
              params: {
                id: props.rowData.campaign.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.campaign.label) + " ")])] : _vm._e()], 2) : _vm._e(), _c('td', [(_props$rowData$data = props.rowData.data) !== null && _props$rowData$data !== void 0 && _props$rowData$data.rpm_average && ((_props$rowData$data2 = props.rowData.data) === null || _props$rowData$data2 === void 0 ? void 0 : _props$rowData$data2.rpm_average) !== 0 ? _c('div', [_vm._v(" $" + _vm._s(props.rowData.data.rpm_average) + " ")]) : _c('div', [_vm._v("0")])]), _c('td', [(_props$rowData$data3 = props.rowData.data) !== null && _props$rowData$data3 !== void 0 && _props$rowData$data3.revenue && ((_props$rowData$data4 = props.rowData.data) === null || _props$rowData$data4 === void 0 ? void 0 : _props$rowData$data4.revenue) !== 0 ? _c('div', [_vm._v(" $" + _vm._s(props.rowData.data.revenue) + " ")]) : _c('div', [_vm._v("0")])]), _c('td', [props.rowData.data.weight ? _c('div', [_vm._v(" " + _vm._s(props.rowData.data.weight) + " ")]) : _vm._e()]), _c('td', [props.rowData.data.min ? _c('div', [_vm._v(" " + _vm._s(props.rowData.data.min) + " ")]) : _vm._e()]), _c('td', [props.rowData.data.max ? _c('div', [_vm._v(" " + _vm._s(props.rowData.data.max) + " ")]) : _vm._e()]), _c('td', [props.rowData.data.expiry ? _c('div', [_vm._v(" " + _vm._s(props.rowData.data.expiry) + " ")]) : _vm._e()]), _c('td', [props.rowData.data.fallback ? _c('div', [_vm._v(" " + _vm._s(props.rowData.data.fallback) + " ")]) : _vm._e()])];
      }
    }], null, false, 905473025)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Offer")]), _c('th', [_vm._v("Geo")]), ((_vm$filters$redirect3 = _vm.filters.redirect) === null || _vm$filters$redirect3 === void 0 ? void 0 : _vm$filters$redirect3.value) !== null ? _c('th', [_vm._v(" Redirect ")]) : _vm._e(), ((_vm$filters$redirect4 = _vm.filters.redirect) === null || _vm$filters$redirect4 === void 0 ? void 0 : _vm$filters$redirect4.value) !== null ? _c('th', [_vm._v(" Campaign ")]) : _vm._e(), _c('th', [_vm._v("Rpm")]), _c('th', [_vm._v("Revenue")]), _c('th', [_vm._v("Weight")]), _c('th', [_vm._v("Min")]), _c('th', [_vm._v("Max")]), _c('th', [_vm._v("Expiry Date")]), _c('th', [_vm._v("Fallback")])])], 2) : _vm._e()], 1), _c('div', {
    staticClass: "mt-2"
  }, [_vm.algorithmsDataV2.length > 0 ? _c('YButton', {
    staticClass: "block m-auto",
    attrs: {
      "color": "link",
      "is-loading": _vm.$apollo.queries.algorithmsDataV2.loading
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v("LOAD MORE")]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }