<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <div class="flex items-center">
        <Icon name="pencil" :size="4" class="mr-2"></Icon>
        <template>Edit Publishers</template>
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <Superselect
        title="Publishers"
        v-model="publisher"
        :query="PUBLISHER_OPTIONS_QUERY"
        :query-variables="{
          first: 20,
          filters: {
            label: {
              value: ['{input}'],
              modifiers: { matchType: 'contains' }
            }
          }
        }"
        placeholder="Select"
        track-by="id"
        label="label"
        :max-height="80"
      ></Superselect>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <YCheckbox
        class="flex items-center"
        v-model="closeOnComplete"
        label="Close on Complete"
      ></YCheckbox>
      <YButton
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="updateIpPublishers"
        >Update</YButton
      >
      <ValidationErrors
        v-if="validationErrors"
        :errors="validationErrors"
      ></ValidationErrors>
    </WidgetFooter>
  </Widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import PUBLISHER_OPTIONS_QUERY from '@/graphql/Publisher/PublisherOptionsQuery.gql'
import SYNC_USER_IP_MUTATION from '@/graphql/Ip/SyncUserIpMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    ip: {
      type: Object,
      required: true
    },
    assignedPublishers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      PUBLISHER_OPTIONS_QUERY,
      publisher: this.assignedPublishers,
      isSubmitting: false,
      closeOnComplete: true
    }
  },
  methods: {
    updateIpPublishers() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: SYNC_USER_IP_MUTATION,
          variables: {
            input: {
              userIds: this.publisher
                ? this.publisher.map(publisher => publisher.id)
                : null,
              ip: this.ip.id
            }
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.isSubmitting = false
          this.$toastr.s('Publishers Updated', 'Success!')
          this.$events.emit('refreshYdnsServers')
          this.$events.emit('refreshUserIps')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
          console.log(error)
        })
    }
  }
}
</script>

<style></style>
