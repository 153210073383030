var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', {
    staticClass: "x-publisher-account-manager"
  }, [_c('WidgetBody', [_vm.me && _vm.me.accountManager ? _c('div', {
    staticClass: "widget-edge"
  }, [_c('div', {
    staticClass: "media"
  }, [_c('div', {
    staticClass: "media-body"
  }, [_c('div', {
    staticClass: "flex items-center"
  }, [_c('img', {
    staticClass: "rounded-circle flex m-3 p-2",
    staticStyle: {
      "border-radius": "1000px"
    },
    attrs: {
      "src": _vm.accountManagerImage(_vm.me.accountManager.primaryContact.name),
      "width": "90"
    }
  }), _c('div', [_c('span', {
    staticClass: "block font-semibold uppercase text-gray-600 text-xs leading-tight"
  }, [_vm._v("Account Manager")]), _c('span', {
    staticClass: "text-xl block font-bold text-gray-800 leading-tight mb-1"
  }, [_vm._v(_vm._s(_vm.me.accountManager.primaryContact.name))]), _c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "w-4 h-4 mr-2 text-gray-400",
    attrs: {
      "name": "email"
    }
  }), _c('a', {
    staticClass: "link",
    attrs: {
      "href": _vm.me.accountManager.primaryContact.email ? _vm.accountManagerEmail(_vm.me.accountManager.primaryContact.email) : _vm.accountManagerEmail(_vm.me.accountManager.email)
    }
  }, [_vm._v(" " + _vm._s(_vm.me.accountManager.primaryContact.email ? _vm.me.accountManager.primaryContact.email : _vm.accountManagerEmail(_vm.me.accountManager.email)) + " ")])], 1), _c('div', {
    staticClass: "flex items-center"
  }, [_c('Icon', {
    staticClass: "w-4 h-4 mr-2 text-blue-500",
    attrs: {
      "name": "skype"
    }
  }), _c('a', {
    staticClass: "link",
    attrs: {
      "href": `skype:${JSON.parse(_vm.me.accountManager.primaryContact.messenger).handle}?add`
    }
  }, [_vm._v(_vm._s(JSON.parse(_vm.me.accountManager.primaryContact.messenger).handle))])], 1)])]), _c('div', {
    staticClass: "Widget-x-edge text-center py-3 border-t bg-gray-100"
  }, [_c('a', {
    staticClass: "font-semibold link",
    attrs: {
      "href": _vm.accountManagerTelegramLink(_vm.me.accountManager.primaryContact.name),
      "target": "_blank"
    }
  }, [_vm._v("Contact an AM on Telegram")])])])])]) : _c('div', {
    staticClass: "widget-edge"
  }, [_c('div', {
    staticClass: "media"
  }, [_c('div', {
    staticClass: "media-body"
  }, [_c('a', {
    attrs: {
      "href": "mailto:affiliates@ytz.com"
    }
  }, [_c('div', {
    staticClass: "Widget-x-edge text-center py-3 border-t bg-gray-100 font-semibold link"
  }, [_vm._v(" Contact an Account Manager ")])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }