var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "flex-grow max-w-full"
  }, [_c('div', {
    staticClass: "flex w-full gap-8 2xl:gap-8"
  }, [_c('div', {
    staticClass: "w-full flex flex-col gap-3 pt-4"
  }, [_c('div', {
    staticClass: "grid grid-cols-9 gap-4"
  }, [_c('div', {
    staticClass: "pt-2 col-span-3 flex flex-col gap-2"
  }, [_c('p', {
    staticClass: "w-full font-bold text-lg mb-3 text-gray-900"
  }, [_vm._v(" English ")]), _vm._l(_vm.offerTranslationVars, function (offerVar, index) {
    return _c('div', {
      key: index
    }, [_vm.inputFieldType(offerVar) == 'string' ? _c('div', [_c('YInput', {
      staticClass: "w-full",
      attrs: {
        "disabled": "",
        "value": _vm.offerVars[offerVar],
        "label": offerVar
      }
    })], 1) : _vm._e(), _vm.inputFieldType(offerVar) == 'textarea' ? _c('div', [_c('YTextarea', {
      staticClass: "w-full",
      attrs: {
        "disabled": "",
        "value": _vm.offerVars[offerVar],
        "label": offerVar
      }
    })], 1) : _vm._e()]);
  })], 2), _c('div', {
    staticClass: "bg-gray-100 rounded-lg py-2 px-4 col-span-6 flex flex-col"
  }, [_c('div', {
    staticClass: "w-full font-bold text-lg text-gray-900 flex justify-between mb-0.5"
  }, [_c('p', [_vm._v(" Language: "), _c('span', {
    staticClass: "uppercase"
  }, [_vm._v(_vm._s(_vm.selectedLanguage))])])]), _vm._m(0), _vm._l(_vm.translationValues, function (translation) {
    return _c('div', {
      key: translation.id,
      staticClass: "grid grid-cols-2 gap-2"
    }, [_c('div', {
      staticClass: "flex flex-col gap-2"
    }, _vm._l(translation.data, function (value, key) {
      return _c('div', {
        key: key
      }, [_vm.inputFieldType(key) == 'string' ? _c('div', [_c('YInput', {
        attrs: {
          "label": key + '_' + _vm.selectedLanguage,
          "value": value,
          "disabled": ""
        }
      })], 1) : _vm._e(), _vm.inputFieldType(key) == 'textarea' ? _c('div', [_c('YTextarea', {
        attrs: {
          "label": key + '_' + _vm.selectedLanguage,
          "value": value,
          "disabled": ""
        }
      })], 1) : _vm._e()]);
    }), 0), _c('div', {
      staticClass: "flex flex-col gap-2"
    }, _vm._l(_vm.existsManualTranslations, function (value, key) {
      return _c('div', {
        key: key
      }, [_vm.inputFieldType(key) == 'string' ? _c('div', [_c('YInput', {
        attrs: {
          "placeholder": key,
          "value": _vm.localTranslation[key],
          "label": 'manual_' + key + '_' + _vm.selectedLanguage
        },
        model: {
          value: _vm.localTranslation[key],
          callback: function ($$v) {
            _vm.$set(_vm.localTranslation, key, $$v);
          },
          expression: "localTranslation[key]"
        }
      })], 1) : _vm._e(), _vm.inputFieldType(key) == 'textarea' ? _c('div', [_c('YTextarea', {
        attrs: {
          "placeholder": key,
          "value": _vm.localTranslation[key],
          "label": 'manual_' + key + '_' + _vm.selectedLanguage
        },
        model: {
          value: _vm.localTranslation[key],
          callback: function ($$v) {
            _vm.$set(_vm.localTranslation, key, $$v);
          },
          expression: "localTranslation[key]"
        }
      })], 1) : _vm._e()]);
    }), 0)]);
  })], 2)]), _c('div', {
    staticClass: "w-full flex justify-end"
  }, [_vm.isSubmitting ? _c('YButton', {
    staticClass: "self-end mt-2 w-24",
    staticStyle: {
      "width": "14rem"
    },
    attrs: {
      "disabled": "",
      "color": "blue"
    }
  }, [_c('Spinner', {
    attrs: {
      "color": "white",
      "size": 5
    }
  })], 1) : _vm._e(), !_vm.isSubmitting ? _c('YButton', {
    staticClass: "self-end mt-2",
    staticStyle: {
      "width": "14rem"
    },
    attrs: {
      "color": "blue",
      "disabled": _vm.isSubmitting
    },
    on: {
      "click": _vm.saveManualTranslations
    }
  }, [_vm._v(" Save Manual Translations ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "flex-end pt-3 flex flex-col",
    staticStyle: {
      "width": "14rem"
    }
  }, [_c('div', {
    staticClass: "w-full rounded-lg border-2 border-blue-500 p-1",
    staticStyle: {
      "height": "fit-content"
    }
  }, [_c('Superselect', {
    staticClass: "w-full",
    attrs: {
      "title": "Available Translations",
      "options": _vm.selectedTranslatableLanguages,
      "multiple": false
    },
    model: {
      value: _vm.selectedLanguage,
      callback: function ($$v) {
        _vm.selectedLanguage = $$v;
      },
      expression: "selectedLanguage"
    }
  })], 1)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex text-xs text-gray-900"
  }, [_c('span', {
    staticClass: "w-1/2 pl-1"
  }, [_vm._v("Original LLM")]), _c('span', {
    staticClass: "w-1/2 pl-1"
  }, [_vm._v("Manual Values")])]);

}]

export { render, staticRenderFns }