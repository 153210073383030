var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    staticClass: "tiny-forms bg-primary blue pt-4 pb-4 text-white"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 ts-4"
  }, [_c('div', {}, [_c('i', {
    staticClass: "zmdi zmdi-file-plus pr-2"
  }), _vm._v(" Edit Provider ")]), _c('close-x', {
    staticClass: "text-white",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  })], 1)])], 2), _c('widget-body', {
    staticClass: "pt-5"
  }, [_c('y-form-group', {
    attrs: {
      "label": "Name",
      "error-strings": _vm.validationErrors.name
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.provider.name,
      expression: "provider.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.provider.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.provider, "name", $event.target.value);
      }
    }
  })]), _c('y-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_c('multiselect', {
    attrs: {
      "options": _vm.typeOptions,
      "label": "label",
      "track-by": "id",
      "multiple": false
    },
    model: {
      value: _vm.provider.type,
      callback: function ($$v) {
        _vm.$set(_vm.provider, "type", $$v);
      },
      expression: "provider.type"
    }
  })], 1)], 1), _c('widget-footer', {
    staticClass: "pt-4 pb-4"
  }, [_c('y-checkbox', {
    attrs: {
      "label": "Close on Complete"
    },
    model: {
      value: _vm.closeOnComplete,
      callback: function ($$v) {
        _vm.closeOnComplete = $$v;
      },
      expression: "closeOnComplete"
    }
  }), _c('y-button', {
    staticClass: "btn btn-primary btn-sm ml-auto",
    attrs: {
      "is-loading": _vm.isSubmitting,
      "type": "button"
    },
    on: {
      "click": _vm.update,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_vm._v("Update")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }