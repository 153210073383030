<template>
  <div class="flex flex-col flex-grow overflow-hidden">
    <div class="x-form px-3">
      <textarea
        class="px-3 py-3 w-full rounded mb-2 border block"
        v-model="message"
        placeholder="Type here..."
        @keydown.exact.prevent.enter="addComment()"
      />
      <YButton
        color="blue"
        class="block w-full py-2"
        @click="addComment()"
        :is-loading="isAdding"
        :disabled="!message"
        >Add Comment</YButton
      >
    </div>
    <div class="x-comments flex-grow overflow-y-auto">
      <div class="x-comment" v-for="comment in comments" :key="comment.id">
        <div class="x-header">
          <div class="flex items-center justify-center ">
            <UserAvatar height="8" :id="comment.commenter.id" />
            <div class="x-header-text">
              {{ comment.commenter.primaryContact.name }}
            </div>
          </div>
          <div class="text-xs text-gray-500">
            {{ comment.createdAt | luxonRelative }}
          </div>
        </div>
        <div class="x-message">
          {{ comment.message }}
        </div>
      </div>
    </div>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </div>
</template>

<script>
import CREATE_COMMENT_MUTATION from '@/graphql/Comment/CreateCommentMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    comments: {
      type: Array
    },
    modelId: {
      type: [Number, String],
      required: true
    },
    modelClass: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      message: null,
      isAdding: false
    }
  },
  methods: {
    addComment() {
      this.isAdding = true
      this.$apollo
        .mutate({
          mutation: CREATE_COMMENT_MUTATION,
          variables: {
            input: {
              modelId: this.modelId,
              modelClass: this.modelClass,
              message: this.message,
              commentType: null,
              tags: []
            }
          }
        })
        .then(() => {
          this.isAdding = false
          this.clearValidationErrors()
          this.$events.emit('refreshParentTable')
          this.$toastr.s('', 'Comment Added')
          this.message = null
        })
        .catch(error => {
          this.isAdding = false
          this.setValidationErrors(error)
        })
    }
  }
}
</script>

<style scoped>
.x-comments {
}

.x-comment {
  @apply py-3 px-3;
}

.x-comment:nth-child(odd) {
  @apply bg-gray-200;
}

.x-header {
  @apply flex items-center justify-between mb-2;
}

.x-header-text {
  @apply font-bold text-gray-800 truncate ml-2;
}

.x-message {
  @apply leading-snug text-gray-800 py-1;
}

.x-counter {
  @apply w-5 h-5 rounded-full bg-green-600 absolute top-0 right-0 -mr-1 -mt-1 text-white text-center text-xs;
}

.x-form {
  @apply mb-4;
}
</style>
