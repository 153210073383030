var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Edit Redirect Fallback ")]), _c('WidgetBody', [_c('UpdateCampaignFallback', {
    attrs: {
      "id": _vm.id,
      "fallbackCampaignId": _vm.fallbackCampaignId,
      "fallbackOfferId": _vm.fallbackOfferId,
      "type": "Redirect"
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }