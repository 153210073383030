// let fields = {
//   day: {
//     filter: {
//       id: "show-day",
//       label: "Day",
//       state: true,
//       visible: true,
//       disabled: false,
//       group: "date"
//     },
//     column: {
//       name: "day",
//       title: "Date",
//       sortField: "day",
//       dataClass: "",
//       titleClass: "",
//       visible: true,
//       defSortDirection: "desc"
//     }
//   },
//   month: {
//     filter: {
//       id: "show-month",
//       label: "Month",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "date"
//     },
//     column: {
//       name: "month",
//       title: "Date",
//       sortField: "month",
//       dataClass: "",
//       titleClass: "",
//       visible: true,
//       defSortDirection: "desc"
//     }
//   },
//   hour: {
//     filter: {
//       id: "show-hour",
//       label: "Hour",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "date"
//     },
//     column: {
//       name: "hour",
//       title: "Date",
//       sortField: "hour",
//       dataClass: "",
//       titleClass: "",
//       visible: true,
//       defSortDirection: "desc"
//     }
//   },
//   impressionId: {
//     filter: {
//       id: "show-impression-id",
//       label: "Impression ID",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "impression-id-slot",
//       title: "Impression ID",
//       sortField: "impressionId",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   clickId: {
//     filter: {
//       id: "show-click-id",
//       label: "Click ID",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "click-id-slot",
//       title: "Click ID",
//       sortField: "clickId",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   country: {
//     filter: {
//       id: "show-country",
//       label: "Country",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "country-slot",
//       title: "Country",
//       sortField: "country",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   publisher: {
//     filter: {
//       id: "show-publisher",
//       label: "Publisher",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "publisher-slot",
//       title: "Publisher",
//       sortField: "publisher",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   publisherId: {
//     filter: {
//       id: "show-publisher-id",
//       label: "Publisher ID",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "publisherId",
//       title: "Publisher ID",
//       sortField: "publisherId",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   publisherLogin: {
//     filter: {
//       id: "show-publisher-id",
//       label: "Publisher Login",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "publisherLogin",
//       title: "Publisher Login",
//       sortField: "publisherLogin",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   publisherAccountManager: {
//     filter: {
//       id: "show-publisher-account-manager",
//       label: "Publisher Account Manager",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "publisherAccountManager",
//       title: "Publisher Account Manager",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   publisherPaymentTerms: {
//     filter: {
//       id: "show-publisher-payment-terms",
//       label: "Publisher Payment Terms",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "publisherPaymentTerms",
//       title: "Publisher Payment Terms",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   publisherPaymentFrequency: {
//     filter: {
//       id: "show-publisher-payment-frequency",
//       label: "Publisher Payment Frequency",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "publisherPaymentFrequency",
//       title: "Publisher Payment Frequency",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   publisherTax: {
//     filter: {
//       id: "show-publisher-tax",
//       label: "Publisher Tax",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "publisherTax",
//       title: "Publisher Tax",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   tipaltiPublisherCountry: {
//     filter: {
//       id: "show-tipalti-publisher-country",
//       label: "Tiplati Publisher Country",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "tipaltiPublisherCountry",
//       title: "Tiplati Country",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   tipaltiPublisherCity: {
//     filter: {
//       id: "show-tipalit-publisher-city",
//       label: "Tipalti Publisher City",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "tipaltiPublisherCity",
//       title: "Tipalti City",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   tipaltiPublisherName: {
//     filter: {
//       id: "show-tipalti-publisher-name",
//       label: "Tipalti Publisher Name",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "tipaltiPublisherName",
//       title: "Tipalti Name",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   tipaltiPublisherCompanyName: {
//     filter: {
//       id: "show-tipalti-publisher-company-name",
//       label: "Tiptali Company Name",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "tipaltiPublisherCompanyName",
//       title: "Tipalti Company Name",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   tipaltiPublisherPaymentMethod: {
//     filter: {
//       id: "show-tipalti-publisher-payment-method",
//       label: "Tipalti Payment Method",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "tipaltiPublisherPaymentMethod",
//       title: "Tipalti Payment Mehtod",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   campaign: {
//     filter: {
//       id: "show-campaign",
//       label: "Campaign",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "campaign-slot",
//       title: "Campaign",
//       sortField: "campaign",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   advertiser: {
//     filter: {
//       id: "show-advertiser",
//       label: "Advertiser",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "advertiser-slot",
//       title: "Advertiser",
//       sortField: "advertiser",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   advertiserId: {
//     filter: {
//       id: "show-advertiser-id",
//       label: "Advertiser ID",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "advertiserId",
//       title: "Advertiser ID",
//       sortField: "advertiserId",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   advertiserName: {
//     filter: {
//       id: "show-advertiser-name",
//       label: "Advertiser Name",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "advertiserName",
//       title: "Advertiser Name",
//       sortField: "advertiserName",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   advertiserBillingName: {
//     filter: {
//       id: "show-advertiser-billing-name",
//       label: "Advertiser Billing Name",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "advertiserBillingName",
//       title: "Advertiser Billing Name",
//       sortField: "advertiserBillingName",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   advertiserAccount: {
//     filter: {
//       id: "show-advertiser-account",
//       label: "Advertiser Account",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "advertiserAccount",
//       title: "Advertiser Account",
//       sortField: "advertiserAccount",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   advertiserAccountManager: {
//     filter: {
//       id: "show-advertiser-account-manager",
//       label: "Advertiser Account Manager",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "advertiserAccountManager",
//       title: "Advertiser Account Manager",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   offer: {
//     filter: {
//       id: "show-offer",
//       label: "Offer",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "offer-slot",
//       title: "Offer",
//       sortField: "offer",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   source: {
//     filter: {
//       id: "show-source",
//       label: "Source",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "source-slot",
//       title: "Source",
//       sortField: "source",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   template: {
//     filter: {
//       id: "show-template",
//       label: "Template",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "template-slot",
//       title: "Template",
//       sortField: "template",
//       callback: "",
//       dataClass: "template-column",
//       titleClass: "template-column",
//       visible: true
//     }
//   },
//   groupService: {
//     filter: {
//       id: "show-group-service",
//       label: "Group Service",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "group-service-slot",
//       title: "Group Service",
//       sortField: "groupService",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   mini: {
//     filter: {
//       id: "show-mini",
//       label: "Mini",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "mini-slot",
//       title: "Mini",
//       sortField: "mini",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   vertical: {
//     filter: {
//       id: "show-vertical",
//       label: "Vertical",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "vertical-slot",
//       title: "Vertical",
//       sortField: "vertical",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   browser: {
//     filter: {
//       id: "show-browser",
//       label: "Browser",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "browser-slot",
//       title: "Browser",
//       sortField: "browser",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   os: {
//     filter: {
//       id: "show-os",
//       label: "OS",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "os-slot",
//       title: "OS",
//       sortField: "os",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   device: {
//     filter: {
//       id: "show-device",
//       label: "Device",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "device-slot",
//       title: "Device",
//       sortField: "device",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   usageType: {
//     filter: {
//       id: "show-usage-type",
//       label: "Usage Type",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "usage-type-slot",
//       title: "Usage Type",
//       sortField: "usageType",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   isp: {
//     filter: {
//       id: "show-isp-type",
//       label: "ISP",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "isp-slot",
//       title: "ISP",
//       sortField: "isp",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   ip: {
//     filter: {
//       id: "show-ip",
//       label: "IP",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "ip-slot",
//       title: "IP",
//       sortField: "ip",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   forwardedForIp: {
//     filter: {
//       id: "show-forwarded-for-ip",
//       label: "Forwarded For IP",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "forwarded-for-ip-slot",
//       title: "Forwarded For IP",
//       sortField: "forwardedForIp",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   redirect: {
//     filter: {
//       id: "show-redirect",
//       label: "Redirect",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "redirect-slot",
//       title: "Redirect",
//       sortField: "redirect",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   redirectLink: {
//     filter: {
//       id: "show-redirect-link",
//       label: "Redirect Link",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "redirect-link-slot",
//       title: "Redirect Link",
//       sortField: "redirectLink",
//       //callback: '',
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   mobileDesktop: {
//     filter: {
//       id: "show-mobile-desktop",
//       label: "Mobile / Desktop",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "mobile-desktop-slot",
//       title: "Mobile / Desktop",
//       sortField: "mobileDesktop",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   language: {
//     filter: {
//       id: "show-language",
//       label: "Language",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "language-slot",
//       title: "Language",
//       sortField: "language",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   referer: {
//     filter: {
//       id: "show-referer",
//       label: "Referer",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "referer-slot",
//       title: "Referer",
//       sortField: "referer",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   subId: {
//     filter: {
//       id: "show-sub-id",
//       label: "Sub ID",
//       state: false,
//       visible: false,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "subid-slot",
//       title: "Sub ID",
//       sortField: "subId",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   sub1: {
//     filter: {
//       id: "show-sub1",
//       label: "Sub1",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "sub1",
//       title: "Sub1",
//       sortField: "sub1",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   sub2: {
//     filter: {
//       id: "show-sub2",
//       label: "Sub2",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "sub2",
//       title: "Sub2",
//       sortField: "sub2",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   sub3: {
//     filter: {
//       id: "show-sub3",
//       label: "Sub3",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "sub3",
//       title: "Sub3",
//       sortField: "sub3",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   sub4: {
//     filter: {
//       id: "show-sub4",
//       label: "Sub4",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "sub4",
//       title: "Sub4",
//       sortField: "sub4",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   sub5: {
//     filter: {
//       id: "show-sub5",
//       label: "Sub5",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "sub5",
//       title: "Sub5",
//       sortField: "sub5",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   keyword: {
//     filter: {
//       id: "show-keyword-id",
//       label: "Keyword",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "keyword-slot",
//       title: "Keyword",
//       sortField: "keyword",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   referralPayoutFrom: {
//     filter: {
//       id: "show-referral-revenue-from",
//       label: "Referral Payout From",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "dimensions"
//     },
//     column: {
//       name: "referralPayoutFrom",
//       title: "Referral Payout From",
//       sortField: "referralPayoutFrom",
//       callback: "",
//       dataClass: "",
//       titleClass: "",
//       visible: true
//     }
//   },
//   //   		rpa: {
//   // filter: {
//   //    			id: 'show-rpa',
//   //    			label: 'RPA',
//   //    			state: false,
//   //    			visible: true,
//   //    			disabled: false,
//   //    			group: 'metrics'
//   // },
//   // 	column: {
//   // 	name: 'rpa',
//   // 	title: 'RPA',
//   // 	sortField: 'rpa',
//   // 	callback: '',
//   // 	dataClass: 'data-metric',
//   // 	titleClass: '',
//   // 	visible: true,
//   // 	callback: '$formatMoney'
//   // }
//   //   		},
//   //   		ppa: {
//   // filter: {
//   //    			id: 'show-ppa',
//   //    			label: 'PPA',
//   //    			state: false,
//   //    			visible: true,
//   //    			disabled: false,
//   //    			group: 'metrics'
//   // },
//   // 	column: {
//   // 	name: 'ppa',
//   // 	title: 'PPA',
//   // 	sortField: 'ppa',
//   // 	callback: '',
//   // 	dataClass: 'data-metric',
//   // 	titleClass: '',
//   // 	visible: true,
//   // 	callback: '$formatMoney'
//   // }
//   //   		},
//   //   		cpm: {
//   // filter: {
//   //    			id: 'show-cpm',
//   //    			label: 'CPM',
//   //    			state: false,
//   //    			visible: true,
//   //    			disabled: false,
//   //    			group: 'metrics'
//   // },
//   // 	column: {
//   // 	name: 'cpm',
//   // 	title: 'CPM',
//   // 	sortField: 'cpm',
//   // 	callback: '',
//   // 	dataClass: 'data-metric',
//   // 	titleClass: '',
//   // 	visible: true,
//   // 	callback: '$formatMoney'
//   // }
//   //   		},
//   uniques: {
//     filter: {
//       id: "show-uniques",
//       label: "Uniques",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "uniques",
//       title: "Uniques",
//       sortField: "uniques",
//       formatter: value => {
//         return this.$formatInt(value);
//       },
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       defSortDirection: "desc"
//     }
//   },
//   visits: {
//     filter: {
//       id: "show-visits",
//       label: "Impressions",
//       state: true,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "visits",
//       title: "Impressions",
//       sortField: "visits",
//       formatter: value => {
//         return this.$formatInt(value);
//       },
//       callback: "$formatInt",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       defSortDirection: "desc"
//     }
//   },
//   ppmActions: {
//     filter: {
//       id: "show-ppm-actions",
//       label: "PPM Actions",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "ppmActions",
//       title: "PPM Actions",
//       sortField: "ppm-actions",
//       formatter: value => {
//         return this.$formatInt(value);
//       },
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       defSortDirection: "desc"
//     }
//   },
//   clicks: {
//     filter: {
//       id: "show-clicks",
//       label: "Clicks",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "clicks",
//       title: "Clicks",
//       sortField: "clicks",
//       formatter: value => {
//         return this.$formatInt(value);
//       },
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       defSortDirection: "desc"
//     }
//   },
//   conversions: {
//     filter: {
//       id: "show-conversions",
//       label: "Conversions",
//       state: true,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "conversions",
//       title: "Conversions",
//       sortField: "conversions",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatInt(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   yLeaderConversions: {
//     filter: {
//       id: "show-y-leader-conversions",
//       label: "yConversions",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "yLeaderConversions",
//       title: "yConversions",
//       sortField: "yLeaderConversions",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatInt(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   publisherConversions: {
//     filter: {
//       id: "show-conversions",
//       label: "Publisher Conversions",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "publisherConversions",
//       title: "Publisher Conversions",
//       sortField: "publisherConversions",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatInt(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   conversionRate: {
//     filter: {
//       id: "show-conversions-rate",
//       label: "CR% - Conversion Rate",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "conversionRate",
//       title: "CR%",
//       sortField: "conversionRate",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatPercent(value, 2);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   //   		adjustments_count: {
//   // filter: {
//   //    			id: 'show-adjustments-count',
//   //    			label: 'Adjustments (Count)',
//   //    			state: false,
//   //    			visible: true,
//   //    			disabled: false,
//   //    			group: 'metrics'
//   // },
//   // 	column: {
//   // 	name: 'adjustments_count',
//   // 	title: 'Adjustments (Count)',
//   // 	sortField: 'adjustments_count',
//   // 	dataClass: 'data-metric',
//   // 	titleClass: '',
//   // 	visible: true,
//   // 	callback: '$formatInt',
//   // 	defSortDirection: 'desc'
//   // }
//   //   		},
//   //   		adjustments_value: {
//   // filter: {
//   //    			id: 'show-adjustments-value',
//   //    			label: 'Adjustments (Value)',
//   //    			state: false,
//   //    			visible: true,
//   //    			disabled: false,
//   //    			group: 'metrics'
//   // },
//   // 	column: {
//   // 	name: 'adjustments_value',
//   // 	title: 'Adjustments (Value)',
//   // 	sortField: 'adjustments_value',
//   // 	dataClass: 'data-metric',
//   // 	titleClass: '',
//   // 	visible: true,
//   // 	callback: '$formatMoney',
//   // 	defSortDirection: 'desc'
//   // }
//   //   		},
//   ctr: {
//     filter: {
//       id: "show-ctr",
//       label: "CTR",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "ctr",
//       title: "CTR",
//       sortField: "ctr",
//       callback: "",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatPercent(value, 2);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   rpa: {
//     filter: {
//       id: "show-rpa",
//       label: "RPA",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "rpa",
//       title: "RPA",
//       sortField: "rpa",
//       callback: "",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatMoney(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   ppa: {
//     filter: {
//       id: "show-ppa",
//       label: "PPA",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "ppa",
//       title: "PPA",
//       sortField: "ppa",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatMoney(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   epc: {
//     filter: {
//       id: "show-epc",
//       label: "EPC",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "epc",
//       title: "EPC",
//       sortField: "epc",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatMoney(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   yEpc: {
//     filter: {
//       id: "show-y-epc",
//       label: "yEPC",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "yEpc",
//       title: "yEPC",
//       sortField: "yEpc",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatMoney(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   yCpl: {
//     filter: {
//       id: "show-y-cpl",
//       label: "yCPL",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "yCpl",
//       title: "yCPL",
//       sortField: "yCpl",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatMoney(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   adminEcpm: {
//     filter: {
//       id: "show-admin-ecpm",
//       label: "eCPM",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "adminEcpm",
//       title: "eCPM",
//       sortField: "adminEcpm",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatMoney(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   ecpm: {
//     filter: {
//       id: "show-ecpm",
//       label: "Publisher eCPM",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "ecpm",
//       title: "Publisher eCPM",
//       sortField: "ecpm",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatMoney(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   payout: {
//     filter: {
//       id: "show-payout",
//       label: "Payout",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "payout",
//       title: "Payout",
//       sortField: "payout",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatMoney(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   //   		net: {
//   // filter: {
//   //    			id: 'show-net',
//   //    			label: 'Net',
//   //    			state: false,
//   //    			visible: true,
//   //    			disabled: false,
//   //    			group: 'metrics'
//   // },
//   // 	column: {
//   // 	name: 'net',
//   // 	title: 'Net',
//   // 	sortField: 'net',
//   // 	callback: '',
//   // 	dataClass: 'data-metric',
//   // 	titleClass: '',
//   // 	visible: true,
//   // 	callback: '$formatMoney'
//   // }
//   //   		},
//   revenue: {
//     filter: {
//       id: "show-revenue",
//       label: "Revenue",
//       state: true,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "revenue",
//       title: "Revenue",
//       sortField: "revenue",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatMoney(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   yLeaderRevenue: {
//     filter: {
//       id: "show-yleader-revenue",
//       label: "yRevenue",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "yLeaderRevenue",
//       title: "yRevenue",
//       sortField: "yLeaderRevenue",
//       dataClass: "data-metric",
//       titleClass: "",
//       visible: true,
//       formatter: value => {
//         return this.$formatMoney(value);
//       },
//       defSortDirection: "desc"
//     }
//   },
//   adjustments: {
//     filter: {
//       id: "show-adjustments",
//       label: "Adjustments",
//       state: false,
//       visible: true,
//       disabled: false,
//       group: "metrics"
//     },
//     column: {
//       name: "adjustments",
//       title: "Adjustments",
//       sortField: "adjustments",
//       dataClass: "data-metric",
//       formatter: value => {
//         return this.$formatInt(value);
//       },
//       titleClass: "",
//       visible: true,
//       defSortDirection: "desc"
//     }
//   }
// };

export const namespaced = true

export const state = {
  // fieldLabels: fields.map(field=>field.column.title),
  // fieldGroups: fields.map(field=>filter.group.title),
  // sortFields: fields.map(field=>field.column.sortField),
  // columnVisibleStates: fields.map(field=>field.column.visible),
  // filterStates: fields.map(field=>field.filter.state),
  // filterVisibleStates: fields.map(field=>field.filter.visible),
  // filterDisabledStates fields.map(field=>field.filter.disabled),
  // defaultSortDirections: fields.map(field=>field.filter.defSortDirection),
  adminFilterJson: null,
  publisherFilterJson: null
}

export const getters = {
  adminFilters: state =>
    state.adminFilterJson ? JSON.parse(state.adminFilterJson) : null,
  publisherFilters: state =>
    state.publisherFilterJson ? JSON.parse(state.publisherFilterJson) : null
}

export const mutations = {
  UPDATE_ADMIN_FILTERS(state, filters) {
    state.adminFilterJson = JSON.stringify(filters)
  },
  UPDATE_PUBLISHER_FILTERS(state, filters) {
    state.publisherFilterJson = JSON.stringify(filters)
  }
}

export const actions = {
  updateAdminFilters({ commit }, filters) {
    commit('UPDATE_ADMIN_FILTERS', filters)
  },
  updatePublisherFilters({ commit }, filters) {
    commit('UPDATE_PUBLISHER_FILTERS', filters)
  }
}
