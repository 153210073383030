<template>
  <div>
    <ReportFilters
      ref="form"
      v-show="showFilters"
      :link="this"
      :master-column-data="masterColumnData"
      :create-vuetable-columns="createVuetableColumns"
      :persist-state="persistState"
      :auto-load="autoLoad"
      :default-date-range="defaultDateRange"
      :default-filters="defaultFilters"
      :initial-preset-id="initialPresetId"
    ></ReportFilters>
    <Widget
      :class="[hasEverLoaded ? '' : 'disabled', 'm-0']"
      :loading="isLoading"
    >
      <WidgetHeader>
        <template slot="rawContent">
          <slot name="table-header">
            <h4 class="widget-title">{{ readableDateRange }}</h4>
          </slot>
          <template v-if="isLoading">
            <Spinner v-if="this.isLoading" :size="35" :color="`green`" />
          </template>
          <template v-else>
            <slot name="right"> </slot>
          </template>
        </template>
      </WidgetHeader>
      <WidgetBody
        :class="[
          $refs.form && $refs.form.$apollo.queries.report.loading
            ? 'reports-loading'
            : ''
        ]"
      >
        <slot name="table-body-start"></slot>
        <div class="row">
          <div class="col overflow-x-scroll">
            <vuetable
              ref="vuetable"
              class="reports-table table-striped table-hover"
              :api-mode="false"
              :fields="fields"
              :data="vuetableData"
              :data-total="dataTotal"
              pagination-path="pagination"
              data-path="data"
              :css="css"
              :sort-order="sortOrder"
              :multi-sort="multiSort"
              :per-page="perPage"
              multi-sort-key="shift"
              cellspacing="0"
              width="100%"
              table-wrapper="vuetable-wrapper"
              @vuetable:loading="onLoading"
              @vuetable:loaded="onLoaded"
            >
              <template slot="tableTotals" slot-scope="props">
                <tr class="spacer"></tr>
                <tr>
                  <td v-for="field in props.fields" :key="field.name">
                    <span v-text="formatTotalField(field)"></span>
                  </td>
                </tr>
                <tr class="spacer h-2"></tr>
              </template>

              <div slot="country-slot" slot-scope="props">
                <div class="report-value">
                  <ReportFilterValueController
                    v-if="props.rowData.country"
                    v-model="$refs.form.filters.country.value"
                    :controller-value="{
                      id: props.rowData.country,
                      label: props.rowData.country
                    }"
                    class="pr-2"
                  ></ReportFilterValueController>
                  <span v-text="props.rowData.country"></span>
                </div>
              </div>
              <div slot="campaign-slot" slot-scope="props">
                <template v-if="props.rowData.campaign">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.campaign"
                      v-model="$refs.form.filters.campaign.value"
                      :controller-value="{
                        id: props.rowData.campaign.split(' - ')[0],
                        label: props.rowData.campaign
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <router-link
                      :to="{
                        name: 'Publisher Campaign',
                        params: {
                          id: props.rowData.campaign.split(' - ')[0]
                        }
                      }"
                      class="link without-truncate"
                      style="max-width: 20rem;"
                      >{{ props.rowData.campaign }}</router-link
                    >
                  </div>
                </template>
              </div>
              <div slot="template-slot" slot-scope="props">
                <template v-if="props.rowData.template">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.template"
                      v-model="$refs.form.filters.template.value"
                      :controller-value="{
                        id: props.rowData.template.split(' - ')[0],
                        label: props.rowData.template
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.template"></span>
                  </div>
                </template>
              </div>

              <div slot="mobile-desktop-slot" slot-scope="props">
                <template v-if="props.rowData.mobileDesktop">
                  <div class="report-value">
                    <a
                      href="#"
                      class="mr-2"
                      :class="[
                        (props.rowData.mobileDesktop == 'Mobile' &&
                          $refs.form.filters.mobileDesktop.modifiers.exclude !=
                            false) ||
                        (props.rowData.mobileDesktop == 'Desktop' &&
                          $refs.form.filters.mobileDesktop.modifiers.exclude ==
                            false)
                          ? 'text-muted'
                          : ''
                      ]"
                      @click.prevent="
                        props.rowData.mobileDesktop == 'Mobile'
                          ? ($refs.form.filters.mobileDesktop.modifiers.exclude = false)
                          : ($refs.form.filters.mobileDesktop.modifiers.exclude = true)
                      "
                    >
                      <Icon name="target" :size="4" />
                    </a>

                    <span v-text="props.rowData.mobileDesktop"></span>
                  </div>
                </template>
              </div>

              <div slot="usage-type-slot" slot-scope="props">
                <template v-if="props.rowData.usageType">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.usageType"
                      v-model="$refs.form.filters.usageType.value"
                      :controller-value="{
                        id: props.rowData.usageType,
                        label: props.rowData.usageType
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.usageType"></span>
                  </div>
                </template>
              </div>

              <div slot="keyword-slot" slot-scope="props">
                <template v-if="props.rowData.keyword">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.keyword"
                      v-model="$refs.form.filters.keyword.value"
                      :controller-value="{
                        id: props.rowData.keyword,
                        label: props.rowData.keyword
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.keyword"></span>
                  </div>
                </template>
              </div>
              <div slot="subid-slot" slot-scope="props">
                <template v-if="props.rowData.subId">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.subId"
                      v-model="$refs.form.filters.subId.value"
                      :controller-value="{
                        id: props.rowData.subId,
                        label: props.rowData.subId
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.subId"></span>
                  </div>
                </template>
              </div>
              <div slot="conversion-type-slot" slot-scope="props">
                <template v-if="props.rowData.payoutConversionType">
                  <div class="report-value">
                    <ReportFilterValueController
                      v-if="props.rowData.payoutConversionType"
                      v-model="$refs.form.filters.payoutConversionType.value"
                      :controller-value="{
                        id: props.rowData.payoutConversionType,
                        label: props.rowData.payoutConversionType
                      }"
                      class="pr-2"
                    ></ReportFilterValueController>
                    <span v-text="props.rowData.payoutConversionType"></span>
                  </div>
                </template>
              </div>
            </vuetable>
          </div>
        </div>
      </WidgetBody>
    </Widget>
  </div>
</template>

<script>
import Vuetable from '@/components/legacy/vuetable-2-2-custom/src/components/Vuetable'
import ReportFilters from '@/views/Publisher/Reports/ReportFilters'
import ReportFilterValueController from '@/views/Publisher/Reports/ReportFilterValueController'

export default {
  components: {
    Vuetable,
    ReportFilters,
    ReportFilterValueController
  },
  props: {
    showFilters: {
      default: true,
      type: Boolean
    },
    persistState: {
      default: false,
      type: Boolean
    },
    autoLoad: {
      default: false,
      type: Boolean
    },
    defaultDateRange: {
      type: Object
    },
    defaultFilters: {
      type: Object
    },
    initialPresetId: {
      type: String,
      default: null
    }
  },
  data() {
    let masterColumnData = {
      day: {
        filter: {
          id: 'show-day',
          label: 'Day',
          state: true,
          visible: true,
          disabled: false,
          group: 'date'
        },
        column: {
          name: 'day',
          title: 'Date',
          sortField: 'day',
          dataClass: '',
          titleClass: '',
          visible: true,
          defSortDirection: 'desc'
        }
      },
      month: {
        filter: {
          id: 'show-month',
          label: 'Month',
          state: false,
          visible: true,
          disabled: false,
          group: 'date'
        },
        column: {
          name: 'month',
          title: 'Date',
          sortField: 'month',
          dataClass: '',
          titleClass: '',
          visible: true,
          defSortDirection: 'desc'
        }
      },
      // hour: {
      //   filter: {
      //     id: 'show-hour',
      //     label: 'Hour',
      //     state: false,
      //     visible: true,
      //     disabled: false,
      //     group: 'date'
      //   },
      //   column: {
      //     name: 'hour',
      //     title: 'Date',
      //     sortField: 'hour',
      //     dataClass: '',
      //     titleClass: '',
      //     visible: true,
      //     defSortDirection: 'desc'
      //   }
      // },
      country: {
        filter: {
          id: 'show-country',
          label: 'Country',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'country-slot',
          title: 'Country',
          sortField: 'country',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      campaign: {
        filter: {
          id: 'show-campaign',
          label: 'Campaign',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'campaign-slot',
          title: 'Campaign',
          sortField: 'campaign',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      payoutConversionType: {
        filter: {
          id: 'show-conversion-type',
          label: 'Conversion Type',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'conversion-type-slot',
          title: 'Conversion Type',
          sortField: 'payoutConversionType',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      usageType: {
        filter: {
          id: 'show-usage-type',
          label: 'Usage Type',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'usage-type-slot',
          title: 'Usage Type',
          sortField: 'usageType',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      // mobileDesktop: {
      //   filter: {
      //     id: 'show-mobile-desktop',
      //     label: 'Mobile / Desktop',
      //     state: false,
      //     visible: true,
      //     disabled: false,
      //     group: 'dimensions'
      //   },
      //   column: {
      //     name: 'mobile-desktop-slot',
      //     title: 'Mobile / Desktop',
      //     sortField: 'mobileDesktop',
      //     callback: '',
      //     dataClass: '',
      //     titleClass: '',
      //     visible: true
      //   }
      // },
      subId: {
        filter: {
          id: 'show-sub-id',
          label: 'Sub ID',
          state: false,
          visible: false,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'subid-slot',
          title: 'Sub ID',
          sortField: 'subId',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sub1: {
        filter: {
          id: 'show-sub1',
          label: 'Sub1',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'sub1',
          title: 'Sub1',
          sortField: 'sub1',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sub2: {
        filter: {
          id: 'show-sub2',
          label: 'Sub2',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'sub2',
          title: 'Sub2',
          sortField: 'sub2',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sub3: {
        filter: {
          id: 'show-sub3',
          label: 'Sub3',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'sub3',
          title: 'Sub3',
          sortField: 'sub3',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sub4: {
        filter: {
          id: 'show-sub4',
          label: 'Sub4',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'sub4',
          title: 'Sub4',
          sortField: 'sub4',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      sub5: {
        filter: {
          id: 'show-sub5',
          label: 'Sub5',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'sub5',
          title: 'Sub5',
          sortField: 'sub5',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      keyword: {
        filter: {
          id: 'show-keyword-id',
          label: 'Keyword',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'keyword-slot',
          title: 'Keyword',
          sortField: 'keyword',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      referralPayoutFrom: {
        filter: {
          id: 'show-referral-revenue-from',
          label: 'Referral Revenue From',
          state: false,
          visible: true,
          disabled: false,
          group: 'dimensions'
        },
        column: {
          name: 'referralPayoutFrom',
          title: 'Referral Revenue From',
          sortField: 'referralPayoutFrom',
          callback: '',
          dataClass: '',
          titleClass: '',
          visible: true
        }
      },
      visits: {
        filter: {
          id: 'show-visits',
          label: 'Impressions',
          state: true,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'visits',
          title: 'Impressions',
          sortField: 'visits',
          formatter: value => {
            return this.$formatInt(value)
          },
          callback: '$formatInt',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          defSortDirection: 'desc'
        }
      },
      //uniques: {
      //  filter: {
      //    id: 'show-uniques',
      //    label: 'Uniques',
      //    state: true,
      //    visible: true,
      //    disabled: false,
      //    group: 'metrics'
      //  },
      //  column: {
      //    name: 'uniques',
      //    title: 'Uniques',
      //    sortField: 'uniques',
      //    formatter: value => {
      //      return this.$formatInt(value)
      //    },
      //    dataClass: 'data-metric',
      //    titleClass: '',
      //    visible: true,
      //    defSortDirection: 'desc'
      //  }
      //},
      publisherConversions: {
        filter: {
          id: 'show-conversions',
          label: 'Conversions',
          state: true,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'publisherConversions',
          title: 'Conversions',
          sortField: 'publisherConversions',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatInt(value)
          },
          defSortDirection: 'desc'
        }
      },
      ecpm: {
        filter: {
          id: 'show-ecpm',
          label: 'eCPM',
          state: true,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'ecpm',
          title: 'eCPM',
          sortField: 'ecpm',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      },
      payout: {
        filter: {
          id: 'show-payout',
          label: 'Revenue',
          state: true,
          visible: true,
          disabled: false,
          group: 'metrics'
        },
        column: {
          name: 'payout',
          title: 'Revenue',
          sortField: 'payout',
          dataClass: 'data-metric',
          titleClass: '',
          visible: true,
          formatter: value => {
            return this.$formatMoney(value)
          },
          defSortDirection: 'desc'
        }
      }
    }

    let columns = this.createVuetableColumns(masterColumnData)

    return {
      dataTotal: 0,
      totals: {},
      vuetableData: null,
      loadTimes: null,
      masterColumnData: masterColumnData,
      fields: columns,
      hasEverLoaded: false,
      css: {
        tableClass:
          'table table-sm table-striped dt-responsive nowrap dataTable',
        loadingClass: 'loading',
        ascendingIcon: 'custom-table-sort-arrow-up',
        descendingIcon: 'custom-table-sort-arrow-down',
        detailRowClass: 'vuetable-detail-row',
        sortHandleIcon: 'grey sidebar icon'
      },
      sortOrder: [
        {
          field: 'revenue',
          sortField: 'revenue',
          direction: 'desc'
        }
      ],
      multiSort: true,
      perPage: 12,
      isLoading: false,
      startTimeDefault: '00:00',
      endTimeDefault: '23:59',
      endTimeKey: 'endTimeKey',
      startTimeKey: 'startTimeKey',
      isMounted: false,
      startDateTime: null,
      endDateTime: null
    }
  },
  watch: {
    sortOrder: function(oldSortOrder, newSortOrder) {
      if (!this.isLoading) {
        this.$events.emit('sortReportRows', newSortOrder)
      }
    },
    isLoading: function(value) {
      if (value == true) {
        this.hasEverLoaded = true
      }
    }
  },
  mounted() {
    this.isMounted = true
  },
  updated() {},
  computed: {
    readableDateRange() {
      if (!this.startDateTime || !this.endDateTime) {
        return
      }

      return `${this.startDateTime} - ${this.endDateTime}`
    }
  },
  methods: {
    formatTotalField(field) {
      let output = ''
      if (this.totals[field.name]) {
        output = field.formatter(this.totals[field.name])
      }
      return output
    },
    createVuetableColumns(master) {
      var vuetableColumns = []
      for (var column in master) {
        if (master[column].filter.state == true) {
          vuetableColumns.push(master[column].column)
        }
      }
      return vuetableColumns
    },
    onPaginationData() {
      // this.$refs.pagination.setPaginationData(paginationData)
      // this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage(page) {
      this.$events.emit('changeReportPage', page)
    },
    onLoading() {
      this.isLoading = true
    },
    onLoaded() {
      this.isLoading = false
    }
  }
}
</script>
<style>
.template-column {
  max-width: 300px !important;
  overflow: hidden;
}
</style>
