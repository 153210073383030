<template>
  <div class="w-full">
    <div class="grid grid-cols-1 sm:grid-cols-3 gap-1">
      <YInput v-model="filters.id.value" label="ID"></YInput>
      <YInput v-model="filters.name.value" label="Name"></YInput>
      <Superselect
        title="Manager"
        v-model="filters.manager.value"
        :multiple="false"
        track-by="id"
        label="label"
        :query="ACCOUNT_MANAGER_OPTIONS_QUERY"
        :query-variables="{
          filters: {
            label: {
              value: [],
              modifiers: { matchType: 'contains' }
            }
          },
          first: 25
        }"
      ></Superselect>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ACCOUNT_MANAGER_OPTIONS_QUERY from '@/graphql/AccountManager/AccountManagerOptionsQuery.gql'

export default {
  data() {
    return {
      ACCOUNT_MANAGER_OPTIONS_QUERY,
      orderBy: {
        column: 'created_at',
        order: 'DESC',
        label: 'Newest'
      },
      orderByOptions: [
        {
          label: 'Newest',
          column: 'created_at',
          order: 'DESC'
        },
        {
          label: 'Oldest',
          column: 'created_at',
          order: 'ASC'
        }
      ]
    }
  },
  methods: {},
  computed: {
    ...mapState({
      filters: state => state.advertiser.filters
    })
  }
}
</script>

<style></style>
