<template>
  <!-- <transition
    @before-enter="animateBeforeEnter"
    @enter="animateEnter"
    @leave="animateLeave"
    :css="false"
  > -->
  <div
    v-if="isVisible"
    :class="
      `bg-${color}-200 border-t-4 border-${color}-800 rounded-b text-${color}-800 px-4 py-3 shadow-md`
    "
    role="alert"
  >
    <div class="flex">
      <Icon
        v-if="icon"
        :name="icon"
        :size="iconSize"
        class="mr-2"
        style="margin-top:2px;"
      />
      <div class="flex-grow">
        <slot>
          <p class="font-bold">{{ title }}</p>
          <p class="text-sm">{{ message }}</p>
        </slot>
      </div>
      <slot name="right">
        <close-x v-if="allowClose" @click="close" class="alert-link"></close-x>
      </slot>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
export default {
  components: {},
  props: {
    color: {
      type: String,
      default: 'blue'
    },
    type: {
      type: String,
      default: 'warning'
    },
    icon: {
      type: String
    },
    iconSize: {
      type: Number,
      default: 4
    },
    iconHide: {
      type: Boolean,
      default: false
    },
    allowClose: {
      type: Boolean,
      default: true
    },
    disableIcon: {
      type: Boolean,
      default: false
    },
    persistId: {
      type: String
    },
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isOpen: true
    }
  },
  computed: {
    className() {
      let className =
        'alert alert-' + this.type + 'flex justify-between items-center'
      return className
    },
    iconClass() {
      var className = 'zmdi zmdi-alert-triangle'
      switch (this.type) {
        case 'warning':
          className = 'zmdi zmdi-alert-triangle'
          break
        case 'info':
          className = 'zmdi zmdi-info-outline'
          break
        case 'success':
          className = 'zmdi zmdi-check'
          break
        case 'danger':
          className = 'zmdi zmdi-alert-triangle'
          break
        default:
          className = 'zmdi zmdi-alert-triangle'
      }
      return className
    },
    isVisible() {
      if (
        this.persistId &&
        this.$session.get('alertClosed_' + this.persistId)
      ) {
        return false
      } else {
        return this.isOpen
      }
    }
  },
  mounted() {
    this.isOpen = true
  },
  updated() {},

  methods: {
    getPersistedState() {
      //Get Persisted Session State
      this.filters = this.$session.get('publisherReportFilters')
        ? this.$session.get('publisherReportFilters')
        : this.filters
      this.sortByFilter = this.$session.get('publisherSortByFilter')
        ? this.$session.get('publisherSortByFilter')
        : this.sortByFilter
      this.showColumnsFilter = this.$session.get(
        'publisherReportShowColumnsFilter'
      )
        ? this.$session.get('publisherReportShowColumnsFilter')
        : this.showColumnsFilter
    },
    close() {
      if (this.persistId) {
        this.$session.set('alertClosed_' + this.persistId, true)
      }
      this.isOpen = false
    }
    // animateBeforeEnter(el) {
    //   el.style.opacity = 0;
    // },
    // animateEnter(el, done) {
    //   Velocity(el, "slideDown", { duration: 300, easing: "easeInQuad" });
    //   Velocity(
    //     el,
    //     { opacity: 1 },
    //     { duration: 300, easing: "easeInQuad", complete: done }
    //   );
    // },
    // animateLeave(el, done) {
    //   Velocity(el, { opacity: 0 }, { duration: 300, easing: "easeOutQuad" });
    //   Velocity(el, "slideUp", {
    //     duration: 300,
    //     easing: "easeOutQuad",
    //     complete: done
    //   });
    // }
  }
}
</script>
<style scoped>
.alert {
  opacity: 1;
}
</style>
