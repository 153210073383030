var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('Widget', {
    staticClass: "tiny-forms"
  }, [_c('WidgetHeader', {
    staticClass: "bg-opacity-gray"
  }, [_vm._v("Add Adjustments")]), _c('WidgetBody', {
    staticClass: "pt-0"
  }, [_c('Tabs', {
    attrs: {
      "tabsClass": "pt-0 widget-edge mb-4 bg-opacity-gray"
    }
  }, [_c('Tab', {
    attrs: {
      "name": "Single Adjustments",
      "active": true
    }
  }, [_c('AdjustmentsFormPublisher', {
    attrs: {
      "loading-preview": _vm.loadingPreview
    }
  })], 1), _c('Tab', {
    attrs: {
      "name": "Bulk Upload"
    }
  }, [_c('AdjustmentsFormBulkPublishers', {
    attrs: {
      "loading-preview": _vm.loadingPreview
    }
  })], 1)], 1)], 1)], 1), _c('AdjustmentsPreview', {
    attrs: {
      "loadingPreview": _vm.loadingPreview
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }