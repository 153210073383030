<template>
  <div v-show="hasDefaultSlot" @click="logout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('Login')
      })
    }
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    }
  }
}
</script>

<style></style>
