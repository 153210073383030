var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', [_c('i', {
    staticClass: "zmdi zmdi-file-plus pr-2"
  }), _vm._v(" Traffic Offer "), _c('h4', {
    attrs: {
      "slot": "right"
    },
    slot: "right"
  }, [_c('close-x', {
    staticClass: "text-white",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  })], 1)]), _c('widget-body', [_c('div', {
    staticClass: "m-auto",
    staticStyle: {
      "max-width": "25rem"
    }
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('RadioBox', {
    attrs: {
      "label": "Smart Weight",
      "value": true
    },
    model: {
      value: _vm.smartWeight,
      callback: function ($$v) {
        _vm.smartWeight = $$v;
      },
      expression: "smartWeight"
    }
  })], 1), _c('div', {
    staticClass: "mb-1"
  }, [_c('RadioBox', {
    attrs: {
      "label": "Manual Weight",
      "value": false
    },
    model: {
      value: _vm.smartWeight,
      callback: function ($$v) {
        _vm.smartWeight = $$v;
      },
      expression: "smartWeight"
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.smartWeight,
      expression: "!smartWeight"
    }],
    staticClass: "mb-6 mt-4"
  }, [_c('div', {
    staticClass: "flex mb-2"
  }, [_c('YInput', {
    staticClass: "w-1/2",
    attrs: {
      "label": "Weight",
      "input-class": "border-r-0 rounded-r-none"
    },
    model: {
      value: _vm.weight,
      callback: function ($$v) {
        _vm.weight = $$v;
      },
      expression: "weight"
    }
  }), _c('YInput', {
    staticClass: "w-1/4",
    attrs: {
      "label": "Min",
      "input-class": "border-l-0 rounded-l-none border-r-0 rounded-r-none"
    },
    model: {
      value: _vm.min,
      callback: function ($$v) {
        _vm.min = $$v;
      },
      expression: "min"
    }
  }), _c('YInput', {
    staticClass: "w-1/4",
    attrs: {
      "label": "Max",
      "input-class": "border-l-0 rounded-l-none"
    },
    model: {
      value: _vm.max,
      callback: function ($$v) {
        _vm.max = $$v;
      },
      expression: "max"
    }
  })], 1), _c('YInput', {
    staticClass: "mb-2",
    attrs: {
      "label": "Expiry Date",
      "dateFormat": "YYYY-MM-DD",
      "dateFormatted": "ll",
      "type": "date"
    },
    model: {
      value: _vm.expiry,
      callback: function ($$v) {
        _vm.expiry = $$v;
      },
      expression: "expiry"
    }
  }), _vm.expiry ? _c('YInput', {
    staticClass: "mb-2",
    attrs: {
      "label": "Fallback value"
    },
    model: {
      value: _vm.fallback,
      callback: function ($$v) {
        _vm.fallback = $$v;
      },
      expression: "fallback"
    }
  }) : _vm._e()], 1)], 1)])]), _c('widget-footer', {
    staticClass: "px-4 py-2 bg-gray-100 text-right"
  }, [_c('y-button', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": function ($event) {
        return _vm.traffic(_vm.offerId);
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.traffic(_vm.offerId);
      }
    }
  }, [_vm._v("Traffic")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }