<template>
  <div>
      <template>
          <div class="flex">
           <YInput
            title="Default Scrub"
            label="Default Scrub"
            v-model="campaignScrub"
            :required="true"
          />
          <YButton
            @click="upsertCampaignScrub(campaignScrub)"
            color="blue"
            class="ml-2 h-12"
            :is-loading="isUpserting"
            >Set</YButton
            >
            <YButton
            @click="deleteCampaignScrub() "
            color="red"
            class="ml-2 h-12"
            :is-loading="isUpserting"
            >Clear</YButton
            >
          </div>

      </template>
    <YTable
      v-if="redirects"
      class="table-striped table-hover overflow-hidden"
      :data="redirects.data"
    >
      <template slot="header-row">
        <th>Redirect</th>
        <th>Scrub Percentage</th>
        <th></th>
        <th></th>
      </template>
      <template slot="secondHeaderRow">
        <td>
          <Superselect
           :required="true"
            title="Redirect"
            v-model="redirectOptions"
            track-by="id"
            label="label"
            :query="REDIRECT_OPTIONS_QUERY"
            :query-variables="{
              first: 100,
              filters: {
                campaignId:{value: [campaignId]},
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              }
            }"
            class="-mx-3"
          />
        </td>
        <td>
          <YInput
            label="Value"
            v-model="triggerValue"
            class="-mx-3"
            :required="true"
          />
        </td>
        <td>
          <div class="flex items-center -ml-3 -mr-3">
            <YButton
              @click="upsertRedirectScrub(redirectOptions, triggerValue)"
              color="link"
              class="px-3"
              :is-loading="isUpserting"
              ><Icon name="plus"
            /></YButton>
          </div>
        </td>
        <td>
          <Spinner v-if="$apollo.queries.redirects.loading" color="blue" :size="6" />
        </td>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          <div class="flex flex-col">
            <span>{{props.rowData.label}}</span>
            <span class="text-xs italic text-gray-700">{{props.rowData.user.label}}</span>
          </div>
        </td>
        <td>{{props.rowData.aSPercentage}}%</td>
        <td></td>
        <td>
            <div class="text-right">
              <a
                href="#"
                :class="'text-red-600 hover:text-red-700'"
                @click="deleteRedirectScrub(props.rowData.id)"
              >
                <Icon class="inline" name="trashCan" :size="4" />
              </a>
            </div>
        </td>
      </template>
    </YTable>
  </div>
</template>

<script>
import Superselect from '../../../components/ui/Superselect.vue'
import REDIRECT_OPTIONS_QUERY from '@/graphql/Redirect/RedirectOptionsQuery.gql'
import REDIRECTS_QUERY from '@/graphql/Redirect/RedirectsQuery.gql'
import UPDATE_REDIRECT_MUTATION from '@/graphql/Redirect/UpdateRedirectMutation.gql'
import UPDATE_CAMPAIGN_MUTATION from '@/graphql/Campaign/UpdateCampaignMutation.gql'

export default {
  components: { Superselect },
  props: {
    campaignId: {
      type: String
    },
    defaultScrub: {
        type: Number
    }
  },
  apollo: {
    redirects: {
      query: REDIRECTS_QUERY,
      variables() {
        return {
          filters: {
            campaignId: { value: [this.campaignId] },
            hasActionsScrub: true
          },
          first: 300,
          orderBy: []
        }
      }
    }
  },
  data() {
    return {
      UPDATE_REDIRECT_MUTATION,
      UPDATE_CAMPAIGN_MUTATION,
      REDIRECT_OPTIONS_QUERY,
      REDIRECTS_QUERY,
      isUpserting: false,
      redirectOptions: [],
      triggerValue: null,
      campaignScrub: this.defaultScrub,
    }
  },
  mounted() {
    this.$events.listen('scrubDeleted', () => {
      this.$apollo.queries.redirects.refetch()
    })
    this.$events.listen('scrubUpserted', () => {
      this.$apollo.queries.redirects.refetch()
    })
    this.$events.listen('scrubDefaultCreated', () => {
      this.$apollo.queries.redirects.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('CapDefaultCreated')
    this.$events.remove('scrubDeleted')
    this.$events.remove('scrubUpserted')
  },
  computed: {
    redirectIds() {
      return this.redirects.map(redirect => redirect.id)
    },
  },
  methods: {
    cleanObjectFilter(filter, pluck) {
      if (filter.length === 0) {
        return undefined
      }
      console.log({
        value: filter.map(value => value[pluck]),
        modifiers: filter.modifiers
      })
      return {
        value: filter.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    upsertCampaignScrub(campaignScrub){
        let input = {
            id: parseInt(this.campaignId),
            aSPercentage: parseInt(campaignScrub),
        }
        this.$apollo.mutate({
            mutation: UPDATE_CAMPAIGN_MUTATION,
            variables: {
                input: input,
            }
            })
            .then(result => {
                this.$toastr.s('Default Scrub Inserted', 'Success')
                this.campaignScrub = result.data.updateCampaign.aSPercentage
                this.$nextTick(() => {
                    this.$events.emit('scrubUpserted')
                })
            })
            .catch(error => {
                this.isUpserting = false
                this.setValidationErrors(error)
            })
    },
    async upsertRedirectScrub(redirects, percentage) {
        this.isUpserting = true
        let mutations = []
        redirects.forEach((redirect) => {
            mutations.push(
              this.runUpsert({aSPercentage: parseInt(percentage)},redirect.id)
            )
          })

        return Promise.all(mutations).then(() => {
            this.isUpserting = false
            this.$events.emit('scrubUpserted')
            this.$toastr.s('Redirect Scrubs Inserted', 'Success')
        })
    },
    async runUpsert(input,redirectId){
        return this.$apollo.mutate({
            mutation: UPDATE_REDIRECT_MUTATION,
            variables: {
                input: input,
                id: parseInt(redirectId)
            }
            })
    },

    deleteCampaignScrub() {
        let input = {
            id: parseInt(this.campaignId),
            aSPercentage: null
        }
        this.$apollo
        .mutate({
          mutation: UPDATE_CAMPAIGN_MUTATION,
            variables: {
                input: input,
            }
        })
        .then(() => {
          this.campaignScrub = null
          this.isUpserting = false
          this.$toastr.s('Default Scrub Cleared', 'Success')
          this.$nextTick(() => {
            this.$events.emit('scrubUpserted')
          })
        })
        .catch(error => {
          this.isUpserting = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },

    deleteRedirectScrub(redirectId) {
        let input = {
            aSPercentage: null,
        }
        this.$apollo
        .mutate({
          mutation: UPDATE_REDIRECT_MUTATION,
          variables: {
            id: redirectId,  
            input: input
          }
        })
        .then(() => {
         
          this.isUpserting = false
          this.$toastr.s('Redirect Scrub Cleared', 'Success')
          this.$nextTick(() => {
            this.$events.emit('scrubUpserted')
          })
        })
        .catch(error => {
          this.isUpserting = false
          this.setValidationErrors(error)
          console.error(error)
        })
    },
    
    
  }
}
</script>

<style scoped></style>
