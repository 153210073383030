var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-col flex-grow overflow-hidden"
  }, [_c('div', {
    staticClass: "x-form px-3"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.message,
      expression: "message"
    }],
    staticClass: "px-3 py-3 w-full rounded mb-2 border block",
    attrs: {
      "placeholder": "Type here..."
    },
    domProps: {
      "value": _vm.message
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        return _vm.addComment();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.message = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('YButton', {
    staticClass: "block w-full py-2",
    attrs: {
      "color": "blue",
      "is-loading": _vm.isAdding,
      "disabled": !_vm.message
    },
    on: {
      "click": function ($event) {
        return _vm.addComment();
      }
    }
  }, [_vm._v("Add Comment")])], 1), _c('div', {
    staticClass: "x-comments flex-grow overflow-y-auto"
  }, _vm._l(_vm.comments, function (comment) {
    return _c('div', {
      key: comment.id,
      staticClass: "x-comment"
    }, [_c('div', {
      staticClass: "x-header"
    }, [_c('div', {
      staticClass: "flex items-center justify-center"
    }, [_c('UserAvatar', {
      attrs: {
        "height": "8",
        "id": comment.commenter.id
      }
    }), _c('div', {
      staticClass: "x-header-text"
    }, [_vm._v(" " + _vm._s(comment.commenter.primaryContact.name) + " ")])], 1), _c('div', {
      staticClass: "text-xs text-gray-500"
    }, [_vm._v(" " + _vm._s(_vm._f("luxonRelative")(comment.createdAt)) + " ")])]), _c('div', {
      staticClass: "x-message"
    }, [_vm._v(" " + _vm._s(comment.message) + " ")])]);
  }), 0), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }