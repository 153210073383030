<template>
  <div>
    <div>
      <div class="grid grid-cols-3 gap-6 mb-6">
        <QuarantineLevelCard
          class="col-span-3"
          v-if="user.isQuarantined && user.caps.length > 0"
          :level="user.quarantine.level"
          :campaign-subscribe-count="user.quarantine.campaignSubscribeCount"
          :payout="
            user.caps
              ? user.caps.filter(
                  cap => cap.type == 'payout' && cap.frequency == 'monthly'
                )[0].currentValue
              : 0
          "
        />
      </div>

      <div class="flex flex-col lg:grid lg:grid-cols-4 gap-6">
        <div class="col-span-3 hidden lg:flex gap-6">
          <div
            class="w-4/12 max-w-sm flex flex-col rounded-md p-6 relative overflow-hidden bg-gradient-to-r from-blue-600 to-blue-800"
            v-if="featuredCampaigns.length > 1"
          >
            <div
              class="absolute rounded-full bg-gradient-to-r from-blue-500 to-blue-900"
              style="right: -8rem; top: -8rem; width: 16rem; height: 16rem;"
            ></div>
            <div
              class="absolute rounded-full bg-gradient-to-r from-blue-500 to-blue-300"
              style="left: 4rem; top: 2.5rem; width: 1.5rem; height: 1.5rem;"
            ></div>
            <div class="mt-auto">
              <div
                class="uppercase mb-2 text-xs tracking-wider font-semibold text-white"
              >
                YTZ
              </div>
              <div class="font-bold text-white text-3xl leading-tightest">
                Featured Campaigns
              </div>
            </div>
          </div>
          <Spinner
            v-if="$apollo.queries.publisherCampaigns.loading"
            color="blue"
            :size="8"
          />
          <div
            class="featuredCampaigns w-8/12 rounded-md border"
            v-if="featuredCampaigns.length > 1"
          >
            <agile
              ref="carousel"
              :autoplay="true"
              :pauseOnHover="true"
              :navButtons="false"
              :autoplaySpeed="9000"
              :key="featuredCampaigns.length"
            >
              <span v-for="feature in featuredCampaigns" :key="feature.id">
                <FeaturedCampaign :data="feature" />
              </span>
            </agile>
          </div>
        </div>
        <div class="col-span-1 hidden lg:flex flex-col">
          <div class="flex-shrink mb-2">
            <AccountManager />
          </div>
          <div style="padding:4px"></div>
          <div
            class="flex-grow rounded-md p-3 text-gray-900 border flex items-center"
            style="border-top: 4px solid #ef4565;"
          >
            <span class="block">
              🎉 YTZ is happy to offer our publishers a referral program. Grab
              your
              <router-link
                :to="{
                  name: 'Publisher Account',
                  params: { tab: 'referrals' }
                }"
                class="link font-bold"
              >
                referral link
              </router-link>
              and share!
            </span>
          </div>
        </div>

        <!-- end of row One -->

        <div class="col-span-3">
          <Reports
            :auto-load="true"
            :show-filters="false"
            :default-filters="reportFilters"
          >
            <template v-slot:table-header>
              <span class="widget-title">Daily Summary</span>
            </template>
            <template v-slot:right>
              <router-link
                :to="{
                  name: 'Publisher Reports'
                }"
                class="link text-uppercase text-xs"
              >
                VIEW REPORTS
              </router-link>
            </template>
          </Reports>
        </div>
        <div class="col-span-1">
          <div
            class="flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
          >
            <div
              class="uppercase text-xs font-semibold text-gray-800 flex justify-between"
            >
              Outstanding Balance
              <Icon name="alertCircle" size="6" class="text-teal-500" />
            </div>
            <div class="text-xl font-bold" v-if="paymentSummary">
              {{
                Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD'
                }).format(this.paymentSummary.paymentBalance)
              }}
            </div>
            <div class="flex justify-between pt-3 text-xs text-gray-800">
              <div>
                Last Updated
              </div>
              <div class="font-semibold">
                {{
                  this.paymentSummary?.confirmedAsDate
                    | luxon({
                      output: { format: 'MMMM dd, yyyy' }
                    })
                }}
              </div>
            </div>
          </div>
          <div
            class="flex-shrink rounded-md px-4 py-3 bg-gray-100 border mb-2 flex flex-col"
          >
            <div
              v-tooltip="
                `The following traffic types are registered to your account. <br>Contact your AM if the list is inaccurate or incomplete.`
              "
              class="uppercase text-xs font-semibold text-gray-800 flex justify-between"
            >
              Your Traffic Types
              <Icon name="information" size="6" class="text-blue-500" />
            </div>
            <div class="text-xl font-bold" v-if="this.me">
              <Tag
                v-for="tag in this.me.tags.filter(
                  tag => tag.type == 'trafficType'
                )"
                :color="tag.color"
                :key="tag.slug"
                class="mr-1"
              >
                {{ tag.name }}
              </Tag>
            </div>
          </div>
          <TopCampaigns />
        </div>

        <!-- end of row two -->

        <div class="col-span-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountManager from '@/views/Publisher/Dashboard/AccountManager'
import TopCampaigns from '@/views/Publisher/Dashboard/TopCampaigns'
import Reports from '@/views/Publisher/Reports/Reports'
import ME_QUERY from '@/graphql/User/MeQuery.gql'
import WalkthroughTutorial from '@/views/Publisher/Dashboard/WalkthroughTutorial.vue'
import FeaturedCampaign from '@/views/Publisher/Dashboard/FeaturedCampaign.vue'
import PUBLISHER_CAMPAIGNS_QUERY from '@/graphql/PublisherCampaign/PublisherCampaignsQuery.gql'
import QuarantineLevelCard from '@/components/general/QuarantineLevelCard.vue'
import { VueAgile } from 'vue-agile'

export default {
  components: {
    QuarantineLevelCard,
    AccountManager,
    TopCampaigns,
    Reports,
    agile: VueAgile,
    FeaturedCampaign
  },
  apollo: {
    me: {
      query: ME_QUERY,
      result({ data }) {
        if (data && data.me) {
          this.user = data.me
          this.showWalkthroughTutorial =
            data.me.userDetails.tutorialViewed || false

          const billingContact = data.me.billingContact ?? null
          if (billingContact) {
            this.paymentSummary = {
              confirmedAsDate:
                billingContact.confirmedAsDate != null
                  ? billingContact.confirmedAsDate.split(' ')[0]
                  : null,
              paymentBalance:
                billingContact.paymentBalance != null
                  ? parseFloat(billingContact.paymentBalance).toFixed(2)
                  : 0.0
            }
          }
        }
      },
      error(error) {
        console.log(error)
      }
    },
    publisherCampaigns: {
      query: PUBLISHER_CAMPAIGNS_QUERY,
      variables() {
        return {
          publisherId: this.me.id,
          first: 20,
          filters: {
            isFeatured: {
              value: [''],
              modifiers: {
                matchType: 'contains'
              }
            }
          },
          orderBy: [
            {
              column: 'featured_at',
              order: 'DESC'
            },
            {
              column: 'id',
              order: 'DESC'
            }
          ]
        }
      },
      result(result) {
        this.featuredCampaigns = result.data.publisherCampaigns.data
      },
      skip() {
        return !this.me
      },
      error(error) {
        console.log(error)
      }
    }
  },
  data() {
    return {
      paymentSummary: null,
      reportFilters: {
        dateTimeRange: {
          start:
            this.$dateTime
              .local()
              .minus({ days: 14 })
              .toFormat('yyyy-LL-dd') + ' 12:00 am',
          end: this.$dateTime.local().toFormat('yyyy-LL-dd') + ' 11:59 pm'
        }
      },
      recentSignUps: [],
      recentLogins: [],
      user: {},
      showWalkthroughTutorial: false,
      modalMounted: false,
      featuredCampaigns: []
    }
  },
  updated() {
    this.user.id && !this.showWalkthroughTutorial && !this.modalMounted
      ? this.showWalkthroughModal()
      : null
  },
  mounted() {
    this.$events.listen('closeModal', () => {
      this.$modal.hide(WalkthroughTutorial)
      this.modalMounted = false
    })
  },
  beforeDestroy() {
    if (this.$refs.carousel) {
      this.$refs.carousel.$destroy()
    }
  },
  computed: {},
  methods: {
    showWalkthroughModal() {
      this.modalMounted = true

      this.$modal.show(
        WalkthroughTutorial,
        {
          name: 'WalkthroughTutorial',
          user: this.user
        },
        {
          height: 'auto',
          scrollable: true,
          width: '80%',
          maxWidth: 900,
          minWidth: 200,
          adaptive: true,
          clickToClose: false
        }
      )
    }
  }
}
</script>

<style scoped>
.widget {
  max-height: 29rem;
  overflow-y: scroll;
}
</style>
