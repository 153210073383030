<template>
  <Widget>
    <WidgetHeader
      class="py-6 border-b"
      :closeable="true"
      @close="$emit('close')"
    >
      <div class="flex items-center">
        <Icon name="pencil" :size="4" class="mr-2"></Icon>
        <template>Edit {{ domain.id }}</template>
      </div>
    </WidgetHeader>
    <WidgetBody class="pt-5">
      <YInput v-model="registrar" label="Registrar"></YInput>
      <YInput
        v-model="registrarAccount"
        class="mt-2"
        label="Registrar Account"
      ></YInput>
    </WidgetBody>
    <WidgetFooter
      class="pt-4 pb-4 flex justify-between items-center px-4 bg-gray-100"
    >
      <YCheckbox
        class="flex items-center"
        v-model="closeOnComplete"
        label="Close on Complete"
      ></YCheckbox>
      <YButton
        :is-loading="isSubmitting"
        type="button"
        color="blue"
        @click="updateNameserverDomain"
        >Update</YButton
      >
    </WidgetFooter>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </Widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import UPDATE_DOMAIN_MUTATION from '@/graphql/Domain/UpdateDomainMutation.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    domain: {
      type: Object,
      required: true
    }
  },
  apollo: {
  },
  data() {
    return {
      isSubmitting: false,
      registrar: this.domain.registrar,
      registrarAccount: this.domain.registrarAccount,
      closeOnComplete: true
    }
  },
  methods: {
    updateNameserverDomain() {
      this.isSubmitting = true

      this.$apollo
        .mutate({
          mutation: UPDATE_DOMAIN_MUTATION,
          variables: {
            input: [
              {
                id: this.domain.id,
                registrar: this.registrar,
                registrarAccount: this.registrarAccount,
              }
            ]
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$events.emit('refreshDnsDomains')
          this.$toastr.s('Domain Updated', 'Success!')
          if (this.closeOnComplete) {
            this.$emit('close')
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)

          console.log(error)
        })
    }
  }
}
</script>

<style></style>
