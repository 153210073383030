var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget', [_c('widget-header', {
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Edit Nameserver Info ")]), _c('widget-body', [_c('form', {
    ref: "form",
    staticClass: "x-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('YInput', {
    attrs: {
      "label": "Prefix"
    },
    model: {
      value: _vm.editPrefix,
      callback: function ($$v) {
        _vm.editPrefix = $$v;
      },
      expression: "editPrefix"
    }
  })], 1)]), _c('widget-footer', {
    staticClass: "bg-gray-100 py-2 px-4 text-right"
  }, [_c('y-button', {
    staticClass: "btn btn-blue",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.updateRelation,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.updateRelation.apply(null, arguments);
      }
    }
  }, [_vm._v("Save Changes")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }