var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('vuetable', {
    ref: "vuetable",
    staticClass: "header-gray-100",
    attrs: {
      "api-mode": false,
      "fields": _vm.fields,
      "data": _vm.data,
      "pagination-path": "pagination",
      "data-path": "data",
      "css": _vm.css,
      "sort-order": _vm.sortOrder,
      "multi-sort": _vm.multiSort,
      "per-page": _vm.perPage,
      "multi-sort-key": "shift",
      "cellspacing": "0",
      "width": "100%",
      "table-wrapper": "vuetable-wrapper"
    },
    on: {
      "vuetable:pagination-data": _vm.onPaginationData,
      "vuetable:loading": _vm.loading,
      "vuetable:loaded": _vm.loaded
    },
    scopedSlots: _vm._u([{
      key: "id-slot",
      fn: function (props) {
        return _c('div', {}, [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Source',
              params: {
                id: props.rowData.id
              }
            }
          }
        }, [_vm._v(_vm._s(props.rowData.id))])], 1);
      }
    }, {
      key: "user-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.user ? [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.user.id
              }
            }
          }
        }, [_vm._v(_vm._s(props.rowData.user.label))])] : _vm._e()], 2);
      }
    }, {
      key: "redirect-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.redirect ? [_c('div', [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              name: 'Redirect',
              params: {
                id: props.rowData.redirect.id
              }
            }
          }
        }, [_vm._v(_vm._s(props.rowData.redirect.label))])], 1)] : _vm._e()], 2);
      }
    }, {
      key: "details-slot",
      fn: function (props) {
        return _c('div', {}, [_c('div', [props.rowData.isAbyssed ? _c('Tag', {
          staticStyle: {
            "margin-top": "1px",
            "margin-bottom": "1px"
          },
          attrs: {
            "type": "danger",
            "color": "pink"
          }
        }, [_c('i', {
          staticClass: "zmdi zmdi-block pr-1"
        }), _vm._v(" Abyssed ")]) : _vm._e()], 1), _c('div', [props.rowData.payouts ? _c('Tag', {
          staticStyle: {
            "margin-top": "1px",
            "margin-bottom": "1px"
          },
          attrs: {
            "type": "info",
            "color": "purple"
          }
        }, [_c('i', {
          staticClass: "zmdi zmdi-check pr-1"
        }), _vm._v(" Payouts ")]) : _vm._e()], 1)]);
      }
    }, {
      key: "stats-slot",
      fn: function (props) {
        return _c('div', {}, [_c('div', {
          staticClass: "flex items-center"
        }, [_c('div', {
          staticClass: "x-stat-wrapper"
        }, [props.rowData.impressions ? _c('Tag', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Impressions`,
            expression: "`Impressions`"
          }],
          staticClass: "mr-2",
          staticStyle: {
            "margin-top": "1px",
            "margin-bottom": "1px"
          },
          attrs: {
            "color": "light"
          }
        }, [_vm._v(_vm._s(_vm.$formatInt(props.rowData.impressions)))]) : _vm._e()], 1), _c('div', {
          staticClass: "x-stat-wrapper"
        }, [props.rowData.revenue ? _c('Tag', {
          directives: [{
            name: "tooltip",
            rawName: "v-tooltip",
            value: `Revenue`,
            expression: "`Revenue`"
          }],
          staticStyle: {
            "margin-top": "1px",
            "margin-bottom": "1px"
          },
          attrs: {
            "color": "teal"
          }
        }, [_vm._v(_vm._s(_vm.$formatMoney(props.rowData.revenue)))]) : _vm._e()], 1)])]);
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }