var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "tag truncate",
    class: [].concat(_vm.classes, [_vm.hasClickListener ? 'cursor-pointer' : '']),
    on: {
      "focus": function ($event) {
        return _vm.$emit('focus');
      },
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_vm._t("icon", function () {
    return [_vm.icon ? _c('Icon', {
      staticClass: "ml-1 -mr-1 w-3 h-3",
      attrs: {
        "name": _vm.icon
      }
    }) : _vm._e()];
  }, {
    "color": _vm.color
  }), _c('span', {
    staticClass: "tag-content",
    class: _vm.tagContentClass
  }, [_vm._t("default")], 2), _vm._t("afterIcon", function () {
    return [_vm.afterIcon ? _c('Icon', {
      staticClass: "fill-current -ml-1 w-3 h-3 mr-1",
      attrs: {
        "name": _vm.afterIcon
      }
    }) : _vm._e()];
  }, {
    "color": _vm.color
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }