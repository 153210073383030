var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "grid grid-cols-1 gap-1 m-auto bg-white pt-5 pb-8 px-6 rounded-b-lg shadow-xl border-b border-l border-r",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_c('YInput', {
    staticClass: "mb-1 text-left",
    attrs: {
      "label": "Email"
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }), _c('YInput', {
    staticClass: "mb-1 text-left",
    attrs: {
      "label": "Password",
      "type": "password"
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c('div', {
    staticClass: "flex justify-start pt-0 mb-10"
  }, [_c('div'), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'Forgot Password'
      }
    }
  }, [_vm._v("Forgot a Password?")])], 1), _c('YButton', {
    staticClass: "w-full",
    attrs: {
      "is-loading": _vm.isLoading,
      "color": "pink"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v("Login")]), _c('div', {
    staticClass: "flex justify-center border-t border-gray-500 pt-4 mt-3"
  }, [_c('p', {
    staticClass: "text-gray-700"
  }, [_vm._v("Don't have an account?")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Register'
      }
    }
  }, [_c('p', {
    staticClass: "link ml-1"
  }, [_vm._v("Sign Up")])])], 1), _c('portal-target', {
    attrs: {
      "name": "login-errors"
    }
  }), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors,
      "portal-to": "login-errors",
      "alert-class": "text-xs",
      "icon-size": 12,
      "icon": null,
      "color": "yellow"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "w-100 m-auto flex justify-end pr-3 pt-1",
    staticStyle: {
      "max-width": "24rem"
    }
  }, [_c('img', {
    staticStyle: {
      "height": "26px"
    },
    attrs: {
      "src": _vm.YtrackLogo
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }