<template>
  <div class="flex items-center">
    <v-popover v-if="me" offset="0" class="p-0 w-full v-popover--block">
      <div
        class="justify-start cursor-pointer pl-3 pr-4 py-3 my-0 items-center"
        :class="[
          userIsImpersonating ? impersonatingContentClass : contentClass
        ]"
      >
        <Icon
          v-if="userIsImpersonating"
          name="eyeOutline"
          class="w-4 h-4 ml-1"
        />
        <span v-else class="bg-green-500 h-2 w-2 m-2 rounded-full"></span>
        <div class="flex-grow font-medium pl-1 pr-2 truncate">
          {{
            me.primaryContact.name && me.primaryContact.name.trim() != ''
              ? me.primaryContact.name
              : me.email
          }}
        </div>
        <div>
          <Icon :name="iconName" />
        </div>
      </div>

      <div v-if="enableResponsiveAvatar" class="block md:hidden cursor-pointer">
        <div
          v-if="userIsImpersonating"
          class="w-10 h-10 text-center leading-10 rounded-full font-extrabold flex items-center justify-center bg-red-600 text-white"
        >
          <Icon name="eyeOutline" />
        </div>
        <UserAvatar :id="me.id" height="10"></UserAvatar>
      </div>

      <!-- This will be the content of the popover -->
      <template slot="popover">
        <ul class="text-right">
          <li v-if="!userIsImpersonating && this.$route.name == 'User'">
            <Impersonate :userId="this.$route.params.id">
              <button
                class="btn bg-white text-yellow-600 hover:bg-yellow-100 flex items-center"
              >
                <Icon name="eyeOutline" class="h-4 w-4 mr-2 -ml-2" />
                Impersonate User ({{ this.$route.params.id }})
              </button>
            </Impersonate>
          </li>
          <li v-if="!userIsImpersonating && this.$route.name == 'Campaign'">
            <Impersonate
              :userId="4352"
              :path="`/publisher/campaign/${this.$route.params.id}`"
            >
              <button
                class="btn bg-white text-yellow-600 hover:bg-yellow-100 flex items-center"
              >
                <Icon name="eyeOutline" class="h-4 w-4 mr-2 -ml-2" />
                View Campaign as Publisher
              </button>
            </Impersonate>
          </li>
          <li v-if="userIsImpersonating && iconName === 'chevronDown'">
            <div
              class="flex md:hidden items-center px-4 py-3 bg-red-600 text-white rounded-t-sm"
              style="max-width: 167px;"
            >
              <p class="truncate">
                {{
                  me.primaryContact.name && me.primaryContact.name.trim() != ''
                    ? me.primaryContact.name
                    : me.email
                }}
              </p>
            </div>
          </li>
          <li v-if="userIsImpersonating">
            <EndImpersonate>
              <button
                class="btn bg-white text-yellow-600  hover:bg-yellow-100 flex items-center"
              >
                <Icon name="eyeOffOutline" class="h-4 w-4 mr-2 -ml-2" />
                End Impersonate
              </button>
            </EndImpersonate>
          </li>
          <li v-if="!userIsImpersonating">
            <Logout>
              <button
                class="btn bg-white text-blue-600  hover:bg-blue-100 flex items-center w-full"
              >
                <Icon name="logout" class="h-4 w-4 mr-2 -ml-2" />
                Log Out
              </button>
            </Logout>
          </li>
        </ul>
      </template>
    </v-popover>
  </div>
</template>

<script>
import ME_QUERY from '@/graphql/User/MeQuery.gql'
import Logout from '@/views/Auth/Logout'
import EndImpersonate from '@/views/Auth/EndImpersonate'
import Impersonate from '@/views/Auth/Impersonate'
import StringToCssColor from '@/assets/js/StringToCssColor'

export default {
  apollo: {
    me: {
      query: ME_QUERY
    }
  },
  components: {
    Logout,
    EndImpersonate,
    Impersonate
  },
  props: {
    contentClass: {
      type: String
    },
    impersonatingContentClass: {
      type: String
    },
    iconName: {
      type: String
    },
    enableResponsiveAvatar: {
      type: Boolean
    }
  },
  computed: {
    userIsImpersonating() {
      return this.$store.getters.isImpersonating
    }
  },
  methods: {
    getColorClassFromString(string) {
      let stringToColor = new StringToCssColor()
      return stringToColor.get(string)
    }
  }
}
</script>

<style>
.sidebar-bottom {
  background-color: rgba(0, 0, 0, 0.4);
}
.sidebar-bottom:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.logout-button {
  padding-right: 15px;
}
</style>
