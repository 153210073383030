var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-8 py-3"
  }, _vm._l(_vm.campaigns.data, function (campaign) {
    return _c('div', {
      key: campaign.id
    }, [_c('CampaignsGridViewCard', {
      attrs: {
        "campaign": campaign
      }
    })], 1);
  }), 0), _vm.campaigns && _vm.campaigns.paginatorInfo && _vm.campaigns.paginatorInfo.hasMorePages ? _c('div', {
    staticClass: "w-100 py-4"
  }, [_c('YButton', {
    staticClass: "block m-auto",
    attrs: {
      "color": "link",
      "is-loading": _vm.isLoading
    },
    on: {
      "click": _vm.onClickButton
    }
  }, [_vm._v("LOAD MORE")])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }