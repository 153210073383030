<template>
  <div
    class="m-auto bg-white rounded-b-xl mb-12 border-l border-r border-b"
    style="max-width: 24rem;"
  >
    <template v-if="!successMessage">
      <form v-on:submit.prevent="submit" class="w-full">
        <div class="flex flex-col px-6">
          <div class="mt-6 font-bold text-lg text-inverse-700">
            Publisher Professional References
          </div>
          <div class="mt-2">
            We will verify the provided references using your
            <strong>name</strong> and <strong> email address</strong> from your
            application. Consistency is crucial for approval.
          </div>
          <!-- references -->
          <div class="mt-4">
            <p class="pt-5 pb-2 font-semibold uppercase text-inverse-700">
              Reference One
            </p>
            <div class="grid grid-rows-1 gap-3">
              <YInput
                required
                label="Company Name"
                v-model="reference1.company"
                type="text"
              />
              <YInput
                required
                label="Contact Name"
                v-model="reference1.contact"
                type="text"
              />
              <YInput
                required
                label="Company Email"
                v-model="reference1.email"
                type="email"
              />
            </div>
          </div>

          <div class="mt-4">
            <p class="pt-5 pb-2 font-semibold uppercase text-inverse-700">
              Reference Two
            </p>
            <div class="grid grid-rows-1 gap-3">
              <YInput
                required
                label="Company Name"
                v-model="reference2.company"
                type="text"
              />
              <YInput
                required
                label="Contact Name"
                v-model="reference2.contact"
                type="text"
              />
              <YInput
                required
                label="Company Email"
                v-model="reference2.email"
                type="email"
              />
            </div>
          </div>

          <div class="mt-4">
            <p class="pt-5 pb-2 font-semibold uppercase text-inverse-700">
              Reference Three
            </p>
            <div class="grid grid-rows-1 gap-3">
              <YInput
                required
                label="Company Name"
                v-model="reference3.company"
                type="text"
              />
              <YInput
                required
                label="Contact Name"
                v-model="reference3.contact"
                type="text"
              />
              <YInput
                required
                label="Company Email"
                v-model="reference3.email"
                type="email"
              />
            </div>
          </div>

          <!-- submit -->
          <div class="mt-10 mb-8">
            <YButton color="pink" :is-loading="isSubmitting" style="width: 100%"
              >Submit References</YButton
            >
          </div>
        </div>
      </form>
    </template>
    <template v-if="successMessage">
      <div class="text-center py-10 px-4">
        <h4 class="font-semibold text-lg mb-1">{{ successMessage }}</h4>
        <p>
          You application will be considered with this additional information.
        </p>
      </div>
    </template>
    <template v-if="errorMessage">
      <div class="text-center pt-4 pb-10 px-4">
        <h4 class="text-red-500  font-semibold text-lg mb-1">
          {{ errorMessage }}
        </h4>
        <p>
          Please try again later.
        </p>
      </div>
    </template>

    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
      portal-to="errors"
      alert-class="text-xs"
      :icon-size="12"
      :icon="null"
      color="yellow"
    ></ValidationErrors>
  </div>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import ADD_USERDETAIL_REFERENCES from '@/graphql/UserDetails/AddUserDetailReferences.gql'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isSubmitting: false,
      successMessage: null,
      errorMessage: null,
      reference1: {
        company: '',
        contact: '',
        email: ''
      },
      reference2: {
        company: '',
        contact: '',
        email: ''
      },
      reference3: {
        company: '',
        contact: '',
        email: ''
      }
    }
  },
  updated() {},
  mounted() {},
  methods: {
    combineReferencesToJson() {
      const combinedData = {
        reference1: { ...this.reference1 },
        reference2: { ...this.reference2 },
        reference3: { ...this.reference3 }
      }
      const combinedDataJson = JSON.stringify(combinedData)
      return combinedDataJson
    },
    submit() {
      this.isSubmitting = true
      this.errorMessage = null
      this.successMessage = null
      this.clearValidationErrors()
      this.$apollo
        .mutate({
          mutation: ADD_USERDETAIL_REFERENCES,
          variables: {
            input: {
              token: this.token,
              references: this.combineReferencesToJson()
            }
          }
        })
        .then(({ data }) => {
          this.clearValidationErrors()
          this.isSubmitting = false
          if (data.addUserDetailReferences.status == 'REFERENCE_CREATED') {
            this.successMessage = 'Thank you!'
          } else {
            this.errorMessage = 'Oops, something went wrong.'
          }
        })
        .catch(error => {
          this.isSubmitting = false
          this.setValidationErrors(error)
        })
    }
  },
  computed: {}
}
</script>

<style scoped>
.x-asterisk {
  @apply text-lg text-red-500 text-2xl inline-block;
  transform: translate(0, 0.2rem);
}
</style>
