<template>
  <div>
    <div class="w-full font-bold text-lg text-gray-900 flex justify-between">
      Regional Descriptions
    </div>

    <div class="w-full flex flex-col justify-between">
      <div class="flex space-between gap-2">
        <Superselect
          title="Country"
          v-model="selectedCountry"
          :options="countryOptions"
          class="flex-grow"
          label="name"
          track-by="code"
          :multiple="false"
        ></Superselect>
        <Superselect
          v-if="regionCodeOptions?.values"
          title="Region"
          v-model="selectedRegion"
          :options="regionCodeOptions.values"
          class="flex-grow"
          :multiple="false"
        ></Superselect>
        <YButton class="w-32" color="blue" @click="createNewDescription()"
          >Add Region</YButton
        >
      </div>

      <div class="mt-4 flex flex-col border-bottom w-full">
        <template v-for="(desc, index) in localizedDescriptions">
          <div :key="index" class="flex w-full items-center gap-3">
            <YTextarea
              v-model="localizedDescriptions[index][Object.keys(desc)[0]]"
              :label="`Regional Description: ${Object.keys(desc)[0]}`"
              class="flex-grow"
            ></YTextarea>
            <div
              @click="removeDescription(Object.keys(desc)[0])"
              class="cursor-pointer"
            >
              <Icon
                name="trashCan"
                :size="5"
                class="text-red-600"
                v-tooltip="'Delete Description'"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import COUNTRIES_QUERY from '@/graphql/Country/CountriesQuery.gql'
import ELASTIC_VALUE_SET_QUERY from '@/graphql/ElasticSearch/ElasticValueSetQuery.gql'

export default {
  mounted() {
    this.setLocalizedDescriptionsFromOfferMeta()
  },
  updated() {},
  props: {
    offerMeta: {
      type: String
    }
  },
  apollo: {
    regionCodeOptions: {
      query: ELASTIC_VALUE_SET_QUERY,
      variables() {
        return {
          input: {
            field: 'geo_region'
          }
        }
      },
      result(data) {
        return data.data.elasticValueSet?.values
      },
      update: data => data.elasticValueSet
    },
    countries: {
      query: COUNTRIES_QUERY,
      variables() {
        return {
          orderBy: [
            {
              column: 'name',
              order: 'ASC'
            }
          ]
        }
      },
      result({ data }) {
        this.countryOptions = data.countries.filter(country => {
          const exclude = ['?', 'A1', 'A2']
          return !exclude.includes(country.code)
        })
      }
    }
  },
  data() {
    return {
      countryOptions: [],
      selectedCountry: null,
      selectedRegion: null,
      localizedDescriptions: []
    }
  },
  computed: {
    computedLocalizedDescriptions() {
      return this.localizedDescriptions
    }
  },
  methods: {
    setLocalizedDescriptionsFromOfferMeta() {
      if (this.offerMeta) {
        const offerMetaObject = JSON.parse(this.offerMeta)
        if (offerMetaObject.description?.regions) {
          let regionsArray = []
          const regions = offerMetaObject.description.regions

          //convert regions object to array
          if (typeof regions === 'object') {
            regionsArray = Object.entries(regions).map(([key, value]) => ({
              [key]: value
            }))
          }

          this.localizedDescriptions = regionsArray.map(region => {
            return { [Object.keys(region)[0]]: Object.values(region)[0] }
          })
        }
      }
    },
    createNewDescription() {
      if (this.selectedCountry && this.selectedCountry.code) {
        let country = this.selectedCountry.code.toUpperCase()
        let region = this.selectedRegion || undefined

        const key = region ? `${country}_${region}` : country

        this.localizedDescriptions.push({ [key]: '' })

        this.selectedCountry = null
        this.selectedRegion = null
      } else {
        this.$toastr.e('Country is required', 'Error')
      }
    },
    removeDescription(keyToRemove) {
      const indexToRemove = this.localizedDescriptions.findIndex(
        desc => Object.keys(desc)[0] === keyToRemove
      )
      if (indexToRemove !== -1) {
        this.localizedDescriptions.splice(indexToRemove, 1)
      }
    }
  },
  watch: {
    computedLocalizedDescriptions: {
      deep: true,
      handler(newVal) {
        this.$emit('update-description-regions', newVal)
      }
    }
  }
}
</script>
