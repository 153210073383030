<template>
  <widget>
    <widget-header @close="$emit('close')">
      Edit Nameserver Info
    </widget-header>
    <widget-body>
      <form @submit.prevent="update" ref="form" class="x-form">
        <YInput class="mb-2" v-model="prefix" label="Prefix"></YInput>
        <Superselect
          title="Server"
          v-model="server"
          :multiple="false"
          label="hostname"
          class="mb-2"
          :options="servers || []"
        ></Superselect>
        <Superselect
          title="Domain"
          v-model="domain"
          :multiple="false"
          label="id"
          class="mb-2"
          :options="domains || []"
        ></Superselect>
      </form>
    </widget-body>
    <widget-footer class="bg-gray-100 py-2 px-4 text-right">
      <y-button
        class="btn btn-blue"
        :is-loading="isSubmitting"
        @click="updateRelation"
        @keydown.enter="updateRelation"
        >Save Changes</y-button
      >
    </widget-footer>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>

<script>
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
import CREATE_DNS_MUTATION from '@/graphql/DomainNameserver/CreateDnsNameserverMutation.gql'
import gql from 'graphql-tag'
export default {
  mixins: [ValidationErrorsMixin],
  props: {},
  components: {},
  apollo: {
    servers: {
      query: gql`
        query ydnsServers($filters: YdnsServerFilterInput) {
          servers: ydnsServers(filters: $filters) {
            id
            hostname
            serverIp
          }
        }
      `,
      variables() {
        return {
          filters: {
            type: {
              value: ['nameserver']
            }
          }
        }
      }
    },
    domains: {
      query: gql`
        query ydnsDomains($filters: YdnsDomainFilterInput) {
          domains: ydnsDomains(filters: $filters) {
            id
          }
        }
      `,
      variables() {
        return {
          filters: {
            type: {
              value: ['nameserver']
            }
          }
        }
      }
    }
  },
  data() {
    return {
      server: {},
      domain: {},
      prefix: null,
      isSubmitting: false,
      colSize: 'col-xl-3 col-lg-6',
      colSize2: 'col-xl-6 col-lg-12'
    }
  },
  watch: {},
  computed: {},
  methods: {
    updateRelation() {
      this.isSubmitting = true
      let input = {
        domainId: this.domain.id,
        domainPrefix: this.prefix,
        serverId: parseInt(this.server.id)
      }

      this.$apollo
        .mutate({
          mutation: CREATE_DNS_MUTATION,
          variables: {
            input: input
          }
        })
        .then(() => {
          this.isSubmitting = false
          this.$events.emit('refreshDnsDomains', true)
          this.$emit('close')
        })
        .catch(error => {
          console.log(error)
          this.isSubmitting = false
        })
    }
  },

  mounted() {},
  updated() {}
}
</script>
