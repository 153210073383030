var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-gray-200 p-1 border-b mb-2"
  }, [[_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 grid-cols-6 gap-1"
  }, [_c('YInput', {
    attrs: {
      "label": "Subdomain"
    },
    model: {
      value: _vm.filters.subdomain.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.subdomain, "value", $$v);
      },
      expression: "filters.subdomain.value"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Exception Type",
      "multiple": false,
      "options": _vm.options.level
    },
    model: {
      value: _vm.filters.level,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "level", $$v);
      },
      expression: "filters.level"
    }
  }), _c('Superselect', {
    attrs: {
      "title": "Country",
      "multiple": false,
      "label": "label",
      "track-by": "code",
      "loading": _vm.$apollo.queries.countries.loading,
      "options": _vm.countries
    },
    model: {
      value: _vm.filters.country.value,
      callback: function ($$v) {
        _vm.$set(_vm.filters.country, "value", $$v);
      },
      expression: "filters.country.value"
    }
  }), _c('YButton', {
    attrs: {
      "color": "blue"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openCreateModal();
      }
    }
  }, [_vm._v("Create Alf Exception")])], 1)])]], 2), _c('div', {
    staticClass: "w-100"
  }, [_vm.alfExceptions ? _c('YTable', {
    attrs: {
      "deletedAtField": "isCapped",
      "deletedAtClass": "text-red-500 font-medium",
      "data": _vm.alfExceptions.data || [],
      "bottom-loading": _vm.$apolloData.queries.alfExceptions.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(_vm._s(props.rowData.subdomain))]), _c('td', [_c('router-link', {
          class: props.rowData.isCapped ? 'text-red-600 hover:text-red-700 font-medium' : 'link',
          attrs: {
            "to": {
              name: 'User',
              params: {
                id: props.rowData.user.id,
                action: 'edit'
              }
            }
          }
        }, [_vm._v(" " + _vm._s(props.rowData.user.label) + " ")])], 1), _c('td', [_vm._v(_vm._s(props.rowData.country) + " ")]), _c('td', [_c('div', {
          staticClass: "text-right"
        }, [_c('a', {
          class: 'text-red-600 hover:text-red-700',
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openDeleteModal(props.rowData);
            }
          }
        }, [_c('Icon', {
          staticClass: "inline",
          attrs: {
            "name": "trashCan",
            "size": 4
          }
        })], 1)])])];
      }
    }, {
      key: "after",
      fn: function () {
        return [_vm.alfExceptions.paginatorInfo ? _c('YPagination', {
          key: _vm.totalPages,
          attrs: {
            "data": _vm.alfExceptions.paginatorInfo
          },
          on: {
            "load-page": _vm.loadPage,
            "next-page": _vm.nextPage,
            "previous-page": _vm.previousPage,
            "per-page": _vm.perPageSelection
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 1468847368)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Subdomain")]), _c('th', [_vm._v("User")]), _c('th', [_vm._v("Country")]), _c('th', [_vm._v("Actions")])])], 2) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }