<template>
  <widget>
    <widget-header @close="$emit('close')" class="bg-gray-100">
      Create New Key
    </widget-header>
    <widget-body class="bg-gray-100">
      <form @submit.prevent="create" ref="form" class="flex">
        <Superselect
          title="Key Name"
          v-model="key.name"
          :multiple="false"
          :options="accountKeyTypeEnum"
          class="flex-grow"
          inputClass="border-r-0 border-l-0 rounded-l-none rounded-r-none"
        ></Superselect>
        <YInput
          class="flex-grow"
          v-model="key.value"
          label="Key Value"
          inputClass="border-r-0 border-l-0 rounded-l-none rounded-r-none"
        ></YInput>
        <y-button
          class="btn btn-blue rounded-l-none"
          :is-loading="isSubmitting"
          @click="saveKey"
          @keydown.enter="saveKey"
          >Add Key</y-button
        >
      </form>
    </widget-body>
    <YTable
      v-if="advertiserAccountKeys"
      :data="advertiserAccountKeys.data || []"
      :bottom-loading="$apolloData.queries.advertiserAccountKeys.loading"
    >
      <template slot="header-row">
        <th>Name</th>
        <th>Value</th>
        <th></th>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          <template v-if="props.rowData.name">{{
            props.rowData.name
          }}</template>
        </td>
        <td>
          <template v-if="props.rowData.name">{{
            props.rowData.value
          }}</template>
        </td>
        <td class="text-right">
          <a
            href="#"
            @click.prevent="deleteKey(props.rowData.id)"
            class="text-red-600 hover:text-red-700"
          >
            <Icon name="trashCan" :size="4" class="inline" />
          </a>
        </td>
      </template>
    </YTable>
    <ValidationErrors
      v-if="validationErrors"
      :errors="validationErrors"
    ></ValidationErrors>
  </widget>
</template>
<script>
import gql from 'graphql-tag'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'
export default {
  mixins: [ValidationErrorsMixin],
  props: {
    advertiserId: {
      type: Number,
      required: true
    },
    accountId: {
      type: Number,
      required: true
    }
  },
  components: {},
  apollo: {
    accountKeyTypeEnum: {
      query: gql`
        {
          accountKeyTypeEnum: __type(name: "AccountKeyTypeEnum") {
            name
            enumValues {
              name
            }
          }
        }
      `,
      result({ data }) {
        if (data) {
          this.accountKeyTypeEnum = data.accountKeyTypeEnum.enumValues.map(
            option => option.name
          )
        }
      }
    },
    advertiserAccountKeys: {
      query: gql`
        query advertiserAccountKeys(
          $filters: AdvertiserAccountKeyFiltersInput
          $first: Int!
        ) {
          advertiserAccountKeys: advertiserAccountKeys(
            filters: $filters
            first: $first
          ) {
            data {
              id
              name
              value
            }
          }
        }
      `,
      variables() {
        return {
          filters: {
            advertiser_account_id: { value: this.accountId }
          },
          first: 100000
        }
      }
    }
  },
  data() {
    return {
      key: {},
      isSubmitting: false,
      colSize: 'col-xl-3 col-lg-6',
      colSize2: 'col-xl-6 col-lg-12',
      yLeadtypeOptions: ['lead', 'real', 'none']
    }
  },
  watch: {},
  computed: {},
  methods: {
    deleteKey(key_id) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteAdvertiserAccountKey($id: ID!) {
              deleteAdvertiserAccountKey(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: key_id
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('Key Deleted')
          this.isSubmitting = false
          this.$events.emit('refreshAdvertiserAccountKeys', true)
        })
        .catch(error => {
          console.log(error)
          //toastr.error(error, 'Oops!')
          this.isSubmitting = false
        })
    },

    saveKey() {
      this.isSubmitting = true
      let input = {
        advertiserAccountId: this.accountId,
        value: this.key.value,
        name: this.key.name
      }

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createAdvertiserAccountKey(
              $input: CreateAdvertiserAccountKeyInput!
            ) {
              createAdvertiserAccountKey(input: $input) {
                id
              }
            }
          `,
          variables: {
            input: input
          }
        })
        .then(() => {
          this.clearValidationErrors()
          this.$toastr.s('', 'Key Added!')
          this.isSubmitting = false
          this.$events.emit('refreshAdvertiserAccountKeys', true)
        })
        .catch(error => {
          this.setValidationErrors(error)
          //toastr.error(error, 'Oops!')
          this.isSubmitting = false
        })
    }
  },
  mounted() {
    this.$events.listen('refreshAdvertiserAccountKeys', value => {
      console.log(value)
      this.$apollo.queries.advertiserAccountKeys.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refreshAdvertiserAccountKeys')
  },
  updated() {}
}
</script>
<style scoped>
/* .x-input-group {
  @apply flex;
}
.x-input-group > * {
  @apply border-r-0 border-l-0;
} */
</style>
