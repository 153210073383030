<template>
  <div>
    <div
      class="grid grid-cols-2
  lg:grid-cols-3
  xl:grid-cols-4
  2xl:grid-cols-5
 gap-8 py-3"
    >
      <div v-for="campaign in campaigns.data" :key="campaign.id">
        <CampaignsGridViewCard :campaign="campaign"></CampaignsGridViewCard>
      </div>
    </div>
    <div
      class="w-100 py-4"
      v-if="
        campaigns &&
          campaigns.paginatorInfo &&
          campaigns.paginatorInfo.hasMorePages
      "
    >
      <YButton
        class="block m-auto"
        color="link"
        @click="onClickButton"
        :is-loading="isLoading"
        >LOAD MORE</YButton
      >
    </div>
  </div>
</template>

<script>
import CampaignsGridViewCard from '@/views/Publisher/Campaigns/CampaignsGridViewCard.vue'
export default {
  components: {
    CampaignsGridViewCard
  },
  props: {
    campaigns: {
      type: Object
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClickButton() {
      this.$emit('loadMore')
    }
  }
}
</script>
