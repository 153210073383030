var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ReportFilters', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilters,
      expression: "showFilters"
    }],
    ref: "form",
    attrs: {
      "link": this,
      "master-column-data": _vm.masterColumnData,
      "create-vuetable-columns": _vm.createVuetableColumns,
      "persist-state": _vm.persistState,
      "auto-load": _vm.autoLoad,
      "default-date-range": _vm.defaultDateRange,
      "default-filters": _vm.defaultFilters,
      "initial-preset-id": _vm.initialPresetId
    }
  }), _c('Widget', {
    class: [_vm.hasEverLoaded ? '' : 'disabled', 'm-0'],
    attrs: {
      "loading": _vm.isLoading
    }
  }, [_c('WidgetHeader', [_c('template', {
    slot: "rawContent"
  }, [_vm._t("table-header", function () {
    return [_c('h4', {
      staticClass: "widget-title"
    }, [_vm._v(_vm._s(_vm.readableDateRange))])];
  }), _vm.isLoading ? [this.isLoading ? _c('Spinner', {
    attrs: {
      "size": 35,
      "color": `green`
    }
  }) : _vm._e()] : [_vm._t("right")]], 2)], 2), _c('WidgetBody', {
    class: [_vm.$refs.form && _vm.$refs.form.$apollo.queries.report.loading ? 'reports-loading' : '']
  }, [_vm._t("table-body-start"), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col overflow-x-scroll"
  }, [_c('vuetable', {
    ref: "vuetable",
    staticClass: "reports-table table-striped table-hover",
    attrs: {
      "api-mode": false,
      "fields": _vm.fields,
      "data": _vm.vuetableData,
      "data-total": _vm.dataTotal,
      "pagination-path": "pagination",
      "data-path": "data",
      "css": _vm.css,
      "sort-order": _vm.sortOrder,
      "multi-sort": _vm.multiSort,
      "per-page": _vm.perPage,
      "multi-sort-key": "shift",
      "cellspacing": "0",
      "width": "100%",
      "table-wrapper": "vuetable-wrapper"
    },
    on: {
      "vuetable:loading": _vm.onLoading,
      "vuetable:loaded": _vm.onLoaded
    },
    scopedSlots: _vm._u([{
      key: "tableTotals",
      fn: function (props) {
        return [_c('tr', {
          staticClass: "spacer"
        }), _c('tr', _vm._l(props.fields, function (field) {
          return _c('td', {
            key: field.name
          }, [_c('span', {
            domProps: {
              "textContent": _vm._s(_vm.formatTotalField(field))
            }
          })]);
        }), 0), _c('tr', {
          staticClass: "spacer h-2"
        })];
      }
    }, {
      key: "country-slot",
      fn: function (props) {
        return _c('div', {}, [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.country ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.country,
              label: props.rowData.country
            }
          },
          model: {
            value: _vm.$refs.form.filters.country.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.country, "value", $$v);
            },
            expression: "$refs.form.filters.country.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.country)
          }
        })], 1)]);
      }
    }, {
      key: "campaign-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.campaign ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.campaign ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.campaign.split(' - ')[0],
              label: props.rowData.campaign
            }
          },
          model: {
            value: _vm.$refs.form.filters.campaign.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.campaign, "value", $$v);
            },
            expression: "$refs.form.filters.campaign.value"
          }
        }) : _vm._e(), _c('router-link', {
          staticClass: "link without-truncate",
          staticStyle: {
            "max-width": "20rem"
          },
          attrs: {
            "to": {
              name: 'Publisher Campaign',
              params: {
                id: props.rowData.campaign.split(' - ')[0]
              }
            }
          }
        }, [_vm._v(_vm._s(props.rowData.campaign))])], 1)] : _vm._e()], 2);
      }
    }, {
      key: "template-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.template ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.template ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.template.split(' - ')[0],
              label: props.rowData.template
            }
          },
          model: {
            value: _vm.$refs.form.filters.template.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.template, "value", $$v);
            },
            expression: "$refs.form.filters.template.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.template)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "mobile-desktop-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.mobileDesktop ? [_c('div', {
          staticClass: "report-value"
        }, [_c('a', {
          staticClass: "mr-2",
          class: [props.rowData.mobileDesktop == 'Mobile' && _vm.$refs.form.filters.mobileDesktop.modifiers.exclude != false || props.rowData.mobileDesktop == 'Desktop' && _vm.$refs.form.filters.mobileDesktop.modifiers.exclude == false ? 'text-muted' : ''],
          attrs: {
            "href": "#"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              props.rowData.mobileDesktop == 'Mobile' ? _vm.$refs.form.filters.mobileDesktop.modifiers.exclude = false : _vm.$refs.form.filters.mobileDesktop.modifiers.exclude = true;
            }
          }
        }, [_c('Icon', {
          attrs: {
            "name": "target",
            "size": 4
          }
        })], 1), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.mobileDesktop)
          }
        })])] : _vm._e()], 2);
      }
    }, {
      key: "usage-type-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.usageType ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.usageType ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.usageType,
              label: props.rowData.usageType
            }
          },
          model: {
            value: _vm.$refs.form.filters.usageType.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.usageType, "value", $$v);
            },
            expression: "$refs.form.filters.usageType.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.usageType)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "keyword-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.keyword ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.keyword ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.keyword,
              label: props.rowData.keyword
            }
          },
          model: {
            value: _vm.$refs.form.filters.keyword.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.keyword, "value", $$v);
            },
            expression: "$refs.form.filters.keyword.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.keyword)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "subid-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.subId ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.subId ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.subId,
              label: props.rowData.subId
            }
          },
          model: {
            value: _vm.$refs.form.filters.subId.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.subId, "value", $$v);
            },
            expression: "$refs.form.filters.subId.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.subId)
          }
        })], 1)] : _vm._e()], 2);
      }
    }, {
      key: "conversion-type-slot",
      fn: function (props) {
        return _c('div', {}, [props.rowData.payoutConversionType ? [_c('div', {
          staticClass: "report-value"
        }, [props.rowData.payoutConversionType ? _c('ReportFilterValueController', {
          staticClass: "pr-2",
          attrs: {
            "controller-value": {
              id: props.rowData.payoutConversionType,
              label: props.rowData.payoutConversionType
            }
          },
          model: {
            value: _vm.$refs.form.filters.payoutConversionType.value,
            callback: function ($$v) {
              _vm.$set(_vm.$refs.form.filters.payoutConversionType, "value", $$v);
            },
            expression: "$refs.form.filters.payoutConversionType.value"
          }
        }) : _vm._e(), _c('span', {
          domProps: {
            "textContent": _vm._s(props.rowData.payoutConversionType)
          }
        })], 1)] : _vm._e()], 2);
      }
    }])
  })], 1)])], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }