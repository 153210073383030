<template>
  <span @click="$emit('input', !value)">
    <slot v-if="value" name="true"></slot>
    <slot v-if="!value" name="false"></slot>
  </span>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  updated() {},
  methods: {}
}
</script>
<style scoped>
span {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
</style>
