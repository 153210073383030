<template>
  <div class="shadow rounded-lg">
    <div class="flex justify-between items-center bg-gray-100 px-4 pt-2">
      <div class="text-muted">
        <span>
          {{ selectedCount }}
          {{ selectedCount == 1 ? 'item' : 'items' }} selected
          <small class="pl-3" v-if="rowsAreSelected">
            <a
              href="#"
              @click.prevent="clearAllSelected"
              class="link"
              style="border-radius: 2rem; padding: 0.1rem 0.6rem"
              >CLEAR SELECTED</a
            >
          </small>
        </span>
      </div>
      <div class="text-right">
        <YButton
          class="text-red-600 hover:bg-red-200"
          :disabled="!rowsAreSelected"
          @click.prevent="openDeleteModalImages(selectedRowIds)"
        >
          Delete
        </YButton>
      </div>
    </div>
    <YTable
      v-if="data"
      :loading="isLoading"
      :data="data || []"
      :selectable="true"
      selectBy="id"
      :selected.sync="selectedRowIds"
    >
      <template v-slot:header-row>
        <th></th>
        <th>name</th>
        <th>
          slug
        </th>
        <th>tags</th>
        <th>
          last updated
        </th>
        <th></th>
      </template>

      <template v-slot:row="props">
        <td class="w-40">
          <EditableImage
            class="rounded"
            :imageUrl="
              formatUpdatedUrl(
                props.rowData.imageUrl,
                props.rowData.image.updatedAt
              )
            "
            :thumbnailUrl="
              formatUpdatedUrl(
                props.rowData.imageThumbnailUrl,
                props.rowData.image.updatedAt
              )
            "
            :height="32"
            :width="32"
            :blankHeight="20"
            :blankWidth="20"
            :editable-only-when-empty="true"
            modelClass="TemplateImage"
            :modelId="props.rowData.id"
          ></EditableImage>
        </td>
        <td>
          <ul>
            <li v-text="props.rowData.name" class="gray-700 font-bold"></li>
          </ul>
        </td>
        <td>
          <ul>
            <li>
              {{ props.rowData.slug }}
              <a
                href="#"
                class="inline-block"
                @click.prevent="copyToClipboard(props.rowData.slug)"
              >
                <Icon
                  v-tooltip.top="`Copy to Clipboard`"
                  name="contentCopy"
                  :size="4"
                  class="ml-4 inline-block text-blue-600"
                />
              </a>
            </li>
          </ul>
        </td>
        <td>
          <Tag
            v-for="tag in props.rowData.tags"
            :key="tag.id"
            class="mr-1 my-1"
            :color="tag.color"
            tabindex="0"
            >{{ tag.name }}</Tag
          >
        </td>
        <td>{{ date(props.rowData.updatedAt) }}</td>
        <td class="text-right">
          <div class="inline-flex">
            <a
              href="#"
              class="link"
              @click.prevent="openEditModal(props.rowData)"
            >
              <Icon name="pencil" class="w-4 h-4" />
            </a>
            <a
              href="#"
              class="ml-2 text-red-500 hover:text-red-600"
              @click.prevent="openDeleteModal(props.rowData)"
            >
              <Icon name="trashCan" class="w-4 h-4" />
            </a>
          </div>
        </td>
      </template>
    </YTable>
  </div>
</template>

<script>
import EditableImage from '@/components/utilities/EditableImage'
import DeleteTemplateImage from '@/views/Admin/TemplateImages/DeleteTemplateImage.vue'
import DeleteTemplateImages from '@/views/Admin/TemplateImages/DeleteTemplateImages.vue'
import EditTemplateImage from '@/views/Admin/TemplateImages/EditTemplateImage.vue'

export default {
  mounted() {},
  updated() {},
  components: {
    EditableImage
  },
  props: {
    data: {
      default: () => {
        ;[]
      },
      date: () => {
        this.updatedAt
      },
      type: Array
    },
    isLoading: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return { selectedRowIds: [] }
  },
  methods: {
    formatUpdatedUrl(url = '', date = '') {
      return url + '?v=' + date
    },
    date(value) {
      return value.slice(0, 10)
    },
    openDeleteModal(value) {
      this.$modal.show(
        DeleteTemplateImage,
        { templateImage: value },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDeleteModalImages(value) {
      this.$modal.show(
        DeleteTemplateImages,
        { rows: value },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openEditModal(value) {
      this.$modal.show(
        EditTemplateImage,
        { templateImage: value },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    copyToClipboard(content) {
      this.$copyText(content).then(
        this.$toastr.s('Copied to Clipboard', 'Success!')
      )
    },
    clearAllSelected() {
      this.selectedRowIds = []
    }
  },
  computed: {
    rowsAreSelected() {
      return this.selectedRowIds.length > 0
    },
    selectedCount() {
      return this.selectedRowIds.length
    }
  }
}
</script>
