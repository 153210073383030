<template>
  <Widget>
    <WidgetHeader>Update Lead Details</WidgetHeader>
    <WidgetBody>
      <div class="grid grid-cols-1 gap-2">
        <YInput class="mb-1" label="Name" v-model="lead.primaryContact.name" />
        <YInput
          class="mb-1"
          label="Company"
          v-model="lead.primaryContact.company"
        />
        <YInput
          class="mb-1"
          label="Messenger Handle"
          v-model="messengerHandle"
        />
        <Superselect
          title="Messenger App"
          v-model="messengerApp"
          :multiple="false"
          :options="['telegram', 'skype']"
          class="mb-1"
        ></Superselect>
        <Superselect
          title="Country"
          v-model="lead.primaryContact.country"
          :multiple="false"
          track-by="code"
          label="label"
          :options="countries"
          class="mb-1"
        ></Superselect>
      </div>
      <div class="w-full flex justify-end gap-3 mt-4">
        <YButton color="gray" @click="$emit('close')">Cancel</YButton>
        <YButton color="blue" @click="update">Save</YButton>
      </div>
    </WidgetBody>
  </Widget>
</template>

<script>
import gql from 'graphql-tag'
import UPDATE_USER_MUTATION from '@/graphql/User/UpdateUserMutation.gql'
import ValidationErrorsMixin from '@/mixins/ValidationErrorsMixin'

export default {
  mixins: [ValidationErrorsMixin],
  props: {
    lead: {
      type: Object,
      required: true
    }
  },
  apollo: {
    countries: {
      query: gql`
        {
          countries {
            code
            name
            label
          }
        }
      `
    }
  },
  data() {
    return {
      isUpdating: false,
      countries: []
    }
  },
  computed: {
    messengerApp: {
      get: function() {
        return this.lead.primaryContact.messenger
          ? JSON.parse(this.lead.primaryContact.messenger).app
          : null
      },
      set: function(newValue) {
        this.lead.primaryContact.messenger = JSON.stringify({
          handle: this.messengerHandle,
          app: newValue
        })
      }
    },
    messengerHandle: {
      get: function() {
        return this.lead.primaryContact.messenger
          ? JSON.parse(this.lead.primaryContact.messenger).handle
          : null
      },
      set: function(newValue) {
        this.lead.primaryContact.messenger = JSON.stringify({
          handle: newValue,
          app: this.messengerApp
        })
      }
    }
  },
  methods: {
    update() {
      this.validationErrors = null
      this.isUpdating = true
      this.$store.dispatch('updateGlobalLoader', 1)
      this.$apollo
        .mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            input: {
              id: this.lead.id,
              contacts: {
                update: [
                  {
                    id: this.lead.primaryContact.id,
                    name: this.lead.primaryContact.name,
                    countryCode: this.lead.primaryContact.country
                      ? this.lead.primaryContact.country.code
                      : undefined,
                    postalCode:
                      this.lead.primaryContact.postalCode ?? undefined,
                    messenger: this.lead.primaryContact.messenger ?? undefined,
                    company: this.lead.primaryContact.company ?? undefined
                  }
                ]
              }
            }
          }
        })
        .then(() => {
          this.isUpdating = false
          this.$toastr.s('Lead Details Updated')
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$emit('close')
        })
        .catch(error => {
          this.isUpdating = false
          this.setValidationErrors(error)
          this.$store.dispatch('updateGlobalLoader', -1)
          this.$emit('close')
        })
    }
  }
}
</script>
