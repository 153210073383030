<template>
  <Tabs show-type="show" :route="tabRoute">
    <Tab name="Bulk Payouts" :active="true">
      <bulk-payouts />
    </Tab>
    <Tab name="Add Adjustment">
      <adjustments />
    </Tab>
    <Tab name="Publisher Balance Upload">
      <publisher-balance-upload />
    </Tab>
  </Tabs>
</template>

<script>
import BulkPayouts from '@/components/legacy/BulkPayouts/AdminBulkPayouts'
import Adjustments from '@/components/legacy/Adjustments'
import PublisherBalanceUpload from '@/views/Admin/Tools/PublisherBalanceUpload'
export default {
  components: {
    BulkPayouts,
    Adjustments,
    PublisherBalanceUpload
  },
  props: {
    tab: {
      type: String,
      default: 'bulk-payouts'
    }
  },
  computed: {
    tabRoute() {
      return { name: 'Tools', params: { tab: this.tab } }
    }
  }
}
</script>

<style></style>
