var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.me ? _c('QuarantineCampaignAlert', {
    attrs: {
      "publisher": _vm.me
    }
  }) : _vm._e(), _c('widget', {
    staticClass: "mb-2"
  }, [_c('widget-header', {
    staticClass: "bg-gray-200 p-1 border-b"
  }, [_c('template', {
    slot: "rawContent"
  }, [_c('PublisherCampaignFilters')], 1)], 2)], 1), !_vm.gridViewHighlight ? _c('div', {
    staticClass: "mb-2 flex justify-end mr-3"
  }, [_vm.listView ? _c('div', {
    staticClass: "tooltip"
  }, [_c('div', {
    staticClass: "bg-pink-100 p-2 rounded-full cursor-pointer hover:bg-pink-200 hover:scale-110 shadow-md transition transform duration-500 ease-in-out",
    on: {
      "click": _vm.gridViewTransform
    }
  }, [_c('Icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "name": "grid",
      "size": "6",
      "color": "gray"
    }
  })], 1), _vm._m(0)]) : _vm._e()]) : _vm._e(), _vm.gridViewHighlight ? _c('div', {
    staticClass: "mb-2 flex justify-end mr-3"
  }, [!_vm.listView ? _c('span', {
    on: {
      "click": _vm.toggleView
    }
  }, [_c('Icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "name": "list",
      "size": "6",
      "color": "gray"
    }
  })], 1) : _vm._e(), _vm.listView ? _c('span', {
    on: {
      "click": _vm.toggleView
    }
  }, [_c('Icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "name": "grid",
      "size": "6",
      "color": "gray"
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm.listView ? _c('CampaignsListView', {
    attrs: {
      "campaigns": _vm.campaigns || [],
      "isLoading": _vm.$apollo.queries.publisherCampaigns.loading
    },
    on: {
      "loadMore": _vm.loadMore
    }
  }) : _vm._e(), !_vm.listView ? _c('CampaignsGridView', {
    attrs: {
      "campaigns": _vm.campaigns || [],
      "isLoading": _vm.$apollo.queries.publisherCampaigns.loading
    },
    on: {
      "loadMore": _vm.loadMore
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "highlight-tooltip"
  }, [_c('p', [_vm._v(" Check out our new "), _c('span', {
    staticClass: "font-extrabold"
  }, [_vm._v("Grid View!")])]), _c('i')]);

}]

export { render, staticRenderFns }