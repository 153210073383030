var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "w-100"
  }, [_vm.subdomainHistory ? _c('YTable', {
    attrs: {
      "data": _vm.subdomainHistory.data || [],
      "loading": _vm.$apolloData.queries.subdomainHistory.loading
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [_c('td', [_vm._v(_vm._s(props.rowData.subdomain))]), _c('td', [props.rowData.replaced == null ? _c('span', {
          staticClass: "text-green-400"
        }, [_vm._v("Acitve")]) : _c('span', {
          staticClass: "text-red-400"
        }, [_vm._v(" " + _vm._s(props.rowData.replaced) + " ")])])];
      }
    }], null, false, 863981874)
  }, [_c('template', {
    slot: "header-row"
  }, [_c('th', [_vm._v("Subdomain")]), _c('th', [_vm._v("Replaced")])])], 2) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }