var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Widget', [_c('WidgetHeader', {
    staticClass: "background-gradient-green-blue text-white tiny-forms",
    on: {
      "close": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Are you sure you want to pause? ")]), _c('WidgetBody', [_c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" Pausing "), this.offer ? _c('span', {
    staticClass: "font-bold"
  }, [_vm._v(_vm._s(this.offer.label))]) : _vm._e(), _vm._v(" will: ")]), _c('ul', {
    staticClass: "list-disc ml-8"
  }, [_c('li', [_vm._v("Delete any active weight rules for this offer")]), this.offersUsing ? _c('li', [_c('p', [_vm._v(" Pause "), _c('span', {
    staticClass: "font-bold text-blue-600"
  }, [_vm._v(_vm._s(this.offersUsing.length))]), _vm._v(" offers pointing to this offer. ")]), _vm.showOffersUsing ? _c('ul', {
    staticClass: "list-disc ml-8"
  }, _vm._l(_vm.offersUsing, function (item) {
    return _c('li', {
      key: item.id
    }, [_c('span', {
      on: {
        "click": function ($event) {
          return _vm.$emit('close');
        }
      }
    }, [_c('router-link', {
      staticClass: "link",
      attrs: {
        "to": {
          name: 'Offer',
          params: {
            id: item.id,
            action: 'edit'
          }
        }
      }
    }, [_vm._v(_vm._s(item.label))])], 1)]);
  }), 0) : _vm._e(), _c('p', [_c('a', {
    staticClass: "link text-xs",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.toggleOffersUsing.apply(null, arguments);
      }
    }
  }, [_vm.showOffersUsing ? [_vm._v("Hide")] : [_vm._v("Show")], _vm._v(" Offers")], 2)])]) : _vm._e()])]), _c('WidgetFooter', {
    staticClass: "px-4 py-2 bg-gray-100 text-right"
  }, [_c('YButton', {
    staticClass: "btn btn-red",
    attrs: {
      "is-loading": _vm.isSubmitting
    },
    on: {
      "click": _vm.remove,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.remove.apply(null, arguments);
      }
    }
  }, [_vm._v("Pause")])], 1), _vm.validationErrors ? _c('ValidationErrors', {
    attrs: {
      "errors": _vm.validationErrors
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }