<template>
  <div>
    <portal to="top-bar-right">
      <div class="flex justify-right">
        <YButton class="btn btn-blue" @click="openCreateModal">
          New Notification
        </YButton>
      </div>
    </portal>
    <YTable
      v-if="notifications"
      class="table-striped table-hover overflow-hidden"
      block-style="min-height: 35rem;"
      :bottom-loading="$apolloData.queries.notifications.loading"
      :data="notifications"
    >
      <template slot="header-row">
        <th>Name</th>
        <th>Routes</th>
        <th>Users</th>
        <th>Color</th>
        <th>Start Date</th>
        <th>Expiry Date</th>
        <th></th>
      </template>
      <template slot="secondHeaderRow">
        <td>
          <YInput label="Name" v-model="name" class="-mx-3" />
        </td>
        <td>
          <Superselect
            title="Routes"
            class="mb-2"
            v-model="routes"
            :options="routeOptions || []"
            track-by="name"
            label="label"
          />
        </td>
        <td>
          <Superselect
            title="Users"
            v-model="users"
            label="label"
            track-by="value"
            class="-mx-3"
            :query="USER_OPTIONS_QUERY"
            :query-variables="{
              filters: {
                label: {
                  value: ['{input}'],
                  modifiers: { matchType: 'contains' }
                }
              },
              first: 25
            }"
          />
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          {{ props.rowData.name }}
        </td>
        <td>
          <div class="w-56">
            {{
              props.rowData.routes &&
                props.rowData.routes
                  .map(route => route.replace('Publisher ', ''))
                  .join(', ')
            }}
          </div>
        </td>
        <td v-if="props.rowData.users">
          <div v-for="user in props.rowData.users" :key="user.id">
            {{ user.email }}
          </div>
        </td>
        <td>
          <Tag :color="props.rowData.color">
            {{ props.rowData.color }}
          </Tag>
        </td>
        <td>
          {{ props.rowData.startDate | luxon }}
        </td>
        <td>
          {{ props.rowData.expiryDate | luxon }}
        </td>
        <td>
          <div class="inline-flex items-center">
            <a
              href="#"
              class="link"
              @click.prevent="openEditModal(props.rowData)"
            >
              <Icon name="pencil" class="w-4 h-4" />
            </a>
            <a
              href="#"
              class="ml-2 text-red-500 hover:text-red-600"
              @click.prevent="openDeleteModal(props.rowData)"
            >
              <Icon name="trashCan" class="w-4 h-4" />
            </a>
          </div>
        </td>
      </template>
    </YTable>
  </div>
</template>

<script>
import NOTIFICATIONS_QUERY from '@/graphql/Notifications/NotificationsQuery.gql'
import CreateNotification from './CreateNotification.vue'
import DeleteNotification from './DeleteNotification.vue'
import EditNotification from './EditNotification.vue'
import USER_OPTIONS_QUERY from '@/graphql/User/UserOptionsQuery.gql'

export default {
  apollo: {
    notifications: {
      query: NOTIFICATIONS_QUERY,
      variables() {
        return {
          filters: {
            userId:
              this.users.length > 0
                ? {
                    value: this.users.map(user => user.id)
                  }
                : undefined,
            name: this.name
              ? {
                  value: [this.name]
                }
              : undefined,
            routes: this.routes
              ? this.routes.map(route => route.name)
              : undefined
          }
        }
      }
    }
  },
  data() {
    return {
      USER_OPTIONS_QUERY,
      name: null,
      routes: [],
      users: [],
      routeList: this.$router.options.routes
    }
  },
  mounted() {
    this.$events.listen('refreshNotifications', () => {
      this.$apollo.queries.notifications.refetch()
    })
  },
  computed: {
    routeOptions() {
      return this.routeList
        .filter(route => route.meta.scope == 'publisher')
        .map(route => {
          return {
            label: route.name.replace('Publisher ', ''),
            name: route.name,
            path: route.path
          }
        })
    }
  },
  methods: {
    displayRouteList(routes) {
      console.log('routes is', routes.join(', '))
      if (JSON.parse(routes)) {
        const routeList = []
        let parsedRoutesObject = JSON.parse(routes)

        parsedRoutesObject.forEach(route => routeList.push(route.name))

        return routeList.join(', ')
      }
    },
    openCreateModal() {
      this.$modal.show(
        CreateNotification,
        {},
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openDeleteModal(notification) {
      this.$modal.show(
        DeleteNotification,
        { notification: notification },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openEditModal(notification) {
      this.$modal.show(
        EditNotification,
        { notification: notification },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>

<style></style>
