var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    ref: "form",
    attrs: {
      "id": "publisher-adjustment-form",
      "name": "publisher-adjustment-form"
    },
    on: {
      "submit": _vm.submitForm,
      "keydown": function ($event) {
        return _vm.errors.clear($event.target.name);
      },
      "change": function ($event) {
        return _vm.errors.clear($event.target.name);
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.type,
      expression: "data.type"
    }],
    attrs: {
      "type": "hidden"
    },
    domProps: {
      "value": _vm.data.type
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.data, "type", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "row pt-3"
  }, [_c('div', {
    staticClass: "grid grid-cols-1 sm:grid-cols-5 gap-1"
  }, [_c('y-form-group', [_c('Superselect', {
    attrs: {
      "title": "Publisher",
      "options": _vm.publisherOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "close-on-select": false,
      "query": _vm.PUBLISHER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": ""
    },
    model: {
      value: _vm.data.publisher,
      callback: function ($$v) {
        _vm.$set(_vm.data, "publisher", $$v);
      },
      expression: "data.publisher"
    }
  })], 1), _c('y-form-group', [_c('Superselect', {
    attrs: {
      "title": "Advertiser",
      "options": _vm.advertiserOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "close-on-select": false,
      "query": _vm.ADVERTISER_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          advertiserLabel: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": ""
    },
    model: {
      value: _vm.data.advertiser,
      callback: function ($$v) {
        _vm.$set(_vm.data, "advertiser", $$v);
      },
      expression: "data.advertiser"
    }
  })], 1), _c('y-form-group', [_c('Superselect', {
    attrs: {
      "title": "Campaign",
      "options": _vm.campaignOptions || [],
      "placeholder": "Select",
      "track-by": "id",
      "label": "label",
      "close-on-select": false,
      "query": _vm.CAMPAIGN_OPTIONS_QUERY,
      "query-variables": {
        first: 20,
        filters: {
          label: {
            value: ['{input}'],
            modifiers: {
              matchType: 'contains'
            }
          }
        }
      },
      "edit-placeholder": ""
    },
    model: {
      value: _vm.data.campaign,
      callback: function ($$v) {
        _vm.$set(_vm.data, "campaign", $$v);
      },
      expression: "data.campaign"
    }
  })], 1), _c('y-form-group', [_c('YInput', {
    staticClass: "form-control",
    attrs: {
      "label": "Payout",
      "placeholder": "0.00",
      "name": "payout"
    },
    model: {
      value: _vm.data.payout,
      callback: function ($$v) {
        _vm.$set(_vm.data, "payout", $$v);
      },
      expression: "data.payout"
    }
  })], 1), _c('y-form-group', [_c('YInput', {
    staticClass: "form-control",
    attrs: {
      "label": "Revenue",
      "placeholder": "0.00",
      "name": "revenue"
    },
    model: {
      value: _vm.data.revenue,
      callback: function ($$v) {
        _vm.$set(_vm.data, "revenue", $$v);
      },
      expression: "data.revenue"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "flex justify-end mt-3"
  }, [_c('YButton', {
    staticClass: "border border-blue-600 hover:bg-blue-600 focus:bg-blue-600 text-blue-600 hover:text-white focus:text-white mr-3 outline-none-important",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearForm.apply(null, arguments);
      }
    }
  }, [_vm._v("Reset")]), _c('YButton', {
    staticClass: "bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 text-white mr-3 outline-none-important",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addToPreview.apply(null, arguments);
      }
    }
  }, [_vm._v("Add to Preview")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }