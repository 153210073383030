var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.me && _vm.me.isAPublisher ? _c('YAlert', {
    staticClass: "mt-0 mb-0 px-6",
    attrs: {
      "color": "red"
    }
  }, [_c('div', {
    staticClass: "flex justify-between items-center"
  }, [_c('div', [_c('h6', {
    staticClass: "mb-0 font-weight-semibold"
  }, [_vm._v(" Due to system maintenance today's stats (May 3rd) may be delayed. This will be fixed within 24 hours. Links and postbacks are working normally. Thanks for your understanding. ")])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }