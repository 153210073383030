<template>
  <div id="top">
    <portal to="top-bar-right">
      <div class="flex justify-right">
        <YButton class="btn btn-blue" @click="openCreateModal"
          >New Tag
        </YButton>
      </div>
    </portal>
    <Widget class="mb-1 overflow-visible">
      <WidgetHeader class="bg-gray-200 p-1 border-b">
        <template slot="rawContent">
          <TagFilters></TagFilters>
        </template>
      </WidgetHeader>
    </Widget>
    <YTable
      v-if="tags"
      :data="tags.data || []"
      :bottom-loading="$apolloData.queries.tags.loading"
      class="mt-2"
    >
      <template slot="header-row">
        <th>Tag</th>
        <th>Type</th>
        <th></th>
      </template>
      <template slot="row" slot-scope="props">
        <td>
          <tag :color="props.rowData.color">{{ props.rowData.name }}</tag>
        </td>
        <td>
          {{ props.rowData.type }}
        </td>
        <td class="text-right">
          <div class="inline-flex items-center">
            <a
              href="#"
              class="link"
              @click.prevent="
                openEditModal(
                  props.rowData.id,
                  props.rowData.name,
                  props.rowData.type,
                  props.rowData.color
                )
              "
            >
              <Icon name="pencil" class="w-4 h-4" />
            </a>
            <a
              href="#"
              class="ml-2 text-red-500 hover:text-red-600"
              @click.prevent="
                openDeleteModal(
                  props.rowData.id,
                  props.rowData.name,
                  props.rowData.type
                )
              "
            >
              <Icon name="trashCan" class="w-4 h-4" />
            </a>
          </div>
        </td>
      </template>
    </YTable>
    <div class="w-100 py-4">
      <YPagination
        v-if="tags.paginatorInfo"
        :data="tags.paginatorInfo"
        @load-page="loadPage"
        @next-page="nextPage"
        @previous-page="previousPage"
        :key="totalPages"
        :onEachSide="2"
        @per-page="perPageSelection"
      ></YPagination>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TagFilters from '@/views/Admin/Tags/TagsFilters'
import DeleteTag from '@/views/Admin/Tags/DeleteTag.vue'
import CreateTag from '@/views/Admin/Tags/CreateTag.vue'
import EditTag from '@/views/Admin/Tags/EditTag.vue'
import TAGS_QUERY from '@/graphql/Tag/TagsQuery.gql'
import BackToTop from '@/mixins/BackToTopMixin.js'
import YPagination from '@/components/ui/YPagination.vue'

export default {
  mixins: [BackToTop],
  components: {
    TagFilters,
    YPagination
  },
  apollo: {
    tags: {
      query: TAGS_QUERY,
      variables() {
        return {
          first: this.perPage,
          page: this.currentPage,
          orderBy: [{ column: 'id', order: 'ASC' }],
          filters: {
            name: this.cleanFilter(this.filters.tag),
            type: this.cleanFilter(this.filters.type)
          }
        }
      },
      result({ data }, key) {
        this.totalPages = data[key].paginatorInfo.lastPage
      },
      deep: true
    }
  },
  data() {
    return {
      tags: [],
      currentPage: 1,
      perPage: 20,
      totalPages: 1,
      // fetchMoreOnScrollToBottom: 'tags',
      filterName: null
    }
  },
  computed: {
    ...mapState({
      filters: state => state.tag.filters
    }),
    orderBy: {
      get() {
        return this.$store.state.tag.orderBy
      },
      set(value) {
        this.$store.state.tag.orderBy = JSON.parse(JSON.stringify(value))
      }
    }
  },
  updated() {},
  mounted() {
    this.$events.listen('refreshTags', () => {
      this.$apollo.queries.tags.refetch()
    })
  },
  beforeDestroy() {
    this.$events.remove('refreshTags')
  },
  watch: {
    totalPages() {
      this.currentPage = 1
    }
  },
  methods: {
    loadPage(page) {
      this.currentPage = page
      this.backToTop()
    },
    nextPage() {
      this.currentPage++
      this.backToTop()
    },
    previousPage() {
      this.currentPage--
      this.backToTop()
    },
    perPageSelection(value) {
      this.perPage = value
    },
    ...mapActions(['updateGlobalLoader']),
    cleanFilter(filter) {
      return filter.value
        ? {
            value: Array.isArray(filter.value) ? filter.value : [filter.value],
            modifiers: filter.modifiers
          }
        : undefined
    },
    cleanObjectFilter(filter, pluck) {
      if (this.cleanFilter(filter) === undefined) {
        return undefined
      }

      // pluck key from value
      return {
        value: this.cleanFilter(filter).value.map(value => value[pluck]),
        modifiers: filter.modifiers
      }
    },
    openDeleteModal(id, name, type) {
      this.$modal.show(
        DeleteTag,
        { id: parseInt(id), name: name, type: type },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openEditModal(id, name, type, color) {
      this.$modal.show(
        EditTag,
        { id: parseInt(id), name: name, type: type, color: color },
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    },
    openCreateModal() {
      this.$modal.show(
        CreateTag,
        {},
        {
          height: 'auto',
          width: '80%',
          maxWidth: 600,
          adaptive: true,
          scrollable: true
        }
      )
    }
  }
}
</script>

<style scoped></style>
